import styled, { css } from 'styled-components'
import { variant } from 'styled-system'

export const Wrapper = styled.div(
  ({ theme: { colors, radii, fonts, fontSizes, fontWeights, shadows } }) => css`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;

    padding: 12px;
    border-radius: ${radii.xxlarge};

    font-family: ${fonts.primary};
    font-size: ${fontSizes.E};
    font-weight: ${fontWeights.regular};

    box-shadow: ${shadows.boxShadow.outer};
    border: 1px solid ${colors.border.A};
    color: ${colors.foreground.secondary.B};
    background-color: ${colors.background.secondary.A};

    a,
    .link {
      color: inherit;
      text-decoration: underline;
    }

    a:hover,
    .link:hover {
      color: inherit;
    }
  `
)

export const ChildrenWrapper = styled.div`
  padding-left: 12px;
  white-space: pre-wrap;
`

export const Icon = styled.svg(
  ({ theme }) => css`
    width: 16px;
    height: 16px;
    flex-shrink: 0;

    ${() =>
      variant({
        variants: {
          info: {
            color: theme.colors.foreground.secondary.D,
          },
          negative: {
            color: theme.colors.state.negative.A,
          },
          positive: {
            color: theme.colors.state.positive.A,
          },
        },
      })}
  `
)

export const CloseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;
  margin-left: 16px;

  cursor: pointer;
`
