import { light } from './colors'

/**
 * Shadow tokens.
 *
 * Named shadows defined in a plain object
 *
 * For use with the following CSS properties:
 *
 *     box-shadow, text-shadow
 *
 * @see https://system-ui.com/theme
 */

// TODO: remove these values or include it as a part of every theme
export const shadows = {
  boxShadow: {
    elevated: `0 7px 25px 0  ${light.colors.border.A}`,
    raised: `0 3px 12px 0  ${light.colors.border.A}`,
    lifted: `0 2px 5px 0 ${light.colors.border.A}`,
    subtle: `0 1px 3px 0  ${light.colors.border.A}`,
    inner: `inset 0 1px 2px 0 ${light.colors.shadow.inner}`,
    outer: `0 4px 8px 0 ${light.colors.shadow.outer}`,
  },
} as const
