import React from 'react'

import { Button, Modal, ModalInjectedProps } from '@sketch/components'
import { useUpdateUserPreferencesMutation } from '@sketch/gql-types'

const SkipFirstStepsModal = (props: ModalInjectedProps) => {
  const [updateUserPreferences, { loading }] = useUpdateUserPreferencesMutation(
    {
      onError: 'show-toast',
      variables: { showOnboardingFirstSteps: false },
      onCompleted: props.hideModal,
    }
  )

  return (
    <Modal onCancel={props.hideModal}>
      <Modal.Header>Skip First Steps?</Modal.Header>
      <Modal.Body>
        Already know your way around Sketch? Feel free to skip guidance for
        newcomers.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.hideModal}>Cancel</Button>
        <Button
          variant="primary"
          loading={loading}
          onClick={() => updateUserPreferences()}
        >
          Skip
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SkipFirstStepsModal
