import { touchableClick } from '@sketch-hq/sketch-web-renderer'
import { useMemo } from 'react'

type TouchableClickEvent<E extends HTMLElement> =
  | React.MouseEvent<E>
  | React.TouchEvent<E>

type CallbackEvent = 'click' | 'cta-click' | 'double-click'
type Callback = (
  event: TouchableClickEvent<any>,
  eventType: CallbackEvent
) => void

export const useArtboardEvents = (callback: Callback) => {
  const touchableClickEvents = useMemo(
    () => ({
      container: touchableClick((e: TouchableClickEvent<HTMLDivElement>) => {
        callback(e, 'click')
      }),
      cta: touchableClick((e: TouchableClickEvent<HTMLButtonElement>) => {
        callback(e, 'cta-click')
      }),
      containerDoubleClick: (e: React.MouseEvent<HTMLDivElement>) => {
        callback(e, 'double-click')
      },
    }),
    [callback]
  )

  return touchableClickEvents
}
