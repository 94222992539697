import styled, { keyframes, css } from 'styled-components'
import { ImageWrapper } from '../../../shares/components/DocumentItem/DocumentItem.styles'

const reveal = keyframes`
    0% {
      opacity: 0;
      transform: translateY(-5px)
    }
    100% {
      opacity: 1;
      transform: translateY(0)
    }
  `

// We want to show a tooltip when a share is dragged over the droppable area.
// Unfortunately `@sketch/components/Tooltip` is tightly coupled to the idea of
// having a "trigger" area that will show the toolip on hover so we can't
// easily re-use that component.
// Instead we have taken a minimal subset of the styles to create a custom
// component that looks similar, and can be shown when a share is dragged over
// the droppable area.
export const OnDraggedOverTooltip = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.ui.tooltips.background};
    color: ${theme.colors.ui.tooltips.foreground};
    border-radius: ${theme.radii.xlarge};
    font-size: ${theme.fontSizes.C};
    animation: ${reveal} ${theme.transitions.duration[4]}
      ${theme.transitions.timing.easeOutExponential};
    padding: 8px 12px;
    position: absolute;
    top: -42px;
    left: 50%;
    margin-left: -60px;
    box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, 0.04);
    z-index: ${({ theme }) => theme.zIndex[8]};
  `
)

export const DroppableArea = styled(ImageWrapper)<{
  $isDraggedOver: boolean
}>`
  &&&::after {
    ${({ $isDraggedOver, theme }) =>
      $isDraggedOver && `box-shadow: inset 0 0 0 4px ${theme.colors.sketch.D};`}

    ${({ $isDraggedOver, theme }) =>
      $isDraggedOver && `background: ${theme.colors.sketch.F};`}
  }
`
