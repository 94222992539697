import React from 'react'
import BaseTimeAgo, {
  DefaultTimeAgoProps,
  Formatter,
  defaultFormatter,
} from './DefaultTimeAgo'

export const timeUnits = {
  second: 's',
  minute: 'm',
  hour: 'h',
  day: 'd',
  week: 'w',
  month: 'mo',
  year: 'y',
}

export function createLongDateFormatter(capitalizeNow?: boolean) {
  const now = capitalizeNow ? 'Now' : 'now'

  const formatter: Formatter = (...args) => {
    const [value, unit, suffix] = args
    if ((value === 1 && unit === 'day') || (value === 24 && unit === 'hour'))
      return 'Yesterday'

    return unit === 'second' ? now : defaultFormatter(value, unit, suffix)
  }

  return formatter
}

export function createShortDateFormatter(
  showSuffix?: boolean,
  capitalizeNow?: boolean
) {
  const now = capitalizeNow ? 'Now' : 'now'

  const formatter: Formatter = (value, unit, suffix) => {
    if (unit === 'second') return now

    const formattedDate = `${value}${timeUnits[unit]}`
    if (showSuffix) {
      return `${formattedDate} ${suffix}`
    }

    return `${formattedDate}`
  }

  return formatter
}

export interface TimeAgoProps extends DefaultTimeAgoProps {
  short?: boolean
  showShortDateSuffix?: boolean
  hideTitle?: boolean
  capitalizeNow?: boolean
}

export const TimeAgo = (props: TimeAgoProps) => {
  const {
    date,
    short = false,
    showShortDateSuffix = false,
    hideTitle = false,
    capitalizeNow = true,
    ...otherProps
  } = props

  const formatter = short
    ? createShortDateFormatter(showShortDateSuffix, capitalizeNow)
    : createLongDateFormatter(capitalizeNow)

  return (
    <BaseTimeAgo
      date={date}
      title={!hideTitle ? new Date(date).toLocaleString() : undefined}
      formatter={formatter}
      {...otherProps}
    />
  )
}
