import React, { useState } from 'react'
import QRCode from 'qrcode.react'

import { Tooltip, Link } from '@sketch/components'
import { useHandleCopyValue } from '@sketch/modules-common'

// Icon
import Logo from '@sketch/icons/logo-qr-code.svg'

import {
  Title,
  Description,
  Code,
  Columns,
  Column,
  List,
  Item,
} from '../Setup2FAModal.styles'

interface QRCodeStepProps {
  otpAuthUri: string
}

const QR_CODE_OPTIONS = {
  src: Logo,
  width: 52,
  height: 52,
  excavate: true,
}

/**
 * QRCodeStep
 *
 * Renders the second step for the 2FA Setup Modal
 */
export const QRCodeStep: React.FC<QRCodeStepProps> = ({ otpAuthUri }) => {
  const [showQRCode, setShowQRCode] = useState(true)

  // Get the secret from the full URI
  const secret = otpAuthUri?.split('=')[2]

  const {
    handleCopyValue,
    copyTooltipText,
    handleEnterCopiableArea,
    handleLeaveCopiableArea,
    copyTooltipVisible,
  } = useHandleCopyValue(secret.replace(/\s/g, ''))

  const handleMouseEnterLabel = () =>
    !secret ? null : handleEnterCopiableArea()
  const handleMouseLeaveLabel = () =>
    !secret ? null : handleLeaveCopiableArea()

  return (
    <>
      <Title>Scan the QR Code</Title>
      <Description>
        Next, link your Sketch account to the authenticator app you just
        installed:
      </Description>
      {showQRCode ? (
        <>
          <Columns>
            <Column>
              <QRCode
                data-testid="qr-code-image"
                value={otpAuthUri}
                imageSettings={QR_CODE_OPTIONS}
                renderAs="svg"
                level="H"
                size={144}
              />
            </Column>
            <List>
              <Item>Open the authenticator app </Item>
              <Item>Tap Scan a QR code </Item>
              <Item>Scan this code with your phone camera </Item>
            </List>
          </Columns>
          <Link
            onClick={e => {
              e.preventDefault()

              setShowQRCode(false)
            }}
            isUnderlined
            external
          >
            Or type in the code
          </Link>
        </>
      ) : (
        <>
          <Code
            data-testid="mfa-activation-code"
            onClick={handleCopyValue}
            onMouseEnter={handleMouseEnterLabel}
            onMouseLeave={handleMouseLeaveLabel}
          >
            <Tooltip
              placement="bottom"
              visible={copyTooltipVisible}
              content={copyTooltipText}
            >
              {secret}
            </Tooltip>
          </Code>
          <Link
            onClick={e => {
              e.preventDefault()

              setShowQRCode(true)
            }}
            isUnderlined
            external
          >
            Scan the code instead
          </Link>
        </>
      )}
    </>
  )
}
