import React, { useCallback, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import {
  Button,
  TimeAgo,
  Section,
  useModalContext,
  Tooltip,
} from '@sketch/components'

// Modals
import ConfirmRevokeAllSessionsModal from './ConfirmRevokeAllSessionsModal'
import ConfirmRevokeIndividualSessionModal from './ConfirmRevokeIndividualSessionModal'

import { countries } from '@sketch/constants'
import { getDeviceNameString } from './SessionsManagementPanel.utils'

import {
  ActiveCircle,
  Cell,
  CurrentPill,
  DeviceInfoStatus,
  DeviceInfoText,
  DeviceInfoWrapper,
  DeviceLastSeen,
  DeviceName,
  Row,
  Table,
} from './SessionsManagementPanel.styles'
import { useGetUserSettingsQuery } from '@sketch/gql-types'

const SessionsManagementPanel: React.FC = () => {
  const { data, loading } = useGetUserSettingsQuery()
  const { showModal } = useModalContext()
  const location = useLocation()
  const sectionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    /**
     * We need this effect to scroll to the Sessions Management section when we have the
     * anchor #sessions in the URL. We use this to link this section directly in the
     * email we send to the user informing about a new login.
     */
    if (location.hash && location.hash === '#sessions' && sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [location.hash, sectionRef, data])

  const renderSignOutButton = useCallback(
    session =>
      session.isCurrentOne ? (
        <Tooltip
          placement="top"
          content="This is your current session, so there’s no need to sign out"
        >
          <Button disabled variant="secondary-untinted" size="32">
            Sign Out&hellip;
          </Button>
        </Tooltip>
      ) : (
        <Button
          onClick={() =>
            showModal(ConfirmRevokeIndividualSessionModal, session)
          }
          variant="secondary-untinted"
          size="32"
        >
          Sign Out&hellip;
        </Button>
      ),
    [showModal]
  )

  const renderRow = useCallback(
    session => (
      <Row key={session.id} data-testid="sessions-list-row">
        <Cell>
          <DeviceInfoWrapper>
            <DeviceInfoStatus>
              <ActiveCircle />
            </DeviceInfoStatus>
            <DeviceInfoText>
              <DeviceName>
                {getDeviceNameString(
                  session.type,
                  session.userAgent,
                  session.deviceName
                )}{' '}
                {session.isCurrentOne && <CurrentPill>current</CurrentPill>}
              </DeviceName>
              <DeviceLastSeen data-testid="session-info">
                {session.lastUpdateAt && (
                  <TimeAgo date={session.lastUpdateAt} />
                )}{' '}
                in {countries[session.countryCode as keyof typeof countries]}{' '}
                {session.ipAddress && `(${session.ipAddress})`}
              </DeviceLastSeen>
            </DeviceInfoText>
            {renderSignOutButton(session)}
          </DeviceInfoWrapper>
        </Cell>
      </Row>
    ),
    [renderSignOutButton]
  )

  const sessions = data?.me.activeSessions?.entries

  return (
    <Section
      ref={sectionRef}
      data-testid="sessions-management-section"
      title="Signed In Devices"
      text={
        <div>
          You can sign out of any active sessions, particularly devices that you
          don&apos;t recognize.
        </div>
      }
      action={
        <Button
          onClick={() => showModal(ConfirmRevokeAllSessionsModal)}
          size="40"
        >
          Sign Out of All&hellip;
        </Button>
      }
    >
      {!loading && !!sessions && (
        <Table
          header={[{ label: 'Device Info' }]}
          items={sessions}
          renderItem={renderRow}
          hideHeader
        />
      )}
    </Section>
  )
}

export default SessionsManagementPanel
