import { AllowedAccessLevels } from './types'

// Usage of PublicAccessLevel and PrivateAccessLevel will not break the build
// eslint-disable-next-line no-restricted-imports
import {
  PublicAccessLevel,
  PrivateAccessLevel,
  WorkspaceAccessLevel,
} from '@sketch/gql-types/expansive'

export function isPublicAccessLevel(value: string): value is PublicAccessLevel {
  const none: PublicAccessLevel = 'NONE'
  const view: PublicAccessLevel = 'VIEW'
  return value === none || value === view
}

export function isPrivateAccessLevel(
  value: string
): value is PrivateAccessLevel {
  const edit: PrivateAccessLevel = 'EDIT'
  const view: PrivateAccessLevel = 'VIEW'
  return value === edit || value === view
}

export function isWorkspaceAccessLevel(
  value: string
): value is WorkspaceAccessLevel {
  const editLevel: WorkspaceAccessLevel = 'DEFAULT'
  const viewLevel: WorkspaceAccessLevel = 'VIEW'
  return value === editLevel || value === viewLevel
}

export const getTriggerCopyFromAccessLevel = (level: AllowedAccessLevels) => {
  switch (level) {
    case 'DEFAULT':
      return 'Default'
    case 'EDIT':
      return 'Edit'
    case 'VIEW':
      return 'View'
    case 'NONE':
      return 'No Access'
    case 'PENDING':
      return 'Request Pending'
  }
}
