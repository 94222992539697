/**
 * Used as a flag for the marketing site to redirect signed in users to cloud.
 *
 * Infra reads this cookie and redirects usr to /c if present
 * The website cleans the redirect cookie, as it should now be managed by infra
 */
const COOKIE_NAME = 'logged_in'

export const useMarketingCookies = () => {
  const setCookies = () => {
    // Make sure the cookie is not added twice
    if (!document.cookie.match(COOKIE_NAME)) {
      document.cookie = `${COOKIE_NAME}=1; path=/;`
    }
  }

  const removeCookies = () => {
    const expiryDate = new Date()
    // Set a random date in the past
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000)

    document.cookie = `${COOKIE_NAME}=;max-age=0;expires=${expiryDate.toUTCString()}`
  }

  return { setCookies, removeCookies }
}
