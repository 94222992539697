import styled from 'styled-components'
import { ReactComponent as ChatIcon } from '@sketch/icons/chat-bubble-16'
import { DropdownWrapper } from '@sketch/components'

export const Wrapper = styled.div`
  position: fixed;
  padding-top: 8px;
  left: 50%;
  top: ${({ theme }) => theme.header.height}px;
  transform: translate(-50%, 0);
  z-index: 1;
`

export const Dropdown = styled(DropdownWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
`

export const Text = styled.span`
  font-size: 0.8125rem;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  margin-right: 8px;
  white-space: nowrap;
`

export const Icon = styled(ChatIcon)`
  color: ${({ theme }) => theme.colors.sketch.A};
  width: 16px;
  height: 16px;
  margin-right: 8px;
`
