import { useCallback, useReducer } from 'react'
import {
  ProjectMembershipFragment,
  UpdateProjectMembershipMutation,
  useUpdateProjectMembershipMutation,
} from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'

type Values = ProjectMembershipFragment

type State = {
  initialValues: Values
  values: Values
}

type Action =
  | { type: 'set-mutating' }
  | { type: 'reset' }
  | { type: 'success'; payload: { values: Values } }
  | { type: 'optimistic-success'; payload: { values: Values } }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'reset':
      return {
        ...state,
        values: { ...state.initialValues },
      }
    case 'success':
      return {
        ...state,
        values: action.payload.values,
        initialValues: action.payload.values,
      }
    case 'optimistic-success':
      return {
        ...state,
        values: action.payload.values,
      }
    default:
      return state
  }
}

interface UseUpdateProjectMemberProps {
  member: ProjectMembershipFragment
}

export const useUpdateProjectMember = ({
  member,
}: UseUpdateProjectMemberProps) => {
  const { showToast } = useToast()
  const [{ values }, dispatch] = useReducer(reducer, {
    initialValues: member,
    values: member,
  })

  const [
    updateMemberMutation,
    { loading },
  ] = useUpdateProjectMembershipMutation({
    onCompleted(data) {
      dispatch({
        type: 'success',
        payload: { values: data.updateProjectMembership.projectMembership },
      })
    },
    onError() {
      dispatch({ type: 'reset' })
      showToast(
        'There was a problem changing the member permissions. Please try again.',
        'negative'
      )
    },
  })

  const { identifier: membershipIdentifier } = member
  const updateMember = useCallback(
    ({
      guestCommentsEnabled,
      guestInspectEnabled,
    }: {
      guestCommentsEnabled: boolean | null
      guestInspectEnabled: boolean | null
    }) => {
      updateMemberMutation({
        variables: {
          membershipIdentifier,
          guestCommentsEnabled,
          guestInspectEnabled,
        },
        optimisticResponse: vars => {
          const {
            membershipIdentifier,
            guestCommentsEnabled,
            guestInspectEnabled,
          } = vars as ExcludeEmpty<typeof vars>
          const response: UpdateProjectMembershipMutation = {
            __typename: 'RootMutationType',
            updateProjectMembership: {
              __typename: 'UpdateProjectMembershipResponse',
              projectMembership: {
                ...member,
                identifier: membershipIdentifier,
                guestCommentsEnabled: guestCommentsEnabled!,
                guestInspectEnabled: guestInspectEnabled!,
              },
            },
          }

          dispatch({
            type: 'optimistic-success',
            payload: {
              values: response.updateProjectMembership.projectMembership,
            },
          })

          return response
        },
      })
    },
    [updateMemberMutation, member, membershipIdentifier]
  )

  const onPermissionChange = useCallback(
    (
      permission: 'guestCommentsEnabled' | 'guestInspectEnabled',
      enabled: boolean
    ) => {
      updateMember({
        ...values,
        [permission]: enabled,
      })
    },
    [values, updateMember]
  )

  return {
    loading,
    updateMember,
    accessLevelProps: {
      guestCommentsEnabled: values.guestCommentsEnabled!,
      guestInspectEnabled: values.guestInspectEnabled!,
      onPermissionChange,
    },
  }
}
