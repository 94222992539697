import { useCallback, useContext, useEffect, useReducer, useRef } from 'react'
import { get, Path, set, ValueAtPath } from '@sketch/utils'
import { DevToolsSettingsContext, SetSettingOptions } from './DevToolsContext'
import { isEqual } from 'lodash'

import { DevToolsSettingsState, PartialDevToolsSettings } from './settingsState'

export const useDevToolsSetting = <P extends Path<DevToolsSettingsState>>(
  path: P
) => {
  const context = useContext(DevToolsSettingsContext)

  const [, forceRender] = useReducer(s => s + 1, 0)

  const { settings, setSettings, subscription } = context

  const valueRef = useRef(get(settings.current, path))

  useEffect(() => {
    const callback = () => {
      const newValue = get(settings.current, path)

      if (isEqual(newValue, valueRef.current)) {
        return
      }

      valueRef.current = newValue
      forceRender()
    }

    subscription.subscribe(callback)
    return () => {
      subscription.unsubscribe(callback)
    }
  }, [path, settings, subscription])

  const setValue = useCallback(
    (
      value: DeepPartial<ValueAtPath<DevToolsSettingsState, P>>,
      options?: SetSettingOptions
    ) => {
      const hydratedObject = set(
        {} as PartialDevToolsSettings,
        path as any,
        value
      )
      setSettings(hydratedObject, options)
    },
    [path, setSettings]
  )

  return [valueRef.current, setValue] as const
}
