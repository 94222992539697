import styled from 'styled-components'

import { Skeleton } from '@sketch/components'
import EmptyStateUnstyled from '../../components/EmptyState'

export const EmptyState = styled(EmptyStateUnstyled)`
  margin: 0 auto;
`

export const StyledSkeleton = styled(Skeleton)`
  margin-top: 12px;
`
