import React, { useRef, useState, useMemo, useCallback } from 'react'

import {
  bannerContext,
  BannerItem,
  BannerParameters,
  DismissBannerFn,
} from './bannerContext'
import { noop, uniqueId } from '@sketch/utils'

const { Provider } = bannerContext

interface BannersContext {
  [id: string]: Required<
    Pick<BannerParameters, 'message' | 'type' | 'dismissible' | 'onDismiss'>
  >
}

export const BannerProvider: React.FC = ({ children }) => {
  const banners = useRef<BannersContext>({})
  const [items, setItems] = useState<string[]>([])

  const dismissBanner = useCallback((id: string) => {
    setItems(items => items.filter(itemId => itemId !== id))

    banners.current[id]?.onDismiss()
    delete banners.current[id]
  }, []) as DismissBannerFn

  const showBanner = useCallback(
    (parameters: BannerParameters) => {
      const {
        message,
        type = 'information',
        id = uniqueId('banner'),
        dismissible = false,
        onDismiss = noop,
      } = parameters

      banners.current[id] = {
        message,
        type,
        dismissible,
        onDismiss,
      }

      setItems(items => [...items, id])

      return {
        id,
        dismissBanner: dismissBanner.bind(null, id as any),
      }
    },
    [dismissBanner]
  )

  const memoizedValue = useMemo(() => {
    return {
      showBanner,
      dismissBanner,
      items: items.map<BannerItem>(id => {
        const { message, type, dismissible } = banners.current[id]

        return {
          /**
           * There is a special case here, with the BannerId. BannersMap interface is expanded in other (higher up) packages, e.g.:
           * ```ts
           * declare module '@sketch/components' {
           *   export interface BannersMap {
           *     'mfa-banner': null
           *   }
           * }
           * ```
           *
           * And when these packages run their type-checking, they will expect the BannerId to be a different thing than for other packages.
           * This makes it hard to correctly type this field, therefore we are using `as any` here to avoid the type-checking entirely.
           */
          id: id as any,
          type,
          dismissible,
          message,
        }
      }),
    }
  }, [dismissBanner, items, showBanner])

  return <Provider value={memoizedValue}>{children}</Provider>
}
