import {
  BillingMinimalFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'
import useWelcomeModal from './useWelcomeModal'

export interface WelcomeModalTriggerProps {
  workspace: WorkspaceMinimalFragment
  subscriptionDetails: Pick<BillingMinimalFragment, 'status'>
}

export const WelcomeModalTrigger = (props: WelcomeModalTriggerProps) => {
  const { workspace, subscriptionDetails } = props
  useWelcomeModal(workspace, subscriptionDetails)

  return null
}
