import styled from 'styled-components'
import { Button, Text, WorkspaceLogo } from '@sketch/components'
import {
  IndexLayoutOldTitle,
  IndexLayoutOldSubtitle,
} from '@sketch/modules-common'

import { breakpoint } from '@sketch/global-styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 472px;
  margin: 0 auto;
`

export const StyledLogo = styled(WorkspaceLogo)`
  display: flex;
  justify-content: center;
  margin: 0 auto 64px;
`

export const StyledButton = styled(Button)`
  margin: 0 0 16px;

  &:last-child {
    margin: 0;
  }

  > div {
    /* Prevent a long workspace name to appear cropped */
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    line-height: 1.2;
  }
`

export const StyledTitle = styled(IndexLayoutOldTitle)`
  margin: 0 0 32px;
`

export const StyledSubtitle = styled(IndexLayoutOldSubtitle)`
  font-size: 1rem;
  margin: 40px 0 18px; /* stylelint-disable-line scales/space */
  color: ${({ theme }) => theme.colors.foreground.secondary.B};

  ${breakpoint('sm')`
    font-size: 1.375rem;
    margin: 68px 0 18px;
  `};
`

export const WrapperMemberCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 20px 24px;
  margin-bottom: 24px;

  border-radius: ${({ theme }) => theme.radii.medium};
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  box-shadow: ${({ theme }) => theme.shadows.boxShadow.lifted};
  background: ${({ theme }) => theme.colors.background.secondary.A};
`

export const RoleText = styled(Text.Span).attrs({
  textStyle: 'copy.tertiary.standard.D',
})`
  margin-left: 16px;
  flex-shrink: 0;
`

export const InfoText = styled.span`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 24px;
`

export const DifferentAccountText = styled(Text.Span).attrs({
  textStyle: 'copy.tertiary.standard.D',
})`
  margin-bottom: 24px;
  text-align: center;

  > b {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
  }
`
