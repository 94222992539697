import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { ApolloLink, Operation } from 'apollo-link'

import {
  GetProjectSharesDropdownOptionsDocument,
  GetProjectSharesDropdownOptionsQuery,
  GetProjectSharesDropdownOptionsQueryVariables,
  GetProjectSharesQueryVariables,
  GetSharesDropdownOptionsDocument,
  GetSharesDropdownOptionsQuery,
  GetSharesDropdownOptionsQueryVariables,
  GetSharesQueryVariables,
  ShareDropdownOptionsPreloadFragment,
  ShareDropdownOptionsPreloadFragmentDoc,
  QueriesMap,
} from '@sketch/gql-types'

import { dataIdFromObject } from '@sketch/graphql-cache'
import { ErrorHandler } from '@sketch/tracing'
import { castError } from '@sketch/utils'

type OperationName = keyof QueriesMap

/**
 * We are fetching list of shares using `no-cache` and then inserting
 * items of that list ourselves using `client.writeFragment` because
 * keys of the Apollo's cache for the list of shares matches
 * (list cache keys are generated by the Apollo, we don't have control here)
 * and effectively conflicts with the pagination of shares.
 *
 * @example
 * Let's take an example, let's say we paginate list of shares using `getShares` query
 * Generated key for that list in the cache might be something like this:
 *    `$Workspace:04fd54b2-0ac9-4bab-9749-749a34ab4ece.shares({"after":null,"search":{"filter":null,"name":""}})`
 *
 * But we'll get the same key once we query list of shares using `getSharesDropdownOptions` query.
 *
 * And let's say that using `getShares` query we have loaded 2 more pages of shares (in total we've loaded 60 shares).
 * And let's say that after that we'll query `getSharesDropdownOptions` (having `after: null`) -
 * once we'll receive a result (list of 20 shares), we'll override the current know list of shares,
 * effectively removing all additional shares fetched using pagination.
 *
 * ------ ------ ------ ------ ------
 *
 * So to avoid all of this, when we are fetching `ShareDropdownOptionsPreload` we are avoiding
 * to interact with the list of shares at all.
 */
const cacheShares = (
  client: ApolloClient<unknown>,
  shares: ShareDropdownOptionsPreloadFragment[]
) => {
  for (const share of shares) {
    client.writeFragment({
      fragment: ShareDropdownOptionsPreloadFragmentDoc,
      fragmentName: 'ShareDropdownOptionsPreload',
      data: share,
      id: dataIdFromObject(share)!,
    })
  }
}

const prefetchWorkspaceDropdownOptions = async (
  operation: Operation,
  client: ApolloClient<unknown>
) => {
  const operationName = operation.operationName as OperationName

  if (operationName !== 'getShares') return

  const variables = operation.variables as GetSharesQueryVariables

  const result = await client.query<
    GetSharesDropdownOptionsQuery,
    GetSharesDropdownOptionsQueryVariables
  >({
    query: GetSharesDropdownOptionsDocument,
    variables,
    fetchPolicy: 'no-cache',
  })

  const shares = result.data.workspace.shares.entries
  if (!shares) return

  cacheShares(client, shares)
}

const prefetchProjectDropdownOptions = async (
  operation: Operation,
  client: ApolloClient<unknown>
) => {
  const operationName = operation.operationName as OperationName

  if (operationName !== 'getProjectShares') return

  const variables = operation.variables as GetProjectSharesQueryVariables

  const result = await client
    .query<
      GetProjectSharesDropdownOptionsQuery,
      GetProjectSharesDropdownOptionsQueryVariables
    >({
      query: GetProjectSharesDropdownOptionsDocument,
      variables,
      fetchPolicy: 'no-cache',
      // This middleware should ignore the error,
      // It will be handled by the query "getProjectShares"
      errorPolicy: 'ignore',
    })
    .catch(() => ({
      // Return an "result" object with null data so we can check below
      data: null,
    }))

  const shares = result.data?.project.shares.entries
  if (!shares) return

  cacheShares(client, shares)
}

export const createPrefetchDropdownOptionsLink = (
  getClient: () => ApolloClient<NormalizedCacheObject> | undefined
) => {
  const apolloLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(result => {
      const client = getClient()
      if (!client) return result

      try {
        prefetchWorkspaceDropdownOptions(operation, client)
        prefetchProjectDropdownOptions(operation, client)
      } catch (e) {
        const err = castError(e)
        ErrorHandler.ignore(err)
      }

      return result
    })
  })

  return apolloLink
}
