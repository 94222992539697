import React from 'react'
import { Form as FormikForm, FormikFormProps } from 'formik'

export const Form = React.forwardRef<HTMLFormElement, FormikFormProps>(
  function Form({ children, ...props }, ref) {
    return (
      <FormikForm ref={ref} translate="yes" {...props}>
        {children}
      </FormikForm>
    )
  }
)
