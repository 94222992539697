import styled, { css } from 'styled-components'

import { HeaderWrapper } from '../Comment'

export interface AnnotationListWrapperProps {
  $isModalVariant: boolean
}

export const AnnotationPopoverListWrapper = styled.div<AnnotationListWrapperProps>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  & [data-first-comment='true'] {
    & ${HeaderWrapper} {
      padding-right: 60px;

      ${({ $isModalVariant }) =>
        $isModalVariant &&
        css`
          padding-right: 108px;
        `}
    }
  }
`
