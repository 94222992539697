import styled, { css } from 'styled-components'

import { ReactComponent as LogoBlackIcon } from '@sketch/icons/logo-black'

import { IconButton } from '../Button'
import { Text, Flex, FlexProps } from '../Box'
import { NavbarLinkWrapper } from './NavbarLinkWrapper'
import { truncateStyles } from '../Truncate'

export const navbarTitleCss = css`
  ${truncateStyles};

  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  min-width: 25px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`

export const GradientSeparator = styled.span`
  display: flex;
  align-self: center;
  flex: 0 0 auto;
  height: 4rem;
  width: 1px;
  margin: 0 4px;
  opacity: 0.08;
  background-image: linear-gradient(
    -180deg,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 0.65) 32%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0.65) 68%,
    rgba(0, 0, 0, 0) 90%
  );
`

export const NavbarSection = styled(Flex)<
  FlexProps & { align?: 'start' | 'center' | 'end' }
>`
  display: flex;
  height: 100%;
  min-height: 100%;
  align-items: center;
  flex: 1 0px;
  overflow: hidden;
  justify-content: ${({ align = 'start' }) =>
    align === 'center' ? 'center' : `flex-${align}`};
  gap: 4px;
`

export const FixedWidthNavbarSection = styled(NavbarSection)<
  FlexProps & { align?: 'start' | 'center' | 'end' }
>`
  flex: none;
`

export const NavbarLogoWrapper = styled(NavbarLinkWrapper)`
  display: flex;
  height: 3rem;
  width: 3rem;
  align-items: center;
  justify-content: center;
  padding: 0;
`

export const LogoBlack = styled(LogoBlackIcon)`
  width: 20px;
  height: 18.75px;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`

export const NavbarTitle = styled(Text).attrs({
  'data-testid': 'navbar-title',
})`
  ${navbarTitleCss};
`

export const NavbarBase = styled.div<{
  fixed?: boolean
  isSidebarLeftOpen?: boolean
  // This prop helps to distinguish from the Document View and CWV from everywhere else.
  // The header is white in Document View and CWV and grey elsewhere
  isDocumentView?: boolean
  /** There's only a single children element */
  singleContent?: boolean
}>(
  ({ theme, fixed, singleContent, isDocumentView }) => css`
    position: relative;
    display: flex;
    /* Adding 1px to make room for border-bottom */
    height: ${theme.header.height + 1}px;
    border-bottom: 1px solid ${theme.colors.border.B};
    width: 100%;
    align-items: center;
    justify-content: ${singleContent ? 'flex-end' : 'space-between'};
    background: ${theme.colors.background.secondary.A};

    ${fixed &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
    `};

    border: none;
    height: ${theme.header.height}px;
    background: ${isDocumentView
      ? theme.colors.background.secondary.A
      : theme.colors.background.secondary.B};
  `
)

export const NavbarDivider = styled(GradientSeparator)`
  display: none;
`

export const NavbarButton = styled(IconButton).attrs(({ size }) => ({
  size: size || 18,
}))`
  position: relative;
  height: 3rem;
  width: 3rem;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  :hover {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
  }

  /* Show the button as hovered when it's inside of a expanded dropdown */
  [aria-expanded='true'] & {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.4;
    `};

  ${props => {
    if (props.active) {
      return css`
        color: ${({ theme }) => theme.colors.sketch.A};

        &:hover,
        &:focus {
          color: ${({ theme }) => theme.colors.sketch.A};
        }

        &:active {
          color: ${({ theme }) => theme.colors.sketch.A};
        }
      `
    }
  }}
`
