import React, { FC } from 'react'

// Images
import logo from '@sketch/icons/logo-black-pdf.png'

import {
  MainContainer,
  Header,
  Logo,
  Container,
  Title,
  Text,
  RecoveryCodesContainer,
  CodeContainer,
  CheckMarkBox,
} from './RecoveryCodesPDFTemplate.styles'

interface RecoveryCodesPDFTemplateProps {
  recoveryCodes: string[]
}

/**
 * RecoveryCodesPDFTemplate
 *
 * Renders the recovery codes PDF template
 */
export const RecoveryCodesPDFTemplate: FC<RecoveryCodesPDFTemplateProps> = ({
  recoveryCodes,
}) => (
  <MainContainer>
    <Header>
      <Logo src={logo} alt="sketch-logo" />
    </Header>

    <Container>
      <Title>2FA Recovery Codes</Title>
      <Text>
        Use these recovery codes to sign in if you lose access to your
        authenticator app. You can only use each recovery code once. Store them
        in a safe place.
      </Text>
      <RecoveryCodesContainer>
        {recoveryCodes.map(code => (
          <CodeContainer key={code}>
            <CheckMarkBox />
            {code}
          </CodeContainer>
        ))}
      </RecoveryCodesContainer>
      <Text>
        Need more codes? Go to your Account at{' '}
        <a href="https://www.sketch.com/">Sketch.com</a>
      </Text>
    </Container>
  </MainContainer>
)
