import {
  WorkspaceMinimalFragment,
  useGetBillingForWorkspaceNoticeQuery,
} from '@sketch/gql-types'
import { useCallback } from 'react'
import {
  isEndedTrialSubscription,
  isOrWillBeCanceledSubscription,
  isExpiringIn30daysOrCanceled,
} from './workspaceStatusUtils'

export type WorkspaceNoticeId =
  | 'TRIAL'
  | 'PERSONAL_WORKSPACE'
  | 'SUBSCRIPTION_CANCELED'
  | 'EDUCATION_CANCEL'

/**
 * All notices id by order of priority, the first one should be the most relevant/important one that we
 * want to show as a priority.
 */
export const NOTICE_PRIORITIES: WorkspaceNoticeId[] = [
  'PERSONAL_WORKSPACE',
  'SUBSCRIPTION_CANCELED',
  'EDUCATION_CANCEL',
  'TRIAL',
]

/**
 * Assign each notice ID to a function checking if the notice should be considered
 * as active or not based on provided variables.
 * Provided variables are defined in useWorkspaceSidebarActiveNoticesFilter
 */
const noticeIdToIsActiveNoticeFnMap: Record<
  WorkspaceNoticeId,
  (visibilityVariables: WorkspaceActiveNoticesVariables) => boolean
> = {
  TRIAL: ({ isOnTrial, isSubscriptionSetup, isEndedTrial, userRole }) =>
    (isOnTrial || isEndedTrial) && !isSubscriptionSetup && userRole !== 'GUEST',
  SUBSCRIPTION_CANCELED: ({
    isSubscriptionCanceled,
    isSubscriptionSetup,
    isEndedTrial,
    userRole,
    isEduWorkspace,
  }) =>
    isSubscriptionCanceled &&
    // Exclude Edu (type `STUDENT`) workspaces
    !isEduWorkspace &&
    // Exclude isEndedTrial scenario as it's handled as the TRIAL component
    !isEndedTrial &&
    userRole !== 'GUEST' &&
    userRole !== 'PARTNER',
  PERSONAL_WORKSPACE: ({ isPersonalWorkspace, userRole }) =>
    isPersonalWorkspace && userRole !== 'GUEST',
  EDUCATION_CANCEL: ({ isEduWorkspace, userRole, isExpiringIn30Days }) =>
    isEduWorkspace && userRole === 'ADMIN' && isExpiringIn30Days,
}

/**
 * Check all notices and select the chosen one (the active one with the highest priority).
 * @param workspace
 * @returns
 */
export function useCurrentWorkspaceSidebarNoticeId(
  workspace: WorkspaceMinimalFragment
) {
  const isNoticeActiveFn = useWorkspaceSidebarActiveNoticesFilter(workspace)

  const activeNotices = NOTICE_PRIORITIES.filter(isNoticeActiveFn)

  // Only show one at a time, the one with the most priority
  return activeNotices[0] ?? null
}

/** Variables you can use to filter if a  */
type WorkspaceActiveNoticesVariables = {
  userRole: WorkspaceMinimalFragment['userRole']
  isOnTrial: boolean
  isEduWorkspace: boolean
  isEndedTrial: boolean
  isSubscriptionCanceled: boolean
  isSubscriptionSetup: boolean
  isPersonalWorkspace: boolean
  isExpiringIn30Days: boolean
}

/**
 * Returns a function we can use to filter all the notice ids to only keep the ones that are currently active/relevant.
 */
function useWorkspaceSidebarActiveNoticesFilter(
  workspace: WorkspaceMinimalFragment
) {
  const { data, loading, error } = useGetBillingForWorkspaceNoticeQuery({
    variables: { identifier: workspace.identifier },
  })

  return useCallback(
    (noticeId: WorkspaceNoticeId) => {
      const billing = data?.workspace?.customer?.billing
      if (loading || error) {
        // Hide all the workspace notices if loading or there is an error.
        // since we can't be sure of the state yet.
        return false
      }

      const isEndedTrial = billing ? isEndedTrialSubscription(billing) : false

      const visibilityVariables: WorkspaceActiveNoticesVariables = {
        userRole: workspace.userRole,
        isOnTrial: Boolean(billing?.status === 'TRIALING'),
        isSubscriptionSetup: Boolean(billing?.subscriptionSetUp),
        isEndedTrial: isEndedTrial,
        isEduWorkspace: workspace.type === 'STUDENT',
        isPersonalWorkspace: workspace.type === 'PERSONAL',
        isSubscriptionCanceled: Boolean(
          billing && isOrWillBeCanceledSubscription(billing)
        ),
        isExpiringIn30Days: Boolean(
          billing && isExpiringIn30daysOrCanceled(billing)
        ),
      }

      return isVisibleWorkspaceSidebarNotice(noticeId, visibilityVariables)
    },
    [error, loading, data, workspace]
  )
}

function isVisibleWorkspaceSidebarNotice(
  noticeId: WorkspaceNoticeId,
  visibilityVariables: WorkspaceActiveNoticesVariables
) {
  const noticeVisibilityFunction = noticeIdToIsActiveNoticeFnMap[noticeId]

  return noticeVisibilityFunction(visibilityVariables)
}
