import styled from 'styled-components'
import { breakpoint } from '@sketch/global-styles'

export const PanelSeparator = styled.div`
  margin-bottom: 16px;

  ${breakpoint('xs')`
    margin-bottom: 32px;
  `}
`
