import styled from 'styled-components'

import { Form } from '@sketch/components'

export const StyledFormField = styled(Form.Field)`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const StyledErrorField = styled(Form.ErrorField)`
  font-size: ${({ theme }) => theme.fontSizes.C};
  text-align: left;
`
