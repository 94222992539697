import { breakpoint } from '@sketch/global-styles'
import styled, { css } from 'styled-components'

import { PageLayout } from '../PageLayout'

/**
 * TYPES
 */
interface SidebarProps {
  isSidebarOpen?: boolean
}

interface ContentContainerProps {
  isSidebarOpen?: boolean
}

/**
 * COMPONENTS
 */
export const FullPageLayout = styled(PageLayout)`
  width: 100%;
  height: 100%;
  margin: 0;
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  /* Force the page to take the maximum of PageLayout */
  flex: 1;
`

// Left sidebar to show the main navigation:
// We show the sidebar for mobile (hidden by default), for desktop we always
// show the sidebar
export const Sidebar = styled.aside<SidebarProps>(
  ({ theme, isSidebarOpen }) => css`
    display: flex;

    position: absolute;
    top: 0;
    bottom: 0;
    z-index: ${theme.zIndex[5]}; /* bigger z-index than header */
    opacity: ${({ isSidebarOpen }: SidebarProps) => (isSidebarOpen ? 1 : 0)};
    width: ${theme.sidebar.leftWidth}px;

    /* For bigger than mobile we always show the sidebar */
    ${breakpoint('sm')`
      position: static;
      opacity: 1;
      transform: none;
      transition: none;
      width: ${theme.sidebar.leftWidth}px;
    `}

    background-color: ${theme.colors.background.secondary.A};

    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    transition-property: transform, opacity;

    will-change: transform; /* Force hardware acceleration */
    transform: ${isSidebarOpen ? 'translateX(0)' : 'translateX(-100%)'};
  `
)

export const Content = styled.div.attrs({
  'data-content-scrollable': true,
  tabIndex: -1,
})<{ isSidebarOpen: boolean }>(
  ({ theme, isSidebarOpen }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    z-index: ${theme.zIndex[2]};

    /* Background covering the whole content while the sidebar is opened */
    &::before {
      content: ${isSidebarOpen ? '""' : 'none'};
      position: absolute;
      inset: 0;
      z-index: ${theme.zIndex[8]};
      background-color: ${theme.colors.background.primary.C};
      transition: background-color 0.3s;
    }

    /* This div gets focus when the <PageLayout /> mounts because is the one
    with scroll, and focusing on this element gives the user the ability to use
    keyboard to scroll up/down, but we don't want any focus style */
    outline: none;
  `
)

export const ContentContainer = styled.div<ContentContainerProps>`
  position: relative;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
`
