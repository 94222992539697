import { useApolloClient } from 'react-apollo'

import { CredentialsFragment } from '@sketch/gql-types'
import { useFetchInitialUser } from './useFetchInitialUser'
import { useUserProfile } from './useUserProfile'
import {
  createPersonalAuthorization,
  getAllAuthorizations,
  replaceAllAuthorizations,
  setUserAuthorization,
} from './multisession'

export const useSetUserAuthorization = () => {
  const client = useApolloClient()

  const fetchInitialUser = useFetchInitialUser()
  const { data: currentUserData } = useUserProfile()

  return async (credentials: CredentialsFragment) => {
    const newAuthorization = createPersonalAuthorization(credentials)

    /* Save the user credentials in the apollo cache */
    setUserAuthorization(client.cache, newAuthorization)

    const allAuths = getAllAuthorizations()

    if (allAuths.length > 1) {
      const newUser = await fetchInitialUser()

      if (currentUserData?.me?.identifier !== newUser?.identifier) {
        replaceAllAuthorizations(newAuthorization)
      }
    }
  }
}
