import React from 'react'

import { Footer } from '../Footer'

import {
  Wrapper,
  Main,
  FooterWrapper,
  Content,
  HeaderContainer,
} from './IndexLayout.styles'

import { IndexLayoutHeader, IndexLayoutHeaderProps } from './IndexLayoutHeader'
import { useCreateLayoutPortal, useLayoutOverrideProps } from '@sketch/utils'

export interface LayoutPassDownProps
  extends Pick<
    IndexLayoutProps,
    'noHeaderBorder' | 'maxContainerWidth' | 'showFooter' | 'disableLogoLink'
  > {}

export interface IndexLayoutExtraProps {
  HeaderPortal: React.FunctionComponent<React.PropsWithChildren<{}>>
  useOverrideLayoutProps: (props: LayoutPassDownProps) => void
  OverrideLayoutProps: (props: LayoutPassDownProps) => JSX.Element
}

interface RenderPropChildren {
  render: (props: IndexLayoutExtraProps) => React.ReactElement
}

interface ComponentChildren {
  component: React.ComponentType<IndexLayoutExtraProps>
}

export type IndexLayoutProps = Pick<IndexLayoutHeaderProps, 'disableLogoLink'> &
  OneOf<RenderPropChildren, ComponentChildren> & {
    className?: string
    isCreatingWorkspace?: boolean
    fullWidth?: boolean
    maxContainerWidth?: string
    showFooter?: boolean
    noHeaderBorder?: boolean
  }

export const IndexLayout: React.FC<IndexLayoutProps> = props => {
  const [HeaderPortal, setPortalContainerRef] = useCreateLayoutPortal()
  const {
    getOverriddenProps,
    useOverrideLayoutProps,
    OverrideLayoutProps,
  } = useLayoutOverrideProps<LayoutPassDownProps>()

  const childrenProps = {
    HeaderPortal,
    useOverrideLayoutProps,
    OverrideLayoutProps,
  }

  const {
    className,
    disableLogoLink,
    isCreatingWorkspace,
    fullWidth,
    showFooter = true,
    render,
    maxContainerWidth,
    component: Component,
  }: typeof props = getOverriddenProps(props)

  return (
    <Wrapper className={className}>
      <IndexLayoutHeader disableLogoLink={disableLogoLink}>
        <HeaderContainer ref={ref => setPortalContainerRef(ref)} />
      </IndexLayoutHeader>
      <Content>
        <Main
          isCreatingWorkspace={isCreatingWorkspace}
          fullWidth={fullWidth}
          maxContainerWidth={maxContainerWidth}
        >
          {render && render(childrenProps)}
          {Component && <Component {...childrenProps} />}
        </Main>
        {showFooter && (
          <FooterWrapper>
            <Footer />
          </FooterWrapper>
        )}
      </Content>
    </Wrapper>
  )
}
