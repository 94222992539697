import React from 'react'

import { routes } from '@sketch/modules-common'
import {
  useForTablet,
  Tooltip,
  WorkspaceLogo,
  TableComponents,
  TimeAgo,
  TimeAgoProps,
} from '@sketch/components'

import { isPersonalWorkspace } from 'cloud-frontend'

import { UserWorkspaceMembershipFragment } from '@sketch/gql-types'

import {
  WorkspaceStatus,
  Sso,
  WorkspaceName,
  WorkspaceNameRow,
  WorkspaceNameWrapper,
  StyledWorkspaceAvatar,
  WorkspaceRole,
  StyledPill,
  WorkspaceNameLink,
  RightTableCell,
  LeaveButton,
  WorkspaceListItemNameWrapper,
  WorkspaceInfoWrapper,
  WorkspaceNameWithTruncate,
  SignedOut,
} from './WorkspaceListItem.styles'

const { TableCell, TableRow } = TableComponents

interface BaseWorkspaceListItemProps {
  workspace: UserWorkspaceMembershipFragment['workspace']
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const trialDateFormat: TimeAgoProps['formatter'] = (value, unit) =>
  `${value} ${unit}${value > 1 ? 's' : ''}`

const WorkspaceListItemLogo: React.FC<BaseWorkspaceListItemProps> = ({
  workspace,
}) => {
  const isExpired = workspace.status === 'INACTIVE'

  return isPersonalWorkspace(workspace) ? (
    <WorkspaceLogo.Icon size="32px" icon="person" />
  ) : (
    <StyledWorkspaceAvatar
      workspaceName={workspace.name}
      src={workspace.avatar?.large}
      size="32px"
      $isExpired={isExpired}
    />
  )
}

const WorkspaceListItemName = ({
  workspace,
  onClick,
}: BaseWorkspaceListItemProps) => {
  const isTabletAndBigger = useForTablet()

  const { billing } = workspace.customer || {}

  const isAdmin = workspace.userRole === 'ADMIN'
  const isGuest = workspace.userRole === 'GUEST'
  const isActive = workspace.status === 'ACTIVE'
  const isTrialing = billing?.status === 'TRIALING'
  const isExpired = workspace.status === 'INACTIVE'

  const showTrialText = !isGuest && isActive && isTrialing
  const linksToWorkspace = isActive || isAdmin

  return (
    <WorkspaceNameWrapper>
      <WorkspaceNameRow>
        {linksToWorkspace ? (
          <WorkspaceNameLink
            onClick={onClick}
            to={
              isGuest
                ? routes.WORKSPACE_SHARES.create({
                    workspaceId: workspace.identifier,
                  })
                : routes.WORKSPACE_SETTINGS.create({
                    workspaceId: workspace.identifier,
                  })
            }
          >
            <WorkspaceName $isExpired={isExpired}>
              <WorkspaceNameWithTruncate>
                {workspace.name}
              </WorkspaceNameWithTruncate>
            </WorkspaceName>
          </WorkspaceNameLink>
        ) : (
          <WorkspaceName $isExpired={isExpired}>
            <WorkspaceNameWithTruncate>
              {workspace.name}
            </WorkspaceNameWithTruncate>
          </WorkspaceName>
        )}
        {workspace.customer?.ssoEnabled && !isGuest && <Sso>SSO</Sso>}
      </WorkspaceNameRow>
      {isTabletAndBigger && (
        <WorkspaceNameRow>
          {showTrialText && billing!.trialEnd && (
            <WorkspaceStatus>
              <TimeAgo date={billing!.trialEnd} formatter={trialDateFormat} />{' '}
              left in trial
            </WorkspaceStatus>
          )}
          {isExpired && <WorkspaceStatus>Expired</WorkspaceStatus>}
        </WorkspaceNameRow>
      )}
    </WorkspaceNameWrapper>
  )
}

const WorkspaceListItemRole = ({ workspace }: BaseWorkspaceListItemProps) => {
  const isAdmin = workspace.userRole === 'ADMIN'
  const isOwner = workspace.userIsOwner
  const isGuest = workspace.userRole === 'GUEST'
  const isEditor = workspace.userCanEdit
  const isPartner = workspace.userRole === 'PARTNER'

  const textRole = isGuest
    ? 'As a Guest'
    : isPartner
    ? 'As a Partner'
    : isEditor
    ? 'As an Editor'
    : 'As a Viewer'

  return (
    <WorkspaceRole>
      {textRole}
      {isAdmin && !isOwner && <StyledPill variant="admin">Admin</StyledPill>}
      {isOwner && <StyledPill variant="owner">Owner</StyledPill>}
    </WorkspaceRole>
  )
}

interface WorkspaceListItemProps {
  onLeaveWorkspace: (membership: UserWorkspaceMembershipFragment) => void
  membership: UserWorkspaceMembershipFragment
  hasAccessToWorkspace: boolean
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}

export const WorkspaceListItem = ({
  membership,
  onLeaveWorkspace,
  hasAccessToWorkspace,
  onClick,
}: WorkspaceListItemProps) => {
  const isTabletAndBigger = useForTablet()
  const { workspace } = membership

  const workspaceInfo = isTabletAndBigger ? (
    <WorkspaceListItemName workspace={workspace} onClick={onClick} />
  ) : (
    <WorkspaceInfoWrapper>
      <WorkspaceListItemName workspace={workspace} onClick={onClick} />
      <WorkspaceListItemRole workspace={workspace} />
    </WorkspaceInfoWrapper>
  )

  const getTooltipText = (userIsOwner: boolean, isPartner: boolean) => {
    if (isPartner)
      return 'You cannot leave a Workspace that you manage as a Sketch Partner'
    if (userIsOwner) return 'You cannot leave your own Workspace'
  }

  const rightCellContent = !hasAccessToWorkspace ? (
    <SignedOut>Signed out</SignedOut>
  ) : (
    <Tooltip
      content={getTooltipText(
        workspace.userIsOwner,
        workspace.userRole === 'PARTNER'
      )}
      disabled={workspace.userRole !== 'PARTNER' && !workspace.userIsOwner}
    >
      <LeaveButton
        disabled={
          isPersonalWorkspace(workspace) ||
          workspace.userIsOwner ||
          workspace.userRole === 'PARTNER'
        }
        onClick={() => onLeaveWorkspace(membership)}
      >
        Leave&hellip;
      </LeaveButton>
    </Tooltip>
  )

  return (
    <TableRow>
      <TableCell>
        <WorkspaceListItemNameWrapper>
          <WorkspaceListItemLogo workspace={workspace} />
          {workspaceInfo}
        </WorkspaceListItemNameWrapper>
      </TableCell>
      {/* Workspace role is displayed as part of the first cell on mobile devices. */}
      {isTabletAndBigger && (
        <TableCell>
          <WorkspaceListItemRole workspace={workspace} />
        </TableCell>
      )}
      <RightTableCell>{rightCellContent}</RightTableCell>
    </TableRow>
  )
}
