import styled from 'styled-components'
import { Text } from '@sketch/components'

export const Paragraph = styled(Text).attrs({
  textStyle: 'copy.tertiary.standard.D',
})`
  margin: 0;

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }
`

export const Section = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};

  :last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`
