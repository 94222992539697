import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "book-open-48", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 48 48", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M24 12a6.737 6.737 0 015.606-3H41a2 2 0 012 2v24a2 2 0 01-2 2H29.606A6.737 6.737 0 0024 40h0V12z", stroke: "currentColor", strokeWidth: 2, strokeLinejoin: "round" }),
        React.createElement("path", { d: "M24 12a6.737 6.737 0 00-5.606-3H7a2 2 0 00-2 2v24a2 2 0 002 2h11.394A6.737 6.737 0 0124 40h0V12z", stroke: "currentColor", strokeWidth: 2, fill: "#C18CCB", strokeLinejoin: "round" }),
        React.createElement("rect", { fill: "currentColor", x: 29, y: 15, width: 9, height: 2, rx: 1 }),
        React.createElement("rect", { fill: "currentColor", x: 29, y: 19, width: 9, height: 2, rx: 1 }),
        React.createElement("rect", { fill: "currentColor", x: 29, y: 23, width: 6, height: 2, rx: 1 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
