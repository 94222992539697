import React from 'react'

import { Button, useModalContext } from '@sketch/components'
import { LaunchSketchAlert } from '../LaunchSketchAlert'

interface OpenMacAppButtonProps {
  buttonStyle?: React.ComponentProps<typeof Button>['buttonStyle']
  label?: string
}

const OpenMacAppButton = (props: OpenMacAppButtonProps) => {
  const { label, buttonStyle = 'primary-32' } = props
  const { showModal } = useModalContext()

  return (
    <Button
      buttonStyle={buttonStyle}
      onClick={() =>
        showModal(LaunchSketchAlert, {
          shareId: '',
          action: 'openInSketch',
        })
      }
    >
      {label || 'Open the Mac App'}
    </Button>
  )
}

export default OpenMacAppButton
