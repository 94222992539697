import styled from 'styled-components'
import { Banner } from '@sketch/components'
import { ReactComponent as ChevronDown } from '@sketch/icons/chevron-vertical-16'

export const Paragraph = styled.p`
  margin: 0;
`

export const PartnerInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 12px;
  background: ${({ theme }) => theme.colors.background.secondary.B};
  border-radius: ${({ theme }) => theme.radii.large};
  margin-top: 24px;
`

export const SketchPartnerLabel = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.D};
  margin-right: 8px;
`

export const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;

  .avatar {
    margin-left: 0;
    margin-right: 16px;
  }
`

export const ExpiredWorkspaceBanner = styled(Banner)`
  margin-top: 24px;
`

export const Chevron = styled(ChevronDown)`
  flex: none;
  height: 16px;
  cursor: pointer;
  margin-left: auto;
  padding-left: 8px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.background.secondary.B};
`
