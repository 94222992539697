import React from 'react'
import { ApolloError } from 'apollo-client'

import {
  BannerSection,
  Button,
  useModalContext,
  ErrorMessage,
} from '@sketch/components'

import { HideProfileSection } from './HideProfileSection'
import { PublishProfileModal } from '../../modals'

export interface WorkspaceSettingsProfileProps {
  identifier: string
  error?: ApolloError
  isPublic?: boolean
}

export const PublishProfileSection = (props: WorkspaceSettingsProfileProps) => {
  const { showModal } = useModalContext()
  const { identifier, error, isPublic } = props

  if (error) {
    return <ErrorMessage.Generic />
  }

  if (isPublic) return <HideProfileSection identifier={identifier} />

  return (
    <BannerSection
      type="neutral"
      button={
        <Button
          size="32"
          variant="primary-untinted"
          onClick={() => showModal(PublishProfileModal, { identifier })}
        >
          Publish Profile…
        </Button>
      }
    >
      Your public profile is hidden — only you and other Admins can see it.
    </BannerSection>
  )
}
