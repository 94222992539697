import styled, { css } from 'styled-components'

export const CanvasStatusWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-self: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  z-index: 1;
`

/**
 * Small wrapper to be able to blur the canvas when canvas is already
 * rendered but we are waiting for something else
 * e.g.: waiting for inspector data when switching to inspector tab
 * (We don't want to make the canvas disappear and re-appear when switching tabs,
 * so we just blur a bit the content and display a loading message on top).
 */
export const CanvasRenderReactWrapper = styled.div<{
  $isWaitingForMoreData: boolean
}>`
  /* Important for canvas to have a size and to not crash */
  height: 100%;
  width: 100%;

  ${({ $isWaitingForMoreData }) =>
    $isWaitingForMoreData &&
    css`
      filter: blur(4px);
      opacity: 0.3;
    `}
`
