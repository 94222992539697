import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "square-grid-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd", stroke: "currentColor", strokeWidth: 1.5 },
        React.createElement("rect", { x: 4.75, y: 4.75, width: 5.5, height: 5.5, rx: 1.5 }),
        React.createElement("rect", { x: 13.75, y: 4.75, width: 5.5, height: 5.5, rx: 1.5 }),
        React.createElement("rect", { x: 4.75, y: 13.75, width: 5.5, height: 5.5, rx: 1.5 }),
        React.createElement("rect", { x: 13.75, y: 13.75, width: 5.5, height: 5.5, rx: 1.5 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
