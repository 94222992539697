import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import * as Collapsible from '@radix-ui/react-collapsible'

import { Truncate } from '@sketch/components'
import { Link, CollapsibleIcon } from './GroupsList.styles'
import { ParentGroup } from './MobileGroupsList.styles'

import { useGetIdentifiers } from '../../../hooks/useGetIdentifiers'
import { getGroupRoute, prepareGroupURL } from '../../utils'

import type { ComponentsTree } from '../../types'
import type { GroupItemProps, GroupsListProps } from './GroupsList'

/**
 * TYPES
 */
interface MobileGroupItemProps extends GroupItemProps {
  handleParentClick: () => void
  groupType: string
  isFirst?: boolean
  tree: ComponentsTree[]
  componentsTree: ComponentsTree[]
}

interface MobileGroupsListProps
  extends OmitSafe<GroupsListProps, 'componentsState'> {
  tree: ComponentsTree[]
  groupType: string
  name: string
  path: string
  handleParentClick: (
    leaf: GroupsListProps['componentsTree'][0],
    path: string
  ) => void
}

/**
 * COMPONENTS
 */
const MobileGroupItem: React.FC<MobileGroupItemProps> = ({
  name,
  path,
  children,
  level,
  selected,
  handleParentClick,
  groupType,
  isFirst,
  componentsTree,
}) => {
  const { versionShortId, shareIdentifier } = useGetIdentifiers()
  const routeMatch = useRouteMatch()

  const groupsArray = path.split('/')
  const routeKey = getGroupRoute(routeMatch.path)

  const getURLSearchQuery = (groups: string[]) => {
    if (groups.length === 1 && (groups[0] === 'View All' || groups[0] === '')) {
      return window.location.pathname
    }

    return prepareGroupURL(groups)
  }

  const getHeadText = () => {
    if (!name) {
      return 'View All'
    }

    if (level === 0 || !isFirst || name === 'View All') {
      return name
    }

    return `View all "${name}" ${groupType}`
  }

  return (
    <Collapsible.Root asChild>
      <li>
        {!children?.length ? (
          <Link
            to={{
              routeKey,
              routeParams: {
                shareID: shareIdentifier,
                versionShortId,
              },
              search: getURLSearchQuery(groupsArray),
            }}
            level={0}
            selected={path === selected}
          >
            <Truncate>{getHeadText()}</Truncate>
          </Link>
        ) : (
          <ParentGroup onClick={handleParentClick} selected={selected === path}>
            <Truncate>{name}</Truncate>
            <Collapsible.Trigger asChild>
              <CollapsibleIcon selected={selected === path} />
            </Collapsible.Trigger>
            <Collapsible.Content>
              <MobileGroupsList
                componentsTree={componentsTree}
                level={level + 1}
                name={name}
                path={path}
                tree={children ?? []}
                selected={selected}
                groupType={groupType}
                handleParentClick={handleParentClick}
              />
            </Collapsible.Content>
          </ParentGroup>
        )}
      </li>
    </Collapsible.Root>
  )
}

// Mobile version of SidebarGroupsList, uses transitions instead of collapsible
// sections like we do on desktop
export const MobileGroupsList: React.FC<MobileGroupsListProps> = ({
  componentsTree,
  tree,
  name,
  path,
  level = 0,
  selected,
  groupType,
  handleParentClick,
}) => {
  return (
    <ul>
      <MobileGroupItem
        tree={tree}
        componentsTree={componentsTree}
        name={name}
        path={path}
        isFirst
        selected={selected}
        level={level}
        groupType={groupType}
        handleParentClick={() => handleParentClick(componentsTree[0], path)}
      />
      {componentsTree.map(leaf => (
        <MobileGroupItem
          tree={tree}
          componentsTree={componentsTree}
          key={leaf.name}
          name={leaf.name}
          path={leaf.path}
          selected={selected}
          level={level}
          handleParentClick={() => handleParentClick(leaf, path)}
          groupType={groupType}
        >
          {leaf.children}
        </MobileGroupItem>
      ))}
    </ul>
  )
}
