import { Form as FormBase } from './Form'
import { FormFooter } from './FormFooter'
import { FormField, StripeFormField } from './FormField'
import { FormLabel } from './FormLabel'
import { FormError } from './FormError'
import { FormErrorField } from './FormErrorField'

export * from './FormField'
export * from './FormError'
export { FormLabel } from './FormLabel'

export const Form = Object.assign(FormBase, {
  Footer: FormFooter,
  Field: FormField,
  StripeField: StripeFormField,
  Label: FormLabel,
  Error: FormError,
  ErrorField: FormErrorField,
})
