import React from 'react'

import { useVersioning } from '../../ShareVersionContext'
import { ShareVersionsItem } from '../ShareVersions/ShareVersionsItem'

import { useGetLatestStarQuery } from '@sketch/gql-types'

import { Title, Wrapper } from './LatestStar.styles'

export const LatestStar = ({ latestStar }: { latestStar?: any }) => {
  const { share, versionShortId, latestVersion } = useVersioning()
  const shareIdentifier = share?.identifier

  const { data, error } = useGetLatestStarQuery({
    variables: { shareIdentifier },
    skip: !shareIdentifier || latestStar,
  })

  const latestStarToShow = latestStar ?? data?.share?.version

  if (
    !shareIdentifier ||
    !latestStarToShow ||
    error ||
    latestStarToShow.kind !== 'PUBLISHED' ||
    !versionShortId ||
    !share
  ) {
    return null
  }

  return (
    <Wrapper>
      <Title textStyle="copy.tertiary.standard.C">Latest starred version</Title>
      <ShareVersionsItem
        key={latestStar.identifier}
        version={latestStar}
        share={share}
        isLatestVersion={latestStar.identifier === latestVersion.identifier}
        isLatestStared
        isSingleVersion
        hideDescription
        isVersionSelected={latestStar.shortId === versionShortId}
      />
    </Wrapper>
  )
}
