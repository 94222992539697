import produce from 'immer'
import { DataProxy } from 'apollo-cache'

import { broadcastChanges } from './apolloCache'

type ScopeValidator = (key: string, value: any) => boolean

const removeQuery = (client: DataProxy, scopeValidator: ScopeValidator) => {
  // DataProxy doesn't expose the extract and restore methods that's why we force the type
  const untypedClient = client as any

  // delete all entry references from cache using the scopeValidator
  const newCache = produce(
    { ...untypedClient.extract() },
    (cacheData: Record<string, any>) => {
      const cacheKeys = Object.keys(cacheData)

      cacheKeys.forEach(key => {
        if (scopeValidator(key, cacheData[key])) {
          delete cacheData[key]
        }
      })
    }
  )

  untypedClient.restore(newCache)

  broadcastChanges(untypedClient)
}

export default removeQuery
