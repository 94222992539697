import styled from 'styled-components'

import { ReactComponent as LicenseDiamondIcon } from '@sketch/icons/license-diamond-48'
import { LandscapeBannerCard } from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'

export const StyledLicenseDiamondIcon = styled(LicenseDiamondIcon)`
  width: 48px;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`

export const StyledLandscapeBannerCard = styled(LandscapeBannerCard)`
  margin-bottom: 24px;

  ${breakpoint('sm')`
    margin-bottom: 64px;
  `};
`
