import styled, { css } from 'styled-components'
import transparencyMask from '@sketch/icons/transparency-mask.png'
import { Text } from '@sketch/components'
import {
  ItemContainerBackgroundColor,
  ItemSecondary,
  SelectedBorderStyle,
} from '../../components/Grid'
import { breakpoint } from '@sketch/global-styles'

export const ColorContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 4px;
  border-radius: 6px;
  aspect-ratio: 3/2;
  ${ItemContainerBackgroundColor};
  ${SelectedBorderStyle};
`

export const SwatchContainer = styled.div`
  width: 64px;
  height: 64px;
  position: relative;
  box-sizing: border-box;
  margin: 24px auto;

  ${breakpoint('lg')`
    width: 80px;
    height: 80px;
  `};

  ${breakpoint('xxl')`
    width: 88px;
    height: 88px;
  `};
`

const swatchStyle = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const ColorSwatchBackground = styled.div`
  ${swatchStyle};
  border-radius: ${({ theme }) => theme.radii.rounded};
  background: url(${transparencyMask});
  background-size: 30%;
`

export const ColorSwatch = styled.div<{
  backgroundColor: string
}>(
  ({ backgroundColor }) => css`
    ${swatchStyle};
    border-radius: ${({ theme }) => theme.radii.rounded};
    background-color: ${backgroundColor};
    border: 1px solid ${({ theme }) => theme.colors.border.A};
  `
)

export const ColorDetail = styled(ItemSecondary)`
  text-transform: uppercase;
  text-align: left;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

export const ColorDetailSearch = styled.span(
  ({ theme }) => css`
    text-transform: uppercase;
    font-size: ${theme.fontSizes.B};
    color: ${theme.colors.foreground.secondary.D};
    display: block;
    text-align: left;
  `
)

export const ColorDetailName = styled(Text.Span).attrs({
  textStyle: 'copy.primary.standard.D',
})`
  display: block;
  text-align: left;
  max-width: 100%;
`
