import { ErrorHandler } from '@sketch/tracing'

import {
  WorkspaceMembershipUpdatedSubscription,
  useWorkspaceMembershipUpdatedSubscription,
} from '@sketch/gql-types'

interface MembershipUpdated {
  status: WorkspaceMembershipUpdatedSubscription['workspaceMembershipUpdated']['status']
  workspaceIdentifier: string
}

interface EventListeners {
  onMembershipUpdated?: (resource: MembershipUpdated) => void
}

const useWorkspaceSubscriptions = (eventListeners?: EventListeners) => {
  const { onMembershipUpdated } = eventListeners || {}

  useWorkspaceMembershipUpdatedSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      const payload = subscriptionData?.data?.workspaceMembershipUpdated

      // Report if the subscription doesn't have payload
      if (!payload) {
        const jsonData = JSON.stringify(subscriptionData)
        ErrorHandler.shouldNeverHappen(
          `missing useWorkspaceMembershipUpdated payload, ${jsonData}`
        )

        return
      }

      onMembershipUpdated?.(payload)
    },
  })
}

export default useWorkspaceSubscriptions
