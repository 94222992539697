import React, { FC, useEffect } from 'react'
import { Location } from 'history'
import { StaticContext } from 'react-router'
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom'

import { IS_EMBEDDED } from '@sketch/constants'
import {
  routes,
  GenericErrorView,
  NotFoundView,
  DynamicLoadingPage,
} from '@sketch/modules-common'
import { ErrorBoundary, useBanner } from '@sketch/components'
import RequiresMembershipView from '../../../views/RequiresMembershipView'

import {
  ShareAuthorizerQueryError,
  useShareAuthorizerQueryWithAllAuths,
} from './useShareAuthorizerQuery'
import ShareMainRoutes from './ShareMainRoutes'
import { isShortId } from '@sketch/utils'
import { ShareDeletedView } from '../ShareDeletedView'
import { WrongAuthorizationView } from '../components/WrongAuthorizationView'
import { ShareViewsNavigationContextProvider } from '../utils/shareViewsNavigation'
import RequiresSignInEmbeddedView from '../../../views/RequiresSignInEmbeddedView'
import { useVersionSubscriptions } from '../../versioning/operations'
import { ShareInfoFragment } from '@sketch/gql-types'

interface LocationState extends Location {
  componentsView: boolean
}
type ShareRoutesProps = RouteComponentProps<
  OmitSafe<Parameters<typeof routes.SHARE_VIEW.create>[0], 'query'>,
  StaticContext,
  LocationState
>

const notFoundAdditionalMessage = (
  <>
    <div>
      It might have been deleted or moved, or you could have the wrong URL for
      it.
    </div>
    <br />
  </>
)

const deprecatedBannerText = (
  <>
    Since&nbsp;<strong>29 April 2020</strong>, we changed the format of document
    URLs making them more secure.&nbsp;&nbsp;
    <a
      href="https://sketch.com/cloud-url-update"
      rel="noopener noreferrer"
      target="_blank"
    >
      Find out more
    </a>
  </>
)

let deprecatedURLFormatBannerShow = false

interface ShareVersionSubscriptionProps {
  share: ShareInfoFragment
}

const ShareVersionSubscription = ({ share }: ShareVersionSubscriptionProps) => {
  useVersionSubscriptions(share)

  return null
}

interface ErrorViewProps {
  shareIdentifier: string
  error: ShareAuthorizerQueryError
}

const ErrorView = ({ shareIdentifier, error }: ErrorViewProps) => {
  switch (error.subtype) {
    case 'forbidden': {
      return IS_EMBEDDED ? (
        <RequiresSignInEmbeddedView />
      ) : (
        <RequiresMembershipView
          shareIdentifier={shareIdentifier}
          canSSOUserRequestAccess={error.canSSOUserRequestAccess}
        />
      )
    }
    case 'wrong-identity':
      return (
        <WrongAuthorizationView
          workspaceIdentifier={error.workspaceIdentifier}
          email={error.email}
        />
      )
    case 'not-found':
      return (
        <NotFoundView
          title="We cannot find this document."
          additionalMessage={notFoundAdditionalMessage}
        />
      )
    default:
      return <GenericErrorView />
  }
}

const ShareRoutes: FC<ShareRoutesProps> = ({ match, location }) => {
  const shareID = match?.params.shareID

  const result = useShareAuthorizerQueryWithAllAuths({ shortId: shareID })

  const showDeprecateBanner =
    /* Checks if the request as fulfilled */
    result.type === 'data' &&
    result.share.identifier !== shareID &&
    /**
     * Since this banner can only be summoned when
     * the user load a share from a shortId and is redirected to a
     * share with UUID. We double check, to make sure he is in the correct path.
     */
    isShortId(shareID) &&
    !isShortId(result.share.identifier)

  const { showBanner } = useBanner()

  useEffect(() => {
    if (showDeprecateBanner) {
      if (!deprecatedURLFormatBannerShow) {
        const onDismiss = () => {
          deprecatedURLFormatBannerShow = false
        }

        showBanner({
          message: deprecatedBannerText,
          type: 'warning',
          dismissible: true,
          onDismiss,
        })

        deprecatedURLFormatBannerShow = true
      }
    }
  }, [showBanner, showDeprecateBanner])

  if (result.type === 'loading') {
    return <DynamicLoadingPage />
  }

  if (result.type === 'error') {
    return <ErrorView shareIdentifier={shareID} error={result} />
  }

  if (result.type === 'deleted') {
    return <ShareDeletedView share={result.share} />
  }

  const { share, refetch } = result

  if (share.userHasUnacceptedInvitationToView) {
    return (
      <Redirect
        to={routes.SHARE_INVITE.create({ shareID: share.identifier })}
      />
    )
  }

  // For libraries we want to show the components view by default (in some circumstances)
  if (share.type === 'LIBRARY' && location.state?.componentsView) {
    return (
      <Redirect to={routes.CWV_SYMBOLS.create({ shareID: share.identifier })} />
    )
  }

  /**
   * Make the URL match the shortId supplied in the request.
   * Basically correct the old shortId to the newer one
   */
  if (shareID !== share.identifier) {
    return (
      <Redirect
        to={{
          pathname: location.pathname.replace(shareID, share.identifier),
        }}
      />
    )
  }

  return (
    <ErrorBoundary fallbackComponent={GenericErrorView}>
      <ShareVersionSubscription share={share} />
      <ShareViewsNavigationContextProvider>
        <ShareMainRoutes
          share={share}
          refetch={refetch}
          key={shareID}
          location={location}
        />
      </ShareViewsNavigationContextProvider>
    </ErrorBoundary>
  )
}

export default withRouter(ShareRoutes)
