import React from 'react'

import { Route, RouteProps as ReactRouteProps } from 'react-router-dom'
import { ColorVariables, LayerStyles, TextStyles, Symbols } from './pages'
import { getAllTemplatesForVersionedRoute } from '@sketch/modules-common'
import { ComponentsStateProvider } from '../components/ComponentsStateContext'
import { DocumentHead } from '@sketch/components'

interface CwvRoutesProps extends ReactRouteProps {
  userIsEditor: boolean
  shareName: string
}

export const CwvRoutes: React.FC<CwvRoutesProps> = ({
  shareName,
  userIsEditor,
}) => (
  <ComponentsStateProvider>
    <Route exact path={getAllTemplatesForVersionedRoute('CWV_SYMBOLS')}>
      <DocumentHead title={`${shareName} Symbols`} />
      <Symbols userIsEditor={userIsEditor} />
    </Route>
    <Route exact path={getAllTemplatesForVersionedRoute('CWV_TEXT_STYLES')}>
      <DocumentHead title={`${shareName} Text Styles`} />
      <TextStyles userIsEditor={userIsEditor} />
    </Route>
    <Route exact path={getAllTemplatesForVersionedRoute('CWV_LAYER_STYLES')}>
      <DocumentHead title={`${shareName} Layer Styles`} />
      <LayerStyles userIsEditor={userIsEditor} />
    </Route>
    <Route exact path={getAllTemplatesForVersionedRoute('CWV_COLOR_VARIABLES')}>
      <DocumentHead title={`${shareName} Color Variables`} />
      <ColorVariables userIsEditor={userIsEditor} />
    </Route>
  </ComponentsStateProvider>
)
