import { useEffect } from 'react'
import { useCombobox } from 'downshift'

import { useGetInvitableUsersForPermissionGroupLazyQuery } from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'
import { useDebounceValue } from '@sketch/utils'

interface MembersAutoCompleteProps {
  workspaceIdentifier: string
  permissionGroupIdentifier?: string
  excludedMemberIdentifiers: string[]
}

export const useMembersAutoComplete = (props: MembersAutoCompleteProps) => {
  const {
    excludedMemberIdentifiers,
    workspaceIdentifier,
    permissionGroupIdentifier,
  } = props

  const { showToast } = useToast()
  const [query, { data }] = useGetInvitableUsersForPermissionGroupLazyQuery({
    fetchPolicy: 'network-only',
    onError: () => {
      showToast('Failed to autocomplete name.', 'negative')
    },
  })

  const items =
    data?.invitableUsersForPermissionGroup.entries.filter(
      ({ identifier }) => !excludedMemberIdentifiers.includes(identifier)
    ) || []
  const combobox = useCombobox({
    items,
    itemToString: item => item?.user?.name || '',
  })

  const { inputValue, selectedItem, reset } = combobox
  const debouncedValue = useDebounceValue(inputValue, 300)
  const isValueSelected = selectedItem?.user?.name === inputValue

  useEffect(() => {
    if (inputValue === '') {
      reset()
    }
  }, [inputValue, reset])

  useEffect(() => {
    if (debouncedValue === '') {
      return
    }

    const search = debouncedValue.replace(/^@/, '')
    query({
      variables: {
        workspaceIdentifier,
        permissionGroupIdentifier,
        search,
      },
    })
  }, [workspaceIdentifier, debouncedValue, query, permissionGroupIdentifier])

  return {
    items,
    combobox,
    valueSelected: selectedItem?.user?.name || '',
    selectedItem,
    isValueSelected,
  }
}
