import styled from 'styled-components'
import { PersonAvatar, SuggestionsInput } from '@sketch/components'

import CommentAuthor from '../CommentAuthor'
import BaseCommentInput from '../CommentInput'

export const Wrapper = styled.div`
  border-radius: ${({ theme }) => theme.radii.large};
  box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.border.B};
  transition: 100ms box-shadow linear;

  :focus-within {
    /* Native focus ring */
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
`

export const CommentInput = styled(BaseCommentInput)`
  border: none;
  padding-top: 0px;
  padding-left: 4px;

  ${SuggestionsInput} {
    margin-top: 0;
    margin-right: 0;
    max-height: 300px;
    overflow-y: auto;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;

  vertical-align: middle;
  padding: 12px 12px 4px;

  font-size: 0.75rem /* 12px */;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  ${PersonAvatar} {
    /* Move the avatar up a bit for visual centering purposes */
    transform: translate(2px, -1px);
  }

  ${PersonAvatar}, ${CommentAuthor} {
    margin-right: 8px;
  }
`
