import React from 'react'

import { WebsocketContext } from '@sketch/modules-common'
import { AuthenticatedSocket } from '@sketch/common-types'
import { createAuthenticatedSocket } from '../websockets/create'
import { SocketChannels } from '../websockets/channels/SocketChannels'
import { AuthenticatedSocket as AuthenticatedSocketClass } from '../websockets/socket/AuthenticatedSocket'

interface WebsocketProviderProps {
  children: React.ReactNode
}

export const WebsocketProvider = ({ children }: WebsocketProviderProps) => {
  const createSocketChannels = (socket: AuthenticatedSocket) =>
    // This method returns an AuthenticatedSocket (the class), but it's type is
    // incompatible with the AuthenticatedSocket interface due the usage of private methods.
    // The usage of "AuthenticatedSocketClass" is to prevent a TS error due to
    // those private methods missing on the AuthenticatedSocket interface.
    // This will not affect anything on consumer components, since we're talking
    // here about private methods.
    new SocketChannels(socket as AuthenticatedSocketClass)

  return (
    <WebsocketContext.Provider
      value={{ createAuthenticatedSocket, createSocketChannels }}
    >
      {children}
    </WebsocketContext.Provider>
  )
}
