import styled, { css } from 'styled-components'
import { ReactComponent as ClockwiseArrowCircle } from '@sketch/icons/clockwise-arrow-circle-16'
import { Button } from '@sketch/components'

export const StyledClockwiseArrowCircle = styled(ClockwiseArrowCircle)`
  width: 16px;
  height: 16px;
`

export const StyledConsolidateButton = styled(Button)(
  ({ loading }) => css`
    padding: ${loading ? '6px 16px' : '6px 8px'};
  `
)
