import React from 'react'
import { useLocation } from 'react-router-dom'
import { Location } from 'history'

import {
  routes,
  RedirectBadge,
  IndexLayoutExtraProps,
  SignUpForm,
  IndexLayoutHeaderLink,
} from '@sketch/modules-common'
import { Link, ExternalLink } from '@sketch/components'

import { isMac } from '@sketch/utils'
import { useSignUpFormContextForAnalytics } from './useSignUpFormContextForAnalytics'

import {
  Line,
  OperatingSystemWarning,
  Container,
  TextSignUp,
  RequiredOSVersion,
} from './SignUpView.styles'
import { DataWithoutUserErrors, SignUpMutation } from '@sketch/gql-types'

export interface BaseSignUpViewProps
  extends Pick<
    IndexLayoutExtraProps,
    'useOverrideLayoutProps' | 'HeaderPortal'
  > {
  email: string | undefined
  invitedUser: boolean
  onCompleted: (data: DataWithoutUserErrors<SignUpMutation>) => void
}

const BaseSignUpView: React.FC<BaseSignUpViewProps> = ({
  email,
  invitedUser,
  onCompleted,
  useOverrideLayoutProps,
  HeaderPortal,
}) => {
  const { state } = useLocation<{ from: Location }>()
  const showOSWarning = !isMac()

  useOverrideLayoutProps({ maxContainerWidth: '960px' })

  const formContextForAnalytics = useSignUpFormContextForAnalytics()

  return (
    <>
      <HeaderPortal>
        <IndexLayoutHeaderLink />
      </HeaderPortal>
      {state?.from && <RedirectBadge {...state} />}
      <Container>
        <RequiredOSVersion>
          To run Sketch you’ll need a macOS device that meets our{' '}
          <ExternalLink href="http://sketch.com/redirect/web/system-requirements">
            system requirements
          </ExternalLink>
          .
        </RequiredOSVersion>
        <SignUpForm
          email={email}
          invitedUser={invitedUser}
          onCompleted={onCompleted}
          formContextForAnalytics={formContextForAnalytics}
        />
        {showOSWarning && (
          <OperatingSystemWarning data-testid="os-warning">
            To run Sketch you’ll need a macOS device that our{' '}
            <ExternalLink href="http://sketch.com/redirect/web/system-requirements">
              system requirements
            </ExternalLink>
            .
          </OperatingSystemWarning>
        )}
        <Line />
        <TextSignUp>
          Already have a Sketch account?{' '}
          <Link
            variant="primary"
            to={{
              pathname: routes.SIGN_IN.create({}),
              state,
            }}
            isUnderlined
          >
            Sign in
          </Link>
        </TextSignUp>
      </Container>
    </>
  )
}

export default BaseSignUpView
