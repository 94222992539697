import styled from 'styled-components'

import { Navbar } from '@sketch/components'

export const NavigationContainer = styled(Navbar.Section)`
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 4px 0 0;
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`
