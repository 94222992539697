import styled from 'styled-components'
import { AsyncButton, Navbar, Skeleton, UnstyledList } from '@sketch/components'
import { ReactComponent as CheckIcon } from '@sketch/icons/checkmark-16'

export const Wrapper = styled.div`
  flex: 1;
  max-width: 660px;
`
export const WrapperList = styled.div`
  flex: 1;
  max-width: 100%;

  ${UnstyledList} {
    li:nth-child(odd) {
      background-color: ${({ theme }) => theme.colors.background.secondary.A};
    }
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

export const MarkAllAsReadButton = styled(AsyncButton).attrs({ size: '32' })`
  margin-left: auto;
  margin-right: 8px;
`

export const StyledMarkAsReadIcon = styled(CheckIcon)`
  width: 16px;
  height: 16px;
`

export const StyledSkeleton = styled(Skeleton)`
  margin-left: auto;
  margin-right: 8px;
`

export const SearchWrapper = styled.div`
  width: 328px;
  flex: 1 1 auto;
`

export const EmptyStateWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`

export const NavbarSection = styled(Navbar.Section)`
  /* Prevent the buttons shadow from being clipped by the overflows set on the parent elements */
  padding-bottom: 2px; /* stylelint-disable-line scales/space */
`
