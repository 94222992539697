import React from 'react'
import copy from 'copy-to-clipboard'
import { getItem } from '@sketch/utils'

import {
  ConfirmationDialog,
  Dropdown,
  EllipsisDropdownAction,
  useModalContext,
  ModalInjectedProps,
} from '@sketch/components'
import { useToast } from '@sketch/toasts'
import { localStorageKeys } from '@sketch/constants'

import { EditDesignSystemModal } from '../EditDesignSystemModal'
import {
  useDeleteDesignSystemMutation,
  GetWorkspaceDesignSystemsDocument,
  GetWorkspaceDesignSystemsQueryVariables,
} from '@sketch/gql-types'
import { DesignSystem } from '../../types'

interface DeleteDSProps extends ModalInjectedProps {
  designSystem: DesignSystem
  callback: () => void
  workspaceId: string
}

const DeleteDSModal: React.FC<DeleteDSProps> = props => {
  const { hideModal, isModalOpen, designSystem, callback, workspaceId } = props
  const { showToast } = useToast()

  const [deleteDesignSystem, { loading }] = useDeleteDesignSystemMutation({
    onCompleted: () => {
      callback()
      hideModal()
      showToast(`"${designSystem.name}" deleted`)
    },
    onError: ({ message }) => {
      hideModal()
      showToast(message, 'negative')
    },
    refetchQueries: [
      {
        query: GetWorkspaceDesignSystemsDocument,
        variables: {
          identifier: workspaceId,
          limit: 3,
        } as GetWorkspaceDesignSystemsQueryVariables,
      },
    ],
  })

  const deleteDs = () => {
    deleteDesignSystem({
      variables: { identifier: designSystem.identifier },
    })
  }

  return (
    <ConfirmationDialog
      title="Delete this Design System?"
      hideModal={hideModal}
      isModalOpen={isModalOpen}
      onConfirm={deleteDs}
      confirmButton={{
        text: 'Delete',
        loading,
        disabled: loading,
        variant: 'negative-secondary',
      }}
    >
      Deleting ”<strong>{designSystem.name}</strong>” will only remove links to
      Libraries that were added to the design system. No Libraries will be
      deleted. You can’t undo this action.
    </ConfirmationDialog>
  )
}

interface DesignSystemDropdownProps {
  designSystem: DesignSystem
  url: string
  deleteCallback: () => void
  userIsGuest: boolean
}

export const DesignSystemDropdown = ({
  designSystem,
  url,
  deleteCallback,
  userIsGuest,
}: DesignSystemDropdownProps) => {
  const { showModal } = useModalContext()
  const { showToast } = useToast()
  const workspaceId = getItem(localStorageKeys.lastWorkspaceIdKey) ?? ''

  const onCopyURL = (e: React.SyntheticEvent) => {
    e.preventDefault()

    copy(`${window.location.origin}${url}`)
    showToast(`Link copied`)
  }

  return (
    <Dropdown
      placement="bottom-end"
      usePortal
      toggle={<EllipsisDropdownAction label="Design System Settings" />}
    >
      <Dropdown.Item onClick={onCopyURL}>Copy Link</Dropdown.Item>
      {!userIsGuest && (
        <>
          <Dropdown.Item
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault()
              showModal(EditDesignSystemModal, {
                designSystemToEdit: designSystem,
                workspaceId,
              })
            }}
          >
            Edit&hellip;
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            intent="negative"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault()
              showModal(DeleteDSModal, {
                workspaceId,
                designSystem,
                callback: deleteCallback,
              })
            }}
          >
            Delete&hellip;
          </Dropdown.Item>
        </>
      )}
    </Dropdown>
  )
}
