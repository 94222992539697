import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "image-fill", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 17 17", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("path", { d: "M8.5 16a7.5 7.5 0 100-15 7.5 7.5 0 000 15z", id: "path-1" })),
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: 1, fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "image.circle" },
            React.createElement("mask", { id: "mask-2", fill: "#fff" },
                React.createElement("use", { xlinkHref: "#path-1" })),
            React.createElement("path", { stroke: "#000", d: "M8.5 15.5a7 7 0 100-14 7 7 0 000 14z" }),
            React.createElement("path", { d: "M.5 12l3.797-3.797a1.002 1.002 0 011.406 0L9.5 12l1.79-1.79a1.008 1.008 0 011.418-.002L15.5 13v3.15H.5V12z", id: "Path-2", stroke: "#000", fillOpacity: 0.35, fill: "#000", strokeLinecap: "round", strokeLinejoin: "round", mask: "url(#mask-2)" }),
            React.createElement("circle", { id: "Oval", stroke: "#000", mask: "url(#mask-2)", cx: 10, cy: 6, r: 1.5 }))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
