import React from 'react'

import { LoadingState } from '@sketch/components'

import { IndexLayoutHeader } from './IndexLayoutHeader'
import { Wrapper } from './IndexLayout.styles'

export const IndexLayoutLoading: React.FC = () => (
  <Wrapper>
    <IndexLayoutHeader />
    <LoadingState />
  </Wrapper>
)
