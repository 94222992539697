import React from 'react'
import {
  TextAlignImg as Icon,
  TextAlignContainer as Container,
} from './TextAlignValue'

import { ReactComponent as Underline } from '@sketch/icons/textformat-underline-16'
import { ReactComponent as Strikethrough } from '@sketch/icons/textformat-strikethrough-16'
import { TextDecoration as TextDecorationEnum } from '../../../../../../inspector'

export const TextDecoration = ({
  decoration,
  label,
}: {
  decoration: TextDecorationEnum
  label: string
}) => {
  const renderImage = () => {
    switch (decoration) {
      case TextDecorationEnum.Underline:
        return <Icon as={Underline} />
      case TextDecorationEnum.LineThrough:
        return <Icon as={Strikethrough} />
      default:
        return null
    }
  }
  return (
    <Container>
      {renderImage()}
      <span aria-label={`${label} ${decoration}`}>{decoration}</span>
    </Container>
  )
}
