import React from 'react'

import { Dropdown } from '@sketch/components'

import { useMultipleCommentContext } from '../CommentEditContext'
import CopyAnnotationLink from '../CopyAnnotationLink'
import DeleteCommentOption from '../DeleteCommentOption'

import { useGetBaseCommentQuery } from '@sketch/gql-types'

interface CommentOptionDropdownProps {
  showCopyLink?: boolean
  annotationIdentifier: string
  commentIdentifier: string
  isFirstComment: boolean
  hasReplies: boolean
  isAnnotationResolved?: boolean
}

const CommentOptionDropdown: React.FC<CommentOptionDropdownProps> = props => {
  const {
    showCopyLink,
    annotationIdentifier,
    commentIdentifier,
    isFirstComment,
    hasReplies,
    isAnnotationResolved,
  } = props

  const { requestEditingComment } = useMultipleCommentContext()

  /**
   *  We use the client "useGetBaseCommentQuery" with the "cache-only" fetch
   *  policy to make "listen" to comment in cache.
   *
   *  We are using a apollo redirect to get this information from cache
   */
  const { data: commentData } = useGetBaseCommentQuery({
    variables: { identifier: commentIdentifier },
    fetchPolicy: 'cache-only',
  })

  const { comment } = commentData || {}

  return (
    <>
      {showCopyLink && (
        <CopyAnnotationLink annotationIdentifier={annotationIdentifier} />
      )}

      {showCopyLink && comment?.userIsCreator && <Dropdown.Divider />}

      {comment?.userIsCreator && (
        <Dropdown.Item
          onClick={() => {
            requestEditingComment(commentIdentifier)
          }}
        >
          Edit…
        </Dropdown.Item>
      )}

      {!isAnnotationResolved && comment?.userCanDelete && (
        <DeleteCommentOption
          annotationIdentifier={annotationIdentifier}
          commentIdentifier={commentIdentifier}
          isAnnotation={isFirstComment}
          hasReplies={hasReplies}
        />
      )}
    </>
  )
}

export default CommentOptionDropdown
