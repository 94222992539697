import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import {
  StepperWrapper,
  StepperButton,
  StyledPlus,
  StyledMinus,
  StepperInput,
} from './Stepper.styles'

const formatValueToPixels = (value: number) => `${value}`.length * 10

interface StepperProps {
  className?: string
  value: number
  onChange: (newNumber: number) => void
  max?: number
  min?: number
  isDisabled?: boolean
}

/**
 * Stepper
 *
 * Renders a number input that allows the user
 * to click a "+" and "-" button to increase or
 * decrease the value
 *
 * Allow for a maximum and minimum based range of values
 */
const StepperUnstyled: React.FC<StepperProps> = ({
  className,
  value,
  onChange,
  max = Infinity,
  min = -Infinity,
  isDisabled,
}) => {
  const [cachedValue, setCachedValue] = useState(value)

  useEffect(() => {
    setCachedValue(() => value)
  }, [value])

  const setValue = (valueFunc: (v: number) => number) => {
    setCachedValue(value => {
      const newValue = Math.min(Math.max(valueFunc(value) || 0, min), max)

      onChange(newValue)

      return newValue
    })
  }

  return (
    <StepperWrapper className={className}>
      <StepperButton
        disabled={isDisabled || cachedValue === min}
        onClick={() => setValue(value => value - 1)}
      >
        <StyledMinus />
        <span className="sr-only">Decrement</span>
      </StepperButton>
      <label>
        <span className="sr-only">Seats stepper</span>
        <StepperInput
          name="extraSeats"
          type="number"
          min={min}
          max={max}
          data-testid="stepper-input"
          /**
           *  Force the value to be string, so we can have a clear representation
           *  "01" -> 1 (input value)
           *  01 -> 01 (input value)
           */
          value={`${cachedValue}`}
          onChange={({ target }) => {
            // Make sure there are no left side zeros
            setValue(() => parseInt(target.value.replace(/^0+/, '')))
          }}
          style={{ width: formatValueToPixels(cachedValue) }}
          disabled={isDisabled}
        />
      </label>
      <StepperButton
        disabled={isDisabled || cachedValue === max}
        onClick={() => setValue(value => value + 1)}
      >
        <StyledPlus />
        <span className="sr-only">Increment</span>
      </StepperButton>
    </StepperWrapper>
  )
}

export const Stepper = styled(StepperUnstyled)``
