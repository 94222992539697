import styled, { css } from 'styled-components'

import { EditorContent } from '@tiptap/react'

export const EditorContentStyled = styled(EditorContent)(
  ({ theme }) => css`
    flex: auto;

    border: 1px solid transparent;
    border-radius: 6px;

    color: ${theme.colors.foreground.secondary.C};

    & *:focus {
      outline: none;
    }

    /* Editor elements */
    h1 {
      color: ${theme.colors.foreground.secondary.A};
      font-size: ${theme.fontSizes.H};
      margin: 16px 0 16px;
    }

    h2 {
      color: ${theme.colors.foreground.secondary.A};
      font-size: ${theme.fontSizes.G};
      margin: 24px 0 16px;
    }

    h3 {
      color: ${theme.colors.foreground.secondary.A};
      font-size: ${theme.fontSizes.F};
      margin: 16px 0;
      text-transform: uppercase;
    }

    li p {
      margin: 0;
    }

    a {
      color: ${theme.colors.foreground.secondary.A};
      font-weight: ${theme.fontWeights.semibold};
      text-decoration: underline;
    }

    .ProseMirror p.is-editor-empty:first-child::before {
      color: ${theme.colors.state.disabled};
      content: attr(data-placeholder);
      pointer-events: none;
      /* needed in order for the placeholder not occupy actual space of the input */
      position: absolute;
    }
  `
)
