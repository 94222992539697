import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "lock-64", viewBox: "0 0 64 64", xmlns: "http://www.w3.org/2000/svg", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("g", { stroke: "currentColor", strokeWidth: 3 },
            React.createElement("path", { d: "M19.91 29.5h24.18c2.23 0 3.037.232 3.852.668a4.543 4.543 0 011.89 1.89c.436.815.668 1.623.668 3.852v16.18c0 2.23-.232 3.037-.668 3.852a4.543 4.543 0 01-1.89 1.89c-.815.436-1.623.668-3.852.668H19.91c-2.23 0-3.037-.232-3.852-.668a4.543 4.543 0 01-1.89-1.89c-.436-.815-.668-1.623-.668-3.852V35.91c0-2.23.232-3.037.668-3.852a4.543 4.543 0 011.89-1.89c.815-.436 1.623-.668 3.852-.668z", fillOpacity: 0.2, fill: "currentColor" }),
            React.createElement("path", { d: "M32 5.5c7.456 0 13.5 6.044 13.5 13.5v10.5h0-27V19c0-7.456 6.044-13.5 13.5-13.5z" })),
        React.createElement("circle", { fill: "currentColor", cx: 32, cy: 42, r: 3 }),
        React.createElement("rect", { fill: "currentColor", x: 30.5, y: 42, width: 3, height: 8, rx: 1.5 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
