import React, { FC } from 'react'
import { ErrorMessage, PageLayout } from '@sketch/components'
import { EmptyHeader } from '../EmptyHeader'
import styled from 'styled-components'
import { ReactComponent as WarningTriangle } from '@sketch/icons/warning-triangle-64'

const StyledErrorMessage = styled(ErrorMessage)`
  flex: 1 0 auto;
`

type EmbeddedErrorProps = {
  for: 'document' | 'artboard' | 'unknown'
}

const EmbeddedError = (props: EmbeddedErrorProps) => {
  const title = {
    document: "We couldn't find the document.",
    artboard: "We couldn't find the artboard.",
    unknown: 'There was an error loading this content.',
  }[props.for]

  const message =
    props.for === 'unknown' ? null : 'It may have been moved or deleted.'

  return (
    <StyledErrorMessage
      icon={<WarningTriangle />}
      iconSize="medium"
      title={title}
    >
      {message}
    </StyledErrorMessage>
  )
}

/**
 * A simple error view for embeds that fail to load.
 */
const EmbeddedErrorPage: FC<EmbeddedErrorProps> = props => {
  return (
    <PageLayout header={<EmptyHeader />}>
      <EmbeddedError {...props} />
    </PageLayout>
  )
}

export { EmbeddedErrorPage, EmbeddedError }
