import styled from 'styled-components'

type NavbarLinkProps = {
  disabled?: boolean
}

export const NavbarLinkWrapper = styled.div<NavbarLinkProps>`
  position: relative;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  opacity: ${props => (props.disabled ? '0.4' : 1)};
  line-height: 1;

  :hover {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }
`
