import React from 'react'
import styled, { css } from 'styled-components'
import { ColorProps } from '../../../../../../../Inspector/Sidebar/components/Color/ColorDot'
import { ColorDotStyle } from '../../../../../../../Inspector/Sidebar/components/Color/ColorDot/ColorDot.styles'

interface ConnectedColorDotProps extends ColorProps {
  connected: boolean
}

const ColorIconUnstyled = ({
  red,
  green,
  blue,
  alpha,
  connected,
  ...props
}: ConnectedColorDotProps) => (
  <div
    role="img"
    aria-label={`rgba(${red}, ${green}, ${blue}, ${alpha})`}
    {...props}
  >
    {connected && <span />}
  </div>
)

export const ConnectedColorDot = styled(
  ColorIconUnstyled
)<ConnectedColorDotProps>(
  ({ theme }) => css`
    ${ColorDotStyle}

    & > span {
      content: '';
      display: block;
      position: absolute;
      top: 16px;
      left: 0;
      right: 0;
      margin: auto;
      width: 1px;
      height: 45px;
      background-color: ${theme.colors.border.A};
    }
  `
)

export default ConnectedColorDot
