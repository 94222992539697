import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "paint-brush-64", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 64 64", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M41.376 43.725l-4.512 4.513a3.837 3.837 0 01-5.426 0l-3.424-3.424a1.918 1.918 0 00-2.713 0l-.081.087-8.007 10.288a5.987 5.987 0 11-8.402-8.402l10.196-7.93a1.92 1.92 0 00.337-2.693l-.158-.178-3.424-3.424a3.837 3.837 0 010-5.426l4.513-4.512 21.101 21.101z", fillOpacity: 0.15, fill: "currentColor" }),
        React.createElement("path", { d: "M40.7 7.624l15.676 15.675a3.837 3.837 0 010 5.426L36.864 48.238a3.837 3.837 0 01-5.426 0l-3.424-3.424a1.918 1.918 0 00-2.713 0l-.081.087-8.007 10.288a5.987 5.987 0 11-8.402-8.402l10.196-7.93a1.92 1.92 0 00.337-2.693l-.158-.178-3.424-3.424a3.837 3.837 0 010-5.426L35.275 7.624a3.837 3.837 0 015.426 0z", stroke: "currentColor", strokeWidth: 3, strokeLinejoin: "round" }),
        React.createElement("circle", { fill: "currentColor", cx: 12.5, cy: 51.5, r: 2 }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 3, d: "M20 22l22 22" }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 3, strokeLinecap: "round", d: "M45 12L35 22m17-3l-6 6" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
