import { useThemeContext } from '@sketch/global-styles'
import React, { FC } from 'react'
import styled, { keyframes, css } from 'styled-components'

import { ReactComponent as BaseSketchLogo } from '@sketch/icons/sketch-logo-24'

const ANIMATION_TIME = 1000

const LOADING_ANIMATION_BEFORE = keyframes`
  0% {
      transform: translate3d(0, 0, 0);
  }

  50% {
      transform: translate3d(-100%, 0, 0);
  }

  100% {
      transform: translate3d(0, 0, 0);
  }
`

const LOADING_ANIMATION = keyframes`
  0% {
     transform: translate3d(-50%, 0, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-50%, 0, 0);
  }
`

const COLOR_ICON_ANIMATION = keyframes`
    0% { color: #FE6C00; }
    7.14% { color: #FE6C00; } 
    14.29% { color: #FFC018; }
    21.43% { color: #FFC018; } 
    28.57% { color: #F53631; }
    35.71% { color: #F53631; } 
    42.86% { color: #00CE6C; }
    50% { color: #00CE6C; }
    57.14% { color: #1A7FF3; }
    64.29% { color: #1A7FF3; }
    71.43% { color: #B261FA; }
    78.57% { color: #B261FA; }
    85.71% { color: #FF3A71; }
    92.86% { color: #FF3A71; }
    100% { color: #FE6C00; }
`

type Size = '16px' | '24px' | '48px' | '64px'

export interface BaseLoadingPlaceholderProps {
  size?: Size
  primary?: boolean
  /* dark prop is used to force a light spinner (for prototypes for example) */
  dark?: boolean
  className?: string
}

type LoadingPlaceholderType = 'color-icon' | 'bar'

export interface LoadingPlaceholderProps extends BaseLoadingPlaceholderProps {
  type?: LoadingPlaceholderType
}

const BAR_HEIGHT = {
  '16px': '4px',
  '24px': '6px',
  '48px': '7px',
  '64px': '8px',
} as const

const INNER_BAR_WIDTH = {
  '16px': '8px',
  '24px': '10px',
  '48px': '15px',
  '64px': '20px',
}

interface LoadingAnimationWrapperProps {
  className?: string
  'data-testid'?: string
  $mountDelay?: number
  $primary?: boolean
  $dark?: boolean
  $size: Size
}

const BarWrapper = styled.span`
  width: 100%;
  border-radius: 999px;
  overflow: hidden;

  /* This is needed to fix a safari bug with border-radius */
  /* https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b */
  /* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  /* stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix */
`

const Bar = styled.span<{ $size: Size }>`
  width: 100%;
  display: block;
  height: 1em;
  position: relative;

  padding-right: 100%;
  padding-left: 100%;
  transform: translate3d(-50%, 0, 0);
  animation: ${LOADING_ANIMATION} ${ANIMATION_TIME}ms linear infinite;

  ::before {
    width: 100%;
    position: absolute;

    left: 0;
    top: 0;

    content: '';
    display: inline-block;
    height: 1em;
    opacity: 0.15;

    background: currentColor;
  }

  ::after {
    width: ${({ $size }) => INNER_BAR_WIDTH[$size]};
    border-radius: 999px;

    content: '';
    display: inline-block;
    height: 1em;
    background: currentColor;

    animation: ${LOADING_ANIMATION_BEFORE} ${ANIMATION_TIME}ms linear infinite;
  }
`

const LoadingAnimationComponent = styled.span<LoadingAnimationWrapperProps>(
  ({ theme: { colors }, $primary, $dark, $mountDelay, $size }) => css`
    display: inline-flex;
    width: ${$size};
    font-size: ${BAR_HEIGHT[$size]};
    aspect-ratio: 1;

    line-height: 1;

    align-items: center;

    overflow: hidden;

    color: ${$dark
      ? 'white'
      : $primary
      ? colors.foreground.primary.A
      : colors.foreground.secondary.D};

    ${Bar} {
      animation-delay: ${$mountDelay}ms;

      :after {
        animation-delay: ${$mountDelay}ms;
      }
    }
  `
)

const Logo = styled(BaseSketchLogo)<LoadingAnimationWrapperProps>(
  ({ $size }) => css`
    display: inline-flex;
    width: ${$size};
    font-size: ${BAR_HEIGHT[$size]};
    aspect-ratio: 1;
    animation: ${COLOR_ICON_ANIMATION} 7s ease-in infinite;
  `
)

const BarLoadingPlaceholder: FC<BaseLoadingPlaceholderProps> = ({
  className,
  size = '24px',
  primary = false,
  dark = false,
  ...props
}) => {
  /**
   * We capture when this component was mounted. This way we can apply a delay
   * to the animation based on that time and make all Loading animations in the app to be
   * in sync.
   *
   * Imagine animation lasts 100ms:
   *
   * 0                 100ms     150ms    200ms
   * | ----------------- | ----------------- |
   *                               |
   *                             mount
   *
   * We configure the animation to start on the previous cycle: 100ms, so we
   * set the animation-delay to -50ms. Finally, we use negative numbers to make
   * the component animate right away (instead of waiting for the next cycle).
   */
  const mountTime = React.useRef(Date.now())
  const { isDarkMode } = useThemeContext()
  const mountDelay = -(mountTime.current % ANIMATION_TIME)

  return (
    <LoadingAnimationComponent
      className={className}
      data-testid="loading-animation"
      $primary={primary}
      $dark={dark || isDarkMode}
      $mountDelay={mountDelay}
      $size={size}
      {...props}
    >
      <BarWrapper>
        <Bar $size={size} />
      </BarWrapper>
      <span className="sr-only">Loading Placeholder icon</span>
    </LoadingAnimationComponent>
  )
}

const IconLoadingPlaceholder = (props: BaseLoadingPlaceholderProps) => {
  const { size = '24px', className } = props
  return <Logo $size={size} className={className} />
}

export const LoadingPlaceholder = (props: LoadingPlaceholderProps) => {
  const { type = 'bar', ...otherProps } = props

  if (type === 'bar') {
    return <BarLoadingPlaceholder {...otherProps} />
  }

  return <IconLoadingPlaceholder {...otherProps} />
}
