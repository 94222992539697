import React, { useMemo, useRef } from 'react'
import * as Yup from 'yup'
import { Form, FormikProvider, useFormik } from 'formik'

import {
  Button,
  FlatButton,
  FormField,
  Input,
  Modal,
  ModalInjectedProps,
  TableComponents,
  Text,
} from '@sketch/components'

import { useToast } from '@sketch/toasts'

import {
  GetWorkspacePermissionGroupsDocument,
  WorkspaceMembershipFragment,
  useCreatePermissionGroupMutation,
} from '@sketch/gql-types'

import EmptyState from '../../../shares/components/EmptyState/EmptyState'

import { useMembersAutoComplete } from '../../hooks'
import { MemberCard } from '../../components/MemberCard'
import PermissionMemberInputWarning from '../../components/PermissionMemberInputWarning'

import {
  AddToListButton,
  InputWrapper,
  MembersTable,
  MinimalTableCell,
  InvitePeopleField,
  MemberCardCell,
} from './AddPermissionGroupModal.styles'

interface AddPermissionGroupModalProps extends ModalInjectedProps {
  workspaceIdentifier: string
}

const INITIAL_FORM_VALUES = {
  name: '',
  members: [] as WorkspaceMembershipFragment[],
}

// Form Validation
export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required(`Give your group a name. Don't worry, you can change it later.`)
    .max(255, 'Enter up to 255 characters')
    .trim(),
  members: Yup.array(),
})

const AddPermissionGroupModal = (props: AddPermissionGroupModalProps) => {
  const { workspaceIdentifier, hideModal } = props
  const { showToast } = useToast()

  const [mutation, { loading }] = useCreatePermissionGroupMutation({
    onError: 'show-toast',
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GetWorkspacePermissionGroupsDocument,
        variables: { workspaceIdentifier, search: '' },
      },
    ],
    onCompleted: ({ createPermissionGroup }) => {
      const { permissionGroup } = createPermissionGroup

      showToast(
        <>
          “<b>{permissionGroup.name}</b>” group created
        </>,
        'positive'
      )
      hideModal()
    },
  })

  const formikBag = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      const { name, members: memberList } = validationSchema.cast(
        values
      ) as typeof INITIAL_FORM_VALUES

      const members = memberList.map(({ identifier }) => identifier)

      mutation({
        variables: {
          input: {
            name,
            members,
            workspaceIdentifier,
          },
        },
      })
    },
  })

  const containerRef = useRef<HTMLInputElement>(null)
  const memberIdentifiers = useMemo(
    () => formikBag.values.members.map(({ identifier }) => identifier),
    [formikBag.values.members]
  )

  const autoComplete = useMembersAutoComplete({
    workspaceIdentifier: props.workspaceIdentifier,
    excludedMemberIdentifiers: memberIdentifiers,
  })

  const handleUserAdd = () => {
    setFieldValue('members', [...values.members, autoComplete.selectedItem])
    autoComplete.combobox.reset()
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setFieldValue,
    touched,
    errors,
    isValid,
  } = formikBag

  let content = null
  if (values.members.length === 0) {
    content = (
      <EmptyState
        title="Empty group"
        icon="group-circle"
        description="Type in Members’ names or emails to add them."
      />
    )
  } else {
    content = (
      <MembersTable
        header={[]}
        items={values.members}
        renderItem={({ user, identifier }) => (
          <TableComponents.TableRow data-testid="permission-group-member">
            <MemberCardCell>
              <MemberCard
                name={user!.name}
                avatarSrc={user!.avatar!.large}
                email={user!.email}
              />
            </MemberCardCell>
            <MinimalTableCell>
              <FlatButton
                variant="negative"
                onClick={() =>
                  setFieldValue(
                    'members',
                    values.members.filter(
                      member => member.identifier !== identifier
                    )
                  )
                }
              >
                Remove
              </FlatButton>
            </MinimalTableCell>
          </TableComponents.TableRow>
        )}
      />
    )
  }

  return (
    <Modal onCancel={props.hideModal}>
      <Modal.Header>Create a Group</Modal.Header>

      <FormikProvider value={formikBag}>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Text>
              Use groups to instantly give multiple Members access to specific
              projects and documents.
            </Text>

            <FormField
              name="name"
              errorText={touched.name ? errors.name : undefined}
            >
              <Input
                data-1p-ignore
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Give your group a name…"
                inputMode="text"
                type="text"
              />
            </FormField>

            <InputWrapper
              onKeyDown={event => {
                // The onsubmit event can be triggered by pressing "enter" key
                // when adding a selected user on the dropdown.
                //
                // so we should check that before submitting the form first
                if (event.key === 'Enter' && autoComplete.selectedItem) {
                  handleUserAdd()
                  event.preventDefault()
                }
              }}
            >
              <InvitePeopleField
                data1pIgnore
                placeholder="Type a Member’s name or email..."
                containerRef={containerRef}
                autocomplete={{ ...autoComplete, formik: formikBag }}
                dynamicAction={() => <PermissionMemberInputWarning />}
              />
              <AddToListButton
                variant="primary"
                disabled={!autoComplete.isValueSelected}
                onClick={handleUserAdd}
              >
                Add
              </AddToListButton>
            </InputWrapper>

            {content}
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={props.hideModal}>Cancel</Button>
            <Button
              variant="primary"
              type="submit"
              loading={loading}
              disabled={!isValid}
            >
              Create Group
            </Button>
          </Modal.Footer>
        </Form>
      </FormikProvider>
    </Modal>
  )
}

export default AddPermissionGroupModal
