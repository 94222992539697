import styled from 'styled-components'

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.G};
  margin: 32px 0 8px 0;
`

export const Description = styled.p`
  margin-bottom: 16px;
`

export const RecoveryCodes = styled.ul`
  text-align: center;
  margin: 0 0 24px 0;
  padding: 24px;
  border: 1px dashed ${({ theme }) => theme.colors.border.A};
  border-radius: ${({ theme }) => theme.radii.xlarge};
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  list-style: none;
  columns: 2;
`

export const RecoveryCode = styled.li`
  display: block;
  font-family: ${({ theme }) => theme.fonts.monospace};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.F};

  :not(:last-child, :nth-child(5)) {
    padding-bottom: 24px;
  }
`

export const Buttons = styled.div`
  display: flex;
  margin-bottom: 16px;
`

export const FormWrapper = styled.div`
  margin-bottom: 32px;
`
