import { FC } from 'react'
import styled from 'styled-components'

import {
  SelectDropdownButtonItem,
  SelectDropdownItemProps,
} from '@sketch/components'

export const DropdownItem = styled.li`
  list-style: none;
`

export const StyledSelectDropdownButtonItem: FC<SelectDropdownItemProps> = styled(
  SelectDropdownButtonItem
)`
  height: 48px;
`
