import styled from 'styled-components'

// components
import { Box, Flex, Heading, RadioButton } from '@sketch/components'

const Separator = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  margin: 24px 0;
`

const DocumentInfo = styled(Flex)`
  flex-wrap: wrap;
`

const DocumentInfoTitle = styled(Heading.H3)`
  margin: 16px 0 0;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`

const DocumentInfoItemWrapper = styled(Box)`
  width: 50%;
`
const DocumentInfoItemTitle = styled(Heading.H5)`
  margin-top: 24px;
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSizes.C};
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

const StyledRadioButton = styled(RadioButton)`
  margin-bottom: 16px;
`

export {
  DocumentInfo,
  DocumentInfoTitle,
  DocumentInfoItemWrapper,
  DocumentInfoItemTitle,
  Separator,
  StyledRadioButton,
}
