import React, { FC, ReactElement } from 'react'
import styled, { css } from 'styled-components'

import { withBox } from '../../Box'
import { Input } from '../../Input'

import { FormLabel } from '../FormLabel'
import { FormError } from '../FormError'

const HelpTextContainer = styled.div`
  margin-top: 6px; /* stylelint-disable-line scales/space */
  text-align: left;
`

const HelpText = styled.small`
  display: block;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  font-size: ${({ theme }) => theme.fontSizes.C};
`

const ErrorList = styled.ul`
  padding: 0;
  margin: 0;
  margin-top: 4px;
  text-align: left;

  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
`

export interface StripeFormFieldProps {
  className?: string
  id?: string
  name?: string
  label?: string
  errorText?: string | string[]
  children?: ReactElement
  help?: string
  htmlFor: string
}

// Helper functions
const renderErrorTextArray = (errorTextArray: string[]) => (
  <ErrorList>
    {errorTextArray.map((errorText, i) => (
      <li key={i}>
        <FormError>{errorText}</FormError>
      </li>
    ))}
  </ErrorList>
)

const renderErrorText = (errorText: string) =>
  errorText ? renderErrorTextArray([errorText]) : null

// Unstyled StripeFormField
const StripeFormFieldBase: FC<StripeFormFieldProps> = ({
  label,
  errorText,
  help,
  htmlFor,
  children,
  ...props
}) => {
  return (
    <div {...props}>
      {label && <FormLabel htmlFor={htmlFor}>{label}</FormLabel>}
      {children}
      {errorText &&
        (Array.isArray(errorText)
          ? renderErrorTextArray(errorText)
          : renderErrorText(errorText))}
      {help && (
        <HelpTextContainer>
          <HelpText>{help}</HelpText>
        </HelpTextContainer>
      )}
    </div>
  )
}

export const StripeFormField = styled(withBox(StripeFormFieldBase))`
  position: relative;
  margin-bottom: 1rem; /* stylelint-disable-line scales/space */

  /*
  Force the input border to be red if there is an external error.

  E.g. stripe.createSource errors don't affect the inputs, or set
  any flag in them this way we can maintain consistency in the border.
  */
  ${Input} {
    ${({ errorText, theme }) =>
      errorText &&
      css`
        border-color: ${theme.colors.state.negative.D};
      `};
  }
  &:last-child {
    margin-bottom: 0;
  }
`
