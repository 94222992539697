import { useEffect, RefObject } from 'react'

type Options = {
  /**
   * React refs of elements to also consider as inside the click. Use this to
   * exclude additional elements from triggering the outside click callback.
   */
  includeRefs?: Array<RefObject<HTMLElement>>
  /**
   * Query selector strings to also consider as inside the click. Use this to
   * exclude additional elements from triggering the outside click callback.
   */
  includeSelectors?: string[]
  /**
   * Allow the hook to only attach the event if a certain condition is matched.
   * If disabled, it won't attach the listener.
   */
  disabled?: boolean
}

export const MODAL_SELECTOR = '[data-testid=modal-root]'
export const DROPDOWN_SELECTOR = '[data-testid=dropdown-options]'
export const OPTION_ITEM = '[data-option-item]'
export const SELECT_POPOVER_SELECTOR = '[data-reach-popover]'

export const useOnClickOutside = (
  /**
   * A ref to the main target element. Any clicks inside this element won't
   * trigger the callback.
   */
  ref: RefObject<HTMLElement>,
  /**
   * Callback invoked everytime there's a click outside the main target.
   */
  handleClickOutside: (event: MouseEvent) => void,
  { includeRefs = [], includeSelectors = [], disabled = false }: Options = {}
) => {
  useEffect(() => {
    // If disabled, don't attach any listener
    if (disabled) return

    const handleClick = (e: MouseEvent) => {
      const target = e.target as Element | null

      if ([ref, ...includeRefs].some(ref => ref.current?.contains(target))) {
        return
      }

      // Check to see if the click target is nested within one of the
      // includeSelectors. The `closest()` method is used here to ensure the
      // most appropriate instance of the selector match is chosen, in case
      // there are multiple in the DOM
      if (
        includeSelectors
          .map(selector => !!target?.closest?.(selector))
          .includes(true)
      ) {
        return
      }

      handleClickOutside(e)
    }

    document.body.addEventListener('click', handleClick, { capture: true })

    return () => {
      document?.body?.removeEventListener('click', handleClick, {
        capture: true,
      })
    }
  }, [includeRefs, handleClickOutside, includeSelectors, ref, disabled])
}
