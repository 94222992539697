import { ElementTimingEntry, ElementTimingHandler } from './types'

interface ObserveOptions {
  buffer?: boolean
}

export class CustomElementsObserver {
  private readonly entries: ElementTimingEntry[] = []
  private readonly listeners: ElementTimingHandler[] = []

  observe = (callback: ElementTimingHandler, options: ObserveOptions = {}) => {
    const { buffer = true }: ObserveOptions = options

    this.listeners.push(callback)
    if (buffer) {
      this.entries.forEach(x => callback(x))
    }

    return () => {
      this.listeners.splice(this.listeners.indexOf(callback), 1)
    }
  }

  pushEntry = (entry: OmitSafe<ElementTimingEntry, 'startTime'>) => {
    const fullEntry = { ...entry, startTime: performance.now() }
    this.entries.push(fullEntry)

    this.listeners.forEach(callback => callback(fullEntry))
  }
}

export const customElementsObserver = new CustomElementsObserver()
