import React, { useState, useCallback, ReactNode } from 'react'
import { usePopper, Modifier } from 'react-popper'
import { Placement, Instance } from '@popperjs/core'
import { Portal } from 'react-portal'

import { clearNaN } from './utils'

const withPortal = (usePortal: boolean, content: ReactNode) =>
  usePortal ? <Portal>{content}</Portal> : content

export interface PopperContentProps {
  ref: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  style: React.CSSProperties
  'data-placement': Placement
  arrowRef: React.Ref<any>
  arrowStyle: React.CSSProperties
  rect: ClientRect | DOMRect | null
  forceUpdate: Instance['forceUpdate'] | null
}

export interface PopperChildrenProps {
  ref: React.Ref<any>
  rect?: ClientRect | DOMRect | null
  [propName: string]: any
}

export type ForceUpdatePopper = Instance['forceUpdate'] | null

interface PopperProps {
  className?: string
  children: ((props: PopperChildrenProps) => ReactNode) | ReactNode
  popup: (props: PopperContentProps) => ReactNode
  /** Styles to apply to the content container */
  contentStyle?: React.CSSProperties
  /** Styles to apply to the arrow */
  arrowStyle?: React.CSSProperties
  spacing?: string
  'data-testid'?: string
  visible?: boolean // needs to be named something different from 'visible', or else react-popper breaks
  placement?: Placement
  modifiers?: Modifier<any>[]
  usePortal?: boolean
  /**
   * In some scenarios it could be semantically incorrect to use
   * a div element for the popper container (default).
   * For example when the tooltip is inside a <p> element, we should not have
   * a div inside a p.
   * In those scenario, use the tooltipContainerAs prop to change the element acting as container
   * for the tooltip. (Example: tooltipContainerAs="span")
   */
  tooltipContainerAs?: React.ElementType
  disableFlip?: boolean
  /** For accessibility purposes */
  onTriggerFocus?: (event: React.FocusEvent<HTMLDivElement>) => void
  onTriggerBlur?: (event: React.FocusEvent<HTMLDivElement>) => void
}

/** Shows a popup on the elements passed as children
 * The popup is visible when the prop `visible` is true
 *
 * Usage example on `Popper.stories.js`
 */
export const Popper = ({
  className,
  children,
  popup,
  spacing = '10px',
  contentStyle = {},
  arrowStyle = {},
  visible = false,
  placement = 'auto',
  modifiers = [],
  usePortal = true,
  disableFlip = false,
  onTriggerFocus,
  onTriggerBlur,
  tooltipContainerAs = 'div',
  ...restProps // passed to children as props
}: PopperProps) => {
  const [rect, setRect] = useState<ClientRect | DOMRect | null>(null)
  const [arrowElement, setArrowElement] = useState(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )

  const { styles, state, forceUpdate } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      modifiers: [
        { name: 'arrow', options: { element: arrowElement } },
        { name: 'computeStyles', options: { gpuAcceleration: false } },
        { name: 'flip', options: { padding: 5 }, enabled: !disableFlip },
        {
          name: 'preventOverflow',
          options: { boundary: 'viewport', padding: 5 },
        },
        { name: 'offset', options: { offset: [0, spacing.split('px')[0]] } },
        ...modifiers,
      ],
    }
  )

  const getReferenceElementRef = useCallback(
    (node: HTMLElement) => {
      // Calculate bounding rectangle to allow customization
      // of content dimensions when needed
      // (popup content is done in render below)
      if (node && typeof node.getBoundingClientRect === 'function' && !rect) {
        setRect(node.getBoundingClientRect())
      }

      setReferenceElement(node)
    },
    [rect]
  )

  const referenceChildrenProps: PopperChildrenProps = {
    ref: getReferenceElementRef,
    rect,
    ...restProps,
  }

  const {
    fillParent,
    popperProps,
    show,
    hide,
    rect: excludedRect,
    ...referenceDivProps
  } = referenceChildrenProps

  // JSDom (used in tests) sometimes returns NaN for the size of elements and this was the workaround for it
  // https://github.com/sketch-hq/cloud-frontend/pull/2207#discussion_r484827523
  const mergedStyle = clearNaN(
    state?.placement
      ? { ...styles.popper, ...contentStyle, willChange: undefined }
      : styles.popper
  )

  const TooltipContainerElementType = tooltipContainerAs

  return (
    <>
      {typeof children === 'function' ? (
        children(referenceChildrenProps)
      ) : (
        <TooltipContainerElementType
          className={className}
          {...referenceDivProps}
          onFocus={onTriggerFocus}
          onBlur={onTriggerBlur}
        >
          {children}
        </TooltipContainerElementType>
      )}

      {visible
        ? withPortal(
            usePortal,
            popup({
              ref: setPopperElement,
              rect,
              'data-placement': state?.placement || placement,
              style: mergedStyle,
              arrowRef: setArrowElement,
              arrowStyle: clearNaN({ ...styles.arrow, ...arrowStyle } || {}),
              forceUpdate,
            })
          )
        : null}
    </>
  )
}
