import { Location } from 'history'

const RESET_TOKEN_STORAGE_KEY = 'resetToken'

type ExpectedLocation = Pick<Location, 'pathname' | 'search'>

const getTokenFromResetURL = (location: ExpectedLocation) => {
  const isRequestPath = location.pathname.includes('reset')

  const urlParams = new URLSearchParams(location.search)
  const token = urlParams.get('token')

  return isRequestPath ? token : null
}

export const getResetToken = (location: ExpectedLocation) => {
  try {
    return (
      window.sessionStorage.getItem(RESET_TOKEN_STORAGE_KEY) ||
      getTokenFromResetURL(location)
    )
  } catch (e) {
    // Location will be used as a fall back if the resetToken is not set in storage
    return getTokenFromResetURL(location)
  }
}

export const clearResetToken = () => {
  try {
    return window.sessionStorage.removeItem(RESET_TOKEN_STORAGE_KEY)
  } catch (e) {
    // If it can't remove from the session-storage it means that it didn't have access before
  }
}

export const handleResetToken = (location: ExpectedLocation) => {
  const token = getTokenFromResetURL(location)

  if (token) {
    try {
      window.sessionStorage.setItem(RESET_TOKEN_STORAGE_KEY, token)

      // Location will only be replaced if the "resetToken" is saved.
      window.location.replace(location.pathname)
    } catch (e) {
      /*
       * If an error occurs when saving the item in local-storage the location shouldn't be replaced
       * keeping the token in the header
       */
    }

    return true
  }

  return false
}
