import React, { useState, useEffect } from 'react'

// Tabs
import AboutTab from '../AboutTab'
import { ShareAnnotations } from '../../../annotations/containers'
import { ShareVersions } from '../../../versioning/components'
import { ShareWithoutVersion } from '../../../versioning/ShareVersionContext/ShareVersionContext'

import SidebarHeader from '../SidebarHeader'
import { GroupType } from '../../ComponentsWebView/utils'
import InspectorRestricted from '../../ComponentsWebView/components/InspectorRestricted'
import { Inspector } from '../../ComponentsWebView/components/Inspector'
import { useInspectContext } from '../../ComponentsWebView/context/InspectContext'

import { useShareSidebarTab, createTabs } from '../ShareSidebarTabContext'

import { SidebarWrapper, Wrapper } from './DocumentSidebar.styles'
import { useBreakpoint } from '@sketch/components'

const CWV_INSPECTOR_TAB: GroupType[] = [
  'COLOR_VARIABLE',
  'LAYER_STYLE',
  'TEXT_STYLE',
]

const CWV_HIDDEN_COMMENTS_TAB: GroupType[] = [
  'COLOR_VARIABLE',
  'LAYER_STYLE',
  'SYMBOL',
  'TEXT_STYLE',
]

const FLOATING_SIDE_MENUS_HIDDEN_SIDEBAR: GroupType[] = ['SYMBOL', 'SHARE_VIEW']

const ALWAYS_SHOW_TABS = {
  shouldShowAbout: true,
  shouldShowVersion: true,
}

interface DocumentSidebarProps {
  share: ShareWithoutVersion
  userCanSeeComments: boolean
  userCanInspect?: boolean
  CWVRoute?: GroupType
  toggleSidebar: () => void
}

const DocumentSidebar: React.FC<DocumentSidebarProps> = props => {
  const {
    userCanInspect,
    userCanSeeComments,
    share,
    toggleSidebar,
    CWVRoute = 'SHARE_VIEW',
  } = props

  const isTabletOrBigger = useBreakpoint('sm')

  const shouldShowInspector =
    userCanInspect && CWV_INSPECTOR_TAB.includes(CWVRoute)

  const shouldShowComment =
    !CWV_HIDDEN_COMMENTS_TAB.includes(CWVRoute) && userCanSeeComments

  /**
   * Save the tabs the user has access and
   * update if "shouldShowCommentTab" and "shouldShowInspector" updates.
   *
   * TODO: simplify this logic using `getActivePanels` instead of `createTabs`
   */
  const [segments, setSegments] = useState(
    createTabs({
      ...ALWAYS_SHOW_TABS,
      shouldShowInspector,
      shouldShowComment,
    })
  )
  useEffect(() => {
    setSegments(
      createTabs({
        ...ALWAYS_SHOW_TABS,
        shouldShowInspector,
        shouldShowComment,
      })
    )
  }, [shouldShowInspector, shouldShowComment])

  /**
   * Enable the "Inspector" tab when the "selectItem" is set
   * also when the tab is changed the inspected item should be unselected
   */
  const { activeSegment, setActiveSegment } = useShareSidebarTab(segments)
  const { selectedItem, handleSelectItem } = useInspectContext()

  useEffect(() => {
    if (selectedItem) {
      setActiveSegment('Inspect')
    }
  }, [selectedItem, setActiveSegment])
  useEffect(() => {
    if (activeSegment === 'Inspect') {
      return () => {
        handleSelectItem(null)
      }
    }
  }, [activeSegment, handleSelectItem])

  // Hide right sidebar for mobile views when panels are active
  if (!isTabletOrBigger) {
    return null
  }

  if (FLOATING_SIDE_MENUS_HIDDEN_SIDEBAR.includes(CWVRoute)) {
    return null
  }

  /*
   * We only use the old sidebar mechanism to show the sidebar
   * when inspecting layers, colors, and text
   */
  if (CWV_INSPECTOR_TAB.includes(CWVRoute)) {
    return userCanInspect ? (
      <Wrapper>
        <SidebarWrapper>
          <Inspector />
        </SidebarWrapper>
      </Wrapper>
    ) : null
  }

  // Render the selected tab
  let tab = null
  switch (activeSegment) {
    case 'About': {
      tab = <AboutTab shareIdentifier={share.identifier} />
      break
    }
    case 'Comment': {
      tab = <ShareAnnotations share={share} />
      break
    }
    case 'Version': {
      tab = <ShareVersions shareIdentifier={share.identifier} />
      break
    }
    case 'Inspect': {
      tab = userCanInspect ? <Inspector /> : <InspectorRestricted />
      break
    }
  }

  return (
    <Wrapper>
      <SidebarHeader
        segments={segments}
        toggleSidebar={toggleSidebar}
        currentValue={activeSegment}
        setValue={setActiveSegment}
      />
      {tab && <SidebarWrapper>{tab}</SidebarWrapper>}
    </Wrapper>
  )
}

export default DocumentSidebar
