import React from 'react'
import { WrapperList } from '../../../user/views/Updates/Updates.styles'
import { Skeleton } from './NotificationListItem.styles'

export const NotificationListItemSkeleton = ({
  pageSize,
}: {
  pageSize: number
}) => {
  return (
    <WrapperList data-testId="skeleton-loading">
      {[...Array(pageSize)].map((_, index) => (
        <Skeleton key={index} />
      ))}
    </WrapperList>
  )
}
