import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "gear-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M13.18 4.5a1 1 0 01.98.804L14.4 6.5c.414.18.804.406 1.162.672l1.157-.391a1 1 0 011.186.447l1.18 2.044a1 1 0 01-.206 1.251l-.916.806a6.066 6.066 0 010 1.342l.916.806a1 1 0 01.206 1.25l-1.18 2.045a1 1 0 01-1.186.447l-1.157-.39a5.994 5.994 0 01-1.161.671l-.24 1.196a1 1 0 01-.98.804H10.82a1 1 0 01-.98-.804l-.24-1.195a5.994 5.994 0 01-1.162-.673l-1.157.391a1 1 0 01-1.186-.447l-1.18-2.044a1 1 0 01.206-1.251l.916-.807a6.066 6.066 0 010-1.34l-.916-.807a1 1 0 01-.206-1.25l1.18-2.045A1 1 0 017.28 6.78l1.157.39A5.994 5.994 0 019.6 6.5l.24-1.195a1 1 0 01.98-.804h2.36zm-1.18 6a1.5 1.5 0 100 3 1.5 1.5 0 000-3z", fillOpacity: 0.15, fill: "currentColor" }),
        React.createElement("path", { d: "M14.4 17.5l-.24 1.196a1 1 0 01-.98.804h-2.36a1 1 0 01-.98-.804l-.24-1.195a5.986 5.986 0 01-.888-.481l-.274-.192-1.157.391a1 1 0 01-.228.049l-.114.004a1 1 0 01-.844-.5l-1.18-2.044a1 1 0 01.206-1.251l.916-.807a6.066 6.066 0 010-1.34l-.916-.807a1 1 0 01-.206-1.25l1.18-2.045a1 1 0 01.731-.491l.113-.009a.998.998 0 01.342.053l1.157.39A5.994 5.994 0 019.6 6.5l.24-1.195a1 1 0 01.98-.804h2.36a1 1 0 01.98.804L14.4 6.5c.414.18.804.406 1.162.672l1.157-.391a1 1 0 01.228-.049l.114-.004a1 1 0 01.844.5l1.18 2.044a1 1 0 01-.206 1.251l-.916.806a6.066 6.066 0 010 1.342l.916.806a1 1 0 01.206 1.25l-1.18 2.045a1 1 0 01-.731.491l-.113.009a.998.998 0 01-.342-.053l-1.157-.39a5.994 5.994 0 01-1.161.671z", stroke: "currentColor", strokeWidth: 1.5 }),
        React.createElement("circle", { stroke: "currentColor", strokeWidth: 1.5, cx: 12, cy: 12, r: 2.25 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
