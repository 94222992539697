import styled, { keyframes } from 'styled-components'

import { ReactComponent as CompassIconRaw } from '@sketch/icons/compass-16'

// Keyframes
const rotate = keyframes`
  from { 
    transform: rotate(360deg);
  }

  to { 
    transform: rotate(0deg);
  }
`

const CompassIcon = styled(CompassIconRaw)`
  path:first-child {
    transform-origin: center center;

    /* TODO: Chrome 103 ruining this animation going to comment it for now */
    /* 
    animation: ${rotate}
       1.5s
       ${({ theme }) => theme.transitions.timing.spring} 
       paused; 
    */
  }
`

export { CompassIcon }
