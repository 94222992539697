/* eslint-disable */

export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string
      name: string
      possibleTypes: {
        name: string
      }[]
    }[]
  }
}
const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: 'UNION',
        name: 'ArtboardRevisionItem',
        possibleTypes: [
          {
            name: 'Revision',
          },
          {
            name: 'RevisionDeleted',
          },
          {
            name: 'RevisionUnavailable',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'BaseProjectAccessChangedEvent',
        possibleTypes: [
          {
            name: 'ProjectAccessGrantedEvent',
          },
          {
            name: 'ProjectAccessRevokedEvent',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'BaseProjectChangedEvent',
        possibleTypes: [
          {
            name: 'ProjectArchivedEvent',
          },
          {
            name: 'ProjectChangedEvent',
          },
          {
            name: 'ProjectCreatedEvent',
          },
          {
            name: 'ProjectDeletedEvent',
          },
          {
            name: 'ProjectMemberAddedEvent',
          },
          {
            name: 'ProjectMemberChangedEvent',
          },
          {
            name: 'ProjectMemberRemovedEvent',
          },
          {
            name: 'ProjectPermanentlyDeletedEvent',
          },
          {
            name: 'ProjectRestoredEvent',
          },
          {
            name: 'ProjectUnarchivedEvent',
          },
          {
            name: 'ProjectWorkspaceAccessLevelChangedEvent',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'BaseShareChangedEvent',
        possibleTypes: [
          {
            name: 'ShareAccessRevokedEvent',
          },
          {
            name: 'ShareDeletedEvent',
          },
          {
            name: 'ShareDeletedPermanentlyEvent',
          },
          {
            name: 'ShareProjectChangedEvent',
          },
          {
            name: 'ShareRestoredEvent',
          },
          {
            name: 'ShareUpdatedEvent',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'BillingHistoryEntry',
        possibleTypes: [
          {
            name: 'CreditTransaction',
          },
          {
            name: 'CustomerInvoice',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'BillingPlanInfo',
        possibleTypes: [
          {
            name: 'AppStoreBillingPlan',
          },
          {
            name: 'CloudBillingPlan',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CatchUpStrategy',
        possibleTypes: [
          {
            name: 'DownloadLatestVersionCatchUp',
          },
          {
            name: 'DownloadPatchesCatchUp',
          },
          {
            name: 'OutOfDateBaseVersionCatchUp',
          },
          {
            name: 'RemoteMigrationCatchUp',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CollaborativePatch',
        possibleTypes: [
          {
            name: 'LocalCollaborativePatch',
          },
          {
            name: 'RemoteCollaborativePatch',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CommunityItem',
        possibleTypes: [
          {
            name: 'CommunityGrid',
          },
          {
            name: 'CommunityHero',
          },
          {
            name: 'CommunityListing',
          },
          {
            name: 'CommunitySlider',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'CommunityPublicationQuery',
        possibleTypes: [
          {
            name: 'CommunityGrid',
          },
          {
            name: 'CommunityListing',
          },
          {
            name: 'CommunitySlider',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'Component',
        possibleTypes: [
          {
            name: 'ColorVariable',
          },
          {
            name: 'LayerStyle',
          },
          {
            name: 'Symbol',
          },
          {
            name: 'TextStyle',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CurrentSubject',
        possibleTypes: [
          {
            name: 'Artboard',
          },
          {
            name: 'ColorVariable',
          },
          {
            name: 'LayerStyle',
          },
          {
            name: 'Page',
          },
          {
            name: 'Symbol',
          },
          {
            name: 'TextStyle',
          },
          {
            name: 'UnprocessedSubject',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'Image',
        possibleTypes: [
          {
            name: 'ResponsiveImage',
          },
          {
            name: 'SingleImage',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'InvitableUserOrPermissionGroup',
        possibleTypes: [
          {
            name: 'PermissionGroup',
          },
          {
            name: 'WorkspaceMembership',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LicenseRedemptionResult',
        possibleTypes: [
          {
            name: 'LicenseRedemption',
          },
          {
            name: 'LicenseRedemptionError',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MigrationEvent',
        possibleTypes: [
          {
            name: 'MigrationCompleted',
          },
          {
            name: 'MigrationFailed',
          },
          {
            name: 'MigrationStarted',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'PaymentDetails',
        possibleTypes: [
          {
            name: 'CreditCardPaymentDetails',
          },
          {
            name: 'InvoicePaymentDetails',
          },
          {
            name: 'NoPaymentDetails',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PermissionGroupRelation',
        possibleTypes: [
          {
            name: 'PermissionGroupRelationProject',
          },
          {
            name: 'PermissionGroupRelationShare',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ProjectMembershipPaginationItem',
        possibleTypes: [
          {
            name: 'PermissionGroupRelationProject',
          },
          {
            name: 'ProjectMembership',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ShareCollectionChangedEvent',
        possibleTypes: [
          {
            name: 'ShareAddedToCollectionEvent',
          },
          {
            name: 'ShareRemovedFromCollectionEvent',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ShareMembershipPaginationItem',
        possibleTypes: [
          {
            name: 'PermissionGroupRelationShare',
          },
          {
            name: 'ShareMembership',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'ShareOwner',
        possibleTypes: [
          {
            name: 'PublicOrganization',
          },
          {
            name: 'PublicUser',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'SignInResponse',
        possibleTypes: [
          {
            name: 'MFACredentials',
          },
          {
            name: 'SignInCredentials',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'SubscriptionInfo',
        possibleTypes: [
          {
            name: 'AppStoreSubscription',
          },
          {
            name: 'CloudSubscription',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'TrashedItem',
        possibleTypes: [
          {
            name: 'Project',
          },
          {
            name: 'Share',
          },
          {
            name: 'TrashedProject',
          },
          {
            name: 'TrashedShare',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'UserNotification',
        possibleTypes: [
          {
            name: 'AddShareMemberNotification',
          },
          {
            name: 'CommentNotification',
          },
          {
            name: 'DocumentUploadCompleteNotification',
          },
          {
            name: 'DocumentUploadProcessedNotification',
          },
          {
            name: 'DownloadableAssetRenderedNotification',
          },
          {
            name: 'MentionNotification',
          },
          {
            name: 'StarNotification',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'VersionHistoryItem',
        possibleTypes: [
          {
            name: 'Version',
          },
          {
            name: 'VersionDeleted',
          },
          {
            name: 'VersionUnavailable',
          },
        ],
      },
    ],
  },
}
export default result
