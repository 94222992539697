import { ShareInfoFragment, useRestoreShareMutation } from '@sketch/gql-types'
import { ErrorHandler } from '@sketch/tracing'
import { handleShareRestored } from './handlers'

export interface UseShareRestoreProps {
  identifier: string
  onCompleted: (share: ShareInfoFragment) => void
  onError: (message: string) => void
}

export const useShareRestore = (props: UseShareRestoreProps) => {
  const { identifier, onCompleted, onError } = props

  return useRestoreShareMutation({
    variables: { identifier },
    onCompleted: data => {
      if (!data?.restoreShare?.share) {
        onError('Something is wrong, please contact our support team')
        ErrorHandler.shouldNeverHappen('restoreShare')
        return
      }

      onCompleted(data.restoreShare.share)
    },
    update: (cache, { data }) => {
      const workspaceIdentifier =
        data?.restoreShare?.share?.workspace.identifier || ''

      const projectIdentifier = data?.restoreShare?.share?.project?.identifier

      if (workspaceIdentifier === '') {
        ErrorHandler.shouldNeverHappen.invalidMutationData('restoreShare')
      }

      handleShareRestored({
        cache,
        identifier,
        workspaceIdentifier,
        projectIdentifier,
      })
    },
    onError: 'show-toast',
  })
}
