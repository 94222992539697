import { withBox } from './withBox'
import { withFlex as withFlexRaw } from './withFlex'
import { withText as withTextRaw } from './withText'
import { withAbsolute as withAbsoluteRaw } from './withAbsolute'
import { ComponentProps } from 'react'

function withFlex<C extends AnyComponent>(Comp: C) {
  return withFlexRaw(withBox(Comp))
}

function withText<C extends AnyComponent>(Comp: C) {
  return withTextRaw(withBox(Comp))
}

function withAbsolute<C extends AnyComponent>(Comp: C) {
  return withAbsoluteRaw(withBox(Comp))
}

export const Box = withBox('div')

export const Absolute = withAbsolute('div')

export const Flex = Object.assign(withFlex('div'), {
  Form: withFlex('form'),
})

export type FlexDivProps = ComponentProps<typeof Flex>

/**
 * Text component
 *
 * Used to render a text using `textStyle` prop that matches the text styles
 * from Prism (note: for now text styles in the FE don't match 100% text styles
 * in Prism doc, that will change when text overrides is available for the
 * mac app)
 *
 * As a last option, Text can also accept these props: `color`, `fontSize`,
 * `fontWeight`, `lineHeight`, `fontStyle`, `textAlign`, `verticalAlign`,
 * `textTransform` or `overflow`.
 */
export const Text = Object.assign(withText('p'), {
  P: withText('p'),
  Span: withText('span'),
  Label: withText('label'),
  Div: withText('div'),
  Section: withText('section'),
  H1: withText('h1'),
  H2: withText('h2'),
  H3: withText('h3'),
  H4: withText('h4'),
  H5: withText('h5'),
  H6: withText('h6'),
  Li: withText('li'),
})
