import React from 'react'

import { routes } from '@sketch/modules-common'

import { useBreakpoint } from '@sketch/components'

import {
  Wrapper,
  EditIcon,
  Text,
  StyledLink,
} from './EditingProfileNavBar.styles'

interface EditingProfileNavBarProps {
  shortUrlName: string
}

export const EditingProfileNavBar: React.FC<EditingProfileNavBarProps> = ({
  shortUrlName,
}) => {
  const isMobile = !useBreakpoint('xs')

  return (
    <Wrapper>
      {isMobile ? (
        <Text>Changes are applied immediately.</Text>
      ) : (
        <>
          <EditIcon />
          <Text>
            Changes you make to your public profile are applied immediately.
          </Text>
        </>
      )}

      <StyledLink
        variant="tertiary"
        target="_blank"
        to={routes.WORKSPACE_PROFILE.create({
          shortUrlName,
        })}
        isUnderlined
      >
        View Profile
      </StyledLink>
    </Wrapper>
  )
}
