import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GenericErrorState from './GenericErrorState'
import * as Sentry from '@sentry/browser'

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  componentDidCatch(error, info) {
    Sentry.withScope(scope => {
      scope.setExtra('ErrorBoundary', info)
      Sentry.captureException(error)
    })
  }

  render() {
    const { fallbackComponent: FallbackComponent } = this.props
    const { error } = this.state

    if (error) {
      return <FallbackComponent error={error} />
    }

    return this.props.children
  }
}

ErrorBoundary.defaultProps = {
  fallbackComponent: GenericErrorState,
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  fallbackComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}
