import React, { useRef } from 'react'

import { useAnalytics, useBrowserSettings } from '@sketch/modules-common'
import {
  Flex,
  TruncateWithTooltip,
  HighlightedText,
  useBreakpoint,
} from '@sketch/components'
import {
  TextItem,
  TextImageWrapper,
  TextImage,
  TextDetail,
  TextAlignImg,
  TextDetailName,
} from './TextStyles.styles'

import useResponsiveImage from '../../hooks/useResponsiveImage'
import {
  useInspectContext,
  useComponentsDescriptionContext,
  ComponentDescriptionProps,
} from '../../context'
import { useOnClickOutside } from '@sketch/utils'

// This is a valid use case to validate TextStyle
// eslint-disable-next-line no-restricted-imports
import { TextStyle, TextStyleAlignment } from '@sketch/gql-types/expansive'

import type { File } from '../../types'

// Text Align Images
import { ReactComponent as AlignCenterIcon } from '@sketch/icons/text-align-center-16'
import { ReactComponent as AlignJustifyIcon } from '@sketch/icons/text-align-justify-16'
import { ReactComponent as AlignLeftIcon } from '@sketch/icons/text-align-left-16'

const TextAlignmentImage = ({
  alignment,
}: {
  alignment: TextStyleAlignment
}) => {
  switch (alignment) {
    case 'RIGHT':
      return (
        <TextAlignImg as={AlignLeftIcon} style={{ transform: 'scaleX(-1)' }} />
      )
    case 'LEFT':
      return <TextAlignImg as={AlignLeftIcon} />
    case 'CENTER':
      return <TextAlignImg as={AlignCenterIcon} />
    case 'JUSTIFIED':
      return <TextAlignImg as={AlignJustifyIcon} />
    default:
      return null
  }
}

interface TextStyleItemProps {
  textStyle: TextStyle
  searchValue: string
  pendingDescription: ComponentDescriptionProps
}

export const TextStyleItem: React.FC<TextStyleItemProps> = ({
  textStyle,
  searchValue,
  pendingDescription,
}) => {
  const isTabletOrBigger = useBreakpoint('sm')
  const isMobile = !isTabletOrBigger

  const { trackEvent } = useAnalytics()
  const { selectedItem, handleSelectItem } = useInspectContext()
  const {
    resetComponentDescriptionSelection,
    onComponentSelected,
  } = useComponentsDescriptionContext()
  const [data, updateBrowserSettings] = useBrowserSettings()
  const isSidebarRightOpen = data?.sidebarRightOpen

  const contentRef = useRef(null)

  const isSelected = selectedItem === textStyle.identifier

  useOnClickOutside(
    contentRef,
    e => {
      handleSelectItem(null)
      resetComponentDescriptionSelection()
    },
    {
      includeSelectors: [
        '[data-testid=side-bar-right]',
        '[data-testid=popover-content]',
      ],
    }
  )

  const imageProps = useResponsiveImage({
    name: textStyle.name,
    files: textStyle.files as File[],
  })

  const fontDetail = `${textStyle.font.name} (${textStyle.font.weight}) —
  ${textStyle.font.size}`

  return (
    <TextItem
      data-component={textStyle.identifier}
      onClick={() => {
        if (isMobile) {
          return
        }

        if (searchValue) {
          trackEvent('CWV - search click', { type: 'text styles' })
        }

        handleSelectItem(textStyle.identifier)
        onComponentSelected(textStyle.uuid, pendingDescription)

        if (!isSidebarRightOpen) {
          updateBrowserSettings({
            sidebarRightOpen: true,
          })
        }
      }}
    >
      <TextImageWrapper
        isSelected={isSelected}
        needsContrastingBackground={textStyle.needsContrastingBackground}
        ref={contentRef}
      >
        <TextImage {...imageProps} />
      </TextImageWrapper>
      <Flex mt={2}>
        <TextDetail>
          <TextDetailName>
            <TruncateWithTooltip>{textStyle.name} </TruncateWithTooltip>
          </TextDetailName>
          <TextAlignmentImage alignment={textStyle.horizontalAlignment} />{' '}
          <HighlightedText search={searchValue}>{fontDetail}</HighlightedText>
        </TextDetail>
      </Flex>
    </TextItem>
  )
}
