import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "exclamation-mark-triangle-64", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 64 64", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M0 0h64v64H0z" }),
        React.createElement("path", { d: "M36.32 11.828l22.24 38.154a5 5 0 01-4.32 7.518H9.76a5 5 0 01-4.32-7.518l22.24-38.154a5 5 0 018.64 0z", stroke: "currentColor", strokeWidth: 3, fillOpacity: 0.2, fill: "currentColor" }),
        React.createElement("path", { d: "M32 23a3.15 3.15 0 013.134 3.48l-1.477 14.028a1.666 1.666 0 01-3.314 0l-1.477-14.027A3.15 3.15 0 0132 23z", fill: "currentColor" }),
        React.createElement("circle", { fill: "currentColor", cx: 32, cy: 48, r: 3 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
