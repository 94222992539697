import styled from 'styled-components'
import { Navbar } from '@sketch/components'

export const PageWrapper = styled.div`
  width: 100%;
  max-width: 640px;
  margin-top: 24px;
`

export const SelectorSection = styled(Navbar.Section)`
  flex: 0 1 auto;
  /* Prevent this section from sending it's siblings of screen */
  overflow: hidden;
`
