import React from 'react'

import {
  DropdownItemAnchorStyle,
  DropdownItemNavLinkStyle,
  DropdownItemStyle,
  ListItem,
} from './DropdownItem.styles'
import { ItemProps, ItemAnchorProps, NavLinkProps } from './types'

const DropdownItem = React.forwardRef<HTMLLIElement, ItemProps>(
  function DropdownItem(
    { unmountAfterClick = true, ...props }: ItemProps,
    ref
  ) {
    return (
      <ListItem ref={ref}>
        <DropdownItemStyle
          tabIndex={0}
          type="button" // needs to be data- so it can be picked up on the DOM
          data-unmountafterclick={unmountAfterClick}
          {...props}
        >
          {props.children}
        </DropdownItemStyle>
      </ListItem>
    )
  }
)

const DropdownItemAnchor = (props: ItemAnchorProps) => (
  <ListItem>
    <DropdownItemAnchorStyle {...props}>
      {props.children}
    </DropdownItemAnchorStyle>
  </ListItem>
)

const DropdownItemNavLink = ({
  unmountAfterClick = true,
  ...props
}: NavLinkProps) => {
  return (
    <ListItem>
      <DropdownItemNavLinkStyle
        data-unmountafterclick={unmountAfterClick}
        {...props}
      >
        {props.children}
      </DropdownItemNavLinkStyle>
    </ListItem>
  )
}

export { DropdownItem, DropdownItemAnchor, DropdownItemNavLink, ListItem }
