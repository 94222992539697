import { castError } from '@sketch/utils'
import { ErrorHandler } from '@sketch/tracing'
import { registrationManager, ServiceWorkerClient } from '../../client'

export const getStorageEstimate = (): Promise<StorageEstimate> => {
  if (navigator?.storage?.estimate) {
    return navigator.storage.estimate()
  }

  if ((navigator as any)?.webkitTemporaryStorage?.queryUsageAndQuota) {
    // Return a promise-based wrapper that will follow the expected interface.
    return new Promise(function (resolve, reject) {
      // eslint-disable-next-line no-extra-semi
      ;(navigator as any).webkitTemporaryStorage.queryUsageAndQuota(function (
        usage: number,
        quota: number
      ) {
        resolve({ usage: usage, quota: quota })
      },
      reject)
    })
  }

  throw new Error('storage quota estimation is not supported')
}

const getClient = async () => {
  try {
    const client = (await registrationManager?.getClient()) || null
    return client
  } catch (e) {
    const err = castError(e)
    ErrorHandler.ignore(err)
    return null
  }
}

const getState = async (client: ServiceWorkerClient) => {
  try {
    const state = await client.getState()
    return state
  } catch (e) {
    const err = castError(e)
    ErrorHandler.ignore(err)
    return null
  }
}

const getQuota = async () => {
  try {
    const estimate = await getStorageEstimate()
    return estimate
  } catch (e) {
    const err = castError(e)
    ErrorHandler.ignore(err)
    return null
  }
}

export const getContextOnPageLoad = async () => {
  try {
    const quota = await getQuota()
    const client = await getClient()
    const state = client ? await getState(client) : null
    return { quota, client, state }
  } catch (e) {
    const err = castError(e)
    ErrorHandler.ignore(err)
    return null
  }
}

// We'll be able to remove this Awaited once we'll upgrade to TypeScript v4.5 or newer
type Awaited<T> = T extends PromiseLike<infer U> ? U : T

export type ServiceWorkerContextOnPageLoad = Awaited<
  ReturnType<typeof getContextOnPageLoad>
>
