/**
 * Border radius design tokens.
 *
 * For use with the following CSS properties:
 *
 *     border-radius
 *
 * @see https://system-ui.com/theme
 */
export const radii = {
  base: '4px',
  small: '3px',
  medium: '5px',
  large: '6px',
  xlarge: '8px',
  xxlarge: '12px',
  xxxlarge: '16px',
  rounded: '999px',
} as const

/**
 * Border width design tokens.
 *
 * For use with the following CSS properties:
 *
 *     border-width
 *
 * @see https://system-ui.com/theme
 */
export const borderWidth = [0, 1, 2, 3, 4] as const
