import React, { ChangeEventHandler, Dispatch, SetStateAction } from 'react'
import {
  Text,
  Form,
  InfiniteList,
  LoadingPlaceholder,
  useBreakpoint,
  Flex,
} from '@sketch/components'
import { useDebounceValue } from '@sketch/utils'
import { thumbnailForPreviewFile } from '../../../shares/utils'
import {
  SelectableShare,
  SelectableDocumentTable,
} from './SelectableDocumentTable'
import {
  LoadingWrapper,
  Thumbnail,
  ItemWrapper,
  SearchInput,
  Panel,
  WidePanel,
  Sticky,
  SkeletonItem,
  Content,
  StyledCheckbox,
} from './SelectableDocumentList.styles'

const Skeleton = ({ count = 6 }: { count: number }) => {
  const placeholderCount = Math.max(0, count)

  return (
    <>
      {[...Array(placeholderCount)].map((_, index) => (
        <SkeletonItem key={index} />
      ))}
    </>
  )
}

const LoadingList = () => {
  return (
    <LoadingWrapper>
      <LoadingPlaceholder size="64px" />
    </LoadingWrapper>
  )
}

interface ItemProps {
  item: SelectableShare
  isChecked: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  name: string
}

const Item = ({ item, onChange, isChecked, name }: ItemProps) => {
  const previewFile =
    item.__typename === 'UnpaginatedCollectionShare'
      ? item.file
      : item.version?.document?.previewFile
  const thumbnail = thumbnailForPreviewFile(previewFile)

  return (
    <ItemWrapper>
      <StyledCheckbox
        name={name}
        value={item.identifier}
        checked={isChecked}
        onChange={onChange}
      >
        <Content>
          {thumbnail && <Thumbnail src={thumbnail} />}
          <Text
            fontWeight="medium"
            color="colors.foreground.secondary.A"
            fontSize="E"
          >
            {item.name}
          </Text>
        </Content>
      </StyledCheckbox>
    </ItemWrapper>
  )
}

interface SelectableDocumentListProp {
  name: string
  items: SelectableShare[]

  placeholderCount?: number
  fetchMore: () => Promise<any>
  resetSelectedShares: () => void
  loading: boolean
  hasWideOption?: boolean

  // UI States
  errorState: React.ReactElement | null
  noResultsState: React.ReactElement | null
  // optional because we might not want to render
  // the document list if there are no documents
  // instead of an empty state list
  emptyState?: React.ReactElement | null

  // Search
  onSearch: Dispatch<SetStateAction<string>>
  search: string
  searchError?: string // Search input error
  searchPlaceholder: string // Search input placeholder
  isSearchDisabled?: boolean

  onItemChange: ChangeEventHandler<HTMLInputElement>
  selectedItems: string[]
  selectAll?: boolean

  listWrapper?: React.ElementType
}

export const SelectableDocumentList: React.FC<SelectableDocumentListProp> = ({
  name,
  items,

  placeholderCount = 6,
  fetchMore,
  resetSelectedShares,
  loading,
  hasWideOption = false,

  errorState,
  emptyState,
  noResultsState,

  onSearch,
  search,
  searchError,
  searchPlaceholder,
  isSearchDisabled,

  onItemChange,
  selectedItems,
  selectAll = false,

  listWrapper: ListWrapper = hasWideOption ? WidePanel : Panel,
}) => {
  const isWideView = useBreakpoint('sm')
  const debouncedSearch = useDebounceValue(search, 500)

  let content = null

  if (!loading && errorState) {
    content = errorState
  } else if (!loading && noResultsState) {
    content = noResultsState
  } else if (!loading && emptyState) {
    content = emptyState
  } else if (items.length) {
    content = isWideView ? (
      <SelectableDocumentTable
        shares={items}
        onChange={onItemChange}
        onLoadMore={fetchMore}
        selectedItems={selectedItems}
        search={search}
        name={name}
        selectAll={selectAll}
        placeholderCount={placeholderCount}
        resetSelectedShares={resetSelectedShares}
      />
    ) : (
      <>
        {items.map(item => {
          const checked = selectedItems.includes(item.identifier)

          // filter out the shares that do not match the search (for the selected ones)
          if (
            !item.name.toLowerCase().includes(debouncedSearch.toLowerCase())
          ) {
            return null
          }

          return (
            <Item
              data-testid="selectable-document-list-item"
              key={item.identifier}
              name={name}
              item={item}
              isChecked={checked}
              onChange={onItemChange}
            />
          )
        })}
      </>
    )
  }

  return (
    <>
      <Sticky hasWideOption={hasWideOption}>
        <Form.Field name="text" errorText={searchError}>
          <SearchInput
            disabled={isSearchDisabled}
            onChange={onSearch}
            value={search}
            name="search"
            placeholder={searchPlaceholder}
          />
        </Form.Field>
      </Sticky>
      {/* the extra content check makes sure there's no blank state while the items are being passed down */}
      {loading || !content ? (
        <Flex alignItems="center" height={395} flexDirection="row">
          <LoadingList />
        </Flex>
      ) : isWideView ? (
        content
      ) : (
        <InfiniteList
          data-testid="selectable-document-list"
          onLoadMore={fetchMore}
          wrappingComponent={ListWrapper}
          placeholderItems={<Skeleton count={placeholderCount} />}
          renderLoading={() => <LoadingPlaceholder size="64px" />}
        >
          {content}
        </InfiniteList>
      )}
    </>
  )
}

export default SelectableDocumentList
