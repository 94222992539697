import styled from 'styled-components'

export const BreadcrumbsContainer = styled.ol`
  margin-top: 0;
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSizes.D};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  line-height: 1.4;

  padding: 0;
  list-style: none;

  li {
    display: inline;

    &:not(:last-child):after {
      content: '/';
      margin: 0 4px;
    }

    &:last-child {
      color: ${({ theme }) => theme.colors.foreground.secondary.A};
    }
  }
`

export const BreadcrumbItem = styled.li`
  word-break: break-all;
  overflow-wrap: anywhere;
`
