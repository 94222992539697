import styled, { css } from 'styled-components'

import { ReactComponent as FolderClosed } from '@sketch/icons/folder-closed-64'
import { ReactComponent as QuestionMarkIcon } from '@sketch/icons/question-mark-16'

import { breakpoint } from '@sketch/global-styles'

import { Text, TextArea, Modal } from '@sketch/components'

const minBreakpoint = 'sm'

export const Separator = styled.div(
  ({ theme }) => css`
    border-top: 1px solid ${theme.colors.border.A};
    margin: 20px 0;
    width: 100%;

    ${breakpoint(minBreakpoint, 'xxl')`
        display: none;
    `}
  `
)

export const ModalWide = styled(Modal)`
  max-width: 512px;

  ${breakpoint(minBreakpoint, 'xxl')`
    max-width: 932px;
    max-height: 550px;
    overflow-y: hidden;
  `}
`

export const ModalWideColumnsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint(minBreakpoint, 'xxl')`
    flex-direction: row;
  `}
`

export const Column = styled.div`
  ${breakpoint(minBreakpoint, 'xxl')`
    &:first-of-type {
        padding-right: 32px;
        width: 324px;
    }

    &:last-of-type {
        flex: 1;
        padding-right: 0;
    }
  `}
`

export const ModalHeader = styled(Modal.Header)`
  padding: 16px 0 4px;

  ${breakpoint('sm')`
      padding: 32px 0 4px;
  `}
`

export const StyledTextArea = styled(TextArea)`
  resize: none;
  width: 100%;
  height: 100px;
`
export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
  margin-bottom: 16px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;

  ${breakpoint('base', 'xs')`
      margin-top: 16px;
      margin-bottom: 16px;
  `}
`

export const Title = styled(Text.H1).attrs({
  textStyle: 'copy.primary.standard.F',
})`
  margin: 0;
  line-height: 1.4;
  font-weight: 500;
`

export const Description = styled(Text)`
  max-width: 320px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

export const StyledFolderClosed = styled(FolderClosed)`
  color: ${({ theme }) => theme.colors.state.disabled};
`

export const StyledQuestionMarkIcon = styled(QuestionMarkIcon)`
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  height: 16px;
  width: 16px;
  margin-left: 4px;
  margin-bottom: -2px; /* stylelint-disable-line scales/space */
`
