import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "template-64", viewBox: "-828 53 64 64", xmlns: "http://www.w3.org/2000/svg", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "template-64_svg__a" },
            React.createElement("path", { d: "M15.727 0c2.065 0 2.9.232 3.715.668a4.543 4.543 0 011.89 1.89c.436.815.668 1.65.668 3.715v2.454c0 2.065-.232 2.9-.668 3.715a4.543 4.543 0 01-1.89 1.89c-.815.436-1.65.668-3.715.668H6.273c-2.065 0-2.9-.232-3.715-.668a4.543 4.543 0 01-1.89-1.89C.232 11.627 0 10.792 0 8.727V6.273c0-2.065.232-2.9.668-3.715a4.543 4.543 0 011.89-1.89C3.373.232 4.208 0 6.273 0h9.454z" })),
        React.createElement("clipPath", { id: "template-64_svg__b" },
            React.createElement("path", { d: "M15.59 0c2.23 0 3.037.232 3.852.668a4.543 4.543 0 011.89 1.89c.436.815.668 1.623.668 3.852v15.18c0 2.23-.232 3.037-.668 3.852a4.543 4.543 0 01-1.89 1.89c-.815.436-1.623.668-3.852.668H6.41c-2.23 0-3.037-.232-3.852-.668a4.543 4.543 0 01-1.89-1.89C.232 24.627 0 23.82 0 21.59V6.41c0-2.23.232-3.037.668-3.852a4.543 4.543 0 011.89-1.89C3.373.232 4.18 0 6.41 0h9.18z" }))),
    React.createElement("g", { clipPath: "url(#template-64_svg__a)", transform: "translate(-820 62)" },
        React.createElement("path", { d: "M6.273 0h9.454c2.065 0 2.9.232 3.715.668a4.543 4.543 0 011.89 1.89c.436.815.668 1.65.668 3.715v2.454c0 2.065-.232 2.9-.668 3.715a4.543 4.543 0 01-1.89 1.89c-.815.436-1.65.668-3.715.668H6.273c-2.065 0-2.9-.232-3.715-.668a4.543 4.543 0 01-1.89-1.89C.232 11.627 0 10.792 0 8.727V6.273c0-2.065.232-2.9.668-3.715a4.543 4.543 0 011.89-1.89C3.373.232 4.208 0 6.273 0z", stroke: "currentColor", strokeWidth: 6, fill: "none", strokeMiterlimit: 5 })),
    React.createElement("g", { clipPath: "url(#template-64_svg__b)", transform: "translate(-820 80)" },
        React.createElement("path", { fill: "rgba(0, 0, 0, 0.15)", d: "M0 0h22v28H0V0z" })),
    React.createElement("g", { clipPath: "url(#template-64_svg__b)", transform: "translate(-820 80)" },
        React.createElement("path", { d: "M6.41 0h9.18c2.23 0 3.037.232 3.852.668a4.543 4.543 0 011.89 1.89c.436.815.668 1.623.668 3.852v15.18c0 2.23-.232 3.037-.668 3.852a4.543 4.543 0 01-1.89 1.89c-.815.436-1.623.668-3.852.668H6.41c-2.23 0-3.037-.232-3.852-.668a4.543 4.543 0 01-1.89-1.89C.232 24.627 0 23.82 0 21.59V6.41c0-2.23.232-3.037.668-3.852a4.543 4.543 0 011.89-1.89C3.373.232 4.18 0 6.41 0z", stroke: "currentColor", strokeWidth: 6, fill: "none", strokeMiterlimit: 5 })),
    React.createElement("g", { clipPath: "url(#template-64_svg__a)", transform: "rotate(-180 -386.5 54)" },
        React.createElement("path", { d: "M6.273 0h9.454c2.065 0 2.9.232 3.715.668a4.543 4.543 0 011.89 1.89c.436.815.668 1.65.668 3.715v2.454c0 2.065-.232 2.9-.668 3.715a4.543 4.543 0 01-1.89 1.89c-.815.436-1.65.668-3.715.668H6.273c-2.065 0-2.9-.232-3.715-.668a4.543 4.543 0 01-1.89-1.89C.232 11.627 0 10.792 0 8.727V6.273c0-2.065.232-2.9.668-3.715a4.543 4.543 0 011.89-1.89C3.373.232 4.208 0 6.273 0z", stroke: "currentColor", strokeWidth: 6, fill: "none", strokeMiterlimit: 5 })),
    React.createElement("g", { clipPath: "url(#template-64_svg__b)", transform: "rotate(-180 -386.5 45)" },
        React.createElement("path", { fill: "rgba(0, 0, 0, 0.15)", d: "M0 0h22v28H0V0z" })),
    React.createElement("g", { clipPath: "url(#template-64_svg__b)", transform: "rotate(-180 -386.5 45)" },
        React.createElement("path", { d: "M6.41 0h9.18c2.23 0 3.037.232 3.852.668a4.543 4.543 0 011.89 1.89c.436.815.668 1.623.668 3.852v15.18c0 2.23-.232 3.037-.668 3.852a4.543 4.543 0 01-1.89 1.89c-.815.436-1.623.668-3.852.668H6.41c-2.23 0-3.037-.232-3.852-.668a4.543 4.543 0 01-1.89-1.89C.232 24.627 0 23.82 0 21.59V6.41c0-2.23.232-3.037.668-3.852a4.543 4.543 0 011.89-1.89C3.373.232 4.18 0 6.41 0z", stroke: "currentColor", strokeWidth: 6, fill: "none", strokeMiterlimit: 5 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
