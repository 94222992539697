import { routes } from '@sketch/modules-common'
import { pick } from '@sketch/utils'

export const authRoutes = pick(routes, [
  'CHOOSE_YOUR_PATH',
  'FORGOT_PASSWORD',
  'PERSONAL_SETTINGS_DISABLE_MFA',
  'RECOVERY_CODE',
  'RESET_PASSWORD',
  'SIGN_IN',
  'SIGN_UP',
  'SSO_ERROR',
  'SSO_LINK_ACCOUNT',
  'SSO_SIGN_IN',
  'SSO_SUCCESSFUL_SIGN_IN',
  'SSO_TOS',
  'VERIFICATION_CODE',
  'CHECK_YOUR_INBOX',
  'SIGN_OUT',
  'SIGN_IN_GET_STARTED',
])

export const allAuthRouteTemplates = Object.values(authRoutes).map(x =>
  x.template()
)
