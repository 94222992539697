import { BannerSection } from '@sketch/components'
import styled from 'styled-components'

export const Banner = styled(BannerSection)`
  justify-content: center;
  min-height: 40px;
  flex: 1 1 100%;

  /* Make the banner closer to the header */
  margin-bottom: -12px;

  > div {
    /* Force the banner message to be centered */
    justify-content: center;
  }
`
