import styled, { css } from 'styled-components'
import { Text, truncateStyles } from '@sketch/components'
import { Image as ImageUnstyled } from '@sketch/modules-common'
import { breakpoint } from '@sketch/global-styles'
import Separator from '../../../Inspector/Sidebar/components/Separator'

export const ItemContainerBackgroundColor = css<{
  needsContrastingBackground?: boolean
}>`
  ${({ needsContrastingBackground, theme }) => css`
    background-color: ${needsContrastingBackground
      ? theme.colors.background.tertiary.A
      : theme.colors.background.tertiary.C};
  `}
`
export const ListGridItem = styled.button`
  margin-top: 12px;
  background: none;
  border: none;
  width: 100%;
  cursor: pointer;
  padding: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;
`

export const SelectedBorderStyle = css<{ isSelected?: boolean }>`
  ${({ theme, isSelected }) => css`
    box-shadow: ${isSelected
      ? `inset 0px 0px 0px 2px ${theme.colors.sketch.A}`
      : undefined};
  `}
`

export const ListGridSeparator = styled(Separator)`
  margin: 24px 0;

  ${breakpoint('sm')`
    margin: 40px 0;
  `};
`

export const ItemImageWrapper = styled.div<{
  isSelected?: boolean
  needsContrastingBackground?: boolean
}>(
  ({ theme }) => css`
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    overflow: hidden;

    ${ItemContainerBackgroundColor};

    border-radius: ${theme.radii.large};

    ${SelectedBorderStyle};

    aspect-ratio: 3/2;

    /* Fallback for browsers not supporting aspect-ratio, mainly Safari 14 or below */
    @supports not (aspect-ratio: auto) {
      padding-top: 66.66%;
      height: 0;
    }

    ${breakpoint('sm')`
      margin-bottom: 4px;
    `};
  `
)

export const ItemImage = styled(ImageUnstyled)`
  /* Using absolute positioning is the only way I found to expand this
  container to the parent that is using aspect-ratio */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  padding: 8px;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: scale-down;
  }
`

export const ItemName = styled(Text.Div).attrs({
  textStyle: 'copy.secondary.standard.D',
})`
  max-width: 100%;
  text-align: left;
`

export const Group = styled.div`
  margin-top: 24px;

  ${breakpoint('sm')`
    margin-top: 0;
  `};
`

export const ItemSecondary = styled(Text.Span).attrs({
  textStyle: 'copy.tertiary.standard.C',
})`
  display: block;
  line-height: 1.2;
  overflow-wrap: break-word;
  text-align: left;
  ${truncateStyles};
`
