import { useCallback } from 'react'
import { RefetchQueriesFunction } from 'react-apollo'
import { useSetNotificationSubscriptionMutation } from '@sketch/gql-types'

// This is a valid use case to narrow down Component type
// eslint-disable-next-line no-restricted-imports
import { NotificationSubscriptionState } from '@sketch/gql-types/expansive'

interface BaseCallbackArguments {
  state: NotificationSubscriptionState
}

interface ShareCallbackArguments extends BaseCallbackArguments {
  shareIdentifier: string
}

interface ArtboardCallbackArguments extends BaseCallbackArguments {
  shareIdentifier: string
  permanentArtboardId: string

  // Needed to make the mutation optimistic response
  permanentArtboardShortId: string
  documentVersionShortId: string
}

interface AnnotationCallbackArguments extends BaseCallbackArguments {
  annotationIdentifier: string
}

type CallbackArguments =
  | ShareCallbackArguments
  | ArtboardCallbackArguments
  | AnnotationCallbackArguments

interface UseDocumentNotificationSubscriptionProps {
  onCompleted?: () => void
}

const useDocumentNotificationSubscription = (
  props?: UseDocumentNotificationSubscriptionProps
) => {
  const [changeNotification] = useSetNotificationSubscriptionMutation({
    onError: 'show-toast',
    onCompleted: props?.onCompleted,
  })

  return useCallback(
    (variables: CallbackArguments, refetchQueries?: RefetchQueriesFunction) => {
      changeNotification({
        variables,
        refetchQueries,
        optimisticResponse: optimisticVariables => {
          if (!('state' in optimisticVariables)) {
            throw new Error('Oops')
          }

          const {
            shareIdentifier,
            state,
            annotationIdentifier,
            permanentArtboardId,
          } = optimisticVariables

          return {
            __typename: 'RootMutationType',
            setNotificationSubscription: {
              __typename: 'SetNotificationSubscriptionResponse',

              share:
                shareIdentifier && !permanentArtboardId
                  ? {
                      __typename: 'Share',
                      identifier: shareIdentifier!,
                      subscriptionStatus: state,
                    }
                  : null,

              artboard:
                shareIdentifier &&
                permanentArtboardId &&
                'documentVersionShortId' in variables
                  ? {
                      __typename: 'Artboard',
                      documentVersionShortId: variables.documentVersionShortId,
                      subscriptionStatus: state,
                      permanentArtboardShortId:
                        variables.permanentArtboardShortId,
                    }
                  : null,

              annotation: annotationIdentifier
                ? {
                    __typename: 'Annotation',
                    identifier: annotationIdentifier,
                    subscriptionStatus: state,
                  }
                : null,
            },
          }
        },
      })
    },
    [changeNotification]
  )
}

export default useDocumentNotificationSubscription
