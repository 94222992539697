import React from 'react'

import { Button, Modal, useModalContext } from '@sketch/components'

import { Title, Description } from '../RegenerateRecoveryCodesModal.styles'

interface FirstStepProps {
  onNextStep: (goToStep?: number) => void
}

/**
 * Renders the first step for the Regenerate Recovery Codes modal
 */
export const FirstStep: React.FC<FirstStepProps> = ({ onNextStep }) => {
  const { hideModal } = useModalContext()

  return (
    <>
      <Modal.Body>
        <Title>Generate New Recovery Codes?</Title>
        <Description>
          If you generate new codes the old ones will become invalid.
          <br /> Store your new codes somewhere safe!
        </Description>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal} size="40">
          Cancel
        </Button>
        <Button variant="primary" onClick={() => onNextStep()} size="40">
          Generate New Codes
        </Button>
      </Modal.Footer>
    </>
  )
}
