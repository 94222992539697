import React from 'react'

import { LoadingPlaceholder, Modal, Flex, Banner } from '@sketch/components'
import {
  SummaryError,
  SummaryHeader,
  SummaryLine,
  SummaryPaymentMethod,
  SummaryTotalDescribed,
  SummaryTotalLine,
  SummaryDiscountLine,
  SummaryProrated,
} from '../../components/Summary'

import { DiscountWrapper } from './ChangeBillingPlanModal.styles'

import {
  BillingPlanFragment,
  BillingSeatsInfoFragment,
  PaymentDetailsFragment,
  useSubscriptionUpdateBillingSimulationQuery,
  useSubscriptionUpdateBillingSimulationLazyQuery,
} from '@sketch/gql-types'
import Discount from '../../components/Discount'

import {
  getDiscountError,
  getAppliedDiscount,
  getPaymentTypeByProratedAmounts,
} from '../../utils'

interface MonthlyToYearlyContentProps {
  selectedPlan: BillingPlanFragment
  customerId: string
  seats: BillingSeatsInfoFragment
  paymentDetails?: PaymentDetailsFragment
  discountCode: string | null
  onValidDiscount: React.Dispatch<React.SetStateAction<string | null>>
  onClearDiscountCode: () => void
}

export const MonthlyToYearlyContent: React.FC<MonthlyToYearlyContentProps> = ({
  selectedPlan,
  customerId,
  seats,
  paymentDetails,
  discountCode,
  onValidDiscount,
  onClearDiscountCode,
}) => {
  const {
    data,
    loading,
    refetch,
    error,
  } = useSubscriptionUpdateBillingSimulationQuery({
    variables: {
      customerId,
      planId: selectedPlan.id,
      seats: seats.currentSeatsTotal,
      promotionCode: discountCode,
    },
  })

  const [
    getSubscriptionUpdateBillingSimulation,
    summaryPreviewWithDiscount,
  ] = useSubscriptionUpdateBillingSimulationLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: data => {
      // This query acts as a validation for the discount code
      if (
        !data?.subscriptionUpdateBillingSimulation?.prorated?.couponInfo
          ?.errorCode
      ) {
        const promotionCode =
          data?.subscriptionUpdateBillingSimulation?.prorated?.couponInfo
            ?.promotionCode
        promotionCode && onValidDiscount(promotionCode)
      }
    },
  })

  if (loading) {
    return (
      <Flex height={248} w="100%" justifyContent="center" alignItems="center">
        <LoadingPlaceholder size="64px" />
      </Flex>
    )
  }

  const { prorated, totalAmount } =
    data?.subscriptionUpdateBillingSimulation || {}

  // We retrieve the couponInfo from the prorated object because the couponInfo from the parent object comes as null
  const previewCouponInfo =
    summaryPreviewWithDiscount.data?.subscriptionUpdateBillingSimulation
      ?.prorated?.couponInfo || undefined
  const couponInfo = prorated?.couponInfo

  const paymentType = getPaymentTypeByProratedAmounts(
    prorated?.chargeAmount,
    prorated?.creditsAmount
  )
  const appliedDiscount = getAppliedDiscount(couponInfo ?? undefined)
  const discountError = getDiscountError(previewCouponInfo)

  const handleApplyDiscount = (discountCode: string) => {
    getSubscriptionUpdateBillingSimulation({
      variables: {
        customerId,
        planId: selectedPlan.id,
        seats: seats.currentSeatsTotal,
        promotionCode: discountCode,
      },
    })
  }

  return (
    <Modal.Body>
      {error ? (
        <SummaryError refetch={() => refetch()} />
      ) : (
        <>
          <DiscountWrapper>
            <Discount
              onApplyDiscountCode={handleApplyDiscount}
              onClearDiscountCode={onClearDiscountCode}
              appliedDiscountCodes={discountCode ? [discountCode] : []}
              error={discountError || undefined}
              isDisabled={loading || summaryPreviewWithDiscount.loading}
              isLoading={summaryPreviewWithDiscount.loading}
            />
          </DiscountWrapper>
          <SummaryHeader
            plan={selectedPlan}
            paymentMethod={
              // Wait for the BE response to show the proper payment method
              prorated && (
                <SummaryPaymentMethod
                  chargeMethod={paymentDetails!}
                  paymentType={paymentType}
                />
              )
            }
          />
          <SummaryLine
            description={`${seats.currentSeatsTotal} Seats`}
            value={totalAmount}
            loading={loading}
          />
          {appliedDiscount && (
            <SummaryDiscountLine
              appliedDiscount={appliedDiscount}
              isAlreadyApplied={!!couponInfo && !discountCode}
            />
          )}
          {prorated && (
            <SummaryProrated
              daysElapsedInCycle={prorated.daysRemainingInCycle}
              refundAmountForNewSeats={prorated.discountForUnusedDays}
            />
          )}
          <SummaryTotalLine
            description="Total due"
            loading={loading}
            value={prorated?.amount || 0}
          />
          <SummaryTotalDescribed
            charge={prorated?.chargeAmount}
            credit={prorated?.creditsAmount}
          />
          {!!seats.scheduledSeatsTotal && (
            <Banner type="warning" showIcon={false}>
              <strong>Your scheduled seat changes will be cancelled</strong>
              <br />
              Please review your scheduled seat changes and take action prior to
              changing your plan if needed. Adding seats will remain an option
              after the plan change by using the <strong>Add Seats</strong>{' '}
              option available in the <strong>People</strong> tab.
            </Banner>
          )}
        </>
      )}
    </Modal.Body>
  )
}
