import React, { useMemo, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router'

import {
  usePRFileArtboards,
  usePan,
  useRootNodeAbsoluteExtent,
  useSetPan,
} from '@sketch-hq/sketch-web-renderer'

import { AnnotationDotFragment } from '@sketch/gql-types'

import { AnnotationOverlay } from '../../../annotations/components'
import { usePlaceDraftAnnotation } from '../../../annotations/hooks'
import {
  ArtboardContainerBounds,
  ReferenceBounds,
} from '../../../annotations/types'

interface CenteredDotLocationState {
  isFromCommentTab?: boolean
}

interface AnnotationsOverlayProps {
  parentWrapper: React.RefObject<HTMLElement>
  activeAnnotationIdentifier?: string
  getArtboardContainerBounds?: (
    artboardCoordinatesByUuid: Record<string, ReferenceBounds>
  ) => ArtboardContainerBounds | undefined
}

const DEFAULT_PAN = { x: 0, y: 0 }

const throttleUpdatePopover = () => {
  window.requestAnimationFrame(() => {
    /**
     * This is a work-around to force popper to update
     * its position.
     *
     * Because popper has listeners for scroll and window
     * resize, we send this fake event when the camera is moving
     *
     * The other way would be using refs and would make the
     * logic complex
     */
    window?.dispatchEvent(new CustomEvent('scroll'))
  })
}

export const AnnotationsOverlay = (props: AnnotationsOverlayProps) => {
  const {
    activeAnnotationIdentifier,
    getArtboardContainerBounds: externalGetArtboardContainerBounds,
    ...otherProps
  } = props

  const location = useLocation<CenteredDotLocationState | undefined>()
  const artboards = usePRFileArtboards()
  const setPan = useSetPan()

  const [isPlacingDraftAnnotation] = usePlaceDraftAnnotation() || []
  const rootExtent = useRootNodeAbsoluteExtent()

  const artboardCoordinatesByUuid = useMemo(() => {
    const coordinatesByUuid: Record<string, ReferenceBounds> = {}

    artboards?.forEach(artboard => {
      coordinatesByUuid[artboard.objectId] = artboard.bounds
    })

    return coordinatesByUuid
  }, [artboards])

  const getPrefixCoordinates = useCallback(
    (annotation: AnnotationDotFragment) => {
      if (annotation.currentSubject.__typename === 'Artboard') {
        return artboardCoordinatesByUuid[annotation.currentSubject.uuid]
      }
    },
    [artboardCoordinatesByUuid]
  )

  const getArtboardContainerBounds = useCallback(
    () => externalGetArtboardContainerBounds?.(artboardCoordinatesByUuid),
    [artboardCoordinatesByUuid, externalGetArtboardContainerBounds]
  )

  /**
   * When the user is panning on the canvas he might reach any
   * of the borders, if possible the popover should move to the
   * opposite side until reaching the other edge.
   */
  const pan = usePan() || DEFAULT_PAN
  useEffect(() => {
    /**
     * We should only activate this when the camera is moving
     * because when zooming we already have the "DROPDOWN_MODIFIERS"
     * that should keep the popover close to the dot
     */
    if (!(activeAnnotationIdentifier || isPlacingDraftAnnotation)) {
      return
    }

    throttleUpdatePopover()
  }, [pan.x, pan.y, activeAnnotationIdentifier, isPlacingDraftAnnotation])

  const handleActiveAnnotation = useCallback(
    (annotation: AnnotationDotFragment) => {
      const { coordinates } = annotation

      if (!coordinates || !location.state?.isFromCommentTab) {
        return
      }

      const prefixBounds = getPrefixCoordinates(annotation)

      const adjustmentX = rootExtent.x - (prefixBounds?.x || 0)
      const adjustmentY = rootExtent.y - (prefixBounds?.y || 0)

      const panCoordinates = {
        x: -(coordinates.x - adjustmentX),
        y: -(coordinates.y - adjustmentY),
      }

      setPan({
        ...panCoordinates,
        centerPointInViewport: true,
      })
    },
    [getPrefixCoordinates, setPan, location, rootExtent]
  )

  return (
    <AnnotationOverlay
      referenceBounds={rootExtent}
      getPrefixCoordinates={getPrefixCoordinates}
      getArtboardBounds={getPrefixCoordinates}
      getArtboardContainerBounds={getArtboardContainerBounds}
      onActiveAnnotation={handleActiveAnnotation}
      {...otherProps}
    />
  )
}
