import React from 'react'
import { Formik, FormikProps } from 'formik'
import * as yup from 'yup'

import { Button, Form, Input } from '@sketch/components'
import { validEmail } from '@sketch/utils'
import {
  useUserRequestPasswordResetMutation,
  UserRequestPasswordResetMutation,
  UserRequestPasswordResetMutationVariables,
  UserErrorFragment,
  MutationOptionsWithoutRedirectedErrors,
} from '@sketch/gql-types'

const forgotPasswordSchema = yup.object().shape({
  email: validEmail().required('Email can’t be blank'),
})

interface Values {
  email: string
}

export interface ForgotPasswordFormExtendedFormikProps
  extends FormikProps<Values> {
  successful: boolean
  apiErrors: (UserErrorFragment | null)[]
}

export const Fields: React.FC<ForgotPasswordFormExtendedFormikProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => (
  <Form.Field
    name="email"
    label="Email"
    errorText={touched.email ? errors.email : undefined}
  >
    <Input
      name="email"
      type="email"
      placeholder="john@appleseed.com"
      value={values.email}
      onChange={handleChange}
      onBlur={handleBlur}
      autoFocus
    />
  </Form.Field>
)

export const Submit: React.FC<ForgotPasswordFormExtendedFormikProps> = ({
  isSubmitting,
  isValid,
  dirty,
}) => (
  <Form.Footer>
    <Button
      variant="primary"
      type="submit"
      fill
      size="40"
      disabled={isSubmitting || !(isValid && dirty)}
      loading={isSubmitting}
    >
      Reset Password
    </Button>
  </Form.Footer>
)

type MutationParameters = MutationOptionsWithoutRedirectedErrors<
  UserRequestPasswordResetMutation,
  UserRequestPasswordResetMutationVariables
>
type MutationHandlers = Pick<MutationParameters, 'onCompleted' | 'onError'>

const defaultFormFields = (
  formProps: ForgotPasswordFormExtendedFormikProps
) => (
  <>
    <Fields {...formProps} />
    <Submit {...formProps} />
  </>
)

export interface ForgotPasswordFormProps extends MutationHandlers {
  initialEmail?: string
  children?: (
    formikProps: ForgotPasswordFormExtendedFormikProps
  ) => React.ReactNode
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  children = defaultFormFields,
  initialEmail,
  onError,
  onCompleted,
}) => {
  const [
    resetPassword,
    { loading, data },
  ] = useUserRequestPasswordResetMutation({
    redirectErrors: false,
    onCompleted,
    onError,
  })

  const apiErrors = data?.userRequestPasswordReset?.errors || []
  const successful = !!data?.userRequestPasswordReset?.successful

  return (
    <Formik
      initialValues={{ email: initialEmail || '' }}
      onSubmit={async ({ email }) => {
        try {
          await resetPassword({ variables: { email } })
        } catch (e) {
          // The error will be handled by the mutation "onError"
        }
      }}
      validationSchema={forgotPasswordSchema}
      validateOnBlur={false}
    >
      {formikBag => (
        <Form>
          {children({
            ...formikBag,
            isSubmitting: loading,
            successful,
            apiErrors,
          })}
        </Form>
      )}
    </Formik>
  )
}
