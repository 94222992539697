import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "person-circle-64", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 64 64", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { transform: "translate(9.5 9.5)", fill: "none", fillRule: "evenodd" },
        React.createElement("circle", { stroke: "currentColor", strokeWidth: 3, cx: 22.5, cy: 22.5, r: 22.5 }),
        React.createElement("path", { d: "M22.5 29.265c7.499 0 13.901 3.573 16.431 8.606A22.438 22.438 0 0122.5 45a22.438 22.438 0 01-16.431-7.129c2.53-5.033 8.932-8.606 16.431-8.606zm0-18.941a6.765 6.765 0 110 13.529 6.765 6.765 0 010-13.53z", fillOpacity: 0.15, fill: "currentColor" }),
        React.createElement("path", { d: "M6.069 37.871c2.53-5.033 8.932-8.606 16.431-8.606 7.499 0 13.901 3.573 16.431 8.606M22.5 10.324a6.765 6.765 0 110 13.529 6.765 6.765 0 010-13.53z", stroke: "currentColor", strokeWidth: 3 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
