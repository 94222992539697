import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  padding: 8px 8px 8px 12px;
  height: 64px;
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.large};
`

export const Item = styled.div`
  & + & {
    margin-left: 12px;
  }

  &:nth-child(2) {
    overflow: hidden;
  }

  &:last-child {
    margin-left: auto;
  }
`
