import { castError } from '@sketch/utils'
import ApolloClient from 'apollo-client'
import {
  GetVersionHistoryDocument,
  GetVersionHistoryQuery,
  GetVersionHistoryQueryVariables,
} from '@sketch/gql-types'
import produce from 'immer'
import { ErrorHandler } from '@sketch/tracing'

export type ShareVersionHistory = NonNullable<GetVersionHistoryQuery['share']>

export interface UpdateVersionHistoryProps {
  client: ApolloClient<object>
  variables: GetVersionHistoryQueryVariables
  update: (data: ShareVersionHistory) => void
}
export const updateVersionHistory = ({
  client,
  variables,
  update,
}: UpdateVersionHistoryProps) => {
  try {
    const result = client.readQuery<
      GetVersionHistoryQuery,
      GetVersionHistoryQueryVariables
    >({
      query: GetVersionHistoryDocument,
      variables,
    })

    if (!result?.share?.versionHistory) {
      ErrorHandler.shouldNeverHappen(
        'share.versionHistory should be in the cache'
      )
      return
    }

    // it is necessary to return a completely different object
    // otherwise Apollo will not rerender listening queries
    const updated = produce(result, draft => {
      update(draft.share!)
    })

    client.writeQuery({
      data: updated,
      query: GetVersionHistoryDocument,
      variables,
    })
  } catch (e) {
    const err = castError(e)
    ErrorHandler.ignore(
      err,
      'there is nothing to update if readQuery or writeQuery throws an error'
    )
  }
}
