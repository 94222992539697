import React, { ChangeEvent, useCallback, useRef } from 'react'
import { Formik, FormikErrors, FormikHelpers, FormikTouched } from 'formik'
import * as yup from 'yup'
import { debounce } from 'lodash'

import { routes } from '@sketch/modules-common'

import { ReactComponent as Checkmark } from '@sketch/icons/check-20'

import {
  useProfileShortURL,
  CurrentStatus,
} from '../../hooks/useProfileShortURL'

import { Button, Input, Link, Text } from '@sketch/components'

import {
  Wrapper,
  StyledForm,
  StyledField,
  StyledLoadingPlaceholder,
  ErrorIcon,
  FormError,
} from './WorkspaceSettingsProfileURL.styles'

type FormActions = FormikHelpers<FormValues>
type IconPerStatus = Record<CurrentStatus, React.ReactElement | undefined>

interface WorkspaceSettingsProfileURLProps {
  profileIdentifier: string
  shortUrlName: string
}

interface FormValues {
  shortUrl: string
}

interface LabelProps {
  hasNewUrl: boolean
  currentShortUrl: string
}

const ICON_PER_STATUS: IconPerStatus = {
  LOADING: <StyledLoadingPlaceholder data-testid="loading-icon" size="16px" />,
  ERROR: <ErrorIcon data-testid="error-icon" width="20px" height="20px" />,
  UNAVAILABLE: (
    <ErrorIcon data-testid="error-icon" width="20px" height="20px" />
  ),
  SUCCESS: <Checkmark data-testid="success-icon" width="20px" height="20px" />,
  EMPTY: undefined, // When we want to hide / clear icons on the input
}

const DEBOUNCE_TIME = 1000
const PROFILE_PREFIX = `sketch.com/profile/`

// This pattern should only allow kebab case string.
// It also cannot start or end with a dash and
// does not allow double dashses "invalid--short-url"
const PATTERN = /^(?:[a-z0-9]+(?:-[a-z0-9]+)*)$/gm
const URL_UNAVAILABLE = `This URL isn’t available.`

const validationSchema = yup.object().shape({
  shortUrl: yup
    .string()
    .min(2, 'Enter 2 or more characters')
    .max(255, 'Enter up to 255 characters')
    .matches(PATTERN, 'Use only letters, numbers, or dashes')
    .trim(),
})

const getRightIcon = (status: CurrentStatus) => ICON_PER_STATUS[status]

const validateNewShortUrl = (newShortUrl: string) => {
  const isValid = validationSchema.isValidSync({ shortUrl: newShortUrl })

  return isValid
}

const getErrorMessage = ({
  touched,
  errors,
  isUrlAvailable,
}: {
  touched: FormikTouched<FormValues>
  errors: FormikErrors<FormValues>
  isUrlAvailable: boolean
}) => {
  if (touched.shortUrl) {
    return !isUrlAvailable ? URL_UNAVAILABLE : errors.shortUrl
  }

  return undefined
}

const Label: React.FC<LabelProps> = ({ hasNewUrl, currentShortUrl }) => {
  if (hasNewUrl) {
    return (
      <Text textStyle="copy.tertiary.standard.E">
        Your current URL is{' '}
        <Link
          href={routes.WORKSPACE_PROFILE.create({
            shortUrlName: currentShortUrl,
          })}
          external
        >
          {PROFILE_PREFIX}
          {currentShortUrl}
        </Link>
      </Text>
    )
  }

  return (
    <Text textStyle="copy.tertiary.standard.E">
      This is your public profile’s URL.{' '}
      <Link
        href={routes.WORKSPACE_PROFILE.create({
          shortUrlName: currentShortUrl,
        })}
        external
      >
        View Profile
      </Link>
    </Text>
  )
}

const WorkspaceSettingsProfileURL: React.FC<WorkspaceSettingsProfileURLProps> = props => {
  const { profileIdentifier, shortUrlName } = props
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    // Mutations
    checkWorkspaceProfileShortUrl,
    updateWorkspaceProfileShortUrl,
    // States
    currentShortUrl,
    setHasNewUrl,
    currentStatus,
    setCurrentStatus,
    // Booleans
    isButtonDisabled,
    isSavingShortUrl,
    isUrlAvailable,
    hasNewUrl,
  } = useProfileShortURL(shortUrlName)

  const handleSubmit = (formData: FormValues, actions: FormActions) => {
    updateWorkspaceProfileShortUrl({
      variables: {
        input: {
          identifier: profileIdentifier,
          shortUrlName: formData.shortUrl,
        },
      },
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleGetURLAvailability = useCallback(
    debounce(async (newShortUrl: string) => {
      checkWorkspaceProfileShortUrl({
        variables: {
          shortUrlName: newShortUrl,
        },
      })
    }, DEBOUNCE_TIME),
    [checkWorkspaceProfileShortUrl]
  )

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    {
      setFieldTouched,
      setFieldValue,
    }: Pick<FormActions, 'setFieldValue' | 'setFieldTouched'>
  ) => {
    const cursorStart = event.target.selectionStart
    const cursorEnd = event.target.selectionEnd

    // Clear any debounce timers that might be running
    handleGetURLAvailability.cancel()

    // Replace spaces with dashes and convert to lowercase
    const newURL = event.target.value.replace(' ', '-').toLowerCase()

    // Reveals a new label
    setHasNewUrl(newURL !== currentShortUrl)

    setFieldTouched('shortUrl', true)
    setFieldValue('shortUrl', newURL)

    if (newURL.length === 0 || newURL === currentShortUrl) {
      // Hide icons if input is empty
      setCurrentStatus('EMPTY')
      return
    }

    if (!validateNewShortUrl(newURL)) {
      setCurrentStatus('ERROR')
      return
    }

    if (currentStatus !== 'LOADING') {
      setCurrentStatus('LOADING')
    }

    // Set cursor position to avoid the cursor
    // from jumping to the end of the input
    if (inputRef.current) {
      const input = inputRef.current

      setTimeout(() => {
        input.setSelectionRange(cursorStart, cursorEnd)
      })
    }

    handleGetURLAvailability(newURL)
  }

  return (
    <Wrapper>
      <Formik
        initialValues={{ shortUrl: currentShortUrl }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          dirty,
          setFieldTouched,
          isValid,
        }) => {
          const isEmpty = dirty && values.shortUrl.length === 0
          const errorMessage = getErrorMessage({
            touched,
            errors,
            isUrlAvailable,
          })

          return (
            <>
              <StyledForm>
                <StyledField
                  name="shortUrl"
                  hasError={!!errorMessage}
                  label={
                    <Label
                      hasNewUrl={hasNewUrl}
                      currentShortUrl={currentShortUrl}
                    />
                  }
                >
                  <Input
                    ref={inputRef}
                    type="text"
                    name="shortUrl"
                    prefix={PROFILE_PREFIX}
                    value={values.shortUrl}
                    onChange={event =>
                      handleChange(event, { setFieldValue, setFieldTouched })
                    }
                    rightIcon={getRightIcon(currentStatus)}
                    autoComplete="off"
                  />
                </StyledField>
                <Button
                  type="submit"
                  disabled={isButtonDisabled || isEmpty || !isValid}
                  loading={isSavingShortUrl}
                >
                  Save
                </Button>
              </StyledForm>
              {errorMessage && <FormError>{errorMessage}</FormError>}
            </>
          )
        }}
      </Formik>
    </Wrapper>
  )
}

export default WorkspaceSettingsProfileURL
