import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
`

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.E};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  margin: 24px 0 0;
`

export const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.D};
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  margin-bottom: 18px; /* stylelint-disable-line scales/space */
  text-align: center;
`
