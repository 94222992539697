import React from 'react'
import { LoadingPlaceholder } from '../LoadingPlaceholder'

import * as S from './PrototypeLoadingPlaceholder.styles'

type PrototypeLoadingPlaceholderProps = {
  progress?: number
  text?: string
}

/**
 * Loading placeholder displayed on top of the dark prototype background.
 * You can pass an optional % progress that will only be shown if defined
 * and more than 0.
 */
export function PrototypeLoadingPlaceholder({
  progress = 0,
  text = 'Loading',
}: PrototypeLoadingPlaceholderProps) {
  const percentageProgress = Math.floor(progress * 100)
  // Don't show percentage when progress is 0
  const loadingText = progress
    ? `${text} ${percentageProgress}%...`
    : `${text}...`
  return (
    <S.PrototypeLoadingPlaceholder>
      <LoadingPlaceholder size="64px" dark={true} />
      <div> {loadingText} </div>
    </S.PrototypeLoadingPlaceholder>
  )
}
