import React, { useState, forwardRef } from 'react'
import styled from 'styled-components'

import { Flex } from '../Box'
import { Input, InputProps } from '../Input'

import { ToggleShowPasswordButton } from './ToggleShowPasswordButton'

const StyledPasswordInput = styled(Input)`
  /*
    This overrides the right padding in order to have no text
    under the button to toggle the visibility.
  */
  padding-right: 48px !important; /* stylelint-disable-line declaration-no-important */
`

interface PasswordInputProps extends InputProps {}

/* eslint-disable-next-line react/display-name */
export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ ...props }: PasswordInputProps, ref) => {
    const [showPassword, setShowPassword] = useState(false)

    return (
      <Flex position="relative">
        <StyledPasswordInput
          type={showPassword ? 'text' : 'password'}
          id="password"
          name="password"
          ref={ref}
          {...props}
        />
        <ToggleShowPasswordButton
          showPassword={showPassword}
          onClick={() => setShowPassword(value => !value)}
          disabled={props.disabled}
        />
      </Flex>
    )
  }
)
