import { ColorFormat } from '../../../../../../types'
import { rgbTo } from '../../../components/Color/utils'
import { Tint } from '../../../../../../../inspector'

const copy = (tints: Tint[], colorFormat?: ColorFormat) => {
  return tints
    .map(tint =>
      tint.isEnabled && tint.color
        ? `Tint\nColor: ${rgbTo(colorFormat, tint.color)}`.trim()
        : null
    )
    .filter(f => f !== null)
    .join('\n\n')
}

export default copy
