import { useState, useEffect, useRef } from 'react'

const getImage = (src: string) => {
  const image = new Image()
  image.src = src

  return image
}

export function useImage(
  src: string | undefined,
  onError?: (msg: string | Event) => void
): boolean {
  const [isLoaded, setLoaded] = useState(() => {
    if (!src) return false
    return getImage(src).complete
  })

  const onErrorRef = useRef(onError)

  useEffect(() => {
    onErrorRef.current = onError
  }, [onError])

  useEffect(() => {
    if (!src) {
      return
    }
    function handleOnLoad() {
      setLoaded(true)
    }
    function handleOnError(msg: string | Event) {
      if (onErrorRef.current) {
        onErrorRef.current(msg)
      }
      setLoaded(false)
    }

    const image = getImage(src)
    image.onload = handleOnLoad
    image.onerror = handleOnError
    image.src = src

    return () => {
      image.onload = null
      image.onerror = null
    }
  }, [src, onError])
  return isLoaded
}
