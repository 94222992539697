import styled from 'styled-components'
import { Button } from '@sketch/components'

export const AddButton = styled(Button)<{
  $toolbar: boolean
}>`
  padding: 4px 8px;
  height: 32px;
  border: none;
  box-shadow: none;

  && svg {
    color: currentColor;
    display: block;
    width: 24px;
    height: 24px;
  }
  ${({ theme, $toolbar }) =>
    $toolbar &&
    `background-color: transparent;
     color: ${theme.colors.foreground.secondary.D};

    &[disabled] {
      cursor: not-allowed;
      pointer-events: none;
      color: ${theme.colors.state.disabled};
    }
  `};
`

export const CancelButton = styled(Button)``
