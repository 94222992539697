import React from 'react'

import { IdentityProviderConfigFragment } from '@sketch/gql-types'
import { SsoSamlSetupForm } from '../SsoSamlSetupForm'

interface SsoSamlConfiguredViewProps {
  customerId: string
  identityProviderConfig: IdentityProviderConfigFragment
}

const SsoSamlConfigured: React.FC<SsoSamlConfiguredViewProps> = ({
  customerId,
  identityProviderConfig,
}) => {
  return (
    <>
      <SsoSamlSetupForm
        customerId={customerId}
        identityProviderConfig={identityProviderConfig}
        disabled
      />
    </>
  )
}

export default SsoSamlConfigured
