import React from 'react'
import isFunction from 'lodash.isfunction'

export type Children = React.ReactNode | ((state: boolean) => React.ReactNode)

export const renderConditionally = (children: Children, state: boolean) => {
  if (isFunction(children)) {
    return children(state) || null
  }

  return state ? children : null
}
