import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "templates-46", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 46 46", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { stroke: "currentColor", strokeWidth: 3, fill: "none", opacity: 0.35 },
        React.createElement("path", { d: "M44.5 7.92c0 1.454-.142 2.164-.49 2.815-.294.55-.726.98-1.275 1.274-.651.349-1.361.491-2.815.491h-1.84c-1.454 0-2.164-.142-2.815-.49a3.064 3.064 0 01-1.274-1.275c-.349-.651-.491-1.361-.491-2.815V6.08c0-1.454.142-2.164.49-2.815.294-.55.726-.98 1.275-1.274.651-.349 1.361-.491 2.815-.491h1.84c1.454 0 2.164.142 2.815.49.55.294.98.726 1.274 1.275.349.651.491 1.361.491 2.815z" }),
        React.createElement("path", { d: "M28.5 7.92c0 1.454-.142 2.164-.49 2.815-.294.55-.726.98-1.275 1.274-.651.349-1.361.491-2.815.491H6.08c-1.454 0-2.164-.142-2.815-.49a3.064 3.064 0 01-1.274-1.275C1.642 10.084 1.5 9.374 1.5 7.92V6.08c0-1.454.142-2.164.49-2.815.294-.55.726-.98 1.275-1.274.651-.349 1.361-.491 2.815-.491h17.84c1.454 0 2.164.142 2.815.49.55.294.98.726 1.274 1.275.349.651.491 1.361.491 2.815z", fillOpacity: 0.15, fill: "currentColor" }),
        React.createElement("path", { d: "M1.5 22.08c0-1.454.142-2.164.49-2.815.294-.55.726-.98 1.275-1.274.651-.349 1.361-.491 2.815-.491h1.84c1.454 0 2.164.142 2.815.49.55.294.98.726 1.274 1.275.349.651.491 1.361.491 2.815v1.84c0 1.454-.142 2.164-.49 2.815-.294.55-.726.98-1.275 1.274-.651.349-1.361.491-2.815.491H6.08c-1.454 0-2.164-.142-2.815-.49a3.064 3.064 0 01-1.274-1.275c-.349-.651-.491-1.361-.491-2.815z" }),
        React.createElement("path", { d: "M17.5 22.08c0-1.454.142-2.164.49-2.815.294-.55.726-.98 1.275-1.274.651-.349 1.361-.491 2.815-.491h17.84c1.454 0 2.164.142 2.815.49.55.294.98.726 1.274 1.275.349.651.491 1.361.491 2.815v1.84c0 1.454-.142 2.164-.49 2.815-.294.55-.726.98-1.275 1.274-.651.349-1.361.491-2.815.491H22.08c-1.454 0-2.164-.142-2.815-.49a3.064 3.064 0 01-1.274-1.275c-.349-.651-.491-1.361-.491-2.815z", fillOpacity: 0.15, fill: "currentColor" }),
        React.createElement("path", { d: "M1.5 38.08c0-1.454.142-2.164.49-2.815.294-.55.726-.98 1.275-1.274.651-.349 1.361-.491 2.815-.491h9.84c1.454 0 2.164.142 2.815.49.55.294.98.726 1.274 1.275.349.651.491 1.361.491 2.815v1.84c0 1.454-.142 2.164-.49 2.815-.294.55-.726.98-1.275 1.274-.651.349-1.361.491-2.815.491H6.08c-1.454 0-2.164-.142-2.815-.49a3.064 3.064 0 01-1.274-1.275c-.349-.651-.491-1.361-.491-2.815z" }),
        React.createElement("path", { d: "M25.5 39.92v-1.84c0-1.454.142-2.164.49-2.815.294-.55.726-.98 1.275-1.274.651-.349 1.361-.491 2.815-.491h9.84c1.454 0 2.164.142 2.815.49.55.294.98.726 1.274 1.275.349.651.491 1.361.491 2.815v1.84c0 1.454-.142 2.164-.49 2.815-.294.55-.726.98-1.275 1.274-.651.349-1.361.491-2.815.491h-9.84c-1.454 0-2.164-.142-2.815-.49a3.064 3.064 0 01-1.274-1.275c-.349-.651-.491-1.361-.491-2.815z", fillOpacity: 0.15, fill: "currentColor" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
