import styled from 'styled-components'
import { Text } from '../Box'

export const Wrapper = styled.aside`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  padding: 24px;
  text-align: center;
`

export const Icon = styled.svg`
  width: 64px;
  height: 64px;

  /* This color should be "Light/colors.state.disabled" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.3;
`

export const Title = styled(Text).attrs({
  as: 'h1',
  textStyle: 'copy.primary.standard.E',
  $reset: true,
})`
  margin: 0;

  ${Icon} + & {
    margin: 9px 0 0; /* stylelint-disable-line scales/space */
  }
`

export const Description = styled(Text).attrs({
  textStyle: 'copy.quaternary.standard.D',
})``

export const ActionWrapper = styled.div`
  margin-top: 16px;
`
