import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "editor-list-bullet-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, strokeLinecap: "round", d: "M5.75 12.75h8.5" }),
        React.createElement("circle", { fill: "currentColor", cx: 2.5, cy: 12.75, r: 1.25 }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, strokeLinecap: "round", d: "M5.75 7.75h8.5" }),
        React.createElement("circle", { fill: "currentColor", cx: 2.5, cy: 7.75, r: 1.25 }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, strokeLinecap: "round", d: "M5.75 2.75h8.5" }),
        React.createElement("circle", { fill: "currentColor", cx: 2.5, cy: 2.75, r: 1.25 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
