import styled, { css } from 'styled-components'
import { IconButton } from './IconButton'

const shadowStyles = css`
  box-shadow: ${({ theme }) => theme.shadows.boxShadow.subtle};
  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.boxShadow.raised};
  }
  &:active {
    box-shadow: ${({ theme }) => theme.shadows.boxShadow.lifted};
  }
`

const transformStyles = css`
  transition: all ${({ theme }) => theme.transitions.duration[1]};
  /* Prevents Chrome flicker on modal - https://github.com/sketch-hq/Cloud/issues/3723 */
  backface-visibility: hidden;
  &:hover {
    transform: translateY(-1px);
  }
  &:active {
    transform: translateY(0);
  }
`

export const CircleButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.background.secondary.A};
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  padding: 16px;
  border-radius: 50%;
  ${shadowStyles};
  ${transformStyles};
`
