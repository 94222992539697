import _set from 'lodash.set'
import { Path, ValueAtPath } from './path.types'

export function set<T extends object, P extends Path<T>>(
  obj: T,
  path: P,
  value: ValueAtPath<T, P>
): T {
  return _set(obj, path, value)
}
