import React from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import {
  RouteParams,
  routes,
  Footer,
  EmptyHeader,
} from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'
import { useAcceptProjectAccessRequestMutation } from '@sketch/gql-types'

/* Components */
import { LoadingState, DoMutation, PageLayout } from '@sketch/components'

interface AcceptProjectAccessRequestViewProps
  extends RouteComponentProps<RouteParams<'PROJECT_ACCEPT_ACCESS_REQUEST'>> {}

export const AcceptProjectAccessRequestView = ({
  match,
}: AcceptProjectAccessRequestViewProps) => {
  const history = useHistory()
  const { showToast } = useToast()
  const { projectMembershipIdentifier } = match.params

  const [acceptProjectAccessRequest] = useAcceptProjectAccessRequestMutation({
    variables: { membershipIdentifier: projectMembershipIdentifier },
    onError: () => {
      showToast(
        'Something went wrong with accepting this access request.',
        'negative'
      )

      history.push({ pathname: routes.ENTRY.create({}) })
    },
    onCompleted: data => {
      showToast(`Request Accepted`)

      const { project, projectMembership } = data.acceptProjectAccessRequest
      history.push(
        routes.WORKSPACE_PROJECT.create({
          workspaceId:
            projectMembership.workspaceMembership.workspace.identifier,
          projectId: project.identifier,
        })
      )
    },
  })

  return (
    <PageLayout header={<EmptyHeader />} footer={<Footer />}>
      <DoMutation mutate={acceptProjectAccessRequest}>
        <LoadingState />
      </DoMutation>
    </PageLayout>
  )
}
