import React, { useEffect, useRef } from 'react'

import { Wrapper } from './AnnotationPopoverWrapper.styles'

const EVENTS_STOP_PROPAGATION = [
  'mousedown',
  'touchstart',
  'touchend',
  'wheel',
  'keypress',
  'contextmenu',
]

const stopPropagation = (event: React.SyntheticEvent | Event) => {
  event.stopPropagation()
}

const AnnotationPopoverWrapper: React.FC = ({ children }) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  /**
   * We use this effect to prevent the HTML mousedown events
   * to stop the propagation. This influences the CommentInput
   */
  useEffect(() => {
    const element = wrapperRef.current

    EVENTS_STOP_PROPAGATION.forEach(event => {
      element?.addEventListener(event, stopPropagation)
    })

    return () => {
      EVENTS_STOP_PROPAGATION.forEach(event => {
        element?.removeEventListener(event, stopPropagation)
      })
    }
  }, [])

  /**
   * The Canvas HTML element is listening for this events, so we are stopping
   * them from propagating so the user can scroll and click normally inside the
   * popover.
   */
  return (
    <Wrapper
      ref={wrapperRef}
      onMouseDown={stopPropagation}
      onPointerDown={stopPropagation}
      onMouseUp={stopPropagation}
      onTouchStart={stopPropagation}
      onTouchEnd={stopPropagation}
      onClick={stopPropagation}
    >
      {children}
    </Wrapper>
  )
}

export default AnnotationPopoverWrapper
