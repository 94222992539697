import { useEffect, useRef, useState } from 'react'

export type Listener<T> = (state: T) => void

export class Observable<T> {
  private _state: T
  private listeners: Array<Listener<T>>

  constructor(initialState: T) {
    this._state = initialState
    this.listeners = []
  }

  subscribe = (listener: Listener<T>): (() => void) => {
    this.listeners.push(listener)
    return () => {
      this.listeners.splice(this.listeners.indexOf(listener), 1)
    }
  }

  setState = (newState: T): void => {
    this._state = newState
    this.listeners.forEach(listener => listener(this._state))
  }

  state = (): T => {
    return this._state
  }
}

export const useObservable = <T>(store: Observable<T>): T => {
  const [state, setState] = useState<T>(store.state())

  const stateCheck = useRef(state)
  stateCheck.current = state

  useEffect(() => {
    const unsubscribe = store.subscribe(setState)

    // working on LayoutPortals it was noticed that there is chance that some extra code (e.g. from another component)
    // will be executed  after invoking useEffect hook itself, but before function passed to the useEffect is executed.
    //
    // And there was a case where state would change in this short gap. Therefore this extra check to make sure we are
    // dealing with the latest state
    const freshState = store.state()
    const capturedState = stateCheck.current

    if (freshState !== capturedState) {
      setState(freshState)
    }

    return () => {
      unsubscribe()
    }
  }, [store])

  return state
}
