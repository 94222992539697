import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "mac-app-download-48", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 48 48", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M15 8h2v32h-2a7 7 0 01-7-7V15a7 7 0 017-7z", fill: "#FAD266" }),
        React.createElement("path", { d: "M30.744 8c3.283 0 4.488.306 5.692.95a6.283 6.283 0 012.614 2.614c.644 1.204.95 2.409.95 5.692h0v13.488c0 3.283-.306 4.488-.95 5.692a6.283 6.283 0 01-2.614 2.614c-1.204.644-2.409.95-5.692.95h0-13.488c-3.283 0-4.488-.306-5.692-.95a6.283 6.283 0 01-2.614-2.614C8.306 35.232 8 34.027 8 30.744h0V17.256c0-3.283.306-4.488.95-5.692a6.283 6.283 0 012.614-2.614c1.204-.644 2.409-.95 5.692-.95h0z", stroke: "currentColor", strokeWidth: 2 }),
        React.createElement("g", { transform: "translate(31 26)", stroke: "currentColor", strokeWidth: 2 },
            React.createElement("circle", { fill: "#FAD266", cx: 8, cy: 8, r: 8 }),
            React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M8 10V5M5.5 8.5L8 11l2.5-2.5" })),
        React.createElement("path", { d: "M13.091 21.048l4.497-6.164a.461.461 0 01.32-.188l6.04-.693a.455.455 0 01.104 0l6.04.693a.461.461 0 01.32.188l4.497 6.164a.476.476 0 01-.02.585L24.177 34.417a.229.229 0 01-.352 0L13.11 21.633a.476.476 0 01-.019-.585zm12.214-5.716l4.616 4.721a.181.181 0 00.261 0 .19.19 0 00.055-.141l-.16-3.42a.093.093 0 01.089-.099.093.093 0 01.094.073l.875 3.936a.373.373 0 00.283.285l2.166.475c.05.011.082.061.07.112a.093.093 0 01-.09.074h-2.16a.37.37 0 00-.323.192l-5.182 9.404a.138.138 0 01-.189.054.142.142 0 01-.058-.183l4.19-9.065a.284.284 0 00-.133-.375.274.274 0 00-.118-.027H18.409a.28.28 0 00-.277.282c0 .042.009.083.026.12l4.19 9.065a.142.142 0 01-.067.188.138.138 0 01-.18-.059L16.92 21.54a.37.37 0 00-.323-.192h-2.16a.093.093 0 01-.093-.094c0-.044.03-.083.073-.092l2.166-.475a.373.373 0 00.283-.285l.875-3.936a.093.093 0 01.11-.07c.044.01.074.05.072.096l-.159 3.42a.187.187 0 00.176.196.183.183 0 00.14-.055l4.616-4.721c.038-.04.1-.04.139 0a.101.101 0 01.007.132l-3.408 4.316a.238.238 0 00.036.33.229.229 0 00.145.052h8.772a.233.233 0 00.232-.235.238.238 0 00-.051-.147l-3.408-4.316a.1.1 0 01.015-.14.097.097 0 01.13.008z", fill: "currentColor" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
