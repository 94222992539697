import React, { FC } from 'react'
import { useLocalStorage } from 'react-use'
import { useHistory } from 'react-router-dom'

import { localStorageKeys } from '@sketch/constants'
import { routes } from '@sketch/modules-common'

import { ReactComponent as ProfileCard } from '@sketch/icons/profile-card-4-fill-wide'

import { ExplainPublicProfileModal } from '../../modals'
import { PopoverInfo } from './PopoverInfo'

import { useGetWorkspaceProfile, useIsProfileCreated } from '../../hooks'
import { useModalContext, SelectDropdownLinkItem } from '@sketch/components'

import {
  useCreateWorkspaceProfileMutation,
  GetWorkspaceProfileDocument,
  GetWorkspaceProfileQueryVariables,
  GetWorkspaceProfileIdentifierDocument,
  GetWorkspaceProfileIdentifierQueryVariables,
} from '@sketch/gql-types'

interface PublicProfileSidebarItemProps {
  workspaceId: string
  isAdmin: boolean
}

interface CreateLinkToProfileProps {
  isAdmin: boolean
  isProfileCreated: boolean
  workspaceId: string
  shortUrlName?: string
}

const createLinkToProfile = ({
  shortUrlName,
  workspaceId,
  isAdmin,
  isProfileCreated,
}: CreateLinkToProfileProps) => {
  // If profile is not created, link we'll be empty and the behavior will be
  // handled by the handleItemClick
  const profileLinkNonAdmins = isProfileCreated
    ? routes.WORKSPACE_PROFILE.create({
        shortUrlName: shortUrlName ?? '', // We always have a shortUrlName if the profile is created
      })
    : ' '

  return isAdmin
    ? routes.WORKSPACE_PROFILE_EDIT.create({ workspaceId })
    : profileLinkNonAdmins
}

export const PublicProfileSidebarItem: FC<PublicProfileSidebarItemProps> = ({
  workspaceId,
  isAdmin,
}) => {
  const history = useHistory()
  const { isProfileCreated } = useIsProfileCreated(workspaceId)
  const { showModal } = useModalContext()

  const { profile } = useGetWorkspaceProfile({
    variables: {
      workspaceIdentifier: workspaceId,
    },
    skip: !isProfileCreated,
  })

  // Once the public profile is created, we link to public profile
  // (edit or non-edit) depending if the user is admin/non-admin
  const linkToProfile = createLinkToProfile({
    workspaceId,
    shortUrlName: profile?.shortUrlName,
    isAdmin,
    isProfileCreated,
  })

  const [
    createWorkspaceProfile,
    { loading },
  ] = useCreateWorkspaceProfileMutation({
    variables: {
      workspaceIdentifier: workspaceId,
    },
    onCompleted: () => {
      history.push(linkToProfile)
    },
    onError: 'show-toast',
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GetWorkspaceProfileDocument,
        variables: {
          workspaceIdentifier: workspaceId,
        } as GetWorkspaceProfileQueryVariables,
      },
      {
        query: GetWorkspaceProfileIdentifierDocument,
        variables: {
          identifier: workspaceId,
        } as GetWorkspaceProfileIdentifierQueryVariables,
      },
    ],
  })

  const [
    publicProfilePopoverSeen,
    setPublicProfilePopoverSeen,
  ] = useLocalStorage(localStorageKeys.publicProfilePopoverSeen, false)

  const target = isProfileCreated && !isAdmin ? '_blank' : undefined

  const showPublicProfileModal = () => {
    showModal(ExplainPublicProfileModal, {
      isAdmin,
      loading,
      onConfirm: () => {
        // This only happens for admins
        createWorkspaceProfile()
      },
    })
  }

  const handleItemClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (!isProfileCreated) {
      event.preventDefault()
      showPublicProfileModal()
    }
    setPublicProfilePopoverSeen(true)
  }

  const text = (
    <>Public Profile {!isAdmin && isProfileCreated && <>&#x2197;</>}</>
  )

  return (
    <SelectDropdownLinkItem
      icon={{
        icon: ProfileCard,
        highlight: !publicProfilePopoverSeen,
      }}
      text={text}
      to={linkToProfile}
      target={target}
      showActionsOnHover
      onClick={handleItemClick}
      action={
        <PopoverInfo
          isProfileCreated={isProfileCreated}
          isAdmin={isAdmin}
          onConfirm={() => {
            if (!isProfileCreated) {
              showPublicProfileModal()
            }
          }}
        />
      }
    />
  )
}
