import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import {
  TruncateWithTooltip,
  truncateStyles,
  WorkspaceLogo,
  Button,
  Pill,
  TableComponents,
} from '@sketch/components'

import { breakpoint } from '@sketch/global-styles'

const { TableCell } = TableComponents

export const StyledWorkspaceAvatar = styled(WorkspaceLogo)<{
  $isExpired: boolean
}>(
  ({ $isExpired }) => css`
    opacity: ${$isExpired ? 0.25 : 1};
    width: 32px;
  `
)

export const WorkspaceNameWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint('sm')`
    margin-left: 12px;
  `}
`

export const WorkspaceNameRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const WorkspaceNameLink = styled(Link)`
  ${breakpoint('base', 'sm')`
    max-width: 160px;
  `}
`

interface WorkspaceNameBaseProps {
  $isExpired: boolean
  children: React.ReactNode
}

const WorkspaceNameBase = styled.span<WorkspaceNameBaseProps>(
  ({ theme, $isExpired }) => css`
    ${truncateStyles}

    font-size: ${theme.fontSizes.E};
    font-weight: ${theme.fontWeights.medium};
    color: ${$isExpired
      ? theme.colors.foreground.secondary.D
      : theme.colors.foreground.secondary.A};
  `
)

export const WorkspaceName = forwardRef<
  HTMLSpanElement,
  WorkspaceNameBaseProps
>(function WorkspaceName(props, ref) {
  return <WorkspaceNameBase ref={ref} {...props} />
})

export const Sso = styled.span(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.C};
    font-weight: ${theme.fontWeights.regular};
    color: ${theme.colors.foreground.secondary.D};

    margin-top: 1px; /* stylelint-disable-line scales/space */
    margin-left: 4px;
  `
)

export const WorkspaceStatus = styled.span(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.D};
    font-weight: ${theme.fontWeights.regular};
    color: ${theme.colors.foreground.secondary.D};
  `
)

export const WorkspaceRole = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;

    font-size: ${theme.fontSizes.D};
    font-weight: ${theme.fontWeights.regular};
    color: ${theme.colors.foreground.secondary.D};
  `
)

export const StyledPill = styled(Pill)`
  margin-left: 8px;
`

export const RightTableCell = styled(TableCell)`
  text-align: right;
`

export const LeaveButton = styled(Button).attrs({
  size: '32',
  variant: 'secondary-untinted',
})`
  box-shadow: unset;
`

export const WorkspaceListItemNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const WorkspaceInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-left: 12px;
`

export const WorkspaceNameWithTruncate = styled(TruncateWithTooltip)`
  max-width: 265px;
`

export const SignedOut = styled.span`
  opacity: 0.45;
`
