import React from 'react'
import { ColorFormat } from '../../../../../../types'
import copy from './copy'
import { AttributeList, Header, SubTitle, Color } from '../../../components'
import { Color as ColorType, Tint } from '../../../../../../../inspector'

export interface TintsProps {
  tints: Tint[]
  onColorFormatChange: (f: ColorFormat) => void
  colorFormat: ColorFormat
  originalValues?: Tint[]
}

const renderTint = (
  { isEnabled, color }: Tint,
  {
    onColorFormatChange,
    colorFormat,
  }: {
    onColorFormatChange: (f: ColorFormat) => void
    colorFormat: ColorFormat
  },
  i: number,
  originalValue?: Tint
) => {
  if (!isEnabled) {
    return null
  }

  return color ? (
    <AttributeList key={`Tint${i}`} data-testid="tint">
      <Color
        {...color}
        onColorFormatChange={onColorFormatChange}
        colorFormat={colorFormat}
        dirtyAttributes={
          originalValue?.color
            ? {
                originalValue: originalValue?.color as ColorType,
                originalProperty: 'Color',
              }
            : undefined
        }
      />
    </AttributeList>
  ) : null
}

// For now only one tint is allowed, but this component is prepared for multiple tints
export const Tints: React.FC<TintsProps> = ({
  tints,
  colorFormat,
  onColorFormatChange,
  originalValues,
}) => {
  const reversedTints = [...tints].reverse()
  const reversedOriginalValues = originalValues
    ? [...originalValues].reverse()
    : undefined

  const renderedTints = reversedTints
    .map((tint, index) =>
      renderTint(
        tint,
        { colorFormat, onColorFormatChange },
        index,
        reversedOriginalValues ? reversedOriginalValues[index] : undefined
      )
    )
    .filter(element => element !== null)

  const copySectionValue = copy(reversedTints, colorFormat)

  const dirtyHeaderAttributes =
    originalValues && originalValues[0] === null
      ? { originalValue: 'disabled', originalProperty: 'Tint value' }
      : undefined

  return renderedTints.length > 0 ? (
    <>
      <Header
        copyValue={copySectionValue}
        dirtyAttributes={dirtyHeaderAttributes}
      >
        <SubTitle>Tint</SubTitle>
      </Header>
      {renderedTints}
    </>
  ) : null
}
