import React from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'

import {
  Button,
  FormField,
  Input,
  Modal,
  ModalInjectedProps,
  Text,
} from '@sketch/components'
import { useToast } from '@sketch/toasts'

import {
  WorkspacePermissionGroupFragment,
  useUpdatePermissionGroupNameMutation,
} from '@sketch/gql-types'

interface RenamePermissionGroupModalProps extends ModalInjectedProps {
  permissionGroup: WorkspacePermissionGroupFragment
}

// Form Validation
export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .max(255, 'Enter up to 255 characters')
    .trim(),
})

const RenamePermissionGroupModal = (props: RenamePermissionGroupModalProps) => {
  const { permissionGroup, hideModal } = props
  const { showToast } = useToast()

  const [mutation, { loading }] = useUpdatePermissionGroupNameMutation({
    onError: 'show-toast',
    onCompleted: () => {
      showToast('Group name updated!', 'positive')
      hideModal()
    },
  })

  return (
    <Modal onCancel={props.hideModal}>
      <Modal.Header>Rename Group</Modal.Header>
      <Formik
        initialValues={{ name: permissionGroup.name }}
        validationSchema={validationSchema}
        onSubmit={values => {
          const { name } = validationSchema.cast(values)
          if (name === permissionGroup.name) {
            hideModal()
            return
          }

          mutation({
            variables: {
              input: { name, identifier: permissionGroup.identifier },
            },
          })
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <Text>Give your group a new name.</Text>
              <FormField
                name="name"
                errorText={touched.name ? errors.name : undefined}
                label="Group name"
              >
                <Input
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Give your group a name"
                  type="text"
                />
              </FormField>
            </Modal.Body>

            <Modal.Footer>
              <Button onClick={hideModal}>Cancel</Button>
              <Button
                variant="primary"
                type="submit"
                loading={loading}
                disabled={!isValid}
              >
                Rename
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default RenamePermissionGroupModal
