import styled from 'styled-components'

import { Link, Text, Form } from '@sketch/components'

export const Wrapper = styled.div`
  margin-top: 38px; /* stylelint-disable-line scales/space */
`

export const StyledForm = styled(Form)`
  margin-bottom: 32px;
`

export const Description = styled.p`
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: ${({ theme }) => theme.fontSizes.E};
`

export const StyledLink = styled(Link)`
  white-space: break-spaces;
`

export const SubTitle = styled(Text.H2).attrs({
  textStyle: 'copy.primary.standard.F',
})`
  margin-top: 24px;
  margin-bottom: 8px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`

export const Label = styled.span`
  margin-top: 24px;
  margin-bottom: 4px;
  font-size: ${({ theme }) => theme.fontSizes.E};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
`

export const FullNameWrapper = styled.div`
  display: flex;
`

export const FormField = styled(Form.Field)`
  flex: 1 1 100%;
  margin-bottom: 0;

  :not(:last-child) {
    margin-right: 16px;
  }
`

export const Columns = styled.div`
  display: flex;
`

export const Column = styled.div`
  align-self: center;
  flex: 1 1 100%;

  :last-child {
    /*
      Avoid the content to jump around when the
      "Edit..." button disappears / appears
    */
    flex: 0 0 100px;
    text-align: right;
  }

  ${Description} {
    margin: 0px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`
