import React from 'react'
import styled, { css } from 'styled-components'
import {
  ErrorMessageTitle,
  ErrorMessageBody,
  ErrorMessageContainer,
} from '@sketch/components'
import { ReactComponent as WarningTriangle } from '@sketch/icons/warning-triangle-64'

export const Title = styled(ErrorMessageTitle)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.C};
    color: ${theme.colors.foreground.secondary.B};
  `
)

const Body = styled(ErrorMessageBody)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.C};
    color: ${theme.colors.foreground.secondary.D};
  `
)

const IconContainer = styled.div`
  width: 28px;
  height: 28px;
  color: ${({ theme }) => theme.colors.state.disabled};
`

export const GroupsError: React.FC = () => {
  return (
    <ErrorMessageContainer>
      <IconContainer>
        <WarningTriangle />
      </IconContainer>
      <Title>Unable to load groups</Title>
      <Body>
        Something went wrong while loading the components&apos; groups. Refresh
        the page to try again.
      </Body>
    </ErrorMessageContainer>
  )
}
