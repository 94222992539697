import React, {
  useRef,
  useCallback,
  useLayoutEffect,
  useEffect,
  useImperativeHandle,
} from 'react'

import styled, { css } from 'styled-components'
import { useKey } from 'react-use'
import { debounce } from 'debounce'

import { breakpoint } from '@sketch/global-styles'
import {
  useFocusTrap,
  FOCUSABLE_ELEMENTS,
  keyWithoutModifier,
} from '@sketch/utils'
import { DisableBodyScroll } from '../DisableBodyScroll'

import { ModalHeader } from './ModalHeader'
import { ModalBody } from './ModalBody'

const BackdropStyle = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex[7]};

  top: 0;
  left: 0;

  padding: 80px 0 0; /* stylelint-disable-line scales/space */

  ${breakpoint('xs')`
    padding: 5vh 0; /* stylelint-disable-line scales/space */
  `}

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  background-color: ${({ theme }) => theme.colors.overlay};

  user-select: none;

  &.modal-enter {
    opacity: 0;
  }
  &.modal-enter-active {
    opacity: 1;
    transition: opacity ${({ theme }) => theme.transitions.duration[2]};
  }
  &.modal-exit {
    opacity: 1;
  }
  &.modal-exit-active {
    opacity: 0;
    transition: opacity ${({ theme }) => theme.transitions.duration[2]};
  }
`

const Container = styled.section(
  ({ theme }) => css`
    margin: auto 0 0;

    width: 100%;
    max-width: 512px;
    font-size: 0.8125rem;

    max-height: 100%;
    transform: translate3d(0, 0, 0);

    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: none;

    border-radius: ${theme.radii.xxxlarge} ${theme.radii.xxxlarge} 0 0;
    background-color: ${theme.colors.background.secondary.A};
    background-clip: padding-box;

    box-shadow: 0 8px 16px 0 ${theme.colors.shadow.outer},
      0px 0px 0px 1px ${theme.colors.border.A};

    ${breakpoint('xs')`
      border-radius: ${theme.radii.xxxlarge};
      margin: auto 0;
    `}

    .modal-enter & {
      opacity: 0;
      transform: translateY(10px);
    }

    .modal-enter-active & {
      opacity: 1;
      transform: translateY(0);
      transition: ${`
        opacity  ${theme.transitions.duration[1]},
        transform  ${theme.transitions.duration[2]} ${theme.transitions.timing.easeInOut}
    `};
    }

    .modal-exit & {
      opacity: 1;
      transform: translateY(0);
    }

    .modal-exit-active & {
      opacity: 0;
      transform: translateY(10px);
      transition: ${`
      opacity  ${theme.transitions.duration[2]},
      transform  ${theme.transitions.duration[1]} ${theme.transitions.timing.easeInOut}
    `};
    }

    & p {
      margin: 0;
      padding-bottom: 20px;
    }

    & p:last-child {
      padding-bottom: 0;
    }

    /* Ensure modals without a header have some spacing above the body */
    & > ${ModalBody} {
      &:first-child {
        margin-top: 20px;
      }
    }

    /* stylelint-disable selector-type-no-unknown */
    ${ModalBody},
    ${ModalBody} + ${ModalBody} {
      border-bottom: 1px solid ${theme.colors.border.A};
      padding-top: 20px;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        border-bottom: 0;
      }
    }

    /* stylelint-enable */
    user-select: text;
  `
)

interface BackdropProps {
  onClick?: () => void
}

const Backdrop: React.FC<BackdropProps> = ({ children, onClick }) => {
  const backdropRef = useRef<HTMLDivElement>(null)
  const hasMouseDown = useRef(false)

  /**
   * Unintended modal closing is happening because of event
   * bubbling to Backdrop, so we need to detect when a complete
   * click (mouse down and up) is happening within Backdrop.
   * The modal will only be closed when a previous mouseDown
   * event is triggered before click.
   *
   * Learn more: https://github.com/sketch-hq/cloud-frontend/pull/2183
   */
  const handleMouseDown = useCallback((event: any) => {
    if (event.target !== backdropRef.current) {
      return
    }

    hasMouseDown.current = true
  }, [])

  const handleClick = useCallback(
    (event: any) => {
      if (
        !onClick ||
        event.target !== backdropRef.current ||
        !hasMouseDown.current
      ) {
        return
      }

      onClick()
    },
    [onClick]
  )

  return (
    <BackdropStyle
      data-testid="modal-backdrop"
      ref={backdropRef}
      {...(onClick && { onClick: handleClick })}
      onMouseDown={handleMouseDown}
    >
      {children}
    </BackdropStyle>
  )
}

export interface ModalProps {
  title?: React.ReactNode
  onCancel: () => void
  cancelOnClickOutside?: boolean
  style?: React.CSSProperties
  onUpdateScroll?: (hasOverflow: boolean) => void
  /**
   * By default the first focusable element will be focused when the modal is opened.
   * This can trigger tooltips to open automatically, so you can disable this behaviour
   * through this props by setting it to false.
   */
  autoFocusOnOpen?: boolean
}

/**
 * Set of Modal primitives to build modals/dialogs. These primitives work
 * together with `ModalProvider` and `ModalRoot`, that handles the logic to
 * open and close modals.
 *
 * Most basic example:
 *
 * ```jsx
 * <Modal title="Modal tile">
 *  <Modal.Body>Body text</Modal.Body>
 *  <Modal.Footer>Footer</Modal.Footer>
 * </Modal>
 * ```
 *
 * Some considerations:
 *
 * - To open/hide modals you should use the hook `useModalContext`.
 * - You'll get some useful injected props in any modal: `isModalOpen` and `hideModal`.
 */
export const Modal = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ModalProps>
>(function Modal(
  {
    title,
    onCancel,
    style,
    children,
    onUpdateScroll,
    cancelOnClickOutside = true,
    autoFocusOnOpen = true,
    ...otherProps
  },
  ref
) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  useImperativeHandle(ref, () => containerRef.current!)
  useFocusTrap(containerRef)

  // Automatically focus on the first focusable element
  useEffect(() => {
    if (!autoFocusOnOpen) {
      return
    }
    const firstItem = containerRef?.current?.querySelector(
      FOCUSABLE_ELEMENTS
    ) as HTMLElement

    if (firstItem) {
      firstItem.focus()
    }
  }, [autoFocusOnOpen])

  useLayoutEffect(() => {
    // used to notify the parent component if the modal has overflow
    const updateBodyHeight = () => {
      const clientHeight = containerRef?.current?.clientHeight ?? 0
      const scrollHeight = containerRef?.current?.scrollHeight ?? 0

      onUpdateScroll && onUpdateScroll(clientHeight < scrollHeight)
    }

    updateBodyHeight()

    window.addEventListener('resize', () => debounce(updateBodyHeight, 250))

    return () => window.removeEventListener('resize', updateBodyHeight)
  }, [containerRef, onUpdateScroll])

  useKey(keyWithoutModifier('Escape'), () => {
    if (cancelOnClickOutside) {
      onCancel()
    }
  })

  return (
    <>
      <DisableBodyScroll />
      <Backdrop {...(cancelOnClickOutside && { onClick: onCancel })}>
        <Container
          role="dialog"
          style={style}
          {...otherProps}
          ref={containerRef}
        >
          {title && <ModalHeader>{title}</ModalHeader>}
          {children}
        </Container>
      </Backdrop>
    </>
  )
})
