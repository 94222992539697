import React from 'react'
import { Modal, Button, ModalInjectedProps, Text } from '@sketch/components'
import { StyledLinkButton } from './WorkspaceSettingsUpsell.styles'

import { SKETCH_WEBSITE } from '@sketch/env-config'

const WorkspaceSettingsBillingUpsellModal = ({
  hideModal,
}: ModalInjectedProps) => (
  <Modal
    title="Choose Payment Options that Suit You"
    onCancel={hideModal}
    cancelOnClickOutside
  >
    <Modal.Body>
      <Text pb={3} width="100%">
        Upgrade to a Business Plan for flexible payment methods or terms, single
        sign-on (SSO), unlimited Workspace storage, and more.
      </Text>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={hideModal}>
        Maybe Later
      </Button>
      <StyledLinkButton
        variant="primary"
        href={`${SKETCH_WEBSITE}/support/contact/`}
        external
      >
        Get in Touch
      </StyledLinkButton>
    </Modal.Footer>
  </Modal>
)

export { WorkspaceSettingsBillingUpsellModal }
