import React from 'react'
import styled from 'styled-components'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import { getIsForInAppPresentation } from '../../routes'

export interface DefaultFooterProps {
  className?: string
  extraContent?: React.ReactNode
}

const FooterWrapper = styled.footer`
  width: 100%;
  font-size: 0.8125rem;
  padding: 48px 16px 16px;
  transition: all
    ${({ theme }) =>
      `${theme.transitions.duration[2]} ${theme.transitions.timing.easeOut}`};

  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
  }

  li {
    margin: 8px 12px;
  }

  a,
  span {
    transition: color ${({ theme }) => theme.transitions.duration[1]};
    white-space: nowrap;
    text-decoration: none;

    color: ${({ theme }) => theme.colors.foreground.secondary.D};
  }

  li a:hover,
  li span:hover {
    cursor: pointer;
    color: inherit;
  }
`

export const Footer: React.FC<DefaultFooterProps> = ({
  className,
  extraContent,
}) => {
  const isForInAppPresentation = getIsForInAppPresentation()

  const target = isForInAppPresentation ? '_self' : '_blank'

  return (
    <FooterWrapper className={className}>
      <ul>
        <li>
          <a
            href={`${SKETCH_WEBSITE}/tos`}
            target={target}
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
        </li>
        <li>
          <a
            href={`${SKETCH_WEBSITE}/support/contact`}
            target={target}
            rel="noopener noreferrer"
          >
            Support
          </a>
        </li>
        {extraContent}
        <li>
          <a
            href={`${SKETCH_WEBSITE}/home`}
            target={target}
            rel="noopener noreferrer"
          >
            © {new Date().getFullYear()} Sketch B.V.
          </a>
        </li>
      </ul>
    </FooterWrapper>
  )
}
