import styled from 'styled-components'
import { ReactComponent as DividerVertical } from '@sketch/icons/divider-vertical-2'

export const VerticalDivider = styled(DividerVertical)`
  flex-shrink: 0;
  width: 2px;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`

export const NavbarItem = styled.div`
  margin: 0 8px;

  & + &,
  & + ${VerticalDivider} {
    margin-left: 0;
  }
`
