import ApolloClient from 'apollo-client'
import * as Sentry from '@sentry/browser'
import 'navigator.locks'

import { castError } from '@sketch/utils'

import {
  areAuthorizationIdsEqual,
  Authorization,
  getAllAuthorizations,
  addOrUpdateAuthorization,
} from '@sketch/modules-common'

import { createExpireDate } from '../utils'
import { ErrorHandler } from '@sketch/tracing'

import { getHeaders, getSignInEndpoint } from '../utils/authorization'

const headers = getHeaders()

export const formatCredentials = (responseBody: any) => {
  const { access_token, refresh_token, expires_in, token_type } = responseBody

  if (token_type === 'basic') {
    ErrorHandler.shouldNeverHappen('Unexpected basic token type')
  }

  return {
    __typename: 'OAuthCredentials' as const,
    authToken: access_token,
    refreshToken: refresh_token,
    expirationDateTime: createExpireDate(expires_in),
  }
}

export const checkIfTokenIsExpired = (expirationDateTime: string) =>
  // Compare to the current date + 5 min. So we can preemptialy refresh
  // the token 5 min before the actual expiration time.
  // 1000ms * 60 * 5 = 5min
  new Date(expirationDateTime) < new Date(Date.now() + 1000 * 60 * 5)

const generateRefreshLockKey = (authorization: Authorization) => {
  const key =
    authorization.type === 'personal' ? 'personal' : authorization.workspaceId

  return `refresh_token_${key}`
}

const fireRefreshAccessTokenMutation = async (
  client: ApolloClient<object>,
  authorization: Authorization
) => {
  const response = await fetch(getSignInEndpoint(), {
    method: 'post',
    body: JSON.stringify({
      access_token: authorization.fragment.authToken,
      refresh_token: authorization.fragment.refreshToken,
      grant_type: 'refresh_token',
    }),
    headers,
  })

  const responseJson = await response.json()
  const fragment = formatCredentials(responseJson)

  const newAuthorization: Authorization = {
    ...authorization,
    fragment,
  }

  addOrUpdateAuthorization(client.cache, newAuthorization)
}

export const refreshToken = async (
  client: ApolloClient<object>,
  authorization: Authorization
) => {
  try {
    const refreshKey = generateRefreshLockKey(authorization)

    // Use a WebLock to prevent multiple tabs to refresh the token
    // at the same time and end up in a logout due to an already
    // used refresh token
    await navigator.locks.request(refreshKey, async () => {
      const allAuthorizations = getAllAuthorizations()
      const currentAuthorization = allAuthorizations.find(auth =>
        areAuthorizationIdsEqual(auth, authorization)
      )

      // The WebLock API creates a queue with all requests, so if the authToken
      // changed, it means that another call already refreshed the token before,
      // so there's no need to do this again
      if (
        currentAuthorization?.fragment.authToken !==
        authorization?.fragment.authToken
      ) {
        return
      }

      await fireRefreshAccessTokenMutation(client, currentAuthorization)
    })

    Sentry.addBreadcrumb({
      category: 'Authentication',
      message: `Token "${refreshKey}" refreshed`,
      level: 'log',
    })

    return true
  } catch (e) {
    const error = castError(e)
    Sentry.addBreadcrumb({
      category: 'Authentication',
      message: `Refresh token request failed with error: ${error.message}`,
      level: 'error',
    })
    return false
  }
}
