import styled, { css } from 'styled-components'
import { breakpoint } from '@sketch/global-styles'
import { Pill } from '@sketch/components'

interface CardWrapperProps {
  isSelected: boolean
  isDisabled: boolean
}

export const CardWrapper = styled.div<CardWrapperProps>(
  ({ theme, isSelected, isDisabled }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 212px;
    padding: 24px;
    text-align: center;
    border-radius: 20px; /* stylelint-disable-line scales/radii */

    color: ${isSelected ? theme.colors.foreground.secondary.A : undefined};

    ${isSelected
      ? css`
          border: 2px solid ${theme.colors.sketch.D};
          background-color: ${theme.colors.background.secondary.A};
        `
      : css`
          border: 1px solid ${theme.colors.border.B};
          background-color: ${theme.colors.background.secondary.B};
        `}

    opacity: ${isDisabled ? '0.8' : '1'};
    transition: background-color ${theme.transitions.duration[2]}
      ${theme.transitions.timing.easeInOut};

    :not(:last-child) {
      ${breakpoint('base', 'sm')`
        margin-bottom:  24px;
        margin-right: 0px;
      `}

      margin-right: 24px;
    }

    hr {
      width: 100%;
      margin: 19.2px 0; /* stylelint-disable-line scales/space */
    }

    ${breakpoint('base', 'sm')`
      width: 162px;
      height: 166px;
    `}
  `
)

export const FakeRadioButton = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 999px;
  background-color: white;
  box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.03);
  cursor: pointer;
  pointer-events: none;
  transition: ${({ theme }) => `
    background-color ${theme.transitions.duration[1]} ${theme.transitions.timing.easeInOut},
    border-color ${theme.transitions.duration[1]} ${theme.transitions.timing.easeInOut}
  `};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 999px;
    background-color: white;
    margin: auto;
    opacity: 0;
    box-shadow: 0px 1px 1px 0px ${({ theme }) => theme.colors.border.B};
  }
`

/*
 * Fixes a bug where pressing the corner of the plan card
 * wouldn't select the plan because the radio button is round
 */
export const HiddenRadioButtonLabel = styled.label`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;

  /*
  * Hides a shadow when tapping radio buttons on mobile devices.
  * Issue: https://github.com/sketch-hq/Cloud/issues/2386
  */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`

export const HiddenRadioButton = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;

  &:checked + ${FakeRadioButton} {
    border-color: ${({ theme }) => theme.colors.sketch.A};
    background-color: ${({ theme }) => theme.colors.sketch.A};

    &:after {
      opacity: 1;
    }
  }

  &:disabled + ${FakeRadioButton} {
    opacity: 0.8;
    cursor: not-allowed;
  }
`

export const PlanName = styled.p`
  width: 100%;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.C};
  font-weight: 600;
  margin: 0 0 4px 0;
`

export const Small = styled.small<{ isSelected: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.E};
  opacity: 0.65;
  pointer-events: none;
  color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.colors.foreground.secondary.A
      : theme.colors.foreground.secondary.D};
`

export const Price = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  letter-spacing: -6px;
  font-weight: ${({ theme }) => theme.fontWeights.thin};
`

export const Currency = styled.span<{ isSelected: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.F};
  color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.colors.foreground.secondary.A
      : theme.colors.foreground.secondary.D};
`

export const Unit = styled.p<{ isSelected: boolean }>`
  align-self: flex-start;
  margin: 0;
  font-size: 5rem; /* stylelint-disable-line scales/font-size */
  font-weight: 100;
  line-height: 1;
  color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.colors.foreground.secondary.A
      : theme.colors.foreground.secondary.C};
`

export const Cents = styled.p`
  margin: 0;
  font-size: 1.625rem; /* stylelint-disable-line scales/font-size */
  letter-spacing: 0;
`

export const TrialPrice = styled.p`
  margin: 0;
  font-size: 2.625rem; /* stylelint-disable-line scales/font-size */
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  height: 80px;
`

export const PlanDescription = styled.p`
  font-size: 0.8125rem;
  opacity: 0.65;
  margin: 0;
  pointer-events: none;
`

export const BestValuePill = styled(Pill)<{ isSelected?: boolean }>`
  position: absolute;
  bottom: -${({ isSelected }) => (isSelected ? 10 : 9)}px;
`
