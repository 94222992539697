import styled from 'styled-components'
import { PersonAvatar, Separator } from '@sketch/components'

export const AvatarTitle = styled.div`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.ui.tooltips.header};
  font-size: 0.6875rem;
  font-weight: 600;
`

export const AvatarTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  ${PersonAvatar} {
    margin-right: 8px;
  }
`

export const AvatarTooltipContent = styled.div`
  ${Separator} {
    border-color: ${({ theme }) => theme.colors.ui.tooltips.separator};
    margin-left: -12px;
    margin-right: -12px;
  }
`

export const PersonName = styled.span`
  max-width: min(300px, 80vw);

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
