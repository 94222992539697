import React from 'react'
import { Skeleton } from '@sketch/components'

const SkeletonGroupsList = () => (
  <ul>
    <li>
      <Skeleton height="32px" />
    </li>
    <li>
      <Skeleton height="32px" />
    </li>
    <li>
      <Skeleton height="32px" />
    </li>
  </ul>
)

export default SkeletonGroupsList
