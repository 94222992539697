import React, { FC } from 'react'
import { Editor } from '@tiptap/react'

import {
  FloatingMenuStyled,
  FloatingMenuTitle,
  FloatingMenuListItem,
  FloatingMenuItem,
  FloatingMenuIcon,
} from './FloatingMenu.styles'
import { Tooltip } from '../../Tooltip'
import {
  ParagraphTooltip,
  TitleTooltip,
  HeadingTooltip,
  SubheadingTooltip,
  BulletListTooltip,
  NumberedListTooltip,
  DividerTooltip,
} from './ShortcutsTooltips'

import {
  setParagraph,
  isParagraphActive,
  setTitle,
  isTitleActive,
  setHeading,
  isHeadingActive,
  setSubheading,
  isSubheadingActive,
  setBulletList,
  isBulletListActive,
  setNumberedList,
  isNumberedListActive,
  setDivider,
} from '../utils'

import { ReactComponent as ParagraphIcon } from '@sketch/icons/editor-text-paragraph-16'
import { ReactComponent as H1Icon } from '@sketch/icons/editor-text-h1-16'
import { ReactComponent as H2Icon } from '@sketch/icons/editor-text-h2-16'
import { ReactComponent as H3Icon } from '@sketch/icons/editor-text-h3-16'
import { ReactComponent as BulletListIcon } from '@sketch/icons/editor-list-bullet-16'
import { ReactComponent as NumberListIcon } from '@sketch/icons/editor-list-number-16'
import { ReactComponent as DividerIcon } from '@sketch/icons/editor-divider-16'

interface FloatingMenuProps {
  editor: Editor | null
}

export const FloatingMenu: FC<FloatingMenuProps> = ({ editor }) => {
  return editor ? (
    <FloatingMenuStyled
      className="floating-menu"
      tippyOptions={{ duration: 100, placement: 'bottom-start' }}
      editor={editor}
    >
      <FloatingMenuTitle>Add Block</FloatingMenuTitle>
      <FloatingMenuListItem>
        <Tooltip
          placement="right"
          spacing="12px"
          content={<ParagraphTooltip />}
        >
          <FloatingMenuItem
            onClick={() => setParagraph(editor)}
            className={isParagraphActive(editor)}
          >
            <FloatingMenuIcon as={ParagraphIcon} />
            <span>Paragraph</span>
          </FloatingMenuItem>
        </Tooltip>
      </FloatingMenuListItem>
      <FloatingMenuListItem>
        <Tooltip placement="right" spacing="12px" content={<TitleTooltip />}>
          <FloatingMenuItem
            onClick={() => setTitle(editor)}
            className={isTitleActive(editor)}
          >
            <FloatingMenuIcon as={H1Icon} />
            <span>Title</span>
          </FloatingMenuItem>
        </Tooltip>
      </FloatingMenuListItem>
      <FloatingMenuListItem>
        <Tooltip placement="right" spacing="12px" content={<HeadingTooltip />}>
          <FloatingMenuItem
            onClick={() => setHeading(editor)}
            className={isHeadingActive(editor)}
          >
            <FloatingMenuIcon as={H2Icon} />
            <span>Heading</span>
          </FloatingMenuItem>
        </Tooltip>
      </FloatingMenuListItem>
      <FloatingMenuListItem>
        <Tooltip
          placement="right"
          spacing="12px"
          content={<SubheadingTooltip />}
        >
          <FloatingMenuItem
            onClick={() => setSubheading(editor)}
            className={isSubheadingActive(editor)}
          >
            <FloatingMenuIcon as={H3Icon} />
            <span>Subheading</span>
          </FloatingMenuItem>
        </Tooltip>
      </FloatingMenuListItem>
      <FloatingMenuListItem>
        <Tooltip
          placement="right"
          spacing="12px"
          content={<BulletListTooltip />}
        >
          <FloatingMenuItem
            onClick={() => setBulletList(editor)}
            className={isBulletListActive(editor)}
          >
            <FloatingMenuIcon as={BulletListIcon} />
            <span>Bullet list</span>
          </FloatingMenuItem>
        </Tooltip>
      </FloatingMenuListItem>
      <FloatingMenuListItem>
        <Tooltip
          placement="right"
          spacing="12px"
          content={<NumberedListTooltip />}
        >
          <FloatingMenuItem
            onClick={() => setNumberedList(editor)}
            className={isNumberedListActive(editor)}
          >
            <FloatingMenuIcon as={NumberListIcon} />
            <span>Numbered list</span>
          </FloatingMenuItem>
        </Tooltip>
      </FloatingMenuListItem>
      <FloatingMenuListItem>
        <Tooltip placement="right" spacing="12px" content={<DividerTooltip />}>
          <FloatingMenuItem onClick={() => setDivider(editor)}>
            <FloatingMenuIcon as={DividerIcon} />
            <span>Divider</span>
          </FloatingMenuItem>
        </Tooltip>
      </FloatingMenuListItem>
    </FloatingMenuStyled>
  ) : null
}
