const deletedVersions: { [id: string]: true | undefined } = {}
const initiatedDeletions: { [id: string]: true | undefined } = {}

/**
 * There are two places which reduce the availableVersions.meta.totalCount
 * one if after the mutation another is when subscription has been received.
 *
 * For user who have deleted the version this logic gets duplicated, and there
 * is no guarantee which action will occur first, therefore, we just simply track
 * whether the totalCount was already reduced or not.
 */
export const shouldReduceTotalCount = (versionIdentifier: string) => {
  const wasAlreadyDeleted = !!deletedVersions[versionIdentifier]
  deletedVersions[versionIdentifier] = true
  return !wasAlreadyDeleted
}

/**
 * Subscription for executed delete version mutation is received even sooner than the
 * result of the mutation. However, it is necessary to that UI would be updated like
 * mutation would've been received first.
 *
 * as a workaround, we are tracking whether this client actually initiated a mutation
 * and if so, we don't execute some part of subscriptions logic.
 *
 * This is quite error prone solution, as in this case, we are expecting that mutation
 * will be eventually successful, which might not be alway the case.
 * Therefore, we are limiting the usage area of initiatedDeletions as much as possible.
 * And even better, systematical review how mutations and subscriptions are orchestrated
 * should be done and things changed accordingly.
 */
export const recordInitiatedDeletion = (versionIdentifier: string) => {
  initiatedDeletions[versionIdentifier] = true
}

export const wasDeletionInitiatedByThisClient = (versionIdentifier: string) =>
  !!initiatedDeletions[versionIdentifier]
