import React from 'react'

import { Table, Skeleton, TableComponents, Flex } from '@sketch/components'

import { SkeletonAvatar } from './MembersTableLoading.styles'
import { TableWrapper } from '../../MembershipsPanel.styles'

import { getColumnValues } from '../../utils'
import { GetWorkspaceMembershipsQueryVariables } from '@sketch/gql-types'

interface MembersTableLoadingProps {
  items: number
  renderSecondaryActions: boolean
  order: GetWorkspaceMembershipsQueryVariables['order']
  sortDirection: GetWorkspaceMembershipsQueryVariables['orderDirection']
}

/*
 * Renders Skeleton UI for the members table
 */
const MembersTableLoading = React.forwardRef<
  HTMLDivElement,
  MembersTableLoadingProps
>(({ items, renderSecondaryActions, order, sortDirection }, ref) => {
  const { tableWidth, firstColWidth, tableHeader } = getColumnValues(
    !!renderSecondaryActions,
    order
  )

  return (
    <TableWrapper
      ref={ref}
      $tableWidth={tableWidth}
      $firstColWidth={firstColWidth}
      $isSticky={renderSecondaryActions}
      data-testid="members-table-loading"
    >
      <Table
        header={tableHeader}
        items={Array.from({ length: items }, (_, index) => index)}
        renderItem={item => (
          <TableComponents.TableRow
            key={item}
            data-testid="members-table-skeleton-row"
          >
            <TableComponents.TableCell>
              <Flex alignItems="center">
                <SkeletonAvatar />
                <Skeleton height="20px" width="200px" />
              </Flex>
            </TableComponents.TableCell>
            <TableComponents.TableCell data-testid="member-access">
              <Skeleton height="20px" width="50px" />
            </TableComponents.TableCell>
            {renderSecondaryActions && (
              <>
                <TableComponents.TableCell>
                  <Skeleton height="20px" width="50px" />
                </TableComponents.TableCell>
                <TableComponents.TableCellSticky>
                  <Skeleton height="20px" width="40px" />
                </TableComponents.TableCellSticky>
              </>
            )}
          </TableComponents.TableRow>
        )}
        sortable
        sortDirection={sortDirection}
      />
    </TableWrapper>
  )
})

MembersTableLoading.displayName = 'MembersTableLoading'
export default MembersTableLoading
