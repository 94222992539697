import { useState, useEffect, useRef, useCallback } from 'react'
import {
  RenderDownloadableAssetsMutation,
  useRenderDownloadableAssetsMutation,
  DocumentDownloadableAssetsFragment,
  DocumentDownloadableAssetsFragmentDoc,
  useGetDownloadableAssetsLazyQuery,
  GetDownloadableAssetsQuery,
  DownloadableAssetFragment,
} from '@sketch/gql-types'
import { dataIdFromObject } from '@sketch/graphql-cache'

type UserError = NonNullable<
  NonNullable<
    RenderDownloadableAssetsMutation['renderDownloadableAssets']
  >['errors'][0]
>

interface AssetStateUnavailable {
  type: 'UNAVAILABLE'
}

interface AssetStateRequestable {
  type: 'REQUESTABLE'
  request: () => void
}

interface AssetStateLoading {
  type: 'LOADING'
}

interface AssetStateRecoverableFailure {
  type: 'RECOVERABLE_FAILURE'
  errors?: UserError[]
  request: () => void
}

interface AssetStatePermenantFailure {
  type: 'PERMANENT_FAILURE'
  errors?: UserError[]
}

interface AssetStateDownloadable {
  type: 'DOWNLOADABLE'
  assets: DownloadableAssetFragment[]
}

export type AssetState =
  | AssetStateUnavailable
  | AssetStateRequestable
  | AssetStateLoading
  | AssetStateRecoverableFailure
  | AssetStatePermenantFailure
  | AssetStateDownloadable

export interface UseAssetDownloadProps {
  // TODO - Mark UseAssetDownloadProps.shareShortId as non nullalbe
  // see https://github.com/sketch-hq/Cloud/issues/3599
  shareIdentifier?: string
  versionShortId?: string
  document?: DocumentDownloadableAssetsFragment
  pollInterval?: number
  pollTimeout?: number
}

const downloadableAssetFromQueryData = (data?: GetDownloadableAssetsQuery) => {
  const document = data?.share?.version?.document

  if (!document) {
    return undefined
  }

  return downloadableAssetFromDocument(document)
}

const downloadableAssetFromMutationData = (
  data?: RenderDownloadableAssetsMutation
) => data?.renderDownloadableAssets?.downloadableAsset

const downloadableAssetFromDocument = (
  document: DocumentDownloadableAssetsFragment
) => {
  const documentAssets = document.downloadableAssets?.filter(
    asset => !asset.layerUuids.length
  )

  if (documentAssets.length <= 0) {
    return undefined
  }

  return documentAssets[0]
}

const useAssetDownload = ({
  document,
  shareIdentifier,
  versionShortId,
  pollInterval = 5000, // 5 naive seconds
  pollTimeout = 5 * 60 * 60 * 1000, // 5 naive minutes
}: UseAssetDownloadProps): AssetState => {
  const [pollingFailed, setPollingFailed] = useState(false)
  const timeoutID = useRef<number | undefined>()
  const [isPolling, setPolling] = useState(!!timeoutID.current)

  const [
    renderAssets,
    { called: mutationCalled, data: mutationData, error: gqlError },
  ] = useRenderDownloadableAssetsMutation({
    redirectErrors: false,
    variables: {
      documentIdentifier: document?.identifier || '',
    },
    onError: 'unsafe-throw-exception',
    update: (client, result) => {
      if (!document) {
        return
      }
      const downloadableAsset =
        result?.data?.renderDownloadableAssets?.downloadableAsset
      if (!downloadableAsset) {
        return
      }
      const downloadableAssets = document.downloadableAssets
      downloadableAssets.push(downloadableAsset)
      const newData: DocumentDownloadableAssetsFragment = {
        __typename: 'Document',
        identifier: document.identifier,
        assetStatus: document.assetStatus,
        downloadableAssets,
      }
      client.writeFragment<DocumentDownloadableAssetsFragment>({
        id: dataIdFromObject(document)!,
        fragment: DocumentDownloadableAssetsFragmentDoc,
        fragmentName: 'DocumentDownloadableAssets',
        data: newData,
      })
    },
  })

  const [
    apolloGetAssets,
    {
      called: queryCalled,
      data: queryData,
      startPolling: apolloStartPolling,
      stopPolling: apolloStopPolling,
    },
  ] = useGetDownloadableAssetsLazyQuery({
    variables: {
      shareIdentifier: shareIdentifier!,
      versionShortId,
    },
  })

  const startPolling = () => {
    if (isPolling || !apolloStartPolling) {
      return
    }

    apolloStartPolling(pollInterval)
    setPolling(true)
  }

  const stopPolling = useCallback(() => {
    if (!isPolling || !apolloStopPolling) {
      return
    }

    apolloStopPolling()
    setPolling(false)
  }, [isPolling, apolloStopPolling])

  const getAssets = () => {
    if (!queryCalled) {
      apolloGetAssets()
    } else {
      startPolling()
    }
  }

  useEffect(() => {
    if (!isPolling) {
      return
    }
    timeoutID.current = window.setTimeout(() => {
      stopPolling()
      setPollingFailed(true)
    }, pollTimeout)

    return () => {
      window.clearTimeout(timeoutID.current)
      timeoutID.current = undefined
    }
  }, [isPolling, pollTimeout, stopPolling])

  if (!document) {
    return { type: 'UNAVAILABLE' }
  }

  const request = () => {
    setPollingFailed(false)
    renderAssets().catch(() => {})
  }

  if (pollingFailed) {
    return { type: 'RECOVERABLE_FAILURE', request }
  }

  const downloadableAsset =
    downloadableAssetFromQueryData(queryData) ||
    downloadableAssetFromMutationData(mutationData) ||
    downloadableAssetFromDocument(document) ||
    undefined

  switch (downloadableAsset?.status) {
    case 'AVAILABLE':
      stopPolling()
      return {
        type: 'DOWNLOADABLE',
        assets: [downloadableAsset],
      }

    case 'FAILED':
      return {
        type: 'PERMANENT_FAILURE',
      }

    case 'REQUESTED':
      getAssets()
      return { type: 'LOADING' }

    default:
      if (downloadableAsset?.status) {
        return { type: 'PERMANENT_FAILURE' }
      }
  }

  if (mutationCalled) {
    if (gqlError) {
      return { type: 'RECOVERABLE_FAILURE', request }
    }

    const errors = mutationData?.renderDownloadableAssets?.errors

    if (errors && errors.length > 0) {
      const isRenderError = errors.find(error => {
        if (!error) {
          return false
        }

        return (
          error.code === 'CONFLICT' &&
          error.message === 'Assets already requested for rendering'
        )
      })
      const isUnauthorizedError = errors.find(error => {
        if (!error) {
          return false
        }

        return error.code === 'FORBIDDEN'
      })

      if (isRenderError) {
        getAssets()
      } else if (isUnauthorizedError) {
        return {
          type: 'PERMANENT_FAILURE',
          errors: errors as UserError[],
        }
      } else {
        return {
          type: 'RECOVERABLE_FAILURE',
          errors: errors as UserError[],
          request,
        }
      }
    }

    return { type: 'LOADING' }
  } else {
    switch (document.assetStatus) {
      default:
      case 'UNAVAILABLE':
        return { type: 'UNAVAILABLE' }

      case 'AVAILABLE':
        return {
          type: 'REQUESTABLE',
          request,
        }
    }
  }
}

export default useAssetDownload
