import styled from 'styled-components'

export const Item = styled.div`
  margin: 0 8px;

  & + & {
    margin-left: 0px;
  }
`

export const BackButtonItem = styled(Item)`
  margin: 0 8px 0 0;
  line-height: 1;
`
