import { ButtonUnstyled, useResponsiveDropdown } from '@sketch/components'
import { ReactComponent as Ellipsis } from '@sketch/icons/ellipsis-16'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { MenuLink } from './MenuLink'
import { useShareDrop, ShareDropPayload } from '@sketch/modules-common'

const ProjectOptionsButton = styled(ButtonUnstyled)`
  display: inline-flex;
  color: currentColor;

  justify-content: center;
  align-items: center;

  padding: 5px; /* stylelint-disable-line scales/space */

  &:focus {
    color: ${({ theme }) => theme.colors.foreground.secondary.D};
  }
`

const StyledEllipsis = styled(Ellipsis)`
  width: 16px;
  height: 16px;
`

export interface ActionableMenuLinkProps {
  icon: ({ isLinkActive }: { isLinkActive: boolean }) => React.ComponentType
  dropdownContent?: React.ReactNode
  onDropped?: (data: ShareDropPayload) => void
  to: string
  text: React.ReactNode
}

const ActionPopoverWrapper: React.FC = ({ children }) => <div>{children}</div>

export const ActionableMenuLink = (props: ActionableMenuLinkProps) => {
  const { text, to, dropdownContent, icon, onDropped } = props
  const location = useLocation()

  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const { isDraggedOver, kind, ...dragEvents } = useShareDrop({ onDropped })

  const isLinkActive = location.pathname === to

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: ActionPopoverWrapper,
    dropdownProps: {
      children: dropdownContent,
      placement: 'bottom',
      onToggle: setDropdownOpen,
      hideOnClick: false,
    },
    placement: 'bottom',
    usePortal: true,
  })

  const dropdown =
    dropdownContent && !isDraggedOver ? (
      <>
        <ProjectOptionsButton data-testid="project-options" {...buttonProps}>
          <StyledEllipsis />
        </ProjectOptionsButton>
        {content}
      </>
    ) : null

  return (
    <MenuLink
      icon={icon({ isLinkActive })}
      to={to}
      $forceHover={isDropdownOpen || isDraggedOver}
      text={text}
      action={dropdown}
      {...dragEvents}
    />
  )
}
