import React from 'react'

import { Title, Description, Links, StyledLink } from '../Setup2FAModal.styles'

/**
 * AuthAppsStep
 *
 * Renders the first step for the 2FA Setup Modal
 */
export const AuthAppsStep: React.FC = () => {
  return (
    <>
      <Title>Install an Authenticator App</Title>
      <Description>
        First, install an authenticator app on your phone. Popular
        authenticators include:
      </Description>
      <Links>
        <StyledLink
          href="https://apps.apple.com/gb/app/google-authenticator/id388497605"
          target="_blank"
          isUnderlined
          external
        >
          Google Authenticator
        </StyledLink>
        <StyledLink
          href="https://apps.apple.com/us/app/authy/id494168017"
          target="_blank"
          isUnderlined
          external
        >
          Authy
        </StyledLink>
        <StyledLink
          href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
          target="_blank"
          isUnderlined
          external
        >
          Microsoft Authenticator
        </StyledLink>
      </Links>
    </>
  )
}
