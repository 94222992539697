import { useContext } from 'react'

import { ModalContext } from '@sketch/components'
import { ShareMinimalForSettingsModal } from '../modals/ShareSettingsModal'
import SharingModal from '../modals/SharingModal'

export const useSharingModal = (share: ShareMinimalForSettingsModal) => {
  const { showModal } = useContext(ModalContext)

  const openShareSettingsModal = (options?: {
    closeOnRouteChange: boolean
  }) => {
    return showModal(
      SharingModal,
      {
        identifier: share.identifier,
        name: share.name,
      },
      options
    )
  }

  return { openShareSettingsModal }
}
