import React from 'react'
import copy from 'copy-to-clipboard'

import { Dropdown } from '@sketch/components'

import { routes } from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'

interface CopyLinkProps {
  shareIdentifier: string
}

const CopyLink: React.FC<CopyLinkProps> = ({ shareIdentifier: shareID }) => {
  const { showToast } = useToast()
  const link = `${window.location.origin}${routes.SHARE_VIEW.create({
    shareID,
  })}`

  return (
    <Dropdown.Item
      onClick={() => {
        copy(link)
        showToast('Link copied')
      }}
    >
      Copy Link
    </Dropdown.Item>
  )
}

export default CopyLink
