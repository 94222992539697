import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "pencil-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M2.033 13.324l1.27-3.44h0l.437-.435 2.485-2.483 4.564-4.56a1.984 1.984 0 012.808.003 1.992 1.992 0 01-.003 2.815L9.03 9.784h0l-2.485 2.484-.436.435-3.434 1.264a.5.5 0 01-.642-.643z", stroke: "currentColor", strokeWidth: 1.5, strokeLinejoin: "round" }),
        React.createElement("path", { fill: "currentColor", fillRule: "nonzero", d: "M3 11l2 2-3 1z" }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, fillOpacity: 0.15, fill: "currentColor", fillRule: "nonzero", d: "M10.002 3.998L12 6" }),
        React.createElement("path", { fillOpacity: 0.15, fill: "currentColor", fillRule: "nonzero", d: "M12 1.5l2.5 2L12 6l-2-2z" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
