import { OPTION_ITEM, useOnClickOutside } from '@sketch/utils'
import React, { useEffect, useRef, useState } from 'react'
import Measure from 'react-measure'

import { selectors } from '@sketch/constants'

import { useForTablet } from '../Breakpoint'
import { DisableBodyScroll } from '../DisableBodyScroll'
import { callIfFunction, RenderFunctionOrNode } from './callIfFunction'
import {
  ContentContainer,
  PageLayout,
  RightSidebarContainer,
  SidebarBackdrop,
  SidebarLeftContainer,
  SidebarPlaceholder,
  SidebarLeftContainerBackdrop,
} from './SidebarLayout.styles'

/**
 * TYPES
 */
export interface SidebarLayoutProps {
  title?: string
  /** Navigation sidebar */
  sidebarLeft?: RenderFunctionOrNode
  /** Activity/inspector sidebar */
  sidebarRight?: RenderFunctionOrNode
  header: RenderFunctionOrNode
  footer?: RenderFunctionOrNode
  children?: RenderFunctionOrNode
  isSidebarRightOpen?: boolean
  toggleSidebarRight?: () => void
  darkBackground?: boolean
  sidebarOnTopOfHeader?: boolean
}

const KEYS_ALLOW_ACTION = ['enter', 'space']

const getSidebarWidth = (width: number | 'auto', isOpen: boolean) =>
  width === 'auto' || isOpen ? width : 0

/**
 * COMPONENT
 * Used for document/artboard view, it provides a right sidebar to show
 * activity/inspector, also provides the left sidebar for nav (as an overlay)
 */
const SidebarLayout: React.FC<SidebarLayoutProps> = props => {
  const {
    title,
    sidebarLeft,
    sidebarRight,
    header,
    footer,
    toggleSidebarRight,
    children,
    darkBackground,
    sidebarOnTopOfHeader,
  } = props

  // If there's no way to render the right sidebar (i.e. it's null), then
  // ensure it can never be set as open
  const isSidebarRightOpen =
    (props.sidebarRight === null ? false : props.isSidebarRightOpen) || false

  const [isSidebarLeftOpen, setSidebarLeftOpen] = useState(false)
  const [sidebarWidth, setSidebarWidth] = useState(
    getSidebarWidth('auto', isSidebarRightOpen)
  )

  const passedProps = {
    isSidebarRightOpen,
    toggleSidebarRight,
    isSidebarLeftOpen,
    setSidebarLeftOpen,
  }

  const isTabletAndBigger = useForTablet()
  const isSmallerThanTablet = !isTabletAndBigger

  // Close the left sidebar when is opened and clicked outside
  const sidebarLeftRef = useRef<HTMLElement>(null)

  // close sidebar left with Esc
  useEffect(() => {
    document.addEventListener('keydown', (e: KeyboardEvent) => {
      const sidebar = document.querySelector(selectors.sidebarLeft)

      if (e.key === 'Escape' && sidebar?.contains(document.activeElement)) {
        setTimeout(() => setSidebarLeftOpen(false), 0)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Adds the `inert` html attribute to left sidebar when it's hidden, this way
  // make the browser ignore user input events for the element, including focus
  // events and events from assistive technologies.
  // Note: we need to do it this way because React still doesn't accept this attr
  useEffect(() => {
    if (!sidebarLeftRef.current) {
      return
    }

    if (isSidebarLeftOpen) {
      sidebarLeftRef.current.removeAttribute('inert')
    } else {
      sidebarLeftRef.current.setAttribute('inert', '')
    }
  }, [isSidebarLeftOpen])

  const closeSidebar = () => {
    if (isSidebarLeftOpen) {
      // setTimeout is needed to make sure `setSidebarLeftOpen` is called after
      // any other click event listener, for example the hamburger icon click
      setTimeout(() => setSidebarLeftOpen(false), 0)
    }
  }

  useOnClickOutside(sidebarLeftRef, closeSidebar, {
    // We don't want to close the sidebar when the user is renaming a project
    includeSelectors: [OPTION_ITEM],
  })

  return (
    <PageLayout
      title={title}
      header={callIfFunction(header, passedProps)}
      darkBackground={darkBackground}
    >
      {sidebarLeft && (
        <>
          {isSmallerThanTablet && isSidebarLeftOpen && (
            <SidebarLeftContainerBackdrop />
          )}
          <SidebarLeftContainer
            data-testid="side-bar-left"
            data-sidebar-left
            ref={sidebarLeftRef}
            isSidebarLeftOpen={isSidebarLeftOpen}
          >
            {sidebarLeft}
          </SidebarLeftContainer>
        </>
      )}

      <ContentContainer>
        {callIfFunction(children, passedProps)}
        {callIfFunction(footer, passedProps)}
      </ContentContainer>

      {sidebarRight && (
        <Measure
          onResize={({ entry }) => {
            entry && setSidebarWidth(entry?.width)
          }}
        >
          {({ measureRef }) => (
            <>
              <RightSidebarContainer
                data-testid="side-bar-right"
                aria-hidden={!isSidebarRightOpen}
                ref={measureRef}
                $sidebarOnTopOfHeader={sidebarOnTopOfHeader}
              >
                {callIfFunction(sidebarRight, passedProps)}
              </RightSidebarContainer>

              {!isSmallerThanTablet && (
                <SidebarPlaceholder
                  style={{
                    width: getSidebarWidth(sidebarWidth, isSidebarRightOpen),
                  }}
                />
              )}

              {isSmallerThanTablet && (
                <SidebarBackdrop
                  tabIndex={0}
                  onKeyPress={({ key }) =>
                    KEYS_ALLOW_ACTION.includes(key) && toggleSidebarRight?.()
                  }
                  onClick={() => toggleSidebarRight?.()}
                  data-testid="sidebar-backdrop-close"
                >
                  <span className="sr-only">Sidebar backdrop close</span>
                </SidebarBackdrop>
              )}
            </>
          )}
        </Measure>
      )}

      {isSmallerThanTablet && isSidebarRightOpen && <DisableBodyScroll />}
    </PageLayout>
  )
}

export { SidebarLayout }
