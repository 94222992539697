import styled from 'styled-components'

import { Text } from '../Box'

export const DropdownItemDescription = styled(Text.Div).attrs({
  textStyle: 'copy.quaternary.standard.D',
})<{ $isDisabled?: boolean }>`
  margin: 4px 0 0 0;
  line-height: 1.2;
  white-space: initial;

  ${({ $isDisabled }) => $isDisabled && 'opacity: 0.55;'}
`
