import styled from 'styled-components'
import { Button } from '@sketch/components'

import { NavbarItem } from '../../../components/NavbarItem'

// This is used to align the action buttons on the canvas header
// with the rest of the elements. And also to adjust the spacing with
// the right separator
export const ActionsNavbarItem = styled(NavbarItem)`
  display: flex;
  margin-right: 0;
`

export const NavbarBackButtonItem = styled(NavbarItem)`
  margin: 0 8px 0 0;
  line-height: 1;
`

export const AddAnnotationText = styled(NavbarItem)`
  font-size: 0.8125rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`

export const MiddleContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

export const ActionButton = styled(Button).attrs({ size: '32' })`
  padding: 6px 8px 6px 12px; /* stylelint-disable-line scales/space */
  font-size: ${({ theme }) => theme.fontSizes.D};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  box-shadow: none;

  &,
  :hover,
  :focus,
  :active {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  &[disabled] {
    opacity: 0.5;
  }
`
