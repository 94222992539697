export function dasherize(str?: string): string {
  if (!str) return ''

  return str.replace(
    /[A-Z](?:(?=[^A-Z])|[A-Z]*(?=[A-Z][^A-Z]|$))/g,
    function (s, i) {
      return (i > 0 ? '-' : '') + s.toLowerCase()
    }
  )
}
