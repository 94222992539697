import { createContext, MutableRefObject } from 'react'

import {
  DevToolsSettingsState,
  PartialDevToolsSettings,
  defaultValues,
} from './settingsState'
import { Subscription } from './Subscription'

export interface SetSettingOptions {
  /**
   * Write changes to the local storage synchronously
   *
   * ---
   *
   * By default all settings changes are also written to the local storage,
   * however, this action is debounced, i.e. we are writing to the local storage
   * after 500ms of idle time without any changes.
   *
   * However, for some actions,  e.g. when we want to refresh the browser straight after
   * we've changed some settings values, waiting for an extra time before writing value to the
   * local storage isn't feasible, therefore this property exists.
   *
   * @default false
   */
  immediately?: boolean
}

export interface SettingsContext {
  settings: MutableRefObject<DevToolsSettingsState>
  subscription: Subscription
  setSettings: (
    value: PartialDevToolsSettings,
    options?: SetSettingOptions
  ) => void
}

export const DevToolsSettingsContext = createContext<SettingsContext>({
  settings: { current: defaultValues },
  setSettings: () => {
    throw new Error('DevToolsSettingsContext was not provided')
  },
  subscription: new Subscription(),
})
