import styled from 'styled-components'
import { breakpoint } from '@sketch/global-styles'

import { ButtonUnstyled, Text } from '@sketch/components'
import { ReactComponent as PencilIcon } from '@sketch/icons/pencil-16'
import { ReactComponent as DividerSvg } from '@sketch/icons/dots-4-vertical-divider-32'

export const BreadCrumb = styled(Text.H1).attrs({
  textStyle: 'subheader.disabled.standard.B',
})`
  margin-top: 0;
  margin-bottom: 4px;
`

export const Description = styled.span`
  padding: 4px;
`

export const DescriptionPreview = styled.span`
  box-sizing: content-box;
  visibility: hidden;

  ::after {
    /* Allow spaces to be added in the end of a string */
    content: 'a';
    font-size: 0;
  }
`

// The form needs the height set so the buttons don't overlap the input
// and the input also gets that height so it's not claustrofobic with bigger text
export const DescriptionForm = styled.form<{ height?: number }>`
  position: relative;
  margin: 0;
  padding: 4px;
  height: ${({ height }) => (height ? `${height}px` : undefined)};
`

export const DescriptionTextArea = styled.textarea<{ height?: number }>`
  box-sizing: content-box;

  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : undefined)};
  position: absolute;
  top: 2px;
  left: 0;

  background: none;

  outline: none;
  resize: none;
  overflow: hidden;
  white-space: pre-wrap;

  border-radius: ${({ theme }) => theme.radii.large};
  border: 2px solid ${({ theme }) => theme.colors.sketch.E};

  ::selection {
    background: ${({ theme }) => theme.colors.sketch.E};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.state.disabled};
  }
`

export const Pencil = styled(PencilIcon)`
  width: 16px;

  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
`

export const PencilButton = styled(ButtonUnstyled)`
  margin: 0 0 0 12px;
  transition: opacity ${({ theme }) => theme.transitions.duration[1]};
  display: flex;
  align-items: center;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 0 8px 0;

  ${breakpoint('base', 'md')`
    width: 100%;
  `}

  /* 540 + 28px occupied by the pencil icon */
  ${breakpoint('lg')`
    width: 568px;
  `}

  /* Hide the pencil button by default */
  & ${PencilButton} {
    opacity: 0;
  }

  /* Show the button when the container is hovered */
  :hover {
    & ${PencilButton} {
      opacity: 1;
    }
  }

  ${Description} {
    /* Show the button when the Description is focused */
    :hover,
    :focus,
    :focus-within {
      ~ ${PencilButton} {
        opacity: 1;
      }
    }
  }

  /* Show the button when it's focused */
  ${PencilButton} {
    :focus {
      opacity: 1;
    }
  }

  /* Keep the pencil visible when the description input is active */
  ${DescriptionForm /* sc-selector */} {
    + ${PencilButton} {
      opacity: 1;
    }
  }
`

export const Divider = styled(DividerSvg)`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  width: 12px;
  height: 24px;
`
