import React, { useCallback, useRef } from 'react'

import { isTouchDevice } from '@sketch/utils'
import useActiveAnnotation from './useActiveAnnotation'
import {
  useCreateDraftAnnotation,
  usePlaceDraftAnnotation,
} from './useAnnotationOverlay'

import { useAnnotationQueryVariables } from '../context'

interface MousePosition {
  x: number
  y: number
}

export const useCanvasAnnotations = () => {
  const pointerDownPosition = useRef<MousePosition | null>(null)

  const [activeAnnotation, setActiveAnnotation] = useActiveAnnotation()
  const { shareIdentifier } = useAnnotationQueryVariables('default')
  const [placeDraftAnnotation] = usePlaceDraftAnnotation() || []

  const handleDraftAnnotationEvent = useCreateDraftAnnotation(shareIdentifier)

  const handlePointerUp = useCallback(
    (hasMouseMoved: boolean, clientX: number, clientY: number) => {
      /**
       * We need to prevent when the dot is dragged
       * to close the annotation (if active)
       */
      if (hasMouseMoved) {
        return
      }
      /**
       * If placeDraftAnnotation is "true" we
       * will forward the events to the "handleDraftAnnotationEvent"
       * so the draft annotation can be placed
       */
      if (placeDraftAnnotation) {
        handleDraftAnnotationEvent({ clientX, clientY })
      } else {
        /**
         * If  placeDraftAnnotation is "false" then
         * we set the "activeAnnotation" to undefined
         * closing it
         */
        activeAnnotation && setActiveAnnotation()
      }
    },
    [
      activeAnnotation,
      placeDraftAnnotation,
      handleDraftAnnotationEvent,
      setActiveAnnotation,
    ]
  )

  /**
   * The events handlers for touch base devices
   */
  const handleTouchStart = useCallback((event: React.TouchEvent) => {
    /**
     * Since these events are only for single touch action let's discard multitouch
     */
    if (event.touches.length !== 1) {
      return
    }

    pointerDownPosition.current = {
      x: event.touches[0].clientX,
      y: event.touches[0].clientY,
    }
  }, [])

  const handleTouchEnd = useCallback(
    (event: React.TouchEvent) => {
      /**
       * Since these events are only for single touch action let's discard multitouch
       * and if the initial down position is not set we can ignore as well
       */
      if (event.changedTouches.length !== 1 || !pointerDownPosition.current) {
        return
      }

      const downPosition = pointerDownPosition.current

      /**
       * This was added to prevent the moving when the user wants to pan
       * the canvas and the pointer not being moved
       */
      const deltaX = downPosition.x - event.changedTouches[0]?.clientX
      const deltaY = downPosition.y - event.changedTouches[0]?.clientY
      const hasMouseMoved = deltaX > 0 || deltaY > 0

      /**
       * Removing the difference in positioning of the canvas to the window
       * so the events coordinates are from inside the canvas
       */
      const currentTargetPosition = event.currentTarget.getBoundingClientRect()
      const { pageX, pageY } = event.changedTouches[0]

      handlePointerUp(
        hasMouseMoved,
        pageX - currentTargetPosition.x,
        pageY - currentTargetPosition.y
      )
    },
    [handlePointerUp]
  )

  /**
   * The events handlers for mouse base devices
   */
  const handleMouseDown = useCallback((event: React.MouseEvent) => {
    pointerDownPosition.current = { x: event.clientX, y: event.clientY }
  }, [])

  const handleMouseUp = useCallback(
    (event: React.MouseEvent) => {
      /**
       * If the initial down position is not set we can ignore or if
       * the button pressed is not the main one
       */
      if (event.button !== 0 || !pointerDownPosition.current) {
        return
      }

      /**
       * This was added to prevent the moving when the user wants to pan
       * the canvas and the pointer not being moved
       */
      const deltaX = (pointerDownPosition.current?.x || 0) - event.clientX
      const deltaY = (pointerDownPosition.current?.y || 0) - event.clientY
      const hasMouseMoved = Math.abs(deltaX) > 0 || Math.abs(deltaY) > 0

      /**
       * Removing the difference in positioning of the canvas to the window
       * so the events coordinates are from inside the canvas
       */
      const currentTargetPosition = event.currentTarget.getBoundingClientRect()
      const { pageX, pageY } = event.nativeEvent

      handlePointerUp(
        hasMouseMoved,
        pageX - currentTargetPosition.x,
        pageY - currentTargetPosition.y
      )
    },
    [handlePointerUp]
  )

  const touchDevice = isTouchDevice()

  if (touchDevice) {
    return {
      activeAnnotation,
      isPlacingDraftAnnotation: placeDraftAnnotation,
      onTouchStart: handleTouchStart,
      onTouchEnd: handleTouchEnd,
    }
  }

  return {
    activeAnnotation,
    isPlacingDraftAnnotation: placeDraftAnnotation,
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
  }
}
