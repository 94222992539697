import { useEffect, useRef } from 'react'
import { useFlag } from '@sketch/modules-common'

import {
  AnnotationReplyCreatedSubscription,
  AnnotationReplyDeletedSubscription,
  AnnotationReplyEditedSubscription,
  AnnotationReplyReadSubscription,
  useAnnotationReplyCreatedSubscription,
  useAnnotationReplyDeletedSubscription,
  useAnnotationReplyEditedSubscription,
  useAnnotationReplyReadSubscription,
} from '@sketch/gql-types'

type OnSubscription =
  | ['created', AnnotationReplyCreatedSubscription]
  | ['deleted', AnnotationReplyDeletedSubscription]
  | ['edited', AnnotationReplyEditedSubscription]
  | ['marked-as-read', AnnotationReplyReadSubscription]

const useCommentSubscriptions = (
  shareIdentifier: string,
  onSubscriptionData?: (...params: OnSubscription) => void
) => {
  const includeHasUnreadComments = useFlag('pages-unread-annotations')
  const variables = { shareIdentifier, includeHasUnreadComments }

  const onSubscriptionRef = useRef(onSubscriptionData)
  useEffect(() => {
    onSubscriptionRef.current = onSubscriptionData
  }, [onSubscriptionData])

  useAnnotationReplyCreatedSubscription({
    variables,
    onSubscriptionData: ({ subscriptionData }) => {
      subscriptionData.data &&
        onSubscriptionRef.current?.('created', subscriptionData.data)
    },
  })

  useAnnotationReplyDeletedSubscription({
    variables,
    onSubscriptionData: ({ subscriptionData }) =>
      subscriptionData.data &&
      onSubscriptionRef.current?.('deleted', subscriptionData.data),
  })

  useAnnotationReplyEditedSubscription({
    variables: { shareIdentifier },
    onSubscriptionData: ({ subscriptionData }) =>
      subscriptionData.data &&
      onSubscriptionRef.current?.('edited', subscriptionData.data),
  })

  useAnnotationReplyReadSubscription({
    variables,
    onSubscriptionData: ({ subscriptionData }) =>
      subscriptionData.data &&
      onSubscriptionRef.current?.('marked-as-read', subscriptionData.data),
  })
}

export default useCommentSubscriptions
