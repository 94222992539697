import { useMemo } from 'react'
import { PresentationManifestPage } from '@sketch-hq/sketch-web-renderer'
import { usePrototypeContext } from './usePrototypeContext'

/**
 * Returns the list of pages referenced in the manifest json.
 */
export function useManifestPages() {
  const { manifest } = usePrototypeContext()

  return useMemo(() => {
    if (!manifest) return null

    const pages: PresentationManifestPage[] = manifest.contents.pages

    return pages
  }, [manifest])
}
