import React, { FC } from 'react'

import { Pill, CrossIcon } from './DiscountPill.styles'

interface DiscountPillProps {
  value: string
  onDeleteClick?: () => void
}

const DiscountPill: FC<DiscountPillProps> = ({ value, onDeleteClick }) => {
  return (
    <Pill>
      <span>{value}</span>
      <CrossIcon data-testid="remove-discount-button" onClick={onDeleteClick} />
    </Pill>
  )
}

export default DiscountPill
