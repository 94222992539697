import styled from 'styled-components'
import BaseHeartButton from '../HeartButton/HeartButton'
import { Text } from '@sketch/components'

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 20px 12px 12px;
  border-radius: ${({ theme }) => theme.radii.large};
`

export const Name = styled(Text).attrs({
  textStyle: 'prominent.primary.E',
  overflow: 'ellipsis',
  $reset: true,
})``

export const NameSkeleton = styled(Name)`
  width: 40%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  border-radius: ${({ theme }) => theme.radii.xlarge};

  ::after {
    /* Have some dummy text to fill the height */
    content: 'a';
    opacity: 0;
  }
`

export const ThumbnailWrapper = styled.div`
  margin-right: 12px;

  width: 32px;
  height: 32px;
  position: relative;

  ::after {
    content: '';
    box-shadow: inset 0 0 1px 0 ${({ theme }) => theme.colors.border.A};
    border-radius: ${({ theme }) => theme.radii.xlarge};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export const HeartButton = styled(BaseHeartButton)`
  margin-left: auto;
  min-width: 64px;
`
