import { useCallback, useEffect, useRef } from 'react'
import { useMarkCommentsAsReadMutation } from '@sketch/gql-types'
import { useFlag } from '@sketch/modules-common'

const MARK_AS_READ_DELAY = 2000
const COMMENT_READ_LIST: string[] = []

const useMarkCommentAsRead = () => {
  const commentIdentifiers = useRef<string[]>([])
  const setTimeOutRef = useRef<NodeJS.Timeout | null>(null)
  const includeHasUnreadComments = useFlag('pages-unread-annotations')

  const [mutation] = useMarkCommentsAsReadMutation({
    onError: 'show-toast',
  })

  useEffect(() => {
    return () => {
      setTimeOutRef.current && clearTimeout(setTimeOutRef.current)
    }
  }, [])

  const queueMarkAsRead = useCallback(() => {
    setTimeOutRef.current = setTimeout(async () => {
      const unreadCommentIdentifiers = commentIdentifiers.current.filter(
        identifier => !COMMENT_READ_LIST.includes(identifier)
      )

      if (unreadCommentIdentifiers.length === 0) {
        // Clear the setTimeOut ref
        setTimeOutRef.current = null
        return
      }

      await mutation({
        variables: {
          commentIdentifiers: unreadCommentIdentifiers,
          includeHasUnreadComments,
        },
        update: () => {
          COMMENT_READ_LIST.push(...unreadCommentIdentifiers)
          // Clear the setTimeOut ref
          setTimeOutRef.current = null
        },
      })
    }, MARK_AS_READ_DELAY)
  }, [mutation, includeHasUnreadComments])

  return useCallback(
    (identifiers: string[]) => {
      commentIdentifiers.current.push(...identifiers)
      queueMarkAsRead()
    },
    [queueMarkAsRead]
  )
}

export default useMarkCommentAsRead
