import React from 'react'

import { Content, Item } from './ListRow.styles'

export interface ListRowWrapperProps
  extends React.ComponentPropsWithoutRef<'div'> {
  $hasWarningText?: boolean
}

// This component is the base implementation for the different rows
export const ListRowWrapper: React.FC<ListRowWrapperProps> = ({
  children,
  ...props
}) => (
  <Content {...props}>
    {React.Children.map(children, item => (
      <Item>{item}</Item>
    ))}
  </Content>
)
