import { DataProxy } from 'apollo-cache'
import { produce } from 'immer'
import {
  GetProjectsDocument,
  GetProjectsQuery,
  ProjectFragment,
  PinProjectMutation,
  UnpinProjectMutation,
} from '@sketch/gql-types'

type UpdatePinnedProjectProps = {
  cache: DataProxy
  data: PinProjectMutation
  workspaceId: string
}

type UpdateUnpinnedProjectProps = {
  cache: DataProxy
  data: UnpinProjectMutation
  workspaceId: string
}

export function updatePinnedProject({
  cache,
  data,
  workspaceId,
}: UpdatePinnedProjectProps) {
  const pinProjectId = data?.pinProject.project.identifier
  const pinnedByCurrentUserAt = new Date().toISOString()
  const existingProjects = cache.readQuery<GetProjectsQuery>({
    query: GetProjectsDocument,
    variables: { workspaceId },
  })

  // We always have a pinned project because it's already existing
  // The ! is mostly to fix some TS nonsense
  const pinnedProject = existingProjects?.workspace.projects.entries.find(
    (project: ProjectFragment) => project.identifier === pinProjectId
  )!

  const newProjects =
    existingProjects?.workspace.projects.entries.filter(
      (project: ProjectFragment) => project.identifier !== pinProjectId
    ) || []

  const newState = produce(existingProjects, (draftState: GetProjectsQuery) => {
    draftState.workspace.projects.entries = [
      {
        ...pinnedProject,
        pinnedByCurrentUserAt,
      },
      ...newProjects,
    ]
  })

  cache.writeQuery({
    query: GetProjectsDocument,
    data: newState,
  })
}

export function updateUnpinnedProject({
  cache,
  data,
  workspaceId,
}: UpdateUnpinnedProjectProps) {
  const unpinProjectId = data?.unpinProject.project.identifier
  const existingProjects = cache.readQuery<GetProjectsQuery>({
    query: GetProjectsDocument,
    variables: { workspaceId },
  })

  // We always have a pinned project because it's already existing
  // The ! is mostly to fix some TS nonsense
  const unpinnedProject = existingProjects?.workspace.projects.entries.find(
    (project: ProjectFragment) => project.identifier === unpinProjectId
  )!

  const newProjects =
    existingProjects?.workspace.projects.entries.filter(
      (project: ProjectFragment) => project.identifier !== unpinProjectId
    ) || []

  const newState = produce(existingProjects, (draftState: GetProjectsQuery) => {
    draftState.workspace.projects.entries = [
      ...newProjects,
      {
        ...unpinnedProject,
        pinnedByCurrentUserAt: null,
      },
    ]
  })

  cache.writeQuery({
    query: GetProjectsDocument,
    data: newState,
  })
}
