import styled, { css, ThemeProps, DefaultTheme } from 'styled-components'

import { IconContainer, PencilIcon, CrossIcon } from './Common.styles'

const inputCommonStyles = ({ theme }: ThemeProps<DefaultTheme>) => css`
  width: 100%;
  text-align: center;
  padding: 12px 40px 8px 40px;
  /* Avoid bouncing when focused */
  border: 2px solid transparent;
  border-bottom: 2px dotted ${theme.colors.background.tertiary.A};
  background: transparent;

  color: ${theme.colors.foreground.secondary.C};

  ::placeholder {
    color: ${theme.colors.state.disabled};
  }

  :focus {
    border-radius: 5px;
    padding: 12px 40px 8px 40px;

    ~ ${IconContainer} {
      right: 2px;
    }

    /* stylelint-disable-next-line selector-type-no-unknown */
    ~ ${IconContainer} ${PencilIcon} {
      opacity: 0;
      /*
      To have transition animations, we are using opacity instead of display.
      The opaque element needs to be behind the visible one in order to have
      click events on the desired element.
      */
      z-index: -1;
    }

    /* stylelint-disable-next-line selector-type-no-unknown */
    ~ ${IconContainer} ${CrossIcon} {
      opacity: 1;
      z-index: 1;
    }
  }

  :hover {
    /* stylelint-disable-next-line selector-type-no-unknown */
    ~ ${IconContainer} ${CrossIcon} {
      opacity: 1;
      z-index: 1;
    }
  }
`

export const InputStyled = styled.input`
  ${inputCommonStyles}
`

export const TextAreaStyled = styled.textarea`
  ${inputCommonStyles}

  resize: none;
  overflow: hidden;
`
