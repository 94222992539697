import React, { useState, ComponentPropsWithoutRef } from 'react'
import styled from 'styled-components'
import copy from 'copy-to-clipboard'
import { Placement } from '@popperjs/core'

import { Flex } from '../Box'
import { Tooltip } from '../Tooltip'
import { Button, IconButton } from '../Button'
import { ReactComponent as CopyIcon } from '@sketch/icons/squares.2.dotted'

export const ANIMATION_DURATION_IN_MILLISECONDS = 1500

type ButtonProps = ComponentPropsWithoutRef<typeof Button>

export interface IconProps {
  handleClick: () => void
  dropdown?: React.ReactNode
}

const Icon: React.FC<IconProps> = ({ handleClick, dropdown }) => (
  <Flex
    alignItems="center"
    maxHeight={17}
    maxWidth={17}
    padding="5px"
    marginTop="2px"
  >
    <IconButton
      type="button"
      icon={CopyIcon}
      size="17px"
      aria-label="Copy"
      title="Copy"
      onClick={handleClick}
    />
    {dropdown}
  </Flex>
)

const CopyButton = styled(Button)``

const CopyOnLabelIconWrapper = styled.div`
  opacity: 0;
`

const CopyOnLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  &:hover {
    ${CopyOnLabelIconWrapper} {
      opacity: 1;
    }
  }
`

export interface CopyProps extends ButtonProps {
  title?: string
  onCopy?: (value: string) => void
  value?: string
  placement?: Placement
  spacing?: string
  dropdown?: React.ReactNode
  className?: string
  showIcon?: boolean
  disabled?: boolean
  fullWidth?: boolean
}

export const CopyToClipboard: React.FC<CopyProps> = ({
  title = 'Copy',
  onCopy = () => {},
  placement = 'left',
  spacing = '10px',
  value = '',
  dropdown,
  showIcon = true,
  children,
  disabled,
  fullWidth,
  className,
  ...rest
}) => {
  const defaultTooltip = 'Copy to clipboard'
  const [copyTooltipText, setCopyTooltipText] = useState(defaultTooltip)
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const handleClick = () => {
    if (disabled) {
      return
    }

    setCopyTooltipText('Copied')
    setTooltipVisible(true)

    if (typeof value === 'string' || typeof value === 'number') {
      copy(`${value}`)
      onCopy(`${value}`)
    }

    window.setTimeout(() => {
      setTooltipVisible(false)
      setCopyTooltipText(defaultTooltip)
    }, ANIMATION_DURATION_IN_MILLISECONDS)
  }

  const handleMouseEnter = () => setTooltipVisible(true)
  const handleMouseLeave = () => setTooltipVisible(false)

  const getPopperContent = () => {
    if (dropdown || fullWidth) {
      return children ? (
        <CopyOnLabelContainer
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {children}
          <CopyOnLabelIconWrapper>
            {showIcon ? (
              <Icon handleClick={handleClick} dropdown={dropdown} />
            ) : (
              dropdown
            )}
          </CopyOnLabelIconWrapper>
        </CopyOnLabelContainer>
      ) : (
        <Icon handleClick={handleClick} dropdown={dropdown} />
      )
    }

    if (showIcon) {
      return (
        <IconButton
          type="button"
          icon={CopyIcon}
          size="17px"
          aria-label={title}
          title={title}
          onClick={handleClick}
        >
          &nbsp;{title}
        </IconButton>
      )
    }

    return (
      <CopyButton
        className={className}
        aria-label={title}
        onClick={handleClick}
        {...rest}
      >
        {title}
      </CopyButton>
    )
  }

  return (
    <Tooltip
      className="CopyToClipboard"
      visible={tooltipVisible}
      placement={placement}
      spacing={spacing}
      content={copyTooltipText}
    >
      {getPopperContent()}
    </Tooltip>
  )
}
