import React, { useMemo } from 'react'
import { Route } from 'react-router'
import styled from 'styled-components'

import { dataIdFromObject } from '@sketch/graphql-cache'

import { MountHandle } from '@sketch/utils'
import WorkspaceList from './WorkspaceList'
import SettingsForm from './SettingsForm'
import {
  TwoFactorAuthenticationPanel,
  EmailNotificationsPanel,
} from '@sketch/user'
import SessionsManagementPanel from './SessionsManagementPanel'
import {
  LinkButton,
  Section,
  handleFetchMore,
  useModalContext,
} from '@sketch/components'
import { OtherOptionsPanel } from './OtherOptionsPanel'
import { LinkNonSSO } from './LinkNonSSO'

// Modals
import LeaveWorkspaceModal from './LeaveWorkspaceModal'

import {
  routes,
  getPersonalAuthorization,
  isSsoAuthorization,
  useUserAuthorizations,
  useUserProfile,
  getIsForInAppPresentation,
} from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'

import {
  useGetUserSettingsQuery,
  useUserUpdateMutation,
  InitialUserFragment,
  UserWorkspaceMembershipFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'
// Usage of WorkspaceMembership is needed for SSOWorkspace
// eslint-disable-next-line no-restricted-imports
import { WorkspaceMembership } from '@sketch/gql-types/expansive'

const createOptimisticResponse = (
  user: InitialUserFragment,
  receiveEmailNotifications: boolean
) => ({
  __typename: 'RootMutationType' as const,
  userUpdate: {
    __typename: 'UserUpdatePayload' as const,
    errors: [],
    successful: true,
    user: {
      ...user,
      receiveEmailNotifications,
    },
  },
})

const RelativeContainer = styled.div`
  position: relative;
  margin-bottom: 64px;
`

const GeneralSettings: React.FC<{
  currentWorkspace?: WorkspaceMinimalFragment
}> = ({ currentWorkspace }) => {
  const { showModal } = useModalContext()
  const { showToast } = useToast()
  const { data: userData } = useUserProfile()
  const { authorizations, hasPersonalAuthorization } = useUserAuthorizations()
  const isForInAppPresentation = getIsForInAppPresentation()

  const [updateUser, { called }] = useUserUpdateMutation({
    redirectErrors: false,
    onCompleted: data => {
      const { errors, successful } = data.userUpdate || {}
      if (successful) {
        showToast('Profile settings updated')
      }

      if (errors?.length! > 0) {
        const errorMessage = errors!.map(error => error?.message).join('; ')
        showToast(errorMessage, 'negative')
      }
    },
    onError: error => {
      showToast(error.message, 'negative')
    },
  })

  const { data: userSettingsData, fetchMore } = useGetUserSettingsQuery()

  const handleLoadMore = handleFetchMore(
    fetchMore,
    ['me', 'workspaceMemberships', 'entries'],
    {
      after: userSettingsData?.me?.workspaceMemberships?.meta.after,
      dataIdFromObject,
    }
  )

  // Merge the user objects, more precisely get the "newEmail".
  const user = {
    ...userData!.me!,
    ...userSettingsData?.me!,
  }

  const memberships: UserWorkspaceMembershipFragment[] =
    userSettingsData?.me?.workspaceMemberships?.entries || []

  const hasMASubscription = memberships.some(
    membership =>
      membership?.workspace?.customer?.source === 'macappstore' &&
      membership?.workspace?.userIsOwner
  )
  const isSignedInWithSso = authorizations.some(isSsoAuthorization)

  const isSsoOnly = !getPersonalAuthorization()

  const workspaceName =
    userSettingsData?.me?.workspaceMemberships?.entries[0]?.workspace?.name ||
    ''

  const SSOWorkspace = useMemo(() => {
    return userSettingsData?.me?.workspaceMemberships?.entries.find(
      membership => membership.workspace.customer?.ssoEnabled
    )
  }, [userSettingsData])

  const showLinkNonSso =
    !!SSOWorkspace && (isSsoOnly || (!isSsoOnly && user.hasPersonalIdentity))

  // Show the subscribe to email comments IF the user has personal identity OR it's a business workspace
  const showEmailNotificationsPanel =
    user.hasPersonalIdentity || currentWorkspace?.type === 'BUSINESS'

  return (
    <>
      <Route
        path={routes.PERSONAL_SETTINGS_UNSUBSCRIBE.template()}
        render={({ history }) => (
          <MountHandle
            onMount={async () => {
              // Prevent calling the mutation again if it's already called (when mounting)
              // Related to https://github.com/sketch-hq/Cloud/issues/6102#issuecomment-804823199
              if (called) {
                return
              }

              user.hasPersonalIdentity &&
                (await updateUser({
                  variables: { input: { receiveEmailNotifications: false } },
                  optimisticResponse: createOptimisticResponse(user, false),
                }))

              history.replace(routes.PERSONAL_SETTINGS.create({}))
            }}
          />
        )}
      />
      <RelativeContainer>
        <Section title="Your Account Settings">
          {showLinkNonSso && currentWorkspace && (
            <LinkNonSSO
              linkedEmail={user.personalIdentityEmail}
              workspaceName={workspaceName}
              currentWorkspace={currentWorkspace}
              SSOWorkspace={SSOWorkspace as WorkspaceMembership}
            />
          )}
          <SettingsForm
            userName={user.name!}
            userEmail={user.email!}
            userNewEmail={user.newEmail}
            userAvatarSrc={user.avatar?.large || user.avatar!.small!}
            userHasAvatar={user.hasAvatar!}
            userHasPersonalIdentity={user.hasPersonalIdentity}
            userHasPersonalAuthorization={hasPersonalAuthorization}
            isSignedInWithSso={isSignedInWithSso}
            onUserUpdate={updateUser}
          />
        </Section>
      </RelativeContainer>

      {/** 2FA Panel */}
      {!isSsoOnly && (
        <TwoFactorAuthenticationPanel
          mfaState={user.mfaState}
          isVerified={user.isVerified}
        />
      )}

      {memberships.length > 0 && (
        <WorkspaceList
          memberships={memberships}
          onLoadMore={handleLoadMore}
          onLeaveWorkspace={membership => {
            showModal(LeaveWorkspaceModal, {
              membershipId: membership.identifier,
              workspace: membership.workspace,
              userHasPersonalIdentity: user.hasPersonalIdentity,
            })
          }}
          linkButton={
            hasPersonalAuthorization &&
            !hasMASubscription &&
            !isForInAppPresentation && (
              <LinkButton
                to={routes.WORKSPACE_CREATE.create({})}
                variant="primary"
                size="40"
              >
                Create Workspace&hellip;
              </LinkButton>
            )
          }
        />
      )}

      {/* Sessions Management Panel */}
      <SessionsManagementPanel />

      {showEmailNotificationsPanel && (
        <EmailNotificationsPanel
          receiveEmailNotifications={user.receiveEmailNotifications!}
        />
      )}

      <OtherOptionsPanel hasPersonalIdentity={user.hasPersonalIdentity} />
    </>
  )
}

export default GeneralSettings
