import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "notes.64", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 64 64", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { id: "Universal/Monochrome/64x64/img.notes.64", stroke: "none", strokeWidth: 1, fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "Group", transform: "translate(8.5 8.5)" },
            React.createElement("path", { d: "M12.82 0h21.36c4.458 0 6.075.464 7.704 1.336a9.086 9.086 0 013.78 3.78C46.536 6.746 47 8.362 47 12.819v21.362c0 4.457-.464 6.074-1.336 7.703a9.086 9.086 0 01-3.78 3.78c-1.63.872-3.246 1.336-7.703 1.336H12.819c-4.457 0-6.074-.464-7.703-1.336a9.086 9.086 0 01-3.78-3.78C.464 40.254 0 38.638 0 34.181V12.819c0-4.457.464-6.074 1.336-7.703a9.086 9.086 0 013.78-3.78C6.746.464 8.362 0 12.819 0z", id: "Rectangle", stroke: "currentColor", strokeWidth: 3 }),
            React.createElement("path", { id: "Rectangle", fill: "currentColor", d: "M0 12.5h47v3H0z" }),
            React.createElement("path", { d: "M13.5 14v9.5H47v3H13.5v8H47v3H13.5V47h-3v-9.5H0v-3h10.5v-8H0v-3h10.5V14h3z", id: "Combined-Shape", fill: "currentColor", opacity: 0.35 }),
            React.createElement("path", { d: "M9.6 0h27.8A9.6 9.6 0 0147 9.6V14H0V9.6A9.6 9.6 0 019.6 0z", id: "Rectangle", fill: "currentColor", opacity: 0.2 }))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
