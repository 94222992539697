import React, { useCallback } from 'react'

import { addProjectMembership } from '../../../operations/utils/projectMemberships.cache'

import { LoadPageSeparator } from '@sketch/components'
import { AddMemberForm } from '../ProjectSharingForm'

import {
  FormLabel,
  RadioLabel,
  StyledRadioButton,
  StyledSeparator,
} from './WorkspacePanel.styles'

// GraphQL
import {
  WorkspaceMinimalFragment,
  useAddProjectMemberMutation,
  ProjectInSidebarAndHeaderFragment,
  GetProjectMembershipsDocument,
  useUpdateProjectPermissionMutation,
  useAddProjectPermissionGroupRelationMutation,
} from '@sketch/gql-types'
import { ShareParentName } from '../../../../shares/modals/SharingModal/SharingModal.styles'
import PermissionGroupRow from './PermissionGroupRow'
import useProjectMemberships from '../useProjectMemberships'
import { FieldAutoCompleteFormValues } from '@sketch/modules-common'
import { ProjectMemberRow } from './WorkspaceMemberRow'
import { ProjectWorkspaceAccessLevel } from '../../../types'

interface FormValues extends FieldAutoCompleteFormValues {
  guestCommentsEnabled: boolean
  guestInspectEnabled: boolean
}

interface WorkspacePanelProps {
  project: ProjectInSidebarAndHeaderFragment
  workspace: WorkspaceMinimalFragment
  editable?: boolean
  hasAccess: boolean
  setWorkspaceAccessLevel: React.Dispatch<
    React.SetStateAction<ProjectWorkspaceAccessLevel>
  >
}

export const WorkspacePanel: React.FC<WorkspacePanelProps> = ({
  project,
  workspace,
  editable = true,
  setWorkspaceAccessLevel,
  hasAccess,
}) => {
  const [updateProjectPermission] = useUpdateProjectPermissionMutation({
    onError: 'show-toast',
    update(_, response) {
      setWorkspaceAccessLevel(
        response.data?.updateProjectPermission.project.workspaceAccessLevel ??
          'DEFAULT'
      )
    },
  })

  const [addMemberToProject] = useAddProjectMemberMutation({
    onError: 'show-toast',
    update(cache, response) {
      if (!response.data?.addProjectMember.projectMembership) {
        return
      }

      addProjectMembership({
        cache,
        projectIdentifier: project.identifier,
        membership: response.data?.addProjectMember.projectMembership,
      })
    },
  })

  const [addGroupToProject] = useAddProjectPermissionGroupRelationMutation({
    onError: 'show-toast',
    awaitRefetchQueries: true,
    // We refetch the ProjectsMemberships query to update the list of members for this project
    // This was the easiest way to update the cache with the new group membership since the response
    // doesn't return a list of members or groups but a relationship identifier
    refetchQueries: [
      {
        query: GetProjectMembershipsDocument,
        variables: { projectIdentifier: project.identifier },
      },
    ],
  })

  const { members, after, loadNewPage } = useProjectMemberships(
    project.identifier
  )

  const handleChangeWorkspaceAccessLevel = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const workspaceAccessLevel = e.target.value as ProjectWorkspaceAccessLevel

    await updateProjectPermission({
      variables: {
        projectIdentifier: project.identifier,
        workspaceAccessLevel,
      },
    })
  }

  const handleAddMember = useCallback(
    async (values: FormValues) => {
      const variables = {
        projectIdentifier: project.identifier,
        email: values.email || undefined,
        workspaceMembershipIdentifier:
          values.workspaceMembershipIdentifier || undefined,
        guestCommentsEnabled: values.guestCommentsEnabled,
        guestInspectEnabled: values.guestInspectEnabled,
      }
      await addMemberToProject({ variables })
    },
    [addMemberToProject, project.identifier]
  )

  const handleAddGroup = useCallback(
    async (values: FormValues) => {
      if (!values.groupIdentifier) return

      const variables = {
        permissionGroupIdentifier: values.groupIdentifier,
        projectIdentifier: project.identifier,
      }

      await addGroupToProject({ variables })
    },
    [project.identifier, addGroupToProject]
  )

  return (
    <>
      <RadioLabel>
        Members of “<ShareParentName>{workspace.name}</ShareParentName>”
        workspace:
      </RadioLabel>
      <StyledRadioButton
        name="workspaceAccessLevel"
        label="Can edit or view documents in this project"
        checked={hasAccess}
        value="DEFAULT"
        onChange={handleChangeWorkspaceAccessLevel}
      />
      <StyledRadioButton
        name="workspaceAccessLevel"
        label="Cannot access unless invited to this project"
        checked={!hasAccess}
        value="NONE"
        onChange={handleChangeWorkspaceAccessLevel}
      />

      {!hasAccess && (
        <>
          <StyledSeparator />

          <FormLabel>
            Invite other “<ShareParentName>{workspace.name}</ShareParentName>”
            members:
          </FormLabel>
          <AddMemberForm
            hideLabel
            workspace={workspace}
            projectIdentifier={project.identifier}
            onMemberAdd={handleAddMember}
            onGroupAdd={handleAddGroup}
            filterMembers={
              workspace.features.permissionGroupsEnabled
                ? 'Members and Groups'
                : 'Members'
            }
          />

          <>
            {members.map(member => {
              if (member.__typename === 'PermissionGroupRelationProject') {
                return (
                  <PermissionGroupRow
                    membership={member}
                    key={member.identifier}
                  />
                )
              }

              return (
                <ProjectMemberRow
                  key={member.identifier}
                  member={member}
                  project={project}
                  editable={editable}
                  workspaceIdentifier={workspace.identifier}
                />
              )
            })}

            {after && (
              <LoadPageSeparator key={after} loadNewPage={loadNewPage} />
            )}
          </>
        </>
      )}
    </>
  )
}
