import { AnalyticsContext } from '@sketch/modules-common'

import { ServiceWorkerContextOnPageLoad } from './getContextOnPageLoad'

const toString = (value: boolean) => String(value) as 'true' | 'false'

export interface ReportOnPageLoadProps {
  analytics: AnalyticsContext
  context: ServiceWorkerContextOnPageLoad
  isSwEnabled: boolean
}

export const reportMetricsOnPageLoad = async (props: ReportOnPageLoadProps) => {
  const { isSwEnabled, context, analytics } = props
  const { trackMetric } = analytics

  trackMetric({
    id: 'webapp_sw_state_on_page_load_count',
    value: 1,
    labels: {
      is_sw_supported: toString('serviceWorker' in navigator),
      is_quota_supported: toString(!!context?.quota),
      is_ff_enabled: toString(isSwEnabled),
      is_running: toString(!!context?.state?.isRunning),
    },
  })

  const quota = context?.quota

  if (quota && quota.quota)
    trackMetric({
      id: 'webapp_storage_quota_bytes',
      value: quota.quota,
    })

  if (quota && quota.usage)
    trackMetric({
      id: 'webapp_storage_usage_bytes',
      value: quota.usage,
    })
}
