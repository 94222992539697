import React from 'react'
import styled, { css } from 'styled-components'

type Size = string | number

interface AvatarWrapperProps {
  size?: Size
}

interface AvatarAdditionalProps {
  className?: string
  'data-testid'?: string
  name: string
  src?: string | null
}

export type AvatarProps = AvatarWrapperProps & AvatarAdditionalProps

const AvatarWrapper = styled.span.attrs<AvatarWrapperProps>(props => ({
  style: {
    width: props.size,
    height: props.size,
    fontSize: props.size,
  },
}))<AvatarWrapperProps>`
  display: block;

  border-radius: 50%;
  position: relative;
  overflow: hidden;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
`

const absoluteFullItems = css`
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
`

export const AvatarPlaceholder = styled.span`
  ${absoluteFullItems};

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: ${({ theme }) => theme.fontSizes.C};
  font-weight: 500;
  text-transform: uppercase;

  /* This is a valid use case to not use a design token, it's a white letter
  inside an orange circle */
  color: white;
  background-color: ${({ theme }) => theme.colors.sketch.A};
`

const AvatarImage = styled.img`
  ${absoluteFullItems};
  background-color: ${({ theme }) => theme.colors.background.secondary.B};

  object-fit: cover;
  object-position: center;
`

/* eslint-disable react/display-name */

/**
 * Avatar
 *
 * Shows the user avatar image if has the image source, if not the first letter of the user name
 *
 * @param props - Component Props.
 *
 * @param {string} [props.className] - Additional styling class for the AvatarWrapper.
 * @param {string} [props.name] - The user name.
 * @param {string} [props.src] - The source of the user image.
 * @param {Size} [props.size] - Size of the Avatar
 *
 * @returns {React.ReactElement} React element.
 *
 * @example
 *
 * // Example without Image
 * <Avatar name="Pieter" />
 *
 * // Example with Image
 * <Avatar name="Pieter" src="https://pieter.image.com" />
 *
 */
export const Avatar: React.FC<AvatarProps> = React.forwardRef<
  HTMLElement,
  AvatarProps
>((props, ref) => {
  const {
    className,
    'data-testid': dataTestId,
    name,
    src,
    size,
    ...otherProps
  } = props

  let content
  if (!src) {
    content = (
      <AvatarPlaceholder data-testid={dataTestId}>
        {name.charAt(0)}
      </AvatarPlaceholder>
    )
  } else {
    content = <AvatarImage data-testid={dataTestId} src={src} alt={name} />
  }

  return (
    <AvatarWrapper className={className} size={size} ref={ref} {...otherProps}>
      {content}
    </AvatarWrapper>
  )
})

Avatar.defaultProps = {
  name: '',
  size: '28px',
}
