import { useVersionUpdateKind } from './useVersionUpdateKind'
import { useToast } from '@sketch/toasts'
import { useModalContext } from '@sketch/components'
import { StarUpdateModal } from '../modals/StarUpdateModal'

export interface UseVersionStarProps {
  versionIdentifier: string
  shareIdentifier: string
  description?: string
  isLibrary: boolean
  isNewLatest: boolean
}

export const useVersionStar = (props: UseVersionStarProps) => {
  const { versionIdentifier, ...modalProps } = props
  const { showModal } = useModalContext()
  const { showToast } = useToast()

  const shouldSkipModal: boolean =
    JSON.parse(
      localStorage.getItem('user:preferences:skipStarModal') || 'false'
    ) ?? false

  const [versionKindUpdate] = useVersionUpdateKind({
    onCompleted: () => {
      showToast('Version starred')
    },
    onError: message => showToast(message, 'negative'),
  })

  if (shouldSkipModal) {
    return [
      () =>
        versionKindUpdate({
          versionIdentifier,
          kind: 'PUBLISHED',
        }),
    ]
  }

  return [
    () =>
      showModal(StarUpdateModal, {
        versionIdentifier: props.versionIdentifier,
        ...modalProps,
      }),
  ]
}
