import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "exclamation-mark-triangle-24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fillRule: "evenodd", stroke: "currentColor" },
        React.createElement("path", { d: "M14.25 4.752l7.365 12.272A2.625 2.625 0 0119.364 21H4.636a2.625 2.625 0 01-2.25-3.976L9.748 4.752a2.625 2.625 0 014.502 0z", strokeWidth: 1.5, fillOpacity: 0.15 }),
        React.createElement("path", { d: "M12 7.5a1.313 1.313 0 011.302 1.487l-.736 5.517a.571.571 0 01-1.132 0l-.736-5.517A1.313 1.313 0 0112 7.5z" }),
        React.createElement("circle", { cx: 12, cy: 17.5, r: 1.25 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
