import React, { useRef, useState, useImperativeHandle, useEffect } from 'react'
import styled from 'styled-components'

import BaseAnnotationListWrapper from './AnnotationListWrapper.styles'

import { lightTheme as theme } from '@sketch/global-styles'

export interface AnnotationListWrapperProps {
  className?: string
  reverse?: boolean
  'data-testid'?: string
  $blockScroll?: boolean
}

const AnnotationListWrapper = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<AnnotationListWrapperProps>
>(function AnnotationListWrapper(
  {
    children,
    reverse,
    $blockScroll,
    'data-testid': dataTestId = 'annotation-list',
    ...listProps
  },
  ref
) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  useImperativeHandle(ref, () => wrapperRef.current!)
  const [isTitleContainerSticky, setIsTitleContainerSticky] = useState(false)

  useEffect(() => {
    const element = wrapperRef.current

    const handleScroll = () => {
      if (element) {
        const sticky = reverse
          ? element.scrollTop >= theme.header.height
          : element.getBoundingClientRect().top <= theme.header.height

        setIsTitleContainerSticky(sticky)
      }
    }

    window.addEventListener('scroll', handleScroll, { capture: true })

    return () => {
      window.removeEventListener('scroll', handleScroll, { capture: true })
    }
  }, [reverse])
  return (
    <BaseAnnotationListWrapper
      data-testid={dataTestId}
      ref={wrapperRef}
      $blockScroll={$blockScroll}
      $isTitleSticky={isTitleContainerSticky}
      {...listProps}
    >
      {children}
    </BaseAnnotationListWrapper>
  )
})

export default styled(AnnotationListWrapper)``
