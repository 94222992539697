import styled, { css } from 'styled-components'

import { Text, PersonAvatar, ListHeader, Dropdown } from '@sketch/components'

import { ReactComponent as InformationIcon } from '@sketch/icons/information-outline-16'
import { ReactComponent as CanvasPageSvg } from '@sketch/icons/canvas-page-16'
import { ReactComponent as FlagSvg } from '@sketch/icons/flag.16'
import { ReactComponent as PrototypeSvg } from '@sketch/icons/prototype-hotspot-16'

const iconStyles = css`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  width: 16px;
  height: 16px;
  flex: none;
`

export const PageIcon = styled(CanvasPageSvg)`
  ${iconStyles}
`

export const PrototypeIcon = styled(PrototypeSvg)`
  ${iconStyles}
`

export const PrototypeStartPointIcon = styled(FlagSvg)`
  ${iconStyles}
`

const BaseSection = styled.div``

export const Section = styled(BaseSection)`
  & + ${BaseSection} {
    border-top: 1px solid ${({ theme }) => theme.colors.border.B};
  }
`

export const HeaderSection = styled(Section)`
  padding: 16px 20px 25px; /* stylelint-disable-line scales/space */
`

export const Copy = styled(Text.Span).attrs({
  textStyle: 'copy.quaternary.standard.C',
})`
  display: inline-block;
  line-height: 1.333; /* stylelint-disable-line scales/line-height */

  margin-bottom: 4px;
`

export const InfoIcon = styled(InformationIcon)`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  width: 16px;
  height: 16px;
  vertical-align: text-top;

  &[aria-selected='true'],
  .active & {
    color: ${({ theme }) => theme.colors.sketch.A};
  }
`

export const LinkSection = styled(Section)`
  padding: 8px 8px 16px;
`

export const AuthorSection = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const AuthorWrapper = styled.div`
  display: flex;
  align-items: center;

  ${PersonAvatar} {
    margin-right: 8px;
  }
`

export const HeaderListItem = styled(ListHeader)`
  pointer-events: none;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

export const LinkListPrototypeCards = styled.div`
  display: flex;
  padding-top: 8px;
  padding-left: 12px;
`

export const LinkListPrototypeCard = styled.div`
  width: 80px;

  :not(:first-child) {
    margin-left: 8px;
  }
`

export const StyledDropdownNavLink = styled(Dropdown.Item.NavLink)`
  align-items: center;
  display: flex;

  svg {
    color: ${({ theme }) => theme.colors.foreground.secondary.D};
    width: 16px;
    height: 16px;
    margin-right: 12px;
    /* Adjust the icons */
    margin-bottom: 1px; /* stylelint-disable scales/space */
  }

  /* We need to set back the active/hover states
  because we override them for svg */
  && {
    &.focus,
    &.active,
    &[data-current] {
      svg {
        color: ${({ theme }) => theme.colors.sketch.A};
      }
    }
  }
`
