import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import {
  Flex,
  FlexProps,
  truncateStyles,
  TruncateWithTooltip,
} from '@sketch/components'
import { Image as ImageUnstyled } from '@sketch/modules-common'
import { Chevron } from '../TextOrLayerStyles'

import { getArtboardThumbnail } from '../../../../../utils/getArtboardThumbnail'

import { useGetDetailViewArtboardQuery } from '@sketch/gql-types'
import { Header, HeaderTitle, Section, Separator } from '../../components'
import { ArtboardDetailInspectorSymbolLink } from '../../../ArtboardDetailInspectorSymbolLink'
import { useFetchSymbolArtboard } from '../../../../hooks/inspector'
import {
  SymbolMaster,
  ElementType,
  SketchLayerElement,
} from '../../../../../../inspector'
import { StyledSkeleton } from '../../ArtboardDetailInspector.styles'
import { useComponentsState } from '../../../../../components/ComponentsStateContext'

const SymbolDetail = styled(Flex)<FlexProps & SymbolBoxProps>(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.D};
    color: ${theme.colors.foreground.secondary.C};
    border: 1px solid ${theme.colors.border.A};
    border-radius: 5px;
    ${truncateStyles};
  `
)

interface SymbolBoxProps extends FlexProps {
  isClickable?: boolean
}

const SymbolBox = styled(SymbolDetail)<SymbolBoxProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  cursor: ${props => (props.isClickable ? 'pointer' : 'auto')};
`

const SymbolContainer = styled(Flex)<FlexProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-weight: 400;
  line-height: 1.2;
  ${truncateStyles};
`

const SymbolPath = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.foreground.secondary.D};
    font-size: ${theme.fontSizes.B};
  `
)

const SymbolThumbnail = styled(ImageUnstyled)`
  display: flex;
  justify-content: center;

  img {
    max-width: 32px;
    max-height: 32px;
  }
`

const SymbolTruncateTooltip = styled.span`
  word-break: break-all;
`

interface SymbolProps {
  layerElement: SketchLayerElement
}

export const SymbolInSidebar: React.FC<SymbolProps> = ({ layerElement }) => {
  const componentsState = useComponentsState()

  const symbolLayerElement =
    layerElement.type === ElementType.SymbolInstance
      ? layerElement
      : layerElement.parentSymbolLayerElement!

  const { displayedName, symbolPath } = useDisplayedLayerNameAndOptionalPath(
    symbolLayerElement.symbolMaster
  )

  const {
    shareIdentifier: symbolMasterShareIdentifier,
    permanentArtboardShortId: symbolMasterPermanentArtboardShortId,
    versionShortId: symbolMasterVersionShortId,
  } = useFetchSymbolArtboard(symbolLayerElement.symbolMaster)

  const symbolThumbnail = useSymbolArtboardThumbnail(
    symbolMasterShareIdentifier,
    symbolMasterPermanentArtboardShortId,
    symbolMasterVersionShortId
  )

  // When the user does not have access to a document where a symbol exists
  // We expect symbolMasterPermanentArtboardShortId to be undefined.
  const isSymbolLink = Boolean(symbolMasterPermanentArtboardShortId)

  const symbolDetail = (
    <SymbolBox isClickable={isSymbolLink}>
      {symbolThumbnail && (
        <Flex width="32px" marginRight={2}>
          <SymbolThumbnail src={symbolThumbnail} alt="thumbnail" />
        </Flex>
      )}
      <SymbolContainer
        marginRight={2}
        data-testid="inspector-sidebar-symbol-info"
      >
        <TruncateWithTooltip>{displayedName}</TruncateWithTooltip>
        <SymbolPath data-testid="inspector-sidebar-symbol-path">
          <TruncateWithTooltip placement="bottom-start">
            <SymbolTruncateTooltip>{symbolPath}</SymbolTruncateTooltip>
          </TruncateWithTooltip>
        </SymbolPath>
      </SymbolContainer>
      {isSymbolLink ? <Chevron /> : null}
    </SymbolBox>
  )

  return (
    <>
      <Separator />
      <Section data-testid="inspector-sidebar-symbol">
        <Header>
          <HeaderTitle>Symbol</HeaderTitle>
        </Header>
        {componentsState === 'PROCESSING' ? (
          <StyledSkeleton />
        ) : (
          <ArtboardDetailInspectorSymbolLink
            permanentArtboardShortId={symbolMasterPermanentArtboardShortId}
            shareIdentifier={symbolMasterShareIdentifier}
            symbolMaster={symbolLayerElement.symbolMaster}
          >
            {symbolDetail}
          </ArtboardDetailInspectorSymbolLink>
        )}
      </Section>
    </>
  )
}

/**
 * The name displayed in the overview is sometimes more than just the layer name.
 * Symbols can be named in Sketch with Slashes in the name.
 * E.g. `Primitive/Icon/Arrow/Right`. Slashes in symbol names act like groups,
 * and the name gets divided into symbolMasterNamePath and symbolMasterShortName
 * if that's the case.
 */
function useDisplayedLayerNameAndOptionalPath(
  symbolMaster: SymbolMaster
): { displayedName: string; symbolPath: string | undefined } {
  return useMemo(() => {
    const docName = symbolMaster.isForeign
      ? symbolMaster.documentName
      : 'This Document'
    if (
      symbolMaster.symbolMasterNamePath?.length &&
      symbolMaster.symbolMasterShortName
    ) {
      const symbolNamePathWithDocument = `${docName}/${symbolMaster.symbolMasterNamePath.join(
        '/'
      )}`
      return {
        displayedName: symbolMaster.symbolMasterShortName,
        symbolPath: symbolNamePathWithDocument,
      }
    }

    return {
      displayedName: symbolMaster.name,
      symbolPath: docName,
    }
  }, [symbolMaster])
}

function useSymbolArtboardThumbnail(
  symbolMasterShareIdentifier: string | undefined,
  symbolMasterPermanentArtboardShortId: string | undefined,
  symbolMasterVersionShortId: string | undefined
) {
  const { data } = useGetDetailViewArtboardQuery({
    shouldInvalidatePrevious: (prev, curr) =>
      prev?.permanentArtboardShortId !== curr?.permanentArtboardShortId,
    // To account for artboard updates we're setting a cache-and-network
    // policy for artboard queries without an explicit version.
    fetchPolicy: symbolMasterVersionShortId
      ? 'cache-first'
      : 'cache-and-network',
    variables: {
      shareIdentifier: symbolMasterShareIdentifier ?? '',
      permanentArtboardShortId: symbolMasterPermanentArtboardShortId ?? '',
      documentVersionShortId: symbolMasterVersionShortId,
    },
    skip:
      !symbolMasterShareIdentifier ||
      !symbolMasterPermanentArtboardShortId ||
      !symbolMasterVersionShortId,
  })

  return useMemo(() => {
    const symbolThumbnail = data?.artboard
      ? getArtboardThumbnail(data.artboard, 'S')
      : null

    return symbolThumbnail
  }, [data?.artboard])
}
