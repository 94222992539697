import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { ListItemSkeleton } from '@sketch/components'

interface SidebarItemProps {
  $hasUnreadComments?: boolean
}

export const SidebarItem = styled(NavLink)<SidebarItemProps>`
  display: block;
  margin: 0px 8px;
  padding: 8px 12px;
  font-size: ${({ theme }) => theme.fontSizes.D};
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  border-radius: 5px;

  &:hover {
    background: ${({ theme }) => theme.colors.background.tertiary.C};
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  &.active {
    color: ${({ theme }) => theme.colors.sketch.A};
    background: ${({ theme }) => theme.colors.sketch.F};
  }

  ${({ $hasUnreadComments, theme }) =>
    $hasUnreadComments &&
    css`
      font-weight: ${theme.fontWeights.semibold};
    `}
`

export const Skeleton = styled(ListItemSkeleton)`
  pointer-events: none;
  > span {
    height: 19px;
    margin: 8px 20px;
  }
`
