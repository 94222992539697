import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "editor-list-bullet-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, strokeLinecap: "round", d: "M10.5 11.75h8.25m-8.25 6h8.25m-8.25-12h8.25" }),
        React.createElement("circle", { fill: "currentColor", cx: 6, cy: 5.75, r: 1.5 }),
        React.createElement("circle", { fill: "currentColor", cx: 6, cy: 11.75, r: 1.5 }),
        React.createElement("circle", { fill: "currentColor", cx: 6, cy: 17.75, r: 1.5 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
