import styled from 'styled-components'

import { Modal } from '@sketch/components'

export const ModalBody = styled(Modal.Body)`
  word-break: break-word;
`

export const NoteTitle = styled.strong`
  display: inline-block;
  padding-bottom: 4px;
`

export const Note = styled.div`
  padding: 16px;
  font-size: ${({ theme }) => theme.fontSizes.D};
  border-radius: ${({ theme }) => theme.radii.xxxlarge};
  background-color: ${({ theme }) => theme.colors.state.neutral.F};
`
