import styled from 'styled-components'
import { Checkbox, Button, Text, Form } from '@sketch/components'
import { Callout } from '../Callout'

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledButton = styled(Button)`
  margin: 48px 0 24px 0;
`

export const Fieldset = styled.fieldset`
  width: 100%;
  padding: 0;
  border: 0;
`

export const UpgradeCallout = styled(Callout)`
  margin-top: 64px;
`

export const UpgradeRadioButtons = styled.div`
  display: flex;
  flex-direction: row;

  & > label {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
    margin-right: 16px;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  width: 100%;
  /*
  Prevent the TOS link from being "blocked" by the input.
  */
  input {
    width: auto;
  }
`

export const ToSFieldSet = styled(Fieldset)`
  margin-top: 64px;
  padding-top: 64px;

  border-top: 1px solid ${({ theme }) => theme.colors.border.A};
`

export const ExtrasTitle = styled(Text).attrs({
  textStyle: 'copy.quaternary.standard.C',
})`
  margin: 0;
`

export const DescriptionWrapper = styled.div`
  margin-bottom: 12px;
`
