import React, { forwardRef } from 'react'

import { Box } from '../Box'
import { LoadingPlaceholder } from '../LoadingPlaceholder'

// Icon
import { ReactComponent as Checkmark } from '@sketch/icons/checkmark-16'

import {
  Container,
  CheckboxInput,
  HiddenInput,
  CheckmarkIcon,
  CheckboxLabel,
  CheckboxLabelText,
  IconWrapper,
  LoadingWrapper,
  SuccessWrapper,
  Caption,
} from './Checkbox.styles'

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode
  label?: string
  className?: string
  disabled?: boolean
  checked?: boolean
  help?: string | React.ReactNode
  loading?: boolean
  success?: boolean
  variant?: 'primary' | 'untinted'
}

/**
 * Checkbox primitive is a lightweight wrapper around a native checkbox with a custom style and some extra capabilities like help message.
 *
 * It should be used as a controlled component using `checked` and `onChange`.
 *
 */
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      checked,
      children,
      disabled,
      className,
      label,
      help,
      loading,
      success,
      variant = 'primary',
      ...props
    }: CheckboxProps,
    ref
  ) => {
    children = children ? children : label
    const as = children ? 'label' : 'div'

    return (
      <Container className={className} as={as} disabled={disabled}>
        <Box opacity={disabled ? 0.5 : undefined}>
          <CheckboxInput
            checked={checked}
            variant={variant}
            disabled={disabled}
            aria-disabled={disabled}
          >
            <HiddenInput
              {...props}
              disabled={disabled || loading}
              type="checkbox"
              aria-label={label}
              checked={checked}
              ref={ref}
            />
            {checked && <CheckmarkIcon />}
          </CheckboxInput>
        </Box>
        <CheckboxLabel disabled={disabled ?? false}>
          {children && <CheckboxLabelText>{children}</CheckboxLabelText>}
          {(loading || success) && (
            <IconWrapper>
              {loading && (
                <LoadingWrapper>
                  <LoadingPlaceholder size="16px" />
                </LoadingWrapper>
              )}
              {success && (
                <SuccessWrapper>
                  <Checkmark width={16} />
                </SuccessWrapper>
              )}
            </IconWrapper>
          )}
          {help && <Caption>{help}</Caption>}
        </CheckboxLabel>
      </Container>
    )
  }
)

Checkbox.displayName = 'Checkbox'
