import styled from 'styled-components'

import { Button, Table, TableComponents } from '@sketch/components'
import { InvitePeopleField as BaseInvitePeopleField } from '@sketch/modules-common'

export const InputWrapper = styled.div`
  display: flex;
  margin: 24px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.border.A};
  padding-top: 24px;
`

export const AddToListButton = styled(Button)`
  margin-top: auto;
  margin-left: 16px;
`

export const InvitePeopleField = styled(BaseInvitePeopleField)`
  width: calc(100% - 69px);
`

export const MembersTable = styled(Table)`
  max-height: 300px;
  width: 100%;
` as typeof Table

export const MinimalTableCell = styled(TableComponents.TableCell)`
  width: 0;
`

export const MemberCardCell = styled(TableComponents.TableCell)`
  max-width: 150px;
  word-wrap: break-word;
`
