import React from 'react'
import { useLocation, matchPath } from 'react-router-dom'

import { VersionedRouteParams, versionedRoutes } from '@sketch/modules-common'

export const SharesTrackingContext = React.createContext<{
  shares: string[]
  isShareChanged: boolean
}>({
  shares: [],
  isShareChanged: false,
})

/**
 * Provider to track shares that are currently being viewed.
 */
export const SharesTrackingProvider: React.FC = ({ children }) => {
  const location = useLocation()
  const [shares, setShares] = React.useState<string[]>([])
  const [isShareChanged, setIsShareChanged] = React.useState(false)

  // Update shares lists if needed when the location changes
  React.useEffect(() => {
    // We just need the shareId, so we can use the latest version route
    // (by default `exact` param is false)
    const match = matchPath<VersionedRouteParams<'SHARE_VIEW'>>(
      location.pathname,
      {
        path: versionedRoutes['SHARE_VIEW'].LATEST.template(),
      }
    )
    const shareId = match?.params.shareID

    // Only for share related urls
    if (shareId) {
      setShares(shares => {
        // We want a list of unique share ids
        if (!shares.includes(shareId)) {
          setIsShareChanged(true)
          return [...shares, shareId]
        } else {
          setIsShareChanged(false)
        }

        return shares
      })
    }
  }, [location.pathname])

  const value = React.useMemo(
    () => ({
      shares,
      isShareChanged,
    }),
    [isShareChanged, shares]
  )

  return (
    <SharesTrackingContext.Provider value={value}>
      {children}
    </SharesTrackingContext.Provider>
  )
}

export const useSharesTracking = () => {
  const context = React.useContext(SharesTrackingContext)

  if (!context) {
    throw new Error(
      'useSharesTracking must be used within a SharesTrackingProvider'
    )
  }

  return context
}
