import React from 'react'

import {
  WorkspaceMinimalFragment,
  useGetWorkspaceMembershipsCountQuery,
} from '@sketch/gql-types'
import { WorkspaceLogo, Truncate, ListRowWrapper } from '@sketch/components'
import { WorkspaceNameText, WorkspaceHelpText } from './WorkspaceRow.styles'

type WorkspaceRowProps = {
  workspace: WorkspaceMinimalFragment
  actions: React.ReactNode
  className?: string
  showAllMembers?: boolean
  warningText?: React.ReactNode
}

export const WorkspaceRow = ({
  workspace,
  actions,
  className,
  showAllMembers,
  warningText,
}: WorkspaceRowProps) => {
  const { data } = useGetWorkspaceMembershipsCountQuery({
    variables: { workspaceId: workspace.identifier },
  })

  const totalCount =
    data?.workspace?.memberships?.totalMemberships?.meta.totalCount || 1
  const guestsCount =
    data?.workspace?.memberships?.totalGuests?.meta.totalCount || 0
  const membersCount = totalCount - guestsCount
  const helpText = membersCount > 1 ? 'Workspace Members' : 'Workspace Member'

  const renderHelpText = showAllMembers
    ? 'All Workspace Members'
    : `${membersCount} ${helpText}`

  return (
    <ListRowWrapper
      key={workspace.identifier}
      aria-label={`Row of ${workspace.name}`}
      className={className}
      $hasWarningText={Boolean(warningText)}
    >
      <WorkspaceLogo
        src={workspace.avatar?.small}
        workspaceName={workspace.name}
        size="32px"
      />
      <Truncate>
        <WorkspaceNameText>{workspace.name}</WorkspaceNameText>
        <WorkspaceHelpText>{renderHelpText}</WorkspaceHelpText>
        {warningText}
      </Truncate>
      {actions}
    </ListRowWrapper>
  )
}
