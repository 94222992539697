import React from 'react'
import styled from 'styled-components'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import { ReactComponent as WarningTriangle } from '@sketch/icons/warning-triangle-64'

import { ErrorMessage } from '@sketch/components'

const GenericError = styled(ErrorMessage.Generic)`
  margin: auto;
  max-width: 320px;
  min-height: min-content;
`

enum ErrorCodes {
  INSPECTOR_NOT_SUPPORTED = 'INSPECTOR_NOT_SUPPORTED',
  NOT_FOUND = 'NOT_FOUND',
  INVALID_INSPECTOR_DATA = 'INVALID_INSPECTOR_DATA',
  DEFAULT = 'DEFAULT',
}

const errorMessages: Record<string, React.ReactNode> = {
  INSPECTOR_NOT_SUPPORTED: (
    <>
      The Inspector couldn’t load because the document is outdated. Save the
      document using the latest version of Sketch and try again.
    </>
  ),

  NOT_FOUND: (
    <>
      The Inspector isn’t available because the document didn’t load. Make sure
      you’re using the correct URL and try again — or{' '}
      <a href={`${SKETCH_WEBSITE}/support/contact/`}>contact us</a>.
    </>
  ),

  DEFAULT: (
    <>
      The Inspector couldn’t load because there’s an error in the document. Try
      uploading your document again, or{' '}
      <a href={`${SKETCH_WEBSITE}/support/contact/`}>contact us</a>.
    </>
  ),
}

const errorCodeMessageMap: Record<ErrorCodes, keyof typeof errorMessages> = {
  [ErrorCodes.INSPECTOR_NOT_SUPPORTED]: 'INSPECTOR_NOT_SUPPORTED',
  [ErrorCodes.NOT_FOUND]: 'NOT_FOUND',
  [ErrorCodes.INVALID_INSPECTOR_DATA]: 'DEFAULT',
  [ErrorCodes.DEFAULT]: 'DEFAULT',
}

interface InspectorErrorProps {
  error: string
  reason?: string
}

const ErrorTemplate = ({
  title = 'Inspector not available',
  message,
}: {
  title?: string
  message: React.ReactNode
}) => (
  <GenericError
    title={title}
    description={message}
    icon={<WarningTriangle />}
    iconSize="medium"
  />
)

function isErrorCode(v?: string): v is ErrorCodes {
  return (
    typeof v === 'string' &&
    Object.values(ErrorCodes as Record<string, string>).includes(v)
  )
}

const InspectorError: React.FC<InspectorErrorProps> = ({ error, reason }) => {
  let code = ErrorCodes.DEFAULT

  if (isErrorCode(reason)) {
    code = reason
  } else if (isErrorCode(error)) {
    code = error
  }

  return <ErrorTemplate message={errorMessages[errorCodeMessageMap[code]]} />
}

export default InspectorError
