import React, { useMemo } from 'react'
import { NavLink, useRouteMatch, match, NavLinkProps } from 'react-router-dom'
import { versionedRoutes } from '@sketch/modules-common'

const versionRouteArray = Object.values(versionedRoutes)

type NavLinkPassedProps = OmitSafe<NavLinkProps, 'to'>

interface VersionLinkProps extends NavLinkPassedProps {
  isLatestVersion: boolean
  versionShortId: string
}

export interface PreventiveVersionLinkProps extends NavLinkPassedProps {
  isLatestVersion: boolean
  versionShortId?: string | false
}

const createLink = (
  route: typeof versionRouteArray[number],
  params: match['params'],
  versionShortId: string,
  isLatestVersion: boolean
) => {
  const { LATEST, VERSION } = route
  const routeParams = params as any

  return isLatestVersion
    ? LATEST.create(routeParams)
    : VERSION?.create({
        ...routeParams,
        versionShortId,
      })
}

const VersionLink: React.FC<VersionLinkProps> = props => {
  const { children, versionShortId, isLatestVersion, ...linkProps } = props
  const route = useRouteMatch()

  const versionedRoute =
    useMemo(
      () =>
        versionRouteArray.find(({ LATEST, VERSION }) => {
          return [LATEST.template(), VERSION.template()].includes(route.path)
        }),
      [route.path]
    ) || versionedRoutes.SHARE_VIEW

  const to = createLink(
    versionedRoute,
    route.params,
    versionShortId,
    isLatestVersion
  )

  return (
    <NavLink data-testid="version-item-link" to={to} exact {...linkProps}>
      {children}
    </NavLink>
  )
}

const PreventiveVersionLink: React.FC<PreventiveVersionLinkProps> = props => {
  if (props.versionShortId) {
    return <VersionLink {...props} versionShortId={props.versionShortId} />
  }

  return <>{props.children}</>
}

export default PreventiveVersionLink
