import styled from 'styled-components'

import { breakpoint } from '@sketch/global-styles'
import { Text } from '@sketch/components'

import BaseSearch from '../../components/Search'

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 72px; /* stylelint-disable-line scales/space */

  ${breakpoint('base', 'sm')`
    flex-direction: column;
    margin-bottom: 36px;
  `}
`

export const Title = styled.div`
  max-width: 522px;
`

export const Search = styled(BaseSearch)`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;

  max-width: 260px;

  ${breakpoint('base', 'sm')`
    max-width: 100%;
    margin-bottom: 24px;
  `};
`

export const Description = styled(Text).attrs({
  textStyle: 'copy.quaternary.standard.F',
})`
  margin-top: 24px;

  ${breakpoint('base', 'sm')`
    margin-bottom: 24px;
  `}
`
