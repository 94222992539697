import { BREAKPOINTS_SORTED } from '../breakpoints'
import { space } from './space'
import * as typography from './typography'
import * as borders from './borders'
import * as variants from './variants'
import { light, dark } from './colors'
import { shadows } from './shadows'
import { zIndex } from './zIndex'
import { transitions } from './transitions'

const breakpoints = BREAKPOINTS_SORTED.slice(2)

/**
 * Common theme properties
 *
 * Theme keys are based on the System UI spec. Variants are groupings of theme
 * values around specific components or site areas.
 *
 * @see https://system-ui.com/theme
 */
const common = {
  // System UI keys
  space,
  breakpoints,
  shadows,
  transitions,
  ...typography,
  ...borders,
  // Variants
  ...variants,
  // Custom
  zIndex,
}

export const lightTheme = {
  ...common,
  ...light,
}

export const darkTheme = {
  ...common,
  ...dark,
}
