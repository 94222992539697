import React from 'react'
import { Link } from '@sketch/components'

import { routes } from '@sketch/modules-common'
import { useCanSubscribeViaStripe } from '../../utils'

import { WorkspaceMinimalFragment } from '@sketch/gql-types'

const SUBSCRIPTION_CANCELED_BANNER_ID = 'subscription-canceled'
const UNPAID_SUBSCRIPTION_CANCELED_BANNER_ID = 'unpaid-subscription-canceled'
const INCOMPLETE_PAYMENT_DETAILS_BANNER_ID = 'incomplete-payment-details'
const PAST_DUE_BANNER_ID = 'past-due'
const UPGRADE_PERSONAL_WORKSPACE_BANNER_ID = 'upgrade-personal-workspace'

interface WorkspaceIdBannerProps {
  workspaceId: string
  workspace?: WorkspaceMinimalFragment
  isUserAdmin?: boolean
}
declare module '@sketch/components' {
  export interface BannersMap {
    [SUBSCRIPTION_CANCELED_BANNER_ID]: null
    [UNPAID_SUBSCRIPTION_CANCELED_BANNER_ID]: WorkspaceIdBannerProps
    [INCOMPLETE_PAYMENT_DETAILS_BANNER_ID]: null
    [PAST_DUE_BANNER_ID]: null
    [UPGRADE_PERSONAL_WORKSPACE_BANNER_ID]: WorkspaceIdBannerProps
  }
}

const BillingLink = ({ workspaceId }: { workspaceId: string }) => (
  <>
    Please reactivate your subscription in{' '}
    <Link
      variant="secondary"
      isUnderlined
      to={routes.WORKSPACE_SETTINGS_BILLING.create({
        workspaceId,
      })}
    >
      Workspace Settings
    </Link>
    .
  </>
)
export const CanceledBanner: React.FC<WorkspaceIdBannerProps> = ({
  workspaceId,
  workspace,
  isUserAdmin,
}) => {
  const customerId = workspace?.customer?.identifier
  const { canSubscribeStripe } = useCanSubscribeViaStripe(
    workspaceId,
    customerId
  )

  const banner = isUserAdmin ? (
    <>
      Your workspace subscription has been canceled and{' '}
      <b>documents are now in read-only mode</b>.{' '}
      {canSubscribeStripe && <BillingLink workspaceId={workspaceId} />}
    </>
  ) : (
    <>
      Your workspace subscription has been canceled and{' '}
      <b>documents are now in read-only mode</b>. Admins can reactivate your
      subscription.
    </>
  )

  return banner
}

export const UnpaidCancelBanner: React.FC<WorkspaceIdBannerProps> = ({
  workspaceId,
}) => (
  <>
    We canceled your workspace due to an issue with your payment method. Please
    update your payment details in your{' '}
    <Link
      variant="secondary"
      isUnderlined
      to={routes.WORKSPACE_SETTINGS_BILLING.create({
        workspaceId,
      })}
    >
      subscription settings
    </Link>
    .
  </>
)

export const IncompleteBanner: React.FC<WorkspaceIdBannerProps> = ({
  workspaceId,
}) => (
  <>
    We were unable to validate your card. Please review your payment details in
    your{' '}
    <Link
      variant="secondary"
      isUnderlined
      to={routes.WORKSPACE_SETTINGS_BILLING.create({
        workspaceId,
      })}
    >
      subscription settings
    </Link>
    .
  </>
)

export const PastDueBanner: React.FC<WorkspaceIdBannerProps> = ({
  workspaceId,
}) => (
  <>
    Your payment is due. Please check your{' '}
    <Link
      variant="secondary"
      isUnderlined
      to={routes.WORKSPACE_SETTINGS_BILLING.create({
        workspaceId,
      })}
    >
      billing details
    </Link>{' '}
    or contact Customer Support.
  </>
)

export const PersonalWorkspaceBanner = ({
  workspaceId,
}: {
  workspaceId: string
}) => (
  <>
    Make the most of Sketch by{' '}
    <Link
      variant="secondary"
      isUnderlined
      to={routes.WORKSPACE_SETTINGS_PEOPLE.create({
        workspaceId,
      })}
    >
      upgrading
    </Link>{' '}
    your Personal Workspace.
  </>
)
