import React, { useEffect, useState } from 'react'
import MediaQueryRaw, { useMediaQuery } from 'react-responsive'
import {
  BREAKPOINTS_IN_PX,
  Breakpoints,
  BREAKPOINTS_KEYS_SORTED,
  BREAKPOINTS_SORTED,
} from '@sketch/global-styles'

interface BreakpointProps {
  on: Breakpoints
  children: React.ReactNode | ((matches: boolean) => React.ReactNode)
}

// There is a mismatched how 'react-responsive' exports the default component for ESM and CommonJS
// This can cause default import to still have a default property
// TODO: check whether this is still needed
const MediaQuery: typeof MediaQueryRaw = (MediaQueryRaw as any).default
  ? (MediaQueryRaw as any).default
  : (MediaQueryRaw as any)

export const Breakpoint: React.FC<BreakpointProps> = ({ children, on }) => (
  <MediaQuery query={`(min-width: ${BREAKPOINTS_IN_PX[on]}px)`}>
    {children}
  </MediaQuery>
)

interface SingleBreakpointProps {
  children: React.ReactNode | ((matches: boolean) => React.ReactNode)
  breakpoint: Breakpoints
  reverse?: boolean
}

/**
 *
 * SingleBreakpoint
 *
 * If you want to render something ONLY in a particular breakpoint.
 *
 * <SingleBreakpoint breakpoint="lg">
 *  <div> Only renders on LG </div>
 * </SingleBreakpoint>
 *
 * Reverse prop will do the opposite, it ONLY DOESN'T render in the selected breakpoint
 *  <SingleBreakpoint breakpoint="lg">
 *    <div> Renders in ALL breakpoints but LG </div>
 *  </SingleBreakpoint>
 */
export const SingleBreakpoint: React.FC<SingleBreakpointProps> = ({
  children,
  breakpoint,
  reverse,
}) => {
  const isBreakpointMatched = useSingleBreakpoint(breakpoint)
  const matched = reverse ? !isBreakpointMatched : isBreakpointMatched

  if (!matched) return null

  return <>{children}</>
}

// All breakpoints are using min-width, so every breakpoint includes bigger
// resolutions
export const useBreakpoint = (on: Breakpoints) =>
  useMediaQuery({ minWidth: BREAKPOINTS_IN_PX[on] })

export const useSingleBreakpoint = (breakpoint: Breakpoints) => {
  const breakpointIndex = BREAKPOINTS_KEYS_SORTED.indexOf(breakpoint)

  const breakpointMinusOnePixel =
    Number(BREAKPOINTS_SORTED[breakpointIndex].split('px')[0]) - 1

  return useMediaQuery({
    minWidth: BREAKPOINTS_SORTED[breakpointIndex - 1],
    maxWidth: `${breakpointMinusOnePixel}px`,
  })
}

/** Returns true for mobile and bigger screens in portrait mode (min-width: 325px) */
export const useForMobile = () => useBreakpoint('xxs')
/** Returns true for screens bigger than mobile in portrait mode (min-width: 512px) */
export const useForLargerThanMobile = () => useBreakpoint('xs')
/** Returns true for tablet and bigger screens in portrait mode (min-width: 768px) */
export const useForTablet = () => useBreakpoint('sm')
/** Returns true for small desktop and bigger screens in portrait mode (min-width: 1024px) */
export const useForDesktop = () => useBreakpoint('md')
/** Returns true for screens bigger than 1200px in portrait mode */
export const useForBigScreen = () => useBreakpoint('lg')
/** Returns true for screens bigger than 2000px in portrait mode */
export const useForXXLScreen = () => useBreakpoint('xxl')

const getCurrentBreakpoint = (width: number) => {
  let breakpoint = 'base' as Breakpoints

  Object.entries(BREAKPOINTS_IN_PX).forEach(breakpointEntry => {
    const breakpointName = breakpointEntry[0] as Breakpoints
    const breakpointWidth = breakpointEntry[1]

    if (width >= breakpointWidth) {
      breakpoint = breakpointName
    }
  })

  return breakpoint
}

/** Retuns the current breakpoint */
export const useCurrentBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<Breakpoints>(
    getCurrentBreakpoint(window.innerWidth)
  )

  useEffect(() => {
    const handleResize = () => {
      setBreakpoint(getCurrentBreakpoint(window.innerWidth))
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return breakpoint
}
