import React from 'react'

import { Flex } from '../Box'

import { ReactComponent as CheckIcon } from '@sketch/icons/checkmark-16'
import { ReactComponent as CircleIcon } from '@sketch/icons/fill-circle-16'

interface PasswordCriteriaProps {
  attendToCriteria: boolean
  text: string | React.ReactNode
}

export const PasswordCriteria: React.FC<PasswordCriteriaProps> = ({
  attendToCriteria,
  text,
}: PasswordCriteriaProps) => {
  return (
    <Flex
      mt={1}
      fontSize="C"
      color={attendToCriteria ? 'state.positive.A' : 'foreground.secondary.D'}
    >
      {attendToCriteria ? (
        <CheckIcon height={16} width={16} color="state.positive.A" />
      ) : (
        <CircleIcon height={16} width={16} color="foreground.secondary.D" />
      )}{' '}
      {text}
    </Flex>
  )
}
