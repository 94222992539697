import React from 'react'
import { useRouteMatch } from 'react-router'

import {
  Dropdown,
  SelectDropdownSubMenu,
  Tooltip,
  useForTablet,
} from '@sketch/components'
import { isSketchVersionGreaterThan } from '../../../versioning/utils'
import {
  isDraftsRoute,
  isLibrariesRoute,
  isSharedWithMeRoute,
  isTemplatesRoute,
  useGetMyDraftsProject,
} from '@sketch/modules-common'
import {
  Chevron,
  SubMenuItem,
  SubMenuTrigger,
} from './DuplicateShareOption.styles'
import { ShareListItemFragment } from '@sketch/gql-types'

const DROPDOWN_ITEMS = ['SAME_LOCATION', 'MY_DRAFTS']

interface ShareForShareDropdown
  extends Pick<
    ShareListItemFragment,
    | 'identifier'
    | 'name'
    | 'project'
    | 'userCanUpdateSettings'
    | 'publicAccessLevel'
    | 'type'
    | 'pinnedByCurrentUserAt'
    | 'collection'
  > {}

interface DuplicateSubMenuProps {
  workspaceIdentifier: string
  share: ShareForShareDropdown
  moreThanOneEligible: boolean

  onDuplicateTo: () => void
  onDuplicateToMyDrafts: () => void
}

export const DuplicateSubMenu: React.FC<DuplicateSubMenuProps> = props => {
  const {
    workspaceIdentifier,
    moreThanOneEligible,
    share,
    onDuplicateTo,
    onDuplicateToMyDrafts,
  } = props

  const isTabletAndBigger = useForTablet()
  const draftsProject = useGetMyDraftsProject(workspaceIdentifier)

  const handleDuplicateToSameLocation = () => {
    onDuplicateTo()
  }

  const handleDuplicateToMyDrafts = () => {
    onDuplicateToMyDrafts()
  }

  const hasDraftsProject = !!draftsProject

  const text = share.collection
    ? `To ${share.collection.name}`
    : share.project
    ? `To ${share.project.name}`
    : `To Same Location`

  return (
    <SelectDropdownSubMenu
      data-hassublevels={true}
      value="Duplicate"
      usePortal
      breakpoint="sm"
      headerItem={
        !isTabletAndBigger && <Dropdown.Header>Duplicate</Dropdown.Header>
      }
      renderTrigger={props => (
        <SubMenuTrigger
          text="Duplicate"
          data-testid="move-submenu"
          additional={<Chevron />}
          data-hassublevels={true}
          {...props}
        />
      )}
      items={DROPDOWN_ITEMS}
      renderItem={(item, props) => {
        if (item === 'SAME_LOCATION') {
          return (
            <SubMenuItem
              text={text}
              data-hassublevels={true}
              onClick={handleDuplicateToSameLocation}
            />
          )
        }

        if (item === 'MY_DRAFTS') {
          if (!hasDraftsProject) {
            return
          }

          return (
            <SubMenuItem
              text={`To My Drafts${moreThanOneEligible ? `…` : ``}`}
              data-hassublevels={true}
              onClick={handleDuplicateToMyDrafts}
            />
          )
        }
      }}
    />
  )
}

interface DuplicateShareOptionProps {
  workspaceIdentifier: string
  sketchVersion: string
  moreThanOneEligible?: boolean
  onDuplicateShareToDraftsClick: () => void
  onDuplicateShareToClick: () => void
  share: ShareForShareDropdown
}

export function DuplicateShareOption(props: DuplicateShareOptionProps) {
  const {
    workspaceIdentifier,
    sketchVersion,
    moreThanOneEligible = false,
    onDuplicateShareToDraftsClick,
    onDuplicateShareToClick,
    share,
  } = props

  /**
   *  We check whether the application is showing the
   *  drafts section here instead of prop drilling a
   *  property which can affect a lot of components until
   *  it gets here
   */
  const route = useRouteMatch()
  const isInMyDraftsPage = isDraftsRoute(route.path)
  const isInMyDraftsProject = share.project?.type === 'PERSONAL_DRAFTS'

  // These routes are limited to a single duplicate action
  const isLimitedRoute =
    isLibrariesRoute(route.path) ||
    isSharedWithMeRoute(route.path) ||
    isTemplatesRoute(route.path)

  const savedUsingSketch43OrGreater = isSketchVersionGreaterThan(
    sketchVersion,
    '43'
  )

  const isDuplicateDisabled = !savedUsingSketch43OrGreater

  const text = (
    <>
      {isInMyDraftsPage ? 'Duplicate' : 'Duplicate to My Drafts'}
      {moreThanOneEligible ? <>&hellip;</> : null}
    </>
  )

  // This renders the "Duplicate" menu item, disabled, with a tooltip
  // if the Sketch document is Version 43 OR below
  if (isDuplicateDisabled) {
    return (
      <Tooltip
        placement="top-end"
        content="To duplicate this document, save it using Sketch version 43 or later."
        disabled={!isDuplicateDisabled}
      >
        <Dropdown.Item disabled={isDuplicateDisabled}>
          {isInMyDraftsProject ? 'Duplicate to My Drafts' : 'Duplicate'}
        </Dropdown.Item>
      </Tooltip>
    )
  }

  // If the document is in the My Drafts project we show a single duplicate option
  const isLibrary = share.type === 'LIBRARY'
  const isTemplate = share.type === 'TEMPLATE'

  if (isInMyDraftsProject || isLimitedRoute || isLibrary || isTemplate) {
    return (
      <Tooltip
        placement="top-end"
        content="To duplicate this document, save it using Sketch version 43 or later."
        disabled={!isDuplicateDisabled}
      >
        <Dropdown.Item
          disabled={isDuplicateDisabled}
          onClick={onDuplicateShareToDraftsClick}
        >
          {text}
        </Dropdown.Item>
      </Tooltip>
    )
  }

  return (
    <DuplicateSubMenu
      share={share}
      workspaceIdentifier={workspaceIdentifier}
      onDuplicateTo={onDuplicateShareToClick}
      onDuplicateToMyDrafts={onDuplicateShareToDraftsClick}
      moreThanOneEligible={moreThanOneEligible}
    />
  )
}
