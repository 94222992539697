import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { useHistory } from 'react-router-dom'
import { History } from 'history'
import { useApolloClient } from '@apollo/react-hooks'
import * as Sentry from '@sentry/browser'

import { authorizationKeys, localStorageKeys } from '@sketch/constants'
import { useRevokeOAuthMutation } from '@sketch/gql-types'
import { initializeLocalApolloState } from '@sketch/graphql-cache'
import { removeItem, useEventDispatch } from '@sketch/utils'

import {
  getAllAuthorizations,
  removeAuthorizationByAuthToken,
} from '../multisession'
import { routes } from '../../../routes'
import { useMarketingCookies } from '../../../user'
import { useThemeContext } from '@sketch/global-styles'

type Location = History.LocationDescriptor
// This array of strings should be a list of auth tokens
type SessionsToRemove = 'all' | 'none' | string[]
type SessionsToRevoke = 'all' | 'none'

interface SharedSignOutProps {
  location?: Location
  reason?: string
  removeDataFromSessions?: SessionsToRemove
  redirectBackAfterLoginAgain?: boolean
}

interface UseSignOutProps extends SharedSignOutProps {
  revokeSessions?: SessionsToRevoke
}

interface SignOutProps extends SharedSignOutProps {
  client: ApolloClient<object>
  history: History
}

declare module '@sketch/utils' {
  export interface EventsMap {
    signOut: {}
  }
}

// Function to be used in cases where a hook cannot be used.
export const signOut = async ({
  client,
  history,
  location,
  removeDataFromSessions = 'all',
  redirectBackAfterLoginAgain = true,
  reason = 'Uncategorized reason',
}: SignOutProps) => {
  const signInPageLocation: Location = location || {
    pathname: routes.SIGN_IN.create({}),
    state: redirectBackAfterLoginAgain
      ? {
          from: {
            pathname: window.location.pathname,
            search: window.location.search,
            state: {},
          },
        }
      : {},
  }

  Sentry.captureMessage(`Sign Out: ${reason}`, scope => {
    scope.addBreadcrumb({
      category: 'Authentication',
      message: 'Signing the user out',
      level: 'log',
    })

    return scope
  })

  if (removeDataFromSessions === 'none') {
    return
  }

  const allAuthorizations = getAllAuthorizations()

  /**
   * Since removeDataFromSessions isn't 'all' or 'none', it's a list of auth tokens
   * of the sessions that should have the data removed. As we have more active sessions
   * than we're gonna remove, it means that we need to remove each one manually to keep
   * the other ones.
   */
  if (
    removeDataFromSessions !== 'all' &&
    allAuthorizations.length > removeDataFromSessions.length
  ) {
    // Remove the session(s) from local storage
    removeDataFromSessions.forEach(session => {
      removeAuthorizationByAuthToken(client.cache, session)
    })

    return
  }

  /**
   * In this case, removeDataFromSessions is 'all' or is an array of sessions to
   * be removed but we're gonna remove all of them. In this case, we need to remove
   * all data from local storage and clean Apollo Client cache.
   */

  /**
   * The order we follow when cleaning things up is important.
   * If we clean Apollo's cache before removing all local storage entries,
   * Apollo's cache subscriptions will be notified and, if they access the local
   * storage (e.g. to verify the list of authorizations), they will wrongly see
   * there are still ongoing sessions.
   */
  authorizationKeys.forEach(key => localStorage.removeItem(key))

  // This makes sure we don't have operations unfinished that might
  // cause error when the user logs out.
  client.stop()

  // Client.resetStore method will allow the cache to be cleared and
  // the connections to any query still mounted to be dropped and reconnected
  // if the reconnects don't occur some hooks like "useUserSignedIn" might not update
  //
  // This method uses internally the same logic as client.clearStore
  // https://github.com/apollographql/apollo-client/blob/v2.6.8/packages/apollo-client/src/ApolloClient.ts#L483-L487
  await client.resetStore()

  // We need to reinitialize the base apolloStore again because the state is now blank
  initializeLocalApolloState(
    (client as ApolloClient<NormalizedCacheObject>).cache
  )

  if (!redirectBackAfterLoginAgain) {
    removeItem(localStorageKeys.lastWorkspaceIdKey)
  }

  // This is basically to keep typescript nonsense validations quiet
  if (typeof signInPageLocation === 'string') {
    history.replace(signInPageLocation)
  } else {
    history.replace(signInPageLocation)
  }

  Sentry.captureEvent({})

  return
}

/**
 * This hook should be used to revoke your sessions that you're actually logged in in the
 * current device and also remove all local variables related to the sessions. To revoke
 * sessions from other devices the user should use the sessions management section in the
 * /settings page.
 * revokeSessions params:
 * - 'all': will revoke all sessions found in the localStorage
 * - 'none': no session will be revoked within the BE
 * removeDataFromSessions params:
 * - 'all': will remove all sessions found in the localStorage
 * - 'none': no session will be removed from the local variables
 * - string[]: we received an array of auth tokens that we're gonna remove from
 *             the local variables
 */
export const useSignOut = ({
  location,
  revokeSessions = 'all',
  removeDataFromSessions = 'all',
  redirectBackAfterLoginAgain = true,
  reason = 'Uncategorized reason',
}: UseSignOutProps = {}) => {
  const { resetToMatchSystem } = useThemeContext()
  const client = useApolloClient()
  const history = useHistory()
  const dispatchSignOutEvent = useEventDispatch('signOut')
  const { removeCookies } = useMarketingCookies()

  const [revokeOAuth] = useRevokeOAuthMutation({
    onError: 'do-nothing',
  })

  return async () => {
    const allAuthorizations = getAllAuthorizations()

    // Revoking sessions
    if (revokeSessions === 'all') {
      allAuthorizations.forEach(authorization => {
        revokeOAuth({
          variables: {
            token: authorization.fragment.authToken,
          },
        })
      })
    }

    // Removing local variables
    await signOut({
      client,
      history,
      location,
      removeDataFromSessions,
      redirectBackAfterLoginAgain,
      reason,
    })

    // Clear Dark Mode settings from local storage
    resetToMatchSystem()

    if (
      removeDataFromSessions === 'all' ||
      (removeDataFromSessions !== 'none' &&
        allAuthorizations.length <= removeDataFromSessions.length)
    ) {
      /**
       * Since we want to remove all sessions, or we're going to have no left session
       * after removing all, we want to remove cookies and dispatch sign out event.
       */

      // Remove marketing cookies
      removeCookies()

      dispatchSignOutEvent({})
    }
  }
}
