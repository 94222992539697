import styled from 'styled-components'
import { ActionableMenuLink } from './ActionableMenuLink'

import {
  MenuLink as MenuLinkRaw,
  menuLinkStyles,
  Text,
  IconWrapper,
  Icon,
} from './MenuLink'

export * from './ActionableMenuLink'

const StyledIcon = styled(Icon)``

const MenuLink = Object.assign(MenuLinkRaw, {
  Actionable: ActionableMenuLink,
})

export { MenuLink, menuLinkStyles, Text, IconWrapper, StyledIcon as Icon }
