import { DataProxy } from 'apollo-cache'
import { removeFromPaginated } from '@sketch/modules-common'
import { ShareListItemFragment } from '@sketch/gql-types'

export interface HandleShareProjectChangedProps {
  cache: DataProxy
  identifier: string
  workspaceIdentifier: string
  previousShare?: ShareListItemFragment
}

export const handleShareProjectChanged = (
  props: HandleShareProjectChangedProps
) => {
  const { cache, identifier, previousShare } = props

  if (!previousShare || !previousShare.project) {
    return
  }

  removeFromPaginated(
    cache,
    { __typename: 'Share', identifier },
    key =>
      key.includes(previousShare.project!.identifier) && key.includes('.shares')
  )
}
