import styled, { css } from 'styled-components'

import {
  Button as BaseButton,
  Input,
  Form as BaseForm,
} from '@sketch/components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: relative;
`

export const Name = styled.span(
  ({ theme }) => css`
    margin-left: 16px;
    font-size: ${theme.fontSizes.F};
    color: ${theme.colors.foreground.secondary.A};
  `
)

export const Form = styled(BaseForm)`
  display: flex;
  align-items: flex-end;
  margin-left: 20px;
  flex: 1;

  ${Input} {
    width: 100%;
  }

  ${BaseForm.Field} {
    flex: 1;
  }
`

export const Button = styled(BaseButton)`
  margin-top: auto;
  margin-bottom: 0;
  margin-left: 20px;
`
