import React from 'react'

import { Button, ErrorMessage } from '@sketch/components'

import { IndexLayoutHeader, IndexLayoutHeaderProps } from './IndexLayoutHeader'
import { Wrapper } from './IndexLayout.styles'

export const IndexLayoutError: React.FC<IndexLayoutHeaderProps> = ({
  headerLink,
}) => (
  <Wrapper>
    <IndexLayoutHeader headerLink={headerLink} disableLogoLink />
    <ErrorMessage.Generic
      title="There was an error loading this page"
      extra={
        <Button variant="primary" onClick={() => window.location.reload()}>
          Refresh the page to try again
        </Button>
      }
    />
  </Wrapper>
)
