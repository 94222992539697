import styled from 'styled-components'

import { breakpoint } from '@sketch/global-styles'

import { ButtonUnstyled, Text } from '@sketch/components'
import { ReactComponent as ChevronVertical } from '@sketch/icons/chevron-vertical-16'

export const Paragraph = styled(Text).attrs({
  textStyle: 'copy.tertiary.standard.D',
})`
  margin: 0;

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }
`

export const Title = styled.h1`
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.4;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin: 0;
`

export const PlanPrice = styled(Paragraph)`
  margin: 0;
`

export const Section = styled.div`
  margin-left: 20px;
  padding: 16px 24px 16px 0;

  ${breakpoint('base', 'sm')`
    margin-left: 24px;
    padding-right: 24px;
  `}

  ${Paragraph} {
    &:nth-child(1) {
      margin-top: 0;
    }

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
`

export const Wrapper = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  box-shadow: ${({ theme }) => theme.shadows.boxShadow.subtle};

  border: 1px solid ${({ theme }) => theme.colors.border.A};
  border-width: 1px 0 0;
  padding-bottom: 77px; /* stylelint-disable-line scales/space */

  > * + * {
    & ${Section} {
      border-top: 1px solid ${({ theme }) => theme.colors.border.A};
    }

    &${Section} {
      border-top: 1px solid ${({ theme }) => theme.colors.border.A};
    }
  }

  ${breakpoint('sm', 'md')`
    /* Compensate for the "Submit button" */
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-width: 1px 1px 0 1px;
  `}

  ${breakpoint('md')`
      padding-bottom: 0;
      border-width: 1px;
      border-radius: 7px;
    `}
`

export const HalfDivider = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 12px;
  }
`

export const SeatsHighlight = styled.p`
  display: inline-block;
  min-width: 30px;
  height: 100%;
  line-height: 1.6;
  text-align: center;
  margin: 0 8px 0 0;
  background-color: ${({ theme }) => theme.colors.background.secondary.A};
  padding: 0 12px;
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  border-radius: 4px;
`

export const Seats = styled.p`
  display: inline-block;
  font-size: 0.8125rem;
  line-height: 1.6;
  margin: 0;
`

export const SeatsTotalPriceWrapper = styled.div`
  height: 100%;
  min-width: 140px;
  margin: 0 -20px 0 0;
  padding: 0 20px 0 0;
  text-align: right;
`

export const SeatsTotalPrice = styled.p`
  display: inline-block;
  font-size: 0.8125rem;
  line-height: 1.6;
  margin: 0;
`

export const Chevron = styled(ChevronVertical)`
  width: 16px;
  height: 16px;

  color: ${({ theme }) => theme.colors.background.primary.A};
`

export const DrawerButton = styled(ButtonUnstyled)<{ $isOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  position: absolute;
  top: 8px;
  right: 19px;

  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform
    ${({ theme }) =>
      `${theme.transitions.duration[2]} ${theme.transitions.timing.spring}`};

  ${breakpoint('md')`
    display: none
  `};
`
