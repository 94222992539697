import styled from 'styled-components'
import { breakpoint } from '@sketch/global-styles'

const CommunityWrapper = styled.div`
  position: relative;
  display: flex;
  flex: auto;
  flex-direction: column;
  max-width: 1280px;
  width: 100%;

  margin: 48px auto 140px auto; /* stylelint-disable-line scales/space */

  ${breakpoint('base', 'sm')`
    margin: 32px auto 140px auto;  /* stylelint-disable-line scales/space */
    padding: 0 24px;
  `}

  padding: 0 40px;
`

export default CommunityWrapper
