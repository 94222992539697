import React, { useState } from 'react'
import { useWindowSize, useLocalStorage } from 'react-use'
import styled from 'styled-components'
import { Placement } from '@popperjs/core'

import { ButtonUnstyled, Dropdown } from '@sketch/components'

import { ColorFormat } from '../Color/utils'

import { ReactComponent as ArrowDown } from '@sketch/icons/chevron-vertical-16'
import { ReactComponent as ArrowRight } from '@sketch/icons/chevron-right-16'

const IconButton = styled(ButtonUnstyled)`
  padding: 9px; /* stylelint-disable-line scales/space */
  border-radius: 4px;
  margin-left: 8px;
  display: flex; /* this removes an extra space around svg */
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  &:hover {
    background: ${({ theme }) => theme.colors.background.tertiary.C};
  }

  svg {
    width: 14px;
  }
`

const NestedDropdownTrigger = styled.div`
  display: flex;

  & > svg {
    margin-left: auto;
  }
`

interface CopyDropdownProps {
  dropdownOpen?: (open: boolean) => void
  onChange: (format: ColorFormat) => void
}

const ColorDropdown: React.FC<CopyDropdownProps> = ({
  dropdownOpen,
  onChange,
}) => {
  const size = useWindowSize()
  let placement: Placement = 'bottom-end'

  const [defaultColorFormat] = useLocalStorage(
    'inspector_color_format',
    ColorFormat.HEX
  )

  const [format, setFormat] = useState<ColorFormat>(defaultColorFormat!)

  if (size) {
    if ((size as { height: number }).height < 650) {
      placement = 'auto'
    }
  }

  const handleColorUpdate = (e: React.SyntheticEvent, format: ColorFormat) => {
    // avoid copy pasting while clicking on the items
    e.stopPropagation()
    setFormat(format)
    onChange(format)
  }

  // Note: there are some limitations for nested dropdows.
  // In nested dropdowns `hideOnClick` prop doesn't work for the nested one,
  // because any dropdown hides when clicking outside by default
  // (`useOnClickOutside` hook in Popover), so clicking an item from a nested
  // dropdown will hide all dropdowns
  return (
    <Dropdown
      toggle={
        <IconButton data-testid="dropdown-button">
          <ArrowDown />
        </IconButton>
      }
      placement={placement}
      onToggle={dropdownOpen}
      hideOnClick={false}
    >
      <Dropdown.Header>Display color as</Dropdown.Header>
      <Dropdown.Item
        onClick={e => handleColorUpdate(e, ColorFormat.HEX)}
        className={format === ColorFormat.HEX ? 'active' : undefined}
      >
        Hex
      </Dropdown.Item>
      <Dropdown.Item
        onClick={e => handleColorUpdate(e, ColorFormat.RGB)}
        className={format === ColorFormat.RGB ? 'active' : undefined}
      >
        RGB
      </Dropdown.Item>
      <Dropdown.Item
        onClick={e => handleColorUpdate(e, ColorFormat.HSL)}
        className={format === ColorFormat.HSL ? 'active' : undefined}
      >
        HSL
      </Dropdown.Item>
      <Dropdown.Item
        className={
          format === ColorFormat.OBJC_NSCOLOR ||
          format === ColorFormat.SWIFT_NSCOLOR
            ? 'active'
            : undefined
        }
      >
        <Dropdown
          toggle={
            <NestedDropdownTrigger>
              NSColor
              <ArrowRight width={16} />
            </NestedDropdownTrigger>
          }
          spacing="18px"
          placement="left"
          usePortal={true}
        >
          <Dropdown.Item
            onClick={e => handleColorUpdate(e, ColorFormat.OBJC_NSCOLOR)}
            className={
              format === ColorFormat.OBJC_NSCOLOR ? 'active' : undefined
            }
          >
            Objective-C
          </Dropdown.Item>
          <Dropdown.Item
            onClick={e => handleColorUpdate(e, ColorFormat.SWIFT_NSCOLOR)}
            className={
              format === ColorFormat.SWIFT_NSCOLOR ? 'active' : undefined
            }
          >
            Swift
          </Dropdown.Item>
        </Dropdown>
      </Dropdown.Item>
      <Dropdown.Item
        className={
          format === ColorFormat.OBJC_UICOLOR ||
          format === ColorFormat.SWIFT_UICOLOR
            ? 'active'
            : undefined
        }
      >
        <Dropdown
          toggle={
            <NestedDropdownTrigger>
              UIColor
              <ArrowRight width={16} />
            </NestedDropdownTrigger>
          }
          spacing="18px"
          placement="left"
          usePortal={true}
        >
          <Dropdown.Item
            onClick={e => handleColorUpdate(e, ColorFormat.OBJC_UICOLOR)}
            className={
              format === ColorFormat.OBJC_UICOLOR ? 'active' : undefined
            }
          >
            Objective-C
          </Dropdown.Item>
          <Dropdown.Item
            onClick={e => handleColorUpdate(e, ColorFormat.SWIFT_UICOLOR)}
            className={
              format === ColorFormat.SWIFT_UICOLOR ? 'active' : undefined
            }
          >
            Swift
          </Dropdown.Item>
        </Dropdown>
      </Dropdown.Item>
    </Dropdown>
  )
}

export default ColorDropdown
