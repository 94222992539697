import { useContext } from 'react'
import { ToastContext } from './ToastContext'

export const useToast = () => {
  const contextValue = useContext(ToastContext)

  if (!contextValue) {
    throw new Error('useToast must be used within a ToastProvider')
  }

  const { showToast, dismissToast } = contextValue

  return {
    showToast,
    dismissToast,
  }
}
