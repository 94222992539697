import React, { FC, useEffect } from 'react'
import { FormikProps } from 'formik'

import { Button, Form, Input, Modal } from '@sketch/components'

import { ModalBody, InputLabel } from './VerifyPartnerForm.styles'

import { FormValues } from './types'

interface VerifyPartnerFormProps {
  formikbag: FormikProps<FormValues>
  loading: boolean
  error: boolean
  hideModal: () => void
}

const VerifyPartnerForm: FC<VerifyPartnerFormProps> = ({
  formikbag,
  loading,
  error,
  hideModal,
}) => {
  const {
    values,
    touched,
    handleChange,
    handleBlur,
    errors,
    setFieldError,
  } = formikbag

  useEffect(() => {
    if (error) {
      setFieldError('email', 'Enter a verified Sketch Partner email')
    }
  }, [error, setFieldError])

  return (
    <>
      <ModalBody>
        <p>
          A Sketch Partner manages billing and Editor Seats on your behalf, but
          they cannot view or edit Sketch documents in your Workspace.
        </p>
        <p>
          When a Sketch Partner joins the Workspace, you {"won't"} be able to
          manage billing or add Editor Seats yourself. Any current Finance
          Members will be removed from the Workspace.
        </p>
        <InputLabel>Sketch Partner Email</InputLabel>
        <Form.Field
          name="text"
          errorText={touched.email ? errors.email : undefined}
        >
          <Input
            name="email"
            type="text"
            placeholder="Enter your Sketch Partner email"
            value={values.email}
            disabled={loading}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Field>
      </ModalBody>
      <Modal.Footer>
        <Button
          variant="secondary"
          disabled={loading}
          onClick={() => {
            hideModal()
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          loading={loading}
          disabled={Boolean(formikbag.errors.email)}
          onClick={formikbag.submitForm}
        >
          Next…
        </Button>
      </Modal.Footer>
    </>
  )
}

export default VerifyPartnerForm
