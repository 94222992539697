import React from 'react'
import styled from 'styled-components'

import { buttonUnstyledStyles, Spinner } from '@sketch/components'
import { useToast } from '@sketch/toasts'

import { breakpoint } from '@sketch/global-styles'
import { useResendVerificationEmailMutation } from '@sketch/gql-types'
import { useAnalytics } from '@sketch/modules-common'

const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${breakpoint('sm')`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `};
`

const BannerText = styled.span`
  /* Space between the text and the button in extra */
  margin-bottom: 10px; /* stylelint-disable-line scales/space */

  ${breakpoint('sm')`
    margin-right: 4px;
    margin-bottom: 0;
  `};
`

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ResendButton = styled.button`
  ${buttonUnstyledStyles};

  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  position: relative;
  text-decoration: underline;
  font-weight: 600;
  padding: 0;

  &[disabled] {
    opacity: 0.6;
  }
`

const StyledSpinner = styled(Spinner)`
  margin-right: 6px; /* stylelint-disable-line scales/space */
`

interface VerifyBannerProps {
  userEmail: string
}

declare module '@sketch/components' {
  export interface BannersMap {
    'verify-email': VerifyBannerProps
  }
}

const VerifyBanner: React.FC<VerifyBannerProps> = ({ userEmail }) => {
  const { showToast } = useToast()
  const { trackEvent } = useAnalytics()

  const [resendInvite, { loading }] = useResendVerificationEmailMutation({
    redirectErrors: true,
    onCompleted: () => showToast(`An email has been sent to ${userEmail}`),
    onError: 'show-toast',
  })

  return (
    <BannerWrapper>
      <BannerText>
        Take a moment to check your email and verify your account
      </BannerText>
      <SpinnerWrapper>
        {loading && <StyledSpinner primary size="14px" />}
        <ResendButton
          disabled={loading}
          onClick={() => {
            trackEvent('ONBOARDING - resend verify email', {
              source: 'verify banner',
            })
            resendInvite()
          }}
        >
          Resend email
        </ResendButton>
      </SpinnerWrapper>
    </BannerWrapper>
  )
}

export default VerifyBanner
