import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "graduate-cap-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fillRule: "evenodd", fill: "currentColor" },
        React.createElement("path", { d: "M8.441 2.235l6.671 3.558a.234.234 0 010 .414l-6.67 3.558a.937.937 0 01-.883 0L.888 6.207a.234.234 0 010-.414l6.67-3.558a.937.937 0 01.883 0z", fillOpacity: 0.15 }),
        React.createElement("path", { d: "M7.206 1.574a1.688 1.688 0 011.588 0l6.671 3.557a.984.984 0 010 1.738l-6.67 3.557a1.688 1.688 0 01-1.59 0L.536 6.87a.984.984 0 010-1.738l6.67-3.557zm.882 1.323a.188.188 0 00-.176 0L2.092 6l5.82 3.103c.037.02.078.026.118.02l.058-.02L13.907 6 8.088 2.897z", fillRule: "nonzero" }),
        React.createElement("path", { d: "M7.665 5.33a.75.75 0 11.67 1.34l-4 2a.75.75 0 11-.67-1.34l4-2z", fillRule: "nonzero" }),
        React.createElement("path", { d: "M8 4.975c.714 0 1.375.353 1.375 1.025 0 .672-.661 1.025-1.375 1.025S6.625 6.672 6.625 6c0-.672.661-1.025 1.375-1.025zM3.75 9.25a.75.75 0 01.75.75v4A.75.75 0 113 14v-4a.75.75 0 01.75-.75z", fillRule: "nonzero" }),
        React.createElement("path", { d: "M3.75 13.375c.59 0 1.125 1.161 1.125 1.688 0 .466-.56.75-1.125.75-.564 0-1.125-.284-1.125-.75 0-.527.536-1.688 1.125-1.688zm8.5-6.125A.75.75 0 0113 8v2.5c0 1.663-2.384 3.5-5 3.5s-5-1.837-5-3.5V8a.75.75 0 011.5 0v2.5c0 .736 1.64 2 3.5 2s3.5-1.264 3.5-2V8a.75.75 0 01.75-.75z", fillRule: "nonzero" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
