import styled from 'styled-components'
import { FormField } from '@sketch/components'

import { breakpoint } from '@sketch/global-styles'

export const Wrapper = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`

export const ResponsiveInlineField = styled.div`
  display: flex;

  ${breakpoint('base', 'sm')`
    flex-direction: column;
  `}

  ${FormField} {
    flex: 0.5;

    + ${FormField} {
      flex: 0.5;
      margin-left: 20px;

      ${breakpoint('base', 'sm')`
        flex: 1 0;
        margin-left: 0;
        margin-bottom: 20px;
      `};
    }
  }
`
