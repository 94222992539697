import { DataProxy } from 'apollo-cache'
import { FetchResult } from 'apollo-link'
import { useApolloClient } from 'react-apollo'

import { ErrorHandler } from '@sketch/tracing'
import { readShareWithProjectFragment } from './utils'
import { removeFromPaginated } from '@sketch/modules-common'
import { noop } from '@sketch/utils'
import {
  invalidateProjectCollections,
  invalidateProjectShares,
} from '../../collections/operations'

// GraphQL
import {
  useMoveShareToProjectMutation,
  MoveShareToProjectMutation,
  MoveShareToProjectMutationVariables,
  ProjectFragment,
  ShareInfoFragment,
  ShareListItemFragment,
  DataWithoutUserErrors,
} from '@sketch/gql-types'

interface MoveShareEventHandlers {
  onError: (error: string) => void
  onCompleted: (project: ProjectFragment) => void
}

export const useMoveShareToProject = ({
  onError = noop,
  onCompleted = noop,
}: MoveShareEventHandlers) => {
  const client = useApolloClient()

  const handleOnCompleted = (
    data: DataWithoutUserErrors<MoveShareToProjectMutation>
  ) => {
    if (!data?.moveShareToProject?.project) {
      onError(
        'An unexpected error occurred while moving document to a project.'
      )
      ErrorHandler.shouldNeverHappen.invalidMutationData('moveShareToProject')
      return
    }

    const { project } = data.moveShareToProject

    onCompleted(project)
  }

  const handleUpdate = (
    cachedShare: ShareInfoFragment | ShareListItemFragment | null,
    cache: DataProxy,
    { data }: FetchResult<MoveShareToProjectMutation>
  ) => {
    if (
      !data ||
      !data.moveShareToProject.successful ||
      !data.moveShareToProject.project ||
      !data.moveShareToProject.share
    ) {
      return ErrorHandler.shouldNeverHappen(
        'Move share to project should always have data'
      )
    }

    const { project, share: updatedShare } = data.moveShareToProject

    const updatedShareInCache = readShareWithProjectFragment(
      cache,
      updatedShare.identifier
    )

    // Prevent the undefined if the share isn't found
    if (!cachedShare || !updatedShare || !updatedShareInCache) {
      ErrorHandler.shouldNeverHappen(
        'there always should be a share when moving between projects'
      )
      return
    }

    if (cachedShare?.project?.identifier) {
      // Update the previous cached project, if there's one
      removeFromPaginated(cache, updatedShare!, cachedShare.project)
      invalidateProjectCollections({
        cache,
        projectIdentifier: cachedShare.project.identifier,
      })
    } else {
      removeFromPaginated(
        cache,
        updatedShare!,
        key => key.includes('.shares') && key.includes('"NO_PROJECT"')
      )
    }

    // Invalidate any cached `project.shares` records so that they are
    // refetched from the GraphQL API
    invalidateProjectShares({
      cache,
      projectIdentifier: project.identifier,
    })
  }

  const [mutate, ...rest] = useMoveShareToProjectMutation({
    redirectErrors: true,
    onError: error => {
      onError(error.message)
    },
    onCompleted: handleOnCompleted,
  })

  return [
    (variables: MoveShareToProjectMutationVariables) => {
      const cachedShare = readShareWithProjectFragment(
        client,
        variables.shareId
      )
      if (cachedShare?.project?.identifier === variables.projectId) {
        return
      }
      return mutate({
        variables,
        update: (...args) => handleUpdate(cachedShare, ...args),
      })
    },
    ...rest,
  ] as const
}
