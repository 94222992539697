import styled, { css } from 'styled-components'

import {
  Button,
  truncateStyles,
  Box,
  Flex,
  Separator,
} from '@sketch/components'
import { ReactComponent as QuestionMarkIcon } from '@sketch/icons/question-mark-16'
import { Section, HeaderTitle, AttributeValue } from '../../components'

export const ExportsSection = styled(Section)`
  padding-bottom: 20px;

  ${Separator} {
    margin: 12px 0;
  }
`
export const AllFormatsSeparator = styled(Separator)``

export const ExportButtonWrapper = styled(Flex)`
  flex: 1;
  justify-content: flex-end;
`

export const DownloadFormatButton = styled(Button)`
  width: 40px;
  height: 21px;
  min-height: 0;
  padding: 0;

  :hover,
  :hover svg {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  /* Fixes a strange glitch in Firefox */
  :hover {
    transform: none;
  }

  :disabled svg {
    color: ${({ theme }) => theme.colors.foreground.secondary.D};
  }
`

export const ExportValue = styled(AttributeValue)`
  flex: 3;
  ${truncateStyles};
`

export const SectionStyled = styled(Section)`
  padding-bottom: 12px;
`

export const QuestionMark = styled(QuestionMarkIcon)`
  width: 17px;
  height: 17px;
  opacity: 0.5;
  margin-left: 4px;
`

export const NoAssetsAvailableTooltip = styled.div`
  ${css`
    padding: 16px;
    font-size: 0.8125rem;
    line-height: 1.6;
    width: 320px;
  `}
`

export const NoAssetsAvailableTitle = styled(HeaderTitle)`
  display: flex;
  align-items: center;

  :hover ${QuestionMark} {
    opacity: 1;
  }
`

export const Error = styled(Box).attrs({
  padding: 3,
  borderRadius: 'small',
  backgroundColor: 'rgba(224, 32, 32, 0.05)',
})`
  font-size: 0.8125rem;
  margin: 6px 26px 8px -12px; /* stylelint-disable-line scales/space */

  :first-child {
    margin-top: 0;
  }

  /* && is needed to give more specificity to avoid stylelint warning */
  && ${ExportValue} {
    width: 136px;

    ::after {
      background-image: none;
    }
  }
`
