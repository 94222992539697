import React, { useState, MutableRefObject } from 'react'
import styled from 'styled-components'

import { Breakpoint, Skeleton, pluralize, Segment } from '@sketch/components'

import { RoleTabsContainer, Column } from './RoleTabs.styles'

import {
  GetWorkspaceMembershipsQueryVariables,
  WorkspaceMembershipFragment,
} from '@sketch/gql-types'

import { MembershipsPanelFilters } from '../../hooks/useMembershipsPanel'

const MEMBER = 'MEMBER'
const TOTAL = 'TOTAL'
const GUEST = 'GUEST'

type UserCountType = {
  totalMemberships: number | null
  totalGuests: number | null
  totalMembers: number | null
}

interface RoleTabsProps {
  filters: MembershipsPanelFilters
  userCount: MutableRefObject<UserCountType>
  userDirectoryEnabled?: boolean
}

type RoleTabsState = {
  totalMemberships: boolean
  totalMembers: boolean
  totalGuests: boolean
}

const StyledSkeleton = styled(Skeleton)`
  margin: 4px;
`

const switchState: (key: string) => RoleTabsState = key => {
  const resetState = () => ({
    totalMemberships: false,
    totalMembers: false,
    totalGuests: false,
  })

  if (key === MEMBER) {
    return {
      ...resetState(),
      totalMembers: true,
    }
  }

  if (key === GUEST) {
    return {
      ...resetState(),
      totalGuests: true,
    }
  }

  return {
    ...resetState(),
    totalMemberships: true,
  }
}

/*
 * RoleTabs
 *
 * Renders the filter tabs for the Memberships Table
 */
const RoleTabs: React.FC<RoleTabsProps> = ({
  filters,
  userCount,
  userDirectoryEnabled,
}) => {
  const [filterState, setFilterState] = useState<RoleTabsState>({
    totalMemberships: true,
    totalMembers: false,
    totalGuests: false,
  })
  /** Set the Access level filter */
  const handleAccessLevelClick = (
    accessLevel: GetWorkspaceMembershipsQueryVariables['accessLevel']
  ) => {
    filters.setAccessLevelFilter(accessLevel)
  }

  const renderAccessLevelTabFilters = () => {
    if (filters.roleFilter !== GUEST) {
      return (
        <>
          <div onClick={() => handleAccessLevelClick(undefined)}>
            <Breakpoint on="md">
              {matches =>
                matches ? (
                  <Segment
                    type="text"
                    label="View All"
                    active={filters.accessLevelFilter === undefined}
                  >
                    View All
                  </Segment>
                ) : (
                  <Segment
                    type="text"
                    label="All"
                    active={filters.accessLevelFilter === undefined}
                  >
                    All
                  </Segment>
                )
              }
            </Breakpoint>
          </div>
          <div onClick={() => handleAccessLevelClick('EDITOR')}>
            <Segment
              type="text"
              label="Editors"
              active={filters.accessLevelFilter === 'EDITOR'}
            >
              Editors
            </Segment>
          </div>
          <div onClick={() => handleAccessLevelClick('VIEWER')}>
            <Segment
              type="text"
              label="Viewers"
              active={filters.accessLevelFilter === 'VIEWER'}
            >
              Viewers
            </Segment>
          </div>
        </>
      )
    }

    return null
  }

  const onClick = (filter: WorkspaceMembershipFragment['role'] | undefined) => {
    // For Members tabs we want to show all the members that are NOT guests
    // This is the reason to treat it differently
    if (filter === MEMBER) {
      filters.setRoleFilter(undefined)
      filters.setExcludeRolesFilter([GUEST])
      setFilterState(switchState(MEMBER))
      return
    }

    if (!filter) {
      filters.setRoleFilter(filter)
      filters.setExcludeRolesFilter(undefined)
      setFilterState(switchState(TOTAL))
      return
    }
    // For any other case, we just filter normally
    // Resetting the excluded roles
    filters.setRoleFilter(filter)
    filters.setExcludeRolesFilter(undefined)
    setFilterState(switchState(GUEST))
  }

  const totalMemberships =
    userCount?.current?.totalMemberships != null
      ? `${userCount.current.totalMemberships} Total`
      : null
  const totalMembers =
    userCount?.current?.totalMembers != null
      ? `${userCount.current.totalMembers} ${pluralize(
          'Member',
          'Members',
          userCount?.current?.totalMembers || 1
        )}`
      : null
  const totalGuests =
    userCount?.current?.totalGuests != null
      ? `${userCount.current.totalGuests} ${pluralize(
          'Guest',
          'Guests',
          userCount.current.totalGuests
        )}`
      : null

  return (
    <RoleTabsContainer>
      <Column>
        {totalMemberships != null ? (
          <div onClick={() => onClick(undefined)}>
            <Segment
              type="text"
              label={totalMemberships}
              active={filterState.totalMemberships}
            >
              {totalMemberships}
            </Segment>
          </div>
        ) : (
          <StyledSkeleton height="10px" width="60px" />
        )}
        {userDirectoryEnabled ? (
          <>
            {totalMembers != null ? (
              <div onClick={() => onClick(MEMBER)}>
                <Segment
                  type="text"
                  label={totalMembers}
                  active={filterState.totalMembers}
                >
                  {totalMembers}
                </Segment>
              </div>
            ) : (
              <StyledSkeleton height="10px" width="60px" />
            )}
          </>
        ) : null}
        {totalGuests != null ? (
          <div onClick={() => onClick(GUEST)}>
            <Segment
              type="text"
              label={totalGuests}
              active={filterState.totalGuests}
            >
              {totalGuests}
            </Segment>
          </div>
        ) : (
          <StyledSkeleton height="10px" width="60px" />
        )}
      </Column>
      {/** Renders the Access Level tabs*/}
      <Column>{renderAccessLevelTabFilters()}</Column>
    </RoleTabsContainer>
  )
}

export default RoleTabs
