import React from 'react'
import styled from 'styled-components'
import { PaymentLogo, CreditCardBrand } from '@sketch/components'

const supportedCreditCards: CreditCardBrand[] = ['amex', 'mastercard', 'visa']

const CreditCardWrapper = styled.div`
  flex-direction: column;
`

const CreditCardLogo = styled(PaymentLogo)`
  margin-right: 8px;
`

type CreditCardsProps = {
  cards?: CreditCardBrand[]
}

const CreditCards: React.FC<CreditCardsProps> = ({
  cards = supportedCreditCards,
}) => (
  <CreditCardWrapper>
    {cards.map(card => (
      <CreditCardLogo className={card} brand={card} key={card} />
    ))}
  </CreditCardWrapper>
)

export default CreditCards
