import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "license-diamond-monochrome-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fillRule: "evenodd", fill: "currentColor" },
        React.createElement("path", { d: "M4.205 3h7.59c1.115 0 1.519.116 1.926.334.407.218.727.538.945.945.218.407.334.811.334 1.926v4.59c0 1.115-.116 1.519-.334 1.926a2.272 2.272 0 01-.945.945c-.407.218-.811.334-1.926.334h-7.59c-1.115 0-1.519-.116-1.926-.334a2.272 2.272 0 01-.945-.945C1.116 12.314 1 11.91 1 10.795v-4.59c0-1.115.116-1.519.334-1.926.218-.407.538-.727.945-.945C2.686 3.116 3.09 3 4.205 3z", fillOpacity: 0.15 }),
        React.createElement("path", { d: "M11.795 3c1.115 0 1.519.116 1.926.334.407.218.727.538.945.945.218.407.334.811.334 1.926v4.59c0 1.115-.116 1.519-.334 1.926a2.272 2.272 0 01-.945.945c-.407.218-.811.334-1.926.334h-7.59c-1.115 0-1.519-.116-1.926-.334a2.272 2.272 0 01-.945-.945C1.116 12.314 1 11.91 1 10.795v-4.59c0-1.115.116-1.519.334-1.926.218-.407.538-.727.945-.945C2.686 3.116 3.09 3 4.205 3h7.59zm.172 1.5H4.033c-.65.007-.847.05-1.047.157a.772.772 0 00-.33.33c-.106.199-.15.396-.155 1.046v4.934c.006.65.049.847.156 1.047a.772.772 0 00.33.33c.215.115.428.156 1.218.156h7.59c.79 0 1.003-.041 1.219-.157a.772.772 0 00.33-.33c.115-.215.156-.428.156-1.218v-4.59c0-.79-.041-1.003-.157-1.219a.772.772 0 00-.33-.33c-.182-.097-.363-.142-.892-.153l-.154-.002z", fillRule: "nonzero" }),
        React.createElement("path", { d: "M14 5.271v2.062h-1.985a.101.101 0 01-.032-.004l-.029-.015a.101.101 0 01-.036-.113l.015-.03 1.1-1.314a.05.05 0 00-.051-.08l-.024.012-1.691 1.516a.101.101 0 01-.168-.06v-.032l.151-.914a.05.05 0 00-.083-.047l-.012.016-.572 1.154a.253.253 0 01-.067.084l-.046.03-.748.377a.05.05 0 00.003.092l.02.004h.666c.067 0 .131.026.178.073l.032.038 2.3 3.373a.05.05 0 00.092-.032l-.005-.02-1.593-3.212a.152.152 0 01.095-.215l.04-.005H14v3.844l-.553.627a.152.152 0 01-.214.014l-.014-.014-4.007-4.352a.203.203 0 01-.015-.25L11 5.528a.203.203 0 01.144-.085L13.31 5.2h.045l.644.071z" }),
        React.createElement("path", { d: "M5.75 6a.75.75 0 010 1.5h-1.5a.75.75 0 010-1.5h1.5zm1.5 2.5a.75.75 0 010 1.5h-3a.75.75 0 010-1.5h3z", fillRule: "nonzero" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
