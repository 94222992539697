import styled from 'styled-components'

import { breakpoint } from '@sketch/global-styles'
import { Filter } from '../Filter'
import { FilterOld } from '../FilterOld'

export const NavbarFilter = styled(Filter)``

export const NavbarFilterOld = styled(FilterOld)`
  min-width: 170px;

  input[type='text'] {
    background: white;
  }

  ${breakpoint('xs')`
    max-width: 300px;
  `}
`
