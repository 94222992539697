import {
  useGetWorkspaceProfileQuery,
  WorkspaceProfileFragment,
} from '@sketch/gql-types'

export interface WorkspaceProfileResultSuccess {
  loading: false
  profile: WorkspaceProfileFragment
  error?: undefined
}

export interface WorkspaceProfileResultLoading {
  loading: true
  profile: WorkspaceProfileFragment | undefined
  error?: undefined
}

export interface WorkspaceProfileResultError {
  loading?: false
  profile?: undefined
  // At the moment only 'NOT_FOUND' error is expected
  error: { type: 'NOT_FOUND' }
}

export type WorkspaceProfileResult =
  | WorkspaceProfileResultSuccess
  | WorkspaceProfileResultLoading
  | WorkspaceProfileResultError

type WorkspaceIdentifier = {
  workspaceIdentifier: string
}
type ShortUrlName = { shortUrlName: string }
type UseGetWorkspaceProfileVariables = OneOf<WorkspaceIdentifier, ShortUrlName>

export interface UseGetWorkspaceProfileProps {
  variables: UseGetWorkspaceProfileVariables
  skip?: boolean
}

export const useGetWorkspaceProfile = ({
  variables,
  skip,
}: UseGetWorkspaceProfileProps): WorkspaceProfileResult => {
  const { data, loading, error } = useGetWorkspaceProfileQuery({
    variables,
    skip,
  })

  const profile = data?.workspaceProfile

  if (loading) {
    return { loading, profile }
  }

  if (error || !profile) {
    return { error: { type: 'NOT_FOUND' } }
  }

  return { loading, profile }
}
