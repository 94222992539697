import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "at-email-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd", stroke: "currentColor", strokeWidth: 1.5 },
        React.createElement("circle", { cx: 8, cy: 8, r: 3 }),
        React.createElement("path", { d: "M14.5 8a6.5 6.5 0 10-2.566 5.174", strokeLinecap: "round" }),
        React.createElement("path", { d: "M14.5 8v.333c0 .92-.784 1.667-1.75 1.667S11 9.254 11 8.333V8" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
