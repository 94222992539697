import styled from 'styled-components'
import { LoadingPlaceholder as BaseLoadingPlaceholder } from '@sketch/components'

export const LoadingPlaceholder = styled(BaseLoadingPlaceholder)`
  margin: auto;
`

export const Wrapper = styled.div`
  min-width: 88px;
  display: flex;
  align-items: center;
`
