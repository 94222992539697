import React from 'react'
import { Route, Switch, useHistory } from 'react-router'

import { useGetInitialUserQuery } from '@sketch/gql-types'

import { routes, useGetNewNotificationsCount } from '@sketch/modules-common'
import { SelectDropdownLinkItem } from '@sketch/components'

import UserNavbarSection from '../../../user/components/UserNavbarSection'

import {
  NoWorkspacesAvatar,
  NoWorkspacesDropdown,
  NoWorkspaceEmptyState,
  NoWorkspaceIcon,
  Title,
  Description,
} from './NoWorkspaceSidebar.styles'

import {
  SidebarWrapper,
  MenuWrapper,
  WorkspaceSelectorWrapper,
  FooterWrapper,
  Spacer,
  MenuListItem,
} from './Sidebar.styles'

import { useThemeContext } from '@sketch/global-styles'

import { ReactComponent as BackArrow } from '@sketch/icons/arrow-top-left-16'
import BottomMenu from './components/BottomMenu/BottomMenu'

import { UserSettingsSidebarMenu } from './SettingsSidebar'

interface NoWorkspaceSidebarProps {
  missingAuthorizations: boolean
}

export const NoWorkspaceSidebar = (props: NoWorkspaceSidebarProps) => {
  const { missingAuthorizations } = props

  const history = useHistory()
  const { data: userData } = useGetInitialUserQuery()

  const { isDarkMode } = useThemeContext()

  const isPartner = userData?.me.isPartner

  useGetNewNotificationsCount()

  return (
    <SidebarWrapper>
      <WorkspaceSelectorWrapper>
        <NoWorkspacesDropdown
          data-testid="noworkspace-avatar"
          isPartner={isPartner}
          onClick={() => {
            if (missingAuthorizations) {
              history.push(routes.SIGN_OUT.create({}))
              return
            }

            if (!isPartner) {
              history.push(routes.WORKSPACE_CREATE.create({}))
            }
          }}
        >
          <NoWorkspacesAvatar />
        </NoWorkspacesDropdown>
        <UserNavbarSection noWorkspace />
      </WorkspaceSelectorWrapper>
      <MenuWrapper $isDarkMode={isDarkMode}>
        <Switch>
          <Route
            path={[
              routes.WORKSPACE_SETTINGS.template(),
              routes.PERSONAL_SETTINGS.template(),
            ]}
            render={() => (
              <>
                <MenuListItem>
                  <SelectDropdownLinkItem
                    icon={BackArrow}
                    text="Back"
                    to={
                      missingAuthorizations
                        ? routes.SIGN_OUT.create({})
                        : routes.ENTRY.create({})
                    }
                  />
                </MenuListItem>
                <Spacer />
                <UserSettingsSidebarMenu />
              </>
            )}
          />
          <Route
            render={() => (
              <NoWorkspaceEmptyState>
                <NoWorkspaceIcon width={24} height={24} />
                <Title>No Workspace Selected</Title>
                <Description>
                  Create or pick an existing Workspace to view your projects and
                  documents
                </Description>
              </NoWorkspaceEmptyState>
            )}
          />
        </Switch>
      </MenuWrapper>

      <FooterWrapper>
        <BottomMenu />
      </FooterWrapper>
    </SidebarWrapper>
  )
}
