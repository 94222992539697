import styled, { css } from 'styled-components'

import { ReactComponent as ArrowLeftIcon } from '@sketch/icons/arrow.left.circle.24'
import {
  SelectDropdownButtonTrigger,
  SelectDropdownModalBody,
} from '@sketch/components'
export {
  SelectDropdownModalHeader,
  SelectDropdownModalFooter,
  SelectDropdownCancelButton,
} from '@sketch/components'

export const Wrapper = styled.div`
  margin-left: 12px;
`

export const ModalBody = styled(SelectDropdownModalBody)`
  &&& {
    margin-top: 12px;
    padding-bottom: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li > a {
    height: 40px;
  }
`

export const BackButton = styled.button(
  ({ theme }) => css`
    height: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    color: ${theme.colors.foreground.secondary.D};
    font-weight: ${theme.fontWeights.semibold};
    font-size: ${theme.fontSizes.B};
    text-transform: uppercase;
  `
)

export const BackIcon = styled(ArrowLeftIcon)`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  height: 24px;
  width: 24px;
  margin-right: 8px;
`

export const Trigger = styled(SelectDropdownButtonTrigger)`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`
