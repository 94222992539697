import React, { useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { useLocation, useRouteMatch } from 'react-router'

import {
  routes,
  useUserSignedIn,
  useUserProfile,
  useMarketingCookies,
} from '@sketch/modules-common'

import { useBanner } from '@sketch/components'

import VerifyBanner from '../VerifyBanner'

const UserContext = () => {
  const { pathname } = useLocation()

  const { showBanner } = useBanner()
  const isSignedIn = useUserSignedIn()
  const { setCookies, removeCookies } = useMarketingCookies()

  // This is the first component to get data for the user profile.
  // With "cache-and-network" we are making sure the data is up to date
  // PrivateRoute and VerifiedUserRoute are cache only, expecting this data
  // to be valid
  const { data } = useUserProfile(false, {
    skip: !isSignedIn,
  })
  const user = data?.me

  // Manage cookies according to the user authentication
  useEffect(() => {
    if (isSignedIn) {
      setCookies()
    } else {
      removeCookies()
    }
  }, [isSignedIn, setCookies, removeCookies])

  /* Update the sentry context */
  useEffect(() => {
    const { identifier, name } = user || {}

    Sentry.configureScope(scope => {
      scope.setUser(identifier && name ? { identifier, name } : null)
    })
  }, [user])

  /* Show the verified banner if needed */
  // On workspace shares route the BannerCards component is displayed, so we
  // don't trigger the showBanner here to not duplicate information.
  const isWorkspaceSharesRoute = useRouteMatch({
    path: routes.WORKSPACE_SHARES.template(),
    exact: true,
  })

  useEffect(() => {
    if (!isWorkspaceSharesRoute && user?.isVerified === false) {
      const { dismissBanner } = showBanner({
        id: 'verify-email',
        message: <VerifyBanner userEmail={user.email!} />,
      })

      return () => {
        dismissBanner()
      }
    }
  }, [showBanner, user, pathname, isWorkspaceSharesRoute])

  return null
}

export default UserContext
