import { ApolloClient } from 'apollo-client'
import {
  ShareVersionHistoryFragment,
  GetVersionHistoryDocument,
  GetVersionHistoryQuery,
  GetVersionHistoryQueryVariables,
} from '@sketch/gql-types'
import uniqBy from 'lodash.uniqby'
import { dataIdFromObject } from '@sketch/graphql-cache'
import { ErrorHandler } from '@sketch/tracing'

type VersionHistoryListItem = NonNullable<
  GetVersionHistoryQuery['share']
>['versionHistory']['entries'][0]

const mergeEntries = (
  oldEntries: VersionHistoryListItem[],
  newEntries: VersionHistoryListItem[]
) => {
  const merged = uniqBy(
    [...newEntries, ...oldEntries],
    dataIdFromObject
  ) as VersionHistoryListItem[]
  return merged
}

const tryReadVersions = (
  client: ApolloClient<object>,
  shareIdentifier: string
) => {
  try {
    const existingData = client.readQuery<
      GetVersionHistoryQuery,
      GetVersionHistoryQueryVariables
    >({
      query: GetVersionHistoryDocument,
      variables: { shareIdentifier, after: null },
    })
    return existingData
  } catch (err) {
    // It is possible that we will receive an event when
    // versions list is still not yet loaded
    return undefined
  }
}

export const handleDocumentUploadEvent = (
  client: ApolloClient<object>,
  share?: ShareVersionHistoryFragment
) => {
  if (!share || !share.versionHistory) {
    ErrorHandler.shouldNeverHappen(
      'There should always be a version history attached to a share'
    )
    return
  }

  const existingData = tryReadVersions(client, share.identifier)

  const existingTotalCount =
    existingData?.share?.versionHistory.meta.totalCount ?? 0
  const newTotalCount = share.versionHistory.meta.totalCount

  const existingLatestVersion = existingData?.share?.versionHistory.entries[0]
  const newLatestVersion = share.versionHistory.entries[0]

  const isUniqueVersion =
    existingLatestVersion?.identifier !== newLatestVersion.identifier

  // We are adding both counts together and checking if the result is 2
  // because this checks for a truthy values and if the value is exactly "1"
  // (1+1 = 2 :exploding_head:)
  const shouldReplaceVersionHistory =
    existingTotalCount + newTotalCount === 2 && isUniqueVersion

  // This is a special case for VIEWERS and GUESTS where they can only see the latest version
  // We are checking if the queries return
  //     - a truthy total count
  //     - total count is "1" for both the new and existing versions
  //     - new version is different from the existing version
  //
  // if this is the case, we write to the cache a single version, which is the only the user can see
  if (shouldReplaceVersionHistory) {
    return client.writeQuery<
      GetVersionHistoryQuery,
      GetVersionHistoryQueryVariables
    >({
      data: { __typename: 'RootQueryType', share },
      query: GetVersionHistoryDocument,
      variables: { shareIdentifier: share.identifier, after: null },
    })
  }

  const existingAfter =
    existingData?.share?.versionHistory?.meta.after ||
    share.versionHistory.meta.after ||
    null

  const mergedEntries = mergeEntries(
    existingData?.share?.versionHistory?.entries || [],
    share.versionHistory.entries
  )

  const updatedData: GetVersionHistoryQuery = {
    __typename: 'RootQueryType',
    share: {
      ...share,
      versionHistory: {
        __typename: 'VersionHistory',
        entries: mergedEntries,
        meta: {
          ...share.versionHistory.meta,
          after: existingAfter,
        },
      },
    },
  }

  client.writeQuery<GetVersionHistoryQuery, GetVersionHistoryQueryVariables>({
    data: updatedData,
    query: GetVersionHistoryDocument,
    variables: { shareIdentifier: share.identifier, after: null },
  })
}
