import React from 'react'

import { TextEditor } from '@sketch/components'
import type { JSONContent } from '@tiptap/core/dist/packages/core/src/types'
import { useDSDocsEditModeContext } from '../../operations/DSDocsEditModeContext'

interface DsmTextEditorProps {
  content?: JSONContent
  placeholder?: string
}

export const DsmTextEditor: React.FC<DsmTextEditorProps> = ({
  content,
  placeholder,
}) => {
  const { isEditMode, persistContent } = useDSDocsEditModeContext()

  return (
    <TextEditor
      content={content}
      placeholder={placeholder}
      editModeEnabled={isEditMode}
      onBlur={({ editor }) => {
        persistContent(editor.getJSON())
      }}
    />
  )
}
