import { useVersionDeleted } from './useVersionDeleted'
import { useVersionUploadComplete } from './useVersionUploadComplete'
import { useVersionUploadProcessed } from './useVersionUploadProcessed'
import { useVersionUpdated } from './useVersionUpdated/useVersionUpdated'
import { ShareInfoFragment } from '@sketch/gql-types'

export const useVersionSubscriptions = (share?: ShareInfoFragment) => {
  /**
   * Subscription receiving a new version.
   * The order is:
   * 1. Version Uploaded -> useVersionUploadComplete
   * 2. Version Processed -> useVersionUploadProcessed
   *
   * Note: the order of these subscriptions matters for handleDocumentUploadEvent test
   */
  useVersionUploadProcessed(share)
  useVersionUploadComplete(share)

  useVersionDeleted()

  useVersionUpdated()
}
