import React, {
  createContext,
  ReactNode,
  useContext,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { useParams } from 'react-router-dom'

import { RouteParams } from '@sketch/modules-common'
import {
  PresentationManifest,
  PresentationManifestArtboard,
  PresentationManifestPrototypeStructure,
  PrototypeResizeMode,
  isDefinedPresentationManifestArtboard,
  usePrototypeEvent,
} from '@sketch-hq/sketch-web-renderer'
import { fullscreenApi, noop } from '@sketch/utils'

/**
 * Context for values and methods that are global to components within the
 * prototype view.
 */
type PrototypeContext = {
  /**
   * The Artboard UUID that should be passed to the player when it boots, to be
   * used to populate the initial item in the player's Screen nav stack.
   * Note: this value never changes, even when the user navigates to a different
   * Screen. If you need to access the live current Screen value - that state is
   * held by the prototype renderer itself and accessed via the `usePrototypeState`
   * hook exported from the web renderer package.
   */
  initialArtboardUUID: string
  /**
   * The UUID that represents the current Prototype flow being played. This is
   * the Artboard UUID of that flow's start point - this UUID is used when
   * restarting the Prototype.
   */
  prototypeArtboardUUID: string
  /**
   * Are the Artboard UUIDs in the route valid and in the manifest?
   */
  isRouteParamsValid: boolean
  /**
   * The manifest.json file for the prototype's document presentation assets.
   */
  manifest: PresentationManifest | null
  /**
   * The prototype structure data - a flat map of all Artboards appearing in the
   * Prototype. This data is passed to the renderer during its initialization,
   * and is used to create an overview of the document's prototypes.
   */
  prototypeStructure: PresentationManifestPrototypeStructure | null
  /**
   * Helper function to retrieve an Artboard from the prototype structure.
   */
  getArtboard: (id: string) => PresentationManifestArtboard | undefined
  /**
   * Setter for the manifest.json.
   */
  setManifest: Dispatch<SetStateAction<PresentationManifest | null>>
  /**
   * The resize mode for the Prototype renderer.
   */
  resizeMode: PrototypeResizeMode
  /**
   * Setter for the resize mode.
   */
  setResizeMode: Dispatch<SetStateAction<PrototypeResizeMode>>
  /**
   * Whether fullscreen mode is active.
   */
  isFullscreen: boolean
  /**
   * Enter fullscreen mode.
   */
  enterFullscreen: () => void
  /**
   * Exit fullscreen mode.
   */
  exitFullscreen: () => void
  /**
   * Whether the exit fullscreen button should be shown.
   */
  showExitFullscreenButton: boolean
  /**
   * Whether the enter browser supports the fullscreen API.
   */
  supportsFullscreen: boolean
  /**
   * Hide the exit fullscreen button.
   */
  toggleOffExitFullscreenButton: () => void
}

const context = createContext<PrototypeContext | undefined>(undefined)

type PrototypeContextProviderProps = {
  children: ReactNode
}

export function PrototypeContextProvider({
  children,
}: PrototypeContextProviderProps) {
  const params = useParams<RouteParams<'PROTOTYPE_PLAYER'>>()
  const paramsRef = useRef(params)

  const [manifest, setManifest] = useState<PresentationManifest | null>(null)
  const [resizeMode, setResizeMode] = useState<PrototypeResizeMode>(
    PrototypeResizeMode.Fit
  )

  const fullscreen = useFullscreen()

  const prototypeStructure = usePrototypeStructure(manifest)
  const getArtboard = useCallback(
    (id: string) =>
      prototypeStructure?.artboards?.find(artboard => artboard.id === id),
    [prototypeStructure]
  )

  // Access the `initialArtboardUUID` via a ref, to freeze in the initial value.
  // We use the `currentArtboardUUID` route param value to enable deep linking to
  // the current Screen
  const initialArtboardUUID = paramsRef.current.currentArtboardUUID

  // Access the `prototypeArtboardUUID` directly from the route params, since
  // we're ok with this value changing as the route is updated while the user
  // interacts with the PrototypeFlowSelector
  const prototypeArtboardUUID = params.prototypeArtboardUUID

  const isRouteParamsValid = useMemo(
    () =>
      !!getArtboard(initialArtboardUUID) &&
      !!getArtboard(prototypeArtboardUUID),
    [initialArtboardUUID, prototypeArtboardUUID, getArtboard]
  )

  const value = useMemo(() => {
    return {
      manifest,
      setManifest,
      resizeMode,
      setResizeMode,
      initialArtboardUUID,
      prototypeArtboardUUID,
      prototypeStructure,
      isRouteParamsValid,
      getArtboard,
      ...fullscreen,
    } as const
  }, [
    fullscreen,
    getArtboard,
    initialArtboardUUID,
    isRouteParamsValid,
    manifest,
    prototypeStructure,
    resizeMode,
    prototypeArtboardUUID,
  ])

  return <context.Provider value={value}>{children}</context.Provider>
}

export function usePrototypeContext() {
  const contextValue = useContext(context)

  if (!contextValue) {
    throw new Error(
      'usePrototypeContext must be used within a PrototypeContextProvider'
    )
  }

  return contextValue
}

function usePrototypeStructure(manifest: PresentationManifest | null) {
  return useMemo(() => {
    if (!manifest) return null
    const prototypeStructure: PresentationManifestPrototypeStructure = {
      artboards: manifest.contents.pages
        .flatMap(page => page.artboards)
        .filter(isDefinedPresentationManifestArtboard),
    }
    return prototypeStructure
  }, [manifest])
}

/**
 * useBrowserFullscreen
 *
 * This hook tries to create a reactive approach to the browser fullscreen
 * status. It also provides a callback to notify when the fullscreen state changes
 */
function useBrowserFullscreen(
  onBrowserFullscreenChange: (nextState: boolean) => void
) {
  const cachedBrowserFullscreenChange = useRef(onBrowserFullscreenChange)
  useEffect(() => {
    cachedBrowserFullscreenChange.current = onBrowserFullscreenChange
  }, [onBrowserFullscreenChange])

  const [browserFullscreen, setBrowserFullscreen] = useState(
    fullscreenApi.fullscreenElement() !== null
  )

  useEffect(() => {
    function onFullscreenChanged() {
      const nextFullScreenState = Boolean(fullscreenApi.fullscreenElement())

      setBrowserFullscreen(nextFullScreenState)
      cachedBrowserFullscreenChange.current(nextFullScreenState)
    }

    if (fullscreenApi.changeEvent) {
      document.addEventListener(fullscreenApi.changeEvent, onFullscreenChanged)
    }

    return () => {
      if (fullscreenApi.changeEvent) {
        document.removeEventListener(
          fullscreenApi.changeEvent,
          onFullscreenChanged
        )
      }
    }
  }, [])

  return browserFullscreen
}

/**
 * Context logic related to entering and exiting fullscreen mode on the
 * Prototype. We still want to support a fullscreen mode even for browsers that
 * don't support the native fullscreen API, and for these browsers we just
 * visually hide the Prototype header.
 */
function useFullscreen(onFullScreenExit: () => void = noop) {
  const browserFullscreen = useBrowserFullscreen(nextBrowserFullscreen => {
    // User can enter and exit fullscreen mode without calling our functions by
    // using native browser buttons or shortcuts - so we still need to detect
    // this happening and sync our `active` value
    setIsFullscreen(nextBrowserFullscreen)

    // If we are exiting the fullscreen mode we need to validate that the
    // URL is cleared from the fullscreen query parameter to prevent
    // a router re-render from re-triggering it and also a page refresh
    if (!nextBrowserFullscreen) {
      cacheOnFullScreenExit.current()
    }
  })

  /**
   * Whether fullscreen mode is active. This is true when either the native
   * fullscreen API is active *or* we've just hidden the header.
   */
  const [isFullscreen, setIsFullscreen] = useState(() => browserFullscreen)

  const cacheOnFullScreenExit = useRef(onFullScreenExit)
  useEffect(() => {
    cacheOnFullScreenExit.current = onFullScreenExit
  }, [onFullScreenExit])

  /**
   * Whether the exit fullscreen button has been visually toggled on or off.
   * Browsers with a native fullscreen API provide their own UI/UX for exiting
   * fullscreen mode, so in this case we never want to show an exit fullscreen
   * button. For browsers that don't support native fullscreen we'll only toggle
   * the exit fullscreen button on when a user clicks outside a hotspot (the so-
   * called "unhandled interaction" on the Prototype).
   */
  const [
    isExitFullscreenButtonToggled,
    setIsExitFullscreenButtonToggled,
  ] = useState(false)

  const enterFullscreen = useCallback(async () => {
    try {
      await fullscreenApi.requestFullscreen(document.documentElement, {
        navigationUI: 'hide',
      })
    } catch (e) {
      // An error here means the browser doesn't support native fullscreen.
      // We can ignore it however, and set `isFullscreen` to `true` regardless
      // since we still want to hide the header
    }
    setIsFullscreen(true)
  }, [])

  const exitFullscreen = useCallback(async () => {
    try {
      await fullscreenApi.exitFullscreen()
    } catch (e) {
      // Ignore for same reason as above
    }
    setIsFullscreen(false)
    cacheOnFullScreenExit.current()
  }, [])

  const onUnhandledClickInteraction = useCallback(() => {
    if (isFullscreen) {
      setIsExitFullscreenButtonToggled(value => !value)
    }
  }, [isFullscreen])

  usePrototypeEvent('prototypeUnhandledPointerUp', onUnhandledClickInteraction)

  const toggleOffExitFullscreenButton = useCallback(() => {
    setIsExitFullscreenButtonToggled(false)
  }, [])

  // Show the exit fullscreen button if...
  const showExitFullscreenButton =
    isFullscreen && // ...we're in fullscreen mode
    !browserFullscreen && // ... and the browser doesn't have a native fullscreen UX
    isExitFullscreenButtonToggled // ... and we've visually toggled on the button (i.e. user clicked outside a hotspot)

  const supportsFullscreen = Boolean(
    document.fullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.msFullscreenEnabled
  )

  return useMemo(() => {
    return {
      isFullscreen,
      enterFullscreen,
      exitFullscreen,
      showExitFullscreenButton,
      supportsFullscreen,
      toggleOffExitFullscreenButton,
    } as const
  }, [
    enterFullscreen,
    exitFullscreen,
    isFullscreen,
    showExitFullscreenButton,
    supportsFullscreen,
    toggleOffExitFullscreenButton,
  ])
}
