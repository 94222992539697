import React from 'react'
import { HeroSkeleton } from '@sketch/discover'
import { GenericSectionTitleSkeleton } from '@sketch/components'

import { PublicationSliderPlaceholder } from '../../components/PublicationSlider'

const CommunityHubPagePlaceholder = () => (
  <>
    <HeroSkeleton />
    <GenericSectionTitleSkeleton />
    <PublicationSliderPlaceholder />
  </>
)

export default CommunityHubPagePlaceholder
