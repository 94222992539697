import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

import keycode from 'keycode'
import { Flex } from '../Box'
import {
  ClearButtonAndChildrenWrapper,
  ClearButton,
  Container,
  CrossIcon,
  Form,
  LoupeIcon,
  LoupeIconContainer,
  SearchInput,
} from './FilterOld.styles'

export interface FilterPropsOld {
  className?: string
  name?: string
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (value: string) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onSubmit?: (event?: React.FormEvent<HTMLFormElement>) => void
  onClear?: () => void
  placeholder?: string
  value?: string
  width?: number
  isCWV?: boolean
  disabled?: boolean
  children?: React.ReactNode
}

const FilterBase = React.forwardRef<HTMLInputElement, FilterPropsOld>(
  function FilterBase(props, ref) {
    const {
      className,
      name,
      onBlur = () => {},
      onChange = () => {},
      onFocus = () => {},
      onKeyDown = () => {},
      onSubmit = () => {},
      onClear = () => {},
      placeholder = 'Search',
      value = '',
      width,
      children,
      isCWV,
      disabled,
      ...rest
    }: typeof props = props

    const inputRef = useRef<HTMLInputElement>(null)
    useImperativeHandle(ref, () => inputRef.current!)

    // This will alow us to control the keyup handler on a global level. For
    // example, in cases where the input does not have focus.
    const eventListenerAdded = useRef<boolean>(false)

    const [internalValue, setInternalValue] = useState<string>(value)

    // Empty and close search if ESC key is clicked
    const onKeyUpHandler = useCallback(
      e => {
        if (e.keyCode !== keycode('escape')) return

        e.preventDefault()

        setInternalValue('')
        onChange('')

        inputRef.current?.blur()
      },
      [onChange]
    )

    useEffect(() => {
      setInternalValue(value)
    }, [value])

    useEffect(() => {
      if (internalValue && !eventListenerAdded.current) {
        document.addEventListener('keyup', onKeyUpHandler)
        eventListenerAdded.current = true
      }

      return () => {
        if (!internalValue && eventListenerAdded.current) {
          document.removeEventListener('keyup', onKeyUpHandler)
          eventListenerAdded.current = false
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalValue])

    return (
      <Form
        className={className}
        width={width}
        onSubmit={e => {
          e.preventDefault()
          onSubmit(e)
        }}
        {...rest}
      >
        <Container>
          <LoupeIconContainer onClick={() => inputRef.current?.focus()}>
            <LoupeIcon data-testid="loupe-icon" />
          </LoupeIconContainer>
          <SearchInput
            ref={inputRef}
            name={name}
            type="search"
            value={internalValue}
            onKeyDown={onKeyDown}
            data-cwv-search-input={isCWV}
            placeholder={placeholder}
            disabled={disabled}
            onFocus={e => {
              onFocus(e)
            }}
            onBlur={e => {
              if (!internalValue) {
                onBlur(e)
              }
            }}
            onChange={e => {
              setInternalValue(e.target.value)
              onChange(e.target.value)
            }}
          />
          <ClearButtonAndChildrenWrapper>
            {internalValue && (
              <ClearButton
                aria-label="Clear search"
                type="button"
                onClick={() => {
                  inputRef.current?.focus()
                  setInternalValue('')
                  onChange('')
                  onSubmit()
                  onClear()
                }}
              >
                <Flex alignItems="center">
                  <CrossIcon data-testid="cross-icon" />
                </Flex>
              </ClearButton>
            )}
            {children}
          </ClearButtonAndChildrenWrapper>
        </Container>
      </Form>
    )
  }
)

export const Filter = styled(FilterBase)``
