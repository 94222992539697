import React from 'react'

import { Tooltip } from '@sketch/components'

import {
  AddTextWrapper,
  AddButton,
  StyledAddButton,
  ProjectsHeaderText,
  ActionsWrapper,
} from './ProjectsHeader.styles'

export interface ProjectsHeaderProps {
  /** Use for external styling. */
  className?: string
  /** Handle the onClick event of the AddButton. */
  onAdd?: () => void
  /** Hides the add button when projects list is disabled */
  isProjectsListDisabled?: boolean
  /** Shows a tool tip if user cannot add projects */
  userCanAddProjects?: boolean
  collapseButton?: React.ReactElement
}

/**
 * ProjectsHeader
 *
 * This component visually represents the "Projects" text with a (+) button.
 *
 * @example // Basic example
 * <ProjectsHeader onAdd={() => {openModal()}}/>
 */
const ProjectsHeader: React.FC<ProjectsHeaderProps> = ({
  className,
  onAdd,
  isProjectsListDisabled = false,
  userCanAddProjects,
  collapseButton,
}) => {
  const tooltipContent = userCanAddProjects
    ? 'Create a new project'
    : 'Only Editors and Admins can create new projects'

  return (
    <AddTextWrapper className={className}>
      <ProjectsHeaderText>Projects</ProjectsHeaderText>

      <ActionsWrapper>
        {onAdd && !isProjectsListDisabled && (
          <Tooltip
            placement="top"
            content={tooltipContent}
            disableWhenTouchDevice={true}
          >
            <AddButton
              data-testid="add-project"
              onClick={onAdd}
              disabled={!userCanAddProjects}
            >
              <StyledAddButton aria-label="Add a new project" />
            </AddButton>
          </Tooltip>
        )}
        {collapseButton}
      </ActionsWrapper>
    </AddTextWrapper>
  )
}

export default ProjectsHeader
