import React, { useEffect, useRef } from 'react'
import { Form } from '@sketch/components'
import {
  ShareVersionDescriptionContainer,
  ShareVersionDescriptionTextArea,
} from './ShareVersionDescription.styles'

export interface ShareVersionDescriptionProps {
  isStarred?: boolean
  description?: string
  handleOnChange?: (description: string) => void
}

export const ShareVersionDescription = ({
  isStarred,
  description,
  handleOnChange,
}: ShareVersionDescriptionProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus()
      textAreaRef.current.selectionStart = textAreaRef.current.value.length
      textAreaRef.current.selectionEnd = textAreaRef.current.value.length
    }
  }, [])

  return (
    <ShareVersionDescriptionContainer>
      <Form.Field
        id="version-description"
        name="description"
        label="Description"
      >
        <ShareVersionDescriptionTextArea
          onChange={(
            e:
              | React.ChangeEvent<HTMLTextAreaElement>
              | React.ChangeEvent<HTMLInputElement>
          ) => handleOnChange && handleOnChange(e.target.value)}
          ref={textAreaRef}
          placeholder={'Describe this version...'}
          defaultValue={description || ''}
        />
      </Form.Field>
    </ShareVersionDescriptionContainer>
  )
}
