import React, { FC, ReactNode } from 'react'
import { ReactComponent as WarningTriangle } from '@sketch/icons/warning-triangle-64'
import styled from 'styled-components'
import { Button, ErrorMessage } from '@sketch/components'

const StyledContainer = styled.div`
  flex: 1 0 auto;
  max-width: 500px;
  margin: auto;
`

const StyledActionsContainer = styled.div`
  display: flex;
`

const ReloadAction: FC = () => (
  <Button onClick={() => window.location.reload()} variant="secondary">
    Reload Canvas
  </Button>
)

type PageCanvasErrorProps = {
  title?: string
  description: ReactNode
  showReloadCanvasAction?: boolean
}

export const PageCanvasError: FC<PageCanvasErrorProps> = ({
  title = "Couldn't load canvas",
  description,
  showReloadCanvasAction,
}) => {
  const actions: ReactNode[] = []

  if (showReloadCanvasAction) {
    actions.push(<ReloadAction key="reload" />)
  }

  return (
    <StyledContainer>
      <ErrorMessage
        icon={<WarningTriangle />}
        iconSize="medium"
        title={title}
        description={description}
        extra={
          actions.length ? (
            <StyledActionsContainer>{actions}</StyledActionsContainer>
          ) : null
        }
      />
    </StyledContainer>
  )
}
