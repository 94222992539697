import ApolloClient from 'apollo-client'
import { OperationNames, VariablesType } from '@sketch/factory'
import { getOperationName } from 'apollo-link'
import { QueryStoreValue } from 'apollo-client/data/queries'

type TypedVariablesQueryStoreValue<T extends OperationNames> = OmitSafe<
  QueryStoreValue,
  'variables'
> & {
  variables: VariablesType<T>
}
export function getCachedQueriesByOperationName<T extends OperationNames>(
  apolloClient: ApolloClient<any>,
  operationName: T,
  filter: (query: TypedVariablesQueryStoreValue<T>) => boolean = () => true
) {
  const queries = apolloClient.queryManager.queryStore.getStore()

  return Object.values(queries).filter(query => {
    return operationName === getOperationName(query.document) && filter?.(query)
  }) as TypedVariablesQueryStoreValue<T>[]
}
