import styled from 'styled-components'

export const PageWrapper = styled.div`
  display: flex;

  width: 100%;
  max-width: 1200px;
`

export const PanelWrapper = styled.div`
  width: 100%;
`
