import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "drive-exclamation-mark24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fillRule: "evenodd" },
        React.createElement("path", { d: "M15.411 5c1.002 0 1.366.088 1.744.26.377.174.686.432.924.772s.39.683.569 1.669l.51 2.809a5.993 5.993 0 00-4.53 2.49h-6.97a3.5 3.5 0 00-3.465 3h-.036l1.51-8.299c.179-.986.33-1.328.568-1.669.237-.34.547-.598.924-.771C7.536 5.088 7.9 5 8.902 5h6.51z", fillOpacity: 0.15 }),
        React.createElement("path", { d: "M13.842 14.5H7.5a2 2 0 100 4h6.342c.19.54.456 1.044.784 1.5H7.5a3.5 3.5 0 010-7l7.127-.001c-.329.456-.595.96-.785 1.5z", fillRule: "nonzero" }),
        React.createElement("path", { d: "M16.5 15.75a.75.75 0 110 1.5.75.75 0 010-1.5z" }),
        React.createElement("path", { d: "M15.255 5c1.001 0 1.366.088 1.743.26.378.174.687.432.925.772.237.34.389.683.568 1.669l.53 2.82a5.867 5.867 0 00-1.485.308l-.52-2.86c-.119-.65-.185-.862-.293-1.032l-.03-.046a.756.756 0 00-.32-.266c-.183-.085-.365-.12-.949-.124L8.745 6.5c-.72 0-.918.033-1.118.125a.756.756 0 00-.32.266c-.126.18-.193.37-.322 1.078L5.796 14.5 4 16l1.509-8.299c.18-.986.33-1.328.568-1.669.238-.34.547-.598.925-.771C7.379 5.088 7.744 5 8.745 5h6.51z", fillRule: "nonzero" }),
        React.createElement("path", { d: "M19.5 12a4.5 4.5 0 110 9 4.5 4.5 0 010-9zm0 5.75a.75.75 0 100 1.5.75.75 0 000-1.5zm0-3.75a.5.5 0 00-.5.5v2a.5.5 0 101 0v-2a.5.5 0 00-.5-.5z" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
