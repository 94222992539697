import styled from 'styled-components'

import { ReactComponent as LicenseDiamondIcon } from '@sketch/icons/license-diamond-48'
import { Button, Link } from '@sketch/components'

export const StyledLicenseDiamondIcon = styled(LicenseDiamondIcon)`
  width: 48px;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const StyledButton = styled(Button)`
  margin-right: ${({ theme }) => `${theme.space[4]}px`};

  /* Margin bottom for when the link after wraps on mobile devices. */
  margin-bottom: ${({ theme }) => `${theme.space[2]}px`};

  /* Remove default space added to link when directly after button */
  & + a {
    margin-left: 0;
  }
`

export const StyledLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSizes.C};
`
