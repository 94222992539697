import React, { useMemo, useRef } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import { IS_EMBEDDED } from '@sketch/constants'

import { useForTablet, ErrorMessage } from '@sketch/components'
import { SharePageCanvas } from './components/SharePageCanvas'
import {
  RouteParams,
  RouteProps,
  getVersionRenderStatus,
  useQueryParams,
} from '@sketch/modules-common'
import PageAnnotationsOverlayContext from './components/PageAnnotationsOverlayContext'
import PageCanvasViewHeader from './components/PageCanvasViewHeader'
import { PagesFloatingPanel } from './components/PagesFloatingPanel'
import {
  isUpgradeToLatestNeeded,
  UpgradeToLatestVersion,
  useVersioning,
} from '../../versioning'

import { PageCanvasSupportGate } from './components/PageCanvasSupportGate'
import {
  DocumentMobileToolbar,
  isMobileToolboxItem,
} from '../components/DocumentMobileToolbar'
import {
  CanvasRendererProvider,
  useStatus,
} from '@sketch-hq/sketch-web-renderer'

import { usePlaceDraftAnnotation } from '../../annotations/hooks'
import ShareReadyGuard from '../components/ShareReadyGuard'
import { useGetVersionQueryWithPolling } from '../operations'
import { ReactComponent as DocumentIcon } from '@sketch/icons/document-64'
import { AnnotationQueryVariablesProvider } from '../../annotations/context'
import { DraftAnnotationNotifier } from '../../annotations/components'

import { ErrorWrapper, StyledCanvasViewWrapper } from './PageCanvasView.styles'
import { DocumentSidebarLayoutExtraProps } from '../components/DocumentSidebarLayout'
import { useCanvasZoom } from '../../../components/WebRendererCanvas/hooks'

import { Panel } from '../components/Panel'
import { ArtboardDetailInspectorProvider } from '../ArtboardDetailView/components/ArtboardDetailInspectorProvider'
import {
  ArtboardDetailContextProvider,
  useGridAndLayoutPropForZoomComponent,
} from '../ArtboardDetailView'
import { OnboardingPanelFloatingPanels } from './OnboardingPanelFloatingPanels'

import { Zoom } from '../../../components/Zoom'
import {
  MIN_ZOOM,
  MAX_ZOOM,
} from '../../../components/WebRendererCanvas/constants'
import { INSPECTOR_SEGMENT } from '../constants'

const PageCanvasMobileToolBox = () => {
  const canvasStatus = useStatus()
  const [zoom, setPageZoom] = useCanvasZoom()
  const [placeDraftAnnotation, setPlaceDraftAnnotation] =
    usePlaceDraftAnnotation() || []

  const MOBILE_TOOLBAR_ITEMS = [
    'doc-info',
    'search',
    'zoom',
    'add-comment',
    'versions',
    'comments',
  ].filter(isMobileToolboxItem)

  return (
    <DocumentMobileToolbar
      items={MOBILE_TOOLBAR_ITEMS}
      zoom={zoom}
      setZoom={setPageZoom}
      isDraftAnnotationEnabled={placeDraftAnnotation}
      setPlaceDraftAnnotation={setPlaceDraftAnnotation}
      disableAddAnnotationButton={canvasStatus?.type !== 'READY'}
    />
  )
}

type PageCanvasViewProps = RouteProps<'SHARE_VIEW' | 'SHARE_PAGE_CANVAS_VIEW'> &
  DocumentSidebarLayoutExtraProps

const FloatingZoom = () => {
  const [zoom, setZoom] = useCanvasZoom()
  const gridAndLayoutPropsForZoomComponent = useGridAndLayoutPropForZoomComponent()
  const preventiveGridAndLayout = gridAndLayoutPropsForZoomComponent

  return (
    <Zoom
      zoom={zoom}
      setZoom={setZoom}
      minZoom={MIN_ZOOM}
      maxZoom={MAX_ZOOM}
      gridAndLayout={preventiveGridAndLayout}
      explicitControls
    />
  )
}

export default function PageCanvasView(props: PageCanvasViewProps) {
  const {
    HeaderPortal,
    SidebarRightPortal,
    setSidebarLeftOpen,
    toggleSidebarRight,
    isSidebarRightOpen,
    location,
  } = props

  const isInspectPanelOpen = location.hash.includes(INSPECTOR_SEGMENT)

  const canvasRef = useRef<HTMLDivElement>(null)

  const isTabletAndBigger = useForTablet()
  const showPagesList = !IS_EMBEDDED

  const queryParams = useQueryParams<'SHARE_PAGE_CANVAS_VIEW' | 'SHARE_VIEW'>()
  const params = useParams<
    RouteParams<'SHARE_PAGE_CANVAS_VIEW'> | RouteParams<'SHARE_VIEW'>
  >()

  const versioning = useVersioning()
  const {
    share,
    versionShortId,
    isViewingLatestVersion,
    hasPendingPatches,
  } = versioning

  const currentVersion = !versioning.loading ? versioning.currentVersion : null

  const { data, error } = useGetVersionQueryWithPolling({
    variables: {
      shareIdentifier: share.identifier,
      versionShortId,
    },
  })

  const permanentPageIdURL = 'pageUUID' in params ? params.pageUUID : undefined
  const firstDocumentPageIdentifier =
    currentVersion?.document?.pages?.entries[0]?.uuid

  const permanentPageId =
    permanentPageIdURL || firstDocumentPageIdentifier || ''

  const subjects = useMemo(
    () => [{ type: 'PAGE' as const, permanentId: permanentPageId }],
    [permanentPageId]
  )

  /**
   * We should redirect to the SHARE_VIEW when:
   * - The pageUUID matches the permanent identifier of the first page (Consistency)
   * - There's a search query arg and we need to show the results grid
   */
  const shouldRedirectToShareView =
    (firstDocumentPageIdentifier &&
      permanentPageIdURL &&
      permanentPageIdURL === firstDocumentPageIdentifier) ||
    'search' in queryParams

  if (isUpgradeToLatestNeeded(error)) {
    return <UpgradeToLatestVersion error={error} />
  }

  if (shouldRedirectToShareView) {
    const pathname = versioning.getPathname({
      routeKey: 'SHARE_VIEW',
      routeParams: {
        shareID: versioning.share.identifier,
      },
    })

    return <Redirect to={{ ...props.location, pathname }} />
  }

  const disableAnnotations = !share.commentsEnabled || IS_EMBEDDED
  const parsedRenderStatus = getVersionRenderStatus(data?.share?.version)

  const isCurrentVersionStarred = currentVersion?.kind === 'PUBLISHED'

  return (
    <CanvasRendererProvider>
      <ArtboardDetailInspectorProvider
        //  Reset context for each page
        key={permanentPageId}
      >
        <ArtboardDetailContextProvider>
          <AnnotationQueryVariablesProvider
            shareIdentifier={share.identifier}
            subjects={subjects}
          >
            <PageAnnotationsOverlayContext
              disabled={disableAnnotations}
              hidden={isInspectPanelOpen}
              isViewingLatestVersion={isViewingLatestVersion}
              permanentPageId={permanentPageId}
            >
              <HeaderPortal>
                <PageCanvasViewHeader
                  share={share}
                  setSidebarLeftOpen={setSidebarLeftOpen}
                  toggleSidebarRight={toggleSidebarRight}
                  isSidebarRightOpen={isSidebarRightOpen}
                  isViewingLatestVersion={isViewingLatestVersion}
                  isViewingStarredVersion={isCurrentVersionStarred}
                  hasPendingPatches={hasPendingPatches}
                  userCanOpenInApp={
                    !!currentVersion?.document?.userCanOpenInApp
                  }
                  currentVersion={currentVersion}
                />

                <Panel
                  share={share}
                  version={currentVersion || undefined}
                  userCanSeeComments
                  userCanInspect={share.userCanInspect}
                  SidebarRightPortal={SidebarRightPortal}
                />
              </HeaderPortal>

              <FloatingZoom />

              <PageCanvasSupportGate>
                <ShareReadyGuard renderStatus={parsedRenderStatus}>
                  {permanentPageId ? (
                    <StyledCanvasViewWrapper>
                      {showPagesList ? (
                        <PagesFloatingPanel
                          shareIdentifier={share.identifier}
                          versionShortId={versionShortId}
                          permanentPageIdentifier={permanentPageId!}
                        />
                      ) : null}
                      <SharePageCanvas
                        ref={canvasRef}
                        permanentPageIdentifier={permanentPageId!}
                      />

                      <OnboardingPanelFloatingPanels />

                      {isTabletAndBigger && (
                        <DraftAnnotationNotifier canvasRef={canvasRef} />
                      )}
                    </StyledCanvasViewWrapper>
                  ) : (
                    <ErrorWrapper>
                      <ErrorMessage
                        title="No pages in document"
                        icon={<DocumentIcon />}
                        iconSize="medium"
                      >
                        No pages have been added here yet.
                      </ErrorMessage>
                    </ErrorWrapper>
                  )}
                </ShareReadyGuard>
              </PageCanvasSupportGate>

              {!IS_EMBEDDED && !isTabletAndBigger && (
                <PageCanvasMobileToolBox />
              )}
            </PageAnnotationsOverlayContext>
          </AnnotationQueryVariablesProvider>
        </ArtboardDetailContextProvider>
      </ArtboardDetailInspectorProvider>
    </CanvasRendererProvider>
  )
}
