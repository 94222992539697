import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "exclamation-mark-alt-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("circle", { fill: "currentColor", cx: 8, cy: 10.5, r: 1 }),
        React.createElement("path", { d: "M7 4.5h2v3a1 1 0 11-2 0v-3z", fill: "currentColor" }),
        React.createElement("path", { d: "M7.815.75c.584 0 1.082.11 1.486.325.388.206.681.507.877.881h0l4.315 8.238c.169.322.257.68.257 1.044h0V12.5a1.246 1.246 0 01-1.25 1.25h0-11a1.246 1.246 0 01-1.25-1.25h0v-1.262c0-.364.088-.722.257-1.044h0l4.315-8.238A2.248 2.248 0 017.815.75z", stroke: "currentColor", strokeWidth: 1.5 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
