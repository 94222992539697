import React, { useRef, useCallback } from 'react'
import * as yup from 'yup'
import { Portal } from 'react-portal'
import { Formik, FormikHelpers, FormikProps } from 'formik'

import {
  InvitePeopleField,
  InvitePeopleFieldForm,
  InvitePeopleFieldEmailField,
  InvitePeopleContent,
  InvitePeopleFieldInviteButton,
  useFieldAutocomplete,
} from '@sketch/modules-common'
import { ConfirmationDialog } from '@sketch/components'
import { validEmail } from '@sketch/utils'

import { PrivatePermissionsAccessSelect } from '../../../components/AccessLevelSelect'
import { ShareForSettingsModal } from '../types'
import {
  AddShareMembershipMutationVariables,
  GetShareDocument,
  GetShareMembershipsDocument,
  GetShareMembershipsQueryVariables,
  GetShareQueryVariables,
  WorkspaceMembershipFragment,
  useAddSharePermissionGroupRelationMutation,
} from '@sketch/gql-types'
import { useAddShareMembership } from '../../../operations/useAddShareMembership/useAddShareMembership'

import { NonMemberError, FormWrapper } from './InvitePeople.styles'
import { documentShareErrors } from '../../../../../utils/guestInviteValidation'

type Values = Pick<
  AddShareMembershipMutationVariables['input'],
  'guestCommentsEnabled' | 'guestInspectEnabled' | 'privateAccessLevel'
> & {
  shouldValidate: 'email' | 'user' | 'group' | false
  email: string | null
  workspaceMembershipIdentifier: string | null
  groupIdentifier: string | null
  role: WorkspaceMembershipFragment['role']
}

const INITIAL_VALUES: Values = {
  shouldValidate: false,
  guestCommentsEnabled: true,
  guestInspectEnabled: true,
  privateAccessLevel: 'VIEW',
  role: 'GUEST',

  /**
   *  This values might be null or not depending on the
   *  selected value on the dropdown
   */
  email: null,
  workspaceMembershipIdentifier: null,
  groupIdentifier: null,
}

const VALIDATION_SCHEMA = yup.object().shape({
  shouldValidate: yup.string().oneOf(['email', 'user', 'group']).required(),
  email: yup
    .string()
    .nullable()
    .max(255)
    .when('shouldValidate', {
      is: 'email',
      then: schema => validEmail(schema.trim().lowercase()).required(),
    }),
  workspaceMembershipIdentifier: yup
    .string()
    .nullable()
    .when('shouldValidate', {
      is: 'user',
      then: schema => schema.required(),
    }),
  groupIdentifier: yup
    .string()
    .nullable()
    .when('shouldValidate', {
      is: 'group',
      then: schema => schema.required(),
    }),
})

export interface ShareForInvite {
  share: Pick<
    ShareForSettingsModal,
    | 'identifier'
    | 'publicUrl'
    | 'publicAccessLevel'
    | 'publicCommentsEnabled'
    | 'userCanUpdateCommentsEnabled'
    | 'type'
    | 'name'
    | 'workspace'
  >
}

type MembersFilter = 'Guests' | 'Members' | 'Members and Groups'

interface FormikRenderProps {
  formikBag: FormikProps<typeof INITIAL_VALUES>
  shareIdentifier: string
  workspaceIdentifier: string
  loading: boolean
  hideLabel?: boolean
  filter?: MembersFilter
  removeSelf?: boolean
}

const FormikRender = ({
  formikBag,
  shareIdentifier,
  workspaceIdentifier,
  loading,
  hideLabel,
  filter,
  removeSelf,
}: FormikRenderProps) => {
  const containerRef = useRef<HTMLInputElement>(null)

  const autocomplete = useFieldAutocomplete({
    shareIdentifier,
    workspaceIdentifier,
    filter,
    removeSelf,
  })

  const { isValid, values, setFieldValue, errors } = formikBag

  const showError =
    filter &&
    !autocomplete.membersLoading &&
    documentShareErrors.includes(errors.email ?? '')

  return (
    <FormWrapper hasError={showError}>
      <InvitePeopleFieldForm>
        <InvitePeopleFieldEmailField name="email">
          <InvitePeopleField
            label={hideLabel ? '' : 'Manage Access'}
            placeholder="Add a name or email address"
            autocomplete={autocomplete}
            containerRef={containerRef}
            dynamicAction={props => {
              const workspaceMembership = props.selectedItem as Partial<WorkspaceMembershipFragment>
              // We need to check that the membership is not null because when not having any
              // selected user we want to make the checkboxes editable
              const isNotGuest =
                workspaceMembership !== null &&
                workspaceMembership?.role !== 'GUEST'

              return (
                <PrivatePermissionsAccessSelect
                  level={values.privateAccessLevel!}
                  setLevel={level => {
                    setFieldValue('privateAccessLevel', level)

                    if (level === 'EDIT') {
                      setFieldValue('guestCommentsEnabled', true)
                      setFieldValue('guestInspectEnabled', true)
                    }
                  }}
                  setCommentsEnabled={event =>
                    setFieldValue(
                      'guestCommentsEnabled',
                      event.target.checked ?? false
                    )
                  }
                  setInspectEnabled={event =>
                    setFieldValue(
                      'guestInspectEnabled',
                      event.target.checked ?? false
                    )
                  }
                  commentsEnabled={
                    values.guestCommentsEnabled ||
                    values.privateAccessLevel === 'EDIT' ||
                    isNotGuest
                  }
                  inspectEnabled={
                    values.guestInspectEnabled ||
                    values.privateAccessLevel === 'EDIT' ||
                    isNotGuest
                  }
                  disabled={values.privateAccessLevel === 'EDIT' || isNotGuest}
                  showDisabledText={isNotGuest}
                  hideCommentLabel
                />
              )
            }}
          />
        </InvitePeopleFieldEmailField>
        <InvitePeopleFieldInviteButton
          size="32"
          type="submit"
          loading={loading || autocomplete.membersLoading}
          disabled={!isValid}
        >
          Add
        </InvitePeopleFieldInviteButton>
      </InvitePeopleFieldForm>
      {showError && <NonMemberError>{errors.email}</NonMemberError>}
    </FormWrapper>
  )
}

interface InvitePeopleProps extends ShareForInvite {
  hideLabel?: boolean
  filter?: MembersFilter
  removeSelf?: boolean
}

const InvitePeople = ({
  share,
  hideLabel,
  filter,
  removeSelf,
}: InvitePeopleProps) => {
  const {
    addMember,
    addMembershipLoading,
    getAddMemberConfirmDialogProps,
    addMemberConfirmDialogOpen,
  } = useAddShareMembership()

  const [
    addPermissionGroup,
    { loading: addGroupMembershipLoading },
  ] = useAddSharePermissionGroupRelationMutation({
    onError: 'show-toast',
    awaitRefetchQueries: true,
    refetchQueries: () => {
      const shareIdentifier = share.identifier

      return [
        {
          query: GetShareMembershipsDocument,
          variables: { shareIdentifier } as GetShareMembershipsQueryVariables,
        },

        // This refetch is needed basically for a single (and likely not that popular case)
        // when user updates permissions for himself/herself (when the confirmation is required)
        // It is really likely that we could find a more performant solution, if we would notice
        // any performance issues.
        {
          query: GetShareDocument,
          variables: { shortId: shareIdentifier } as GetShareQueryVariables,
        },
      ]
    },
  })

  const loading = addGroupMembershipLoading || addMembershipLoading
  const confirmDialogOpen = addMemberConfirmDialogOpen

  const handleSubmit = useCallback(
    (values: Values, actions: FormikHelpers<Values>) => {
      actions.resetForm()
      const { email, workspaceMembershipIdentifier, groupIdentifier } = values

      if (groupIdentifier) {
        addPermissionGroup({
          variables: {
            permissionGroupIdentifier: groupIdentifier,
            shareIdentifier: share.identifier,
            accessLevel: values.privateAccessLevel!,
          },
        })
      } else if (email || workspaceMembershipIdentifier) {
        const variables = {
          shareIdentifier: share.identifier,
          privateAccessLevel: values.privateAccessLevel,
          guestCommentsEnabled: values.guestCommentsEnabled,
          guestInspectEnabled: values.guestInspectEnabled,
          userIdentifier: values.workspaceMembershipIdentifier || undefined,
          email: values.email || undefined,
        }

        addMember({
          input: variables,
        })
      }
    },
    [share, addMember, addPermissionGroup]
  )

  return (
    <>
      <InvitePeopleContent data-testid="invite-people-form">
        <Formik<Values>
          validateOnBlur={false}
          initialValues={INITIAL_VALUES}
          onSubmit={handleSubmit}
          validationSchema={VALIDATION_SCHEMA}
        >
          {formikBag => (
            <FormikRender
              formikBag={formikBag}
              shareIdentifier={share.identifier}
              workspaceIdentifier={share.workspace.identifier}
              loading={loading}
              hideLabel={hideLabel}
              filter={filter}
              removeSelf={removeSelf}
            />
          )}
        </Formik>
      </InvitePeopleContent>

      {confirmDialogOpen && (
        <Portal>
          <ConfirmationDialog
            {...getAddMemberConfirmDialogProps()}
            title={'Add yourself to this document?'}
          >
            You will no longer be able to edit this document. If you need Edit
            permissions again later, ask a Workspace Admin.
          </ConfirmationDialog>
        </Portal>
      )}
    </>
  )
}

export default InvitePeople
