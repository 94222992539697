import { useMemo } from 'react'
import { ErrorHandler } from '@sketch/tracing'

import {
  ApolloParsedError,
  RawParsedError,
  UserParsedError,
} from './ParsedError'
import { findUserErrors } from './useErrorRedirect'
import {
  ExecutionResult,
  MutateFn,
  MutationResult,
  MutationTuple,
} from './useMutation.types'
import { UseParseErrorHandlerResult } from './useParseErrorHandler'

import type {
  MutationResult as OriginalMutationResult,
  MutationTuple as OriginalMutationTuple,
} from 'react-apollo'
export const getMutationResult = <Data>(
  originalState: OriginalMutationResult<Data>,
  redirectErrors: boolean
): MutationResult<Data> => {
  const { error, ...rest } = originalState
  if (error) {
    return {
      ...rest,
      error: new ApolloParsedError(error),
    }
  }

  if (redirectErrors) {
    const userErrors = findUserErrors(originalState.data)
    if (userErrors.length > 0) {
      return {
        ...rest,
        error: new UserParsedError(userErrors),
      }
    }
  }

  return { ...rest, error: undefined }
}

interface GetMutationFunctionProps<Data, Variables>
  extends Pick<
    UseParseErrorHandlerResult,
    'onApolloErrorHandler' | 'onErrorDispatch'
  > {
  originalMutateFn: OriginalMutationTuple<Data, Variables>[0]
}

const getMutateFunction = <Data, Variables>(
  props: GetMutationFunctionProps<Data, Variables>
): MutateFn<Data, Variables> => {
  const { onApolloErrorHandler, onErrorDispatch, originalMutateFn } = props

  const mutateFn: MutateFn<Data, Variables> = options => {
    if (!onApolloErrorHandler) {
      // here we are allowing originalMutateFn to throw exceptions
      return originalMutateFn(options)
    }

    return new Promise<ExecutionResult<Data>>(res => {
      let isResolved = false
      const resolve = (data: ExecutionResult<Data>) => {
        res(data)
        isResolved = true
      }
      onErrorDispatch(error => {
        if (!isResolved) resolve({ error })
      })

      originalMutateFn(options).then(result => {
        if (result !== undefined) {
          if (!isResolved) resolve(result)
          return
        }

        if (result === undefined) {
          // if onError handler is passed and error occurs, Apollo's useMutation return `undefined` as direct
          // function response. Which isn't expected, nor allowed following TypeScript annotations.
          //
          // However, before reaching this point of the code, we have already checked that `onError` is passed and
          // error received through the `onError` callback should be already returned as the as direct function response.
          //
          // However, if this does not happen (although, that should _never_ happen), we have a last resort safeguard,
          // to ping our sentry and indicate that we have a bug in our code.
          setTimeout(() => {
            if (!isResolved) {
              ErrorHandler.shouldNeverHappen(
                'bug in useMutation proxy - incorrectly resolved direct mutation response'
              )
              resolve({
                error: new RawParsedError(
                  new Error(
                    'Something is wrong, please contact our support team'
                  )
                ),
              })
            }
          }, 500)
        }
      })
    })
  }

  return mutateFn
}

export interface UseFixedMutationTupleProps<Data, Variables>
  extends Pick<
    UseParseErrorHandlerResult,
    'onApolloErrorHandler' | 'onErrorDispatch'
  > {
  originalMutationTuple: OriginalMutationTuple<Data, Variables>
  redirectErrors?: boolean
}

export const useFixedMutationTuple = <Data, Variables>(
  props: UseFixedMutationTupleProps<Data, Variables>
): MutationTuple<Data, Variables> => {
  const {
    originalMutationTuple,
    onErrorDispatch,
    onApolloErrorHandler,
    redirectErrors,
  } = props

  const [originalMutateFn, originalState] = originalMutationTuple

  const mutateFn = useMemo(
    () =>
      getMutateFunction({
        originalMutateFn,
        onApolloErrorHandler,
        onErrorDispatch,
      }),
    [onApolloErrorHandler, onErrorDispatch, originalMutateFn]
  )

  const state = getMutationResult(originalState, redirectErrors ?? false)

  return [mutateFn, state]
}
