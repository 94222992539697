import React, { useState, ComponentPropsWithoutRef } from 'react'
import { useIsMountedRef } from '@sketch/utils'
import { Button } from './Button'

type ButtonProps = ComponentPropsWithoutRef<typeof Button>

export interface AsyncButtonProps extends ButtonProps {
  onClick: (...args: Parameters<ButtonProps['onClick']>) => Promise<any>
  disabled?: boolean
}

const AsyncButton = ({ disabled, onClick, ...props }: AsyncButtonProps) => {
  const [loading, setLoading] = useState(false)
  /**
   * This state will prevent a state to be set if the button is no longer mounted
   * Preventing the react error:
   * "Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in %s.%s"
   */
  const isMountedRef = useIsMountedRef()

  return (
    <Button
      {...props}
      disabled={disabled || loading}
      loading={loading}
      onClick={async (...args) => {
        const promise = onClick(...args)

        setLoading(true)

        try {
          await promise
        } catch (error) {
          // Ignore it's not this components responsibility
          // to handle errors
        } finally {
          isMountedRef.current && setLoading(false)
        }
      }}
    />
  )
}

export { AsyncButton }
