import React from 'react'

import { Link, Banner, Section, useModalContext } from '@sketch/components'

// Modals
import { Setup2FAModal } from '../../modals/Setup2FAModal'
import { Disable2FAModal } from '../../modals/Disable2FAModal'
import { RegenerateRecoveryCodesModal } from '../../modals/RegenerateRecoveryCodesModal'

import {
  Shield,
  Status,
  StyledButton,
  StatusList,
  StyledLink,
} from './TwoFactorAuthenticationPanel.styles'

import { InitialUserFragment } from '@sketch/gql-types'

interface TwoFactorAuthenticationPanelProps {
  mfaState: InitialUserFragment['mfaState']
  isVerified: InitialUserFragment['isVerified']
}

/**
 * Renders the Panel with the Two Factor Authentication controls
 * Allows the user to enable,disable and generate new codes the 2FA
 */
export const TwoFactorAuthenticationPanel: React.FC<TwoFactorAuthenticationPanelProps> = ({
  mfaState,
  isVerified,
}) => {
  const { showModal } = useModalContext()

  const renderMfaState = () => {
    if (mfaState === 'DISABLED') {
      return null
    }

    if (mfaState === 'ENABLED') {
      return (
        <>
          <Status>
            <Shield status={mfaState} /> 2FA Enabled
          </Status>
          <div>
            Lost your recovery codes?
            <StyledLink
              onClick={e => {
                e.preventDefault()

                showModal(RegenerateRecoveryCodesModal)
              }}
              isUnderlined
              external
            >
              Generate new
            </StyledLink>
          </div>
        </>
      )
    }
  }

  const handleMFA = () => {
    if (mfaState === 'DISABLED') {
      showModal(Setup2FAModal)
    }

    if (mfaState === 'ENABLED') {
      showModal(Disable2FAModal)
    }
  }

  const getButtonCopy = () => {
    if (mfaState === 'ENABLED') {
      return 'Disable 2FA…'
    }

    return 'Enable 2FA…'
  }

  const variant = mfaState === 'DISABLED' ? 'primary' : 'negative-secondary'

  return (
    <Section
      title="Two-Factor Authentication (2FA)"
      text={
        <div>
          Two-factor authentication protects your account by adding an extra
          security step when you sign in.{' '}
          <Link
            href="http://www.sketch.com/docs/workspaces/managing-your-sketch-account/#two-factor-authentication-2fa"
            target="_blank"
            isUnderlined
            external
          >
            Learn more
          </Link>
        </div>
      }
      action={
        <StyledButton
          variant={variant}
          onClick={handleMFA}
          size="40"
          disabled={!isVerified}
        >
          {getButtonCopy()}
        </StyledButton>
      }
    >
      {isVerified ? (
        <StatusList>{renderMfaState()}</StatusList>
      ) : (
        <Banner type="warning">
          Verify your email to enable two-factor authentication (2FA).
        </Banner>
      )}
    </Section>
  )
}
