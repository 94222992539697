import { useGetMentionableUsersLazyQuery } from '@sketch/gql-types'

const useMentionableUsers = (shareIdentifier: string) => {
  const [query, { data, loading, called }] = useGetMentionableUsersLazyQuery({
    variables: { shareIdentifier },
  })

  const users = data?.share?.mentionableUsers || []

  return [query, { loading, users, called }] as const
}

export default useMentionableUsers
