import { IS_EMBEDDED } from '@sketch/constants'
import { AugmentedHistory } from './app-wide-types'
import {
  createBrowserHistory,
  Path,
  LocationState,
  LocationDescriptorObject,
  History,
} from 'history'

// history object contains `listen` property, but as of version 4 it isn't possible to listen
// for events which are already pushed but haven't been executed, e.g. for event like `beforePush`
// or `beforeLocationChange`.
// However, it is necessary for us to act on the before event, as otherwise we are degrading user
// experience. Therefore, we have developer `push` method wrapper, which invokes our custom event.
// It would be really helpful to check if this is still needed with higher `history` npm package versions.
const createPushListeners = (originalPush: History<LocationState>['push']) => {
  let pushListeners: ((
    descriptor: LocationDescriptorObject<LocationState>
  ) => void)[] = []

  function onPush(
    fn: (descriptor: LocationDescriptorObject<LocationState>) => void
  ) {
    let isActive = true

    function listener(descriptor: LocationDescriptorObject<LocationState>) {
      if (isActive) fn(descriptor)
    }

    pushListeners.push(listener)

    const cleanup = () => {
      isActive = false
      pushListeners = pushListeners.filter(item => item !== listener)
    }
    return cleanup
  }

  function notifyListeners(
    descriptor: LocationDescriptorObject<LocationState>
  ) {
    pushListeners.forEach(listener => listener(descriptor))
  }

  const push = (
    ...args: [Path, LocationState?] | [LocationDescriptorObject<LocationState>]
  ) => {
    const descriptor: LocationDescriptorObject<LocationState> =
      typeof args[0] === 'object'
        ? args[0]
        : {
            pathname: args[0],
            state: args[1],
          }

    notifyListeners(descriptor)
    return originalPush(...(args as [any, any]))
  }

  return { push, onPush }
}

export const createHistory = (): AugmentedHistory => {
  const history = createBrowserHistory({
    basename: IS_EMBEDDED ? '/embed' : undefined,
  })

  return Object.assign(history, createPushListeners(history.push))
}
