import React from 'react'

import { Button, Box, Modal, ModalInjectedProps } from '@sketch/components'

export interface ChooseTargetDuplicationWorkspaceLayoutProps
  extends ModalInjectedProps {
  shareName: string
  isLoading: boolean
  isEnabled: boolean
  onActionClick?: () => void
  onDuplicationCancel: () => void
}

export const ChooseTargetDuplicationWorkspaceLayout: React.FC<ChooseTargetDuplicationWorkspaceLayoutProps> = props => {
  const {
    shareName,
    children,
    isLoading,
    isEnabled,
    onActionClick,
    onDuplicationCancel,
  } = props

  return (
    <Modal
      title="Duplicate Document to My Drafts?"
      onCancel={onDuplicationCancel}
    >
      <Modal.Body>
        Choose where you want to create a duplicate of “<b>{shareName}</b>”.
        <Box mt={6}>{children}</Box>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onDuplicationCancel}>Cancel</Button>
        <Button
          variant="primary"
          loading={isLoading}
          disabled={!isEnabled}
          onClick={onActionClick}
        >
          Duplicate
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
