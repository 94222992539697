import React from 'react'

import { Button } from '@sketch/components'

interface ShareYourCreationsButtonProps {
  small: boolean
}

const ShareYourCreationsButton = (props: ShareYourCreationsButtonProps) => {
  const { small } = props
  return (
    <Button variant={small ? 'tertiary' : 'primary-untinted'}>
      {small ? 'Share' : 'Share Your Creations'}
    </Button>
  )
}

export default ShareYourCreationsButton
