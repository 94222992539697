import React from 'react'
import { FlatButton, useModalContext } from '@sketch/components'
import { ConfirmRemoveMemberModal } from '../../components/RemoveMemberModal'
import {
  ProjectInSidebarAndHeaderFragment,
  useLeaveProjectMutation,
} from '@sketch/gql-types'

interface LeaveProjectButtonProps {
  project: Pick<ProjectInSidebarAndHeaderFragment, 'identifier' | 'name'>
}

export const LeaveProjectButton: React.FC<LeaveProjectButtonProps> = ({
  project,
}) => {
  const { showModal, hideModal } = useModalContext()
  const [leaveProject] = useLeaveProjectMutation({
    variables: { projectIdentifier: project.identifier },
    onError() {
      // onError is used in `<RemoveMemberButton .../>` to handle the case
      // where a user is removing themselves we must show them the confirmation
      // dialog first. All other errors are ignored there.
      //
      // Since we are showing the confirmation dialog in all circumstances (and
      // providing `confirmed: true`) it is not necessary to handle this error
      // case.
      return
    },
  })

  const onConfirm = async () => {
    await leaveProject()
    hideModal()
  }

  return (
    <FlatButton
      variant="negative"
      onClick={() =>
        showModal(ConfirmRemoveMemberModal, {
          projectName: project.name,
          onConfirm,
          onCancelAction: hideModal,
        })
      }
    >
      Leave Project&hellip;
    </FlatButton>
  )
}
