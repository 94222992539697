import styled, { css } from 'styled-components'

import { ReactComponent as ChevronRight } from '@sketch/icons/chevron-right-16'
import { SelectDropdownItem } from '@sketch/components'

export const Chevron = styled(ChevronRight)`
  width: 16px;
  margin: 0 12px 0 auto;
  opacity: 0.55;
`

export const SubMenuItem = styled(SelectDropdownItem)`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

export const SubMenuTrigger = styled(SelectDropdownItem)`
  margin: 0 8px 0 8px;
  width: fill-available;
`
