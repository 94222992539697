import { castError } from '@sketch/utils'
import ApolloClient from 'apollo-client'
import {
  TrashedShareFragment,
  TrashedShareFragmentDoc,
} from '@sketch/gql-types'
import { dataIdFromObject } from '@sketch/graphql-cache'
import { DownloadProps, getDownloadProps } from '../utils'
import { ErrorHandler } from '@sketch/tracing'
import { useApolloClient } from 'react-apollo'

import {
  useGetShareDropdownOptions,
  UseGetShareDropdownOptionsProps,
} from './useGetShareDropdownOptions'

interface TrashedDropdownOptionsLoading {
  loading: true
  error?: false
  data?: undefined
}

interface TrashedDropdownOptionsError {
  loading?: false
  error: true
  data?: undefined
}

interface TrashedDropdownOptionsData {
  loading?: false
  error?: false
  data: TrashedDropdownOptionsPayload
}

interface TrashedDropdownOptionsPayload {
  userAccessLevel: TrashedShareFragment['userAccessLevel']
  download: DownloadProps
}

export type TrashedDropdownOptions =
  | TrashedDropdownOptionsLoading
  | TrashedDropdownOptionsError
  | TrashedDropdownOptionsData

const tryReadTrashedShare = (
  client: ApolloClient<unknown>,
  identifier: string
) => {
  try {
    const cachedTrashedShare = client.readFragment<TrashedShareFragment>({
      fragment: TrashedShareFragmentDoc,
      fragmentName: 'TrashedShare',
      id: dataIdFromObject({ __typename: 'TrashedShare', identifier })!,
    })
    return cachedTrashedShare
  } catch (e) {
    const err = castError(e)
    ErrorHandler.ignore(err)
    return null
  }
}

export const useGetTrashedShareDropdownOptions = (
  props: OmitSafe<UseGetShareDropdownOptionsProps, 'skip'>
): TrashedDropdownOptions => {
  const { share, version } = props

  const client = useApolloClient()
  const cachedTrashedShare = tryReadTrashedShare(client, share.identifier)

  const dropdownOptions = useGetShareDropdownOptions({
    share,
    version,
    skip: !!cachedTrashedShare,
  })

  if (cachedTrashedShare) {
    const download = getDownloadProps(cachedTrashedShare.version)
    const { userAccessLevel } = cachedTrashedShare
    return {
      data: { download, userAccessLevel },
    }
  }

  if (!dropdownOptions.data) return dropdownOptions

  return {
    data: {
      download: dropdownOptions.data.download,
      userAccessLevel: dropdownOptions.data.share.userAccessLevel,
    },
  }
}
