import React from 'react'

import {
  Box,
  PermissionGroupIcon,
  Tooltip,
  WorkspaceLogo,
} from '@sketch/components'

import ProjectMembershipAvatar from '../ProjectMembershipAvatar'

import { getTriggerCopyFromAccessLevel } from '@sketch/modules-common'
import {
  ProjectMembershipFragment,
  WorkspaceMinimalFragment,
  GetProjectMembershipsQuery,
  ProjectGroupMembershipFragment,
} from '@sketch/gql-types'
import {
  AvatarTooltipWrapper,
  TooltipContentWrapper,
  TooltipContentName,
  TooltipPermissions,
} from './ProjectMembershipsTooltip.styles'

type ProjectWorkspaceAccessLevel = 'DEFAULT' | 'NONE'

type ProjectMembershipsTooltipProps = {
  memberships?: GetProjectMembershipsQuery['project']['members']
  workspaceAccessLevel: ProjectWorkspaceAccessLevel
  workspace: Pick<WorkspaceMinimalFragment, 'identifier' | 'avatar' | 'name'>
  children: React.ReactNode
  disabled?: boolean
  shouldShowWorkspace?: boolean
}

const ProjectMemberRow: React.FC<{
  projectMembership: ProjectMembershipFragment
}> = ({ projectMembership }) => {
  const { workspaceMembership } = projectMembership

  const name =
    workspaceMembership.user?.name || workspaceMembership.invite?.email || ''

  return (
    <AvatarTooltipWrapper>
      <ProjectMembershipAvatar membership={projectMembership} size="16px" />
      <TooltipContentName>{name}</TooltipContentName>
      <TooltipPermissions>
        {workspaceMembership.isEditor ? 'Edit' : 'View'}
      </TooltipPermissions>
    </AvatarTooltipWrapper>
  )
}

interface PermissionGroupMemberRowProps {
  permissionMembership: ProjectGroupMembershipFragment
}

const PermissionGroupMemberRow = ({
  permissionMembership,
}: PermissionGroupMemberRowProps) => {
  const { permissionGroup } = permissionMembership
  const name = permissionGroup.name

  return (
    <AvatarTooltipWrapper>
      <PermissionGroupIcon name={name} size="16px" dark />
      <TooltipContentName>{name}</TooltipContentName>
    </AvatarTooltipWrapper>
  )
}

const ProjectMembershipsTooltip = ({
  children,
  workspace,
  memberships,
  workspaceAccessLevel,
  disabled,
  shouldShowWorkspace = true,
}: ProjectMembershipsTooltipProps) => {
  const members = memberships?.entries || []
  const totalMembershipsCount = memberships?.meta.totalCount || 0

  return (
    <Tooltip
      placement="bottom-start"
      content={
        <TooltipContentWrapper aria-label="Project Access List">
          {'Shared With'}
          {shouldShowWorkspace ? (
            <AvatarTooltipWrapper key={workspace.identifier}>
              <WorkspaceLogo
                src={workspace.avatar?.small || ''}
                workspaceName={workspace.name}
                size="16px"
              />
              <TooltipContentName>{workspace.name}</TooltipContentName>
              <TooltipPermissions>
                {getTriggerCopyFromAccessLevel(workspaceAccessLevel)}
              </TooltipPermissions>
            </AvatarTooltipWrapper>
          ) : null}
          {members.map(membership => {
            if (membership.__typename === 'PermissionGroupRelationProject') {
              return (
                <PermissionGroupMemberRow
                  key={membership.identifier}
                  permissionMembership={membership}
                />
              )
            }

            return (
              <ProjectMemberRow
                key={membership.identifier}
                projectMembership={membership}
              />
            )
          })}

          {totalMembershipsCount > 20 && (
            <Box mt={2}>{totalMembershipsCount - 20} Members</Box>
          )}
        </TooltipContentWrapper>
      }
      spacing="3px"
      disabled={disabled}
      scrollable
    >
      {children}
    </Tooltip>
  )
}

export default ProjectMembershipsTooltip
