import React from 'react'

import {
  BreakpointKeys,
  sortBreakpointsArray,
  clampCurrentBreakpoint,
} from '../../utils/clampCurrentBreakpoint'

import { useCurrentBreakpoint } from '@sketch/components'

import { BREAKPOINTS_IN_PX } from '@sketch/global-styles'
import { ImageContainer, Picture, Placeholder } from './ResponsiveImage.styles'

import { ResponsiveImageFragment, SingleImageFragment } from '@sketch/gql-types'
import { usePreloadImage } from '@sketch/utils'

type Source = { [Key in BreakpointKeys]: string }

export interface ResponsiveImageProps {
  image: SingleImageFragment | ResponsiveImageFragment
  withSkeletonUI?: boolean
  className?: string
}

/**
 * ResponsiveImage
 *
 * This component allows us to render one image per breakpoint.
 *
 * "image" param can be a regular string (the image source) or
 * an object of breakpoints with the url for each of them.
 *
 * Breakpoints are optional. Example:
 *  const image = { source: { md: 'x.png', xl: 'y.png }}
 *  -  x.png will be used or mobile and tablet
 *  -  y.png will be used for desktop and wide
 *
 * It also only loads images when they are just below the fold
 * to avoid loading data we don't need
 *
 * It has an optional skeleton ui while the image is loading
 */
const ResponsiveImage: React.FC<ResponsiveImageProps> = ({
  image,
  withSkeletonUI = false,
  className,
}) => {
  const currentBreakpoint = useCurrentBreakpoint()

  const actualBreakpoint = clampCurrentBreakpoint(currentBreakpoint)

  const srcSet =
    image.__typename === 'SingleImage'
      ? image.src // Only one source available to load
      : (image.source[actualBreakpoint] as string) // Load the src for the current breakpoint

  const { ref, isLoaded } = usePreloadImage({
    srcSet,
  })

  if (!isLoaded && withSkeletonUI) {
    return (
      <Placeholder
        data-testid="image-placeholder"
        className={className}
        ref={ref}
      />
    )
  }

  // We are not using a "srcset", its just a regular image
  if (image.__typename === 'SingleImage') {
    return (
      <img
        className={className}
        srcSet={image!.src}
        alt={image!.alt || undefined}
      />
    )
  }

  // Below this point we have image with sources for each breakpoint
  // We prepare to generate a <picture> with multiple <sources />
  const imageSourceSet = image.source as Source
  const largestSrcPossible =
    imageSourceSet.xl ??
    imageSourceSet.lg ??
    imageSourceSet.md ??
    imageSourceSet.sm

  // Generate a <source /> tag for each breakpoint (used in <picture /> tag)
  // and fitler the GQL key out (this is temporary, until we have a CMS)
  const sourceSetBreakpoints = Object.keys(imageSourceSet).filter(
    source => source !== '__typename'
  ) as BreakpointKeys[]

  const sourceTags = sourceSetBreakpoints
    .sort(sortBreakpointsArray)
    .map(sourceBreakpoint => {
      const breakpointImageSource = imageSourceSet[sourceBreakpoint]

      return (
        <source
          key={sourceBreakpoint}
          media={`(max-width: ${BREAKPOINTS_IN_PX[sourceBreakpoint]}px)`}
          srcSet={breakpointImageSource}
        />
      )
    })

  return (
    <Picture>
      {sourceTags.map(source => source)}
      <ImageContainer
        className={className}
        srcSet={largestSrcPossible}
        alt={image!.alt || undefined}
      />
    </Picture>
  )
}

export default ResponsiveImage
