import styled from 'styled-components'
import { Skeleton } from '@sketch/components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 100%;
`

export const StyledSkeleton = styled(Skeleton)`
  width: auto;
  margin: 20px;
  height: 40px;
`
export const ExportsWrapper = styled.div`
  margin-top: auto;
  position: sticky;
  bottom: 0;
  z-index: 1;

  /* Make sure the overlay has the same color as the parent */
  background: var(
    --background-color,
    ${({ theme }) => theme.colors.background.secondary.A}
  );
`
