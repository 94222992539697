import { useGetProfilePublicationsQuery } from '@sketch/gql-types'

import { usePublicationsPagination } from '../usePublicationsPagination'

export const useGetPublications = (workspaceId: string) => {
  const publicationsResult = useGetProfilePublicationsQuery({
    variables: { workspaceIdentifier: workspaceId },
    skip: !workspaceId,
  })

  const { data, error } = publicationsResult

  const publications = data?.workspaceProfile.publications.entries ?? []

  const { placeholders } = usePublicationsPagination({
    publicationsResult,
    publicationsLength: publications.length,
  })

  /**
   * - if it is `undefined` - it means it is still loading,
   *     i.e. we don't know whether the profile is empty or not
   * - if `false` - we know tha the profile is not empty
   * - if `true` - we know tha the profile is empty
   */
  const isEmpty = data && publications.length === 0
  const loading = publicationsResult.loading

  return { publications, placeholders, isEmpty, error, loading }
}
