import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "globe-64", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 64 64", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M31.57 7.5h.86c5.266 0 9.54.993 13.096 2.895a19.42 19.42 0 018.08 8.079c1.901 3.556 2.894 7.83 2.894 13.097h0v.858c0 5.267-.993 9.541-2.895 13.097a19.42 19.42 0 01-8.079 8.08c-3.556 1.9-7.83 2.894-13.096 2.894h0-.858c-5.267 0-9.541-.993-13.097-2.895a19.42 19.42 0 01-8.08-8.079C8.494 41.97 7.5 37.696 7.5 32.43h0v-.858c0-5.267.993-9.541 2.895-13.097a19.42 19.42 0 018.079-8.08C22.03 8.494 26.304 7.5 31.57 7.5h0z", stroke: "currentColor", strokeWidth: 3 }),
        React.createElement("path", { d: "M32 7.5c1.273 0 2.414.535 3.43 1.452 1.246 1.124 2.3 2.793 3.182 4.85 1.97 4.595 3.03 11.067 3.03 18.198s-1.06 13.603-3.03 18.198c-.882 2.057-1.936 3.726-3.182 4.85-1.016.917-2.157 1.452-3.43 1.452-1.273 0-2.414-.535-3.43-1.452-1.246-1.124-2.3-2.793-3.182-4.85-1.97-4.595-3.03-11.067-3.03-18.198s1.06-13.603 3.03-18.198c.882-2.057 1.936-3.726 3.182-4.85C29.586 8.035 30.727 7.5 32 7.5z", stroke: "currentColor", strokeWidth: 3 }),
        React.createElement("path", { fill: "currentColor", fillRule: "nonzero", d: "M55.833 22.25v3.25H9.25v-3.25zm0 16.25v3.25H9.25V38.5z" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
