import { roundWithLocale } from '@sketch/utils'

export const renderLineHeight = (
  lineHeightAbsolute: number,
  lineHeightRelative?: number
) => {
  if (lineHeightRelative || lineHeightRelative === 0) {
    return `${roundWithLocale(lineHeightAbsolute, 2)}px (${roundWithLocale(
      lineHeightRelative,
      3
    )})`
  }
  return `${roundWithLocale(lineHeightAbsolute, 2)}`
}
