import debounce from 'debounce'
import ApolloClient from 'apollo-client'
import * as Sentry from '@sentry/browser'

import type { History } from 'history'
import {
  AnalyticsContext,
  routes,
  getActiveAuthorization,
  getAllAuthorizations,
  removeActiveAuthorization,
  signOut,
} from '@sketch/modules-common'

export const debouncedLogoutActiveSession = debounce(
  (
    client: ApolloClient<object>,
    history: History,
    operationName: string,
    message: string,
    getAnalytics: () => Pick<AnalyticsContext, 'trackEvent'>,
    reason?: string
  ) => {
    const allAuthorizations = getAllAuthorizations()

    const forceLogoutEventProps = reason
      ? { operationName, message, reason }
      : { operationName, message }

    getAnalytics().trackEvent(
      'AUTH - invalid active authorization removed',
      forceLogoutEventProps
    )

    const activeAuthorization = getActiveAuthorization(client.cache)
    const sentryBreadcrumbData = {
      active: {
        type: activeAuthorization?.type,
        expirationDateTime: activeAuthorization?.fragment.expirationDateTime,
      },
      // Convert to string as arrays don't seem to be displayed on Sentry breadcrumbs UI
      all: JSON.stringify(
        allAuthorizations.map(auth => ({
          type: auth.type,
          expirationDateTime: auth.fragment.expirationDateTime,
        }))
      ),
    }
    if (allAuthorizations.length <= 1) {
      Sentry.addBreadcrumb({
        category: 'Authentication',
        message: `Force signout`,
        level: 'log',
        data: sentryBreadcrumbData,
      })
      getAnalytics().trackEvent(
        'AUTH - user forced to logout',
        forceLogoutEventProps
      )
      signOut({
        client,
        history,
        reason: reason || 'Forced to logout the only active session',
      })
    } else {
      Sentry.addBreadcrumb({
        category: 'Authentication',
        message: `Force auth token removal`,
        level: 'log',
        data: sentryBreadcrumbData,
      })
      removeActiveAuthorization(client.cache)
      history.push(routes.ENTRY.create({}))
    }
  },
  100,
  true
)
