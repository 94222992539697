import styled from 'styled-components'
import {
  Button,
  Input,
  inputBaseStyles,
  inputBorderStyle,
} from '@sketch/components'

import { ReactComponent as ChevronsUpDownIcon } from '@sketch/icons/chevrons-up-down-16'

export const PathHighlightedWord = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

export const FakeInputContainer = styled.div`
  ${inputBaseStyles}
  ${inputBorderStyle}
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 2px; /* stylelint-disable-line scales/space */
`

export const UnstyledInput = styled(Input)`
  && {
    border: none;
    background: transparent;
    box-shadow: none;
    outline: none;
    padding-left: 0;
    padding-right: 0;
  }
`

export const ChevronsIcon = styled(ChevronsUpDownIcon)`
  width: 16px;
  height: 100%;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  position: absolute;
  top: 0px;
  right: 8px;
`

export const StyledDropdownTriggerButton = styled(Button)`
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes.D};
  padding-right: 28px;

  &:hover {
    ${ChevronsIcon} {
      color: ${({ theme }) => theme.colors.foreground.secondary.A};
    }
  }

  &[aria-expanded='true'] {
    ${ChevronsIcon} {
      color: ${({ theme }) => theme.colors.sketch.A};
    }
  }
`
