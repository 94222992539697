import React from 'react'
import { useForTablet } from '@sketch/components'
import { Section } from './Section'
import { Sidebar } from './EditorSidebar.styles'
import { WorkspaceMinimalFragment } from '@sketch/gql-types'
import {
  DesignSystem as DesignSystemType,
  DesignSystemSection,
} from '../../types'

interface EditorSidebarProps {
  workspace: WorkspaceMinimalFragment
  designSystem: DesignSystemType
  sections: DesignSystemSection[]
}

const EditorSidebar: React.FC<EditorSidebarProps> = ({
  workspace,
  designSystem,
  sections,
}) => {
  const isTabletAndBigger = useForTablet()

  if (!isTabletAndBigger) {
    return null
  }

  return (
    <Sidebar>
      {sections.map(section => (
        <Section
          key={section.identifier}
          section={section}
          workspaceId={workspace.identifier}
          designSystemId={designSystem.identifier}
        />
      ))}
    </Sidebar>
  )
}

export default EditorSidebar
