import { useCallback } from 'react'
import {
  useGetBrowserSettingsQuery,
  GetBrowserSettingsQuery,
  GetBrowserSettingsDocument,
} from '@sketch/gql-types'

type browserSettings = OmitSafe<GetBrowserSettingsQuery, '__typename'>

export const useBrowserSettings = () => {
  const { data, client } = useGetBrowserSettingsQuery()

  const updateBrowserSettings = useCallback(
    (values: browserSettings) => {
      client.writeQuery<GetBrowserSettingsQuery>({
        query: GetBrowserSettingsDocument,
        data: {
          __typename: 'RootQueryType',
          ...values,
        },
      })
    },
    [client]
  )

  return [data, updateBrowserSettings] as const
}
