import React from 'react'

import { Tooltip } from '@sketch/components'
import NotificationStatusButton from '../NotificationStatusButton'
import useDocumentNotificationSubscription from '../../operations/useNotificationSubscription'

// This is a valid use case to narrow down Component type
// eslint-disable-next-line no-restricted-imports
import { NotificationSubscriptionStatus } from '@sketch/gql-types/expansive'

interface AnnotationSubscriptionStatusProps {
  annotationIdentifier: string
  notificationStatus: NotificationSubscriptionStatus
  variant?: 'button' | 'dropdown'
}

const tooltipCopy: {
  [key in NotificationSubscriptionStatus]: JSX.Element | string
} = {
  ON: 'Stop receiving updates on new comments',
  OFF: 'Receive updates on new comments',
  PARTIAL: '',
} as const

const AnnotationSubscriptionStatus = (
  props: AnnotationSubscriptionStatusProps
) => {
  const { annotationIdentifier, notificationStatus, variant } = props
  const updateNotificationStatus = useDocumentNotificationSubscription()

  const newStatus = notificationStatus === 'ON' ? 'OFF' : 'ON'

  return (
    <Tooltip
      placement="bottom-start"
      disableWhenTouchDevice
      content={tooltipCopy[notificationStatus]}
    >
      <NotificationStatusButton
        data-testid="annotation-notification-status"
        notificationStatus={notificationStatus}
        variant={variant}
        onClick={() => {
          updateNotificationStatus({
            annotationIdentifier,
            state: newStatus,
          })
        }}
      />
    </Tooltip>
  )
}

export default AnnotationSubscriptionStatus
