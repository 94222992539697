import styled from 'styled-components'
import { breakpoint } from '@sketch/global-styles'

export const Wrapper = styled.div`
  max-height: calc(100vh / 2);
  display: flex;
  flex-direction: column;
  margin: -8px 0;

  /* Prevent the canvas cursor from influencing */
  cursor: default;

  /*
  In some implementations the wrappers of the annotation dot
  have "pointer-events: none" to prevent the wrapper from consuming the events
  having "pointer-events: auto" here will make sure this events are still actionable
  even if the parent ain't
   */
  pointer-events: auto;

  ${breakpoint('sm')`
      width: 295px;
  `}
`
