import React from 'react'
import styled from 'styled-components'

import { routes } from '@sketch/modules-common'

import { Dropdown } from '@sketch/components'
import { prepareGroupURL } from '../../../ComponentsWebView/utils'

import { DropdownItem } from '../NavigationItem.styles'

interface CWVNavDropdownProps {
  groups: string[]
  shareIdentifier: string
}

const DropdownWrapper = styled.div`
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  cursor: pointer;
  overflow: hidden;

  &:last-of-type {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`

export const CWVNavDropdown: React.FC<CWVNavDropdownProps> = props => {
  const { groups, shareIdentifier } = props

  const getPageLink = (group: string) => {
    const groupBreadcrumb = [...groups]

    groupBreadcrumb.splice(
      groupBreadcrumb.indexOf(group) + 1,
      groupBreadcrumb.length
    )

    const groupURL = prepareGroupURL(groupBreadcrumb)

    return `${routes.SHARE_VIEW.create({
      shareID: shareIdentifier,
    })}/symbols?${groupURL}`
  }

  return (
    <DropdownWrapper data-testid="groups-selector-dropdown">
      <Dropdown.Header>Groups</Dropdown.Header>
      {groups.map(group => (
        <DropdownItem key={group} to={getPageLink(group)}>
          {group}
        </DropdownItem>
      ))}
    </DropdownWrapper>
  )
}
