import styled, { css } from 'styled-components'

import { ReactComponent as LogoBlackIcon } from '@sketch/icons/logo-black'

import { Link, ExternalLink } from '@sketch/components'

const fixVerticalAlignment = css`
  /* Fix vertical alignment */
  line-height: 0;
`

export const Logo = styled(LogoBlackIcon)`
  width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`

export const StyledLink = styled(Link)`
  ${fixVerticalAlignment}
`

export const StyledExternalLink = styled(ExternalLink)`
  ${fixVerticalAlignment}
`
