import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "bullet-list-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round", d: "M10 5.75h9m-9 6h9" }),
        React.createElement("circle", { fill: "currentColor", cx: 5.75, cy: 5.75, r: 1.25 }),
        React.createElement("circle", { fill: "currentColor", cx: 5.75, cy: 11.75, r: 1.25 }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round", d: "M10 17.75h9" }),
        React.createElement("circle", { fill: "currentColor", cx: 5.75, cy: 17.75, r: 1.25 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
