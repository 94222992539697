import styled from 'styled-components'
import { ReactComponent as ArrowCircleIcon } from '@sketch/icons/arrow-circle-right-16'

export const ContentLayout = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.C};
  align-items: center;
`

export const ChildrenContainer = styled.div`
  flex-grow: 1;
`

export const NoticeIcon = styled.svg`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-right: 8px;
  color: var(--notice__icon);
`

export const StyledArrowCircleIcon = styled(ArrowCircleIcon)`
  width: 16px;
  height: 16px;
`
