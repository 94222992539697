import React from 'react'
import { useField } from 'formik'

import {
  Form,
  CardNumberInput,
  CardCVCInput,
  CardExpiryInput,
} from '@sketch/components'

const INPUT_BY_TYPE = {
  'card-number': CardNumberInput,
  'card-expiration': CardExpiryInput,
  'card-cvc': CardCVCInput,
}

export interface StripeFieldProps {
  className?: string
  name: string
  id: string
  label: string
  type: 'card-number' | 'card-expiration' | 'card-cvc'
  help?: string
}

const StripeField: React.FC<StripeFieldProps> = props => {
  const { className, id, label, name, type, help } = props

  const [field, meta] = useField({ name, id })
  const StripeInput = INPUT_BY_TYPE[type]

  return (
    <Form.StripeField
      className={className}
      htmlFor={id}
      label={label}
      errorText={meta.touched ? meta.error : undefined}
      help={help}
    >
      <StripeInput
        id={id}
        invalid={!!(meta.touched && meta.error)}
        onChange={value => field.onChange({ target: { name, value } })}
        onBlur={() => field.onBlur({ target: { name } })}
      />
    </Form.StripeField>
  )
}

export default StripeField
