import styled from 'styled-components'

import { breakpoint } from '@sketch/global-styles'

import { FlatButton, Pill, PillProps } from '@sketch/components'

export const ButtonCell = styled.div`
  text-align: right;

  ${breakpoint('base', 'sm')`
    margin: 10px 0 0 0;
  `}
`

export const Description = styled.p`
  margin: 0;
`

export const ErrorPill = styled(Pill).attrs<PillProps>({
  variant: 'error',
})`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`

export const StyledButton = styled(FlatButton)`
  display: inline;
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  color: ${({ theme }) => theme.colors.sketch.A};
  cursor: pointer;
`
