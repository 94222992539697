import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "persons-2-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 15 13.5", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "currentColor" },
        React.createElement("path", { d: "M4.5 8.5c1.114 0 2.124.386 2.855 1.017.702.607 1.145 1.443 1.145 2.372-.328.437-.589.567-.9.679-.816.292-1.959.432-3.1.432s-2.284-.14-3.1-.432c-.311-.112-.572-.242-.746-.405-.154-1.203.289-2.039.991-2.646C2.375 8.886 3.386 8.5 4.5 8.5zm0-6c.552 0 1.052.224 1.414.586.362.362.586.862.586 1.414 0 .552-.224 1.052-.586 1.414A1.994 1.994 0 014.5 6.5a1.994 1.994 0 01-1.414-.586A1.994 1.994 0 012.5 4.5c0-.552.224-1.052.586-1.414A1.994 1.994 0 014.5 2.5z", fillOpacity: 0.15 }),
        React.createElement("path", { d: "M4.5 8C6.985 8 9 9.741 9 11.89c0 1.032-2.08 1.568-4.24 1.608l-.26.002c-2.25 0-4.5-.537-4.5-1.61 0-2.084 1.894-3.784 4.275-3.885L4.5 8zm0 1.5c-1.512 0-2.706.887-2.953 1.973l-.014.071.201.081.127.043c.658.21 1.622.332 2.639.332 1.017 0 1.98-.123 2.639-.332.09-.029.17-.057.239-.086l.088-.038-.013-.07c-.236-1.042-1.343-1.9-2.766-1.97L4.5 9.5zm0-7.5a2.5 2.5 0 01.164 4.995L4.5 7a2.5 2.5 0 01-.164-4.995L4.5 2zm0 1.5a1 1 0 100 2 1 1 0 000-2z" }),
        React.createElement("path", { d: "M10.5 6.5c1.114 0 2.124.386 2.855 1.017.702.607 1.145 1.443 1.145 2.372-.328.437-.589.567-.9.679-.816.292-1.959.432-3.1.432h-.08c-.278-1.522-1.258-2.81-2.616-3.612l-.159.13C8.375 6.885 9.386 6.5 10.5 6.5zm0-6c.552 0 1.052.224 1.414.586.362.362.586.862.586 1.414 0 .552-.224 1.052-.586 1.414A1.994 1.994 0 0110.5 4.5a1.994 1.994 0 01-1.414-.586A1.994 1.994 0 018.5 2.5c0-.552.224-1.052.586-1.414A1.994 1.994 0 0110.5.5z", fillOpacity: 0.15 }),
        React.createElement("path", { d: "M10.5 6C12.985 6 15 7.741 15 9.89c0 1.032-2.08 1.568-4.24 1.608l-.26.002h-.015a4.91 4.91 0 00-.363-1.507c.124.005.25.007.378.007 1.017 0 1.98-.123 2.639-.332.09-.029.17-.057.239-.086l.088-.038-.013-.07c-.236-1.042-1.343-1.9-2.766-1.97L10.5 7.5a3.46 3.46 0 00-1.837.504 6.038 6.038 0 00-1.337-.871c.763-.657 1.8-1.08 2.95-1.128L10.5 6zm0-6a2.5 2.5 0 01.164 4.995L10.5 5a2.5 2.5 0 01-.164-4.995L10.5 0zm0 1.5a1 1 0 100 2 1 1 0 000-2z" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
