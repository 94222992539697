import styled, { css } from 'styled-components'
import { SelectorButtonStylingProps } from './types'
import { ReactComponent as ChevronsUpDownIcon } from '@sketch/icons/chevrons-up-down-16'
import { ReactComponent as ChevronsHorizontal } from '@sketch/icons/chevron-horizontal-12'
import { ReactComponent as ChevronVertical } from '@sketch/icons/chevron-vertical-12'
import { ReactComponent as ChevronsUpDownIconNegative } from '@sketch/icons/chevrons-up-down-16-negative'

export const StyledDefaultSelectorButton = styled.button<SelectorButtonStylingProps>`
  ${({
    fullWidth,
    chevrons,
    backArrow,
    chevronVertical,
    theme: { colors, fontSizes, fontWeights, fonts, radii },
  }) => css`
    position: relative;
    color: ${colors.foreground.secondary.C};
    font-size: ${fontSizes.D};
    font-weight: ${fontWeights.regular};
    font-family: ${fonts.buttons};
    line-height: 1.462; /* stylelint-disable-line scales/line-height */
    padding: 7px 12px 6px;
    user-select: none;
    cursor: pointer;
    appearance: none;
    border: none;
    border-radius: ${radii.large};
    background-color: transparent;

    &:hover {
      color: ${colors.foreground.secondary.A};
      background-color: ${colors.state.hover};
    }

    &:active {
      background-color: ${colors.background.tertiary.B};
    }

    &[disabled] {
      cursor: not-allowed;
      pointer-events: none;
      color: ${colors.foreground.secondary.D};
    }

    ${fullWidth &&
    css`
      display: block;
      width: 100%;
    `}

    ${(chevrons || backArrow || chevronVertical) &&
    css`
      padding-right: 28px;
    `}

    ${backArrow &&
    css`
      padding-left: 28px;
    `}
  `}
`

const chevronsStyles = css<{
  disabled?: boolean
}>`
  margin: auto;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 0.55)};
  width: 16px;
  height: 100%;
  position: absolute;
  top: 0;

  /* stylelint-disable-next-line selector-type-no-unknown */
  ${StyledDefaultSelectorButton}:hover & {
    opacity: 1;
  }
`

export const SelectorButtonChevronsUpDown = styled(ChevronsUpDownIcon)`
  ${chevronsStyles};
  right: 8px;
`

export const SelectorButtonChevronsUpDownIconNegative = styled(
  ChevronsUpDownIconNegative
)`
  ${chevronsStyles};
  right: 8px;
`

export const SelectorButtonChevronHorizontal = styled(ChevronsHorizontal)`
  ${chevronsStyles};
  left: 8px;
`
export const SelectorButtonChevronVertical = styled(ChevronVertical)`
  ${chevronsStyles};
  right: 8px;
`

export const StyledHeaderSelectorButton = styled(StyledDefaultSelectorButton)`
  font-size: ${({ theme }) => theme.fontSizes.B};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  text-transform: uppercase;
`

export const StyledStrongSelectorButton = styled(StyledDefaultSelectorButton)`
  border: 1px solid ${({ theme }) => theme.colors.border.A};
`
