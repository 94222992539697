import React from 'react'
import styled from 'styled-components'

const Bar = styled.div<{ progress: number }>`
  width: 100%;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  box-shadow: 0px 1px 2px 0px ${({ theme }) => theme.colors.shadow.inner} inset;
  border-radius: ${({ theme }) => theme.radii.large};
  overflow: hidden;

  ::before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.sketch.A};
    transform: scaleX(calc(${props => props.progress} / 100));
    transform-origin: left center;
    transition: transform
      ${({ theme }) =>
        `${theme.transitions.duration[1]} ${theme.transitions.timing.easeInOut}`};
  }
`

interface ProgressBarProps {
  // Min and Max values, at the moment just for Accessibility reasons
  min?: number
  max?: number
  // The progress of the bar
  progress: number
}

/**
 * ProgressBar
 *
 * A simple progress bar component.
 *
 */
export const ProgressBar: React.FC<ProgressBarProps> = ({
  min = 0,
  progress = 0,
  max = 100,
}) => {
  return (
    <Bar
      role="progressbar"
      progress={progress}
      aria-valuenow={progress}
      aria-valuemin={min}
      aria-valuemax={max}
    />
  )
}
