import { useRouteMatch } from 'react-router'

import { useGetProjectInSidebarQuery } from '@sketch/gql-types'
import { RouteParams, routes } from '@sketch/modules-common'

const useGetActiveProjectInRoute = () => {
  const projectRoute = useRouteMatch<RouteParams<'WORKSPACE_PROJECT'>>({
    path: routes.WORKSPACE_PROJECT.template(),
  })

  const { data: projectData } = useGetProjectInSidebarQuery({
    fetchPolicy: 'cache-only',
    variables: { shortId: projectRoute?.params.projectId || '' },
    skip: !projectRoute?.params.projectId,
  })

  return projectData?.project
}

export default useGetActiveProjectInRoute
