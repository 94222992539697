import React from 'react'

import { IS_EMBEDDED } from '@sketch/constants'
import {
  useForDesktop,
  NewFeatureHighlightPopover,
  PopoverProps,
} from '@sketch/components'

import { EditInSketchButton } from '../EditInSketchButton'
import { NavbarItem } from '../NavbarItem'
import { ShareWithoutVersion } from '../../../versioning'

import { ReactComponent as Sketch } from '@sketch/icons/logo'
import {
  ActionButton,
  DownloadButton,
  IconContainer,
} from './DerivedHeaderActions.styles'

import { useHeaderActionDeriver } from '../../hooks/useHeaderActionDeriver'
import { VersionFragment } from '@sketch/gql-types'

export const REMIX_POPOVER_MODIFIER: PopoverProps['modifiers'] = [
  {
    name: 'offset',
    options: {
      offset: ({ reference }: any) => {
        return [reference.width, 0]
      },
    },
  },
]

interface HeaderActionsProps {
  share: ShareWithoutVersion
  currentVersion: VersionFragment | null
  userCanOpenInApp: boolean
  isViewingLatestVersion: boolean
}

const DerivedHeaderActions: React.FC<HeaderActionsProps> = ({
  share,
  currentVersion,
  userCanOpenInApp,
  isViewingLatestVersion,
}) => {
  const isDesktop = useForDesktop()

  const {
    showDownloadButton,
    showRemixButton,
    isLoading,
    duplicateShare,
    download,
  } = useHeaderActionDeriver({
    share,
    currentVersion,
  })

  if (!isDesktop) {
    return null
  }

  const canOpenInApp = userCanOpenInApp && !IS_EMBEDDED

  return (
    <>
      {showDownloadButton && (
        <DownloadButton href={download.url}>
          Download
          <IconContainer>
            <Sketch width="16" height="16" />
          </IconContainer>
        </DownloadButton>
      )}

      {showRemixButton ? (
        <NavbarItem>
          <NewFeatureHighlightPopover
            placement="bottom-start"
            closeButtonTestId="remix-close-callout"
            highlightPlacement="header"
            titleText="Edit this document"
            bodyText="Create a copy of this document that you can edit in the Mac app."
            localStorageKey="seenRemixButton"
            showPrimaryCloseButton
            modifiers={REMIX_POPOVER_MODIFIER}
          >
            <ActionButton
              onClick={duplicateShare}
              size="32"
              loading={isLoading}
            >
              Remix
              <IconContainer>
                <Sketch width="16" height="16" />
              </IconContainer>
            </ActionButton>
          </NewFeatureHighlightPopover>
        </NavbarItem>
      ) : (
        canOpenInApp && (
          <NavbarItem>
            <EditInSketchButton
              shareId={share.identifier}
              disabled={!isViewingLatestVersion}
              loading={isLoading}
            />
          </NavbarItem>
        )
      )}
    </>
  )
}

export default DerivedHeaderActions
