import React from 'react'

import { Tooltip, TimeAgo } from '@sketch/components'
import { dateFormat } from '@sketch/utils'
import {
  CommentDateWrapper,
  ResolvedContainer,
  ResolvedIcon,
} from './CommentDate.styles'

export interface CommentDateProps {
  isResolved?: boolean
  date: string
  className?: string
}

const CommentDate: React.FC<CommentDateProps> = ({
  date,
  isResolved,
  className,
}) => {
  return (
    <CommentDateWrapper className={className}>
      {isResolved && (
        <Tooltip
          content="Resolved"
          placement="bottom-start"
          tooltipContainerAs={ResolvedContainer}
        >
          <ResolvedIcon
            data-testid="annotation-resolved-icon"
            width="16px"
            height="16px"
          />
        </Tooltip>
      )}
      <Tooltip
        content={dateFormat(new Date(date))}
        placement="bottom-start"
        tooltipContainerAs="span"
      >
        <TimeAgo data-testid="annotation-creation-time" date={date} short />
      </Tooltip>
    </CommentDateWrapper>
  )
}

export default CommentDate
