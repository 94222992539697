import styled, { css } from 'styled-components'

import { Box } from '../../Box'

export const dropdownDividerStyles = css(
  ({ theme }) => css`
    display: block;
    margin: 8px 0;
    height: 1px;
    background: ${theme.colors.border.B};
  `
)

export const DropdownDivider = styled(Box)`
  ${dropdownDividerStyles};
`
