import React from 'react'
import {
  BaseListRow,
  BaseListRowInfo,
  BaseListRowAvatar,
} from '@sketch/components'

import {
  ProjectMembershipFragment,
  WorkspaceMembershipFragment,
} from '@sketch/gql-types'
import { useUserProfile } from '@sketch/modules-common'

type MemberRowProps = {
  identifier: string
  email: string
  name: string
  avatarSrc?: string | null
  inviteStatus?: ProjectMembershipFragment['inviteStatus'] | null
  role: WorkspaceMembershipFragment['role']
  actions: React.ReactNode
  isCurrentUser: boolean
  showStripes: boolean
}

export const MemberRow = ({
  identifier,
  email,
  name,
  avatarSrc = '',
  role,
  inviteStatus,
  actions,
  isCurrentUser,
  showStripes,
}: MemberRowProps) => (
  <BaseListRow
    identifier={email}
    ariaLabel={`Row of ${name || email}`}
    name={name}
    showStripes={showStripes}
    avatar={
      <BaseListRowAvatar
        src={avatarSrc}
        name={`${name || email} avatar`}
      ></BaseListRowAvatar>
    }
    actions={actions}
  >
    <BaseListRowInfo
      identifier={identifier}
      title={name}
      subtitle={email}
      inviteStatus={inviteStatus || undefined}
      role={role}
      isCurrentUser={isCurrentUser}
    />
  </BaseListRow>
)

export interface WorkspaceMemberRowProps {
  workspaceMembership: Pick<
    WorkspaceMembershipFragment,
    'role' | 'user' | 'invite'
  >
  inviteStatus?: ProjectMembershipFragment['inviteStatus'] | null
  actions: React.ReactNode
}

export const WorkspaceMemberRow = ({
  workspaceMembership,
  inviteStatus,
  actions,
}: WorkspaceMemberRowProps) => {
  const userData = useUserProfile(true)
  const memberName = workspaceMembership.user?.name || ''
  const memberEmail =
    workspaceMembership.user?.email || workspaceMembership.invite?.email || ''
  const isCurrentUser =
    userData?.data?.me.identifier === workspaceMembership?.user?.identifier

  return (
    <MemberRow
      identifier={workspaceMembership.user?.identifier || memberEmail}
      email={memberEmail}
      name={memberName}
      avatarSrc={workspaceMembership.user?.avatar?.small}
      inviteStatus={inviteStatus}
      role={workspaceMembership.role}
      actions={actions}
      isCurrentUser={isCurrentUser}
      showStripes
    />
  )
}
