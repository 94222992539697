// This is a valid use case of expansive types
// eslint-disable-next-line no-restricted-imports
import { BillingStatus } from '@sketch/gql-types/expansive'

const ACTIVE_WORKSPACE_SUBSCRIPTION_STATUS = [
  'ACTIVE',
  'TRIALING',
  'PAST_DUE',
  'INCOMPLETE',
]
const CANCELED_WORKSPACE_SUBSCRIPTION_STATUS = [
  'CANCELED',
  'INCOMPLETE_EXPIRED',
  'UNPAID',
]

export const isWorkspaceSubscriptionActive = (status: BillingStatus) =>
  ACTIVE_WORKSPACE_SUBSCRIPTION_STATUS.includes(status)

export const isWorkspaceSubscriptionCanceled = (status: BillingStatus) =>
  CANCELED_WORKSPACE_SUBSCRIPTION_STATUS.includes(status)

export const isWorkspaceSubscriptionTrialling = (status: BillingStatus) =>
  status === 'TRIALING'
