import React from 'react'

import { dataIdFromObject } from '@sketch/graphql-cache'
import { BaseAnnotationFragment, GetAnnotationsQuery } from '@sketch/gql-types'
import { LoadPageSeparator, handleFetchMore } from '@sketch/components'

import Annotation from '../Annotation'
import AnnotationInboxOptions from '../AnnotationInboxOptions'

import CommentSkeleton from '../CommentSkeleton'

import { FetchMoreFunc } from '@sketch/components/src/InfiniteList/InfiniteList'
import { NavLinkProps } from 'react-router-dom'
import { isAnnotationNewOrHasNewComments } from '../../utils'
import Link from '../Link'
import { ResolvedSeparator, Separator } from './AnnotationList.styles'

const ENTRIES_PATH = ['annotations', 'entries']
const LOADING_PAGE_SIZE = 6

const createRenderResolvedSeparator = () => {
  let isResolveSeparatorRendered = false

  return (annotation: BaseAnnotationFragment) => {
    const isAnnotationResolved = !!annotation.resolution?.resolvedAt

    if (isAnnotationResolved && !isResolveSeparatorRendered) {
      isResolveSeparatorRendered = true
      return true
    }

    return false
  }
}

const createUniqueLatestLabel = () => {
  let isLatestLabelRendered = false
  let hasAnnotationUnread = false

  return (annotation: BaseAnnotationFragment) => {
    const isUnreadNewAnnotation = isAnnotationNewOrHasNewComments(annotation)
    if (isUnreadNewAnnotation) {
      hasAnnotationUnread = true
    }

    if (
      !isLatestLabelRendered &&
      !isUnreadNewAnnotation &&
      hasAnnotationUnread
    ) {
      isLatestLabelRendered = true
      return true
    }

    return false
  }
}

interface PreventiveLinkProps extends OmitSafe<NavLinkProps, 'to'> {
  to?: NavLinkProps['to']
  $isResolved?: boolean
}

const PreventiveLink: React.FC<PreventiveLinkProps> = ({
  children,
  ...props
}) => {
  if (!props.to) {
    return <>{children}</>
  }

  return (
    <Link {...props} to={props.to}>
      {children}
    </Link>
  )
}

// Same interface used in "containers/AnnotationsListContainer"
interface ListProps {
  activeAnnotationIdentifier?: string
  resolveAnnotationLink?: (
    annotation: BaseAnnotationFragment
  ) => NavLinkProps['to'] | undefined
}

interface AnnotationListProps extends ListProps {
  entries: BaseAnnotationFragment[]
  meta: GetAnnotationsQuery['annotations']['meta']
  fetchMore: FetchMoreFunc
  listingType: 'ACTIVE_ONLY' | 'ALL' | null
}

const AnnotationList: React.FC<AnnotationListProps> = ({
  entries,
  meta,
  fetchMore,
  resolveAnnotationLink,
  activeAnnotationIdentifier,
  listingType,
}) => {
  const isAllAnnotations = listingType === 'ALL'
  const isActiveOnly = listingType === 'ACTIVE_ONLY'

  const canRenderResolvedSeparator = createRenderResolvedSeparator()
  const canRenderLatestLabel = createUniqueLatestLabel()

  return (
    <>
      {entries.map(annotation => (
        <React.Fragment key={`unresolved-${annotation.identifier}`}>
          {isActiveOnly && canRenderLatestLabel(annotation) && (
            <Separator data-testid="annotation-viewed-separator">
              <span className="sr-only">Viewed</span>
            </Separator>
          )}

          {isAllAnnotations && canRenderResolvedSeparator(annotation) && (
            <ResolvedSeparator>Resolved</ResolvedSeparator>
          )}

          {/* Render the annotation */}
          <PreventiveLink
            to={resolveAnnotationLink?.(annotation)}
            isActive={match =>
              !!match && annotation.identifier === activeAnnotationIdentifier
            }
          >
            <Annotation
              body={annotation.firstComment.body}
              user={annotation.firstComment.user}
              commentNumber={annotation.comments.meta.totalCount}
              createdAt={annotation.firstComment.createdAt}
              hasNewComments={annotation.hasNewComments!}
              resolvedAt={annotation.resolution?.resolvedAt ?? undefined}
              isNew={annotation.isNew!}
              optionsMenu={className => (
                <AnnotationInboxOptions
                  className={className}
                  annotationIdentifier={annotation.identifier}
                  notificationStatus={annotation.subscriptionStatus}
                  isResolved={!!annotation?.resolution}
                  userCanDeleteAnnotation={
                    annotation.firstComment.userCanDelete
                  }
                />
              )}
            />
          </PreventiveLink>
        </React.Fragment>
      ))}

      {meta.after && (
        <LoadPageSeparator
          key={meta.after}
          loadNewPage={handleFetchMore(fetchMore, ENTRIES_PATH, {
            dataIdFromObject,
            after: meta.after,
          })}
        />
      )}

      <CommentSkeleton
        count={Math.min(LOADING_PAGE_SIZE, meta.totalCount - entries.length)}
      />
    </>
  )
}

export default AnnotationList
