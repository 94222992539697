import React from 'react'

import { LoadingPlaceholder } from '@sketch/components'
import { RenderStatus } from '@sketch/modules-common'

import {
  PageLoading,
  ProcessingContainer,
  ProcessingMessage,
} from './ShareReadyGuard.styles'

import { ReactComponent as DocumentProcessing } from '@sketch/icons/document-processing-64'
import { ReactComponent as CloudArrowUp } from '@sketch/icons/cloud-arrow-up-64'
import { ReactComponent as WarningTriangle } from '@sketch/icons/warning-triangle-64'

interface ShareReadyGuardProps {
  renderStatus: RenderStatus
}

const ShareReadyGuard: React.FC<ShareReadyGuardProps> = props => {
  const { renderStatus, children } = props

  switch (renderStatus) {
    case 'ready': {
      return <>{children}</>
    }
    case 'uploading':
      return (
        <ProcessingContainer>
          <ProcessingMessage
            icon={<CloudArrowUp />}
            iconSize="medium"
            title="Uploading document…"
            description="Your uploaded document will appear here in a moment."
          />
        </ProcessingContainer>
      )
    case 'processing': {
      return (
        <ProcessingContainer>
          <ProcessingMessage
            icon={<DocumentProcessing />}
            iconSize="medium"
            title="Processing document…"
            description="Your document will appear here in a moment."
          />
        </ProcessingContainer>
      )
    }
    case 'failed': {
      return (
        <ProcessingContainer>
          <ProcessingMessage
            icon={<WarningTriangle />}
            iconSize="medium"
            title="We could not process this document"
            description="We’re sorry, but something went wrong
                  while processing this document. Please try saving
                  the document again."
          />
        </ProcessingContainer>
      )
    }
    default: {
      return (
        <PageLoading>
          <LoadingPlaceholder size="64px" />
        </PageLoading>
      )
    }
  }
}

export default ShareReadyGuard
