import React from 'react'
import { RouteParams, versionedRoutes } from '@sketch/modules-common'
import { Redirect, useParams } from 'react-router'

export function ArtboardRevisionRedirect() {
  const { revisionDocShortId, permanentArtboardShortId, shareID } = useParams<
    RouteParams<'ARTBOARD_REVISION'>
  >()

  const to = versionedRoutes.ARTBOARD_DETAIL.VERSION.create({
    shareID,
    permanentArtboardShortId,
    versionShortId: revisionDocShortId,
  })

  return <Redirect to={to} push={false} />
}
