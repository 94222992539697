import React, { useEffect } from 'react'
import { Formik, FormikProps } from 'formik'
import * as yup from 'yup'

import { Form, PasswordInput } from '@sketch/components'
import { ParsedError } from '@sketch/graphql-apollo/useMutation'

import { SignInMutation } from '@sketch/gql-types'
import { useSignIn } from '@sketch/modules-common'

type Values = typeof INITIAL_VALUES

interface ErrorsType {
  errors?: ParsedError
}

const INITIAL_VALUES = {
  password: '',
}

const signInSchema = yup.object().shape({
  password: yup.string().required('Password can’t be blank'),
})

const PasswordField = ({
  touched,
  errors,
  values,
  handleChange,
  handleBlur,
}: FormikProps<Values>) => (
  <Form.Field
    name="password"
    label="Password"
    errorText={touched.password ? errors.password : undefined}
    mb={0}
  >
    <PasswordInput
      name="password"
      value={values.password}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  </Form.Field>
)

export const Errors: React.FC<ErrorsType> = ({ errors }) =>
  errors?.message ? <Form.ErrorField>{errors.message}</Form.ErrorField> : null

interface SubmitButtonProps {
  submitButtonRef: React.Ref<HTMLButtonElement>
  disabled?: boolean
  onDisableSubmit?: (disabled: boolean) => void
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  submitButtonRef,
  disabled = false,
  onDisableSubmit,
}) => {
  useEffect(() => {
    onDisableSubmit?.(disabled)
  }, [disabled, onDisableSubmit])

  return <button ref={submitButtonRef} type="submit" hidden />
}

interface SignInWithPasswordFormProps {
  email: string
  onSubmit: () => void
  onCompleted: (data: SignInMutation) => void
  onError: () => void
  onDisableSubmit?: (disabled: boolean) => void
  submitButtonRef: React.Ref<HTMLButtonElement>
}

const SignInWithPasswordForm = ({
  email,
  onSubmit,
  onCompleted,
  onError,
  onDisableSubmit,
  submitButtonRef,
}: SignInWithPasswordFormProps) => {
  const [signIn, { error }] = useSignIn({
    onCompleted,
    onError,
  })

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={async ({ password }) => {
        try {
          onSubmit()
          await signIn({ variables: { email, password } })
        } catch (e) {
          // The error handling should be taken care of by Apollo
        }
      }}
      validationSchema={signInSchema}
      validateOnBlur={false}
    >
      {formikProps => (
        <Form>
          <PasswordField {...formikProps} />
          <Errors errors={error} />
          <SubmitButton
            submitButtonRef={submitButtonRef}
            disabled={!(formikProps.dirty && formikProps.isValid)}
            onDisableSubmit={onDisableSubmit}
          />
        </Form>
      )}
    </Formik>
  )
}

export default SignInWithPasswordForm
