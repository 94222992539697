import React from 'react'
import { ListHeader, ListItemSkeleton } from '@sketch/components'
import { DocumentDescriptionWrapper } from '../EditableDocumentDescription/EditableDocumentDescription.styles'
import { DocumentNameWrapper } from '../EditableDocumentName/EditableDocumentName.styles'

import { Copy, HeaderSection, LinkSection } from './AboutTab.styles'

import { SkeletonDescription, SkeletonTitle } from './AboutTabSkeleton.styles'

const AboutTabSkeleton = () => {
  return (
    <>
      <HeaderSection data-testid="about-tab-skeleton">
        <Copy>Document</Copy>
        <DocumentNameWrapper>
          <SkeletonTitle />
        </DocumentNameWrapper>
        <DocumentDescriptionWrapper>
          <SkeletonDescription />
        </DocumentDescriptionWrapper>
      </HeaderSection>
      <LinkSection>
        <ListHeader>Views</ListHeader>
        <ListItemSkeleton icon />
        <ListItemSkeleton icon />
        <ListItemSkeleton icon />
        <ListItemSkeleton icon />
        <ListItemSkeleton icon />
        <ListItemSkeleton icon />
      </LinkSection>
    </>
  )
}

export default AboutTabSkeleton
