import React, { FC } from 'react'

import { noop } from '@sketch/utils'

import { Button, Link, Modal, ModalInjectedProps } from '@sketch/components'

interface RemovePartnerModalProps extends ModalInjectedProps {
  partnerName: string
}

const RemovePartnerModal: FC<RemovePartnerModalProps> = ({
  partnerName,
  hideModal,
}) => {
  return (
    <Modal onCancel={noop}>
      <Modal.Header>How to Remove a Sketch Partner</Modal.Header>
      <Modal.Body>
        To remove <b>{partnerName}</b> as a Sketch Partner, please get in touch
        with us at{' '}
        <Link external href="mailto:partners@sketch.com">
          partners@sketch.com
        </Link>
        .
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RemovePartnerModal
