import React from 'react'
import { ApolloError } from 'apollo-client'
import * as Sentry from '@sentry/browser'
import styled, { css } from 'styled-components'
import {
  Button,
  ErrorMessageTitle,
  ErrorMessageBody,
  ErrorMessageContainer,
} from '@sketch/components'
import { ReactComponent as WarningTriangle } from '@sketch/icons/warning-triangle-64'

export const Title = styled(ErrorMessageTitle)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.F};
    color: ${theme.colors.foreground.secondary.B};
  `
)

const Body = styled(ErrorMessageBody)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.E};
    color: ${theme.colors.foreground.secondary.D};
  `
)

const IconContainer = styled.div`
  width: 64px;
  height: 64px;
`

const LoadingError = ({
  error,
  componentType,
}: {
  error: ApolloError | undefined
  componentType: 'Color Variables' | 'Layer Styles' | 'Symbols' | 'Text Styles'
}) => {
  if (typeof error === 'string') {
    Sentry.captureException(error)
  } else if (error?.graphQLErrors) {
    const versionForbiddenError = error.graphQLErrors.some(e =>
      [
        'USER_CANT_VIEW_OLD_SHARE_VERSIONS',
        'USER_CANT_VIEW_VERSION_HISTORY',
        'USER_CANT_VIEW_SHARE_VERSION',
      ].includes(e.extensions?.reason)
    )

    if (!versionForbiddenError) {
      Sentry.captureException(error)
    }
  }

  const handleRefreshClick = () => window.location.reload()

  return (
    <ErrorMessageContainer>
      <IconContainer>
        <WarningTriangle />
      </IconContainer>
      <Title>Unable to load {componentType}</Title>
      <Body>Something went wrong when loading {componentType}.</Body>
      <Button variant="primary" onClick={handleRefreshClick}>
        Refresh the page
      </Button>
    </ErrorMessageContainer>
  )
}

export default LoadingError
