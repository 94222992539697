import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "warning-triangle-64", viewBox: "0 0 64 64", xmlns: "http://www.w3.org/2000/svg", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fillOpacity: 0.5, fillRule: "evenodd" },
        React.createElement("path", { d: "M38.047 12.793l18.76 32.182A7 7 0 0150.76 55.5H13.24a7 7 0 01-6.048-10.525l18.76-32.182a7 7 0 0112.095 0z", stroke: "currentColor", strokeOpacity: 0.5, strokeWidth: 3, fillOpacity: 0.15 }),
        React.createElement("path", { d: "M32 21a3.15 3.15 0 013.134 3.48l-1.477 14.028a1.666 1.666 0 01-3.314 0l-1.477-14.027A3.15 3.15 0 0132 21z", fill: "currentColor" }),
        React.createElement("circle", { cx: 32, cy: 46, r: 3, fill: "currentColor" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
