import styled from 'styled-components'
import { Separator } from '@sketch/components'
import { Wrapper, Logo } from '../DesignSystem/DesignSystem.styles'

export const WrapperDocs = styled(Wrapper)``
export const LogoDocs = styled(Logo)`
  margin-top: -32px;
  z-index: 9999;
`

export const Content = styled.div`
  width: 712px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

export const SeparatorDocs = styled(Separator)`
  border-top: 1px solid ${({ theme }) => theme.colors.border.B};
  margin-top: 40px;
  margin-bottom: 8px;
`
