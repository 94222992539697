import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'

import { useToast } from '@sketch/toasts'

import {
  Button,
  Form,
  Modal,
  PasswordInput,
  ModalInjectedProps,
  useModalContext,
} from '@sketch/components'
import { Confirm2FADisabledModal } from '../Confirm2FADisabledModal'

import { Title, Description } from './Disable2FAModal.styles'

// GQL
import { useDisableMfaMutation } from '@sketch/gql-types'

type FormValues = typeof INITIAL_VALUES

const INITIAL_VALUES = {
  password: '',
}

const VALIDATION_SCHEMA = yup.object().shape({
  password: yup.string().required('Password can’t be blank'),
})

export const Disable2FAModal: React.FC<ModalInjectedProps> = ({
  hideModal,
}) => {
  const { showModal } = useModalContext()
  const { showToast } = useToast()

  const [disableMFA, { loading }] = useDisableMfaMutation({
    onCompleted: () => {
      showModal(Confirm2FADisabledModal)
    },
    onError: ({ message }) => {
      showToast(message, 'negative')
    },
  })

  const handleSubmit = (values: FormValues) => {
    disableMFA({
      variables: {
        password: values.password,
      },
    })
  }

  return (
    <Modal onCancel={hideModal}>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, dirty }) => (
          <Form>
            <Modal.Body>
              <Title>Disable Two-Factor Authentication (2FA)?</Title>
              <Description>
                Enter your password to disable two-factor authentication (2FA).
              </Description>

              <Form.Field
                name="password"
                label="Password"
                errorText={touched.password ? errors.password : undefined}
              >
                <PasswordInput
                  name="password"
                  value={values.password}
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Field>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={hideModal} disabled={loading} size="40">
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                loading={loading}
                disabled={!dirty || loading}
                size="40"
              >
                Disable 2FA
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
