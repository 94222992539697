import React from 'react'
import { matchPath, useHistory, useLocation } from 'react-router'

import { Button, Modal, ModalInjectedProps } from '@sketch/components'

import {
  ProjectFragment,
  TrashedProjectFragment,
  useDeleteProjectPermanentlyMutation,
} from '@sketch/gql-types'
import { ProjectName } from './DeleteProjectPermanently.styles'
import { removeFromPaginated, routes } from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'

interface DeleteProjectPermanentlyProps extends ModalInjectedProps {
  // ProjectFragment should be removed once BE returns the newer types in the getTrash
  // https://github.com/sketch-hq/Cloud/issues/11226
  project: Pick<
    ProjectFragment | TrashedProjectFragment,
    'name' | 'identifier' | '__typename'
  >
  workspaceId: string
}

const DeleteProjectPermanently: React.FC<DeleteProjectPermanentlyProps> = props => {
  const { project, workspaceId, hideModal } = props

  const { showToast } = useToast()
  const location = useLocation()
  const history = useHistory()

  const [deleteProject, { loading }] = useDeleteProjectPermanentlyMutation({
    variables: {
      projectIdentifier: project.identifier,
    },
    onError: 'show-toast',
    update: cache => {
      removeFromPaginated(
        cache,
        { __typename: project.__typename, identifier: project.identifier },
        key => key.includes('workspaceTrash')
      )
    },
    onCompleted: () => {
      showToast(`“${project.name}” has been permanently deleted`, 'positive')
      hideModal()

      if (matchPath(location.pathname, routes.WORKSPACE_TRASH.template())) {
        history.replace(routes.WORKSPACE_TRASH.create({ workspaceId }))
      }
    },
  })

  return (
    <Modal
      title="Are you sure you want to permanently delete this item?"
      onCancel={hideModal}
    >
      <Modal.Body>
        Deleting “<ProjectName>{project.name}</ProjectName>” permanently will
        also delete its activity and history across the Workspace. You can’t
        undo this action.
      </Modal.Body>
      <Modal.Footer>
        <form
          onSubmit={e => {
            e.preventDefault()
            deleteProject()
          }}
        >
          <Button variant="secondary" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="negative" type="submit" loading={loading}>
            Delete Permanently
          </Button>
        </form>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteProjectPermanently
