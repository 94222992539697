import { GridSize } from '@sketch/components'

/**
 * Folowing our <Grid /> breakpoint, this array represents the following:
 * 1 row big + small
 * 1 row 50/50 each
 * 1 row small + big
 */
export const GRID_PATTERN = [
  [8, 4],
  [6, 6],
  [4, 8],
].flat() as GridSize[]

export const getGridColumnSize = (index: number) => {
  return GRID_PATTERN[index % GRID_PATTERN.length]
}
