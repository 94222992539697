import {
  AnnotationResolutionInfoFragment,
  InitialUserFragment,
} from '@sketch/gql-types'

export const createAnnotationResolutionInfoFragment = (
  user: InitialUserFragment
): AnnotationResolutionInfoFragment['resolution'] => ({
  __typename: 'AnnotationResolution',
  resolvedAt: new Date().toISOString(),
  user: {
    __typename: 'PublicUser',
    avatar: {
      __typename: 'Avatar',
      small: user.avatar?.small || '',
    },
    identifier: user.identifier,
    name: user.name,
  },
})
