import { SizeFragment } from '@sketch/gql-types'
import { GridColumnProps } from '@sketch/components'

export const convertSize = (size: SizeFragment | null): GridColumnProps => {
  const columnProps: GridColumnProps = {}
  if (!size) return columnProps

  const { __typename, ...remainingSizeProps } = size

  type Key = keyof typeof remainingSizeProps

  Object.entries(remainingSizeProps).forEach(([key, value]) => {
    columnProps[key as Key] = value || undefined
  })

  return columnProps
}
