import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "folder-closed-64", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 64 64", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none" },
        React.createElement("path", { d: "M22 12.5a3.5 3.5 0 012.8 1.4 6.5 6.5 0 005.2 2.6h17.347c2.584 0 3.542.216 4.492.724a4.654 4.654 0 011.937 1.937c.508.95.724 1.908.724 4.492v20.694c0 2.584-.216 3.542-.724 4.492a4.654 4.654 0 01-1.937 1.937c-.95.508-1.908.724-4.492.724H16.653c-2.584 0-3.542-.216-4.492-.724a4.654 4.654 0 01-1.937-1.937c-.508-.95-.724-1.908-.724-4.492V18.744c0-1.596.233-2.665.724-3.583a4.654 4.654 0 011.937-1.937c.918-.491 1.987-.724 3.583-.724z", stroke: "currentColor", strokeWidth: 3 }),
        React.createElement("path", { d: "M10 25h44v22a4 4 0 01-4 4H14a4 4 0 01-4-4V25z", fillOpacity: 0.15, fill: "currentColor" }),
        React.createElement("path", { fill: "currentColor", d: "M10 23h44v3H10z" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
