import { v1 as uuid } from 'uuid'

import { CommentFragment, InitialUserFragment } from '@sketch/gql-types'

export const createCommentFragment = (
  body: string,
  user: InitialUserFragment,
  identifier: string = uuid()
): CommentFragment => ({
  __typename: 'Comment',
  body,
  createdAt: new Date().toISOString(),
  identifier,
  user: {
    __typename: 'PublicUser',
    avatar: {
      __typename: 'Avatar',
      small: user.avatar?.small || '',
    },
    identifier: user.identifier,
    name: user.name,
  },
  isRead: true,
  isEdited: false,
  userIsCreator: true,
  userCanDelete: true,
})
