import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "square-stack-plus-circle-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M15.25 9.5a.25.25 0 01.25.25v1.833a5.985 5.985 0 00-3.243 1.674A5.981 5.981 0 0010.5 17.5c0 1.094.293 2.12.804 3.002L4.75 20.5a.25.25 0 01-.25-.25V9.75a.25.25 0 01.25-.25h10.5z", fillOpacity: 0.15, fill: "currentColor" }),
        React.createElement("path", { d: "M16.5 13a4.5 4.5 0 110 9 4.5 4.5 0 010-9zm0 2a.5.5 0 00-.5.5V17h-1.5a.5.5 0 100 1H16v1.5a.5.5 0 101 0V18h1.5a.5.5 0 100-1H17v-1.5a.5.5 0 00-.5-.5z", fill: "currentColor", fillRule: "nonzero" }),
        React.createElement("path", { d: "M5 5h10a1 1 0 011 1v.5H4V6a1 1 0 011-1zm1-3h8a1 1 0 011 1v.5H5V3a1 1 0 011-1z", fill: "currentColor" }),
        React.createElement("path", { d: "M11.25 21.25h-6.5a1 1 0 01-1-1V9.75a1 1 0 011-1h10.5a1 1 0 011 1v1", stroke: "currentColor", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
