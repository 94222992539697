import { Operation } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'

export const isOperationSubscription = ({ query }: Operation) => {
  const definition = getMainDefinition(query)
  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  )
}

export const isPaymentRequest = ({ query }: Operation) => {
  const { directives } = getMainDefinition(query)
  return directives?.find(({ name: { value } }) => value === 'PaymentQLApi')
}

export const isCapacitorRequest = ({ query }: Operation) => {
  const { directives } = getMainDefinition(query)
  /**
   * Using a custom directive implies that we'll need to write queries like this:
   * query getCollaborativeEdit(...) @capacitorapi { ... }
   */
  return directives?.find(({ name: { value } }) => value === 'capacitorapi')
}
