import { useUserSignedIn } from '@sketch/modules-common'
import { IS_EMBEDDED } from '@sketch/constants'

import {
  useGetWorkspaceQuery,
  useGetWorkspacesForDuplicationQuery,
  VersionFragment,
} from '@sketch/gql-types'
import { getDownloadProps } from '../utils'
import { useDuplicateShareToDrafts } from './useDuplicateShareToDrafts'
import { useCheckCurrentAuthorizationForWorkspace } from '../../sso/operations/useCheckCurrentAuthorizationForWorkspace'
import { getEligibleWorkspacesForDuplication } from '../../workspace/utils/getEligibleWorkspacesForDuplication'
import { ShareWithoutVersion } from '../../versioning'

interface UseDeriveDownloadActionProps {
  share: ShareWithoutVersion
  currentVersion: VersionFragment | null
}

export const useHeaderActionDeriver = ({
  share,
  currentVersion,
}: UseDeriveDownloadActionProps) => {
  const isSignedIn = useUserSignedIn()
  const { checkAuthorization } = useCheckCurrentAuthorizationForWorkspace()

  const {
    data: workspaceData,
    loading: loadingWorkspace,
  } = useGetWorkspaceQuery({
    variables: {
      identifier: share.workspace.identifier,
    },
    skip: !share.workspace.userIsMember,
  })

  const {
    data,
    loading: loadingWorkspacesForDuplication,
    networkStatus,
  } = useGetWorkspacesForDuplicationQuery({
    skip: !isSignedIn,
  })

  const workspaces = data?.me.workspaces ?? []

  const { userCanDuplicate } = getEligibleWorkspacesForDuplication(
    workspaces,
    share.workspace.identifier,
    share.publicAccessLevel,
    { hasAccessToWorkspaceCheckFn: checkAuthorization }
  )

  const [duplicateShare] = useDuplicateShareToDrafts({
    share,
    versionIdentifier: currentVersion?.identifier ?? '',
    workspaceIdentifier: share.workspace.identifier,
  })

  const isEditor = workspaceData?.workspace.userCanEdit
  const download = getDownloadProps(currentVersion)
  const isLoading = loadingWorkspace || loadingWorkspacesForDuplication

  const isViewerWithoutElligibleWorkspaces =
    isSignedIn && !isEditor && workspaces.length === 0 && networkStatus === 7

  const showDownloadButton =
    // Anonymous users see "Download" button
    (!isSignedIn && download?.isAvailable) ||
    // Viewers without elligible Workspaces to duplicate too see the "Download" button
    (isViewerWithoutElligibleWorkspaces && download?.isAvailable)

  const showRemixButton =
    // Signed in, non-Editors with elligible Workspaces to duplicate too see the "Remix" button
    isSignedIn && !isEditor && userCanDuplicate && !IS_EMBEDDED

  return {
    showDownloadButton,
    showRemixButton,
    isLoading,
    duplicateShare,
    download,
  }
}
