import styled from 'styled-components'
import { ReactComponent as ArrowRightCircleIcon } from '@sketch/icons/arrow-right-circle-24'
import { LinkButton } from '@sketch/components'

export const StyledLeftArrow = styled(ArrowRightCircleIcon)`
  width: 24px;
  transform: rotate(180deg);
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

export const StyledButton = styled(LinkButton).attrs({
  variant: 'tertiary',
  size: '32',
})`
  width: 32px;
  height: 32px;
  background-color: transparent;

  &:hover,
  &:active {
    background-color: transparent;
  }
`
