import React, { useEffect, useRef } from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'

import {
  Button,
  Form,
  Input,
  Modal,
  PasswordInput,
  ModalInjectedProps,
} from '@sketch/components'
import { castError, validEmail } from '@sketch/utils'

import { useToast } from '@sketch/toasts'

import { useEmailUpdateMutation } from '@sketch/gql-types'
import { StyledForm } from './ChangeEmailModal.styles'

const SCHEMA = yup.object().shape({
  email: validEmail().required('Email can’t be blank'),
  password: yup.string().required('Current Password can’t be blank'),
})

interface FormValues {
  email: string
  password: string
}

type FormActions = FormikHelpers<FormValues>

interface ChangeEmailModalProps extends ModalInjectedProps {
  initialEmail: string
}

export const ChangeEmailModal = ({
  initialEmail,
  hideModal,
}: ChangeEmailModalProps) => {
  const [updateEmail] = useEmailUpdateMutation({
    redirectErrors: false,
    onError: 'unsafe-throw-exception',
  })
  const { showToast } = useToast()
  const emailInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    emailInput.current?.focus()
    emailInput.current?.select()
  }, [emailInput])

  const handleSubmit = async (values: FormValues, actions: FormActions) => {
    if (initialEmail === values.email) {
      actions.setFieldError('email', `This is the email you're currently using`)
      return
    }

    try {
      const { data } = await updateEmail({
        variables: {
          email: values.email,
          password: values.password,
        },
      })

      const emailUpdate = data?.emailUpdate

      if ((emailUpdate?.errors?.length ?? 0) > 0) {
        throw emailUpdate!.errors[0]
      }

      const successMessage = `Please confirm your new email address using the link we sent to '${values.email}'`
      showToast(successMessage, 'positive')
      hideModal()
    } catch (e) {
      const error = castError(e)
      showToast(error.message, 'negative')
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <Modal title="Change Your Email Address" onCancel={hideModal}>
      <Formik
        initialValues={{ email: initialEmail, password: '' }}
        validationSchema={SCHEMA}
        onReset={hideModal}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          handleReset,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
        }) => (
          <>
            <Modal.Body>
              <StyledForm id="change-email-form">
                <Form.Field
                  name="email"
                  label="Email"
                  errorText={touched.email ? errors.email : undefined}
                >
                  <Input
                    name="email"
                    type="text"
                    placeholder="john@example.com"
                    ref={emailInput}
                    value={values.email}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Field>
                <Form.Field
                  name="password"
                  label="Enter Your Password to Confirm"
                  errorText={touched.password ? errors.password : undefined}
                >
                  <PasswordInput
                    name="password"
                    aria-label="Password"
                    value={values.password}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Field>
              </StyledForm>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="reset"
                variant="secondary"
                disabled={isSubmitting}
                onClick={handleReset}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                loading={isSubmitting}
                disabled={
                  isSubmitting ||
                  values.email.length === 0 ||
                  values.password.length === 0
                }
                form="change-email-form"
              >
                Change Email
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  )
}
