import React from 'react'

import { EditableText } from '@sketch/components'

import MarkdownDocumentDescription from './MarkdownDocumentDescription'
import { DocumentDescriptionWrapper } from './EditableDocumentDescription.styles'

interface EditableDocumentDescriptionProps {
  description?: string
  editHandler?: (description: string) => void
}

const EditableDocumentDescription: React.FC<EditableDocumentDescriptionProps> = ({
  description,
  editHandler,
}) => {
  return (
    <DocumentDescriptionWrapper data-testid="document-description">
      {editHandler ? (
        <EditableText
          element={MarkdownDocumentDescription}
          value={description}
          placeholder="Enter a description..."
          onEdit={editHandler}
          allowNewLines
        />
      ) : (
        <MarkdownDocumentDescription>{description}</MarkdownDocumentDescription>
      )}
    </DocumentDescriptionWrapper>
  )
}

export default EditableDocumentDescription
