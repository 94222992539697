import React, { ErrorInfo } from 'react'

import * as Sentry from '@sentry/browser'
import { GenericError } from '@sketch/components'

export class ArtboardDetailErrorBoundary extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setTag('sketch_error_boundary', 'ArtboardDetailErrorBoundary')
      scope.setExtra('info', info)
      Sentry.captureException(error)
    })
  }

  render() {
    if (this.state.hasError) {
      return <GenericError />
    }

    return this.props.children
  }
}
