import styled, { css } from 'styled-components'
import { PersonAvatar as BaseAvatar, Skeleton } from '@sketch/components'

const Avatar = styled(BaseAvatar)<{ invitationRequested?: boolean }>`
  display: flex;

  ${({ invitationRequested }) =>
    invitationRequested &&
    css`
      opacity: 50%;
    `}
`

const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const NameSkeleton = styled(Skeleton)`
  margin-bottom: 2px; /* stylelint-disable-line scales/space */
`

export { Avatar, NameSkeleton, PlaceholderWrapper }
