import React, { useMemo, createContext, useContext } from 'react'
import { useLocalStorage } from 'react-use'

export type ArtboardDetailContext = {
  isGridEnabled: boolean
  isLayoutEnabled: boolean
  /** Invert the isGridEnabled value  */
  toggleGrid: () => void
  /** Invert the isLayoutEnabled value  */
  toggleLayout: () => void
}

export const artboardDetailContext = createContext<
  ArtboardDetailContext | undefined
>(undefined)

type ArtboardDetailContextProviderProps = {
  children: React.ReactNode
}
export function ArtboardDetailContextProvider({
  children,
}: ArtboardDetailContextProviderProps) {
  const [isGridEnabled, setIsGridEnabled] = useLocalStorage(
    'inspector_grid_enabled',
    false
  )
  const [isLayoutEnabled, setIsLayoutEnabled] = useLocalStorage(
    'inspector_layout_enabled',
    false
  )

  const contextValue = useMemo((): ArtboardDetailContext => {
    const isGridActive = isGridEnabled || false
    const isLayoutActive = isLayoutEnabled || false

    return {
      isGridEnabled: isGridActive,
      toggleGrid: () => {
        setIsGridEnabled(!isGridActive)
      },
      isLayoutEnabled: isLayoutActive,
      toggleLayout: () => {
        setIsLayoutEnabled(!isLayoutActive)
      },
    }
  }, [isGridEnabled, isLayoutEnabled, setIsGridEnabled, setIsLayoutEnabled])

  return (
    <artboardDetailContext.Provider value={contextValue}>
      {children}
    </artboardDetailContext.Provider>
  )
}

export function useArtboardDetailContext() {
  const value = useContext(artboardDetailContext)

  if (typeof value === 'undefined') {
    throw new Error(
      'useArtboardDetailContext must be used within an ArtboardDetailContextProvider component'
    )
  }

  return value
}
