import React from 'react'
import { Dropdown, useModalContext } from '@sketch/components'
import { WorkspacePermissionGroupFragment } from '@sketch/gql-types'

import RenamePermissionGroupModal from '../../modals/RenamePermissionGroupModal'
import PermissionGroupMemberModal from '../../modals/PermissionGroupMembersModal'
import DeletePermissionGroupModal from '../../modals/DeletePermissionGroupModal'
import PermissionGroupMembershipsModal from '../../modals/PermissionGroupMembershipsModal'

interface PermissionGroupOptionsProps {
  permissionGroup: WorkspacePermissionGroupFragment
  workspaceIdentifier: string
}

const PermissionGroupOptions = (props: PermissionGroupOptionsProps) => {
  const { permissionGroup, workspaceIdentifier } = props
  const { showModal } = useModalContext()

  return (
    <>
      <Dropdown.Header>Manage Group</Dropdown.Header>
      <Dropdown.Item
        onClick={() =>
          showModal(PermissionGroupMemberModal, {
            permissionGroupIdentifier: permissionGroup.identifier,
            permissionGroupName: permissionGroup.name,
            workspaceIdentifier,
          })
        }
      >
        View and Edit Members…
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() =>
          showModal(RenamePermissionGroupModal, { permissionGroup })
        }
      >
        Rename Group…
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        onClick={() =>
          showModal(PermissionGroupMembershipsModal, { permissionGroup })
        }
      >
        Projects and Documents…
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        intent="negative"
        onClick={() =>
          showModal(DeletePermissionGroupModal, {
            workspaceIdentifier,
            permissionGroupIdentifier: permissionGroup.identifier,
            permissionGroupName: permissionGroup.name,
          })
        }
      >
        Delete Group…
      </Dropdown.Item>
    </>
  )
}
export default PermissionGroupOptions
