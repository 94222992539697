import React from 'react'

import { PersonAvatar, PersonAvatarProps } from '@sketch/components'

import { ProjectMembershipFragment } from '@sketch/gql-types'

type ProjectMembershipAvatarProps = {
  membership: ProjectMembershipFragment
  size: PersonAvatarProps['size']
}

const ProjectMembershipAvatar: React.FC<ProjectMembershipAvatarProps> = ({
  membership,
  size,
}) => {
  const { workspaceMembership } = membership

  // Guests can be invited to projects by the email address. If they don't have
  // an account yet, then they will be prompted to sign up to accept the
  // invitation.
  // Before they sign up, their workspace membership will not have an
  // associated user yet, so we don't yet know their name or avatar.
  // So we fall back to using their email from the invitation and a default
  // avatar in this case.
  const name =
    workspaceMembership.user?.name || workspaceMembership.invite?.email || ''
  const maybeAvatarSrc = membership.workspaceMembership.user?.avatar?.small
  const avatarProps: PersonAvatarProps = maybeAvatarSrc
    ? {
        name,
        size,
        flavour: 'image',
        src: maybeAvatarSrc,
      }
    : {
        name,
        size,
        flavour: 'color',
        color: 'default',
      }

  return <PersonAvatar {...avatarProps} />
}

export default ProjectMembershipAvatar
