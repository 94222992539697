import React from 'react'

import { Tooltip } from '@sketch/components'

import {
  VatCheckmark,
  VatClock,
  VatExclamation,
  VatValue,
  VatErrorText,
  VatWrapper,
} from './VatStatus.styles'

import { BillingDetailsFragment } from '@sketch/gql-types'

interface VatStatusProps {
  taxId: TaxId
}

type TaxId = NonNullable<BillingDetailsFragment['taxId']>

const PENDING_VERIFICATION_UI = {
  icon: <VatClock data-testid={'vat-pending'} />,
  tooltipContent: 'Pending Verification',
}

const STATUS_TO_UI = {
  UNAVAILABLE: PENDING_VERIFICATION_UI,
  PENDING: PENDING_VERIFICATION_UI,
  VERIFIED: {
    icon: <VatCheckmark data-testid={'vat-verified'} />,
    tooltipContent: 'Verified',
  },
  UNVERIFIED: {
    icon: <VatExclamation data-testid={'vat-unverified'} />,
    tooltipContent: 'VAT Not Valid',
  },
} as const

/**
 * VatStatus
 *
 * Renders the VAT status. You can find it inside the PaymentInformationPanel
 */
export const VatStatus: React.FC<VatStatusProps> = ({ taxId }) => {
  const tooltipContent = STATUS_TO_UI[taxId.status].tooltipContent
  const vatIcon = STATUS_TO_UI[taxId.status].icon

  return (
    <VatWrapper>
      <span>
        VAT Number: <VatValue status={taxId.status}>{taxId.id}</VatValue>
      </span>
      <span>
        <Tooltip content={tooltipContent} placement="top">
          {vatIcon}
        </Tooltip>
      </span>
      {taxId.status === 'UNVERIFIED' && (
        <VatErrorText>VAT not valid</VatErrorText>
      )}
    </VatWrapper>
  )
}
