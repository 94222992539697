import React, { useState } from 'react'
import { Formik, FormikProps } from 'formik'
import * as yup from 'yup'

import { Button, LoadingState, Form, Input } from '@sketch/components'
import { useToast } from '@sketch/toasts'

import { StyledFormField, StyledErrorField } from './SsoSignInForm.styles'

import { useGetSsoStartUrl } from '../useGetSsoStartUrl'

import { SsoSignInSource } from '@sketch/modules-common'

// Validation
const signInSchema = yup.object().shape({
  teamName: yup.string().trim().required('Enter your Workspace name'),
})

const INITIAL_VALUES = {
  teamName: '',
}

// Stypes
type Values = typeof INITIAL_VALUES

const TeamNameField = ({
  touched,
  errors,
  values,
  handleChange,
  handleBlur,
}: FormikProps<Values>) => (
  <StyledFormField
    name="teamName"
    label="SSO Shortname"
    errorText={touched.teamName ? errors.teamName : undefined}
  >
    <Input
      name="teamName"
      type="text"
      placeholder="SSO-Shortname"
      value={values.teamName}
      onChange={handleChange}
      onBlur={handleBlur}
      autoFocus
    />
  </StyledFormField>
)

export const Errors = ({ errors }: { errors: React.ReactNode[] }) =>
  errors.length > 0 ? <StyledErrorField>{errors[0]}</StyledErrorField> : null

export interface SubmitProps {
  isLoading: boolean
  disabled: boolean
}

const Submit: React.FC<SubmitProps> = ({ isLoading, disabled }) => (
  <Form.Footer>
    <Button
      variant="primary"
      type="submit"
      disabled={isLoading || disabled}
      loading={isLoading}
      size="40"
      fill
    >
      Continue
    </Button>
  </Form.Footer>
)

const SsoSignInForm = ({
  source,
  from,
  teamName,
}: {
  source: SsoSignInSource
  from?: string
  teamName?: string
}) => {
  const [showServerErrors, setShowServerErrors] = useState(false)
  const { showToast } = useToast()
  const { getUrl, response, isLoading } = useGetSsoStartUrl({
    source,
    from,
    onNetworkError: error => showToast(error, 'negative'),
    onCompleted: () => {
      setShowServerErrors(true)
    },
  })

  if (response.url) {
    window.location.href = response.url
  }

  if (teamName && (response.url || isLoading)) {
    return <LoadingState />
  }

  return (
    <Formik
      initialValues={{ ...INITIAL_VALUES, teamName: teamName || '' }}
      onSubmit={getUrl}
      validationSchema={signInSchema}
      validateOnBlur={false}
    >
      {formikProps => (
        <Form>
          <TeamNameField
            {...formikProps}
            handleChange={(e: React.ChangeEvent<any>) => {
              if (showServerErrors) {
                setShowServerErrors(false)
              }

              return formikProps.handleChange(e)
            }}
          />
          <Errors errors={showServerErrors ? response.errors : []} />
          <Submit
            isLoading={isLoading}
            // If we have a teamName we don't need to wait for the form to be dirty
            disabled={!(formikProps.isValid && (teamName || formikProps.dirty))}
          />
        </Form>
      )}
    </Formik>
  )
}

export default SsoSignInForm
