import React from 'react'
import {
  ScrollbarWrapper,
  SliderWrapper,
  GridPublicationItemPlaceholder,
  ScrollbarTrack,
} from './PublicationSlider.styles'

const PublicationSliderPlaceholder = () => (
  <ScrollbarWrapper>
    <SliderWrapper>
      <GridPublicationItemPlaceholder />
      <GridPublicationItemPlaceholder />
      <GridPublicationItemPlaceholder />
      <GridPublicationItemPlaceholder />
    </SliderWrapper>
    <ScrollbarTrack $pressed={false} style={{ opacity: 0 }} />
  </ScrollbarWrapper>
)

export default PublicationSliderPlaceholder
