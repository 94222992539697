import React, { FC, ReactNode } from 'react'
import { useRouteMatch } from 'react-router'
import { dataIdFromObject } from '@sketch/graphql-cache'
import {
  ArtboardDetailInfoFragment,
  GetArtboardsForDocumentQuery,
  GetArtboardsForPageQuery,
} from '@sketch/gql-types'
import { ApolloError, ObservableQuery, OperationVariables } from 'apollo-client'
import get from 'lodash.get'
import groupBy from 'lodash.groupby'
import { getArtboardThumbnail } from '../../utils'
import { ResponsiveValues } from '@sketch/global-styles'
import {
  LoadingPlaceholder,
  Box,
  Tooltip,
  ErrorMessage,
  AspectRatioGrid,
  AspectRatioGridResponseProp,
  handleFetchMore,
  InfiniteList,
  useForDesktop,
} from '@sketch/components'
import Artboard from '../Artboard'
import PrototypeCard from '../PrototypeCard'
import { useVersioning } from '../../../versioning'
import { useContainerRefScroll } from '@sketch/utils'
import { useSketchWebSupport } from '../../PageCanvasView/hooks'
import { ReactComponent as CanvasTooltip } from '@sketch/icons/canvas-tooltip'
import { ReactComponent as DocumentIcon } from '@sketch/icons/document-64'
import { ReactComponent as MagGlassIcon } from '@sketch/icons/mag-glass-64'

import {
  Container,
  FullScreenContainer,
  StyledArrowRightCircleIcon,
  StyledCanvasViewIcon,
  StyledPageCanvasLink,
  StyledPageLink,
  StyledPageLinkLabel,
  StyledPageTitleContainer,
  StyledPill,
  StyledVersionLink,
  Title,
} from './ArtboardList.styles'
import { useAnalytics, isDocumentRoute } from '@sketch/modules-common'

type File = NonNullable<NonNullable<ArtboardDetailInfoFragment['files']>[0]>

type ThumbnailType = NonNullable<NonNullable<File['thumbnails']>[0]>['type']

const VERTICAL_ROW_SPACE_GRID = [28, 28, 32] as [number, number, number]

const defaultColumns: AspectRatioGridResponseProp = [2, 2, 3, 4, 5, 6, 7]

const PageCanvasLinkTooltip: FC = ({ children }) => (
  <Tooltip
    content={
      <>
        <Box maxWidth={225}>
          View all Artboards as they appear in the original document, including
          elements outside Artboards.
        </Box>
        <Box marginTop={3}>
          <CanvasTooltip width={225} />
        </Box>
      </>
    }
    placement="bottom"
  >
    {children}
  </Tooltip>
)

type ArtboardsProps = {
  title: string
  shareID: string
  columns?: AspectRatioGridResponseProp
  pageUUID: string
  children?: ReactNode
  totalCount?: number
}

export const Artboards = ({
  title,
  columns = defaultColumns,
  totalCount,
  children,
  shareID,
  pageUUID,
}: ArtboardsProps) => {
  const { trackEvent } = useAnalytics()
  const { getPathname } = useVersioning()
  const { path } = useRouteMatch()
  const isDocumentView = isDocumentRoute(path)
  const canvasSupport = useSketchWebSupport()

  const pagePath = getPathname({
    routeKey: 'SHARE_PAGE_VIEW',
    routeParams: { shareID, pageUUID },
  })

  const canvasPath = getPathname({
    routeKey: 'SHARE_PAGE_CANVAS_VIEW',
    routeParams: { shareID, pageUUID },
  })

  const showCanvasLink = canvasSupport.supported

  const onPageLinkClick = () => {
    trackEvent('DOCUMENT - open page', {
      dropdown: false,
    })
  }

  const onCanvasLinkClick = () => {
    trackEvent('DOCUMENT - open canvas', {
      isPageView: !isDocumentView,
    })
  }

  return (
    <Container>
      <StyledPageTitleContainer>
        <Title>
          <StyledPageLink
            to={pagePath}
            external={false}
            onClick={onPageLinkClick}
            // Avoids adding entries to the browser history if user clicks
            // on the link while already being on the page view.
            replace={!isDocumentView}
          >
            <StyledPageLinkLabel>{title}</StyledPageLinkLabel>
            {totalCount && (
              <StyledPill variant="secondary">{totalCount}</StyledPill>
            )}
            {isDocumentView && <StyledArrowRightCircleIcon />}
          </StyledPageLink>
        </Title>
        {showCanvasLink && (
          <PageCanvasLinkTooltip>
            <StyledPageCanvasLink onClick={onCanvasLinkClick} to={canvasPath}>
              <StyledCanvasViewIcon />
              Canvas View
            </StyledPageCanvasLink>
          </PageCanvasLinkTooltip>
        )}
      </StyledPageTitleContainer>
      <AspectRatioGrid
        verticalRowSpace={VERTICAL_ROW_SPACE_GRID}
        columns={columns}
        gutterSize={32}
      >
        {children}
      </AspectRatioGrid>
    </Container>
  )
}

interface ArtboardsListProps {
  fetchMore: ObservableQuery<any, OperationVariables>['fetchMore']
  afterPath: string[]
  entriesPath: string[]
  data?: GetArtboardsForDocumentQuery | GetArtboardsForPageQuery
  loading: boolean
  error?: ApolloError
  filterValue: string
  columns: ResponsiveValues<number>
  pageUUID?: string
  /**
   * Should Artboards with `belongToPrototype` `true` be rendered as Prototype
   * cards with a play button, or normal Artboard cards.
   */
  enablePrototypeCards?: boolean
}

/**
 * Handles an infinite list of artboards fetched by either the
 * GetArtboardsForDocumentQuery or the GetArtboardsForPageQuery.
 */
export const ArtboardList = ({
  fetchMore,
  afterPath,
  entriesPath,
  data,
  loading,
  error,
  filterValue,
  columns,
  pageUUID,
  enablePrototypeCards,
}: ArtboardsListProps) => {
  const isDesktop = useForDesktop()
  const { saveScrollTop } = useContainerRefScroll()
  const { setArtboardThumbnail } = useVersioning()
  const { path } = useRouteMatch()
  const isDocumentView = isDocumentRoute(path)
  const { trackEvent } = useAnalytics()

  if (loading) {
    return (
      <FullScreenContainer>
        <LoadingPlaceholder size="64px" />
      </FullScreenContainer>
    )
  }

  if (error || !data?.share) return <ErrorMessage.Generic />

  const { commentsEnabled = true, identifier: shareID } = data.share
  const entries = get(data, entriesPath, []) as ArtboardDetailInfoFragment[]

  if (entries.length === 0) {
    if (filterValue) {
      return (
        <ErrorMessage
          title="We couldn’t find any Artboards with that name."
          icon={<MagGlassIcon />}
          iconSize="medium"
        >
          {isDesktop && <>Press the Escape key to return to your document.</>}
        </ErrorMessage>
      )
    }

    return (
      <ErrorMessage
        title="No pages in document"
        icon={<DocumentIcon />}
        iconSize="medium"
      >
        No pages have been added here yet.
      </ErrorMessage>
    )
  }

  const artboardsByPage = groupBy(entries, e => e.page?.identifier)
  const pages = Object.values(artboardsByPage)

  const handleArtboardClick = (artboard: ArtboardDetailInfoFragment) => {
    saveScrollTop()
    setArtboardThumbnail(getArtboardThumbnail(artboard))

    trackEvent('DOCUMENT - open artboard', {
      isPageView: !isDocumentView,
    })
  }

  return (
    <InfiniteList
      wrappingComponent={React.Fragment}
      renderLoading={() => <LoadingPlaceholder size="64px" />}
      onLoadMore={handleFetchMore(fetchMore, entriesPath, {
        dataIdFromObject,
        after: get(data, afterPath),
      })}
    >
      {pages.map(artboards => (
        <Artboards
          key={artboards[0]?.page?.uuid!}
          columns={columns}
          title={artboards[0]?.page?.name!}
          shareID={shareID}
          pageUUID={artboards[0]?.page?.uuid!}
          totalCount={artboards.length}
        >
          {artboards.map(artboard =>
            enablePrototypeCards && artboard.isFlowHome ? (
              <PrototypeCard
                artboardUUID={artboard.uuid}
                key={artboard.identifier}
                title={artboard.name}
                src={getArtboardThumbnail(artboard)}
              />
            ) : (
              <StyledVersionLink
                data-testid="artboard-link"
                onClick={() => handleArtboardClick(artboard)}
                key={artboard.identifier}
                to={{
                  routeKey: 'ARTBOARD_DETAIL',
                  routeParams: {
                    shareID,
                    permanentArtboardShortId: artboard.permanentArtboardShortId!,
                  },
                  state: {
                    fromOverview: true,
                    pageUUID,
                  },
                }}
              >
                <Artboard
                  title={artboard.name}
                  search={filterValue}
                  imageSrc={getArtboardThumbnail(artboard)}
                  srcSet={
                    createThumbnailSrcSetForFiles(
                      artboard?.files as File[],
                      'L'
                    ) ?? undefined
                  }
                  commentCount={commentsEnabled ? artboard.annotationCount : 0}
                  hasNewComments={artboard.unreadCount > 0}
                />
              </StyledVersionLink>
            )
          )}
        </Artboards>
      ))}
    </InfiniteList>
  )
}

export const createThumbnailSrcSetForFiles = (
  files: File[],
  type: ThumbnailType = 'L'
): string | null => {
  if (!files) return null

  return files
    .sort((a: File, b: File): number => {
      if (a.scale && b.scale) return a.scale - b.scale
      return 0
    })
    .map((a: File) => {
      const thumbnails = a.thumbnails ?? []
      const largeThumbnail = thumbnails.find(t => t?.type === type) // try to find the correct size
      const thumbnail = largeThumbnail ?? thumbnails[0] // fall back to first available thumbnail

      return thumbnail?.url ?? null
    })
    .filter(url => url !== null)
    .map((url, i) => `${url} ${i + 1}x`)
    .join(', ')
}
