import styled from 'styled-components'
import { Text } from '@sketch/components'

export const Container = styled.div`
  padding: 16px;

  background-color: ${({ theme }) => theme.colors.sketch.F};
  border-radius: ${({ theme }) => theme.radii.xxlarge};
`
export const Title = styled(Text).attrs({
  textStyle: 'copy.primary.standard.E',
})`
  padding: 0;
  margin: 0 0 8px 0;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`

export const Description = styled(Text).attrs({
  textStyle: 'copy.tertiary.standard.E',
})`
  padding: 0;
  margin: 0;
`
