import styled, { css } from 'styled-components'

import { ImageWrapper } from '../DocumentItem/DocumentItem.styles'

export const DragWrapper = styled.div<{
  $isDraggedOver: boolean
}>`
  -webkit-touch-callout: none;

  ${({ $isDraggedOver, theme }) =>
    $isDraggedOver &&
    css`
      ${ImageWrapper} {
        box-shadow: 0px 0px 0px 4px ${theme.colors.sketch.D};
      }
    `}
`
