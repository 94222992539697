import React from 'react'
import { Portal } from 'react-portal'

import { Modal } from '../Modal'
import { ModalTransition } from '../ModalManager'

import {
  CancelButton,
  ModalBody,
  ModalFooter,
} from './ResponsiveDropdown.styles'

interface ModalContainerProps {
  onHide: () => void
  visible: boolean
  style?: React.CSSProperties
  useModalRoot?: boolean
  hideCancel?: boolean
}

/**
 * This component has the visual representation of a modal
 * when rendered using the "useResponsiveDropdown" hook
 *
 * It has been implemented using as foundation the SelectDropdown code
 * https://github.com/sketch-hq/cloud-frontend/blob/4c7a4c26b63e085ea1aabdbd9d79c7b00342388b/src/modules/projects/components/SelectDropdown/SelectList/SelectList.tsx#L78-L95
 *
 * This component is in charge of:
 * - Adding a cancel button to hide the modal
 * - Hiding the modal when clicked outside
 */
export const ModalContainer = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ModalContainerProps>
>(function ModalContainer(
  { children, visible, onHide, style, useModalRoot, hideCancel },
  ref
) {
  return (
    <ModalTransition show={visible}>
      <Portal
        node={useModalRoot ? document.getElementById('modal-root') : undefined}
      >
        <Modal
          data-testid="popover-content"
          ref={ref}
          onCancel={onHide}
          style={style}
        >
          <ModalBody>{children}</ModalBody>
          {!hideCancel && (
            <ModalFooter>
              <CancelButton variant="tertiary" size="40" fill onClick={onHide}>
                Cancel
              </CancelButton>
            </ModalFooter>
          )}
        </Modal>
      </Portal>
    </ModalTransition>
  )
})
