import { useReducer } from 'react'
import { useOnEvent } from '@sketch/utils'
import { useGetComponentsCountQuery } from '@sketch/gql-types'
import { useGetIdentifiers } from '../../hooks/useGetIdentifiers'
import { useComponentsTrigger } from '../../components/ComponentsStateContext'

interface CountTotalComponentsProps {
  loading: boolean
  symbolsCount: number
  layerStylesCount: number
  textStylesCount: number
  colorVarsCount: number
}

const countTotalComponents = (componentsCount: CountTotalComponentsProps) => {
  const {
    loading,
    symbolsCount,
    layerStylesCount,
    textStylesCount,
    colorVarsCount,
  } = componentsCount

  if (
    loading ||
    symbolsCount === undefined ||
    layerStylesCount === undefined ||
    textStylesCount === undefined ||
    colorVarsCount === undefined
  ) {
    return false
  }

  return symbolsCount + textStylesCount + layerStylesCount + colorVarsCount
}

export const useGetComponentsCount = () => {
  const { shareIdentifier, versionShortId } = useGetIdentifiers()

  const { loading, data, refetch, error } = useGetComponentsCountQuery({
    variables: {
      shareIdentifier,
      versionShortId,
    },
    skip: !shareIdentifier || !versionShortId,
  })

  // We just need to trigger components ingestion here because this hook is
  // used in all components pages
  const componentsState = useComponentsTrigger(refetch)

  // This is just to force re-render
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  // This event is triggered when a new version is processed, when this happens
  // we need to re-render this component again to calculate the components number of each type
  useOnEvent('versionIsProcessed', share => {
    if (share.identifier === shareIdentifier) {
      forceUpdate()
    }
  })

  const hasComponentManifest = data?.share?.version?.hasComponentManifest
  const symbolsCount = data?.share?.version?.document?.componentCount.symbol!
  const layerStylesCount = data?.share?.version?.document?.componentCount
    .layerStyle!
  const textStylesCount = data?.share?.version?.document?.componentCount
    .textStyle!
  const colorVarsCount = data?.share?.version?.document?.componentCount
    .colorVar!

  const hideCounters =
    !hasComponentManifest &&
    ['CAN_PROCESS', 'CANNOT_PROCESS'].includes(
      componentsState ?? 'CANNOT_PROCESS'
    )

  const totalComponents = hasComponentManifest
    ? countTotalComponents({
        loading,
        symbolsCount,
        layerStylesCount,
        textStylesCount,
        colorVarsCount,
      })
    : false

  return {
    loading,
    error,
    componentsState,
    totalComponents,
    symbolsCount: hideCounters ? undefined : symbolsCount,
    symbolsLabel: `Symbols ${
      hideCounters || !symbolsCount ? '' : `(${symbolsCount})`
    }`,
    layerStylesCount: hideCounters ? undefined : layerStylesCount,
    layerStylesLabel: `Layer Styles ${
      hideCounters || !layerStylesCount ? '' : `(${layerStylesCount})`
    }`,
    textStylesCount: hideCounters ? undefined : textStylesCount,
    textStylesLabel: `Text Styles ${
      hideCounters || !textStylesCount ? '' : `(${textStylesCount})`
    }`,
    colorVarsCount: hideCounters ? undefined : colorVarsCount,
    colorVarsLabel: `Color Variables ${
      hideCounters || !colorVarsCount ? '' : `(${colorVarsCount})`
    }`,
  }
}
