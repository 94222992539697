import { OnboardingPanelBody } from './OnboardingPanelBody'
import { OnboardingPanelFooter } from './OnboardingPanelFooter'
import { OnboardingPanelHeader } from './OnboardingPanelHeader'
import { OnboardingPanel as OnboardingPanelBase } from './OnboardingPanel'

export * from './OnboardingPanel'

export const OnboardingPanel = Object.assign(OnboardingPanelBase, {
  Body: OnboardingPanelBody,
  Footer: OnboardingPanelFooter,
  Header: OnboardingPanelHeader,
})
