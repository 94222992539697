import React, { useState } from 'react'
import { FileSelection } from '@sketch/components'
import { validateImage, castError } from '@sketch/utils'
import { ReactComponent as DSIcon } from '@sketch/icons/default-ds-icon'
import { IconOverlay, RelativeBox, DSImage } from './FormEditCreate.styles'
import { useToast } from '@sketch/toasts'

interface EditImageProps {
  onChange: (file: File) => void
  logo?: string | null
}

export const EditImage = ({ onChange, logo }: EditImageProps) => {
  const [file, setFile] = useState<string | null>(null)
  const { showToast } = useToast()

  const handleFileOnChange = async (file: File) => {
    try {
      await validateImage(file)

      // update the File on the form component (so it can be sent when hitting save)
      onChange(file)

      // to display the file on the widget, we need to read it
      const reader = new FileReader()

      reader.readAsDataURL(file)
      reader.onload = function (e: ProgressEvent<FileReader>) {
        setFile(e?.target?.result as string | null)
      }
    } catch (e) {
      const error = castError(e)
      showToast(error.message, 'negative')
    }
  }

  let imgSrc: string | null = null

  if (file) {
    imgSrc = file
  } else if (logo) {
    imgSrc = logo
  }

  return (
    <RelativeBox width={64} height={64}>
      <FileSelection
        onFileSelected={handleFileOnChange}
        accept=".png, .jpg, .jpeg"
      >
        {({ onSelectFile }) => (
          <>
            {imgSrc ? <DSImage src={imgSrc} /> : <DSIcon />}
            <IconOverlay onClick={onSelectFile} />
          </>
        )}
      </FileSelection>
    </RelativeBox>
  )
}
