import styled from 'styled-components'

import { breakpoint } from '@sketch/global-styles'
import { Text } from '@sketch/components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
`

export const InnerWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;

  padding: 24px 0;
  margin: 24px 0;

  border-top: 1px solid ${({ theme }) => theme.colors.border.A};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`

export const Item = styled(Text.Div).attrs({
  textStyle: 'copy.quaternary.standard.E',
})<{
  marginBottom?: boolean
  singleColumn?: boolean
}>`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: ${({ singleColumn }) =>
    singleColumn ? 'auto' : '70% 30%'};

  margin-bottom: ${({ marginBottom }) => (marginBottom ? '16px' : '4px')};

  :last-child {
    margin-bottom: 0;
  }

  > :nth-child(2) {
    text-align: right;
  }
`
export const TrialText = styled(Text).attrs({
  textStyle: 'copy.tertiary.standard.E',
})`
  margin: 0;
`
export const Paragraph = styled(Text).attrs({
  textStyle: 'copy.tertiary.standard.D',
})`
  margin: 0;

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }
`

export const StrongText = styled.strong`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`
export const SmallText = styled.small`
  font-size: ${({ theme }) => theme.fontSizes.C};
`

// Old
export const HalfDivider = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 12px;
  }
`

export const Section = styled.div`
  margin-left: 20px;
  padding: 16px 24px 16px 0;

  ${breakpoint('base', 'sm')`
    margin-left: 24px;
    padding-right: 24px;
  `}

  ${Paragraph} {
    &:nth-child(1) {
      margin-top: 0;
    }

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
`
