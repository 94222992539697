import { getUserAuthorization } from '@sketch/modules-common'
import {
  GetUserCredentialsDocument,
  GetUserCredentialsQuery,
} from '@sketch/gql-types'
import { log, uniqueId } from '@sketch/utils'
import { ApolloClient } from 'apollo-client'
import { WebsocketContext } from '@sketch/common-types'
import config from '@sketch/env-config'

export const createPresencesChannels = (
  apolloClient: ApolloClient<any>,
  { createAuthenticatedSocket, createSocketChannels }: WebsocketContext
) => {
  const cache = apolloClient.cache

  const socketUri = config.presences_ws_uri

  const socket = createAuthenticatedSocket(socketUri, () => apolloClient)

  const channels = createSocketChannels(socket)

  const authorization = getUserAuthorization(cache)
  let currentAuthToken = authorization?.authToken || ''

  cache.watch({
    query: GetUserCredentialsDocument,
    optimistic: false,
    /**
     * We are including a fake variables object here to enforce cache.watch
     * to notify this subscriber.
     *
     * Apollo's cache will create a key for every watcher for internal
     * storage. For the current version of Apollo (v2.6.2), this key depends
     * on just two things: the query and the variables used. This means that
     * Apollo will only notify one watcher, even though there are more than
     * one as long as the query and the variables are the same.
     *
     * This is what's happening here, the socket.ts subscription is exactly
     * the same than here and, therefore, this callback is never notified.
     * Creating a fake variables object tricks the cache into believing this
     * is a different watcher (which it is!) and fixes the issue.
     *
     * More modern versions of Apollo added the callback function to the
     * watcher key, effectively fixing this issue.
     *
     * See Apollo's commit fixing the issue:
     * https://github.com/apollographql/apollo-client/commit/088d4853371b27af2b0ca34836edaaa0c41b1aff
     */
    variables: { key: uniqueId() },
    callback: async cachedValue => {
      const userCredentials = cachedValue.result as GetUserCredentialsQuery
      const newAuthToken = userCredentials.userCredentials?.authToken

      if (currentAuthToken === newAuthToken) {
        log('Socket -> Credentials changed but token did not')
        return
      }

      if (!newAuthToken) {
        log('Socket -> Credentials changed but token isEmpty')
        socket.disconnect()
        return
      }

      currentAuthToken = newAuthToken

      /**
       * Calling `conn.close` is the recommended method to reconnect
       * the websocket with new authentication parameters
       *
       * `conn` property is only available when WebSocket connection is
       *  established, so we need to check if it is available before executing
       *
       * Seems like `conn` is not defined in TypeScript types as it is
       * an internal property, that's why we cast it to any before checking
       * the availability
       *
       * https://hexdocs.pm/absinthe/apollo.html#reconnecting-the-websocket-link
       */
      const connectedSocket = socket as any
      if (connectedSocket.conn) {
        log('Socket -> Refreshing socket connection with new credentials')
        connectedSocket.conn.close()
      } else {
        log('Socket -> Open socket because there was no connection')
        await socket.connect()
      }
    },
  })

  return channels
}
