import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "trash-bin-64", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 64 64", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M22.5 15l-.04-5.26A4.24 4.24 0 0126.7 5.5h10.6a4.24 4.24 0 014.24 4.24L41.5 15", stroke: "currentColor", strokeWidth: 3 }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 3, strokeLinecap: "round", d: "M5.5 14.5h53" }),
        React.createElement("path", { d: "M12 14.5h40l-.71 33.93c-.073 3.522-.46 4.793-1.167 6.072a7.263 7.263 0 01-3.02 2.958c-1.294.68-2.572 1.04-6.096 1.04H22.993c-3.524 0-4.802-.36-6.096-1.04a7.263 7.263 0 01-3.02-2.958c-.707-1.279-1.094-2.55-1.168-6.072L12 14.5h0z", stroke: "currentColor", strokeWidth: 3 }),
        React.createElement("path", { d: "M11.86 13.98h40.28l-.82 34.475c-.085 3.517-.473 4.784-1.181 6.06a7.262 7.262 0 01-3.02 2.95c-1.292.676-2.569 1.035-6.087 1.035H22.968c-3.518 0-4.795-.359-6.086-1.036a7.262 7.262 0 01-3.02-2.949c-.709-1.276-1.097-2.543-1.181-6.06L11.86 13.98z", fillOpacity: 0.15, fill: "currentColor" }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 3, strokeLinecap: "round", d: "M24.58 25.64l2.12 21.2m12.72-21.2l-2.12 21.2" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
