import React from 'react'
import styled from 'styled-components'
import {
  Modal,
  Button,
  Text,
  Flex,
  ModalInjectedProps,
} from '@sketch/components'

const Image = styled.img`
  width: 128px;
`

const StyledModal = styled(Modal)`
  width: 334px;
`

interface WorkspaceApprovedModalProps extends ModalInjectedProps {
  title: string
  description: string | React.ReactNode
  image: { src: string; src2x: string }
  buttonText: string
}

export const WorkspaceApprovedModal: React.FC<WorkspaceApprovedModalProps> = ({
  hideModal,
  title,
  description,
  image,
  buttonText,
}) => {
  return (
    <StyledModal onCancel={hideModal}>
      <Modal.Body>
        <Flex alignItems="center" flexDirection="column" pt={4}>
          <Image
            alt="Workspace Approved"
            src={image.src}
            srcSet={`${image.src2x} 2x`}
          />
          <Text.H1
            textStyle="header.primary.G"
            textAlign="center"
            mt={7}
            mb={1}
          >
            {title}
          </Text.H1>
          <Text
            textStyle="copy.tertiary.standard.E"
            textAlign="center"
            // necessary to override the default 20px <p> padding
            style={{ paddingBottom: 24 }}
          >
            {description}
          </Text>
          <Button variant="primary" onClick={hideModal} size="40">
            {buttonText}
          </Button>
        </Flex>
      </Modal.Body>
    </StyledModal>
  )
}
