import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "circles-2-overlapping-diagonal-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { stroke: "currentColor", strokeWidth: 1.5, fill: "none", fillRule: "evenodd" },
        React.createElement("circle", { cx: 6.25, cy: 9.75, r: 4.5 }),
        React.createElement("circle", { cx: 9.75, cy: 6.25, r: 4.5 }),
        React.createElement("path", { d: "M6.25 5.25c1.243 0 2.368.504 3.182 1.318a4.486 4.486 0 011.226 4.09 4.514 4.514 0 01-4.09-1.226 4.486 4.486 0 01-1.226-4.09c.293-.06.597-.092.908-.092h0z", fillOpacity: 0.15, fill: "currentColor" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
