import React from 'react'
import { LinkButton } from '@sketch/components'
import InfoMessage from '../InfoMessage'
import { ReactComponent as CommentsCrossIcon } from '@sketch/icons/comment-cross-64'
import { ReactComponent as CommentsIcon } from '@sketch/icons/comments-64'
import { useLocation } from 'react-router'

import { routes, useUserSignedIn } from '@sketch/modules-common'
import { ReactComponent as PersonIcon } from '@sketch/icons/person-circle-20'

const SignInButton = () => {
  const location = useLocation()

  return (
    <LinkButton
      variant="primary"
      icon={() => <PersonIcon width="16" height="16" />}
      size="32"
      to={{ pathname: routes.SIGN_IN.create({}), state: { from: location } }}
    >
      Sign in
    </LinkButton>
  )
}

const CommentTabMessageRestricted: React.FC = () => {
  const isSignedIn = useUserSignedIn()

  const title = isSignedIn ? 'Comments unavailable' : 'Sign in or sign up'

  const description = isSignedIn
    ? `You don't have permission to view or add comments to this document.`
    : `Sign in with your Sketch account (or sign up for free) to add comments.`

  const icon = isSignedIn ? CommentsCrossIcon : CommentsIcon

  return (
    <>
      <InfoMessage title={title} icon={icon}>
        <p>{description}</p>
        <br />
        {!isSignedIn && <SignInButton />}
      </InfoMessage>
    </>
  )
}

export default CommentTabMessageRestricted
