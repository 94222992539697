import styled from 'styled-components'

import { ButtonUnstyled, Navbar } from '@sketch/components'

import { ReactComponent as Hamburger } from '@sketch/icons/list-lines-24'

export const HamburgerIcon = styled(Hamburger)`
  width: 24px;
  height: 24px;
`

export const HamburgerButton = styled(ButtonUnstyled)`
  padding: 8px 12px;
  line-height: 0; /* Needed to avoid extra height around the icon */

  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
`

export const SelectorSection = styled(Navbar.Section)`
  /* We dont allow this element to shrink to avoid the button being  */
  /* squashed by other menu items and effectively be hidden (in mobile) */
  flex: 0 0 auto;
  /* Prevent this section from sending it's siblings of screen */
  overflow: hidden;
`

export const MobileHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`

export const WorkspacesDropdownNavbar = styled.div`
  margin-left: 12px;
  margin-right: -6px; /* stylelint-disable-line scales/space */
`
