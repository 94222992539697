import React from 'react'
import {
  HamburgerButton,
  HamburgerIcon,
  MobileHeaderLeft,
  SelectorSection,
  WorkspacesDropdownNavbar,
} from './WorkspaceNavbar.styles'

import { WorkspacesDropdown } from '../../containers/WorkspacesDropdown'
import { WorkspaceMinimalFragment } from '@sketch/gql-types'
import { useForTablet } from '@sketch/components'

interface WorkspaceNavbarProps {
  workspace: WorkspaceMinimalFragment
  setSidebarLeftOpen?: (state: boolean) => void
}

export const WorkspaceNavbar = (props: WorkspaceNavbarProps) => {
  const { workspace, setSidebarLeftOpen } = props

  // Responsiveness
  const isTabletAndBigger = useForTablet()

  if (isTabletAndBigger) {
    return null
  }

  return (
    <MobileHeaderLeft data-mobile-header-left>
      <WorkspacesDropdownNavbar data-workspace-dropdown-navbar>
        <WorkspacesDropdown workspace={workspace!} size={24} />
      </WorkspacesDropdownNavbar>
      <SelectorSection>
        <HamburgerButton
          aria-label="Hamburger menu button"
          onClick={() => setSidebarLeftOpen?.(true)}
        >
          <HamburgerIcon />
        </HamburgerButton>
      </SelectorSection>
    </MobileHeaderLeft>
  )
}
