import React, { useState } from 'react'

import { Portal } from 'react-portal'
import { Placement } from '@popperjs/core'

import {
  SelectDropdownCancelButton,
  SelectDropdownItemWrapper,
  SelectDropdownModalHeader,
  SelectDropdownModalBody,
  SelectDropdownModalFooter,
} from './SelectList.styles'
import { Breakpoints, lightTheme as theme } from '@sketch/global-styles'
import { StatelessDropdown } from '../../Dropdown'
import { Modal } from '../../Modal'
import { ModalTransition } from '../../ModalManager'
import { useBreakpoint } from '../../Breakpoint'

interface SelectListProps {
  expanded: boolean
  children: React.ReactNode
  headerItem?: React.ReactNode
  footerItem?: React.ReactNode
  placement?: Placement
  breakpoint?: Breakpoints
  setExpanded: (
    setExpanded: (state: boolean, button?: HTMLButtonElement) => boolean
  ) => void
  usePortal: boolean
}

export function SelectList(props: SelectListProps) {
  const {
    expanded,
    children,
    headerItem,
    footerItem,
    placement = 'bottom-start',
    breakpoint = 'md',
    setExpanded,
    usePortal,
  } = props
  const shouldRenderDropdown = useBreakpoint(breakpoint)
  const [hasOverflow, setHasOverflow] = useState(false)

  if (shouldRenderDropdown) {
    return expanded ? (
      <StatelessDropdown
        visible={expanded}
        toggleDropdown={() => {}}
        placement={placement}
        contentPadding="0"
        minWidth="180px"
        maxWidth="320px"
        contentStyle={{
          borderRadius: theme.radii.xxlarge,
        }}
        onClickOutside={e => {
          setExpanded((state, button) => {
            // If it's the button the click handler then no need to override the state
            if (e.target === button) {
              return state
            }
            return false
          })
        }}
        usePortal={usePortal}
      >
        <SelectDropdownItemWrapper>
          {headerItem}
          {children}
        </SelectDropdownItemWrapper>
        {footerItem && (
          <SelectDropdownItemWrapper>{footerItem}</SelectDropdownItemWrapper>
        )}
      </StatelessDropdown>
    ) : null
  } else {
    const handleClose = () => setExpanded(() => false)

    return (
      <Portal>
        <ModalTransition show={expanded}>
          <Modal onCancel={handleClose} onUpdateScroll={setHasOverflow}>
            {headerItem ? (
              <SelectDropdownModalHeader as="div">
                {headerItem}
              </SelectDropdownModalHeader>
            ) : null}
            <SelectDropdownModalBody>{children}</SelectDropdownModalBody>
            <SelectDropdownModalFooter hasOverflow={hasOverflow}>
              {footerItem}
              <SelectDropdownCancelButton
                variant="tertiary"
                size="40"
                onClick={handleClose}
                fill
              >
                Cancel
              </SelectDropdownCancelButton>
            </SelectDropdownModalFooter>
          </Modal>
        </ModalTransition>
      </Portal>
    )
  }
}
