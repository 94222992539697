import React, { useMemo } from 'react'
import * as S from './ArtboardDetailInspectorOverlay.styles'
import { Line, Orientation } from './types'
import {
  useGetMapPointToCanvasRelativePosition,
  useGetMapSizeToCanvasRelativeSize,
} from '../../hooks/inspector'
import { roundWithLocale } from '@sketch/utils'

export type MeasurementLineProps = {
  line: Line
  orientation: Orientation
}

export function MeasurementLine({ line, orientation }: MeasurementLineProps) {
  const relativeLine = useMapLineValuesToBeRelativeToCanvas(line)

  if (!relativeLine || line.size < 1) {
    return null
  }

  const distanceToDisplay = roundWithLocale(line.size, 2)

  return (
    <S.MeasurementLine
      $x={relativeLine.x}
      $y={relativeLine.y}
      $orientation={orientation}
      $lineSize={relativeLine?.size}
    >
      <S.MeasurementPill $orientation={orientation}>
        {distanceToDisplay}
      </S.MeasurementPill>
    </S.MeasurementLine>
  )
}

function useMapLineValuesToBeRelativeToCanvas(line: Line) {
  const mapPointToCanvasRelativePosition = useGetMapPointToCanvasRelativePosition()
  const mapSizeToCanvasRelativeSize = useGetMapSizeToCanvasRelativeSize()

  const relativeLine = useMemo(() => {
    const position = mapPointToCanvasRelativePosition({ x: line.x, y: line.y })
    const size = mapSizeToCanvasRelativeSize(line.size)

    if (position === null || size === null) {
      return null
    }
    return {
      ...position,
      size,
    }
  }, [
    line.size,
    line.x,
    line.y,
    mapPointToCanvasRelativePosition,
    mapSizeToCanvasRelativeSize,
  ])

  return relativeLine
}
