import React, { useEffect, useState } from 'react'
import { NavLinkProps } from 'react-router-dom'

import { PersonAvatar } from '@sketch/components'

import CommentDate from '../CommentDate'
import EditCommentInput from '../EditCommentInput'
import CommentAuthor from '../CommentAuthor'
import { useMultipleCommentContext } from '../CommentEditContext'
import Link from '../Link'

import {
  CommentDetails,
  HeaderWrapper,
  Wrapper,
  Markdown,
  AuthorName,
  CommentOptionsWrapper,
} from './Comment.styles'

import { CommentUserFragment } from '@sketch/gql-types'

export interface CommentProps extends React.ComponentPropsWithoutRef<'div'> {
  className?: string
  'data-testid'?: string
  'data-first-comment'?: boolean
  body: string
  identifier: string
  user: CommentUserFragment
  edited?: boolean
  createdAt: string
  link?: NavLinkProps['to']
  optionsMenu?: (className?: string) => React.ReactElement | null
  highlight?: boolean
  /**
   * By default, comments have  a _pressed state_ to respond to user clicks.
   * Set this to `false` to disable that behaviour.
   */
  showPressedState?: boolean
}

const Comment = React.forwardRef<HTMLDivElement, CommentProps>(function Comment(
  props,
  ref
) {
  const {
    className,
    'data-testid': dataTestId = 'comment',
    'data-first-comment': dataFirstComment,
    user,
    createdAt,
    body,
    children,
    link,
    identifier,
    edited,
    highlight: externalHighlight,
    showPressedState = true,
    optionsMenu,
    ...rest
  } = props

  const styleProps = {
    className,
    'data-first-comment': dataFirstComment,
  }

  const {
    activeCommentIdentifier,
    dismissEditing,
  } = useMultipleCommentContext()

  /* Allow the highlight to be dismissed when leaving the card */
  const [highlight, setHighlight] = useState(externalHighlight)
  useEffect(() => {
    setHighlight(externalHighlight)
  }, [externalHighlight])

  if (activeCommentIdentifier === identifier) {
    return (
      <EditCommentInput
        identifier={identifier}
        user={user}
        createdAt={createdAt}
        comment={body}
        onCancel={() => dismissEditing()}
        {...styleProps}
      />
    )
  }

  const comment = (
    <Wrapper
      $showPressedState={showPressedState}
      data-testid={dataTestId}
      data-highlight={highlight}
      ref={ref}
      onMouseLeave={() => setHighlight(false)}
      {...styleProps}
      {...rest}
    >
      {/* Render the options menu outside  */}
      <CommentOptionsWrapper>{optionsMenu}</CommentOptionsWrapper>

      <HeaderWrapper>
        <PersonAvatar
          flavour="image"
          size="16px"
          src={user.avatar!.small!}
          name={user.name!}
        />
        <CommentAuthor placement="bottom-end">
          <AuthorName>{user.name}</AuthorName>
        </CommentAuthor>
        <CommentDetails>
          <CommentDate date={createdAt} />
          {edited && <>{' (Edited)'}</>}
        </CommentDetails>
      </HeaderWrapper>
      <Markdown data-testid={`${dataTestId}-body`}>{body}</Markdown>
      {children}
    </Wrapper>
  )

  if (link) {
    return <Link to={link}>{comment}</Link>
  }

  return comment
})

export default Comment
