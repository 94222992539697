import styled from 'styled-components'
import { TextArea } from '@sketch/components'

export const ShareVersionDescriptionTextArea = styled(TextArea)`
  min-height: 100px;
  max-height: 60vh;
  resize: vertical;
`

export const ShareVersionDescriptionContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
`
