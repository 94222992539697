import React from 'react'
import {
  Link,
  IsEmbedded,
  NotEmbedded,
  CenterContent,
  ErrorMessage,
} from '@sketch/components'
import { EmptyHeader } from '../EmptyHeader'
import styled from 'styled-components'
import { ReactComponent as Logo } from '@sketch/icons/logo'
import { IsSignedIn } from '../../user'
import { EmbeddedErrorPage } from '../Embedding'
import { routes } from '../../routes'

const StyledErrorMessage = styled(ErrorMessage)`
  flex: 1 0 auto;
  align-self: center;
`

interface RenderNotFoundStateProps {
  isSignedIn?: boolean
  title?: string
  additionalMessage?: React.ReactNode
}

export const RenderNotFoundState: React.FC<RenderNotFoundStateProps> = ({
  title = 'We cannot find the page you’re looking for.',
  additionalMessage,
  isSignedIn,
}) => (
  <StyledErrorMessage
    title={title}
    icon={() => <Logo width="46px" height="46px" />}
  >
    {additionalMessage}
    {isSignedIn ? (
      <Link to={routes.ENTRY.create({})}>Go to your documents overview</Link>
    ) : (
      <span>
        <Link to={routes.SIGN_IN.create({})}>Sign in to Sketch</Link> or{' '}
        <Link to={routes.SIGN_UP.create({})}>Create an account</Link>
      </span>
    )}
  </StyledErrorMessage>
)

interface NotFoundStateProps {
  title?: string
  additionalMessage?: React.ReactNode
}

export const NotFoundState: React.FC<NotFoundStateProps> = props => (
  <IsSignedIn>
    {(isSignedIn: boolean) => (
      <RenderNotFoundState
        title={props.title}
        additionalMessage={props.additionalMessage}
        isSignedIn={isSignedIn}
      />
    )}
  </IsSignedIn>
)

interface NotFoundViewProps {
  title?: string
  additionalMessage?: React.ReactNode
  isInLayout?: boolean
}

export const NotFoundView: React.FC<NotFoundViewProps> = props => (
  <>
    <NotEmbedded>
      <CenterContent>
        {props.isInLayout ? null : <EmptyHeader />}
        <NotFoundState
          title={props.title}
          additionalMessage={props.additionalMessage}
        />
      </CenterContent>
    </NotEmbedded>
    <IsEmbedded>
      <EmbeddedErrorPage for="unknown" />
    </IsEmbedded>
  </>
)
