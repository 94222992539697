import { find } from 'linkifyjs'

/**
 * This Plugin is an modified version of the remark url
 * https://github.com/remarkjs/remark/blob/8fad30e203d033a23965dd7d49c882d1795fb12c/packages/remark-parse/lib/tokenize/url.js */

const URL_REGEX = /((https?:\/\/){0,}[a-zA-Z0-9@+-]+(\.[a-z]+)+)/g

/**
 * TokenizeLink.
 *
 * This function is responsible for replacing a text by a link element.
 *
 * @param {Function} eat - Replace the match by an object.
 * @param {string} value - text value caught.
 * @param {boolean} silent - Whether to detect or consume.
 *
 */
function tokenizeLink(eat, value, silent) {
  const link = find(value)[0]

  if (!link || silent || !value.startsWith(link.value)) {
    return true
  }

  const text = {
    type: 'text',
    value: link.value,
  }

  return eat(link.value)({
    type: 'link',
    url: link.href,
    children: [text],
  })
}

tokenizeLink.locator = (value, fromIndex) => {
  const regexMatch = value.slice(fromIndex).match(URL_REGEX)

  if (regexMatch) {
    return value.indexOf(regexMatch[0], fromIndex)
  }

  return -1
}

/**
 * Link plugin for the `remark-js`.
 */
export function link() {
  const Parser = this.Parser
  const tokenizers = Parser.prototype.inlineTokenizers
  const methods = Parser.prototype.inlineMethods

  tokenizers.url = tokenizeLink

  methods.splice(methods.indexOf('url'), 1)
  methods.splice(methods.indexOf('emoji'), 0, 'url')
}
