import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
  IndexLayoutContent,
  IndexLayoutExtraProps,
  IndexLayoutHeaderLink,
  IndexLayoutOldTitle,
  routes,
  useFetchInitialUser,
} from '@sketch/modules-common'
import { Link } from '@sketch/components'

import { isPricingSourceRoute } from '../../../utils'

import {
  FormContainer,
  LinkDescription,
  Subtitle,
  Wrapper,
} from './RecoveryCodeView.styles'
import { RecoveryCodeForm } from './RecoveryCodeForm'

interface LocationState {
  mfaToken: string
  from?: Location
}

export interface RecoveryCodeViewProps extends IndexLayoutExtraProps {}

const RecoveryCodeView = (props: RecoveryCodeViewProps) => {
  const { HeaderPortal } = props
  const history = useHistory()
  const location = useLocation<LocationState>()

  const fetchInitialUser = useFetchInitialUser()

  return (
    <IndexLayoutContent center="horizontal" marginTop paddingHorizontal>
      <HeaderPortal>
        <IndexLayoutHeaderLink headerLink="back-sign-in" />
      </HeaderPortal>
      <Wrapper>
        <IndexLayoutOldTitle>Enter Recovery Code</IndexLayoutOldTitle>
        <Subtitle>
          Can’t access your authenticator app? Enter a recovery code instead.
        </Subtitle>
        <FormContainer>
          <RecoveryCodeForm
            onCompleted={async data => {
              // Get the user profile for the first time now, since <UserContext /> (App.tsx)
              // can't do it because the user is not signed in until now
              await fetchInitialUser()

              if (
                isPricingSourceRoute(location) &&
                data.validateMfaRecoveryCode.createdWorkspace
              ) {
                const workspaceId =
                  data.validateMfaRecoveryCode.createdWorkspace

                history.push(routes.WORKSPACE_SUBSCRIBE.create({ workspaceId }))
                return
              }

              history.push(location.state?.from || routes.ENTRY.create({}))
            }}
          />
        </FormContainer>
        <LinkDescription>
          Authenticator app working again?{' '}
          <Link
            to={{
              pathname: routes.VERIFICATION_CODE.create({}),
              search: location.search,
              state: {
                mfaToken: location.state.mfaToken,
                from: location.state.from,
              },
            }}
            variant="tertiary"
            isUnderlined
          >
            Enter verification code
          </Link>
        </LinkDescription>
        <LinkDescription>
          Lost your recovery codes?{' '}
          <Link
            external
            variant="tertiary"
            isUnderlined
            href="https://www.sketch.com/support/contact/"
          >
            Contact us
          </Link>
        </LinkDescription>
      </Wrapper>
    </IndexLayoutContent>
  )
}

export default RecoveryCodeView
