import { useEvent, Metric, MetricId } from '@sketch-hq/sketch-web-renderer'
import { useCallback, useEffect, useRef, useState } from 'react'
import { AnalyticsContext, useAnalytics } from '@sketch/modules-common'
import * as Sentry from '@sentry/browser'
import { useDidBrowserTabChange } from '@sketch/utils'

type DurationMS = number

type MetricsPayload = {
  metadata: Partial<{
    shareID: string
    pageUUID: string
    versionShortId: string
    // All the info below are captured using the detect-gpu package
    // @see https://github.com/pmndrs/detect-gpu
    deviceInfo: {
      gpu: string
      fps: number // This is a static lookup based on the gpu. Here's the table used: https://gfxbench.com/result.jsp
      cores: number
      isMobile: boolean
    }
    prFileInfo: {
      imagesCount: number
      weightInBytes: number
      version: string
    }
  }>
  metrics: Partial<
    { [key in MetricId]: DurationMS } & {
      Settled: DurationMS
      FileResolve: DurationMS
    }
  >
}

type AllMetricId = keyof MetricsPayload['metrics']

type UseSketchWebMetricsProps = {
  shareID: string
  pageUUID: string
  versionShortId: string
  filePath?: string | null
}

const sendMetrics = (payload: MetricsPayload, analytics: AnalyticsContext) => {
  const { trackMetric } = analytics
  const file_version = payload.metadata.prFileInfo?.version

  if (payload.metrics.WasmModuleDownload !== undefined)
    trackMetric({
      id: 'webapp_render_wasm_module_download_seconds',
      value: payload.metrics.WasmModuleDownload / 1000,
      labels: { file_version },
    })

  if (payload.metrics.FileDownload !== undefined)
    trackMetric({
      id: 'webapp_render_file_download_seconds',
      value: payload.metrics.FileDownload / 1000,
      labels: { file_version },
    })

  if (payload.metrics.FileParse !== undefined)
    trackMetric({
      id: 'webapp_render_file_parse_seconds',
      value: payload.metrics.FileParse / 1000,
      labels: { file_version },
    })

  if (payload.metrics.InitialRender !== undefined)
    trackMetric({
      id: 'webapp_render_initial_render_seconds',
      value: payload.metrics.InitialRender / 1000,
      labels: { file_version },
    })

  if (payload.metrics.FirstPaint !== undefined)
    trackMetric({
      id: 'webapp_render_first_paint_seconds',
      value: payload.metrics.FirstPaint / 1000,
      labels: { file_version },
    })

  if (payload.metrics.ImagesDownload !== undefined)
    trackMetric({
      id: 'webapp_render_images_download_seconds',
      value: payload.metrics.ImagesDownload / 1000,
      labels: { file_version },
    })

  if (payload.metrics.Settled !== undefined)
    trackMetric({
      id: 'webapp_render_settled_seconds',
      value: payload.metrics.Settled / 1000,
      labels: { file_version },
    })

  // Log more details if rendering takes a long time
  if (payload.metrics.FirstPaint && payload.metrics.FirstPaint > 60_000) {
    Sentry.captureMessage('Slow web render', {
      extra: { payload },
    })
  }
}

export const useSketchWebMetrics = (props: UseSketchWebMetricsProps) => {
  const { shareID, pageUUID, versionShortId, filePath } = props
  const analytics = useAnalytics()

  const [payload, setPayload] = useState<MetricsPayload>({
    metadata: {},
    metrics: {},
  })

  const mountTime = useRef(0)
  const didBrowserTabChangeRef = useDidBrowserTabChange()

  useEvent('deviceInfo', deviceInfo => {
    setPayload(state => ({
      ...state,
      metadata: {
        ...state.metadata,
        deviceInfo: {
          gpu: deviceInfo.gpu ?? 'Unknown',
          fps: deviceInfo.fps ?? 0,
          cores: deviceInfo.hardwareConcurrency,
          isMobile: deviceInfo.isMobile ?? false,
        },
      },
    }))
  })

  useEvent('fileReady', (version, weightInBytes, imagesCount) => {
    setPayload(state => ({
      ...state,
      metadata: {
        ...state.metadata,
        prFileInfo: {
          version,
          weightInBytes,
          imagesCount,
        },
      },
    }))
  })

  const addMetric = useCallback(
    (id: AllMetricId, value: DurationMS) =>
      setPayload(state => ({
        ...state,
        metrics: { ...state.metrics, [id]: Math.round(value) },
      })),
    []
  )

  const onMetric = useCallback(
    (metric: Metric) => {
      const { id, duration, end } = metric
      switch (metric.id) {
        case 'FirstPaint':
          // The `FirstPaint` metric works better when measured from when this
          // hook mounts, as opposed to from when the WASM module
          // has initialized (the raw event duration), so we re-calculate it:
          addMetric(id, end - mountTime.current)
          break
        case 'ImagesDownload':
          // The `ImagesDownloaded` metric is the last metric we expect to
          // recieve, so it allows us to calculate the final `Settled`
          // metric, which we define as the time taken from view mount until the
          // canvas has rendered and then loaded all external images
          addMetric(id, duration)
          addMetric('Settled', end - mountTime.current)
          break
        default:
          // For the other discrete metrics, we just forward them on unchanged
          addMetric(id, duration)
      }
    },
    [addMetric]
  )

  useEffect(() => {
    // `FileResolve` is a new metric calculated here from scratch. It's the time
    // taken for the `filePath` field to transition from undefined to defined,
    // which defines how long it takes the BE to provide the PR file. This will
    // either be very fast for pre-exported/cached PR files, or quite long for
    // PR files that are generated on demand.
    if (typeof filePath === 'string') {
      addMetric('FileResolve', Date.now() - mountTime.current)
    }
  }, [filePath, addMetric])

  useEffect(() => {
    mountTime.current = Date.now()

    setPayload(state => ({
      ...state,
      metadata: {
        ...state.metadata,
        pageUUID,
        shareID,
        versionShortId,
      },
    }))

    return () => setPayload(() => ({ metadata: {}, metrics: {} }))
  }, [pageUUID, shareID, versionShortId])

  useEffect(() => {
    // Skipping the metrics in case the user tab changed.
    // We have no reliable way to track/restore the metrics in case the tab changes, so we just discard them
    if (didBrowserTabChangeRef.current) return

    // `Settled` is the last metric to be added, so use its presence as a
    // heuristic to detect when the metrics object is fully populated and
    // ready to send
    if ((Object.keys(payload.metrics) as AllMetricId[]).includes('Settled')) {
      sendMetrics(payload, analytics)
    }
  }, [payload, didBrowserTabChangeRef, analytics])

  useEvent('metric', onMetric)
}
