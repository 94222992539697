import styled from 'styled-components'

import { Text, Form } from '@sketch/components'

export const StyledForm = styled(Form)`
  margin-top: 24px;
`

export const NewPasswordCaption = styled(Text.Span).attrs({
  textStyle: 'copy.quaternary.standard.C',
})`
  display: block;
  margin-top: 4px;
`
