import React from 'react'
import { DataProxy } from 'apollo-cache'
import {
  AccessLevelSelect,
  AccessLevelSelectOption,
  AccessLevelSelectCheckbox,
  AccessLevelSelectSeparator,
  AccessLevelSelectBanner,
} from '@sketch/modules-common'
import { AddMemberButton } from '../AddMemberButton/AddMemberButton'
import { RemoveMemberButton } from '../RemoveMemberButton/RemoveMemberButton'
import { removeProjectMembershipUserAccessModal } from '../../operations/utils/projectMemberships.cache'
import {
  ProjectFragment,
  ProjectMembershipFragment,
  RemoveProjectMemberMutation,
} from '@sketch/gql-types'
import { Tooltip } from '@sketch/components'

export interface ProjectMemberPermissionsAccessSelectProps {
  member: {
    privateAccessLevel: ProjectMembershipFragment['privateAccessLevel']
    workspaceMembership: {
      role?: ProjectMembershipFragment['workspaceMembership']['role']
    }
  }
  guestCommentsEnabled?: boolean
  guestInspectEnabled?: boolean
  onPermissionChange?: (
    permission: 'guestInspectEnabled' | 'guestCommentsEnabled',
    value: boolean
  ) => void
  onlyMembers?: boolean
}

export interface EditProjectMemberPermissionsAccessSelectProps
  extends ProjectMemberPermissionsAccessSelectProps {
  project: ProjectFragment
  member: Pick<
    ProjectMembershipFragment,
    'identifier' | 'privateAccessLevel' | 'inviteStatus' | 'workspaceMembership'
  >
  // We need these two parameters for when we will remove
  // members from user permissions modal
  workspaceIdentifier?: string
  workspaceMembershipIdentifier?: string
  onAction: () => void
}

const memberOptions: Record<'EDIT' | 'VIEW', React.ReactNode> = {
  EDIT: (
    <AccessLevelSelectOption
      data-testid="access-level-dropdown-edit"
      level="EDIT"
      label="Edit"
      help="Edit and view the document"
      disabled
    />
  ),
  VIEW: (
    <AccessLevelSelectOption
      data-testid="access-level-dropdown-view"
      level="VIEW"
      label="View"
      help="View, inspect and download document in the web app"
      disabled
    />
  ),
}

export const ProjectMemberPermissionsAccessSelect = ({
  member,
  guestCommentsEnabled,
  guestInspectEnabled,
  onPermissionChange,
  onlyMembers,
}: ProjectMemberPermissionsAccessSelectProps) => {
  const isWorkspaceMember =
    member.workspaceMembership.role !== 'GUEST' || onlyMembers

  return (
    <>
      {memberOptions[member.privateAccessLevel]}
      <AccessLevelSelectBanner>
        {isWorkspaceMember
          ? 'Access is determined by the Member′s Workspace role.'
          : 'Guest access for projects is always set to View.'}
      </AccessLevelSelectBanner>
      <AccessLevelSelectSeparator />
      <Tooltip
        placement="bottom-end"
        content="Workspace Members can always download and inspect"
        disabled={!isWorkspaceMember}
      >
        <AccessLevelSelectCheckbox
          data-testid="inspect-enabled"
          label="Can download and inspect"
          checked={isWorkspaceMember || guestInspectEnabled}
          disabled={isWorkspaceMember}
          onChange={e =>
            onPermissionChange?.('guestInspectEnabled', e.target.checked)
          }
        />
      </Tooltip>
      <Tooltip
        placement="bottom-end"
        content="Workspace Members can always comment"
        disabled={!isWorkspaceMember}
      >
        <AccessLevelSelectCheckbox
          data-testid="comments-enabled"
          label="Can comment"
          checked={isWorkspaceMember || guestCommentsEnabled}
          disabled={isWorkspaceMember}
          onChange={e => {
            onPermissionChange?.('guestCommentsEnabled', e.target.checked)
          }}
        />
      </Tooltip>
    </>
  )
}

export const EditProjectMemberPermissionsAccessSelect = ({
  project,
  member,
  guestCommentsEnabled,
  guestInspectEnabled,
  workspaceIdentifier,
  workspaceMembershipIdentifier,
  onAction,
  onPermissionChange,
}: EditProjectMemberPermissionsAccessSelectProps) => {
  // This handler will be called when we will be accesing from
  // User Permissions Directory, and it will remove from the cache
  // the shares from a given user
  const onComplete = (
    cache: DataProxy,
    data?: RemoveProjectMemberMutation | null
  ) =>
    workspaceIdentifier &&
    workspaceMembershipIdentifier &&
    removeProjectMembershipUserAccessModal({
      cache,
      data,
      workspaceIdentifier,
      workspaceMembershipIdentifier,
    })

  const memberContent = (
    <>
      <ProjectMemberPermissionsAccessSelect
        member={member}
        guestCommentsEnabled={guestCommentsEnabled}
        guestInspectEnabled={guestInspectEnabled}
        onPermissionChange={onPermissionChange}
      />
      <AccessLevelSelectSeparator />
      <RemoveMemberButton
        project={project}
        member={member}
        onCancel={onAction}
        onComplete={onComplete}
      >
        Remove Selected Access
      </RemoveMemberButton>
    </>
  )

  const requestPendingContent = (
    <>
      <AddMemberButton membershipIdentifier={member.identifier}>
        Accept and Give Access
      </AddMemberButton>
      <RemoveMemberButton
        project={project}
        member={member}
        onCancel={onAction}
        onComplete={onComplete}
      >
        Deny Access
      </RemoveMemberButton>
    </>
  )

  const isPendingRequest = member.inviteStatus === 'REQUESTED'
  const memberLevel = isPendingRequest ? 'PENDING' : member.privateAccessLevel

  return (
    <AccessLevelSelect
      hideCommentLabel={true}
      level={memberLevel}
      setLevel={() => {}}
    >
      {isPendingRequest ? requestPendingContent : memberContent}
    </AccessLevelSelect>
  )
}

export const AddProjectMemberPermissionsAccessSelect = ({
  member,
  guestCommentsEnabled,
  guestInspectEnabled,
  onPermissionChange,
  onlyMembers,
}: ProjectMemberPermissionsAccessSelectProps) => {
  return (
    <AccessLevelSelect
      level={member.privateAccessLevel}
      hideCommentLabel
      setLevel={() => {}}
    >
      <ProjectMemberPermissionsAccessSelect
        member={member}
        guestCommentsEnabled={guestCommentsEnabled}
        guestInspectEnabled={guestInspectEnabled}
        onPermissionChange={onPermissionChange}
        onlyMembers={onlyMembers}
      />
    </AccessLevelSelect>
  )
}
