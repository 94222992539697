import styled from 'styled-components'

import { Button, Text } from '@sketch/components'

export const FormFieldRow = styled.div`
  display: flex;
  flex-direction: row;

  & > input {
    min-width: 0;
    flex: 1;
  }

  & > button {
    flex: 0;
  }
`

export const ApprovedLabel = styled(Text.Span).attrs({
  textStyle: 'positive.standard.E',
})`
  margin-left: 4px;
  margin-bottom: 2px; /* stylelint-disable-line scales/space */
`

export const StyledButton = styled(Button)`
  margin-left: 12px;
`

export const ApprovedWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 20px;
  color: ${({ theme }) => theme.colors.state.positive.A};
`

export const ShortnameWrapper = styled.div`
  margin-top: 44px;
  margin-bottom: 12px;
  padding-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`

export const TabsWrapper = styled.div`
  margin-top: 32px;
`
