import { ErrorHandler } from '@sketch/tracing'
import { ApolloError } from 'apollo-client'

import { ParsedError } from './useMutation.types'
import { UserErrorFragment } from './UserErrorFragment.type'

export type { ParsedError } from './useMutation.types'

export class ApolloParsedError extends Error implements ParsedError {
  constructor(public readonly originalError: ApolloError) {
    super()
  }

  get message(): string {
    const error = this.originalError
    const parsedMessage =
      error.graphQLErrors?.map(x => x.message).join(', ') ||
      error.networkError?.message ||
      error.message

    if (parsedMessage) {
      return parsedMessage
    }

    ErrorHandler.shouldNeverHappen(
      'ApolloParsedError should always parse a truthy error message '
    )

    return 'Something is wrong, please contact our support team'
  }

  get messages() {
    const { graphqlErrorMessages, networkErrorMessage } = this

    if (networkErrorMessage) {
      return [...graphqlErrorMessages, networkErrorMessage]
    }

    return graphqlErrorMessages
  }

  get graphqlErrorMessages() {
    return this.originalError.graphQLErrors?.map(x => x.message)
  }

  get networkErrorMessage() {
    return this.originalError.networkError?.message || undefined
  }

  includesErrorCode(value: string) {
    const error = this.originalError

    return error.graphQLErrors.some(e => e.extensions?.code === value)
  }

  includesErrorReason(value: string) {
    const error = this.originalError
    return error.graphQLErrors.some(e => e.extensions?.reason === value)
  }

  getContext() {
    const error = this.originalError
    return error.graphQLErrors.map(e => e.extensions?.context).flat()
  }

  /**
   * @deprecated
   * This field is only for backwards compatibility (particularly for .js files)
   * and it is meant only for the transition.
   */
  get graphQLErrors() {
    return this.originalError.graphQLErrors
  }

  /**
   * @deprecated
   * This field is only for backwards compatibility (particularly for .js files)
   * and it is meant only for the transition.
   */
  get networkError() {
    return this.originalError.networkError
  }

  /**
   * @deprecated
   * This field is only for backwards compatibility (particularly for .js files)
   * and it is meant only for the transition.
   */
  get extraInfo() {
    return this.originalError.extraInfo
  }

  /**
   * @deprecated
   * This field is only for backwards compatibility (particularly for .js files)
   * and it is meant only for the transition.
   */
  get name() {
    return this.originalError.name
  }
}

export class UserParsedError extends Error implements ParsedError {
  constructor(public readonly originalErrors: UserErrorFragment[]) {
    super()
  }

  get message(): string {
    return this.messages.join(', ')
  }

  get messages() {
    return this.originalErrors.map(x => x.message)
  }

  get graphqlErrorMessages() {
    return this.messages
  }

  get networkErrorMessage() {
    return undefined
  }

  includesErrorCode(value: string) {
    return this.originalErrors.some(x => x.code === value)
  }

  includesErrorReason(value: string) {
    return false
  }
}

export class RawParsedError extends Error implements ParsedError {
  constructor(public readonly originalError: Error) {
    super()
  }

  get message(): string {
    return this.originalError.message
  }

  get messages() {
    return [this.message]
  }

  get graphqlErrorMessages() {
    return []
  }

  get networkErrorMessage() {
    return this.originalError.message
  }

  includesErrorCode(value: string) {
    return false
  }

  includesErrorReason(value: string) {
    return false
  }
}
