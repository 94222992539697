import { useCallback, useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import * as Sentry from '@sentry/browser'

import {
  usePRFile,
  useEvent,
  PRFileMarina,
} from '@sketch-hq/sketch-web-renderer'

import { buildSketchSceneTreeFromPRFile } from './sketchSceneLib/buildSketchScene/buildSketchSceneTree'
import { SketchElement } from '../../../../inspector'

/**
 * Build the tree of sketch elements from the PR file from the web-renderer.
 * The PR file contains the rendering node from which we reconstruct
 * the tree of layers like what we show to users in the Sketch app.
 */
export function useSketchSceneTree(isInspectorActive: boolean) {
  const [hasFailed, setFailed] = useState(false)
  const [
    sketchRootElement,
    setSketchRootElement,
  ] = useState<SketchElement | null>(null)

  const prFile = usePRFile()

  const createSketchRootElement = useCallback((prFile: PRFileMarina) => {
    try {
      const sketchRootElement = buildSketchSceneTreeFromPRFile(prFile)
      setSketchRootElement(sketchRootElement)
      setFailed(false)
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setTag('sketch_inspector', 'InvalidSketchScene')
        Sentry.captureException(e)
      })

      setSketchRootElement(null)
      setFailed(true)
    }
  }, [])

  const updateBuildScene = useMemo(() => {
    return debounce(() => {
      if (!prFile || !isInspectorActive) {
        return
      }

      createSketchRootElement(prFile)
    }, 1000)
  }, [prFile, createSketchRootElement, isInspectorActive])

  useEffect(() => {
    if (!prFile || !isInspectorActive) {
      return
    }

    createSketchRootElement(prFile)
  }, [prFile, createSketchRootElement, isInspectorActive])

  useEvent('fragmentLoad', () => {
    updateBuildScene?.()
  })

  return { sketchRootElement, hasFailed }
}
