import React, { ReactNode, useCallback } from 'react'
import { IS_EMBEDDED } from '@sketch/constants'
import { useResponsiveDropdown } from '@sketch/components'
import * as S from './ResizeModeSelector.styles'
import {
  PrototypeResizeMode,
  usePrototypeZoom,
} from '@sketch-hq/sketch-web-renderer'
import { useSyncedUrlPrototypeResizeMode } from '../../hooks'

export function ResizeModeSelector() {
  const { setResizeMode } = useSyncedUrlPrototypeResizeMode()
  const prototypeScalePercent = Math.round(usePrototypeZoom() * 100)

  const setToActualSize = useCallback(
    () => setResizeMode(PrototypeResizeMode.ActualSize),
    [setResizeMode]
  )

  const setToFit = useCallback(() => setResizeMode(PrototypeResizeMode.Fit), [
    setResizeMode,
  ])

  const setToFillWidth = useCallback(
    () => setResizeMode(PrototypeResizeMode.FillWidth),
    [setResizeMode]
  )

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: ResizeModeDropdown,
    dropdownProps: {
      setToActualSize,
      setToFit,
      setToFillWidth,
    },
    placement: 'bottom-end',
    usePortal: true,
  })

  return (
    <>
      <S.Button
        variant="strong"
        label={`${prototypeScalePercent}%`}
        fullWidth
        data-testid="resize-mode-selector"
        chevrons
        {...buttonProps}
      />
      {content}
    </>
  )
}

type ResizeModeDropdownProps = {
  setToActualSize: () => void
  setToFit: () => void
  setToFillWidth: () => void
}

function ResizeModeDropdown(props: ResizeModeDropdownProps) {
  const { resizeMode } = useSyncedUrlPrototypeResizeMode()

  return (
    <>
      <ResizeModeDropdownItem
        onClick={props.setToFit}
        active={resizeMode === PrototypeResizeMode.Fit}
        keyboardShortCutHint="Shift 0"
      >
        Fit
      </ResizeModeDropdownItem>
      <ResizeModeDropdownItem
        onClick={props.setToFillWidth}
        active={resizeMode === PrototypeResizeMode.FillWidth}
        keyboardShortCutHint="Shift 1"
      >
        Fill Width
      </ResizeModeDropdownItem>
      <ResizeModeDropdownItem
        onClick={props.setToActualSize}
        active={resizeMode === PrototypeResizeMode.ActualSize}
        keyboardShortCutHint="z"
      >
        Actual Size
      </ResizeModeDropdownItem>
    </>
  )
}

type ResizeModeDropdownItemProps = {
  active: boolean
  children: ReactNode
  onClick: () => void
  keyboardShortCutHint: string
}

function ResizeModeDropdownItem(props: ResizeModeDropdownItemProps) {
  return (
    <S.Item onClick={props.onClick}>
      <S.StyledRadioButton
        checked={props.active}
        // noop to avoid console warning (we are using onClick handler instead)
        onChange={() => {}}
      />
      <S.Label>{props.children}</S.Label>
      {!IS_EMBEDDED && (
        <S.KeyboardShortcut>{props.keyboardShortCutHint}</S.KeyboardShortcut>
      )}
    </S.Item>
  )
}
