import React from 'react'
import {
  Wrapper,
  ListPublicationItemPlaceholder,
} from './PublicationList.styles'

const PublicationListPlaceholder = () => {
  return (
    <Wrapper>
      <ListPublicationItemPlaceholder />
      <ListPublicationItemPlaceholder />
      <ListPublicationItemPlaceholder />
    </Wrapper>
  )
}

export default PublicationListPlaceholder
