import styled from 'styled-components'

import { breakpoint } from '@sketch/global-styles'

import {
  WorkspaceLogo as UnstyledWorkspaceLogo,
  Link as UnstyledLink,
  Heading,
} from '@sketch/components'

export const Cards = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;

  ${breakpoint('base', 'md')`
    flex-direction: column;
  `}
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 240px;
  height: 277px;

  padding: 24px 24px 20px;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.colors.shadow.outer};
  background-color: ${({ theme }) => theme.colors.background.secondary.A};

  :not(:last-child) {
    margin-right: 32px;
  }

  ${breakpoint('base', 'md')`
      :not(:last-child) {
        margin-right: 0px;
        margin-bottom: 20px;
      }
  `}
`

export const Image = styled.svg.attrs({
  role: 'img',
})`
  height: 48px;
  width: 48px;
  margin-bottom: 8px;
`

export const CardTitle = styled(Heading.H1)`
  font-size: ${({ theme }) => theme.fontSizes.F};
  font-weight: 400;
  line-height: 1.4;
  margin: 0;
`

export const Description = styled.p<{ marginTop?: number }>`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.E};
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  margin: 0;
  margin-top: ${({ marginTop }) => `${marginTop}px`};
`

export const DescriptionPaymentMethod = styled(Description)`
  min-height: 38px;
`

export const Link = styled(UnstyledLink)`
  font-size: ${({ theme }) => theme.fontSizes.E};
`

export const Separator = styled.hr`
  /* 24px = left/ right padding size of the Card element */
  width: calc(100% + 24px * 2);
  background-color: ${({ theme }) => theme.colors.background.secondary.C};
  margin: 24px 0 20px;
`

export const CtaWrapper = styled.div<{ marginTop: number }>`
  margin-top: ${({ marginTop }) => `${marginTop}px`};
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const WorkspaceLinkWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const WorkspaceLogo = styled(UnstyledWorkspaceLogo)`
  margin-right: 8px;
  /* TODO: Instead of this background color, this should be the tint color */
  background-color: ${({ theme }) => theme.colors.background.primary.B};
`
