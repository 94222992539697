import { Placement, Modifier, State } from '@popperjs/core'

export interface CreatePopperModifiersParams {
  placement?: Placement
  offset?: [number, number]
  modifiers?: Partial<Modifier<any, any>>[]
  onFirstUpdate?: (state: Partial<State>) => void
}

export const createPopperModifiers = ({
  placement = 'auto',
  offset = [0, 5],
  modifiers = [],
  onFirstUpdate,
}: CreatePopperModifiersParams) => ({
  placement,
  onFirstUpdate,
  modifiers: [
    {
      name: 'computeStyles',
      options: { gpuAcceleration: false, roundOffsets: false },
    },
    { name: 'flip', options: { padding: 0 }, enabled: true },
    {
      name: 'preventOverflow',
      options: { boundary: 'clippingParents', padding: 5 },
    },
    { name: 'offset', options: { offset } },
    { name: 'hide', enabled: false },
    ...modifiers,
  ],
})
