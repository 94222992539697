import React from 'react'

import { isTouchDevice } from '@sketch/utils'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import {
  useResponsiveDropdown,
  BaseResponsiveDropdownProps,
  SelectDropdownItem,
  SelectDropdownExternalLinkItem,
  SelectDropdownLinkItem,
} from '@sketch/components'
import { TroubleshootingConsentMenuItem } from '../TroubleshootingConsentMenuItem'

import { icons } from '../../icons'

import { Chevron } from '../BottomMenu/BottomMenu.styles'
import {
  DropdownDivider,
  DropdownWrapper,
  DropdownItem,
  DropdownHeader,
} from './HelpMenu.styles'
import { routes } from '@sketch/modules-common'

interface HelpMenuProps {
  title?: string
  userCanAdminister?: boolean
  workspaceId?: string
  workspaceName?: string
}

interface HelpMenuContentProps extends BaseResponsiveDropdownProps {
  userCanAdminister?: boolean
  workspaceId?: string
  workspaceName?: string
}

/**
 *
 * HelpMenuContent
 *
 * Renders the content for the new Sidebar Help menu
 * found at the bottom  of the sidebar
 */
const HelpMenuContent: React.FC<HelpMenuContentProps> = ({
  currentVariant,
  userCanAdminister,
  workspaceId,
  workspaceName,
}) => {
  const isModalVariant = currentVariant === 'modal'
  const isTouch = isTouchDevice()

  return (
    <DropdownWrapper isModalVariant={isModalVariant}>
      {isModalVariant && <DropdownHeader>Help</DropdownHeader>}
      <DropdownItem>
        <SelectDropdownExternalLinkItem
          icon={icons.SketchLogo}
          href={`${SKETCH_WEBSITE}/updates/`}
          text="What's New in Sketch"
        />
      </DropdownItem>
      <DropdownDivider />
      {workspaceId && (
        <DropdownItem>
          <SelectDropdownLinkItem
            icon={icons.Book}
            text="Guides and Courses"
            to={routes.WORKSPACE_DISCOVER.create({ workspaceId })}
          />
        </DropdownItem>
      )}
      <DropdownItem>
        <SelectDropdownExternalLinkItem
          icon={icons.InformationIcon}
          href={`${SKETCH_WEBSITE}/docs/`}
          text="Documentation"
        />
      </DropdownItem>
      {!isTouch && (
        <DropdownItem>
          <SelectDropdownExternalLinkItem
            icon={icons.Command}
            href={`${SKETCH_WEBSITE}/docs/designing/shortcuts/#web-app-shortcuts`}
            text="Keyboard Shortcuts"
          />
        </DropdownItem>
      )}
      <DropdownItem>
        <SelectDropdownExternalLinkItem
          icon={icons.ChatBubbles}
          href="https://forum.sketch.com"
          text="Community Forum"
        />
      </DropdownItem>
      <DropdownItem>
        <SelectDropdownExternalLinkItem
          icon={icons.Extension}
          href="https://developer.sketch.com"
          text="Developer Platform"
        />
      </DropdownItem>
      <DropdownDivider />
      <DropdownItem>
        <SelectDropdownExternalLinkItem
          icon={icons.Home}
          href={`${SKETCH_WEBSITE}/home`}
          text="Visit Sketch.com"
        />
      </DropdownItem>
      <DropdownItem>
        <SelectDropdownExternalLinkItem
          icon={icons.Desktop}
          href={`${SKETCH_WEBSITE}/downloads/mac/`}
          text="Get Sketch for Mac"
        />
      </DropdownItem>
      <DropdownItem>
        <SelectDropdownExternalLinkItem
          icon={icons.Phone}
          href="https://apps.apple.com/app/sketch-view-and-mirror/id1609224699"
          text="Get Sketch for iOS"
        />
      </DropdownItem>
      <DropdownDivider />
      <DropdownItem>
        <SelectDropdownExternalLinkItem
          icon={icons.MailEnvelope}
          href={`${SKETCH_WEBSITE}/support/`}
          text="Contact Customer Support"
        />
      </DropdownItem>
      {userCanAdminister && (
        <>
          <DropdownDivider />
          <TroubleshootingConsentMenuItem
            workspaceId={workspaceId}
            workspaceName={workspaceName}
          />
        </>
      )}
    </DropdownWrapper>
  )
}

/**
 *
 * HelpMenu
 *
 * Renders the HelpMenu used in the "BottomMenu" on the "Sidebar"
 */
const HelpMenu: React.FC<HelpMenuProps> = ({
  title = 'Help with Sketch',
  userCanAdminister,
  workspaceId,
  workspaceName,
}) => {
  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: HelpMenuContent,
    placement: 'right-end',
    dropdownProps: {
      userCanAdminister,
      workspaceId,
      workspaceName,
    },
  })

  return (
    <>
      <SelectDropdownItem
        icon={icons.LifeBelt}
        text={title}
        additional={<Chevron />}
        {...buttonProps}
      />
      {content}
    </>
  )
}

export default HelpMenu
