import { AnnotationCoordinatesFragment } from '@sketch/gql-types'

import { Coordinates } from '../types'
import { DEFAULT_COORDINATES } from '../constants'
/**
 * Remove `__typename` & `identifier` fields from Annotation coordinates.
 *
 * We only need these fields when updating the cache, and they can cause
 * unnecessary re-renders, since the `identifier` changes when Apollo overwrites
 * optimistic coordinates with the actual mutation results.
 */
export const getCleanAnnotationCoordinates = (
  coords?: AnnotationCoordinatesFragment['coordinates']
): Coordinates => ({
  x: coords?.x || DEFAULT_COORDINATES.x,
  y: coords?.y || DEFAULT_COORDINATES.y,
})
