import { isMac } from '../isMac'

type OS = Pick<Navigator, 'platform'>

export const isInputFocused = (event: KeyboardEvent) => {
  const target = event.target
  if (
    target instanceof HTMLInputElement ||
    target instanceof HTMLTextAreaElement
  ) {
    return true
  }
  return false
}

const isModifierKeyPressed = (
  event: KeyboardEvent,
  navigator: OS = window.navigator
) => (isMac(navigator) ? event.metaKey : event.ctrlKey)

// To use with useKey hook: useKey(keyWithoutModifier(['=', '+']), console.log)
export const keyWithoutModifier = (
  key: string | string[],
  navigator: OS = window.navigator
) => {
  return (event: KeyboardEvent): boolean => {
    if (isInputFocused(event)) return false
    if (Array.isArray(key)) {
      return key.includes(event.key) && !isModifierKeyPressed(event, navigator)
    } else {
      return key === event.key && !isModifierKeyPressed(event, navigator)
    }
  }
}

// To use with useKey hook: useKey(keyWithModifier('/'), console.log)
export const keyWithModifier = (
  key: string | string[],
  navigator: OS = window.navigator
) => {
  return (event: KeyboardEvent): boolean => {
    if (isInputFocused(event)) return false
    if (Array.isArray(key)) {
      return key.includes(event.key) && isModifierKeyPressed(event, navigator)
    } else {
      return key === event.key && isModifierKeyPressed(event, navigator)
    }
  }
}

// To use with useKey hook: useKey(keyCodeWithShift('Digit0'), console.log)
export const keyCodeWithShift = (code: string | string[]) => {
  return (event: KeyboardEvent): boolean => {
    if (isInputFocused(event)) return false
    if (Array.isArray(code)) {
      return code.includes(event.code) && event.shiftKey
    } else {
      return code === event.code && event.shiftKey
    }
  }
}
