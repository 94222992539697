import React from 'react'
import { LinkProps as ReactRouterLinkProps } from 'react-router-dom'

import { InternalLink, ExternalLink, Variants, Sizes } from './Link.styles'

export interface CommonProps {
  variant?: Variants
  isUnderlined?: boolean
  icon?: React.ReactElement
  size?: Sizes
  'data-testid'?: string
}

export type InternalLinkProps = ReactRouterLinkProps &
  CommonProps & { external?: false }

type ExternalLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  CommonProps & { external: true; to?: never }

export type LinkProps = InternalLinkProps | ExternalLinkProps

/**
 * A Link component that renders a Prism styled 'react-router-dom' Link.
 *
 * This component extends 'react-router-dom' Link component.
 *
 * Variants:
 *
 *  - 'primary' -> Beige link
 *  - 'secondary' -> Black link
 *  - 'tertiary' -> Grey link
 *
 * Examples:
 *
 * Internal Link
 *
 * ```jsx
 * <Link
 *    to={...}
 *    variant="secondary"
 *    isUnderlined
 * />
 * ```
 *
 * External Link
 *
 * ```jsx
 * <Link
 *    href={...}
 *    variant="primary"
 *    isUnderlined
 *    external
 * />
 * ```
 */
export const Link: React.FC<LinkProps> = ({
  variant = 'primary',
  isUnderlined = false,
  external,
  href,
  children,
  className,
  onClick,
  icon,
  size,
  target,
  'data-testid': dataTestId,
  ...props
}) => {
  if (external) {
    return (
      <ExternalLink
        $variant={variant}
        $size={size}
        $isUnderlined={isUnderlined}
        href={href || ''}
        className={className}
        rel="noopener noreferrer"
        target={target ? target : '_blank'}
        onClick={onClick}
        data-testid={dataTestId}
        {...(props as ReactRouterLinkProps)}
      >
        {icon}
        {children}
      </ExternalLink>
    )
  }

  return (
    <InternalLink
      $variant={variant}
      $size={size}
      $isUnderlined={isUnderlined}
      className={className}
      onClick={onClick}
      target={target}
      data-testid={dataTestId}
      {...(props as ReactRouterLinkProps)}
    >
      {icon}
      {children}
    </InternalLink>
  )
}
