import { NetworkStatus } from 'apollo-client'

import {
  GetAnnotationsQueryVariables,
  useGetAnnotationsQuery,
} from '@sketch/gql-types'

interface UseGetAnnotationsProps {
  skip?: boolean
  variables: GetAnnotationsQueryVariables
}

const loadingStatus = [NetworkStatus.loading, NetworkStatus.setVariables]

const useGetAnnotations = ({ skip, variables }: UseGetAnnotationsProps) => {
  const query = useGetAnnotationsQuery({
    variables,
    fetchPolicy: 'cache-and-network',
    skip,
    shouldInvalidatePrevious: (prev, curr) =>
      prev?.versionIdentifier !== curr?.versionIdentifier ||
      prev?.annotationStatus !== curr?.annotationStatus ||
      prev?.sort !== curr?.sort,
  })

  return {
    ...query,
    /* Only turn on loading state at initial load or when the `versionIdentifier` changes */
    loading: loadingStatus.includes(query.networkStatus) && !query.data,
  }
}

export default useGetAnnotations
