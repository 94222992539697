import React, { useRef, useState, useEffect, useMemo } from 'react'
import {
  useTransition,
  config,
  animated,
  UseTransitionResult,
  ForwardedProps,
} from 'react-spring'
import styled, { css } from 'styled-components'
import omit from 'lodash.omit'
import { ToastId } from '../Context'
import { pick } from '@sketch/utils'

/*
 * there were a lot of discussions regarding this solution:
 * choosing between two approaches:
 *  1. do not calculate heights of toasts
 *  2. calculate heights of toasts
 * it is possible to follow up the discussion here:
 * https://bohemiancoding.slack.com/archives/CESQLT4RZ/p1554369552237100
 * https://github.com/awkward/sketch-frontend/pull/629
 */

// values are just a random value, used to animate slide in of an child item
const INITIAL_Y_OFFSET = -500
const INITIAL_X_OFFSET = 300

const BaseToastsContainerCss = css`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
`

const ToastsContainerCss = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const ToastsContainer = styled.div`
  ${BaseToastsContainerCss};
  ${ToastsContainerCss};
`

const AnimationWrapper = styled(animated.div)`
  float: right;
  max-width: 50%;
  height: 0;
  margin: unset;
  text-align: center;
  will-change: transform;
  pointer-events: all;
`
const leaveBehindStyles = {
  position: 'relative',
  zIndex: -1,
} as const

const calculateHeights = (
  childKeys: string[],
  refs: React.MutableRefObject<{ [key: string]: HTMLDivElement }>,
  currentHeights: { [key: string]: number }
) => {
  const getHeight = (key: string) => {
    if (!refs || !refs.current[key]) {
      return undefined
    }
    return refs.current[key].clientHeight
  }

  const heights = childKeys.map(key => ({
    [key]: getHeight(key) || currentHeights[key],
  }))
  const updatedHeights = heights.reduce(
    (acc, curr) => ({ ...acc, ...curr }),
    {}
  )

  const result = { ...currentHeights, ...updatedHeights }
  return result
}

export type ToastChild = React.ReactElement & { key: ToastId }

export interface ToastsDisplayProps {
  className?: string
  children: ToastChild[] | ToastChild
}

type TransitionProps = { x: number; y: number; opacity: number }
type TransitionResult = UseTransitionResult<
  ToastChild,
  ForwardedProps<TransitionProps>
>

export const ToastsDisplay = ({ children, ...props }: ToastsDisplayProps) => {
  const refs = useRef<{ [key: string]: HTMLDivElement }>({})
  const [heights, setHeights] = useState<{ [key: string]: number }>({})

  const childKeys = useMemo(
    () => React.Children.map(children, child => child.key),
    [children]
  )

  useEffect(() => {
    setHeights(heights => calculateHeights(childKeys, refs, heights))
  }, [childKeys])

  useEffect(() => {
    if (childKeys.length === 0) {
      return undefined
    }
    const handleResize = () =>
      setHeights(heights => calculateHeights(childKeys, refs, heights))

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [childKeys])

  const onDestroy = ({ key }: ToastChild) => {
    refs.current = pick(refs.current, childKeys)
    setHeights(heights => omit(heights, [key]))
  }

  const transition = useTransition<
    ToastChild,
    // useTransition is somehow badly typed, we are passing partial type here
    // because otherwise we have to pass those values to a type where they are not expected
    Partial<TransitionProps>
  >(
    children,
    childKeys,
    {
      trail: 100,
      config: config.stiff,
      // onDestroyed is badly typed. In reality it is passing an item, not a boolean
      onDestroyed: onDestroy as any,
      from: { opacity: 1, x: INITIAL_X_OFFSET, y: INITIAL_Y_OFFSET },
      enter: { opacity: 1, x: 0, y: 0 },
      leave: (item: ToastChild) => ({
        opacity: 0,
        xy: [0, -1 * heights[item.key]],
      }),
    }
    // Use a type without `Partial` of TransitionProps
  ) as TransitionResult[]

  const onRefUpdate = (key: string) => (ref: HTMLDivElement | null) => {
    if (!ref) {
      return
    }
    refs.current[key] = ref
  }

  return (
    <ToastsContainer {...props}>
      {transition.map(({ item, state, props: { opacity, x, y } }) => {
        return (
          <AnimationWrapper
            key={item.key}
            style={{
              height: y.interpolate(y =>
                Math.max(0, (heights[item.key] || 0) + y)
              ),
              opacity: opacity.interpolate(opacity =>
                opacity === 1 ? undefined : opacity
              ),
              transform: x?.interpolate(x =>
                state !== 'leave' && x !== 0 ? `translateX(${x}px)` : undefined
              ),
              paddingBottom: y?.interpolate(y =>
                y > 0 ? `${y}px` : undefined
              ),
              ...(state === 'leave' ? leaveBehindStyles : {}),
            }}
          >
            <div ref={onRefUpdate(item.key)} style={{ padding: `5px 0` }}>
              {item}
            </div>
          </AnimationWrapper>
        )
      })}
    </ToastsContainer>
  )
}
