import React, { useEffect, useRef, useState } from 'react'
import { routes, useTrackEventInView } from '@sketch/modules-common'
import { PublicationItemFragment } from '@sketch/gql-types'

import {
  SliderWrapper,
  GridPublicationItem,
  ScrollbarTrack,
  ScrollbarWrapper,
  ScrollbarTrackWrapper,
} from './PublicationSlider.styles'

const getRatio = (wrapper: HTMLElement) => {
  return (wrapper.clientWidth / wrapper.scrollWidth) * 100
}

const getScrollRatio = (wrapper: HTMLElement) => {
  return (wrapper.scrollLeft / wrapper.scrollWidth) * 100
}

interface ScrollbarProps {
  wrapperRef: React.RefObject<HTMLElement>
  itemsLength: number
}

const Scrollbar = ({ wrapperRef, itemsLength }: ScrollbarProps) => {
  /* Ratio of visible area and scrollable area in percentage */
  const [ratio, setRatio] = useState(0)
  /* Ratio between the scrollable pixels and the scrollable area size */
  const [scrollLeft, setScrollLeft] = useState(0)
  /* Check the scroll bar has been pressed */
  const [pressed, setPressed] = useState(false)

  const originalPointerXPosition = useRef(0)
  const originalScrollLeft = useRef(0)
  const scrollbarRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    /**
     * We set the "ratio" between the visible-area and the total
     * scroll area. So we can recreate the scrollbar with the correct
     * width.
     *
     * Given that the slider container takes 100% the scrollbar should
     * take a percentage of that
     */
    const wrapper = wrapperRef.current
    if (!wrapper) {
      return
    }

    setRatio(getRatio(wrapper))

    /**
     * If the user resizes the tab the visible-area will be minor and the
     * scrollable area size will increase. So we need to update the ratio
     */
    const updateScrollbarWidth = () => {
      setRatio(getRatio(wrapper))
    }

    const observer = new ResizeObserver(updateScrollbarWidth)
    observer.observe(wrapper)

    return () => observer.disconnect()
  }, [wrapperRef, itemsLength])

  useEffect(() => {
    /**
     * We add the scroll event to the wrapper to
     * make sure the position of the fake scrollbar is updated
     */
    const wrapper = wrapperRef.current
    if (!wrapper) {
      return
    }

    const handleScroll = () => {
      setScrollLeft(getScrollRatio(wrapper))
    }

    wrapper.addEventListener('scroll', handleScroll)
    return () => {
      wrapper.removeEventListener('scroll', handleScroll)
    }
  }, [wrapperRef, itemsLength])

  useEffect(() => {
    /**
     * We are preventing the touch-devices from having default behaviour
     * when the touch events are propagated of the scrollbar to the container.
     *
     * Because the scroll directions are inverse this causes flicker
     * and blocks the function of the scrollbar so we need to block it.
     */
    const wrapper = wrapperRef.current
    if (!wrapper || !pressed) {
      return
    }

    const handleEventBehaviour = (event: Event) => {
      event.preventDefault()
    }

    wrapper.addEventListener('touchstart', handleEventBehaviour)
    wrapper.addEventListener('touchmove', handleEventBehaviour)

    return () => {
      wrapper.removeEventListener('touchstart', handleEventBehaviour)
      wrapper.removeEventListener('touchmove', handleEventBehaviour)
    }
  }, [wrapperRef, pressed])

  useEffect(() => {
    /**
     * We are preventing the event of the touchstart from
     * scrollbar to the parent container.
     *
     * We do it here instead of user "onTouchStart" because React
     * takes longer to set the stop the propagation causing the event
     * to not always be stopped
     */
    const scroll = scrollbarRef.current
    if (!scroll) {
      return
    }

    const handleEventBehaviour = (event: TouchEvent) => {
      event.stopPropagation()
    }

    scroll.addEventListener('touchstart', handleEventBehaviour)

    return () => {
      scroll.removeEventListener('touchstart', handleEventBehaviour)
    }
  }, [wrapperRef])

  useEffect(() => {
    /**
     * Allow the user to hold and move the scrollbar outside of its
     * hit area.
     *
     * We only allow this after the user clicks and holds (pressed value)
     */
    const wrapper = wrapperRef.current
    if (!wrapper || !pressed) {
      return
    }

    const handlePointerMove = (event: PointerEvent) => {
      const pointerXPositionDiff =
        originalPointerXPosition.current - event.screenX

      wrapper.scrollLeft = originalScrollLeft.current - pointerXPositionDiff
      setScrollLeft(getScrollRatio(wrapper))
    }

    window.addEventListener('pointermove', handlePointerMove)

    return () => {
      window.removeEventListener('pointermove', handlePointerMove)
    }
  }, [wrapperRef, pressed])

  useEffect(() => {
    /**
     * Because we allow the user to still scroll after moving away
     * of the scrollbar hit area, we need to listen to when it
     * releases. So we can reset the pressed value
     */
    const wrapper = wrapperRef.current
    if (!wrapper || !pressed) {
      return
    }

    const handlePointerUp = () => {
      setPressed(false)
    }

    window.addEventListener('pointerup', handlePointerUp)

    return () => {
      window.removeEventListener('pointerup', handlePointerUp)
    }
  }, [wrapperRef, pressed])

  const width = `${Math.round(ratio)}%`
  const leftTransform = Math.round(scrollLeft / (ratio / 100))
  const transform = `translate3d(${leftTransform}%, 0, 0)`

  /* If the ratio is 100(%) it means we should hide scrollbar, no overflow happening */
  const opacity = ratio === 100 ? 0 : 1

  return (
    <ScrollbarTrackWrapper
      ref={scrollbarRef}
      onPointerDown={event => {
        originalPointerXPosition.current = event.screenX
        originalScrollLeft.current = wrapperRef.current?.scrollLeft || 0

        setPressed(true)
      }}
    >
      <ScrollbarTrack
        $pressed={pressed}
        style={{ width, transform, opacity }}
      />
    </ScrollbarTrackWrapper>
  )
}

export interface PublicationSliderProps {
  analyticsId: string
  className?: string
  items: PublicationItemFragment[]
}

const PublicationSlider = (props: PublicationSliderProps) => {
  const { className, items, analyticsId } = props
  const wrapperRef = useRef<HTMLDivElement>(null)

  // Analytics
  const { ref } = useTrackEventInView(`COMMUNITY - Slider`, {
    type: 'load',
    target: analyticsId,
  })

  /**
   * To make sure the natural scroll events from "ScrollbarWrapper" pass to
   * "Scrollbar" we had to move the items to a inner container ("SliderWrapper")
   *
   * Given that "SliderWrapper" has flex rules that prevented the "Scrollbar" to show properly
   *
   * The natural scroll events that this changed allow are.
   * - wheel event when the mouse cursor is on top of the scrollbar
   * - arrow key clicks when the scrollbar has been previously focused
   */
  return (
    <ScrollbarWrapper className={className} ref={wrapperRef}>
      <SliderWrapper data-testid="publication-slider" ref={ref}>
        {items.map(publication => (
          <GridPublicationItem
            key={publication.identifier}
            publication={publication}
            url={routes.WORKSPACE_PROFILE_DOCUMENT.create({
              publicationId: publication.identifier,
              shortUrlName: publication.workspaceProfile!.shortUrlName,
            })}
            showProfile
          />
        ))}
      </SliderWrapper>
      <Scrollbar wrapperRef={wrapperRef} itemsLength={items.length} />
    </ScrollbarWrapper>
  )
}

export default PublicationSlider
