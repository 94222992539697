import React from 'react'

export interface TabPanelProps {
  selectedTabIndex?: number
  children: React.ReactNode
}

export const TabPanel = ({ children }: TabPanelProps) => <>{children}</>

TabPanel.tabType = 'TabPanel'
