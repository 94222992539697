import {
  isTrashRoute,
  isProjectTrashRoute,
  isDraftsRoute,
  isWorkspaceRoute,
  isSharedWithMeRoute,
  isLibrariesRoute,
  isTemplatesRoute,
  RouteParams,
} from '@sketch/modules-common'

import {
  getCachedCollection,
  getCachedProject,
} from '../../../projects/operations/useProjectSubscriptions/utils'
import { DataProxy } from 'apollo-cache'

export const getProjectTitle = (
  params: RouteParams<'WORKSPACE_PROJECT'>,
  cache: DataProxy
) => {
  const projectId = params.projectId

  const project = getCachedProject({
    cache,
    identifier: projectId,
  })

  return project?.name
}

export const getCollectionTitle = (
  params: RouteParams<'WORKSPACE_COLLECTION'>,
  cache: DataProxy
) => {
  const collectionId = params.collectionId

  const collection = getCachedCollection({
    cache,
    identifier: collectionId,
  })

  return collection?.name
}

export const getSectionTitle = (path: string): string | null => {
  if (isWorkspaceRoute(path)) {
    return 'All Documents'
  }

  if (isSharedWithMeRoute(path)) {
    return 'Shared with Me'
  }

  if (isLibrariesRoute(path)) {
    return 'Libraries'
  }

  if (isTemplatesRoute(path)) {
    return 'Templates'
  }

  if (isDraftsRoute(path)) {
    return 'My Drafts'
  }

  if (isTrashRoute(path) || isProjectTrashRoute(path)) {
    return 'Trash'
  }

  return null
}
