import { useContext, useCallback, useMemo } from 'react'
import {
  ShareVersionContext,
  VersioningContext,
  VersioningContextReady,
} from './ShareVersionContext'
import {
  getVersionedRoute,
  VersionedRoutes,
  RouteParam,
} from '@sketch/modules-common'

interface GetVersionPathnameProps<Key extends keyof VersionedRoutes> {
  versionShortId: string | null
  isViewingLatestVersion: boolean
  routeKey: Key
  routeParams: RouteParam<Key>
}

export interface GetPathnameProps<Key extends keyof VersionedRoutes>
  extends Pick<GetVersionPathnameProps<Key>, 'routeKey' | 'routeParams'> {}

const getVersionedPathname = <Key extends keyof VersionedRoutes>({
  versionShortId,
  isViewingLatestVersion,
  routeKey,
  routeParams: params,
}: GetVersionPathnameProps<Key>) => {
  const route = getVersionedRoute(routeKey, isViewingLatestVersion)

  const routeParams =
    isViewingLatestVersion || !versionShortId
      ? params || {}
      : { ...(params || {}), versionShortId }

  return route.create(routeParams)
}

const isVersionContextReady = (
  value: VersioningContext
): value is VersioningContextReady => !value.loading

export const useVersioning = () => {
  const context = useContext(ShareVersionContext)!

  const {
    isViewingLatestVersion,
    versionShortId,
    share,
    latestVersion,
  } = context

  const getPathname = useCallback(
    <Key extends keyof VersionedRoutes>(props: GetPathnameProps<Key>) =>
      getVersionedPathname({
        ...props,
        isViewingLatestVersion,
        versionShortId,
      }),
    [isViewingLatestVersion, versionShortId]
  )

  const versionIdentifier = useMemo(
    () =>
      isVersionContextReady(context)
        ? context.currentVersion.identifier
        : undefined,
    /*
      Since react uses the `Object.is` algorithm, object dependencies would need to
      refer to the same object in memory. Using `currentVersion.identifier` instead.
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [(context as VersioningContextReady)?.currentVersion?.identifier]
  )

  return {
    ...context,
    versionIdentifier,
    getPathname,
    isLibrary: share.type === 'LIBRARY',
    latestVersion,
  }
}
