import React, { ComponentType, useContext } from 'react'

export interface ModalContextProps {
  hideModal: () => void
}

// This controls if we will close the modal when the routes
// change in the background. By default is true
export interface OptionInjectedProps {
  closeOnRouteChange?: boolean
}

export interface ModalInjectedProps extends ModalContextProps {
  isModalOpen?: boolean
  cancelOnClickOutside?: boolean
  /**
   * @deprecated - use hideModal
   */
  onCancel?: never
}

export type ShowModal = <T extends ModalInjectedProps>(
  modal: ComponentType<T>,
  props?: OmitSafe<T, keyof ModalInjectedProps>,
  options?: OptionInjectedProps
) => void

export interface ModalContextValues extends ModalContextProps {
  showModal: ShowModal
  showRestrictedModal: ShowModal
  modal?: React.ComponentType<any>
  isModalOpen: boolean
}

export const ModalContext = React.createContext<ModalContextValues>({
  isModalOpen: false,
  showModal: () => {},
  showRestrictedModal: () => {},
  hideModal: () => {},
})

export const useModalContext = () => useContext(ModalContext)
