import React from 'react'

import { castError, useStableHandler } from '@sketch/utils'
import { ErrorHandler } from '@sketch/tracing'

import { useToast } from '@sketch/toasts'

import { useToggleResolveAnnotation } from '../../hooks'

import { Tooltip } from '@sketch/components'

import Selector from '../Selector'

import {
  ResolveAnnotationIconMobile,
  UnResolveAnnotationIconMobile,
  ResolveAnnotationIconDesktop,
  UnResolveAnnotationIconDesktop,
} from './ResolveAnnotationButton.styles'

const BUTTON_CONTENT = {
  active: {
    toastSuccessMessage: 'Comment and replies resolved',
    toastErrorMessage: 'An error occured while trying to resolve the comment.',
    tooltipContent: 'Resolve',
    accessibleName: 'Resolve Annotation',
    icon: {
      desktop: ResolveAnnotationIconDesktop,
      mobile: ResolveAnnotationIconMobile,
    },
  },
  resolved: {
    toastSuccessMessage: 'Comment and replies reopened',
    toastErrorMessage: 'An error occured while trying to reopen the comment',
    tooltipContent: 'Reopen',
    accessibleName: 'Reopen Annotation',
    icon: {
      desktop: UnResolveAnnotationIconDesktop,
      mobile: UnResolveAnnotationIconMobile,
    },
  },
}

interface ResolveAnnotationButtonProps {
  annotationIdentifier: string
  isResolved: boolean
  isModalVariant?: boolean
}

const ResolveAnnotationButton: React.FC<ResolveAnnotationButtonProps> = props => {
  const { annotationIdentifier, isResolved, isModalVariant } = props
  const { showToast } = useToast()

  const toggleResolveAnnotation = useToggleResolveAnnotation()

  const {
    accessibleName,
    toastSuccessMessage,
    toastErrorMessage,
    tooltipContent,
    icon,
  } = BUTTON_CONTENT[isResolved ? 'resolved' : 'active']

  const ResolutionStatusIcon = icon[isModalVariant ? 'mobile' : 'desktop']

  const handleToggleResolve = useStableHandler(async () => {
    try {
      await toggleResolveAnnotation(annotationIdentifier)
      showToast(toastSuccessMessage, 'positive')
    } catch (err) {
      ErrorHandler.ignore(castError(err))
      showToast(toastErrorMessage, 'negative')
    }
  })

  return (
    <Tooltip
      content={<>{tooltipContent}</>}
      disableWhenTouchDevice
      placement="bottom"
    >
      <Selector data-testid="resolve-annotation" onClick={handleToggleResolve}>
        <ResolutionStatusIcon role="img" />
        <span className="sr-only">{accessibleName}</span>
      </Selector>
    </Tooltip>
  )
}

export default ResolveAnnotationButton
