import React from 'react'

import { SidebarEmpty } from '@sketch/components'
import { GroupType } from '../../utils'

import { ReactComponent as ColorFan } from '@sketch/icons/color-fan-64'
import { ReactComponent as PainBrush } from '@sketch/icons/paint-brush-64'
import { ReactComponent as TextFormat } from '@sketch/icons/text-format-64'

type AllowedGroupTypes = Exclude<GroupType, 'SHARE_VIEW' | 'SYMBOL'>

const TITLE_BY_GROUP_TYPE: Record<AllowedGroupTypes, string> = {
  COLOR_VARIABLE: 'No Color Variable selected',
  LAYER_STYLE: 'No Layer Style selected',
  TEXT_STYLE: 'No Text Styles selected',
}

const DESCRIPTION_BY_GROUP_TYPE: Record<AllowedGroupTypes, string> = {
  COLOR_VARIABLE: 'Select a Color Variable to inspect it.',
  LAYER_STYLE: 'Select a Layer Style to inspect it.',
  TEXT_STYLE: 'Select a text style to inspect it.',
}

const ICON_BY_GROUP_TYPE: Record<AllowedGroupTypes, React.ComponentType> = {
  COLOR_VARIABLE: ColorFan,
  LAYER_STYLE: PainBrush,
  TEXT_STYLE: TextFormat,
}

interface UnselectedPlaceholderProps {
  groupType: GroupType
}

const UnselectedPlaceholder = (props: UnselectedPlaceholderProps) => {
  const { groupType } = props

  if (groupType === 'SHARE_VIEW' || groupType === 'SYMBOL') {
    return <SidebarEmpty title="Please select a component to inspect" />
  }

  return (
    <SidebarEmpty
      title={TITLE_BY_GROUP_TYPE[groupType]}
      description={DESCRIPTION_BY_GROUP_TYPE[groupType]}
      icon={ICON_BY_GROUP_TYPE[groupType]}
    />
  )
}

export default UnselectedPlaceholder
