import styled from 'styled-components'
import { Text } from '@sketch/components'

export const DocumentDescription = styled(Text.Div).attrs({
  textStyle: 'copy.tertiary.standard.D',
})`
  padding: 0;
  overflow-wrap: break-word;
  line-height: 1.462; /* stylelint-disable-line scales/line-height */

  white-space: break-spaces;

  margin: 0;
`
export const DocumentDescriptionWrapper = styled.div`
  margin-bottom: 12px;
`
