import { useEffect, useState } from 'react'
import { isEqualWith } from 'lodash'

import {
  PrototypeLayer,
  useGetScreen,
  usePrototypeEvent,
  usePrototypeStatus,
} from '@sketch-hq/sketch-web-renderer'

export type ExtendedPrototypeLayer = {
  id: string
  scrollPosition: { x: number; y: number }

  /* This value will be the artboard size limited to the Prototype screen (if scrollable)*/
  bounds: PrototypeLayer['bounds']
  /* This value will represent the artboard actual size (screen + scrollable area) (if scrollable) */
  boundsWithScroll: PrototypeLayer['bounds']
  /* If the artboard is not scrollable "bounds" and "boundsWithScroll" should have the same value */
}

type PrototypeLayerArrayOrItem =
  | ExtendedPrototypeLayer[]
  | ExtendedPrototypeLayer

const comparePrototypeLayers = (
  a: PrototypeLayerArrayOrItem,
  b: PrototypeLayerArrayOrItem
) => {
  if (Array.isArray(a) || Array.isArray(b)) {
    return
  }

  const aBounds = a.bounds
  const bBounds = b.bounds

  /**
   * We are comparing the id and the item bounds
   * because they might change on resize or when entering the artboard mode
   * changing the position of the overlay
   */
  return (
    a.id === b.id &&
    aBounds.x === bBounds.x &&
    aBounds.y === bBounds.y &&
    aBounds.width === bBounds.width &&
    aBounds.height === bBounds.height &&
    a.scrollPosition.x === b.scrollPosition.x &&
    b.scrollPosition.x === b.scrollPosition.y
  )
}

const formatLayerItem = (layer: PrototypeLayer) => {
  const node = layer.getNode()! // The node should never be null here. If it is, then better to throw an error
  const scrollPosition = node.getScrollPosition()
  const scrollBounds = node.getScrollBounds()
  const bounds = layer.bounds

  return {
    id: layer.id,
    bounds: bounds,
    boundsWithScroll: {
      /**
       * We translate the artboard position
       * depending on scroll position
       */
      x: bounds.x - scrollPosition.x,
      y: bounds.y - scrollPosition.y,
      width: bounds.width + scrollBounds.width,
      height: bounds.height + scrollBounds.height,
    },
    scrollPosition: { x: scrollPosition.x, y: scrollPosition.y },
  }
}

export const useGetPrototypeArtboards = () => {
  const getScreen = useGetScreen()
  const prototypeStatus = usePrototypeStatus()

  const [artboards, setArtboards] = useState(
    getScreen(false)?.layers.map(formatLayerItem) || []
  )

  usePrototypeEvent('sceneChange', () => {
    const artboards = getScreen(false)?.layers.map(formatLayerItem) || []

    setArtboards(previousArtboards => {
      if (isEqualWith(previousArtboards, artboards, comparePrototypeLayers)) {
        return previousArtboards
      }

      return artboards
    })
  })

  useEffect(() => {
    if (prototypeStatus.type === 'READY') {
      setArtboards(getScreen(false)?.layers.map(formatLayerItem) || [])
    }
  }, [getScreen, prototypeStatus])

  return artboards
}
