import styled from 'styled-components'

import { ReactComponent as CoffeeCupIconUnstyled } from '@sketch/icons/coffee-cup-24'

export const Button = styled.button`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 8px;
  margin: 7px auto 7px; /* stylelint-disable-line scales/space */
  font-size: ${({ theme }) => theme.fontSizes.E};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  background: ${({ theme }) => theme.colors.background.secondary.A};
  cursor: pointer;

  border-radius: 5px;

  border: 1px solid ${({ theme }) => theme.colors.border.A};
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.colors.shadow.outer};
`

export const CoffeeCupIcon = styled(CoffeeCupIconUnstyled)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`
