import React, { ReactNode } from 'react'

import { Container, Title, Description } from './Callout.styles'

export interface CalloutProps extends React.ComponentPropsWithoutRef<'div'> {
  title: string
  description: ReactNode
  children?: ReactNode
}

export const Callout = ({
  title,
  description,
  children,
  ...props
}: CalloutProps) => {
  return (
    <Container {...props}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {children}
    </Container>
  )
}
