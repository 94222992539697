import { stringify } from 'query-string'

type MaybeUndefined<T> = T | undefined

type QueryParameters = {
  annotation?: MaybeUndefined<string>
  annotationStatus?: MaybeUndefined<string>
}

export const addAnnotationToQueryParams = (
  queryParams: QueryParameters,
  annotation?: string
) => stringify({ ...queryParams, annotation })

export const removeAnnotationFromQueryParams = (queryParams: QueryParameters) =>
  addAnnotationToQueryParams(queryParams, undefined)

export const addAnnotationStatusToQueryParams = (
  queryParams: QueryParameters,
  annotationStatus?: string
) => stringify({ ...queryParams, annotationStatus })
