import React from 'react'

import { ReactComponent as CheckMarkIcon } from '@sketch/icons/checkmark-16'
import { SKETCH_WEBSITE } from '@sketch/env-config'

import { Button, Modal, ModalInjectedProps, Text } from '@sketch/components'

import {
  StyledLinkButton,
  StyledIconBulletedList,
  BulletedList,
  BulletedListItem,
} from './WorkspaceSettingsUpsell.styles'

interface UpsellBannerModalProps extends ModalInjectedProps {
  modalBodyText: string
  modalHeaderText: string
}

const modalHeaderText = 'Upgrade Sketch to Scale with Your Business'
const modalBodyText1 =
  'Single Sign-On (SSO) for seamless and secure Member access'
const modalBodyText2 =
  'More powerful access control over documents and projects'
const modalBodyText3 = 'Flexible payment options or terms'
const modalBodyText4 = 'Dedicated support if you have 25 or more Editors'

const BulletedText: React.FC = ({ children }) => {
  return (
    <BulletedListItem>
      <StyledIconBulletedList as={CheckMarkIcon} />
      <Text pb={3} width="100%">
        {children}
      </Text>
    </BulletedListItem>
  )
}

const WorkspaceSettingsUpsellModal: React.FC<UpsellBannerModalProps> = ({
  hideModal,
}) => (
  <Modal title={modalHeaderText} onCancel={hideModal} cancelOnClickOutside>
    <Modal.Body>
      <Text pb={3} width="100%">
        With our Business Plan you gain:
      </Text>
      <BulletedList>
        <BulletedText>{modalBodyText1}</BulletedText>
        <BulletedText>{modalBodyText2}</BulletedText>
        <BulletedText>{modalBodyText3}</BulletedText>
        <BulletedText>{modalBodyText4}</BulletedText>
      </BulletedList>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={hideModal}>
        Maybe Later
      </Button>
      <StyledLinkButton
        variant="primary"
        href={`${SKETCH_WEBSITE}/support/contact/`}
        external
      >
        Get in Touch
      </StyledLinkButton>
    </Modal.Footer>
  </Modal>
)

export { WorkspaceSettingsUpsellModal }
