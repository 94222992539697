import { useMemo } from 'react'

import { handleFetchMore } from '@sketch/components'
import { dataIdFromObject } from '@sketch/graphql-cache'
import {
  ProjectGroupMembershipFragment,
  ProjectMembershipFragment,
  useGetProjectMembershipsQuery,
} from '@sketch/gql-types'

const EMPTY_ARRAY = [] as const
const ENTRIES_PATH = ['project', 'members', 'entries']

const useProjectMemberships = (projectIdentifier: string) => {
  const { data, loading, error, fetchMore } = useGetProjectMembershipsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { projectIdentifier },
  })

  const memberships = data?.project?.members?.entries || EMPTY_ARRAY

  const after =
    data?.project?.members.meta.totalCount === memberships.length
      ? null
      : data?.project?.members.meta.after

  const { guests, members } = useMemo(() => {
    const guests: ProjectMembershipFragment[] = []
    const members: (
      | ProjectMembershipFragment
      | ProjectGroupMembershipFragment
    )[] = []

    memberships.forEach(member => {
      if (
        member.__typename === 'ProjectMembership' &&
        member.workspaceMembership?.role === 'GUEST'
      ) {
        guests.push(member)
      } else {
        members.push(member)
      }
    })

    return { guests, members }
  }, [memberships])

  const loadNewPage = handleFetchMore(fetchMore, ENTRIES_PATH, {
    dataIdFromObject,
    after,
  })

  return {
    loading,
    error,
    after,
    guestsCount: guests.length,
    guests,
    members,
    memberships,
    loadNewPage,
  }
}

export default useProjectMemberships
