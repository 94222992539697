import { useToast } from '@sketch/toasts'
import { ShareInfoFragment } from '@sketch/gql-types'
import { useShareRestore } from '../../operations'
import React from 'react'
import { Link } from '@sketch/components'
import { routes } from '@sketch/modules-common'

export interface UseShareRestoreWithUiProps {
  identifier: string
  onCompleted?: (share: ShareInfoFragment) => void
  onError?: (message: string) => void
}
export const useShareRestoreWithUi = (props: UseShareRestoreWithUiProps) => {
  const { identifier, onCompleted, onError } = props
  const { showToast } = useToast()
  return useShareRestore({
    identifier,
    onError: message => {
      showToast(message, 'negative')
      onError?.(message)
    },
    onCompleted: share => {
      showToast(
        <>
          &quot;
          <Link to={routes.SHARE_VIEW.create({ shareID: share.identifier })}>
            {share.name}
          </Link>
          &quot; has been restored
        </>
      )
      onCompleted?.(share)
    },
  })
}
