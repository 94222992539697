import { DataProxy } from 'apollo-cache'
import produce, { Draft } from 'immer'
import { v1 as uuid } from 'uuid'

import {
  errorPreventiveCacheRead,
  errorPreventiveFragmentRead,
} from '@sketch/modules-common'

import { dataIdFromObject } from '@sketch/graphql-cache'
import {
  AnnotationArtboardInfoFragment,
  AnnotationArtboardInfoFragmentDoc,
  AnnotationDotFragment,
  AnnotationDotFragmentDoc,
  AnnotationPageInfoFragment,
  AnnotationPageInfoFragmentDoc,
  AnnotationResolutionInfoFragment,
  AnnotationResolutionInfoFragmentDoc,
  AnnotationSubjectFragment,
  AnnotationSubjectFragmentDoc,
  BaseAnnotationFragment,
  BaseAnnotationFragmentDoc,
  GetAnnotationCommentsDocument,
  GetAnnotationCommentsQuery,
  GetAnnotationCommentsQueryVariables,
  GetAnnotationDotsDocument,
  GetAnnotationDotsQuery,
  GetAnnotationDotsQueryVariables,
  GetAnnotationsDocument,
  GetAnnotationsQuery,
  GetAnnotationsQueryVariables,
} from '@sketch/gql-types'

import {
  AnnotationArtboardSubject,
  CacheAnnotationSubjectInput,
  CacheAnnotationVariablesSubjectUpdater,
  AnnotationSubjectInput,
  AnnotationNonArtboardSubject,
} from '../types'

const TYPENAME_BY_COMPONENT_TYPE = {
  COLOR_VARIABLE: 'ColorVariable',
  LAYER_STYLE: 'LayerStyle',
  TEXT_STYLE: 'TextStyle',
} as const

export const getAnnotationDot = (apollo: DataProxy, identifier: string) =>
  errorPreventiveFragmentRead<AnnotationDotFragment>(apollo, {
    fragment: AnnotationDotFragmentDoc,
    fragmentName: 'AnnotationDot',
    id: dataIdFromObject({ __typename: 'Annotation', identifier })!,
  })

export const getBaseAnnotation = (apollo: DataProxy, identifier: string) =>
  errorPreventiveFragmentRead<BaseAnnotationFragment>(apollo, {
    fragment: BaseAnnotationFragmentDoc,
    fragmentName: 'BaseAnnotation',
    id: dataIdFromObject({ __typename: 'Annotation', identifier })!,
  })

export const getAnnotationSubject = (apollo: DataProxy, identifier: string) =>
  errorPreventiveFragmentRead<AnnotationSubjectFragment>(apollo, {
    fragment: AnnotationSubjectFragmentDoc,
    fragmentName: 'AnnotationSubject',
    id: dataIdFromObject({ __typename: 'Annotation', identifier })!,
  })

export const getAnnotationResolution = (
  apollo: DataProxy,
  identifier: string
) =>
  errorPreventiveFragmentRead<AnnotationResolutionInfoFragment>(apollo, {
    fragment: AnnotationResolutionInfoFragmentDoc,
    fragmentName: 'AnnotationResolutionInfo',
    id: dataIdFromObject({ __typename: 'Annotation', identifier })!,
  })

export const getCommentsQuery = (
  apollo: DataProxy,
  variables: GetAnnotationCommentsQueryVariables
) => {
  return errorPreventiveCacheRead<
    GetAnnotationCommentsQuery,
    GetAnnotationCommentsQueryVariables
  >(apollo, {
    query: GetAnnotationCommentsDocument,
    variables,
  })
}

export const getAnnotationsQuery = (
  apollo: DataProxy,
  variables: GetAnnotationsQueryVariables
) => {
  return errorPreventiveCacheRead<
    GetAnnotationsQuery,
    GetAnnotationsQueryVariables
  >(apollo, {
    query: GetAnnotationsDocument,
    variables,
  })
}

export const getAnnotationDotsQuery = (
  apollo: DataProxy,
  variables: GetAnnotationDotsQueryVariables
) => {
  return errorPreventiveCacheRead<
    GetAnnotationDotsQuery,
    GetAnnotationDotsQueryVariables
  >(apollo, {
    query: GetAnnotationDotsDocument,
    variables,
  })
}

export const updateCommentsQuery = (
  apollo: DataProxy,
  variables: GetAnnotationCommentsQueryVariables,
  updater: (draft: Draft<GetAnnotationCommentsQuery>) => void
) => {
  const query = getCommentsQuery(apollo, variables)

  if (!query) {
    /**
     * If the query doesn't exist it doesn't make
     * sense to update it
     */
    return
  }

  apollo.writeQuery<
    GetAnnotationCommentsQuery,
    GetAnnotationCommentsQueryVariables
  >({
    query: GetAnnotationCommentsDocument,
    variables,
    data: produce(query, updater),
  })
}

export const updateAnnotationsQuery = (
  apollo: DataProxy,
  variables: GetAnnotationsQueryVariables,
  updater: (draft: Draft<GetAnnotationsQuery>) => void
) => {
  const query = getAnnotationsQuery(apollo, variables)

  if (!query) {
    /**
     * If the query doesn't exist it doesn't make
     * sense to update it
     */
    return
  }

  apollo.writeQuery<GetAnnotationsQuery, GetAnnotationsQueryVariables>({
    query: GetAnnotationsDocument,
    variables,
    data: produce(query, updater),
  })
}

export const updateAnnotationsDotsQuery = (
  apollo: DataProxy,
  variables: GetAnnotationDotsQueryVariables,
  updater: (draft: Draft<GetAnnotationDotsQuery>) => void
) => {
  const query = getAnnotationDotsQuery(apollo, variables)

  if (!query) {
    /**
     * If the query doesn't exist it doesn't make
     * sense to update it
     */
    return
  }

  apollo.writeQuery<GetAnnotationDotsQuery, GetAnnotationDotsQueryVariables>({
    query: GetAnnotationDotsDocument,
    variables,
    data: produce(query, updater),
  })
}

export const updateMultipleAnnotationQueries = (
  apollo: DataProxy,
  variables: CacheAnnotationVariablesSubjectUpdater<GetAnnotationsQueryVariables>,
  updater: (draft: Draft<GetAnnotationsQuery>) => void
) => {
  const { subject, ...otherVariables } = variables

  if (!subject) {
    return
  }

  if (subject.type === 'ARTBOARD') {
    const artboardSubject = {
      type: 'ARTBOARD' as const,
      permanentId: subject.permanentId,
    }

    updateAnnotationsQuery(
      apollo,
      { ...otherVariables, subject: artboardSubject },
      updater
    )

    const pageSubject = {
      type: 'PAGE' as const,
      permanentId: subject.permanentPageId,
    }

    updateAnnotationsQuery(
      apollo,
      { ...otherVariables, subject: pageSubject },
      updater
    )
  } else {
    updateAnnotationsQuery(apollo, { ...otherVariables, subject }, updater)
  }
}

export const updateMultipleAnnotationDotQueries = (
  apollo: DataProxy,
  variables: CacheAnnotationVariablesSubjectUpdater<GetAnnotationsQueryVariables>,
  updater: (draft: Draft<GetAnnotationDotsQuery>) => void
) => {
  const { subject, ...otherVariables } = variables

  if (!subject) {
    return
  }

  if (subject.type === 'ARTBOARD') {
    const artboardSubject = {
      type: 'ARTBOARD' as const,
      permanentId: subject.permanentId,
    }

    updateAnnotationsDotsQuery(
      apollo,
      { ...otherVariables, subject: artboardSubject },
      updater
    )

    const pageSubject = {
      type: 'PAGE' as const,
      permanentId: subject.permanentPageId,
    }

    updateAnnotationsDotsQuery(
      apollo,
      { ...otherVariables, subject: pageSubject },
      updater
    )
  } else if (subject.type === 'PAGE') {
    updateAnnotationsDotsQuery(apollo, { ...otherVariables, subject }, updater)
  }
}

export const updateAnnotationsInboxOrder = (
  apollo: DataProxy,
  variables: GetAnnotationsQueryVariables,
  annotation: BaseAnnotationFragment
) => {
  updateAnnotationsQuery(apollo, variables, data => {
    const { annotations } = data

    // Find the annotation existence on the array
    const latestCommentCreatedDate = new Date(annotation.latestCommentCreatedAt)

    // Remove the current item from the array
    const initialAnnotationEntriesCount = annotations.entries.length
    annotations.entries = annotations.entries.filter(
      ({ identifier }) => annotation.identifier !== identifier
    )

    let annotationNextPosition = 0
    for (
      ;
      annotationNextPosition < annotations.entries.length;
      annotationNextPosition++
    ) {
      const indexAnnotation = annotations.entries[annotationNextPosition]

      /**
       * The next validations basically make sure the order of the list
       * is the expected one. We prioritise (by order)
       * - Annotation with hasNewComments or new Annotations
       * - last comment created earliest
       */

      const annotationNew = annotation.isNew || annotation.hasNewComments
      const indexAnnotationNew =
        indexAnnotation.isNew || indexAnnotation.hasNewComments

      const sortedByLastComment =
        new Date(indexAnnotation.latestCommentCreatedAt) >
        latestCommentCreatedDate

      if (annotationNew && !indexAnnotationNew) {
        break
      } else if (indexAnnotationNew && annotationNew) {
        if (!sortedByLastComment) {
          break
        }
      } else if (!indexAnnotationNew && !annotationNew) {
        if (!sortedByLastComment) {
          break
        }
      }
    }

    // Re-add the item to the array
    annotations.entries.splice(annotationNextPosition, 0, annotation)

    // Check if the entries count increased and adjust the totalCount
    if (initialAnnotationEntriesCount < annotations.entries.length) {
      annotations.meta.totalCount++
    }
  })
}

export const updateAnnotationsAllOrder = (
  apollo: DataProxy,
  variables: GetAnnotationsQueryVariables,
  annotation: BaseAnnotationFragment
) => {
  updateAnnotationsQuery(apollo, variables, data => {
    const { annotations } = data

    // Find the annotation existence on the array
    const latestCommentCreatedDate = new Date(annotation.latestCommentCreatedAt)

    // Remove the current item from the array
    const initialAnnotationEntriesCount = annotations.entries.length
    annotations.entries = annotations.entries.filter(
      ({ identifier }) => annotation.identifier !== identifier
    )

    let annotationNextPosition = 0
    for (
      ;
      annotationNextPosition < annotations.entries.length;
      annotationNextPosition++
    ) {
      const indexAnnotation = annotations.entries[annotationNextPosition]

      const sortedByLastComment =
        new Date(indexAnnotation.latestCommentCreatedAt) >
        latestCommentCreatedDate

      if (!sortedByLastComment) {
        break
      }
    }

    // Re-add the item to the array
    annotations.entries.splice(annotationNextPosition, 0, annotation)

    // Check if the entries count increased and adjust the totalCount
    if (initialAnnotationEntriesCount < annotations.entries.length) {
      annotations.meta.totalCount++
    }
  })
}

export const getCacheInputFromAnnotationSubject = (
  annotation: AnnotationSubjectFragment
): CacheAnnotationSubjectInput | null => {
  if (annotation.currentSubject.__typename === 'UnprocessedSubject') {
    return null
  } else if (annotation.currentSubject.__typename === 'Artboard') {
    return {
      type: 'ARTBOARD',
      permanentId: annotation.currentSubject.uuid,
      permanentPageId: annotation.currentSubject.page?.uuid || '',
    }
  } else if (annotation.currentSubject.__typename === 'Page') {
    return {
      type: 'PAGE',
      permanentId: annotation.currentSubject.pageUUID || '',
    }
  } else {
    return {
      type: 'COMPONENT',
      permanentId: annotation.currentSubject.uuid,
    }
  }
}

export const getOptimisticArtboardSubjectFromInputSubject = (
  subject: AnnotationArtboardSubject,
  permanentPageId: string
) => {
  return {
    __typename: 'Artboard',
    unreadCount: 0,
    annotationCount: 1,
    subscriptionStatus: 'ON',
    documentVersionShortId: uuid(),
    permanentArtboardShortId: uuid(),
    uuid: subject.permanentId,
    page: {
      __typename: 'Page',
      hasUnreadComments: false,
      identifier: uuid(),
      uuid: permanentPageId,
      pageUUID: permanentPageId,
    },
  } as const
}

export const getOptimisticSubjectFromInputSubject = (
  subject: AnnotationNonArtboardSubject
) => {
  if (subject.type === 'PAGE') {
    return {
      __typename: 'Page',
      hasUnreadComments: false,
      identifier: uuid(),
      pageUUID: subject.permanentId,
    } as const
  }

  return {
    __typename: TYPENAME_BY_COMPONENT_TYPE[subject.componentType!],
    identifier: uuid(),
    uuid: subject.permanentId,
  }
}

const getAnnotationSubjectUUID = ({
  currentSubject,
}: AnnotationSubjectFragment) => {
  if (currentSubject.__typename === 'UnprocessedSubject') {
    return
  } else if (currentSubject.__typename === 'Page') {
    return currentSubject.pageUUID
  } else {
    return currentSubject.uuid
  }
}

export const isSameAnnotationSubject = (
  annotationA: AnnotationSubjectFragment,
  annotationB: AnnotationSubjectFragment
) =>
  getAnnotationSubjectUUID(annotationA) ===
  getAnnotationSubjectUUID(annotationB)

export const getAnnotationArtboardInfo = (
  cache: DataProxy,
  documentVersionShortId: string,
  permanentArtboardShortId: string
) => {
  const artboardId = dataIdFromObject({
    __typename: 'Artboard',
    documentVersionShortId,
    permanentArtboardShortId,
  })!

  return errorPreventiveFragmentRead<AnnotationArtboardInfoFragment>(cache, {
    fragment: AnnotationArtboardInfoFragmentDoc,
    fragmentName: 'AnnotationArtboardInfo',
    id: artboardId,
  })
}

export const getAnnotationPageInfo = (cache: DataProxy, identifier: string) => {
  const pageId = dataIdFromObject({
    __typename: 'Page',
    identifier,
  })!

  return errorPreventiveFragmentRead<AnnotationPageInfoFragment>(cache, {
    fragment: AnnotationPageInfoFragmentDoc,
    fragmentName: 'AnnotationPageInfo',
    id: pageId,
  })
}

export const isArtboardAnnotationSubjectInput = (
  subject: AnnotationSubjectInput
): subject is AnnotationArtboardSubject => subject.type === 'ARTBOARD'
