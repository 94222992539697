export type Values = {
  name: string
  password: string
  email: string
  'terms-privacy-policy': boolean
  'marketing-consent': boolean
}

export const SIGN_UP_INITIAL_VALUES: Values = {
  name: '',
  password: '',
  email: '',
  'terms-privacy-policy': false,
  'marketing-consent': false,
}

export const TERMS_OF_SERVICE_PRIVACY_POLICY_ERROR =
  'You need to agree to the Terms of Service and Privacy Policy to continue'
