import React from 'react'

// GQL
import { BillingHistoryEntryFragment } from '@sketch/gql-types'

import { dateFormat, formatPriceShort } from '@sketch/utils'

import BillingInfoTable from '../BillingInfoTable'
import { LinkButton } from '@sketch/components'

import {
  BillingDescriptionCell,
  Description,
  DiscountCode,
} from './BillingHistoryItem.styles'

interface BillingHistoryItemProps {
  item: BillingHistoryEntryFragment
}

/**
 * BillingHistoryItem
 *
 * Renders each row in the Billing History Panel in Workspace Settings
 */
const BillingHistoryItem: React.FC<BillingHistoryItemProps> = ({ item }) => {
  if (item.__typename === 'CustomerInvoice') {
    const { createdAt, amount, pdf, couponInfo } = item

    const shouldRenderDiscount = !!couponInfo

    return (
      <BillingInfoTable.Row>
        <BillingInfoTable.HeadingCell>
          {dateFormat(new Date(createdAt), 'en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}
        </BillingInfoTable.HeadingCell>
        <BillingDescriptionCell>
          <div>
            Total including tax{' '}
            <strong>
              {formatPriceShort(amount, { trimDecimalIfZero: false })}
            </strong>
          </div>
          {shouldRenderDiscount && (
            <div>
              <DiscountCode>{couponInfo!.promotionCode}</DiscountCode> discount
              applied ({couponInfo!.percentOff}%)
            </div>
          )}
        </BillingDescriptionCell>
        <BillingInfoTable.Cell>
          <LinkButton variant="secondary-untinted" external href={pdf}>
            View Invoice
          </LinkButton>
        </BillingInfoTable.Cell>
      </BillingInfoTable.Row>
    )
  } else if (
    item.__typename === 'CreditTransaction' &&
    item?.licenseRedemption
  ) {
    const { createdAt, amount, licenseRedemption } = item

    return (
      <BillingInfoTable.Row>
        <BillingInfoTable.HeadingCell>
          {dateFormat(new Date(createdAt), 'en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}
        </BillingInfoTable.HeadingCell>
        <BillingDescriptionCell>
          <div>
            Total{' '}
            <strong>
              +{formatPriceShort(amount, { trimDecimalIfZero: false })}
            </strong>{' '}
            credit (redeemed license)
          </div>
          <Description>{licenseRedemption?.serial}</Description>
          <Description>
            $9 x {licenseRedemption.seats} seats x {licenseRedemption.months}{' '}
            months
          </Description>
        </BillingDescriptionCell>
      </BillingInfoTable.Row>
    )
  } else if (item.__typename === 'CreditTransaction') {
    const { createdAt, amount, description } = item

    return (
      <BillingInfoTable.Row>
        <BillingInfoTable.HeadingCell>
          {dateFormat(new Date(createdAt), 'en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}
        </BillingInfoTable.HeadingCell>
        <BillingDescriptionCell>
          <div>
            Total{' '}
            <strong>
              {amount > 0 && '+'}
              {formatPriceShort(amount, { trimDecimalIfZero: false })}
            </strong>{' '}
            credit
          </div>
          <Description>{description}</Description>
        </BillingDescriptionCell>
      </BillingInfoTable.Row>
    )
  }

  return null
}

export default BillingHistoryItem
