import React, { useMemo, useEffect, useRef, useState } from 'react'

import * as yup from 'yup'

import {
  Button,
  Separator,
  Form as BaseForm,
  Input,
  Modal,
  LearnMoreTooltip,
  AccessLevelSelectCheckbox,
} from '@sketch/components'

import {
  useFlag,
  InvitePeopleFieldForm,
  AccessLevelSelect,
  AccessLevelSelectOption,
  AccessLevelSelectSeparator,
  AccessLevelSelectRemoveButton,
  AccessLevelSelectBanner,
  WorkspaceRow,
  useUserProfile,
  useFieldAutocomplete,
  PermissionGroupItem,
} from '@sketch/modules-common'
import { ProjectAccessSelect } from '../../components/ProjectAccessSelect/ProjectAccessSelect'
import { WorkspaceMemberRow } from '../../components/MemberRow'
import {
  ProjectMembershipFragment,
  WorkspaceMembershipFragment,
} from '@sketch/gql-types'
import type {
  CreateProjectWorkspaceAccessLevel,
  FormikRenderProps,
  PendingProjectMember,
  PendingGuestMember,
} from './types'
import {
  MembersList,
  StyledInvitePeopleField,
  StyledInviteButton,
  ListRow,
} from './AddNewProjectModal.styles'
import {
  AddProjectMemberPermissionsAccessSelect,
  ProjectMemberPermissionsAccessSelect,
} from '../../components/ProjectMemberPermissionsAccessSelect/ProjectMemberPermissionsAccessSelect'
import { SKETCH_WEBSITE } from '@sketch/env-config'

/*
TODO: Use the correct types when this components update to Typescript
https://github.com/sketch-hq/Cloud/issues/1690
*/
const Form = BaseForm as any

const emailValidation = yup.string().trim().required().lowercase().email()

export const FormikRender = (props: FormikRenderProps) => {
  // Remove this FF when released
  // https://github.com/sketch-hq/Cloud/issues/18702
  const hasGuestsInProjects = useFlag('guests-in-project')
  const [membersList, setMembersList] = useState<PendingProjectMember[]>([])
  const [guestPermissions, setGuestPermissions] = useState<{
    guestCommentsEnabled: boolean
    guestInspectEnabled: boolean
  }>({
    guestCommentsEnabled: true,
    guestInspectEnabled: true,
  })

  const { formikBag, hideModal, workspace } = props
  const containerRef = useRef<HTMLInputElement>(null)
  const { data: userData } = useUserProfile()
  const workspaceIdentifier = workspace.identifier
  const projectNameInputRef = useRef<HTMLInputElement>(null)

  const currentUser = {
    avatar: userData?.me.avatar,
    name: userData?.me.name,
    identifier: userData?.me.identifier,
    email: userData?.me.email,
  }
  // We need to do this to render the current user membership row, as we don't have the
  // workspace membership of the current user easily accesible.
  //
  // There's no need to add it when submitting the form, this is only visual.
  // BE adds automatically the workspace membership of the project creator
  const normalizedCurrentUser = {
    user: currentUser,
  } as WorkspaceMembershipFragment

  const {
    errors,
    values,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    isValid,
  } = formikBag

  const excludeItems = useMemo(
    () =>
      membersList
        .filter(member => Boolean(member.workspaceMembershipIdentifier))
        .map(member => member.workspaceMembership),
    [membersList]
  )

  const autocomplete = useFieldAutocomplete({
    workspaceIdentifier,
    excludeItems,
    filter: workspace.features.permissionGroupsEnabled
      ? 'Members, Groups and Guests'
      : 'Members and Guests',
  })

  useEffect(() => {
    // We need to manually autofocus the project name input
    // when we open the modal, as autofocus it's not very reliable on this case
    projectNameInputRef.current?.focus()
  }, [])

  const addMember = () => {
    const selectedItem = autocomplete.combobox.selectedItem

    if (selectedItem) {
      const pendingMember: PendingProjectMember = {
        workspaceMembershipIdentifier: selectedItem.identifier,
        workspaceMembership: selectedItem,
        privateAccessLevel:
          selectedItem.__typename === 'WorkspaceMembership' &&
          selectedItem.isEditor
            ? 'EDIT'
            : 'VIEW',
      }
      setMembersList([...membersList, pendingMember])
      autocomplete.combobox.reset()
      return
    }

    if (autocomplete.combobox.inputValue) {
      const pendingGuest: PendingProjectMember = {
        email: autocomplete.combobox.inputValue,
        privateAccessLevel: 'VIEW',
        guestCommentsEnabled: guestPermissions.guestCommentsEnabled,
        guestInspectEnabled: guestPermissions.guestInspectEnabled,
        workspaceMembership: {
          __typename: 'WorkspaceMembership',
          role: 'GUEST',
          user: null,
          identifier: '',
          isEditor: false,
          invite: {
            __typename: 'WorkspaceInvite',
            identifier: '',
            email: autocomplete.combobox.inputValue,
          },
        },
      }
      setMembersList([...membersList, pendingGuest])
      autocomplete.combobox.reset()
      return
    }
  }

  const deleteMember = (index: number) => {
    membersList.splice(index, 1)
    setMembersList([...membersList])
  }

  const setWorkspacePermissions = (
    level: CreateProjectWorkspaceAccessLevel
  ) => {
    setFieldValue('projectWorkspaceAccessLevel', level)
  }

  const cleanMembersList = () => {
    const projectMembersDefaultList: {
      members: string[]
      guests: PendingGuestMember[]
      groups: string[]
    } = {
      members: [],
      guests: [],
      groups: [],
    }

    const projectMembersList = membersList.reduce((list, currentMember) => {
      if (currentMember.workspaceMembership.__typename === 'PermissionGroup') {
        list.groups.push(currentMember.workspaceMembership.identifier)
      }

      // Guests that do NOT belong to the workspace
      if (currentMember.email) {
        list.guests.push({
          email: currentMember.email,
          guestCommentsEnabled: !!currentMember.guestCommentsEnabled!,
          guestInspectEnabled: !!currentMember.guestInspectEnabled!,
        })
      }

      // Guests that belong to the workspace
      if (
        currentMember.workspaceMembership.__typename ===
          'WorkspaceMembership' &&
        currentMember.workspaceMembership.role === 'GUEST' &&
        currentMember.workspaceMembership?.user
      ) {
        list.guests.push({
          email: currentMember.workspaceMembership.user.email,
          guestCommentsEnabled: !!currentMember.guestCommentsEnabled!,
          guestInspectEnabled: !!currentMember.guestInspectEnabled!,
        })
      }

      if (currentMember.workspaceMembershipIdentifier) {
        list.members.push(currentMember.workspaceMembershipIdentifier)
      }

      return list
    }, projectMembersDefaultList)

    setFieldValue('workspaceMembershipIdentifiers', projectMembersList.members)
    setFieldValue(
      'workspacePermissionGroupIdentifiers',
      projectMembersList.groups
    )
    setFieldValue('guestsToInvite', projectMembersList.guests)
  }

  const submitForm = async () => {
    cleanMembersList()
    await handleSubmit()
  }

  const isInputValid =
    autocomplete.isValueSelected ||
    (hasGuestsInProjects &&
      emailValidation.isValidSync(autocomplete.combobox.inputValue))

  return (
    <Modal title="Create a New Project" onCancel={hideModal}>
      <Form
        onSubmit={(e: Event) => {
          e.preventDefault()
          submitForm()
        }}
      >
        <Modal.Body>
          <p>
            Use projects to organize and share Sketch documents.{' '}
            <LearnMoreTooltip
              href={`${SKETCH_WEBSITE}/docs/workspaces/organizing-your-workspace/`}
              tooltipContent="Learn more about using projects"
              tooltipPlacement="top"
            />
          </p>
          <Form.Field
            name="projectName"
            label="Project Name"
            errorText={touched.projectName && errors.projectName}
          >
            <Input
              ref={projectNameInputRef}
              name="projectName"
              type="text"
              placeholder="Give your project a name"
              aria-label="Project name input field"
              value={values.projectName}
              onChange={handleChange}
              disabled={isSubmitting}
            />
          </Form.Field>
          <Separator mt={4} mb={6} />
          <InvitePeopleFieldForm>
            <StyledInvitePeopleField
              label="Manage Access"
              aria-label="Invite people input field"
              containerRef={containerRef}
              autocomplete={autocomplete}
              placeholder="Add a name or email address"
              dynamicAction={props => {
                const workspaceMembership = props.selectedItem as Partial<WorkspaceMembershipFragment>
                const membership = {
                  workspaceMembership: workspaceMembership ?? {
                    __typename: 'WorkspaceMembership',
                    role: 'GUEST',
                  },
                  privateAccessLevel: workspaceMembership?.isEditor
                    ? ('EDIT' as ProjectMembershipFragment['privateAccessLevel'])
                    : ('VIEW' as ProjectMembershipFragment['privateAccessLevel']),
                }

                return (
                  <AddProjectMemberPermissionsAccessSelect
                    member={membership}
                    guestCommentsEnabled={guestPermissions.guestCommentsEnabled}
                    guestInspectEnabled={guestPermissions.guestInspectEnabled}
                    onPermissionChange={(permission, value) => {
                      setGuestPermissions({
                        ...guestPermissions,
                        [permission]: value,
                      })
                    }}
                  />
                )
              }}
            />
            <StyledInviteButton
              size="32"
              onClick={addMember}
              name="Add member to project"
              disabled={!isInputValid}
            >
              Add
            </StyledInviteButton>
          </InvitePeopleFieldForm>
          <MembersList>
            <WorkspaceRow
              workspace={workspace}
              actions={
                <ProjectAccessSelect
                  level={values.projectWorkspaceAccessLevel}
                  setLevel={setWorkspacePermissions}
                  hideCommentLabel
                />
              }
            />
            <WorkspaceMemberRow
              key={currentUser.identifier}
              workspaceMembership={normalizedCurrentUser}
              actions={
                <AccessLevelSelect
                  level="EDIT"
                  setLevel={() => false}
                  hideCommentLabel
                >
                  <AccessLevelSelectOption
                    level="EDIT"
                    label="Edit"
                    help="Edit and view the document"
                    disabled
                  />
                  <AccessLevelSelectBanner>
                    Access is determined by the Member&prime;s Workspace role.
                  </AccessLevelSelectBanner>
                </AccessLevelSelect>
              }
            />
            {membersList.map((member, index) => {
              if (member.workspaceMembership.__typename === 'PermissionGroup') {
                const permissionGroup = member.workspaceMembership

                return (
                  <ListRow
                    key={permissionGroup.identifier}
                    name={permissionGroup.name}
                    actions={
                      <AccessLevelSelect
                        level={member.privateAccessLevel}
                        setLevel={() => {}}
                        hideCommentLabel
                      >
                        <AccessLevelSelectOption
                          level="VIEW"
                          label="View"
                          help="View, inspect and download document in the web app"
                          disabled
                        />
                        <AccessLevelSelectBanner>
                          Access is determined by the Member′s Workspace role.
                        </AccessLevelSelectBanner>
                        <AccessLevelSelectSeparator />

                        <AccessLevelSelectCheckbox
                          data-testid="inspect-enabled"
                          label="Can download and inspect"
                          checked
                          disabled
                        />
                        <AccessLevelSelectCheckbox
                          data-testid="comments-enabled"
                          label="Can comment"
                          checked
                          disabled
                        />
                      </AccessLevelSelect>
                    }
                    showStripes
                  >
                    <PermissionGroupItem permissionGroup={permissionGroup} />
                  </ListRow>
                )
              }

              return (
                <WorkspaceMemberRow
                  key={member.workspaceMembershipIdentifier || member.email}
                  workspaceMembership={member.workspaceMembership}
                  actions={
                    <AccessLevelSelect
                      level={member.privateAccessLevel}
                      setLevel={() => {}}
                      hideCommentLabel
                    >
                      <ProjectMemberPermissionsAccessSelect
                        member={{
                          privateAccessLevel: member.privateAccessLevel,
                          workspaceMembership: member.workspaceMembership,
                        }}
                        guestCommentsEnabled={member.guestCommentsEnabled}
                        guestInspectEnabled={member.guestInspectEnabled}
                        onPermissionChange={(permission, value) => {
                          membersList[index][permission] = value
                          setMembersList([...membersList])
                        }}
                      />
                      <AccessLevelSelectSeparator />
                      <AccessLevelSelectRemoveButton
                        onClick={() => deleteMember(index)}
                      >
                        Remove Member
                      </AccessLevelSelectRemoveButton>
                    </AccessLevelSelect>
                  }
                />
              )
            })}
          </MembersList>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSubmitting} type="button" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={!isValid || isSubmitting}
            name="Create Project"
          >
            Create Project
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
