import React from 'react'
import * as S from './ExitFullScreenButton.styles'
import { useExitFullscreenButton } from './useExitFullscreenButton'
import { usePrototypeContext } from '../../hooks'

export function ExitFullScreenButton() {
  const { onExitFullscreenButtonClick } = useExitFullscreenButton()
  const { showExitFullscreenButton } = usePrototypeContext()

  if (!showExitFullscreenButton) {
    return null
  }

  return (
    <S.ExitFullscreenButton
      aria-label="Exit fullscreen"
      variant="primary-untinted"
      size="40"
      onClick={onExitFullscreenButtonClick}
    >
      <S.ArrowsIcon width={24} />
    </S.ExitFullscreenButton>
  )
}
