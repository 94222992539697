import jwtDecode from 'jwt-decode'

import { CredentialsFragment } from '@sketch/gql-types'

export interface BaseAuthorization {
  fragment: CredentialsFragment
}

export type PersonalAuthorizationId = { type: 'personal' }
export type SsoAuthorizationId = {
  type: 'sso'
  workspaceId: string
}
export type AuthorizationId = PersonalAuthorizationId | SsoAuthorizationId

export type PersonalAuthorization = BaseAuthorization & PersonalAuthorizationId
export type SsoAuthorization = BaseAuthorization & SsoAuthorizationId
export type Authorization = PersonalAuthorization | SsoAuthorization

export const createPersonalAuthorization = (
  fragment: CredentialsFragment
): Authorization => ({
  type: 'personal',
  fragment,
})

export const createSsoAuthorization = (
  workspaceId: string,
  fragment: CredentialsFragment
): Authorization => ({
  type: 'sso',
  workspaceId,
  fragment,
})

export const isPersonalAuthorization = (
  auth?: Authorization
): auth is PersonalAuthorization => auth?.type === 'personal'

export const isSsoAuthorization = (
  auth?: Authorization
): auth is SsoAuthorization => auth?.type === 'sso'

export const areAuthorizationIdsEqual = (
  auth1: AuthorizationId,
  auth2: AuthorizationId
) => {
  switch (auth1.type) {
    case 'personal':
      return auth2.type === 'personal'
    case 'sso':
      return auth2.type === 'sso' && auth1.workspaceId === auth2.workspaceId
  }
}

interface DecodedToken {
  ses: string
}

export const getSessionIdFromToken = (auth: Authorization): string | null => {
  const payload = jwtDecode<DecodedToken>(auth.fragment.authToken)

  if (!payload) return null

  return payload.ses
}
