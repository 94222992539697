import React, { useRef } from 'react'
import produce from 'immer'

import { Button, Modal, ModalInjectedProps, Text } from '@sketch/components'

import {
  errorPreventiveCacheRead,
  removeFromPaginated,
} from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'

import {
  GetWorkspacePermissionGroupMembersDocument,
  GetWorkspacePermissionGroupMembersQuery,
  GetWorkspacePermissionGroupMembersQueryVariables,
  useAddMemberToPermissionGroupMutation,
} from '@sketch/gql-types'

import { useMembersAutoComplete } from '../../hooks'
import PermissionGroupMemberTable from '../../components/PermissionGroupMemberTable'
import PermissionMemberInputWarning from '../../components/PermissionMemberInputWarning'
import RemoveGroupMember from '../../components/RemovePermissionGroupMember'

import {
  AddMemberButton,
  InputWrapper,
  InvitePeopleField,
} from './PermissionGroupMembersModal.styles'

interface PermissionGroupMemberModalProps extends ModalInjectedProps {
  permissionGroupIdentifier: string
  permissionGroupName: string
  workspaceIdentifier: string
}

const PermissionGroupMembersModal = (
  props: PermissionGroupMemberModalProps
) => {
  const {
    hideModal,
    permissionGroupIdentifier,
    permissionGroupName,
    workspaceIdentifier,
  } = props

  const { showToast } = useToast()
  const containerRef = useRef<HTMLInputElement>(null)

  const autoComplete = useMembersAutoComplete({
    workspaceIdentifier,
    permissionGroupIdentifier,
    excludedMemberIdentifiers: [],
  })

  const [
    addMember,
    { loading: addMemberLoading },
  ] = useAddMemberToPermissionGroupMutation({
    onError: 'show-toast',
    onCompleted: () => {
      showToast('Member has been added', 'positive')
      autoComplete.combobox.reset()
    },
    update: (cache, { data }) => {
      const cacheResult = errorPreventiveCacheRead<
        GetWorkspacePermissionGroupMembersQuery,
        GetWorkspacePermissionGroupMembersQueryVariables
      >(cache, {
        query: GetWorkspacePermissionGroupMembersDocument,
        variables: { permissionGroupIdentifier },
      })

      if (!cacheResult) {
        return
      }

      const updatedCacheResult = produce(cacheResult, result => {
        data!.addPermissionGroupMembers.members.forEach(member => {
          result.permissionGroup.members.entries.push(member!)
          result.permissionGroup.members.meta.totalCount++
        })
      })

      cache.writeQuery<
        GetWorkspacePermissionGroupMembersQuery,
        GetWorkspacePermissionGroupMembersQueryVariables
      >({
        query: GetWorkspacePermissionGroupMembersDocument,
        variables: { permissionGroupIdentifier },
        data: updatedCacheResult,
      })

      // Remove the added members from the autocomplete lists
      data!.addPermissionGroupMembers.members.forEach(member => {
        removeFromPaginated(
          cache,
          {
            __typename: 'WorkspaceMembership',
            identifier: member?.workspaceMember.identifier,
          },
          query => query.includes('invitableUsersForPermissionGroup'),
          false
        )
      })
    },
  })

  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>View and Edit Group Members</Modal.Header>
      <Modal.Body>
        <Text>
          View, add or search for Members in the <b>“{permissionGroupName}”</b>{' '}
          group.
        </Text>
        <InputWrapper>
          <InvitePeopleField
            label="Users List"
            placeholder="Add a name or email address"
            containerRef={containerRef}
            autocomplete={{ ...autoComplete, formik: {} as any }}
            dynamicAction={() => <PermissionMemberInputWarning />}
          />
          <AddMemberButton
            variant="primary"
            onClick={() => {
              addMember({
                variables: {
                  input: {
                    identifier: permissionGroupIdentifier,
                    members: [autoComplete.selectedItem!.identifier],
                  },
                },
              })
            }}
            loading={addMemberLoading}
            disabled={!autoComplete.selectedItem}
          >
            Add
          </AddMemberButton>
        </InputWrapper>

        <PermissionGroupMemberTable
          permissionGroupIdentifier={permissionGroupIdentifier}
          renderAction={identifier => (
            <RemoveGroupMember memberIdentifier={identifier} />
          )}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal}>Done</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PermissionGroupMembersModal
