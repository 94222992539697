import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

import { Navbar } from '../Navbar'
import { useBanner } from '../Banner'

const StyledHeader = styled.header<{
  $hideShadow?: boolean
}>(
  ({ theme, $hideShadow }) => css`
    width: 100%;
    position: sticky;
    top: 0;
    z-index: ${theme.zIndex[4]};

    ${$hideShadow &&
    css`
      ${Navbar} {
        box-shadow: none;
      }
    `}
  `
)

interface HeaderProps {
  className?: string
}

export const HeaderOld = React.forwardRef<
  HTMLElement,
  PropsWithChildren<HeaderProps>
>(function Header({ children, className }, ref) {
  const { items } = useBanner()

  return (
    <StyledHeader
      className={className}
      ref={ref}
      $hideShadow={items.length > 0}
    >
      {children}
    </StyledHeader>
  )
})
