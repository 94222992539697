import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "exclamation-mark-triangle-monochrome-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fillRule: "evenodd", fill: "currentColor" },
        React.createElement("path", { d: "M9.744 3.106l4.584 8.165a2 2 0 01-1.744 2.979H3.416a2 2 0 01-1.744-2.98l4.584-8.164a2 2 0 013.488 0z", fillOpacity: 0.15 }),
        React.createElement("path", { d: "M5.602 2.74a2.75 2.75 0 014.796 0l4.584 8.164A2.75 2.75 0 0112.584 15H3.416a2.75 2.75 0 01-2.398-4.096l4.584-8.165zm3.01.256a1.25 1.25 0 00-1.702.478l-4.584 8.164a1.25 1.25 0 001.09 1.862h9.168a1.25 1.25 0 001.09-1.862L9.09 3.474a1.25 1.25 0 00-.478-.478z", fillRule: "nonzero" }),
        React.createElement("circle", { cx: 8, cy: 11.5, r: 1 }),
        React.createElement("path", { d: "M8 5.25a1 1 0 011 1V8.5a1 1 0 11-2 0V6.25a1 1 0 011-1z" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
