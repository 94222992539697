import React from 'react'
import { ChildrenWrapper, CloseWrapper, Wrapper, Icon } from './Toast.styles'
import { HoverableProps } from 'react-event-as-prop'
import { Variant } from './Toast.types'

import { ReactComponent as CrossIcon } from '@sketch/icons/cross-16'
import { ReactComponent as InfoIcon } from '@sketch/icons/information-16'
import { ReactComponent as PositiveIcon } from '@sketch/icons/checkmark-circle-fill-16'
import { ReactComponent as NegativeIcon } from '@sketch/icons/exclamation-mark-fill-16'

const testIds = {
  default: 'toast-default',
  info: 'toast-info',
  negative: 'toast-negative',
  positive: 'toast-positive',
}

const icons: Record<Exclude<Variant, 'default'>, React.ElementType> = {
  info: InfoIcon,
  positive: PositiveIcon,
  negative: NegativeIcon,
}

export interface ToastProps extends HoverableProps {
  id: string
  children: React.ReactNode
  variant: Variant
  onClose?: () => void
}

export const Toast = ({
  children,
  id,
  variant,
  onClose,
  ...props
}: ToastProps) => {
  return (
    <Wrapper id={id} data-testid={testIds[variant]} role="alert" {...props}>
      {variant !== 'default' && <Icon variant={variant} as={icons[variant]} />}
      <ChildrenWrapper>{children}</ChildrenWrapper>
      {onClose && (
        <CloseWrapper data-testid="toast-close" onClick={onClose}>
          <CrossIcon width={16} height={16} />
        </CloseWrapper>
      )}
    </Wrapper>
  )
}
