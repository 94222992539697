import { useFlag } from '@sketch/modules-common'
import { useGetWorkspaceQuery } from '@sketch/gql-types'

export const useIsProfileEnabled = (identifier: string) => {
  const isProfileFFEnabled = useFlag('profiles')

  const { data: workspaceData, loading } = useGetWorkspaceQuery({
    variables: { identifier },
    skip: !isProfileFFEnabled,
  })

  const isWorkspaceProfileEnabled = !!workspaceData?.workspace.features
    .workspaceProfileEnabled

  const isProfileEnabled = isProfileFFEnabled && isWorkspaceProfileEnabled

  return { isProfileEnabled, loading }
}
