import * as React from 'react'

interface RenderPassedProps {
  isSidebarRightOpen?: boolean
  isSidebarLeftOpen?: boolean
  toggleSidebarRight?: () => void
  setSidebarLeftOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

type RenderFunction = (passedProps: RenderPassedProps) => React.ReactNode

export type RenderFunctionOrNode = React.ReactNode | RenderFunction

export const callIfFunction = (
  componentOrFn: RenderFunctionOrNode,
  props: RenderPassedProps = {}
) =>
  typeof componentOrFn === 'function' ? componentOrFn(props) : componentOrFn
