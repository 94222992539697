import React from 'react'
import { useFlag } from '@sketch/modules-common'

import { Box, Flex, Text, UnstyledList } from '@sketch/components'
import {
  StyledDocumentCardIcons,
  StyledIcon,
  PlaceholderDSLogo,
  DSLogo,
  StyledTooltip,
} from './DocumentCardIcons.styles'

import { DocumentSharingStatus } from '../../../modules/shares/utils/getDocumentSharingStatus'

import { ReactComponent as SymbolIcon } from '@sketch/icons/link-library-16'
import { ReactComponent as TwoPersonsIcon } from '@sketch/icons/persons-two-16'
import { ReactComponent as LockIcon } from '@sketch/icons/lock-16'
import { ReactComponent as GlobeIcon } from '@sketch/icons/globe-16'
import { ReactComponent as DSIcon } from '@sketch/icons/design-system-16'
import { ReactComponent as TemplatesIcon } from '@sketch/icons/templates-16'
import { ReactComponent as CommunityIcon } from '@sketch/icons/community-16'
import { ReactComponent as PinIcon } from '@sketch/icons/pin-16'
import { ShareType } from '../../../modules/shares/components/EditInSketchButton'

export interface DesignSystemForLibraryList {
  name: string
  preview: string | null
}

interface IconWithTooltipProps {
  children: React.ReactNode
  content: React.ReactNode
  className?: string
}

const IconTooltip = ({
  children,
  content,
  className,
}: IconWithTooltipProps) => {
  return (
    <StyledTooltip
      placement="bottom-start"
      content={content}
      className={className}
    >
      {children}
    </StyledTooltip>
  )
}

export const sharedStatusToIconMap: Record<
  DocumentSharingStatus,
  React.ReactNode
> = {
  ANYONE_WITH_LINK: (
    <IconTooltip content={<span>Anyone with the link can access</span>}>
      <StyledIcon as={GlobeIcon} aria-label="Publicly shared icon" />
    </IconTooltip>
  ),
  GUESTS: (
    <IconTooltip content={<span>Selected Guests can access</span>}>
      <StyledIcon as={TwoPersonsIcon} aria-label="Guests icon" />
    </IconTooltip>
  ),
  SELECTED_WORKSPACE_MEMBERS: (
    <IconTooltip
      content={<span>Selected people in your Workspace can access</span>}
    >
      <StyledIcon as={LockIcon} aria-label="Lock icon" />
    </IconTooltip>
  ),
  ONLY_YOU: (
    <IconTooltip content={<span>Only you can access</span>}>
      <StyledIcon as={LockIcon} aria-label="Lock icon" />
    </IconTooltip>
  ),
  WORKSPACE_MEMBERS: null,
  PUBLICLY_VISIBLE: (
    <IconTooltip content={<span>Publicly available</span>}>
      <StyledIcon as={CommunityIcon} aria-label="Publicly shared icon" />
    </IconTooltip>
  ),
}

const DesignSystemsIcon = ({
  designSystems,
}: {
  designSystems: DesignSystemForLibraryList[]
}) => {
  if (!designSystems.length) {
    return null
  }

  return (
    <>
      <Text fontWeight={600} mb={1}>
        Part of
      </Text>
      <Box mt={2}>
        <UnstyledList>
          {designSystems.map(designSystem => (
            <li key={designSystem.name}>
              <Flex mt={1}>
                <Box width={16} mr={2}>
                  {designSystem.preview ? (
                    <DSLogo src={designSystem.preview} />
                  ) : (
                    <PlaceholderDSLogo />
                  )}
                </Box>
                <Text color="foreground.primary.B">{designSystem.name}</Text>
              </Flex>
            </li>
          ))}
        </UnstyledList>
      </Box>
    </>
  )
}

export const PinnedIcon = () => {
  return (
    <IconTooltip content={<span>Pinned to the top</span>}>
      <StyledIcon as={PinIcon} aria-label="Pin icon" />
    </IconTooltip>
  )
}

export const TemplateIcon = () => {
  return (
    <IconTooltip content={<span>Template</span>}>
      <StyledIcon as={TemplatesIcon} aria-label="Template icon" />
    </IconTooltip>
  )
}

interface LibraryIconProps {
  showDesignSystems: boolean
  designSystems?: DesignSystemForLibraryList[]
}

export const LibraryIcon: React.FC<LibraryIconProps> = ({
  showDesignSystems,
  designSystems,
}) => {
  return (
    <>
      <IconTooltip content={<span>Library</span>}>
        <StyledIcon as={SymbolIcon} aria-label="Library icon" />
      </IconTooltip>
      {showDesignSystems && (
        <IconTooltip
          content={<DesignSystemsIcon designSystems={designSystems ?? []} />}
        >
          <StyledIcon as={DSIcon} aria-label="Design System icon" />
        </IconTooltip>
      )}
    </>
  )
}

export interface DocumentCardIconsProps {
  documentSharingStatus: DocumentSharingStatus
  designSystems?: DesignSystemForLibraryList[]
  type?: ShareType
  pinned?: string | null
}

const DocumentCardIcons = ({
  documentSharingStatus,
  designSystems,
  type,
  pinned,
}: DocumentCardIconsProps) => {
  const isDesignSystemsOn = useFlag('design-systems')
  const isLibrary = type === 'LIBRARY'
  const isTemplate = type === 'TEMPLATE'

  if (
    !isLibrary &&
    !isTemplate &&
    documentSharingStatus === 'WORKSPACE_MEMBERS' &&
    !pinned
  ) {
    // No icons to show
    return null
  }

  const sharingStatusIcon = sharedStatusToIconMap[documentSharingStatus]
  const showDesignSystems = !!(isDesignSystemsOn && designSystems?.length)

  return (
    <StyledDocumentCardIcons data-testid="document-card-icon-container">
      {pinned && <PinnedIcon />}
      {sharingStatusIcon}
      {isLibrary && (
        <LibraryIcon
          showDesignSystems={showDesignSystems}
          designSystems={designSystems}
        />
      )}
      {isTemplate && <TemplateIcon />}
    </StyledDocumentCardIcons>
  )
}

export default DocumentCardIcons
