import React, { useEffect } from 'react'
import {
  withRouter,
  RouteComponentProps,
  useRouteMatch,
} from 'react-router-dom'
import queryString from 'query-string'
import {
  useAnalytics,
  EmptyHeader,
  routes,
  QueryParams,
  useSignOut,
} from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'
import { useEmailVerifyMutation } from '@sketch/gql-types'

interface EmailVerifyViewProps extends RouteComponentProps {
  isSignedIn: boolean
}

type EmailVerifyQueryParams = QueryParams<'ACCEPT'> | QueryParams<'CONFIRM'>

/**
 * This component is used to validate a user email.
 * Example URL: https://staging.sketch.cloud/accept?token=e2f0f334-9d86-43af-912a-2b7d178b142b
 * It's used in the "Verify your Sketch Cloud account" email
 * and redirects the user to it's home-view if everything is alright.
 *
 * It also works for the `confirm` query string, which is used to confirm an
 * email that has changed.
 * Example URL: https://staging.sketch.cloud/confirm?token=e2f0f334-9d86-43af-912a-2b7d178b142b
 */
const EmailVerifyView = (props: EmailVerifyViewProps) => {
  const { token } = queryString.parse(
    props.location.search
  ) as EmailVerifyQueryParams

  const isConfirmEmailChangeRoute = useRouteMatch({
    path: routes.CONFIRM.template(),
    exact: true,
  })

  const { showToast } = useToast()
  const { trackEvent } = useAnalytics()

  const signOut = useSignOut({
    reason: 'Confirming email',
  })

  const [verifyToken] = useEmailVerifyMutation({
    redirectErrors: false,
    onError: error => {
      showToast(error.message, 'negative')
    },
    onCompleted: data => {
      const { email, successful, errors } = data?.emailVerify || {}
      if (successful) {
        if (isConfirmEmailChangeRoute) {
          showToast(
            `${
              email ?? 'Your email'
            } successfully verified. Please sign in again.`,
            'positive'
          )
          signOut()
        } else {
          trackEvent('ONBOARDING - email verified')
          showToast(
            `${email ?? 'Your email'} successfully verified.`,
            'positive'
          )
        }
      } else if (errors && errors.length > 0) {
        const apiErrors = errors.map(error => error?.message).join('; ')
        showToast(apiErrors, 'negative')
        props.history.replace(routes.SIGN_UP.create({}))
        return
      } else {
        showToast('Unable to verify your email', 'negative')
      }
      props.history.replace(routes.ENTRY.create({}))
    },
  })
  useEffect(() => {
    if (token) {
      verifyToken({ variables: { token } })
    }
  }, [token, verifyToken])
  return <EmptyHeader />
}

export default withRouter(EmailVerifyView)
