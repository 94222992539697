import React, { useState, useEffect } from 'react'

import DiscountPill from './DiscountPill'

import {
  StyledInput,
  StyledButton,
  FieldsWrapper,
  AppliedDiscounts,
  ErrorMessage,
} from './Discount.styles'

export interface DiscountError {
  code: string
  reason: string
}

interface DiscountProps {
  onApplyDiscountCode: (discountCode: string) => void
  onClearDiscountCode?: () => void
  appliedDiscountCodes?: string[]
  error?: DiscountError
  isDisabled?: boolean
  isLoading?: boolean
}

/**
 * Discount
 *
 * Renders the Discount input and button which
 * lets the user apply a discount code.
 */
const Discount: React.FC<DiscountProps> = ({
  onApplyDiscountCode,
  onClearDiscountCode,
  appliedDiscountCodes,
  error,
  isDisabled,
  isLoading,
}) => {
  const [discountCode, setDiscountCode] = useState('')

  useEffect(() => {
    // clear input when its done loading
    if (isLoading) {
      return () => {
        setDiscountCode('')
      }
    }
  }, [isLoading])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountCode(event?.target?.value)
  }

  const handleApplyDiscount = () => {
    onApplyDiscountCode(discountCode.trimStart().trimEnd()) // remove white spaces
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()

      if (event.currentTarget.value) {
        handleApplyDiscount()
      }
    }
  }

  const hasAppliedDiscounts = !!appliedDiscountCodes?.length
  const isEmpty = !discountCode.length

  return (
    <div>
      <FieldsWrapper>
        <StyledInput
          type="text"
          placeholder="Discount code"
          onChange={handleChange}
          value={discountCode}
          onKeyDown={handleKeyDown}
        />
        <StyledButton
          variant="secondary-untinted"
          onClick={handleApplyDiscount}
          disabled={isDisabled || isEmpty}
          loading={isLoading}
          small
        >
          Apply
        </StyledButton>
      </FieldsWrapper>
      {error && (
        <ErrorMessage>
          {error.code.toUpperCase()}
          {/* Using the .replace here to remove the coupon code from the BE error message */}
          {error.reason.replace(error.code, '')!}
        </ErrorMessage>
      )}
      {hasAppliedDiscounts && (
        <AppliedDiscounts>
          {appliedDiscountCodes!.map(discount => (
            <DiscountPill
              key={discount}
              value={discount}
              onDeleteClick={onClearDiscountCode}
            >
              {discount}
            </DiscountPill>
          ))}
        </AppliedDiscounts>
      )}
    </div>
  )
}

export default Discount
