import React from 'react'
import styled from 'styled-components'
import { useMeasure } from 'react-use'

import { breakpoint } from '@sketch/global-styles'

interface CollapsibleSectionProps {
  collapsed?: boolean
  mobileOnly?: boolean
}

const Wrapper = styled.div<{ $mobileOnly?: boolean }>`
  overflow: hidden;
  transition: max-height
    ${({ theme }) =>
      `${theme.transitions.duration[4]} ${theme.transitions.timing.easeInOut}`};
  will-change: height;

  ${({ $mobileOnly }) =>
    $mobileOnly &&
    breakpoint('md')`
      max-height: none!important;
    `}
`

export const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  children,
  collapsed,
  mobileOnly,
}) => {
  const [bind, { height }] = useMeasure<HTMLDivElement>()
  const maxHeight = collapsed ? 0 : height

  return (
    <Wrapper $mobileOnly={mobileOnly} style={{ maxHeight }}>
      <div ref={bind}>{children}</div>
    </Wrapper>
  )
}
