import React from 'react'

import {
  NotificationShareName,
  NotificationBody,
  StarredIcon,
} from '../NotificationListItem.styles'

import { StarNotificationFragment } from '@sketch/gql-types'

interface StarNotificationListItemProps {
  notification: StarNotificationFragment
}

const StarNotificationListItem: React.FC<StarNotificationListItemProps> = ({
  notification,
}) => {
  const { share } = notification

  return (
    <>
      <NotificationShareName data-testid="notification-share-name">
        {share?.workspace.name} / {share?.name}
      </NotificationShareName>
      <NotificationBody data-testid="notification-body">
        <StarredIcon />
        An update in this document has been starred
      </NotificationBody>
    </>
  )
}

export default StarNotificationListItem
