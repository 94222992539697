import styled, { css } from 'styled-components'
import { Skeleton as BaseSkeleton } from '@sketch/components'

import { ReactComponent as ImagePlaceholder } from '@sketch/icons/image-placeholder-64'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const thumbnailCommon = css`
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: ${({ theme }) => theme.radii.xlarge};
`

export const NoImageThumbnail = styled.div`
  ${thumbnailCommon}

  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
`

export const Thumbnail = styled.img`
  ${thumbnailCommon}
  object-fit: cover;

  transition: box-shadow ${({ theme }) => theme.transitions.duration[3]};

  :hover {
    box-shadow: 0 4px 12px 0 ${({ theme }) => theme.colors.border.A},
      0 12px 40px 0 ${({ theme }) => theme.colors.border.A};
  }
`

export const NoImageIcon = styled(ImagePlaceholder).attrs({ role: 'img' })`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
  aspect-ratio: 1;

  width: 100%;
  max-width: 64px;
`

export const Skeleton = styled(BaseSkeleton)`
  ${thumbnailCommon};
  aspect-ratio: 1;
`
