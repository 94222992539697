import { GenericQueryError } from '../../utils'
import { useGetCommunityPublications } from '../../hooks'

/**
 * useGetCommunityItemPublications
 *
 * The existing of this hook is only to highlight, even more, the fact that
 * if the query network errors it will throw an error that will be caught by
 * the parent component, in this case CommunityHubPage.
 *
 * This will basically force the scenario if one query errors, the page errors
 * and no data should be seen, rendering a broken page.
 *
 * This was discussed in:
 * https://sketch.slack.com/archives/C04S6BB9TCM/p1686004438887389
 */
export const useGetCommunityItemPublications = (
  ...args: Parameters<typeof useGetCommunityPublications>
) => {
  const query = useGetCommunityPublications(...args)

  if (query.error) {
    /**
     * Throw the error to the "CommunityHubPage" to render the GenericError.
     *
     * While there's no app breaking error React will still bubble the event down to window
     * however this behaviour doesn't exist in production.
     *
     * https://react.dev/reference/react/Component#componentdidcatch-caveats
     */
    throw new GenericQueryError(query.error.message)
  }

  return query
}
