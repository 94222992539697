import React from 'react'
import { LoadingWorkspaceSidebarLayout } from '@sketch/components'
import { DynamicLoadingPropsByKey } from '../DynamicLoadingPage/DynamicLoadingPage.types'

export const WorkspaceSidebarLoadingPage = (
  props: DynamicLoadingPropsByKey<'WORKSPACE'>
) => {
  return (
    <LoadingWorkspaceSidebarLayout
      sidebar={props.sidebar}
      header={props.header}
    />
  )
}
