import { CouponFragment } from '@sketch/gql-types'

export const ERROR_MESSAGE = {
  NOT_FOUND_OR_EXPIRED: ' could not be found or is expired ',
  INVALID_PLAN_NOT_YEARLY: ' is only valid for yearly plans',
  INVALID_PRODUCT: ' is not valid for this product',
} as const

// Grab the error message and return a structure error object for the discount
export const getDiscountError = (couponInfo?: CouponFragment) => {
  if (!couponInfo || !couponInfo.errorCode) {
    return
  }

  const { promotionCode, errorCode } = couponInfo

  return {
    code: promotionCode,
    reason:
      errorCode in ERROR_MESSAGE
        ? ERROR_MESSAGE[errorCode]
        : ' is not a valid coupon',
  }
}

// Get the discount percent off, amount off and code
export const getAppliedDiscount = (couponInfo?: CouponFragment) => {
  const hasError = !!getDiscountError(couponInfo)

  if (!couponInfo || hasError) {
    return
  }

  const { promotionCode, amountOff, percentOff } = couponInfo

  return {
    code: promotionCode,
    price: amountOff!,
    percentOff: percentOff,
  }
}
