import React from 'react'
import styled from 'styled-components'

import { DownloadFileAnchor } from '../DownloadFile'
import { dropdownItemStyles } from './Item/DropdownItem.styles'
import { ListItem } from './Item/DropdownItem'

const DropdownDownloadLink = styled(DownloadFileAnchor).attrs({
  role: 'button',
})`
  ${dropdownItemStyles}
`

export const DropdownDownload = (
  props: React.ComponentProps<typeof DropdownDownloadLink>
) => (
  <ListItem>
    <DropdownDownloadLink data-unmountafterclick {...props} />
  </ListItem>
)
