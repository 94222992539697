import { getLCP as getLcpOriginal, Metric } from 'web-vitals'

import { OperationName } from './getRouteExpectations'
import { timestampsRef } from './OperationsTimestampLink'

const isVerifiedLCPValue = (
  metric: Metric,
  expectedOperations: OperationName | 'none' | undefined
): boolean | undefined => {
  if (expectedOperations === undefined) {
    return undefined
  }
  if (expectedOperations === 'none') {
    return true
  }

  const threshold = Math.min(
    Number.POSITIVE_INFINITY,
    ...Object.entries(timestampsRef)
      .filter(([opName]) =>
        expectedOperations.includes(opName as OperationName)
      )
      .map(([, value]) => value!)
  )

  return metric.value > threshold
}

export interface LcpEvent {
  metric: Metric
  isAfterReceivingData: boolean | undefined
}

export type LcpEventHandler = (payload: LcpEvent) => void

export const getLCP = (
  handler: LcpEventHandler,
  expectedOperations: OperationName | 'none' | undefined
) => {
  getLcpOriginal(metric => {
    handler({
      metric,
      isAfterReceivingData: isVerifiedLCPValue(metric, expectedOperations),
    })
  }, false)
}
