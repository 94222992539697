import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "ink-pen-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none" },
        React.createElement("path", { d: "M11.63 7l-.64 4.158a1.125 1.125 0 01-.808.912l-8.04 2.25a.375.375 0 01-.462-.462l2.254-8.035c.12-.425.476-.741.912-.808l4.159-.64h0", stroke: "currentColor", strokeWidth: 1.5, strokeLinejoin: "round" }),
        React.createElement("path", { d: "M6.116 9.884a1.25 1.25 0 101.768-1.768 1.25 1.25 0 00-1.768 1.768z", fill: "currentColor" }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, d: "M6.71 9.298l-4.198 4.197" }),
        React.createElement("path", { d: "M11.054 2l2.955 2.956a1.71 1.71 0 01-2.418 2.418L8.636 4.42A1.71 1.71 0 1111.054 2z", stroke: "currentColor", strokeWidth: 1.5, fillOpacity: 0.15, fill: "currentColor" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
