import styled from 'styled-components'

import { NavbarExtended } from '../NavbarExtended'

export const SidebarWrapper = styled.section`
  display: flex;
  flex-direction: column;

  /* Limit the components width, preventing items with big name to have scroll */
  width: 100%;
  height: 100%;
`

export const LoadingSidebarWorkspace = styled(NavbarExtended)`
  background: none;
`
