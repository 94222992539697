import React from 'react'

import { Button, ModalInjectedProps } from '@sketch/components'
import { SelectedItemsProps } from '../types'

export interface FooterActionsProps extends ModalInjectedProps {
  step: number
  disabled: boolean
  loading: boolean
  onSendToTrash: ({ shareIdentifiers }: { shareIdentifiers: string[] }) => void
  onViewTrash: () => void
  goToFirstStep: () => void
  nextStep: () => void
  previousStep: () => void
  selectedItems: SelectedItemsProps[]
}

const FooterActions: React.FC<FooterActionsProps> = ({
  step,
  disabled,
  selectedItems,
  loading,
  hideModal,
  onSendToTrash,
  onViewTrash,
  goToFirstStep,
  nextStep,
  previousStep,
}) => {
  const noItemsSelected = selectedItems.length === 0

  const handleSendToTrash = () => {
    const shareIdentifiers = selectedItems.map(item => item.identifier)
    onSendToTrash({ shareIdentifiers })
  }

  if (step === 0) {
    return (
      <>
        <Button onClick={hideModal} disabled={disabled} size="40">
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={nextStep}
          disabled={noItemsSelected}
          size="40"
        >
          Review Selection
        </Button>
      </>
    )
  }

  if (step === 1) {
    return (
      <>
        <Button onClick={previousStep} disabled={disabled} size="40">
          Back
        </Button>
        <Button
          variant="negative"
          onClick={handleSendToTrash}
          loading={loading}
          size="40"
        >
          Send to Trash
        </Button>
      </>
    )
  }

  if (step === 2) {
    return (
      <>
        <Button onClick={goToFirstStep} disabled={disabled} size="40">
          Review More Documents…
        </Button>
        <Button variant="primary" onClick={onViewTrash} size="40">
          View Trash
        </Button>
      </>
    )
  }

  return null
}

export default FooterActions
