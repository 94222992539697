import styled from 'styled-components'

import { Dropdown, SelectorButton, RadioButton } from '@sketch/components'

export const Button = styled(SelectorButton)`
  font-variant-numeric: tabular-nums;
  padding: 4px 28px 4px 8px;
  height: 32px;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  min-width: 74px;
  text-align: left;
`

export const Item = styled(Dropdown.Item)`
  display: flex;
  align-items: center;
`

export const StyledRadioButton = styled(RadioButton)`
  margin: 0;
`

export const Label = styled.span`
  margin-right: 16px;
  line-height: 1;
`

export const KeyboardShortcut = styled.span`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  margin-left: auto;

  @media (hover: none) and (pointer: coarse) {
    display: none;
  }
`
