import React, { useState, useEffect, useCallback } from 'react'
import { useRouteMatch } from 'react-router'
import { useForTablet, NewFeatureHighlightPopover } from '@sketch/components'

import {
  isDocumentRoute,
  isArtboardRoute,
  isDocumentPageRoute,
  isDocumentPrototypeRoute,
  isCwvRoute,
} from '@sketch/modules-common'

interface IntroPopoverProps {
  onConfirm?: () => void
  confirmCopy?: string
  shouldShow?: boolean
  titleText?: string
  bodyText?: string
}

// route verification is needed because otherwise the popover will appear everywhere until it's manually closed
const isWrongRoute = (path: string) =>
  isDocumentRoute(path) ||
  isArtboardRoute(path) ||
  isDocumentPageRoute(path) ||
  isDocumentPrototypeRoute(path) ||
  isCwvRoute(path)

const IntroPopover: React.FC<IntroPopoverProps> = ({
  onConfirm,
  confirmCopy = 'Create New',
  shouldShow = true,
  titleText = 'Design Systems Made Easy',
  bodyText = 'Combine your Libraries with customizable guidelines to create your first design system in Sketch.',
  children,
}) => {
  const { path } = useRouteMatch()
  const isTabletAndBigger = useForTablet()

  const [showPopover, setShowPopover] = useState(false)

  useEffect(() => {
    setShowPopover(!isWrongRoute(path) && shouldShow)
  }, [setShowPopover, shouldShow, path])

  const closePopover = useCallback(() => {
    setShowPopover(false)
  }, [setShowPopover])

  useEffect(() => {
    window.addEventListener('dsm_intro_seen', closePopover, { capture: true })

    return () => {
      window.removeEventListener('dsm_intro_seen', closePopover, {
        capture: true,
      })
    }
  }, [closePopover])

  if (!isTabletAndBigger) {
    return <>{children}</>
  }

  return (
    <NewFeatureHighlightPopover
      highlightPlacement="sidebar"
      titleText={titleText}
      bodyText={bodyText}
      confirmCopy={confirmCopy}
      onConfirm={onConfirm}
      localStorageKey="dsmIntroductionSeen"
      show={showPopover}
    >
      {children}
    </NewFeatureHighlightPopover>
  )
}

export default IntroPopover
