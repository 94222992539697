import React, { FC } from 'react'

import { ErrorMessage, ErrorMessageProps } from './ErrorMessage'

import { ReactComponent as Logo } from '@sketch/icons/logo'

export const GenericError: FC<Partial<ErrorMessageProps>> = props => (
  <ErrorMessage {...(props as ErrorMessageProps)} />
)

GenericError.defaultProps = {
  title: 'Something went wrong',
  /* "iconSize" is not a known property of SVG logos so needs to be extracted */
  icon: function Icon({ iconSize, ...props }) {
    return <Logo width="46px" height="46px" {...props} />
  },
  iconSize: 'small',
  description: (
    <>
      We’re looking into the issue. Try again later or visit our{' '}
      <a href="https://status.sketch.com/">Status Page</a>.
    </>
  ),
}
