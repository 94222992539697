import React from 'react'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import {
  GetAnnotationSettingsDocument,
  GetDocumentListSettingsDocument,
  GetInitialUserDocument,
  GetProjectSearchSettingsDocument,
} from '@sketch/gql-types'
import { createApolloClient, ExtraLinksFn } from './apolloClient'
import { createStoragePersist } from './utils'

import { ApolloProvider as ApolloProviderOriginal } from 'react-apollo'
import { setFallbackAuthorizationId } from '@sketch/modules-common'

/**
 * TODO: research whether it would be safe to remove global ApolloClient reference and
 * use React's `useRef` and/or `useEffect` hooks.
 *
 * Particularly see how the app would behave if ApolloProvider would be unmounted,
 * an error would be catched by higher `ErrorBoundary` and similar edge cases.
 */
let globallyCachedApolloClient:
  | ApolloClient<NormalizedCacheObject>
  | undefined = undefined

const getClient = (extraLinks: ExtraLinksFn) => {
  if (globallyCachedApolloClient) {
    return globallyCachedApolloClient
  }

  const apolloClient = createApolloClient({ extraLinks })

  // GraphQL Queries synced with local-storage
  const storagePersist = createStoragePersist(apolloClient)

  storagePersist.syncQueryWithStorage(GetInitialUserDocument, 'userProfile')
  storagePersist.syncQueryWithStorage(
    GetDocumentListSettingsDocument,
    'documentLayout'
  )
  storagePersist.syncQueryWithStorage(
    GetAnnotationSettingsDocument,
    'userAnnotationSettings'
  )
  storagePersist.syncQueryWithStorage(
    GetProjectSearchSettingsDocument,
    'userProjectSearchSettings'
  )

  setFallbackAuthorizationId(apolloClient.cache)

  globallyCachedApolloClient = apolloClient
  return globallyCachedApolloClient
}

export interface ApolloProviderProps {
  extraLinks: ExtraLinksFn
}

export const ApolloProvider: React.FC<ApolloProviderProps> = ({
  children,
  extraLinks,
}) => {
  const client = getClient(extraLinks)
  return (
    <ApolloProviderOriginal client={client}>{children}</ApolloProviderOriginal>
  )
}
