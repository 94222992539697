import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "arrow-down-to-line-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { stroke: "currentColor", strokeWidth: 1.5, fill: "none", fillRule: "evenodd", strokeLinecap: "round" },
        React.createElement("path", { d: "M8 9V2" }),
        React.createElement("path", { strokeLinejoin: "round", d: "M5.5 8L8 10.5 10.5 8" }),
        React.createElement("path", { d: "M1.75 13.25h12.5" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
