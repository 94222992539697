import { useModalContext } from '@sketch/components'
import { useUserProfile } from '@sketch/modules-common'
import TosMandatoryModal from '../../modules/workspace/modals/TosMandatoryModal/TosMandatoryModal'

export const UserAcceptedToS = () => {
  const { showModal } = useModalContext()
  const { data, error } = useUserProfile(true, {})

  if (!error && data?.me.hasAcceptedAgreement === false) {
    showModal(TosMandatoryModal, {}, { closeOnRouteChange: false })
  }

  return null
}
