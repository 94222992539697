import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  width: ${({ theme }) => theme.sidebar.canvasFirstRightWidth}px;

  /* Force the sidebar to move right */
  margin-left: auto;
`

export const SidebarWrapper = styled.aside`
  flex: 1;
  overflow-y: auto;
`
