import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "dots-3-horizontal-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "currentColor", fillRule: "evenodd" },
        React.createElement("circle", { cx: 5, cy: 12, r: 2 }),
        React.createElement("circle", { cx: 12, cy: 12, r: 2 }),
        React.createElement("circle", { cx: 19, cy: 12, r: 2 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
