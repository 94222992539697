import { ApolloLink } from 'apollo-link'

import { OperationsMap } from '@sketch/gql-types'
import { getDeviceId } from '../utils'

type OperationName = keyof OperationsMap

/**
 * We need to include here all GraphQL operations that we'd like
 * to add the x-device-id header.
 * Right now, signUp is the only one because we're adding it only
 * to auth requests and signUp is the only auth request that is
 * an GraphQL operation.
 */
const OPERATIONS_TO_INCLUDE_DEVICE_ID: OperationName[] = ['signUp']

/**
 * Apollo Link to add the header x-device-id to identify
 * the id of the device in specific operations.
 */
export const createDeviceIdLink = () => {
  return new ApolloLink((operation, forward) => {
    if (
      OPERATIONS_TO_INCLUDE_DEVICE_ID.includes(
        operation.operationName as OperationName
      )
    ) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          'x-device-id': getDeviceId(),
        },
      }))
    }

    return forward(operation)
  })
}
