import styled from 'styled-components'

import { InfiniteList } from '@sketch/components'

export const ListWrapper = styled(InfiniteList)`
  padding: 0;
  margin: 0;
`

export const DisabledProjectList = styled.ul`
  margin: 0;
  padding: 0;
  opacity: 0.55;
`

export const DisabledProject = styled.li`
  display: flex;
  padding: 8px 0px 8px 12px;
  font-size: ${({ theme }) => theme.fontSizes.D};
  line-height: ${({ theme }) => theme.lineHeights.D};
`

export const Icon = styled.svg`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  flex-shrink: 0;

  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
`

export const DisabledProjectName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Separator = styled.hr`
  margin: 8px 12px;
  background: ${({ theme }) => theme.colors.background.tertiary.C};
`
