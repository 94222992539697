import { capitalize } from '@sketch/utils'

export interface CopyProps {
  opacityPercentage: string
  blendMode: string
}

const copy = ({ opacityPercentage, blendMode }: CopyProps) => {
  const blendModeCopyValue =
    blendMode.toLowerCase() !== 'normal' ? ` (${capitalize(blendMode)})` : ''

  return `Opacity: ${opacityPercentage}${blendModeCopyValue}`
}

export default copy
