import styled from 'styled-components'
import { Truncate } from '@sketch/components'

const WorkspaceNameText = styled(Truncate)`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 500;
`

const HelpText = styled(Truncate)`
  width: 100%;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  font-size: ${({ theme }) => theme.fontSizes.D};
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 8px 8px 8px 12px;
  min-height: 64px;
`

const TextWrapper = styled.div`
  margin: 0 auto 0 12px;
  overflow: hidden;
`

export { WorkspaceNameText, HelpText, Wrapper, TextWrapper }
