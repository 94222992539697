import React from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'

import {
  Button,
  Box,
  CreatePasswordInput,
  passwordStrengthValidation,
} from '@sketch/components'
import {
  IndexLayoutContent,
  IndexLayoutExtraProps,
  IndexLayoutHeaderLink,
  IndexLayoutOldTitle,
  RouteProps,
  routes,
} from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'

import { getResetToken, clearResetToken } from '@sketch/utils'
import { useUserPasswordResetMutation } from '@sketch/gql-types'
import { Location } from 'history'

import { Form } from './ResetPasswordView.styles'

const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'Password is too short (minimum is 8 characters)')
    .max(72, 'Password is too long (maximum is 72 characters)')
    .test(
      'passwordStrength',
      'The password strength should be at least Okay',
      passwordStrengthValidation
    )
    .required('Password can’t be blank'),
})

const INITIAL_VALUES = {
  password: '',
}

interface LocationState {
  from?: Location
}

export interface ResetPasswordRouteProps extends RouteProps<'RESET_PASSWORD'> {
  location: Location<LocationState>
}

export interface ResetPasswordViewProps
  extends ResetPasswordRouteProps,
    IndexLayoutExtraProps {}

const ResetPasswordView: React.FC<ResetPasswordViewProps> = props => {
  const { history, HeaderPortal, location } = props
  const { showToast } = useToast()

  const [resetPassword, { loading, data }] = useUserPasswordResetMutation({
    redirectErrors: false,
    onError: error => {
      showToast(error.message, 'negative')
    },
    onCompleted: data => {
      const { successful } = data.userPasswordReset || {}

      if (successful) {
        showToast('Your password has been reset! Please sign in.')
        history.push(routes.SIGN_IN.create({}))

        // Clear the token from local-storage
        clearResetToken()
      }
    },
  })

  const apiErrors = data?.userPasswordReset?.errors || []

  const handleSubmit = (values: typeof INITIAL_VALUES) => {
    /**
     * Check for reset token presence and
     * handle it to prevent referrer leak
     * https://github.com/sketch-hq/Cloud/issues/3898
     */
    const token = getResetToken(location)

    if (!token) {
      showToast(
        'Something is wrong, please contact our support team',
        'negative'
      )
      return
    }

    resetPassword({
      variables: {
        newPassword: values.password,
        token,
      },
    })
  }

  return (
    <IndexLayoutContent center="horizontal" marginTop paddingHorizontal>
      <HeaderPortal>
        <IndexLayoutHeaderLink headerLink="sign-in" />
      </HeaderPortal>
      <IndexLayoutOldTitle>Enter Your New Password</IndexLayoutOldTitle>

      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={resetPasswordSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          isValid,
          handleChange,
          handleBlur,
        }) => (
          <Form>
            <Box mb={6}>
              <CreatePasswordInput
                label="New Password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>

            {apiErrors.length > 0 && (
              <Form.ErrorField>
                {apiErrors.map(error => error?.message).join('; ')}
              </Form.ErrorField>
            )}

            <Form.Footer>
              <Button
                variant="primary"
                fill
                type="submit"
                loading={loading}
                disabled={!(isValid && dirty)}
              >
                Change Password
              </Button>
            </Form.Footer>
          </Form>
        )}
      </Formik>
    </IndexLayoutContent>
  )
}

export default ResetPasswordView
