import { SketchElement } from '../types'
import { isArtboardElement } from './elements'

export function getWebRenderCursor(
  isPlacingDraftAnnotation: boolean | undefined,
  hoveredElement: SketchElement | null
) {
  if (isPlacingDraftAnnotation) {
    return 'copy'
  }

  if (hoveredElement && !isArtboardElement(hoveredElement)) {
    return 'auto'
  }

  return undefined
}
