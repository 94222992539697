import styled from 'styled-components'
import { ReactComponent as FolderClosedIcon } from '@sketch/icons/folder-closed-16'
import { Banner } from '@sketch/components'

export const Bold = styled.b`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-weight: 500;
`

export const FolderIcon = styled(FolderClosedIcon)`
  height: 1rem;
`

export const CenterIconText = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 24px;
`

export const StyledBanner = styled(Banner)`
  margin-bottom: 24px;
`
