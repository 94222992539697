import React, { useContext } from 'react'
import * as yup from 'yup'
import { Formik, FormikProps, FormikHelpers } from 'formik'
import { castError } from '@sketch/utils'
import { ErrorHandler } from '@sketch/tracing'

import {
  Button,
  Form,
  Input,
  Modal,
  ModalInjectedProps,
} from '@sketch/components'

import { ToastContext } from '@sketch/toasts'

import {
  useRenameProjectMutation,
  RenameProjectMutation,
  GetProjectsDocument,
  GetProjectsQueryVariables,
  DataWithoutUserErrors,
} from '@sketch/gql-types'

interface FormValues {
  projectName: string
}

type FormActions = FormikHelpers<FormValues>

type FormOnSubmit = (
  formValues: FormValues,
  formikActions: FormActions
) => Promise<any> | undefined

const renameProjectFormSchema = yup.object({
  projectName: yup.string().trim().required('A project name is required'),
})

interface RenameProjectFormProps {
  initialName: string
  onCancel: () => void
  onSubmit: FormOnSubmit
}

const RenameProjectForm: React.FC<RenameProjectFormProps> = props => {
  const { initialName: projectName, onCancel, onSubmit } = props

  const handleFormikRender = (formikBag: FormikProps<FormValues>) => {
    const {
      touched,
      errors,
      values,
      handleBlur,
      handleChange,
      isSubmitting,
    } = formikBag

    return (
      <Form>
        <Modal.Body>
          <Form.Field
            name="projectName"
            label="Project Name"
            errorText={touched.projectName ? errors.projectName : undefined}
          >
            <Input
              name="projectName"
              type="text"
              placeholder="Name"
              value={values.projectName}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              autoFocus
            />
          </Form.Field>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSubmitting} type="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button loading={isSubmitting} type="submit" variant="primary">
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    )
  }

  const handleOnSubmit = async (
    values: FormValues,
    formikActions: FormActions
  ) => {
    const trimmedValues = renameProjectFormSchema.cast(values)

    formikActions.setSubmitting(true)

    try {
      // This is already being validated, but since yup.cast can return
      // undefined we need this extra check.
      const { projectName } = trimmedValues
      projectName && (await onSubmit({ projectName }, formikActions))
    } catch (e) {
      const error = castError(e)
      ErrorHandler.ignore(
        error,
        'Ignore this error the mutation will handle it'
      )
    } finally {
      formikActions.setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={{ projectName }}
      onSubmit={handleOnSubmit}
      validationSchema={renameProjectFormSchema}
    >
      {handleFormikRender}
    </Formik>
  )
}

const typeInline = <T extends any>(variables: T) => variables

interface RenameProjectProps extends ModalInjectedProps {
  projectId: string
  projectName: string
  workspaceId: string
}

const RenameProject: React.FC<RenameProjectProps> = props => {
  const { hideModal, projectId, projectName, workspaceId } = props
  const { showToast } = useContext(ToastContext)

  const handleOnComplete = (
    data: DataWithoutUserErrors<RenameProjectMutation>
  ) => {
    showToast('Project was renamed successfully')
    hideModal()
  }

  const [renameProject] = useRenameProjectMutation({
    redirectErrors: true,
    onCompleted: handleOnComplete,
    onError: 'show-toast',
    refetchQueries: [
      {
        query: GetProjectsDocument,
        variables: typeInline<GetProjectsQueryVariables>({
          workspaceId,
        }),
      },
    ],
    awaitRefetchQueries: true,
  })

  const onSubmit = ({ projectName: newProjectName }: FormValues) => {
    if (projectName === newProjectName) {
      hideModal()
      return
    }

    return renameProject({
      variables: {
        name: newProjectName,
        projectId,
      },
    })
  }

  return (
    <Modal title="Rename Project" onCancel={hideModal}>
      <RenameProjectForm
        onCancel={hideModal}
        onSubmit={onSubmit}
        initialName={projectName}
      />
    </Modal>
  )
}

export default RenameProject
