import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "folder-open-20", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: 1, fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "Group", stroke: "currentColor", transform: "translate(.8 3)" },
            React.createElement("path", { d: "M5.993.5c.32 0 .627.127.853.354.414.413.976.646 1.561.646h5.909c.861 0 1.18.072 1.497.241.278.15.497.368.646.646.169.317.241.636.241 1.497h0V4.5h-15V2.581c0-.532.078-.888.241-1.194.15-.278.368-.497.646-.646C2.893.578 3.249.5 3.78.5h2.212z", id: "Rectangle", fillRule: "nonzero" }),
            React.createElement("path", { d: "M16.13 4.5c.414 0 .79.168 1.06.44.266.264.432.628.44 1.031h0l-.705 5.684c-.088.694-.17.945-.329 1.19-.136.21-.318.37-.543.479-.263.126-.523.176-1.222.176h0H3.569c-.7 0-.959-.05-1.222-.176a1.316 1.316 0 01-.543-.479c-.159-.245-.24-.496-.329-1.19h0L.782 6.19a1.498 1.498 0 011.27-1.673h0z", id: "Rectangle", fillOpacity: 0.2, fill: "currentColor" }))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
