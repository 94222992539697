import styled from 'styled-components'
import { HEIGHT } from '../../ReviewLargeDocumentsModal.styles'
import { TableComponents } from '@sketch/components'

export const EmptyStateWrapper = styled.div`
  /* Make this container touch the sides of the modal */
  margin: 0 -32px 32px -32px;
  padding-top: 8px;
  border-top: 1px solid ${({ theme }) => theme.colors.border.A};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: ${HEIGHT};
  padding: 0 32px;
`

export const StyledTableHeaderCellCheckbox = styled(
  TableComponents.TableHeaderCell
)`
  width: 30px;
`

export const StyledTableHeaderCellDocument = styled(
  TableComponents.TableHeaderCell
)`
  width: 60%;
`

export const StyledTableHeaderCellFixed = styled(
  TableComponents.TableHeaderCell
)`
  width: 100px;
`

export const StyledTableHeaderCellButton = styled(
  TableComponents.TableHeaderCell
)`
  width: 50px;
`
