import React, { createRef, useEffect } from 'react'
import { Gradient } from '../../../../../../types'
import styled from 'styled-components'
import { colorStep, nextColor } from './utils'

interface CanvasProps {
  size: number
}

const Canvas = styled.canvas<CanvasProps>`
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px; /* stylelint-disable-line scales/space */
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`

interface AngularGradientIconProps {
  gradient: Gradient
  size?: number
}

const drawAngularCircle = (
  gradient: Gradient,
  size: number,
  context: CanvasRenderingContext2D
) => {
  const radius = size / 2
  const centerX = radius
  const centerY = radius

  const colors = gradient.stops
    .sort((lhs, rhs) => lhs.position - rhs.position)
    .map(stop => stop.color)

  const firstPosition = gradient.stops[0].position
  const positions = gradient.stops
    .sort((lhs, rhs) => lhs.position - rhs.position)
    .map(stop => stop.position - firstPosition)

  // Make sure that the last position has the initial color
  positions.push(1)
  colors.push(colors[0])

  let startAngle = positions[0] * (2 * Math.PI)

  for (let i = 0; i < positions.length - 1; i++) {
    const nextAngle = positions[i + 1] * (2 * Math.PI)

    const stepCount = 100
    const angleStep = Math.abs(nextAngle - startAngle) / stepCount

    const stepColor = colorStep(colors[i], colors[i + 1], stepCount)

    for (let p = 0; p < stepCount; p++) {
      const currentAngle = (startAngle + angleStep * p) % (2 * Math.PI)
      const endAngle = (startAngle + angleStep * (p + 1)) % (2 * Math.PI)

      const startColor = nextColor(colors[i], stepColor, p)
      const endColor = nextColor(colors[i], stepColor, p + 1)

      const gradientStartX = centerX + Math.cos(currentAngle) * radius
      const gradientEndX = centerX + Math.cos(endAngle) * radius
      const gradientStartY = centerY + Math.sin(currentAngle) * radius
      const gradientEndY = centerY + Math.sin(endAngle) * radius

      const gradient = context.createLinearGradient(
        gradientStartX,
        gradientEndX,
        gradientStartY,
        gradientEndY
      )
      gradient.addColorStop(
        0,
        `rgba(${startColor.red}, ${startColor.green}, ${startColor.blue}, ${startColor.alpha})`
      )
      gradient.addColorStop(
        1.0,
        `rgba(${endColor.red}, ${endColor.green}, ${endColor.blue}, ${endColor.alpha})`
      )

      context.beginPath()
      // Subtracted and added values fill the render
      // inconsistencies between angular portions
      context.arc(
        centerX,
        centerY,
        radius,
        currentAngle - 0.02,
        endAngle + 0.04,
        false
      )
      context.strokeStyle = gradient
      context.lineWidth = radius * 2
      context.stroke()
    }

    startAngle = positions[i + 1] * (2 * Math.PI)
  }
}

const AngularGradientIcon = ({
  size = 16,
  gradient,
  ...props
}: AngularGradientIconProps) => {
  const adjustedSize = window.devicePixelRatio * size
  const canvasRef = createRef<HTMLCanvasElement>()

  useEffect(() => {
    if (canvasRef.current) {
      const canvas: HTMLCanvasElement = canvasRef.current
      const context = canvas.getContext('2d')

      if (context) {
        context.clearRect(0, 0, adjustedSize, adjustedSize)
        drawAngularCircle(gradient, adjustedSize, context)
      }
    }
  })

  return (
    <Canvas
      {...props}
      ref={canvasRef}
      width={adjustedSize}
      height={adjustedSize}
      size={size}
    />
  )
}

export default AngularGradientIcon
