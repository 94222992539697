import React, { ComponentProps, createRef, MouseEvent } from 'react'
import classNames from 'classnames'

import { LoadingPlaceholder } from '../LoadingPlaceholder'
import { ButtonType, ButtonVariant, ButtonSize, ButtonStyle } from './types'
import {
  ButtonIconWrapper,
  ButtonWrapper,
  StyledButton,
  BaseButton,
  LoadingWrapper,
} from './Button.styles'
import { useThemeContext } from '@sketch/global-styles'

export interface BaseButtonProps {
  /** Set the loading state */
  loading?: boolean
  /** For styling purposes via `styled-components` */
  className?: string
  /** Set the button style. By default `tinted` you can also use the `untinted` versions */
  variant?: ButtonVariant
  /** To use an icon `along` the button. For using just the icon use it as `children` */
  icon?: AnyComponent
  /* Position of the icon if needed */
  iconPosition?: 'left' | 'right'
  /** Set the wrapper for the button */
  contentWrapper?: AnyComponent
  /** The children node */
  children?: React.ReactNode
  /** Distinguish between regular `button`, `submit` a form or `reset` */
  type?: ButtonType
  /** Set disabled state */
  disabled?: boolean
  /** onClick handler function */
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  /** Applies a text size + height */
  size?: ButtonSize
  /** This will fill the 100% of the container width */
  fill?: boolean
  /** You can also pass the colouring and size through here, like primary-small */
  buttonStyle?: ButtonStyle
}

export type ButtonProps = BaseButtonProps & ComponentProps<typeof BaseButton>

const isComponentWithRef = (child: any) =>
  child &&
  React.isValidElement<React.ComponentPropsWithRef<AnyComponent>>(child)

/**
 * Button style is determined by `variant` prop, there are 8 possible values:
 *
 * 1. primary
 * 2. secondary
 * 3. tertiary
 * 4. primary-untinted
 * 5. secondary-untinted
 * 6. negative
 * 7. negative-secondary
 * 8. ghost
 *
 * The tinted versions are the default ones, because we will use them quite more often than the untinted ones.
 *
 * You can also pass the colouring and size through the prop buttonStyle, like primary-small or tertiary-micro
 *
 * When dealing with Icons, you can use the `icon` prop if you will use the icon along with a text. For using just a button, please check [these][button with icon] recipes
 */
export const Button: React.FC<ButtonProps> = React.forwardRef(function Button(
  {
    loading,
    children,
    className,
    variant = 'secondary',
    size = '40',
    icon: Icon,
    iconPosition,
    contentWrapper: ContentWrapper = ButtonWrapper,
    type = 'button',
    disabled,
    buttonStyle,
    ...props
  },
  ref
) {
  const { isDarkMode } = useThemeContext()
  const childRef = createRef<HTMLElement>()

  /**
   * Renders the child component while keeping a reference to it. This way we
   * can proxy all click events sent to the button to the child.
   * @param child Child element we are about to insert
   */
  const renderChildren = (child: any) => {
    if (isComponentWithRef(child)) {
      return React.cloneElement(child, { ref: childRef })
    }

    return child
  }

  const onButtonClick = () => {
    // Send click event to the child component
    if (typeof childRef.current?.click === 'function') {
      childRef.current?.click()
    }
  }

  // unpack the buttonStyle
  let variantUnpacked = variant
  let sizeUnpacked = size

  if (buttonStyle) {
    const lastIndex = buttonStyle.lastIndexOf('-')

    if (lastIndex !== -1) {
      variantUnpacked = buttonStyle.slice(0, lastIndex) as ButtonVariant
      sizeUnpacked = buttonStyle.slice(lastIndex + 1) as ButtonSize
    }
  }

  const primaryLoader =
    variant === 'primary' ||
    variant === 'primary-untinted' ||
    variant === 'negative'

  return (
    <StyledButton
      className={classNames('Button', className)}
      disabled={loading || disabled}
      loading={loading}
      variant={variantUnpacked}
      size={sizeUnpacked}
      type={type}
      ref={ref}
      onClick={onButtonClick}
      $alignIconRight={iconPosition === 'right'}
      /**
       * Hack to force refresh styles in Safari
       * after transitioning from disabled to another style
       */
      key={window.safari && disabled ? 'disabled' : 'button'}
      {...props}
    >
      {Icon && (
        <ButtonIconWrapper>
          <Icon role="img" />
        </ButtonIconWrapper>
      )}

      <ContentWrapper>
        {React.Children.map(children, renderChildren)}
      </ContentWrapper>

      {loading && (
        <LoadingWrapper data-testid="button-spinner">
          <LoadingPlaceholder
            primary={primaryLoader}
            size="24px"
            dark={isDarkMode}
          />
        </LoadingWrapper>
      )}
    </StyledButton>
  )
})
