import React from 'react'

import { EditableText } from '@sketch/components'

import {
  DocumentName,
  DocumentNameWrapper,
} from './EditableDocumentName.styles'

interface EditableDocumentNameProps {
  name: string
  editHandler?: (name: string) => void
}

const EditableDocumentName: React.FC<EditableDocumentNameProps> = ({
  name,
  editHandler,
}) => {
  return (
    <DocumentNameWrapper data-testid="document-name">
      {editHandler ? (
        <EditableText
          element={DocumentName}
          value={name}
          onEdit={editHandler}
        />
      ) : (
        <DocumentName>{name}</DocumentName>
      )}
    </DocumentNameWrapper>
  )
}

export default EditableDocumentName
