import React, { useEffect, useMemo } from 'react'
import { Modifier } from '@popperjs/core'

import {
  BaseResponsiveDropdownProps,
  useResponsiveDropdown,
  UseResponsiveDropdownProps,
} from '@sketch/components'

const OFFSET = [0, 0] as [number, number]
const MODIFIERS: Partial<Modifier<any, any>>[] = [
  {
    name: 'flip',
    options: {
      allowedAutoPlacements: ['top', 'bottom'],
      flipVariations: false,
    },
  },
  {
    name: 'computeStyles',
    options: { adaptive: true, gpuAcceleration: true },
  },
  // This custom modifier will re-calculate the Popper position when the popper element is resized
  // We use it to reposition the dropdown when the content changes (for example, from a loading skeleton to the actual content)
  {
    name: 'recalculatePosition',
    phase: 'afterWrite',
    enabled: true,
    fn: () => {},
    effect: ({ state, instance }) => {
      const resizeObservable = new ResizeObserver(() => {
        instance.update()
      })

      resizeObservable.observe(state.elements.popper)

      return () => {
        resizeObservable.disconnect()
      }
    },
  },
]

const DROPDOWN_HIDE_TRIGGER = ['modalHide' as const]

type UseAnnotationPopoverBaseProps = {
  parentWrapper: React.RefObject<HTMLElement>
}

const useAnnotationPopover = <T extends {}>(
  params: UseResponsiveDropdownProps<T & BaseResponsiveDropdownProps> &
    UseAnnotationPopoverBaseProps
) => {
  const {
    dropdown,
    dropdownProps,
    parentWrapper,
    onHide,
    ...otherParams
  } = params

  const annotationModifiers = useMemo(
    () => [
      ...MODIFIERS,
      {
        name: 'preventOverflow',
        options: {
          boundary: parentWrapper.current,
          altBoundary: true,
          altAxis: true,
        },
      },
    ],
    [parentWrapper]
  )

  const dropdownBag = useResponsiveDropdown({
    dropdown,
    dropdownProps,
    placement: 'bottom-end',
    hide: DROPDOWN_HIDE_TRIGGER,
    modifiers: annotationModifiers,
    offset: OFFSET,
    useModalRoot: true,
    onHide,
    ...otherParams,
  })

  const { setVisible } = dropdownBag[2]

  /**
   * Open the dropdown right when rendered
   */
  useEffect(() => {
    setVisible(true)
  }, [setVisible])

  return dropdownBag
}

export default useAnnotationPopover
