import { useQuery as useOriginalQuery } from '@apollo/react-hooks'
import { OperationVariables } from 'apollo-client'
import { DocumentNode } from 'graphql'
import { QueryResult, QueryHookOptions } from './useQuery.types'

import { captureCacheMiss } from './captureCacheMiss'
import { useShouldInvalidatePrevious } from './useShouldInvalidatePrevious'

let _usageMetricsHook:
  | (<Data, Variables>(
      result: QueryResult<Data, Variables>,
      query: DocumentNode
    ) => void)
  | null = null

export const hookUsageMetrics = (
  usageMetricsHook: <Data, Variables>(
    result: QueryResult<Data, Variables>,
    query: DocumentNode
  ) => void
) => {
  _usageMetricsHook = usageMetricsHook
}

export function useQuery<Data = any, Variables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<Data, Variables>
): QueryResult<Data, Variables> {
  // just omit shouldInvalidatePrevious prop from passing props forward
  const { shouldInvalidatePrevious, ...restOptions } = options || {}
  const result = useOriginalQuery(query, restOptions)

  captureCacheMiss(query, options, result)

  if (useShouldInvalidatePrevious<Data, Variables>(options, result)) {
    Object.assign(result, { data: undefined, error: undefined })
  }

  // exclude this from production build
  if (
    process.env.REACT_APP_ENV === 'dev' ||
    process.env.REACT_APP_ENV === 'test'
  ) {
    _usageMetricsHook?.(result, query)
  }

  return result
}
