import {
  getAllTemplatesForVersionedRoute,
  routes,
  CWV_ROUTES,
} from '@sketch/modules-common'

const documentSidebarLayoutRoutesRoutes = {
  ARTBOARD_REVISION: getAllTemplatesForVersionedRoute('ARTBOARD_REVISION'),
  ARTBOARD_DETAIL: getAllTemplatesForVersionedRoute('ARTBOARD_DETAIL'),
  SHARE_VIEW: getAllTemplatesForVersionedRoute('SHARE_VIEW'),
  SHARE_UNSUBSCRIBE: routes.SHARE_UNSUBSCRIBE.template(),
  SHARE_PAGE_CANVAS_VIEW: getAllTemplatesForVersionedRoute(
    'SHARE_PAGE_CANVAS_VIEW'
  ),
  SHARE_PAGE_VIEW: getAllTemplatesForVersionedRoute('SHARE_PAGE_VIEW'),
  SHARE_PROTOTYPES: getAllTemplatesForVersionedRoute('SHARE_PROTOTYPES'),
  CWV_ROUTES: CWV_ROUTES,
  DESIGN_SYSTEM_PAGES: routes.DESIGN_SYSTEM_PAGES.template(),
}

export const documentSidebarLayoutRoutesRoutePaths = Object.values(
  documentSidebarLayoutRoutesRoutes
).flat()
