import React, { useState } from 'react'

import { useToast } from '@sketch/toasts'

import { Button, Modal, ModalInjectedProps } from '@sketch/components'

import { StyledRadioButton } from './ReportShareModal.styles'

import { useReportShareMutation } from '@sketch/gql-types'
// eslint-disable-next-line no-restricted-imports
import { ShareReportReason } from '@sketch/gql-types/expansive'

const VALID_REASONS: ShareReportReason[] = [
  'INAPPROPRIATE',
  'SPAM',
  'VIOLATED_COPYRIGHT',
]

const isValidReason = (value: string) => {
  return VALID_REASONS.includes(value as ShareReportReason)
}

interface ReportShareModalProps extends ModalInjectedProps {
  identifier: string
}

const ReportShareModal: React.FC<ReportShareModalProps> = props => {
  const { hideModal, identifier } = props
  const { showToast } = useToast()
  const [selectedOption, setSelectedOption] = useState<
    ShareReportReason | undefined
  >()

  const [reportShare, { loading }] = useReportShareMutation({
    onError: 'show-toast',
    onCompleted: () => {
      showToast('Thanks for letting us know — we’re on it!', 'positive')
      hideModal()
    },
  })

  const handleReport = () => {
    if (!selectedOption) return

    reportShare({
      variables: {
        identifier,
        reason: selectedOption,
      },
    })
  }

  const handleOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (!isValidReason(value)) return

    setSelectedOption(value as ShareReportReason)
  }

  return (
    <Modal title="Report this Document?" onCancel={hideModal}>
      <Modal.Body>
        <p>
          Is there something not quite right about this document that we should
          know about?
        </p>
        <p>Select a Reason</p>
        <StyledRadioButton
          name="option"
          label="It contains copyrighted content"
          value="VIOLATED_COPYRIGHT"
          onChange={handleOption}
          checked={selectedOption === 'VIOLATED_COPYRIGHT'}
        />
        <StyledRadioButton
          name="option"
          label="It’s inappropriate"
          value="INAPPROPRIATE"
          onChange={handleOption}
          checked={selectedOption === 'INAPPROPRIATE'}
        />
        <StyledRadioButton
          name="option"
          label="It’s spam"
          value="SPAM"
          onChange={handleOption}
          checked={selectedOption === 'SPAM'}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal} disabled={loading}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleReport}
          loading={loading}
          disabled={!selectedOption}
        >
          Report
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ReportShareModal
