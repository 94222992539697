import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { localStorageKeys } from '@sketch/constants'
import { setItem } from '@sketch/utils'
import {
  GetWorkspaceDocument,
  GetWorkspaceQuery,
  useSignInWithSsoMutation,
} from '@sketch/gql-types'
import { useAsync } from 'react-use'
import {
  useAnalytics,
  useQueryParams,
  createSsoAuthorization,
  replaceAllAuthorizations,
  setUserAuthorization,
  useFetchInitialUser,
} from '@sketch/modules-common'
import { ErrorHandler } from '@sketch/tracing'
import { useApolloClient } from '@apollo/react-hooks'

type Loading = { type: 'loading' }
type SuccessCloud = { type: 'success-cloud'; from?: string }
type SuccessCloudPopup = { type: 'success-cloud-popup'; workspaceName: string }
type SuccessSketchApp = {
  type: 'success-sketch-app'
  exchangeToken: string
  samlSessionId?: string
}
type Error = { type: 'error' }

export type State =
  | Loading
  | SuccessCloud
  | SuccessSketchApp
  | SuccessCloudPopup
  | Error

export const useSsoSuccessfulSignIn = () => {
  const { location } = useHistory()

  const fetchInitialUser = useFetchInitialUser()
  const [state, setState] = useState<State>({ type: 'loading' })
  const queryParams = useQueryParams<'SSO_SUCCESSFUL_SIGN_IN'>()
  const apolloClient = useApolloClient()
  const { trackEvent } = useAnalytics()

  const [signInWithSso] = useSignInWithSsoMutation({
    onError: () => {
      setState({ type: 'error' })
    },
    onCompleted: async data => {
      const workspaceId = data.signInWithSso!.workspaceId!
      const credentials = data.signInWithSso!.credentials!
      const newAuthorization = createSsoAuthorization(workspaceId, credentials)

      const previousUser = await fetchInitialUser()

      setUserAuthorization(apolloClient.cache, newAuthorization)

      const newUser = await fetchInitialUser()

      if (!newUser) {
        ErrorHandler.shouldNeverHappen(
          `Could not get user after successful SSO sign in`
        )
        setState({ type: 'error' })
        return
      }

      /**
       * If we are trying to login with an entirely different user then we first
       * sign out from all other credentials and use the new one.
       */
      if (previousUser?.identifier !== newUser.identifier) {
        replaceAllAuthorizations(newAuthorization)
      }

      setItem(localStorageKeys.lastWorkspaceIdKey, workspaceId)

      if (queryParams.source === 'cloud-popup') {
        const { data } = await apolloClient.query<GetWorkspaceQuery>({
          query: GetWorkspaceDocument,
          variables: { identifier: workspaceId },
        })

        const workspaceName = data.workspace.name || ''
        setState({ type: 'success-cloud-popup', workspaceName })
      } else {
        setState({ type: 'success-cloud', from: queryParams.from })
      }

      trackEvent('Successful SignIn', { type: 'sso' })
    },
  })

  const signInToCloudWithSso = (exchangeToken: string) => {
    signInWithSso({
      variables: { exchangeToken },
    })
  }

  useAsync(async () => {
    if (state.type !== 'loading') return

    try {
      if (!queryParams.token) {
        setState({ type: 'error' })
        return
      }

      switch (queryParams.source) {
        case 'sketch':
          setState({
            type: 'success-sketch-app',
            exchangeToken: queryParams.token!,
            samlSessionId: queryParams.s,
          })
          break
        case 'cloud':
        default:
          signInToCloudWithSso(queryParams.token!)
          break
      }
    } catch (error) {
      setState({ type: 'error' })
    }
  }, [state, location])

  return { state }
}
