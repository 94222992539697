import styled from 'styled-components'

import { Link as LinkUnstyled } from '../Link'

export const List = styled.ol`
  display: inline-block;
  counter-reset: steps;
  padding: 0;
  margin: 0;
  z-index: 1;
  line-height: 1;
`

export const Link = styled(LinkUnstyled)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`
