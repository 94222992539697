import styled, { css } from 'styled-components'
import { breakpoint } from '@sketch/global-styles'

import { ReactComponent as CheckMarkIcon } from '@sketch/icons/checkmark-small-16'

interface DotProps {
  $flavour: 'unread' | 'read' | 'resolved' | 'new'
}

const BACKGROUND_COLOR = {
  unread: css`
    background-color: ${({ theme }) => theme.colors.sketch.A};
  `,
  read: css`
    background-color: ${({ theme }) =>
      theme.colors.ui.annotations.dotBackgroundRead};
  `,
  resolved: css`
    background-color: ${({ theme }) => theme.colors.state.positive.A};
  `,
  new: css`
    background-color: hsl(207, 100%, 51%);
  `,
}

export const CheckMark = styled(CheckMarkIcon)`
  position: absolute;

  width: 16px;
  height: 16px;

  top: 50%;
  left: 50%;
  /* The button is centered on its origin so it can be placed simply with left and top */
  transform: translate3d(-50%, -50%, 0);
`

export const Dot = styled.span<DotProps>(
  ({ theme, $flavour }) => css`
    display: flex;

    justify-content: center;
    align-items: center;

    position: relative;

    min-width: 14px;
    min-height: 14px;
    border-radius: 999px;

    line-height: 1;
    font-size: 0.5625rem; /* stylelint-disable-line scales/font-size */
    font-weight: 500;
    font-variant-numeric: tabular-nums;
    color: ${theme.colors.ui.annotations.dotBackground};

    padding: 2px 4px; /* stylelint-disable-line scales/space */

    /* The button is centered on its origin so it can be placed simply with left and top */
    transform: translate3d(-50%, -50%, 0);

    transition: all 0.05s ease-out;

    ${BACKGROUND_COLOR[$flavour || 'read']}

    box-shadow: 0 0 0 2px ${theme.colors.ui.annotations
      .dropShadowInner},0 0 0 3px hsla(0, 0%, 0%, .08),
      0 1px 2px 4px ${theme.colors.ui.annotations.dropShadowOuter};

    &:hover {
      min-width: 16px;
      min-height: 16px;
      padding: 3px 5px; /* stylelint-disable-line scales/space */
    }

    &[aria-selected='true'] {
      min-width: 22px;
      min-height: 22px;

      ${CheckMark} {
        width: 24px;
        height: 24px;
      }
    }

    /* Make the dot touch area bigger in mobile */
    ${breakpoint('base', 'sm')`${css`
      &::before {
        content: '';

        width: 40px;
        height: 40px;

        position: absolute;

        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        border-radius: 999px;
        user-select: none;
      }
    `}`}
  `
)
