import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "sidebar-24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M17.116 4.75c.79 0 1.087.056 1.38.212.233.125.417.309.542.543.156.292.212.589.212 1.38h0v10.23c0 .791-.056 1.088-.212 1.38a1.305 1.305 0 01-.543.543c-.292.156-.589.212-1.38.212h0-10.23c-.791 0-1.088-.056-1.38-.212a1.305 1.305 0 01-.543-.543c-.156-.292-.212-.589-.212-1.38h0V6.886c0-.791.056-1.088.212-1.38.125-.234.309-.418.543-.543.292-.156.589-.212 1.38-.212h0z", stroke: "currentColor", strokeWidth: 1.5 }),
        React.createElement("path", { fillOpacity: 0.15, fill: "currentColor", d: "M14 5h5v14h-5z" }),
        React.createElement("path", { fill: "currentColor", d: "M13 5h1.5v14H13z" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
