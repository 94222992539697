import React from 'react'

import styled, { css } from 'styled-components'

const SsoBadge = styled.span(
  ({ theme }) => css`
    margin-left: 8px;
    line-height: 1.2;
    font-size: ${theme.fontSizes.C};
    font-weight: ${theme.fontWeights.regular};

    a:not(.active) & {
      color: ${theme.colors.foreground.secondary.D};
    }
  `
)

export const SsoTag = ({ className }: { className?: string }) => (
  <SsoBadge className={className}>SSO</SsoBadge>
)
