import React from 'react'
import { CSSTransition } from 'react-transition-group'

type CSSTransitionProps = Pick<
  React.ComponentProps<typeof CSSTransition>,
  'onExited'
>

interface ModalTransitionProps extends CSSTransitionProps {
  show: boolean
  ref?: React.RefObject<HTMLDivElement>
}

export const ModalTransition: React.FC<ModalTransitionProps> = ({
  show: showModal,
  ref,
  ...props
}) => (
  <CSSTransition
    timeout={300}
    mountOnEnter
    unmountOnExit
    in={showModal}
    classNames="modal"
    nodeRef={ref}
    {...props}
  />
)
