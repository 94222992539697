import React from 'react'

import { useIsNodeContentTruncated } from '@sketch/utils'

import { Tooltip } from '@sketch/components'

import { privacyIcon } from '../ProjectListItem'

import {
  DisabledProject,
  DisabledProjectName,
  Icon,
} from './ProjectsList.styles'

import { ProjectInSidebarAndHeaderFragment } from '@sketch/gql-types'

interface DisabledProjectItemsProps {
  project: ProjectInSidebarAndHeaderFragment
}

/**
 *
 * DisabledProjectItem
 *
 * Renders a disabled version of a Project item used in the Sidebar for the projects section
 */
const DisabledProjectItem: React.FC<DisabledProjectItemsProps> = ({
  project,
}) => {
  const [isNodeContentTruncated, truncatedNodeRef] = useIsNodeContentTruncated()

  return (
    <Tooltip
      key={project.identifier}
      placement="right"
      content={project.name}
      disabled={!isNodeContentTruncated}
    >
      <DisabledProject aria-disabled="true">
        <Icon as={privacyIcon(project)} />{' '}
        <DisabledProjectName ref={truncatedNodeRef}>
          {project.name}
        </DisabledProjectName>
      </DisabledProject>
    </Tooltip>
  )
}

export default DisabledProjectItem
