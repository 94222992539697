import { SearchOptionsObject, NotificationType } from '../types'

const convertToUTC = (dateString: string): string => {
  const date = new Date(dateString)

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  ).toISOString()
}

const updateDate = (type: string, s: string) => {
  const from = s.split(`${type}:`)[1].split(' ')[0]
  const newFrom = convertToUTC(from)
  return s.replace(from, newFrom)
}

const getUTCSearch = (search: string) => {
  let newSearch = search

  // converts any dates to UTC
  if (newSearch.includes('from:')) {
    newSearch = updateDate('from', newSearch)
  }

  if (newSearch.includes('to:')) {
    newSearch = updateDate('to', newSearch)
  }

  return newSearch
}

const replaceNotificationType = ({
  updatedSearch,
  option,
}: {
  updatedSearch: string
  option: NotificationType
}) => {
  if (updatedSearch.includes(`type:${option}`)) {
    // remove option from search
    return updatedSearch.replace(`type:${option}`, '').replace(/  +/g, ' ')
  }
  return `${updatedSearch} type:${option}`.replace(/  +/g, ' ')
}

export const getSearchString = ({
  search,
  options,
}: {
  search: string
  options: SearchOptionsObject
}) => {
  const notificationTypes = [
    'comments',
    'replies',
    'mentions',
    'downloadableAssets',
    'starredUpdates',
  ] as NotificationType[]

  let updatedSearch = search

  // NOTIFICATION TYPES
  notificationTypes.forEach(option => {
    // if it's not checked, we skip it
    if (options[option] === false) {
      return
    }
    updatedSearch = replaceNotificationType({ updatedSearch, option })
  })

  const { location, users } = options

  // LOCATIONS
  if (location === null) {
    // remove locations from search
    updatedSearch = updatedSearch
      .replace(/project:([^ ]+)/, '')
      .replace(/share:([^ ]+)/, '')
      .replace(/  +/g, ' ')
  } else {
    const locationString = `${location.type}:${location.identifier}`

    if (updatedSearch.includes(locationString)) {
      // remove option from search
      updatedSearch = updatedSearch
        .replace(locationString, '')
        .replace(/  +/g, ' ')
    } else {
      updatedSearch = locationString.replace(/  +/g, ' ')
    }
  }

  // USERS
  if (users.length === 0) {
    // remove locations from search
    updatedSearch = updatedSearch
      .replace(/user:([^ ]+)/, '')
      .replace(/  +/g, ' ')
  }

  users.forEach(user => {
    const userString = `user:${user.identifier}`

    if (updatedSearch.includes(userString)) {
      // remove option from search
      updatedSearch = updatedSearch.replace(userString, '').replace(/  +/g, ' ')
    } else {
      updatedSearch = userString.replace(/  +/g, ' ')
    }
  })

  // DATES
  const dateTypes = ['from', 'to']

  dateTypes.forEach(dateType => {
    if (updatedSearch.includes(`${dateType}:`)) {
      // remove previous date
      const startRemoval = updatedSearch.indexOf(dateType)
      updatedSearch = (
        updatedSearch.slice(0, startRemoval) +
        updatedSearch.slice(startRemoval + dateType.length + 11)
      ).replace(/  +/g, ' ')
    }
  })

  if (options.from !== '') {
    updatedSearch = `${updatedSearch} from:${options.from}`.replace(/  +/g, ' ')
  }

  if (options.to !== '') {
    updatedSearch = `${updatedSearch} to:${options.to}`.replace(/  +/g, ' ')
  }

  updatedSearch = getUTCSearch(updatedSearch)

  return updatedSearch
}
