import React from 'react'
import { useField } from 'formik'

import { countries } from '@sketch/constants'
import { Form, Select, SelectOption } from '@sketch/components'

const countryList = Object.entries(countries)

interface CountryFieldProps {
  name: string
  disabled?: boolean
}

const CountryField: React.FC<CountryFieldProps> = ({ name, disabled }) => {
  const [field, meta, { setValue }] = useField({ name })

  return (
    <Form.Field
      id="country"
      label="Country"
      errorText={meta.touched ? meta.error : undefined}
    >
      <Select {...field} onChange={setValue} disabled={disabled}>
        {countryList.map(([countryCode, country]) => (
          <SelectOption key={countryCode} value={countryCode}>
            {country}
          </SelectOption>
        ))}
      </Select>
    </Form.Field>
  )
}

export default CountryField
