import { ApolloClient } from 'apollo-client'
import { ApolloCache } from 'apollo-cache'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { refreshToken, checkIfTokenIsExpired } from '../oauth-fetcher'
import * as Sockets from '../../websockets/create'
import {
  GetUserCredentialsDocument,
  GetUserCredentialsQuery,
} from '@sketch/gql-types'
import { IS_EMBEDDED } from '@sketch/constants'
import { log } from '@sketch/utils'
import { getActiveAuthorization } from '@sketch/modules-common'

let pendingRefreshTokenAction: Promise<boolean> | null

export const createSocketLink = (
  getClient: () => ApolloClient<NormalizedCacheObject>,
  cache: ApolloCache<NormalizedCacheObject>
) => {
  // Return a noop link while embedded. We don't want embeds to be opening
  // subscriptions, whether authenticated or not.
  if (IS_EMBEDDED) return new ApolloLink()

  log('Socket -> Creating a new socket')
  const connection = Sockets.createAuthenticatedSocketWithLink(
    undefined,
    getClient
  )
  const authorization = getActiveAuthorization(cache)
  let currentAuthToken = authorization?.fragment.authToken || ''

  cache.watch({
    query: GetUserCredentialsDocument,
    optimistic: false,
    callback: async cachedValue => {
      const userCredentials = cachedValue.result as GetUserCredentialsQuery
      const newAuthToken = userCredentials.userCredentials?.authToken

      if (currentAuthToken === newAuthToken) {
        log('Socket -> Credentials changed but token did not')
        return
      }

      if (!newAuthToken) {
        log('Socket -> Credentials changed but token isEmpty')
        connection.socket.disconnect()
        return
      }

      currentAuthToken = newAuthToken

      /**
       * Calling `conn.close` is the recommended method to reconnect
       * the websocket with new authentication parameters
       *
       * `conn` property is only available when WebSocket connection is
       *  established, so we need to check if it is available before executing
       *
       * Seems like `conn` is not defined in TypeScript types as it is
       * an internal property, that's why we cast it to any before checking
       * the availability
       *
       * https://hexdocs.pm/absinthe/apollo.html#reconnecting-the-websocket-link
       */
      const connectedSocket = connection.socket as any
      if (connectedSocket.conn) {
        log('Socket -> Refreshing socket connection with new credentials')
        connectedSocket.conn.close()
      } else {
        log('Socket -> Open socket because there was no connection')
        await connection.socket.connect()
      }
    },
  })

  /**
   * Context to check if current authorization is expired
   * and refresh it accordingly
   *
   * Apollo Link does not allow async functions, so Apollo
   * recommends to use setContext link for this use case
   *
   * https://github.com/apollographql/apollo-client/issues/2441#issuecomment-340819525
   */
  const checkExpiredToken = setContext(async (_operation, context) => {
    const credentials = getActiveAuthorization(cache)
    if (!credentials) {
      return context
    }

    const isTokenExpired = checkIfTokenIsExpired(
      credentials.fragment.expirationDateTime ?? '0'
    )

    if (!isTokenExpired) {
      return context
    }

    log('Socket -> Token is expired')

    if (!pendingRefreshTokenAction) {
      pendingRefreshTokenAction = refreshToken(getClient(), credentials)
    }

    await pendingRefreshTokenAction
    // eslint-disable-next-line require-atomic-updates
    pendingRefreshTokenAction = null

    return context
  })

  const link = new ApolloLink((operation, forward) => {
    return connection.link.request(operation, forward)
  })

  return ApolloLink.from([checkExpiredToken, link])
}
