import React from 'react'
import { Banner, ConfirmationDialogProps } from '@sketch/components'

interface CreatePublishDocumentProps {
  shareName: string
  isProfileCreated: boolean
}

type PublishModalContent = Pick<CreatePublishDocumentProps, 'shareName'>

export type BaseModalProps = Pick<
  ConfirmationDialogProps,
  'title' | 'confirmButton'
> & { children: React.ReactNode }

const PublishDocumentContent: React.FC<PublishModalContent> = ({
  shareName,
}) => {
  return (
    <>
      <p>
        After you publish “<strong>{shareName}</strong>”, everybody will be able
        to view and download it from the Workspace profile.
      </p>
      <p>
        Only the last saved or starred version will be displayed. Updates are
        automatically published.
      </p>
    </>
  )
}

const PublishAndCreateDocumentContent: React.FC<PublishModalContent> = ({
  shareName,
}) => {
  return (
    <>
      <p>
        You can publish “<strong>{shareName}</strong>” right away using an
        automatically created public profile.
      </p>
      <p>
        After you publish, everybody will be able to view and download the
        document. Only the last saved or starred version will be displayed, and
        updates are automatically published.
      </p>
      <Banner type="warning" showIcon={false}>
        You can edit public profile details and the URL at any time from your
        Workspace settings.
      </Banner>
    </>
  )
}

export const createPublishDocumentModal = ({
  shareName,
  isProfileCreated,
}: CreatePublishDocumentProps): BaseModalProps => {
  if (isProfileCreated) {
    return {
      title: 'Publish Document?',
      confirmButton: { text: 'Publish', variant: 'primary' },
      children: <PublishDocumentContent shareName={shareName} />,
    }
  }

  return {
    title: 'Create a Public Profile and Publish?',
    confirmButton: { text: 'Create Profile & Publish', variant: 'primary' },
    children: <PublishAndCreateDocumentContent shareName={shareName} />,
  }
}
