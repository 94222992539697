import React from 'react'

import { LoadingState } from '@sketch/components'

import { IndexLayoutHeader, IndexLayoutHeaderProps } from './IndexLayoutHeader'
import { Wrapper } from './IndexLayout.styles'

export const IndexLayoutLoading: React.FC<IndexLayoutHeaderProps> = ({
  headerLink,
}) => (
  <Wrapper>
    <IndexLayoutHeader headerLink={headerLink} />
    <LoadingState />
  </Wrapper>
)
