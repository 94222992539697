import React from 'react'

import { ModalRestrictor } from '@sketch/components'

import { CollectionItemGridView } from './CollectionItemGridView'
import { CollectionItemListView } from './CollectionItemListView'

import { CollectionPreviewsFragment } from '@sketch/gql-types'
import { RenderCollectionDropdown } from './types'

interface CollectionItemProps {
  collection: CollectionPreviewsFragment
  projectIdentifier: string
  workspaceIdentifier: string
  onClick?: React.MouseEventHandler<HTMLElement>
  renderDropdown: RenderCollectionDropdown
  presentation: 'grid' | 'list'
}

const CollectionItem: React.FC<CollectionItemProps> = ({
  collection,
  projectIdentifier,
  workspaceIdentifier,
  presentation,
  onClick,
  renderDropdown,
}) => {
  const Component =
    presentation === 'grid' ? CollectionItemGridView : CollectionItemListView

  return (
    <ModalRestrictor>
      <Component
        data-testid="collection-item"
        collection={collection}
        projectIdentifier={projectIdentifier}
        workspaceIdentifier={workspaceIdentifier}
        renderDropdown={() => renderDropdown(collection)}
        onClick={onClick}
      />
    </ModalRestrictor>
  )
}

export default CollectionItem
