import {
  GetShareDropdownOptionsQuery,
  useGetShareDropdownOptionsQuery,
} from '@sketch/gql-types'
import { DownloadProps, getDownloadProps } from '../utils'

interface DropdownOptionsLoading {
  loading: true
  error?: false
  data?: undefined
}

interface DropdownOptionsError {
  loading?: false
  error: true
  data?: undefined
}
interface DropdownOptionsData {
  loading: false
  error: false
  data: DropdownOptionsPayload
}

type ShareForDownloadOptions = NonNullable<
  GetShareDropdownOptionsQuery['share']
>
type DocumentForDownloadOptions = NonNullable<
  NonNullable<ShareForDownloadOptions['version']>['document']
>

interface DropdownOptionsPayload {
  download: DownloadProps
  share: ShareForDownloadOptions
  document: DocumentForDownloadOptions
}

export type DropdownOptions =
  | DropdownOptionsLoading
  | DropdownOptionsError
  | DropdownOptionsData

export interface UseGetShareDropdownOptionsProps {
  share: { identifier: string }
  version: { shortId: string }
  skip?: boolean
}

export const useGetShareDropdownOptions = (
  props: UseGetShareDropdownOptionsProps
): DropdownOptions => {
  const { loading, data, error } = useGetShareDropdownOptionsQuery({
    variables: {
      shareIdentifier: props.share.identifier,
      versionShortId: props.version.shortId,
    },
    skip: props.skip,
  })

  if (loading || props.skip) return { loading: true }

  const share = data?.share
  const document = data?.share?.version?.document

  if (error || !share || !document) return { error: true }

  const download = getDownloadProps(share.version)

  return {
    data: { download, share, document },
    error: false,
    loading: false,
  }
}
