import React from 'react'
import { ApolloError } from 'apollo-client'
import { GraphQLError } from 'graphql'

import { ReactComponent as WarningTriangle } from '@sketch/icons/warning-triangle-64'

import { ProjectFragment } from '@sketch/gql-types'

import { ErrorMessage } from '@sketch/components'
import ProjectRequestAccess from '../ProjectRequestAccess'

const PENDING_PROJECT_REQUEST = 'PENDING_REQUEST'
const REQUEST_ACCESS_REASON = [
  'RESTRICTED_TO_PROJECT_MEMBERS',
  PENDING_PROJECT_REQUEST,
]

interface ProjectCollectionErrorProps {
  projectId: ProjectFragment['identifier']
  error?: ApolloError
  type: 'project' | 'collection'
}

const errorCopy = {
  project: {
    NOT_FOUND: {
      title: 'Project not available',
      description:
        'The project you’re looking for might have been deleted, or you don’t have the right permissions to view it.',
    },
    GENERIC: {
      title: 'Something went wrong while loading the project',
    },
  },
  collection: {
    NOT_FOUND: {
      title: 'Collection not available',
      description: "The collection you're looking for might have been removed",
    },
    GENERIC: {
      title: 'Something went wrong while loading the collection',
    },
  },
}

function hasGraphQLErrorCode(
  apolloError: ApolloError,
  errorCode: string
): GraphQLError | undefined {
  return apolloError.graphQLErrors.find(
    error => error.extensions?.code === errorCode
  )
}

const ProjectCollectionError: React.FC<ProjectCollectionErrorProps> = ({
  projectId,
  error,
  type,
}) => {
  if (error) {
    const forbiddenError = hasGraphQLErrorCode(error, 'FORBIDDEN')

    if (
      forbiddenError &&
      REQUEST_ACCESS_REASON.includes(forbiddenError.extensions?.reason)
    ) {
      return (
        <ProjectRequestAccess
          projectShortId={projectId!}
          hasPendingRequest={
            forbiddenError.extensions!.reason === PENDING_PROJECT_REQUEST
          }
        />
      )
    }

    if (hasGraphQLErrorCode(error, 'NOT_FOUND')) {
      return (
        <ErrorMessage
          icon={<WarningTriangle />}
          title={errorCopy[type].NOT_FOUND.title}
          description={errorCopy[type].NOT_FOUND.description}
        />
      )
    }
  }

  return (
    <ErrorMessage.Generic
      icon={<WarningTriangle />}
      title={errorCopy[type].GENERIC.title}
    />
  )
}

export default ProjectCollectionError
