import { localStorageKeys } from '@sketch/constants'
import { getItem } from '@sketch/utils'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { DocumentNode, FieldNode, OperationDefinitionNode } from 'graphql'
import {
  getDummyDataSettings,
  getFactoryModule,
  getIsDummyDataEnabled,
} from '@sketch/devtools'

let _isRunningCypressTests: boolean | null = null
const getIsRunningCypressTests = () => {
  if (_isRunningCypressTests !== null) return _isRunningCypressTests

  _isRunningCypressTests =
    getItem(localStorageKeys.isRunningCypressTests) === 'true'

  return _isRunningCypressTests
}

const isDummyOperation = (queryDoc: DocumentNode): boolean => {
  const opDefinition = queryDoc.definitions.find(
    x => x.kind === 'OperationDefinition'
  ) as OperationDefinitionNode | undefined

  // we are interested only in root field, as if `@client` directive is used
  // somewhere deeper - Cypress stills receives a request from web app and it is
  // still able to generate data for the whole query / mutation.
  const rootField = opDefinition?.selectionSet.selections[0] as
    | FieldNode
    | undefined

  if (!rootField) return false

  const firstDirective = rootField.directives?.[0]
  if (!firstDirective) return false

  if (firstDirective.name.value === 'dummy') {
    return true
  }

  return false
}

export const createDummyDataLink = () => {
  return new ApolloLink((operation, forward) => {
    const isRunningCypressTests = getIsRunningCypressTests()
    const isDummyDataEnabled = getIsDummyDataEnabled()
    if (
      isRunningCypressTests ||
      !isDummyDataEnabled ||
      !isDummyOperation(operation.query)
    ) {
      return forward(operation)
    }

    const dummyDataLink = createHttpLink({
      fetch: async (input, info) => {
        const factoryModule = await getFactoryModule()

        if (!factoryModule) {
          return fetch(input, info)
        }
        const { getDummyDataFetch } = factoryModule

        const dummyFetch = getDummyDataFetch({
          operation,
          getDummyDataSettings,
        })

        return dummyFetch(input, info)
      },
    })

    return dummyDataLink.request(operation, forward)
  })
}
