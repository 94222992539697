import React from 'react'
import isEqual from 'react-fast-compare'

import { BaseResponsiveDropdownProps } from '@sketch/components'

import AnnotationCommentsList from '../AnnotationCommentsList'
import AnnotationPopoverWrapper from '../AnnotationPopoverWrapper'
import AnnotationPopoverOptions from '../AnnotationPopoverOptions'

import { useAnnotationOverlayContext } from '../../hooks'

import { AnnotationPopoverListWrapper } from './AnnotationPopover.styles'

interface AnnotationPopoverProps extends BaseResponsiveDropdownProps {
  annotationIdentifier: string
  cacheOnly?: boolean
}

const AnnotationPopover = (props: AnnotationPopoverProps) => {
  const { annotationIdentifier, cacheOnly, currentVariant, hide } = props

  const context = useAnnotationOverlayContext()
  const isModalVariant = currentVariant === 'modal'

  return (
    <AnnotationPopoverWrapper>
      <AnnotationPopoverListWrapper $isModalVariant={isModalVariant}>
        <AnnotationCommentsList
          annotationIdentifier={annotationIdentifier}
          isViewingLatestVersion={Boolean(context?.isViewingLatestVersion)}
          cacheOnly={cacheOnly}
          commentType="FLOATING"
          header={
            <AnnotationPopoverOptions
              annotationIdentifier={annotationIdentifier}
              onModalHide={currentVariant && hide}
              isModalVariant={isModalVariant}
            />
          }
        />
      </AnnotationPopoverListWrapper>
    </AnnotationPopoverWrapper>
  )
}

export default React.memo(AnnotationPopover, isEqual)
