import React, { useEffect, useState } from 'react'
import PrivateRoute from '../../../../containers/PrivateRoute'
import { RouteProps, Route, RouteComponentProps } from 'react-router-dom'
import {
  WorkspaceMinimalFragment,
  useGetWorkspaceQuery,
} from '@sketch/gql-types'
import {
  NotFoundView,
  GenericErrorView,
  setActiveAuthorizationIdForWorkspace,
  useUserAuthorizations,
  mapSsoStartUrl,
  DynamicLoadingPage,
} from '@sketch/modules-common'
import { setItem, getItem, removeItem } from '@sketch/utils'
import { localStorageKeys } from '@sketch/constants'
import { useApolloClient } from '@apollo/react-hooks'
import { currentWorkspaceContext } from './currentWorkspaceContext'

export interface PrivateWorkspaceRouteExtraProps {
  workspace: WorkspaceMinimalFragment
}

interface WorkspaceDataProps extends PrivateWorkspaceRouteExtraProps {
  routeProps: React.PropsWithChildren<WorkspaceRouteProps>
}

const WorkspaceAuth: React.FC<WorkspaceDataProps> = ({
  workspace,
  routeProps: { component: Component, render, ...routeProps },
}) => {
  const { authorizations, hasAccessToWorkspace } = useUserAuthorizations()
  const { customer } = workspace
  // `Customer` is not available if the Workspace is a Personal type
  const { ssoStartUrl } = customer || {}

  useEffect(() => {
    setItem(localStorageKeys.lastWorkspaceIdKey, workspace.identifier)
  }, [workspace.identifier])

  const shouldRedirectSso =
    authorizations.length > 0 && !hasAccessToWorkspace(workspace)

  if (shouldRedirectSso && ssoStartUrl) {
    window.location.href = mapSsoStartUrl(ssoStartUrl, {
      from: routeProps.location?.pathname,
    })

    return <DynamicLoadingPage />
  }

  /**
   * <Route component> takes precedence over <Route render> so don’t use both in the same <Route>.
   *
   * from: https://v5.reactrouter.com/web/api/Route/render-func
   */
  if (Component) {
    return (
      <Route
        {...routeProps}
        render={routeProps => (
          <Component {...routeProps} workspace={workspace} />
        )}
      />
    )
  }

  if (render) {
    return (
      <Route
        {...routeProps}
        render={routeProps =>
          render({
            ...routeProps,
            workspace,
          })
        }
      />
    )
  }

  return null
}

interface WorkspacesDataProps {
  workspaceId: string
  routeProps: React.PropsWithChildren<WorkspaceRouteProps>
}

const WorkspacesAuth: React.FC<WorkspacesDataProps> = ({
  workspaceId,
  routeProps,
}) => {
  const { loading, error, data } = useGetWorkspaceQuery({
    variables: {
      identifier: workspaceId,
    },
  })
  const apolloClient = useApolloClient()
  const [authId, setAuthId] = useState<string>()

  const workspace = data?.workspace

  useEffect(() => {
    if (workspace && !loading && !error) {
      setActiveAuthorizationIdForWorkspace(
        {
          ssoEnabled: workspace.customer?.ssoEnabled || false,
          identifier: workspace.identifier,
        },
        apolloClient.cache
      )
      setAuthId(workspace.identifier)
    }
  }, [workspace, loading, error, apolloClient.cache])

  if (loading) {
    return <DynamicLoadingPage />
  }

  // Used when we don't want to render the <NotFoundView /> or the
  // <GenericErrorView />
  // Usefull when this component is used in multiple route Switch under the same
  // parent component
  if (routeProps.skipErrorView) return null

  if (error?.graphQLErrors.some(x => x.extensions?.code === 'NOT_FOUND')) {
    return <NotFoundView isInLayout />
  }

  if (!workspace || error) {
    // In case there are problems to load `getWorkspace` query - clean up the
    // `lastWorkspaceId` key from the local storage. There is a possibility to
    // end up in a endless loop always redirected to a GenericErrorView
    // see: https://github.com/sketch-hq/Cloud/issues/14813
    //
    // If someone will ever want to remove this safeguard - make sure to test
    // the steps mentioned in the #14813 ticket
    removeItem(localStorageKeys.lastWorkspaceIdKey)
    return (
      <GenericErrorView
        isInLayout
        error={error}
        onClick={() => (window.location.href = '/c')}
      />
    )
  }

  // Ensure workspace view is only rendered after auth is set
  if (authId !== workspace.identifier) {
    return <DynamicLoadingPage />
  }

  return (
    <currentWorkspaceContext.Provider value={workspace}>
      <WorkspaceAuth workspace={workspace} routeProps={routeProps} />
    </currentWorkspaceContext.Provider>
  )
}

export interface WorkspaceRouteProps
  extends OmitSafe<RouteProps, 'render' | 'component'> {
  component?: React.ComponentType<
    RouteComponentProps<any> & PrivateWorkspaceRouteExtraProps
  >
  render?: (
    props: RouteComponentProps<any> & PrivateWorkspaceRouteExtraProps
  ) => React.ReactNode
  // Prevent <GenericErrorView /> from render
  skipErrorView?: boolean
}

export const PrivateWorkspaceRoute: React.FC<WorkspaceRouteProps> = props => {
  const { component, ...otherProps } = props
  const lastWorkspaceId = getItem(localStorageKeys.lastWorkspaceIdKey)

  return (
    <PrivateRoute
      {...otherProps}
      render={privateRouteProps => {
        const workspaceId =
          privateRouteProps.match.params.workspaceId || lastWorkspaceId

        return <WorkspacesAuth workspaceId={workspaceId!} routeProps={props} />
      }}
    />
  )
}
