import React from 'react'

import { LinkProps, LinkUnstyled } from '@sketch/components'
import { SvgComponent } from '@sketch/icons'

import * as S from './NoticeContentTemplateFullLink.styles'

type WorkspaceSidebarNoticeTemplateFullLinkProps = {
  link: LinkProps
  children: React.ReactNode
  icon: SvgComponent
  /**
   * Allow to disable the link to be text only in case the user does not
   * have the role to perform the action for example. When true it will
   * remove the link and the circled arrow icon
   */
  disableLink?: boolean
}

/**
 * Template for notices where the whole notice is a link.
 * (We also display an right circle arrow to indicate that).
 */
export function NoticeContentTemplateFullLink({
  link,
  children,
  icon,
  disableLink,
}: WorkspaceSidebarNoticeTemplateFullLinkProps) {
  const content = (
    <S.ContentLayout>
      <S.NoticeIcon as={icon} />
      <S.ChildrenContainer>{children}</S.ChildrenContainer>
      {!disableLink && <S.StyledArrowCircleIcon />}
    </S.ContentLayout>
  )

  if (disableLink) {
    return content
  }

  return <LinkUnstyled {...link}>{content}</LinkUnstyled>
}
