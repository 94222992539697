import styled, { css } from 'styled-components'

import { breakpoint } from '@sketch/global-styles'

import { Dropdown } from '@sketch/components'

export const DropdownDivider = styled(Dropdown.Divider)`
  margin: 8px -8px;
`

export const DropdownItem = styled.li`
  list-style: none;
`

export const DropdownWrapper = styled.div<{ isModalVariant?: boolean }>`
  width: 230px;

  ${breakpoint('base', 'xs')`
    width: 100%;
  `}

  ${({ isModalVariant }) =>
    isModalVariant
      ? css`
          width: 100%;
          padding: 0px 6px;
        `
      : css`
          padding: 0px 8px;
        `}
`

export const DropdownHeader = styled.p`
  /*
    Raising specificity with "&&" to avoid a "p" style in "Modal"
    https://github.com/sketch-hq/cloud-frontend/blob/bfbf9dae7e4ab15e508730b27f3da011c8485b31/packages/cloud-frontend/src/components/Modal/Modal.tsx#L111-L114
  */

  && {
    margin: 0;
    padding: 8px 0 8px 12px;
    color: ${({ theme }) => theme.colors.foreground.secondary.D};
    font-size: ${({ theme }) => theme.fontSizes.B};
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    text-transform: uppercase;
  }
`
