import React from 'react'
import { DiscoverItemFragment } from '@sketch/gql-types'

import { Item, ItemWrapper, Title } from './DiscoverInsightList.styles'

interface Link {
  text?: string
  url?: string
}

export interface DiscoverInsightListProps {
  title: string
  subTitle?: string
  titleLink?: Link
  items: DiscoverItemFragment[]
}

const DiscoverInsightList = (props: DiscoverInsightListProps) => {
  const { title, subTitle, titleLink, items } = props

  return (
    <>
      <Title title={title} subTitle={subTitle} titleLink={titleLink} />
      <ItemWrapper data-testid="discovery-insight-list">
        {items.map(item => (
          <Item key={item.id} {...item} />
        ))}
      </ItemWrapper>
    </>
  )
}

export default DiscoverInsightList
