import { useCallback, useContext } from 'react'
import { ExportModal } from './ExportModal'
import { ModalContext } from '@sketch/components'
import { useExportNode, usePRFile } from '@sketch-hq/sketch-web-renderer'
import { ExportSettingsItem } from './types'
import { useExportAndDownloadLayer } from './useExportAndDownloadLayer'
import { ErrorHandler } from '@sketch/tracing'
import { PREVIEW_CANVAS_SIZE } from './ExportModal.styles'
import { ExportPreviewData } from './useExportPreview'

export function useExportModal() {
  const { showModal } = useContext(ModalContext)

  const exportNode = useExportNode()
  const prFile = usePRFile()

  const exportAndDownloadLayer = useExportAndDownloadLayer()

  const openExportModal = useCallback(
    (nodeIdentifier: bigint, elementName: string) => {
      const exportAndDownload = async (exportList: ExportSettingsItem[]) => {
        return await exportAndDownloadLayer(
          nodeIdentifier,
          elementName,
          exportList
        )
      }

      const getExportPreview = async (): Promise<ExportPreviewData | null> => {
        const node = prFile?.findByIdentifier(nodeIdentifier)
        if (!node) {
          return null
        }
        const nodeExtent = node.getAbsoluteExtent()
        try {
          const wScale = PREVIEW_CANVAS_SIZE.width / nodeExtent.width
          const hScale = PREVIEW_CANVAS_SIZE.height / nodeExtent.height
          const targetScale = Math.min(wScale, hScale)
          const pixelRatio = window.devicePixelRatio
          const backingScale = Math.min(1.0, targetScale) * pixelRatio

          const result = await exportNode(nodeIdentifier, {
            format: 'raster',
            backingScale: backingScale,
          })

          return {
            blob: result.blob,
            imageHeight: result.height,
            imageWidth: result.width,
            layerHeight: nodeExtent.height,
            layerWidth: nodeExtent.width,
          }
        } catch (e) {
          ErrorHandler.ignore(e as Error)
          return null
        }
      }

      showModal(ExportModal, { exportAndDownload, getExportPreview })
    },
    [exportAndDownloadLayer, exportNode, prFile, showModal]
  )

  return { openExportModal }
}
