import { ErrorHandler } from '@sketch/tracing'

const sortObjectKeys = <T extends { [key: string]: any }>(object: T): T =>
  Object.fromEntries(
    Object.keys(object)
      .sort()
      .map(keyName => {
        const value =
          typeof object[keyName] === 'object'
            ? sortObjectKeys(object[keyName])
            : object[keyName]

        return [keyName, value]
      })
  ) as T

const getVariablesKey = (variables: { [key: string]: any }) =>
  JSON.stringify(sortObjectKeys(variables))

type MutationRecords = { [key: string]: { timeout: number } | undefined }
type RecordedMutations = {
  [mutationName in string]?: MutationRecords
}

/**
 * The idea is that majority of the mutations registrations will be
 * unregistered when we'll receive corresponding subscription event
 * or an error.
 *
 * However, if for some reasons (e.g. permissions, networking issues,
 * unforeseen events), registered mutations will not be unregistered
 * we want to be aware of these cases, file a bug report and fix it
 * case by case.
 */
const CLEANUP_TIMEOUT = 10000

/**
 * In quite many scenarios mutations and subscriptions are coming in pairs,
 * first FE triggers a mutation, which in returns pushed subscription data
 * back to the FE (including the same client which triggered the mutation)
 *
 * Also, both mutations and subscriptions end up making the same cache
 * and similar UI (e.g. toasts) changes.
 * Due to the latter fact, we don't want that subscription handlers would be
 * executed for the same client which triggered the mutation.
 * Therefore we have this class.
 *
 * The idea is, that when we trigger a mutation and if within really quickly
 * time a subscription comes in with the same variables, we assume, that this
 * subscription was triggered by us, and therefore we should ignore it.
 *
 * However, if subscription data comes later (after a timeout) or with different
 * arguments, we say that this is a different event, and therefore we update the
 * state accordingly.
 */
export class MutationsTracker {
  private recordedMutations: RecordedMutations = {}

  private getTrackedMutationState = (
    mutationName: string,
    variables: Record<string, unknown>
  ) => {
    if (!this.recordedMutations[mutationName]) {
      this.recordedMutations[mutationName] = {}
    }
    const records = this.recordedMutations[mutationName] as MutationRecords
    const variablesKey = getVariablesKey(variables)

    const setValue = (value: { timeout: number } | undefined) => {
      const trackedMutation = records[variablesKey]

      if (trackedMutation) {
        window.clearTimeout(trackedMutation.timeout)
      }
      records[variablesKey] = value
    }

    return { setValue, variablesKey, trackedMutation: records[variablesKey] }
  }

  public registerMutation = (mutationName: string, variables: {}): void => {
    const { setValue, variablesKey } = this.getTrackedMutationState(
      mutationName,
      variables
    )

    setValue({
      timeout: window.setTimeout(() => {
        ErrorHandler.shouldNeverHappen(
          `Mutation "${mutationName}" (variables: ${variablesKey}) should be unregistered, but it wasn't within ${CLEANUP_TIMEOUT} ms`
        )
        setValue(undefined)
      }, CLEANUP_TIMEOUT),
    })
  }

  public unregisterMutation = (mutationName: string, variables: {}): void => {
    this.getTrackedMutationState(mutationName, variables).setValue(undefined)
  }

  public wasMutationFired = (mutationName: string, variables: {}): boolean => {
    const variablesKey = getVariablesKey(variables)
    const records = this.recordedMutations[mutationName]

    return !!records?.[variablesKey]
  }
}
