import React, { CSSProperties, forwardRef } from 'react'
import styled, {
  keyframes,
  css,
  ThemeProps,
  DefaultTheme,
} from 'styled-components'

export interface SkeletonProps {
  className?: string
  delay?: number
  height?: string
  width?: string
  style?: CSSProperties
}

// A smoother skeleton effect (used in BannerCard for example)
const smoothFlow = ({ theme }: ThemeProps<DefaultTheme>) => keyframes`
  0%   {
    background-color:  ${theme.colors.background.tertiary.D};
  }
  100%  {
    background-color: ${theme.colors.background.tertiary.C};
  }
`

export const skeletonFadeStyles = css`
  background-color: ${({ theme }) => theme.colors.background.tertiary.B};
  animation: ${smoothFlow} ${({ theme }) => theme.transitions.duration[7]}
    infinite;
  animation-timing-function: ${({ theme }) =>
    theme.transitions.timing.oscillate};
  animation-direction: alternate;
`
////////////////////////////////////////////////////////////

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`

const SkeletonContainer = styled.div<SkeletonProps>(
  ({ theme, delay = 0, width, height }) => css`
    animation: ${skeletonKeyframes} 2s
      ${theme.transitions.timing.easeOutExponential} infinite;
    animation-delay: ${`${delay}s`};

    background-color: ${theme.colors.skeleton.baseColor};
    background-image: linear-gradient(
      90deg,
      ${theme.colors.skeleton.baseColor},
      ${theme.colors.skeleton.highlightColor},
      ${theme.colors.skeleton.baseColor}
    );
    background-position: -200px 0;
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;

    display: inline-block;

    width: ${width !== undefined ? width : '100%'};
    height: ${height !== undefined ? height : '100%'};

    min-width: ${width === undefined ? '20px' : undefined};
    min-height: ${height === undefined ? '20px' : undefined};
  `
)

export const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>(
  function Skeleton(props, ref) {
    return (
      <SkeletonContainer
        ref={ref}
        data-testid="skeleton"
        aria-busy="true"
        {...props}
      />
    )
  }
)
