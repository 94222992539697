import styled, { css } from 'styled-components'
import { BubbleMenu } from '@tiptap/react'

import { DropdownItemStyle } from '../../Dropdown'

import { ReactComponent as DividerSvg } from '@sketch/icons/dots-4-vertical-divider-32'

export const BubbleMenuStyled = styled(BubbleMenu)(
  ({ theme }) => css`
    display: flex;

    padding: 8px;

    background: ${theme.colors.background.secondary.A};
    background-clip: padding-box;

    box-shadow: ${theme.shadows.boxShadow.outer};
    border: 1px solid ${theme.colors.border.A};
    border-radius: ${theme.radii.xxlarge};

    /* Make the popover on top of the modal */
    z-index: ${theme.zIndex[8]};

    button:focus {
      background: ${theme.colors.background.tertiary.C};
      color: ${theme.colors.foreground.secondary.A};
    }
  `
)

export const BubbleMenuListItem = styled.li`
  list-style: none;
`

export const BubbleMenuItem = styled(DropdownItemStyle)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 32px;
  min-width: 0;

  &:disabled {
    opacity: 0.6;
  }
`

export const BubbleMenuIcon = styled.svg(
  ({ theme }) => css`
    flex: none;
    width: 24px;
    height: 24px;
    color: ${theme.colors.foreground.secondary.D};

    .is-active & {
      color: inherit;
    }
  `
)

export const Divider = styled(DividerSvg)`
  width: 2px;
  height: 32px;
  margin-right: 4px;
  margin-left: 4px;
`
