import styled from 'styled-components'
import CommentInput from '../CommentInput'

const StickyCommentInput = styled(CommentInput)`
  /* Make this always the bottom item near the sidebar end */
  margin-top: auto;

  position: sticky;
  bottom: 0;

  /* This allows the background to use the same colour of the parent */
  /* either AnnotationPopover or the sidebar */
  background-color: ${({ theme }) => theme.colors.background.secondary.A};
`

export default StickyCommentInput
