import styled from 'styled-components'
import { ReactComponent as Document } from '@sketch/icons/document-64'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
`

export const DocumentProcessing = styled(Document)`
  width: 64px;
  height: 64px;
  /* different colour + opacity hack while we don't have this icon with separate paths */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.3;
`
