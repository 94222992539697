import { useParams } from 'react-router'
import { useCallback } from 'react'

import {
  useGetWorkspaceQuery,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

// eslint-disable-next-line no-restricted-imports
import { Roles } from '@sketch/gql-types/expansive'

import { RouteParams } from '../../routes'
import type {
  AnalyticsEventName,
  AnalyticsEventNameWithoutProps,
  AnalyticsEventNameWithProps,
  AnalyticsEventPropsOf,
} from '../plausible'
import { useAnalytics } from '../analytics.context'

const getUserRole = (workspace: WorkspaceMinimalFragment): Roles => {
  if (workspace.userRole === 'ADMIN') return 'ADMIN'

  return workspace.userCanEdit ? 'EDITOR' : 'VIEWER'
}

const composeEventValue = <TEventName extends AnalyticsEventName>(
  target: string,
  type: string,
  props: TEventName extends AnalyticsEventNameWithProps
    ? AnalyticsEventPropsOf<TEventName> & {
        target?: string
        section?: string
      }
    : never
) =>
  props?.section
    ? `${type} - ${props.section}: ${target}`
    : `${type} - ${target}`

export const useTrackEvent = () => {
  const { workspaceId } = useParams<RouteParams<'WORKSPACE_DISCOVER'>>()
  const analytics = useAnalytics()

  const { data } = useGetWorkspaceQuery({
    variables: { identifier: workspaceId },
    fetchPolicy: 'cache-only',
    skip: !workspaceId,
  })

  const currentWorkspace = data?.workspace

  const memoizedTrackEvent = useCallback(
    <TEventName extends AnalyticsEventName>(
      eventName: TEventName,
      eventProps?: TEventName extends AnalyticsEventNameWithProps
        ? AnalyticsEventPropsOf<TEventName> & { target?: string; type?: string }
        : never
    ) => {
      if (eventProps && eventProps.target && eventProps.type) {
        const { target, type, ...newEventProps } = eventProps

        analytics.trackEvent(eventName as AnalyticsEventNameWithProps, {
          ...newEventProps,
          type: composeEventValue(target, type, newEventProps),
          role: currentWorkspace
            ? composeEventValue(
                target,
                getUserRole(currentWorkspace),
                newEventProps
              )
            : 'Unknown',
        })
        return
      }

      analytics.trackEvent(eventName as AnalyticsEventNameWithoutProps)
    },
    [analytics, currentWorkspace]
  )

  return { trackEvent: memoizedTrackEvent }
}
