import { useState } from 'react'
import { matchPath } from 'react-router-dom'
import { captureException } from '@sentry/browser'
import {
  getAllTemplatesForVersionedRoute,
  useUserProfile,
} from '@sketch/modules-common'
import { dataIdFromObject } from '@sketch/graphql-cache'
import {
  useNotificationCreatedSubscription,
  NotificationInfoFragment,
  NotificationStatusFragmentDoc,
  NotificationStatusFragment,
  useGetUserNotificationsCountLazyQuery,
} from '@sketch/gql-types'

interface ArtboardRouteParams {
  permanentArtboardShortId: string
}

const shouldUpdateUserBadge = (
  notification: NotificationInfoFragment,
  previousId?: string
) => {
  if (notification.identifier === previousId) {
    return false
  }

  const match = matchPath<ArtboardRouteParams>(window.location.pathname, {
    path: getAllTemplatesForVersionedRoute('ARTBOARD_DETAIL'),
  })

  // For comments/replies/mentions, we don't want to show the badge if the user is
  // already in the artboard
  if (
    notification.__typename === 'CommentNotification' ||
    notification.__typename === 'MentionNotification'
  ) {
    if (match) {
      return (
        match.params.permanentArtboardShortId !==
        notification.artboard?.permanentArtboardShortId
      )
    }

    return true
  }

  return true
}

export const useBellNotifications = () => {
  const [previousId, setPreviousId] = useState<string | undefined>()

  const { data } = useUserProfile()
  const user = data?.me

  /**
   * This request is made lazy so anonymous users don't
   * get redirected to login because of it. Since this will only be
   * used when a new notification event is received
   */
  const [fetchNotificationsCount] = useGetUserNotificationsCountLazyQuery({
    fetchPolicy: 'network-only',
  })

  useNotificationCreatedSubscription({
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData.data?.notificationCreated) {
        return
      }

      const newNotification = subscriptionData.data.notificationCreated!

      switch (newNotification.__typename) {
        case 'DocumentUploadCompleteNotification':
        case 'DocumentUploadProcessedNotification': {
          // These events are deprecated, we already have 2 subscriptions for document
          // related notifications (useVersionSubscriptions)
          break
        }

        default: {
          // Refetch the user notifications counter
          fetchNotificationsCount()

          if (!shouldUpdateUserBadge(newNotification, previousId)) {
            return
          }

          // Prevent the UI from breaking
          try {
            if (user) {
              client.writeFragment<NotificationStatusFragment>({
                id: dataIdFromObject(user)!,
                fragment: NotificationStatusFragmentDoc,
                fragmentName: 'NotificationStatus',
                data: {
                  __typename: 'User',
                  identifier: user.identifier,
                  hasUnreadNotifications: true,
                  hasUnseenNotifications: true,
                },
              })
            }
          } catch (e) {
            captureException(e)
          }

          setPreviousId(newNotification.identifier)
        }
      }
    },
  })
}
