import type {
  File,
  Thumbnail,
  ThumbnailType,
  ColorVariableParsed,
} from './types'
import { isCwvSpecificRoute } from '@sketch/modules-common'

// This is a valid use case to narrow down Component type
// eslint-disable-next-line no-restricted-imports
import {
  LayerStyle,
  Symbol as SymbolComponent,
  TextStyle,
} from '@sketch/gql-types/expansive'

export type GroupType =
  | 'SHARE_VIEW'
  | 'SYMBOL'
  | 'LAYER_STYLE'
  | 'TEXT_STYLE'
  | 'COLOR_VARIABLE'

// Used to group Symbols, Layer styles, color variables
// so that, for instance, the symbols named Button/32px/Primary and
// Button/32px/Secondary can be grouped together on the UI under
// the Button/32px group
export function groupStyles(
  entries?:
    | ColorVariableParsed[]
    | LayerStyle[]
    | SymbolComponent[]
    | TextStyle[]
) {
  let groups: { [key: string]: any } = {}

  if (!Object.keys(entries || {}).length) {
    return {}
  }

  entries!.forEach(component => {
    // if is a parentless component, we don't group it, we just use a space to
    // place it at the top of the group names list
    const path = component.depth === 0 ? ' ' : component.path

    if (groups[path]) {
      groups[path] = [...groups[path], component]
    } else {
      groups = { ...groups, [path]: [component] }
    }
  })

  return groups
}

function getNextThumbnailSize(type: ThumbnailType) {
  switch (type) {
    case 'XL':
      return 'L'
    case 'L':
      return 'M'
    default:
      return 'S'
  }
}

export function getThumbnail(
  thumbnails?: Thumbnail[],
  type: ThumbnailType = 'L'
): string {
  if (!thumbnails?.length) {
    return ''
  }

  const selectedType = thumbnails.find(t => t?.type === type)

  if (!selectedType) {
    return getThumbnail(thumbnails, getNextThumbnailSize(type))
  }

  return (selectedType || thumbnails[0])?.url ?? ''
}

// temporarily adding this function here with different types
export function createSrcSet(files: File[]): string | null {
  if (!files) return null

  return files
    .sort((a, b): number => {
      if (a.scale && b.scale) return a.scale - b.scale
      return 0
    })
    .map(a => a.url)
    .filter(url => url !== null)
    .map((url, i) => `${url} ${i + 1}x`)
    .join(', ')
}

export function rgbaToHex(color: string) {
  if (color.includes('#')) {
    return color
  }

  // stolen from https://stackoverflow.com/questions/49974145/how-to-convert-rgba-to-hex-color-code-using-javascript
  const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',')

  let hex = `#${(
    (1 << 24) +
    (parseInt(rgba[0], 10) << 16) +
    (parseInt(rgba[1], 10) << 8) +
    parseInt(rgba[2], 10)
  )
    .toString(16)
    .slice(1)}`

  if (rgba[4]) {
    const alpha = Math.round(0o1 * 255)
    const hexAlpha = (alpha + 0x10000).toString(16).substr(-2).toUpperCase()
    hex += hexAlpha
  }

  return hex
}

export const getGroupType = (path: string): GroupType => {
  if (isCwvSpecificRoute(path, 'CWV_COLOR_VARIABLES')) {
    return 'COLOR_VARIABLE'
  }
  if (isCwvSpecificRoute(path, 'CWV_SYMBOLS')) {
    return 'SYMBOL'
  }
  if (isCwvSpecificRoute(path, 'CWV_LAYER_STYLES')) {
    return 'LAYER_STYLE'
  }
  if (isCwvSpecificRoute(path, 'CWV_TEXT_STYLES')) {
    return 'TEXT_STYLE'
  }

  /**
   *  If the path is not any of the group types we are defaulting to
   *  the shave view
   */
  return 'SHARE_VIEW'
}

export const getCwvRouteTitle = (path: string): string => {
  const links = {
    SHARE_VIEW: 'Pages',
    CWV_SYMBOLS: 'Symbols',
    CWV_TEXT_STYLES: 'Text Styles',
    CWV_LAYER_STYLES: 'Layer Styles',
    CWV_COLOR_VARIABLES: 'Color Variables',
  }

  if (
    isCwvSpecificRoute(path, 'SHARE_VIEW') ||
    isCwvSpecificRoute(path, 'SHARE_PAGE_VIEW')
  ) {
    return links.SHARE_VIEW
  }
  if (isCwvSpecificRoute(path, 'CWV_SYMBOLS')) {
    return links.CWV_SYMBOLS
  }
  if (isCwvSpecificRoute(path, 'CWV_LAYER_STYLES')) {
    return links.CWV_LAYER_STYLES
  }
  if (isCwvSpecificRoute(path, 'CWV_TEXT_STYLES')) {
    return links.CWV_TEXT_STYLES
  }
  if (isCwvSpecificRoute(path, 'CWV_COLOR_VARIABLES')) {
    return links.CWV_COLOR_VARIABLES
  }

  return links.SHARE_VIEW
}

export const getGroupRoute = (path: string) => {
  if (isCwvSpecificRoute(path, 'SHARE_VIEW')) {
    return 'SHARE_VIEW'
  }
  if (isCwvSpecificRoute(path, 'CWV_SYMBOLS')) {
    return 'CWV_SYMBOLS'
  }
  if (isCwvSpecificRoute(path, 'CWV_LAYER_STYLES')) {
    return 'CWV_LAYER_STYLES'
  }
  if (isCwvSpecificRoute(path, 'CWV_TEXT_STYLES')) {
    return 'CWV_TEXT_STYLES'
  }

  return 'CWV_COLOR_VARIABLES'
}

export function getGroupFromURL() {
  const URLparams = new URLSearchParams(window.location.search)

  // if it's actually a search, ignore everything
  if (URLparams.get('search')) {
    return ''
  }

  return decodeURIComponent(URLparams.get('g') || '')
}

export function prepareGroupURL(groups: string[]) {
  // trim to 500 chars, just to avoid huge group names on the URL
  // value was agreed on with the team
  const groupString = groups.join('/').substring(0, 500)

  // extra encode here to avoid issues with the url parsing (because of
  // potential & and = symbols in group names)
  const paramsString = { g: encodeURIComponent(groupString) }

  const searchParams = new URLSearchParams(paramsString)

  return searchParams.toString()
}
