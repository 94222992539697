import { DataProxy } from 'apollo-cache'
import {
  GetSharesDocument,
  GetSharesQuery,
  GetSharesQueryVariables,
  GetProjectSharesDocument,
  GetProjectSharesQuery,
  GetProjectSharesQueryVariables,
} from '@sketch/gql-types'

// Here FilterTypes are used in non exhaustive way
// therefore build should not break after SketchQL deployments
// eslint-disable-next-line no-restricted-imports
import {
  ShareSortOrder,
  WorkspaceShareFilterType,
} from '@sketch/gql-types/expansive'
import produce from 'immer'
import { ErrorHandler } from '@sketch/tracing'
import { castError } from '@sketch/utils'

export type SharesPagination = NonNullable<
  GetSharesQuery['workspace']['shares']
>

interface UpdateSharesCache {
  cache: DataProxy
  update: (data: SharesPagination) => void
  workspaceId: string
  filter?: WorkspaceShareFilterType | null
  sort?: ShareSortOrder
}

export const updateSharesCache = ({
  cache,
  update,
  workspaceId,
  filter = null,
  sort = 'LAST_MODIFIED_DESC',
}: UpdateSharesCache) => {
  const variables = {
    identifier: workspaceId,
    after: null,
    search: {
      name: '',
      filters: filter ? [filter] : [],
    },
    sortOrder: sort,
  }
  let existingData: GetSharesQuery | null
  try {
    existingData = cache.readQuery<GetSharesQuery, GetSharesQueryVariables>({
      query: GetSharesDocument,
      variables,
    })
  } catch (e) {
    const err = castError(e)
    ErrorHandler.ignore(err)
    existingData = null
  }

  if (!existingData) return

  const updated = produce(existingData, draft => {
    update(draft.workspace.shares as SharesPagination)
  })

  cache.writeQuery<GetSharesQuery, GetSharesQueryVariables>({
    query: GetSharesDocument,
    data: updated,
    variables,
  })
}

interface UpdateProjectSharesCache {
  cache: DataProxy
  update: (data: SharesPagination) => void
  projectShortId: string
  sort?: ShareSortOrder
}

export const updateProjectSharesCache = ({
  cache,
  update,
  projectShortId,
  sort = 'LAST_MODIFIED_DESC',
}: UpdateProjectSharesCache) => {
  const variables = {
    shortId: projectShortId,
    after: null,
    search: {
      name: null,
      isCurrentVersionDownloadable: null,
      filters: ['NO_COLLECTION' as const],
    },
    sortOrder: sort,
  }

  let existingData: GetProjectSharesQuery | null
  try {
    existingData = cache.readQuery<
      GetProjectSharesQuery,
      GetProjectSharesQueryVariables
    >({
      query: GetProjectSharesDocument,
      variables,
    })
  } catch (e) {
    const err = castError(e)
    ErrorHandler.ignore(err)
    existingData = null
  }

  if (!existingData) return

  const updated = produce(existingData, draft => {
    update(draft.project.shares as SharesPagination)
  })

  cache.writeQuery<GetProjectSharesQuery, GetProjectSharesQueryVariables>({
    query: GetProjectSharesDocument,
    data: updated,
    variables,
  })
}
