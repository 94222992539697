import styled from 'styled-components'
import {
  PermissionGroupIcon,
  PersonAvatar,
  Truncate,
  WorkspaceLogo,
} from '@sketch/components'

export const AvatarTooltipWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;

  ${/* sc-selector */ PersonAvatar},
  ${/* sc-selector */ WorkspaceLogo},
  ${/* sc-selector */ PermissionGroupIcon} {
    margin-right: 8px;
  }
`

export const TooltipContentWrapper = styled.div`
  max-width: 180px;
`

export const TooltipContentName = styled(Truncate)`
  margin-right: 4px;
`

export const TooltipPermissions = styled.span`
  color: ${({ theme }) =>
    theme._name === 'dark'
      ? theme.colors.foreground.secondary.C
      : theme.colors.foreground.primary.B};
  white-space: nowrap;
`
