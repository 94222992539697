import { ConfirmationDialog, ModalInjectedProps } from '@sketch/components'
import React from 'react'

import { useToast } from '@sketch/toasts'
import { useUpdateWorkspaceProfileMutation } from '@sketch/gql-types'

interface PublishProfileModalProps extends ModalInjectedProps {
  identifier: string
}

export const PublishProfileModal: React.FC<PublishProfileModalProps> = props => {
  const { hideModal, isModalOpen, identifier } = props
  const { showToast } = useToast()

  const [publishProfile, { loading }] = useUpdateWorkspaceProfileMutation({
    onCompleted: () => {
      showToast('Public profile successfully published')
      hideModal()
    },
    onError: message => showToast(message, 'negative'),
    variables: {
      input: {
        identifier,
        isPublic: true,
      },
    },
  })

  return (
    <ConfirmationDialog
      title="Ready to Publish Your Public Profile?"
      hideModal={hideModal}
      isModalOpen={isModalOpen}
      cancelButton={{
        disabled: loading,
      }}
      confirmButton={{
        text: 'Publish',
        variant: 'primary',
        loading,
        disabled: loading,
      }}
      onConfirm={() => publishProfile()}
    >
      <p>
        You can hide your public profile anytime from your Profile Settings.
      </p>
    </ConfirmationDialog>
  )
}
