import { useContext, useMemo } from 'react'

import {
  AnnotationQueryVariablesContext,
  AnnotationQueryVariablesContextValue,
} from '../context'
import {
  GetAnnotationDotsQueryVariables,
  GetAnnotationsQueryVariables,
} from '@sketch/gql-types'

type AnnotationVariablesFormat = 'overlay' | 'sidebar' | 'default'

const formatSubject = ({ subjects }: AnnotationQueryVariablesContextValue) => {
  const formattedSubjects = subjects.map(({ type, permanentId }) => ({
    type,
    permanentId,
  }))

  if (formattedSubjects.length === 1) {
    return {
      subject: formattedSubjects[0],
    }
  }

  return {
    subjects: formattedSubjects,
  }
}

export const formatOverlayVariables = (
  context: AnnotationQueryVariablesContextValue
) => {
  const { shareIdentifier, versionIdentifier, annotationStatus } = context

  const queryVariables: GetAnnotationDotsQueryVariables = {
    shareIdentifier,
    versionIdentifier,
    annotationStatus,
    ...formatSubject(context),
  }

  return queryVariables
}

export const formatSidebarVariables = (
  context: AnnotationQueryVariablesContextValue
) => {
  const {
    shareIdentifier,
    versionIdentifier,
    annotationStatus,
    annotationSort: sort,
  } = context

  const queryVariables: GetAnnotationsQueryVariables = {
    shareIdentifier,
    versionIdentifier,
    annotationStatus,
    sort,
    ...formatSubject(context),
  }

  return queryVariables
}

export const formatDefaultVariables = (
  context: AnnotationQueryVariablesContextValue
) => context

const formatterByFormat = {
  overlay: formatOverlayVariables,
  sidebar: formatSidebarVariables,
  default: formatDefaultVariables,
} as const

export const useAnnotationQueryVariables = <
  T extends AnnotationVariablesFormat
>(
  format: T
) => {
  const contextValue = useContext(AnnotationQueryVariablesContext)

  if (!contextValue) {
    throw new Error(
      '`useAnnotationQueryVariables` must be used with the `AnnotationQueryVariablesProvider`'
    )
  }

  const formatter = formatterByFormat[format]

  return useMemo(
    () => formatter(contextValue) as ReturnType<typeof formatterByFormat[T]>,
    [contextValue, formatter]
  )
}
