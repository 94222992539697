import styled from 'styled-components'

export const RoleTabsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const Column = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  gap: 4px;
`
