import styled from 'styled-components'

export const StepperSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Paragraph = styled.p`
  font-size: 0.8125rem;
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  line-height: 1.6;

  /* Needed because of modal internal styling */
  /* https://github.com/sketch-hq/cloud-frontend/blob/39270c24d5f558c765d111943d2767986587347e/src/components/Modal/Modal.tsx#L78-L81 */
  && {
    padding: 0;
  }
`

export const BoldParagraph = styled.p`
  margin: 0;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 1.6;

  /* Needed because of modal internal styling */
  /* https://github.com/sketch-hq/cloud-frontend/blob/39270c24d5f558c765d111943d2767986587347e/src/components/Modal/Modal.tsx#L78-L81 */
  && {
    padding: 0;
  }
`

export const Strong = styled.strong`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`

export const SeatsMaximumWarning = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.C};
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  text-align: right;
  margin: 0;
`

export const DiscountWrapper = styled.div`
  margin-bottom: 16px;
  padding: 16px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.border.A};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`
