import { ApolloCache } from 'apollo-cache'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { IS_EMBEDDED } from '@sketch/constants'

import {
  GetAnnotationSettingsDocument,
  GetAnnotationSettingsQuery,
  GetBrowserSettingsDocument,
  GetBrowserSettingsQuery,
  GetDocumentListSettingsDocument,
  GetDocumentListSettingsQuery,
  GetProjectSearchSettingsDocument,
  GetProjectSearchSettingsQuery,
} from '@sketch/gql-types'

export const initializeLocalApolloState = (
  cache: ApolloCache<NormalizedCacheObject>
) => {
  // Initialize the DocumentLayout grid setting on cache
  cache.writeQuery<GetDocumentListSettingsQuery>({
    query: GetDocumentListSettingsDocument,
    data: {
      __typename: 'RootQueryType',
      documentsLayout: 'GRID',
      showDocumentsInProjects: true,
      showArchivedDocuments: false,
    },
  })

  // Initialize the Annotation settings on cache
  cache.writeQuery<GetAnnotationSettingsQuery>({
    query: GetAnnotationSettingsDocument,
    data: {
      __typename: 'RootQueryType',
      hideAnnotationsDots: false,
    },
  })

  // Initialize user settings on cache
  cache.writeQuery<GetBrowserSettingsQuery>({
    query: GetBrowserSettingsDocument,
    data: {
      __typename: 'RootQueryType',
      sidebarRightOpen: !IS_EMBEDDED,
    },
  })

  // Initialize Project search settings in the cache
  cache.writeQuery<GetProjectSearchSettingsQuery>({
    query: GetProjectSearchSettingsDocument,
    data: {
      __typename: 'RootQueryType',
      hideCollections: false,
    },
  })
}
