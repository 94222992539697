import React, { useState } from 'react'

import { useToast } from '@sketch/toasts'

import {
  useForTablet,
  Box,
  DocumentHeader,
  useModalContext,
} from '@sketch/components'

import { ProjectHeaderActions } from './ProjectHeaderActions'

import {
  GetProjectsDocument,
  GetProjectsQueryVariables,
  useRenameProjectMutation,
} from '@sketch/gql-types'
import { useLayoutPortal } from '@sketch/utils'
import type { ProjectHeaderProps } from './types'
import { SKETCH_WEBSITE } from '@sketch/env-config'
import { RenameProject } from '../../modals'

const typeInline = <T extends any>(variables: T) => variables

const DRAFTS_HEADER_COPY = {
  title: 'My Drafts',
  description:
    'Only you can see documents that you keep here, unless you share them.',
  learnMoreTooltipTitle: 'Learn more about My Drafts',
  learnMoreTooltipURL: `${SKETCH_WEBSITE}/docs/getting-started/saving-and-managing-documents/#managing-documents-in-my-drafts`,
}

const ProjectHeader: React.FC<ProjectHeaderProps> = props => {
  const { workspace, project, userCanRenameAndDeleteProject } = props
  const { identifier: workspaceId } = workspace

  const HeaderPortal = useLayoutPortal('header-portal')
  const HeaderSlimPortal = useLayoutPortal('header-slim-portal')
  const { showModal } = useModalContext()

  const { showToast } = useToast()
  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger

  const [forceEdit, setForceEdit] = useState(false)

  const [renameProject] = useRenameProjectMutation({
    redirectErrors: true,
    onError: 'show-toast',
    refetchQueries: [
      {
        query: GetProjectsDocument,
        variables: typeInline<GetProjectsQueryVariables>({
          workspaceId,
        }),
      },
    ],
    awaitRefetchQueries: true,
    optimisticResponse: ({ name }) => ({
      __typename: 'RootMutationType',
      renameProject: {
        __typename: 'ProjectRenamePayload',
        errors: [],
        successful: true,
        project: {
          ...project,
          name,
        },
      },
    }),
    onCompleted: () => {
      showToast('Project was renamed successfully')
    },
  })

  const isDraft = project.type === 'PERSONAL_DRAFTS'

  const headerCopy: {
    title: string
    description?: string
    learnMoreTooltipTitle?: string
    learnMoreTooltipURL?: string
  } = isDraft ? DRAFTS_HEADER_COPY : { title: project?.name || '' }

  // Hide the Edit UI if the user doesn't have these permissions
  let handleEdit
  if (userCanRenameAndDeleteProject && !project.deletedAt) {
    handleEdit = async (title: string) => {
      await renameProject({
        variables: { name: title, projectId: project.identifier },
      })
    }
  }

  if (isMobile) {
    return (
      <Box marginBottom={5}>
        <DocumentHeader
          title={headerCopy.title}
          description={headerCopy?.description}
          learnMoreTooltipTitle={headerCopy?.learnMoreTooltipTitle}
          learnMoreTooltipURL={headerCopy?.learnMoreTooltipURL}
          titlePlaceholder="Project name"
          actions={
            <ProjectHeaderActions {...props} onRenameProject={setForceEdit} />
          }
          onEdit={handleEdit}
          forceEdit={forceEdit}
          onClearForceEdit={() => setForceEdit(false)}
        />
      </Box>
    )
  }

  return (
    <>
      <HeaderPortal>
        <DocumentHeader
          title={headerCopy.title}
          description={headerCopy?.description}
          learnMoreTooltipTitle={headerCopy?.learnMoreTooltipTitle}
          learnMoreTooltipURL={headerCopy?.learnMoreTooltipURL}
          titlePlaceholder="Project name"
          actions={
            <ProjectHeaderActions {...props} onRenameProject={setForceEdit} />
          }
          onEdit={handleEdit}
          forceEdit={forceEdit}
          onClearForceEdit={() => setForceEdit(false)}
        />
      </HeaderPortal>
      <HeaderSlimPortal>
        <DocumentHeader
          title={headerCopy.title}
          titlePlaceholder="Project name"
          actions={
            <ProjectHeaderActions
              {...props}
              onRenameProject={() => {
                showModal(RenameProject, {
                  projectId: project.identifier,
                  projectName: project.name,
                  workspaceId: workspace.identifier,
                })
              }}
              headerSlim
            />
          }
        />
      </HeaderSlimPortal>
    </>
  )
}

export default ProjectHeader
