import React, { useCallback, useMemo } from 'react'

import {
  Rect,
  usePrototypePan,
  usePrototypeState,
  usePrototypeZoom,
} from '@sketch-hq/sketch-web-renderer'
import { AnnotationDotFragment } from '@sketch/gql-types'

import {
  AnnotationOverlay,
  DraftAnnotationNotifier,
} from '../../../annotations/components'

import { useGetPrototypeArtboards, ExtendedPrototypeLayer } from '../../hooks'

const FALLBACK_PAN = { x: 0, y: 0 }

const preventiveParseFloat = (string: string) => {
  const number = parseFloat(string)
  return isNaN(number) ? 0 : number
}

interface PrototypeOriginProps {
  baseArtboard: ExtendedPrototypeLayer
}

const PrototypeOrigin = ({
  baseArtboard,
  children,
}: React.PropsWithChildren<PrototypeOriginProps>) => {
  const zoom = usePrototypeZoom()
  const { x: panX, y: panY } = usePrototypePan() || { x: 0, y: 0 }

  /**
   * The "isChanging" value is used here to temporarily hide
   * the annotations layer because performance wise it's faster
   * to just hide the one HTML element then all the annotations
   *
   * This change was done after noticing a significant slowness when
   * transitioning back and forward
   */
  const { isChanging } = usePrototypeState()

  const style = useMemo(() => {
    return {
      position: 'absolute',
      left: panX / window.devicePixelRatio + baseArtboard.bounds.x * zoom,
      top: panY / window.devicePixelRatio + baseArtboard.bounds.y * zoom,
      width: baseArtboard.bounds.width * zoom,
      height: baseArtboard.bounds.height * zoom,
      /* This will allow the clicks to propagate to the canvas hotspots */
      pointerEvents: 'none',
      opacity: isChanging ? 0 : 1,
    } as const
  }, [baseArtboard, panX, panY, zoom, isChanging])

  return (
    <div data-testid="prototype-origin" style={style}>
      {children}
    </div>
  )
}

interface PrototypeAnnotationOverlayProps {
  parentWrapper: React.RefObject<HTMLDivElement>
}

export const PrototypeAnnotationsOverlay = (
  props: PrototypeAnnotationOverlayProps
) => {
  const { parentWrapper } = props

  const zoom = usePrototypeZoom()
  const pan = usePrototypePan() || FALLBACK_PAN

  const artboards = useGetPrototypeArtboards()
  const artboard = artboards[0] as ExtendedPrototypeLayer | undefined

  const artboardCoordinatesByUuid = useMemo(() => {
    const coordinatesByUuid: Record<string, Rect> = {}

    artboards?.forEach(artboard => {
      coordinatesByUuid[artboard.id] = artboard.boundsWithScroll
    })

    return coordinatesByUuid
  }, [artboards])

  const referenceBounds = useMemo(
    () => ({
      x: 0,
      y: 0,
      width: artboard?.bounds?.width || 0,
      height: artboard?.bounds?.height || 0,
    }),
    [artboard]
  )

  const getPrefixCoordinates = useCallback(
    (annotation: AnnotationDotFragment) => {
      if (annotation.currentSubject.__typename === 'Artboard') {
        return artboardCoordinatesByUuid[annotation.currentSubject.uuid]
      }
    },
    [artboardCoordinatesByUuid]
  )

  const getArtboardContainerBounds = useCallback(() => {
    const artboardBounds = artboard?.bounds

    if (artboardBounds && pan && parentWrapper.current) {
      const parentBounds = parentWrapper.current.getBoundingClientRect()
      /**
       * If the parentWrapper contains any padding that
       * could affect the expected positioning of the canvas
       * we need to remove it from the mouse coordinates
       */
      const canvasRectStyle = getComputedStyle(parentWrapper.current)

      const top =
        pan.y / window.devicePixelRatio +
        artboardBounds.y +
        parentBounds.y +
        preventiveParseFloat(canvasRectStyle.paddingTop)

      const left =
        pan.x / window.devicePixelRatio +
        artboardBounds.x +
        parentBounds.x +
        preventiveParseFloat(canvasRectStyle.paddingLeft)

      return {
        top,
        bottom: top + artboardBounds.height * zoom,
        left,
        right: left + artboardBounds.width * zoom,
      }
    }
  }, [pan, zoom, artboard, parentWrapper])

  const shouldRenderAnnotation = useCallback(
    (annotation: AnnotationDotFragment) => {
      const annotationPrefixCoordinates = getPrefixCoordinates(annotation)

      const finalCoordinates = {
        x:
          (annotationPrefixCoordinates?.x || 0) +
          (annotation.coordinates?.x || 0),
        y:
          (annotationPrefixCoordinates?.y || 0) +
          (annotation.coordinates?.y || 0),
      }

      return (
        finalCoordinates.x <= referenceBounds.width &&
        finalCoordinates.y <= referenceBounds.height
      )
    },
    [getPrefixCoordinates, referenceBounds]
  )

  return (
    <>
      <DraftAnnotationNotifier canvasRef={parentWrapper} />
      {artboard && (
        <PrototypeOrigin baseArtboard={artboard}>
          <AnnotationOverlay
            parentWrapper={parentWrapper}
            getPrefixCoordinates={getPrefixCoordinates}
            referenceBounds={referenceBounds}
            getArtboardBounds={getPrefixCoordinates}
            getArtboardContainerBounds={getArtboardContainerBounds}
            shouldRenderAnnotation={shouldRenderAnnotation}
          />
        </PrototypeOrigin>
      )}
    </>
  )
}
