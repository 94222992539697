import React from 'react'

import { NewFeatureHighlightPopover } from '@sketch/components'

const popoverTexts = {
  profile: {
    admin: {
      title: 'A Space for Editors to Shine',
      body:
        'With a Workspace public profile, your Editors have a space to share and show off their best work.',
      button: undefined,
    },
    noAdmin: {
      title: 'A Space for Your Best Work',
      body:
        'Your Workspace has a public profile, giving you a space to share and show off your best work.',
      button: undefined,
    },
  },
  noProfile: {
    admin: {
      title: 'A Space for Editors to Shine',
      body:
        'Create a Workspace public profile with a custom URL — giving your Editors a secure space to show their best work.',
      button: 'Start',
    },
    noAdmin: {
      title: 'A Space for Your Best Work',
      body:
        'With a Workspace public profile you’ll have a space to show your best work. Ask an Admin to create a public profile!',
      button: 'Learn More',
    },
  },
}

interface Props {
  isAdmin: boolean
  isProfileCreated: boolean
  onConfirm?: () => void
}

export const PopoverInfo = ({
  isAdmin,
  isProfileCreated,
  onConfirm,
}: Props) => {
  const profileKey: keyof typeof popoverTexts = isProfileCreated
    ? 'profile'
    : 'noProfile'

  const adminKey: keyof typeof popoverTexts['profile'] = isAdmin
    ? 'admin'
    : 'noAdmin'

  const title = popoverTexts[profileKey][adminKey].title
  const body = popoverTexts[profileKey][adminKey].body
  const button = popoverTexts[profileKey][adminKey].button
  const onConfirmHandler = () => {
    if (button) {
      onConfirm?.()
    }

    return undefined
  }

  return (
    <NewFeatureHighlightPopover
      highlightPlacement="sidebar"
      titleText={title}
      bodyText={body}
      confirmCopy={button}
      onConfirm={onConfirmHandler}
      localStorageKey="publicProfilePopoverSeen"
    />
  )
}
