import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "image-64", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 64 64", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { transform: "translate(9.5 14.5)", fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M0 17.5v9.808c0 2.675.278 3.645.801 4.623a5.452 5.452 0 002.268 2.268c.978.523 1.948.801 4.623.801h29.616c2.675 0 3.645-.278 4.623-.801a5.452 5.452 0 002.268-2.268c.523-.978.801-1.948.801-4.623V7.692c0-2.675-.278-3.645-.801-4.623A5.452 5.452 0 0041.93.801C40.953.278 39.983 0 37.308 0H7.692C5.017 0 4.047.278 3.069.801A5.452 5.452 0 00.801 3.07C.278 4.047 0 5.017 0 7.692V17.5", stroke: "currentColor", strokeWidth: 3, strokeLinejoin: "round" }),
        React.createElement("path", { d: "M18.317 17.743c.101.091.2.186.294.285L28.928 28.84l2.556-2.657a5.114 5.114 0 017.543.187l5.04 5.79-.004.01a5.442 5.442 0 01-2.133 2.03c-.978.523-1.948.801-4.623.801H7.692c-2.675 0-3.645-.278-4.623-.801a5.452 5.452 0 01-2.268-2.27 5.545 5.545 0 01-.58-1.591l10.875-12.186a5.114 5.114 0 017.22-.41z", fillOpacity: 0.15, fill: "currentColor" }),
        React.createElement("path", { d: "M.221 30.339l11.161-12.506c1.88-2.106 4.984-2.157 6.94-.108L28.929 28.84l2.883-2.998c1.958-2.036 5.045-1.964 6.906.174l5.35 6.144-.005.01", stroke: "currentColor", strokeWidth: 3, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("ellipse", { fill: "currentColor", cx: 33.5, cy: 11.541, rx: 5, ry: 5.041 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
