import { Toast } from '@sketch/toasts'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;

  bottom: 0;
  left: 0;
  right: 0;

  padding: 0 16px 20px;
`

export const StyledToast = styled(Toast)`
  width: 100%;
`
