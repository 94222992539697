import styled, { css } from 'styled-components'

import { Flex, FlexProps } from '../Box'
import { breakpoint } from '@sketch/global-styles'

export const NavbarSection = styled(Flex)<
  FlexProps & { align?: 'start' | 'center' | 'end' }
>`
  display: flex;
  min-width: 0;
  height: 100%;
  min-height: 100%;
  align-items: center;
  flex: 1 0px;
  justify-content: ${({ align = 'start' }) =>
    align === 'center' ? 'center' : `flex-${align}`};
`

export const NavbarExtendedBase = styled.div<{
  fixed?: boolean
  isSidebarLeftOpen?: boolean
  /** There's only a single children element */
  singleContent?: boolean
}>(
  ({ theme, fixed, singleContent }) => css`
    display: flex;
    height: ${theme.header.height}px;
    position: relative;

    & & {
      display: flex;
      padding: 0;
      margin: 0;
    }

    width: 100%;
    align-items: center;
    justify-content: ${singleContent ? 'flex-end' : 'space-between'};
    background: ${theme.colors.background.secondary.A};

    &:empty {
      display: none;
    }

    ${breakpoint('sm')`
      display: block;
      height: auto;
      padding: 76px 52px 0; /* stylelint-disable-line scales/space */
      background: none;
    `};

    ${fixed &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
    `};
  `
)
