import env from './appEnv'

/**
 * Will output the correct Sketch website depending
 * on the environment
 *
 * @example
 * <Link href={`${SKETCH_WEBSITE}/home`}
 */
export const SKETCH_WEBSITE = env.sketch_website
