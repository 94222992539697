import React from 'react'

import { useUserSignedIn } from '../useUserSignedIn'
import { Children, renderConditionally } from '@sketch/utils'

interface SignedInProps {
  children: Children
}

export const IsNotSignedIn: React.FC<SignedInProps> = ({ children }) => (
  <>{renderConditionally(children, !useUserSignedIn())}</>
)

export const IsSignedIn: React.FC<SignedInProps> = ({ children }) => (
  <>{renderConditionally(children, useUserSignedIn())}</>
)
