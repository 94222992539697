import React, { CSSProperties } from 'react'
import { ColorFormat, Color as ColorType } from '../../../../../../types'
import { rgbTo } from '../utils'
import Opacity from '../../Attribute/components/Opacity'
import { Container, ColorIcon, Text } from './ColorDot.styles'

export interface ColorProps extends ColorType {
  style?: CSSProperties
  displayOpacity?: boolean
  format?: ColorFormat
  $iconSize?: number
}

export const ColorDot: React.FC<ColorProps> = ({
  red,
  green,
  blue,
  alpha = 1,
  displayOpacity = true,
  format = ColorFormat.HEX,
}) => {
  const colorString = rgbTo(format, { red, green, blue, alpha })

  return (
    <Container>
      <ColorIcon red={red} green={green} blue={blue} alpha={alpha} />
      <Text className="ColorText">{colorString}</Text>
      {displayOpacity && format === ColorFormat.HEX && (
        <Opacity value={alpha} />
      )}
    </Container>
  )
}
