import React, { useState } from 'react'

const MESSAGES = [
  'Optimizing keming...',
  'Hinting pixels...',
  'Summoning shadows...',
  'Chasing rogue vectors...',
  'Making it pop…',
  'Wondering if anyone reads these…',
  'Organizing components...',
  'Inspecting layers...',
  'One more thing...',
  'Making it snappier...',
  'Thinking differently...',
  'Styling your styles...',
  'Assembling components...',
  'Interpreting Symbols...',
  'Arranging Artboards...',
  'Framing the Canvas...',
  'Sharpening pencils...',
]

interface LoadingMessageProps {
  className?: string
}

export const LoadingMessage = ({ className }: LoadingMessageProps) => {
  const [messagePosition] = useState(
    Math.round(Math.random() * MESSAGES.length)
  )

  return <span className={className}>{MESSAGES[messagePosition]}</span>
}
