import { castError } from '@sketch/utils'
import { getStorageEstimate } from '../react-app/reportMetrics/getContextOnPageLoad'
import { ServiceWorkerClient } from './ServiceWorkerClient'

// TODO: Cleanup additional service worker sentry logging,
//       https://github.com/sketch-hq/Cloud/issues/11830

// We'll be able to remove this Awaited once we'll upgrade to TypeScript v4.5 or newer
type Awaited<T> = T extends PromiseLike<infer U> ? U : T

type AllowedResult =
  | Record<string, any>
  | string
  | ServiceWorkerRegistration
  | readonly ServiceWorkerRegistration[]
  | undefined

const tryPromiseFn = async <FN extends () => Promise<AllowedResult>>(
  fn: FN
) => {
  try {
    const result = (await fn()) as Awaited<ReturnType<FN>>
    return { result, error: undefined }
  } catch (e) {
    const err = castError(e)
    return { result: undefined, error: err?.message as string | undefined }
  }
}

const getSentryClientExtras = async (client: ServiceWorkerClient) => {
  try {
    const swState = client?.originalSw?.state
    const scriptURL = client?.originalSw?.scriptURL
    const requestsLog = client?.getRequestLog()

    const [runnerState, logs] = (
      await Promise.all([
        tryPromiseFn(() => client.getState()),
        tryPromiseFn(() => client.getLogs({ first: 10, last: 20 })),
      ])
    ).map(x => x.error || x.result)

    return {
      swState,
      scriptURL,
      requestsLog,
      runnerState,
      logs,
    }
  } catch (e) {
    const err = castError(e)
    return { error: `Could not get extra props for sentry: ${err?.message}` }
  }
}

export const getSentryExtras = async (existingClient?: ServiceWorkerClient) => {
  try {
    const [
      registration,
      registrationRoot,
      registrationOrigin,
      registrations,
      storageEstimate,
    ] = await Promise.all([
      tryPromiseFn(() => window.navigator.serviceWorker.getRegistration()),
      tryPromiseFn(() => window.navigator.serviceWorker.getRegistration('/')),
      tryPromiseFn(() =>
        window.navigator.serviceWorker.getRegistration(window.origin)
      ),
      tryPromiseFn(() => window.navigator.serviceWorker.getRegistrations()),
      tryPromiseFn(() => getStorageEstimate()),
    ])
    const controllerState =
      window.navigator.serviceWorker.controller?.state ?? null

    const registrationAny =
      registration.result ||
      registrationRoot.result ||
      registrationOrigin.result ||
      (registrations.result && registrations.result?.[0])

    let serviceWorker: ServiceWorker | null = null
    if (registrationAny) {
      serviceWorker =
        registrationAny.active ||
        registrationAny.installing ||
        registrationAny.waiting
    }

    let client: ServiceWorkerClient | null = null
    if (serviceWorker) {
      client = existingClient || new ServiceWorkerClient(serviceWorker)
    }

    const clientExtras = client && (await getSentryClientExtras(client))

    const registrationFlags = Object.fromEntries(
      Object.entries({
        registration,
        registrationRoot,
        registrationOrigin,
        registrations,
      }).map(([name, value]) => [name, value.error || !!value.result])
    )

    return {
      controllerState,
      ...clientExtras,
      client: !!client,
      existingClient: !!existingClient,
      serviceWorker: !!serviceWorker,
      registrationAny: !!registrationAny,
      storageEstimate: storageEstimate.error || storageEstimate.result,
      ...registrationFlags,
      registrationsLength:
        (registrations.result && registrations.result?.length) ?? null,
    }
  } catch (e) {
    const err = castError(e)
    return { error: `couldn't get sentry extras: ${err?.message}` }
  }
}

if (
  process.env.REACT_APP_ENV === 'dev' ||
  process.env.REACT_APP_ENV === 'test'
) {
  // eslint-disable-next-line no-extra-semi
  ;(window as any).__getSwSentryExtras = getSentryExtras
}
