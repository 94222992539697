import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "notes-20-2x", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { id: "Icons/Cloud/20/my.drafts@2x", stroke: "none", strokeWidth: 1, fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "Group", transform: "translate(3 3)" },
            React.createElement("path", { d: "M3.846 0h6.308c1.337 0 1.822.14 2.311.4.49.262.873.646 1.134 1.135.262.489.401.974.401 2.31v6.31c0 1.336-.14 1.821-.4 2.31a2.726 2.726 0 01-1.135 1.134c-.489.262-.974.401-2.31.401h-6.31c-1.336 0-1.821-.14-2.31-.4A2.726 2.726 0 01.4 12.464c-.261-.488-.4-.973-.4-2.309v-6.31c0-1.336.14-1.821.4-2.31A2.726 2.726 0 011.536.4c.488-.261.973-.4 2.309-.4z", id: "Rectangle", stroke: "currentColor" }),
            React.createElement("path", { id: "Rectangle", fill: "currentColor", d: "M0 4h14v1H0z" }),
            React.createElement("path", { d: "M4 4v3h10v1H4v2h10v1H4v3H3v-3H0v-1h3V8H0V7h3V4h1z", id: "Combined-Shape", fill: "currentColor", opacity: 0.35 }),
            React.createElement("path", { d: "M3 0h8a3 3 0 013 3v1.5H0V3a3 3 0 013-3z", id: "Rectangle", fill: "currentColor", opacity: 0.2 }))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
