import { CANCELED_SUBSCRIPTION_STATUSES } from './constants'
import {
  BillingWorkspaceNoticeFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

type BillingStatus = BillingWorkspaceNoticeFragment['status']

/**
 * Workspace is still active but the subscription was canceled and will automatically
 * switch to canceled status at the end of term instead of being renewed. This typically happens when
 * the user is subscribed and cancels the subscription before the end. We still provide the active
 * workspace until the date they have paid for.
 */
export function isWillBeCanceledSubscription(
  billing: BillingWorkspaceNoticeFragment
) {
  return billing?.status === 'ACTIVE' && billing.subscriptionEnd
}

export function isCanceledStatus(billingStatus: BillingStatus | undefined) {
  return billingStatus
    ? CANCELED_SUBSCRIPTION_STATUSES.includes(billingStatus)
    : false
}

export function isExpiringIn30daysOrCanceled(
  billing: BillingWorkspaceNoticeFragment
) {
  const daysDifference = Math.abs(
    new Date(billing.subscriptionEnd!).getTime() - new Date().getTime()
  )

  const daysLeft = daysDifference / (1000 * 3600 * 24)

  return Math.abs(daysLeft) <= 30 || isCanceledStatus(billing.status)
}

export function isOrWillBeCanceledSubscription(
  billing: BillingWorkspaceNoticeFragment
) {
  return (
    isCanceledStatus(billing.status) || isWillBeCanceledSubscription(billing)
  )
}

export function isEndedTrialSubscription(
  billing: Pick<BillingWorkspaceNoticeFragment, 'status' | 'subscriptionSetUp'>
) {
  return Boolean(billing?.status === 'CANCELED' && !billing?.subscriptionSetUp)
}

export function canManageSubscription(
  userRole: WorkspaceMinimalFragment['userRole']
) {
  return userRole === 'ADMIN' || userRole === 'FINANCE'
}
