import styled from 'styled-components'
import { Text } from '@sketch/components'

import { ReactComponent as LockIcon } from '@sketch/icons/lock-16'

import StripeField from '../StripeField'

export const InlineStripeField = styled(StripeField)`
  flex: 1;

  & + & {
    margin-left: ${({ theme }) => theme.space[5]}px;
  }
`

export const TransactionsText = styled(Text.Span).attrs({
  textStyle: 'copy.tertiary.standard.E',
})`
  display: flex;
  align-items: center;
  user-select: none;
`

export const TransactionsIcon = styled(LockIcon)`
  margin-right: ${({ theme }) => theme.space[2]}px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  width: 16px;
  height: 16px;
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`

export const Section = styled.div`
  padding: ${({ theme }) => theme.space[8]}px 0;
`
