import styled from 'styled-components'

import { Button } from '@sketch/components'
import { InvitePeopleField as BaseInvitePeopleField } from '@sketch/modules-common'

export const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
`

export const AddMemberButton = styled(Button)`
  margin-top: auto;
  margin-left: 16px;
`

export const InvitePeopleField = styled(BaseInvitePeopleField)`
  width: calc(100% - 69px);
`
