import React, { useState } from 'react'

import { useParams } from 'react-router-dom'

import {
  IndexLayoutContent,
  RouteParams,
  IndexLayoutExtraProps,
} from '@sketch/modules-common'
import { LoadingState, WorkspaceLogo } from '@sketch/components'

import MFAInterface from './components/MFAInterface'
import SignInInterface from './components/SignInInterface'

import { Header } from '../LinkAccountConfirmationView/LinkAccountConfirmationView.styles'
import {
  StyledPanel,
  UberHeader,
  GenericErrorMessage,
  StyledSsoTag,
  WorkspaceName,
} from './SignInToLinkAccountView.styles'

import { SignInMutation, useGetPublicWorkspaceQuery } from '@sketch/gql-types'

export const FullHeader = ({
  name,
  avatarUrl,
}: {
  name: string
  avatarUrl: string | null
}) => {
  return (
    <UberHeader>
      <WorkspaceLogo
        size="32px"
        src={avatarUrl}
        workspaceName={name}
        UNSAFE_ADD_MARGIN
      />
      <Header>
        Signing in to the <WorkspaceName>{name}</WorkspaceName> (
        <StyledSsoTag />) Workspace
      </Header>
    </UberHeader>
  )
}

export const SignInToLinkAccountView = (props: IndexLayoutExtraProps) => {
  const { workspaceId } = useParams<RouteParams<'SSO_LINK_ACCOUNT'>>()
  const [mfaToken, setMfaToken] = useState('')

  const { data, loading, error } = useGetPublicWorkspaceQuery({
    variables: { identifier: workspaceId },
  })

  if (loading) {
    return <LoadingState />
  }

  if (error || !data?.publicWorkspace) {
    return <GenericErrorMessage />
  }

  const handleCompleted = (data: SignInMutation) => {
    if (data.signIn.__typename === 'MFACredentials' && data.signIn.mfaToken) {
      setMfaToken(data.signIn.mfaToken)
    }
  }

  return (
    <IndexLayoutContent center="horizontal" marginTop paddingHorizontal>
      <StyledPanel>
        {mfaToken ? (
          <MFAInterface mfaToken={mfaToken} />
        ) : (
          <>
            <FullHeader
              name={data.publicWorkspace.name}
              avatarUrl={data.publicWorkspace?.avatar?.small ?? null}
            />
            <SignInInterface
              workspaceId={workspaceId}
              onCompleted={handleCompleted}
            />
          </>
        )}
      </StyledPanel>
    </IndexLayoutContent>
  )
}
