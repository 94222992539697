import { isBillingHidden } from '@sketch/env-config'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'

const getRequestsModule = () => import('./replaceRequests')

type RequestsModule = Awaited<ReturnType<typeof getRequestsModule>>

const contextKey = 'hidePaymentsRequestsModule'

/**
 * Hide payments link
 *
 * This link is used to hide all payments-related operations
 * This is used for private cloud installations where billing is not available.
 */
export const createHidePaymentsLink = () => {
  if (!isBillingHidden) return

  let requests: RequestsModule | null = null

  /**
   * Async function to lazy load the requests module.
   *
   * Apollo Link does not allow async functions, so Apollo
   * recommends to use setContext link for this use case
   *
   * https://github.com/apollographql/apollo-client/issues/2441#issuecomment-340819525
   */
  const lazyLoadModuleLink = setContext(async () => {
    if (!requests) {
      requests = await getRequestsModule()
    }

    /**
     * Internally `setContext` merges existing context with the new values using this call:
     * ```
     * context = { ...context, ...next }
     * ```
     * see more:
     * - https://github.com/apollographql/apollo-link/blob/apollo-link%401.2.14/packages/apollo-link/src/linkUtils.ts#L102-L108
     * - https://github.com/apollographql/apollo-link/blob/apollo-link-context%401.0.20/packages/apollo-link-context/src/index.ts#L22
     */
    return { [contextKey]: requests }
  })

  const link = new ApolloLink((operation, forward) => {
    const requests = operation.getContext()[contextKey] as RequestsModule

    // delegate the request handling to the requests module
    // in this file we don't want to do anything more than that.
    return requests.handleRequest(operation, forward)
  })

  return ApolloLink.from([lazyLoadModuleLink, link])
}
