import styled from 'styled-components'
import { ReactComponent as ArrowTopLeft } from '@sketch/icons/arrow-top-left-16'
import { ReactComponent as ChevronRight } from '@sketch/icons/chevron-right-16'
import { ReactComponent as DividerSvg } from '@sketch/icons/dots-4-vertical-divider-32'
import { buttonUnstyledStyles } from '@sketch/components'

export const Container = styled.div`
  display: inline-flex;
  height: 32px;
  padding: 0 3px; /* stylelint-disable-line scales/space */
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  border-radius: ${({ theme }) => theme.radii.medium};
`

export const Divider = styled(DividerSvg)`
  width: 2px;
  height: 31px; /* stylelint-disable-line scales/space */
  margin-right: 4px;
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.background.tertiary.D};
`

export const Button = styled.button`
  ${buttonUnstyledStyles}

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0;
  width: 24px;
  height: 31px; /* stylelint-disable-line scales/space */

  user-select: none;
  appearance: none;

  /* This color should be "Light/colors.foreground.secondary.D" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  opacity: 0.55;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;

  &:hover,
  &:focus,
  &:active {
    /* This color should be "Light/colors.foreground.secondary.A" but given the icon composition */
    /* Opacity needs to be applied to the whole object */
    opacity: 1;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.25;
    pointer-events: none;
  }
`

export const RestartIcon = styled(ArrowTopLeft)`
  width: 16px;
  height: 16px;
`

export const GoForwardsButton = styled(ChevronRight)`
  width: 16px;
  height: 16px;
`

export const GoBackwardsButton = styled(GoForwardsButton)`
  transform: scaleX(-1);
`
