import styled from 'styled-components'
import { Button, Panel } from '@sketch/components'

export const PanelHeader = styled(Panel.Header)`
  padding: 0;
`

export const StyledButton = styled(Button)`
  margin-top: 16px;
`

export const PanelBody = styled(Panel.Body)`
  width: fit-content;

  /** We need to override Panel.Body padding in this case*/
  && {
    padding: 0;
  }
`
