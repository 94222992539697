export const castError = (maybeError: unknown): Error => {
  if (maybeError instanceof Error) return maybeError
  if (!maybeError) return new Error('Unknown empty error')
  if (typeof maybeError !== 'object') return new Error('Unknown error')

  const message = (maybeError as any)?.message
  const name = (maybeError as any)?.name

  if (typeof message === 'string') {
    if (typeof name === 'string') {
      return maybeError as Error
    }

    return new Error(message)
  }

  return new Error('Unknown error')
}
