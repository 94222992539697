import { BillingWorkspaceNoticeFragment } from '@sketch/gql-types'

type BillingStatus = BillingWorkspaceNoticeFragment['status']

/**
 * All the statuses indicating that the subscription has been canceled for different reasons.
 * Those statuses come frome stripe subscriptions.
 * Summary of all statuses: https://mrcoles.com/stripe-api-subscription-status/
 * More info: https://stripe.com/docs/billing/subscriptions/overview
 */
export const CANCELED_SUBSCRIPTION_STATUSES: BillingStatus[] = [
  /** Very first payment of the subscription failed because of some kind of card error. User has 23h to fix it. */
  'INCOMPLETE',
  /** Users failed to paid very first invoice. After 23h the INCOMPLETE subscription automatically becomes INCOMPLETE_EXPIRED. */
  'INCOMPLETE_EXPIRED',
  /** Latest payment. On most cases we give 20 days from first failed payment before canceling subscription. */
  'PAST_DUE',
  /** A workspace becomes UNPAID after being PAST_DUE for an amount of time. The workspace becomes inactive until the user reactivates the subscription */
  'UNPAID',
  /** Subscription has been completely canceled (likely by the user?) */
  'CANCELED',
]
