import { Grid as GridBase } from './Grid'
import { Column } from './Column'
export type {
  ColumnProps as GridColumnProps,
  GridProps,
  Gutter as GridGutter,
  Size as GridSize,
} from './Grid.types'

export const Grid = Object.assign(GridBase, {
  Column,
})
