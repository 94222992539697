export const getItem = (key: string): string | undefined => {
  try {
    return window.localStorage.getItem(key) ?? undefined
  } catch (e) {
    return undefined
  }
}

export const getParsedItem = <T = any>(key: string): T | undefined => {
  try {
    const rawItem = getItem(key)
    if (rawItem === undefined) return undefined
    return JSON.parse(rawItem)
  } catch (e) {
    return undefined
  }
}

export const setItem = (key: string, item: string) => {
  try {
    window.localStorage.setItem(key, item)
  } catch (e) {
    // The error will be ignored
  }
}

export const setStringifiedItem = <T extends object | any[]>(
  key: string,
  item: T
) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(item))
  } catch (e) {
    // The error will be ignored
  }
}

export const removeItem = (key: string) => {
  try {
    window.localStorage.removeItem(key)
  } catch (e) {
    // The error will be ignored
  }
}
