import React from 'react'
import { StripeProvider } from '@sketch/components'
import config from '@sketch/env-config'

const StripeProviderWrapper: React.FC = ({ children }) => (
  <StripeProvider stripeKey={config['stripe_api_key']}>
    {children}
  </StripeProvider>
)

export { StripeProviderWrapper as StripeProvider }
