import React, { ComponentType, CSSProperties } from 'react'
import { Box } from '../Box'
import { FormError } from '../Form'

type ExtractProps<T> = T extends ComponentType<infer R> ? R : never
type BoxDivProps = ExtractProps<typeof Box>

type ParsedError = {
  messages: string[]
}

type ApolloErrorProps = {
  error: ParsedError
  style?: CSSProperties
} & BoxDivProps

export const ApolloError = ({ error, ...props }: ApolloErrorProps) => (
  <Box {...props}>
    {!error || !error.messages || error.messages.length < 1 ? (
      <FormError>An unexpected error occurred.</FormError>
    ) : (
      error.messages.map((msg, i) => <FormError key={i}>{msg}</FormError>)
    )}
  </Box>
)
