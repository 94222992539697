import React, { FC } from 'react'

import { useGetTrash } from '../../operations'

import TrashItemsLayout from '../TrashItemsLayout'
import TrashListEmptyState from '../TrashListEmptyState'

import {
  useForTablet,
  DocumentHead,
  ErrorMessage,
  DocumentHeader,
  LoadingState,
} from '@sketch/components'
import { WorkspaceMinimalFragment } from '@sketch/gql-types'
import { useLayoutPortal } from '@sketch/utils'
import { useSearch } from '../../hooks/useSearch'

import { SKETCH_WEBSITE } from '@sketch/env-config'

export interface TrashListProps {
  workspaceId: string
  userRole: WorkspaceMinimalFragment['userRole']
}

const TrashList: FC<TrashListProps> = ({ workspaceId, userRole }) => {
  const { searchDebounced: search } = useSearch()
  const isTabletAndBigger = useForTablet()

  const HeaderPortal = useLayoutPortal('header-portal')
  const HeaderSlimPortal = useLayoutPortal('header-slim-portal')

  const result = useGetTrash({ workspaceId, search })

  if (!result || result.kind === 'error') {
    return <ErrorMessage.Generic />
  }

  if (result.kind === 'loading') return <LoadingState />

  const { fetchMore, items, loading, totalCount } = result

  const header = isTabletAndBigger && (
    <>
      <HeaderPortal>
        <DocumentHeader
          title="Trash"
          learnMoreTooltipTitle="Learn about deleting documents"
          learnMoreTooltipURL={`${SKETCH_WEBSITE}/docs/getting-started/saving-and-managing-documents/#deleting-documents`}
          description="Documents and projects in Trash are permanently deleted after 90 days."
        />
      </HeaderPortal>
      <HeaderSlimPortal>
        <DocumentHeader title="Trash" />
      </HeaderSlimPortal>
    </>
  )

  if (!loading && items.length === 0) {
    return (
      <>
        {header}
        <TrashListEmptyState search={search} />
      </>
    )
  }

  return (
    <>
      <DocumentHead title="Trash" />
      <TrashItemsLayout
        items={items}
        loading={loading}
        userRole={userRole}
        totalItemsCount={totalCount}
        onLoadMore={fetchMore}
        workspaceId={workspaceId}
        header={header}
      />
    </>
  )
}

export default TrashList
