import styled, { css } from 'styled-components'
import { tintColors } from '@sketch/global-styles'

type COLOR_KEYS_TYPE = keyof typeof tintColors
export type Size = '16px' | '24px' | '32px' | '64px'

interface CommonProps {
  $size: Size
}

const commonStyles = css<CommonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  flex-shrink: 0;

  position: relative;

  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};
  overflow: hidden;
  border-radius: 100%;
`

export const ImageWrapper = styled.span<CommonProps>`
  ${commonStyles};
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

interface TextProps extends CommonProps {
  $color: COLOR_KEYS_TYPE
}

export const Text = styled.span<TextProps>`
  ${commonStyles};

  /* Correct vertical appearance */
  padding-top: 1px; /* stylelint-disable-line scales/space */

  line-height: 1;
  user-select: none;
  background-color: ${({ $color }) => tintColors[$color]};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.foreground.primary.A};

  /* stylelint-disable scales/font-size */
  font-size: ${({ $size }) => {
    switch ($size) {
      case '16px':
        return '0.4375rem'
      case '24px':
        return '0.5625rem'
      case '32px':
        return '0.75rem'
      case '64px':
        return '1.5rem'
    }
  }};
  /* stylelint-enable scales/font-size */

  /* If it's a single letter the font-size is different */
  &:first-child:last-child {
    ${({ $size }) =>
      $size === '16px' &&
      css`
        font-size: 0.5rem; /* stylelint-disable-line scales/font-size */
      `}
  }
`

export const OverLayWrapper = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.colors.foreground.secondary.C};
`
