import styled, { css } from 'styled-components'
import { breakpoint } from '@sketch/global-styles'

import { Form, Box, TextArea } from '@sketch/components'
import { ReactComponent as SearchIcon } from '@sketch/icons/search-64'

export const PaddedContainer = styled.div`
  padding: 0 16px 0 16px;

  ${breakpoint('sm')`
    padding: 0 32px 0 32px;
  `}
`

export const ScrollingContainer = styled(PaddedContainer)<{ height: number }>`
  overflow-y: auto;
  position: relative;
  min-height: 280px;
  height: ${({ height }) => (height !== 0 ? `${height}px` : '100%')};
`

export const Separator = styled.div(
  ({ theme }) => css`
    border-top: 1px solid ${theme.colors.border.A};
    margin: 20px 0;
    width: 100%;
  `
)

// CONTENTS

export const Field = styled(Form.Field)`
  margin-bottom: 4px;
`

export const DescriptionTextArea = styled(TextArea)`
  resize: none;
  width: 100%;
  height: 92px;
`

export const SearchEmptyIcon = styled(SearchIcon)`
  color: #b3b3b3;
  height: 64px;
`

// ICON UPLOAD

export const IconOverlay = styled.div(
  ({ theme }) => css`
    width: 64px;
    height: 64px;
    position: absolute;
    top: 0;
    left: 0;

    background-color: ${theme.colors.foreground.secondary.C};
    border-radius: ${theme.radii.medium};
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 0.8125rem;
    text-align: center;
    cursor: pointer;

    opacity: 0;
    transition: opacity ${theme.transitions.duration[2]}
      ${theme.transitions.timing.easeInOut};

    &:hover {
      opacity: 1;
    }
  `
)

export const DSImage = styled.img`
  width: 64px;
  height: 64px;
`

export const RelativeBox = styled(Box)(
  ({ theme }) => css`
    position: relative;
    border-radius: ${theme.radii.medium};

    &:hover {
      border-color: transparent;
    }
  `
)
