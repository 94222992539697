import React from 'react'

// Images
import ImageSrc from '@sketch/icons/create-profile-modal.png'
import RetinaImageSrc from '@sketch/icons/create-profile-modal@2x.png'

import {
  Modal,
  ModalInjectedProps,
  ConfirmationDialog,
} from '@sketch/components'

import { Image } from './ExplainPublicProfileModal.styles'

interface Props extends ModalInjectedProps {
  isAdmin: boolean
  loading: boolean
  onConfirm?: () => void
}

export const ExplainPublicProfileModal = ({
  hideModal,
  isAdmin,
  onConfirm,
  loading,
}: Props) => {
  const title = 'Showcase Your Design Powers'

  return (
    <ConfirmationDialog
      hideModal={hideModal}
      title={title}
      onConfirm={() => {
        onConfirm?.()
      }}
      hero={
        <Image alt={title} src={ImageSrc} srcSet={`${RetinaImageSrc} 2x`} />
      }
      confirmButton={{
        text: 'Customise Public Profile',
        variant: 'primary',
        isHidden: !isAdmin,
        loading,
      }}
      cancelButton={{
        text: isAdmin ? 'Maybe later' : 'Close',
      }}
    >
      <p>
        Curate your best work, create a portfolio and publish it to your public
        profile. You can also set a custom URL via Settings.
      </p>
      <p>
        Your public profile will stay private until you choose to publish it.
      </p>
      {!isAdmin && (
        <Modal.Explainer>
          The “Sketch” Workspace doesn’t have a public profile yet — ask an
          Admin to create it!
        </Modal.Explainer>
      )}
    </ConfirmationDialog>
  )
}
