import React from 'react'

import { ReactComponent as CrossIcon } from '@sketch/icons/cross-16'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import { getIsForInAppPresentation } from '../../routes'

import { Flex } from '@sketch/components'

import {
  Container,
  CookieCaption,
  CookieLink,
  Close,
} from './CookieBanner.styles'

interface CookieBannerProps {
  onClickClose: () => void
}

const CookieBanner = ({ onClickClose }: CookieBannerProps) => {
  const isForInAppPresentation = getIsForInAppPresentation()
  const target = isForInAppPresentation ? '_self' : '_blank'

  return (
    <Container>
      <Flex justifyContent="center">
        <CookieCaption>
          By using Sketch, you agree to our{' '}
          <CookieLink
            href={`${SKETCH_WEBSITE}/tos`}
            target={target}
            rel="noopener noreferrer"
          >
            Terms of Service
          </CookieLink>
          .
        </CookieCaption>
        <Close onClick={onClickClose}>
          <CrossIcon width="16px" height="16px" />
        </Close>
      </Flex>
    </Container>
  )
}

export default CookieBanner
