import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "lock-open", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("g", { fill: "currentColor" },
            React.createElement("path", { d: "M5.243 7.5h5.514c.78 0 1.063.081 1.348.234.285.152.509.376.661.661.153.285.234.568.234 1.348v2.514c0 .78-.081 1.063-.234 1.348a1.59 1.59 0 01-.661.661c-.285.153-.568.234-1.348.234H5.243c-.78 0-1.063-.081-1.348-.234a1.59 1.59 0 01-.661-.661C3.08 13.32 3 13.037 3 12.257V9.743c0-.78.081-1.063.234-1.348a1.59 1.59 0 01.661-.661c.285-.153.568-.234 1.348-.234z", fillOpacity: 0.15 }),
            React.createElement("path", { d: "M4.558 7.002L11.257 7c.65 0 .954.056 1.203.163l.145.07c.285.153.509.377.661.662l.07.145c.108.249.164.553.164 1.203v3.514c0 .65-.056.954-.163 1.203l-.07.145a1.59 1.59 0 01-.662.661l-.145.07c-.249.108-.553.164-1.203.164H4.743c-.65 0-.954-.056-1.203-.163l-.145-.07a1.59 1.59 0 01-.661-.662l-.07-.145c-.097-.224-.152-.493-.162-1.018L2.5 9.243c0-.65.056-.954.163-1.203l.07-.145a1.59 1.59 0 01.662-.661l.145-.07c.224-.097.493-.152 1.018-.162zm6.944 1.5H4.498l-.228.01a.9.9 0 00-.048.007l-.07.016-.05.021a.096.096 0 00-.033.027l-.013.02a.309.309 0 00-.021.05l-.016.069a.9.9 0 00-.006.048l-.009.136L4 9.11l.002 3.892.01.228a.9.9 0 00.007.048l.016.07.021.05a.096.096 0 00.027.033l.02.013a.26.26 0 00.05.021l.069.016a.9.9 0 00.048.006l.136.009.337.004 6.759-.002.228-.01a.9.9 0 00.048-.007l.07-.016.05-.021a.096.096 0 00.033-.027l.013-.02a.309.309 0 00.021-.05l.016-.069a.9.9 0 00.006-.048l.009-.136.004-.337-.002-3.759-.01-.228a.9.9 0 00-.007-.048l-.016-.07-.021-.05a.096.096 0 00-.027-.033l-.02-.013a.309.309 0 00-.05-.021l-.069-.016a.9.9 0 00-.048-.006l-.136-.009a5.413 5.413 0 00-.092-.002z", fillRule: "nonzero" })),
        React.createElement("path", { d: "M4.75 7.5V4a3.25 3.25 0 116.5 0v.25h0", stroke: "currentColor", strokeWidth: 1.5, strokeLinecap: "round" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
