import React from 'react'
import {
  AccessLevelSelect,
  AccessLevelSelectCheckbox,
  AccessLevelSelectSeparator,
  AccessLevelSelectOption,
  AccessLevelSelectProps,
} from '@sketch/modules-common'

// Usage of PublicAccessLevel and PrivateAccessLevel will not break the build
// eslint-disable-next-line no-restricted-imports
import { ProjectWorkspaceAccessLevel } from '@sketch/gql-types/expansive'
import { Tooltip } from '@sketch/components'

export interface ProjectAccessSelectProps
  extends AccessLevelSelectProps<
    ProjectWorkspaceAccessLevel,
    ProjectWorkspaceAccessLevel
  > {
  level: ProjectWorkspaceAccessLevel
}

export const ProjectAccessSelect = ({
  level,
  setLevel,
}: ProjectAccessSelectProps) => (
  <AccessLevelSelect
    hideCommentLabel={true}
    level={level}
    setLevel={setLevel}
    noAccessRedColor
  >
    <AccessLevelSelectOption
      data-testid="access-level-dropdown-none"
      level="NONE"
      label="No Access"
      help="No Access for Workspace Members, unless invited"
      negative
    />
    <AccessLevelSelectOption
      data-testid="access-level-dropdown-default"
      level="DEFAULT"
      label="Default"
      help="Workspace Editors can edit and Viewers can inspect"
    />
    <AccessLevelSelectSeparator />
    <Tooltip
      placement="bottom-end"
      content="Workspace Members can always download and inspect"
    >
      <AccessLevelSelectCheckbox
        data-testid="inspect-enabled"
        label="Can download and inspect"
        checked={true}
        disabled={true}
        readOnly={true}
      />
    </Tooltip>
    <Tooltip
      placement="bottom-end"
      content="Workspace Members can always comment"
    >
      <AccessLevelSelectCheckbox
        data-testid="comments-enabled"
        label="Can comment"
        checked={true}
        disabled={true}
        readOnly={true}
      />
    </Tooltip>
  </AccessLevelSelect>
)
