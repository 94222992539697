import styled from 'styled-components'

import { ReactComponent as CrossIcon } from '@sketch/icons/cross-16'

import { Text } from '../Box'
import { ButtonUnstyled } from '../Button'

export const StyledCrossIcon = styled(CrossIcon)`
  width: 16px;
  height: 16px;
`

export const CloseButton = styled(ButtonUnstyled)`
  align-self: center;
  display: flex;
  opacity: 0.4;

  :hover {
    opacity: 0.85;
  }
`

export const Container = styled.section`
  background-color: ${({ theme }) => theme.colors.background.secondary.A};
  border-radius: ${({ theme }) => theme.radii.medium};
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  z-index: 1;
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.colors.background.secondary.A};
  border-radius: ${({ theme }) => `
    ${theme.radii.medium} ${theme.radii.medium} 0px 0px
  `};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`

export const Title = styled(Text).attrs({
  textStyle: 'copy.primary.standard.D',
})`
  margin: 0;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 1.6;
`

export const Content = styled.div`
  padding: 16px;
`
