import { CredentialsFragment } from '@sketch/gql-types'
import { isObject } from 'lodash'
import { Authorization } from '@sketch/modules-common'

export const formatAuthorizationHeader = (
  credentials: CredentialsFragment | undefined | null
) => {
  return credentials ? `bearer ${credentials.authToken}` : null
}

const normalizeHeaders = (headers: RequestInit['headers']) => {
  if (Array.isArray(headers)) {
    return Object.fromEntries(headers)
  } else if (headers instanceof Headers) {
    return Object.fromEntries(headers.entries())
  } else if (isObject(headers)) {
    return { ...headers }
  } else {
    return {}
  }
}

export const addCredentialsToOptions = (
  authorizations: Authorization[],
  options?: RequestInit
) => {
  const [primaryAuthorization, ...secondaryAuthorizations] = authorizations
  const headers = normalizeHeaders(options?.headers)

  if (primaryAuthorization) {
    headers['authorization'] = formatAuthorizationHeader(
      primaryAuthorization.fragment
    )!
  }

  if (secondaryAuthorizations.length > 0) {
    headers['X-Auth-Token-Count'] = `${secondaryAuthorizations.length}`

    secondaryAuthorizations.forEach(({ fragment }, index) => {
      headers[`X-Auth-Token-${index + 1}`] = formatAuthorizationHeader(
        fragment
      )!
    })
  }

  return { ...options, headers }
}
