import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "folder-closed-20", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: 1, fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "Cloud/20/Folder", fillRule: "nonzero" },
            React.createElement("g", { id: "Group", transform: "translate(2 3)" },
                React.createElement("path", { d: "M2.581.5h2.212c.32 0 .627.127.853.354.414.413.976.646 1.561.646h5.909c.861 0 1.18.072 1.497.241.278.15.497.368.646.646.169.317.241.636.241 1.497h0v7.232c0 .861-.072 1.18-.241 1.497a1.551 1.551 0 01-.646.646c-.317.169-.636.241-1.497.241h0H2.884c-.861 0-1.18-.072-1.497-.241a1.551 1.551 0 01-.646-.646C.572 12.296.5 11.977.5 11.116h0V2.58c0-.532.078-.888.241-1.194.15-.278.368-.497.646-.646C1.693.578 2.049.5 2.58.5h0z", id: "Rectangle", stroke: "currentColor" }),
                React.createElement("path", { id: "Rectangle", fillOpacity: 0.2, fill: "currentColor", d: "M1 4h14v9H1z" }),
                React.createElement("path", { id: "Rectangle", fill: "currentColor", d: "M1 3h14v1H1z" })))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
