import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "chat-bubbles-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "currentColor" },
        React.createElement("path", { d: "M13.321 3.684c1.704.848 2.61 2.475 2.61 4.816l-.006.314c-.04 1.137-.306 2.109-.8 2.9l-.051.077.092.275c.176.491.374.926.595 1.305a1.75 1.75 0 01-2.086 2.532l-.29-.11c-.865-.35-1.66-.89-2.382-1.613l-.204-.215-.22.017c-.21.012-.426.018-.649.018-1.89 0-3.364-.455-4.378-1.34l.153-.138.025-.026.267.004c.756 0 1.461-.065 2.114-.193.524.129 1.13.193 1.82.193.384 0 .744-.02 1.079-.062a.75.75 0 01.661.256c.755.88 1.586 1.473 2.497 1.792a.25.25 0 00.295-.362c-.372-.64-.684-1.391-.937-2.254a.75.75 0 01.134-.679c.506-.634.77-1.524.77-2.691 0-1.259-.304-2.209-.935-2.866l.002-.134c0-.56-.044-1.089-.132-1.588z" }),
        React.createElement("path", { d: "M5.997 0c3.87 0 6 1.91 6 5.5s-2.13 5.5-6 5.5c-.222 0-.439-.006-.65-.018l-.213-.017-.207.22c-.72.733-1.493 1.272-2.321 1.61l-.278.104a1.75 1.75 0 01-2.133-2.444c.198-.389.385-.841.561-1.357l.1-.305-.054-.08c-.494-.79-.76-1.762-.8-2.899L-.003 5.5c0-3.59 2.129-5.5 6-5.5zm0 1.5c-3.077 0-4.5 1.277-4.5 4 0 1.167.264 2.057.77 2.691a.75.75 0 01.134.68c-.258.877-.546 1.632-.868 2.261a.25.25 0 00.304.35c.849-.296 1.655-.885 2.417-1.785a.75.75 0 01.664-.26c.335.042.694.063 1.079.063 3.076 0 4.5-1.277 4.5-4s-1.424-4-4.5-4z" }),
        React.createElement("path", { d: "M5.997 1.5c-3.077 0-4.5 1.277-4.5 4 0 1.167.264 2.057.77 2.691a.75.75 0 01.134.68c-.258.877-.546 1.632-.868 2.261a.25.25 0 00.304.35c.849-.296 1.655-.885 2.417-1.785a.75.75 0 01.664-.26c.335.042.694.063 1.079.063 3.076 0 4.5-1.277 4.5-4s-1.424-4-4.5-4z", fillOpacity: 0.15 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
