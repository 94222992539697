import { useVersionStar } from './useVersionStar'
import { useVersionUnstar } from './useVersionUnstar'

export interface UseVersionStarUnstarProps {
  shareIdentifier: string
  versionIdentifier: string
  versionDescription?: string
  isNewLatest: boolean
  isLibrary: boolean
}

const useVersionStarUnstar = ({
  versionDescription,
  ...props
}: UseVersionStarUnstarProps) => {
  const [unstar] = useVersionUnstar(props)
  const [star] = useVersionStar({ ...props, description: versionDescription })

  return (starred: boolean) => {
    starred ? unstar() : star()
  }
}

export default useVersionStarUnstar
