import React from 'react'

import { NavbarFilter } from '../Navbar/NavbarFilter'

import { ReactComponent as Logo } from '@sketch/icons/logo'

import { NavbarSection, NavbarExtendedBase } from './Navbar.styles'
import {
  NavbarTitle,
  NavbarDivider,
  FixedWidthNavbarSection,
  LogoBlack,
  NavbarButton,
  NavbarLogoWrapper,
} from '../Navbar/Navbar.styles'

const NavbarLogo = ({ variant }: { variant?: 'colored' | 'black' }) => (
  <NavbarLogoWrapper aria-haspopup="menu" tabIndex={0} role="button">
    {variant === 'black' ? (
      <LogoBlack />
    ) : (
      <Logo width="27px" height="25px" style={{ margin: 'auto' }} />
    )}
  </NavbarLogoWrapper>
)

const NavbarExtended = Object.assign(NavbarExtendedBase, {
  Button: NavbarButton,
  Divider: NavbarDivider,
  Section: NavbarSection,
  FixedWidthSection: FixedWidthNavbarSection,
  Logo: NavbarLogo,
  Title: NavbarTitle,
  Filter: NavbarFilter,
})

export { NavbarExtended }
