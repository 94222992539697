import React from 'react'
import { useThemeContext } from '@sketch/global-styles'
import { useFlag } from '@sketch/modules-common'
import { Checkbox, Text, Section } from '@sketch/components'
import {
  ThemeOption,
  Thumbnail,
  RadioButtonStyled,
  Themes,
} from './AppearanceSettings.styles'
import { ReactComponent as DarkMode } from '@sketch/icons/appearance-settings-dark-mode'
import { ReactComponent as LightMode } from '@sketch/icons/appearance-settings-light-mode'

const AppearanceSettings: React.FC = () => {
  const isDarkModeToggleEnabled = useFlag('dark-mode-toggle')
  const {
    enableLightMode,
    enableDarkMode,
    toggleMatchSystem,
    theme,
    matchSystem,
  } = useThemeContext()

  if (!isDarkModeToggleEnabled) {
    return null
  }

  return (
    <Section title="Theme">
      <Text mt={0} mb={6}>
        Choose how Sketch will appear across all Workspaces.
      </Text>
      <Checkbox
        checked={matchSystem}
        onChange={toggleMatchSystem}
        label="Match to System Settings"
      />
      <Themes>
        <ThemeOption
          active={!matchSystem && theme === 'light'}
          onClick={enableLightMode}
        >
          <RadioButtonStyled
            disabled={matchSystem}
            name="theme"
            label="Light"
            value="light"
            checked={theme === 'light'}
            onChange={enableLightMode}
          />
          <Thumbnail as={LightMode} disabled={matchSystem} />
        </ThemeOption>
        <ThemeOption
          active={!matchSystem && theme === 'dark'}
          onClick={enableDarkMode}
        >
          <RadioButtonStyled
            disabled={matchSystem}
            name="theme"
            label="Dark"
            value="dark"
            checked={theme === 'dark'}
            onChange={enableDarkMode}
          />
          <Thumbnail as={DarkMode} disabled={matchSystem} />
        </ThemeOption>
      </Themes>
    </Section>
  )
}

export default AppearanceSettings
