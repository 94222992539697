import React, { FC, useContext, Fragment } from 'react'
import styled from 'styled-components'
import { useKey } from 'react-use'
import { shortcuts, keyWithoutModifier } from '@sketch/utils'

import { Flex, Text } from '../Box'
import { IconButton } from '../Button'
import { ModalContext } from '../ModalManager'

import { ReactComponent as ChevronRight } from '@sketch/icons/circle.chevron.right'

const ChevronLeft = styled(ChevronRight)`
  transform: rotate(180deg);
`

const PaginationControlsNumbers = styled(Text.Span).attrs({
  textStyle: 'copy.primary.standard.D',
})`
  font-family: ${({ theme }) => theme.fonts.buttons};
  margin-top: -1px; /* HACK: vertical align with buttons */ /* stylelint-disable-line scales/space */
  font-variant-numeric: tabular-nums;
`

const PaginationControlsButton = styled(IconButton).attrs({
  size: 20,
})`
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  margin-left: 9px; /* stylelint-disable-line scales/space */
  margin-right: 9px; /* stylelint-disable-line scales/space */

  &:hover {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  :disabled {
    opacity: 0.5;
  }
`

type PaginationControlsProps = {
  total: number
  current: number
  onClickNext?: () => void
  onClickPrevious?: () => void
  showNumbers?: boolean
  onlyKeyboardNavigation?: boolean
}

export const PaginationControls: FC<PaginationControlsProps> = ({
  total,
  current,
  onClickNext,
  onClickPrevious,
  showNumbers = true,
  onlyKeyboardNavigation = false,
  ...props
}) => {
  const { isModalOpen } = useContext(ModalContext)

  const disablePrevious = current <= 1 || !onClickPrevious || isModalOpen
  const disableNext = current === total || !onClickNext || isModalOpen

  // Keyboard shortcuts to navigate to prev/next artboard
  useKey(keyWithoutModifier(shortcuts.paginationPrevious), () => {
    if (!disablePrevious) {
      onClickPrevious()
    }
  })

  useKey(keyWithoutModifier(shortcuts.paginationNext), () => {
    if (!disableNext) {
      onClickNext()
    }
  })

  return (
    <Fragment>
      {!onlyKeyboardNavigation && (
        <Flex alignItems="center" {...props}>
          <PaginationControlsButton
            data-testid="pagination-previous"
            icon={ChevronLeft}
            onClick={onClickPrevious}
            disabled={disablePrevious}
          />
          {showNumbers && (
            <PaginationControlsNumbers>
              {current}/{total}
            </PaginationControlsNumbers>
          )}
          <PaginationControlsButton
            data-testid="pagination-next"
            icon={ChevronRight}
            onClick={onClickNext}
            disabled={disableNext}
          />
        </Flex>
      )}
    </Fragment>
  )
}
