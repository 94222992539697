import React from 'react'
import { ReactComponent as CrossIcon } from '@sketch/icons/cross-20'

import { Navbar } from '@sketch/components'
import { SidebarLoadingPage } from './SidebarLoadingPage'

const CrossButton = () => <Navbar.Button icon={CrossIcon} />

export const ArtboardDetailsLoadingPage = () => {
  return <SidebarLoadingPage logo={CrossButton} footer={null} />
}
