import React from 'react'
import styled from 'styled-components'
import {
  LoadingPlaceholder,
  LoadingPlaceholderProps,
} from '../LoadingPlaceholder'

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
`

const StyledMessageContainer = styled.div`
  position: absolute;

  /* 16px for extra space between the logo and text */
  margin-top: calc(74px + 16px);
`

export interface LoadingStateProps {
  message?: React.ReactNode
  size?: LoadingPlaceholderProps['size']
  type?: LoadingPlaceholderProps['type']
}

export const LoadingState = ({
  message,
  size = '64px',
  type = 'bar',
}: LoadingStateProps) => (
  <LoadingWrapper>
    <LoadingPlaceholder size={size} type={type} />
    {message && <StyledMessageContainer>{message}</StyledMessageContainer>}
  </LoadingWrapper>
)
