import styled from 'styled-components'
import { Heading, FormField } from '@sketch/components'

import { breakpoint } from '@sketch/global-styles'

export const TitleWrapper = styled.div`
  padding-top: 20px;
`

export const H3 = styled(Heading.H3)`
  font-weight: 600;
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 4px;
`

export const ResponsiveInlineField = styled.div`
  display: flex;

  ${breakpoint('base', 'sm')`
    flex-direction: column;
  `}

  ${FormField} {
    flex: 0.5;

    + ${FormField} {
      flex: 0.5;
      margin-left: 20px;

      ${breakpoint('base', 'sm')`
        flex: 1 0;
        margin-left: 0;
        margin-bottom: 20px;
      `};
    }
  }
`
