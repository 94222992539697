import React, { useEffect, useState, useRef } from 'react'

import { SelectList } from './SelectList'
import {
  SelectDropdownButtonTrigger,
  ChevronsUpDownIcon,
} from './SelectDropdown.styles'
import { MenuContextProvider } from './SubMenu'

type DropdownState<T> = {
  active: boolean
  setItem: (item?: T) => void
}

type ItemOptions<T> = DropdownState<T> & {
  a11yProps: {
    id: string
    role: 'option'
  }
}

interface SelectDropdownProps<T> {
  placeholder: string
  disabled?: boolean

  value?: T
  items: T[] | ReadonlyArray<T>
  onChange?: (item: T, path: any[]) => void

  renderValueText?: (item: T) => string
  renderItem: (item: T, options: ItemOptions<T>) => React.ReactNode

  headerItem?: React.ReactNode
  footerItem?: React.ReactNode
}

/**
 * SelectDropdown
 *
 * This component renders a visual looking select that shows either
 * a modal on mobile and a dropdown in tablet/desktop.
 *
 * This components receives a "items" prop that in conjunction with the
 * "renderItem" one represents the items visually on the viewport wrapper
 *
 * Usage:
 * <SelectDropdown
 *    placeholder="Select a fruit..."
 *    items={fruits}
 *    renderItem={(item, props) => <SelectDropdownItem text={item} {...props} />}
 *  />
 */
export function SelectDropdown<T>(props: SelectDropdownProps<T>) {
  const {
    placeholder,
    value,
    renderItem,
    renderValueText = item => `${item}`,
    items,
    onChange,
    headerItem,
    footerItem,
    disabled,
  } = props

  const [expandedState, setExpanded] = useState(false)
  const [forceExpanded, setForceExpanded] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [activePath, setActivePath] = useState<any[]>([])

  const expanded = expandedState || forceExpanded

  // If we close the dropdown the button goes back to focus
  useEffect(() => {
    const button = buttonRef.current

    if (expanded) {
      return () => {
        button?.focus()
      }
    }
  }, [expanded])

  // If the value changed then we need to close the dropdown
  useEffect(() => {
    setExpanded(false)
    setForceExpanded(false)
  }, [value])

  const renderChildren = (item: T, index: number) => {
    const options: ItemOptions<T> = {
      active: item === value,
      setItem: (clickedItem = item) => {
        const path = [clickedItem]
        onChange?.(clickedItem, path)
        setActivePath(path)

        setExpanded(false)
      },
      a11yProps: {
        role: 'option',
        id: `option-${index}`,
      },
    }

    return (
      <React.Fragment key={index}>{renderItem(item, options)}</React.Fragment>
    )
  }

  const children = (
    <div role="listbox" tabIndex={-1}>
      {items.map(renderChildren)}
    </div>
  )

  const contextSetValue = (item: T, path: any[]) => {
    setExpanded(false)

    if (onChange) {
      onChange?.(item, path)
      setActivePath(path)
    }
  }

  return (
    <MenuContextProvider
      value={{
        activePath,
        setValue: contextSetValue,
        setForceExpanded,
        forceExpanded,
      }}
    >
      <SelectDropdownButtonTrigger
        aria-placeholder={placeholder}
        type="button"
        aria-haspopup
        aria-valuetext={value && renderValueText(value)}
        aria-expanded={expanded}
        disabled={disabled}
        ref={buttonRef}
        onClick={() => setExpanded(value => !value)}
      >
        {value ? renderValueText(value) : placeholder}
        <ChevronsUpDownIcon />
      </SelectDropdownButtonTrigger>

      <SelectList
        expanded={expanded}
        headerItem={headerItem}
        footerItem={footerItem}
        placement="bottom-start"
        setExpanded={getState => {
          setExpanded(expanded => getState(expanded, buttonRef.current!))
        }}
        usePortal
      >
        {children}
      </SelectList>
    </MenuContextProvider>
  )
}
