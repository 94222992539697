import styled from 'styled-components'

import { ReactComponent as Cross } from '@sketch/icons/cross-20'

import { Form, LoadingPlaceholder } from '@sketch/components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledField = styled(Form.Field)`
  position: relative;
  width: 100%;
  margin: 0 16px 0 0;
`

export const StyledForm = styled(Form)`
  display: flex;
  align-items: flex-end;
  width: 100%;
`

export const StyledLoadingPlaceholder = styled(LoadingPlaceholder)`
  margin-right: 4px;
`

export const ErrorIcon = styled(Cross)`
  color: ${({ theme }) => theme.colors.state.negative.A};
`

export const FormError = styled(Form.Error)`
  margin-top: 6px; /* stylelint-disable-line scales/space */
`
