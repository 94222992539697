import { Color } from '../../../../../../../types'

export const colorStep = (
  startColor: Color,
  endColor: Color,
  stepCount: number
): Color => {
  const red = (endColor.red - startColor.red) / stepCount
  const green = (endColor.green - startColor.green) / stepCount
  const blue = (endColor.blue - startColor.blue) / stepCount

  const currentAlpha = startColor.alpha === 0 ? 0 : startColor.alpha || 1
  const nextAlpha = endColor.alpha === 0 ? 0 : endColor.alpha || 1
  const alpha = (nextAlpha - currentAlpha) / stepCount

  return {
    red,
    green,
    blue,
    alpha,
  }
}

export const nextColor = (
  color: Color,
  stepColor: Color,
  stepCount: number
): Color => {
  const red = color.red + stepColor.red * stepCount
  const green = color.green + stepColor.green * stepCount
  const blue = color.blue + stepColor.blue * stepCount

  const colorAlpha = color.alpha === 0 ? 0 : color.alpha || 1
  const stepColorAlpha = stepColor.alpha === 0 ? 0 : stepColor.alpha || 0
  const alpha = colorAlpha + stepColorAlpha * stepCount

  return {
    red,
    green,
    blue,
    alpha,
  }
}
