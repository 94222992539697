import { JSXElementConstructor } from 'react'

const MAX_STRING_LENGTH = 20

/**
 * insprired by https://reactjs.org/docs/higher-order-components.html
 */
export function getDisplayName(
  WrappedComponent: keyof JSX.IntrinsicElements | JSXElementConstructor<any>
): string {
  try {
    const Component = WrappedComponent as any
    if (typeof Component === 'string') {
      return Component
    }
    if (Component.displayName || Component.name) {
      return Component.displayName || Component.name
    }
    if (typeof Component === 'function') {
      const compString = (Component as () => {}).toString()
      return compString.length > MAX_STRING_LENGTH
        ? compString.substr(0, MAX_STRING_LENGTH) + '...'
        : compString
    }
  } catch {
    // this should never happen, but in case we missed something - let's be safe
    return 'Component'
  }
  // this should never happen, but in case we missed something - let's be safe
  return 'Component'
}
