export function computeSymbolMasterNames(name: string) {
  const parts = name.split('/')
  if (parts.length === 1) {
    return {
      symbolMasterShortName: undefined,
      symbolMasterNamePath: undefined,
    }
  }

  const symbolMasterShortName = parts.slice(-1)[0]
  const symbolMasterNamePath = parts.slice(0, -1)

  return {
    symbolMasterShortName: symbolMasterShortName.trim(),
    symbolMasterNamePath: symbolMasterNamePath
      // Remove empty parts
      .filter(Boolean)
      .map(p => p.trim()),
  }
}
