import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'

import { useVersioning } from '../../../versioning'

type ComponentUuid = string

type ComponentDescriptionValue = {
  shareIdentifier: string
  baseVersionIdentifier?: string
  compatibilityVersion: number
  canEditDescriptions: boolean
  documentVersion: number
  description?: string | null
}

export type ComponentDescriptionProps = {
  componentUuid: string
} & ComponentDescriptionValue

export type ComponentDescriptionContext = {
  selected: ComponentUuid | null
  descriptions: Record<ComponentUuid, ComponentDescriptionValue>
}

const defaultContextValue = {
  selected: null,
  descriptions: {},
}

const context = createContext<
  [
    ComponentDescriptionContext,
    Dispatch<SetStateAction<ComponentDescriptionContext>>
  ]
>([defaultContextValue, () => {}])

export const ComponentDescriptionContextProvider: React.FC = ({ children }) => {
  const value = useState<ComponentDescriptionContext>(defaultContextValue)

  return <context.Provider value={value}>{children}</context.Provider>
}

export const useComponentsDescriptionContext = () => {
  const [componentsDescription, setComponentsDescription] = useContext(context)!

  const { versionIdentifier } = useVersioning()

  const resetComponentDescriptionSelection = () => {
    setComponentsDescription({
      selected: null,
      descriptions: componentsDescription.descriptions,
    })
  }

  // Adds new component description or updates an existing one
  const onComponentSelected = (
    id: ComponentUuid,
    pendingDescription?: ComponentDescriptionProps
  ) => {
    if (!pendingDescription?.componentUuid || !versionIdentifier) {
      return
    }

    const { componentUuid, ...descriptionWithoutUuid } = pendingDescription

    // If the component already exists in the context (for the same version)
    // we just update the `selected` field
    if (
      Object.keys(componentsDescription.descriptions).includes(id) &&
      componentsDescription.descriptions?.[id]?.baseVersionIdentifier ===
        versionIdentifier
    ) {
      setComponentsDescription({
        ...componentsDescription,
        selected: id,
      })
      return
    }

    // At this point we are updating the context adding a new description
    setComponentsDescription({
      selected: id,
      descriptions: {
        ...componentsDescription.descriptions,
        [id]: descriptionWithoutUuid,
      },
    })
  }

  const updateDescripton = (id: ComponentUuid, description: string) => {
    setComponentsDescription({
      selected: id,
      descriptions: {
        ...componentsDescription.descriptions,
        [id]: {
          ...componentsDescription.descriptions[id],
          description,
        },
      },
    })
  }

  return {
    componentsDescription,
    resetComponentDescriptionSelection,
    onComponentSelected,
    updateDescripton,
  }
}
