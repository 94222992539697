import {
  LayerStyle,
  TextLayerStyleProperties,
  LayerStyleWithSharedStyle,
  ElementType,
  SketchNonGraphicLayerElement,
  SketchGroupLayerElement,
  SketchSymbolInstanceLayerElement,
} from '../types'
import {
  SketchArtboardElement,
  SketchElement,
  SketchLayerElement,
  SketchPageElement,
} from '../types/SketchScene'

export function isArtboardElement(
  element: SketchElement
): element is SketchArtboardElement {
  return isArtboardElementType(element.type)
}

export const findArtboardElementFromSelected = (
  selectedElement: SketchElement
) => {
  if (isArtboardElement(selectedElement)) {
    return selectedElement
  } else if (
    selectedElement.artboardAncestorElement &&
    isArtboardElement(selectedElement.artboardAncestorElement)
  ) {
    return selectedElement.artboardAncestorElement
  }

  return null
}

// Finds the artboard element from these 2 scenarios:
// - When an element is selected
// - When there is an artboard in the artboard detail view
export const findArtboardElement = (
  selectedElement: SketchElement | null,
  sketchSceneRootElement: SketchElement | null
) => {
  return selectedElement
    ? findArtboardElementFromSelected(selectedElement)
    : sketchSceneRootElement && isArtboardElement(sketchSceneRootElement)
    ? sketchSceneRootElement
    : null
}

export function isPageElement(
  element: SketchElement
): element is SketchPageElement {
  return element.type === ElementType.Page
}

export function isLayerElement(
  element: SketchElement
): element is SketchLayerElement {
  return !isPageElement(element) && !isArtboardElement(element)
}

export function isSymbolMasterElement(element: SketchElement) {
  return element.type === ElementType.SymbolMaster
}

export function isArtboardElementType(type: ElementType) {
  return type === ElementType.Artboard || type === ElementType.SymbolMaster
}

export function isGroupElement(
  element: SketchElement
): element is SketchGroupLayerElement | SketchSymbolInstanceLayerElement {
  return isGroupElementType(element.type)
}

export function isGroupElementType(type: ElementType) {
  return type === 'group' || type === 'symbolInstance'
}

export function isLayerWithSharedStyle(
  style: LayerStyle | undefined
): style is LayerStyleWithSharedStyle {
  return style ? 'isDirty' in style : false
}

export function isTextLayerStyle(
  style: LayerStyle
): style is TextLayerStyleProperties {
  return 'text' in style
}

export function isNonGraphicLayerElement(
  element: SketchElement
): element is SketchNonGraphicLayerElement {
  return isNonGraphicElementType(element.type)
}

export function isNonGraphicElementType(type: SketchElement['type']) {
  return type === ElementType.Hotspot || type === ElementType.Slice
}
