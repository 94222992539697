import React from 'react'

import { MenuIconButton } from '@sketch/components'

import { ReactComponent as AddChatBubbles } from '@sketch/icons/chat-bubble-add-24'
import { useAnnotationOverlayContext } from '../../../annotations/hooks'

export const AddAnnotationButton = () => {
  const { setPlaceDraftAnnotation, placeDraftAnnotation } =
    useAnnotationOverlayContext() || {}

  return (
    <MenuIconButton
      active={placeDraftAnnotation}
      description="Add comment"
      onClick={() => {
        setPlaceDraftAnnotation?.(!placeDraftAnnotation)
      }}
    >
      <AddChatBubbles role="img" />
      <span className="sr-only">Add comment</span>
    </MenuIconButton>
  )
}
