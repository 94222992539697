import React from 'react'

import {
  WorkspaceLogo,
  AvatarStack,
  useModalContext,
  PermissionGroupIcon,
} from '@sketch/components'
import ProjectMembershipAvatar from '../ProjectMembershipAvatar'
import { ProjectSharingModal } from '../../modals/ProjectSharingModal'
import {
  SharingButton as StyledSharingButton,
  TextWrapper,
} from './ProjectHeader.styles'
import {
  WorkspaceMinimalFragment,
  ProjectInSidebarAndHeaderFragment,
  GetProjectMembershipsQuery,
} from '@sketch/gql-types'
import {
  GuestLogoWrapper,
  PrivacyIconWrapper,
  WorkspaceLogoWrapper,
  AvatarWrapper,
} from './SharingButton.styles'

type SharingButtonProps = {
  project: ProjectInSidebarAndHeaderFragment
  workspace: WorkspaceMinimalFragment
  userCanRenameAndDeleteProject?: boolean
  memberships?: GetProjectMembershipsQuery['project']['members']
  headerSlim?: boolean
}

type PrivacyAndWorkspaceIconsProps = {
  project: ProjectInSidebarAndHeaderFragment
  workspace: WorkspaceMinimalFragment
}

const PrivacyAndWorkspaceIcons: React.FC<PrivacyAndWorkspaceIconsProps> = props => {
  const { project, workspace } = props

  if (project.privacyIcon === 'INVITED_WORKSPACE_MEMBERS') {
    // For "restricted" projects, we show the lock icon without the workspace avatar/
    // Users must be invited to access shares in this project.
    return (
      <WorkspaceLogo.Icon
        size="24px"
        icon="lock"
        data-testid="workspace-logo-lock"
      />
    )
  } else if (project.privacyIcon === 'GUESTS') {
    // For non-restricted projects with guests invited, we show the "people"
    // icon to represent guests along with the workspace avatar.
    return (
      <GuestLogoWrapper>
        <PrivacyIconWrapper>
          <WorkspaceLogo.Icon
            size="24px"
            icon="people"
            data-testid="workspace-logo-people"
          />
        </PrivacyIconWrapper>
        <WorkspaceLogoWrapper>
          <WorkspaceLogo
            key={workspace.identifier}
            size="24px"
            workspaceName={workspace.name}
            src={workspace.avatar?.small}
          />
        </WorkspaceLogoWrapper>
      </GuestLogoWrapper>
    )
  } else {
    // For non-restricted projects with no guests invited, we show the workspace avatar
    return (
      <WorkspaceLogo
        key={workspace.identifier}
        size="24px"
        workspaceName={workspace.name}
        src={workspace.avatar?.small}
      />
    )
  }
}

const SharingButton = ({
  project,
  memberships,
  workspace,
  userCanRenameAndDeleteProject,
  headerSlim,
}: SharingButtonProps) => {
  const { showModal } = useModalContext()

  const totalMembershipsCount = memberships?.meta?.totalCount || 0
  const members = memberships?.entries || []

  const isAdmin = workspace.userRole === 'ADMIN'
  const isUserEditor = workspace.userCanEdit

  const canOpenSharingModal = isAdmin || isUserEditor

  const workspaceAndAvatarStacks = (
    <AvatarWrapper>
      {!headerSlim && (
        <>
          <WorkspaceLogoWrapper>
            <PrivacyAndWorkspaceIcons workspace={workspace} project={project} />
          </WorkspaceLogoWrapper>
          <AvatarStack size="24px" totalCount={totalMembershipsCount}>
            {...members.map(member => {
              if (member.__typename === 'PermissionGroupRelationProject') {
                return (
                  <PermissionGroupIcon
                    key={member.identifier}
                    name={member.permissionGroup.name}
                    size="24px"
                  />
                )
              }

              return (
                <ProjectMembershipAvatar
                  key={member.identifier}
                  membership={member}
                  size="24px"
                />
              )
            })}
          </AvatarStack>
        </>
      )}

      {canOpenSharingModal && (
        <TextWrapper>
          {userCanRenameAndDeleteProject ? 'Share…' : 'Sharing'}
        </TextWrapper>
      )}
    </AvatarWrapper>
  )

  if (!canOpenSharingModal) {
    return workspaceAndAvatarStacks
  }

  return (
    <StyledSharingButton
      data-testid="project-sharing-settings"
      variant={headerSlim ? 'secondary-untinted' : 'secondary'}
      onClick={() => {
        showModal(ProjectSharingModal, {
          project: project,
          workspace,
        })
      }}
    >
      {workspaceAndAvatarStacks}
    </StyledSharingButton>
  )
}

export default SharingButton
