import { SYSTEM_FONT } from '@sketch/global-styles'
import merge from 'lodash.merge'
import { Theme } from 'styled-components'

/**
 * Important note: don't use any HSL color in these stripe style objects,
 * Stripe doesn't support it, an error is thrown and inputs are unusable.
 *
 * This is the documentation about style object where it's not clear that they
 * don't accept HSL: https://stripe.com/docs/js/appendix/style?type=card
 */
export const commonStyles = (theme: Theme) => {
  return {
    base: {
      fontFamily: `Marfa, ${SYSTEM_FONT}`,
      fontSmoothing: 'antialiased',
      color: theme.colors.stripe.foreground,
    },
    invalid: {
      color: theme.colors.stripe.foreground,
    },
  }
}

export const invalidStyles = (theme: Theme) => {
  return merge({}, commonStyles(theme), {
    base: {
      '::placeholder': {
        color: theme.colors.stripe.invalid,
      },
    },
  })
}
