import { ApolloLink } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import * as Sentry from '@sentry/browser'

export const createSubscriptionsSentryLink = (): ApolloLink => {
  return new ApolloLink((operation, forward) => {
    const { query } = operation
    const mainDefinition = getMainDefinition(query)
    if (
      mainDefinition.kind !== 'OperationDefinition' ||
      mainDefinition.operation !== 'subscription'
    ) {
      return forward(operation)
    }
    return forward(operation).map(result => {
      Sentry.addBreadcrumb({
        category: 'Subscription',
        message: mainDefinition.name?.value || 'unknown',
        level:
          (result.errors?.length ?? 0) > 0 || !result.data ? 'error' : 'log',
      })

      return result
    })
  })
}
