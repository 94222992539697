import React from 'react'

import { Button, BannerSection, useModalContext } from '@sketch/components'
import { PublishProfileModal } from '../../../community/modals'

export interface HiddenProfileBannerProps {
  identifier: string
}

export const HiddenProfileBanner = (props: HiddenProfileBannerProps) => {
  const { showModal } = useModalContext()
  const { identifier } = props

  return (
    <BannerSection
      type="neutral"
      rounded={false}
      size="tiny"
      borders={['bottom']}
      button={
        <Button
          size="32"
          variant="primary-untinted"
          onClick={() => showModal(PublishProfileModal, { identifier })}
        >
          Publish Profile…
        </Button>
      }
    >
      Your public profile is currently hidden — only you and other Admins can
      see it.
    </BannerSection>
  )
}
