import styled from 'styled-components'

import { LinkButton } from '@sketch/components'

const VersionLinkButton = styled(LinkButton).attrs({ size: '32' })`
  min-width: 40px;
  padding: 4px 12px;
  font-size: ${({ theme }) => theme.fontSizes.D};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  box-shadow: none;
  color: ${({ theme }) => theme.colors.foreground.primary.A};
  background-color: ${({ theme }) => theme.colors.sketch.B};
  border: none;

  &:hover {
    color: ${({ theme }) => theme.colors.foreground.primary.A};
    background-color: ${({ theme }) => theme.colors.sketch.B};
  }
`

export default VersionLinkButton
