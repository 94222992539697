import {
  ErrorMessage as ErrorMessageBase,
  Title as ErrorMessageTitle,
} from './ErrorMessage'
import { GenericError } from './GenericError'

export {
  Title as ErrorMessageTitle,
  Body as ErrorMessageBody,
  Container as ErrorMessageContainer,
} from './ErrorMessage'
export type { ErrorMessageProps } from './ErrorMessage'
export { GenericError } from './GenericError'

export const ErrorMessage = Object.assign(ErrorMessageBase, {
  Title: ErrorMessageTitle,
  Generic: GenericError,
})
