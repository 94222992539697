import React from 'react'
import styled from 'styled-components'
import { Tooltip, Truncate } from '@sketch/components'
import { useHandleCopyValue, CopiableArea } from '@sketch/modules-common'

const Name = styled(Truncate)`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: 0.8125rem; /* 13px */
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`

const SubName = styled(Truncate)`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  font-size: 0.75rem; /* 12px */
`

export const ComponentName: React.FC = ({ children }) => {
  const {
    handleCopyValue,
    copyTooltipText,
    handleEnterCopiableArea,
    handleLeaveCopiableArea,
    copyTooltipVisible,
  } = useHandleCopyValue(`${children}`)

  return (
    <CopiableArea
      onClick={handleCopyValue}
      onMouseEnter={handleEnterCopiableArea}
      onMouseLeave={handleLeaveCopiableArea}
    >
      <Tooltip
        placement="left"
        spacing="10px"
        visible={copyTooltipVisible}
        content={copyTooltipText}
      >
        <Name>{children}</Name>
      </Tooltip>
    </CopiableArea>
  )
}

export const ComponentSubName: React.FC = ({ children }) => {
  const {
    handleCopyValue,
    copyTooltipText,
    handleEnterCopiableArea,
    handleLeaveCopiableArea,
    copyTooltipVisible,
  } = useHandleCopyValue(`${children}`)

  return (
    <CopiableArea
      onClick={handleCopyValue}
      onMouseEnter={handleEnterCopiableArea}
      onMouseLeave={handleLeaveCopiableArea}
    >
      <Tooltip
        placement="left"
        spacing="10px"
        visible={copyTooltipVisible}
        content={copyTooltipText}
      >
        <SubName>{children}</SubName>
      </Tooltip>
    </CopiableArea>
  )
}
