import React from 'react'
import { ReactComponent as CommentExclamationMarkIcon } from '@sketch/icons/comment-exclamation-64'
import InfoMessage from '../InfoMessage'

interface CommentTabMessageErrorProps {
  title: string
}

const CommentTabMessageError: React.FC<CommentTabMessageErrorProps> = ({
  title,
  children,
}) => (
  <InfoMessage title={title} icon={CommentExclamationMarkIcon}>
    {children}
  </InfoMessage>
)

export default CommentTabMessageError
