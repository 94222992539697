import { WorkspaceCreationState, WorkspaceMembers, Member } from '../../types'
import {
  CreateWorkspaceMutationVariables,
  CreateWorkspaceMutation,
  InitialUserFragment,
} from '@sketch/gql-types'

type CreateWorkspaceMembers = NonNullable<
  CreateWorkspaceMutationVariables['input']['members']
>
export type CreateWorkspaceResponse = NonNullable<
  CreateWorkspaceMutation['createWorkspace']['workspace']
>

const formatMembers = (members: WorkspaceMembers): CreateWorkspaceMembers =>
  members.map(({ email, isEditor, isAdmin }) => ({
    email,
    accessLevel: isEditor ? 'EDITOR' : 'VIEWER',
    admin: isAdmin,
  }))

export const formatMutationInput = (state: WorkspaceCreationState) => {
  const { details, billing, members } = state
  const { name, logo } = details

  return {
    avatar: logo,
    name,
    members: formatMembers(members),
    billing,
  }
}

export const formatOwnerToMember = (user: InitialUserFragment): Member => ({
  isAdmin: true,
  isEditor: true,
  isOwner: true,
  name: user.name!,
  email: user.email!,
  avatar: user.avatar!.small!,
})
