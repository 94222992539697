import React from 'react'

import { Button, Modal, ModalInjectedProps } from '@sketch/components'

import { Title, Description } from './Confirm2FADisabledModal.styles'

export const Confirm2FADisabledModal: React.FC<ModalInjectedProps> = ({
  hideModal,
}) => (
  <Modal onCancel={hideModal}>
    <Modal.Body>
      <Title>Two-Factor Authentication (2FA) Disabled</Title>
      <Description>
        Two-factor authentication (2FA) is now disabled on your account. You can
        enable it again anytime from the Accounts page.
      </Description>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={hideModal} smaller variant="primary">
        Close
      </Button>
    </Modal.Footer>
  </Modal>
)
