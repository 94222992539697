import React from 'react'
import { useLocalStorage } from 'react-use'
import { useFlag } from '@sketch/modules-common'
import {
  useForTablet,
  Box,
  Text,
  Button,
  useModalContext,
} from '@sketch/components'
import { UpsellModal } from '../UpsellModal'
import {
  BannerContainer,
  TextContainer,
  HideButton,
  Puzzle,
  PuzzleMobile,
} from './UpsellBanner.styles'

export const UpsellBanner = () => {
  const isDesignSystemsOn = useFlag('design-systems')
  const [bannerSeen, setBannerSeen] = useLocalStorage('dsm_banner_seen', false)
  const { showModal } = useModalContext()
  const isTabletAndBigger = useForTablet()

  if (bannerSeen || !isDesignSystemsOn) {
    return null
  }

  const onLearnMore = () => showModal(UpsellModal)
  const hideBanner = () => setBannerSeen(true)

  return (
    <BannerContainer>
      <TextContainer>
        <Text textStyle="header.primary.G">
          Build Your Design Systems in Sketch
        </Text>
        <Text mt={2} textStyle="copy.quaternary.standard.D">
          Combine your Libraries to build a design system directly in Sketch,
          supported by an easy-to-use editor to manage your documentation.
        </Text>
        <Box mt={5}>
          {isTabletAndBigger ? (
            <Button size="32" variant="primary-untinted" onClick={onLearnMore}>
              Create a Design System
            </Button>
          ) : (
            <Button size="32" variant="primary-untinted" onClick={onLearnMore}>
              Create
            </Button>
          )}
          <Button size="32" variant="secondary-untinted" onClick={onLearnMore}>
            Learn More
          </Button>
          <HideButton variant="secondary" onClick={hideBanner}>
            Hide
          </HideButton>
        </Box>
      </TextContainer>
      {isTabletAndBigger ? <Puzzle /> : <PuzzleMobile />}
    </BannerContainer>
  )
}
