import styled from 'styled-components'
import { Item as MemberListItem } from './components/Item'
import { AvatarPlaceholder } from '@sketch/components'
import { tintColors } from '@sketch/global-styles'
const MAX_LIST_HEIGHT = 370

const AVATAR_COLOR_PATTERN: { [key: number]: string } = {
  1: tintColors[1],
  2: tintColors[6],
  3: tintColors[4],
  4: tintColors[2],
  5: tintColors[7],
  6: tintColors[3],
  7: tintColors[8],
  8: tintColors[5],
}

/*
  Gimmick to have a padding between the scrollbar and the items.::after

  Why? Visual appearance, having the item dropdown close the scrollbar
  give it a sloppy look, and we did want to make sure we aligned it with the
  top "Invite" button.

  How this works?

  We basically have all items with a 10px margin right, already prevent the case
  where the scrollbar is close to the item it self.

  Then we basically use a CSS strategy to count how many Item we have.

  If it's only 5 items then we force a margin right of zero, otherwise 10px will be applied
  */
export const Item = styled(MemberListItem)`
  &:first-child:nth-last-child(-n + 5),
  &:first-child:nth-last-child(-n + 5) ~ & {
    margin-right: 0;
  }

  /*
     There's a repeating pattern of colors for the avatar placeholder
     You can find it here: https://www.sketch.com/s/0818038b-8789-4df3-9512-1780080863b7/a/YGpj3DL
     This logic below makes it so that the pattern matches the designs
     and repeats itself every 8 items (8 being total number of tint colors)
  */
  &:nth-child(8n - 7) ${AvatarPlaceholder} {
    background-color: ${AVATAR_COLOR_PATTERN[8]};
  }

  &:nth-child(8n - 6) ${AvatarPlaceholder} {
    background-color: ${AVATAR_COLOR_PATTERN[1]};
  }

  &:nth-child(8n - 5) ${AvatarPlaceholder} {
    background-color: ${AVATAR_COLOR_PATTERN[2]};
  }

  &:nth-child(8n - 4) ${AvatarPlaceholder} {
    background-color: ${AVATAR_COLOR_PATTERN[3]};
  }

  &:nth-child(8n - 3) ${AvatarPlaceholder} {
    background-color: ${AVATAR_COLOR_PATTERN[4]};
  }

  &:nth-child(8n - 2) ${AvatarPlaceholder} {
    background-color: ${AVATAR_COLOR_PATTERN[5]};
  }

  &:nth-child(8n - 1) ${AvatarPlaceholder} {
    background-color: ${AVATAR_COLOR_PATTERN[6]};
  }

  &:nth-child(8n - 0) ${AvatarPlaceholder} {
    background-color: ${AVATAR_COLOR_PATTERN[7]};
  }
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 0 32px;
  list-style: none;
  margin-top: 0px;
  margin-bottom: 0px;
`

/*
  Gimmick to show the (x) remove buttons

  Why? Because we addded the overflow-y all the buttons that are outside
  of the UL area will be hidden because of the overflow.

  This fakes a bigger element by adding extra padding on the children.
  Allowing the (x) to show.
  */
export const ListWrapper = styled.div`
  margin-left: -32px;

  /* Prevent the members list from being too big */
  max-height: ${MAX_LIST_HEIGHT}px; /* ~6 x List item height */
  overflow-y: auto;
`
