import styled from 'styled-components'
import { IndexLayoutOldTitle } from '@sketch/modules-common'

import { breakpoint } from '@sketch/global-styles'

export const BreadcrumbsContainer = styled.div`
  display: none;

  ${breakpoint('sm')`
    display: block;

    text-align: center;
    margin-top: 16px;

    + ${IndexLayoutOldTitle} {
      margin-top: 32px;
    }
  `}
`
