import {
  GetProfilePublicationsQuery,
  GetProfilePublicationsQueryVariables,
} from '@sketch/gql-types'
import { clamp } from '@sketch/utils'
import { handleFetchMore } from '@sketch/components'
import { GridPublicationItemPlaceholder } from '../../components/GridPublicationItem'
import React, { useCallback } from 'react'
import { QueryResult } from 'react-apollo'
import { InView } from 'react-intersection-observer'
import { dataIdFromObject } from '@sketch/graphql-cache'

const PAGE_SIZE = 20
const getPlaceholdersLength = (remainingDocsToLoad: number) =>
  clamp(remainingDocsToLoad, 0, PAGE_SIZE)

const entriesPath = ['workspaceProfile', 'publications', 'entries']

interface PlaceholderProps {
  onLoadMore(): Promise<unknown>
}

export const DocumentGridItemPlaceholder: React.FC<PlaceholderProps> = props => {
  const { onLoadMore } = props

  return (
    <GridPublicationItemPlaceholder>
      <InView onChange={inView => inView && onLoadMore()}>
        <span className="sr-only">Loading Placeholder</span>
      </InView>
    </GridPublicationItemPlaceholder>
  )
}

export type PublicationsResult = QueryResult<
  GetProfilePublicationsQuery,
  GetProfilePublicationsQueryVariables
>

export interface UsePublicationsPaginationProps {
  publicationsResult: PublicationsResult
  publicationsLength: number
}

export const usePublicationsPagination = (
  props: UsePublicationsPaginationProps
) => {
  const { publicationsResult, publicationsLength } = props
  const { fetchMore, data } = publicationsResult

  const after = data?.workspaceProfile.publications.meta.after || null
  const totalCount = data?.workspaceProfile.publications.meta.totalCount

  const loadMoreHandler = useCallback(() => {
    return handleFetchMore(fetchMore, entriesPath, {
      dataIdFromObject,
      after,
    })()
  }, [after, fetchMore])

  const placeholdersLength = getPlaceholdersLength(
    (totalCount || 0) - publicationsLength
  )

  const placeholders = Array.from({
    length: placeholdersLength,
  }).map((value, index) => (
    <DocumentGridItemPlaceholder key={index} onLoadMore={loadMoreHandler} />
  ))

  return { placeholders }
}
