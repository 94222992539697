import {
  PublicationQueryPreferencesFragment,
  useGetPublicationsQuery,
} from '@sketch/gql-types'

export interface GetCommunityPublicationsProps {
  preferences: Partial<PublicationQueryPreferencesFragment> | null
}

const yearFormatter = new Intl.DateTimeFormat('en', { year: 'numeric' })
const monthFormatter = new Intl.DateTimeFormat('en', { month: '2-digit' })
const dayFormatter = new Intl.DateTimeFormat('en', { day: '2-digit' })

const formatDate = (timestamp: number) => {
  const year = yearFormatter.format(timestamp)
  const month = monthFormatter.format(timestamp)
  const day = dayFormatter.format(timestamp)

  return `${year}-${month}-${day}`
}

const createdBeforeDateFromPeriod = (
  period: PublicationQueryPreferencesFragment['period']
) => {
  const today = new Date()

  switch (period) {
    case 'FOREVER':
      return null
    case 'YEAR':
      return formatDate(new Date().setFullYear(today.getFullYear() - 1))
    case 'MONTH':
      return formatDate(new Date().setDate(today.getDate() - 30))
    case 'WEEK':
      return formatDate(new Date().setDate(today.getDate() - 7))
    case 'DAY':
      return formatDate(today.valueOf())
  }
}

export const useGetCommunityPublications = ({
  preferences,
}: GetCommunityPublicationsProps) => {
  /**
   * Excluding the null values from the preferences so
   * BE doesn't throw errors
   */
  const variables = Object.fromEntries(
    Object.entries(preferences || {}).filter(([key, value]) => !!value)
  )

  const {
    sort,
    include,
    limit,
    search,
    exclude,
    documentType,
    period,
  } = variables as {
    [K in keyof PublicationQueryPreferencesFragment]?: PublicationQueryPreferencesFragment[K]
  }

  return useGetPublicationsQuery({
    variables: {
      sort,
      include,
      limit,
      exclude,
      search: {
        name: search,
        createdAfter: createdBeforeDateFromPeriod(period || 'FOREVER'),
        documentType:
          documentType === 'ALL_DOCUMENT_TYPES' ? null : documentType,
      },
    },
  })
}
