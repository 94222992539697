import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import {
  Form,
  Input,
  Button,
  UrlIcon,
  SubmitIcon,
  CancelIcon,
} from './BubbleMenuLink.styles'

interface BubbleMenuLinkProps {
  currentUrl?: string
  onAdd: (url: string) => void
  onRemove: () => void
  isLinkActive: boolean
}

const validationSchema = yup.object().shape({
  url: yup.string().url().trim().required("A URL can't be blank"),
})

const HTTP = 'http://'

export const BubbleMenuLink: React.FC<BubbleMenuLinkProps> = ({
  currentUrl,
  onAdd,
  onRemove,
  isLinkActive,
}) => {
  // This is a super small form, so better to use `useFormik` instead of Formik
  // components (that avoids using React Context)
  const formik = useFormik({
    initialValues: { url: currentUrl || '' },
    validationSchema,
    onSubmit: values => {
      onAdd(values.url)
    },
  })

  return (
    <Form
      onClick={event =>
        event.stopPropagation()
      } /* to avoid closing the bubblemenu when clicking inside the form */
      onSubmit={formik.handleSubmit}
    >
      <Input
        id="url"
        name="url"
        type="url"
        icon={<UrlIcon />}
        placeholder={HTTP}
        value={formik.values.url}
        onChange={formik.handleChange}
        onFocus={event => {
          // Add protocol to input field when empty
          if (!event.target.value) {
            formik.setFieldValue('url', HTTP)
          }
        }}
        onPaste={() => {
          // Empty input value before pasting a url
          formik.setFieldValue('url', '')
        }}
        autoFocus
      />
      <Button type="submit" disabled={!formik.values.url || !formik.isValid}>
        <SubmitIcon />
      </Button>
      <Button
        type="button"
        disabled={
          !isLinkActive || !formik.values.url || formik.values.url === HTTP
        }
        onClick={() => {
          formik.resetForm()
          onRemove()
        }}
      >
        <CancelIcon />
      </Button>
    </Form>
  )
}
