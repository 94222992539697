import React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from '@sketch/global-styles'
import { Box } from '../Box'
import { Heading } from '../Heading'

export const cardResting = css`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.background.secondary.A};
`

const PanelContainer = styled(Box)`
  background-clip: padding-box;
  overflow: hidden;
`

const PanelSectionContainer = styled.section`
  background-clip: padding-box;
`

const PanelHeaderOuter = styled(Box)`
  padding: 16px 16px 0;

  ${breakpoint('sm')`
    padding: 44px 48px 0;
  `}

  justify-content: space-between;
  align-items: center;
`

const PanelTitle = styled(Heading.H1)(
  ({ theme }) => css`
    display: block;
    width: 100%;

    font-size: ${theme.fontSizes.G};
    color: inherit;

    margin: 0;
    text-transform: none;
    word-break: break-word;

    span {
      display: block;
    }
  `
)

export interface PanelHeaderProps {
  extra?: React.ReactNode
}

export const PanelHeader: React.FC<PanelHeaderProps> = ({
  children,
  extra,
  ...props
}) => (
  <PanelHeaderOuter {...props}>
    <PanelTitle>{children}</PanelTitle>
    {extra}
  </PanelHeaderOuter>
)

export const PanelBody = styled.div`
  padding: 16px;

  ${breakpoint('sm')`
    padding: 0 48px;

    ${PanelHeaderOuter} + & {
        padding-top: 16px;
    }
  `};

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  &:last-child {
    border-bottom: none;
  }
`

export const PanelFooter = styled.div(
  ({ theme }) => css`
    padding: 16px;

    background-color: ${theme.colors.background.secondary.A};
    border-radius: 0 0 12px 12px; /* stylelint-disable-line scales/radii */
    border-bottom: none;

    text-align: right;
  `
)

export const Panel: React.FC = ({ children, ...props }) => (
  <PanelContainer {...props}>{children}</PanelContainer>
)

export const PanelSection: React.FC = ({ children, ...props }) => (
  <PanelSectionContainer {...props}>{children}</PanelSectionContainer>
)
