import { useEffect, useCallback, useState, useMemo } from 'react'
import { getUniquePresences, transformPropertyNames } from '../../utils'
import { createPresencesChannels } from '../../createChannels'
import { Presence } from '../../types'

import { useApolloClient } from '@apollo/react-hooks'
import ApolloClient from 'apollo-client'
import { useWebsocket } from '@sketch/modules-common'
import { WebsocketContext, SocketChannels } from '@sketch/common-types'

let channelsGlobalRef: SocketChannels | undefined

export const initChannels = (
  apolloClient: ApolloClient<object>,
  websocketContext: WebsocketContext
) => {
  if (!channelsGlobalRef) {
    channelsGlobalRef = createPresencesChannels(apolloClient, websocketContext)
  }
  return channelsGlobalRef
}

const PRESENCES_CHANNEL_PREFIX = 'presence-ro'

interface PresenceOptions {
  skip?: boolean
}

export const usePresences = (
  shareIdentifier: string,
  options: PresenceOptions = { skip: false }
) => {
  const apolloClient = useApolloClient()
  const [presences, setPresences] = useState<Presence[]>([])
  const [loading, setLoading] = useState(true)

  const websocketContext = useWebsocket()
  const channels = initChannels(apolloClient, websocketContext)

  const handlePresenceChange = useCallback(
    (response: { presences: Presence[] }) => {
      setLoading(false)
      setPresences(response.presences)
    },
    []
  )

  useEffect(() => {
    if (options.skip) {
      return
    }

    const channelName = `${PRESENCES_CHANNEL_PREFIX}:${shareIdentifier}`

    channels?.join(channelName, 'presences', handlePresenceChange, {
      onClose: () => handlePresenceChange({ presences: [] }),
      onError: () => handlePresenceChange({ presences: [] }),
    })

    return () => {
      channels?.leave(channelName, 'presences', handlePresenceChange)
    }
  }, [shareIdentifier, options.skip, handlePresenceChange, channels])

  const uniquePresences = useMemo(
    () => transformPropertyNames(getUniquePresences(presences || [])),
    [presences]
  )

  return {
    loading,
    presences: uniquePresences,
  }
}
