import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "border-cap-round-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "currentColor", fillRule: "evenodd" },
        React.createElement("path", { d: "M8 2.75h6v10.5H8a5.25 5.25 0 010-10.5z", fillOpacity: 0.15 }),
        React.createElement("path", { d: "M14 2v1.5H8a4.5 4.5 0 00-.212 8.995L8 12.5h6V14H8a6 6 0 01-.225-11.996L8 2h6z", fillRule: "nonzero" }),
        React.createElement("path", { fillRule: "nonzero", d: "M14 7.5V9H7.5V7.5z" }),
        React.createElement("path", { d: "M6.5 6.5H10V10H6.5z" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
