import { useEffect } from 'react'

import { useGetVersionQuery, GetVersionQueryVariables } from '@sketch/gql-types'
import { getVersionRenderStatus } from '@sketch/modules-common'

const DEFAULT_POLLING_INTERVAL = 15000

interface Props {
  variables: GetVersionQueryVariables
  skipPolling?: boolean
  pollingIntervalMs?: number
}

/**
 * Wrapper around useGetVersionQuery to refetch document version
 * every X milliseconds in case the document is still processing.
 *
 * We need to do this refetch since anonymous users cannot use
 * websocket subscriptions to learn of version render status changes,
 * since Websocket connections to SketchQL require authentication.
 *
 * Once the document is "ready" we can stop polling.
 *
 * A document is considered ready when:
 *
 *  - it has finished uploading
 *  - it has finished processing on the RenderFarm
 *  - it has presentation files
 *
 *  The `skipPolling` option can be used to disable polling.
 *  This is useful for scenarios where we want polling for anonymous users
 *  but to skip polling and rely on subscriptions for signed in users.
 */
export function useGetVersionQueryWithPolling({
  variables,
  skipPolling = false,
  pollingIntervalMs = DEFAULT_POLLING_INTERVAL,
}: Props) {
  const queryResult = useGetVersionQuery({
    variables,
    shouldInvalidatePrevious: (prev, curr) =>
      prev?.versionShortId !== curr?.versionShortId,
    notifyOnNetworkStatusChange: true,
  })

  const { data, error, startPolling, stopPolling } = queryResult
  const renderStatus = getVersionRenderStatus(data?.share?.version)
  const presentationStatus =
    data?.share?.version?.presentationStatus ?? 'PENDING'

  useEffect(() => {
    const isWaitingForDocumentToBeProcessed =
      renderStatus === 'uploading' ||
      renderStatus === 'processing' ||
      presentationStatus === 'PENDING'

    if (skipPolling || !isWaitingForDocumentToBeProcessed) {
      return
    }

    startPolling(pollingIntervalMs)

    return () => {
      stopPolling()
    }
  }, [
    renderStatus,
    presentationStatus,
    skipPolling,
    pollingIntervalMs,
    startPolling,
    stopPolling,
    error,
  ])

  return queryResult
}
