import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useContext,
} from 'react'

const DEFAULT_SELECTION = 'View All'

const SelectedGroupContext = createContext<
  [string, Dispatch<SetStateAction<string>>]
>([DEFAULT_SELECTION, () => {}])

export const SelectedGroupContextProvider: React.FC = ({ children }) => {
  const value = useState<string>(DEFAULT_SELECTION)

  return (
    <SelectedGroupContext.Provider value={value}>
      {children}
    </SelectedGroupContext.Provider>
  )
}

export function useSelectedGroupContext() {
  const [selected, setSelected] = useContext(SelectedGroupContext)

  const handleSelect = (text: string | null) => {
    setSelected(text === '' || !text ? DEFAULT_SELECTION : text)
  }

  const resetGroupSelect = () => setSelected(DEFAULT_SELECTION)

  return { selected, setSelected: handleSelect, resetGroupSelect }
}
