import React, { FC } from 'react'
import { useHistory } from 'react-router'

import { getItem, removeItem } from '@sketch/utils'
import { localStorageKeys } from '@sketch/constants'
import {
  Link,
  ConfirmationDialog,
  ModalInjectedProps,
} from '@sketch/components'

import { useToast } from '@sketch/toasts'
import {
  deleteEntityFromCache,
  removeAuthorizationByAuthToken,
  routes,
  useGetHasDraftsProject,
  useGetWorkspaces,
  useUserAuthorizations,
} from '@sketch/modules-common'
import { useRevokeOAuthMutation } from '@sketch/gql-types'

import { useLeaveWorkspace } from './useLeaveWorkspace'

interface LeaveWorkspaceModalProps extends ModalInjectedProps {
  workspace: { identifier: string; name: string }
  membershipId: string
  userHasPersonalIdentity: boolean
}

/**
 * LeaveWorkspaceModal
 *
 * This component is visually a Modal that Prompts the user
 * with a confirmation to leave the workspace
 */
const LeaveWorkspaceModal: FC<LeaveWorkspaceModalProps> = props => {
  const {
    hideModal,
    isModalOpen,
    workspace,
    membershipId,
    userHasPersonalIdentity,
  } = props

  const { showToast } = useToast()
  const { workspaces } = useGetWorkspaces()
  const history = useHistory()

  const { hasAccessToWorkspace, authorizations } = useUserAuthorizations()
  const [revokeSession] = useRevokeOAuthMutation({ onError: 'do-nothing' })

  const [leaveWorkspace, { loading }] = useLeaveWorkspace({
    variables: { membershipId },
    onError: message => showToast(message, 'negative'),
    onCompleted: () => {
      showToast(`You have been removed from "${workspace.name}"`)

      const workspaceIdentifierToRemove = workspace.identifier
      const validWorkspaces = workspaces?.filter(
        workspace =>
          !workspaceIdentifierToRemove &&
          hasAccessToWorkspace({
            identifier: workspace.identifier,
            customer: workspace.customer,
            userRole: workspace.userRole,
          })
      )

      hideModal()

      /**
       * Check if the user has any other valid workspaces to go
       * if not sign-out. For SSO authorizations only, personal workspaces
       * can create new workspaces.
       **/
      if (!userHasPersonalIdentity && validWorkspaces?.length === 0) {
        history.replace(routes.SIGN_OUT.create({}))
        return
      }

      /**
       * Remove the saved workspace identifier if it's the workspace the
       * user is leaving
       */
      const lastAccessedWorkspace = getItem(localStorageKeys.lastWorkspaceIdKey)
      if (workspace.identifier === lastAccessedWorkspace) {
        removeItem(localStorageKeys.lastWorkspaceIdKey)
      }
    },
    update: cache => {
      /**
       * We need to remove a SSO workspace auth token before
       * clearing the workspace information from cache hence the
       * we are using update
       */
      const workspaceIdentifierToRemove = workspace.identifier
      const workspaceToBeRemoved = workspaces?.find(
        ({ identifier }) => identifier === workspaceIdentifierToRemove
      )

      if (workspaceToBeRemoved?.customer?.ssoEnabled) {
        const authorizationToRemove = authorizations.find(
          authorization =>
            authorization.type === 'sso' &&
            authorization.workspaceId === workspaceIdentifierToRemove
        )

        if (authorizationToRemove) {
          const authToken = authorizationToRemove.fragment.authToken

          removeAuthorizationByAuthToken(cache, authToken)
          revokeSession({ variables: { token: authToken } })
        }
      }

      /* Remove all the workspace information from the cache */
      deleteEntityFromCache(cache, {
        __typename: 'Workspace',
        identifier: workspace.identifier,
      })
    },
  })

  const hasDraftsProject = useGetHasDraftsProject(workspace.identifier)

  return (
    <ConfirmationDialog
      title="Are you sure you want to leave the Workspace?"
      hideModal={hideModal}
      isModalOpen={isModalOpen}
      onConfirm={leaveWorkspace}
      confirmButton={{
        text: 'Leave',
        loading,
        disabled: loading,
        variant: 'negative',
      }}
    >
      <p>
        After you leave you won&apos;t be able to view, comment on, or upload
        documents to &quot;<strong>{workspace.name}</strong>&quot; anymore.
      </p>
      {hasDraftsProject && (
        <p>
          Make sure you back up any private work in
          <Link
            to={routes.WORKSPACE_DRAFTS.create({
              workspaceId: workspace.identifier,
            })}
            onClick={() => hideModal()}
          >
            {' '}
            My Drafts{' '}
          </Link>
          before you leave.
        </p>
      )}
    </ConfirmationDialog>
  )
}

export default LeaveWorkspaceModal
