import React from 'react'
import copy from 'copy-to-clipboard'

import { useToast } from '@sketch/toasts'
import {
  Text,
  Dropdown,
  ConfirmationDialog,
  EllipsisDropdownAction,
  useModalContext,
  ModalInjectedProps,
} from '@sketch/components'

import { useDSDocsEditModeContext } from '../../../operations/DSDocsEditModeContext'
import { DesignSystemPage } from '../../../types'

interface DeletePageModalProps extends ModalInjectedProps {
  workspaceId: string
  designSystemId: string
  page: DesignSystemPage
  handleDeletePage: (pageId: string) => void
}

const DeletePageModal: React.FC<DeletePageModalProps> = props => {
  const { hideModal, isModalOpen, page, handleDeletePage } = props
  const { showToast } = useToast()

  const onDelete = () => {
    hideModal()
    showToast(`"${page.name}" deleted`)
    handleDeletePage(page.identifier)
  }

  return (
    <ConfirmationDialog
      title="Are you sure you want to delete this page?"
      hideModal={hideModal}
      isModalOpen={isModalOpen}
      onConfirm={onDelete}
      confirmButton={{
        text: 'Delete Page',
      }}
    >
      <Text pt={2}>
        This will permanently delete the page &quot;{page.name} &quot;. You
        can&apos;t undo this action.
      </Text>
    </ConfirmationDialog>
  )
}

interface ComponentPageDropdownProps {
  workspaceId: string
  designSystemId: string
  url: string
  page: DesignSystemPage
  handleDeletePage: (pageId: string) => void
  handleDuplicatePage: (pageId: string) => void
  handleRenamePage: (pageId: string, newName: string) => void
  setIsRenaming: (isRenaming: boolean) => void
}

export const ComponentPageDropdown = (props: ComponentPageDropdownProps) => {
  const { page, handleDuplicatePage, setIsRenaming } = props
  const { showModal } = useModalContext()
  const { showToast } = useToast()
  const { isEditMode } = useDSDocsEditModeContext()

  const onCopyURL = (e: React.SyntheticEvent) => {
    e.preventDefault()

    copy(`${window.location.origin}${props.url}`)
    showToast(`Link copied`)
  }

  const handleDuplicate = () => handleDuplicatePage(page.identifier)

  return (
    <Dropdown
      placement="bottom-end"
      usePortal
      toggle={<EllipsisDropdownAction label="Design System Settings" />}
    >
      <Dropdown.Item onClick={onCopyURL}>Copy Link</Dropdown.Item>
      {isEditMode && (
        <>
          <Dropdown.Item onClick={() => setIsRenaming(true)}>
            Rename&hellip;
          </Dropdown.Item>
          <Dropdown.Item onClick={handleDuplicate}>
            Duplicate&hellip;
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            intent="negative"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault()
              showModal(DeletePageModal, { ...props })
            }}
          >
            Delete&hellip;
          </Dropdown.Item>
        </>
      )}
    </Dropdown>
  )
}
