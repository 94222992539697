import React, { useState } from 'react'

import {
  Box,
  Button,
  Checkbox,
  Modal,
  ModalInjectedProps,
  Text,
} from '@sketch/components'
import {
  GetWorkspaceSupportAccessDocument,
  GetWorkspaceSupportAccessQuery,
  useDisableSupportAccessMutation,
  useEnableSupportAccessMutation,
} from '@sketch/gql-types'
import { dateFormat } from '@sketch/utils'
import { DataProxy } from 'apollo-cache'

const updateCachedSupportAccessData = (
  cache: DataProxy,
  workspaceId: string,
  data?: string | null
) => {
  cache.writeQuery({
    query: GetWorkspaceSupportAccessDocument,
    variables: { workspaceId },
    data: {
      __typename: 'RootQueryType',
      workspace: {
        __typename: 'Workspace',
        identifier: workspaceId,
        supportAccessGrantedUntil: data,
      },
    } as GetWorkspaceSupportAccessQuery,
  })
}

interface TroubleshootingConsentModalProps extends ModalInjectedProps {
  workspaceId: string
  workspaceName: string
  supportAccessGrantedUntil?: string | null
}

export const TroubleshootingConsentModal: React.FC<TroubleshootingConsentModalProps> = ({
  workspaceId,
  workspaceName,
  supportAccessGrantedUntil,
  hideModal,
}: TroubleshootingConsentModalProps) => {
  const [supportAccessUntil, setSupportAccessUntil] = useState<
    string | null | undefined
  >(supportAccessGrantedUntil)
  const [
    enableSupportAccess,
    { data: enableData, loading: enableLoading, error: enableError },
  ] = useEnableSupportAccessMutation({
    variables: {
      identifier: workspaceId,
    },
    onError: 'show-toast',
    update: (cache, { data }) => {
      updateCachedSupportAccessData(
        cache,
        workspaceId,
        data?.enableSupportAccess.workspace.supportAccessGrantedUntil
      )
    },
    onCompleted: data => {
      setSupportAccessUntil(
        data.enableSupportAccess.workspace.supportAccessGrantedUntil
      )
    },
  })
  const [
    disableSupportAccess,
    { data: disableData, loading: disableLoading, error: disableError },
  ] = useDisableSupportAccessMutation({
    variables: {
      identifier: workspaceId,
    },
    onError: 'show-toast',
    update: (cache, { data }) => {
      updateCachedSupportAccessData(
        cache,
        workspaceId,
        data?.disableSupportAccess.workspace.supportAccessGrantedUntil
      )
    },
    onCompleted: data => {
      setSupportAccessUntil(
        data.disableSupportAccess.workspace.supportAccessGrantedUntil
      )
    },
  })

  const hasSuccessfulMutationData = !!(enableData || disableData)
  const hasMutationError = !!(enableError || disableError)
  const mutationWasSuccessful = hasSuccessfulMutationData && !hasMutationError

  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>
        <header>
          Support Access for &quot;{workspaceName}&quot; Workspace
        </header>
      </Modal.Header>
      <Modal.Body>
        <Text>
          You can give our support team temporary access to your Workspace so we
          can troubleshoot problems on your behalf.
        </Text>
        <Text>
          While enabled, our support team will be able to view all Workspace
          documents and, if necessary, transfer them to other Workspaces.
          Support access expires after 7 days, but you can disable it earlier.
        </Text>
        <Box>
          <Checkbox
            value={supportAccessUntil ? 1 : 0}
            aria-current={supportAccessUntil ? 'true' : 'false'}
            name="support-access"
            checked={!!supportAccessUntil}
            loading={enableLoading || disableLoading}
            success={mutationWasSuccessful}
            label="Enable support access"
            onChange={() => {
              if (!supportAccessUntil) {
                enableSupportAccess()
              } else {
                disableSupportAccess()
              }
            }}
            help={
              supportAccessUntil
                ? `Expires on ${dateFormat(
                    new Date(supportAccessUntil),
                    undefined,
                    {
                      dateStyle: 'full',
                      timeStyle: 'short',
                      timeZone: 'UTC',
                    }
                  )} UTC`
                : ''
            }
          />
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
