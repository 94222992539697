import { DataProxy } from 'apollo-cache'
import produce from 'immer'

import {
  GetProjectSharesDocument,
  GetProjectSharesQuery,
  GetProjectSharesQueryVariables,
  ShareListItemFragment,
  ShareListItemFragmentDoc,
  GetProjectsDocument,
  GetProjectsQueryVariables,
  GetProjectsQuery,
  GetShareCollectionQuery,
  GetShareCollectionQueryVariables,
  GetShareCollectionDocument,
  GetProjectsListQueryVariables,
} from '@sketch/gql-types'
import { dataIdFromObject } from '@sketch/graphql-cache'
import {
  errorPreventiveCacheRead,
  removeFromPaginated,
  removeQuery,
} from '@sketch/modules-common'

// eslint-disable-next-line no-restricted-imports
import { SharesSearchParams } from '@sketch/gql-types/expansive'

type ProjectRecipeFunction = (cachedObject: GetProjectSharesQuery) => void

// Safety check to validate if the archive variable exists in the query getProjectListQuery
// check the "restoreWorkspaceProject" method
// this check should only be deleted if the variable is removed
type CheckIfArchiveVariableIsPresent = {
  [K in NonNullable<GetProjectsListQueryVariables['archived']>]: true
}['ARCHIVED']

interface FilterArguments {
  projectId: string
  search?: string | null
  filters?: SharesSearchParams['filters']
}

const createProjectQuery = ({
  projectId,
  search = null,
  filters,
}: FilterArguments) => {
  return {
    query: GetProjectSharesDocument,
    variables: {
      shortId: projectId,
      after: null,
      search: {
        name: search,
        isCurrentVersionDownloadable: null,
        filters,
      } as const,
    },
  }
}

export const readProjectList = (
  cache: DataProxy,
  filterArguments: FilterArguments
) => {
  const cacheArguments = createProjectQuery(filterArguments)

  return errorPreventiveCacheRead<
    GetProjectSharesQuery,
    GetProjectSharesQueryVariables
  >(cache, cacheArguments)
}

export const updateProjectList = (
  cache: DataProxy,
  filterArguments: FilterArguments,
  recipe: ProjectRecipeFunction
) => {
  const cachedProject = readProjectList(cache, filterArguments)

  if (!cachedProject) {
    return
  }

  const project = produce(cachedProject, recipe)

  cache.writeQuery<GetProjectSharesQuery, GetProjectSharesQueryVariables>({
    ...createProjectQuery(filterArguments),
    data: project,
  })

  return project
}

export const readShareCollection = (
  cache: DataProxy,
  shareIdentifier: string
) => {
  const cacheArguments = {
    query: GetShareCollectionDocument,
    variables: {
      shareIdentifier,
    },
  }

  return errorPreventiveCacheRead<
    GetShareCollectionQuery,
    GetShareCollectionQueryVariables
  >(cache, cacheArguments)
}

const createShareFragmentQuery = (shareIdentifier: string) => {
  return {
    fragment: ShareListItemFragmentDoc,
    fragmentName: 'ShareListItem',
    id: dataIdFromObject({
      __typename: 'Share',
      identifier: shareIdentifier,
    }) as string,
  }
}

export const readShareWithProjectFragment = (
  cache: DataProxy,
  shareIdentifier: string
) => {
  const cacheArguments = createShareFragmentQuery(shareIdentifier)

  return cache.readFragment<ShareListItemFragment>(cacheArguments)
}

type ShareRecipeFunction = (cachedObject: ShareListItemFragment) => void

export const updateShareWithProjectFragment = (
  cache: DataProxy,
  shareIdentifier: string,
  recipe: ShareRecipeFunction
) => {
  const cachedShare = readShareWithProjectFragment(cache, shareIdentifier)

  if (!cachedShare) {
    return
  }

  const share = produce(cachedShare, recipe)

  cache.writeFragment({
    ...createShareFragmentQuery(shareIdentifier),
    data: share,
  })

  return share
}

export const deleteWorkspaceProject = (
  cache: DataProxy,
  workspaceId: string,
  projectId: string
) => {
  const queryParams: DataProxy.Query<GetProjectsQueryVariables> = {
    query: GetProjectsDocument,
    variables: { workspaceId },
  }

  const workspaceProjects = errorPreventiveCacheRead<
    GetProjectsQuery,
    GetProjectsQueryVariables
  >(cache, queryParams)

  if (!workspaceProjects) {
    // If we can't read the query we can't perform any cache change
    return
  }

  const updatedWorkspaceProjects = produce(workspaceProjects, () => {
    const { projects } = workspaceProjects!.workspace
    projects.entries = projects.entries.filter(
      entry => entry?.identifier !== projectId
    )

    projects.meta.totalCount--
  })

  cache.writeQuery({
    ...queryParams,
    data: updatedWorkspaceProjects,
  })

  // Invalidate the workspace trash and the shares queries
  // we can have shares that belong to the deleted project that
  // will be deleted was well
  removeQuery(
    cache,
    key =>
      key.includes(workspaceId) &&
      (key.includes('.workspaceTrash') || key.includes('.shares'))
  )
}

export const restoreWorkspaceProject = (
  cache: DataProxy,
  workspaceId: string,
  projectId: string,
  archived: boolean
) => {
  // Remove the project from the trash query
  removeFromPaginated(
    cache,
    { __typename: 'Project', identifier: projectId },
    key => key.includes('workspaceTrash')
  )

  // Invalidate the workspace trash and the shares queries
  // we can have shares that belong to the deleted project that
  // will be deleted was well
  removeQuery(cache, key => {
    const sameWorkspace = key.includes(workspaceId)
    const trashOrShareQuery =
      key.includes('.workspaceTrash') || key.includes('.shares')

    // Make sure this query check still makes sense
    const archiveVariableCheck: CheckIfArchiveVariableIsPresent = true

    const isArchived =
      archived &&
      key.includes('.projects') &&
      key.includes('"archived":"ARCHIVED"') &&
      archiveVariableCheck

    return sameWorkspace && (trashOrShareQuery || isArchived)
  })
}
