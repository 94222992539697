import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "textformat-underline-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M1 12l2.97-8.327a.562.562 0 011.06 0L8 12h0", stroke: "currentColor", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, d: "M1.75 9.25H7" }),
        React.createElement("path", { d: "M10.5 7c1.006-1.52 3.75-1.426 3.75 1v4", stroke: "currentColor", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M14.69 8.74c0 .564-.26.71-.57.71-1.264.012-2.079.082-2.445.207-.548.189-.927.41-.927.888 0 .483.242.708.553.79.618.165 1.752.184 2.309-1.969.074-.286.647.788.57 1.074-.539 2.011-2.216 2.502-3.313 2.249-.974-.224-1.607-1.005-1.607-2.018 0-1.345.568-2.228 2.993-2.35 2.424-.122 2.437-.144 2.437.42z", fill: "currentColor", fillRule: "nonzero" }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, strokeLinecap: "round", d: "M.75 14.75h14.5" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
