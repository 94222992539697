import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "arrow-right-rectangle-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd", stroke: "currentColor", strokeWidth: 1.5 },
        React.createElement("path", { strokeLinejoin: "round", d: "M3.75 5.25v-1.5a2 2 0 012-2h5.5a2 2 0 012 2v8.5a2 2 0 01-2 2h-5.5a2 2 0 01-2-2v-2" }),
        React.createElement("g", { strokeLinecap: "round" },
            React.createElement("path", { d: "M1.75 7.75h7" }),
            React.createElement("path", { strokeLinejoin: "round", d: "M7.25 5.25l2.5 2.5-2.5 2.5" }))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
