import {
  CommunityHeroFragment,
  CommunityItemFragment,
  CommunitySectionFragment,
  PublicationListingFragment,
} from '@sketch/gql-types'

export const defaultPublicationQueryPreferences = {
  __typename: 'PublicationQueryPreferences' as const,
  allowPagination: null,
  include: null,
  exclude: null,
  limit: null,
  search: null,
  sort: null,
  period: null,
  documentType: null,
}

export const defaultPublicationListing: PublicationListingFragment = {
  __typename: 'PublicationListing' as const,
  title: '',
  preferences: defaultPublicationQueryPreferences,
  modifiers: [],
}

export const defaultCommunitySection: CommunitySectionFragment = {
  __typename: 'CommunitySection',
  id: 'DEFAULT_COMMUNITY_SECTION_ID',
  items: [],
  size: null,
}

export const defaultCommunitySlider: CommunityItemFragment = {
  __typename: 'CommunitySlider',
  id: 'DEFAULT_COMMUNITY_SLIDER_ID',
  title: 'DEFAULT_COMMUNITY_SLIDER_TITLE',
  subTitle: null,
  link: null,
  preferences: defaultPublicationQueryPreferences,
}

export const defaultCommunityGrid: CommunityItemFragment = {
  __typename: 'CommunityGrid',
  id: 'DEFAULT_COMMUNITY_GRID_ID',
  title: 'DEFAULT_COMMUNITY_GRID_TITLE',
  subTitle: null,
  link: null,
  preferences: defaultPublicationQueryPreferences,
  modifiers: [],
}

export const defaultCommunityListing: CommunityItemFragment = {
  __typename: 'CommunityListing',
  id: 'DEFAULT_COMMUNITY_LISTING_ID',
  title: 'DEFAULT_COMMUNITY_LISTING_TITLE',
  subTitle: null,
  link: null,
  preferences: defaultPublicationQueryPreferences,
}

export const defaultCommunityHero: CommunityHeroFragment = {
  __typename: 'CommunityHero',
  id: 'DEFAULT_COMMUNITY_HERO_ID',
  title: 'DEFAULT_COMMUNITY_HERO_TITLE',
  image: null,
  video: null,
  backgroundColor: null,
  content: null,
  contentPosition: null,
  icon: null,
  links: null,
  size: null,
}
