import React from 'react'
import { Link } from 'react-router-dom'
import { LocationDescriptor } from 'history'

import { PublicationItemFragment } from '@sketch/gql-types'
import { Text } from '@sketch/components'
import { useFlag, useTrackEvent } from '@sketch/modules-common'

import PublicationThumbnail from '../PublicationThumbnail/'
import {
  HeartButton,
  ThumbnailWrapper,
  Wrapper,
} from './ListPublicationItem.styles'

export interface ListPublicationItemProps {
  className?: string
  publication: PublicationItemFragment
  url?: LocationDescriptor
}

const ListPublicationItem = (props: ListPublicationItemProps) => {
  const { className, publication, url } = props

  const isHeartButtonAvailable = useFlag('publication-hearts')

  // Analytics
  const { trackEvent } = useTrackEvent()

  const handleClick = () => {
    trackEvent('COMMUNITY - Publication', {
      type: 'Clicks - Publication List Item',
      target: publication.share.name,
    })
  }

  const content = (
    <>
      <ThumbnailWrapper>
        <PublicationThumbnail publication={publication} />
      </ThumbnailWrapper>
      <Text as="h1" textStyle="prominent.primary.E" overflow="ellipsis" $reset>
        {publication.share.name}
      </Text>
      {isHeartButtonAvailable && (
        <HeartButton publication={publication} flavour="list" trackHeartCount />
      )}
    </>
  )

  if (url) {
    return (
      <li className={className}>
        <Link to={url} onClick={handleClick}>
          <Wrapper>{content}</Wrapper>
        </Link>
      </li>
    )
  }

  return <Wrapper className={className}>{content}</Wrapper>
}

export default ListPublicationItem
