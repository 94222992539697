import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import {
  routes,
  useFlag,
  useUserSignedIn,
  DynamicLoadingPage,
  useUserProfile,
} from '@sketch/modules-common'

const VerifiedUsersRoute: React.FC = ({ children }) => {
  // TODO: Remove usage of mandatory-email-verification when release to production
  // https://github.com/sketch-hq/Cloud/issues/11277
  const isMandatoryEmailVerification = useFlag('mandatory-email-verification')

  const isSignedIn = useUserSignedIn()
  const { data, loading } = useUserProfile(true, {
    skip: !isSignedIn || !isMandatoryEmailVerification,
  })

  const isUserVerified = data?.me.isVerified

  if (loading) {
    return <DynamicLoadingPage />
  }

  if (isMandatoryEmailVerification) {
    /**
     * We are locking all users (signed in) that did not verify their email in the
     * "Check your inbox" page. Unverified users can no longer use the web app
     */
    if (isSignedIn && !isUserVerified) {
      return <Redirect to={routes.CHECK_YOUR_INBOX.create({})} />
    }
  }

  /**
   * We added the Switch here to make sure only one of the children routes would render
   * In a particular scenario, multiple components were being rendered due the children Routes
   * not having a Switch parent component
   */
  return <Switch>{children}</Switch>
}

export default VerifiedUsersRoute
