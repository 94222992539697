import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { ReactComponent as FolderClosedSvg } from '@sketch/icons/folder-closed-16'
import { ReactComponent as SquareStack } from '@sketch/icons/square-stack-16'
import { ReactComponent as DocumentSvg } from '@sketch/icons/document-16'
import { ReactComponent as CanvasPageSvg } from '@sketch/icons/canvas-page-16'
import { ReactComponent as ArtboardSvg } from '@sketch/icons/artboard-16'
import { ReactComponent as ChevronsSVG } from '@sketch/icons/chevrons-up-down-16'
import { ReactComponent as FlagSvg } from '@sketch/icons/flag.16'
import { ReactComponent as PrototypeSvg } from '@sketch/icons/prototype-hotspot-16'
import { ReactComponent as ComponentsSvg } from '@sketch/icons/components-16'
import { ReactComponent as TemplateSvg } from '@sketch/icons/templates-16'
import { ReactComponent as DSSvg } from '@sketch/icons/design-system-16'

import { Tooltip, Dropdown, Separator } from '@sketch/components'

interface LinkProps {
  $secondary?: boolean
}

const linkStyles = css<LinkProps>`
  font-size: ${({ theme }) => theme.fontSizes.D};
  display: inline-flex;
  align-items: center;
  padding: 8px 0;
  overflow: hidden;
  position: relative;
  color: inherit;
  font-weight: inherit;
  user-select: none;
  min-width: 20px;
  flex-shrink: 10e6;
  max-width: calc(100% - 16px);
  justify-content: center;
  outline-offset: -1px; /* To avoid cutting focus ring */

  &:hover,
  &:focus,
  &:active,
  &:last-child {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
  }

  &:last-child {
    /*
    This flex-shrink value is intentionally much smaller than the default value (10e6).
    In practice this means the last element will only shrink after the other siblings.
    */
    flex-shrink: 1;
  }

  &&& {
    ${({ theme, $secondary }) =>
      $secondary &&
      css`
        font-weight: 400;
        color: ${theme.colors.foreground.secondary.C};
      `}
  }
`

export const NavigationLink = styled(NavLink)<LinkProps>`
  ${linkStyles}
`

export const UnlinkedNavigationItem = styled.div<LinkProps>`
  ${linkStyles}
  font-weight: 500;
  color: ${({ theme }) => theme.colors.foreground.secondary.B};
`

const iconStyles = css`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  width: 16px;
  height: 16px;
  flex: none;
`

export const ProjectIcon = styled(FolderClosedSvg)`
  ${iconStyles}
`

export const CollectionIcon = styled(SquareStack)`
  ${iconStyles}
`

export const DocumentIcon = styled(DocumentSvg)`
  ${iconStyles}
`

export const TemplateIcon = styled(TemplateSvg)`
  ${iconStyles}
`

export const PageIcon = styled(CanvasPageSvg)`
  ${iconStyles}
`

export const ArtboardIcon = styled(ArtboardSvg)`
  ${iconStyles}
`

export const PrototypeIcon = styled(PrototypeSvg)`
  ${iconStyles}
`

export const PrototypeStartPointIcon = styled(FlagSvg)`
  ${iconStyles}
`

export const ComponentsIcon = styled(ComponentsSvg)`
  ${iconStyles}
`

export const DSIcon = styled(DSSvg)`
  ${iconStyles}
`

type ItemLabelProps = {
  noIcon?: boolean
}

export const ItemLabel = styled.span<ItemLabelProps>`
  margin-left: ${props => (props.noIcon ? 0 : 4)}px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ItemTooltip = styled(Tooltip)`
  user-select: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const ChevronsIcon = styled(ChevronsSVG).attrs({
  role: 'img',
  'aria-label': 'Zoom',
})`
  width: 16px;
  height: 16px;
  margin: 0 0 0 4px;
  flex: none;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`

export const ViewWrapper = styled.div`
  position: sticky;
  bottom: 56px;
  background: ${({ theme }) => theme.colors.background.secondary.A};
`

export const DropdownItem = styled(Dropdown.Item.NavLink)`
  align-items: center;
  display: flex;

  svg {
    color: ${({ theme }) => theme.colors.foreground.secondary.D};
    width: 16px;
    height: 16px;
    margin-right: 12px;
    /* Adjust the icons */
    margin-bottom: 1px; /* stylelint-disable scales/space */
  }

  /* We need to set back the active/hover states
  because we override them for svg */
  && {
    &.focus,
    &.active,
    &[data-current] {
      svg {
        color: ${({ theme }) => theme.colors.sketch.A};
      }
    }
  }
`

export const StyledSeparator = styled(Separator)`
  margin: 16px 0;
`

export const Content = styled.div``
