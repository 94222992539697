import React from 'react'

import { List, ListWrapper, Item } from './MembersList.styles'
import { WorkspaceMembers, Member } from '../../types'

export interface MembersListProps {
  members: WorkspaceMembers
  onEditor?: (member: Member) => void
  onViewer?: (member: Member) => void
  onRemove?: (member: Member) => void
  onToggleAdmin?: (member: Member) => void
  isDisabled?: boolean
  hasSingleEditor?: boolean
  className?: string
  isEditorDisabled?: boolean
  isIOS?: boolean
}

/*
 * MemberList
 *
 * With an array for members, renders a list of MemberCards
 * with a dropdown that allows the user to change the role of each member
 * or remove them from the list
 *
 */
export const MembersList: React.FC<MembersListProps> = ({
  members,
  onEditor,
  onViewer,
  onRemove,
  onToggleAdmin,
  isDisabled,
  hasSingleEditor,
  className,
  isEditorDisabled,
  isIOS,
}) => {
  return (
    <ListWrapper data-testid="member-list-wrapper" className={className}>
      <List>
        {members.map(member => {
          return (
            <Item
              key={member.email}
              member={member}
              onEditor={onEditor}
              onViewer={onViewer}
              onRemove={onRemove}
              onToggleAdmin={onToggleAdmin}
              isDisabled={isDisabled}
              hasSingleEditor={hasSingleEditor}
              isEditorDisabled={isEditorDisabled}
              isIOS={isIOS}
            />
          )
        })}
      </List>
    </ListWrapper>
  )
}
