import styled from 'styled-components/'

import { Link, Text } from '@sketch/components'

export const Wrapper = styled.div`
  padding-top: 32px;
  border-top: 1px solid ${({ theme }) => theme.colors.border.A};
`

export const Title = styled(Text.H2).attrs({
  textStyle: 'header.primary.G',
})`
  margin-top: 0;
  margin-bottom: 0px;
`

export const Description = styled.p`
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: ${({ theme }) => theme.fontSizes.E};
`

export const StyledLink = styled(Link)`
  white-space: break-spaces;
`
