import styled from 'styled-components'

import { Text, Link, FakeLinkButton } from '@sketch/components'

export const Title = styled.h1`
  margin: 32px 0px 4px 0px;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.G};
`

export const Description = styled(Text).attrs({
  textStyle: 'copy.tertiary.standard.E',
})`
  /* Needed to override Modal > p styles */
  && {
    padding-bottom: 24px;
  }
`

export const Note = styled(Text.Div).attrs({
  textStyle: 'copy.tertiary.standard.C',
})`
  margin: 24px 0 0 0;
`

export const StyledFakeLinkButton = styled(FakeLinkButton)`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`
export const StyledLink = styled(Link)`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`
