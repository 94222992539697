import React, { useState, useContext, createContext } from 'react'
import { useLocalStorage } from 'react-use'
import { localStorageKeys } from '@sketch/constants'

import { GetWorkspaceMembershipsQueryVariables } from '@sketch/gql-types'

type WorkspaceMembershipOrderType = GetWorkspaceMembershipsQueryVariables['order']
type OrderDirection = GetWorkspaceMembershipsQueryVariables['orderDirection']

type SortLocalStorage = {
  order: GetWorkspaceMembershipsQueryVariables['orderDirection']
  key: GetWorkspaceMembershipsQueryVariables['order']
}

type WorkspaceSettingsPeopleContext = {
  order: WorkspaceMembershipOrderType
  orderDirection: OrderDirection
  setOrder: React.Dispatch<
    React.SetStateAction<
      GetWorkspaceMembershipsQueryVariables['order'] | undefined
    >
  >

  setOrderDirection: React.Dispatch<
    React.SetStateAction<
      GetWorkspaceMembershipsQueryVariables['orderDirection'] | undefined
    >
  >
  setPersistSorting: React.Dispatch<
    React.SetStateAction<SortLocalStorage | undefined>
  >
}

type WorkspaceSettingsPeopleContextProviderProps = {
  children: React.ReactNode
}

const context = createContext<WorkspaceSettingsPeopleContext | undefined>(
  undefined
)

export function WorkspaceSettingsPeopleContextProvider({
  children,
}: WorkspaceSettingsPeopleContextProviderProps) {
  const [persistSorting, setPersistSorting] = useLocalStorage<SortLocalStorage>(
    localStorageKeys.membershipPanelTable
  )

  const [order, setOrder] = useState<WorkspaceMembershipOrderType>(
    persistSorting?.key || 'ACTIVITY_STATUS'
  )

  const [orderDirection, setOrderDirection] = useState<OrderDirection>(
    persistSorting?.order || 'DESC'
  )

  return (
    <context.Provider
      value={{
        order,
        setOrder,
        orderDirection,
        setOrderDirection,
        setPersistSorting,
      }}
    >
      {children}
    </context.Provider>
  )
}

export function useWorkspaceSettingsPeopleContext() {
  const contextValue = useContext(context)

  if (!contextValue) {
    throw new Error(
      'useWorkspaceSettingsPeople must be used within a WorkspaceSettingsPeopleProvider'
    )
  }

  return contextValue
}
