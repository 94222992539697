import { useCallback, useEffect } from 'react'

export const useDisableZoom = (isLoading: boolean) => {
  // Function to disable zoom
  const disableZoom = useCallback(
    (e: WheelEvent | TouchEvent) => {
      if (isLoading) {
        e.preventDefault()
      }
    },
    [isLoading]
  )

  useEffect(() => {
    window.addEventListener('wheel', disableZoom, { passive: false })
    window.addEventListener('touchmove', disableZoom, { passive: false })

    return () => {
      window.removeEventListener('wheel', disableZoom)
      window.removeEventListener('touchmove', disableZoom)
    }
  }, [isLoading, disableZoom])
}
