import { useEffect, useRef } from 'react'

/**
 * Returns a ref object containing the boolean value to check if the component is still mounted.
 *
 * This is useful to avoid the `Can't perform a React state update on an unmounted component` console warning
 * when async callbacks and promises are trying to update the state of a component.
 */
export const useIsMountedRef = () => {
  const isMountedRef = useRef(false)

  useEffect(() => {
    isMountedRef.current = true

    return () => {
      isMountedRef.current = false
    }
  }, [])

  return isMountedRef
}
