import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "exclamation-mark-circle-11", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 11 11", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { id: "Page-1", stroke: "none", strokeWidth: 1, fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "Download-Assets---Failed-to-Export-Assets-Notification", transform: "translate(-921 -156)" },
            React.createElement("g", { id: "Mac/Monochrome/11x11/exclamation.mark.circle", transform: "translate(921 156)" },
                React.createElement("g", { id: "Group-3" },
                    React.createElement("circle", { id: "Oval-5", stroke: "currentColor", cx: 5.5, cy: 5.5, r: 5 }),
                    React.createElement("rect", { id: "Rectangle-5", fill: "currentColor", x: 5, y: 2.5, width: 1, height: 3.5, rx: 0.5 }),
                    React.createElement("rect", { id: "Rectangle-7", fill: "currentColor", x: 5, y: 7, width: 1, height: 1, rx: 0.5 }))))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
