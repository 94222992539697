import { parseToRgb } from 'polished'

/**
 * Functions to transform colors from our theme to a format that web renderer
 * understands
 */
interface ColorInput {
  red: number
  green: number
  blue: number
  alpha?: number
}

interface ColorOutput {
  r: number
  g: number
  b: number
  a: number
}

export const parseRGBA = (color: string): ColorOutput => {
  const rgb = parseToRgb(color) as ColorInput

  return {
    r: rgb.red / 255,
    g: rgb.green / 255,
    b: rgb.blue / 255,
    a: rgb?.alpha || 1,
  }
}
