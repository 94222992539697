import React from 'react'
import { MemberIcon, MemberIconGroup, Size } from './PermissionGroupIcon.styles'
import styled from 'styled-components'

interface PermissionGroupIconProps {
  name: string
  className?: string
  size?: Size
  dark?: boolean
}

// We want to allow emojis if they are the first character, otherwise ignore all of them
// 'Hello 🌎 World 💥' renders 'HW'
// '🪃 Hello World!' renders '🪃'
const getFirstLettersOfWords = (text: string): string => {
  const emojiRegex = /(\p{Emoji_Presentation}|\p{Extended_Pictographic})/u

  const firstChar = Array.from(text)[0]
  if (emojiRegex.test(firstChar)) {
    return firstChar
  }

  let result = ''
  const words = text.split(/\s+/)
  let count = 0

  for (const word of words) {
    if (count >= 2) break
    const firstCharacter = Array.from(word)[0]
    if (!emojiRegex.test(firstCharacter)) {
      result += firstCharacter
      count++
    }
  }

  return result
}

const PermissionGroupIcon = ({
  className,
  name,
  dark = false,
  size = '32px',
}: PermissionGroupIconProps) => {
  const parsedName = getFirstLettersOfWords(name)

  return (
    <MemberIconGroup className={className} $size={size} $dark={dark}>
      <MemberIcon $dark={dark}>{parsedName}</MemberIcon>
    </MemberIconGroup>
  )
}

export default styled(PermissionGroupIcon)``
