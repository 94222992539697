import { RouteKeys, RouteProps } from '@sketch/modules-common'
import React, { useMemo, useRef } from 'react'
import { Route as TsRoute } from 'typesafe-react-router'
import { Location } from 'history'

interface ReturnRouteProps {
  path: string | string[]
  render: (routeProps: RouteProps<any>) => React.ReactNode
  exact: true
}

interface RoutesAny {
  [key: string]: TsRoute<any, any>
}

interface LocationState {
  from?: Location
}
type WithFromLocation<T extends { location: any }> = OmitSafe<T, 'location'> & {
  location: Location<LocationState>
}

type CastRouteKeys<P> = P extends RouteKeys ? P : never

/**
 * This hooks allows to simplify wiring Routes inside a layout.
 *
 * It creates creates `render`, `path` and `exact` properties which are
 * linked together and allows to simplify code from this:
 * ```
 * <Route
 *   exact
 *   path={routes.SHARE_INVITE.template()}
 *   render={routeProps => (
 *     <AcceptShareInviteByShareShortIdView
 *       {...(routeProps as RouteProps<'SHARE_INVITE'>)}
 *       {...layoutProps}
 *     />
 *   )}
 * />
 * ```
 *
 * into this:
 * ```
 * <Route {...to('SHARE_INVITE', AcceptShareInviteByShareShortIdView)} />
 * ```
 */
export const useLayoutRouteProps = <
  LayoutProps extends {},
  Routes extends RoutesAny
>(
  layoutProps: LayoutProps,
  routes: Routes
) => {
  const layoutPropsRef = useRef(layoutProps)
  layoutPropsRef.current = layoutProps

  const routesRef = useRef(routes)
  routesRef.current = routes

  return useMemo(() => {
    const to = <
      P extends keyof Routes,
      /**
       * We don't really need this extra `Route` generic type,
       * However, it is still useful.
       *
       * With this type in place, developer can hover their mouse
       * over this function and see parts of the route they're working on.
       */
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Route = Routes[P]
    >(
      route: P | P[],
      Component: React.ComponentType<RouteProps<CastRouteKeys<P>> & LayoutProps>
    ): ReturnRouteProps => {
      return {
        path: Array.isArray(route)
          ? route.map(x => routesRef.current[x].template())
          : routesRef.current[route].template(),
        // we always want to keep the route exact
        exact: true,
        render: routeProps => (
          <Component {...routeProps} {...layoutPropsRef.current} />
        ),
      }
    }

    /**
     * Some views expect that `from?: Location` state will be save in the history.
     * so for convenience there is `toWithLocation` helper method to cast the RouteProps
     */
    const toWithLocation = to as <
      P extends keyof Routes,
      /**
       * We don't really need this extra `Route` generic type,
       * However, it is still useful.
       *
       * With this type in place, developer can hover their mouse
       * over this function and see parts of the route they're working on.
       */
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Route = Routes[P]
    >(
      route: P | P[],
      Component: React.ComponentType<
        WithFromLocation<RouteProps<CastRouteKeys<P>>> & LayoutProps
      >
    ) => ReturnRouteProps

    return { to, toWithLocation }
  }, [])
}
