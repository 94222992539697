import React from 'react'
import { useListboxContext } from '@reach/listbox'

import { Option as SelectOption, RadioButton } from './AccessLevelSelect.styles'
import type { AccessLevelOptionProps } from './types'

export const Option: React.FC<AccessLevelOptionProps> = props => {
  const {
    disabled,
    'data-testid': dataTestId,
    label,
    help,
    level,
    negative,
  } = props
  const { value } = useListboxContext()

  return (
    <SelectOption
      data-testid={dataTestId}
      name="AccessLevelOption"
      value={level}
      label={label}
      disabled={disabled}
      $negative={negative}
    >
      <RadioButton
        name="access-level"
        help={help}
        label={label}
        checked={level === value}
        onChange={() => {}}
        disabled={disabled}
        negative={negative}
      />
    </SelectOption>
  )
}
