import styled from 'styled-components'

export const AnimationWrapper = styled.div`
  .onboarding-panel-enter & {
    opacity: 0;
    transform: translateY(10px);
  }

  .onboarding-panel-enter-active & {
    opacity: 1;
    transform: translateY(0);
    transition: $ opacity ${({ theme }) => theme.transitions.duration[1]},
      transform ${({ theme }) => theme.transitions.duration[2]}
        ${({ theme }) => theme.transitions.timing.easeInOut};
  }

  .onboarding-panel-exit & {
    opacity: 1;
    transform: translateY(0);
  }

  .onboarding-panel-exit-active & {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity ${({ theme }) => theme.transitions.duration[2]},
      transform ${({ theme }) => theme.transitions.duration[1]}
        ${({ theme }) => theme.transitions.timing.easeInOut};
  }
`
