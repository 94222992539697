import { matchPath } from 'react-router-dom'
import { Location } from 'history'

import { RouteParams, RouteKeys, routes } from '@sketch/modules-common'

export const matchKnownPath = <Key extends RouteKeys>(
  location: Location,
  key: Key
) =>
  matchPath<RouteParams<Key>>(location.pathname, {
    path: routes[key].template(),
    exact: false,
  })
