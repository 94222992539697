import styled, { css } from 'styled-components'

import { ReactComponent as BaseEllipsisIcon } from '@sketch/icons/dots-3-horizontal-24'

import { Dropdown, DropdownButton } from '@sketch/components'
import { MemberCard } from '../../../MemberCard'

export const Wrapper = styled.li`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 16px;
  margin-top: 12px;
  border-radius: ${({ theme }) => theme.radii.large};

  :nth-child(2n + 1) {
    background-color: ${({ theme }) => theme.colors.background.secondary.B};
  }
`
export const StyledMemberCard = styled(MemberCard)`
  overflow: hidden;
  /* Fix the MemberCard avatar margin-left */
  margin-left: 0;
  margin-right: 20px;
  flex-grow: 1;
`

export const EllipsisIcon = styled(BaseEllipsisIcon)`
  width: 20px;
  height: 20px;
`

export const TableButton = styled(DropdownButton)`
  color: ${({ theme: { colors } }) => colors.foreground.secondary.D};

  /* Reset styles */
  opacity: 1;
  transform: translate3d(0, 0, 0);

  &:hover {
    color: inherit;
  }
`

export const RemoveButton = styled(Dropdown.Item)`
  ${({ theme: { fonts, colors } }) => css`
    font-family: ${fonts.buttons};
    color: ${colors.state.negative.B};

    &:hover,
    &:focus,
    &:active {
      color: ${colors.state.negative.A};
    }
  `}
`
