export type Callback = () => void

export class Subscription {
  private readonly listeners: Callback[] = []

  subscribe = (callback: Callback) => {
    this.listeners.push(callback)
  }

  unsubscribe = (callback: Callback) => {
    const index = this.listeners.indexOf(callback)
    if (index === -1) return

    this.listeners.splice(index, 1)
  }

  notify = () => {
    const listeners = [...this.listeners]
    for (const callback of listeners) {
      callback()
    }
  }
}
