import {
  useVersionDeleteMutation,
  GetVersionHistoryDocument,
  GetVersionHistoryQuery,
  GetVersionHistoryQueryVariables,
} from '@sketch/gql-types'
import { ErrorHandler } from '@sketch/tracing'
import produce from 'immer'
import { errorPreventiveCacheRead } from '@sketch/modules-common'
import {
  shouldReduceTotalCount,
  recordInitiatedDeletion,
} from './deletedVersionsTracking'

const GENERIC_ERROR_MESSAGE = 'Something went wrong. Please try again later'

interface UseVersionDeleteProps {
  versionIdentifier: string
  onError(message: string): void
  onCompleted(): void
  shareIdentifier: string
}

export const useVersionDelete = (props: UseVersionDeleteProps) => {
  const { onError, versionIdentifier, onCompleted, shareIdentifier } = props

  const [mutate, ...rest] = useVersionDeleteMutation({
    redirectErrors: true,
    variables: { versionIdentifier },
    onCompleted: data => {
      if (!data || !data.versionDelete) {
        onError(GENERIC_ERROR_MESSAGE)
        return
      }

      onCompleted()
    },
    update: (cache, { data }) => {
      if (!data?.versionDelete?.versionDeleted) {
        ErrorHandler.shouldNeverHappen.invalidMutationData('versionDelete')
        return
      }

      try {
        const versionHistory = errorPreventiveCacheRead<
          GetVersionHistoryQuery,
          GetVersionHistoryQueryVariables
        >(cache, {
          query: GetVersionHistoryDocument,
          variables: { shareIdentifier, after: null },
        })

        if (!versionHistory?.share) {
          ErrorHandler.shouldNeverHappen(
            'versionHistory data should be loaded when deleting version'
          )
          return
        }

        const newVersionHistory = produce(versionHistory, ({ share }) => {
          if (!share) return

          share.versionHistory.entries = share.versionHistory.entries.filter(
            entry => entry.identifier !== versionIdentifier
          )

          if (shouldReduceTotalCount(versionIdentifier)) {
            share.versionHistory.meta.totalCount--
            share.availableVersions.meta.totalCount--
          }
        })

        cache.writeQuery<
          GetVersionHistoryQuery,
          GetVersionHistoryQueryVariables
        >({
          data: newVersionHistory,
          query: GetVersionHistoryDocument,
          variables: { shareIdentifier, after: null },
        })
      } catch (error) {
        ErrorHandler.shouldNeverHappen(
          'failed to update versionHistory data after deleting version'
        )
        onError(GENERIC_ERROR_MESSAGE)
      }
    },
    onError: error => {
      onError(error.message)
    },
  })

  const augmentedMutate: typeof mutate = (...args) => {
    recordInitiatedDeletion(versionIdentifier)
    return mutate(...args)
  }

  return [augmentedMutate, ...rest] as const
}
