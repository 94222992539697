import styled from 'styled-components'

import { BuyMeACoffeeButton as BuyMeACoffeeButtonUnstyled } from '../BuyMeACoffeeButton'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 470px;
  text-align: center;
`

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  white-space: break-spaces;

  :not(:first-child) {
    margin-top: 8px;
  }
`

export const BuyMeACoffeeButton = styled(BuyMeACoffeeButtonUnstyled)`
  margin-top: 20px;
`
