import styled, { css } from 'styled-components'

import { breakpoint } from '@sketch/global-styles'

import {
  TableComponents,
  Banner,
  Filter as BaseFilter,
  Button,
} from '@sketch/components'

import { MemberCard as BaseMemberCard } from '../MemberCard'

export const Filter = styled(BaseFilter)`
  ${Banner} + & {
    margin-top: 20px;
  }
`

export const TableWrapper = styled.div<{
  $tableWidth: '100%' | '470px'
  $firstColWidth: '100%' | '80%'
  $isSticky: boolean
}>(
  ({ $tableWidth, $firstColWidth, $isSticky }) => css`
    overflow: auto;
    margin-top: 24px;

    ${TableComponents.Table} {
      table-layout: fixed;
      width: 100%;
      ${breakpoint('base', 'xs')`
        width: ${$tableWidth}
      `}

      ${/* sc-selector */ TableComponents.TableHeaderCell}:nth-child(1) {
        width: ${$firstColWidth};
      }

      ${/* sc-selector */ TableComponents.TableHeaderCell}:nth-child(2) {
        width: 25%;
      }

      ${/* sc-selector */ TableComponents.TableHeaderCell}:nth-child(3) {
        width: 25%;
      }

      ${/* sc-selector */ TableComponents.TableHeaderCell}:nth-last-child(1) {
        text-align: right;
        width: 90px;

        ${$isSticky &&
        breakpoint('base', 'xs')`
          width: 60px;
        `}
      }

      ${TableComponents.TableRow} td:last-child {
        text-align: right;
        width: 90px;

        ${$isSticky &&
        breakpoint('base', 'xs')`
          width: 60px;
        `}
      }
    }
  `
)

export const LoadMoreWrapper = styled.div`
  margin: 24px;
  text-align: center;
`

export const MemberCard = styled(BaseMemberCard)<{ $dimmed: boolean }>`
  ${({ $dimmed }) =>
    $dimmed &&
    css`
      opacity: 0.5;
    `}
`

export const LoadingWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  flex: 1;

  :nth-child(1) {
    justify-content: center;
  }

  :nth-child(2) {
    justify-content: center;
  }

  :last-child {
    justify-content: flex-end;
  }
`

export const EmptyList = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.background.secondary.B};
    border-radius: ${theme.radii.large};
    margin-top: 32px;
    padding: 24px;
    text-align: center;
    color: ${theme.colors.foreground.secondary.D};
  `}
`

export const ErrorContainer = styled.div`
  margin: 48px;
`

export const StatusCell = styled.span<{ $isActive: boolean }>`
  ${({ theme, $isActive }) => css`
    color: ${$isActive ? theme.colors.state.positive.A : 'inherit'};
  `}
`

export const StyledTableCell = styled(TableComponents.TableCell)`
  max-width: 200px;
`

export const StyledButton = styled(Button)`
  flex: 0 0 120px;
`

export const SearchWrapper = styled.div`
  display: flex;
  gap: 12px;
`
