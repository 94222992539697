import React, { useContext } from 'react'
import { WebsocketContext as WebsocketContextProps } from '@sketch/common-types'

export const WebsocketContext = React.createContext<
  WebsocketContextProps | undefined
>(undefined)

export function useWebsocket() {
  const contextValue = useContext(WebsocketContext)

  if (!contextValue) {
    throw new Error('useWebsocket must be used within a WebsocketProvider')
  }

  return contextValue
}
