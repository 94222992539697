import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "color-fan-64", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 64 64", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("path", { d: "M64 0v64H0V0h64zM27.71 6.763H12.817a5.053 5.053 0 00-5.053 5.053V41.68c-1.61 5.604.722 11.793 6 14.84 6.218 3.59 14.169 1.46 17.758-4.758l16.974-29.4a5.053 5.053 0 00-1.85-6.901L32.882 7.514a5.035 5.035 0 00-3.708-.537 5.077 5.077 0 00-1.462-.214z", id: "color-fan-64_svg__a" }),
        React.createElement("path", { d: "M64 0v64H0V0h64zM27.974 7.026H13.079a5.053 5.053 0 00-5.053 5.053v33.447c0 6.904 5.597 12.5 12.5 12.5 6.904 0 12.5-5.596 12.5-12.5V12.08a5.053 5.053 0 00-5.052-5.053z", id: "color-fan-64_svg__c" })),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("mask", { id: "color-fan-64_svg__b", fill: "#fff" },
            React.createElement("use", { xlinkHref: "#color-fan-64_svg__a" })),
        React.createElement("path", { d: "M43.912 18.33c.85-.49 1.818-.59 2.696-.355a3.542 3.542 0 012.157 1.656h0l7.948 13.765c.49.85.59 1.817.355 2.696a3.542 3.542 0 01-1.656 2.157h0L26.013 55.222a11.464 11.464 0 01-8.726 1.15 11.464 11.464 0 01-6.983-5.359 11.464 11.464 0 01-1.149-8.726 11.464 11.464 0 015.358-6.983h0z", stroke: "currentColor", strokeWidth: 3, fillOpacity: 0.2, fill: "currentColor", fillRule: "nonzero", mask: "url(#color-fan-64_svg__b)" }),
        React.createElement("mask", { id: "color-fan-64_svg__d", fill: "#fff" },
            React.createElement("use", { xlinkHref: "#color-fan-64_svg__c" })),
        React.createElement("path", { d: "M29.698 8.722a3.542 3.542 0 012.696.355h0l13.765 7.947c.85.49 1.42 1.279 1.655 2.157a3.542 3.542 0 01-.355 2.696h0l-16.973 29.4a11.464 11.464 0 01-6.983 5.357 11.464 11.464 0 01-8.727-1.148 11.464 11.464 0 01-5.358-6.983 11.464 11.464 0 011.149-8.727h0l16.974-29.399a3.542 3.542 0 012.157-1.655z", stroke: "currentColor", strokeWidth: 3, fillOpacity: 0.1, fill: "currentColor", fillRule: "nonzero", mask: "url(#color-fan-64_svg__d)" }),
        React.createElement("path", { d: "M28.21 7.763c.982 0 1.87.398 2.513 1.04a3.542 3.542 0 011.04 2.513h0v33.947c0 3.176-1.287 6.05-3.368 8.132a11.464 11.464 0 01-8.132 3.368c-3.175 0-6.05-1.287-8.132-3.368a11.464 11.464 0 01-3.368-8.132h0V11.316c0-.981.398-1.87 1.04-2.512a3.542 3.542 0 012.513-1.04h0z", stroke: "currentColor", strokeWidth: 3 }),
        React.createElement("circle", { stroke: "currentColor", strokeWidth: 3, cx: 20.263, cy: 45.263, r: 3.5 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
