import React from 'react'

import { useResponsiveDropdown } from '@sketch/components'

import { ToggleButton, Wrapper } from './PublicationPageSelector.styles'

import { PublicationPageFragment } from '@sketch/gql-types'
import PublicationPagesDropdown from '../PublicationPagesDropdown'

interface PublicationPageSelectorProps {
  identifier: string
  page: PublicationPageFragment
  onPageSelected: (page: PublicationPageFragment) => void
}

const PublicationPageSelector: React.FC<PublicationPageSelectorProps> = ({
  identifier,
  page,
  onPageSelected,
}) => {
  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: PublicationPagesDropdown,
    dropdownProps: {
      identifier,
      onPageSelected,
      selectedPage: page,
    },
    placement: 'bottom-start',
    usePortal: true,
  })

  return (
    <Wrapper>
      <ToggleButton
        label={page.name}
        variant="strong"
        chevrons
        {...buttonProps}
      />
      {content}
    </Wrapper>
  )
}

export default PublicationPageSelector
