import { useMemo, useCallback, useRef } from 'react'
import qs from 'query-string'
import { useHistory } from 'react-router-dom'
import { QueryParams, RouteKeys } from './routes'

export const useQueryParams = <TKey extends RouteKeys>() => {
  const { location } = useHistory()
  const queryParams = useMemo(
    () => qs.parse(location.search) as QueryParams<TKey>,
    [location]
  )

  return queryParams
}

/**
 * Set one or multiple query params in the URL. Params passed to
 * the set function will be assigned to the existing query params.
 * All query params values need to be set as string.
 *
 * @example
 * const setQueryParams = useSetQueryParams<SHARE_PAGE_CANVAS_VIEW>()
 * setQueryParams({ zoom: "0.2" })
 */
export const useSetQueryParams = <TKey extends RouteKeys>() => {
  const currentQueryParams = useQueryParams()
  const history = useHistory()

  // Because currentQueryParams object is regenerated every time
  // the URL changes we need setQueryParams to access it through
  // a ref to avoid re-creating setQueryParams function each time
  // we call it to update the URL.
  const currentQueryParamsRef = useRef(currentQueryParams)
  // Always update the ref value to the latest
  if (currentQueryParamsRef.current !== currentQueryParams) {
    currentQueryParamsRef.current = currentQueryParams
  }

  const setQueryParams = useCallback(
    (paramsToUpdate: Partial<QueryParams<TKey>>) => {
      const newQueryParams = {
        ...currentQueryParamsRef.current,
        ...paramsToUpdate,
      }
      history.replace({
        pathname: history.location.pathname,
        search: qs.stringify(newQueryParams),
      })
    },
    [history]
  )

  return setQueryParams
}
