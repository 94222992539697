import { captureException } from '@sentry/browser'
import {
  ShareInfoFragment,
  ShareInfoFragmentDoc,
  useVersionUploadProcessedSubscription,
} from '@sketch/gql-types'
import { dataIdFromObject } from '@sketch/graphql-cache'
import { useEventDispatch } from '@sketch/utils'
import { getPreferredStateVersionFromShare } from '../utils'

declare module '@sketch/utils' {
  export interface EventsMap {
    versionIsProcessed: ShareInfoFragment
  }
}

export const useVersionUploadProcessed = (share?: ShareInfoFragment) => {
  const onVersionProcessed = useEventDispatch('versionIsProcessed')
  const preferredState = getPreferredStateVersionFromShare(share)

  // Subscription receiving a new processed version (it happens after versionUploadComplete)
  useVersionUploadProcessedSubscription({
    variables: share
      ? { preferredState, shareIdentifier: share.identifier }
      : {},
    onSubscriptionData: ({ client, subscriptionData }) => {
      const share = subscriptionData.data?.versionUploadProcessed

      if (share) {
        // We need to trigger share update event before we update the cache
        // see https://github.com/sketch-hq/Cloud/issues/1394
        onVersionProcessed(share)

        // Prevent the UI from breaking
        try {
          client.writeFragment<ShareInfoFragment>({
            id: dataIdFromObject(share) as string,
            fragment: ShareInfoFragmentDoc,
            fragmentName: 'ShareInfo',
            data: share,
          })
        } catch (e) {
          captureException(e)
        }
      }
    },
    fetchPolicy: 'no-cache',
  })
}
