import styled, { css } from 'styled-components'
import { breakpoint } from '@sketch/global-styles'
import { FlatButton } from '@sketch/components'
import background from '@sketch/icons/upsell-background.png'
import puzzle from '@sketch/icons/upsell-puzzle.png'

export const BannerContainer = styled.div(
  ({ theme }) => css`
    background-image: url(${background});
    background-size: cover;
    border-radius: ${theme.radii.xxlarge};
    margin-bottom: 40px;
    width: 100%;
    display: flex;
    flex: 2 1;
    position: relative;
    justify-content: flex-start;

    ${breakpoint('xs')`
      justify-content: center;
    }

    ${breakpoint('md')`
      justify-content: space-between;
    `};
  `};
  `
)

export const TextContainer = styled.div`
  padding: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: auto;
  flex-shrink: 1;
  z-index: 1;

  ${breakpoint('sm')`
    max-width: 595px;
  `};
`

export const HideButton = styled(FlatButton)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.C};
    text-decoration: underline;
  `
)

export const Puzzle = styled.div(
  ({ theme }) => css`
    width: 50%;
    max-width: 442px;
    height: 100%;
    flex-shrink: 1;
    border-radius: 0 ${theme.radii.xxlarge} ${theme.radii.xxlarge} 0;
    background-image: url(${puzzle});
    background-position: right center;
    background-repeat: repeat-y;
    display: none;

    ${breakpoint('md')`
        display: inherit;
        background-size: 100%;
    `};

    ${breakpoint('lg')`
        background-size: cover;
    `};
  `
)

export const PuzzleMobile = styled.div(
  ({ theme }) => css`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 144px;
    height: 100px;
    background-position: center top;
    border-radius: 0 ${theme.radii.xxlarge} ${theme.radii.xxlarge} 0;
    background-image: url(${puzzle});
    background-repeat: repeat-y;
    background-size: cover;
    display: none;

    ${breakpoint('xs')`
     display: inherit;
    }
  `};
  `
)
