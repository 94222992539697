import styled from 'styled-components'
import { Input as BaseInput } from '../Input/Input'
import { Text } from '../Box'
import { truncateStyles } from '../Truncate'

const InputContainer = styled.div`
  display: flex;
  flex: 1;
`

const InputBackground = styled(BaseInput)`
  display: block;
  width: 100%;
`

const InputValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-left: -100%;
  padding: 12px;
  z-index: 1;
`

export const InputValue = styled(Text.Span).attrs({
  textStyle: 'copy.quaternary.standard.E',
})`
  ${truncateStyles}
`

export const Input = {
  Container: InputContainer,
  Background: InputBackground,
  Value: Object.assign(InputValue, {
    Container: InputValueContainer,
  }),
}
