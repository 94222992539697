import { useState } from 'react'
import { getParsedItem, setItem } from '../utils'

export function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value, pass initial state function to useState so logic
  // is only executed once
  const [storedValue, setStoredValue] = useState<T>(
    getParsedItem(key) ?? initialValue
  )
  // Return a wrapped version of useState's setter function that persists the
  // new value to localStorage
  const setValue = (value: T | ((val: T) => T)) => {
    // Allow value to be a function so we have same API as useState
    const valueToStore = value instanceof Function ? value(storedValue) : value

    setStoredValue(valueToStore)
    setItem(key, JSON.stringify(valueToStore))
  }

  return [storedValue, setValue] as const
}
