import { handleDocumentUploadEvent } from './handleDocumentUploadEvent'
import { insertNewShare } from './insertNewShare'
import {
  ShareInfoFragment,
  useVersionUploadCompleteSubscription,
} from '@sketch/gql-types'
import { readShareListItemFromCache } from '@sketch/modules-common'
import { useEventDispatch } from '@sketch/utils'
import { getPreferredStateVersionFromShare } from '../../utils'

interface VersionIsUploadedEvent {
  shareIdentifier: string
  workspaceIdentifier?: string
}

declare module '@sketch/utils' {
  export interface EventsMap {
    versionIsUploaded: VersionIsUploadedEvent
  }
}

export const useVersionUploadComplete = (share?: ShareInfoFragment) => {
  const onVersionUploaded = useEventDispatch('versionIsUploaded')
  const preferredState = getPreferredStateVersionFromShare(share)

  useVersionUploadCompleteSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      const share = subscriptionData.data?.versionUploadComplete
      const workspace = subscriptionData.data?.versionUploadComplete.workspace
      if (!share) return

      onVersionUploaded({
        shareIdentifier: share.identifier,
        workspaceIdentifier: workspace?.identifier,
      })

      const cachedShare = readShareListItemFromCache({
        cache: client,
        id: share.identifier,
      })

      /**
       * To see if we have to create a new share we just look for an entry in
       * the user's cache, if it already exists we just skip it.
       */
      if (!cachedShare) {
        insertNewShare(client, share.identifier)
      }

      handleDocumentUploadEvent(client, share)
    },
    variables: {
      limit: 3,
      ...(share ? { shareIdentifier: share.identifier, preferredState } : {}),
    },
    fetchPolicy: 'no-cache',
  })
}
