import React from 'react'
import styled from 'styled-components'

import { useBackButton } from '../BackButton'
import BackButton from '../BackButton/BackButton'
import { useForTablet } from '@sketch/components'

const NavbarBackButtonItem = styled.div`
  margin: 0 8px 0 0;
  line-height: 1;
`

/**
 * This component acts as a wrapper to run useBackButton hook only when
 * BackButton needs to be rendered. By doing that, DocumentSidebarLayout can
 * be rendered outside ShareViewsNavigationContextProvider and ShareVersionProvider contexts.
 */
export const LayoutHeaderBackButton = () => {
  const isTabletAndBigger = useForTablet()
  const { link: shouldRenderBackButton } = useBackButton()

  return isTabletAndBigger && shouldRenderBackButton ? (
    <NavbarBackButtonItem>
      <BackButton />
    </NavbarBackButtonItem>
  ) : null
}
