import React from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'

import {
  Form,
  Button,
  Modal,
  PasswordInput,
  useModalContext,
} from '@sketch/components'

import {
  Title,
  Description,
  FormWrapper,
} from '../RegenerateRecoveryCodesModal.styles'

interface SecondStepProps {
  onSubmit: (values: typeof INITIAL_VALUES) => void
  isLoading: boolean
}

const VALIDATION_SCHEMA = yup.object().shape({
  password: yup.string().required('Password can’t be blank'),
})

const INITIAL_VALUES = {
  password: '',
}

/**
 * Renders the second step for the Regenerate Recovery Codes modal
 */
export const SecondStep: React.FC<SecondStepProps> = ({
  onSubmit,
  isLoading,
}) => {
  const { hideModal } = useModalContext()

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        dirty,
        isValid,
        handleChange,
        handleBlur,
      }) => (
        <Form>
          <Modal.Body>
            <Title>Enter Your Password</Title>
            <Description>
              Enter your password to generate a new set of recovery codes.
            </Description>
            <FormWrapper>
              <Form.Field
                name="password"
                label="Password"
                errorText={touched.password ? errors.password : undefined}
              >
                <PasswordInput
                  name="password"
                  placeholder="Password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Field>
            </FormWrapper>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={hideModal} size="40">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              size="40"
              loading={isLoading}
              disabled={!(dirty && isValid)}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}
