import {
  DiscoverSectionFragment,
  DiscoverItemFragment,
} from '@sketch/gql-types'

export const defaultSectionValues: Partial<DiscoverSectionFragment> = {
  __typename: 'DiscoverSection',
  categories: null,
  items: [],
  limit: null,
  links: null,
  permissions: null,
  size: null,
  stretchedContent: null,
  subTitle: null,
  title: null,
  visibility: {
    __typename: 'Visibility',
    hiddenForFF: null,
    hideOnBreakpoint: null,
  },
}

export const defaultItemValues: Partial<DiscoverItemFragment> = {
  __typename: 'DiscoverItem',
  author: null,
  categories: null,
  contentPosition: null,
  featured: null,
  icon: null,
  length: null,
  links: null,
  mobile: null,
  note: null,
  permissions: null,
  size: null,
  source: null,
  url: null,
  content: null,
  image: null,
  video: null,
  backgroundColor: null,
  place: null,
  date: null,
  dismissable: null,
  speakers: [],
}
