import React from 'react'
import { ButtonUnstyled } from '../Button'
import { CommonProps as LinkCommonProps } from '../Link'
import * as S from './FakeLinkButton.styles'

type LinkStylingProps = Pick<
  LinkCommonProps,
  'isUnderlined' | 'variant' | 'size'
>

export type FakeLinkButtonProps = React.ComponentProps<typeof ButtonUnstyled> &
  LinkStylingProps & {
    className?: string
  }

/**
 * Button imitating the appearance of a link. It looks like a link but it's
 * a button.
 */
export function FakeLinkButton({
  children,
  isUnderlined = false,
  variant = 'primary',
  size,
  onClick,
  className,
  ...otherButtonProps
}: FakeLinkButtonProps) {
  return (
    <S.FakeLinkButton
      {...otherButtonProps}
      className={className}
      onClick={onClick}
      $size={size}
      $variant={variant}
      $isUnderlined={isUnderlined}
    >
      {children}
    </S.FakeLinkButton>
  )
}
