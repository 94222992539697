import React from 'react'
import { Redirect } from 'react-router-dom'

import {
  routes,
  IndexLayoutOldSubtitle,
  IndexLayoutOldTitle,
  setSketchUrlScheme,
  IndexLayoutExtraProps,
  IndexLayoutContent,
} from '@sketch/modules-common'
import { Button, LoadingState } from '@sketch/components'
import { Wrapper } from './SsoSuccessfulSigninView.styles'
import { useSsoSuccessfulSignIn } from './useSsoSuccessfulSignIn'

interface SSOSuccessPopupProps
  extends Pick<IndexLayoutExtraProps, 'useOverrideLayoutProps'> {
  workspaceName: string
}

const SSOSuccessPopup = ({
  workspaceName,
  useOverrideLayoutProps,
}: SSOSuccessPopupProps) => {
  return (
    <IndexLayoutContent center="horizontal" marginTop paddingHorizontal>
      <Wrapper>
        <IndexLayoutOldTitle>
          You are now signed in into &quot;{workspaceName}&quot;
        </IndexLayoutOldTitle>
        <IndexLayoutOldSubtitle>
          Please close this window and resume your work on the main one
        </IndexLayoutOldSubtitle>
        <Button variant="primary-untinted" onClick={window.close}>
          Close window
        </Button>
      </Wrapper>
    </IndexLayoutContent>
  )
}

export interface SsoSuccessfulSignInViewProps extends IndexLayoutExtraProps {}

const SsoSuccessfulSignInView = (props: SsoSuccessfulSignInViewProps) => {
  const { state } = useSsoSuccessfulSignIn()

  switch (state.type) {
    case 'loading':
      return <LoadingState />
    case 'success-cloud-popup':
      return <SSOSuccessPopup {...props} workspaceName={state.workspaceName} />
    case 'success-cloud':
      return <Redirect to={state.from ?? routes.ENTRY.create({})} />
    case 'success-sketch-app': {
      const redirectUrl =
        typeof state.samlSessionId === 'undefined'
          ? `sketch.cloud/signin/sso/success?token=${state.exchangeToken}`
          : `sketch.cloud/signin/sso/success?token=${state.exchangeToken}&s=${state.samlSessionId}`
      window.location.href = setSketchUrlScheme(redirectUrl)
      return null
    }
    case 'error':
      return <Redirect to={routes.SSO_SIGN_IN.create({})} />
  }
}

export default SsoSuccessfulSignInView
