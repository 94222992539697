import { useCallback } from 'react'
import { useGetProjectSearchSettingsQuery } from '@sketch/gql-types'

export const useHideCollectionsSetting = () => {
  const { data, updateQuery } = useGetProjectSearchSettingsQuery()

  const hideCollections = data?.hideCollections ?? false

  const setHideCollections = useCallback(
    (hideCollections: boolean) => {
      updateQuery(previous => ({
        ...previous,
        hideCollections,
      }))
    },
    [updateQuery]
  )

  return { hideCollections, setHideCollections }
}
