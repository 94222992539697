import { PaymentDetailsFragment } from '@sketch/gql-types'

// eslint-disable-next-line no-restricted-imports
import {
  CreditCardPaymentDetails,
  InvoicePaymentDetails,
} from '@sketch/gql-types/expansive'

export const getPaymentTypeByProratedAmounts = (charge = 0, credits = 0) => {
  if (credits > 0 && charge === 0) {
    return 'CREDITS'
  } else if (credits && charge) {
    return 'CREDITS-CHARGE_METHOD'
  } else {
    return 'CHARGE_METHOD'
  }
}

export const isPaymentMethodCard = (
  paymentDetails: PaymentDetailsFragment
): paymentDetails is CreditCardPaymentDetails => paymentDetails.type === 'CARD'

export const isPaymentMethodInvoice = (
  paymentDetails: PaymentDetailsFragment
): paymentDetails is InvoicePaymentDetails => paymentDetails.type === 'INVOICE'

export const hasPaymentDetails = (
  paymentDetails?: PaymentDetailsFragment
): boolean => !!paymentDetails && paymentDetails.type !== 'NONE'
