const DEFAULT_OPTIONS: Intl.NumberFormatOptions = {
  notation: 'standard',
  style: 'unit',
  unit: 'gigabyte',
  unitDisplay: 'short',
  maximumFractionDigits: 2,
  useGrouping: false,
}

export const formatStorageNumber = (
  storage: number,
  options = DEFAULT_OPTIONS
) => {
  const gigabytes = Intl.NumberFormat('en', options).format(storage)

  return gigabytes
}
