import React from 'react'
import produce from 'immer'

import { Button, Modal, ModalInjectedProps, Text } from '@sketch/components'
import {
  GetWorkspacePermissionGroupsDocument,
  GetWorkspacePermissionGroupsQuery,
  GetWorkspacePermissionGroupsQueryVariables,
  useDeletePermissionGroupMutation,
} from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'
import {
  errorPreventiveCacheRead,
  removeFromPaginated,
} from '@sketch/modules-common'
import PermissionGroupMemberTable from '../../components/PermissionGroupMemberTable/PermissionGroupMemberTable'

interface DeletePermissionGroupModalProps extends ModalInjectedProps {
  workspaceIdentifier: string
  permissionGroupIdentifier: string
  permissionGroupName: string
}

const DeletePermissionGroupModal = (props: DeletePermissionGroupModalProps) => {
  const {
    permissionGroupIdentifier,
    permissionGroupName,
    workspaceIdentifier,
    hideModal,
  } = props

  const { showToast } = useToast()
  const [deleteGroup, { loading }] = useDeletePermissionGroupMutation({
    variables: { input: { permissionGroupIdentifier } },
    onError: 'show-toast',
    onCompleted: () => {
      showToast('Permission group has been deleted!')
      hideModal()
    },
    update: cache => {
      removeFromPaginated(
        cache,
        {
          __typename: 'PermissionGroup',
          identifier: permissionGroupIdentifier,
        },
        () => true
      )
      const query = errorPreventiveCacheRead<
        GetWorkspacePermissionGroupsQuery,
        GetWorkspacePermissionGroupsQueryVariables
      >(cache, {
        query: GetWorkspacePermissionGroupsDocument,
        variables: { workspaceIdentifier, search: '' },
      })

      if (!query) {
        return
      }

      const newQuery = produce(query, query => {
        query.workspace.totalWorkspacePermissionGroups.meta.totalCount =
          query.workspace.totalWorkspacePermissionGroups.meta.totalCount - 1
      })

      cache.writeQuery({
        query: GetWorkspacePermissionGroupsDocument,
        variables: { workspaceIdentifier, search: '' },
        data: newQuery,
      })
    },
  })

  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>Delete Group?</Modal.Header>
      <Modal.Body>
        <Text>
          If you delete the “<b>{permissionGroupName}</b>” group its Members
          will no longer have access to its projects and documents. You can’t
          undo this action.
        </Text>

        <PermissionGroupMemberTable
          permissionGroupIdentifier={permissionGroupIdentifier}
          emptyStateDescription={null}
        />
      </Modal.Body>
      <Modal.Footer>
        <form
          onSubmit={event => {
            // Prevent the HTML form regular behaviour
            event.preventDefault()
            deleteGroup()
          }}
        >
          {/* This input needs to exist so the form can submit, however there's no
          logic to send so it's pretty much dummy */}
          <input name="submit" hidden />

          <Button type="button" onClick={hideModal}>
            Cancel
          </Button>

          <Button autoFocus variant="negative" loading={loading} type="submit">
            Delete Group
          </Button>
        </form>
      </Modal.Footer>
    </Modal>
  )
}

export default DeletePermissionGroupModal
