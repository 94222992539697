import config from '@sketch/env-config'

import { getNetworkRequestHeaders } from '@sketch/modules-common'

import { getDeviceId } from './deviceId'

const SECONDS_TO_MILLISECONDS = 1000

const SIGN_IN_ENDPOINT = config.graphql_api_domain.replace(
  '/api',
  '/oauth/token'
)

const SIGN_IN_WITH_SSO_ENDPOINT = config.graphql_api_domain.replace(
  '/api',
  '/sso/token'
)
const REVOKE_OAUTH_ENDPOINT = config.graphql_api_domain.replace(
  '/api',
  '/oauth/revoke'
)

const VALIDATE_ONE_TIME_PASSWORD_ENDPOINT = config.graphql_api_domain.replace(
  '/api',
  '/oauth/mfa_totp'
)

const CONFIRM_DISABLE_MFA_ENDPOINT = config.graphql_api_domain.replace(
  '/api',
  '/oauth/disable_mfa'
)

const HEADERS = {
  ...getNetworkRequestHeaders(),
  'Content-Type': 'application/json',
} as const

export const getSignInEndpoint = () => SIGN_IN_ENDPOINT
export const getSignInWithSsoEndpoint = () => SIGN_IN_WITH_SSO_ENDPOINT
export const getRevokeOAuthEndpoint = () => REVOKE_OAUTH_ENDPOINT
export const getValidateOneTimePasswordEndpoint = () =>
  VALIDATE_ONE_TIME_PASSWORD_ENDPOINT
export const getConfirmDisableMfaEndpoint = () => CONFIRM_DISABLE_MFA_ENDPOINT

export const createExpireDate = (duration: number) =>
  new Date(Date.now() + duration * SECONDS_TO_MILLISECONDS).toISOString()

export const getHeaders = () => ({
  ...HEADERS,
  /**
   * Adding the x-device-id header to auth requests that are
   * REST requests. If you need to add it to GraphQL Operations,
   * it's done in deviceIdLink.ts
   */
  'x-device-id': getDeviceId(),
})
