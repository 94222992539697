import React, { FC, MouseEvent } from 'react'
import styled, { css } from 'styled-components'

import { Button } from '../Button'
import { Dropdown, DropdownProps } from './Dropdown'

import { ReactComponent as BaseEllipsisIcon } from '@sketch/icons/dots-3-horizontal-24'

export const DropdownButton = styled(Button).attrs({
  variant: 'secondary',
  size: '32',
})`
  background-clip: padding-box;
  padding: 4px 8px;

  opacity: 0;
  transform: translate3d(0, -5px, 0);

  transition: ${({ theme }) => `
    opacity ${theme.transitions.duration[0]} ${theme.transitions.timing.easeInOut},
    transform ${theme.transitions.duration[0]} ${theme.transitions.timing.easeInOut};
  `};
`

const showDropdownButtonStyles = css`
  ${DropdownButton} {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const DropdownButtonWrapper = styled.div`
  position: absolute;

  top: 9px;
  right: 9px;
  z-index: 1;

  /* Prevent the button from hiding when the dropdown is open */
  [aria-expanded='true'] {
    ${showDropdownButtonStyles};
  }

  :focus-within {
    ${showDropdownButtonStyles};
  }
`

const EllipsisIcon = styled(BaseEllipsisIcon)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

const handleDropdownWrapperClick = (event: MouseEvent<HTMLElement>) => {
  /* Work-around to prevent Dropdown items from triggering any parent link */
  event.stopPropagation()
}

export const showDropdownStyles = css`
  @media (hover: none) {
    ${showDropdownButtonStyles}
  }

  &:hover,
  &:focus,
  &:focus-within {
    ${showDropdownButtonStyles}
  }
`

export interface CardDropdownProps extends Partial<DropdownProps> {
  buttonLabel?: string
}

export const CardDropdown: FC<CardDropdownProps> = props => {
  const {
    children,
    placement = 'bottom',
    buttonLabel = 'Card Settings',
    toggle = (
      <DropdownButton aria-label={buttonLabel}>
        <EllipsisIcon />
        <span className="sr-only">{buttonLabel}</span>
      </DropdownButton>
    ),
    ...rest
  } = props

  return (
    <DropdownButtonWrapper onClick={handleDropdownWrapperClick}>
      <Dropdown placement={placement} toggle={toggle} {...rest}>
        {children}
      </Dropdown>
    </DropdownButtonWrapper>
  )
}
