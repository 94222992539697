import React, { useEffect } from 'react'

import { useGetDiscoverResourcesQuery } from '@sketch/gql-types'
import { ErrorHandler } from '@sketch/tracing'
import * as Sentry from '@sentry/browser'

import {
  DiscoverInsightListSkeleton,
  DiscoverInsightList,
} from '../../components/DiscoverInsightList'

const RESOURCE_TYPE_TO_API_TYPE = {
  'empty-state-collection': 'EMPTY_STATE_COLLECTION',
  'empty-state-libraries': 'EMPTY_STATE_LIBRARIES',
  'empty-state-my-drafts': 'EMPTY_STATE_MY_DRAFTS',
  'empty-state-project': 'EMPTY_STATE_PROJECT',
  'empty-state-shared-with-me': 'EMPTY_STATE_SHARED_WITH_ME',
  'empty-state-templates': 'EMPTY_STATE_TEMPLATES',
  'empty-state-components': 'EMPTY_STATE_COMPONENTS',
  'empty-state-prototypes': 'EMPTY_STATE_PROTOTYPES',
} as const

interface DiscoverResourceProps {
  type: keyof typeof RESOURCE_TYPE_TO_API_TYPE
}

const DiscoverResources = ({ type }: DiscoverResourceProps) => {
  const { loading, error, data } = useGetDiscoverResourcesQuery({
    variables: { type: RESOURCE_TYPE_TO_API_TYPE[type] },
  })

  useEffect(() => {
    const { title, items } = data?.discoverResources || {}

    if (loading) {
      return
    }

    if (error) {
      Sentry.captureException(error)
      ErrorHandler.shouldNeverHappen(
        `Discover Resource [${type}] is returning an error: ${error.message}`
      )
      return
    }

    if (!title || !items) {
      ErrorHandler.shouldNeverHappen(
        `Discover Resource [${type}] is returning invalid content. No "title" or "items" supplied`
      )
    }
  }, [data, error, type, loading])

  if (loading || error || !data) {
    return <DiscoverInsightListSkeleton />
  }

  /**
   * If the content is invalid for some reason we should
   * continue showing the loading state to prevent a blank space
   * from being seen
   */
  const { title, subTitle, links, items } = data.discoverResources
  if (!title || !items) {
    return <DiscoverInsightListSkeleton />
  }

  const titleLink = links?.[0]
    ? { url: links[0].url, text: links[0].text! }
    : undefined

  return (
    <DiscoverInsightList
      items={items}
      title={title}
      subTitle={subTitle?.text}
      titleLink={titleLink}
    />
  )
}

export default DiscoverResources
