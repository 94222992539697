import React, { FC } from 'react'

import { Flex } from '../Box'
import { Spinner } from '../Spinner'
import { Button } from './Button'

interface LoadMoreButtonProps {
  onClick: () => void
  loading: boolean
}

export const LoadMoreButton: FC<LoadMoreButtonProps> = ({
  loading,
  onClick,
}) => (
  <Flex mt={4} justifyContent="center">
    <Button
      size="40"
      disabled={loading}
      width="100px"
      onClick={onClick}
      variant="secondary"
    >
      {loading ? <Spinner.Flex primary /> : 'Load More'}
    </Button>
  </Flex>
)
