import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { localStorageKeys } from '@sketch/constants'
import { routes, RouteParams } from '@sketch/modules-common'
import { getItem } from '@sketch/utils'

import { RestrictedWorkspaceBannerManager } from './RestrictedWorkspaceBannerManager'

const UpdatesGuard = () => {
  const [workspaceId] = useState<string | undefined>(
    getItem(localStorageKeys.lastWorkspaceIdKey)
  )

  if (!workspaceId) return null

  return <RestrictedWorkspaceBannerManager workspaceId={workspaceId} />
}

/**
 * We are listing all the workspace routes instead of using a more
 * generic one like "/workspace" because we want to ignore some of them
 * like "/workspace/create".
 *
 * https://github.com/sketch-hq/cloud-frontend/pull/6733#discussion_r1151827106
 */
const WORKSPACE_ROUTES = [
  routes.WORKSPACE_SETTINGS.template(),
  routes.WORKSPACE_DISCOVER.template(),
  routes.WORKSPACE_SUBSCRIBE.template(),
  routes.WORKSPACE_PROJECT.template(),
  routes.WORKSPACE_SHARES.template(),
  routes.WORKSPACE_LIBRARIES.template(),
  routes.WORKSPACE_TRASH.template(),
  routes.WORKSPACE_DRAFTS.template(),
  routes.WORKSPACE_SHARED_WITH_ME.template(),
  routes.WORKSPACE_TEMPLATES.template(),
  routes.WORKSPACE_TRASH_PROJECT.template(),
]

export const RestrictedWorkspaceBannerManagerSwitch = () => {
  const updateRouteMatch = useRouteMatch({
    path: routes.UPDATES.template(),
  })

  const workspaceRouteMatch = useRouteMatch<RouteParams<'WORKSPACE'>>({
    path: WORKSPACE_ROUTES,
  })

  if (workspaceRouteMatch) {
    const { workspaceId } = workspaceRouteMatch.params
    return <RestrictedWorkspaceBannerManager workspaceId={workspaceId} />
  }

  if (updateRouteMatch) {
    /**
     * This isn't ideal, right now `Updates` view in essence depends on the workspace, but due to historical reasons
     * it also at the same time "doesn't depend" on the workspace.
     * So therefore we are using a workaround to learn about the current active workspace by reading localStorage value
     * TODO: fix this, see GitHub issue: https://github.com/sketch-hq/Cloud/issues/13134
     */
    return <UpdatesGuard />
  }

  return null
}
