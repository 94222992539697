import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import { routes } from '@sketch/modules-common'
import { Link } from '@sketch/components'
import { Wrapper } from './CommentInputSignedOut.styles'

interface CommentInputIfSignedOutProps extends RouteComponentProps {
  className?: string
}

const CommentInputIfSignedOut: React.FC<CommentInputIfSignedOutProps> = ({
  location,
  className,
}) => (
  <Wrapper className={className} data-testid="comment-input-placeholder">
    <Link
      to={{
        pathname: routes.SIGN_UP.create({}),
        state: { from: location },
      }}
    >
      Sign up
    </Link>{' '}
    or
    <Link
      to={{
        pathname: routes.SIGN_IN.create({}),
        state: { from: location },
      }}
    >
      {' '}
      Sign In
    </Link>{' '}
    to comment
  </Wrapper>
)

export default withRouter(CommentInputIfSignedOut)
