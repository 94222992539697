import { DataProxy } from 'apollo-cache'

const errorPreventiveCacheRead = <Query = any, Variables = any>(
  cache: DataProxy,
  readOptions: DataProxy.Query<Variables>,
  optimistic?: boolean
) => {
  /*
  Apollo seems to throw errors when he can't find an object in cache.
  The Apollo repo already has a open issue for it

  https://github.com/apollographql/apollo-feature-requests/issues/1

  Remove this code after this issue is solved.
  */
  try {
    return cache.readQuery<Query, Variables>(readOptions, optimistic)
  } catch (e) {
    return null
  }
}

export default errorPreventiveCacheRead
