import React from 'react'
import { Link } from '@sketch/components'

import { dateFormat } from '@sketch/utils'
import { routes } from '@sketch/modules-common'

import {
  ExclamationIcon,
  ScheduleDisclaimer,
} from './ModalScheduleDisclaimer.styles'

interface ModalScheduleDisclaimerProps {
  hideModal: () => void
  nextBillingCycleDate: string
  workspaceId: string
  scheduledSeats: number
}

/**
 * Disclaimer
 *
 * Renders a small disclaimer below the Billing Summary
 */
export const ModalScheduleDisclaimer = ({
  nextBillingCycleDate,
  workspaceId,
  hideModal,
  scheduledSeats,
}: ModalScheduleDisclaimerProps) => (
  <ScheduleDisclaimer>
    <ExclamationIcon />
    <p>
      Scheduled change. You have scheduled to have {scheduledSeats} Editor seats
      from {dateFormat(new Date(nextBillingCycleDate))}.{' '}
      <Link
        onClick={() => hideModal()}
        to={routes.WORKSPACE_SETTINGS_BILLING.create({ workspaceId })}
      >
        See Details.
      </Link>
    </p>
  </ScheduleDisclaimer>
)
