import { ApolloClient } from 'apollo-client'
import { ApolloCache } from 'apollo-cache'
import { ApolloLink, Operation } from 'apollo-link'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { createLink } from 'apollo-absinthe-upload-link'
import { localStorageKeys } from '@sketch/constants'
import {
  isCapacitorRequest,
  isOperationSubscription,
  isPaymentRequest,
} from './utils'

import config from '@sketch/env-config'

import {
  UserAvatarCreateMutation,
  CreateWorkspaceMutation,
  CreatePartnerWorkspaceMutation,
  UpgradePersonalWorkspaceMutation,
  UpdateWorkspaceCustomerSsoIdentityProviderConfigMutation,
  UpdateWorkspaceMutation,
  CreateDesignSystemMutation,
  UpdateDesignSystemMutation,
} from '@sketch/gql-types'

import { createSocketLink } from './socket'
import { createOAuthFetcher } from '../oauth-fetcher'
import { getItem } from '@sketch/utils'

/**
 * This typescript additional logic is a safeguard to make
 * sure if we updated the avatar logic in the future we don't
 * forget it has a special file link and it needs to be revised.
 * These operations are sent through a multipart request.
 */
type MutationsWithFileOperations =
  | keyof OmitSafe<UserAvatarCreateMutation, '__typename'>
  | keyof OmitSafe<CreateWorkspaceMutation, '__typename'>
  | keyof OmitSafe<CreatePartnerWorkspaceMutation, '__typename'>
  | keyof OmitSafe<UpgradePersonalWorkspaceMutation, '__typename'>
  | keyof OmitSafe<UpdateWorkspaceMutation, '__typename'>
  | keyof OmitSafe<CreateDesignSystemMutation, '__typename'>
  | keyof OmitSafe<UpdateDesignSystemMutation, '__typename'>
  | keyof OmitSafe<
      UpdateWorkspaceCustomerSsoIdentityProviderConfigMutation,
      '__typename'
    >

const OPERATIONS_WITH_FILES: MutationsWithFileOperations[] = [
  'userAvatarCreate',
  'createWorkspace',
  'createPartnerWorkspace',
  'upgradePersonalWorkspace',
  'updateWorkspace',
  'updateWorkspaceCustomerSsoIdentityProviderConfig',
  'createDesignSystem',
  'updateDesignSystem',
]

const isOperationWithFiles = ({ operationName }: Operation) =>
  OPERATIONS_WITH_FILES.includes(operationName as MutationsWithFileOperations)

const getShouldAddOperationQueryParam = () => {
  const storedValue = getItem(localStorageKeys.addOperationQueryParamToURL)
  if (storedValue === 'true') return true

  if (
    process.env.REACT_APP_ENV === 'dev' ||
    process.env.REACT_APP_ENV === 'test' ||
    process.env.REACT_APP_ENV === 'localCloudStack'
  ) {
    return true
  }

  return false
}

export const createConnectionLink = (
  getClient: () => ApolloClient<NormalizedCacheObject>,
  cache: ApolloCache<NormalizedCacheObject>
) => {
  const fetcher = createOAuthFetcher(getClient)
  const multiAuthFetcher = createOAuthFetcher(
    getClient,
    'current-and-secondary'
  )

  const shouldAddOperationQueryParam = getShouldAddOperationQueryParam()

  /**
   * The apollo-absinthe-upload-link createLint is basically a
   * wrapper component that allows the files to work with the elixir internals.
   *
   * We opted by using this Link only for mutations with files because they
   * are not updated as often as the "apollo-link-http" is and we are isolating
   * this logic to a couple of mutations
   */
  const fileConnection = createLink({
    uri: config['graphql_api_domain'],
    credentials: 'same-origin',
    fetch: multiAuthFetcher,
  })

  const sketchQLConnection = createHttpLink({
    uri: ({ operationName }) =>
      shouldAddOperationQueryParam
        ? `${config['graphql_api_domain']}?op=${operationName}`
        : config['graphql_api_domain'],
    credentials: 'same-origin',
    fetch: multiAuthFetcher,
  })

  const paymentQLConnection = createHttpLink({
    uri: config['paymentql_api_domain'],
    credentials: 'same-origin',
    fetch: fetcher,
  })

  const capacitorQLConnection = createHttpLink({
    uri: ({ operationName }) =>
      shouldAddOperationQueryParam
        ? `${config['capacitor_api_domain']}?op=${operationName}`
        : config['capacitor_api_domain'],
    credentials: 'same-origin',
    fetch: fetcher,
  })

  const socket = createSocketLink(getClient, cache)

  return new ApolloLink((operation, forward) => {
    if (isOperationSubscription(operation)) {
      return socket.request(operation, forward)
    }

    if (isOperationWithFiles(operation)) {
      return fileConnection.request(operation, forward)
    }

    if (isPaymentRequest(operation)) {
      return paymentQLConnection.request(operation, forward)
    }

    if (isCapacitorRequest(operation)) {
      return capacitorQLConnection.request(operation, forward)
    }

    return sketchQLConnection.request(operation, forward)
  })
}
