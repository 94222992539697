import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "clock-rewind-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd", strokeLinecap: "round", stroke: "currentColor", strokeWidth: 1.5 },
        React.createElement("path", { d: "M18.188 5.806a6.43 6.43 0 011.127 1.525c.61 1.14.935 2.484.935 4.332v.674c0 1.848-.325 3.191-.935 4.332a6.36 6.36 0 01-2.646 2.646c-1.14.61-2.484.935-4.332.935h-.674c-1.848 0-3.191-.325-4.332-.935a6.36 6.36 0 01-2.646-2.646c-.61-1.14-.935-2.484-.935-4.332v-.674c0-1.848.325-3.191.935-4.332a6.36 6.36 0 012.646-2.646c1.14-.61 2.484-.935 4.332-.935h.674", strokeLinejoin: "round" }),
        React.createElement("path", { strokeLinejoin: "round", d: "M17.25 8.25v-3h3" }),
        React.createElement("path", { d: "M14 14l-1.485-1.485a1.286 1.286 0 01-.338-1.221L13 8h0" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
