import React from 'react'
import { isMac } from '@sketch/utils'

import { TooltipShortcut } from '../../Tooltip'

const META_KEY = isMac() ? '⌘' : 'Ctrl'
const OPTION_KEY = isMac() ? '⌥' : 'Alt'

export const ParagraphTooltip = () => (
  <>
    Paragraph
    <TooltipShortcut>
      {META_KEY} {OPTION_KEY} 0
    </TooltipShortcut>
  </>
)

export const TitleTooltip = () => (
  <>
    Title
    <TooltipShortcut>
      {META_KEY} {OPTION_KEY} 1
    </TooltipShortcut>
  </>
)

export const HeadingTooltip = () => (
  <>
    Heading
    <TooltipShortcut>
      {META_KEY} {OPTION_KEY} 2
    </TooltipShortcut>
  </>
)

export const SubheadingTooltip = () => (
  <>
    Subheading
    <TooltipShortcut>
      {META_KEY} {OPTION_KEY} 3
    </TooltipShortcut>
  </>
)

export const BulletListTooltip = () => (
  <>
    Bullet list
    <TooltipShortcut>{META_KEY} ⇧ 8</TooltipShortcut>
  </>
)

export const NumberedListTooltip = () => (
  <>
    Numbered list
    <TooltipShortcut>{META_KEY} ⇧ 7</TooltipShortcut>
  </>
)

export const BoldTooltip = () => (
  <>
    Bold
    <TooltipShortcut>{META_KEY} B</TooltipShortcut>
  </>
)

export const ItalicsTooltip = () => (
  <>
    Italics
    <TooltipShortcut>{META_KEY} I</TooltipShortcut>
  </>
)

export const UnderlineTooltip = () => (
  <>
    Underline
    <TooltipShortcut>{META_KEY} U</TooltipShortcut>
  </>
)

export const DividerTooltip = () => <>Divider</>

export const LinkTooltip = () => (
  <>
    Link
    <TooltipShortcut>{META_KEY} K</TooltipShortcut>
  </>
)
