import styled from 'styled-components'
import { breakpoint } from '@sketch/global-styles'
import { FlatButton, Text } from '@sketch/components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-top: 48px;

  font-weight: ${({ theme }) => theme.fontWeights.medium};
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  margin-top: 16px;

  ${breakpoint('sm')`{
    flex-direction: row;
    justify-content: space-between;
  }`}
`

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 0.8125rem;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`

export const Title = styled(FlatButton).attrs({
  variant: 'primary',
  intent: 'primary',
})`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  text-align: left;
  padding: 0;
`

export const Description = styled(Text.Span).attrs({
  textStyle: 'copy.tertiary.standard.D',
})`
  margin-top: 8px;

  ${breakpoint('sm')`{
    margin-top: 0;
  }`}
`

// This component adds different kind of margins between 2 sibling options
// based on the fact whether the option is closed or open.
// If both options are closed - we are adding small 8px gap
// if one of the options (or both) are open - a 48px gap
export const Wrapper = styled.div`
  &.open + &.closed,
  &.open + &.open,
  &.closed + &.open {
    margin-top: 48px;
  }

  &.closed + &.closed {
    margin-top: 8px;
  }
`
