import React from 'react'
import styled from 'styled-components'

import invert from 'lodash.invert'

// Card Images
import amexCardLogo from '@sketch/icons/payments/amex.svg'
import dinersClubCardLogo from '@sketch/icons/payments/diners-club.svg'
import jcbCardLogo from '@sketch/icons/payments/jcb.svg'
import mastercardLogo from '@sketch/icons/payments/mastercard.svg'
import unknownCardLogo from '@sketch/icons/payments/unknown.svg'
import unionPayCardLogo from '@sketch/icons/payments/union-pay.svg'
import visaCardLogo from '@sketch/icons/payments/visa.svg'
import creditLogo from '@sketch/icons/payments/credit.svg'
import invoiceLogo from '@sketch/icons/payments/invoice.svg'

const cardBrandToLogo = {
  amex: amexCardLogo,
  diners: dinersClubCardLogo,
  jcb: jcbCardLogo,
  mastercard: mastercardLogo,
  unionpay: unionPayCardLogo,
  unknown: unknownCardLogo,
  visa: visaCardLogo,
  credit: creditLogo,
  invoice: invoiceLogo,
  AMERICAN_EXPRESS: amexCardLogo,
  DINERS_CLUB: dinersClubCardLogo,
  DISCOVER: unknownCardLogo,
  JCB: jcbCardLogo,
  MASTERCARD: mastercardLogo,
  UNION_PAY: unionPayCardLogo,
  VISA: visaCardLogo,
  UNKNOWN: unknownCardLogo,
} as const

const brandNameToCardSlug = {
  'American Express': 'amex',
  'Diners Club': 'diners',
  JCB: 'jcb',
  MasterCard: 'mastercard',
  UnionPay: 'unionpay',
  Visa: 'visa',
  Credit: 'credit',
  Invoice: 'invoice',
} as const

export type CreditCardBrand =
  | keyof typeof cardBrandToLogo
  | keyof typeof brandNameToCardSlug
  | string

const cardSlugToBrandName = invert(brandNameToCardSlug)

const CardLogo = styled.img`
  width: 28px;
  height: 20px;
`

export interface PaymentLogoProps {
  className?: string
  brand?: CreditCardBrand
}

/**
 * PaymentLogo
 *
 * This component presents the credit card brand logo according to the brand prop.
 * The brand prop supports two formats:
 * - Slug (Used in the Stripe card number input)
 * - Name (Used in the Team billing resume)
 *
 * @param {Object} props - Component Props.
 * @param {string} props.className - Style string class.
 * @param {string} [props.brand] - Credit card brand.
 *
 * @example
 * // Example with brand slug
 * <PaymentLogo brand="amex" />
 *
 * // Example with brand name
 * <PaymentLogo brand="American Express" />
 */
export const PaymentLogo: React.FC<PaymentLogoProps> = ({
  className,
  brand,
}) => {
  const brandName = brand ? `${cardSlugToBrandName[brand] || brand}` : 'unknown'
  const altText = `${brandName} card logo`

  const brandLogo =
    cardBrandToLogo[brand as keyof typeof cardBrandToLogo] ||
    cardBrandToLogo[
      brandNameToCardSlug[brand as keyof typeof brandNameToCardSlug]
    ] ||
    unknownCardLogo

  return (
    <CardLogo
      alt={altText}
      className={className}
      src={brandLogo}
      title={brandName}
    />
  )
}
