import React, { useContext, useRef, useState, useEffect } from 'react'

import { ConfirmationDialog, useModalContext } from '@sketch/components'
import { useStableHandler, stripMarkdownParser } from '@sketch/utils'

type ActiveComment = string | null
type CommentMediator = () => Promise<void>

interface ContextProps {
  activeCommentIdentifier: ActiveComment
  requestEditingComment: (commentIdentifier: string) => Promise<void>
  registerEditingComment: (getCommentCallback: CommentMediator) => void
  dismissEditing: () => void
}

const Context = React.createContext<ContextProps>({
  activeCommentIdentifier: null,
  requestEditingComment: () => Promise.resolve(),
  registerEditingComment: () => {},
  dismissEditing: () => {},
})

/**
 * CommentEditingProvider
 *
 * Simple Context that saves the active comment handler in case we need
 * to show the Pending changes modal
 */
export const CommentEditingProvider: React.FC = ({ children }) => {
  const [
    activeCommentIdentifier,
    setActiveCommentIdentifier,
  ] = useState<ActiveComment>(null)

  const editingCommentCallback = useRef<CommentMediator | null>()

  const requestEditingComment = async (newEditingComment: string) => {
    if (activeCommentIdentifier) {
      try {
        await editingCommentCallback.current?.()
        setActiveCommentIdentifier(newEditingComment)
      } catch {
        // nothing to do
      }
    } else {
      setActiveCommentIdentifier(newEditingComment)
    }
  }

  const dismissEditing = () => {
    setActiveCommentIdentifier(null)
    editingCommentCallback.current = null
  }

  const registerEditingComment = (getCommentCallback: CommentMediator) => {
    editingCommentCallback.current = getCommentCallback
  }

  return (
    <Context.Provider
      value={{
        activeCommentIdentifier,
        requestEditingComment,
        registerEditingComment,
        dismissEditing,
      }}
    >
      {children}
    </Context.Provider>
  )
}

interface ActiveEditingCommentProps {
  value: string
  isDirty: boolean
  onCloseEditMode: () => void
  onFocusComment: () => void
}

/**
 * ActiveEditingComment
 *
 * This component will be responsible by flagging
 * that a given Comment is being edited.
 *
 * It uses the props "isDirty" to make sure that the input has
 * been changed, otherwise it wouldn't make sense to show a modal
 * for an un-edited comment.
 *
 * "onFocusComment" and "onCloseEditMode" are aux methods to allow ease the UI interaction
 * and "value" will used to represent the pending changed edit on the modal
 */
export const ActiveEditingComment = (props: ActiveEditingCommentProps) => {
  const { value, isDirty, onCloseEditMode, onFocusComment } = props

  const { registerEditingComment } = useContext(Context)
  const { showModal, hideModal } = useModalContext()

  const commentEditorMediator = useStableHandler(() => {
    /* The method will create a promise that will mediate the comment behaviour
     * according to the isDirty value.
     *
     * If not dirty -> it will follow as resolved
     *
     * If the promise is resolved -> the initial editing comment should be closed
     * allowing the new one to take editing
     *
     * If the promise is rejected -> the initial comment will be "refocused" and
     * the edit request will be canceled
     */
    return new Promise<void>((resolve, reject) => {
      const discardChanged = () => {
        onCloseEditMode()
        resolve()
      }

      const continueEditing = () => {
        reject()
        onFocusComment()
      }

      if (!isDirty) {
        discardChanged()
        return
      }

      const markdownStrippedValue = stripMarkdownParser(value)

      showModal(ConfirmationDialog, {
        title: 'Discard changes?',
        children: (
          <p>
            You have the following unsaved changes on another comment: &quot;
            {typeof markdownStrippedValue === 'string'
              ? markdownStrippedValue.trim()
              : markdownStrippedValue}
            &quot;
          </p>
        ),
        confirmButton: {
          text: 'Discard Changes',
        },
        cancelButton: {
          text: 'Continue Editing',
        },
        onConfirm: () => {
          hideModal()
          discardChanged()
        },
        onHide: () => {
          hideModal()
          continueEditing()
        },
      })
    })
  }) as CommentMediator

  useEffect(() => {
    registerEditingComment(commentEditorMediator)
  }, [commentEditorMediator, registerEditingComment])

  return null
}

export const useMultipleCommentContext = () => useContext(Context)
