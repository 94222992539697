import { useMemo, useRef } from 'react'
import { useZoom, useIsCameraMoving } from '@sketch-hq/sketch-web-renderer'

/**
 * Equivalent of useZoom() but optimized for performance when the camera is moving.
 * It's preferable to use this hook over useZoom() (from web-renderer package) in most cases.
 *
 * Internally this hook locks the zoom factor while the camera is moving to avoid propagating
 * all the changes of zoom which is then used to calculate different things
 * and can be expensive in terms of performance.
 */
export function usePerformanceOptimizedZoom() {
  const isCameraMoving = useIsCameraMoving()
  const zoomFactor = useZoom() ?? 1

  const lastZoomFactorValueRef = useRef(zoomFactor)

  return useMemo(() => {
    const lastKnownValue = lastZoomFactorValueRef.current
    if (isCameraMoving) {
      return lastKnownValue
    }

    // Update once the camera is not moving anymore
    lastZoomFactorValueRef.current = zoomFactor

    return zoomFactor
  }, [isCameraMoving, zoomFactor])
}
