import React from 'react'
import { WorkspaceNoticeComponentProps } from '../types'
import { useGetBillingForWorkspaceNoticeQuery } from '@sketch/gql-types'

import { ReactComponent as GraduateCapIcon } from '@sketch/icons/graduate-cap-16'
import { ReactComponent as ExclamationMarkIcon } from '@sketch/icons/exclamation-mark-triangle-monochrome-16'

import { Text, Flex, Button, Link } from '@sketch/components'
import { routes } from '@sketch/modules-common'
import * as S from './WorkspaceSidebarNoticeEducationCancel.styles'

import { WorkspaceSidebarNoticeContainer } from '../WorkspaceSidebarNoticeContainer'
import { SKETCH_WEBSITE } from '@sketch/env-config'

type WorkspaceSidebarNoticeCanceledSubscriptionProps = WorkspaceNoticeComponentProps

const WorkspaceSidebarNoticeEducationCancel = ({
  workspace,
}: WorkspaceSidebarNoticeCanceledSubscriptionProps) => {
  const billingQuery = useGetBillingForWorkspaceNoticeQuery({
    variables: { identifier: workspace.identifier },
  })

  const billing = billingQuery.data?.workspace.customer?.billing

  if (billingQuery.loading || billingQuery.error || !billing) {
    return null
  }

  const expiresIn30Days = Math.abs(
    new Date(billing.subscriptionEnd!).getTime() - new Date().getTime()
  )

  const daysLeft = Math.floor(expiresIn30Days / (1000 * 3600 * 24))

  const headlineText =
    billing.status === 'ACTIVE'
      ? `${daysLeft} days left of your free Education Workspace`
      : 'Your free Education Workspace has expired'

  return (
    <WorkspaceSidebarNoticeContainer>
      <S.ContentLayout>
        <S.NoticeIcon
          as={
            billing.status === 'ACTIVE' ? GraduateCapIcon : ExclamationMarkIcon
          }
        />
        <Flex flexDirection="column">
          <Text fontWeight={600}>{headlineText}</Text>
          <Text mt={1}>Ask for an extension or subscribe to keep going!</Text>
          <Flex flexDirection="row" alignItems="center" mt={2}>
            <Button
              variant="primary"
              size="24"
              onClick={() => window.open(`${SKETCH_WEBSITE}/store/education`)}
              external
            >
              Extend...
            </Button>
            <Link
              variant="tertiary"
              to={routes.WORKSPACE_SUBSCRIBE.create({
                workspaceId: workspace.identifier,
              })}
              isUnderlined
            >
              Subscribe...
            </Link>
          </Flex>
        </Flex>
      </S.ContentLayout>
    </WorkspaceSidebarNoticeContainer>
  )
}

export { WorkspaceSidebarNoticeEducationCancel }
