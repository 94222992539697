import React, { useState } from 'react'

import { ConfirmationDialog, ModalInjectedProps } from '@sketch/components'
import { useToast } from '@sketch/toasts'

import { useDeleteSharePermanentlyMutation } from '@sketch/gql-types'
import { handleShareDeletedPermanently } from '../../../shares/operations/handlers'

export interface DeleteSharePermanentlyModalProps extends ModalInjectedProps {
  name: string
  shareIdentifier: string
  workspaceId: string
}

export const DeleteSharePermanentlyModal: React.FC<DeleteSharePermanentlyModalProps> = props => {
  const { name, hideModal, shareIdentifier, workspaceId, isModalOpen } = props

  const { showToast } = useToast()
  const [success, setSuccess] = useState(false)

  const [deletePermanently, { loading }] = useDeleteSharePermanentlyMutation({
    variables: { workspaceId: workspaceId, shareIdentifier },
    update: cache => {
      handleShareDeletedPermanently({ cache, identifier: shareIdentifier })
    },
    onCompleted: () => {
      setSuccess(true)
      showToast(`"${name}" has been permanently deleted`)
      hideModal()
    },
    onError: 'show-toast',
  })

  return (
    <ConfirmationDialog
      title="Are you sure you want to permanently delete this item?"
      isModalOpen={isModalOpen}
      hideModal={hideModal}
      cancelButton={{
        disabled: loading,
      }}
      confirmButton={{
        text: 'Delete Permanently',
        variant: 'negative',
        loading,
        disabled: loading || success,
      }}
      onConfirm={deletePermanently}
    >
      <p>
        Deleting “<strong>{name}</strong>” permanently will also delete its
        activity and history across the Workspace. You can’t undo this action.
      </p>
    </ConfirmationDialog>
  )
}
