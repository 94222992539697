import styled from 'styled-components'

import { Button, Text, Input } from '@sketch/components'

export const FieldsWrapper = styled.div`
  display: flex;
`

export const StyledInput = styled(Input)`
  height: 40px;
  font-size: ${({ theme }) => theme.fontSizes.D};
`

export const StyledButton = styled(Button)`
  margin-left: 8px;
`

export const AppliedDiscounts = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
`

export const ErrorMessage = styled(Text).attrs({
  textStyle: 'copy.negative.standard.C',
})`
  /* Needed because of modal internal styling */
  /* https://github.com/sketch-hq/cloud-frontend/blob/39270c24d5f558c765d111943d2767986587347e/src/components/Modal/Modal.tsx#L78-L81 */
  && {
    margin: 4px 0 0 0;
    padding: 0;
  }
`

export const ValidationError = styled(Text).attrs({
  textStyle: 'copy.negative.standard.D',
})``
