import styled, { css } from 'styled-components'
import { breakpoint } from '@sketch/global-styles'
import { ButtonUnstyled } from '@sketch/components'

import BaseGridPublicationItem, {
  GridPublicationItemPlaceholder as BaseGridPublicationItemPlaceholder,
} from '../GridPublicationItem'

export const GridPublicationItem = styled(BaseGridPublicationItem)`
  min-width: 252px;
  max-width: 25%;
  flex: 1;

  & + & {
    margin-left: 40px;
  }
`

export const GridPublicationItemPlaceholder = styled(
  BaseGridPublicationItemPlaceholder
)`
  min-width: 252px;
  flex: 1;

  & + & {
    margin-left: 40px;
  }
`

export const SliderWrapper = styled.ul`
  display: flex;
  position: relative;
  list-style: none;
  margin: 0 -40px;
  padding: 0;

  ${breakpoint('base', 'sm')`
    margin: 0 -24px;
  `};

  ::after,
  ::before {
    ${breakpoint('base', 'sm')`
      width: 24px;
    `}

    width: 40px;

    content: '';
    display: block;
    flex-shrink: 0;
  }
`

export const ScrollbarWrapper = styled.div`
  margin: 0 -40px;
  padding: 32px 40px 0; /* stylelint-disable-line scales/space */
  -webkit-overflow-scrolling: touch;

  overflow-x: auto;

  /* We hide the browser scrollbars because we have a fake one */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  ${breakpoint('base', 'sm')`
    margin: 0 -24px;
    padding: 8px 24px 0;
  `};
`

export const ScrollbarTrackWrapper = styled.div`
  display: block;
  position: sticky;
  left: 0;
  top: 0;
`

export const ScrollbarTrack = styled(ButtonUnstyled)<{ $pressed: boolean }>`
  padding: 15px 0; /* stylelint-disable-line scales/space */
  transition: transform 0.01s linear;

  cursor: default;

  ::after {
    content: '';
    display: block;
    height: 10px;
    background-color: ${({ theme }) => theme.colors.background.tertiary.A};
    border-radius: 10px; /* stylelint-disable-line scales/radii */
  }

  :hover {
    ::after {
      background-color: ${({ theme }) => theme.colors.background.primary.C};
    }
  }

  ${({ $pressed }) =>
    $pressed &&
    css`
      ::after {
        background-color: ${({ theme }) => theme.colors.background.primary.C};
      }
    `}
`
