import styled from 'styled-components'
import { PersonAvatar, Text } from '@sketch/components'

export const Wrapper = styled(Text.Div).attrs({
  textStyle: 'copy.quaternary.standard.C',
})`
  display: flex;
  gap: 8px;
  align-items: center;
  line-height: 1.333; /* stylelint-disable-line scales/line-height */
`

export const Copy = styled.span`
  display: inline-block;
`

export const AvatarTooltipWrapper = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 8px;
  }

  ${PersonAvatar} {
    margin-right: 8px;
  }
`

export const PersonName = styled.span`
  max-width: min(300px, 80vw);

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
