import React, { FC, ReactNode } from 'react'

import { IS_EMBEDDED } from '@sketch/constants'

type IsEmbeddedProps = {
  children: ReactNode
}

/**
 * Only renders its children when the app is in an embedded context.
 */
const IsEmbedded: FC<IsEmbeddedProps> = props =>
  IS_EMBEDDED ? <>{props.children}</> : null

export { IsEmbedded }
