import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "squares.2.dotted", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 17 17", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { id: "Web/Regular/17x17/squares.2.dotted", stroke: "currentColor", strokeWidth: 1, fill: "none", fillRule: "evenodd" },
        React.createElement("rect", { id: "Rectangle", x: 6.5, y: 6.5, width: 7, height: 7, rx: 0.75 }),
        React.createElement("path", { d: "M3.5 5v-.75a.75.75 0 01.75-.75H5", id: "Path-2" }),
        React.createElement("path", { id: "Path-3", d: "M6 3.5h2" }),
        React.createElement("path", { d: "M9 3.5h.75a.75.75 0 01.75.75V5h0", id: "Path-4" }),
        React.createElement("path", { id: "Path-5", d: "M3.5 6v2" }),
        React.createElement("path", { d: "M3.5 9v.75c0 .414.336.75.75.75H5", id: "Path-6" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
