import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "document-processing-64", viewBox: "0 0 64 64", xmlns: "http://www.w3.org/2000/svg", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("linearGradient", { x1: "50%", y1: "0%", x2: "50%", y2: "100%", id: "a" },
            React.createElement("stop", { stopOpacity: 0.2, offset: "0%" }),
            React.createElement("stop", { stopOpacity: 0, offset: "100%" }))),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M16.01.5l1.427.09A7 7 0 0124 7.576V15a1 1 0 001 1h7.451a7 7 0 016.985 6.534l.02.3.044-.431V46.5a5 5 0 01-5 5h-29a5 5 0 01-5-5v-41a5 5 0 015-5h10.51zm10.948 3.756l9.57 9.458a9.997 9.997 0 011.512 1.911L26.958 4.256z", fill: "url(#a)", transform: "translate(12 6)" }),
        React.createElement("path", { d: "M12.5 27.5v-17a4 4 0 014-4h14.549a10 10 0 017.029 2.887l10.986 10.856a8.2 8.2 0 012.436 5.832V27.5m0 9v5m0 9v3a4 4 0 01-4 4h-3m-9 0h-7m-9 0h-3a4 4 0 01-4-4v-3m0-9v-5", stroke: "currentColor", strokeWidth: 3, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M51.46 25.918a7.005 7.005 0 00-6.233-4.41l-.274-.005h-7.451a1 1 0 01-.994-.884l-.006-.116v-7.424A7.003 7.003 0 0032.03 6.55", stroke: "currentColor", strokeWidth: 3, strokeLinecap: "round", strokeLinejoin: "round" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
