import { PresentationManifest } from '@sketch-hq/sketch-web-renderer'
import { useFetchJSON } from '@sketch/utils'

export function usePresentationManifest(url?: string | null) {
  return useFetchJSON<PresentationManifest>(url, {
    validator: validateManifest,
  })
}

/**
 * Do an extremely basic smoke test on the Manifest data format.
 */
function validateManifest(manifest: unknown): manifest is PresentationManifest {
  if (
    manifest !== null &&
    typeof manifest === 'object' &&
    'contents' in manifest
  ) {
    return true
  } else {
    return false
  }
}
