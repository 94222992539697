import React from 'react'
import { NavLink } from 'react-router-dom'

import { routes } from '@sketch/modules-common'

import { Segment, Navbar } from '@sketch/components'
import { CanRenderOptions } from '../../types'

interface WorkspaceSettingsNavbarProps {
  workspaceId: string
  canRender: CanRenderOptions
}

const WorkspaceSettingsNavbar: React.FC<WorkspaceSettingsNavbarProps> = ({
  workspaceId,
  canRender,
}) => {
  return (
    <Navbar.Section ml={2} data-testid="workspace-settings-navbar">
      {canRender.generalMenu && (
        <Segment
          type="text"
          label="General"
          render={(className, children) => (
            <NavLink
              className={className}
              to={routes.WORKSPACE_SETTINGS_SETTINGS.create({
                workspaceId,
              })}
            >
              {children}
            </NavLink>
          )}
        />
      )}
      {canRender.peopleMenu && (
        <Segment
          type="text"
          label="People"
          render={(className, children) => (
            <NavLink
              className={className}
              to={routes.WORKSPACE_SETTINGS_PEOPLE.create({
                workspaceId,
              })}
            >
              {children}
            </NavLink>
          )}
        />
      )}
      {canRender.permissionGroups && (
        <Segment
          type="text"
          label="Groups"
          render={(className, children) => (
            <NavLink
              data-testid="workspace-settings-groups-link"
              className={className}
              to={routes.WORKSPACE_SETTINGS_PERMISSION_GROUPS.create({
                workspaceId,
              })}
            >
              {children}
            </NavLink>
          )}
        />
      )}
      {canRender.profileMenu && (
        <Segment
          type="text"
          label="Profile"
          render={(className, children) => (
            <NavLink
              className={className}
              to={routes.WORKSPACE_SETTINGS_PROFILE.create({
                workspaceId,
              })}
            >
              {children}
            </NavLink>
          )}
        />
      )}
      {canRender.billingMenu && (
        <Segment
          type="text"
          label="Billing"
          render={(className, children) => (
            <NavLink
              className={className}
              to={routes.WORKSPACE_SETTINGS_BILLING.create({
                workspaceId,
              })}
            >
              {children}
            </NavLink>
          )}
        />
      )}
      {canRender.SSOMenu && (
        <Segment
          type="text"
          label="Single Sign-On"
          render={(className, children) => (
            <NavLink
              className={className}
              to={routes.WORKSPACE_SETTINGS_SSO.create({
                workspaceId,
              })}
            >
              {children}
            </NavLink>
          )}
        />
      )}
    </Navbar.Section>
  )
}

export default WorkspaceSettingsNavbar
