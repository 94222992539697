import React from 'react'

import { SsoSamlConfiguration } from '../SsoSamlConfiguration'
import { SsoAttributeMappingForm } from '../SsoAttributeMappingForm'

import { WorkspaceSsoConfigFragment } from '@sketch/gql-types'

interface SetUpSketchProps {
  customerId: string
  isSsoActive: boolean
  config: WorkspaceSsoConfigFragment
}

/**
 * Renders the contents of the "Set Up Sketch" panel
 * on the Single Sign-On setup flow
 */
const SetUpSketch: React.FC<SetUpSketchProps> = ({
  customerId,
  config,
  isSsoActive,
}) => {
  return (
    <>
      <SsoSamlConfiguration
        customerId={customerId}
        identityProviderConfig={config?.config ?? undefined}
      />
      <SsoAttributeMappingForm
        customerId={customerId}
        attributeMapping={config?.attributeMapping ?? undefined}
        isSsoActive={isSsoActive}
      />
    </>
  )
}

export default SetUpSketch
