import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "design-system-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "design-system-16_svg__a" },
            React.createElement("path", { d: "M2.005 2.095L2 2a1 1 0 001.267.964 5.5 5.5 0 006.852 8.567 1 1 0 00.57 1.42A7 7 0 011.803 2.31l.202-.216zM11 11c-.092 0-.182.013-.267.036a5.5 5.5 0 00-6.852-8.567 1 1 0 00-.57-1.42 7 7 0 018.685 10.854l.001.046-.004-.066A1 1 0 0011 11z" })),
        React.createElement("clipPath", { id: "design-system-16_svg__b" },
            React.createElement("path", { d: "M2 0a2 2 0 110 4 2 2 0 010-4z" })),
        React.createElement("clipPath", { id: "design-system-16_svg__c" },
            React.createElement("path", { d: "M3.5 0a3.5 3.5 0 110 7 3.5 3.5 0 010-7z" }))),
    React.createElement("g", { clipPath: "url(#design-system-16_svg__a)", transform: "translate(1 1)" },
        React.createElement("path", { d: "M0 0h14v14H0V0z", fill: "currentColor" })),
    React.createElement("g", { clipPath: "url(#design-system-16_svg__b)", transform: "translate(2 1)" },
        React.createElement("path", { d: "M2 4a2 2 0 100-4 2 2 0 000 4z", stroke: "currentColor", strokeWidth: 3, fill: "none", strokeLinejoin: "bevel" })),
    React.createElement("g", { clipPath: "url(#design-system-16_svg__b)", transform: "translate(10 11)" },
        React.createElement("path", { d: "M2 4a2 2 0 100-4 2 2 0 000 4z", stroke: "currentColor", strokeWidth: 3, fill: "none", strokeLinejoin: "bevel" })),
    React.createElement("g", { clipPath: "url(#design-system-16_svg__c)", transform: "translate(4.5 4.5)" },
        React.createElement("path", { d: "M3.5 7a3.5 3.5 0 100-7 3.5 3.5 0 000 7z", stroke: "currentColor", strokeWidth: 3, fill: "none", strokeLinejoin: "bevel" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
