import deburr from 'lodash.deburr'
import { SuggestionDataItem } from 'react-mentions'
import { emojiAliases } from './emojiAliases'

const DEFAULT_EMOJI = [
  { id: '+1', display: '👍' },
  { id: '-1', display: '👎' },
  { id: 'white_check_mark', display: '✅' },
  { id: '100', display: '💯' },
]

export const strippedString = (string: string) => {
  return deburr((string || '').toLowerCase())
}

const emoji = Object.entries(emojiAliases)
  .sort(([a], [b]) => a.length - b.length)
  .map(a => ({ id: a[0], display: a[1] }))

const prefixComparatorFor = (query: string) => (
  { id: firstId }: SuggestionDataItem,
  { id: secondId }: SuggestionDataItem
) => {
  const firstStartsWith = `${firstId}`.startsWith(query)
  const secondStartsWith = `${secondId}`.startsWith(query)

  if (firstStartsWith && secondStartsWith) {
    return 0
  } else if (firstStartsWith) {
    return -1
  }

  return 1
}

const idIncludes = (query: string) => ({ id }: SuggestionDataItem) => {
  return `${id}`.includes(query)
}

const namePrefixComparatorFor = (query: string) => (
  { display: firstDisplay }: SuggestionDataItem,
  { display: secondDisplay }: SuggestionDataItem
) => {
  const firstStartsWith = `${firstDisplay}`.toLowerCase().startsWith(query)
  const secondStartsWith = `${secondDisplay}`.toLowerCase().startsWith(query)

  if (firstStartsWith && secondStartsWith) {
    return 0
  } else if (firstStartsWith) {
    return -1
  }

  return 1
}

const nameIncludes = (query: string) => ({ display }: SuggestionDataItem) => {
  const strippedQuery = strippedString(query)
  const strippedName = strippedString(`${display}`)
  return strippedName.includes(strippedQuery)
}

export const getUsersListBySearch = <T extends SuggestionDataItem>(
  users: T[],
  query: string
) =>
  users
    .filter(nameIncludes(query))
    .sort(namePrefixComparatorFor(query))
    .slice(0, 5)

export const getEmojisListBySearch = (query: string) => {
  if (query.length === 0) {
    return DEFAULT_EMOJI
  }

  return emoji
    .filter(idIncludes(query))
    .sort(prefixComparatorFor(query))
    .slice(0, 5)
}
