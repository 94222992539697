import React from 'react'
import * as yup from 'yup'
import { Formik, FormikHelpers } from 'formik'

import {
  Button,
  Form,
  Modal,
  PasswordInput,
  CreatePasswordInput,
  passwordStrengthValidation,
  ModalInjectedProps,
} from '@sketch/components'

import { useToast } from '@sketch/toasts'

import { useUserPasswordUpdateMutation } from '@sketch/gql-types'
import { ErrorHandler } from '@sketch/tracing'
import { StyledForm } from './ChangePasswordModal.styles'
import { useSignOut } from '@sketch/modules-common'

const SCHEMA = yup.object().shape({
  password: yup.string().required('Current Password can’t be blank'),
  newPassword: yup
    .string()
    .min(8, 'Password is too short (minimum is 8 characters)')
    .max(72, 'Password is too long (maximum is 72 characters')
    .required('New Password can’t be blank')
    .test(
      'passwordStrength',
      'The password strength should be at least Okay',
      passwordStrengthValidation
    ),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), undefined], 'Passwords don’t match')
    .required('Confirm New Password can’t be blank'),
})

interface FormValues {
  password: string
  newPassword: string
  confirmNewPassword: string
}

type FormActions = FormikHelpers<FormValues>

interface ChangePasswordModalProps extends ModalInjectedProps {}

export const ChangePasswordModal = ({
  hideModal,
}: ChangePasswordModalProps) => {
  const signOut = useSignOut({
    revokeSessions: 'none',
    reason: 'Changing password',
  })
  const { showToast } = useToast()

  const [updatePassword] = useUserPasswordUpdateMutation({
    redirectErrors: true,
    onCompleted: data => {
      if (!data || !data.userPasswordUpdate) {
        return ErrorHandler.shouldNeverHappen('Could not update password')
      }

      signOut()
      showToast('Your password has been updated. Please sign in')
      hideModal()
    },
    onError: error => {
      showToast(error.message, 'negative')
    },
  })

  const handleSubmit = async (values: FormValues, actions: FormActions) => {
    await updatePassword({
      variables: {
        currentPassword: values.password,
        newPassword: values.newPassword,
      },
    })

    actions.setSubmitting(false)
  }

  return (
    <Modal title="Change Your Password" onCancel={hideModal}>
      <Formik
        initialValues={{
          password: '',
          newPassword: '',
          confirmNewPassword: '',
        }}
        validationSchema={SCHEMA}
        onReset={hideModal}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          handleReset,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
          dirty,
        }) => (
          <>
            <Modal.Body>
              <StyledForm id="change-password-form">
                <Form.Field
                  name="password"
                  label="Password"
                  errorText={touched.password ? errors.password : undefined}
                >
                  <PasswordInput
                    name="password"
                    aria-label="Password"
                    value={values.password}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Field>
                <CreatePasswordInput
                  name="newPassword"
                  label="New Password"
                  data-testid="new-password-input"
                  aria-label="New Password"
                  value={values.newPassword}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Form.Field
                  name="confirmNewPassword"
                  label="Confirm New Password"
                  errorText={
                    touched.confirmNewPassword
                      ? errors.confirmNewPassword
                      : undefined
                  }
                >
                  <PasswordInput
                    name="confirmNewPassword"
                    aria-label="Confirm New Password"
                    value={values.confirmNewPassword}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Field>
              </StyledForm>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="reset"
                variant="secondary"
                disabled={isSubmitting}
                onClick={handleReset}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                loading={isSubmitting}
                disabled={isSubmitting || !isValid || !dirty}
                form="change-password-form"
              >
                Change Password
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  )
}
