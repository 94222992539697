import { useDeleteCollectionMutation } from '@sketch/gql-types'
import { useEventDispatch } from '@sketch/utils'
import {
  invalidateProjectCollections,
  invalidateProjectShares,
  removeCollectionFromProject,
} from './utils/collection.cache'

interface UseDeleteCollectionProps {
  workspaceIdentifier: string
  projectIdentifier: string
  onCompleted?: () => void
}

export const useDeleteCollection = ({
  workspaceIdentifier,
  projectIdentifier,
  onCompleted,
}: UseDeleteCollectionProps) => {
  const dispatchSharesRefresh = useEventDispatch('workspaceShareRefresh')
  const [mutation] = useDeleteCollectionMutation({
    onError: 'show-toast',
    onCompleted,
    update: (cache, { data }) => {
      if (!data) {
        return
      }

      const collectionIdentifier = data.deleteCollection.collection.identifier

      removeCollectionFromProject({
        cache,
        projectIdentifier,
        collectionIdentifier,
      })

      // Invalidate any cached `project.shares` and `project.collections` records so that they are
      // refetched from the GraphQL API
      invalidateProjectShares({ cache, projectIdentifier })
      invalidateProjectCollections({ cache, projectIdentifier })

      // Force a refresh of the project view if the user is currently viewing
      // it. Since the user can delete a collection from the project view, we
      // must force a refresh so that the list of shares is refetched after the
      // cache invalidations
      dispatchSharesRefresh({
        workspaceIdentifier,
        projectIdentifier,
      })
    },
  })

  return mutation
}
