import styled, { css } from 'styled-components'
import { ReactComponent as MagGlassSvg } from '@sketch/icons/magnifying-glass-24'
import { ReactComponent as CrossSvg } from '@sketch/icons/cross-circle-18'
import { breakpoint } from '@sketch/global-styles'
import { ButtonUnstyled } from '../Button'
import { Input } from '../Input'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;

  /* For mobile views we want the search to appear first */
  ${breakpoint('base', 'sm')`
    flex-direction: row-reverse;
    align-items: center;
  `}
`

export const iconStyles = css`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.foreground.secondary.B};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  &:active {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
  }
`

export const LoupeIconContainer = styled(ButtonUnstyled)`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  z-index: 1;

  width: 24px;
  height: 24px;

  margin: auto;
`

export const LoupeIcon = styled(MagGlassSvg).attrs({
  role: 'img',
  'aria-label': 'Search',
})(
  ({ theme }) => css`
    width: 24px;
    height: 24px;

    transition: all
      ${`${theme.transitions.duration[2]} ${theme.transitions.timing.easeInOut}`};

    ${iconStyles};

    color: ${({ theme }) => theme.colors.foreground.secondary.D};
  `
)

export const ClearButton = styled(ButtonUnstyled)`
  width: 24px;
  height: 24px;
  margin: 4px 8px;
`

export const CrossIcon = styled(CrossSvg).attrs({
  role: 'img',
  'aria-label': 'Clear',
})(
  ({ theme }) => css`
    width: 16px;
    height: 16px;
    margin: 0 -3px 0 8px; /* stylelint-disable-line scales/space */

    ${iconStyles}
  `
)

export const SearchInput = styled(Input)<{
  $hidden?: boolean
  $hasContent: boolean
}>(
  ({ theme, $hasContent }) => css`
    height: 40px;

    ${breakpoint('sm')`
      height: 32px;
    `}

    /* &[type='search'] is needed because inputs already styles that selector,
    so we need to overwrite them */
    &[type='search'] {
      font-size: ${theme.fontSizes.D};
      border-radius: ${theme.radii.large};
      padding-left: 40px;
      padding-right: ${$hasContent ? '40px' : '0px'};
      background: ${theme.colors.background.tertiary.C};

      ::-webkit-search-decoration,
      ::-webkit-search-cancel-button {
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-appearance: none;
        appearance: none;
      }

      &::placeholder {
        color: ${theme.colors.state.disabled};
      }

      &:disabled {
        border: 1px dashed ${theme.colors.border.A};
        background: ${theme.colors.background.secondary.B};
      }

      opacity: 1;
      width: 100%;
    }
  `
)

export const Form = styled.form<{ width?: number }>(
  ({ theme, width }) => css`
    width: 100%;
    border-radius: ${theme.radii.large};

    transition: all
      ${`${theme.transitions.duration[2]} ${theme.transitions.timing.easeInOut}`};

    ${typeof width !== 'undefined' &&
    breakpoint('sm')`
      width: ${width}px;
    `};
  `
)

export const ClearButtonAndChildrenWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const FormContent = styled.div<{ $fullWidth?: boolean }>(
  ({ $fullWidth }) => css`
    position: relative;
    max-width: ${$fullWidth ? '100%' : '280px'};
    height: 40px;

    ${breakpoint('sm')`
      height: 32px;
      width: ${$fullWidth ? '100%' : '280px'};
    `}
  `
)
