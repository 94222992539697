import React from 'react'
import Helmet from 'react-helmet'

import { Skeleton } from '@sketch/components'

import { WorkspaceProfilePublications } from '../WorkspaceProfilePublications'

import {
  Container,
  ProfileAvatar,
  ProfileHeader,
  ProfileInfoContainer,
  PublishedDocumentsContainer,
} from './WorkspaceProfileLayout.styles'

import type {
  WorkspaceProfileResultLoading,
  WorkspaceProfileResultSuccess,
} from '../../hooks/useGetWorkspaceProfile'

interface WorkspaceProfileLayoutProps {
  workspaceId: string
  isProfileEditRoute: boolean
  profileResult: WorkspaceProfileResultSuccess | WorkspaceProfileResultLoading
  profileInfo: React.ReactNode
}

export const WorkspaceProfileLayout = (props: WorkspaceProfileLayoutProps) => {
  const { profileResult, isProfileEditRoute, profileInfo } = props
  const { profile, loading } = profileResult
  const workspaceName = profile?.workspace.name!
  const workspaceAvatar = profile?.workspace.avatar

  const profileContent = loading ? (
    <>
      <Skeleton width="320px" height="48px" style={{ marginBottom: 12 }} />
      <Skeleton width="470px" height="30px" style={{ marginBottom: 8 }} />
      <Skeleton width="350px" height="30px" style={{ marginBottom: 8 }} />
      <Skeleton width="350px" height="30px" />
    </>
  ) : (
    profileInfo
  )

  return (
    <>
      <Helmet>
        <title>{`${workspaceName} on the Sketch Community Canvas`}</title>
        <meta
          property="og:title"
          content={`${workspaceName} on the Sketch Community Canvas`}
        />
        <meta
          property="og:description"
          content={`Explore and remix ${workspaceName} freely-available resources, made with love in Sketch.`}
        />
        <meta
          property="og:image"
          content={
            workspaceAvatar?.large ?? workspaceAvatar?.small ?? undefined
          }
        />
      </Helmet>

      <Container isProfileEditRoute={isProfileEditRoute}>
        <ProfileHeader>
          <ProfileAvatar
            workspaceName={workspaceName}
            avatar={workspaceAvatar}
          />
          <ProfileInfoContainer>{profileContent}</ProfileInfoContainer>
        </ProfileHeader>

        <PublishedDocumentsContainer>
          <WorkspaceProfilePublications {...props} />
        </PublishedDocumentsContainer>
      </Container>
    </>
  )
}
