import {
  useVersionUpdateKindMutation,
  VersionUpdateKindMutation,
  VersionListItemFragment,
  VersionListItemFragmentDoc,
} from '@sketch/gql-types'
import { dataIdFromObject } from '@sketch/graphql-cache'

export type VersionKindUpdated = NonNullable<VersionUpdateKindMutation>

interface Props {
  onCompleted: (data: VersionKindUpdated) => void
  onError: (message: string) => void
}

export const useVersionUpdateKind = ({ onCompleted, onError }: Props) => {
  const [mutate, ...rest] = useVersionUpdateKindMutation({
    onCompleted: data => {
      onCompleted(data)
    },
    onError: ({ message }) => {
      onError(message)
    },
  })

  return [
    ({
      versionIdentifier,
      kind,
    }: {
      versionIdentifier: string
      kind: VersionListItemFragment['kind']
    }) =>
      mutate({
        variables: { versionIdentifier, kind },

        update: (cache, { data }) => {
          if (!data || !data.versionUpdateKind) {
            return
          }

          const cachedVersion = cache.readFragment<VersionListItemFragment>({
            fragment: VersionListItemFragmentDoc,
            fragmentName: 'VersionListItem',
            id: dataIdFromObject(data.versionUpdateKind)!,
          })

          if (!cachedVersion) {
            return
          }

          cache.writeFragment({
            fragment: VersionListItemFragmentDoc,
            fragmentName: 'VersionListItem',
            id: dataIdFromObject(data.versionUpdateKind)!,
            data: { ...cachedVersion, kind },
          })
        },
      }),
    ...rest,
  ] as const
}
