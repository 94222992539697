import React, { useEffect, useRef } from 'react'
import { InView } from 'react-intersection-observer'
import { useHistory } from 'react-router'

import { routes, useUserProfile } from '@sketch/modules-common'

import {
  Breakpoint,
  Dropdown,
  Table,
  AspectRatioGrid,
  ModalRestrictor,
  useModalContext,
  useResponsiveDropdown,
  LoadingState,
} from '@sketch/components'

import { ShareActionsDropdownTrashed } from '../ShareActionsDropdownTrashed'

import {
  ShareListItemFragment,
  TrashedProjectFragment,
  TrashedShareFragment,
  useGetDocumentListSettingsQuery,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

import { ResponsiveValues } from '@sketch/global-styles'

import {
  DocumentGridItemPlaceholder,
  DocumentTableItemPlaceholder,
  ConnectedDocumentItem,
} from '../DocumentItem'

import { ProjectItem } from '../ProjectItem'

import { ShareTrashedModal } from '../ShareTrashedModal'
import { thumbnailForPreviewFile } from '../../utils'

import {
  ActionTableCell,
  Footer,
  TableWrapper,
  FocusLink,
  HeaderTableCell,
} from '../DocumentItemsLayout/DocumentItemsLayout.styles'

import {
  DeleteProjectPermanentlyAction,
  RestoreProjectAction,
} from '../../../projects/components/ProjectDropdownActions'

import {
  DropdownButton,
  DropdownButtonEllipsis,
  DropdownWrapper,
} from '../DocumentItem/DocumentItem.styles'

// ShareListItemFragment should be removed once BE returns the newer types in the getTrash
// https://github.com/sketch-hq/Cloud/issues/11226
type Item =
  | ShareListItemFragment
  | TrashedProjectFragment
  | TrashedShareFragment
type RenderItem = Item | 'listener' | 'placeholder'

export type TrashedShare = TrashedProjectFragment['shares']['entries'][0]

interface TrashItemsLayoutProps {
  items: Item[]
  loading: boolean
  onLoadMore: () => Promise<any>
  totalItemsCount: number
  header?: React.ReactNode
  workspaceId: string
  userRole: WorkspaceMinimalFragment['userRole']
}

export const TABLE_HEADER = [
  { label: 'Name', customCell: HeaderTableCell },
  { label: 'Project', customCell: HeaderTableCell },
  { label: 'Deleted', customCell: HeaderTableCell },
  { label: 'Updated By', customCell: HeaderTableCell },
  { label: '', customCell: ActionTableCell },
]

const COLUMNS: ResponsiveValues<number> = [2, 2, 3, 3, 3, 4, 5, 6]
const GUTTER: ResponsiveValues<number> = [16, 16, 16, 16, 40, 40, 40]
const VERTICAL_SPACE: ResponsiveValues<number> = [24, 24, 24, 24, 40, 40, 40]
const PAGE_SIZE = 20

const buildPlaceholderArray = (number: number) => {
  if (number <= 0) {
    return []
  }

  if (number > PAGE_SIZE) {
    return ['listener', ...Array(PAGE_SIZE - 1).fill('placeholder')] as const
  }

  return ['listener', ...Array(number - 1).fill('placeholder')] as const
}

const ActionPopoverWrapper: React.FC = ({ children }) => <div>{children}</div>

const ResponsiveDropdown: React.FC = ({ children }) => {
  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: ActionPopoverWrapper,
    dropdownProps: {
      children,
      usePortal: true,
    },
    placement: 'bottom-end',
  })

  return (
    <DropdownWrapper onClick={event => event.stopPropagation()}>
      <DropdownButton {...buttonProps}>
        <DropdownButtonEllipsis aria-label="Document Settings" />
        <span className="sr-only">Document Settings</span>
      </DropdownButton>
      {content}
    </DropdownWrapper>
  )
}

const renderDropdown = (children: JSX.Element) => (
  <ResponsiveDropdown>{children}</ResponsiveDropdown>
)

// Auxiliary components
const GridLayout: React.FC = ({ children }) => (
  <AspectRatioGrid
    columns={COLUMNS}
    verticalRowSpace={VERTICAL_SPACE}
    gutterSize={GUTTER}
  >
    {children}
  </AspectRatioGrid>
)

const TrashItemsLayout: React.FC<TrashItemsLayoutProps> = props => {
  const {
    items,
    onLoadMore,
    loading,
    totalItemsCount,
    header,
    workspaceId,
    userRole,
  } = props

  const { showModal } = useModalContext()
  const history = useHistory()

  // Make sure the onLoadMore Reference is always the latest
  const onLoadMoreRef = useRef<() => Promise<any>>(onLoadMore)
  useEffect(() => {
    onLoadMoreRef.current = onLoadMore
  }, [onLoadMore])

  // Load the document layout
  const { data } = useGetDocumentListSettingsQuery()
  const { documentsLayout } = data || {}

  // Get the User Identifier to show the "You" label
  const { data: userData } = useUserProfile()
  const userIdentifier = userData?.me.identifier

  if (loading && items.length === 0) {
    return <LoadingState />
  }

  const placeholders = buildPlaceholderArray(
    (totalItemsCount || 0) - items.length
  )

  // Merge the items with the placeholders have them rendered together
  // This prevents the page from bumping when the placeholders are replaced
  // with actual items
  const sharesAndPlaceholders = [...items, ...placeholders]

  const onShareAction = (
    item: ShareListItemFragment | TrashedShareFragment
  ) => {
    showModal(ShareTrashedModal, {
      share: item,
    })
  }

  const canPermaDelete = userRole === 'ADMIN'

  const renderProjectDropdownMenu = (item: TrashedProjectFragment) => (
    <>
      <Dropdown.Header>Project</Dropdown.Header>
      <RestoreProjectAction project={item} workspaceId={workspaceId} />
      {canPermaDelete && (
        <>
          <Dropdown.Divider />
          <DeleteProjectPermanentlyAction
            project={item}
            workspaceId={workspaceId}
          />
        </>
      )}
    </>
  )

  const renderGridItem = (item: RenderItem, index: number) => {
    if (item === 'listener' || item === 'placeholder') {
      return (
        <DocumentGridItemPlaceholder
          key={index}
          inViewListener={
            item === 'listener' && (
              <InView onChange={inView => inView && onLoadMoreRef.current?.()}>
                <span className="sr-only">Loading Placeholder</span>
              </InView>
            )
          }
        />
      )
    }

    if (item.__typename === 'TrashedProject') {
      const shares = item.shares.entries

      const thumbnails = shares.map((item: TrashedShare) => {
        const imageSource = thumbnailForPreviewFile(
          item.version?.document?.previewFile
        )
        return { name: item.name, imageSource }
      })

      return (
        <ModalRestrictor key={item.identifier}>
          <FocusLink
            to={routes.WORKSPACE_TRASH_PROJECT.create({
              projectId: item.identifier,
              workspaceId,
            })}
            key={item.identifier}
          >
            <ProjectItem.Card
              name={item.name}
              deletedAt={item.deletedAt!}
              documentsNumber={item.shares.meta.totalCount}
              thumbnails={thumbnails}
              renderDropdownItems={() => renderProjectDropdownMenu(item)}
            />
          </FocusLink>
        </ModalRestrictor>
      )
    }

    return (
      <ConnectedDocumentItem
        key={item.identifier}
        share={item}
        renderDropdown={() =>
          renderDropdown(<ShareActionsDropdownTrashed share={item} />)
        }
        presentation="grid"
        showProjectName
        onClick={() => onShareAction(item)}
        userIdentifier={userIdentifier}
      />
    )
  }

  const renderTableItem = (item: RenderItem) => {
    if (item === 'listener' || item === 'placeholder') {
      return (
        <DocumentTableItemPlaceholder
          inViewListener={
            item === 'listener' && (
              <InView onChange={inView => inView && onLoadMoreRef.current?.()}>
                <span className="sr-only">Loading Placeholder</span>
              </InView>
            )
          }
        />
      )
    }

    if (item.__typename === 'TrashedProject') {
      return (
        <ModalRestrictor key={item.identifier}>
          <ProjectItem.ListItem
            name={item.name}
            deletedAt={item.deletedAt!}
            onClick={event => {
              const link = routes.WORKSPACE_TRASH_PROJECT.create({
                projectId: item.identifier,
                workspaceId,
              })

              if (event.metaKey) {
                /* Simulate the link opening with meta key */
                window.open(link, '_blank')
              } else {
                history.push(link)
              }
            }}
            renderDropdownItems={() => renderProjectDropdownMenu(item)}
          />
        </ModalRestrictor>
      )
    }

    return (
      <ConnectedDocumentItem
        key={item.identifier}
        share={item}
        renderDropdown={() =>
          renderDropdown(<ShareActionsDropdownTrashed share={item} />)
        }
        presentation="list"
        showProjectName
        onClick={() => onShareAction(item)}
        userIdentifier={userIdentifier}
      />
    )
  }

  const renderLayout = (isMobile: boolean) => {
    if (documentsLayout === 'LIST') {
      return (
        <TableWrapper $hideProjects={false}>
          <Table
            header={TABLE_HEADER}
            items={sharesAndPlaceholders}
            renderItemKey={item =>
              // if it's not a share the table will generate a index based key
              typeof item === 'object' ? item.identifier : undefined
            }
            renderItem={renderTableItem}
            evenColumns={!isMobile}
          />
        </TableWrapper>
      )
    }

    return <GridLayout>{sharesAndPlaceholders.map(renderGridItem)}</GridLayout>
  }

  return (
    <>
      {header}
      <Breakpoint on="sm">{matches => renderLayout(!matches)}</Breakpoint>
      <Footer />
    </>
  )
}

export default TrashItemsLayout
