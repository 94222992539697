import React from 'react'
import { useFlag } from '@sketch/modules-common'
import { ExportsProps, Exports } from './Exports'
import { Exports as ExportNext } from '../ExportsNext'

export const ExportsFFGate = (props: ExportsProps) => {
  const exportAnyLayerFF = useFlag('export-any-layer')

  return exportAnyLayerFF ? <ExportNext {...props} /> : <Exports {...props} />
}
