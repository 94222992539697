import { ColorFormat } from '../../../../../../types'
import { capitalize } from '@sketch/utils'
import copyGradient from '../../../components/GradientAttribute/copy'
import { rgbTo } from '../../../components/Color/utils'
import { Border, FillType } from '../../../../../../../inspector'

const isBlendModeVisible = (blendMode: string) =>
  blendMode.toUpperCase() !== 'NORMAL'

const borderCopyValue = (
  {
    isEnabled,
    fillType,
    gradient,
    color,
    position,
    thickness,
    appearance,
  }: Border,
  colorFormat: ColorFormat
) => {
  if (!isEnabled) {
    return null
  }

  let copyValue = ``

  if (fillType === FillType.Color && color) {
    copyValue += `Color: ${rgbTo(colorFormat, color)}`
  } else if (fillType === FillType.Gradient && gradient) {
    copyValue += copyGradient(gradient, appearance, colorFormat)
  }

  const blendMode = appearance && appearance.blendMode
  if (isBlendModeVisible(blendMode)) {
    copyValue += `\nBlend Mode: ${capitalize(blendMode)}`
  }
  if (position) {
    copyValue += `\nPosition: ${position}`
  }

  copyValue += `\nWidth: ${thickness}`

  copyValue += `\n\n`

  return copyValue
}

const copy = (
  borders: Border[],
  colorFormat: ColorFormat = ColorFormat.HEX
) => {
  let sectionCopyValue = ``
  borders.forEach((border, index) => {
    const copyValue = borderCopyValue(border, colorFormat)
    if (copyValue) {
      sectionCopyValue += `Border ${index}\n`
      sectionCopyValue += copyValue
    }
  })
  return sectionCopyValue
}

export default copy
