import React from 'react'

import {
  Wrapper,
  ThumbnailWrapper,
  NameSkeleton,
} from './ListPublicationItem.styles'
import { PublicationThumbnailSkeleton } from '../PublicationThumbnail'

export interface ListPublicationItemPlaceholderProps {
  className?: string
}

export const ListPublicationItemPlaceholder = ({
  className,
}: ListPublicationItemPlaceholderProps) => {
  return (
    <Wrapper className={className}>
      <ThumbnailWrapper>
        <PublicationThumbnailSkeleton />
      </ThumbnailWrapper>
      <NameSkeleton />
    </Wrapper>
  )
}
