import React from 'react'
import { ApolloError } from 'apollo-client'

import AnnotationListWrapper from '../AnnotationListWrapper'
import CommentSkeleton from '../CommentSkeleton'
import InlineSidebarError from '../InlineSidebarError'

const DEFAULT_PLACEHOLDER_COUNT = 3

interface AnnotationsSkeletonProps {
  error?: ApolloError
  /**
   * Number of placeholder items to show in the skeleton loader.  Defaults to 3.
   */
  placeholderCount?: number
}

const AnnotationsSkeleton: React.FC<AnnotationsSkeletonProps> = ({
  error,
  placeholderCount = DEFAULT_PLACEHOLDER_COUNT,
}) => (
  <AnnotationListWrapper $blockScroll>
    {/* Placeholder items */}
    <CommentSkeleton count={placeholderCount} />

    {/* Error message */}
    {error && <InlineSidebarError error={error} />}
  </AnnotationListWrapper>
)

export default AnnotationsSkeleton
