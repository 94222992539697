import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "graduate-cap-48", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 48 48", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M25.555 6.776l19.48 10.565a.75.75 0 010 1.318l-19.48 10.565a3 3 0 01-2.86 0L3.215 18.66a.75.75 0 010-1.318l19.48-10.565a3 3 0 012.86 0z", fill: "#C18CCB" }),
        React.createElement("g", { transform: "translate(2.2 6.25)" },
            React.createElement("path", { d: "M23.247.796l19.16 10.547a.75.75 0 010 1.314l-19.16 10.547a3 3 0 01-2.894 0L1.193 12.657a.75.75 0 010-1.314L20.354.796a3 3 0 012.894 0z", stroke: "currentColor", strokeWidth: 2, strokeLinejoin: "round" }),
            React.createElement("ellipse", { fill: "#000", cx: 21.8, cy: 11.25, rx: 2.5, ry: 1.5 })),
        React.createElement("path", { d: "M11.394 24.093l12.014-6.613-12.014 6.613z", stroke: "currentColor", strokeWidth: 2 }),
        React.createElement("path", { d: "M36 23.25v7.975a5.986 5.986 0 01-2.124 4.576C31.36 37.934 28.068 39 24 39s-7.36-1.066-9.876-3.2A5.986 5.986 0 0112 31.226V23.25h0", stroke: "currentColor", strokeWidth: 2, strokeLinejoin: "round" }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 2, d: "M12 43.75V31.375" }),
        React.createElement("path", { d: "M12 46c1.243 0 2.25-.245 2.25-1.429C14.25 43.388 13.243 41 12 41s-2.25 2.388-2.25 3.571C9.75 45.755 10.757 46 12 46z", fill: "#000" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
