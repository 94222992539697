import React from 'react'
import { Box, LoadPageSeparator } from '@sketch/components'

import { ShareMember, ShareMemberPlaceholder } from '../ShareMember'
import PermissionGroupMember from '../PermissionGroupMember'
import useShareMemberships from '../../SharingModal/useShareMemberships'

import { ShareMemberWrapper } from './ShareMemberships.styles'
import { ShareForSettingsModal } from '../types'

export type ShareForMembershipList = Pick<
  ShareForSettingsModal,
  | 'identifier'
  | 'userCanUpdateCommentsEnabled'
  | 'userCanUpdateSettings'
  | 'type'
  | 'parentAccessLevel'
  | 'isDraft'
  | 'name'
  | 'memberships'
>

interface MembershipListProps {
  share: ShareForMembershipList
  guestsOnly?: boolean
  excludeGuests?: boolean
}

const PLACEHOLDER_ITEMS = 5

const buildPlaceholderArray = (count: number) => (
  <>
    {new Array(count).fill(null).map((_, index) => (
      <ShareMemberPlaceholder key={index} />
    ))}
  </>
)

const ShareMemberships = (props: MembershipListProps) => {
  const { share, guestsOnly, excludeGuests } = props

  const {
    loading,
    error,
    members,
    guests,
    memberships,
    after,
    myMembership,
    loadNewPage,
    data,
  } = useShareMemberships(share.identifier)

  if (error) {
    return (
      <Box my={4} color="red">
        Memberships failed to load
      </Box>
    )
  }

  if (!data && loading) {
    return buildPlaceholderArray(2)
  }

  let items = memberships

  if (guestsOnly) {
    items = guests
  } else if (excludeGuests) {
    items = members
  }

  if (items.length === 0) {
    return null
  }

  return (
    <>
      {items.map(membership => {
        if (membership.__typename === 'PermissionGroupRelationShare') {
          return (
            <ShareMemberWrapper key={membership.identifier}>
              <PermissionGroupMember membership={membership} />
            </ShareMemberWrapper>
          )
        }

        return (
          <ShareMemberWrapper key={membership.identifier}>
            <ShareMember
              share={share}
              member={membership}
              isMyMembership={
                myMembership?.identifier === membership.identifier
              }
            />
          </ShareMemberWrapper>
        )
      })}

      {after && <LoadPageSeparator key={after} loadNewPage={loadNewPage} />}
      {loading && buildPlaceholderArray(PLACEHOLDER_ITEMS)}
    </>
  )
}

export default ShareMemberships
