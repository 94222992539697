import { useParams } from 'react-router-dom'
import { RouteParams } from '@sketch/modules-common'
import { useVersioning } from '../../versioning'

interface UseGetIdentifiersReady {
  shareIdentifier: string
  versionShortId: string
  versionIdentifier?: string
  documentIdentifier?: string
}

export const useGetIdentifiers = (): UseGetIdentifiersReady => {
  const { shareID } = useParams<RouteParams<'SHARE_VIEW'>>()
  const shareVersionContext = useVersioning()

  if (shareVersionContext.loading) {
    return {
      shareIdentifier: shareID,
      versionShortId: '',
      versionIdentifier: '',
      documentIdentifier: '',
    }
  }

  return {
    shareIdentifier: shareID,
    versionShortId: shareVersionContext.versionShortId,
    versionIdentifier: shareVersionContext.versionIdentifier,
    documentIdentifier:
      shareVersionContext?.currentVersion?.document?.identifier,
  }
}
