import React, { useState } from 'react'
import { ChooseTargetDuplicationWorkspaceLayout } from './ChooseTargetWorkspaceDuplicationLayout'
import { ChooseTargetWorkspaceDuplication } from './ChooseTargetWorkspaceDuplication'
import {
  DuplicationWorkspaceFragment,
  ShareInfoFragment,
  useDuplicateShareToDraftsMutation,
} from '@sketch/gql-types'

export interface ChooseTargetWorkspaceDuplicationModalProps {
  share: Pick<ShareInfoFragment, 'name' | 'identifier' | 'publicAccessLevel'>
  versionIdentifier: string
  workspaces: DuplicationWorkspaceFragment[]
  onDuplicationSuccess: (
    targetWorkspaceId: string,
    targetWorkspaceName: string
  ) => void
  onDuplicationError: () => void
  hideModal: () => {}
  onDuplicationCancel: () => void
}

export const ChooseTargetWorkspaceDuplicationModal: React.FC<ChooseTargetWorkspaceDuplicationModalProps> = props => {
  const {
    share,
    versionIdentifier,
    workspaces,
    hideModal,
    onDuplicationSuccess,
    onDuplicationError,
    onDuplicationCancel,
  } = props

  const [
    selectedWorkspace,
    setSelectedWorkspace,
  ] = useState<DuplicationWorkspaceFragment | null>(null)

  const [duplicateShare, { loading }] = useDuplicateShareToDraftsMutation({
    variables: {
      input: {
        sourceShareIdentifier: share.identifier,
        sourceVersionIdentifier: versionIdentifier,
        targetWorkspaceIdentifier: selectedWorkspace?.identifier ?? '',
      },
    },
    onCompleted(data) {
      if (!data.duplicateShareToDrafts?.share) {
        return
      }

      const { workspace } = data.duplicateShareToDrafts.share
      hideModal()
      onDuplicationSuccess(workspace.identifier, workspace.name)
    },
    onError: () => {
      hideModal()
      onDuplicationError()
    },
  })

  return (
    <ChooseTargetDuplicationWorkspaceLayout
      shareName={share.name}
      hideModal={hideModal}
      isLoading={loading}
      isEnabled={Boolean(selectedWorkspace)}
      onActionClick={duplicateShare}
      onDuplicationCancel={onDuplicationCancel}
    >
      <ChooseTargetWorkspaceDuplication
        loading={loading}
        workspaces={workspaces}
        onWorkspaceSelect={workspace => setSelectedWorkspace(workspace)}
      />
    </ChooseTargetDuplicationWorkspaceLayout>
  )
}
