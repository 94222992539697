interface Member {
  user?: {
    name?: string | null
    email?: string | null
  } | null
  invite?: {
    email?: string | null
  } | null
}

export const getMemberName = (member: Member) =>
  member?.user?.name || member?.invite?.email

export const getMemberEmail = (member: Member) =>
  member?.user?.email || member?.invite?.email
