import React from 'react'
import { Button, useModalContext } from '@sketch/components'
import {
  Container,
  ButtonWrapper,
  StyledBody,
  LearnMoreButton,
  StyledIcon,
  StyledHeading,
} from './WorkspaceSettingsUpsell.styles'
import { SKETCH_WEBSITE } from '@sketch/env-config'
import { ReactComponent as ShieldIcon } from '@sketch/icons/shield-48'
import { WorkspaceSettingsUpsellModal } from './WorkspaceSettingsUpsellModal'

const WorkspaceSettingsSsoUpsell = () => {
  const { showModal } = useModalContext()

  return (
    <Container>
      <div>
        <StyledIcon as={ShieldIcon} />
      </div>
      <div>
        <StyledHeading>
          Upgrade Sketch to Scale with Your Business
        </StyledHeading>
        <StyledBody>
          With our Business Plan you gain Single Sign-On (SSO), a powerful
          permissions directory - and many more features that give you greater
          visibility and control over the Workspace.
        </StyledBody>
        <ButtonWrapper>
          <Button
            variant="primary"
            onClick={() => window.open(`${SKETCH_WEBSITE}/support/contact/`)}
          >
            Contact Us
          </Button>
          <LearnMoreButton
            onClick={() => showModal(WorkspaceSettingsUpsellModal)}
          >
            Learn More
          </LearnMoreButton>
        </ButtonWrapper>
      </div>
    </Container>
  )
}

export { WorkspaceSettingsSsoUpsell }
