import React, { useState } from 'react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'

import { MAX_SIZE } from '@sketch/utils'

import { WorkspaceLogo } from '@sketch/components'

import {
  PreviewContainer,
  Blur,
  Spinner,
  ChooseFile,
  Container,
  Dropzone,
  RemoveButton,
  UploadLogoCaption,
} from './UploadWorkspaceLogo.styles'

interface PreviewProps {
  loading: boolean
  workspaceName: string
  imageURL?: string
  onRemove?: () => void
  placeholderIcon?: React.ReactElement
}

const megabytesToBytes = (n: number): number => n * 1024 * 1024

const Preview: React.FC<PreviewProps> = props => {
  const { loading, workspaceName, imageURL, onRemove } = props

  return (
    <PreviewContainer>
      <WorkspaceLogo workspaceName={workspaceName} src={imageURL} size="64px" />
      <RemoveButton
        onClick={onRemove}
        disabled={!imageURL || loading}
        data-testid="workspace-logo-remove-button"
      />
      {loading && (
        <>
          <Blur />
          <Spinner data-testid="logo-spinner" />
        </>
      )}
    </PreviewContainer>
  )
}

interface UploadWorkspaceLogoProps {
  logo?: string
  workspaceName: string
  inputId?: string
  onDrop: (file: File) => Promise<void> | void
  onError?: (message: string) => void
  onRemove?: () => void
}

export const UploadWorkspaceLogo: React.FC<UploadWorkspaceLogoProps> = props => {
  const { logo, workspaceName, inputId, onDrop, onError, onRemove } = props
  const [loading, setLoading] = useState(false)

  const handleOnDrop: DropzoneOptions['onDrop'] = async ([acceptedFile]) => {
    if (!acceptedFile) {
      const maxFileSize = MAX_SIZE / megabytesToBytes(1)

      onError?.(
        `Please upload a png or jpg with a maximum size of ${maxFileSize}MB`
      )
      return
    }

    setLoading(true)

    await onDrop(acceptedFile)
    setLoading(false)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/png': [],
      'image/jpeg': [],
      'image/jpg': [],
    },
    onDrop: handleOnDrop,
    maxSize: MAX_SIZE,
  })

  return (
    <Container>
      <Dropzone
        data-testid="dropzone"
        isDragActive={isDragActive}
        {...getRootProps({ refKey: 'ref' })}
      />
      <input id={inputId} {...getInputProps()} />
      <Preview
        loading={loading}
        imageURL={logo}
        workspaceName={workspaceName}
        onRemove={onRemove}
      />
      <UploadLogoCaption textAlign="center" mb="0">
        <ChooseFile>Upload an image</ChooseFile> or drag and drop
      </UploadLogoCaption>
    </Container>
  )
}
