import React from 'react'
import { Text } from '../Box'

interface NewFeatureHighlightPopoverBodyProps {
  titleText: string
  bodyText: string
}

export const NewFeatureHighlightPopoverBody: React.FC<NewFeatureHighlightPopoverBodyProps> = ({
  titleText,
  bodyText,
}) => {
  return (
    <>
      <Text textStyle="copy.primary.standard.E">{titleText}</Text>
      <Text mt={2} textStyle="copy.tertiary.standard.C">
        {bodyText}
      </Text>
    </>
  )
}
