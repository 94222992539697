import { useAnnotationQueryVariables } from '../context'
import useGetAnnotations from '../operations/useGetAnnotations'
import {
  GetAnnotationsQueryVariables,
  BaseAnnotationFragment,
} from '@sketch/gql-types'

export function useHasNewNotifications() {
  const variables = useAnnotationQueryVariables('sidebar')
  const queryVariables = {
    ...variables,
    sort: 'NEW_FIRST',
    annotationStatus: 'ACTIVE_ONLY',
  } as GetAnnotationsQueryVariables

  const skip = !((variables.subjects?.length || 0) > 0 || variables.subject)

  const { data } = useGetAnnotations({
    variables: queryVariables,
    skip,
  })

  const annotations = data?.annotations.entries || []

  return annotations.some(
    (entry: BaseAnnotationFragment) => entry.isNew || entry.hasNewComments
  )
}
