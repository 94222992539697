import React, { FC, useEffect, useState, useMemo } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { IS_EMBEDDED } from '@sketch/constants'
import { shortcuts } from '@sketch/utils'
import {
  Navbar,
  useForTablet,
  IsEmbedded,
  NotEmbedded,
  ModalRestrictor,
  MenuIconButton,
  TooltipShortcut,
} from '@sketch/components'
import { RouteParams, useCopyLink } from '@sketch/modules-common'
import { ShareWithoutVersion } from '../../../../versioning'
import { useHasNewNotifications } from '../../../../annotations/hooks'

import HeaderNavigation from '../../../components/HeaderNavigation'
import DocumentNavbar from '../../../components/DocumentNavbar'
import DocumentSettingsDropdown from '../../../components/DocumentHeader/DocumentSettingsDropdown'
import DerivedHeaderActions from '../../../components/DerivedHeaderActions'

import { ActionButton } from './PageCanvasViewHeader.styles'

import { DocumentSidebarLayoutExtraProps } from '../../../components/DocumentSidebarLayout'
import { VersionStatusButton } from '../../../components/DocumentHeader/VersionStatusButton'
import { useShareModal } from './PageCanvasViewHeader.hooks'

import { NavbarItem, VerticalDivider } from '../../../components/NavbarItem'
import {
  useShareSidebarTab,
  getActivePanels,
} from '../../../components/ShareSidebarTabContext'

import { ReactComponent as ClockReWind } from '@sketch/icons/clock-rewind-24'
import { ReactComponent as ChatBubbles } from '@sketch/icons/chat-bubbles-24'
import { ReactComponent as CodeBlock } from '@sketch/icons/code-block-24'
import { ReactComponent as Information } from '@sketch/icons/information-24'

import { VersionFragment } from '@sketch/gql-types'

type PageCanvasViewHeaderProps = {
  share: ShareWithoutVersion
  isViewingLatestVersion: boolean
  isViewingStarredVersion: boolean
  hasPendingPatches: boolean
  userCanOpenInApp: boolean
  currentVersion: VersionFragment | null
} & Pick<
  DocumentSidebarLayoutExtraProps,
  'setSidebarLeftOpen' | 'toggleSidebarRight' | 'isSidebarRightOpen'
>

const FloatingPanelTriggers = (props: PageCanvasViewHeaderProps) => {
  const {
    share,
    isViewingLatestVersion,
    userCanOpenInApp,
    isViewingStarredVersion,
    hasPendingPatches,
    isSidebarRightOpen,
    toggleSidebarRight,
    currentVersion,
  } = props

  const isTabletAndBigger = useForTablet()
  const showShareModal = useShareModal()
  const { pageUUID } = useParams<RouteParams<'SHARE_PAGE_CANVAS_VIEW'>>()
  const { path } = useRouteMatch()

  const activePanels = useMemo(
    () =>
      getActivePanels({
        path,
        canInspect: share.userCanInspect,
        // Even when the comments are not allowed, the comments panel should be available
        canComment: true,
      }),
    [path, share.userCanInspect]
  )

  /**
   * Save the tabs the user has access and
   * update if "shouldShowCommentTab" and "shouldShowInspector" updates
   */
  const [segments, setSegments] = useState(activePanels)

  useEffect(() => {
    setSegments(activePanels)
  }, [activePanels])

  const { activeSegment, toggleSegment } = useShareSidebarTab(segments, {
    isSidebarRightOpen,
    toggleSidebarRight,
  })
  const hasNewNotifications = useHasNewNotifications()

  const copyLink = useCopyLink()

  const showLatestVersionButton = isTabletAndBigger && !IS_EMBEDDED

  return (
    <>
      <DerivedHeaderActions
        share={share}
        currentVersion={currentVersion}
        userCanOpenInApp={userCanOpenInApp}
        isViewingLatestVersion={isViewingLatestVersion}
      />

      {isTabletAndBigger && (
        <NavbarItem>
          {share.userCanUpdateSettings ? (
            <ActionButton onClick={showShareModal}>Share...</ActionButton>
          ) : (
            <ActionButton onClick={copyLink}>Copy Link</ActionButton>
          )}
        </NavbarItem>
      )}

      {showLatestVersionButton && (
        <VersionStatusButton
          shareID={share.identifier}
          pageUUID={pageUUID}
          isViewingLatestVersion={isViewingLatestVersion}
          isViewingStarredVersion={isViewingStarredVersion}
          userCanEdit={userCanOpenInApp}
          hasPendingPatches={hasPendingPatches}
        />
      )}

      <NavbarItem>
        <ModalRestrictor>
          <DocumentSettingsDropdown showAnnotationActions />
        </ModalRestrictor>
      </NavbarItem>

      {isTabletAndBigger && (
        <>
          <VerticalDivider />

          <NavbarItem data-testid="panel-triggers-header">
            <MenuIconButton
              description={
                <>
                  Document details{' '}
                  <TooltipShortcut>
                    Press {shortcuts.about.toUpperCase()}
                  </TooltipShortcut>
                </>
              }
              aria-current={activeSegment === 'About'}
              onClick={() => toggleSegment('About')}
            >
              <Information role="img" />
              <span className="sr-only">About</span>
            </MenuIconButton>

            <MenuIconButton
              description={
                <>
                  Versions{' '}
                  <TooltipShortcut>
                    Press {shortcuts.version.toUpperCase()}
                  </TooltipShortcut>
                </>
              }
              aria-current={activeSegment === 'Version'}
              onClick={() => toggleSegment('Version')}
            >
              <ClockReWind role="img" />
              <span className="sr-only">Versions</span>
            </MenuIconButton>

            {share.userCanInspect && (
              <MenuIconButton
                description={
                  <>
                    Inspect{' '}
                    <TooltipShortcut>
                      Press {shortcuts.inspect.toUpperCase()}
                    </TooltipShortcut>
                  </>
                }
                aria-current={activeSegment === 'Inspect'}
                onClick={() => toggleSegment('Inspect')}
              >
                <CodeBlock role="img" />
                <span className="sr-only">Inspect</span>
              </MenuIconButton>
            )}

            <MenuIconButton
              description={
                <>
                  Comments{' '}
                  <TooltipShortcut>
                    Press {shortcuts.comment.toUpperCase()}
                  </TooltipShortcut>
                </>
              }
              data-testid="comment-tab"
              aria-current={activeSegment === 'Comment'}
              onClick={() => toggleSegment('Comment')}
              $isHighlighted={hasNewNotifications}
            >
              <ChatBubbles role="img" />
              <span className="sr-only">Comments</span>
            </MenuIconButton>
          </NavbarItem>
        </>
      )}
    </>
  )
}

export const PageCanvasViewHeader: FC<PageCanvasViewHeaderProps> = props => {
  const isTabletAndBigger = useForTablet()

  return (
    <>
      <NotEmbedded>
        <Navbar.Section>
          <HeaderNavigation share={props.share} />
        </Navbar.Section>

        <Navbar.FixedWidthSection align="end">
          {isTabletAndBigger && (
            <>
              <NavbarItem>
                <DocumentNavbar />
              </NavbarItem>
            </>
          )}

          <FloatingPanelTriggers {...props} />
        </Navbar.FixedWidthSection>
      </NotEmbedded>

      <IsEmbedded>
        <Navbar.Section>
          <HeaderNavigation share={props.share} />
        </Navbar.Section>
      </IsEmbedded>
    </>
  )
}

export default PageCanvasViewHeader
