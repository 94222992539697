import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { breakpoint } from '@sketch/global-styles'
import { ButtonUnstyled } from '@sketch/components'

import Search from '../Search'

export const Header = styled.div`
  margin: 24px;
  display: flex;
  height: 3rem;
  justify-content: space-between;
  align-items: center;
`

export const LeftContent = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 0;
`

export const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0;
`

export const CenterContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  position: relative;
  flex: 1;
  height: 100%;
  margin-left: 16px;

  /* Hide the translated input from being seen */
  clip-path: inset(-2px 0px -2px -2px);

  + ${RightContent} {
    ::before {
      /* | separator */
      content: '';
      margin-right: 12px;
      border-right: 1px solid
        ${({ theme }) => theme.colors.background.tertiary.B};
      height: 24px;
      z-index: 1;
    }
  }
`

export const CommunityLink = styled(Link)<{ $expanded: boolean }>`
  display: flex;
  align-items: center;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0px;

  ${breakpoint('sm')`
    margin-left: 24px;
  `}

  [role='img'] {
    width: 16px;
    margin-right: 4px;
    color: ${({ theme }) => theme.colors.foreground.secondary.D};
  }

  transform: translate3d(0, 0, 0);
  transition: all
    ${({ theme }) =>
      `${theme.transitions.duration[2]} ${theme.transitions.timing.easeInOut}`};

  ${({ $expanded }) =>
    $expanded &&
    css`
      transform: translate3d(-100%, 0, 0);
      opacity: 0;
    `}
`

export const SearchWrapper = styled.div<{ $expanded: boolean }>(
  ({ $expanded, theme }) => css`
    display: flex;
    align-items: center;

    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;

    padding-left: 4px;
    padding-right: 12px;
    max-width: 259px;
    width: 100%;

    transform: translate3d(calc(100% - 52px), 0, 0);
    transition: all
      ${`${theme.transitions.duration[2]} ${theme.transitions.timing.easeInOut}`};

    & ${Search} {
      background-color: white;

      ${ButtonUnstyled} {
        /* 
          This is needed to fix an issue inside the 
          Filter component given that it was causing 
          the animation to jump on chrome.
        */
        z-index: auto;
      }

      [type='search'] {
        opacity: 0;

        /*
          Fix safari issue with the input showing when hidden
          https://github.com/sketch-hq/Cloud/issues/17130
        */
        pointer-events: none;
      }
    }

    ${$expanded &&
    css`
      transform: translate3d(0, 0, 0);

      & ${Search} {
        [type='search'] {
          opacity: 1;

          /*
            Fix safari issue with the input showing when hidden
            https://github.com/sketch-hq/Cloud/issues/17130
          */
          pointer-events: all;
        }
      }
    `}
  `
)
