import { Text } from '@sketch/components'
import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 16px;
  padding-right: 8px;
  padding-bottom: 4px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`

export const Title = styled(Text)`
  margin-left: 16px;
`
