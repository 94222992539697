import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { Redirect, RouteComponentProps } from 'react-router'

import {
  IndexLayoutExtraProps,
  IndexLayoutContent,
  IndexLayoutHeaderLink,
  routes,
  useGetWorkspaces,
} from '@sketch/modules-common'
import { DocumentHead, LoadingPlaceholder } from '@sketch/components'

import { Wrapper } from './SignInGetStarted.styles'
import { useCreateWorkspaceMutation } from '@sketch/gql-types'

interface SignInGetStartedProps
  extends IndexLayoutExtraProps,
    RouteComponentProps {}

/**
 * SignInGetStarted
 *
 * This component is responsible by checking if the user has workspaces
 * and create one if there isn't any. This logic was implemented according to
 * https://github.com/sketch-hq/Cloud/issues/19414
 *
 * This component is linked to the route `/signin/get-started` which is used
 * when either signin or signup routes are accessed with the parameter source=pricing
 */
export const SignInGetStarted = (props: SignInGetStartedProps) => {
  const { HeaderPortal, history } = props
  const { workspaces, loading } = useGetWorkspaces()

  const [createWorkspace] = useCreateWorkspaceMutation({
    onError: 'do-nothing',
    onCompleted: ({ createWorkspace }) => {
      history.push(
        routes.WORKSPACE_SUBSCRIBE.create({
          workspaceId: createWorkspace.workspace.identifier,
        })
      )
    },
  })

  useEffect(() => {
    const awaitCreateWorkspace = async () => {
      await createWorkspace({ variables: { input: {} } })
    }

    if (workspaces?.length === 0) {
      awaitCreateWorkspace()
    }
  }, [workspaces, createWorkspace])

  // Already has workspace send it to the billing settings
  if (!loading && workspaces && workspaces?.length > 0) {
    return (
      <Redirect to={routes.WORKSPACE_AGNOSTIC_SETTINGS_BILLING.create({})} />
    )
  }

  // Entertain the user while the workspaces are loading
  return (
    <IndexLayoutContent center="horizontal" marginTop paddingHorizontal>
      <HeaderPortal>
        <IndexLayoutHeaderLink />
      </HeaderPortal>
      <Helmet>
        <link rel="canonical" href="https://www.sketch.com/signin/" />
        <meta property="og:url" content="https://www.sketch.com/signin/" />
      </Helmet>
      <DocumentHead title="Sign in - It's great to see you again" />
      <Wrapper>
        <LoadingPlaceholder size="64px" />
      </Wrapper>
    </IndexLayoutContent>
  )
}
