// Usage of BillingPlanType will not break the build
// eslint-disable-next-line no-restricted-imports
import { BillingPlanType } from '@sketch/gql-types/expansive'

export const PLAN_TYPE_LABEL: Record<BillingPlanType, string> = {
  TRIAL: 'Trial',
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly',
}

const PLAN_TO_DURATION_LABEL = {
  MONTHLY: 'month',
  YEARLY: 'year',
  TRIAL: '1 month',
}

const PLAN_TO_NAME = {
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly',
}

/**
 * We don't restrict the function input because BE could at any moment add temporary text plan
 * and the "planType" wouldn't make sense.
 *
 * That's the same reason why we return the "planType" if there isn't a text.
 */

export const getPlanDuration = (planType: string) =>
  (PLAN_TO_DURATION_LABEL as any)[planType] || planType

export const getPlanName = (planType: string) =>
  (PLAN_TO_NAME as any)[planType] || planType

export const nextPricingDate = Date.UTC(2023, 4, 18, 23, 59, 59)
