import { matchPath } from 'react-router-dom'
import {
  getVersionedRoute,
  RouteParams,
  VersionedRouteParams,
} from '@sketch/modules-common'

export interface MatchedSpecificVersion {
  isShareRoute: true
  matchKind: 'specific'
  versionShortId: string
  shareIdentifier: string
}

export interface MatchedLatestVersion {
  isShareRoute: true
  matchKind: 'latest'
  versionShortId: null
  shareIdentifier: string
}

export interface UnMatchedLatestVersion {
  isShareRoute: false
  matchKind: 'none'
  versionShortId: null
  shareIdentifier: null
}

export type VersionRouteInformation =
  | MatchedSpecificVersion
  | MatchedLatestVersion
  | UnMatchedLatestVersion

export const extractVersionInformation = (
  pathname: string
): VersionRouteInformation => {
  const versionMatch = matchPath<VersionedRouteParams<'SHARE_VIEW'>>(pathname, {
    path: getVersionedRoute('SHARE_VIEW', false).template(),
    exact: false,
  })

  const latestMatch = matchPath<RouteParams<'SHARE_VIEW'>>(pathname, {
    path: getVersionedRoute('SHARE_VIEW').template(),
    exact: false,
  })

  if (versionMatch) {
    const { versionShortId, shareID } = versionMatch.params
    return {
      isShareRoute: true,
      matchKind: 'specific',
      shareIdentifier: shareID,
      versionShortId,
    }
  }

  if (latestMatch) {
    const { shareID } = latestMatch.params
    return {
      isShareRoute: true,
      matchKind: 'latest',
      shareIdentifier: shareID,
      versionShortId: null,
    }
  }

  return {
    isShareRoute: false,
    matchKind: 'none',
    shareIdentifier: null,
    versionShortId: null,
  }
}
