import React from 'react'
import { roundWithLocale } from '@sketch/utils'
import {
  AttributeList,
  Section,
  Separator,
  FullCopyAttribute,
  Header,
  HeaderTitle,
} from '../../components'
import { GridSettings as GridSettingsType } from '../../../../../../inspector'

export const GridSettings = ({
  grid,
}: {
  grid: GridSettingsType | undefined
}) => {
  if (!grid || !grid.isEnabled) {
    return null
  }

  const { size, thickLineStep } = grid

  return (
    <>
      <Separator />
      <Section data-testid="inspector-sidebar-artboard-grid-settings">
        <Header
          copyValue={`Grid size: ${size}px, thick lines every: ${thickLineStep} blocks`}
        >
          <HeaderTitle>Grid Settings</HeaderTitle>
        </Header>
        <Separator />
        <AttributeList>
          {size !== undefined ? (
            <FullCopyAttribute
              display="wide"
              label="Grid Size"
              copyValue={`${size}px`}
              value={`${roundWithLocale(size, 2)}px`}
            />
          ) : null}
          {thickLineStep !== undefined ? (
            <FullCopyAttribute
              display="wide"
              label="Thick lines every N blocks"
              copyValue={`${thickLineStep}px`}
              value={roundWithLocale(thickLineStep, 2)}
            />
          ) : null}
        </AttributeList>
      </Section>
    </>
  )
}
