import { castError } from '@sketch/utils'
import { DataProxy } from 'apollo-cache'
import { dataIdFromObject } from '@sketch/graphql-cache'
import {
  ShareListItemFragment,
  ShareListItemFragmentDoc,
  ShareDeleteMembershipMutation,
  GetShareMembershipsDocument,
  GetShareMembershipsQuery,
  GetAccessShareMembershipsQuery,
  GetAccessShareMembershipsDocument,
  ShareMembershipInfoFragment,
  SharePublicPermissionsFragment,
  SharePublicPermissionsFragmentDoc,
} from '@sketch/gql-types'
import produce from 'immer'
import { ErrorHandler } from '@sketch/tracing'

/**
 * TYPES
 */

interface WriteShareListItemToCache {
  cache: DataProxy
  share: ShareListItemFragment
}

interface DeleteShareMembershipWithPreviewProps {
  cache: DataProxy
  data?: ShareDeleteMembershipMutation | null
  share: Pick<ShareListItemFragment, 'identifier'>
  member: ShareMembershipInfoFragment
}

interface DeleteAccessShareMembershipProps {
  cache: DataProxy
  data?: ShareDeleteMembershipMutation | null
  workspaceIdentifier: string
  workspaceMembershipIdentifier: string
}

/**
 * CACHE HELPERS
 */

export const writeShareListItemToCache = ({
  cache,
  share,
}: WriteShareListItemToCache) =>
  cache.writeFragment<ShareListItemFragment>({
    id: dataIdFromObject(share) as string,
    fragment: ShareListItemFragmentDoc,
    fragmentName: 'ShareListItem',
    data: share,
  })

export const publicPermissionsCache = {
  read: (cache: DataProxy, identifier: string) =>
    cache.readFragment<SharePublicPermissionsFragment>({
      fragment: SharePublicPermissionsFragmentDoc,
      fragmentName: 'SharePublicPermissions',
      id: dataIdFromObject({ __typename: 'Share', identifier }) as string,
    }),
  write: (cache: DataProxy, share: SharePublicPermissionsFragment) =>
    cache.writeFragment<SharePublicPermissionsFragment>({
      id: dataIdFromObject(share) as string,
      fragment: SharePublicPermissionsFragmentDoc,
      fragmentName: 'SharePublicPermissions',
      data: share,
    }),
}

// Updates cache removing a member for both `GetShareMembershipsQuery` and
// `GetShareMembershipsPreviewQuery`
export const deleteShareMembershipWithPreview = ({
  cache,
  data,
  share,
  member,
}: DeleteShareMembershipWithPreviewProps) => {
  try {
    const query = {
      query: GetShareMembershipsDocument,
      variables: { shareIdentifier: share.identifier },
    }

    const shareMembershipsData = cache.readQuery<GetShareMembershipsQuery>(
      query
    )

    if (!shareMembershipsData) {
      return
    }

    const newData = produce(shareMembershipsData, newData => {
      const memberships = newData.share?.members?.entries
      const currentUserMembership = newData.share?.myMembership
      const newMember = data?.shareDeleteMembership?.membership

      if (member.identifier === currentUserMembership?.identifier) {
        newData.share!.myMembership = null
      }

      if (!memberships || !newMember) {
        return
      }

      const newEntries = memberships.filter(
        entry => entry!.identifier !== member.identifier
      )

      newData.share!.members!.entries = newEntries
      newData.share!.members!.meta.totalCount =
        newData.share!.members!.meta.totalCount - 1
    })

    cache.writeQuery<GetShareMembershipsQuery>({
      ...query,
      data: newData,
    })
  } catch (e) {
    const error = castError(e)
    // Reading from the cache can throw when the query doesn't exist.
    // We ingore this error because the worst that will happen is that a
    // removed member will not disappear without re-opening the modal.
    ErrorHandler.ignore(error)
  }
}

// Updates cache removing a member from `GetAccessShareMemberships`
export const deleteAccessShareMemberships = ({
  cache,
  data,
  workspaceIdentifier,
  workspaceMembershipIdentifier,
}: DeleteAccessShareMembershipProps) => {
  try {
    const query = {
      query: GetAccessShareMembershipsDocument,
      variables: {
        workspaceIdentifier,
        workspaceMembershipIdentifier,
        shareSearch: { name: '' },
      },
    }

    const shareMembershipsData = cache.readQuery<GetAccessShareMembershipsQuery>(
      query
    )

    if (!shareMembershipsData) {
      return
    }

    const newData = produce(shareMembershipsData, newData => {
      const memberships =
        newData.workspace.workspaceMembership?.shareMemberships.entries
      const deletedMembership = data?.shareDeleteMembership?.membership

      if (
        !memberships ||
        !deletedMembership ||
        !newData.workspace.workspaceMembership
      ) {
        return
      }

      const newEntries = memberships.filter(
        entry => entry.identifier !== deletedMembership.identifier
      )

      newData.workspace.workspaceMembership.shareMemberships.entries = newEntries
      newData.workspace.workspaceMembership.shareMemberships.meta.totalCount =
        newEntries.length
    })

    cache.writeQuery<GetAccessShareMembershipsQuery>({
      ...query,
      data: newData,
    })
  } catch (e) {
    const error = castError(e)
    // Reading from the cache can throw when the query doesn't exist.
    // We ingore this error because the worst that will happen is that a
    // removed member will not disappear without re-opening the modal.
    ErrorHandler.ignore(error)
  }
}
