import {
  useGetPublicWorkspaceForAccountLinkingQuery,
  WorkspaceMinimalIdentityFragment,
} from '@sketch/gql-types'

export interface SignInWorkspace
  extends OmitSafe<WorkspaceMinimalIdentityFragment, '__typename'> {
  ssoStartUrl: string
}

type LinkedTeamsResult =
  | { type: 'error'; message?: string }
  | { type: 'loading' }
  | {
      type: 'loaded'
      signInWorkspace: SignInWorkspace
      linkedWorkspaces: WorkspaceMinimalIdentityFragment[]
      ssoLinkToken: string
    }

export const useLinkAccountConfirmation = (
  workspaceId: string
): LinkedTeamsResult => {
  const { data, loading, error } = useGetPublicWorkspaceForAccountLinkingQuery({
    variables: { workspaceId },
  })

  if (loading) {
    return { type: 'loading' }
  }

  if (error || !data) {
    return {
      type: 'error',
      message: error?.message,
    }
  }

  const linkedWorkspaces = data.me.personalWorkspace
    ? [data.me.personalWorkspace, ...data.me.workspaces]
    : data.me.workspaces

  return {
    type: 'loaded',
    signInWorkspace: {
      ...data.publicWorkspace!,
      ssoStartUrl: data.publicWorkspace!.customer!.ssoStartUrl!,
    },
    linkedWorkspaces,
    ssoLinkToken: data.me.ssoLinkToken,
  }
}
