/**
 * Light theme is based on tint.default, color variables in the Sketch file
 * related with primaty tint won't match these token values because in Sketch
 * we use a combination of Tint + Fill, but for CSS we just use one color.
 */

export const fallbackLightTheme = {
  background: {
    primary: {
      A: 'hsla(0, 0%, 0%, 1)',
      B: 'hsla(0, 0%, 0%, 0.85)',
      C: 'hsla(0, 0%, 0%, 0.7)',
    },
    secondary: {
      A: 'hsla(0, 0%, 100%, 1)',
      B: 'hsla(0, 0%, 98%, 1)',
      C: 'hsla(0, 0%, 90%, 1)',
    },
    tertiary: {
      A: 'hsla(0, 0%, 0%, 0.3)',
      B: 'hsla(0, 0%, 0%, 0.1)',
      C: 'hsla(0, 0%, 0%, 0.04)',
      D: 'hsla(0, 0%, 0%, 0.02)',
    },
  },
  foreground: {
    primary: {
      A: 'hsla(0, 0%, 100%, 1)',
      B: 'hsla(0, 0%, 100%, 0.7)',
    },
    secondary: {
      A: 'hsla(0, 0%, 0%, 1)',
      B: 'hsla(0, 0%, 0%, 0.85)',
      C: 'hsla(0, 0%, 0%, 0.7)',
      D: 'hsla(0, 0%, 0%, 0.55)',
    },
  },
  border: {
    A: 'hsla(0, 0%, 0%, 0.1)',
    B: 'hsla(0, 0%, 90%, 1)',
  },
  overlay: 'hsla(210, 3%, 12%, 0.75)',
  shadow: {
    inner: 'hsla(0, 0%, 0%, 0.1)',
    outer: 'hsla(0, 0%, 0%, 0.1)',
  },
  state: {
    disabled: 'hsla(0, 0%, 0%, 0.3)',
    hover: 'hsla(0, 0%, 0%, 0.04)',
    negative: {
      A: 'hsla(0, 100%, 40%, 1)',
      B: 'hsla(0, 100%, 40%, 0.85)',
      C: 'hsla(0, 100%, 40%, 0.7)',
      D: 'hsla(0, 100%, 40%, 0.55)',
      E: 'hsla(0, 100%, 40%, 0.3)',
      F: 'hsla(0, 100%, 40%, 0.1)',
    },
    neutral: {
      A: 'hsla(45, 100%, 48%, 1)',
      B: 'hsla(45, 100%, 48%, 0.85)',
      C: 'hsla(45, 100%, 48%, 0.7)',
      D: 'hsla(45, 100%, 48%, 0.55)',
      E: 'hsla(45, 100%, 48%, 0.3)',
      F: 'hsla(45, 100%, 48%, 0.1)',
    },
    positive: {
      A: 'hsla(135, 82%, 41%, 1)',
      B: 'hsla(135, 82%, 41%, 0.85)',
      C: 'hsla(135, 82%, 41%, 0.7)',
      D: 'hsla(135, 82%, 41%, 0.55)',
      E: 'hsla(135, 82%, 41%, 0.3)',
      F: 'hsla(135, 82%, 41%, 0.1)',
    },
  },
} as const

export const fallbackDarkTheme = {
  background: {
    primary: {
      A: 'hsla(0, 0%, 0%, 1)',
      B: 'hsla(0, 0%, 0%, 0.85)',
      C: 'hsla(0, 0%, 0%, 0.7)',
    },
    secondary: {
      A: 'hsla(240, 3%, 13%, 1)',
      B: 'hsla(240, 2%, 18%, 1)',
      C: 'hsla(0, 0%, 27%, 1)',
    },
    tertiary: {
      A: 'hsla(0, 0%, 100%, 0.02)',
      B: 'hsla(0, 0%, 100%, 0.08)',
      C: 'hsla(0, 0%, 100%, 0.16)',
      D: 'hsla(0, 0%, 100%, 0.3)',
    },
  },
  foreground: {
    primary: {
      A: 'hsla(240, 3%, 13%, 1)',
      B: 'hsla(240, 3%, 13%, 0.7)',
    },
    secondary: {
      A: 'hsla(0, 0%, 100%, 1)',
      B: 'hsla(0, 0%, 100%, 0.85)',
      C: 'hsla(0, 0%, 100%, 0.7)',
      D: 'hsla(0, 0%, 100%, 0.55)',
    },
  },
  border: {
    A: 'hsla(0, 0%, 100%, 0.07)',
    B: 'hsla(0, 0%, 27%, 1)',
  },
  overlay: 'hsla(210, 3%, 15%, 0.75)',
  shadow: {
    inner: 'hsla(0, 0%, 0%, 0.1)',
    outer: 'hsla(0, 0%, 0%, 0.5)',
  },
  state: {
    disabled: 'hsla(0, 0%, 100%, 0.3)',
    hover: 'hsla(0, 0%, 100%, 0.08)',
    negative: {
      A: 'hsla(0, 100%, 40%, 1)',
      B: 'hsla(0, 100%, 40%, 0.85)',
      C: 'hsla(0, 100%, 40%, 0.7)',
      D: 'hsla(0, 100%, 40%, 0.55)',
      E: 'hsla(0, 100%, 40%, 0.3)',
      F: 'hsla(0, 100%, 40%, 0.1)',
    },
    neutral: {
      A: 'hsla(45, 100%, 48%, 1)',
      B: 'hsla(45, 100%, 48%, 0.85)',
      C: 'hsla(45, 100%, 48%, 0.7)',
      D: 'hsla(45, 100%, 48%, 0.55)',
      E: 'hsla(45, 100%, 48%, 0.3)',
      F: 'hsla(45, 100%, 48%, 0.1)',
    },
    warning: 'hsl(40, 89%, 44%)',
    positive: {
      A: 'hsla(135, 82%, 41%, 1)',
      B: 'hsla(135, 82%, 41%, 0.85)',
      C: 'hsla(135, 82%, 41%, 0.7)',
      D: 'hsla(135, 82%, 41%, 0.55)',
      E: 'hsla(135, 82%, 41%, 0.3)',
      F: 'hsla(135, 82%, 41%, 0.1)',
    },
  },
} as const
