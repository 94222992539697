import { useCallback, useRef, useState } from 'react'
import {
  useGetArtboardAtPosition,
  useGetPanAtPosition,
  useLockCamera,
  useZoom,
} from '@sketch-hq/sketch-web-renderer'

export type Coordinates = {
  clientX: number
  clientY: number
  posX: number
  posY: number
}

export type ContextMenuMetaData = {
  canvasPanPosition: Coordinates
  zoom: number
  artboardUUID: string | null
}

/**
 * All the logic related to handling right click on the canvas:
 * - Manage state of position of contextMenu and if it should show it or not.
 * - Save Pan/Zoom position of the right click so we can then create a URL pointing
 *  to that exact position.
 */
export function useCanvasContextMenu() {
  const lockCamera = useLockCamera()
  const getArtboardAtPosition = useGetArtboardAtPosition()
  const getCanvasPositionFromMouseEvent = useGetCanvasPositionFromMouseEvent()
  const [contextMenuPos, setContextMenuPos] = useState<Coordinates | null>(null)
  const [
    contextMenuMetaData,
    setContextMenuMetaData,
  ] = useState<ContextMenuMetaData | null>(null)
  const canvasContainerRef = useRef<HTMLDivElement>(null)

  const contextMenuClickHandler = useCallback<useContextMenuCallback>(
    async (e, relativeX, relativeY) => {
      // For some reasons the e.nativeEvent becomes null after next tick,
      // so we need to save it's value before we run any async code.
      const clientX = e.nativeEvent.clientX
      const clientY = e.nativeEvent.clientY

      const artboard = await getArtboardAtPosition(relativeX, relativeY)

      const canvasPosition = await getCanvasPositionFromMouseEvent(
        clientX,
        clientY
      )

      if (!canvasPosition) {
        return
      }

      setContextMenuPos({
        clientX,
        clientY,
        posX: relativeX,
        posY: relativeY,
      })
      lockCamera(true)

      setContextMenuMetaData({
        canvasPanPosition: {
          clientX,
          clientY,
          posX: canvasPosition.posX,
          posY: canvasPosition.posY,
        },
        zoom: canvasPosition.zoom,
        artboardUUID: artboard?.getLayerUUID() || null,
      })
    },
    [getArtboardAtPosition, getCanvasPositionFromMouseEvent, lockCamera]
  )

  const { handleContextMenu } = useContextMenuClick(
    canvasContainerRef,
    contextMenuClickHandler
  )

  const handleCloseContextMenu = useCallback(() => {
    setContextMenuPos(null)
    lockCamera(false)
  }, [lockCamera])

  return {
    contextMenuPos,
    contextMenuMetaData,
    canvasContainerRef,
    handleContextMenu,
    handleCloseContextMenu,
  }
}

function useGetCanvasPositionFromMouseEvent() {
  const getPanAtPosition = useGetPanAtPosition()
  const zoom = useZoom()

  return useCallback(
    async (clientX: number, clientY: number) => {
      const panPosition = await getPanAtPosition(clientX, clientY)

      if (!zoom || !panPosition) {
        return
      }

      return {
        posX: panPosition.x,
        posY: panPosition.y,
        zoom: zoom,
      }
    },
    [getPanAtPosition, zoom]
  )
}

type useContextMenuCallback = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  /** Position X of the click relative to the container */
  relativeX: number,
  /** Position Y of the click relative to the container */
  relativeY: number
) => void

/**
 * Wrap provided contextMenuCallback function to calculate
 * coordinates of the click relative to the provided container.
 */
function useContextMenuClick(
  containerRef: React.RefObject<HTMLElement>,
  contextMenuCallback: useContextMenuCallback
) {
  const handleContextMenu = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.nativeEvent.preventDefault()
    const container = containerRef.current
    if (!container) {
      return
    }
    const containerRect = container.getBoundingClientRect()
    const relativeX = e.pageX - containerRect.left
    const relativeY = e.pageY - containerRect.top

    contextMenuCallback(e, relativeX, relativeY)
  }

  return {
    handleContextMenu,
  }
}
