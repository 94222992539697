import React from 'react'
import { Route as TypeRoute } from 'typesafe-react-router'
import { Location, LocationDescriptor } from 'history'
import { Route, RouteProps, Redirect, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import {
  useUserSignedIn,
  GenericErrorView,
  routes,
  useUserAuthorizations,
  useUserProfile,
} from '@sketch/modules-common'

type RedirectPathSignOut = 'sign-in' | 'sign-up'

const REDIRECT_PATHS_BY_KEY: Record<
  RedirectPathSignOut,
  TypeRoute<any, any>
> = {
  'sign-in': routes.SIGN_IN,
  'sign-up': routes.SIGN_UP,
}

interface PrivateRouteProps extends RouteProps {
  redirectPathIfSignOut?:
    | RedirectPathSignOut
    | ((location: Location<any>) => LocationDescriptor)
  // Prevent <GenericErrorView /> from render
  skipErrorView?: boolean

  /**
   * Property which is used during transitional period
   * from `rendering Views first, Layouts second`
   * to   `rendering Layouts first, Views second`
   *
   * see more: https://github.com/sketch-hq/sketch-rfc/pull/88
   *
   * TODO: remove this property once all PrivateRoute components will be rendered inside a layout.
   * prerequisites:
   *   - https://github.com/sketch-hq/Cloud/issues/16943
   *   - https://github.com/sketch-hq/Cloud/issues/16941
   */
  isInLayout?: boolean
}

const PrivateRoute: React.FC<PrivateRouteProps> = props => {
  const {
    children,
    component,
    location: externalLocation,
    render,
    redirectPathIfSignOut = 'sign-in',
    skipErrorView,
    isInLayout,
    ...routeProps
  } = props

  const contextLocation = useLocation()
  const location = externalLocation || contextLocation

  const isSignedIn = useUserSignedIn()
  const { data, error } = useUserProfile(true, {
    skip: !isSignedIn,
  })

  const { authorizations, activeAuthorization } = useUserAuthorizations()

  const hasUserData = !!data?.me?.identifier

  // Prevent error UI from showing in case the data is not yet available in
  // cache
  if (isSignedIn && !hasUserData) return null

  const hasErrorState = error || !hasUserData

  /* Render as a normal route would */
  if (!hasErrorState) {
    return <Route {...props} />
  }

  /**
   * If the user is not signed-in or has invalid credentials
   * UserContext will make sure the state is updated and therefore make
   * "isSignedIn" false
   */
  if (!isSignedIn) {
    let to

    if (typeof redirectPathIfSignOut === 'function') {
      to = redirectPathIfSignOut(location)
    } else {
      to = {
        pathname: REDIRECT_PATHS_BY_KEY[redirectPathIfSignOut].create({}),
        state: { from: location },
        search: location.search,
      }
    }

    return <Redirect to={to} />
  }

  return (
    <Route
      {...routeProps}
      location={location}
      render={() => {
        Sentry.addBreadcrumb({
          category: 'Authentication',
          message: `Private route error: ${error?.message}`,
          level: 'error',
          data: {
            // Convert to string as false values don't display on Sentry breadcrumbs UI
            hasUserSession: `${hasUserData}`,
            active: {
              type: activeAuthorization?.type,
              expirationDateTime:
                activeAuthorization?.fragment.expirationDateTime,
            },
            // Convert to string as arrays don't seem to be displayed on Sentry breadcrumbs UI
            all: JSON.stringify(
              authorizations.map(auth => ({
                type: auth.type,
                expirationDateTime: auth.fragment.expirationDateTime,
              }))
            ),
          },
        })

        // Used when we don't want to render the <GenericErrorView />
        // Usefull when this component is used in multiple route Switch under the same
        // parent component
        if (skipErrorView) return null

        return <GenericErrorView error={error} isInLayout={isInLayout} />
      }}
    />
  )
}

export default PrivateRoute
