import { useCallback } from 'react'
import {
  GetAnnotationSettingsDocument,
  GetAnnotationSettingsQuery,
  useGetAnnotationSettingsQuery,
} from '@sketch/gql-types'

import { useIsAnnotationContextAvailable } from './useAnnotationOverlay'

const useHideAnnotationDots = () => {
  const isContextAvailable = useIsAnnotationContextAvailable()

  const { data, client } = useGetAnnotationSettingsQuery()
  const { hideAnnotationsDots } = data || {}

  const setHideAnnotationDots = useCallback(
    (hideAnnotationsDots: boolean) => {
      client.writeQuery<GetAnnotationSettingsQuery>({
        query: GetAnnotationSettingsDocument,
        data: {
          __typename: 'RootQueryType',
          hideAnnotationsDots,
        },
      })
    },
    [client]
  )

  if (!isContextAvailable) {
    return
  }

  return [hideAnnotationsDots, setHideAnnotationDots] as const
}

export default useHideAnnotationDots
