import React from 'react'
import { Text, pluralize } from '@sketch/components'
import { ShareWithSizeFragment } from '@sketch/gql-types'
import SendToTrashDocumentList from '../SendToTrashDocumentList'

const Confirmation: React.FC<{
  numberSelectedDocuments: number
  sharesWithSize: ShareWithSizeFragment[]
}> = ({ numberSelectedDocuments, sharesWithSize }) => {
  return (
    <>
      <Text textStyle="header.primary.G">
        {pluralize('Document', 'Documents', numberSelectedDocuments)} Sent to
        Trash
      </Text>
      <Text textStyle="copy.tertiary.standard.E">
        If you want to free up storage space immediately, head over to the
        Workspace Trash and empty it now.
      </Text>
      <SendToTrashDocumentList sharesWithSize={sharesWithSize} />
    </>
  )
}

export default Confirmation
