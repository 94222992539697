import React from 'react'
import {
  Section,
  Header,
  HeaderTitle,
  Separator,
  AttributeColumns,
  AttributeList,
  FullCopyAttribute,
} from '../../components'
import { roundWithLocale } from '@sketch/utils'
import copy from './copy'

interface PositionAndSizeProps {
  rotate?: number
  x: number
  y: number
  width: number
  height: number
}

export const PositionAndSize = ({
  rotate = 0,
  x,
  y,
  width,
  height,
}: PositionAndSizeProps) => {
  const roundedX = roundWithLocale(x, 2)
  const roundedY = roundWithLocale(y, 2)
  // The raw rotate value in the pr file works counter-clockwise, but users think
  // in clockwise rotation, so flip the sign
  const reversedRotate = rotate !== 0 ? -rotate : rotate
  const roundedRotate = `${roundWithLocale(reversedRotate, 2)}°`
  const roundedWidth = roundWithLocale(width, 2)
  const roundedHeight = roundWithLocale(height, 2)

  const sectionCopyValue = copy({
    rotate: roundedRotate,
    x: roundedX,
    y: roundedY,
    width: roundedWidth,
    height: roundedHeight,
  })

  return (
    <>
      <Separator />
      <Section data-testid="inspector-sidebar-position">
        <Header copyValue={sectionCopyValue}>
          <HeaderTitle>Position and Size</HeaderTitle>
        </Header>
        <AttributeList>
          <AttributeColumns>
            <FullCopyAttribute label="X" value={roundedX} display="block" />
            <FullCopyAttribute label="Y" value={roundedY} display="block" />
            <FullCopyAttribute
              label="Rotate"
              value={roundedRotate}
              display="block"
            />
          </AttributeColumns>
          <AttributeColumns>
            <FullCopyAttribute
              label="Width"
              value={roundedWidth}
              display="block"
            />
            <FullCopyAttribute
              label="Height"
              value={roundedHeight}
              display="block"
            />
          </AttributeColumns>
        </AttributeList>
      </Section>
    </>
  )
}
