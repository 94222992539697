import React from 'react'
import styled, { css } from 'styled-components'

import { routes } from '@sketch/modules-common'

import { ReactComponent as Shield } from '@sketch/icons/shield-16'

import { Text, Link } from '@sketch/components'

declare module '@sketch/components' {
  export interface BannersMap {
    'mfa-banner': null
  }
}

const BannerWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: ${theme.colors.background.tertiary.C};
    border-radius: ${theme.radii.large};
  `
)

const BannerText = styled(Text).attrs({
  textStyle: 'copy.tertiary.standard.D',
})`
  display: flex;
  margin: 0;
  padding: 12px 16px;
`

const StyledLink = styled(Link)`
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
`

const IconWrapper = styled.span`
  display: inline-flex;
  margin-right: 8px;
`

const MfaBanner: React.FC = () => (
  <BannerWrapper>
    <BannerText>
      <IconWrapper>
        <Shield width="16px" height="16px" />
      </IconWrapper>
      Use two-factor authentication (2FA) when you sign in to better protect
      your account.
      <StyledLink
        variant="secondary"
        to={routes.PERSONAL_SETTINGS.create({})}
        isUnderlined
      >
        Manage Account
      </StyledLink>
    </BannerText>
  </BannerWrapper>
)

export default MfaBanner
