import styled, { css } from 'styled-components'

import { breakpoint } from '@sketch/global-styles'

import { skeletonFadeStyles } from '../Skeleton'

export const bannerCardTitleBaseCss = css`
  font-size: ${({ theme }) => theme.fontSizes.F};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

export const BannerCardTitle = styled.p<{ $small?: boolean }>`
  ${bannerCardTitleBaseCss}
  margin: ${({ $small }) => ($small ? '0px' : '16px 0 0 0')};

  ${({ $small }) =>
    $small &&
    css`
      ${breakpoint('base', 'sm')`
        display: none;
      `}
    `};
`

export const bannerCardDescriptionBaseCss = css`
  font-size: ${({ theme }) => theme.fontSizes.D};
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

export const Description = styled.p<{ $small?: boolean }>`
  ${bannerCardDescriptionBaseCss}
  margin: ${({ $small }) => ($small ? '0px' : '6px 0 0 0')};

  ${({ $small }) =>
    $small &&
    css`
      ${breakpoint('base', 'sm')`
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      `}
    `};
`

export const bannerCardIconContainerBaseCss = css<{ $small?: boolean }>`
  display: flex;
  align-items: flex-start;
  width: ${({ $small }) => ($small ? '32px' : '48px')};
  height: ${({ $small }) => ($small ? '32px' : '48px')};

  ${breakpoint('base', 'xs')`
    width: 32px;
    height: 32px;
  `}
`

export const IconContainer = styled.div<{ $small?: boolean }>`
  ${bannerCardIconContainerBaseCss}

  margin-right: ${({ $small }) => ($small ? '12px' : '0')};
`

export const BannerCardBodyContainer = styled.div<{ $small?: boolean }>`
  display: flex;
  flex-direction: ${({ $small }) => ($small ? 'row' : 'column')};
  flex: 1;
`

export const BannerCardActionContainer = styled.div<{ $small?: boolean }>`
  ${({ $small }) =>
    $small
      ? css`
          display: flex;
          flex: 0 0 auto;
          align-self: center;
          align-items: flex-end;
          margin-left: 16px;
        `
      : css`
          margin-top: 16px;
        `};
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const bannerCardWrapperBaseCss = css`
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  border-radius: ${({ theme }) => theme.radii.large};
`
export const Wrapper = styled.div<{ $small?: boolean }>`
  ${bannerCardWrapperBaseCss}

  ${({ theme, $small }) => css`
    position: relative;

    display: flex;
    flex-direction: ${$small ? 'row' : 'column'};
    flex: 1;
    max-width: 100%;

    padding: ${$small ? '14px 16px' : '24px'};

    :hover {
      ${Dismiss} {
        opacity: 1;
      }
    }
  `}
`

export const Action = styled.div<{ $small?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ $small }) =>
    $small &&
    css`
      align-self: flex-end;

      ${breakpoint('base', 'sm')`
        display: none;
      `}
    `};
`

export const Dismiss = styled.button<{ $small?: boolean }>`
  position: absolute;
  top: -11px;
  right: -11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  padding: 4px;
  background: ${({ theme }) => theme.colors.background.secondary.A};
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  border-radius: 100%;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  cursor: pointer;

  ${({ $small }) =>
    $small &&
    css`
      ${breakpoint('base', 'sm')`
          position: relative;
          top: auto;
          right: auto;
          opacity: 1;
        `};
    `};
`

export const BannerCardSkeleton = styled(Wrapper)`
  height: 272px;
  border: 0;

  ${skeletonFadeStyles}
`
