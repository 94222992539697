import { merge } from 'lodash'

import {
  CommunityItemFragment,
  CommunitySectionFragment,
  PermissionsFragment,
  LinkFragment,
  SizeFragment,
  ResponsiveImageFragment,
  SingleImageFragment,
  PublicationListingFragment,
  PublicationQueryPreferencesFragment,
} from '@sketch/gql-types'

import {
  defaultLinkValues,
  defaultPermissionsValues,
  defaultResponsiveImageValues,
  defaultSingleImageValues,
  defaultSizeValues,
} from '@sketch/modules-common'

import {
  defaultCommunityGrid,
  defaultCommunityHero,
  defaultCommunityListing,
  defaultCommunitySection,
  defaultCommunitySlider,
  defaultPublicationListing,
  defaultPublicationQueryPreferences,
} from './defaults'

/**
 * Validate the response from the listingData
 * and make sure it matches the apollo expected response
 */
export const normalizeListingData = (data: any) => {
  return merge(defaultPublicationListing, data)
}

type CommunityElements = NonNullable<
  | CommunitySectionFragment
  | CommunityItemFragment
  | PermissionsFragment
  | LinkFragment
  | SizeFragment
  | ResponsiveImageFragment
  | SingleImageFragment
  | PublicationListingFragment
  | PublicationQueryPreferencesFragment
>

const defaultValues: Record<CommunityElements['__typename'], any> = {
  CommunitySection: defaultCommunitySection,
  CommunityGrid: defaultCommunityGrid,
  CommunityListing: defaultCommunityListing,
  CommunitySlider: defaultCommunitySlider,
  Permissions: defaultPermissionsValues,
  Link: defaultLinkValues,
  Size: defaultSizeValues,
  ResponsiveImage: defaultResponsiveImageValues,
  SingleImage: defaultSingleImageValues,
  CommunityHero: defaultCommunityHero,
  PublicationListing: defaultPublicationListing,
  PublicationQueryPreferences: defaultPublicationQueryPreferences,
}

/**
 * Adds the required default values for the given __typename.
 */
const decorateItemWithDefaultValues = (item: any) => {
  const itemKeys = Object.keys(item)

  return itemKeys.reduce((acc, curr) => {
    const itemValue = item[curr] as CommunityElements

    if (Array.isArray(itemValue)) {
      const newItems = visitItems(itemValue)

      return { ...acc, [curr]: newItems }

      // Search of values with __typename
    } else if (typeof itemValue === 'object') {
      const newValue = {
        ...defaultValues[itemValue.__typename],
        ...itemValue,
      }

      return { ...acc, [curr]: newValue }
    }

    return { ...acc, [curr]: itemValue }
  }, {})
}

/**
 * Iterate over all properties of a given object, and call the decorate function
 * on the ones with a __typename.
 */
export const visitItems = (items: CommunityElements[]): CommunityElements[] => {
  return items.map(item => {
    if (item && typeof item === 'object') {
      const itemWithDefaultValues = decorateItemWithDefaultValues(item)

      return { ...defaultValues[item.__typename], ...itemWithDefaultValues }
    }

    return item
  })
}
