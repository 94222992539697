import styled from 'styled-components'

import { skeletonFadeStyles } from '@sketch/components'

export const Wrapper = styled.div`
  position: relative;
`

export const Picture = styled.picture`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
`

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;

  ${skeletonFadeStyles};
`

export const ImageContainer = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
