import React, {
  FC,
  ReactNode,
  CSSProperties,
  useRef,
  useLayoutEffect,
} from 'react'
import styled, { keyframes, css } from 'styled-components'
import { Placement } from '@popperjs/core'

import { Popper } from '../Popper'

import { isTouchDevice, useHover } from '@sketch/utils'
import { Modifier } from 'react-popper'

const reveal = keyframes`
    0% {
      opacity: 0;
      transform: translateY(-5px)
    }
    100% {
      opacity: 1;
      transform: translateY(0)
    }
  `

type TooltipContentProps = Pick<TooltipProps, 'scrollable' | 'placement'>

const TooltipContent = styled.div<TooltipContentProps>(
  ({
    theme: { colors, radii, fontWeights, transitions, fontSizes },
    scrollable,
    placement,
  }) => css`
    /* when there's no title / body structures, we want the tooltip text to look like a title (white text) */
    color: ${colors.ui.tooltips.title};
    background: ${colors.ui.tooltips.background};
    font-size: ${fontSizes.C};
    filter: drop-shadow(0 4px 8px 0 ${colors.ui.tooltips.shadow});
    border-radius: ${radii.xlarge};
    padding: 8px 12px;
    font-weight: ${fontWeights.medium};
    z-index: 9999;
    animation: ${reveal} ${transitions.duration[4]}
      ${transitions.timing.easeOutExponential};
    box-shadow: 0 4px 8px 0 ${colors.ui.tooltips.shadow};
    max-width: 232px;

    /* Forces hardware acceleration in Safari which fixes a persistent shadow bug*/
    transform: translate3d(0, 0, 0);

    mark {
      background: ${colors.sketch.B};
    }

    /* This can be extended for every other tooltip position */
    ${scrollable && placement === 'top'
      ? css`
          &:after {
            content: ' ';
            position: absolute;
            bottom: -10px;
            width: 100%;
            height: 10px;
            left: 0;
          }
        `
      : ''}
  `
)

export interface TooltipChildrenProps {
  ref: React.Ref<any>
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export interface TooltipProps {
  children: ((props: TooltipChildrenProps) => ReactNode) | ReactNode
  content: ReactNode
  /** Styles to apply to the tooltip container */
  contentStyle?: CSSProperties
  /** This prop is deprecated, but still here for backwards compatibily */
  arrowStyle?: CSSProperties
  style?: CSSProperties
  /** space between the tooltip and the trigger */
  spacing?: string
  /** data-testid attr of the container */
  'data-testid'?: string
  disabled?: boolean
  /** hide when the device has touch capacities */
  disableWhenTouchDevice?: boolean
  placement?: Placement
  className?: string
  scrollable?: boolean
  visible?: boolean
  modifiers?: Modifier<any>[]
  ref?: null | HTMLDivElement
  /**
   * In some scenarios it could be semantically incorrect to use
   * a div element for the popper container (default).
   * For example when the tooltip is inside a <p> element, we should not have
   * a div inside a p.
   * In those scenario, use the tooltipContainerAs prop to change the element acting as container
   * for the tooltip (set as "div" by default)
   * (Example: tooltipContainerAs="span")
   */
  tooltipContainerAs?: React.ElementType
}

const TooltipTitle = styled.div`
  color: ${({ theme }) => theme.colors.ui.tooltips.title};
`

const TooltipBody = styled.div`
  color: ${({ theme }) => theme.colors.ui.tooltips.foreground};
`

export const TooltipShortcut = styled.div`
  color: ${({ theme }) => theme.colors.ui.tooltips.shortcutText};

  @media (hover: none) and (pointer: coarse) {
    /* Hide this so mobile devices should not see it */
    display: none;
  }
`

/**
 * Used for contextual help. For instance, when you need to provide
 * more context to a icon button on hover.
 *
 * It can have different placements, you can check all of them via
 * the props table on Storybook.
 */
const TooltipBase: FC<TooltipProps> = ({
  children,
  className,
  content,
  disabled = false,
  disableWhenTouchDevice = true,
  scrollable = false,
  placement,
  ...props
}: TooltipProps) => {
  const [visible, hoverEventHandlers, setVisible] = useHover()
  const forceUpdateRef = useRef<(() => void) | null>()

  const forceShow = () => setVisible(true)
  const forceHide = () => setVisible(false)

  useLayoutEffect(() => {
    // We need to call forceUpdate from Popper
    // to reposition the tooltip whenever the content
    // changes, avoiding misalignment.
    // https://github.com/sketch-hq/Cloud/issues/5334
    const forceUpdateFn = forceUpdateRef?.current
    if (forceUpdateFn) {
      forceUpdateFn()
    }
  }, [content])

  if (disableWhenTouchDevice && isTouchDevice()) {
    return typeof children === 'function' ? (
      <>{children({ ref: null })}</>
    ) : (
      <>{children}</>
    )
  }

  return (
    <Popper
      className={className}
      visible={visible && !disabled}
      placement={placement}
      spacing="5px"
      onTriggerFocus={forceShow}
      onTriggerBlur={forceHide}
      {...props}
      {...hoverEventHandlers}
      popup={({ forceUpdate, ...popperProps }) => {
        forceUpdateRef.current = forceUpdate

        return (
          <TooltipContent
            {...popperProps}
            {...(scrollable ? hoverEventHandlers : {})}
            scrollable={scrollable}
            placement={placement}
            data-testid="tooltip"
          >
            {content}
          </TooltipContent>
        )
      }}
    >
      {children}
    </Popper>
  )
}

export const Tooltip = Object.assign(TooltipBase, {
  Title: TooltipTitle,
  Body: TooltipBody,
  Shortcut: TooltipShortcut,
})
