import { useGetUserCredentialsQuery } from '@sketch/gql-types'
import { useEffect } from 'react'
import env from '@sketch/env-config'
import { setConfig } from '../telemetry'

export const TelemetryConfig: React.FC = () => {
  // keep auth token in sync with the one in cache
  const { data } = useGetUserCredentialsQuery({
    fetchPolicy: 'cache-only',
  })
  useEffect(() => {
    setConfig({
      apiHost: env['telemetry_uri'],
      authToken: data?.userCredentials?.authToken,
    })
  }, [data])

  return null
}
