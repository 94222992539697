import React from 'react'
import styled from 'styled-components'

import { TimeAgo, TableComponents, HighlightedText } from '@sketch/components'
import { ReactComponent as FolderIcon } from '@sketch/icons/folder-closed-16'

import {
  ImageWrapper,
  TableWrapper,
  Name,
  TableCell,
  NameWrapper,
} from '../DocumentItem/DocumentItem.styles'
import { ProjectItemProps, getButtonProps, renderDropdown } from './utils'
import { handleTimeFormat } from '../DocumentItem'
import { StyledStatusIcon } from './ProjectItem.styles'
import { useSearch } from '../../hooks/useSearch'

export const ProjectItemListView = styled(function ProjectItemListView(
  props: ProjectItemProps
) {
  const {
    className,
    name,
    deletedAt,
    renderDropdownItems,
    onClick,
    documentsNumber,
    archivedAt,
    totalCollections,
  } = props
  const { search } = useSearch()
  const dropdownContent = renderDropdownItems?.()
  const a11yProps = onClick ? getButtonProps(onClick) : {}
  const archivedDate = archivedAt
    ? new Date(archivedAt).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      })
    : '-'

  return (
    <TableWrapper className={className} title={name} {...a11yProps}>
      <TableCell>
        <NameWrapper>
          <ImageWrapper title={name}>
            <StyledStatusIcon as={FolderIcon} aria-label={`Project ${name}`} />
          </ImageWrapper>
          <Name>
            <HighlightedText search={search}>{name}</HighlightedText>
          </Name>
        </NameWrapper>
      </TableCell>
      <TableCell>{totalCollections}</TableCell>
      <TableCell>
        <div className="keep-size">
          {deletedAt ? (
            <TimeAgo date={deletedAt} formatter={handleTimeFormat} />
          ) : (
            <>{documentsNumber}</>
          )}
        </div>
      </TableCell>
      <TableCell>{archivedDate}</TableCell>
      {dropdownContent && (
        <TableComponents.TableCellSticky>
          {renderDropdown(dropdownContent, props.deletedAt)}
        </TableComponents.TableCellSticky>
      )}
    </TableWrapper>
  )
})``
