import { useMemo } from 'react'

import {
  useGetCustomerPlanQuery,
  useGetPartnerBillingDetailsQuery,
} from '@sketch/gql-types'

export const usePartnerSubscribeInformation = (customerId?: string) => {
  const customerPlanQuery = useGetCustomerPlanQuery({
    variables: {
      customerId: customerId || '',
    },
    skip: !customerId,
    fetchPolicy: 'network-only',
  })

  const partnerBillingDetailsQuery = useGetPartnerBillingDetailsQuery({
    fetchPolicy: 'network-only',
  })

  const data = useMemo(() => {
    if (!customerPlanQuery.data && !partnerBillingDetailsQuery.data) {
      return undefined
    }

    return {
      ...customerPlanQuery.data,
      ...partnerBillingDetailsQuery.data,
    }
  }, [customerPlanQuery.data, partnerBillingDetailsQuery.data])

  return {
    loading: customerPlanQuery.loading || partnerBillingDetailsQuery.loading,
    error: customerPlanQuery.error || partnerBillingDetailsQuery.error,
    data,
  }
}
