// GraphQl
import { useGetUserNotificationsCountQuery } from '@sketch/gql-types'

import { WatchQueryFetchPolicy } from 'apollo-client'

import { ErrorHandler } from '@sketch/tracing'

interface UseGetNewNotificationsCountProps {
  fetchPolicy?: WatchQueryFetchPolicy
}

export const useGetNewNotificationsCount = (
  props: UseGetNewNotificationsCountProps = {}
): number => {
  const { fetchPolicy = 'cache-first' } = props
  const { data } = useGetUserNotificationsCountQuery({
    fetchPolicy,
    onError: error => {
      ErrorHandler.shouldNeverHappen(
        `Unexpected error fetching me.notifications.meta: '${error.message}'`
      )
    },
  })

  const unreadNotificationsCount =
    data?.me.unreadNotificationsCount?.meta.totalCount ?? 0
  const newNotificationsCount =
    data?.me.newNotificationsCount?.meta.totalCount ?? 0

  return unreadNotificationsCount + newNotificationsCount
}
