import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "cloud-arrow-up-64", viewBox: "0 0 64 64", xmlns: "http://www.w3.org/2000/svg", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M24.5 46.5h-11c-6.075 0-11-4.925-11-11s4.925-11 11-11l.33.005C15.464 15.959 22.978 9.5 32 9.5c9.023 0 16.537 6.459 18.17 15.005l.33-.005c6.075 0 11 4.925 11 11 0 5.979-4.77 10.843-10.712 10.996l-.288.004h-11", stroke: "currentColor", strokeWidth: 3, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M13.5 46.5c-6.075 0-11-4.925-11-11s4.925-11 11-11l.33.005C15.464 15.959 22.978 9.5 32 9.5c9.023 0 16.537 6.459 18.17 15.005l.33-.005c6.075 0 11 4.925 11 11 0 5.979-4.77 10.843-10.712 10.996l-.288.004h-37z", fillOpacity: 0.2, fill: "currentColor" }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 3, strokeLinecap: "round", strokeLinejoin: "round", d: "M32 29v30.5m-7-24l7-7 7 7" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
