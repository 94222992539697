import React from 'react'

import { NavbarFilter, NavbarFilterOld } from './NavbarFilter'

import { ReactComponent as Logo } from '@sketch/icons/logo'

import {
  FixedWidthNavbarSection,
  LogoBlack,
  NavbarButton,
  NavbarSection,
  NavbarLogoWrapper,
  NavbarDivider,
  NavbarBase,
  NavbarTitle,
  navbarTitleCss,
} from './Navbar.styles'

const NavbarLogo = ({ variant }: { variant?: 'colored' | 'black' }) => (
  <NavbarLogoWrapper aria-haspopup="menu" tabIndex={0} role="button">
    {variant === 'black' ? (
      <LogoBlack />
    ) : (
      <Logo width="27px" height="25px" style={{ margin: 'auto' }} />
    )}
  </NavbarLogoWrapper>
)

const Navbar = Object.assign(NavbarBase, {
  Button: NavbarButton,
  Divider: NavbarDivider,
  Section: NavbarSection,
  FixedWidthSection: FixedWidthNavbarSection,
  Logo: NavbarLogo,
  Title: NavbarTitle,
  Filter: NavbarFilter,
  FilterOld: NavbarFilterOld,
})

export { Navbar, navbarTitleCss }
