import React from 'react'

import { useResponsiveDropdown } from '@sketch/components'
import CommentOptionDropdown from '../CommentOptionDropdown'
import Selector from '../Selector'

import { ReactComponent as DotIcon } from '@sketch/icons/dots-3-horizontal-16'

const DROPDOWN_MODIFIERS = [
  { name: 'computeStyles', options: { adaptive: true, gpuAcceleration: true } },
]

interface CommentOptionsProps
  extends React.ComponentProps<typeof CommentOptionDropdown> {
  className?: string
}

const CommentOptions = ({ className, ...props }: CommentOptionsProps) => {
  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: CommentOptionDropdown,
    dropdownProps: props,
    placement: 'bottom-end',
    modifiers: DROPDOWN_MODIFIERS,
  })

  return (
    <>
      {content}
      <Selector
        className={className}
        data-testid="comment-options"
        {...buttonProps}
      >
        <DotIcon role="img" />
        <span className="sr-only">Options</span>
      </Selector>
    </>
  )
}

export default CommentOptions
