import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "sun-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "currentColor", transform: "translate(1 1)", fillRule: "evenodd" },
        React.createElement("path", { d: "M7 4.75c.621 0 1.184.252 1.591.659.407.407.659.97.659 1.591s-.252 1.184-.659 1.591c-.407.407-.97.659-1.591.659a2.243 2.243 0 01-1.591-.659A2.243 2.243 0 014.75 7c0-.621.252-1.184.659-1.591.407-.407.97-.659 1.591-.659z", stroke: "currentColor", strokeWidth: 1.5, fillOpacity: 0.15 }),
        React.createElement("circle", { cx: 7, cy: 1, r: 1 }),
        React.createElement("circle", { cx: 7, cy: 13, r: 1 }),
        React.createElement("path", { d: "M3 4a1 1 0 100-2 1 1 0 000 2zm0 8a1 1 0 100-2 1 1 0 000 2zm8-8a1 1 0 100-2 1 1 0 000 2z" }),
        React.createElement("circle", { cx: 1, cy: 7, r: 1 }),
        React.createElement("circle", { cx: 13, cy: 7, r: 1 }),
        React.createElement("circle", { cx: 11, cy: 11, r: 1 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
