import styled from 'styled-components'
import Separator from '../Separator'

const Section = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  /* Children should be Header, AttributeList or AttributeColumns */
  & > * {
    padding-left: 20px;
    padding-right: 20px;
  }

  ${Separator} {
    margin-right: 0;
  }

  /* Inside a section, gives vertical spacing to children */
  & > * + * {
    margin-top: 12px;
  }
`

export default Section
