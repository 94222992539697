import React, { FC, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { withBox } from '../../Box'

type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement>

export interface FormLabelProps extends LabelProps {
  children: ReactNode
  after?: ReactNode
}

const FormLabelBase: FC<FormLabelProps> = ({ children, after, ...props }) => (
  <label {...props}>
    {children}
    {after}
  </label>
)

export const FormLabel = styled(withBox(FormLabelBase))(
  ({ theme }) => css`
    display: block;

    /* 6px space  does not exist in our space token array */
    /* stylelint-disable-next-line scales/space */
    margin-bottom: 6px;

    color: ${theme.colors.foreground.secondary.C};
    font-size: ${theme.fontSizes.E};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.lineHeights.C};
  `
)
