import styled from 'styled-components'

import {
  LoadingPlaceholder as BaseLoadingPlaceholder,
  Skeleton,
  Table,
  TableComponents,
} from '@sketch/components'

export const MembersTable = styled(Table)`
  width: 100%;
` as typeof Table

export const MembersTableWrapper = styled.div`
  max-height: 300px;
  overflow: auto;
`

export const MinimalTableCell = styled(TableComponents.TableCell)`
  width: 0;
  text-align: center;
`

export const MemberCardCell = styled(TableComponents.TableCell)`
  max-width: 150px;
  word-wrap: break-word;
`

export const SkeletonAvatar = styled(Skeleton)`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  margin-right: 8px;
  border-radius: ${({ theme }) => theme.radii.rounded};
`

export const SkeletonName = styled(Skeleton)`
  margin-bottom: 5px; /* stylelint-disable-line scales/space */
  height: 20px;
  width: 200px;
`

export const LoadingPlaceholder = styled(BaseLoadingPlaceholder)`
  margin: auto;
`
