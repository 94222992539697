import React, { useState } from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'
import { isApolloError } from 'apollo-client'

import { castError } from '@sketch/utils'

import { Button, Tooltip, Input } from '@sketch/components'

import { useToast } from '@sketch/toasts'

import {
  ButtonWrapper,
  Column,
  Columns,
  Fields,
  FormField,
  FullNameWrapper,
  Label,
  StyledForm,
  Wrapper,
  SubTitle,
  Description,
} from './SsoAttributeMappingForm.styles'

import {
  useUpdateWorkspaceCustomerSsoAttributeMappingMutation,
  WorkspaceSsoConfigFragment,
} from '@sketch/gql-types'

const schema = yup.object().shape({
  firstName: yup.string().required('Enter your first name'),
  surname: yup.string().required('Enter your surname'),
  email: yup.string().required('Enter your email address'),
})

type Values = {
  firstName: string
  surname: string
  email: string
}

type AttributeMapping = WorkspaceSsoConfigFragment['attributeMapping']

const initialValues = {
  firstName: '',
  surname: '',
  email: '',
}

interface SsoAttributeMappingFormProps {
  customerId: string
  attributeMapping?: AttributeMapping
  isSsoActive: boolean
}

export const SsoAttributeMappingForm: React.FC<SsoAttributeMappingFormProps> = props => {
  const { customerId, attributeMapping, isSsoActive } = props

  const [isEditingForm, setIsEditingForm] = useState(false)
  const { showToast } = useToast()

  const currentValues = {
    firstName: attributeMapping?.fullName?.firstName ?? initialValues.firstName,
    surname: attributeMapping?.fullName?.surname ?? initialValues.surname,
    email: attributeMapping?.email?.mapping ?? initialValues.email,
  }

  const [
    updateAttributeMapping,
  ] = useUpdateWorkspaceCustomerSsoAttributeMappingMutation({
    onError: 'unsafe-throw-exception',
  })

  const onCancel = () => {
    setIsEditingForm(false)
  }

  const onSubmit = async (values: Values) => {
    try {
      await updateAttributeMapping({
        variables: {
          customerIdentifier: customerId,
          mappings: {
            fullName: {
              firstName: values.firstName,
              surname: values.surname,
              updateOnLogin: true,
            },
            email: {
              mapping: values.email,
              updateOnLogin: true,
            },
          },
        },
      })

      showToast('SSO configuration successfully saved', 'positive')
      setIsEditingForm(false)
    } catch (e) {
      const error = castError(e)
      if (isApolloError(error)) {
        const errorMessage = error.graphQLErrors
          .map(({ message }) => message)
          .join('; ')

        showToast(errorMessage, 'negative')
      } else {
        showToast('Something went wrong. Please try again later.', 'negative')
      }
    }
  }

  const handleEdit = () => {
    setIsEditingForm(true)
  }

  return (
    <Wrapper>
      <SubTitle>Custom Attribute Mapping</SubTitle>
      <Columns>
        <Column>
          <Description>
            You can manually configure your SAML attributes to make them match
            with those of your IdP.
          </Description>
        </Column>
        <Column>
          {!isEditingForm && (
            <Tooltip
              disabled={isSsoActive}
              placement="top"
              content="To configure Attribute Mapping you need to set up SAML first"
            >
              <Button
                data-testid="edit-attributes"
                onClick={handleEdit}
                disabled={!isSsoActive}
                size="40"
              >
                Edit&hellip;
              </Button>
            </Tooltip>
          )}
        </Column>
      </Columns>
      <Formik
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={currentValues}
      >
        {formikProps => (
          <StyledForm>
            <Fields>
              <Label>Full Name</Label>
              <FullNameWrapper>
                <FormField
                  name="firstName"
                  errorText={
                    formikProps.touched['firstName']
                      ? formikProps.errors['firstName']
                      : undefined
                  }
                >
                  <Input
                    name="firstName"
                    aria-label="firstName"
                    type="text"
                    value={formikProps.values['firstName']}
                    placeholder="first_name"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    disabled={!isEditingForm}
                  />
                </FormField>
                <FormField
                  label=""
                  name="surname"
                  errorText={
                    formikProps.touched['surname']
                      ? formikProps.errors['surname']
                      : undefined
                  }
                >
                  <Input
                    name="surname"
                    aria-label="surname"
                    type="text"
                    value={formikProps.values['surname']}
                    placeholder="surname"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    disabled={!isEditingForm}
                  />
                </FormField>
              </FullNameWrapper>
              <Label>Email</Label>
              <FormField
                name="email"
                errorText={
                  formikProps.touched['email']
                    ? formikProps.errors['email']
                    : undefined
                }
              >
                <Input
                  name="email"
                  aria-label="email"
                  type="text"
                  value={formikProps.values['email']}
                  placeholder="email"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  disabled={!isEditingForm}
                />
              </FormField>
            </Fields>
            {isEditingForm && (
              <ButtonWrapper>
                <Button
                  type="button"
                  disabled={formikProps.isSubmitting}
                  onClick={e => {
                    formikProps.handleReset(e)
                    onCancel()
                  }}
                  size="40"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={formikProps.isSubmitting}
                  loading={formikProps.isSubmitting}
                  size="40"
                >
                  Save Changes
                </Button>
              </ButtonWrapper>
            )}
          </StyledForm>
        )}
      </Formik>
    </Wrapper>
  )
}
