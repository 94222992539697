export enum LineCapStyle {
  Butt = 'butt',
  Round = 'round',
  Square = 'square',
}

export enum LineJoinStyle {
  Bevel = 'bevel',
  Miter = 'miter',
  Round = 'round',
}

export enum BorderPosition {
  Center = 'center',
  Inside = 'inside',
  Outside = 'outside',
}

export enum BlendMode {
  Normal = 'normal',
  DestAtop = 'destatop',
  Clear = 'clear',
  Source = 'source',
  Darken = 'darken',
  Multiply = 'multiply',
  ColorBurn = 'color burn',
  Lighten = 'lighten',
  Screen = 'screen',
  ColorDodge = 'color dodge',
  Overlay = 'overlay',
  SoftLight = 'soft light',
  HardLight = 'hard light',
  Difference = 'difference',
  Exclusion = 'exclusion',
  Hue = 'hue',
  Saturation = 'saturation',
  Color = 'color',
  Luminosity = 'luminosity',
  PlusLighter = 'plus lighter',
  PlusDarker = 'plus darker',
}

export enum FillType {
  Color = 'color',
  Gradient = 'gradient',
  Pattern = 'pattern',
}

export enum ImageFillType {
  Fit = 'fit',
  Fill = 'fill',
  Stretch = 'stretch',
  Tile = 'tile',
}

export enum BlurType {
  Motion = 'motion',
  Zoom = 'zoom',
  Background = 'background',
  Gaussian = 'gaussian',
}

export enum GradientType {
  Linear = 'linear',
  Radial = 'radial',
  Angular = 'angular',
}

export enum TextTransform {
  Uppercase = 'uppercase',
  Lowercase = 'lowercase',
}

export enum TextDecoration {
  LineThrough = 'line-through',
  Underline = 'underline',
}

export enum VerticalTextAlignment {
  Top = 'top',
  Middle = 'middle',
  Bottom = 'bottom',
}

export enum HorizontalTextAlignment {
  Left = 'left',
  Right = 'right',
  Center = 'center',
  Justified = 'justified',
}

export enum VisibleScaleType {
  Scale = 1,
  Width = 2,
  Height = 3,
}

export enum ExportFormatType {
  PNG = 'PNG',
  JPG = 'JPG',
  TIFF = 'TIFF',
  WEBP = 'WEBP',
  PDF = 'PDF',
  EPS = 'EPS',
  SVG = 'SVG',
  HEIC = 'HEIC',
}

export enum ElementType {
  Artboard = 'artboard',
  SymbolMaster = 'symbolMaster',
  SymbolInstance = 'symbolInstance',
  Hotspot = 'hotspot',
  Bitmap = 'bitmap',
  Group = 'group',
  Oval = 'oval',
  Polygon = 'polygon',
  Rectangle = 'rectangle',
  ShapeGroup = 'shapeGroup',
  ShapePath = 'shapePath',
  Star = 'star',
  Text = 'text',
  Triangle = 'triangle',
  Slice = 'slice',
  Page = 'page', // This enum value is a SketchSceneLib concept only
  Undefined = 'undefined', // This enum value is a SketchSceneLib concept only
}

export enum CornerStyle {
  Rounded = 'rounded',
  Smooth = 'smooth',
  Angled = 'angled',
  InsideSquare = 'inside square',
  InsideArc = 'inside arc',
}
