import { v1 as uuid } from 'uuid'
import { useFlag } from '@sketch/modules-common'

import {
  MoveAnnotationMutationVariables,
  useApolloClient,
  useMoveAnnotationMutation,
} from '@sketch/gql-types'

import { useStableHandler } from '@sketch/utils'
import { ErrorHandler } from '@sketch/tracing'

import {
  Coordinates,
  MouseCoordinates,
  VariablesWithAdditionalParameters,
} from '../types'
import { useAnnotationOverlayContext } from '../hooks'
import { moveAnnotationInCache } from '../utils/annotationCacheOperations'
import {
  getBaseAnnotation,
  getOptimisticArtboardSubjectFromInputSubject,
  getOptimisticSubjectFromInputSubject,
  isArtboardAnnotationSubjectInput,
} from '../utils/cache'
import { AnnotationQueryVariablesContextValue } from '../context/AnnotationQueryVariablesProvider'

const preventiveParseFloat = (string: string) => {
  const number = parseFloat(string)
  return isNaN(number) ? 0 : number
}

const createOptimisticResponse = <T extends MoveAnnotationMutationVariables>(
  ...args: VariablesWithAdditionalParameters<T>
) => {
  const [variables, additionalParams] = args
  const { annotationIdentifier, coordinates, subject } = variables

  const currentSubject = !isArtboardAnnotationSubjectInput(subject)
    ? getOptimisticSubjectFromInputSubject(subject)
    : getOptimisticArtboardSubjectFromInputSubject(
        subject,
        additionalParams?.permanentPageId!
      )

  return {
    __typename: 'RootMutationType',
    moveAnnotation: {
      __typename: 'MoveAnnotationResponse',
      annotation: {
        __typename: 'Annotation',
        identifier: annotationIdentifier,
        subscriptionStatus: 'ON',
        currentSubject,
        coordinates: {
          ...coordinates,
          __typename: 'AnnotationCoordinates',
          identifier: uuid(),
        },
        share: {
          __typename: 'Share',
          identifier: uuid(),
          subscriptionStatus: 'ON',
        },
      },
    },
  } as const
}

const useMoveAnnotation = (
  params: AnnotationQueryVariablesContextValue,
  parentWrapper: React.RefObject<HTMLElement>
) => {
  const context = useAnnotationOverlayContext()
  const includeHasUnreadComments = useFlag('pages-unread-annotations')

  const apolloClient = useApolloClient()
  const [mutation] = useMoveAnnotationMutation({
    onError: 'show-toast',
  })

  return useStableHandler(
    async (
      annotationIdentifier: string,
      mouseCoordinates: MouseCoordinates
    ) => {
      if (context?.status !== 'available' || !parentWrapper.current) {
        return
      }

      const canvasRect = parentWrapper.current.getBoundingClientRect()

      /**
       * If the parentWrapper contains any padding that
       * could affect the expected positioning of the canvas
       * we need to remove it from the mouse coordinates
       */
      const canvasRectStyle = getComputedStyle(parentWrapper.current)

      const canvasRelativePosition = {
        clientX:
          mouseCoordinates.clientX -
          canvasRect.x -
          preventiveParseFloat(canvasRectStyle.paddingLeft),
        clientY:
          mouseCoordinates.clientY -
          canvasRect.y -
          preventiveParseFloat(canvasRectStyle.paddingTop),
      }

      let coordinates = context.getPositionRelativeToOrigin(
        canvasRelativePosition
      )

      if (!coordinates) {
        /* If there's no coordinates there's no point in continuing */
        ErrorHandler.shouldNeverHappen(
          `User is moving an annotation but the "getPositionRelativeToOrigin" is not returning a valid position`
        )
        return
      }

      const element = await context.getElementAtPosition(canvasRelativePosition)
      if (!element) {
        /* If there's no element (artboard/page) there's no point in continuing */
        ErrorHandler.shouldNeverHappen(
          `User is moving an annotation but the "getElementAtPosition" is not returning a valid element`
        )
        return
      }

      let prefixBounds: Coordinates | undefined
      if (element && element.type === 'ARTBOARD') {
        prefixBounds = {
          x: element.bounds.x,
          y: element.bounds.y,
        }

        coordinates = {
          x: coordinates.x - prefixBounds.x,
          y: coordinates.y - prefixBounds.y,
        }
      }

      const baseVariables = {
        annotationIdentifier,
        coordinates,
        includeHasUnreadComments,
      }

      const subject =
        element.type === 'ARTBOARD'
          ? { type: 'ARTBOARD' as const, permanentId: element.permanentId }
          : { type: 'PAGE' as const, permanentId: element.permanentId }

      const permanentPageId =
        element.type === 'ARTBOARD'
          ? element.permanentPageId
          : '' /* This case will never occur given that we only need the "permanentPageId" when the type is artboard */

      const variables = { ...baseVariables, subject }
      const optimisticResponse = !isArtboardAnnotationSubjectInput(subject)
        ? () => createOptimisticResponse(variables)
        : () =>
            createOptimisticResponse(
              { ...baseVariables, subject },
              { permanentPageId }
            )

      const cachedAnnotation =
        getBaseAnnotation(apolloClient, annotationIdentifier) || undefined

      return mutation({
        variables,
        optimisticResponse,
        update: (cache, { data }) => {
          const { annotation } = data?.moveAnnotation || {}
          if (!annotation) {
            return
          }

          const { shareIdentifier, versionIdentifier } = params

          /**
           * Check if BaseAnnotation fragment exists
           * if it does it means that a listing has been loaded with this
           * identifier, meaning we need to update the listings
           */
          const baseAnnotation = getBaseAnnotation(cache, annotation.identifier)

          baseAnnotation &&
            moveAnnotationInCache(
              apolloClient,
              { shareIdentifier, versionIdentifier: versionIdentifier || '' },
              { ...baseAnnotation, ...annotation },
              cachedAnnotation
            )
        },
      })
    }
  )
}

export default useMoveAnnotation
