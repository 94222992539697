import { parse } from 'query-string'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useHistory, useLocation } from 'react-router'
import { addAnnotationToQueryParams } from '../utils'

const useActiveAnnotation = () => {
  const location = useLocation()
  const { replace } = useHistory()

  const queryParams = parse(location.search)
  const queryParamsRef = useRef(queryParams)

  useEffect(() => {
    queryParamsRef.current = queryParams
  }, [queryParams])

  /**
   * Unify the active annotation query param response
   */
  const activeAnnotation = Array.isArray(queryParams.annotation)
    ? queryParams.annotation[0]!
    : queryParams.annotation || undefined

  /**
   * Render preventive "setActiveAnnotation"
   */
  const setActiveAnnotation = useCallback(
    (annotationIdentifier?: string) => {
      // Prevent unnecessary state updates
      if (queryParamsRef.current?.annotation === annotationIdentifier) {
        return
      }

      const search = addAnnotationToQueryParams(
        queryParamsRef.current,
        annotationIdentifier
      )

      replace({ ...location, search })
    },
    [location, replace]
  )

  return useMemo(() => [activeAnnotation, setActiveAnnotation] as const, [
    activeAnnotation,
    setActiveAnnotation,
  ])
}

export default useActiveAnnotation
