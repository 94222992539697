import { ReactComponent as ChevronsUpDownSvg } from '@sketch/icons/chevrons-up-down-24'
import styled from 'styled-components'

import { selectBaseStyles } from '../Select'
import { truncateStyles } from '../Truncate'

export const SelectDropdownButtonTrigger = styled.button`
  ${selectBaseStyles}

  position: relative;
  text-align: left;

  min-height: 40px;
  padding-right: 44px;
  line-height: 1;

  cursor: pointer;

  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  box-shadow: 0px 0px 0px 2px transparent;

  transition: box-shadow 125ms ease-in-out;

  /* Prevent a large name from increasing the height */
  ${truncateStyles};

  &[aria-valuetext] {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  /* && is needed to increase specificity and avoid these styles to be
     overwritten by this global selector ":focus:not(:focus-visible)" */
  &&[aria-expanded='true'],
  &&:focus-visible {
    /* Native focus ring */
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }

  :disabled {
    cursor: default;
  }
`

export const ChevronsUpDownIcon = styled(ChevronsUpDownSvg)`
  width: 24px;
  height: 24px;
  margin: auto;

  position: absolute;
  top: 0;
  right: 8px;
  bottom: 0;
`
