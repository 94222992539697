// Taken from https://gist.github.com/Craga89/2829457
export const getiOSVersion = (userAgent: string) =>
  parseFloat(
    (
      '' +
      (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(
        userAgent
      ) || [0, ''])[1]
    )
      .replace('undefined', '3_2')
      .replace('_', '.')
      .replace('_', '')
  ) || false

type SafariUserAgent = {
  hardware: 'Macintosh' | 'iPhone' | 'iPad'
  version: {
    major: number
    minor: number
    patch?: number
  }
}

const SAFARI_USER_AGENT_REGEX = /(iPhone|Macintosh|iPad)(.*)(Version)\/(0|[1-9]\d*)\.(0|[1-9]\d*)\.?(0|[1-9]\d*)?/

/**
 * Parses a Safari user agent string into structured data. If the user agent string
 * isn't for a Safari browser, null is returned.
 *
 * User Agent parsing is generally discouraged and can never be 100% reliable. So favour
 * feature detection when possible, and do not use this method for critical functionality.
 */
export const parseSafariUserAgent = (
  userAgent: string = window.navigator.userAgent
): SafariUserAgent | null => {
  if (
    userAgent.includes('Chrome/') ||
    userAgent.includes('Chromium/') ||
    userAgent.includes('Opera/') ||
    userAgent.includes('Firefox/')
  )
    return null

  const match = userAgent.match(SAFARI_USER_AGENT_REGEX)

  if (!match) return null

  const hardware: string = match[1]

  if (hardware !== 'Macintosh' && hardware !== 'iPad' && hardware !== 'iPhone')
    return null

  return {
    hardware,
    version: {
      major: parseInt(match[4]) || 0,
      minor: parseInt(match[5]) || 0,
      patch: parseInt(match[6]) || 0,
    },
  }
}

/**
 * Given a User Agent it returns the respective web browser name or 'Browser'
 * if it wasn't able to identify the browser.
 *
 * User Agent parsing is generally discouraged but as this function doesn't
 * focus on getting the current User Agent, but User Agents received from
 * the Backend, feature detection isn't possible.
 */
export const getBrowserFromUserAgent = (userAgent: string) => {
  const browserTests = [
    {
      tests: [/\sedg\//i, /edg([ea]|ios)/i],
      name: 'Microsoft Edge',
    },
    {
      tests: [/chrome|crios|crmo/i],
      name: 'Google Chrome',
    },
    {
      tests: [/safari|applewebkit/i],
      name: 'Safari',
    },
    {
      tests: [/firefox|iceweasel|fxios/i],
      name: 'Firefox',
    },
    {
      tests: [/opera/i, /opr\/|opios/i],
      name: 'Opera',
    },
    {
      tests: [/msie|trident/i],
      name: 'Internet Explorer',
    },
  ]

  for (const browser of browserTests) {
    if (browser.tests.some(test => !!userAgent.match(test))) {
      return browser.name
    }
  }

  return 'Browser'
}
