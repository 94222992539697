import React, { ReactNode, MouseEvent } from 'react'
import { LocationDescriptorObject } from 'history'
import { Link } from '@sketch/components'
import { VersionedRoutes, RouteParam } from '@sketch/modules-common'
import { useVersioning } from '../../ShareVersionContext'

export interface VersionLinkProps<Key extends keyof VersionedRoutes> {
  to: {
    routeKey: Key
    routeParams: RouteParam<Key>
  } & LocationDescriptorObject
  children?: ReactNode
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
}

const VersionLink = <Key extends keyof VersionedRoutes>({
  children,
  to: { routeKey, routeParams, ...toProps },
  ...props
}: VersionLinkProps<Key>) => {
  const { getPathname } = useVersioning()
  const pathname = getPathname({ routeKey, routeParams })
  return (
    <Link {...props} to={{ pathname, ...toProps }}>
      {children}
    </Link>
  )
}

export default VersionLink
