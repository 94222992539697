import styled, { css } from 'styled-components'
import {
  ListItemIconBase,
  ListItemText,
  ListItemWrapperBase,
  ListItemActionWrapperBase,
} from '@sketch/components'

import { Section } from './AboutTab.styles'

const AssetSection = styled(Section)`
  padding: 8px;
`
const AssetButtonSubText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.C};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`
const disabledStyle = css`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  cursor: not-allowed;
`

const AssetButton = styled.button.attrs<
  { 'data-testid'?: string },
  { 'data-testid': string }
>(props => ({
  'data-testid': props['data-testid'] ?? 'document-export-assets-button',
}))<{ disabled?: boolean }>`
  cursor: pointer;
  border: none;
  background: none;
  width: 100%;
  padding: 0;
  text-align: start;
  ${({ disabled }) => disabled && disabledStyle}

  &:hover {
    border-radius: ${({ theme }) => theme.radii.large};
    background: ${({ theme }) => theme.colors.state.hover};
  }
`

const AssetItemTextWrapper = styled(ListItemText)`
  display: flex;
  flex-direction: column;
`
// the tokens icon needs the extra width to show as 16px wide
const AssetItemIcon = styled(ListItemIconBase)<{ $extraWidth?: boolean }>`
  width: ${({ $extraWidth }) => ($extraWidth ? '26px' : undefined)};
`
const AssetItemText = styled.span``
const AssetItemActionWrapper = styled(ListItemActionWrapperBase)``

const defaultStyle = css`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  ${AssetItemText} {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
  }
`
const activeStyle = css`
  color: ${({ theme }) => theme.colors.sketch.A};
  > span {
    /* We need to override the spinner core colours */
    color: currentColor !important; /* stylelint-disable-line declaration-no-important */
  }

  ${AssetButtonSubText} {
    display: none;
  }
`
const errorStyle = css`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  cursor: not-allowed;

  ${AssetItemText} {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
  }

  ${AssetButtonSubText} {
    color: ${({ theme }) => theme.colors.state.negative.A};
  }
`

interface AssetItemWrapperProps {
  disabled?: boolean
  error?: boolean
  active?: boolean
}
const AssetItemWrapper = styled(ListItemWrapperBase)<AssetItemWrapperProps>`
  align-items: start;
  ${({ active, disabled, error }) => {
    if (active) {
      return css`
        ${activeStyle}
      `
    } else if (disabled) {
      return css`
        ${disabledStyle}
      `
    } else if (error) {
      return css`
        ${disabledStyle}
        ${errorStyle}
      `
    } else {
      return css`
        ${defaultStyle}
      `
    }
  }}
`

export {
  AssetButton,
  AssetButtonSubText,
  AssetSection,
  AssetItemIcon,
  AssetItemText,
  AssetItemTextWrapper,
  AssetItemWrapper,
  AssetItemActionWrapper,
}
