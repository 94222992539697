import React, { useEffect, useMemo, useState, useRef } from 'react'
import { Portal } from 'react-portal'
import { useHistory } from 'react-router-dom'

import { ModalContext, ShowModal } from './ModalContext'
import { ModalTransition } from './ModalTransition'

interface ModalProviderProps {
  onHideModal?: () => void
  onShowModal?: () => void
}

const ModalPortal: React.FC = ({ children }) => {
  const [documentElement, setDocumentElement] = useState<HTMLElement | null>(
    null
  )

  useEffect(() => {
    // Save the modal-root node when mounting (if exists) so the portal open in a specific location
    setDocumentElement(document && document.getElementById('modal-root'))
  }, [])

  return <Portal node={documentElement}>{children}</Portal>
}

export const ModalProvider: React.FC<ModalProviderProps> = props => {
  const { children, onShowModal, onHideModal } = props
  const history = useHistory()
  const [modal, setModal] = useState<Parameters<ShowModal>>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const closeOnRouteChange = useRef(true)

  const methods = useMemo(() => {
    const auxShowModal = (
      modal: React.ComponentType<any>,
      props?: Record<string, unknown>
    ) => {
      setModal([modal, props])
      setIsModalOpen(true)
    }

    // This is usually the first step, either opens a modal or overrides one
    const showModal: ShowModal = (modal, props, options) => {
      closeOnRouteChange.current = options?.closeOnRouteChange ?? true
      auxShowModal(modal, { ...props, cancelOnClickOutside: true })
    }

    // This method is the same as showModal one but forces the "cancelOnClickOutside" to be undefined
    const showRestrictedModal: ShowModal = (modal, props) => {
      auxShowModal(modal, props)
    }

    // This modal closes the modal
    const hideModal = () => {
      setIsModalOpen(false)
    }

    return {
      showModal,
      showRestrictedModal,
      hideModal,
      /* This type is overrided to make sure the types pass, but prevent functionality to break */
      onCancel: hideModal as any,
    }
  }, [])

  useEffect(() => {
    if (isModalOpen) {
      // This method will be called when any modal is shown
      onShowModal?.()

      return () => {
        // This method will be called when any modal is hidden
        onHideModal?.()
      }
    }
  }, [isModalOpen, onShowModal, onHideModal])

  useEffect(() => {
    // We need to check if we use it without a router. This is mostly for testing
    if (!history) return

    const unlistenRouteChanges = history.listen(() => {
      if (!closeOnRouteChange.current) {
        return
      }

      methods.hideModal()
    })

    return unlistenRouteChanges
  }, [history, methods, closeOnRouteChange])

  const [Modal, modalProps] = modal || []

  const state = useMemo(
    () => ({
      ...methods,
      isModalOpen,
      modal: Modal,
    }),
    [methods, isModalOpen, Modal]
  )

  return (
    <ModalContext.Provider value={state}>
      {children}
      <ModalPortal>
        <ModalTransition
          show={isModalOpen}
          onExited={() => {
            // We wait to have the animation finish to remove the components from state
            setModal(undefined)
          }}
        >
          <>{Modal && <Modal {...state} {...modalProps} />}</>
        </ModalTransition>
      </ModalPortal>
    </ModalContext.Provider>
  )
}
