import { useRef, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import isEqual from 'lodash.isequal'

import { NavigationStack, ShareViewsNavigationLocationState } from './types'
import {
  createNavigationStackNodeForRoute,
  getUpdatedNavigationStack,
  isRouteToIgnore,
} from './utils'
import { useStableHandler } from '@sketch/utils'

const defaultNavigationStack: NavigationStack = [
  {
    view: 'document',
    level: 1,
  },
]

/**
 * Hook listening to location changes to detect navigation between the different
 * views of the share module and re-calculate the current navigation stack accordingly.
 *
 * Our navigation stack is a list of "nodes", each of them corresponding to a view. The goal of this stack
 * is to provide a back button to the user to come back to the "previous view". The views
 * (and therefore the nodes of the stack) use a system of levels to create a certain hierarchy between the views:
 * - documentView (lvl 1) > pageView (lvl 2) > canvasView (lvl 3) > artboardView (lvl 4)
 *
 * In reality, the way users navigate through our share views is not a linear path but more of a graph on which
 * a user can access a certain node using different optional node creating different potential paths.
 * The hierarchy system allows us to come back to a previous node of the stack even if the user has not
 * used the back button.
 *
 * Example 1: document view (a) -> page view (b) -> artboard view (c) --(navigate through breadcrumbs)-> page view (d).
 * In this scenario the user went backward in terms of level without using the back button.
 * With a traditional stack system, when the use travels to the page view (b) through the breadcrumbs (d), the previous
 * view would be "artboard view (c)" but in our case we want "document view (a)" to be the previous view.
 *
 * Example 2: document view (a) -> page view (b) -> canvas view (c) -> artboard view (d).
 * In this scenario if the user keeps pressing the back button they would simply revert the stack:
 * artboard view (d) -> canvas view (c) -> page view (b) -> document view (a)
 *
 * Example 3: document view (a) -> canvas view (b) -> artboard view (c) --(navigate through breadcrumbs)-> page view (d).
 * In this scenario, we are navigating backward to a node we have not previously visited "page view (d)" and
 * the previous view should be "document view (a)"
 *
 * Note: in order to preserve the internal share view navigation stack across potential browser history
 * navigation, this hook also ensures that each browser history entry contains the shareViewNavigation stack
 * in the history location state.
 *
 * @returns the internal share views navigation stack for the current location.
 */
export function useShareViewsNavigationStack() {
  const location = useLocation<ShareViewsNavigationLocationState | undefined>()

  const lastKnownNavigationStack = useRef<NavigationStack>(
    defaultNavigationStack
  )

  // Pretend the view has not changed when going on views
  // that just do a redirect.
  const shouldIgnoreRoute = useMemo(() => {
    return isRouteToIgnore(location.pathname)
  }, [location.pathname])

  const currentNavigationStack = useMemo(() => {
    if (shouldIgnoreRoute) {
      // Pretend the view has not changed
      return lastKnownNavigationStack.current
    }

    const shareViewsNavigationFromLocationState =
      location.state?.shareViewsNavigation

    // Checking if this is a pre-existing or new history entry
    if (shareViewsNavigationFromLocationState) {
      // This is a pre-existing history API entry (user navigating through browser back/next button).
      // Re-use the navigation stack we had saved in the location state for that particular history entry.
      return shareViewsNavigationFromLocationState.navigationStack
    } else {
      // A new entry has been pushed to the history API (user navigating through links),
      // we will probably need to update the current navigation stack.

      return getNavigationStackForNewlyPushedHistoryEntry(
        lastKnownNavigationStack.current,
        location.pathname,
        location.search
      )
    }
  }, [
    location.pathname,
    location.search,
    location.state?.shareViewsNavigation,
    shouldIgnoreRoute,
  ])

  useSaveNavigationStackToHistoryIfNeeded(
    currentNavigationStack,
    shouldIgnoreRoute
  )

  if (!shouldIgnoreRoute) {
    lastKnownNavigationStack.current = currentNavigationStack
  }

  return currentNavigationStack
}

/**
 * A newly pushed history entry means that the user has navigated
 * to a new URL (without using back/forward history buttons). We need to
 * detect if the URL corresponds to a new view and update the navigation stack
 * accordingly.
 * @returns - The new navigation stack corresponding to the new view, or the previous
 * navigation stack when the user is still on the same view.
 */
function getNavigationStackForNewlyPushedHistoryEntry(
  lastKnownNavigationStack: NavigationStack,
  currentLocationPathname: string,
  currentLocationSearch: string
): NavigationStack {
  const currentNavigationStackNode = createNavigationStackNodeForRoute(
    currentLocationPathname,
    currentLocationSearch
  )

  const previousNavigationStackNode =
    lastKnownNavigationStack[lastKnownNavigationStack.length - 1]

  const isSameAsPreviousView = isEqual(
    currentNavigationStackNode,
    previousNavigationStackNode
  )

  // This happen when updating the current view URL query params or hash,
  // location object has changed but not the actual view.
  if (isSameAsPreviousView) {
    // Stack has not changed since last time.
    return lastKnownNavigationStack
  } else {
    // Stack has changed, get a new updated one.
    return getUpdatedNavigationStack(
      lastKnownNavigationStack,
      currentNavigationStackNode
    )
  }
}

/**
 * Replace the current history entry with a copy that has the
 * shareViewsNavigation stack attached to it.
 * It's important to ensure that each entry of the browser history API
 * contains the navigation stack it had when the entry was created.
 * This allows us to restore the share view navigation stack when users
 * travel using the browser history back/forward buttons.
 */
function useSaveNavigationStackToHistoryIfNeeded(
  currentNavigationStack: NavigationStack,
  shouldIgnoreRoute: boolean
) {
  const history = useHistory<ShareViewsNavigationLocationState | undefined>()

  const updateLocationState = useStableHandler(
    (newLocationState: ShareViewsNavigationLocationState) => {
      history.replace({
        ...history.location,
        state: {
          ...history.location.state,
          ...newLocationState,
        },
      })
    }
  )

  useEffect(() => {
    if (shouldIgnoreRoute) {
      return
    }

    const shareViewsNavigationFromLocationState =
      history.location.state?.shareViewsNavigation

    // If not already present in location state
    if (!shareViewsNavigationFromLocationState) {
      const newLocationState: ShareViewsNavigationLocationState = {
        shareViewsNavigation: {
          navigationStack: currentNavigationStack,
        },
      }

      updateLocationState(newLocationState)
    }
  }, [
    currentNavigationStack,
    history.location.state?.shareViewsNavigation,
    shouldIgnoreRoute,
    updateLocationState,
  ])
}
