import { createContext } from 'react'
import { Stripe, StripeElements } from '@stripe/stripe-js'

export type StripeLoadingState = 'pending' | 'success' | 'error'

export interface StripeContextType {
  status: StripeLoadingState
  stripe?: Stripe
  elements?: StripeElements
  load: () => Promise<Stripe>
}

export const BASE_STATE: StripeContextType = {
  load: async () => {
    // To be consistent with the load return type
    // we opted to throw an error here in an async function
    throw new Error('Stripe context failed to initialize')
  },
  status: 'pending',
}

export const StripeContext = createContext(BASE_STATE)
