import { lightTheme } from '@sketch/global-styles'
import styled from 'styled-components'

export const PrototypeLoadingPlaceholder = styled.div`
  /* Hard coding this because cant guarantee this component always appears within a <ThemeProvider theme={darkTheme}> */
  color: ${lightTheme.colors.foreground.primary.A};
  display: flex;
  flex-direction: column;
  align-items: center;
`
