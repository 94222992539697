import { useForTablet } from '@sketch/components'
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react'

type InspectItem = string | null

const context = createContext<
  [InspectItem, Dispatch<SetStateAction<InspectItem>>]
>([null, () => {}])

interface InspectContextProviderProps {
  onItemSelect?: () => void
}

export const InspectContextProvider: React.FC<InspectContextProviderProps> = ({
  children,
  onItemSelect,
}) => {
  const value = useState<string | null>(null)
  const [selected, setSelected] = value
  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger

  useEffect(() => {
    // When we switch between mobile and desktop, we need to reset
    // the selected item. Otherwise it can lead to an infinite loop
    // if we have the inspector opened
    if (isMobile) {
      setSelected(null)
      return
    }

    if (selected) {
      onItemSelect?.()
    }
  }, [selected, setSelected, onItemSelect, isMobile])

  return <context.Provider value={value}>{children}</context.Provider>
}

export function useInspectContext() {
  const [inspectItem, setInspectItem] = useContext(context)

  const handleSelectItem = useCallback(
    (identifier: string | null) => {
      setInspectItem(inspectItem =>
        inspectItem === identifier ? null : identifier
      )
    },
    [setInspectItem]
  )

  return { selectedItem: inspectItem, handleSelectItem }
}
