import { useContext, useEffect, useMemo, useState } from 'react'
import {
  GetPageImageTokensDocument,
  GetPageImageTokensQuery,
  GetPageImageTokensQueryVariables,
  useApolloClient,
} from '@sketch/gql-types'
import { ShareVersionContext } from '../../versioning'
import { useIsMountedRef } from '@sketch/utils'
import { ApolloQueryResult } from 'apollo-client'

type ImageTokens = Record<string, string>

type Page = {
  uuid: string | null
  presentationFile: { imageToken: string } | null
}

/**
 * Presentation file access tokens are scoped per document page, so we need to iteratively fetch
 * all pages to build a mapping of page uuids to image tokens.
 * @TODO This should ideally be fixed on the BE. Either by making the image tokens work globally
 * across the Document, or better yet would be to move the presentation file logic and machinery off
 * of the Page type, and onto the Document type. Presentation files and their assets are global to
 * the Document, not scoped to a single Page.
 */
export function useImageTokens(skip: boolean): ImageTokens | null {
  const shareVersionContext = useContext(ShareVersionContext)!
  const { versionShortId, share } = shareVersionContext
  const { query } = useApolloClient()
  const [pages, setPages] = useState<Page[] | null>(null)
  const isMounted = useIsMountedRef()

  useEffect(() => {
    if (skip) return

    async function fetchAllPages() {
      async function fetchPages(
        accumulator: Page[],
        after: string | null
      ): Promise<Page[] | null> {
        let res: ApolloQueryResult<GetPageImageTokensQuery>
        try {
          res = await query<
            GetPageImageTokensQuery,
            GetPageImageTokensQueryVariables
          >({
            query: GetPageImageTokensDocument,
            variables: {
              shareIdentifier: share.identifier,
              versionShortId,
              after,
            },
          })
        } catch (e) {
          return null
        }

        const { data, errors } = res

        if ((errors?.length ?? 0) > 0) return null

        const nextAfter =
          data?.share?.version?.document?.pages?.meta?.after ?? null
        const entries = data?.share?.version?.document?.pages?.entries ?? []
        accumulator = [...accumulator, ...entries]

        if (!nextAfter) return accumulator

        return await fetchPages(accumulator, nextAfter)
      }
      const fetchedPages = await fetchPages([], null)
      if (isMounted.current) setPages(fetchedPages)
    }

    fetchAllPages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip])

  return useMemo(() => {
    if (!pages) return null

    const imageTokens: ImageTokens = {}

    for (const page of pages) {
      imageTokens[page.uuid ?? ''] = page.presentationFile?.imageToken ?? ''
    }

    return imageTokens
  }, [pages])
}
