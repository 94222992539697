import styled, { css } from 'styled-components'
import { light } from '@sketch/global-styles'

export type WorkspaceSidebarNoticeVariant = 'standard' | 'black'

export const WorkspaceSidebarNotice = styled.div``

export type WorkspaceSidebarNoticeContainerProps = {
  $variant: WorkspaceSidebarNoticeVariant
  $isDarkMode: boolean
}

/**
 * Define css variables for descendants of the WorkspaceSidebarNoticeContainer to use to styles
 * the notice content based on the background color of the container.
 */
function setColorVariablesBasedOnVariant() {
  return css<WorkspaceSidebarNoticeContainerProps>`
    ${({ theme, $variant, $isDarkMode }) => {
      let arrowColor = theme.colors.border.B

      /*
        In this case we want the "Light" tertiary.B to actually make the background darker
        because our "Dark" tertiary.B is a very transparent white color which makes the background brighter
        */
      let backgroundColor = $isDarkMode
        ? light.colors.background.tertiary.B
        : theme.colors.background.secondary.A

      let textColor = theme.colors.foreground.secondary.A
      let linkColor = theme.colors.foreground.secondary.A
      let linkColorHover = theme.colors.foreground.secondary.C
      let icon = theme.colors.foreground.secondary.C

      if ($variant === 'black') {
        backgroundColor = theme.colors.background.primary.B
        arrowColor = theme.colors.background.primary.C
        textColor = theme.colors.ui.tooltips.title
        linkColor = theme.colors.ui.tooltips.shortcutText
        linkColorHover = theme.colors.foreground.primary.A
        icon = $isDarkMode ? icon : theme.colors.foreground.primary.B
      }

      return css`
        --notice__link-color: ${linkColor};
        --notice__link-color-hover: ${linkColorHover};
        --notice__text-color: ${textColor};
        --notice__arrow-color: ${arrowColor};
        --notice__background-color: ${backgroundColor};
        --notice__icon: ${icon};
      `
    }}
  `
}

export const WorkspaceSidebarNoticeContainer = styled.div<WorkspaceSidebarNoticeContainerProps>(
  ({ theme }) => css`
    ${setColorVariablesBasedOnVariant()}
    position: relative;
    color: var(--notice__text-color);
    background-color: var(--notice__background-color);
    border-radius: 6px;
    border: 1px solid ${theme.colors.border.A};
    margin: 8px;
    padding: 12px;
  `
)
