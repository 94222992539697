import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { GetSharesQueryVariables } from '@sketch/gql-types'

export type ShareSearchFilter = NonNullable<
  NonNullable<GetSharesQueryVariables['search']>['filters']
>[0]

const filtersFromParam = (filtersStr: string): ShareSearchFilter[] => {
  return filtersStr.split(',').filter(x => x.length > 0) as ShareSearchFilter[]
}

const filtersToParam = (filters: ShareSearchFilter[]): string => {
  return filters.join(',')
}

export const useSearchFilters = () => {
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const filters = filtersFromParam(searchParams.get('filters') || '')

  const setFilters = useCallback(
    (newFilters: ShareSearchFilter[]) => {
      if (newFilters.length === 0) {
        searchParams.delete('filters')
      } else {
        const filtersStr = filtersToParam(newFilters)
        searchParams.set('filters', filtersStr)
      }
      history.replace({ search: searchParams.toString() })
    },
    [searchParams, history]
  )

  return { filters, setFilters }
}
