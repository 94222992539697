import React from 'react'

export interface ExternalLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string
}

/**
 * Renders a simple external link wrapper that opens in a new tab.
 */
export const ExternalLink: React.FC<ExternalLinkProps> = ({
  title,
  children,
  'aria-label': ariaLabel,
  ...rest
}) => (
  <a
    {...rest}
    title={title}
    aria-label={ariaLabel || title}
    rel="noopener noreferrer"
    target="_blank"
  >
    {children}
  </a>
)
