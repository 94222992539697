import {
  ShareInfoFragment,
  useVersionDeletedSubscription,
  ShareInfoFragmentDoc,
  ShareLatestStarredVersionFragment,
  ShareLatestStarredVersionFragmentDoc,
} from '@sketch/gql-types'
import { dataIdFromObject, useShortIdsMapper } from '@sketch/graphql-cache'
import { deleteEntityFromCache } from '@sketch/modules-common'
import { useEventDispatch } from '@sketch/utils'

import {
  shouldReduceTotalCount,
  wasDeletionInitiatedByThisClient,
} from './deletedVersionsTracking'
import { updateVersionHistory } from './updateVersionHistory'
import { ErrorHandler } from '@sketch/tracing'

declare module '@sketch/utils' {
  export interface EventsMap {
    versionDeleted: {
      /**
       * shortId of previous latest version
       */
      versionShortId: string
      share: ShareInfoFragment
      versionKind: string
    }
  }
}

export const useVersionDeleted = () => {
  const onVersionDeleted = useEventDispatch('versionDeleted')
  const shortIdsMapper = useShortIdsMapper()

  useVersionDeletedSubscription({
    onSubscriptionData: ({
      client,
      subscriptionData: { loading, data, error },
    }) => {
      if (!data || error || loading) {
        ErrorHandler.shouldNeverHappen(
          'versionDeleted event should contain valid data'
        )
        return
      }

      const { share, versionDeleted } = data.versionDeleted

      updateVersionHistory({
        client,
        variables: { shareIdentifier: share.identifier },
        update: share => {
          if (shouldReduceTotalCount(versionDeleted.identifier)) {
            share.availableVersions.meta.totalCount--
            share.versionHistory.meta.totalCount--
          }
          share.versionHistory.entries = share.versionHistory.entries.filter(
            entry => entry.identifier !== versionDeleted.identifier
          )
        },
      })

      const versionShortId = shortIdsMapper.toShortId('Version', versionDeleted)
      const versionKind = versionDeleted.kind

      if (versionShortId) {
        if (!wasDeletionInitiatedByThisClient(versionDeleted.identifier)) {
          onVersionDeleted({ versionShortId, share, versionKind })
        }
      }

      client.writeFragment<ShareInfoFragment>({
        data: share,
        fragment: ShareInfoFragmentDoc,
        id: dataIdFromObject(share)!,
        fragmentName: 'ShareInfo',
      })

      // Synch the latest starred version with the share type
      client.writeFragment<ShareLatestStarredVersionFragment>({
        data: share,
        fragment: ShareLatestStarredVersionFragmentDoc,
        id: dataIdFromObject(share)!,
        fragmentName: 'ShareLatestStarredVersion',
      })

      if (versionShortId) {
        // we have to remove data from the cache only when we known that we'll not need to access older data
        deleteEntityFromCache(client.cache, {
          __typename: 'Version',
          shortId: versionShortId,
        })
      }
    },
    fetchPolicy: 'no-cache',
  })
}
