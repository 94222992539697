import styled from 'styled-components'
import { LoadingPlaceholder, Banner } from '@sketch/components'

export const FontWeightSemibold = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`

export const Paragraph = styled.p`
  margin: 0;
`

export const MembersTitleContainer = styled.div`
  display: flex;
`

export const WorkspaceMembershipsLoading = styled(LoadingPlaceholder)`
  margin-right: 8px;
`

export const StyledBanner = styled(Banner)`
  margin-bottom: 40px;
`

export const DeleteWorkspaceText = styled.div`
  display: flex;
  flex-direction: column;
`
