import * as Sentry from '@sentry/browser'
import { OperationVariables } from 'apollo-client'
import { getMainDefinition } from 'apollo-utilities'
import { DocumentNode } from 'graphql'
import { QueryHookOptions, QueryResult } from 'react-apollo'

const getOperationName = (query: DocumentNode) => {
  try {
    const node = getMainDefinition(query)
    if (node.kind === 'FragmentDefinition') {
      return { name: node.name.value, operation: 'fragment' }
    }
    return { name: node.name?.value, operation: node.operation }
  } catch {
    return { name: undefined, operation: undefined }
  }
}

export const captureCacheMiss = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options: QueryHookOptions<TData, TVariables> | undefined,
  result: QueryResult<TData, TVariables>
) => {
  try {
    if (
      result.data &&
      !result.loading &&
      !result.error &&
      Object.keys(result.data).length === 0
    ) {
      Sentry.withScope(scope => {
        const { name, operation } = getOperationName(query)
        scope
          .setExtra('options', JSON.stringify(options))
          .setExtra('operation_name', name)
          .setExtra('operation_type', operation)

        Sentry.captureMessage(
          `Cache miss noticed using ${operation} ${JSON.stringify(
            name || query
          ).slice(0, 500)}`
        )
      })
    }
  } catch (err) {
    Sentry?.captureException?.(err)
  }
}
