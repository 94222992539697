import { usePrototypeContext } from './usePrototypeContext'
import {
  useGoBack,
  useGoForwards,
  useRestartPrototype,
  useNavigateToFirstFlow,
} from '@sketch-hq/sketch-web-renderer'
import { useKey } from 'react-use'
import { shortcuts, keyWithoutModifier } from '@sketch/utils'

function blurActiveElement() {
  if (document.activeElement instanceof HTMLElement) {
    document.activeElement.blur()
  }
}

/**
 * Keyboard shortcuts for the prototype player.
 */
export function useKeyboardShortcuts() {
  const { prototypeArtboardUUID } = usePrototypeContext()
  const restartPrototype = useRestartPrototype()
  const navigateToFirstFlow = useNavigateToFirstFlow()
  const goBack = useGoBack()
  const goForwards = useGoForwards()

  useKey(keyWithoutModifier(shortcuts.prototypePrevious), () => {
    blurActiveElement()
    goBack()
  })

  useKey(keyWithoutModifier(shortcuts.prototypeNext), () => {
    blurActiveElement()
    goForwards()
  })

  useKey(keyWithoutModifier(shortcuts.prototypeRestart), () => {
    blurActiveElement()
    restartPrototype(prototypeArtboardUUID)
  })

  // The PageUp and PageDown shortcuts below are a semi "hidden" feature to
  // facilitate stepping through simple linear "presentation" style prototypes
  // with a hardware presentation clicker device. While clicking forwards it
  // simply selects the first hotspot/flow on the current Artboard and navigates
  // to its destination.

  useKey(keyWithoutModifier(shortcuts.prototypeBack), () => {
    blurActiveElement()
    goBack()
  })

  useKey(keyWithoutModifier(shortcuts.prototypeFirstFlow), () => {
    blurActiveElement()
    navigateToFirstFlow()
  })
}
