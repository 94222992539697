import { useMemo, useRef } from 'react'
import { useLocation } from 'react-router'
import { parse } from 'query-string'

export function useHighlightHotspots() {
  const locationRef = useRef(useLocation())
  const { search } = locationRef.current

  return useMemo(() => {
    const { hotspots } = parse(search)

    if (!hotspots) return true
    if (Array.isArray(hotspots)) return true
    return hotspots !== 'false'
  }, [search])
}
