import React from 'react'

import {
  Button,
  CopyToClipboard,
  ErrorMessage,
  FlatButton,
  LoadingPlaceholder,
  ModalInjectedProps,
  Tab,
  TabPanel,
  Tabs,
  useForTablet,
  useModalContext,
} from '@sketch/components'
import LeaveShareModal from '../../components/LeaveShareModal/LeaveShareModal'
import { SharingModalHeader } from './SharingModalHeader'
import {
  WorkspaceMembersPanel,
  WorkspaceMembersTitle,
} from './WorkspaceMembersPanel'
import { GuestsTitle, GuestsPanel } from './GuestsPanel'
import { EveryoneTitle, EveryonePanel } from './EveryonePanel'
import useShareMemberships from './useShareMemberships'

import {
  StyledModal as Modal,
  StyledTabList as TabList,
  TabPanelWrapper,
} from './SharingModal.styles'

// GraphQL
import { useGetShareQuery } from '@sketch/gql-types'

interface SharingModalProps extends ModalInjectedProps {
  identifier: string
  name: string
}

const SharingModal = ({ hideModal, identifier, name }: SharingModalProps) => {
  const { showModal } = useModalContext()

  const handleOnLeaveDocument = () => {
    showModal(LeaveShareModal, {
      shareIdentifier: identifier,
      shareName: name,
    })
  }

  const { data, loading, error } = useGetShareQuery({
    variables: { shortId: identifier },
  })

  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger

  const { guestsCount } = useShareMemberships(identifier)

  if (loading) {
    return (
      <Modal onCancel={hideModal}>
        <SharingModalHeader />
        <Modal.Body>
          <LoadingPlaceholder size="64px" />
        </Modal.Body>
      </Modal>
    )
  }

  const share = data?.share

  if (error || !share) {
    return (
      <Modal onCancel={hideModal}>
        <SharingModalHeader />
        <Modal.Body>
          <ErrorMessage.Generic />
        </Modal.Body>
      </Modal>
    )
  }

  const hideLeaveButton = !share.userCanLeaveShare

  return (
    <Modal onCancel={hideModal}>
      <SharingModalHeader share={share} />
      <Modal.Body>
        <Tabs>
          <TabList shouldDrawSeparator>
            <Tab>
              <WorkspaceMembersTitle share={share} />
            </Tab>
            <Tab>
              <GuestsTitle guestsCount={guestsCount} />
            </Tab>
            <Tab data-testid="share-public-settings">
              <EveryoneTitle isPublic={share.publicAccessLevel !== 'NONE'} />
            </Tab>
          </TabList>
          <TabPanel>
            <TabPanelWrapper>
              <WorkspaceMembersPanel share={share} />
            </TabPanelWrapper>
          </TabPanel>
          <TabPanel>
            <TabPanelWrapper>
              <GuestsPanel share={share} />
            </TabPanelWrapper>
          </TabPanel>
          <TabPanel>
            <TabPanelWrapper>
              <EveryonePanel share={share} />
            </TabPanelWrapper>
          </TabPanel>
        </Tabs>
      </Modal.Body>
      <Modal.Footer
        leftContent={
          hideLeaveButton ? undefined : (
            <FlatButton variant="negative" onClick={handleOnLeaveDocument}>
              Leave Document&hellip;
            </FlatButton>
          )
        }
      >
        {isMobile && (
          <CopyToClipboard
            size="40"
            showIcon={false}
            value={share.publicUrl}
            title={
              share.publicAccessLevel !== 'NONE'
                ? 'Copy Public Link'
                : 'Copy Link'
            }
          />
        )}
        <Button variant="primary" onClick={hideModal}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { SharingModal as default }
