import { RouteParams } from 'typesafe-react-router'
import { versionedRoutes } from '@sketch/modules-common'
import { matchPath } from 'react-router'
import { NavigationStackNode, NavigationStack, ViewLevels } from './types'
import { getGroupFromURL } from '../../ComponentsWebView/utils'

export function createNavigationStackNodeForRoute(
  pathname: string,
  search: string
): NavigationStackNode {
  const artboardRouteMatch = getArtboardRouteMatch(pathname)
  if (artboardRouteMatch) {
    return {
      view: 'artboard',
      level: ViewLevels.artboard,
      permanentArtboardShortId:
        artboardRouteMatch.params.permanentArtboardShortId,
    }
  }

  const prototypeRouteMatch = getPrototypeRouteMatch(pathname)
  if (prototypeRouteMatch) {
    return {
      view: 'prototype',
      level: ViewLevels.prototype,
      shareID: prototypeRouteMatch.params.shareID,
      currentArtboardUUID: prototypeRouteMatch.params.currentArtboardUUID,
      prototypeArtboardUUID: prototypeRouteMatch.params.prototypeArtboardUUID,
    }
  }

  const sharePrototypeRouteMatch = getSharePrototypeRouteMatch(pathname)
  if (sharePrototypeRouteMatch) {
    return {
      view: 'shareprototypes',
      level: ViewLevels.shareprototypes,
      shareID: sharePrototypeRouteMatch.params.shareID,
    }
  }
  const canvasRouteMatch = getPageCanvasRouteMatch(pathname)
  if (canvasRouteMatch) {
    return {
      view: 'canvas',
      level: ViewLevels.canvas,
      pageUUID: canvasRouteMatch.params.pageUUID,
    }
  }

  const pageRouteMatch = getPageRouteMatch(pathname)
  if (pageRouteMatch) {
    return {
      view: 'page',
      level: ViewLevels.page,
      pageUUID: pageRouteMatch.params.pageUUID,
    }
  }

  const symbolsRouteMatch = getSymbolsRouteMatch(pathname)
  if (symbolsRouteMatch) {
    return {
      view: 'symbols',
      level: ViewLevels.symbols,
      groupId: getGroupFromURL(),
    }
  }

  const documentRouteMatch = getDocumentRouteMatch(pathname)
  if (documentRouteMatch) {
    return {
      view: 'document',
      level: ViewLevels.document,
    }
  }

  // For all other views under /s/* (prototypes, design-system) we consider
  // it as the document view.
  return {
    view: 'document',
    level: ViewLevels.document,
  }
}

export function getUpdatedNavigationStack(
  previousPath: NavigationStack,
  currentNode: NavigationStackNode
) {
  const previousNode = previousPath[previousPath.length - 1]
  const isForwardNavigation = currentNode.level > previousNode.level
  let previousPathToKeep: NavigationStack
  if (isForwardNavigation) {
    previousPathToKeep = previousPath
  } else {
    // We are going backward in the levels, we keep everything before the currentNode level (excluded).
    // The current node is excluded because it may or may not already be in the previous path and
    // it's easier to exclude it and always add it at the end.
    previousPathToKeep = previousPath.reduce(
      (acc: NavigationStack, pathNode) => {
        if (pathNode.level < currentNode.level) {
          acc.push(pathNode)
        }

        return acc
      },
      []
    )
  }

  return [...previousPathToKeep, currentNode]
}

/**
 * There are some routes we want to ignore completely and for which we
 * pretend the current view has not changed. This is mainly used for views
 * that just do a temporary redirect to another one.
 */
export function isRouteToIgnore(pathname: string) {
  return (
    Boolean(
      // ARTBOARD_DETAIL_UUID automatically redirects to ARTBOARD_DETAIL
      matchesVersionedRoute(versionedRoutes.ARTBOARD_DETAIL_UUID, pathname)
    ) ||
    Boolean(
      matchesVersionedRoute(versionedRoutes.PROTOTYPE_LEGACY_ROUTE_1, pathname)
    ) ||
    Boolean(
      matchesVersionedRoute(versionedRoutes.PROTOTYPE_LEGACY_ROUTE_2, pathname)
    )
  )
}

function getArtboardRouteMatch(pathname: string) {
  return (
    matchesVersionedRoute(versionedRoutes.ARTBOARD_DETAIL, pathname) ||
    matchesVersionedRoute(versionedRoutes.ARTBOARD_REVISION, pathname)
  )
}

function getPrototypeRouteMatch(pathname: string) {
  return matchesVersionedRoute(versionedRoutes.PROTOTYPE_PLAYER, pathname)
}

function getSharePrototypeRouteMatch(pathname: string) {
  return matchesVersionedRoute(versionedRoutes.SHARE_PROTOTYPES, pathname)
}

function getPageCanvasRouteMatch(pathname: string) {
  return matchesVersionedRoute(versionedRoutes.SHARE_PAGE_CANVAS_VIEW, pathname)
}

function getPageRouteMatch(pathname: string) {
  return matchesVersionedRoute(versionedRoutes.SHARE_PAGE_VIEW, pathname)
}

function getSymbolsRouteMatch(pathname: string) {
  return matchesVersionedRoute(versionedRoutes.CWV_SYMBOLS, pathname)
}

function getDocumentRouteMatch(pathname: string) {
  return matchesVersionedRoute(versionedRoutes.SHARE_VIEW, pathname)
}

type VersionedRouteKeys = keyof typeof versionedRoutes
type VersionedRoute = typeof versionedRoutes[VersionedRouteKeys]

function matchesVersionedRoute<TVersionedRoute extends VersionedRoute>(
  versionedRoute: TVersionedRoute,
  pathname: string
) {
  return (
    matchPath<RouteParams<TVersionedRoute['LATEST']>>(pathname, {
      path: versionedRoute.LATEST.template(),
      exact: true,
    }) ||
    matchPath<RouteParams<TVersionedRoute['VERSION']>>(pathname, {
      path: versionedRoute.VERSION.template(),
      exact: true,
    })
  )
}
