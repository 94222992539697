import styled from 'styled-components'
import { Text } from '@sketch/components'

export const HeaderItem = styled(Text.H1).attrs({
  textStyle: 'subheader.quaternary.standard.B',
})`
  margin: 0;
  padding: 10px 12px; /* stylelint-disable-line scales/space */
  text-transform: uppercase;
`
