import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "page-canvas-64", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 64 64", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M54.5 45.5v-27a4 4 0 00-4-4h-37a4 4 0 00-4 4v27a4 4 0 004 4h37a4 4 0 004-4z", stroke: "currentcolor", strokeOpacity: 0.15, strokeWidth: 3 }),
        React.createElement("path", { d: "M50.5 13h-37A5.5 5.5 0 008 18.5v27a5.5 5.5 0 005.5 5.5h37a5.5 5.5 0 005.5-5.5v-27a5.5 5.5 0 00-5.5-5.5z", fill: "currentcolor", fillOpacity: 0.15, fillRule: "nonzero" }),
        React.createElement("path", { d: "M23 14.5h-9.5a4 4 0 00-4 4V28M41 49.5h9.5a4 4 0 004-4V36", stroke: "#000", strokeLinecap: "round", strokeWidth: 3 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
