import { DataProxy } from 'apollo-cache'

import {
  BaseAnnotationFragment,
  GetAnnotationCommentsDocument,
  GetAnnotationCommentsQuery,
  GetAnnotationCommentsQueryVariables,
} from '@sketch/gql-types'

export const createAnnotationCommentsQuery = (
  cache: DataProxy,
  annotation: BaseAnnotationFragment,
  shareIdentifier: string
) => {
  cache.writeQuery<
    GetAnnotationCommentsQuery,
    GetAnnotationCommentsQueryVariables
  >({
    variables: { annotationIdentifier: annotation.identifier },
    query: GetAnnotationCommentsDocument,
    data: {
      __typename: 'RootQueryType',
      annotation: {
        ...annotation,
        subscriptionStatus: 'ON',
        share: {
          __typename: 'Share',
          identifier: shareIdentifier,
        },

        comments: {
          __typename: 'Comments',
          entries: [annotation.firstComment],
          meta: {
            __typename: 'PaginationMeta',
            after: null,
            totalCount: 1,
          },
        },
      },
    },
  })
}
