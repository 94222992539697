// Small set of utilities to make our life working with localStorage
// easier. They're extracted from @sketch/utils but included
// here to break dependencies between these two packages belonging to the same layer.
// More info: https://github.com/sketch-hq/Cloud/issues/16416
export const getItem = (key: string): string | undefined => {
  try {
    return window.localStorage.getItem(key) ?? undefined
  } catch (e) {
    return undefined
  }
}

export const getParsedItem = <T = any>(key: string): T | undefined => {
  try {
    const rawItem = getItem(key)
    if (rawItem === undefined) return undefined
    return JSON.parse(rawItem)
  } catch (e) {
    return undefined
  }
}

export const setItem = (key: string, item: string) => {
  try {
    window.localStorage.setItem(key, item)
  } catch (e) {
    // The error will be ignored
  }
}

// Utily to check the iOS version. Extracted from @sketch/utils
// Taken from https://gist.github.com/Craga89/2829457
export const getiOSVersion = (userAgent: string) =>
  parseFloat(
    (
      '' +
      (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(
        userAgent
      ) || [0, ''])[1]
    )
      .replace('undefined', '3_2')
      .replace('_', '.')
      .replace('_', '')
  ) || false
