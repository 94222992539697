import React from 'react'

import BillingInfoTable from '../BillingInfoTable'
import Panel from '../Panel'

import { CurrentPlan } from '../../components/CurrentPlan'
import ProjectedTotal from '../ProjectedTotal/ProjectedTotal'
import BillingCycle from '../../components/BillingCycle'
import { Button, LinkButton } from '@sketch/components'
import { routes } from '@sketch/modules-common'

import { useToast } from '@sketch/toasts'
// GQL
import {
  BillingPlanFragment,
  BillingSeatsInfoFragment,
  PaymentDetailsFragment,
  GetCustomerDocument,
  GetWorkspaceSettingsDocument,
  useReactivateSubscriptionMutation,
} from '@sketch/gql-types'

// Usage of BillingStatus enum is needed to make the expected strings match the BE ones
// eslint-disable-next-line no-restricted-imports
import { BillingStatus } from '@sketch/gql-types/expansive'

import {
  CanceledPill,
  BlockText,
  StyledPanelTitle,
} from './PlanOverviewPanel.styles'
import BillingFor from '../BillingFor'

import {
  isWorkspaceSubscriptionActive,
  isWorkspaceSubscriptionTrialling,
} from '../../utils'

interface PlanOverviewPanelProps {
  workspaceId: string
  customerId: string
  subscriptionEnd?: string
  currentPlan?: BillingPlanFragment
  seats: BillingSeatsInfoFragment
  nextBillingCycleDate?: string
  projectedCost?: number
  subscriptionStatus: BillingStatus
  isPartner?: boolean
  isSsoActive?: boolean
  customerHasPaymentDetails: boolean
  trialEndDate: string
  paymentDetails?: PaymentDetailsFragment
  onWorkspaceSettingRefresh: () => Promise<void>
  onReactivateSubscription: () => void
  onCancelSeatScheduleChange: () => void
  onSeatScheduleChange: () => void
}

/**
 * PlanOverviewPanel
 *
 * This component holds all the data logic needed to fulfill {@link PlanOverviewPanel}.
 */
export const PlanOverviewPanel: React.FC<PlanOverviewPanelProps> = props => {
  const {
    workspaceId,
    customerId,
    subscriptionStatus,
    customerHasPaymentDetails,
    currentPlan,
    subscriptionEnd,
    seats,
    isPartner,
    isSsoActive,
    nextBillingCycleDate,
    projectedCost,
    trialEndDate,
    paymentDetails,
    onWorkspaceSettingRefresh,
    onReactivateSubscription,
    onCancelSeatScheduleChange,
    onSeatScheduleChange,
  } = props
  const { showToast } = useToast()
  const [reactivate, { loading }] = useReactivateSubscriptionMutation({
    onCompleted: () => showToast('Subscription reactivated successfully'),
    variables: { customerId },
    onError: 'show-toast',
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GetWorkspaceSettingsDocument,
        variables: {
          identifier: workspaceId,
          includeShares: false,
        },
      },
      {
        query: GetCustomerDocument,
        variables: {
          customerId,
        },
      },
    ],
  })
  const isNewPricingEnabled = currentPlan?.product !== 'BUSINESS'
  const isSubscriptionEnd = !!subscriptionEnd
  const isWorkspaceOnTrial = isWorkspaceSubscriptionTrialling(
    subscriptionStatus
  )
  const isSubscriptionActive = isWorkspaceSubscriptionActive(subscriptionStatus)

  const isEducationPlan = currentPlan?.product === 'STUDENT'

  if (!isSubscriptionActive) {
    return (
      <Panel>
        <StyledPanelTitle>Plan Overview</StyledPanelTitle>
        <Panel.Body>
          <BillingInfoTable.Row>
            <BillingInfoTable.HeadingCell>
              Current Plan
            </BillingInfoTable.HeadingCell>
            <BillingInfoTable.Cell>
              <div>
                <CanceledPill data-testid="canceled-subscription-badge">
                  Subscription Canceled
                </CanceledPill>
                <BlockText>
                  Your Workspace subscription has been canceled and documents
                  are now in read-only mode.
                </BlockText>
              </div>
              {isPartner && (
                <Button
                  variant="primary"
                  size="32"
                  loading={loading}
                  onClick={() => reactivate()}
                >
                  Renew
                </Button>
              )}
              {!isPartner &&
                !isSsoActive &&
                paymentDetails?.type !== 'INVOICE' && (
                  <LinkButton
                    to={routes.WORKSPACE_SUBSCRIBE.create({
                      workspaceId,
                    })}
                    variant="primary"
                    size="32"
                  >
                    Subscribe
                  </LinkButton>
                )}
            </BillingInfoTable.Cell>
          </BillingInfoTable.Row>
        </Panel.Body>
      </Panel>
    )
  }

  return (
    <Panel>
      <StyledPanelTitle>Plan Overview</StyledPanelTitle>
      <Panel.Body>
        <BillingInfoTable>
          <CurrentPlan
            workspaceId={workspaceId}
            customerId={customerId}
            isWorkspaceOnTrial={isWorkspaceOnTrial}
            subscriptionStatus={subscriptionStatus}
            customerHasPaymentDetails={customerHasPaymentDetails}
            currentPlan={currentPlan}
            trialEndDate={trialEndDate}
            isSubscriptionEnd={isSubscriptionEnd}
            onWorkspaceSettingRefresh={onWorkspaceSettingRefresh}
            isNewPricingEnabled={isNewPricingEnabled}
            onReactivateSubscription={onReactivateSubscription}
            seats={seats}
            paymentDetails={paymentDetails}
            renewalDate={nextBillingCycleDate!}
            isPartner={isPartner}
          />
          <BillingFor
            seats={seats}
            isSubscriptionEnd={isSubscriptionEnd}
            onSeatScheduleChange={onSeatScheduleChange}
            isEducationPlan={isEducationPlan}
          />
          <ProjectedTotal
            projectedCost={projectedCost}
            seats={seats}
            hasSubscription={customerHasPaymentDetails}
          />
          <BillingCycle
            nextBillingCycleDate={nextBillingCycleDate}
            subscriptionEnd={subscriptionEnd}
            isPartner={isPartner}
            seats={seats}
            isWorkspaceOnTrial={isWorkspaceOnTrial}
            customerHasPaymentDetails={customerHasPaymentDetails}
            onCancelSeatScheduleChange={onCancelSeatScheduleChange}
            currentPlan={currentPlan}
          />
        </BillingInfoTable>
      </Panel.Body>
    </Panel>
  )
}
