import styled, { css } from 'styled-components'

import { ButtonUnstyled } from '../Button'
import { ReactComponent as PencilIcon } from '@sketch/icons/pencil-16'

const placeholderStyle = css`
  color: ${({ theme }) => theme.colors.state.disabled};
  font-style: italic;
`

export const Pencil = styled(PencilIcon)`
  width: 16px;

  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
`

export const PencilButton = styled(ButtonUnstyled)`
  margin: 0 0 0 12px;
  transition: opacity ${({ theme }) => theme.transitions.duration[1]};

  /* If the button is focused we should force the Light/colors.foreground.secondary.1 */
  :hover,
  :focus {
    ${Pencil} {
      opacity: 1;
    }
  }
`

export const EditInput = styled.textarea<{
  noExtraStyling?: boolean
}>(
  ({ theme, noExtraStyling }) => css`
    box-sizing: content-box;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: none;

    outline: none;
    resize: none;
    overflow: hidden;

    border-radius: ${theme.radii.large};
    ${noExtraStyling
      ? css`
          border: none;
        `
      : css`
          border: 2px solid ${theme.colors.border.A};
        `};

    :focus {
      ${noExtraStyling
        ? css`
            border: none;
          `
        : css`
            /* Native focus ring */
            outline: 5px auto Highlight;
            outline: 5px auto -webkit-focus-ring-color;
          `};
    }

    ::selection {
      background: ${theme.colors.sketch.E};
    }

    ::placeholder {
      ${placeholderStyle};
      ${noExtraStyling &&
      css`
        font-style: normal;
      `}
    }

    /* Re-enforce any style that the preview element might have */
    /* (-4px - 2px) to compensate for the border */
    && {
      ${noExtraStyling
        ? css`
            margin-left: -6px;
            padding-left: 4px;
            padding-top: 0;
            padding-bottom: 0;
          `
        : css`
            margin: -6px; /* stylelint-disable-line scales/space */
            padding: 4px;
          `}
    }
  `
)

export const Form = styled.form`
  margin: 0;
`

export const Preview = styled.span`
  display: block;

  width: 100%;

  box-sizing: content-box;
  visibility: hidden;

  ::after {
    /* Allow spaces to be added in the end of a string, h1 cuts the excess space */
    content: 'a';
    font-size: 0;
  }
`

export const Text = styled.span<{
  noExtraStyling?: boolean
  removePlaceholderMarginBottom?: boolean
}>(
  ({ noExtraStyling, removePlaceholderMarginBottom, theme }) => css`
    /* Remove the button size from the max-width but add the negative border (28px - 2x6px = 16) */
    max-width: calc(100% - 16px);
    ${noExtraStyling
      ? css`
          border: none;
        `
      : css`
          border: 2px solid transparent;
        `};
    border-radius: ${theme.radii.large};

    /* Force the placeholder text to be rendered when the value is empty */
    :empty {
      ::after {
        ${placeholderStyle};
        content: attr(placeholder);
        cursor: text;
        padding-right: 7px; /* stylelint-disable-line scales/space */
      }
    }

    && {
      padding: 4px;
      /* Re-enforce any style that the preview element might have */
      /* (-4px - 2px) to compensate for the border */
      ${noExtraStyling
        ? css`
            margin: -12px -6px 0 -6px; /* stylelint-disable-line scales/space */
          `
        : css`
            margin: -6px; /* stylelint-disable-line scales/space */
          `};
      ${removePlaceholderMarginBottom &&
      css`
        margin-bottom: -6px; /* stylelint-disable-line scales/space */
      `}
    }
  `
)

export const InputWrapper = styled.div`
  position: relative;

  /* Make sure the pencil button is had in consideration  */
  max-width: calc(100% - 28px);
`

export const Wrapper = styled.div<{ noExtraStyling?: boolean }>(
  ({ noExtraStyling }) => css`
    display: flex;
    align-items: center;

    /* Show the button when the container is hovered */
    :hover {
      & ${PencilButton} {
        opacity: 1;
      }
    }

    & ${PencilButton} {
      opacity: 0;
    }

    /* Show the button when it's focused */
    ${PencilButton} {
      :focus {
        opacity: 1;
      }
    }

    /* Keep the pencil visible when the title input is active */
    ${InputWrapper /* sc-selector */} {
      + ${PencilButton} {
        opacity: 1;

        /* If the button is focused we should force the Light/colors.foreground.secondary.1 */
        ${Pencil} {
          opacity: 1;
        }
      }
    }

    &:focus-within {
      & ${Text} {
        ${noExtraStyling
          ? css`
              border: none;
            `
          : css`
              /* Native focus ring */
              outline: 5px auto Highlight;
              outline: 5px auto -webkit-focus-ring-color;
            `};
      }

      & ${PencilButton} {
        opacity: 1;

        ${Pencil} {
          opacity: 1;
        }
      }
    }
  `
)
