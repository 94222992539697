import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ApolloQueryResult } from 'apollo-client'

import { routes } from '@sketch/modules-common'

import {
  Box,
  InfiniteList,
  useModalContext,
  SelectDropdownLinkItem,
} from '@sketch/components'
import { DesignSystemDropdown } from '../../components/DesignSystemDropdown'
import { CreateDesignSystemModal } from '../CreateDesignSystemModal'
import { SidebarHeader } from './SidebarHeader'

import { DesignSystem as DesignSystemType } from '../../types'

import { ReactComponent as DSIcon } from '@sketch/icons/design-system-16'

import { useGetDesignSystems } from '../../operations'

// Autogenerated types/operations
import {
  WorkspaceMinimalFragment,
  GetWorkspaceDesignSystemsQuery,
} from '@sketch/gql-types'

const Item = ({
  designSystem,
  userIsGuest,
  refetch,
  workspaceId,
}: {
  designSystem: DesignSystemType
  userIsGuest: boolean
  refetch: () => Promise<ApolloQueryResult<GetWorkspaceDesignSystemsQuery>>
  workspaceId: string
}) => {
  const location = useLocation()
  const history = useHistory()

  const url = routes.DESIGN_SYSTEM.create({
    workspaceId,
    designSystemId: designSystem.identifier,
  })

  const handleDelete = () => {
    if (url === location.pathname) {
      history.push(routes.WORKSPACE_LIBRARIES.create({ workspaceId }))
    }

    refetch()
  }

  return (
    <SelectDropdownLinkItem
      data-testid="design-system-item"
      icon={DSIcon}
      text={designSystem.name}
      to={routes.DESIGN_SYSTEM.create({
        workspaceId,
        designSystemId: designSystem.identifier,
      })}
      action={
        <DesignSystemDropdown
          deleteCallback={handleDelete}
          designSystem={designSystem}
          url={url}
          userIsGuest={userIsGuest}
        />
      }
    />
  )
}

interface DesignSystemSidebarProps {
  workspace: WorkspaceMinimalFragment
}

export const DesignSystemSidebar: React.FC<DesignSystemSidebarProps> = ({
  workspace,
}) => {
  const { showModal } = useModalContext()

  const {
    designSystems,
    reloadList,
    onLoadMore,
    canUseDesignSystemManager,
    userIsGuest,
    refetch,
    loading,
    after,
  } = useGetDesignSystems({ workspace })

  if (userIsGuest) {
    return null
  }

  const showDesignSystemsModal = () => {
    showModal(CreateDesignSystemModal, {
      workspaceId: workspace.identifier,
      loadLatestHandler: reloadList,
    })
  }

  if (!canUseDesignSystemManager) {
    return null
  }

  // only show intro popover when everything has finished loading (and there are really no DS for this workspace)
  const shouldShow = !loading && designSystems && designSystems.length === 0

  // Only users that can edit this workspace are able to add design systems
  const canManageDesignSystems = workspace.userCanEdit

  return (
    <Box mb={shouldShow ? 0 : 4}>
      <SidebarHeader
        canManageDesignSystems={canManageDesignSystems}
        onAdd={showDesignSystemsModal}
        shouldShow={shouldShow}
      />
      <InfiniteList canLoadMore={after !== null} onLoadMore={onLoadMore}>
        {designSystems.map(designSystem => (
          <Item
            key={designSystem.identifier}
            designSystem={designSystem as DesignSystemType}
            userIsGuest={userIsGuest}
            refetch={refetch}
            workspaceId={workspace.identifier}
          />
        ))}
      </InfiniteList>
    </Box>
  )
}
