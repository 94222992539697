import React, { FC } from 'react'

import { BillingInfoTable as StyledBillingInfoTable } from './BillingInfoTable.styles'

const BillingInfoTable: FC = ({ children }) => (
  <StyledBillingInfoTable>
    <tbody>{children}</tbody>
  </StyledBillingInfoTable>
)

export default BillingInfoTable
