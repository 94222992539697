import React from 'react'
import { useLocation, NavLink, useRouteMatch } from 'react-router-dom'

import {
  routes,
  useFlag,
  useGetNewNotificationsCount,
  useUserProfile,
  useSignOut,
  isWorkspaceSettingsRoute,
  isPersonalSettingsRoute,
  isUpdatesRoute,
} from '@sketch/modules-common'

import { ReactComponent as KeyIcon } from '@sketch/icons/key-8'
import { ReactComponent as SignOutIcon } from '@sketch/icons/arrow-right-rectangle-16'
import { ReactComponent as PersonIcon } from '@sketch/icons/person-prism-16'
import { ReactComponent as BellIcon } from '@sketch/icons/bell-24'
import { ReactComponent as GearIcon } from '@sketch/icons/gear-24'

import { Link, useResponsiveDropdown, Tooltip } from '@sketch/components'

import { AppearanceMenu } from './AppearanceMenu'

import {
  DropdownHeader,
  UserName,
  StyledDropdownItemNavLink,
  StyledDropdownItem,
  Icon,
  Button,
  Avatar,
  Pill,
  DropdownUserName,
  DropdownUserEmail,
  KeyWrapper,
  SidebarHeaderRight,
  NavLinkWrapper,
} from './UserDropdown.styles'

import { UserFragment } from '@sketch/gql-types'

const DROPDOWN_STYLE = {
  width: '216px',
}

const formatUnreadNotifications = (unreadCounter: number) => {
  return unreadCounter > 99 ? '99+' : `${unreadCounter}`
}

interface NotificationsPillProps {
  unreadNotifications: number
  withNumberDisplay: boolean
}

export const NotificationsPill = ({
  unreadNotifications,
  withNumberDisplay,
}: NotificationsPillProps) => {
  const formattedNotification = formatUnreadNotifications(unreadNotifications)
  return unreadNotifications > 0 ? (
    <Pill
      data-testid="user-unseen-notifications"
      variant="secondary"
      withNumberDisplay={withNumberDisplay}
    >
      {withNumberDisplay ? (
        formattedNotification
      ) : (
        <span className="sr-only">{formattedNotification}</span>
      )}
    </Pill>
  ) : null
}

interface DropdownContentProps {
  name: string
  email: string
  unreadNotifications: number
}

const DropdownContent = (props: DropdownContentProps) => {
  const { name, email } = props

  const isDarkModeToggleEnabled = useFlag('dark-mode-toggle')

  const signOut = useSignOut({
    redirectBackAfterLoginAgain: false,
    reason: 'Logout button',
  })

  return (
    <>
      <DropdownHeader>
        <DropdownUserName>{name}</DropdownUserName>
        <DropdownUserEmail>{email}</DropdownUserEmail>
      </DropdownHeader>

      <StyledDropdownItemNavLink to={routes.PERSONAL_SETTINGS.create({})}>
        <Icon width="16px" height="16px" as={PersonIcon} /> Account
      </StyledDropdownItemNavLink>

      {isDarkModeToggleEnabled && <AppearanceMenu />}

      <StyledDropdownItem onClick={signOut}>
        <Icon width="16px" height="16px" as={SignOutIcon} /> Sign Out
      </StyledDropdownItem>
    </>
  )
}

interface AuthenticatedUserDropdownProps {
  className?: string
  user: UserFragment
  unreadNotifications: number
  noWorkspace?: boolean
  isWorkspaceGuestUser?: boolean
}

const AuthenticatedUserDropdown = (props: AuthenticatedUserDropdownProps) => {
  const {
    className,
    user,
    unreadNotifications,
    noWorkspace,
    isWorkspaceGuestUser,
  } = props

  const { path } = useRouteMatch()

  const { avatar, email, name } = user
  const isSuperAdmin = !!user?.isSuperadmin

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: DropdownContent,
    dropdownStyle: DROPDOWN_STYLE,
    dropdownProps: {
      email: email || '',
      name: name || '',
      unreadNotifications,
    },
    placement: 'top',
  })

  const hasWorkspaces = !noWorkspace

  const settingsRoute =
    hasWorkspaces && !isWorkspaceGuestUser
      ? routes.WORKSPACE_AGNOSTIC_SETTINGS_SETTINGS.create({})
      : routes.PERSONAL_SETTINGS.create({})

  // Agnostic workspace routes redirect to their respective workspace route
  // eg: workspace/settings/settings -> workspace/(id)/settings/settings
  // Due to this redirect we need to set the active state manually
  const isSettingsActive =
    isWorkspaceSettingsRoute(path) || isPersonalSettingsRoute(path)

  const isUpdatesActive = isUpdatesRoute(path)

  return (
    <SidebarHeaderRight>
      <NavLinkWrapper>
        <Tooltip
          content="Settings"
          placement="bottom"
          disabled={isSettingsActive}
        >
          <Button
            data-testid="user-settings"
            as={NavLink}
            to={settingsRoute}
            className={isSettingsActive ? 'active' : undefined}
          >
            <Icon width="24px" height="24px" as={GearIcon} />
            <span className="sr-only">Settings</span>
          </Button>
        </Tooltip>
        <Tooltip
          content="Updates"
          placement="bottom"
          disabled={isUpdatesActive}
          disableWhenTouchDevice
        >
          <Button as={NavLink} to={routes.UPDATES.create({})}>
            <Icon width="24px" height="24px" as={BellIcon} />
            <span className="sr-only">Updates</span>
            <NotificationsPill
              unreadNotifications={unreadNotifications}
              withNumberDisplay={false}
            />
          </Button>
        </Tooltip>
      </NavLinkWrapper>
      <Tooltip content="Your Account" placement="bottom">
        <Button
          className={className}
          data-testid="user-avatar"
          {...buttonProps}
        >
          <Avatar src={avatar!.small!} name={name!} size="24px" />
          {isSuperAdmin && (
            <KeyWrapper>
              <KeyIcon data-testid="super-admin-badge" width={8} />
            </KeyWrapper>
          )}
        </Button>
      </Tooltip>
      {content}
    </SidebarHeaderRight>
  )
}

const UnAuthenticatedUserDropdown = () => {
  const location = useLocation()

  return (
    <Button
      as={Link}
      to={{ pathname: routes.SIGN_IN.create({}), state: { from: location } }}
    >
      <Icon as={PersonIcon} />
      <UserName>Sign in</UserName>
    </Button>
  )
}

const PreventiveUserDropdown = (
  props: Partial<AuthenticatedUserDropdownProps>
) => {
  const { user, unreadNotifications, noWorkspace, isWorkspaceGuestUser } = props

  const content = !user ? (
    <UnAuthenticatedUserDropdown />
  ) : (
    <AuthenticatedUserDropdown
      user={user}
      unreadNotifications={unreadNotifications || 0}
      noWorkspace={noWorkspace}
      isWorkspaceGuestUser={isWorkspaceGuestUser}
    />
  )

  return <>{content}</>
}

const ConnectedUserDropdown: React.FC = () => {
  const { data } = useUserProfile()
  const newNotificationsCount = useGetNewNotificationsCount({
    fetchPolicy: 'cache-only',
  })

  return (
    <PreventiveUserDropdown
      user={data?.me}
      unreadNotifications={newNotificationsCount}
    />
  )
}

export { ConnectedUserDropdown }
export default PreventiveUserDropdown
