import styled, { css } from 'styled-components'

import { Heading } from '@sketch/components'

import { breakpoint } from '@sketch/global-styles'

export interface IndexLayoutContentProps {
  marginTop?: boolean
  paddingHorizontal?: boolean
  center?: boolean | 'vertical' | 'horizontal'

  /**
   * IndexLayoutContent is a **optional** agnostic helper component which is intended to use as it is and
   * we are actively avoiding to override IndexLayoutContent styles using styled-components later on.
   *
   * IndexLayout provides just an empty space that you can fill in whichever way you want.
   * IndexLayoutContent provides default and common spacing options (margins, centering, padding).
   *
   * The reason why we are doing this is that it would be easier to reason about the `IndexLayout` or
   * `IndexLayoutContent` if we would want to change the default behavior.
   *
   * While working on https://github.com/sketch-hq/sketch-rfc/pull/88 it was noticed that IndexLayout
   * accumulated quite a few anti-patterns, and the hypothesis why happened is that it was hard to
   * reason about IndexLayout, and which changes will impact why.
   *
   * Therefore we want to keep the IndexLayout and IndexLayoutContent as simple as possible.
   *
   * Possible solutions how to work around this limitation:
   *  - don't use IndexLayoutContent at all
   *  - use a custom wrapper / container child component which defines custom styling.
   */
  className?: never
  style?: never
}

/**
 * IndexLayoutContent provides default spacing values and it is intended to use
 * at the views which are rendered inside IndexLayout.
 *
 * It is _optional_ to use this component and if any particular view needs different values
 * it is possible to define a custom container at that view.
 */
export const IndexLayoutContent = styled.div<IndexLayoutContentProps>`
  width: 100%;
  height: 100%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  ${({ paddingHorizontal }) =>
    paddingHorizontal
      ? css`
          padding: 0px 16px;
        `
      : null}

  ${({ center }) => {
    switch (center) {
      case true:
        return css`
          justify-content: center;
          align-items: center;
        `
      case 'vertical':
        return css`
          justify-content: center;
        `

      case 'horizontal':
        return css`
          align-items: center;
        `
      default:
        return ''
    }
  }}

  ${({ marginTop }) =>
    // justify content hack to center when the viewport is short
    marginTop
      ? css`
          margin-top: 0;
          justify-content: center;

          @media (min-height: 768px) {
            margin-top: 85px;
            justify-content: unset;
          }

          ${breakpoint('base', 'sm')`
            margin-top: 33px;
          `};
        `
      : ''}
`

export const IndexLayoutSubtitle = styled(Heading.H2)`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.H};
  line-height: 1.4;
  text-align: center;
  font-weight: 400;

  margin-top: 8px;
  margin-bottom: 24px;

  ${breakpoint('sm')`
      font-size: 1.25rem;
      margin-top: 8px;
  `}
`

export const IndexLayoutTitle = styled.h1(
  ({ theme }) => css`
    margin-top: 0px;
    margin-bottom: 0;
    font-size: ${theme.fontSizes.I};
    text-align: center;
    line-height: 1;

    + :not(${/* sc-selector */ IndexLayoutSubtitle}) {
      margin-top: 32px;
    }

    + ${IndexLayoutSubtitle} {
      font-size: ${theme.fontSizes.F};
    }

    ${breakpoint('base', 'sm')`
      font-size: ${theme.fontSizes.H};
    `}
  `
)
