import React, { FC } from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'
import {
  GetProjectsDocument,
  useCreateProjectMutation,
} from '@sketch/gql-types'
import { FormikRender } from './AddNewProjectModalForm'
import type {
  FormValues,
  FormActions,
  AddNewProjectModalProps,
  CreateProjectWorkspaceAccessLevel,
  PendingGuestMember,
} from './types'

const FORM_INITIAL_VALUES: FormValues = {
  projectName: '',
  projectWorkspaceAccessLevel: 'DEFAULT',
  workspaceMembershipIdentifiers: [],
  workspacePermissionGroupIdentifiers: [],
  guestsToInvite: [],
}

const newProjectFormSchema = yup.object({
  projectName: yup.string().trim().required('A project name is required'),
  projectWorkspaceAccessLevel: yup
    .mixed()
    .oneOf<CreateProjectWorkspaceAccessLevel>(['NONE', 'DEFAULT'])
    .required(),
  workspaceMembershipIdentifiers: yup.array<string[]>(),
  workspacePermissionGroupIdentifiers: yup.array<string[]>(),
  guestsToInvite: yup.array<PendingGuestMember>(),
})

const AddNewProjectModal: FC<AddNewProjectModalProps> = props => {
  const { onCompleted, workspace, hideModal } = props

  const workspaceIdentifier = workspace.identifier

  const [createProject] = useCreateProjectMutation({
    onCompleted,
    onError: 'show-toast',
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GetProjectsDocument,
        variables: { workspaceId: workspaceIdentifier },
      },
    ],
  })

  const handleOnSubmit = async (
    values: FormValues,
    formikActions: FormActions
  ) => {
    const trimmedValues = newProjectFormSchema.cast(values) as FormValues
    formikActions.setSubmitting(true)

    const permissionGroups = (
      trimmedValues.workspacePermissionGroupIdentifiers || []
    ).map(identifier => ({ identifier, accessLevel: 'VIEW' as const }))

    try {
      await createProject({
        variables: {
          workspaceId: workspaceIdentifier,
          name: trimmedValues.projectName!,
          workspaceAccessLevel: trimmedValues.projectWorkspaceAccessLevel,
          permissionGroups,
          workspaceMembershipIdentifiers:
            trimmedValues.workspaceMembershipIdentifiers,
          guestsToInvite: trimmedValues.guestsToInvite,
        },
      })
    } catch (error) {
      // Ignore this error the mutation will handle it
    } finally {
      formikActions.setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={FORM_INITIAL_VALUES}
      onSubmit={handleOnSubmit}
      validationSchema={newProjectFormSchema}
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={true}
    >
      {formikBag => (
        <FormikRender
          formikBag={formikBag}
          workspace={workspace}
          hideModal={hideModal}
        />
      )}
    </Formik>
  )
}

export default AddNewProjectModal
