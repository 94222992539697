import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { routes as routesOutside } from '@sketch/modules-common'

import { PrivateRoute, DisableMFAView } from 'cloud-frontend'

import PublicOnlyRoute from '../../containers/PublicOnlyRoute'

import {
  SsoSignInView,
  SsoSuccessfulSignInView,
  SsoSignInErrorView,
  CheckYourInboxView,
  ChooseYourPathView,
  SignUpView,
  SignInView,
  SignOutView,
  ForgotPasswordView,
  RecoveryCodeView,
  VerificationCodeView,
  ResetPasswordView,
  SsoTosView,
  SignInGetStarted,
  isPricingSourceRoute,
} from '@sketch/sub-app-authentication'

import { authRoutes as routes } from './routes'
import { SsoLinkAccountRoute } from './SsoLinkAccountRoute'
import { MFAPrivateRoute } from '../../containers/MFAPrivateRoute'
import { useLayoutRouteProps } from '../useLayoutRouteProps'
import { withIndexLayout } from '../../layouts/withIndexLayout'

export const AuthRoutes = withIndexLayout(layoutProps => {
  const { to, toWithLocation } = useLayoutRouteProps(layoutProps, routes)

  return (
    <Switch>
      <PrivateRoute
        isInLayout
        {...to('CHOOSE_YOUR_PATH', ChooseYourPathView)}
      />

      <Route {...toWithLocation('SIGN_OUT', SignOutView)} />

      <PrivateRoute
        {...toWithLocation('SIGN_IN_GET_STARTED', SignInGetStarted)}
      />

      <PublicOnlyRoute
        redirectPath={location => {
          if (isPricingSourceRoute(location)) {
            return routes.SIGN_IN_GET_STARTED.create({})
          }
        }}
        {...toWithLocation('SIGN_IN', SignInView)}
      />

      <PublicOnlyRoute {...toWithLocation('SIGN_UP', SignUpView)} />
      <PublicOnlyRoute
        redirectPath={routesOutside.PERSONAL_SETTINGS.template()}
        {...toWithLocation('FORGOT_PASSWORD', ForgotPasswordView)}
      />
      <Route {...toWithLocation('RESET_PASSWORD', ResetPasswordView)} />
      <Route {...to('SSO_SIGN_IN', SsoSignInView)} />
      <Route {...to('SSO_ERROR', SsoSignInErrorView)} />
      <Route {...to('SSO_SUCCESSFUL_SIGN_IN', SsoSuccessfulSignInView)} />
      <Route {...to('SSO_LINK_ACCOUNT', SsoLinkAccountRoute)} />
      <Route {...to('SSO_TOS', SsoTosView)} />
      <Route {...to('PERSONAL_SETTINGS_DISABLE_MFA', DisableMFAView)} />
      <MFAPrivateRoute {...to('VERIFICATION_CODE', VerificationCodeView)} />
      <MFAPrivateRoute {...to('RECOVERY_CODE', RecoveryCodeView)} />
      <PrivateRoute
        isInLayout
        {...to('CHECK_YOUR_INBOX', CheckYourInboxView)}
      />
    </Switch>
  )
})
