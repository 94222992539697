import React, { FC } from 'react'
import { escapeRegExp } from 'lodash'
import styled, { css } from 'styled-components'

const Mark = styled.mark(
  ({ theme }) => css`
    background-color: ${theme.colors.sketch.E};
    color: ${theme.colors.foreground.secondary.A};
  `
)

export const HighlightedText: FC<{ search: string }> = ({
  search,
  children,
}) => {
  const text = children?.toString()

  if (!text) {
    return null
  }

  if (!search.trim()) {
    return <>{text}</>
  }

  const regex = new RegExp(`(${escapeRegExp(search)})`, 'gi')
  const parts = text.split(regex)

  return (
    <>
      {parts
        .filter(part => part)
        .map((part, i) =>
          regex.test(part) ? (
            <Mark key={i}>{part}</Mark>
          ) : (
            <span key={i}>{part}</span>
          )
        )}
    </>
  )
}
