import {
  ForgotPasswordForm as ForgotPasswordFormBase,
  Fields,
  Submit,
} from './ForgotPasswordForm'

export type { ForgotPasswordFormExtendedFormikProps } from './ForgotPasswordForm'

export const ForgotPasswordForm = Object.assign(ForgotPasswordFormBase, {
  Fields,
  Submit,
})
