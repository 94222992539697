import styled from 'styled-components'

import { ReactComponent as PencilUnstyled } from '@sketch/icons/pencil-16'
import { ReactComponent as CrossUnstyled } from '@sketch/icons/cross-circle-black-24'

export const PencilIcon = styled(PencilUnstyled)`
  position: absolute;
  width: 16px;
  height: 16px;
  opacity: 0;
  transition: opacity 0.3s;
`

export const CrossIcon = styled(CrossUnstyled)`
  position: absolute;
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.background.tertiary.B};
  opacity: 0;
  transition: opacity 0.3s;
`

export const IconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 24px;
  height: 24px;
  right: 2px;
  bottom: 2px;
  padding: 18px; /* stylelint-disable-line scales/space */
  cursor: pointer;

  :hover {
    ${CrossIcon},
    ${PencilIcon} {
      opacity: 1;
      z-index: 1;
    }
  }
`

export const Container = styled.div<{
  width?: string | number
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || '100%'};
`

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  /*
  Only show the Pencil icon when hovering empty and not focused
  for both textarea and input
  */
  :hover {
    & > :empty:not(:focus) {
      /* stylelint-disable-next-line selector-type-no-unknown */
      ~ ${IconContainer} ${PencilIcon} {
        opacity: 1;
        z-index: 1;
      }
    }
  }
`

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.state.negative.A};
  font-size: 0.875rem;
  margin-top: 4px;
  text-align: center;
`
