import React from 'react'

import { CANVAS_VIEW_DOCS } from './constants'
import {
  WebRendererErrorCode,
  WebRendererStatus,
} from '@sketch-hq/sketch-web-renderer'
import { isTouchDevice } from '@sketch/utils'

type SketchWebErrorStatus =
  | {
      type: 'FAILURE'
      code: WebRendererErrorCode
    }
  | {
      type: 'WEBGL_CONTEXT_LOST'
    }

export function getErrorDescription(
  rendererStatus: SketchWebErrorStatus
): React.ReactNode {
  if (rendererStatus.type === 'WEBGL_CONTEXT_LOST') {
    return (
      <>
        The canvas couldn’t load due to a problem with WebGL. Please reload — or
        try again later. <a href={CANVAS_VIEW_DOCS}>Learn more</a> about the
        canvas view.
      </>
    )
  }

  switch (rendererStatus.code) {
    case 'WASM_ERROR':
      return (
        <>
          The canvas couldn’t load due to a problem with Web Assembly. Please
          reload — or try again later. <a href={CANVAS_VIEW_DOCS}>Learn more</a>{' '}
          about the canvas view.
        </>
      )
    case 'FILE_ERROR':
      return (
        <>
          There was a problem loading the canvas data. Please reload — or try
          again later. <a href={CANVAS_VIEW_DOCS}>Learn more</a> about the
          canvas view.
        </>
      )
    case 'WEBGL_ERROR':
      return (
        <>
          The canvas couldn’t load due to a problem with WebGL. Please reload —
          or try again later. <a href={CANVAS_VIEW_DOCS}>Learn more</a> about
          the canvas view.
        </>
      )
    case 'ERROR':
    default:
      return (
        <>
          Something went wrong while loading the canvas. Please reload — or try
          again later. <a href={CANVAS_VIEW_DOCS}>Learn more</a> about the
          canvas view.
        </>
      )
  }
}

type RenderStatusLoading = {
  type: ExtractSafe<
    WebRendererStatus['type'],
    'IDLE' | 'INITIALIZING' | 'LOADING_FILE' | 'DRAWING_FILE' | 'FILE_READY'
  >
}

export function getLoadingMessage(rendererStatus: RenderStatusLoading | null) {
  if (
    !rendererStatus ||
    rendererStatus.type === 'INITIALIZING' ||
    rendererStatus.type === 'IDLE'
  ) {
    return 'Initializing…'
  }

  if (rendererStatus.type === 'DRAWING_FILE') {
    return 'Drawing…'
  }

  return 'Loading…'
}

/**
 * Prevent to bind both onClick and touchEnd events by only binding the required event based on the device.
 * The function is designed to be used with React.
 *
 * How to use:
 *
 * <div {...touchableClick(myCallback) />
 */
export function touchableClick<T>(callback: T) {
  return isTouchDevice() ? { onTouchEnd: callback } : { onClick: callback }
}

export function relativeMeasure(measure: number, parentMeasure: number) {
  return (measure / parentMeasure) * 100
}
