import React from 'react'
import { ErrorMessage, Link } from '@sketch/components'
import { ReactComponent as ExclamationMarkTriangleIcon } from '@sketch/icons/exclamation-mark-triangle-64'
import { ReactComponent as ImagePlaceholderIcon } from '@sketch/icons/image-placeholder-64'
import { SvgComponent } from '@sketch/icons'
import { WebRendererErrorCode } from '@sketch-hq/sketch-web-renderer'

type PrototypeErrorStatusProps = {
  type:
    | 'GENERIC_SERVER'
    | 'ARTBOARD_NOT_FOUND'
    | `RENDERER_${WebRendererErrorCode}`
}

/**
 * Shows an error view for the Prototype. Intended to replace the main Prototype
 * content and take over the whole view.
 */
export function PrototypeErrorStatus(props: PrototypeErrorStatusProps) {
  let title: string, description: React.ReactNode, Icon: SvgComponent

  switch (props.type) {
    case 'ARTBOARD_NOT_FOUND':
      title = 'Cannot find Artboard'
      description = (
        <>
          The Artboard might have been moved or deleted, or the URL could be
          incorrect.
        </>
      )
      Icon = ImagePlaceholderIcon
      break
    case 'RENDERER_WASM_ERROR':
      title = "Couldn't play the Prototype"
      description = (
        <>
          The Prototype couldn’t play due to a problem with Web Assembly. Please
          reload — or try again later.
        </>
      )
      Icon = ExclamationMarkTriangleIcon
      break
    case 'RENDERER_WEBGL_ERROR':
      title = "Couldn't play the Prototype"
      description = (
        <>
          The Prototype couldn’t play due to a problem with WebGL. Please reload
          — or try again later.
        </>
      )
      Icon = ExclamationMarkTriangleIcon
      break
    case 'RENDERER_ERROR':
    case 'RENDERER_FILE_ERROR':
      title = "Couldn't play the Prototype"
      description = (
        <>
          Something went wrong while playing the Prototype. Please reload — or
          try again later.
        </>
      )
      Icon = ExclamationMarkTriangleIcon
      break
    case 'GENERIC_SERVER':
      title = 'Failed to load the Prototype'
      description = (
        <>
          {"We're looking into the issue. Try again later or visit our "}
          <Link
            variant="quaternary"
            external={true}
            isUnderlined={true}
            title="Sketch status page"
            href="https://status.sketch.com/"
          >
            Status Page
          </Link>
          .
        </>
      )
      Icon = ExclamationMarkTriangleIcon
      break
  }

  return (
    <ErrorMessage
      dark
      iconSize="medium"
      icon={<Icon />}
      title={title}
      description={description}
    />
  )
}
