import uniqBy from 'lodash.uniqby'
import { Presence } from './types'

const SEVERALPEOPLE_BREAKPOINT = 2

export const isOwnUser = function (
  presence: Presence,
  userIdentifier?: string
) {
  return presence.user.identifier === userIdentifier
}

const getPresenceText = function (
  presences: Presence[],
  includesOwnUser: boolean = false
) {
  if (presences.length >= SEVERALPEOPLE_BREAKPOINT || includesOwnUser) {
    return 'are editing'
  }

  return 'is editing'
}

const getPresenceNames = function (
  presences: Presence[],
  includesOwnUser: boolean = false
) {
  if (presences.length >= SEVERALPEOPLE_BREAKPOINT) {
    return 'Several people'
  }

  if (includesOwnUser) {
    return 'You'
  }

  return presences[0].user.name
}

export const getPresenceMessage = function (
  presences: Presence[],
  ownUserIdentifier?: string
) {
  const includesOwnUser = presences.some(presence =>
    isOwnUser(presence, ownUserIdentifier)
  )

  return {
    names: getPresenceNames(presences, includesOwnUser),
    message: getPresenceText(presences, includesOwnUser),
  }
}

export const getUniquePresences = function (presences: Presence[] = []) {
  // We want to keep one presence per user, and prioritise keeping "editing"
  // presences over "viewing" presences if the user has multiple.
  // To acheive this we:
  //   1. sort the array so that all "viewing" presences appear before "editing" ones
  //   2. use uniqBy(..., 'user.identifier') which will keep the later entry if
  //   two entries are considered the same
  // So if the user has both a "viewing" and "editing" presence, the "viewing"
  // presence will appear first in the sorted array. Then `uniqBy` will keep
  // the "editing" presence as it will have the same `user.identifier` but
  // appears later in the array.
  // We have a test in `./utils.test` to protect against regressions in this
  // ordering behaviour.
  const sorted = presences.sort((a, b) => {
    if (a.action === 'editing') {
      return -1
    } else {
      return 1
    }
  })

  return uniqBy(sorted, 'user.identifier')
}

export function transformPropertyNames(presences: any[]): Presence[] {
  return presences.map(presence => ({
    currentPage: presence.current_page,
    followable: presence.followable,
    following: presence.following,
    id: presence.id,
    user: {
      avatarUrl: presence.user.avatar_url,
      identifier: presence.user.identifier,
      kind: presence.user.kind,
      name: presence.user.name,
    },
    action: presence.action,
    platform: presence.platform,
  }))
}
