import React from 'react'
import { FirstStepsItem, FirstStepsItemAction } from '../types'

import * as S from './FirstStepsCard.styles'

type FirstStepsCardProps = FirstStepsItem

export function FirstStepsCard({
  stepId,
  useGetPropsHook,
}: FirstStepsCardProps) {
  const itemProps = useGetPropsHook()

  return (
    <S.FirstStepsCard>
      {itemProps.icon && (
        <S.FirstStepsCardIcon
          as={itemProps.icon}
          aria-label={itemProps.title}
        />
      )}
      <S.FirstStepsCardTitle>{itemProps.title}</S.FirstStepsCardTitle>
      <S.FirstStepsCardDescription>
        {itemProps.description}
      </S.FirstStepsCardDescription>
      {itemProps.action && <FirstStepsCardAction action={itemProps.action} />}
    </S.FirstStepsCard>
  )
}

type FirstStepsCardActionProps = {
  action: FirstStepsItemAction
}

export function FirstStepsCardAction({ action }: FirstStepsCardActionProps) {
  const content = (
    <>
      <S.FirstStepsCardActionName>
        {action.text} <S.ActionArrow />
      </S.FirstStepsCardActionName>
    </>
  )

  if (action?.link) {
    return (
      <S.StyledActionLink variant="tertiary" {...action.link}>
        {content}
      </S.StyledActionLink>
    )
  }

  return (
    <S.StyledActionButton onClick={() => action.onClick?.()}>
      {content}
    </S.StyledActionButton>
  )
}
