import { createContext, useContext } from 'react'
import { ErrorHandler } from '@sketch/tracing'
import { PageLoadEvents } from './pageLoad'
import { TrackEventFn } from './plausible'
import { TrackMetricFn } from './telemetry'

export interface AnalyticsContext {
  trackEvent: TrackEventFn
  pageLoad: PageLoadEvents
  trackMetric: TrackMetricFn
  trackSimpleAnalytics: () => void
  removeSimpleAnalytics: () => void
  trackSimpleEvent: (eventName: string, path: string) => void
}

const defaultFailFn = () => {
  const message = 'AnalyticsProvider was not mounted but useAnalytics was used.'

  if (
    process.env.REACT_APP_ENV === 'dev' ||
    process.env.REACT_APP_ENV === 'test'
  ) {
    throw new Error(message)
  }

  ErrorHandler.shouldNeverHappen(message)
}

export const defaultFailContext: AnalyticsContext = {
  trackEvent: defaultFailFn,
  trackMetric: defaultFailFn,
  trackSimpleAnalytics: defaultFailFn,
  removeSimpleAnalytics: defaultFailFn,
  trackSimpleEvent: defaultFailFn,
  pageLoad: {
    observeCustomElement: defaultFailFn,
  },
}

const analyticsContext = createContext<AnalyticsContext>(defaultFailContext)

export const useAnalytics = () => {
  const analytics = useContext(analyticsContext)
  return analytics
}

export const AnalyticsProvider = analyticsContext.Provider
