import React from 'react'
import classnames from 'classnames'
import {
  CardCvcElementComponent,
  CardExpiryElementComponent,
  ElementProps,
} from '@stripe/react-stripe-js'
import styled, { useTheme } from 'styled-components'

import { Input, InputProps } from '../Input'
import { commonStyles, invalidStyles } from './commonStyles'
import { inputBaseStyles } from '../Input.styles'

const BaseInput = styled(Input)<InputProps>`
  ${inputBaseStyles};
`

export interface StripeInputProps extends ElementProps {
  disabled?: boolean
  invalid?: boolean
}

const createInput = (
  Element: CardCvcElementComponent | CardExpiryElementComponent
) => {
  const BaseElement = styled(Element)`
    flex: 1;
    height: 40px;
    padding-top: 2px; /* stylelint-disable-line scales/space */
  `

  const StripeInput = (props: StripeInputProps) => {
    const theme = useTheme()

    return (
      <BaseInput
        /*
      Stripe sometimes removes the className from the wrapper div.
      Forcing it like this will prevent this behaviour
      */
        className={classnames('StripeElement', props.className)}
        disabled={props.disabled ?? false}
        invalid={props.invalid}
        as="div"
      >
        <BaseElement
          options={{
            style: props.invalid ? invalidStyles(theme) : commonStyles(theme),
          }}
          {...props}
        />
      </BaseInput>
    )
  }

  return StripeInput
}

export default createInput
