import { StripeElementChangeEvent } from '@stripe/stripe-js'

type FormikField = StripeElementChangeEvent | undefined

export const createFormikValidator = <F extends string>(
  fields: Array<F>,
  requiredErrorMessage: { [K in F]: string }
) => {
  const stripeFieldValidator = (values: Record<F, FormikField>) =>
    fields.reduce((acc, field) => {
      const fieldValue = values[field]

      if (fieldValue?.error) {
        acc[field] = fieldValue.error.message!
      } else if (!fieldValue || fieldValue.empty) {
        acc[field] = requiredErrorMessage[field]
      }

      return acc
    }, {} as Record<F, string>)

  return stripeFieldValidator
}
