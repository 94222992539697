import React from 'react'
import {
  Section,
  Separator,
  Header,
  HeaderTitle,
  AttributeList,
  FullCopyAttribute,
} from '../../components'
import { Preset, Rect } from '../../../../../types'
import { Text } from '@sketch/components'

const isPresetResized = ({
  frame,
  preset,
}: {
  frame: Rect
  preset?: Preset
}) => {
  if (!preset) {
    return false
  }

  return frame.width !== preset.width || frame.height !== preset.height
}

interface ArtboardOverviewProps {
  preset?: Preset
  frame: Rect
}

export const ArtboardOverview: React.FC<ArtboardOverviewProps> = ({
  preset,
  frame,
}) => {
  const template = preset?.name || 'Custom'
  const isResized = isPresetResized({ frame, preset })

  return (
    <>
      <Separator />
      <Section data-testid="inspector-sidebar-artboard-size">
        <Header>
          <HeaderTitle>Artboard</HeaderTitle>
        </Header>
        <AttributeList>
          <FullCopyAttribute
            label="Template"
            value={
              <div>
                {template}
                {isResized && (
                  <Text textStyle="copy.quaternary.standard.C"> (Resized)</Text>
                )}
              </div>
            }
            copyValue={template}
          />
        </AttributeList>
      </Section>
    </>
  )
}
