import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "design-system-64", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 64 64", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M15.25 19.334l-.12.159A20.906 20.906 0 0011 32c0 11.598 9.402 21 21 21 2.892 0 5.647-.585 8.154-1.642a5.983 5.983 0 001.395 2.666A23.898 23.898 0 0132 56C18.745 56 8 45.255 8 32a23.896 23.896 0 015.006-14.673 6.026 6.026 0 002.243 2.007zM32 8c13.255 0 24 10.745 24 24a23.896 23.896 0 01-5.006 14.673 6.026 6.026 0 00-2.243-2.007A20.904 20.904 0 0053 32c0-11.598-9.402-21-21-21-2.892 0-5.647.585-8.154 1.642a5.983 5.983 0 00-1.395-2.666A23.898 23.898 0 0132 8z", fill: "currentColor", fillRule: "nonzero" }),
        React.createElement("circle", { stroke: "currentColor", strokeWidth: 3, cx: 18, cy: 14, r: 4.5 }),
        React.createElement("circle", { stroke: "currentColor", strokeWidth: 3, cx: 46, cy: 50, r: 4.5 }),
        React.createElement("circle", { stroke: "currentColor", strokeWidth: 3, fillOpacity: 0.15, fill: "currentColor", cx: 32, cy: 32, r: 10.5 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
