import {
  indexLayoutSidebar,
  leftWidth,
  rightWidth,
  canvasFirstRightWidth,
} from '@sketch/constants'

export const sidebar = {
  indexLayoutSidebar,
  leftWidth,
  rightWidth,
  canvasFirstRightWidth,
} as const
