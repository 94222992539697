import styled, { css } from 'styled-components'
import { FlatButton } from './FlatButton'

const hoverStyle = css<{ disabled?: boolean }>`
  padding: 8px 12px;

  border-radius: ${({ theme }) => theme.radii.large};

  :hover {
    ${({ disabled, theme }) =>
      !disabled && `background: ${theme.colors.background.tertiary.D}`};
  }
`

export const DropdownActionButton = styled(FlatButton).attrs({
  size: '40',
})`
  ${hoverStyle}

  font-size: 0.8125rem;
  font-weight: 400;
  width: calc(100% - 16px);
  text-align: initial;
  margin: 0 8px;
`
