import { useEffect, useRef, FC } from 'react'

type FuncHandler = Falsy | (() => void)
export interface MountHandleProps {
  onMount?: FuncHandler
  onUnmount?: FuncHandler
}

export const MountHandle: FC<MountHandleProps> = ({ onMount, onUnmount }) => {
  const mountRef = useRef<FuncHandler>()
  mountRef.current = onMount
  const unmountRef = useRef<FuncHandler>()
  unmountRef.current = onUnmount

  useEffect(() => {
    mountRef.current && mountRef.current()

    return () => {
      if (unmountRef.current) {
        unmountRef.current()
      }
    }
  }, [])

  return null
}
