import { useMemo } from 'react'

import {
  useGetWorkspaceSubscribeInformationQuery,
  useGetPaymentDetailsQuery,
  useGetAvailablePlansQuery,
} from '@sketch/gql-types'

export const useWorkspaceSubscribeInformation = (
  workspaceId: string,
  customerId: string
) => {
  const plansQuery = useGetAvailablePlansQuery({
    variables: { customerId },
  })

  const subscribeInformation = useGetWorkspaceSubscribeInformationQuery({
    variables: { identifier: workspaceId },
    fetchPolicy: 'network-only',
  })

  const customerPayment = useGetPaymentDetailsQuery({
    variables: { customerId },
    fetchPolicy: 'network-only',
  })

  /**
   * The Memo exists to prevent infinite loops on effects
   * that might depend on this data
   */
  const data = useMemo(() => {
    if (
      !plansQuery.data ||
      !subscribeInformation.data ||
      !customerPayment.data
    ) {
      return undefined
    }

    return {
      ...plansQuery.data,
      ...subscribeInformation.data,
      ...customerPayment.data,
    }
  }, [customerPayment.data, plansQuery.data, subscribeInformation.data])

  return {
    loading:
      subscribeInformation.loading ||
      customerPayment.loading ||
      plansQuery.loading,
    error:
      subscribeInformation.error || customerPayment.error || plansQuery.error,
    data,
  }
}
