import styled, { css } from 'styled-components'

import { Dropdown } from '@sketch/components'

import { ReactComponent as Chevrons } from '@sketch/icons/chevrons-up-down-16'

interface ToggleWrapperProps {
  $isEmbedded?: boolean
  disabled?: boolean
}

export const ToggleWrapper = styled.button.attrs({
  'data-focus-ring-offset-none': true,
})<ToggleWrapperProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
  background: none;
  font-size: ${({ theme }) => theme.fontSizes.D};

  cursor: pointer;

  ${({ $isEmbedded }) =>
    $isEmbedded
      ? css`
          height: 40px;
          position: absolute;
          top: 0;
          right: 0;
          padding: 10px 12px;
          color: ${({ theme }) => theme.colors.foreground.secondary.A};
        `
      : css`
          color: ${({ theme }) => theme.colors.foreground.secondary.C};
        `}

  ${({ disabled }) =>
    disabled &&
    `
    // Allows hover events to be triggered on disabled elements https://github.com/facebook/react/issues/4251#issuecomment-529182276
    pointer-events: none;
    opacity: 0.5;
  `}
`

export const IconWrapper = styled.div`
  display: flex;
  margin-left: 4px;
`

export const StyledChevron = styled(Chevrons)`
  width: 16px;
  height: 16px;
`

export const RemoveFromInviteItem = styled(Dropdown.Item)`
  padding: 0 0 0 2px; /* stylelint-disable-line scales/space */
`
