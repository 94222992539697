import React, { FC } from 'react'

import { OrderedBreadcrumbs } from '@sketch/components'

import { BreadcrumbsWrapper } from './SignUpBreadcrumbs.styles'

const SIGN_UP_CRUMBS = [{ content: 'Create Account' }, { content: 'About You' }]
const WORKSPACE_CRUMBS = [...SIGN_UP_CRUMBS, { content: 'Create Workspace' }]

interface SignUpBreadcrumbsProps {
  showWorkspaceCrumbs: boolean
  currentCrumb: number
}

const SignUpBreadcrumbs: FC<SignUpBreadcrumbsProps> = ({
  showWorkspaceCrumbs,
  currentCrumb,
}) => (
  <BreadcrumbsWrapper>
    <OrderedBreadcrumbs
      crumbs={showWorkspaceCrumbs ? WORKSPACE_CRUMBS : SIGN_UP_CRUMBS}
      currentCrumb={currentCrumb}
    />
  </BreadcrumbsWrapper>
)

export default SignUpBreadcrumbs
