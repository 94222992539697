import styled from 'styled-components'
import { Text } from '@sketch/components'

export const Edit = styled.span`
  margin-right: ${({ theme }) => theme.space[1]}px;
`

export const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.F};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`

export const SubTitle = styled(Text.H2).attrs({
  textStyle: 'copy.primary.standard.F',
})`
  margin-top: 24px;
  margin-bottom: 8px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`

export const Description = styled.p`
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: ${({ theme }) => theme.fontSizes.E};
`

export const Container = styled.div<{ isLoading: boolean }>`
  position: relative;
  width: 100%;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 20px; /* stylelint-disable-line scales/radii */
  border: 1px solid ${({ theme }) => theme.colors.border.B};
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  box-shadow: ${({ theme }) =>
    `${theme.colors.shadow.inner} 0 1px 2px 0 inset;`};

  ${({ isLoading }) =>
    isLoading &&
    `
      pointer-events: none;
      opacity: 0.5;
    `}
`

export const Dropzone = styled.div<{ isDragActive: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: ${({ theme, isDragActive }) =>
    isDragActive ? theme.colors.sketch.A : theme.colors.foreground.secondary.A};
  border-radius: 20px; /* stylelint-disable-line scales/radii */
  cursor: pointer;

  :focus {
    border-color: ${({ theme }) => theme.colors.sketch.A};
    -web-focus-ring: none;
    outline: none;
  }
`

export const DropzoneInstructions = styled(Text).attrs({
  textStyle: 'copy.quaternary.standard.E',
})`
  z-index: 1;
  user-select: none;
  cursor: pointer;
`

export const Label = styled.p`
  margin-top: 20px;
  margin-bottom: 6px; /* stylelint-disable-line scales/space */
  font-size: ${({ theme }) => theme.fontSizes.E};
`
