import { useToast } from '@sketch/toasts'
import { useStableHandler } from '@sketch/utils'
import { useLocation } from 'react-router-dom'
import { stringifyUrl } from 'query-string'
import copy from 'copy-to-clipboard'

interface UseCopyLinkProps {
  additionalQueryParameters?: Record<string, string>
}

export const useCopyLink = (props?: UseCopyLinkProps) => {
  const { additionalQueryParameters } = props || {}

  const location = useLocation()
  const { showToast } = useToast()

  const createLink = useStableHandler(() => {
    const url = stringifyUrl({
      url: `${window.location.origin}${location.pathname}`,
      query: additionalQueryParameters,
    })

    copy(url)
    showToast('Link copied')
  })

  return createLink
}
