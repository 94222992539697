import styled, { css } from 'styled-components'
import { Banner } from '@sketch/components'
export {
  BackButton,
  BackIcon,
} from '../../../../shares/ComponentsWebView/components/Sidebar/MobileGroupsDropdown.styles'
export {
  SelectDropdownModalFooter,
  SelectDropdownCancelButton,
} from '@sketch/components'

export const MobileNavigationHeader = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.foreground.secondary.D};
    font-weight: ${theme.fontWeights.semibold};
    font-size: ${theme.fontSizes.B};
    text-transform: uppercase;
    padding: 8px 16px 12px;
  `
)

export const WarningMessage = styled(Banner)`
  margin: 0 8px;
`
