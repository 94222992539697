import styled from 'styled-components'
import { RadioButton, Separator, Text } from '@sketch/components'

export const RadioLabel = styled(Text).attrs({
  textStyle: 'copy.tertiary.standard.E',
})`
  padding-bottom: 12px !important; /* stylelint-disable-line declaration-no-important */
`

export const StyledRadioButton = styled(RadioButton)`
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const StyledSeparator = styled(Separator)`
  margin: 24px 0 4px 0;
`

export const FormLabel = styled(Text).attrs({
  textStyle: 'copy.tertiary.standard.E',
})`
  padding-bottom: 12px !important; /* stylelint-disable-line declaration-no-important */
  padding-top: 20px;
`
