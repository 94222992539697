import React from 'react'
import { Text } from '@sketch/components'

const OpacityPercentage = ({ value: opacity }: { value?: number }) =>
  (opacity && opacity < 1) || opacity === 0 ? (
    <Text.Span textStyle="copy.quaternary.standard.D" mr={3} ml={1}>{`- ${(
      opacity * 100
    ).toFixed()}%`}</Text.Span>
  ) : null

export default OpacityPercentage
