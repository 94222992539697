import React from 'react'

import {
  AccessLevelSelect,
  AccessLevelSelectOption,
  AccessLevelSelectRemoveButton,
  AccessLevelSelectSeparator,
  PermissionGroupItem,
  removeFromPaginated,
} from '@sketch/modules-common'

import {
  ShareGroupMembershipInfoFragment,
  useRemovePermissionGroupToShareProjectMutation,
  useUpdateSharePermissionGroupRelationMutation,
} from '@sketch/gql-types'

import PermissionWrapper from '../PermissionWrapper'

interface PermissionGroupMemberProps {
  membership: ShareGroupMembershipInfoFragment
}

const PermissionGroupMember = (props: PermissionGroupMemberProps) => {
  const { membership } = props

  const [updatePermissionGroup] = useUpdateSharePermissionGroupRelationMutation(
    {
      onError: 'show-toast',
      optimisticResponse: ({ relationIdentifier, accessLevel }) => ({
        __typename: 'RootMutationType',
        updateSharePermissionGroupRelation: {
          __typename: 'UpdateSharePermissionGroupRelationResponse',
          relation: {
            accessLevel: accessLevel!,
            identifier: relationIdentifier,
            __typename: 'PermissionGroupRelationShare',
          },
        },
      }),
    }
  )

  const [deleteRelation] = useRemovePermissionGroupToShareProjectMutation({
    onError: 'show-toast',
    update: cache => {
      removeFromPaginated(
        cache,
        {
          __typename: 'PermissionGroupRelationShare',
          identifier: membership.identifier,
        },
        () => true
      )
    },
    optimisticResponse: () => ({
      __typename: 'RootMutationType',
      removePermissionGroupRelation: {
        __typename: 'RemovePermissionGroupRelationResponse',
        permissionGroup: membership.permissionGroup,
        project: null,
        share: null,
      },
    }),
  })

  return (
    <PermissionWrapper>
      <PermissionGroupItem permissionGroup={membership.permissionGroup} />
      <AccessLevelSelect
        level={membership.accessLevel}
        setLevel={(
          accessLevel: ShareGroupMembershipInfoFragment['accessLevel']
        ) => {
          updatePermissionGroup({
            variables: {
              relationIdentifier: membership.identifier,
              accessLevel,
            },
          })
        }}
        commentsEnabled={true}
        hideCommentLabel={false}
      >
        <AccessLevelSelectOption
          data-testid="access-level-dropdown-view"
          level="VIEW"
          label="View"
          help="View document in the web app"
        />
        <AccessLevelSelectOption
          data-testid="access-level-dropdown-edit"
          level="EDIT"
          label="Edit"
          help="Edit and view the document"
        />

        <AccessLevelSelectSeparator />
        <AccessLevelSelectRemoveButton
          onClick={() =>
            deleteRelation({
              variables: {
                input: { relationIdentifier: membership.identifier },
              },
            })
          }
        >
          Remove Selected Group
        </AccessLevelSelectRemoveButton>
      </AccessLevelSelect>
    </PermissionWrapper>
  )
}

export default PermissionGroupMember
