import styled, { css } from 'styled-components'
import { getDisplayName } from '@sketch/utils'
import {
  top,
  left,
  right,
  bottom,
  LeftProps,
  TopProps,
  RightProps,
  BottomProps,
  zIndex,
  ZIndexProps,
} from 'styled-system'

export interface AbsoluteProps
  extends LeftProps,
    TopProps,
    RightProps,
    BottomProps,
    ZIndexProps {
  position?: never
}

const absoluteCss = css`
  position: absolute;
  ${left}
  ${top}
  ${right}
  ${bottom}
  ${zIndex}
`

export function withAbsolute<C extends AnyComponent>(Comp: C) {
  const styledComp = styled(Comp)<AbsoluteProps>`
    ${absoluteCss}
  `
  styledComp.displayName = `withAbsolute(${getDisplayName(Comp)})`
  return styledComp
}
