import styled, { css } from 'styled-components'
import { Text } from '@sketch/components'
import {
  WrapperDocs,
  Content,
  SeparatorDocs,
} from '../DesignSystemWithDocs/DesignSystemWithDocs.styles'

export const PageWrapper = styled(WrapperDocs)`
  margin: 0;
  min-height: calc(100vh - 90px);
`

export const PageContent = styled(Content)``

export const PageSeparator = styled(SeparatorDocs)`
  margin-bottom: 40px;
`

const PlaceholderStyle = css`
  :empty {
    ::after {
      font-style: normal;
    }
  }
`

export const Title = styled(Text.H1).attrs({
  textStyle: 'header.primary.I',
})`
  overflow-wrap: break-word;
  ${PlaceholderStyle};
`

export const Description = styled.h1(
  ({ theme }) => css`
    overflow-wrap: break-word;
    color: ${theme.colors.foreground.secondary.A};
    font-size: ${theme.fontSizes.G};
    font-weight: ${theme.fontWeights.regular};
    ${PlaceholderStyle};
  `
)
