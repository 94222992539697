import React from 'react'

import { Pill, GuestBadge } from './BaseRow/BaseListRow.styles'

import { BadgeForMemberRoleProps, InviteStatus } from './types'

export const badgeForMemberRole = ({
  role,
  inviteStatus,
}: BadgeForMemberRoleProps) => {
  switch (inviteStatus) {
    case 'INVITED':
      return (
        <Pill role="status-badge" variant="secondary">
          Invitation Pending
        </Pill>
      )
    case 'REQUESTED':
      switch (role) {
        case 'MEMBER':
          return null
        default:
          return <GuestBadge role="status-badge">Guest</GuestBadge>
      }
    case 'ACCEPTED':
      switch (role) {
        case 'ADMIN':
          return (
            <Pill role="status-badge" variant="admin">
              Admin
            </Pill>
          )
        case 'GUEST':
          return <GuestBadge role="status-badge">Guest</GuestBadge>
        case 'MEMBER':
          return null
      }
      return <GuestBadge role="status-badge">Guest</GuestBadge>
    default:
      return null
  }
}

export const isInvitationRequested = (inviteStatus: InviteStatus) =>
  inviteStatus === 'REQUESTED' || inviteStatus === 'INVITED'
