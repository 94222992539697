import styled from 'styled-components'

import { Button } from '@sketch/components'
import AppLogo from '@sketch/icons/logo-2x.png'

export const ActionButton = styled(Button).attrs({ size: '32' })`
  padding: 4px 8px;
  padding-left: 10px; /* stylelint-disable-line scales/space */
  font-size: ${({ theme }) => theme.fontSizes.D};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  box-shadow: none;

  &,
  :hover,
  :focus,
  :active {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  &[disabled] {
    opacity: 0.5;
  }
`

export const AppIcon = styled.img.attrs({ src: AppLogo })`
  width: 16px;
  height: 16px;
  margin-left: 4px;
  /* This new icon has a shadow below it so we need
  to compensate it with margin*/
  margin-top: 2px; /* stylelint-disable-line scales/space */
`

export const TextButtonWrapper = styled.span``
