import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "border-join-miter-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "currentColor", fillRule: "evenodd" },
        React.createElement("path", { fillOpacity: 0.15, d: "M2.75 2.75H14V14H2.75z" }),
        React.createElement("path", { fillRule: "nonzero", d: "M14 2v1.5H3.5V14H2V2z" }),
        React.createElement("path", { fillRule: "nonzero", d: "M14 7.5V9H9v5H7.5V7.5z" }),
        React.createElement("path", { d: "M6.5 6.5H10V10H6.5z" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
