import React, { FC } from 'react'

import { Link } from '@sketch/components'

import { WorkspaceProfileFragment } from '@sketch/gql-types'

import { Container, Text, BuyMeACoffeeButton } from './ProfileInfo.styles'

const getShortWebsiteURL = (url: string) =>
  new URL(url).hostname.replace('www.', '')

interface ProfileInfoProps {
  profile: Partial<
    Pick<WorkspaceProfileFragment, 'donateUrl' | 'description' | 'websiteUrl'>
  >
}

export const ProfileInfo: FC<ProfileInfoProps> = ({ profile }) => {
  const { donateUrl, description, websiteUrl } = profile
  const isProfileInfoEmpty = !description && !websiteUrl && !donateUrl

  // Avoid the container to render if the user does have profile information
  if (isProfileInfoEmpty) return null

  return (
    <Container>
      {description && <Text>{description}</Text>}
      {websiteUrl && (
        <Text>
          <Link external href={websiteUrl}>
            {getShortWebsiteURL(websiteUrl)}
          </Link>
        </Text>
      )}
      {donateUrl && <BuyMeACoffeeButton url={donateUrl} />}
    </Container>
  )
}
