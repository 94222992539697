import { ApolloLink } from 'apollo-link'
import { getNetworkRequestHeaders } from '@sketch/modules-common'

export const createClientHeaders = () =>
  new ApolloLink((operation, forward) => {
    // Add client version headers to monitor API calls
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...getNetworkRequestHeaders(),
      },
    }))

    return forward(operation)
  })
