import React from 'react'

import {
  Wrapper,
  AdditionalText,
  AvatarWrapper,
  Size,
  AvatarItemWrapper,
} from './AvatarStack.styles'

interface AvatarStackProps {
  className?: string
  totalCount: number
  size: Size
  // TODO: pass this as a prop - if there is just a single item, react can pass just that node instead of the array
  // TODO: we should limit this to accept ImagePersonAvatar, ColorPersonAvatar or WorkspaceLogo
  children: React.ReactNode[]
}

const MAXIMUM_AVATAR_COUNT = 3

export const AvatarStack = React.forwardRef<HTMLDivElement, AvatarStackProps>(
  function AvatarStack(props, ref) {
    const { className, size, totalCount } = props
    let { children } = props
    const showAdditionalText = totalCount > MAXIMUM_AVATAR_COUNT

    if (!children) return null

    // if there is just a single item, react can pass just that node instead of the array
    if (!Array.isArray(children)) {
      children = [children]
    }

    return (
      <Wrapper
        className={className}
        ref={ref}
        $size={size}
        $hasBackground={showAdditionalText}
        aria-label="Avatar stack"
      >
        {showAdditionalText && (
          <AdditionalText>+{totalCount - 1}</AdditionalText>
        )}
        <AvatarWrapper>
          {React.Children.map(children.slice(0, MAXIMUM_AVATAR_COUNT), item => (
            <AvatarItemWrapper>{item}</AvatarItemWrapper>
          ))}
        </AvatarWrapper>
      </Wrapper>
    )
  }
)
