import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "eye-strikethrough-24", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("path", { d: "M3.81 1.73l10.606 9.092C12.808 11.936 11.051 12.5 9.15 12.5c-3.36 0-6.276-1.765-8.71-5.224L.213 6.95a1.25 1.25 0 010-1.398c1.098-1.629 2.298-2.905 3.595-3.82zM9.15 0c3.361 0 6.276 1.765 8.711 5.224l.226.327a1.25 1.25 0 010 1.398c-.776 1.15-1.602 2.125-2.478 2.92L5.169.922A8.923 8.923 0 019.15 0z", id: "eye-strikethrough-24_svg__a" })),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("g", { transform: "translate(2.85 5.75)" },
            React.createElement("mask", { id: "eye-strikethrough-24_svg__b", fill: "#fff" },
                React.createElement("use", { xlinkHref: "#eye-strikethrough-24_svg__a" })),
            React.createElement("path", { d: "M.835 5.972C3.182 2.49 5.954.75 9.15.75c3.197 0 5.969 1.74 8.316 5.222h0a.5.5 0 010 .557c-2.347 3.48-5.12 5.221-8.316 5.221-3.196 0-5.968-1.74-8.315-5.222h0a.5.5 0 010-.557z", stroke: "currentColor", strokeWidth: 1.5, strokeLinejoin: "round", mask: "url(#eye-strikethrough-24_svg__b)" }),
            React.createElement("circle", { stroke: "currentColor", strokeWidth: 1.5, mask: "url(#eye-strikethrough-24_svg__b)", cx: 9.15, cy: 6.25, r: 3 })),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round", d: "M4.5 6.5l14 12" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
