import React from 'react'
import { roundWithLocale, capitalize } from '@sketch/utils'
import { ColorFormat } from '../../../../../../types'
import copy from './copy'
import {
  Color,
  AttributeList,
  FullCopyAttribute,
  Header,
  SubTitle,
} from '../../../components'
import { getDirtyAttributes } from '../../../components/Style/DirtyIconTooltip'
import { Shadow } from '../../../../../../../inspector'

const renderShadow = (
  { color, offsetX, offsetY, blurRadius, spread, appearance }: Shadow,
  {
    onColorFormatChange,
    colorFormat,
  }: {
    onColorFormatChange: (f: ColorFormat) => void
    colorFormat: ColorFormat
  },
  i: number,
  originalValue?: Shadow
) => (
  <AttributeList key={`Shadow${i}`} data-testid="shadow">
    <Color
      {...color}
      onColorFormatChange={onColorFormatChange}
      colorFormat={colorFormat}
      dirtyAttributes={
        originalValue?.color
          ? {
              originalValue: originalValue.color,
              originalProperty: 'Color',
            }
          : undefined
      }
    />
    {appearance?.blendMode &&
    appearance?.blendMode.toLowerCase() !== 'normal' ? (
      <FullCopyAttribute
        label="Blend Mode"
        value={capitalize(appearance.blendMode)}
        dirtyAttributes={getDirtyAttributes({
          originalValueKey: 'blendMode',
          valueToDisplay: originalValue?.blendMode,
          originalValues: originalValue,
          labelToDisplay: 'Blend Mode',
        })}
      />
    ) : null}
    <FullCopyAttribute
      label="X"
      copyValue={`${offsetX}`}
      value={`${roundWithLocale(offsetX, 2)}`}
      dirtyAttributes={getDirtyAttributes({
        originalValueKey: 'offsetX',
        valueToDisplay: `${roundWithLocale(originalValue?.offsetX ?? 0, 2)}`,
        originalValues: originalValue,
        labelToDisplay: 'X',
      })}
    />
    <FullCopyAttribute
      label="Y"
      copyValue={`${offsetY}`}
      value={`${roundWithLocale(offsetY, 2)}`}
      dirtyAttributes={getDirtyAttributes({
        originalValueKey: 'offsetY',
        valueToDisplay: `${roundWithLocale(originalValue?.offsetY ?? 0, 2)}`,
        originalValues: originalValue,
        labelToDisplay: 'Y',
      })}
    />
    <FullCopyAttribute
      label="Blur"
      copyValue={`${blurRadius}`}
      value={`${roundWithLocale(blurRadius, 2)}`}
      dirtyAttributes={getDirtyAttributes({
        originalValueKey: 'blurRadius',
        valueToDisplay: `${roundWithLocale(originalValue?.blurRadius ?? 0, 2)}`,
        originalValues: originalValue,
        labelToDisplay: 'Radius',
      })}
    />
    {spread || spread === 0 ? (
      <FullCopyAttribute
        label="Spread"
        copyValue={`${spread}`}
        value={`${roundWithLocale(spread, 2)}`}
        dirtyAttributes={getDirtyAttributes({
          originalValueKey: 'spread',
          valueToDisplay: `${roundWithLocale(originalValue?.spread ?? 0, 2)}`,
          originalValues: originalValue,
        })}
      />
    ) : null}
  </AttributeList>
)

export interface ShadowsProps {
  title?: string
  shadows: Shadow[]
  onColorFormatChange: (f: ColorFormat) => void
  colorFormat: ColorFormat
  originalValues?: Shadow[]
}

export const Shadows = (props: ShadowsProps) => {
  const { onColorFormatChange, colorFormat, originalValues } = props
  const shadows: Shadow[] = props.shadows
  // TODO: move this processing to the `useGetInspectorData` once https://github.com/sketch-hq/Cloud/issues/3052 is done
  const reversedShadows = [...shadows].reverse()
  const reversedOriginalValues = originalValues
    ? [...originalValues].reverse()
    : undefined
  const enabledShadows = reversedShadows.filter(s => s.isEnabled)
  if (enabledShadows.length === 0) {
    return null
  }

  const dirtyHeaderAttributes =
    originalValues && originalValues[0] === null
      ? { originalValue: 'disabled', originalProperty: 'Shadow value' }
      : undefined

  return (
    <>
      <Header
        copyValue={copy(enabledShadows, colorFormat)}
        dirtyAttributes={dirtyHeaderAttributes}
      >
        <SubTitle>{props.title || 'Shadows'}</SubTitle>
      </Header>
      {enabledShadows.map((shadow, i) =>
        renderShadow(
          shadow,
          { onColorFormatChange, colorFormat },
          i,
          reversedOriginalValues ? reversedOriginalValues[i] : undefined
        )
      )}
    </>
  )
}
