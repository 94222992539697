import React from 'react'
import { useParams } from 'react-router-dom'

import {
  Box,
  Button,
  ConfirmationDialog,
  Navbar,
  useModalContext,
} from '@sketch/components'
import { RouteParams } from '@sketch/modules-common'
import { useDSDocsEditModeContext } from '../../operations/DSDocsEditModeContext'
import { CreateDSDocumentationModal } from '../../components/CreateDSDocumentationModal'
import { NavbarIcon } from './EditorSidebar.styles'
import { ReactComponent as PlusIcon } from '@sketch/icons/plus-16'
import { ReactComponent as EditIcon } from '@sketch/icons/pencil-16'
import { DesignSystem as DesignSystemType } from '../../types'

const NavbarSection = Navbar.Section

interface EditorNavbarProps {
  userIsGuest: boolean
  designSystem: DesignSystemType
}

const EditorNavbar: React.FC<EditorNavbarProps> = ({
  userIsGuest,
  designSystem,
}) => {
  const { showModal, hideModal } = useModalContext()
  const { designSystemId } = useParams<RouteParams<'DESIGN_SYSTEM'>>()
  const {
    isEditMode,
    discardChanges,
    saveContent,
    openEditMode,
  } = useDSDocsEditModeContext()

  const onAddDocumentation = () => {
    showModal(CreateDSDocumentationModal, {
      openEditMode,
      designSystemId,
    })
  }

  const handleDiscardChanges = () => {
    showModal(ConfirmationDialog, {
      title: 'Discard changes?',
      children: (
        <p>
          You have unsaved changes on this page. Do you want to discard those
          changes, or continue editing?
        </p>
      ),
      confirmButton: {
        text: 'Continue Editing',
      },
      cancelButton: {
        text: 'Discard Changes',
      },
      onConfirm: () => {
        hideModal()
      },
      onHide: () => {
        discardChanges()
      },
    })
  }

  const hasDocs = designSystem?.sections.length > 0

  let content = null

  if (isEditMode) {
    content = (
      <>
        <Button
          size="32"
          variant="secondary-untinted"
          onClick={handleDiscardChanges}
        >
          Discard Changes
        </Button>
        <Button size="32" variant="primary" onClick={saveContent}>
          Done
        </Button>
      </>
    )
  } else if (!userIsGuest) {
    content = !hasDocs ? (
      <Button
        size="32"
        variant="secondary-untinted"
        onClick={onAddDocumentation}
      >
        <NavbarIcon as={PlusIcon} />
        Add Documentation&hellip;
      </Button>
    ) : (
      <Button size="32" variant="secondary-untinted" onClick={openEditMode}>
        <NavbarIcon as={EditIcon} />
        Edit
      </Button>
    )
  }

  return (
    <Box pr={4} pl={4} pt={2} pb={2} width="100%">
      <NavbarSection align="end">{content}</NavbarSection>
    </Box>
  )
}

export default EditorNavbar
