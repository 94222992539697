import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "img-arrows-up-right-down-left-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd", strokeLinecap: "round", stroke: "currentColor", strokeWidth: 1.5 },
        React.createElement("path", { strokeLinejoin: "round", d: "M14.25 4.75h5v5" }),
        React.createElement("path", { d: "M19 5l-4.5 4.5" }),
        React.createElement("path", { strokeLinejoin: "round", d: "M9.75 19.25h-5v-5" }),
        React.createElement("path", { d: "M5 19l4.5-4.5" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
