import { useState, useEffect } from 'react'
import { Location, History } from 'history'
import qs from 'query-string'

import debounce from 'debounce'

const DEBOUNCE_INTERVAL = 500

const setSearchQueryDebounced = debounce(
  (
    searchValue: string,
    history: History,
    previousQuery: Record<string, string>
  ) => {
    // When the user clears a search we remove the search query param
    if (!searchValue && previousQuery?.search) {
      // Drop the search term but keep the other query parameters
      const { search: _, ...newQuery } = previousQuery
      history.push({ search: qs.stringify(newQuery) })
      return
    }

    if (!searchValue && previousQuery?.search) {
      // If there's no search then we should clear the URL parameter
      const { search, ...otherParameters } = previousQuery

      history.push({
        search: qs.stringify(otherParameters),
      })
    } else if (!searchValue) {
      // If there's no search then we should clear the URL parameter
      return
    }

    history.push({
      search: qs.stringify({
        ...previousQuery,
        search: searchValue,
      }),
    })
  },
  DEBOUNCE_INTERVAL
)

export const useURLQuerySearch = (
  location: Location,
  history: History
): [string, (searchValue: string) => void, string] => {
  const queryArguments = qs.parse(location.search) as Record<string, string>
  const searchQuery = queryArguments.search || ''
  const [search, setSearchValue] = useState(searchQuery)

  const setSearchQuery = (searchValue: string) => {
    setSearchValue(searchValue)
    setSearchQueryDebounced(searchValue, history, queryArguments)
  }

  /**
   * Sync the internal search with the url search
   */
  useEffect(() => {
    setSearchValue(searchQuery)
  }, [searchQuery])

  return [search, setSearchQuery, searchQuery]
}
