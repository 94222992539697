import React from 'react'

import {
  Container,
  ContainerWithoutLink,
  LinkStyled,
  VersionLinkStyled,
  Body,
  Preview,
  Chevron,
  Name,
  Path,
  DirtyLayerWarningTooltipHeader,
  DirtyLayerWarningTooltipContent,
  ExclamationMarkIcon,
  StyledDirtyLayerTooltip,
} from './TextOrLayerStyles.styles'

import { routes } from '@sketch/modules-common'
import { useResponsiveImage } from '../../../../../ComponentsWebView/hooks'

import { VersionFragment } from '@sketch/gql-types'
import { File } from '../../../../../ComponentsWebView/types'

// This is a valid use case to narrow down ComponentInfoFragment type
// eslint-disable-next-line no-restricted-imports
import { LayerStyle } from '@sketch/gql-types/expansive'
import { Header, HeaderTitle, Section, Separator } from '../../components'
import {
  useGetComponentWithUuid,
  isTextStyle,
} from '../../useGetComponentWithUuid'
import { formatPath } from '../../../../utils'
import { SharedStyleReference } from '../../../../../../inspector'
import { useComponentsState } from '../../../../../components/ComponentsStateContext'
import { StyledSkeleton } from '../../ArtboardDetailInspector.styles'
import { Tooltip } from '@sketch/components'

/**
 * UTILS
 */
const getPath = (
  textOrLayerStyle: SharedStyleReference,
  externalShareName?: string | null
): { name: string; path: string } => {
  const textStylePath = textOrLayerStyle.name.split('/')
  const name = textStylePath.pop()

  const documentName = textOrLayerStyle.isForeign
    ? externalShareName
    : 'This Document'

  return {
    name: name?.trim() ?? '',
    path: formatPath([documentName || '', ...textStylePath]),
  }
}

/**
 * HELPER COMPONENTS
 */
const LinkUnavailable: React.FC = ({ children }) => (
  <Tooltip
    placement="top"
    content="This style belongs to a Library that is unavailable"
  >
    {children}
  </Tooltip>
)

interface TextOrLayerStylesProps {
  /** True if it's a textStyle false it's a layerStyle */
  isTextStyleComponent: boolean
  isDirty?: boolean
  shareIdentifier: string
  currentVersion: VersionFragment
  sharedStyleReference: SharedStyleReference
}

/**
 * MAIN COMPONENT
 */
export const TextOrLayerStyles = ({
  isTextStyleComponent,
  isDirty,
  shareIdentifier,
  currentVersion,
  sharedStyleReference,
}: TextOrLayerStylesProps) => {
  const componentsState = useComponentsState()

  const {
    loading,
    shareID,
    externalShareName,
    versionShortId,
    component,
    componentUnavailable,
    searchParams,
  } = useGetComponentWithUuid({
    componentId: sharedStyleReference?.isForeign
      ? sharedStyleReference?.remoteId
      : sharedStyleReference?.id,
    externalDocumentId: sharedStyleReference?.documentId,
    externalDocumentName: sharedStyleReference?.documentName,
    isForeign: sharedStyleReference?.isForeign,
    shareIdentifier,
    currentVersion,
  })

  const previewProps = useResponsiveImage({
    name: component?.name || '',
    files: (component as LayerStyle)?.files as File[],
  })

  if (!sharedStyleReference) {
    return null
  }

  const { name, path } = getPath(
    sharedStyleReference,
    // externalShareName has wrong value when there is an error with the query,
    // that's why we need to provide a fallback using sharedStyleReference.documentName
    componentUnavailable ? sharedStyleReference.documentName : externalShareName
  )
  const sectionCopyValue = sharedStyleReference.name

  const body = (
    <Body>
      <Name isDirty={isDirty}>
        {name}
        {isDirty && `*`}
      </Name>
      <Path>{path}</Path>
    </Body>
  )

  const componentWithoutLink = <Container>{body}</Container>

  const getPathnameForLatestShareVersion = () =>
    isTextStyleComponent
      ? routes.CWV_TEXT_STYLES.create({
          shareID: shareID!,
        })
      : routes.CWV_LAYER_STYLES.create({
          shareID: shareID!,
        })

  const linkExternalComponent = componentUnavailable ? (
    <LinkUnavailable>
      <ContainerWithoutLink data-testid="inspector-component-no-link">
        {body}
      </ContainerWithoutLink>
    </LinkUnavailable>
  ) : (
    <LinkStyled
      data-testid="inspector-component-link-external"
      to={{
        pathname: getPathnameForLatestShareVersion(),
        search: searchParams,
      }}
    >
      <Preview
        isTextStyle={isTextStyle(component)}
        needsContrastingBackground={
          !!(component as LayerStyle)?.needsContrastingBackground
        }
        {...previewProps}
      />
      {body}
      <Chevron />
    </LinkStyled>
  )

  const linkInternalComponent = (
    <VersionLinkStyled
      data-testid="inspector-component-link-internal"
      to={{
        routeKey: isTextStyleComponent ? 'CWV_TEXT_STYLES' : 'CWV_LAYER_STYLES',
        routeParams: {
          shareID: shareIdentifier,
          versionShortId,
        },
        search: searchParams,
      }}
    >
      <Preview
        isTextStyle={isTextStyle(component)}
        needsContrastingBackground={
          !!(component as LayerStyle)?.needsContrastingBackground
        }
        {...previewProps}
      />
      {body}
      <Chevron />
    </VersionLinkStyled>
  )

  // Note: when linking to a foreign component we always link to the latest
  // version, but for internal components we want a versioned link
  const wrapperLink = sharedStyleReference.isForeign
    ? loading
      ? componentWithoutLink
      : linkExternalComponent
    : linkInternalComponent

  return (
    <>
      <Separator />
      <Section data-testid="inspector-sidebar-styles">
        <Header
          copyValue={sectionCopyValue}
          extraRightContent={
            isDirty && (
              <DirtyLayerWarning isTextStyleComponent={isTextStyleComponent} />
            )
          }
        >
          <HeaderTitle>
            {isTextStyleComponent ? 'Text Styles' : 'Layer Styles'}
          </HeaderTitle>
        </Header>
        {componentsState === 'PROCESSING' ? <StyledSkeleton /> : wrapperLink}
      </Section>
    </>
  )
}

type DirtyLayerWarningProps = {
  isTextStyleComponent: boolean
}
function DirtyLayerWarning({ isTextStyleComponent }: DirtyLayerWarningProps) {
  return (
    <StyledDirtyLayerTooltip
      content={
        <DirtyLayerWarningTooltipContent>
          <DirtyLayerWarningTooltipHeader>
            {isTextStyleComponent ? 'Text' : 'Layer'} Style(s) out of sync
          </DirtyLayerWarningTooltipHeader>
          <br />
          One or more attributes are out of sync with the original Layer Style
        </DirtyLayerWarningTooltipContent>
      }
      placement="top"
    >
      <ExclamationMarkIcon
        role="img"
        aria-label="Dirty style"
        width={17}
        height={17}
      />
    </StyledDirtyLayerTooltip>
  )
}
