import React, { useEffect } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { routes } from '@sketch/modules-common'
import { useBanner } from '@sketch/components'

import { dateFormat } from '@sketch/utils'

// Usage of BillingStatus enum is needed to make the expected strings match the BE ones
// eslint-disable-next-line no-restricted-imports
import { BillingStatus } from '@sketch/gql-types/expansive'
import {
  CanceledBanner,
  UnpaidCancelBanner,
  PastDueBanner,
  IncompleteBanner,
} from './Banners'
import { WorkspaceMinimalFragment } from '@sketch/gql-types'

interface BillingStatusBannersProps {
  workspaceId: string
  workspace?: WorkspaceMinimalFragment
  sharesCount?: number
  billingStatus: BillingStatus
  cancelPeriodEnd?: string
  isUserAdmin?: boolean
  isEducationWorkspace: boolean
}

const CANCELED_PAYMENT_ISSUES_STATUS = ['UNPAID', 'INCOMPLETE_EXPIRED']

export const BillingStatusBanners: React.FC<BillingStatusBannersProps> = props => {
  const {
    billingStatus,
    workspaceId,
    workspace,
    cancelPeriodEnd,
    isUserAdmin,
    sharesCount,
    isEducationWorkspace,
  } = props
  const location = useLocation()
  const { showBanner } = useBanner()

  useEffect(() => {
    const isSettingsRoute = matchPath(location.pathname, {
      path: routes.WORKSPACE_SETTINGS.template(),
    })

    let banner: ReturnType<typeof showBanner> | null = null

    if (
      (isSettingsRoute && billingStatus === 'CANCELED') ||
      (!isSettingsRoute && billingStatus === 'CANCELED' && sharesCount)
    ) {
      banner = showBanner({
        message: (
          <CanceledBanner
            workspace={workspace}
            workspaceId={workspaceId}
            isUserAdmin={isUserAdmin}
          />
        ),
        type: 'error',
      })
    } else if (CANCELED_PAYMENT_ISSUES_STATUS.includes(billingStatus)) {
      banner = showBanner({
        message: <UnpaidCancelBanner workspaceId={workspaceId} />,
        type: 'error',
      })
    } else if (billingStatus === 'PAST_DUE' && isUserAdmin) {
      banner = showBanner({
        message: <PastDueBanner workspaceId={workspaceId} />,
        type: 'warning',
      })
    } else if (billingStatus === 'INCOMPLETE') {
      banner = showBanner({
        message: <IncompleteBanner workspaceId={workspaceId} />,
        type: 'warning',
      })
    } else if (
      cancelPeriodEnd &&
      !isEducationWorkspace &&
      (sharesCount || isSettingsRoute)
    ) {
      // check for sharesCount to prevent this banner from showing along with
      // the inactive workspace empty state
      banner = showBanner({
        message: (
          <>
            Your workspace subscription has been canceled. You can keep using
            Sketch until <b>{dateFormat(new Date(cancelPeriodEnd))}</b>.
          </>
        ),
        type: 'warning',
      })
    }

    return () => {
      banner?.dismissBanner()
    }
  }, [
    cancelPeriodEnd,
    showBanner,
    workspace,
    workspaceId,
    billingStatus,
    isUserAdmin,
    sharesCount,
    location.pathname,
    isEducationWorkspace,
  ])

  return null
}
