import { useEffect, useState } from 'react'

interface useCalculateScrollableAreaHeightProps {
  fixedHeightRef: React.RefObject<HTMLDivElement>
  hasLibraries: boolean
  listHeight: React.RefObject<HTMLDivElement>
}

export function useCalculateScrollableAreaHeight({
  fixedHeightRef,
  hasLibraries,
  listHeight,
}: useCalculateScrollableAreaHeightProps) {
  const [scrollablePanelHeight, setScrollablePanelHeight] = useState(0)
  const [initialListHeight, setInitialListHeight] = useState(0)

  useEffect(() => {
    const updateScrollHeight = () => {
      if (!fixedHeightRef.current || !hasLibraries || !listHeight.current) {
        return
      }

      if (!initialListHeight) {
        // store the list height without resizings
        setInitialListHeight(listHeight.current.offsetHeight)
      }

      // 500 is the size of the modal's header + footer + paddings
      const modalHeaderFooterPaddings = 500

      // height of the portion of the modal that does not scroll (name and avatar areas)
      const fixedHeight = fixedHeightRef.current.offsetHeight

      const totalModalHeight =
        modalHeaderFooterPaddings + fixedHeight + initialListHeight

      if (totalModalHeight > window.innerHeight) {
        const scrollHeight =
          window.innerHeight - fixedHeight - modalHeaderFooterPaddings

        // and setting the min height of 280, otherwise it's unreadable
        const minScrollHeight = 280

        setScrollablePanelHeight(
          scrollHeight < minScrollHeight ? minScrollHeight : scrollHeight
        )
      } else {
        setScrollablePanelHeight(initialListHeight)
      }
    }

    updateScrollHeight()

    window.addEventListener('resize', updateScrollHeight)

    return () => {
      window.removeEventListener('resize', updateScrollHeight)
    }
  }, [fixedHeightRef, hasLibraries, initialListHeight, listHeight])

  return scrollablePanelHeight
}
