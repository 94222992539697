import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "group-circle-64", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 64 64", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("circle", { id: "group-circle-64_svg__a", cx: 22.5, cy: 22.5, r: 22.5 }),
        React.createElement("path", { d: "M47-2l-1.373 24.5c-.509 6.131-2.942 11.487-7.3 16.068-1.793 1.885-3.668 3.383-5.626 4.493a56.74 56.74 0 001.59-1.396c-2.134-4.456-6.335-8.094-11.666-10.163a10.954 10.954 0 002.875-7.414c0-5.566-4.134-10.167-9.5-10.898V-2h31z", id: "group-circle-64_svg__c" })),
    React.createElement("g", { transform: "translate(9.5 9.5)", fill: "none", fillRule: "evenodd" },
        React.createElement("mask", { id: "group-circle-64_svg__b", fill: "#fff" },
            React.createElement("use", { xlinkHref: "#group-circle-64_svg__a" })),
        React.createElement("use", { stroke: "currentColor", strokeWidth: 3, xlinkHref: "#group-circle-64_svg__a" }),
        React.createElement("g", { mask: "url(#group-circle-64_svg__b)" },
            React.createElement("path", { d: "M13.055 34.607c7.95 0 14.558 4.376 15.896 10.138a22.402 22.402 0 01-10.17 4.848c-1.828-.026-3.773-.077-5.726-.084-.588-.002-1.177 0-1.764.005l-.702.006-.688.008a22.463 22.463 0 01-12.011-6.85c2.261-4.71 8.198-8.07 15.165-8.07zM14.5 19.088a5 5 0 110 10 5 5 0 010-10z", fillOpacity: 0.15, fill: "currentColor" }),
            React.createElement("path", { d: "M-1.931 42.871c2.351-4.678 8.303-8 15.273-8 6.97 0 12.922 3.322 15.273 8M14.5 19.088a5 5 0 110 10 5 5 0 010-10z", stroke: "currentColor", strokeWidth: 3 })),
        React.createElement("mask", { id: "group-circle-64_svg__d", fill: "#fff" },
            React.createElement("use", { xlinkHref: "#group-circle-64_svg__c" })),
        React.createElement("use", { fillOpacity: 0, fill: "#FFF", xlinkHref: "#group-circle-64_svg__c" }),
        React.createElement("g", { mask: "url(#group-circle-64_svg__d)" },
            React.createElement("path", { d: "M32.5 26.265c7.499 0 13.901 3.573 16.431 8.606A22.439 22.439 0 0132.5 42a22.438 22.438 0 01-16.43-7.128c2.529-5.034 8.931-8.607 16.43-8.607zm-1.765-15.412a5 5 0 110 10 5 5 0 010-10z", fillOpacity: 0.15, fill: "currentColor" }),
            React.createElement("path", { d: "M15.069 35.871c2.53-5.033 8.932-8.606 16.431-8.606 7.499 0 13.901 3.573 16.431 8.606M30.5 11.088a5 5 0 110 10 5 5 0 010-10z", stroke: "currentColor", strokeWidth: 3 }))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
