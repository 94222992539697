import React, { useContext } from 'react'

import { Dropdown, ModalContext } from '@sketch/components'
import { RenameShareModal } from '@sketch/modules-common'

interface RenameActionProps {
  id: string
  name: string
}

const RenameAction: React.FC<RenameActionProps> = ({ id, name, children }) => {
  const { showModal } = useContext(ModalContext)

  return (
    <Dropdown.Item
      onClick={() => {
        showModal(RenameShareModal, {
          id,
          name,
        })
      }}
    >
      {children}
    </Dropdown.Item>
  )
}

export default RenameAction
