import React, { FC, useContext } from 'react'
import styled from 'styled-components'

import {
  Flex,
  Button,
  AnchorButton,
  DownloadFileAnchor,
  ErrorMessage,
  Navbar,
  ModalContext,
  SidebarLayout,
} from '@sketch/components'
import { Footer } from '@sketch/modules-common'
import { ShareInfoFragment } from '@sketch/gql-types'
import { ReactComponent as TrashLarge } from '@sketch/icons/trash-bin-64'
import { RestoreShareModal } from '../components/RestoreShareModal'
import { getDownloadProps } from '../utils'
import ShareWorkspaceSidebar from '../components/ShareWorkspaceSidebar'
import HeaderBadge from '../components/HeaderBadge'

export type ShareForDeletedView = Pick<
  ShareInfoFragment,
  | 'name'
  | 'identifier'
  | 'version'
  | 'workspace'
  | 'userCanEdit'
  | 'userCanUpdateSettings'
>

export interface ShareDeletedViewProps {
  share: ShareForDeletedView
}

const DownloadButton = styled(DownloadFileAnchor).attrs({
  as: AnchorButton,
  variant: 'secondary',
  size: '40',
})``

export const ShareDeletedView: FC<ShareDeletedViewProps> = ({ share }) => {
  const { name, identifier } = share
  const { showModal } = useContext(ModalContext)

  const title = 'Document is in the Trash'
  const download = getDownloadProps(share.version)
  const userCanRestoreDocument =
    share.userCanEdit || share.userCanUpdateSettings

  const onRestore = () => {
    showModal(RestoreShareModal, {
      name,
      shareIdentifier: identifier,
    })
  }

  return (
    <SidebarLayout
      title={title}
      header={({ setSidebarLeftOpen }) => (
        <Navbar>
          <HeaderBadge
            workspace={share.workspace}
            setSidebarLeftOpen={setSidebarLeftOpen}
          />
        </Navbar>
      )}
      sidebarLeft={
        <ShareWorkspaceSidebar
          workspaceIdentifier={share.workspace.identifier}
        />
      }
      footer={<Footer />}
    >
      <ErrorMessage
        icon={<TrashLarge />}
        iconSize="medium"
        title={title}
        extra={
          <Flex>
            {download?.isAvailable && (
              <DownloadButton href={download.url}>Download</DownloadButton>
            )}
            {userCanRestoreDocument && (
              <Button variant="secondary" onClick={onRestore} small>
                Restore
              </Button>
            )}
          </Flex>
        }
      >
        You cannot open <b>“{share.name}”</b> because it&apos;s in the Workspace
        Trash.
      </ErrorMessage>
    </SidebarLayout>
  )
}
