import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "workspaces-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, strokeLinejoin: "round", d: "M8 .75l6.25 3.594v7.307L8 15.25l-6.25-3.599V4.344z" }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, fillOpacity: 0.15, fill: "currentColor", strokeLinejoin: "round", d: "M8 4.37l3.25 1.896v3.791L8 11.953l-3.25-1.896V6.266z" }),
        React.createElement("path", { fillOpacity: 0.15, fill: "currentColor", d: "M5 10l-3 1.5L8 15l6-3.5v-7L11 6v4l-3 1.5z" }),
        React.createElement("path", { d: "M8 1v3.5m0 7V15M2 4.5l3 2m9-2l-3 2m-9 5l3-2m6 0l3 2", stroke: "currentColor", strokeWidth: 1.5 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
