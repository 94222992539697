import { ButtonUnstyled } from '@sketch/components'
import styled from 'styled-components'

import AnnotationDot from '../AnnotationDot'

export const AnnotationDotWrapper = styled.div`
  width: 0px;
  height: 0px;

  /*
  In some implementations the wrappers of the annotation dot
  have "pointer-events: none" to prevent the wrapper from consuming the events
  having "pointer-events: auto" here will make sure this events are still actionable
  even if the parent ain't
   */
  pointer-events: auto;

  /* Prevent the other dots from being on top of the popover + active dot */
  z-index: 1;

  > ${ButtonUnstyled} {
    position: relative;

    /* We removed the offset from the dot to apply it on the button */
    transform: translate(-50%, -50%);

    /* Force the Button to be above the popover */
    z-index: ${({ theme }) => theme.zIndex[8]};

    ${AnnotationDot} {
      transform: none;
    }
  }
`
