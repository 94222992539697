import styled from 'styled-components'

import { ErrorMessage } from '@sketch/components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1 0 auto;
`

export const StyledErrorMessage = styled(ErrorMessage)`
  height: auto;
  max-width: 542px;
  flex: 1 0 auto;
  align-self: center;
  padding: 16px;
`
