import {
  GetWorkspacesDocument,
  useAcceptShareMembershipInvitationMutation,
  AcceptShareMembershipInvitationMutation,
  AcceptShareMembershipInvitationMutationVariables,
  MutationOptionsWithRedirectedErrors,
  NonChangedMutationHookOptions,
} from '@sketch/gql-types'

interface Options
  extends OmitSafe<
      NonChangedMutationHookOptions<
        AcceptShareMembershipInvitationMutation,
        AcceptShareMembershipInvitationMutationVariables
      >,
      'ignoreResults'
    >,
    Pick<
      MutationOptionsWithRedirectedErrors<
        AcceptShareMembershipInvitationMutation,
        AcceptShareMembershipInvitationMutationVariables
      >,
      'onError' | 'refetchQueries' | 'onCompleted' | 'update'
    > {}

const useAcceptShareMembershipInvitation = (params: Options) => {
  const { refetchQueries, ...rest } = params

  return useAcceptShareMembershipInvitationMutation({
    ...rest,
    awaitRefetchQueries: true,
    refetchQueries: ({ data }) => {
      const { refetchQueries } = params || {}

      const queries =
        (typeof refetchQueries === 'function'
          ? refetchQueries({ data })
          : refetchQueries) || []

      queries.push({ query: GetWorkspacesDocument })

      return queries
    },
  })
}

export default useAcceptShareMembershipInvitation
