import React, { useCallback, useMemo } from 'react'
import { ExportPrefixSuffixSettings, ExportSettingsItem } from '../types'
import { ExportFormat } from '@sketch-hq/sketch-web-renderer'
import { Dropdown } from '@sketch/components'

import { PrefixSuffixInput } from './PrefixSuffixInput'

import * as S from './ExportItem.styles'

interface ExportItemProps {
  updateItem: (id: string, update: Partial<ExportSettingsItem>) => void
  removeItem: (id: string) => void
  exportSettingsItem: ExportSettingsItem
  /**
   * Controls if the delete button (cross icon) should be disabled or not.
   * (we don't want to allow to remove the last export setting)
   */
  allowRemove: boolean
}

/**
 * A export setting row allowing to define the format, backing scale and
 * the prefix/suffix.
 */
export function ExportItem({
  updateItem,
  removeItem,
  exportSettingsItem,
  allowRemove,
}: ExportItemProps) {
  const {
    updateFormat,
    updateBackingScale,
    updatePrefixSuffixSettings,
  } = useSettingItemsUpdateFunctions(exportSettingsItem, updateItem)

  return (
    <S.ExportItem key={exportSettingsItem.id}>
      <Dropdown
        usePortal={true}
        minWidth={'auto'}
        placement="bottom-start"
        toggle={
          <FakeInputButton rightContent={<S.ChevronsIcon />}>
            {exportSettingsItem.backingScale}x
          </FakeInputButton>
        }
      >
        <ScaleDropdownItem
          scale={0.5}
          exportSettingsItem={exportSettingsItem}
          updateScale={updateBackingScale}
        />
        <ScaleDropdownItem
          scale={1}
          exportSettingsItem={exportSettingsItem}
          updateScale={updateBackingScale}
        />
        <ScaleDropdownItem
          scale={1.5}
          exportSettingsItem={exportSettingsItem}
          updateScale={updateBackingScale}
        />
        <ScaleDropdownItem
          scale={2}
          exportSettingsItem={exportSettingsItem}
          updateScale={updateBackingScale}
        />
        <ScaleDropdownItem
          scale={3}
          exportSettingsItem={exportSettingsItem}
          updateScale={updateBackingScale}
        />
        <ScaleDropdownItem
          scale={4}
          exportSettingsItem={exportSettingsItem}
          updateScale={updateBackingScale}
        />
      </Dropdown>
      <PrefixSuffixInput
        exportSettingsItems={exportSettingsItem}
        updatePrefixSuffixSettings={updatePrefixSuffixSettings}
      />
      <Dropdown
        usePortal={true}
        placement="bottom-start"
        minWidth={'auto'}
        toggle={
          <FakeInputButton rightContent={<S.ChevronsIcon />}>
            {exportSettingsItem.format.toUpperCase()}
          </FakeInputButton>
        }
      >
        <FormatDropdownItem
          format="png"
          exportSettingsItem={exportSettingsItem}
          updateFormat={updateFormat}
        />
        <FormatDropdownItem
          format="jpg"
          exportSettingsItem={exportSettingsItem}
          updateFormat={updateFormat}
        />
        <FormatDropdownItem
          format="webp"
          exportSettingsItem={exportSettingsItem}
          updateFormat={updateFormat}
        />
        <Dropdown.Divider />
        <FormatDropdownItem
          format="svg"
          exportSettingsItem={exportSettingsItem}
          updateFormat={updateFormat}
        />
        <FormatDropdownItem
          format="pdf"
          exportSettingsItem={exportSettingsItem}
          updateFormat={updateFormat}
        />
      </Dropdown>
      <S.DeleteButton
        variant="ghost"
        disabled={!allowRemove}
        size="32"
        onClick={() => removeItem(exportSettingsItem.id)}
      >
        <S.StyledCrossIcon />
      </S.DeleteButton>
    </S.ExportItem>
  )
}

type FormatDropdownItemProps = {
  format: ExportFormat
  exportSettingsItem: ExportSettingsItem
  updateFormat: (format: ExportFormat) => void
}

/**
 * Individual dropdown item for selecting the export format.
 */
function FormatDropdownItem({
  format,
  exportSettingsItem,
  updateFormat,
}: FormatDropdownItemProps) {
  return (
    <Dropdown.Item
      className={exportSettingsItem.format === format ? 'active' : undefined}
      minWidth={'auto'}
      onClick={() => updateFormat(format)}
    >
      {format.toUpperCase()}
    </Dropdown.Item>
  )
}

type ScaleDropdownItemProps = {
  scale: number
  exportSettingsItem: ExportSettingsItem
  updateScale: (scale: number) => void
}

/**
 * Individual dropdown item for selecting the scale.
 */
function ScaleDropdownItem({
  scale,
  exportSettingsItem,
  updateScale,
}: ScaleDropdownItemProps) {
  return (
    <Dropdown.Item
      className={
        exportSettingsItem.backingScale === scale ? 'active' : undefined
      }
      minWidth={'auto'}
      onClick={() => updateScale(scale)}
    >
      {`${scale}x`}
    </Dropdown.Item>
  )
}

type FakeInputButtonProps = React.PropsWithChildren<{
  rightContent?: React.ReactNode
}>

/**
 * Button that is styled as an input, this is used as the trigger
 * of the format and scale dropdowns.
 */
function FakeInputButton({ children, rightContent }: FakeInputButtonProps) {
  return (
    <S.FakeInputButton>
      <S.FakeInputContent>{children}</S.FakeInputContent>
      {rightContent}
    </S.FakeInputButton>
  )
}

/**
 * Get functions to update the export settings of a single exportSettingItem.
 */
function useSettingItemsUpdateFunctions(
  exportSettingsItem: ExportSettingsItem,
  updateItem: (id: string, update: Partial<ExportSettingsItem>) => void
) {
  const updateFormat = useCallback(
    (format: ExportFormat) => {
      if (format !== exportSettingsItem.format) {
        updateItem(exportSettingsItem.id, { format })
      }
    },
    [exportSettingsItem.format, exportSettingsItem.id, updateItem]
  )

  const updatePrefixSuffixSettings = useCallback(
    (prefixSuffix: ExportPrefixSuffixSettings) => {
      const hasDifferentContent =
        prefixSuffix.content !== exportSettingsItem.prefixSuffix.content
      const hasDifferentType =
        prefixSuffix.type !== exportSettingsItem.prefixSuffix.type
      if (hasDifferentContent || hasDifferentType) {
        updateItem(exportSettingsItem.id, { prefixSuffix })
      }
    },
    [
      exportSettingsItem.id,
      exportSettingsItem.prefixSuffix.content,
      exportSettingsItem.prefixSuffix.type,
      updateItem,
    ]
  )

  const updateBackingScale = useCallback(
    (backingScale: number) => {
      if (backingScale !== exportSettingsItem.backingScale) {
        const shouldUpdatePrefixSuffix =
          !exportSettingsItem.prefixSuffix.content ||
          exportSettingsItem.prefixSuffix.content ===
            `@${exportSettingsItem.backingScale}x`

        if (shouldUpdatePrefixSuffix) {
          // In some condition we want the prefix/suffix to be updated
          // to reflect the scale.
          const newPrefixSuffix = {
            ...exportSettingsItem.prefixSuffix,
            content: `@${backingScale}x`,
          }
          updateItem(exportSettingsItem.id, {
            backingScale,
            prefixSuffix: newPrefixSuffix,
          })
        } else {
          updateItem(exportSettingsItem.id, { backingScale })
        }
      }
    },
    [
      exportSettingsItem.backingScale,
      exportSettingsItem.id,
      exportSettingsItem.prefixSuffix,
      updateItem,
    ]
  )

  return useMemo(
    () => ({
      updateFormat,
      updatePrefixSuffixSettings,
      updateBackingScale,
    }),
    [updateBackingScale, updateFormat, updatePrefixSuffixSettings]
  )
}
