import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "globe-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M12 3.75c1.557 0 2.995.397 4.22 1.103a7.96 7.96 0 012.927 2.928c.706 1.224 1.103 2.662 1.103 4.219 0 1.557-.397 2.995-1.103 4.22a7.96 7.96 0 01-2.928 2.927c-1.224.706-2.662 1.103-4.219 1.103-1.557 0-2.995-.397-4.22-1.103a7.96 7.96 0 01-2.927-2.928C4.147 14.995 3.75 13.557 3.75 12c0-1.557.397-2.995 1.103-4.22a7.96 7.96 0 012.928-2.927C9.005 4.147 10.443 3.75 12 3.75z", stroke: "currentColor", strokeWidth: 1.5 }),
        React.createElement("path", { d: "M12 3.75c.385 0 .727.167 1.033.443.41.37.754.922 1.044 1.599.671 1.567 1.03 3.776 1.03 6.208 0 2.432-.359 4.64-1.03 6.208-.29.677-.634 1.229-1.044 1.599-.306.276-.648.443-1.033.443-.385 0-.727-.167-1.033-.443-.41-.37-.754-.922-1.044-1.599-.671-1.567-1.03-3.776-1.03-6.208 0-2.432.359-4.64 1.03-6.208.29-.677.634-1.229 1.044-1.599.306-.276.648-.443 1.033-.443z", stroke: "currentColor", strokeWidth: 1.5 }),
        React.createElement("path", { fill: "currentColor", fillRule: "nonzero", d: "M20.25 8.625V9.75H4.125V8.625zm0 5.625v1.125H4.125V14.25z" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
