import remark from 'remark'
import remarkDisabledBlock from 'remark-disable-tokenizers'

import { color, emoji, link, newline, mention } from '@sketch/utils'
import { createMarkdownComponent } from '../Components'

const parser = remark()
  .use(emoji)
  .use(mention)
  .use(newline)
  .use(link)
  .use(color)
  .use(remarkDisabledBlock, {
    block: [
      'atxHeading',
      'definition',
      'footnote',
      'html',
      'setextHeading',
      'table',
    ],
    inline: ['html', 'reference', 'link'],
  })

export const defaultMarkdownParser = content =>
  parser.parse(content).children.map(createMarkdownComponent)
