import styled, { css } from 'styled-components'
import { Wrapper as AnnotationListHeader } from '../AnnotationListHeader/AnnotationListHeader.styles'

export interface WrapperProps {
  $blockScroll?: boolean
  $isTitleSticky?: boolean
}

const AnnotationListWrapper = styled.div<WrapperProps>`
  /* Make the wrapper fill the parent */
  display: flex;
  flex-direction: column;
  flex: 1;

  /* This is for the empty state to fill the height of the div */
  height: 100%;

  /* This will allow the scroll header from not disappearing */
  overflow: auto;
  overflow-x: hidden;

  padding: 8px;
  padding-top: 0px;

  ${css`
    ${AnnotationListHeader}:nth-of-type(2) {
      padding-top: 24px;
    }
  `}

  ${({ $isTitleSticky, theme }) =>
    $isTitleSticky &&
    css`
      ${AnnotationListHeader}:nth-of-type(2) {
        top: -15px; /* stylelint-disable-line scales/space */
      }
      ${AnnotationListHeader}:nth-of-type(1) {
        top: 0px;
        border-bottom: 1px solid ${theme.colors.border.A};
      }
    `}

  /* This logic will allows the placeholder/error list to be blocked */
  ${({ $blockScroll }) =>
    $blockScroll &&
    css`
      overflow: hidden;
      max-height: 100%;
    `}
`

export default AnnotationListWrapper
