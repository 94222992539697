import React, { useCallback } from 'react'
import styled from 'styled-components'

import { routes, mapSsoStartUrl, SsoSignInSource } from '@sketch/modules-common'

import { Link } from '@sketch/components'

import { ErrorHandler } from '@sketch/tracing'

import {
  useGetSsoStartUrlLazyQuery,
  GetSsoStartUrlQuery,
} from '@sketch/gql-types'

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.state.negative.A};
`
export interface GetSsoStartUrlResponse {
  url?: string
  errors: React.ReactNode[]
}

const mapResponse = ({
  source,
  from,
  data,
}: {
  source: SsoSignInSource
  from?: string
  data?: GetSsoStartUrlQuery
}): GetSsoStartUrlResponse => ({
  url: data?.ssoStartUrl.successful
    ? mapSsoStartUrl(data.ssoStartUrl.url!, { source, from })
    : undefined,
  errors:
    data?.ssoStartUrl.errors
      .filter(e => !!e)
      .map(e => {
        if (e!.code === 'NOT_FOUND') {
          return (
            <>
              The shortname is incorrect or the Workspace isn’t configured for
              SSO. Check the shortname,{' '}
              <StyledLink to={routes.SIGN_IN.create({})} isUnderlined>
                sign in with your email and password.
              </StyledLink>
            </>
          )
        } else {
          return e!.message
        }
      }) ?? [],
})

export const useGetSsoStartUrl = ({
  source,
  from,
  onNetworkError,
  onCompleted,
}: {
  source: SsoSignInSource
  from?: string
  onNetworkError: (message: string) => void
  onCompleted?: () => void
}) => {
  const [getSsoStartUrl, { data, loading }] = useGetSsoStartUrlLazyQuery({
    fetchPolicy: 'network-only',
    onError: () => {
      ErrorHandler.apollo('GetSsoStartUrl', {})
      onNetworkError('Something went wrong')
    },
    onCompleted: () => {
      onCompleted?.()
    },
  })

  const getUrl = useCallback(
    ({ teamName }: { teamName: string }) => {
      getSsoStartUrl({ variables: { teamName: teamName.trim() } })
    },
    [getSsoStartUrl]
  )

  const response = mapResponse({ source, from, data })

  return { getUrl, response, isLoading: loading }
}
