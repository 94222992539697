import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "envelope-checkmark-48", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 48 48", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M7 17l17 11 17-11.5V32a5 5 0 01-5 5H12a5 5 0 01-5-5V17z", fill: "#BBD975" }),
        React.createElement("path", { d: "M13.537 11H34.48c2.197 0 3.104.216 3.978.683.8.429 1.43 1.057 1.858 1.858.467.874.683 1.781.683 3.978h0v12.79c0 2.39-.214 3.272-.683 4.15a4.466 4.466 0 01-1.858 1.858c-.878.47-1.76.683-4.15.683h0-20.617c-2.392 0-3.273-.214-4.15-.683a4.466 4.466 0 01-1.859-1.858c-.47-.878-.683-1.76-.683-4.15h0V17.536c0-2.217.215-3.121.683-3.996a4.466 4.466 0 011.858-1.858c.875-.468 1.779-.683 3.996-.683h0z", stroke: "currentColor", strokeWidth: 2 }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 2, strokeLinejoin: "round", d: "M7.236 16.824L24 28l16.976-11.317" }),
        React.createElement("g", { transform: "translate(31 5)", stroke: "currentColor", strokeWidth: 2 },
            React.createElement("circle", { fill: "#BBD975", cx: 8, cy: 8, r: 8 }),
            React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M5.5 8.5l1.5 2 3.5-5" }))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
