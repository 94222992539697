import React from 'react'
import { Tooltip, TooltipShortcut } from '@sketch/components'
import {
  useRestartPrototype,
  useGoBack,
  useGoForwards,
  usePrototypeState,
} from '@sketch-hq/sketch-web-renderer'
import {
  Container,
  Divider,
  Button,
  RestartIcon,
  GoForwardsButton,
  GoBackwardsButton,
} from './PrototypeControls.styles'
import { usePrototypeContext } from '../../hooks'

type PrototypeControlsProps = {
  className?: string
}

export function PrototypeControls({ className }: PrototypeControlsProps) {
  const restartPrototype = useRestartPrototype()
  const goBack = useGoBack()
  const goFowards = useGoForwards()
  const { currentArtboardId, canGoBack, canGoForwards } = usePrototypeState()
  const { prototypeArtboardUUID } = usePrototypeContext()

  const canRestart = prototypeArtboardUUID !== currentArtboardId || canGoBack

  return (
    <Container className={className}>
      <Tooltip
        placement="bottom"
        disableWhenTouchDevice
        content={
          <>
            Restart Prototype
            <TooltipShortcut>Press R</TooltipShortcut>
          </>
        }
        disabled={!canRestart}
      >
        <Button
          type="button"
          aria-label="Restart prototype"
          disabled={!canRestart}
          onClick={() => restartPrototype(prototypeArtboardUUID)}
        >
          <RestartIcon />
        </Button>
      </Tooltip>
      <Divider />
      <Tooltip
        placement="bottom"
        disableWhenTouchDevice
        content={
          <>
            Go Back
            <TooltipShortcut>Press left arrow</TooltipShortcut>
          </>
        }
        disabled={!canGoBack}
      >
        <Button
          type="button"
          aria-label="Go Back"
          disabled={!canGoBack}
          onClick={goBack}
        >
          <GoBackwardsButton />
        </Button>
      </Tooltip>
      <Tooltip
        disableWhenTouchDevice
        placement="bottom"
        content={
          <>
            Go Forward
            <TooltipShortcut>Press right arrow</TooltipShortcut>
          </>
        }
        disabled={!canGoForwards}
      >
        <Button
          type="button"
          aria-label="Go Forward"
          disabled={!canGoForwards}
          onClick={goFowards}
        >
          <GoForwardsButton />
        </Button>
      </Tooltip>
    </Container>
  )
}
