import styled from 'styled-components'

import {
  Table as DefaultTable,
  TableComponents,
  Text,
  Pill,
} from '@sketch/components'

export const CurrentPill = styled(Pill).attrs({
  variant: 'finance',
})`
  margin-left: 4px;
`

export const Table = styled(DefaultTable)`
  width: 100%;
`

export const Row = TableComponents.TableRow

export const Cell = TableComponents.TableCell

export const ActiveCircle = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ theme: { colors } }) => colors.state.positive.A};
`

export const DeviceInfoWrapper = styled.div`
  display: flex;
`

export const DeviceInfoStatus = styled.div`
  padding: 6px; /* stylelint-disable-line scales/space */
`

export const DeviceInfoText = styled.div`
  flex: 1;
`

export const DeviceName = styled(Text.Div).attrs({
  textStyle: 'copy.primary.standard.E',
})`
  display: flex;
  align-items: center;
`

export const DeviceLastSeen = styled(Text.Div).attrs({
  textStyle: 'copy.quaternary.standard.D',
})``
