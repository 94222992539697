import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import Helmet from 'react-helmet'

import {
  DynamicLoadingPage,
  NotFoundView,
  RouteProps,
  routes,
  useUserProfile,
} from '@sketch/modules-common'

import WorkspaceProfileDocument from '../containers/WorkspaceProfileDocument/WorkspaceProfileDocument'

import { useGetPublicationQuery } from '@sketch/gql-types'

interface PublicationViewProps
  extends RouteProps<'WORKSPACE_PROFILE_DOCUMENT'> {}

const PublicationView = (props: PublicationViewProps) => {
  const { shortUrlName, publicationId } = props.match.params
  const { replace } = useHistory()

  const { data: userData } = useUserProfile()
  const { data, loading, error } = useGetPublicationQuery({
    variables: { identifier: publicationId },
  })

  const user = userData?.me
  const publication = data?.publication
  const workspaceName = publication?.share?.workspace?.name
  const documentName = publication?.share?.name
  const documentThumbnail =
    publication?.share?.version?.document?.previewFile?.thumbnails?.[0]?.url ??
    undefined
  const profileShortUrlName =
    publication?.share?.workspace?.profile?.shortUrlName

  // Fix the shortUrlName if it doesn't match with the Publication shortUrlName
  // This happens if the admin changed the shortUrlName and users are visiting "old" links
  useEffect(() => {
    if (profileShortUrlName && shortUrlName !== profileShortUrlName) {
      replace({
        pathname: routes.WORKSPACE_PROFILE_DOCUMENT.create({
          shortUrlName: profileShortUrlName,
          publicationId,
        }),
      })
    }
  }, [profileShortUrlName, publicationId, replace, shortUrlName])

  if (loading) {
    return <DynamicLoadingPage />
  }

  if (error || !publication) {
    return <NotFoundView />
  }

  return (
    <>
      <Helmet>
        <title>{`${documentName} on the Sketch Community Canvas`}</title>
        <meta
          property="og:title"
          content={`${documentName} on the Sketch Community Canvas`}
        />
        <meta
          property="og:description"
          content={`Download and remix ${workspaceName}'s ${documentName} — a freely-available resource, made with love in Sketch. `}
        />
        <meta property="og:image" content={documentThumbnail} />
      </Helmet>

      <WorkspaceProfileDocument
        user={user}
        publication={publication}
        shortUrlName={shortUrlName}
      />
    </>
  )
}

export default PublicationView
