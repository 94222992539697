import styled, { css } from 'styled-components'

import {
  StatelessDropdown,
  dropdownItemStyles,
  truncateStyles,
} from '@sketch/components'

export const DropdownWrapper = styled(StatelessDropdown)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: absolute;
`

export const ContextMenuNav = styled.nav`
  /* max height = 100% viewport height - header height - extra margin */
  max-height: ${({ theme }) =>
    `calc(100vh - ${theme.header.height}px - 70px);`};
  overflow: auto;
  background: ${({ theme }) => theme.colors.background.secondary.A};
  margin: 0 8px;

  ol {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
    padding: 0;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.875rem;
    width: 100%;
    ${truncateStyles};
  }
`

interface ContextMenuLabelProps {
  level: number
  isSelected: boolean
}

export const ContextMenuLabel = styled.div.attrs(
  (props: ContextMenuLabelProps) => ({
    'aria-selected': props.isSelected,
  })
)<ContextMenuLabelProps>(
  ({ theme, level, isSelected }) => css`
    ${dropdownItemStyles};

    display: flex;
    align-items: center;

    height: 32px;
    width: 100%;

    padding-left: ${theme.space[3] + level * theme.space[3]}px;

    /* &&& is needed to increase specificity and avoid showing the hover state
    for a selected item */
    &&& {
      background-color: ${isSelected ? theme.colors.sketch.F : undefined};
      color: ${isSelected ? theme.colors.sketch.A : undefined};

      [data-exportable-icon] {
        /* This background can't be with alpha because the purpose is to
        generate a border around the knife icon and hide the icon that is
        behind, so in this case #f8eee5 is emulating the background of a
        selected element (theme.colors.background.tertiary.C) */
        background: ${isSelected ? '#f8eee5' : undefined};
      }
    }
  `
)
