import React from 'react'

import { useModalContext } from '@sketch/components'
import { ExportColorTokensModal } from './ExportColorTokensModal'
import {
  ExportButton,
  ButtonText,
  ExplanationText,
  ExportContainer,
  ExportContainerSeparator,
} from './ExportColorTokens.styles'
import { useGetComponentsCountQuery } from '@sketch/gql-types'
import { useGetIdentifiers } from '../../../hooks/useGetIdentifiers'
import { useVersioning } from '../../../../versioning'
import { useUserSignedIn, useAnalytics } from '@sketch/modules-common'

import { ReactComponent as TokenIcon } from '@sketch/icons/token-16'

const SIDEBAR_BREAKPOINT = 210

export const ExportColorTokens = () => {
  const { shareIdentifier, versionShortId } = useGetIdentifiers()
  const { showModal } = useModalContext()
  const isUserSignedIn = useUserSignedIn()
  const { share } = useVersioning()
  const { trackEvent } = useAnalytics()

  const { loading, data, error } = useGetComponentsCountQuery({
    variables: {
      shareIdentifier,
      versionShortId,
    },
    skip: !shareIdentifier || !versionShortId || !isUserSignedIn,
  })

  const colorVarsCount = data?.share?.version?.document?.componentCount
    .colorVar!

  if (loading || error || !colorVarsCount || !isUserSignedIn) {
    return null
  }

  const sidebarWidth = document.getElementsByClassName('cwvSidebar')[0]
    ?.scrollWidth
  const isNarrowSidebar = sidebarWidth < SIDEBAR_BREAKPOINT
  const buttonCopy = !isNarrowSidebar ? (
    <>Export Color Tokens&hellip;</>
  ) : (
    <>Color Tokens</>
  )

  const handleExportClick = () => {
    trackEvent('CWV - export color tokens button clicked', {})

    showModal(ExportColorTokensModal, {
      shareIdentifier,
      userAccessLevel: share.userAccessLevel,
      versionShortId,
    })
  }

  return (
    <ExportContainer>
      <ExportContainerSeparator />
      <ExplanationText>
        Export your Color Variables as JSON and CSS, or create a public link
      </ExplanationText>
      <ExportButton
        $narrowSidebar={isNarrowSidebar}
        size="32"
        onClick={handleExportClick}
      >
        <TokenIcon height={16} />
        <ButtonText>{buttonCopy}</ButtonText>
      </ExportButton>
    </ExportContainer>
  )
}
