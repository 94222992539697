import React from 'react'
import { SvgComponent } from '@sketch/icons'
import { Button, Link, LinkProps } from '@sketch/components'

import * as S from './NoticeContentTemplateWithOptionalActionAndLearnMore.styles'

export type NoticeContentTemplateWithOptionalActionAndLearnMoreProps = {
  title: string
  icon: SvgComponent
  children: React.ReactNode
  action?: {
    label: string
    callback?: () => {}
    link?: LinkProps
  }
  learnMoreLink?: LinkProps
}

/**
 * Template for notices where the notice has an action and a optional
 * learn more link.
 */
export function NoticeContentTemplateWithOptionalActionAndLearnMore({
  children,
  title,
  icon,
  action,
  learnMoreLink,
}: NoticeContentTemplateWithOptionalActionAndLearnMoreProps) {
  const hasAnyAction = action || learnMoreLink
  return (
    <>
      <S.ContentLayout>
        <S.TitleWrapper>
          <S.NoticeIcon as={icon} />
          {title}
        </S.TitleWrapper>
        <S.ChildrenContainer>
          {children}
          {hasAnyAction && (
            <S.ActionContainer>
              {action && (
                <ButtonOrLinkButton action={action}>
                  {action?.label}
                </ButtonOrLinkButton>
              )}
              {learnMoreLink && (
                <S.LearnMoreLink
                  variant="tertiary"
                  isUnderlined={true}
                  {...learnMoreLink}
                >
                  Learn More
                </S.LearnMoreLink>
              )}
            </S.ActionContainer>
          )}
        </S.ChildrenContainer>
      </S.ContentLayout>
    </>
  )
}

export type ButtonOrLinkButtonProps = React.PropsWithChildren<
  Pick<NoticeContentTemplateWithOptionalActionAndLearnMoreProps, 'action'>
>
function ButtonOrLinkButton({ children, action }: ButtonOrLinkButtonProps) {
  const button = (
    <Button variant="primary" size="24" onClick={action?.callback}>
      {children}
    </Button>
  )

  if (action?.link) {
    return <Link {...action.link}>{button}</Link>
  }

  return button
}
