/**
 * Controls in an organize way what overlays should be rendered on top.
 */
export const OVERLAYS_Z_INDEX = {
  SELECTION_RECTANGLE: 1,
  SELECTED_SYMBOL_RECTANGLE: 2,
  /**
   * The SELECTION_RECTANGLE is rendered below the SELECTED_SYMBOL_RECTANGLE but
   * the white & red corners from the selection should be displayed on top of everthing.
   */
  SELECTED_ELEMENT_RECTANGLE: 3,
  MEASUREMENT_LINE: 4,
}
