import { useMemo } from 'react'
import { usePersistedURLQueryParam } from '@sketch/utils'

export type SortBy = 'name' | 'created-at' | 'last-modified'
export type SortOrder = 'asc' | 'desc'
type SetSortBy = (sortBy: SortBy) => void
type SetSortOrder = (order: SortOrder) => void

export type ShareSortOrder =
  | 'CREATED_AT_ASC'
  | 'CREATED_AT_DESC'
  | 'LAST_MODIFIED_ASC'
  | 'LAST_MODIFIED_DESC'
  | 'NAME_ASC'
  | 'NAME_DESC'

interface useSearchReturn {
  sort: ShareSortOrder
  sortBy: SortBy
  sortOrder: SortOrder
  setSortBy: SetSortBy
  setSortOrder: SetSortOrder
  sortLabel: string
}

const MAP_SORT_ORDER: Record<SortBy, Record<SortOrder, ShareSortOrder>> = {
  'created-at': {
    asc: 'CREATED_AT_ASC',
    desc: 'CREATED_AT_DESC',
  },
  'last-modified': {
    asc: 'LAST_MODIFIED_ASC',
    desc: 'LAST_MODIFIED_DESC',
  },
  name: {
    asc: 'NAME_ASC',
    desc: 'NAME_DESC',
  },
}

const MAP_SORTING_LABEL: Record<SortBy, Record<SortOrder, string>> = {
  'created-at': {
    asc: 'Date created / Older first',
    desc: 'Date created / Newer first',
  },
  'last-modified': {
    asc: 'Last updated / Older first',
    desc: 'Last updated / Newer first',
  },
  name: {
    asc: 'Name / A-Z',
    desc: 'Name / Z-A',
  },
}

/**
 * Custom hook to get values from URL related to sorting search results.
 */
export const useSortingSettings = (): useSearchReturn => {
  const [sortByRaw, setSortBy] = usePersistedURLQueryParam('sort-by', {})
  const [sortOrderRaw, setSortOrder] = usePersistedURLQueryParam(
    'sort-order',
    {}
  )

  // The default search value is LAST_MODIFIED_DESC
  const sortBy = (sortByRaw as SortBy) || 'last-modified'
  const sortOrder = (sortOrderRaw as SortOrder) || 'desc'

  const memoizedValue = useMemo(
    () => ({
      sort: MAP_SORT_ORDER[sortBy][sortOrder],
      sortBy,
      sortOrder,
      setSortBy,
      setSortOrder,
      sortLabel: MAP_SORTING_LABEL[sortBy][sortOrder],
    }),
    [sortBy, sortOrder, setSortBy, setSortOrder]
  )

  return memoizedValue
}
