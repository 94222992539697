import styled from 'styled-components'

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.G};
  margin: 32px 0 8px 0;
`

export const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.E};
  margin-bottom: 16px;
`
