import React from 'react'
import { Filter } from '@sketch/components'
import { useNotificationSearchContext } from '../../../../context/NotificationSearchContext'
import { FilterWrapper } from './Searchbar.styles'

export const Searchbar: React.FC<{
  disabled?: boolean
}> = ({ disabled }) => {
  const { search, setSearch } = useNotificationSearchContext()
  return (
    <>
      <FilterWrapper>
        <Filter
          disabled={disabled}
          onChange={setSearch}
          onSubmit={() => setSearch(search)}
          onClear={() => setSearch('')}
          value={search}
          placeholder="Search"
          fullWidth
        />
      </FilterWrapper>
    </>
  )
}
