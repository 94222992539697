import styled from 'styled-components'

export const CoreLayoutWrapper = styled.div`
  /* Makes content full height in Safari (was 100% before) */
  min-height: 100vh;

  /* Workaround to make 100vh work on safari, see polyfills.js */
  min-height: calc(var(--vh, 1vh) * 100);

  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`
