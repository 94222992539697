import React, { FC, useState } from 'react'
import { WebRendererStatus } from '@sketch-hq/sketch-web-renderer'
import { PageCanvasError } from '../PageCanvasError'
import { getErrorDescription } from '../../utils'
import {
  Skeleton,
  Box,
  Flex,
  LoadingWrapper,
  useBreakpoint,
} from '@sketch/components'
import { isArtboardRoute } from '@sketch/modules-common'
import { useRouteMatch } from 'react-router'

type PageCanvasStatusProps = {
  status: WebRendererStatus | null
}

const LoadingPlaceholder = () => (
  <Flex flexDirection="column" mr={5}>
    <Box mb={2}>
      <Skeleton width="124px" height="16px" />
    </Box>
    <Skeleton width="305px" height="191px" />
  </Flex>
)

export const PageCanvasStatus: FC<PageCanvasStatusProps> = props => {
  const { status } = props
  const { path } = useRouteMatch()
  const isMobile = !useBreakpoint('md')
  // Generate a random number between 1 and 3, but only once when the component is first rendered
  // When we are in Artboard view, we only want to render one placeholder, same for mobile
  const randomNumberOfPlaceholders =
    isArtboardRoute(path) || isMobile ? 1 : Math.floor(Math.random() * 3) + 1
  const [numPlaceholders] = useState(randomNumberOfPlaceholders)

  if (
    !status ||
    status.type === 'IDLE' ||
    status.type === 'INITIALIZING' ||
    status.type === 'LOADING_FILE' ||
    status.type === 'FILE_READY' ||
    status.type === 'DRAWING_FILE'
  ) {
    return (
      <LoadingWrapper data-testid="page-canvas-placeholder">
        <Flex>
          {Array.from({ length: numPlaceholders }, (_, i) => (
            <LoadingPlaceholder key={i} />
          ))}
        </Flex>
      </LoadingWrapper>
    )
  }

  if (status.type === 'FAILURE' || status.type === 'WEBGL_CONTEXT_LOST') {
    return (
      <PageCanvasError
        showReloadCanvasAction
        description={getErrorDescription(status)}
      />
    )
  }

  return null
}
