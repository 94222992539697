import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import ReactDOM from 'react-dom'
import { Observable, useObservable } from '../observable'

type PortalsContext = Record<
  string,
  (props: PropsWithChildren<{}>) => JSX.Element
>

const defaultContextState = {}
const defaultContextSetState = () => {}

const contextDefaultState = [defaultContextState, defaultContextSetState] as [
  PortalsContext,
  Dispatch<SetStateAction<PortalsContext>>
]

const LayoutPortalsContext = React.createContext<
  [PortalsContext, Dispatch<SetStateAction<PortalsContext>>]
>(contextDefaultState)

export const LayoutPortalsProvider: React.FC = ({ children }) => {
  const value = React.useState<PortalsContext>(defaultContextState)

  return (
    <LayoutPortalsContext.Provider value={value}>
      {children}
    </LayoutPortalsContext.Provider>
  )
}

export interface LayoutPortalRawProps extends PropsWithChildren<{}> {
  container: Observable<HTMLDivElement | null>
}

export function LayoutPortalRaw({ children, container }: LayoutPortalRawProps) {
  const ref = useObservable(container)
  return ref ? ReactDOM.createPortal(children, ref) : null
}

/**
 * Creates a layout portal, it also stores the portal in a context.
 */
export const useCreateLayoutPortal = () => {
  // We are using useRef as a replacement of useMemo for a single reason
  // using useRef doesn't require to pass this variable to dependencies array of other hooks
  const portalContainerRef = useRef(new Observable<HTMLDivElement | null>(null))

  const [, setPortalsContext] = React.useContext(LayoutPortalsContext)

  useEffect(() => {
    return () => {
      // When the component unmounts we want to reset the portals context, this
      // fixes an issue where portals stored in the context wouldn't render
      // when changing routes because of changes in the DOM container
      setPortalsContext({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // To make it easier to deal with this component later on, we are stabilizing
  // the reference of this component, i.e. we are aiming to make that dependencies
  // array would be empty
  const Portal = useMemo(
    () =>
      function Portal(props: PropsWithChildren<{}>) {
        const { children } = props

        return (
          <LayoutPortalRaw container={portalContainerRef.current}>
            {children}
          </LayoutPortalRaw>
        )
      },
    []
  )

  const setPortalContainerRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node === null) return

      portalContainerRef.current.setState(node)

      setPortalsContext(portals => {
        // We don't want to store the portal in a context if it's already there
        // or if the id is missing
        if (!node.id || portals[node.id]) {
          // returning the current value should skip re-render
          return portals
        }

        return { ...portals, [node.id]: Portal }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return [Portal, setPortalContainerRef] as [
    (props: PropsWithChildren<{}>) => JSX.Element,
    (ref: HTMLDivElement | null) => void
  ]
}

/**
 * Retieves a layout portal from the context, it's an alternative way to use an
 * existing layout portal. Apart from this hook we pass down the layout props
 * from the layout to the child component, but this isn't convenient if you
 * need to use the portal in a component down in the React render tree.
 */
export const useLayoutPortal = (id: string) => {
  const contextValue = React.useContext(LayoutPortalsContext)

  if (contextValue === contextDefaultState) {
    throw new Error(
      'useLayoutPortal must be used within a LayoutPortalsProvider'
    )
  }

  const portalContext = contextValue[0]

  if (!portalContext[id]) {
    throw new Error(`There's no layout portal with the id ${id}`)
  }

  return portalContext[id]
}
