import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * This hook detects if there is a `component` property in the URL query params,
 * so we can link directly to an specific component from the inspector
 */
export function useComponentLink(loading: boolean) {
  const { search } = useLocation<{ component?: string }>()

  const urlParams = new URLSearchParams(search)
  const componentId = urlParams.get('component')

  // If there is a `component` in the URL search query params we automatically
  // click on that component to show the inspector data
  useEffect(() => {
    if (!componentId || loading) {
      return
    }

    const linkedStyle = document.querySelector<HTMLDivElement>(
      `[data-component="${componentId}"]`
    )

    if (!linkedStyle) {
      return
    }

    linkedStyle.click()

    // It needs to use the `center` option because with the default one
    // `linkedStyle` would be hidden behind the fixed subheader
    linkedStyle.scrollIntoView({ block: 'center' })
  }, [componentId, loading])
}
