import React from 'react'
import {
  AssetButtonSubText,
  AssetItemIcon,
  AssetItemText,
  AssetItemTextWrapper,
  AssetItemWrapper,
} from './AboutTabExportItem.styles'

import { TimeAgo } from '@sketch/components'

interface AboutTabExportButtonProps {
  text: string
  subtext?: string
  updatedAt?: string
  disabled?: boolean
  error?: boolean
  active?: boolean
  icon: React.ElementType
  extraWidth?: boolean
}

const AboutTabExportItem = ({
  text,
  subtext,
  disabled,
  updatedAt,
  error,
  active,
  icon,
  extraWidth,
}: AboutTabExportButtonProps) => {
  return (
    <AssetItemWrapper disabled={disabled} active={active} error={error}>
      <AssetItemIcon $extraWidth={extraWidth} role="img" as={icon} />
      <AssetItemTextWrapper>
        <AssetItemText>{text}</AssetItemText>
        {subtext &&
          (updatedAt ? (
            <>
              <AssetButtonSubText>
                {subtext} {' - '}
                <TimeAgo date={updatedAt} hideTitle={true} />
              </AssetButtonSubText>
            </>
          ) : (
            <AssetButtonSubText>{subtext}</AssetButtonSubText>
          ))}
      </AssetItemTextWrapper>
    </AssetItemWrapper>
  )
}

export default AboutTabExportItem
