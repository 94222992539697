import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { routes } from '@sketch/modules-common'
import { useWorkspaceSettings } from './useWorkspaceSettings'

/**
 * Listen for workspace settings view route changes and call the refetch function
 * when the route has changed (between ../settings/general, .../settings/billing, etc...).
 *
 * This is to make sure the settings are always up to date with the server.
 * We need this hook because the useWorkspaceSettings hook to query the settings
 * is called inside WorkspaceSettingsView which is not unmounted & remounted when switching
 * the workspace settings tabs/routes and therefore does not refetch the query automatically.
 */
export function useRefetchWorkspaceSettingsOnRouteChange(
  refetch: ReturnType<typeof useWorkspaceSettings>['refetch'],
  workspaceId: string
) {
  const location = useLocation()
  const [previousPathname, setPreviousPathname] = useState(location.pathname)

  useEffect(() => {
    if (previousPathname === location.pathname) {
      return
    }

    // Don't refetch when route is auto-redirected from `../settings` to `../settings/general`.
    if (
      previousPathname !== routes.WORKSPACE_SETTINGS.create({ workspaceId })
    ) {
      refetch()
    }

    setPreviousPathname(location.pathname)
  }, [location.pathname, previousPathname, refetch, workspaceId])
}
