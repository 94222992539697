import styled from 'styled-components'
import { truncateStyles } from '@sketch/components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSizes.D};
`

export const Variable = styled.span`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  ${truncateStyles};
`

export const ValueContainer = styled.div`
  display: flex;
`

export const Text = styled.span<{ hasColorVariable: boolean }>`
  color: ${({ theme, hasColorVariable }) =>
    hasColorVariable
      ? theme.colors.foreground.secondary.D
      : theme.colors.foreground.secondary.A};
  ${truncateStyles};
`

export const Alpha = styled.div`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  font-size: 0.8125rem;
  margin-left: 4px;
  margin-right: 8px;
`
