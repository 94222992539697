import { useCallback, useContext } from 'react'
import { ErrorHandler } from '@sketch/tracing'

import {
  Context,
  AnnotationOverlayContextAvailable,
} from '../components/AnnotationOverlayContext'

import {
  MouseCoordinates,
  Coordinates,
  PageAnnotationSubject,
  ArtboardAnnotationSubjectWithBounds,
} from '../types'

/**
 * Little util to clean the subject from the bounds type so it doesn't
 * throw any errors when formatted into the GraphQL payload on "DraftAnnotationPopover"
 */
const formatSubject = (
  subject: PageAnnotationSubject | ArtboardAnnotationSubjectWithBounds
) => {
  if (subject.type === 'ARTBOARD') {
    const { bounds, ...otherSubject } = subject
    return otherSubject
  }

  return subject
}

/**
 * usePlaceDraftAnnotation
 *
 * Allows/Deny a annotation to place a draft annotation
 * when the status of the context is "available" it returns
 * the current value/setting of conceding a draft annotation
 * to be placed
 */
export const usePlaceDraftAnnotation = () => {
  const context = useContext(Context)

  if (context.status === 'available') {
    return [
      context.placeDraftAnnotation,
      context.setPlaceDraftAnnotation,
    ] as const
  }
}

export const useAnnotationOverlayContext = () => {
  const context = useContext(Context)

  if (context.status === 'available') {
    return context
  }
}
/**
 *
 * useCreateDraftAnnotation
 *
 * Saves the position of a draft annotation on the context
 * it also saves the context of the draft annotation like the artboard or the page
 */
export const useCreateDraftAnnotation = (shareIdentifier: string) => {
  const context = useContext(Context)

  return useCallback(
    async (eventPosition: MouseCoordinates, forceDraftMode?: boolean) => {
      /**
       * "forceDraftMode"
       *
       * This value will allow the annotation to be set on the spot
       * enabling the draft mode after. This will allow to use
       * the event position and do a 2 step operation
       * - Set the draft mode on
       * - Place the annotation in the position
       */

      /**
       * Make sure the context is available before continuing
       * and that "placeDraftAnnotation" is "true"
       */
      if (context.status === 'unavailable') {
        return
      }

      let coordinates = context.getPositionRelativeToOrigin(eventPosition)

      if (!coordinates) {
        /* If there's no coordinates there's no point in continuing */
        ErrorHandler.shouldNeverHappen(
          `User is moving an annotation but the "getPositionRelativeToOrigin" is not returning a valid position`
        )
        return
      }

      const element = await context.getElementAtPosition(eventPosition)
      if (!element) {
        /* If there's no element (artboard/page) there's no point in continuing */
        ErrorHandler.shouldNeverHappen(
          `User is moving an annotation but the "getElementAtPosition" is not returning a valid element`
        )
        return
      }

      let prefixBounds: Coordinates | undefined
      if (element.type === 'ARTBOARD') {
        prefixBounds = {
          x: element.bounds.x,
          y: element.bounds.y,
        }

        coordinates = {
          x: coordinates.x - element.bounds.x,
          y: coordinates.y - element.bounds.y,
        }
      }

      forceDraftMode && context.setPlaceDraftAnnotation(true)

      context.addDraftAnnotation({
        context: {
          shareIdentifier,
          subject: formatSubject(element),
        },
        coordinates,
        prefixBounds,
      })
    },
    [shareIdentifier, context]
  )
}

/**
 * useGetDraftAnnotation
 *
 * Gets the information of a draft annotation if
 * it has been set or if the context is available
 */
export const useGetDraftAnnotation = () => {
  const context = useContext(Context)

  if (context.status === 'available') {
    return context.draftAnnotation || undefined
  }
}

export const useGetAnnotationContext = () => {
  return useContext(Context) as Partial<AnnotationOverlayContextAvailable>
}

/**
 * useGetDraftAnnotationComment
 *
 * Gets the information of a draft annotation comment if
 * it has been set or if the context is available
 */
export const useGetDraftAnnotationComment = () => {
  const context = useContext(Context)

  if (context.status === 'available') {
    return [
      context.draftAnnotationComment,
      context.setDraftAnnotationComment,
    ] as const
  }

  return []
}

/**
 * useIsAnnotationAvailable
 *
 * Returns if the context is available or not
 */
export const useIsAnnotationContextAvailable = () => {
  const context = useContext(Context)

  return context?.status === 'available'
}

export const useMovingAnnotation = () => {
  const context = useContext(Context)

  if (context.status === 'available') {
    return [context.movingAnnotation, context.setMovingAnnotation] as const
  }
}
