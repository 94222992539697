import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { useCollectionSearchArgument } from '../../../collections/hooks'

import { ModalInjectedProps } from '@sketch/components'
import { ToastContext } from '@sketch/toasts'
import ShareAccessList from '../../../shares/components/ShareAccessList'
import { RemoveShareFromProjectLayout } from './RemoveShareFromProjectLayout'

import {
  StyledDraftsWarningContainer,
  Separator,
} from './RemoveShareFromProject.styles'

import {
  invalidateCollectionsExcept,
  removeSharesFromCollection,
} from '../../../collections/operations/utils'
import { removeFromPaginated, routes } from '@sketch/modules-common'
import { ErrorHandler } from '@sketch/tracing'

// GraphQL
import {
  CollectionForSelectFragment,
  ProjectFragment,
  ShareInfoFragment,
  useRemoveShareFromProjectMutation,
} from '@sketch/gql-types'
import { updateShareWithProjectFragment } from '../../operations'

export interface RemoveShareFromProjectProps extends ModalInjectedProps {
  project: ProjectFragment
  share: Pick<
    ShareInfoFragment,
    'identifier' | 'name' | 'workspace' | 'memberships'
  >
  collection?: CollectionForSelectFragment
}

const WordBreaker = styled.span`
  overflow-wrap: break-word;
`

export const RemoveShareFromProject: React.FC<RemoveShareFromProjectProps> = props => {
  const { hideModal, project, share } = props
  const { showToast } = useContext(ToastContext)
  const search = useCollectionSearchArgument()

  const [removeFromProject, { loading }] = useRemoveShareFromProjectMutation({
    redirectErrors: true,
    variables: { shareId: share.identifier, projectId: project.identifier },
    update: (cache, data) => {
      if (!data) {
        ErrorHandler.shouldNeverHappen.invalidMutationData(
          'removeShareFromProject'
        )
        return
      }

      removeFromPaginated(
        cache,
        { identifier: share.identifier, __typename: 'Share' },
        { identifier: project.identifier, __typename: 'Project' }
      )

      // Update the Share cache
      updateShareWithProjectFragment(cache, share.identifier, share => {
        share.project = null
      })

      // Removing a share from a project removes it from any collections in the
      // project.
      // If the share belonged to a collection, we remove it from the
      // collection in the cache.
      if (props.collection) {
        removeSharesFromCollection({
          cache,
          projectIdentifier: project.identifier,
          collectionIdentifier: props.collection.identifier,
          search,
          sharesToRemove: [share],
        })
        invalidateCollectionsExcept({
          cache,
          projectIdentifier: project.identifier,
          search,
        })
      }
    },
    onCompleted: () => {
      showToast(
        <>
          Document removed from &quot;
          <Link
            to={routes.WORKSPACE_PROJECT.create({
              projectId: project.identifier,
              workspaceId: share.workspace.identifier,
            })}
          >
            {project.name}
          </Link>
          &quot;
        </>
      )
      hideModal()
    },
    onError: 'show-toast',
  })

  const isDraft = project.type === 'PERSONAL_DRAFTS'

  return (
    <RemoveShareFromProjectLayout
      title={`Remove Document from ${isDraft ? 'My Drafts' : 'Project'}?`}
      hideModal={hideModal}
      button={{ loading, onClick: () => removeFromProject() }}
    >
      {isDraft ? (
        <StyledDraftsWarningContainer>
          <p>
            <WordBreaker>
              “<strong>{share.name}</strong>”
            </WordBreaker>{' '}
            will be removed from My Drafts. You can find it in the All Documents
            view and everyone in the Workspace can access it.
          </p>
          <br />
        </StyledDraftsWarningContainer>
      ) : (
        <p>
          <WordBreaker>
            “<strong>{share.name}</strong>”
          </WordBreaker>{' '}
          will be removed from the{' '}
          <WordBreaker>
            “<strong>{project.name}</strong>”
          </WordBreaker>{' '}
          Project. You can find it in the All Documents view.
        </p>
      )}
      <Separator />
      <ShareAccessList
        shareIdentifier={share.identifier}
        membershipCount={share.memberships?.meta.totalCount}
      />
    </RemoveShareFromProjectLayout>
  )
}
