import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "workspace-48", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 48 48", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { fill: "#C18CCB", d: "M15 29l-8 5 17 10 17-10V14l-8 5v10l-9 5.5z" }),
        React.createElement("path", { stroke: "#000", strokeWidth: 2, strokeLinejoin: "round", d: "M24 4l17 9.914v20.159L24 44 7 34.073v-20.16z" }),
        React.createElement("path", { stroke: "#000", strokeWidth: 2, d: "M24 4v10m0 20.5V44M7 14l8 5m26-5l-8 5M7 34l8-5m18 0l8 5" }),
        React.createElement("path", { stroke: "#000", strokeWidth: 2, fill: "#C18CCB", strokeLinejoin: "round", d: "M24 13.5l9 5.25v10.5l-9 5.25-9-5.25v-10.5z" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
