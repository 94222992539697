import React, { FC, ReactNode } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { IsEmbedded } from './IsEmbedded'
import { NotEmbedded } from './NotEmbedded'

interface Route {
  template: () => string
}

/**
 * Determines if the supplied pathname matches one of the whitelisted
 * embeddable routes.
 */
const isPathnameEmbeddable = (
  pathname: string,
  embeddableRoutes: Route[]
): boolean =>
  Boolean(
    matchPath(pathname.replace(/^\/embed/, ''), {
      path: embeddableRoutes.map(route => route.template()),
      strict: false,
      exact: true,
    })
  )

type EmbedGateProps = {
  children: ReactNode
  embeddableRoutes: Route[]
}

/**
 * If rendering while embedded, the EmbedGate only renders its children if the
 * initial pathname is strictly one of the whitelisted embedded routes,
 * otherwise it outputs null. While rendering normally/un-embedded it's
 * a no-op and always renders children.
 */
const EmbedGate: FC<EmbedGateProps> = props => {
  const location = useLocation()
  const embeddable = isPathnameEmbeddable(
    location.pathname,
    props.embeddableRoutes
  )
  return (
    <>
      <IsEmbedded>{embeddable ? props.children : null}</IsEmbedded>
      <NotEmbedded>{props.children}</NotEmbedded>
    </>
  )
}

export { EmbedGate }
