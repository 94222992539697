import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "document-placeholder-64", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 64 64", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd", stroke: "#000", strokeWidth: 3 },
        React.createElement("path", { d: "M49.5 32.5v-3a4 4 0 00-4-4h-8a4 4 0 01-4-4v-8a4 4 0 00-4-4h-11a4 4 0 00-4 4v19" }),
        React.createElement("path", { d: "M14.5 28.5v22a4 4 0 004 4h27a4 4 0 004-4v-24h0", strokeDasharray: 4 }),
        React.createElement("path", { d: "M49.5 31.5v-2.686a8 8 0 00-2.343-5.657L35.843 11.843A8 8 0 0030.186 9.5H20.5h0" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
