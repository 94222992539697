import { getItem } from '@sketch/utils'
import { EnvironmentsConfig, NamedEnvironment } from './environments.types'

// __ENVIRONMENTS__ is a variable injected directly to the index.html file by the build process
declare const __ENVIRONMENTS__: EnvironmentsConfig | undefined

export const environments =
  typeof __ENVIRONMENTS__ !== 'undefined'
    ? __ENVIRONMENTS__
    : (() => {
        throw new Error(
          'Environments are not defined, double check the build process'
        )
      })()

export type ReactAppBuildEnv = 'production' | 'staging' | 'test' | 'dev'
export type ReactAppBeEnv = string

const getEnvironment = (env = 'production'): NamedEnvironment => {
  if (environments.main.name === env) {
    return environments.main
  }

  const pickedEnv = (environments.extra || {})[env]
  if (pickedEnv) return { name: env, values: pickedEnv }

  return environments.main
}

const getCurrentEnv = (): NamedEnvironment => {
  if (process.env.REACT_APP_ENV === 'production') {
    return getEnvironment('production')
  }

  const appEnv = getItem('app_env') as ReactAppBuildEnv
  if (appEnv) {
    /* eslint-disable no-console */
    console.warn(`Using '${appEnv}' environment from local storage.`)
    console.warn(`Use localStorage.removeItem('app_env') to reset.`)
    /* eslint-enable no-console */
    return getEnvironment(appEnv)
  }

  return getEnvironment(process.env.REACT_APP_ENV)
}

const currentEnv = getCurrentEnv()
export const CURRENT_ENV = currentEnv.name

// Utility to check if the current target (backend) environment is the production environment.
export const isProduction = () => CURRENT_ENV === 'production'

export default currentEnv.values
