import { ErrorHandler } from '@sketch/tracing'
import { DataProxy } from 'apollo-cache'

import {
  useEmptiedWorkspaceTrashSubscription,
  useShareChangedSubscription,
  SubscriptionShareFragment,
  ShareChangedSubscription,
  ShareChangedDocument,
  ShareListItemFragment,
  useShareMembershipChangedSubscription,
  ShareMembershipChangedSubscription,
} from '@sketch/gql-types'

import {
  handleShareDeleted,
  handleShareRestored,
  handleShareDeletedPermanently,
  handleEmptiedWorkspaceTrash,
  handleShareProjectChanged,
} from '../handlers'
import { readShareListItemFromCache } from '@sketch/modules-common'

interface HandlerParameters {
  cache: DataProxy
  identifier: string
  workspaceIdentifier: string
  previousShare?: ShareListItemFragment
}

const CACHE_UPDATE_BY_TYPENAME: {
  [key: string]: (props: HandlerParameters) => void
} = {
  ShareDeletedEvent: handleShareDeleted,
  ShareRestoredEvent: handleShareRestored,
  ShareDeletedPermanentlyEvent: handleShareDeletedPermanently,
  ShareProjectChangedEvent: handleShareProjectChanged,
} as const

interface ShareResourceEvent {
  share: SubscriptionShareFragment
  shareIdentifier: string
  previousShare?: ShareListItemFragment
}

interface ShareDeletedPermanentlyResourceEvent {
  workspaceIdentifier: string
  shareIdentifier: string
  previousShare?: ShareListItemFragment
}

interface EmptiedResourceEvent {
  workspaceIdentifier: string
}

interface EventListeners {
  onEmptiedTrash?: (resource: EmptiedResourceEvent) => void
  onDeleted?: (resource: ShareResourceEvent) => void
  onRestored?: (resource: ShareResourceEvent) => void
  onDeletedPermanently?: (
    resource: ShareDeletedPermanentlyResourceEvent
  ) => void
  onShareProjectChanged?: (resource: ShareResourceEvent) => void
  onShareMembershipUpdate?: (
    shareMembership: ShareMembershipChangedSubscription['shareMembershipChanged']
  ) => void
}

const useShareSubscriptions = (eventListeners?: EventListeners) => {
  const {
    onDeleted,
    onDeletedPermanently,
    onEmptiedTrash,
    onRestored,
    onShareProjectChanged,
    onShareMembershipUpdate,
  } = eventListeners || {}

  useEmptiedWorkspaceTrashSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      const payload = subscriptionData?.data?.emptiedWorkspaceTrash

      // Report if the subscription doesn't have payload
      if (!payload) {
        const jsonData = JSON.stringify(subscriptionData)
        ErrorHandler.shouldNeverHappen(
          `missing useEmptiedWorkspaceTrash payload, ${jsonData}`
        )

        return
      }

      const { workspaceIdentifier } = payload

      // Update the cache to reflect the event changes
      handleEmptiedWorkspaceTrash({
        cache: client,
        identifier: workspaceIdentifier,
      })

      // Call the correspondent event listener (if exists)
      onEmptiedTrash?.({ workspaceIdentifier })
    },
  })

  useShareChangedSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      const payload = subscriptionData?.data?.shareChanged

      // Report if the subscription doesn't have payload
      if (!payload) {
        const jsonData = JSON.stringify(subscriptionData)
        ErrorHandler.shouldNeverHappen(
          `missing useShareChanged payload, ${jsonData}`
        )

        return
      }

      const shareIdentifier = payload.shareIdentifier
      const workspaceIdentifier = payload.workspace.identifier

      // See if this share has been cached before
      const previousShare = readShareListItemFromCache({
        cache: client,
        id: shareIdentifier,
      })

      // Update the cache to reflect the event changes
      if (payload.__typename in CACHE_UPDATE_BY_TYPENAME) {
        CACHE_UPDATE_BY_TYPENAME[payload.__typename]({
          cache: client,
          identifier: shareIdentifier,
          workspaceIdentifier,
          previousShare,
        })
      }

      // Call the correspondent event listener for each __typename (if they exist)
      if (payload.__typename === 'ShareDeletedEvent') {
        onDeleted?.({
          share: payload.share,
          shareIdentifier,
          previousShare,
        })
      } else if (payload.__typename === 'ShareRestoredEvent') {
        onRestored?.({
          share: payload.share,
          shareIdentifier,
          previousShare,
        })
      } else if (payload.__typename === 'ShareDeletedPermanentlyEvent') {
        onDeletedPermanently?.({
          shareIdentifier,
          workspaceIdentifier,
          previousShare,
        })
      } else if (payload.__typename === 'ShareProjectChangedEvent') {
        onShareProjectChanged?.({
          share: payload.share,
          shareIdentifier,
          previousShare,
        })
      }

      // Write to the cache the share subscription updates
      client.writeQuery<ShareChangedSubscription>({
        query: ShareChangedDocument,
        data: subscriptionData.data!,
        variables: {},
      })
    },
    /**
     * The "no-cache" fetchPolicy was set to prevent the share update from being updated
     * this way we can read it from the cache, and then update it
     */
    fetchPolicy: 'no-cache',
  })

  useShareMembershipChangedSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      const data = subscriptionData.data?.shareMembershipChanged
      data && onShareMembershipUpdate?.(data)
    },
  })
}

export default useShareSubscriptions
