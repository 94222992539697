import React from 'react'

import { Flex, Box, SelectDropdown } from '@sketch/components'
import { GroupsManager } from '../Sidebar'
import {
  MobileContainer,
  MobileNavigationLink,
  MobileNavigationHeader,
} from './Header.styles'

import { useSelectedGroupContext } from '../../context/SelectedGroupContext'
import { isCwvSpecificRoute, isCwvRouteOnly } from '@sketch/modules-common'
import { getCwvRouteTitle, getGroupType } from '../../utils'
import { useGetComponentsGroups } from '../../operations'
import { Icon } from '../Sidebar/ComponentsList.styles'

import { ReactComponent as SymbolIcon } from '@sketch/icons/symbol-16'
import { ReactComponent as PaintBrushIcon } from '@sketch/icons/paint-brush-16'
import { ReactComponent as TextIcon } from '@sketch/icons/text-format-16'
import { ReactComponent as ColorFanIcon } from '@sketch/icons/color-fan-16'

const links = {
  CWV_SYMBOLS: 'Symbols',
  CWV_TEXT_STYLES: 'Text Styles',
  CWV_LAYER_STYLES: 'Layer Styles',
  CWV_COLOR_VARIABLES: 'Color Variables',
} as const

// This is just TS sorcery to get the union type of values from links object
type Link = typeof links[keyof typeof links]

const getRouteKey = (link: Link) => {
  switch (link) {
    case 'Symbols':
      return 'CWV_SYMBOLS'
    case 'Layer Styles':
      return 'CWV_LAYER_STYLES'
    case 'Text Styles':
      return 'CWV_TEXT_STYLES'
    default:
      return 'CWV_COLOR_VARIABLES'
  }
}

const getIcon = (link: Link) => {
  switch (link) {
    case 'Symbols':
      return <Icon role="img" as={SymbolIcon} />
    case 'Layer Styles':
      return <Icon role="img" as={PaintBrushIcon} />
    case 'Text Styles':
      return <Icon role="img" as={TextIcon} />
    default:
      return <Icon role="img" as={ColorFanIcon} />
  }
}

export const MobileHeader: React.FC<{
  shareID: string
  path: string
  versionShortId: string
}> = ({ shareID, path, versionShortId }) => {
  // we only need the group select on CWV pages
  const showGroupSelect = isCwvRouteOnly(path)
  const { resetGroupSelect, selected } = useSelectedGroupContext()
  const groupType = getGroupType(path)
  const { status, tree } = useGetComponentsGroups(groupType)

  const dropdown = (
    <SelectDropdown
      headerItem={<MobileNavigationHeader>Components</MobileNavigationHeader>}
      placeholder={links.CWV_SYMBOLS}
      items={Object.values(links)}
      value={getCwvRouteTitle(path)}
      renderItem={item => (
        <MobileNavigationLink
          selected={isCwvSpecificRoute(path, getRouteKey(item as Link))}
          onClick={() => resetGroupSelect()}
          to={{
            routeKey: getRouteKey(item as Link),
            routeParams: {
              shareID,
              versionShortId,
            },
          }}
        >
          {getIcon(item as Link)}
          {item}
        </MobileNavigationLink>
      )}
    />
  )

  return (
    <MobileContainer pt={2} pl={3} pb={2}>
      <Flex>
        <Box minWidth="60px" width={showGroupSelect ? '50%' : '100%'}>
          {dropdown}
        </Box>
        {showGroupSelect && (
          <Box minWidth="60px" width="50%">
            <GroupsManager key={selected} status={status} tree={tree} />
          </Box>
        )}
      </Flex>
    </MobileContainer>
  )
}
