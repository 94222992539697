import styled, { css } from 'styled-components'

import { ButtonUnstyled, Link } from '@sketch/components'
import { ReactComponent as ArrowRight } from '@sketch/icons/arrow-right-16'

/* Width of the card (in pixels) */
export const CARD_WIDTH = 252

export const FirstStepsCard = styled.div`
  display: flex;
  flex-direction: column;
  /* Prevent action button/link clickable area to take the whole card */
  align-items: flex-start;
  width: ${CARD_WIDTH}px;
  height: 100%;
  flex-shrink: 0;
`
export const FirstStepsCardIcon = styled.svg`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
`

export const FirstStepsCardTitle = styled.div`
  margin-top: 8px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.F};
  line-height: ${({ theme }) => theme.lineHeights.D};
`

export const FirstStepsCardDescription = styled.p`
  margin-top: ${({ theme }) => theme.space[1]}px;
  margin-bottom: ${({ theme }) => theme.space[4]}px;

  font-size: ${({ theme }) => theme.fontSizes.D};
  line-height: ${({ theme }) => theme.lineHeights.E};
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  flex-grow: 1;
  word-break: break-word;
`

export const FirstStepsCardActionName = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.E};
`

export const ActionArrow = styled(ArrowRight)`
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.background.tertiary.A};
`

const actionCss = css`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  &:hover {
    /* Overwrite default link hover color change */
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
    text-decoration: underline;
  }
`
export const StyledActionButton = styled(ButtonUnstyled)`
  ${actionCss}
`

export const StyledActionLink = styled(Link)`
  ${actionCss}
`
