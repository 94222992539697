import { useShareDeleteMutation, ShareDeleteMutation } from '@sketch/gql-types'
import { ErrorHandler } from '@sketch/tracing'
import { handleShareDeleted } from './handlers'

export type ShareDeleted = NonNullable<
  NonNullable<ShareDeleteMutation['shareDelete']>['share']
>

interface UseShareDeleteProps {
  onCompleted: (data: ShareDeleted) => void
  onError: (message: string) => void
}

export const useShareDelete = (props: UseShareDeleteProps) => {
  const { onCompleted, onError } = props
  const [mutate, ...rest] = useShareDeleteMutation({
    redirectErrors: true,
    onCompleted: data => {
      if (!data?.shareDelete?.share) {
        onError('Something is wrong, please contact our support team')
        ErrorHandler.shouldNeverHappen.invalidMutationData('shareDelete')
        return
      }

      onCompleted(data.shareDelete.share)
    },
    onError: error => {
      onError(error.message)
    },
  })

  return [
    ({ identifier }: { identifier: string }) =>
      mutate({
        variables: { identifier },
        update: (cache, { data }) => {
          const workspaceIdentifier =
            data?.shareDelete?.share?.workspace.identifier || ''

          if (workspaceIdentifier === '') {
            ErrorHandler.shouldNeverHappen.invalidMutationData('shareDelete')
          }

          handleShareDeleted({
            cache,
            identifier,
            workspaceIdentifier,
          })
        },
      }),
    ...rest,
  ] as const
}
