import styled, { css } from 'styled-components'
import { breakpoint } from '@sketch/global-styles'

import { RadioButton } from '@sketch/components'

interface CardWrapperProps {
  isSelected: boolean
  isDisabled: boolean
}

export const OuterCardWrapper = styled.div`
  width: 212px;
  text-align: center;

  :not(:last-child) {
    ${breakpoint('base', 'sm')`
        margin-bottom:  24px;
      `}

    margin-right: 16px;
  }

  hr {
    width: 100%;
    margin: 19.2px 0; /* stylelint-disable-line scales/space */
  }

  ${breakpoint('base', 'sm')`
      width: 162px;
    `}

  padding: 4px;
`

export const CardWrapper = styled.div<CardWrapperProps>(
  ({ theme, isSelected, isDisabled }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    padding: 24px;

    height: 216px;
    min-width: 134px;

    border-radius: 20px; /* stylelint-disable-line scales/radii */

    color: ${isSelected ? theme.colors.foreground.secondary.A : undefined};

    ${isSelected
      ? css`
          border: 2px solid ${theme.colors.sketch.D};
          background-color: ${theme.colors.background.secondary.A};
        `
      : css`
          border: 1px solid ${theme.colors.border.B};
          background-color: ${theme.colors.background.secondary.B};
        `}

    opacity: ${isDisabled ? '0.8' : '1'};
    transition: background-color ${theme.transitions.duration[2]}
      ${theme.transitions.timing.easeInOut};
  `
)

export const FakeRadioButton = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 999px;
  background-color: white;
  box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.03);
  cursor: pointer;
  pointer-events: none;
  transition: ${({ theme }) => `
    background-color ${theme.transitions.duration[1]} ${theme.transitions.timing.easeInOut},
    border-color ${theme.transitions.duration[1]} ${theme.transitions.timing.easeInOut}
  `};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 999px;
    background-color: white;
    margin: auto;
    opacity: 0;
    box-shadow: 0 1px 1px 0 ${({ theme }) => theme.colors.border.B};
  }
`

export const StyledRadioButton = styled(RadioButton)`
  position: absolute;
  top: 12px;
  right: 0;
  left: 12px;
  bottom: 0;

  ${RadioButton.Input} {
    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.8 !important;
        cursor: not-allowed;
      `}
  }
`

export const PlanName = styled.p`
  width: 100%;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.C};
  font-weight: 600;
  margin: 0 0 4px 0;
`

export const Small = styled.small<{ isSelected: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.E};
  opacity: 0.65;
  pointer-events: none;
  color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.colors.foreground.secondary.A
      : theme.colors.foreground.secondary.D};
`

export const SmallText = styled.small`
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.D};

  pointer-events: none;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  line-height: ${({ theme }) => theme.lineHeights.E};
  margin-top: 16px;

  strong {
    display: block;
  }
`

export const Price = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  letter-spacing: -6px;
  font-weight: ${({ theme }) => theme.fontWeights.thin};
`

export const Currency = styled.span<{ isSelected: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.F};
  color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.colors.foreground.secondary.A
      : theme.colors.foreground.secondary.D};
`

export const Unit = styled.p<{ isSelected: boolean }>`
  align-self: flex-start;
  margin: 0;
  font-size: 5rem; /* stylelint-disable-line scales/font-size */
  font-weight: 100;
  line-height: 1;
  color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.colors.foreground.secondary.A
      : theme.colors.foreground.secondary.C};
`

export const Cents = styled.p`
  margin: 0;
  font-size: 1.625rem; /* stylelint-disable-line scales/font-size */
  letter-spacing: 0;
`
