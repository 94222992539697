import ApolloClient from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { createOAuthFetcher as newCreateOAuthFetcher } from './oauth-fetcher'

type AuthorizationType = 'current-only' | 'current-and-secondary'
type FetchType = typeof window.fetch

export {
  checkIfTokenIsExpired,
  refreshToken,
  formatCredentials,
} from './refreshToken'

const createOAuthFetcher = (
  getClient: () => ApolloClient<NormalizedCacheObject>,
  authorizationType: AuthorizationType = 'current-only'
) => {
  const oauthFetcher: FetchType = (...args) => {
    return newCreateOAuthFetcher(getClient, authorizationType)(...args)
  }

  return oauthFetcher
}

export { createOAuthFetcher }
