import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "extension-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { fillOpacity: 0.15, fill: "currentColor", d: "M1.88 4.906l5.99 3.125v6.771l-5.99-3.385z" }),
        React.createElement("path", { d: "M8.368 1.207l5.5 3.094a.75.75 0 01.382.653v6.154a.75.75 0 01-.382.654l-5.5 3.094a.75.75 0 01-.736 0l-5.5-3.094a.75.75 0 01-.382-.654V4.954a.75.75 0 01.382-.653l5.5-3.094a.75.75 0 01.736 0z", stroke: "currentColor", strokeWidth: 1.5 }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, d: "M1.88 4.646L8 8.03l6.12-3.385M7.75 8.031v6.771" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
