import styled from 'styled-components'
import { BaseListRow, BaseListRowPlaceholder } from '@sketch/components'

const USER_PERMISSIONS_ROW_HEIGHT = 60

export const BaseUserPermissionRow = styled(BaseListRow)`
  height: ${USER_PERMISSIONS_ROW_HEIGHT}px;
`

export const UserPermissionRowPlaceholder = styled(
  BaseListRowPlaceholder
).attrs({
  height: USER_PERMISSIONS_ROW_HEIGHT,
  pt: 0,
  pb: 0,
})``
