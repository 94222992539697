import { castError } from '@sketch/utils'
import { DataProxy } from 'apollo-cache'
import {
  GetArtboardRevisionsDocument,
  GetArtboardRevisionsQueryVariables,
  GetArtboardRevisionsQuery,
} from '@sketch/gql-types'
import produce from 'immer'
import { ErrorHandler } from '@sketch/tracing'

export interface UpdateArtboardRevisionProps {
  client: DataProxy
  variables: GetArtboardRevisionsQueryVariables
  update: (
    artboardRevisions: GetArtboardRevisionsQuery['artboardRevisions']
  ) => void
}
export const updateArtboardRevisions = ({
  client,
  variables,
  update,
}: UpdateArtboardRevisionProps) => {
  try {
    const artboardRevisions = client.readQuery<
      GetArtboardRevisionsQuery,
      GetArtboardRevisionsQueryVariables
    >({
      query: GetArtboardRevisionsDocument,
      variables,
    })

    // When a user goes directly to the inspector (by URL or remembering the
    // option via localStorage) activites are not loaded but revision
    // subscription is already listening, so this situation is expected
    if (!artboardRevisions) {
      return
    }

    const newArtboardRevisions = produce(artboardRevisions, draft =>
      update(draft.artboardRevisions)
    )

    client.writeQuery({
      data: newArtboardRevisions,
      query: GetArtboardRevisionsDocument,
      variables,
    })
  } catch (e) {
    const err = castError(e)
    ErrorHandler.ignore(
      err,
      'there is nothing to update if readQuery or writeQuery throws an error'
    )
  }
}
