import { castError, validEmail } from '@sketch/utils'
import React, { useEffect, useRef, useState } from 'react'
import * as yup from 'yup'
import { Formik, FormikHelpers } from 'formik'
import { isApolloError } from 'apollo-client'
import { Stripe } from '@stripe/stripe-js'

import {
  FormikScrollToError,
  Button,
  Checkbox,
  Link,
  pluralize,
} from '@sketch/components'

import { createFormikValidator } from '../../components/StripeField'
import CreditCardForm from '../../components/CreditCardForm'
import BillingDetailsForm from '../../components/BillingDetailsForm'
import ShippingDetailsForm from '../../components/ShippingDetailsForm'

import Fieldset from '../../components/Fieldset'
import BillSimulation, {
  BillSimulationProps as BaseBillSimulationProps,
} from '../../components/BillSimulation'
import AddEditors from '../../components/AddEditors'
import { ShippingDetailsFormFields } from '../../components/ShippingDetailsForm/types'

import {
  H1,
  Form,
  Layout,
  PaymentFormContainer,
  BillingDetailsFormFormContainer,
  TermsOfServiceSection,
  ManageSeatsSection,
  BillSummarySection,
  WorkspaceLogoContainer,
  StyledLogo,
  WorkspaceName,
  PartnersInvoicePaymentsInfo,
  ReduceSeatsWarning,
  Sticky,
} from './WorkspaceSubscribePaymentDetails.styles'
import {
  H2,
  Text,
} from '../../views/WorkspaceSubscribeView/WorkspaceSubscribeView.styles'

// Form values
/**
 * Common values between payment methods (invoice and card)
 */
const BILLING_VALUES = {
  name: '',
  email: '',
  address: '',
  city: '',
  country: 'US',
  postalCode: '',
  cardName: '',
  taxId: '',
  state: '',
  tos: false,
  shippingName: '',
  shippingState: '',
  shippingAddress: '',
  shippingCity: '',
  shippingCountry: 'US',
  shippingPostalCode: '',
}
const BILLING_VALUES_WITHOUT_SHIPPING = {
  name: '',
  email: '',
  address: '',
  city: '',
  country: 'US',
  postalCode: '',
  cardName: '',
  taxId: '',
  state: '',
  tos: false,
}
/**
 * These fields are related with the credit-card
 * data, in order to get formik like validation
 * we need to include them in the "initial values"
 *
 * these values are only placeholders, in the end,
 * none of their values will be used
 */
const CARD_PAYMENT_VALUES = {
  cardNumber: undefined,
  cardCvc: undefined,
  cardExpirationDate: undefined,
}

const STRIPE_VALUES = {
  ...BILLING_VALUES_WITHOUT_SHIPPING,
  ...CARD_PAYMENT_VALUES,
}
const STRIPE_ERRORS_TO_FIELDS: Record<string, keyof typeof STRIPE_VALUES> = {
  incorrect_cvc: 'cardCvc',
  processing_error: 'cardNumber',
  expired_card: 'cardExpirationDate',
  card_declined: 'cardNumber',
  incorrect_number: 'cardNumber',
  tax_id_invalid: 'taxId',
}

const stripeValidation = createFormikValidator(
  ['cardCvc', 'cardExpirationDate', 'cardNumber'],
  {
    cardNumber: 'Card Number is required',
    cardCvc: 'CVC is required',
    cardExpirationDate: 'Expiry Date is required',
  }
)

type InvoiceForm = typeof BILLING_VALUES
type CardForm = typeof STRIPE_VALUES
type FormValues = InvoiceForm | CardForm
type SubmitCardValues = typeof BILLING_VALUES_WITHOUT_SHIPPING
export interface SubmitInvoiceValues extends InvoiceForm {
  seats?: number
}
type SubmitValues = SubmitInvoiceValues | SubmitCardValues

type PaymentMethod = 'invoice' | 'card'

interface BillSimulationProps extends BaseBillSimulationProps {
  onEditorsChange?: (editors: number) => void
}

interface CommonWorkspaceSubscribePaymentDetailsProps {
  title: string
  billSimulation: BillSimulationProps
  stripe?: Stripe
  variant?: 'default' | 'partner'
  isCreatingWorkspace?: boolean
  paymentMethod?: PaymentMethod
  billingInfo?: Partial<typeof BILLING_VALUES>
  onCompleteSubscription?: () => void
  onSubmit: (values: SubmitValues) => Promise<void>
}

interface Workspace {
  name: string
  avatar?: string | null
}

type WorkspaceSubscribePaymentDetailsDefault = CommonWorkspaceSubscribePaymentDetailsProps & {
  workspace?: never
}

type WorkspaceSubscribePaymentDetailsPartners = CommonWorkspaceSubscribePaymentDetailsProps & {
  workspace: Workspace
}

export type WorkspaceSubscribePaymentDetailsProps =
  | WorkspaceSubscribePaymentDetailsDefault
  | WorkspaceSubscribePaymentDetailsPartners

function isCardPayment(values: FormValues): values is CardForm {
  return (
    (values as CardForm).cardName !== undefined &&
    (values as CardForm).cardName !== ''
  )
}

const getInitialFormValues = (
  method: PaymentMethod,
  billingInfo?: Partial<typeof BILLING_VALUES>
): FormValues => {
  if (method === 'card') return { ...STRIPE_VALUES, ...billingInfo }

  return { ...BILLING_VALUES, ...billingInfo }
}

export const WorkspaceSubscribePaymentDetails: React.FC<WorkspaceSubscribePaymentDetailsProps> = ({
  title,
  billSimulation,
  workspace,
  billingInfo,
  isCreatingWorkspace = false,
  variant = 'default',
  paymentMethod = 'card',
  onSubmit,
}) => {
  const formRef = useRef<HTMLFormElement>(null)
  const initialSeatsNumber = useRef<number>(0)
  const [showReduceSeatsWarning, setShowReduceSeatsWarning] = useState(false)

  const [shippingInfoAsBilling, setShippingInfoAsBilling] = useState(true)

  const getShippingValidation = () => {
    if (shippingInfoAsBilling) return undefined
    return {
      shippingPostalCode: yup
        .string()
        .trim()
        .required('Postal Code is required'),
      shippingCity: yup.string().trim().required('City is required'),
      shippingName: yup.string().trim().required("Customer's name is required"),
      shippingCountry: yup.string().trim().required('Country is required'),
      shippingAddress: yup.string().trim().required('Address is required'),
      shippingState: yup.string().when('shippingCountry', {
        is: 'US',
        then: schema => schema.trim().required('State is required'),
        otherwise: schema => schema.trim(),
      }),
    }
  }

  const invoicevalidationSchema = yup.object().shape({
    name: yup.string().trim().required('Name is required'),
    email: validEmail().trim().required('Email is required'),
    address: yup.string().trim().required('Address is required'),
    country: yup.string().required('Country is required'),
    city: yup.string().trim().required('City is required'),
    state: yup.string().when('country', {
      is: 'US',
      then: schema => schema.trim().required('State is required'),
      otherwise: schema => schema.trim(),
    }),
    postalCode: yup.string().trim().required('Postal Code is required'),
    ...getShippingValidation(),
    taxId: yup.string().trim(),
  })

  // Formik Validation
  const INVOICE_VALIDATION_SCHEMA = yup
    .object()
    .shape({
      tos: yup.mixed().oneOf([true], 'Terms of Service must be agreed'),
    })
    .concat(invoicevalidationSchema)

  const CARD_VALIDATION_SCHEMA = yup
    .object()
    .shape({
      cardName: yup.string().trim().required('Name on Card is required'),
    })
    .concat(INVOICE_VALIDATION_SCHEMA)

  useEffect(() => {
    // Reduce seats warning should show only for partners
    if (variant === 'default') return

    // Since seats number is not available by the time this components render
    // for the first type, we set to 0 and assume any value different than that
    // mean the component already reveived the values from the query.
    if (!initialSeatsNumber.current && billSimulation.numberEditors) {
      initialSeatsNumber.current = billSimulation.numberEditors
      return
    }

    setShowReduceSeatsWarning(
      billSimulation.numberEditors < initialSeatsNumber.current
    )
  }, [variant, billSimulation.numberEditors])

  const numberOfEditorSeatsHelpText = `The Workspace is currently being billed for ${
    billSimulation.numberEditors
  } Editor ${pluralize(
    'Seat',
    'Seats',
    billSimulation.numberEditors
  )}. Once you‘ve accepted your role as a Partner, you can add more Editors from the Billing screen — which will be included in the next billing cycle.`

  const validate = (values: FormValues) =>
    paymentMethod === 'card' ? stripeValidation(values as CardForm) : undefined

  const handleOnSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    try {
      if (isCardPayment(values)) {
        const {
          cardCvc,
          cardExpirationDate,
          cardNumber,
          ...correctValues
        } = values

        await onSubmit(correctValues)
      } else {
        let finalValues = { ...values }
        if (shippingInfoAsBilling) {
          finalValues = {
            ...values,
            shippingName: values.name,
            shippingState: values.state,
            shippingAddress: values.address,
            shippingCountry: values.country,
            shippingPostalCode: values.postalCode,
            shippingCity: values.city,
          }
        }
        await onSubmit({ ...finalValues, seats: billSimulation.numberEditors })
      }
    } catch (e) {
      const error = castError(e)
      if (isApolloError(error)) {
        error.graphQLErrors.forEach(({ extensions, message }) => {
          if (message === 'Invalid value for us_ein.') {
            actions.setFieldError(
              STRIPE_ERRORS_TO_FIELDS['tax_id_invalid'],
              'Please enter a valid VAT or tax ID number.'
            )
            return
          }
          const formField = STRIPE_ERRORS_TO_FIELDS[extensions?.stripeErrorCode]
          formField && actions.setFieldError(formField, message)
        })
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  let submitCopy = 'Confirm Payment'

  if (variant === 'partner') {
    submitCopy = isCreatingWorkspace ? submitCopy : 'Confirm and Join'
  }

  return (
    <>
      {workspace && variant === 'partner' && !isCreatingWorkspace && (
        <WorkspaceLogoContainer>
          <StyledLogo
            workspaceName={workspace.name}
            src={workspace.avatar}
            size="24px"
          />
          <WorkspaceName>{workspace.name}</WorkspaceName>
        </WorkspaceLogoContainer>
      )}

      <H1 $variant={variant}>{title}</H1>
      <Formik
        initialValues={getInitialFormValues(paymentMethod, billingInfo)}
        validationSchema={
          paymentMethod === 'card'
            ? CARD_VALIDATION_SCHEMA
            : INVOICE_VALIDATION_SCHEMA
        }
        validate={values => validate(values)}
        onSubmit={handleOnSubmit}
        enableReinitialize
      >
        {({ isSubmitting, touched, errors, handleChange, values }) => (
          <Form ref={formRef}>
            <Layout>
              <div>
                <FormikScrollToError formRef={formRef} />
                <Fieldset>
                  {paymentMethod === 'card' && (
                    <>
                      <H2 size="medium">Payment Method</H2>
                      <Text>Enter your card details</Text>
                      <PaymentFormContainer>
                        <CreditCardForm<any> />
                      </PaymentFormContainer>
                    </>
                  )}

                  <H2 size="medium">
                    {paymentMethod === 'card'
                      ? 'Your Details'
                      : 'Review Invoice Details'}
                  </H2>

                  {variant === 'partner' && (
                    <PartnersInvoicePaymentsInfo type="information">
                      You’re currently using invoices as your preferred payment
                      method. To change your payment method or details, contact
                      <br />
                      <Link external href="mailto:partners@sketch.com">
                        partners@sketch.com
                      </Link>
                    </PartnersInvoicePaymentsInfo>
                  )}

                  <BillingDetailsFormFormContainer>
                    {paymentMethod !== 'card' && (
                      <ShippingDetailsForm<ShippingDetailsFormFields>
                        shippingInfoAsBilling={shippingInfoAsBilling}
                        setShippingInfoAsBilling={setShippingInfoAsBilling}
                      />
                    )}
                    <BillingDetailsForm<FormValues>
                      disabled={paymentMethod === 'invoice'}
                      showHeader
                    />
                  </BillingDetailsFormFormContainer>
                </Fieldset>
              </div>

              <div>
                {variant === 'partner' && (
                  <ManageSeatsSection>
                    <H2 size="medium">Manage Editor Seats</H2>
                    <AddEditors
                      description="Schedule Editor Seats for the next billing cycle"
                      helpText={numberOfEditorSeatsHelpText}
                      seats={billSimulation.numberEditors}
                      onChange={editors => {
                        billSimulation.onEditorsChange?.(editors)
                      }}
                    />
                    {showReduceSeatsWarning && (
                      <ReduceSeatsWarning type="warning" showIcon={false}>
                        Reducing Seats will mean that one or more current
                        Editors will no longer be able to edit Sketch documents
                        in the next billing cycle.
                      </ReduceSeatsWarning>
                    )}
                  </ManageSeatsSection>
                )}

                <Sticky>
                  <BillSummarySection $variant={variant}>
                    <H2 size="medium">Bill Summary</H2>
                    <BillSimulation variant={variant} {...billSimulation} />
                  </BillSummarySection>

                  <TermsOfServiceSection>
                    <label htmlFor="terms-of-service" className="sr-only">
                      I’ve read and agree to the Sketch Terms of Service and
                      order details.
                    </label>
                    <Form.Field
                      name="tos"
                      errorText={touched.tos ? errors.tos : undefined}
                    >
                      <Checkbox
                        name="tos"
                        id="terms-of-service"
                        variant="untinted"
                        checked={values.tos}
                        onChange={handleChange}
                      >
                        I’ve read and agree to the Sketch{' '}
                        <Link
                          variant="secondary"
                          isUnderlined
                          external
                          href="https://www.sketch.com/tos"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Service
                        </Link>{' '}
                        and order details.
                      </Checkbox>
                    </Form.Field>
                    <Button
                      variant="primary"
                      fill
                      type="submit"
                      loading={isSubmitting}
                    >
                      {submitCopy}
                    </Button>
                  </TermsOfServiceSection>
                </Sticky>
              </div>
            </Layout>
          </Form>
        )}
      </Formik>
    </>
  )
}
