import React, { useEffect } from 'react'
import * as S from './ArtboardDetailInspectorOverlay.styles'
import { PRMarinaRect, useIsCameraMoving } from '@sketch-hq/sketch-web-renderer'
import { useMapPRMarinaRectToCanvasRelativeRect } from '../../hooks/inspector'

import {
  SketchElement,
  isArtboardElement,
  isPageElement,
} from '../../../../inspector'

export type HoveredLayerOverlayProps = {
  bounds: PRMarinaRect
  element: SketchElement
}

export function HoveredLayerOverlay(props: HoveredLayerOverlayProps) {
  const { bounds, element } = props

  const canvasRelativeRect = useMapPRMarinaRectToCanvasRelativeRect(bounds)
  const [isHidden, setIsHidden] = React.useState(false)

  const isCameraMoving = useIsCameraMoving()

  // When the camera is moving, we hide the hovered layer and avoid updating the
  // hovered element state (see useCanvasMouseMoveHandler.ts). However, once the camera
  // stops moving the hovered element can have changed (when panning with trackpad).
  // useCanvasMouseMoveHandler has a useEffect that runs after the camera has finished moving
  // to recalculate the hovered element, but the new value becomes available only in the next render cycle.
  // We use an internal state isHidden here to hide the element until the next render cycle.
  // and avoid the old hovered element being marked as hovered for a split second when the camera stops moving
  // and a new layer is hovered because of the camera pan/zoom.
  useEffect(() => {
    if (!isCameraMoving && isHidden) {
      setIsHidden(false)
    } else if (isCameraMoving && !isHidden) {
      setIsHidden(true)
    }
    // Run the hook only when the camera is moving state has changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCameraMoving])

  /**
   * We are ignoring the rendering of Page elements
   * because they are not relevant for the display of the inspector
   */
  if (!canvasRelativeRect || isPageElement(element)) {
    return null
  }

  /**
   * If we are hovering an artboard and there's still fragment data pending
   * we should show a loading state so the user can be warned it's not complete yet
   */
  if (isArtboardElement(element) && element.hasFragmentPending) {
    return (
      <S.LayerOverlayLoadingWrapper
        $width={canvasRelativeRect.width}
        $height={canvasRelativeRect.height}
        $x={canvasRelativeRect.x}
        $y={canvasRelativeRect.y}
        $hidden={isHidden}
      >
        <S.LayerOverlayLoadingClip>
          <S.LayerOverlayLoadingBorder
            style={{
              width: Math.max(
                canvasRelativeRect.width,
                canvasRelativeRect.height
              ),
            }}
          />
        </S.LayerOverlayLoadingClip>
      </S.LayerOverlayLoadingWrapper>
    )
  }

  return (
    <S.LayerOverlay
      $borderSize={2}
      $color="orange"
      $width={canvasRelativeRect.width}
      $height={canvasRelativeRect.height}
      $x={canvasRelativeRect.x}
      $y={canvasRelativeRect.y}
      $hidden={isHidden}
    />
  )
}
