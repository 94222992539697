import React, { useRef } from 'react'

import {
  useIsCameraMoving,
  useIsCameraZooming,
  usePan,
  useZoom,
  useRootNodeAbsoluteExtent,
} from '@sketch-hq/sketch-web-renderer'

import { useDevToolsSetting } from '@sketch/devtools'
import { Wrapper, OriginCross } from './OriginContent.styles'

export const OriginContent: React.FC = ({ children }) => {
  const ref = useRef(null)

  const pan = usePan() || { x: 0, y: 0 }
  const zoom = useZoom() || 1
  const isCameraMoving = useIsCameraMoving()
  const isCameraZooming = useIsCameraZooming()

  const [showPageOrigin] = useDevToolsSetting('webRenderer.showPageOrigin')
  const rootExtent = useRootNodeAbsoluteExtent()

  const style = {
    // willChange hints the browser that we are going to update these properties
    willChange: isCameraMoving ? 'contents' : undefined,
    // Disabling the pointer-events when the camera is moving, so the user can pan/scroll freely
    pointerEvents: isCameraMoving ? 'none' : 'auto',
    transform: `translate3d(${Math.round(pan.x * zoom)}px, ${Math.round(
      pan.y * zoom
    )}px, 0)`,
    width: `${(rootExtent?.width ?? 0) * zoom}px`,
    height: `${(rootExtent?.height ?? 0) * zoom}px`,
    marginTop: `${-(rootExtent?.y ?? 0) * zoom}px`,
    marginLeft: `${-(rootExtent?.x ?? 0) * zoom}px`,
  } as const

  return (
    <Wrapper
      ref={ref}
      style={style}
      data-canvas-zooming={isCameraZooming}
      data-testid="origin-content"
    >
      {children}
      {showPageOrigin && <OriginCross />}
    </Wrapper>
  )
}
