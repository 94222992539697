import { errorStyles } from './consoleTheme'
import * as sentry from '@sentry/browser'
import throttle from 'lodash.throttle'

interface IgnoredError {
  kind: 'ignoredError'
  error: Error
  reason?: string
  timestamp: number
}

interface ForbiddenError {
  kind: 'forbiddenError'
  reason: string
  timestamp: number
}

interface ApolloError {
  kind: 'apolloError'
  error: Error
  query?: string
  variables?: any
  timestamp: number
}

export type AnyError = IgnoredError | ForbiddenError | ApolloError
type ReportableError = ForbiddenError

let verbose = false
try {
  if (localStorage.getItem('verbose_errors') === 'true') {
    verbose = true
  }
} catch {
  // If can't access local storage it will keep
  // the default value of false for verbose
}

const throttleTime = 5000
const errorsCounter = {
  ignored: 0,
  forbidden: 0,
  apollo: 0,
  batchTotal: 0,
  total: 0,
}

// Array of errors that will be sent to Sentry in a batch
const reportableErrors: ReportableError[] = []

// Array of errors that will be used only locally
const verboseErrors: AnyError[] = []

const sendReportableErrors = (error: ReportableError) => {
  sentry.withScope(scope => {
    // Output the total number of errors in the time frame defined
    scope.setTag('throttledErrors', `${reportableErrors.length}`)

    // Add extra information regarding all the session errors count
    scope.setExtra('errorsCounter', errorsCounter)

    // Set a maximum number of 50 errors to report
    const errorsExtra = reportableErrors.slice(0, 50)
    scope.setExtra('errors', errorsExtra)

    // Capture error on Sentry
    sentry.captureException(new Error(error.reason))

    // Clear array of errors
    reportableErrors.length = 0

    // Increase the batch total which tells us how many
    // error batches were sent to sentry in total
    errorsCounter.batchTotal++
  })
}

const throttleReportableErrors = throttle(sendReportableErrors, throttleTime, {
  leading: true,
  trailing: true,
})
const reportError = (error: ReportableError) => {
  reportableErrors.push(error)
  throttleReportableErrors(error)
}

const increaseCounter = (error: AnyError) => {
  errorsCounter.total++

  switch (error.kind) {
    case 'apolloError':
      errorsCounter.apollo++
      break
    case 'forbiddenError':
      errorsCounter.forbidden++
      break
    case 'ignoredError':
      errorsCounter.ignored++
      break
  }
  updateReadOnlyCounter()
}

const getGroupMessageTitle = (message: string, maxLength = 100) =>
  message.replaceAll('\n', ' ').substr(0, maxLength) +
  (message.length > maxLength ? '…' : '')

const trackVerboseError = (error: AnyError) => {
  verboseErrors.push(error)
  increaseCounter(error)

  if (!verbose) {
    if (verboseErrors.length > 100) {
      // Remove first errors to cap number of errors
      // at 100 if verbose mode is off
      verboseErrors.splice(0, verboseErrors.length - 100)
    }

    return
  }

  if (error.kind === 'ignoredError') {
    const title = getGroupMessageTitle(error.error.message)
    /* eslint-disable no-console */
    console.groupCollapsed(`[IgnoredError]: %c${title} 👇`, errorStyles)
    console.log(error.error)
    console.log(error.reason)
    console.groupEnd()
    /* eslint-enable no-console */
  }

  if (error.kind === 'forbiddenError') {
    /* eslint-disable no-console */
    console.log(`[ForbiddenError]: %c${error.reason}`, errorStyles)
    /* eslint-enable no-console */
  }

  if (error.kind === 'apolloError') {
    /* eslint-disable no-console */
    const title = getGroupMessageTitle(error.error.message)
    console.group(`[ApolloError]: %c${title} 👇`, errorStyles)
    console.log(error.error)
    console.log(error.query)
    console.log(error.variables)
    console.groupEnd()
    /* eslint-enable no-console */
  }
}

const shouldNeverHappenRaw = (reason: string) => {
  const forbiddenError: ForbiddenError = {
    kind: 'forbiddenError',
    reason,
    timestamp: Date.now(),
  }

  trackVerboseError(forbiddenError)
  reportError(forbiddenError)
  return null
}

const shouldNeverHappen = Object.assign(shouldNeverHappenRaw, {
  invalidMutationData: (mutationName: string) => {
    shouldNeverHappenRaw(`Mutation "${mutationName}" should return valid data`)
  },
})

export const ErrorHandler = {
  // Actions
  ignore: (error: Error, reason?: string) => {
    const ignoredError: IgnoredError = {
      kind: 'ignoredError',
      error,
      reason,
      timestamp: Date.now(),
    }

    trackVerboseError(ignoredError)
  },
  shouldNeverHappen,
  apollo: (query: string, variables: any) => (error: Error) => {
    const apolloError: ApolloError = {
      kind: 'apolloError',
      error,
      query,
      variables,
      timestamp: Date.now(),
    }

    trackVerboseError(apolloError)
    return null
  },
}

function mapTimestamps<T extends { timestamp: number }>(array: T[]) {
  return array.map(x => ({
    ...x,
    timestamp: new Date(x.timestamp).toISOString(),
  }))
}

export const ErrorLogger = {
  // Displays verbose value observing ErrorLogger object
  // This value is read only and can be change only by setVerbose method.
  // We are assigning this value at the top of the object in order to appear
  // first when opening browser developer tools.
  _verbose: undefined,

  // Displays throttleTime value observing ErrorLogger object
  // This value is read only and cannot be changed.
  // We are assigning this value at the top of the object in order to appear
  // first when opening browser developer tools.
  _throttleTime: undefined,

  // Display counter of how many errors of each kind occurred observing ErrorLogger object
  // This value is read only and cannot be changed.
  // We are assigning this value at the top of the object in order to appear
  // first when opening browser developer tools.
  _counter: undefined,

  // Verbose
  setVerbose: (display = !verbose) => {
    Object.defineProperty(ErrorLogger, '_verbose', {
      writable: false,
      value: display,
    })

    verbose = display
    return display ? 'Verbose mode activated' : 'Verbose mode deactivated'
  },

  // Get errors
  get: {
    all: () => [...verboseErrors],
    ignored: () => verboseErrors.filter(x => x.kind === 'ignoredError'),
    apollo: () => verboseErrors.filter(x => x.kind === 'apolloError'),
    forbidden: () => verboseErrors.filter(x => x.kind === 'forbiddenError'),
  },

  // Log errors
  log: {
    /* eslint-disable no-console */
    all: () => console.table(mapTimestamps(ErrorLogger.get.all())),
    ignored: () => console.table(mapTimestamps(ErrorLogger.get.ignored())),
    apollo: () => console.table(mapTimestamps(ErrorLogger.get.apollo())),
    forbidden: () => console.table(mapTimestamps(ErrorLogger.get.forbidden())),
    /* eslint-enable no-console */
  },

  // Reset
  reset: () => {
    throttleReportableErrors.cancel()
    verboseErrors.length = 0
    reportableErrors.length = 0
    verbose = false
    errorsCounter.total = 0
    errorsCounter.ignored = 0
    errorsCounter.apollo = 0
    errorsCounter.forbidden = 0
    errorsCounter.batchTotal = 0
    updateReadOnlyProps()
  },
}

const updateReadOnlyField = (
  fieldName: keyof typeof ErrorLogger,
  value: any
) => {
  Object.defineProperty(ErrorLogger, fieldName, {
    writable: false,
    configurable: true,
    enumerable: true,
    value,
  })
}

const updateReadOnlyCounter = () => {
  const counter = {
    ...errorsCounter,
  }
  Object.freeze(counter)
  updateReadOnlyField('_counter', counter)
}

const updateReadOnlyVerbose = () => updateReadOnlyField('_verbose', verbose)
const updateReadOnlyThrottleTime = () =>
  updateReadOnlyField('_throttleTime', throttleTime)

const updateReadOnlyProps = () => {
  updateReadOnlyCounter()
  updateReadOnlyVerbose()
  updateReadOnlyThrottleTime()
}

updateReadOnlyProps()

// Prevent external users from using the error handling tool to dispatch Sentry messages
if (!(process.env.REACT_APP_ENV === 'production')) {
  Object.assign(ErrorLogger, { capture: ErrorHandler })
}
