import React, { useState } from 'react'
import { useConsolidateAutoVersionMutation } from '@sketch/gql-types'
import {
  StyledClockwiseArrowCircle,
  StyledConsolidateButton,
} from './ConsolidateVersionButton.styles'

export interface ConsolidateVersionButtonProps {
  shareIdentifier: string
}

export const ConsolidateVersionButton = ({
  shareIdentifier,
}: ConsolidateVersionButtonProps) => {
  const [isConsolidating, setIsConsolidating] = useState(false)

  const [consolidateShare] = useConsolidateAutoVersionMutation({
    variables: {
      shareIdentifier,
    },
    onError: 'show-toast',
  })

  const doConsolidate = async () => {
    setIsConsolidating(true)
    consolidateShare()
  }

  return (
    <StyledConsolidateButton
      loading={isConsolidating}
      onClick={doConsolidate}
      variant="primary"
      size="32"
      data-testid="consolidate-pending-button"
    >
      {isConsolidating ? '-' : <StyledClockwiseArrowCircle />}
    </StyledConsolidateButton>
  )
}
