import React, { FC } from 'react'

import Icon, { IconName } from '../Icon/Icon'
import ResponsiveImage from '../ResponsiveImage'

import { useTrackEvent, useTrackEventInView } from '@sketch/modules-common'

import {
  Container,
  ContentContainer,
  Title,
  Content,
  ActionsContainer,
  StyledLinkButton,
  StyledFlatButton,
  MediaContainer,
  StyledIcon,
  Video,
} from './Hero.styles'

import {
  CommunityHeroFragment,
  DiscoverItemFragment,
  VideoSourceFragment,
} from '@sketch/gql-types'
import { ButtonVariant } from '@sketch/components'
import { ContextType } from '../Section'

type Role = 'ADMIN' | 'EDITOR' | 'VIEWER'

interface HeroProps extends OmitSafe<CommunityHeroFragment, '__typename'> {
  roles?: Role[]
  onDismiss?: (id: string) => void
  context: ContextType
  backgroundColor:
    | DiscoverItemFragment['backgroundColor']
    | VideoSourceFragment['backgroundColor']
}

/**
 * Hero
 *
 * Renders a hero that can display both video and an image
 * Used in the Discover page
 */
const Hero: FC<HeroProps> = ({
  id,
  title,
  content,
  image,
  video,
  links,
  contentPosition = 'left',
  onDismiss,
  backgroundColor,
  context,
}) => {
  // Analytics
  const { trackEvent } = useTrackEvent()

  const { ref } = useTrackEventInView(`${context} - H`, {
    type: 'load',
    target: title,
  })

  // Hero has no media, or has both video and image
  if ((!image && !video) || (!!image && !!video)) {
    return null
  }

  const isRetinaScreen = window.devicePixelRatio > 1
  const hasVideoContent = !image && !!video
  const hasRetinaVideo = hasVideoContent && !!video.retina

  // We allow a custom background color for the Hero. The color is optional and will
  // fallback to the brand.yellow. We can also define a background per video
  const heroBackgroundColor = hasVideoContent
    ? isRetinaScreen && hasRetinaVideo
      ? video.retina?.backgroundColor
      : video.standard.backgroundColor
    : backgroundColor

  const renderMediaContent = () =>
    hasVideoContent ? (
      <Video
        poster={
          isRetinaScreen && video?.retina?.poster
            ? video.retina.poster
            : video.standard.poster!
        }
        src={
          isRetinaScreen && video?.retina?.src
            ? video.retina.src
            : video.standard.src!
        }
        autoPlay
        loop
        muted
        controls={false}
        controlsList="nofullscreen nodownload noremoteplayback"
        playsInline
      />
    ) : (
      <ResponsiveImage image={image!} />
    )

  return (
    <Container
      ref={ref}
      $contentPosition={contentPosition}
      $backgroundColor={heroBackgroundColor}
    >
      {onDismiss && (
        <StyledFlatButton
          data-testid="dismiss-button"
          onClick={() => {
            onDismiss(id)
            trackEvent(`${context} - H`, {
              type: 'dismiss',
            })
          }}
        >
          <StyledIcon name="close" />
        </StyledFlatButton>
      )}
      <MediaContainer $contentPosition={contentPosition}>
        {renderMediaContent()}
      </MediaContainer>
      <ContentContainer>
        <Title>{title}</Title>
        <Content>{content}</Content>
        <ActionsContainer>
          {links?.map(link => (
            <StyledLinkButton
              key={link.url}
              variant={(link.variant as ButtonVariant) || 'primary-untinted'}
              size="40"
              icon={
                link?.icon
                  ? () => <Icon name={link.icon as IconName} size="16px" />
                  : undefined
              }
              href={link.url}
              external
              onClick={() =>
                trackEvent(`${context} - H`, {
                  type: 'click',
                  target: link.text!,
                })
              }
            >
              {link.text}
            </StyledLinkButton>
          ))}
        </ActionsContainer>
      </ContentContainer>
    </Container>
  )
}

export default Hero
