import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { routes } from '@sketch/modules-common'

import { ComponentPageDropdown } from './ComponentPageDropdown'
import { InlinePageForm } from './InlinePageForm'
import { DesignSystemPage } from '../../../types'
import { useDSDocsEditModeContext } from '../../../operations/DSDocsEditModeContext'
import {
  ConfirmationDialog,
  useModalContext,
  SelectDropdownLinkItem,
} from '@sketch/components'

import { ReactComponent as TextIcon } from '@sketch/icons/text-format-16'
import { ReactComponent as InkPenIcon } from '@sketch/icons/ink-pen-16'
import { ReactComponent as DocumentIcon } from '@sketch/icons/document-16'
import { ReactComponent as ColorFanIcon } from '@sketch/icons/color-fan-16'

interface FormValues {
  pageName: string
}

export const ComponentPage = ({
  page,
  workspaceId,
  designSystemId,
  handleDeletePage,
  handleDuplicatePage,
  handleRenamePage,
}: {
  page: DesignSystemPage
  workspaceId: string
  designSystemId: string
  handleDeletePage: (pageId: string) => void
  handleDuplicatePage: (pageId: string) => void
  handleRenamePage: (pageId: string, newName: string) => void
}) => {
  const [isRenaming, setIsRenaming] = useState(false)

  const history = useHistory()
  const { showModal, hideModal } = useModalContext()
  const { hasUnsavedChanges, discardChanges } = useDSDocsEditModeContext()

  const url = routes.DESIGN_SYSTEM_PAGES.create({
    workspaceId,
    designSystemId,
    pageId: page.identifier,
  })

  const onSubmit = ({ pageName: newPageName }: FormValues) => {
    if (page.name === newPageName) {
      setIsRenaming(false)
      return
    }

    handleRenamePage(page.identifier, newPageName)
    setIsRenaming(false)
  }

  return (
    <li>
      {!isRenaming ? (
        <SelectDropdownLinkItem
          icon={DocumentIcon}
          exact
          text={page.name}
          onClick={event => {
            if (hasUnsavedChanges) {
              event.preventDefault()

              showModal(ConfirmationDialog, {
                title: 'Discard changes?',
                children: (
                  <p>
                    You have unsaved changes on this page. Do you want to
                    discard those changes, or continue editing?
                  </p>
                ),
                confirmButton: {
                  text: 'Continue Editing',
                },
                cancelButton: {
                  text: 'Discard Changes',
                },
                onConfirm: () => {
                  hideModal()
                },
                onHide: () => {
                  discardChanges()
                  history.push(url)
                },
              })
            }
          }}
          to={url}
          action={
            <ComponentPageDropdown
              handleDeletePage={handleDeletePage}
              handleRenamePage={handleRenamePage}
              handleDuplicatePage={handleDuplicatePage}
              designSystemId={designSystemId}
              url={url}
              workspaceId={workspaceId}
              page={page}
              setIsRenaming={setIsRenaming}
            />
          }
        />
      ) : (
        <InlinePageForm
          onSubmit={onSubmit}
          toggleEditor={() => setIsRenaming(false)}
          defaultValue={page.name}
        />
      )}
    </li>
  )
}

export const Page = ({
  page,
  workspaceId,
  designSystemId,
}: {
  page: DesignSystemPage
  workspaceId: string
  designSystemId: string
}) => {
  const history = useHistory()
  const { showModal, hideModal } = useModalContext()
  const { hasUnsavedChanges, discardChanges } = useDSDocsEditModeContext()

  const getIcon = () => {
    switch (page.name) {
      case 'Colors':
        return ColorFanIcon
      case 'Typography':
        return TextIcon
      case 'Icons':
        return InkPenIcon
      default:
        return DocumentIcon
    }
  }

  const url = routes.DESIGN_SYSTEM_PAGES.create({
    workspaceId,
    designSystemId,
    pageId: page.identifier,
  })

  return (
    <li>
      <SelectDropdownLinkItem
        icon={getIcon()}
        exact
        text={page.name}
        onClick={event => {
          if (hasUnsavedChanges) {
            event.preventDefault()

            showModal(ConfirmationDialog, {
              title: 'Discard changes?',
              children: (
                <p>
                  You have unsaved changes on this page. Do you want to discard
                  those changes, or continue editing?
                </p>
              ),
              confirmButton: {
                text: 'Continue Editing',
              },
              cancelButton: {
                text: 'Discard Changes',
              },
              onConfirm: () => {
                hideModal()
              },
              onHide: () => {
                discardChanges()
                history.push(url)
              },
            })
          }
        }}
        to={url}
      />
    </li>
  )
}
