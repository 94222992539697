import React from 'react'
import { InView } from 'react-intersection-observer'

import { PublicationItemFragment } from '@sketch/gql-types'

import GridPublicationItem, {
  GridPublicationItemPlaceholder,
} from '../GridPublicationItem/'

import { Wrapper } from './PublicationGrid.styles'
import { routes, useTrackEventInView } from '@sketch/modules-common'

export interface PublicationGridProps {
  analyticsId: string
  items: PublicationItemFragment[]
  placeholderCount?: number
  onLoadMore?: () => void
}

const PublicationGrid = (props: PublicationGridProps) => {
  const { items, placeholderCount, onLoadMore, analyticsId } = props

  // Analytics
  const { ref } = useTrackEventInView(`COMMUNITY - Grid`, {
    type: 'load',
    target: analyticsId,
  })

  return (
    <Wrapper data-testid="publication-grid" ref={ref}>
      {items.map(publication => (
        <GridPublicationItem
          key={publication.identifier}
          publication={publication}
          url={routes.WORKSPACE_PROFILE_DOCUMENT.create({
            publicationId: publication.identifier,
            shortUrlName: publication.workspaceProfile!.shortUrlName,
          })}
          showProfile
        />
      ))}

      {!!placeholderCount &&
        Array.from({ length: placeholderCount }, (_, index) => (
          <GridPublicationItemPlaceholder key={index}>
            {index === 0 && onLoadMore && (
              <InView onChange={inView => inView && onLoadMore()}>
                <span className="sr-only">Loading Placeholder</span>
              </InView>
            )}
          </GridPublicationItemPlaceholder>
        ))}
    </Wrapper>
  )
}

export default PublicationGrid
