import React from 'react'

import { Box, PersonAvatar } from '@sketch/components'

import { TooltipAvatar } from '../NotificationListItem.styles'

const NotificationAvatar: React.FC<{
  avatar: string
  name: string
}> = ({ avatar, name }) => (
  <Box marginRight={2}>
    {avatar ? (
      <TooltipAvatar content={name}>
        <PersonAvatar flavour="image" size="16px" name={name} src={avatar} />
      </TooltipAvatar>
    ) : (
      <PersonAvatar flavour="color" size="16px" name={name} />
    )}
  </Box>
)

export default NotificationAvatar
