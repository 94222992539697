import React, {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme } from './theme'
import { useKonamiCode, useLocalStorage } from '../hooks'
import { localStorageKeys } from '@sketch/constants'
import { getParsedItem } from '../utils'

type Theme = 'light' | 'dark'

const themes = {
  light: lightTheme,
  dark: darkTheme,
}

type ThemeContext = {
  theme: Theme
  overriddenTheme: Theme | null
  selectedTheme: Theme
  matchSystem: boolean
  enableLightMode: () => void
  enableDarkMode: () => void
  toggleMatchSystem: () => void
  resetToMatchSystem: () => void
  setOverriddenTheme: (theme: Theme) => void
  resetOverriddenTheme: () => void
}

const defaultContext = {
  theme: 'light',
  overriddenTheme: null,
  selectedTheme: 'light',
  matchSystem: false,
  enableLightMode: () => null,
  enableDarkMode: () => null,
  toggleMatchSystem: () => null,
  resetToMatchSystem: () => null,
  setOverriddenTheme: () => null,
  resetOverriddenTheme: () => null,
} as const

const context = createContext<ThemeContext>(defaultContext)

export { ThemeProvider } from 'styled-components'

export const ThemeManager: React.FC = ({ children }) => {
  const matchMedia = window.matchMedia('(prefers-color-scheme: dark)')

  const [matchSystem, setMatchSystem] = useLocalStorage<boolean>(
    localStorageKeys.matchSystemTheme,
    true
  )

  const [overriddenTheme, setOverriddenThemeValue] = useState<Theme | null>(
    null
  )

  const [selectedTheme, setTheme] = useLocalStorage<Theme>(
    localStorageKeys.theme,
    matchMedia.matches ? 'dark' : 'light'
  )

  const actions = useMemo(() => {
    const toggleTheme = () =>
      setTheme(theme => (theme === 'light' ? 'dark' : 'light'))

    const enableLightMode = () => {
      setTheme('light')
      setMatchSystem(false)
    }

    const enableDarkMode = () => {
      setTheme('dark')
      setMatchSystem(false)
    }

    const toggleMatchSystem = () => setMatchSystem(matchSystem => !matchSystem)

    const resetToMatchSystem = () => {
      setMatchSystem(true)
      window.localStorage.removeItem(localStorageKeys.theme)
    }

    const setOverriddenTheme = (theme: Theme) => {
      setOverriddenThemeValue(theme)
    }

    const resetOverriddenTheme = () => {
      setOverriddenThemeValue(null)
    }

    return {
      toggleTheme,
      enableLightMode,
      enableDarkMode,
      toggleMatchSystem,
      resetToMatchSystem,
      setOverriddenTheme,
      resetOverriddenTheme,
    }
  }, [setTheme, setMatchSystem])

  useKonamiCode(actions.toggleTheme)

  // listening to OS System Theme setting changes, to update our theme in case `matchSystem` is on
  useEffect(() => {
    // If the user didn't check the "Match system" checkbox, we can return and ignore system theme changes
    if (!matchSystem) {
      return
    }

    const matchSystemAutomatically = ({
      matches: prefersDarkScheme,
    }: Pick<MediaQueryListEvent, 'matches'>) => {
      prefersDarkScheme ? setTheme('dark') : setTheme('light')
    }

    // We need to check initially if the theme and the system theme are in sync
    // This will fix some mismatch cases where the Appearance is set to System Theme, we close
    // the window and then update the theme in System Settings. In these cases, the theme
    // was not updated in the app, because the event listener was not triggered.
    if (getParsedItem(localStorageKeys.matchSystemTheme)) {
      matchSystemAutomatically(matchMedia)
    }

    matchMedia.addEventListener('change', matchSystemAutomatically)

    return () => {
      matchMedia.removeEventListener('change', matchSystemAutomatically)
    }
  }, [matchMedia, matchSystem, setTheme])

  const theme = overriddenTheme || selectedTheme
  const value = {
    theme,
    overriddenTheme,
    selectedTheme,
    matchSystem,
    ...actions,
  }

  return (
    <context.Provider value={value}>
      <ThemeProvider theme={themes[theme]}>{children}</ThemeProvider>
    </context.Provider>
  )
}

interface ThemeOverrideProps {
  theme: Theme
}

export const ThemeOverride = (
  props: React.PropsWithChildren<ThemeOverrideProps>
) => {
  const { theme, children } = props
  const { setOverriddenTheme, resetOverriddenTheme } = useContext(context)

  useLayoutEffect(() => {
    setOverriddenTheme(theme)

    return () => {
      resetOverriddenTheme()
    }
  }, [theme, setOverriddenTheme, resetOverriddenTheme])

  return <>{children}</>
}

export function useThemeContext() {
  const values = useContext(context)
  const isDarkMode = values.theme === 'dark'

  return { ...values, isDarkMode }
}
