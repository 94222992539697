import styled, { css } from 'styled-components'
import { BannerPageWrapper } from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'

export const BannerWrapper = styled(BannerPageWrapper)`
  margin: 24px 16px 0;

  ${breakpoint('sm')`
    margin: 0;
  `}
`

export const SearchBarWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;

  > div {
    width: 100%;
  }

  form {
    max-width: unset;
  }
`

export const NavbarTitle = styled.span.attrs({
  'data-testid': 'navbar-section-title',
  'data-navbar-section-title': true,
})(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.D};
    font-weight: 500;

    ${breakpoint('sm')`
      font-size: ${theme.fontSizes.F};
    `}

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
)

export const FirstStepsBannerWrapper = styled.div`
  margin: 0 0 20px;

  :empty {
    margin: 0;
  }

  ${breakpoint('sm')`
    margin: 0 -52px 40px;
  `}
`
