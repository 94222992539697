import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Link, Text, Form, ApolloError } from '@sketch/components'
import {
  Panel as StyledPanel,
  BodyPanel,
  PanelFooter,
  Content,
  InvitedEmail,
} from './shared'
import { ParsedError } from '@sketch/graphql-apollo/useMutation'
import {
  useAnalytics,
  IndexLayoutOldTitle,
  routes,
  RedirectBadge,
  Inviter,
  SignUpForm,
  useUserProfile,
} from '@sketch/modules-common'
import queryString from 'query-string'

export interface SignUpAndAcceptMembershipPanelProps {
  shareShortId: string
  invitedEmail: string
  inviter: Inviter
  inviteToken: string
  resourceName: string
  acceptMembership(): void
  acceptMembershipLoading: boolean
  acceptMembershipError?: ParsedError
}

const SignUpAndAcceptMembershipPanel = ({
  invitedEmail,
  inviter,
  inviteToken,
  resourceName,
  acceptMembership,
  acceptMembershipLoading,
  acceptMembershipError,
}: SignUpAndAcceptMembershipPanelProps) => {
  const location = useLocation()
  const history = useHistory()
  const query = queryString.parse(location.search)
  const { data: profile } = useUserProfile()
  const userEmail = profile?.me.email
  const { trackEvent } = useAnalytics()

  return (
    <StyledPanel>
      <RedirectBadge
        from={location.pathname}
        documentInvitation={{ inviter, documentName: resourceName }}
      />

      <SignUpForm
        invitedUser
        email={invitedEmail}
        formContextForAnalytics="share invite"
        onCompleted={() => {
          if (invitedEmail === userEmail) {
            acceptMembership()
            trackEvent('SIGN UP - Sign up form success', {
              willContinueToWorkspaceCreation: false,
              formContext: 'share invite',
            })
          } else {
            history.push(
              routes.SHARE_ACCEPT_INVITE.create({
                query,
              }),
              {
                from: location.pathname,
              }
            )
          }
        }}
      >
        {formProps => (
          <StyledPanel>
            <BodyPanel>
              <IndexLayoutOldTitle>Create a Sketch Account</IndexLayoutOldTitle>
              <Content mb={32}>
                To access the document now, sign up as{' '}
                <InvitedEmail>
                  <strong>{invitedEmail}</strong>
                </InvitedEmail>
                . Alternatively, sign up or{' '}
                <Link
                  to={{
                    pathname: routes.SHARE_ACCEPT_INVITE.create({}),
                    search: `?token=${inviteToken}`,
                  }}
                  variant="secondary"
                  isUnderlined
                >
                  sign in
                </Link>{' '}
                with a different email and request access.
              </Content>

              <SignUpForm.Fields
                {...formProps}
                formContextForAnalytics="share invite"
              />
            </BodyPanel>
            <PanelFooter>
              {(acceptMembershipError || formProps.apiError) && (
                <ApolloError
                  style={{ textAlign: 'left' }}
                  my={4}
                  error={acceptMembershipError || formProps.apiError!}
                />
              )}
              {formProps.status && (
                <Form.Error>
                  <Text textAlign="center" mt={0}>
                    {formProps.status}
                  </Text>
                </Form.Error>
              )}
              <SignUpForm.Submit
                isSubmitting={formProps.isSubmitting || acceptMembershipLoading}
                onClick={() => {
                  const submitCount = formProps.submitCount + 1
                  trackEvent('SIGN UP - Sign up form submitted', {
                    submitCount: submitCount,
                    formContext: 'share invite',
                  })
                }}
              />
            </PanelFooter>
          </StyledPanel>
        )}
      </SignUpForm>
    </StyledPanel>
  )
}

export default SignUpAndAcceptMembershipPanel
