import styled from 'styled-components'

import { Navbar } from '@sketch/components'

export const NavbarWrapper = styled(Navbar)`
  justify-content: flex-start;
  flex-shrink: 0;
  padding: 8px;
`

export const NavbarSpacer = styled.div`
  flex: 1 0 0;
`

export const ToggleButton = styled(Navbar.Button)`
  width: 40px;
  height: 100%;
  max-height: 40px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.state.hover};
    border-radius: 6px;
  }
`
