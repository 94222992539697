import React from 'react'

import { downloadFile } from './downloadFile'

export interface DownloadFileAnchorProps
  extends React.AnchorHTMLAttributes<any> {}

export const DownloadFileAnchor: React.FC<DownloadFileAnchorProps> = ({
  href,
  children,
  ...props
}) => {
  return (
    <a
      href={href} // needed to allow tab focusing
      onClick={e => {
        e.preventDefault()
        downloadFile(href ?? '')
      }}
      {...props}
    >
      {children}
    </a>
  )
}
