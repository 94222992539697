import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { Tooltip, Text } from '@sketch/components'
import { useHandleCopyValue } from '@sketch/modules-common'
import {
  Section as BaseSection,
  Header,
  HeaderTitle,
  Separator,
} from '../../components'

const ContentContainer = styled(Text.Div).attrs({
  textStyle: 'copy.secondary.standard.D',
})`
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  border: 1px dashed ${({ theme }) => theme.colors.border.A};
  border-radius: 4px;
  display: block;
  padding: 8px 12px;
  margin: 12px 20px 0;
  cursor: pointer;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${Header} {
    margin-right: 0;
  }
`

const Section = styled(BaseSection)`
  .CopyToClipboard {
    visibility: hidden;
  }
  &:hover .CopyToClipboard {
    visibility: visible;
  }
`

interface ContentProps {
  children: string
  style?: CSSProperties
}

export const TextContent: React.FC<ContentProps> = ({ children, ...props }) => {
  const {
    handleCopyValue,
    copyTooltipText,
    handleEnterCopiableArea,
    handleLeaveCopiableArea,
    copyTooltipVisible,
  } = useHandleCopyValue(children ?? '')

  const handleMouseEnterLabel = () =>
    !children ? null : handleEnterCopiableArea()
  const handleMouseLeaveLabel = () =>
    !children ? null : handleLeaveCopiableArea()

  return (
    <>
      <Separator />
      <Section data-testid="inspector-sidebar-content">
        <HeaderWrapper>
          <Header copyValue={children}>
            <HeaderTitle>Content</HeaderTitle>
          </Header>
        </HeaderWrapper>
        <ContentContainer
          data-testid="content-container"
          {...props}
          onClick={handleCopyValue}
          onMouseEnter={handleMouseEnterLabel}
          onMouseLeave={handleMouseLeaveLabel}
        >
          <Tooltip
            placement="left"
            spacing="10px"
            visible={copyTooltipVisible}
            content={copyTooltipText}
          >
            {children.split(/\r?\n/).map((text, index) => (
              <span key={index}>
                {text}
                <br />
              </span>
            ))}
          </Tooltip>
        </ContentContainer>
      </Section>
    </>
  )
}
