import styled from 'styled-components'

import { ReactComponent as ExclamationIconRaw } from '@sketch/icons/exclamation-circle-filled-12'

export const ExclamationIcon = styled(ExclamationIconRaw)`
  width: 12px;
  height: 12px;
  margin-right: 8px;
  color: ${({ theme }) => theme.colors.state.negative.B};

  /* Prevent the flex from shrinking the icon */
  flex-shrink: 0;

  /* Fix the line-height (1.5) and text difference (13px) */
  margin-top: 4px;
`

export const ScheduleDisclaimer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
`
