import React from 'react'

import { Dropdown } from '@sketch/components'

// This is a valid use case to narrow down Component type
// eslint-disable-next-line no-restricted-imports
import { NotificationSubscriptionStatus } from '@sketch/gql-types/expansive'

interface NotificationStatusDropdownProps {
  type: 'share' | 'artboard'
  notificationStatus: NotificationSubscriptionStatus
  onNotificationChange: (notificationStatus: any) => void
}

const NOTIFICATION_STATUS_COPY = {
  share: {
    action: {
      OFF: 'Unfollow Document',
      PARTIAL: 'Follow Entire Document',
    },
  },
  artboard: {
    action: {
      OFF: 'Unfollow Artboard',
      PARTIAL: 'Follow All Artboards',
    },
  },
}

const NotificationStatusDropdown = (props: NotificationStatusDropdownProps) => {
  const { onNotificationChange, type } = props
  const copy = NOTIFICATION_STATUS_COPY[type]

  return (
    <>
      <Dropdown.Item onClick={() => onNotificationChange('ON')}>
        {copy.action.PARTIAL}
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => onNotificationChange('OFF')}
        intent="negative"
      >
        {copy.action.OFF}
      </Dropdown.Item>
    </>
  )
}

export default NotificationStatusDropdown
