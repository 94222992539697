import { IS_EMBEDDED } from '@sketch/constants'

import { useUserSignedIn } from '@sketch/modules-common'
import {
  useGetWorkspaceQuery,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

/**
 * Handles the logic & queries related to the left sidebar showing
 * workspaces and projects in the DocumentView, DetailView and PageCanvasView.
 * The navbar is only shown for signed in users and when the document is not embedded,
 * so data is only requested if needed.
 */
export function useShareWorkspaceSidebar(workspaceIdentifier: string) {
  const isSignedIn = useUserSignedIn()
  const { data: workspaceData } = useGetWorkspaceQuery({
    variables: { identifier: workspaceIdentifier },
    skip: !isSignedIn,
  })
  const workspace = workspaceData?.workspace as WorkspaceMinimalFragment

  const shouldRenderWorkspaceSidebar = !IS_EMBEDDED && isSignedIn && !!workspace

  return {
    shouldRenderWorkspaceSidebar,
    workspace,
  }
}
