import { useEffect, useRef, useState } from 'react'
import { ObservableQueryFields, useApolloClient } from 'react-apollo'
import { NetworkStatus } from 'apollo-client'
import isEqual from 'lodash.isequal'
import { dataIdFromObject } from '@sketch/graphql-cache'

import { handleFetchMore } from '@sketch/components'
import { useLatestShareVersionSubscribe } from '../ShareVersionContext'

import {
  GetVersionHistoryDocument,
  GetVersionHistoryQuery,
  GetVersionHistoryQueryVariables,
  useGetVersionHistoryQuery,
} from '@sketch/gql-types'
import { handleDocumentUploadEvent } from '../operations/useVersionUploadComplete/handleDocumentUploadEvent'

const ENTRIES_PATH = ['share', 'versionHistory', 'entries']
const AFTER_PATH = ['share', 'versionHistory', 'meta', 'after']

const createHandleFetchLatest = (
  fetchMore: ObservableQueryFields<any, any>['fetchMore']
) =>
  handleFetchMore(fetchMore, ENTRIES_PATH, {
    dataIdFromObject,
    fetchLatest: true,
    preserveAfter: true,
    afterPath: AFTER_PATH,
  })

const useGetShareVersions = (shareIdentifier: string) => {
  const apolloClient = useApolloClient()

  const query = useGetVersionHistoryQuery({
    variables: { shareIdentifier },
    shouldInvalidatePrevious: (...args) => !isEqual(...args),
  })

  const [initialNetworkStatus] = useState(query.networkStatus)

  /* Make sure that the loadLatestHandler is always updated, but is only called once */
  const loadLatestHandler = useRef(createHandleFetchLatest(query.fetchMore))

  useEffect(() => {
    loadLatestHandler.current = createHandleFetchLatest(query.fetchMore)
  }, [query.fetchMore])

  useEffect(() => {
    /* Call the "loadLatestHandler" when mounted to refresh the list */
    /* But only when the request is already cached initially */
    initialNetworkStatus === NetworkStatus.ready && loadLatestHandler.current()
  }, [initialNetworkStatus])

  useLatestShareVersionSubscribe({
    shareID: shareIdentifier,
    onVersionUpdate: async () => {
      // Query the latest version history
      const newVersionData = await apolloClient.query<
        GetVersionHistoryQuery,
        GetVersionHistoryQueryVariables
      >({
        query: GetVersionHistoryDocument,
        variables: { shareIdentifier },
        fetchPolicy: 'no-cache',
      })

      // This will merge the new version history with the existing one
      // OR write a single version to the cache (VIEWERS and GUESTS)
      handleDocumentUploadEvent(
        apolloClient,
        newVersionData?.data?.share || undefined
      )
    },
  })

  return query
}

export default useGetShareVersions
