import React, { useState, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Placement } from '@popperjs/core'
import { InView } from 'react-intersection-observer'
import { Modifier } from 'react-popper'

import { noop } from '@sketch/utils'
import { Popover, PopoverChildrenProps } from '../Popover'

interface DropdownStateProps {
  contentRef?: any
  onToggle: (visible: boolean) => void
}

interface DropdownStateReturn {
  visible: boolean
  show: () => void
  hide: () => void
  toggleDropdown: () => void
}

interface DropdownState {
  (props: DropdownStateProps): DropdownStateReturn
}

export const useDropdownState: DropdownState = ({ onToggle = noop }) => {
  const [visible, setVisible] = useState(false)

  const show = useCallback(() => {
    if (!visible) {
      onToggle(true)
      setVisible(true)
    }
  }, [onToggle, visible])

  const hide = useCallback(() => {
    if (visible) {
      onToggle(false)
      setVisible(false)
    }
  }, [onToggle, visible])

  const toggleDropdown = useCallback(() => {
    visible ? hide() : show()
  }, [hide, show, visible])

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') hide()
    }

    window.addEventListener('keydown', handleEscape)
    return () => window.removeEventListener('keydown', handleEscape)
  })

  return { visible, show, hide, toggleDropdown }
}

export interface StatelessDropdownProps {
  children: React.ReactNode
  toggleDropdown: () => void
  className?: string
  visible?: boolean
  minWidth?: string | number
  maxWidth?: string | number
  toggle?: PopoverChildrenProps
  style?: React.CSSProperties
  contentPadding?: string
  placement?: Placement
  modifiers?: Modifier<any>[]
  contentStyle?: React.CSSProperties
  spacing?: string // space between the trigger and the dropdown content
  onClickOutside: (e: MouseEvent) => void
  usePortal?: boolean
  disableFlip?: boolean
}

export const StatelessDropdown = ({
  style = {},
  minWidth = '160px',
  maxWidth = '352px',
  contentPadding = '0.5rem 0',
  spacing,
  children,
  toggleDropdown,
  toggle,
  visible,
  className,
  onClickOutside,
  usePortal = false,
  contentStyle,
  ...props
}: StatelessDropdownProps) => {
  const handleObservableChange = (inView: boolean) => {
    if (visible && !inView) {
      toggleDropdown()
    }
  }

  return (
    /**
     * We use this InView intersection observer component to automatically close
     * the dropdown when this one gets hidden outside the viewport.
     * (This can happen when scrolling the page).
     */
    <InView
      // Close dropdown when more than 25% of it is hidden (i.e. outside of the viewport).
      threshold={0.75}
      onChange={handleObservableChange}
      className={className}
    >
      {({ ref }) => (
        <Popover
          popup={
            <div ref={ref} style={{ minWidth, maxWidth }}>
              {children}
            </div>
          }
          spacing={spacing}
          style={style}
          contentPadding={contentPadding}
          onClickOutside={onClickOutside}
          usePortal={usePortal}
          contentStyle={contentStyle}
          onClick={(e: Event) => {
            e.stopPropagation()
            e.preventDefault()
            toggleDropdown()
          }}
          className={className}
          visible={visible}
          {...props}
        >
          {toggle}
        </Popover>
      )}
    </InView>
  )
}

export interface DropdownProps
  extends Pick<
    StatelessDropdownProps,
    | 'className'
    | 'minWidth'
    | 'maxWidth'
    | 'usePortal'
    | 'spacing'
    | 'contentPadding'
    | 'style'
    | 'contentStyle'
    | 'placement'
    | 'modifiers'
  > {
  children: React.ReactNode
  onToggle?: (visible: boolean) => void
  toggle: React.ReactNode | ((visible: boolean) => React.ReactNode)
  disabled?: boolean
  hideOnClick?: boolean
  visible?: boolean
  disableFlip?: boolean
}

/**
 * The Dropdown is used in place of a Select when we want to have more customizeable options.
 *
 * It can have different placements, you can check all of them via the Properties table controls.
 */
export const Dropdown = ({
  children,
  toggle,
  style = {},
  minWidth = '160px',
  maxWidth = '352px',
  onToggle = noop,
  disabled = false,
  usePortal = false,
  hideOnClick = true,
  ...props
}: DropdownProps) => {
  const { visible, hide, toggleDropdown } = useDropdownState({
    onToggle,
  })

  const optionsRef = useRef<HTMLUListElement>(null)
  const tabPressCounter = useRef(0)
  const previouslyFocused = useRef() as React.MutableRefObject<HTMLElement>

  const findFirstElement = useCallback(
    (element: Element): HTMLElement | null => {
      return element?.querySelector('a:not(:disabled), button:not(:disabled)')
    },
    []
  )

  useEffect(() => {
    const refInstance = optionsRef?.current

    if (!refInstance) {
      return
    }

    // after opening a dropdown and pressing tab, force focus
    // on first focusable element of the dropdown options
    const handlePressTab = (event: KeyboardEvent) => {
      if (event.key === 'Tab' && visible) {
        if (!previouslyFocused?.current && document.activeElement) {
          previouslyFocused.current = document.activeElement as HTMLElement
        }

        event.preventDefault()

        const optionsLength = refInstance ? refInstance?.childElementCount : 0

        // skip the separator
        if (
          refInstance?.children[tabPressCounter.current % optionsLength]
            .localName === 'div'
        ) {
          tabPressCounter.current = tabPressCounter.current + 1
        }

        // loop through the dropdown options
        findFirstElement(
          refInstance?.children[tabPressCounter.current % optionsLength]
        )?.focus()

        tabPressCounter.current = tabPressCounter.current + 1
      }
    }

    // only use the event listener if the dropdown is globally positioned
    if (usePortal) {
      document.addEventListener('keydown', handlePressTab)
    }

    return () => {
      previouslyFocused?.current?.focus()

      return document.removeEventListener('keydown', handlePressTab)
    }
  }, [optionsRef, visible, usePortal, previouslyFocused, findFirstElement])

  const isVisible = !disabled && visible

  return (
    <StatelessDropdown
      visible={isVisible}
      toggleDropdown={toggleDropdown}
      toggle={typeof toggle === 'function' ? toggle(isVisible) : toggle}
      style={style}
      minWidth={minWidth}
      maxWidth={maxWidth}
      onClickOutside={hide}
      usePortal={usePortal}
      {...props}
    >
      <DropdownOptionsList
        ref={optionsRef}
        onClick={hideOnClick ? hide : e => e.preventDefault()}
        data-testid="dropdown-options"
      >
        {children}
      </DropdownOptionsList>
    </StatelessDropdown>
  )
}

const DropdownOptionsList = styled.ul`
  padding: 0;
  margin: 0;
`
