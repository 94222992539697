import React from 'react'
import { pluralize } from '@sketch/components'

interface PersonalWorkspaceContentsCountProps {
  nonLibrarySharesCount: number
  librariesCount: number
}

export const PersonalWorkspaceContentsCount = ({
  nonLibrarySharesCount,
  librariesCount,
}: PersonalWorkspaceContentsCountProps) => {
  const nonLibrarySharesCountText = `${nonLibrarySharesCount} ${pluralize(
    'Document',
    'Documents',
    nonLibrarySharesCount
  )}`

  const librariesCountText = `${librariesCount} ${pluralize(
    'Library',
    'Libraries',
    librariesCount
  )}`

  if (nonLibrarySharesCount > 0 && librariesCount > 0) {
    return (
      <>
        <b>{nonLibrarySharesCountText}</b> and <b>{librariesCountText}</b>
      </>
    )
  } else if (nonLibrarySharesCount > 0) {
    return <b>{nonLibrarySharesCountText}</b>
  } else {
    return <b>{librariesCountText}</b>
  }
}
