import styled, { css } from 'styled-components'

import { ReactComponent as ShieldIcon } from '@sketch/icons/shield-16'

import { breakpoint } from '@sketch/global-styles'

import { Button, Text, Link } from '@sketch/components'

import { InitialUserFragment } from '@sketch/gql-types'

type StatusType = {
  status: InitialUserFragment['mfaState']
}

export const StatusList = styled.div`
  margin-top: 24px;
`

export const Shield = styled(ShieldIcon)<StatusType>`
  margin-right: 8px;
  width: 16px;
  height: 16px;

  ${({ theme, status }) => {
    if (status === 'ENABLED') {
      return css`
        color: ${theme.colors.state.positive.A};
      `
    }
  }}
`

export const Status = styled(Text.Div).attrs({
  textStyle: 'copy.primary.standard.E',
})`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-bottom: 8px;
`

export const StyledButton = styled(Button)`
  margin-left: 16px;

  ${breakpoint('base', 'sm')`
    margin-left: 0px;
  `}
`

export const StyledLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSizes.E};
  margin-left: 8px;
`
