import React from 'react'
import { useRouteMatch } from 'react-router'

import { isDraftsRoute, isProjectRoute } from '@sketch/modules-common'
import {
  addCollectionToProject,
  createOptimisticCollection,
  removeSharesFromProject,
} from '../../../collections/operations/utils'

import {
  DragAndDroppedShareIds,
  useShareListItemDragDrop,
} from '../../hooks/useShareDragAndDrop'
import { useCollectionSearchArgument } from '../../../collections/hooks'

import { Tooltip } from '@sketch/components'

import { DragWrapper } from './ShareListItemDrag.styles'

import { useApolloClient } from '@sketch/gql-types'

interface ShareListItemDragProps {
  disabled?: boolean
  shareId: string
  projectId?: string
}

const ShareListItemDrag: React.FC<ShareListItemDragProps> = props => {
  const {
    children,
    disabled = false,
    shareId,
    projectId: projectIdentifier,
  } = props
  const search = useCollectionSearchArgument()

  const cache = useApolloClient()
  const { path } = useRouteMatch()

  const isProjectView = isProjectRoute(path)
  const isDraftView = isDraftsRoute(path)

  const isValidViewToCreateCollection = isProjectView || isDraftView

  const handleDroppedShare = ({
    draggedShareId,
    droppedShareId,
  }: DragAndDroppedShareIds) => {
    // we need a valid project id to create a collection by drag and drop
    if (!projectIdentifier) {
      return
    }

    if (draggedShareId === droppedShareId) {
      // If share was dropped on itself, ignore
      return
    }

    if (!isValidViewToCreateCollection) {
      return
    }

    const optimisticCollection = createOptimisticCollection({
      cache,
      shareIds: [draggedShareId, droppedShareId],
    })

    addCollectionToProject({
      cache,
      projectIdentifier,
      search,
      collection: {
        __typename: 'Collection',
        ...optimisticCollection,
      },
    })

    removeSharesFromProject(cache, projectIdentifier, [
      { identifier: draggedShareId },
      { identifier: droppedShareId },
    ])
  }

  const {
    isDraggedOver,
    ...dragDropEvents
  } = useShareListItemDragDrop<HTMLDivElement>({
    disabled,
    shareId,
    onDropped: handleDroppedShare,
  })

  const canCreateCollectionByDragNDrop =
    isValidViewToCreateCollection && !!isDraggedOver

  return (
    <DragWrapper
      data-testid="drag-wrapper"
      $isDraggedOver={canCreateCollectionByDragNDrop}
      {...dragDropEvents}
    >
      <Tooltip
        content="Create a new collection"
        visible={canCreateCollectionByDragNDrop}
        placement="top"
      >
        {children}
      </Tooltip>
    </DragWrapper>
  )
}

export default ShareListItemDrag
