import styled from 'styled-components'
import { breakpoint } from '@sketch/global-styles'

export const Row = styled.tr<{ alignItems?: 'flex-start' | 'center' }>`
  display: flex;
  flex-wrap: nowrap;
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};

  padding: 14px 12px; /* stylelint-disable-line scales/space */
  border-radius: ${({ theme }) => theme.radii.large};

  font-size: ${({ theme }) => theme.fontSizes.E};
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  line-height: 1.4;

  strong {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  ${breakpoint('base', 'sm')`
    flex-direction: column;

    td:not(:last-child) {
     margin-bottom: 8px;
    }
  `}
`

export const HeadingCell = styled.td`
  display: flex;
  min-width: 128px;

  ${breakpoint('base', 'sm')`
    width: 100%;
  `}
`

export const Cell = styled.td`
  display: flex;
  flex: 1 1 auto;
  align-items: flex-start;

  /* When the row has 3 cells the middle one gets the maximum width */
  &:nth-child(3) {
    flex: 0;
  }

  ${breakpoint('base', 'sm')`
    width: 100%;
  `}
`

export const BillingInfoTable = styled.table`
  display: flex;
  flex-direction: column;

  ${Row} {
    &:nth-child(odd) {
      background: ${({ theme }) => theme.colors.background.secondary.B};
    }
  }
`
