import styled from 'styled-components'

import { ButtonUnstyled } from '@sketch/components'

import { ReactComponent as ChevronUpDown } from '@sketch/icons/chevrons-up-down-16'

export const DropdownButton = styled(ButtonUnstyled)`
  display: flex;
  align-items: center;
  padding: 6px 8px; /* stylelint-disable-line scales/space */

  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  font-size: ${({ theme }) => theme.fontSizes.D};
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  border-radius: ${({ theme }) => theme.radii.medium};

  & [role='img'] {
    width: 16px;
    height: 16px;
  }
`

export const Chevron = styled(ChevronUpDown)`
  margin-left: 4px;
`

export const Strong = styled.strong`
  padding-left: 4px;

  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`
