import styled, { css } from 'styled-components'
import {
  ButtonUnstyled,
  WorkspaceLogo as BaseWorkspaceLogo,
} from '@sketch/components'

export const Dropzone = styled.div<{ $isDragActive: boolean }>`
  position: absolute;
  z-index: 1;
  top: -4px;
  left: -4px;
  bottom: -4px;
  right: -4px;

  padding: 4px;
  border: 2px dashed ${({ theme }) => theme.colors.border.A};
  border-radius: ${({ theme }) => theme.radii.xxxlarge};

  ${({ $isDragActive, theme }) =>
    $isDragActive &&
    css`
      border-color: ${theme.colors.sketch.A};
    `}

  cursor: pointer;
  user-select: none;
  * {
    pointer-events: none;
  }
`

export const WorkspaceLogo = styled(BaseWorkspaceLogo)<{ src: string }>`
  ${({ theme, src }) =>
    !src &&
    css`
      background-color: ${theme.colors.background.secondary.C};
    `};
  /* This needs to be white because the background is orange */
  color: white;
  border: none;

  /* Border radius is supposed to be like this by design */
  border-radius: 12px; /* stylelint-disable-line scales/radii */

  ::after {
    border: none;
  }
`

export const WorkspaceLogoDropzoneWrapper = styled.div`
  position: relative;
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: ${({ theme }) => theme.fontSizes.C};
  color: ${({ theme }) => theme.colors.foreground.primary.A};
  background-color: ${({ theme }) => theme.colors.background.primary.C};

  /* Border radius is supposed to be like this by design */
  border-radius: 12px; /* stylelint-disable-line scales/radii */

  transition: opacity ease-in-out 100ms;
`

export const ToggleWrapper = styled(ButtonUnstyled)`
  position: relative;
  display: block;

  ${Overlay} {
    opacity: 0;
  }

  :hover,
  :focus,
  :focus-within {
    ${Overlay} {
      opacity: 1;
    }
  }
`
