import React from 'react'
import { Tooltip } from '@sketch/components'
import { usePrototypeContext } from '../../hooks'
import * as S from './NavbarEnterFullScreenButton.styles'

export function NavbarEnterFullScreenButton() {
  const { enterFullscreen } = usePrototypeContext()
  return (
    <Tooltip
      disableWhenTouchDevice
      placement="bottom"
      content="Enter Fullscreen"
    >
      <S.Button
        data-testid="fullscreen-button"
        onClick={enterFullscreen}
        aria-label="FullScreen"
      >
        <S.Icon />
      </S.Button>
    </Tooltip>
  )
}
