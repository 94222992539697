export enum OperationsSortCriteria {
  DEFAULT = 'Default',
  NAME = 'Name',
  REQUEST_COUNT = 'Request count',
  TOTAL_DURATION = 'Total duration',
  MAX_DURATION = 'Max duration',
  LASt_OP_DURATION = 'Duration',
  DB_QUERY_COUNT = 'DB query count',
  DB_TOTAL_DECODE_TIME = 'DB total decode time',
  DB_TOTAL_QUERY_TIME = 'DB total query time',
  DB_TOTAL_QUEUE_TIME = 'DB total queue time',
  DB_TOTAL_TIME = 'DB total time',
}
