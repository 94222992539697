import styled, { css } from 'styled-components'

import { Skeleton, Text } from '@sketch/components'
import GridPublicationItem from '../GridPublicationItem'

import { breakpoint } from '@sketch/global-styles'

const documentStyles = css`
  flex: 0 1 252px;

  :not(:last-child) {
    margin-right: 44px;
  }

  ${breakpoint('base', 'xs')`
      flex: 1;

      :not(:last-child) {
        margin-right: 0;
        margin-bottom: 28px;
      }
   `}
`

export const StyledSkeleton = styled(Skeleton)`
  display: flex;
  aspect-ratio: 1/1;
`

export const Container = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  ${breakpoint('base', 'xs')`
      flex-direction: column;
  `}

  ${StyledSkeleton} {
    ${documentStyles}
  }
`

export const Title = styled(Text.H1).attrs({ textStyle: 'header.primary.G' })`
  margin: 20px 0 32px 0;

  ${breakpoint('base', 'xs')`
      margin: 32px 0 15px 0;
  `}
`

export const StyledGridPublicationItem = styled(GridPublicationItem)`
  ${documentStyles}
`
