import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useToast } from '@sketch/toasts'
import {
  useFlag,
  useAnalytics,
  IndexLayoutOldTitle,
  IndexLayoutOldSubtitle,
  routes,
  IndexLayoutExtraProps,
  IndexLayoutContent,
  useUserProfile,
  useSignOut,
} from '@sketch/modules-common'

import {
  Wrapper,
  TextInbox as Text,
  StyledLinkButton,
  DoThisLaterButton,
} from './CheckYourInboxView.styles'

import { useResendVerificationEmailMutation } from '@sketch/gql-types'

export interface CheckYourInboxViewProps extends IndexLayoutExtraProps {}

export const CheckYourInboxView = (props: CheckYourInboxViewProps) => {
  const history = useHistory()
  const { showToast } = useToast()
  const { trackEvent } = useAnalytics()

  // TODO: Remove usage of mandatory-email-verification when release to production
  // https://github.com/sketch-hq/Cloud/issues/11277
  const isMandatoryEmailVerification = useFlag('mandatory-email-verification')
  const { data } = useUserProfile()

  // Redirect already verified users to the entry route
  useEffect(() => {
    if (!isMandatoryEmailVerification) {
      if (data?.me.isVerified) history.push(routes.ENTRY.create({}))
    }
  }, [data, history, isMandatoryEmailVerification])

  // Operations
  const [resendVerificationEmail] = useResendVerificationEmailMutation({
    redirectErrors: true,
    onCompleted: () => showToast(`An email has been sent to ${data?.me.email}`),
    onError: 'show-toast',
  })

  const signOut = useSignOut({
    location: routes.SIGN_UP.create({
      query: {
        email: encodeURIComponent(data?.me.email ?? ''),
      },
    }),
    reason: 'Mistyped email button',
  })

  return (
    <IndexLayoutContent center paddingHorizontal>
      <Wrapper>
        <IndexLayoutOldTitle>Check Your Inbox</IndexLayoutOldTitle>
        <IndexLayoutOldSubtitle>
          A verification email is on its way to{' '}
          <strong>{data?.me.email}</strong>.
          <br />
          Confirm your email to start using Sketch.
        </IndexLayoutOldSubtitle>
        <Text>
          <span>
            Can&apos;t find the email?{' '}
            <StyledLinkButton
              onClick={() => {
                trackEvent('ONBOARDING - resend verify email', {
                  source: 'check your inbox page',
                })
                resendVerificationEmail()
              }}
            >
              Resend
            </StyledLinkButton>{' '}
            the verification email
          </span>
        </Text>
        <Text>
          <span>
            Mistyped your email?&nbsp;
            <StyledLinkButton variant="secondary" onClick={signOut}>
              Sign out
            </StyledLinkButton>
            &nbsp;and try again
          </span>
        </Text>
        {!isMandatoryEmailVerification && (
          <DoThisLaterButton
            to={routes.WORKSPACE_AGNOSTIC_DISCOVER.create({})}
            variant="primary-untinted"
            onClick={() => {
              trackEvent('ONBOARDING - skip email verification')
            }}
          >
            Do This Later
          </DoThisLaterButton>
        )}
      </Wrapper>
    </IndexLayoutContent>
  )
}
