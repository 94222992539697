import React from 'react'
import { EmptyHeader } from '../EmptyHeader'
import { EmbeddedErrorPage } from '../Embedding'
import styled from 'styled-components'
import {
  Button,
  IsEmbedded,
  NotEmbedded,
  ErrorMessage,
  CenterContent,
} from '@sketch/components'
import * as Sentry from '@sentry/browser'
import { ApolloError } from 'apollo-client'

const StyledErrorMessage = styled(ErrorMessage.Generic)`
  flex: 1 0 auto;
  max-width: 500px;
  margin: auto;
`

interface GenericErrorViewProps {
  error?: ApolloError | string
  errorTitle?: string
  onClick?(): void

  /**
   * Property which is used during transitional period
   * from rendering Views first, Layouts second
   * to rendering Layouts first, Views second
   *
   * see more: https://github.com/sketch-hq/sketch-rfc/pull/88
   *
   * TODO: remove this property once all PrivateRoute components will be rendered inside a view.
   * TODO: remove "View" keyword from this component
   * prerequisites:
   *   - https://github.com/sketch-hq/Cloud/issues/16943
   *   - https://github.com/sketch-hq/Cloud/issues/16941
   */
  isInLayout?: boolean
}

export const GenericErrorView = ({
  error,
  errorTitle,
  onClick,
  isInLayout,
}: GenericErrorViewProps) => {
  if (typeof error === 'string') {
    Sentry.captureException(error)
  } else if (error?.graphQLErrors) {
    const versionForbiddenError = error.graphQLErrors.every(e =>
      [
        'USER_CANT_VIEW_OLD_SHARE_VERSIONS',
        'USER_CANT_VIEW_VERSION_HISTORY',
        'USER_CANT_VIEW_SHARE_VERSION',
      ].includes(e.extensions?.reason)
    )

    const authError = error.graphQLErrors.every(e =>
      [
        'ACCESS_TOKEN_EXPIRED',
        'INVALID_ACCESS_TOKEN',
        'USER_NOT_LOGGED_IN',
        'TOKEN_EXPIRED',
        'NO_TOKEN',
      ].includes(e.extensions?.reason)
    )

    if (!versionForbiddenError && !authError) {
      Sentry.captureException(error)
    }
  }

  const onClickHandler = onClick || (() => window.location.reload())

  return (
    <>
      <NotEmbedded>
        <CenterContent>
          {isInLayout ? null : <EmptyHeader />}
          <StyledErrorMessage
            title={errorTitle || 'There was an error loading this information'}
            extra={
              <Button variant="primary" onClick={onClickHandler}>
                Refresh the page to try again
              </Button>
            }
          />
        </CenterContent>
      </NotEmbedded>
      <IsEmbedded>
        <EmbeddedErrorPage for="unknown" />
      </IsEmbedded>
    </>
  )
}
