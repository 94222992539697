import styled, { css } from 'styled-components'
import { WorkspaceLogo } from '@sketch/components'
import { ReactComponent as Hamburger } from '@sketch/icons/list-lines-24'

const badgeStyles = css`
  cursor: pointer;
  margin: 0 16px;
  flex: none;
`

const StyledWorkspaceIcon = styled(WorkspaceLogo.Icon)`
  ${badgeStyles};
`

const StyledBadge = styled(WorkspaceLogo)`
  ${badgeStyles};
  /** We need to compensate some margin left to align perfectly with the
  Workspace Selector */
  margin-left: 16px; /* stylelint-disable-line scales/space */
`

const StyledHamburgerIcon = styled(Hamburger)`
  ${badgeStyles};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  width: 24px;
  height: 24px;
`

export { StyledBadge, StyledWorkspaceIcon, StyledHamburgerIcon }
