import React, {
  FC,
  InputHTMLAttributes,
  RefObject,
  useLayoutEffect,
  useRef,
} from 'react'
import { useField, useFormikContext } from 'formik'
import { useKey } from 'react-use'

import {
  Container,
  InputContainer,
  IconContainer,
  PencilIcon,
  CrossIcon,
  ErrorMessage,
} from './Common.styles'
import { InputStyled, TextAreaStyled } from './Input.styles'

const MAX_BIOGRAPHY_LENGTH = 160

export interface InputProps
  extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  width?: string
  name: string
}

export interface IconProps {
  handleClear: () => void
  handleEdit: () => void
  isEmpty?: boolean
}

const Icon: React.FC<IconProps> = ({ handleClear, handleEdit, isEmpty }) => {
  if (!isEmpty) {
    return (
      <IconContainer onClick={handleClear}>
        <CrossIcon />
      </IconContainer>
    )
  } else {
    return (
      <IconContainer onClick={handleEdit}>
        <PencilIcon />
      </IconContainer>
    )
  }
}

export const Input: FC<InputProps> = ({
  width,
  onChange,
  className,
  name,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null)

  const [{ value }, { error, touched }, { setValue }] = useField(name)
  const { submitForm, handleChange } = useFormikContext()

  const isDescriptionInput = name === 'description'

  // When pressing enter on an Input we should blur.
  // The form will be submitted follwing the onChange handler
  useKey(
    'Enter',
    () => {
      // Allow user to add new lines in the bio so we ignore the enter here
      if (isDescriptionInput) return

      inputRef.current?.blur()
    },
    {},
    [isDescriptionInput]
  )

  // Compute the textarea height so it grows as the user types
  useLayoutEffect(() => {
    if (!isDescriptionInput) return

    const textArea = inputRef?.current

    if (textArea) {
      const minHeight = 4 // makes the empty field height 46px to match the other fields
      textArea.style.height = `${minHeight}px` // reset height

      const scrollHeight = textArea.scrollHeight
      const height = `${scrollHeight + minHeight}px`

      textArea.style.height = height
    }
  }, [isDescriptionInput, value])

  const handleFormSubmit = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChange(event)
    submitForm()
  }

  const handleClear = () => {
    setValue('')
    submitForm()
  }

  const handleEdit = () => {
    inputRef.current?.focus()
  }

  return (
    <Container width={width} className={className}>
      <InputContainer>
        {isDescriptionInput ? (
          <TextAreaStyled
            {...props}
            ref={inputRef as RefObject<HTMLTextAreaElement>}
            name={name}
            value={value}
            onChange={handleFormSubmit}
            maxLength={MAX_BIOGRAPHY_LENGTH}
            autoComplete="off"
          />
        ) : (
          <InputStyled
            {...props}
            ref={inputRef as RefObject<HTMLInputElement>}
            name={name}
            value={value}
            onChange={handleFormSubmit}
            autoComplete="off"
          />
        )}
        <Icon
          handleClear={handleClear}
          handleEdit={handleEdit}
          isEmpty={value.length === 0}
        />
      </InputContainer>
      {error && touched && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  )
}
