import styled from 'styled-components'
import BaseAnnotationListWrapper from '../AnnotationListWrapper'

export const AnnotationListWrapper = styled(BaseAnnotationListWrapper)`
  overflow-y: auto;
  /**  
  * Safari was showing horizontal scroll due to negative
  * margins on AnnotationFirstCommentSeparator. We make sure
  * the horizontal scroll never shows.
  */
  overflow-x: hidden;
`
