import React, { useCallback, useEffect, useRef } from 'react'
import { Observable, useObservable } from '../observable'

function arraysHaveSameValues<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) {
    return false
  }

  // Sort both arrays
  const sortedArr1 = arr1.slice().sort()
  const sortedArr2 = arr2.slice().sort()

  // Compare each element using the `every` method
  return sortedArr1.every((value, index) => value === sortedArr2[index])
}

const isShallowEqual = <T extends {}>(props1: T, props2: T) => {
  if (props1 === props2) return true

  const props1Keys = Object.keys(props1) as (keyof T)[]
  const props2Keys = Object.keys(props2) as (keyof T)[]

  if (!arraysHaveSameValues(props1Keys, props2Keys)) {
    return false
  }

  for (const key of props1Keys) {
    if (props1[key] !== props2[key]) {
      return false
    }
  }

  return true
}

const LayoutOverrideProps = <T extends {}>(
  props: T & { useOverrideLayoutProps: (props: T) => void }
) => {
  const { useOverrideLayoutProps, ...rest } = props

  useOverrideLayoutProps(rest as any)

  return null
}

export const useLayoutOverrideProps = <T extends {}>() => {
  const propsOverrideState = useRef(new Observable<T>({} as T))

  // trigger rerendering on value change
  useObservable(propsOverrideState.current)

  const useOverrideLayoutProps = useCallback((props: T) => {
    /**
     * We are creating useOverrideLayoutProps hook on the fly.
     * So standard linting is a bit off and we have to be extra careful
     * ourselves to not introduce common bugs
     */
    /* eslint-disable react-hooks/rules-of-hooks */
    const observable = propsOverrideState.current
    const savedProps = observable.state()

    useEffect(() => {
      return () => {
        const observable = propsOverrideState.current
        observable.setState({} as T)
      }
    }, [])

    // we are wrapping `observable.setState` with an useEffect hook
    // so that `observable.setState` would be executed during correct render phase
    // see more: https://github.com/sketch-hq/cloud-frontend/pull/6897#pullrequestreview-1417074287
    useEffect(() => {
      if (!isShallowEqual(props, savedProps)) {
        observable.setState(props)
      }

      /* eslint-enable*/
      /* Do not add dependencies array here to this hook */
    })
  }, [])

  const getOverriddenProps = useCallback(<R extends T>(originalProps: R): R => {
    const overrides = propsOverrideState.current.state()
    return { ...originalProps, ...overrides }
  }, [])

  const OverrideLayoutProps = useCallback(
    (props: T) => (
      <LayoutOverrideProps
        useOverrideLayoutProps={useOverrideLayoutProps}
        {...props}
      />
    ),
    [useOverrideLayoutProps]
  )

  return { useOverrideLayoutProps, getOverriddenProps, OverrideLayoutProps }
}
