import styled from 'styled-components'
import { ListHeader } from '@sketch/components'

export const Header = styled(ListHeader)`
  padding-left: 0px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  &:hover {
    color: unset;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex: none;
  align-items: center;
  top: 0px;

  background-color: ${({ theme }) => theme.colors.background.secondary.A};

  position: sticky;
  padding: 7px 8px 8px 18px; /* stylelint-disable-line scales/space */
  padding-left: 18px; /* stylelint-disable-line scales/space */
  margin: 0 -8px 8px; /* stylelint-disable-line scales/space */
  z-index: ${({ theme }) => theme.zIndex[1]};
  min-height: 32px;
  transition: 0.1s border-bottom linear;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`

export const ActionWrapper = styled.div`
  margin-left: auto;
  display: flex;

  /* Fine tuning
     Ticket: https://github.com/sketch-hq/cloud-frontend/pull/5377 */
  margin-right: -1px; /* stylelint-disable-line scales/space */
`
