import React from 'react'
import {
  Button,
  Modal,
  ApolloError,
  ModalInjectedProps,
} from '@sketch/components'
import { useShareLeaveMutation } from '@sketch/gql-types'
import {
  routes,
  deleteEntityFromCache,
  removeFromPaginated,
  useUserProfile,
} from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'
import { withRouter, RouteComponentProps, matchPath } from 'react-router-dom'

type InjectedProps = RouteComponentProps & ModalInjectedProps

interface LeaveShareModalProps extends InjectedProps {
  shareName: string
  shareIdentifier: string
}

const LeaveShareModal: React.FC<LeaveShareModalProps> = props => {
  const { shareName, shareIdentifier, location, history, hideModal } = props

  const { showToast } = useToast()

  const { data } = useUserProfile()
  const user = data!.me!

  const [leaveShare, { error, loading }] = useShareLeaveMutation({
    redirectErrors: true,
    variables: { identifier: shareIdentifier },
    onCompleted: data => {
      const { share } = data?.shareLeave || {}
      showToast(`You left “${shareName}”`)

      // Redirect the user to the parent page of the app if he leaves a document
      const isInsideDocumentPage = matchPath(location.pathname, {
        path: routes.SHARE_VIEW.template(),
        exact: false,
      })

      const { identifier, userIsMember } = share?.workspace || {}

      if (!userIsMember) {
        // User doesn't have more available documents on this workspace, so it goes to the next workspace
        history.push(routes.ENTRY.create({}))
      } else if (userIsMember && isInsideDocumentPage) {
        // User is still a guest meaning he still has documents shared with him on this workspace so he can safely go there
        history.push(
          routes.WORKSPACE_SHARES.create({ workspaceId: identifier! })
        )
      }

      hideModal()
    },
    onError: 'show-toast',
    update: (cache, { data }) => {
      const { share } = data?.shareLeave || {}

      const userIsMember = share?.workspace?.userIsMember
      const workspaceId = share?.workspace?.identifier

      if (userIsMember === false) {
        // Remove everything that is related with the Workspace
        deleteEntityFromCache(cache, {
          __typename: 'Workspace',
          identifier: workspaceId,
        })
        removeFromPaginated(cache, user, {
          __typename: 'Workspace',
          identifier: workspaceId,
        })
      } else {
        // Remove from the workspace all documents/libraries list
        removeFromPaginated(cache, share!, {
          __typename: 'Workspace',
          identifier: workspaceId,
        })
      }
    },
  })

  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>
        <span>Leave Document?</span>
      </Modal.Header>
      <Modal.Body>
        <p>If you leave, you’ll no longer have access to “{shareName}”</p>
        {error && <ApolloError error={error} />}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} onClick={hideModal}>
          Cancel
        </Button>
        <Button
          variant="negative"
          loading={loading}
          onClick={() => leaveShare()}
        >
          Leave Document
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default withRouter(LeaveShareModal)
