let iframe: HTMLIFrameElement

export const downloadFile = (url: string) => {
  if (!iframe) {
    iframe = document.createElement('iframe')
    iframe.setAttribute('data-testid', 'automatic-download')
    iframe.setAttribute('style', 'display: none;')
    document.body.appendChild(iframe)
  }

  iframe.setAttribute('src', url)
}
