import styled from 'styled-components'
import { Text } from '@sketch/components'

export const UserChipContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0 16px;
`

export const Label = styled(Text.Span).attrs({
  textStyle: 'copy.tertiary.standard.E',
})`
  margin-bottom: 6px; /* stylelint-disable-line scales/space */
`

export const LoadingErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0 16px;
`
