import React from 'react'
import styled from 'styled-components'

import { capitalize } from '@sketch/utils'
import { getMemberName } from '../../utils/members'

import { useToast } from '@sketch/toasts'

import {
  Button,
  Banner,
  Modal,
  useModalContext,
  ModalInjectedProps,
} from '@sketch/components'
import { ErrorHandler } from '@sketch/tracing'

import {
  UpdateWorkspaceMemberMutationVariables,
  useUpdateWorkspaceMemberMutation,
  WorkspaceMembershipFragment,
  WorkspaceMinimalFragment,
  GetWorkspaceMembershipsDocument,
} from '@sketch/gql-types'
import { useGetHasDraftsProject } from '@sketch/modules-common'

const Description = styled.p`
  overflow-wrap: anywhere;
`

export const StyledBanner = styled(Banner)`
  margin-top: 16px;
`

interface ChangeRoleConfirmationModalProps extends ModalInjectedProps {
  variant: ExtractSafe<
    WorkspaceMinimalFragment['userRole'],
    'ADMIN' | 'FINANCE'
  >
  workspaceId: string
  workspaceName: string
  membershipId: string
  memberName?: string
  userEmail: string
  role: UpdateWorkspaceMemberMutationVariables['input']['role']
}

export const ChangeRoleConfirmationModal: React.FC<ChangeRoleConfirmationModalProps> = ({
  variant,
  workspaceId,
  membershipId,
  memberName,
  workspaceName,
  userEmail,
  role,
}) => {
  const { showToast } = useToast()
  const { hideModal } = useModalContext()

  const hasDraftsProject = useGetHasDraftsProject(workspaceId)

  const [updateMember, { loading }] = useUpdateWorkspaceMemberMutation({
    onError: ({ message }) => {
      showToast(message, 'negative')
      hideModal()
    },
    onCompleted: data => {
      hideModal()
      const { membership } = data?.updateWorkspaceMember || {}

      // Apollo might return empty object as data if Cache Miss would happen
      if (!membership) {
        ErrorHandler.shouldNeverHappen(
          `updateWorkspaceMember should return valid data`
        )
        return
      }

      const { role } = membership!
      showToast(
        `${getMemberName(membership)} access changed to ${capitalize(role!)}`
      )
    },
    refetchQueries: [
      {
        query: GetWorkspaceMembershipsDocument,
        variables: {
          workspaceId,
        },
      },
    ],
  })

  const handleConfirm = () => {
    let newRole: WorkspaceMembershipFragment['role']
    if (variant === 'ADMIN') {
      newRole = role === 'ADMIN' ? 'MEMBER' : 'ADMIN'
    } else {
      newRole = role === 'FINANCE' ? 'MEMBER' : 'FINANCE'
    }

    updateMember({
      variables: {
        input: {
          membershipIdentifier: membershipId,
          role: newRole,
        },
      },
    })
  }

  const isFinance = role === 'FINANCE'

  const financeContent = isFinance ? (
    <>
      If you remove <strong>{memberName ? memberName : userEmail}</strong> as
      Finance they will no longer be able to manage billing and subscriptions —
      but they will get access to documents in the &ldquo;
      <strong>{workspaceName}</strong>&rdquo; Workspace again.
    </>
  ) : (
    <>
      Assign the Finance role to{' '}
      <strong>{memberName ? memberName : userEmail}</strong> allows them to
      manage billing, Editor seats and subscription settings in &ldquo;
      <strong>{workspaceName}</strong>&rdquo;.
      {hasDraftsProject ? (
        <StyledBanner type="warning" showIcon={false}>
          <strong>{memberName ? memberName : userEmail}</strong> will no longer
          have access to documents. If they are an Editor, they will become a
          Viewer. Their Drafts will become a Project that only Admins can
          access.
        </StyledBanner>
      ) : (
        <StyledBanner type="warning" showIcon={false}>
          <strong>{memberName ? memberName : userEmail}</strong> will no longer
          have access to documents. If they are an Editor, they will become a
          Viewer.
        </StyledBanner>
      )}
    </>
  )

  let title
  let body
  if (variant === 'ADMIN') {
    title = role === 'ADMIN' ? 'Remove as Admin?' : 'Assign as Admin?'
    body = (
      <>
        {role === 'ADMIN' ? (
          <>
            If you remove <strong>{memberName ? memberName : userEmail}</strong>{' '}
            as an Admin they will no longer be able to add or remove Members —
            or manage the &ldquo;<strong>{workspaceName}</strong>&rdquo;
            Workspace anymore.
          </>
        ) : (
          <>
            If you assign <strong>{memberName ? memberName : userEmail}</strong>{' '}
            as an Admin they will be able to add and remove Members — and manage
            the &ldquo;<strong>{workspaceName}</strong>&rdquo; Workspace.
          </>
        )}
      </>
    )
  } else {
    title = `${isFinance ? 'Remove' : 'Assign'} Finance role?`
    body = financeContent
  }

  const confirmButton =
    variant === 'ADMIN'
      ? role === 'ADMIN'
        ? 'Remove as Admin'
        : 'Assign as Admin'
      : isFinance
      ? 'Remove as Finance'
      : 'Assign as Finance'

  return (
    <Modal title={title} onCancel={hideModal}>
      <Modal.Body>
        <Description>{body}</Description>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} onClick={hideModal}>
          Cancel
        </Button>
        <Button
          data-testid="modal-action-button"
          onClick={handleConfirm}
          loading={loading}
          disabled={loading}
          variant="primary"
        >
          {confirmButton}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
