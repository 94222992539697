import { ConfirmationDialog, ModalInjectedProps } from '@sketch/components'
import React from 'react'
import ShareAccessList from '../ShareAccessList'
import { useShareRestoreWithUi } from './useShareRestoreWithUi'
import { ProjectFragment } from '@sketch/gql-types'
import { Separator } from './RestoreShareModal.styles'
import type { TransferDocumentInfo } from '../../../projects/modals/MoveShareToProject/types'

export interface RestoreShareModalProps extends ModalInjectedProps {
  name: string
  shareIdentifier: string
  isInDraftsProject?: boolean
  project?: Pick<ProjectFragment, 'workspaceAccessLevel' | 'name'> | null
}

const PLACEHOLDER_MEMBERSHIP_ITEMS = 1

const getRestoreShareCopy = (
  isInDraftsProject: boolean,
  name: string,
  project?: RestoreShareModalProps['project']
) => {
  if (isInDraftsProject) {
    return (
      <>
        Restoring “<strong>{name}</strong>” from Trash will move it back to My
        Drafts.
      </>
    )
  } else if (project) {
    return (
      <>
        Restoring “<strong>{name}</strong>” from Trash will move it back to “
        <strong>{project.name}</strong>”
      </>
    )
  } else {
    return (
      <>
        Restoring “<strong>{name}</strong>” from Trash will move it back to All
        Documents.
      </>
    )
  }
}

export const RestoreShareModal: React.FC<RestoreShareModalProps> = props => {
  const {
    name,
    hideModal,
    shareIdentifier,
    isModalOpen,
    isInDraftsProject,
    project,
  } = props

  const [restoreShare, { loading }] = useShareRestoreWithUi({
    identifier: shareIdentifier,
    onCompleted: () => {
      hideModal()
    },
  })

  const destination = project
    ? ({ project } as TransferDocumentInfo)
    : undefined

  const restoreCopy = getRestoreShareCopy(
    Boolean(isInDraftsProject),
    name,
    project
  )

  return (
    <ConfirmationDialog
      title="Restore Document?"
      isModalOpen={isModalOpen}
      hideModal={hideModal}
      cancelButton={{
        disabled: loading,
        text: 'Cancel',
      }}
      confirmButton={{
        text: 'Restore Document',
        variant: 'primary',
        loading,
        disabled: loading,
      }}
      onConfirm={restoreShare}
    >
      <p>{restoreCopy}</p>
      <Separator />
      <ShareAccessList
        shareIdentifier={shareIdentifier}
        membershipCount={PLACEHOLDER_MEMBERSHIP_ITEMS}
        destination={destination}
      />
    </ConfirmationDialog>
  )
}
