import styled from 'styled-components'

import { breakpoint } from '@sketch/global-styles'
import { ButtonUnstyled, NavbarExtended } from '@sketch/components'

import { ReactComponent as DividerSvg } from '@sketch/icons/dots-4-vertical-divider-32'
import { ReactComponent as SortIconSvg } from '@sketch/icons/arrow-up-down-24'

export const NavbarWrapper = styled(NavbarExtended)`
  background: none;
  border: none;
  height: auto;
  overflow: hidden;

  margin: 16px 16px 0;

  ${breakpoint('sm')`
    margin-bottom: 52px;
  `}
`

export const NavbarSection = styled(NavbarExtended.Section)``

export const SearchBarWrapper = styled.div`
  form {
    max-width: initial;
  }
`

export const Divider = styled(DividerSvg)`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  width: 12px;
  height: 24px;
  flex-shrink: 0;
`

export const SortIcon = styled(SortIconSvg)`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  height: 24px;
  width: 24px;
`

export const SortButton = styled(ButtonUnstyled)`
  cursor: pointer;
  width: 40px;
  height: 32px;

  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.radii.large};

  &:hover {
    background-color: ${({ theme }) => theme.colors.state.hover};

    ${SortIcon} {
      color: ${({ theme }) => theme.colors.foreground.secondary.A};
    }
  }
`
