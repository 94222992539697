import styled, { css } from 'styled-components'

import { GroupLink } from '../Grid/GroupName'
import { LinkStyle } from './GroupsList.styles'

export const Link = styled(GroupLink)<{
  selected?: boolean
}>(
  ({ theme, selected }) => css`
    ${LinkStyle};
    justify-content: flex-start;
    &:hover {
      background: ${selected ? undefined : theme.colors.background.tertiary.C};
    }
  `
)

export const Icon = styled.svg(
  ({ theme }) => css`
    width: 16px;
    height: 16px;
    margin-right: 16px;
  `
)

export const List = styled.ul(
  ({ theme }) => css`
    position: relative;
    /* Needed to increase specificity to avoid inheriting generic ul styles */
    && {
      margin-bottom: 8px;
      padding-bottom: 8px;
    }
    &:after {
      content: '';
      position: absolute;
      height: 1px;
      background: ${theme.colors.border.A};
      bottom: 0;
      left: -8px;
      right: -8px;
    }
  `
)
