import React, { useRef, useState, useEffect } from 'react'

import {
  EditInput,
  InputWrapper,
  Pencil,
  PencilButton,
  Preview,
  Wrapper,
  Form,
  Text,
} from './EditableText.styles'

type EditReturn = Promise<any> | void
type EditHandler = (value: string) => EditReturn

interface InputProps {
  value: string
  placeholder?: string
  onEdit: EditHandler
  onCancel: () => void
  previewElement: React.ElementType
  allowNewLines?: boolean
  noExtraStyling?: boolean
}

interface EditableTextProps {
  forceEdit?: boolean
  element: React.ElementType
  onClearForceEdit?: () => void
  onEdit: EditHandler
  value?: string
  placeholder?: string
  allowNewLines?: boolean
  noExtraStyling?: boolean // removes the borders and pencil icon
  removePlaceholderMarginBottom?: boolean
}

const Input: React.FC<InputProps> = props => {
  const {
    value: externalValue,
    onEdit,
    placeholder,
    previewElement: PreviewElement,
    onCancel,
    allowNewLines,
    noExtraStyling,
  } = props

  const [value, setValue] = useState(externalValue)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    textAreaRef.current?.select()
  }, [])

  // Make the text area appear selected when opened and
  // mount the escape button hook
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onCancel()
      }
    }

    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [onCancel])

  const createSubmit = (form: HTMLFormElement | null) => {
    form?.dispatchEvent(new Event('submit', { cancelable: true }))
  }

  return (
    <InputWrapper>
      <PreviewElement as={Preview}>
        {value === '' ? placeholder : value}
      </PreviewElement>
      <Form
        onSubmit={e => {
          e.preventDefault()
          onEdit(value.trim())
        }}
      >
        <PreviewElement
          noExtraStyling={noExtraStyling}
          as={EditInput}
          data-testid="editabletext-input"
          name="text"
          placeholder={placeholder}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setValue(
              // Replace double space from triggering a new line
              allowNewLines
                ? e.target.value
                : e.target.value.replaceAll(/\s{2,}/g, ' ')
            )
          }
          onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => {
            createSubmit(e.currentTarget.form)
          }}
          onKeyPress={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            // Make the "Enter" key submit the change instead of a new line
            if (e.key === 'Enter' && !(e.shiftKey && allowNewLines)) {
              e.preventDefault()
              createSubmit(e.currentTarget.form)
            }
          }}
          ref={textAreaRef}
          value={value}
        />
      </Form>
    </InputWrapper>
  )
}

export const EditableText: React.FC<EditableTextProps> = props => {
  const {
    forceEdit,
    element: Element,
    value = '',
    placeholder,
    onEdit,
    onClearForceEdit,
    allowNewLines,
    noExtraStyling,
    removePlaceholderMarginBottom,
  } = props

  const [isEditing, setEditing] = useState(forceEdit)

  const headerRef = useRef<HTMLHeadingElement>(null)
  let text = (
    <Text
      as={Element}
      tabIndex={0}
      aria-label={value}
      ref={headerRef}
      noExtraStyling={noExtraStyling}
      removePlaceholderMarginBottom={removePlaceholderMarginBottom}
      onClick={() => {
        // Only allow since click for edit mode in non-touch devices
        if ('ontouchstart' in document.documentElement === false) {
          setEditing(true)
        }
      }}
      onKeyPress={(event: React.KeyboardEvent<HTMLHeadingElement>) => {
        // Allow the Enter and " " chars to enter in edit mode
        if (['Enter', ' '].includes(event.key)) {
          setEditing(true)
        }
      }}
      placeholder={placeholder}
    >
      {value}
    </Text>
  )

  // Set the "isEditing" true when the forceEdit is send
  useEffect(() => {
    if (forceEdit) {
      setEditing(true)
    }
  }, [forceEdit])

  // Call "onClearForceEdit" when the edit is set to false
  useEffect(() => {
    if (isEditing && forceEdit) {
      return () => {
        onClearForceEdit?.()
      }
    }
  }, [isEditing, forceEdit, onClearForceEdit])

  if (isEditing) {
    text = (
      <Input
        noExtraStyling={noExtraStyling}
        value={value}
        placeholder={placeholder}
        previewElement={Element}
        onEdit={async newValue => {
          if (value !== newValue) {
            await onEdit(newValue)
          }

          setEditing(false)
        }}
        onCancel={() => {
          setEditing(false)
        }}
        allowNewLines={allowNewLines}
      />
    )
  }

  return (
    <Wrapper noExtraStyling={noExtraStyling}>
      {text}
      {!noExtraStyling && (
        <PencilButton
          aria-label="Edit"
          tabIndex={-1}
          onClick={() => setEditing(true)}
          disabled={isEditing}
        >
          <span className="sr-only">Edit</span>
          <Pencil />
        </PencilButton>
      )}
    </Wrapper>
  )
}
