import { routes } from '@sketch/modules-common'
import React from 'react'
import { Route, Switch } from 'react-router'
import { SettingsSidebar, Sidebar as NewSidebar } from '../Sidebar'
import { WorkspaceMinimalFragment } from '@sketch/gql-types'

interface WorkspaceSidebarProps {
  workspace: WorkspaceMinimalFragment
}

export const WorkspaceSidebar = ({ workspace }: WorkspaceSidebarProps) => (
  <Switch>
    <Route
      path={[
        routes.WORKSPACE_SETTINGS.template(),
        routes.PERSONAL_SETTINGS.template(),
      ]}
      render={() => <SettingsSidebar workspace={workspace} />}
    />
    <Route render={() => <NewSidebar workspace={workspace} />} />
  </Switch>
)
