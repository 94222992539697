import React from 'react'
import styled from 'styled-components'
import {
  TableHead,
  TableHeaderStyle,
  TableStyle,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  StyledSortIcon,
  CellHeight,
  StatusHeader,
  StickyStyledTableCell,
  StickyStyledTableHeader,
} from './Table.styles'

type OrderDirection = 'ASC' | 'DESC'

type TableHeaderProps = React.PropsWithChildren<{
  className?: string
  onClick?: () => void
}>

interface TableSortableHeaderProps<K> extends TableHeaderProps {
  className?: string
  onHeaderClick?: (sortKey: K) => void
  sortDirection?: OrderDirection | null
  header: TableHeaderItem[]
}

interface TableProps<T, K> extends TableComponentProps {
  header: TableHeaderItem[]
  items: T[]
  renderItem: (item: T) => React.ReactNode
  renderItemKey?: (item: T) => string | undefined
  onHeaderClick?: (sortKey: K) => void
  sortDirection?: OrderDirection | null
  sortable?: boolean
  isLoading?: boolean
  loadingComponent?: React.ReactNode
}

export interface TableHeaderItem {
  label: React.ReactNode
  sort?: boolean
  sortKey?: string | undefined
  iconVisible?: boolean
  customCell?: React.ComponentType
}

type TableComponentProps = React.PropsWithChildren<{
  className?: string
  evenColumns?: boolean
  hideHeader?: boolean
  cellHeight?: CellHeight
  'data-testid'?: string
}>

const getClickHeaderProps = ({
  sort,
  onHeaderClick,
  sortKey,
}: {
  sort: boolean | undefined
  onHeaderClick: any
  sortKey: string | undefined
}) => {
  if (!sort || !onHeaderClick || !sortKey) return undefined

  return { onClick: () => onHeaderClick(sortKey) }
}

const TableHeader = styled(function TableHeader(props: TableHeaderProps) {
  const { className, children } = props

  return (
    <TableHead className={className}>
      <TableHeaderStyle>{children}</TableHeaderStyle>
    </TableHead>
  )
})``

const TableComponent = styled(function TableComponent(
  props: TableComponentProps
) {
  const {
    className,
    children,
    evenColumns,
    hideHeader,
    cellHeight,
    'data-testid': dataTestId,
  } = props

  return (
    <TableStyle
      className={className}
      data-testid={dataTestId}
      $evenColumns={evenColumns}
      $hideHeader={hideHeader}
      cellHeight={cellHeight}
    >
      {children}
    </TableStyle>
  )
})``

function TableSortableHeader<K>(props: TableSortableHeaderProps<K>) {
  const { className, header, onHeaderClick, sortDirection } = props

  return (
    <TableHead className={className}>
      <TableHeaderStyle>
        {header.map((item, index) => {
          const {
            label,
            sortKey,
            customCell: TableCell = TableHeaderCell,
            sort,
            iconVisible,
          } = item

          return (
            <TableCell
              {...getClickHeaderProps({
                sort,
                onHeaderClick,
                sortKey,
              })}
              key={index}
              $cursor={sort}
            >
              {iconVisible ? (
                <StatusHeader>
                  <>{label}</>
                  <StyledSortIcon
                    transform={
                      sortDirection === 'ASC' ? 'rotate(180) ' : undefined
                    }
                  />
                </StatusHeader>
              ) : (
                <>{label}</>
              )}
            </TableCell>
          )
        })}
      </TableHeaderStyle>
    </TableHead>
  )
}

export function Table<T, K>(props: TableProps<T, K>) {
  const {
    header,
    items,
    renderItem,
    renderItemKey,
    onHeaderClick,
    sortDirection,
    sortable = false,
    isLoading,
    loadingComponent,
    ...tableProps
  } = props

  return (
    <TableComponent {...tableProps}>
      {sortable ? (
        <TableSortableHeader
          header={header}
          sortDirection={sortDirection}
          onHeaderClick={onHeaderClick}
        />
      ) : (
        <TableHeader>
          {header.map(
            ({ label, customCell: TableCell = TableHeaderCell }, index) => (
              <TableCell key={index}>{label}</TableCell>
            )
          )}
        </TableHeader>
      )}
      <TableBody>
        {isLoading
          ? loadingComponent
          : items.map((item, index) => (
              <React.Fragment key={renderItemKey?.(item) || index}>
                {renderItem(item)}
              </React.Fragment>
            ))}
      </TableBody>
    </TableComponent>
  )
}

export const TableComponents = {
  Table: TableComponent,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  TableCellSticky: StickyStyledTableCell,
  TableHeaderCellSticky: StickyStyledTableHeader,
}
