import React, {
  FC,
  useLayoutEffect,
  InputHTMLAttributes,
  useState,
  useRef,
  SyntheticEvent,
} from 'react'
import { useField, useFormikContext } from 'formik'
import { useKey } from 'react-use'

import { useOnClickOutside } from '@sketch/utils'

import {
  Button,
  useBreakpoint,
  Modal,
  ModalTransition,
  ModalInjectedProps,
  useModalContext,
} from '@sketch/components'

import {
  InputContainer as FakeInputContainer,
  IconContainer,
  PencilIcon,
  CrossIcon,
} from './Common.styles'
import {
  Container,
  FakeInput,
  Menu as MenuStyled,
  Title,
  Description,
  InputContainer,
  Link,
  InputPlaceholder,
  Input,
  ButtonsContainer,
} from './BuyMeACoffeeInput.styles'

import {
  BuyMeACoffeeButton,
  BUY_ME_A_COFFEE_URL,
  SHORT_BUY_ME_A_COFFEE_URL,
} from '../BuyMeACoffeeButton'

interface ToggleProps {
  value: string
  editing: boolean
  onClick: (event: SyntheticEvent) => void
  onClearClick: () => void
  placeholder?: string
}

const Toggle: FC<ToggleProps> = ({
  value,
  editing,
  onClick,
  onClearClick,
  placeholder,
}) => {
  const handleInputContainerClick = (event: SyntheticEvent) => {
    // Only trigger the click on the container when input is empty,
    // otherwise when clicking on the cross the container click action will
    // also be trigered and the menu will open.
    if (!value) onClick(event)
  }

  return (
    <FakeInputContainer
      onClick={handleInputContainerClick}
      onFocus={handleInputContainerClick}
    >
      {editing ? (
        <BuyMeACoffeeButton />
      ) : (
        <FakeInput empty={!!value} onClick={onClick}>
          {value ? (
            <>
              <span>{SHORT_BUY_ME_A_COFFEE_URL}</span>
              {value}
            </>
          ) : (
            placeholder
          )}
        </FakeInput>
      )}
      {value ? (
        <IconContainer>
          <CrossIcon onClick={onClearClick} />
        </IconContainer>
      ) : (
        !editing && (
          <IconContainer>
            <PencilIcon />
          </IconContainer>
        )
      )}
    </FakeInputContainer>
  )
}
interface MenuBodyProps {
  inputRef: React.Ref<HTMLInputElement>
  name: string
  value: string
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const MenuBody: FC<MenuBodyProps> = ({
  inputRef,
  name,
  value,
  onInputChange,
}) => (
  <>
    <Title>BUY ME A COFFEE</Title>
    <Description>
      Add an optional <b>Buy Me a Coffee</b> button for donations.{' '}
      <Link isUnderlined external href={BUY_ME_A_COFFEE_URL}>
        Learn More
      </Link>
    </Description>

    <InputContainer>
      <InputPlaceholder>{SHORT_BUY_ME_A_COFFEE_URL}</InputPlaceholder>
      <Input
        ref={inputRef}
        name={name}
        value={value}
        onChange={onInputChange}
        autoComplete="off"
      />
    </InputContainer>
  </>
)

interface MenuFooterProps {
  onCancelClick: () => void
  onAddClick: () => void
  editing?: boolean
}

const MenuFooter: FC<MenuFooterProps> = ({
  onCancelClick,
  onAddClick,
  editing,
}) => {
  useKey(
    'Enter',
    () => {
      editing && onAddClick()
    },
    {},
    [editing]
  )

  return (
    <>
      <Button variant="secondary-untinted" size="24" onClick={onCancelClick}>
        Cancel
      </Button>
      <Button variant="primary" size="24" onClick={onAddClick}>
        Add Button
      </Button>
    </>
  )
}

interface DesktopMenuProps {
  editing: boolean
  inputRef: React.Ref<HTMLInputElement>
  name: string
  value: string
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onCancelClick: () => void
  onAddClick: () => void
}

const DesktopMenu: FC<DesktopMenuProps> = ({
  editing,
  inputRef,
  name,
  value,
  onInputChange,
  onCancelClick,
  onAddClick,
}) => {
  const containerRef = useRef(null)

  // Close the container when the users clicks outside of it
  useOnClickOutside(
    containerRef,
    () => {
      onCancelClick()
    },
    { disabled: !editing }
  )

  return (
    <MenuStyled ref={containerRef} editing={editing}>
      <MenuBody {...{ inputRef, name, value, onInputChange, onAddClick }} />

      <ButtonsContainer>
        <MenuFooter {...{ onCancelClick, onAddClick, editing }} />
      </ButtonsContainer>
    </MenuStyled>
  )
}

interface MobileMenuProps extends ModalInjectedProps {
  inputRef: React.Ref<HTMLInputElement>
  name: string
  value: string
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onCancelClick: () => void
  onAddClick: () => void
  mobile?: boolean
}

const MobileMenu: FC<MobileMenuProps> = ({
  hideModal,
  inputRef,
  name,
  value,
  onInputChange,
  onAddClick,
  onCancelClick,
}) => {
  const handleAddClick = () => {
    hideModal()
    onAddClick()
  }

  const handleCancelClick = () => {
    hideModal()
    onCancelClick()
  }

  return (
    <Modal onCancel={handleCancelClick}>
      <Modal.Body>
        <MenuBody {...{ inputRef, name, value, onInputChange }} />
      </Modal.Body>

      <Modal.Footer>
        <MenuFooter
          onAddClick={handleAddClick}
          onCancelClick={handleCancelClick}
        />
      </Modal.Footer>
    </Modal>
  )
}

interface BuyMeACoffeeInputProps extends InputHTMLAttributes<HTMLInputElement> {
  width?: string
  name: string
  onAddClick: () => void
}

export const BuyMeACoffeeInput: FC<BuyMeACoffeeInputProps> = ({
  width,
  name,
  className,
  onAddClick,
  placeholder,
}) => {
  const [editing, setEditing] = useState(false)
  const [{ value }, , { setValue }] = useField(name)
  const { submitForm } = useFormikContext()

  // Focus when entering edit mode
  const inputRef = useRef<HTMLInputElement>(null)
  useLayoutEffect(() => {
    if (editing) {
      inputRef.current?.focus()
    }
  })

  // Close edit mode when escape key is pressed
  useKey('Escape', () => {
    handleCancel()
  })

  // Handlers
  const handleAdd = () => {
    setEditing(false)
    onAddClick()
  }

  const handleCancel = () => {
    setEditing(false)
  }

  // Mobile
  const isSmallerMobile = !useBreakpoint('xs')

  const { hideModal } = useModalContext()

  const handleClear = () => {
    setValue('')
    submitForm()
  }

  return (
    <Container
      width={width}
      className={className}
      editing={editing}
      empty={!value}
    >
      <Toggle
        value={value}
        editing={editing}
        onClick={() => setEditing(true)}
        onClearClick={handleClear}
        placeholder={placeholder}
      />

      {isSmallerMobile ? (
        <ModalTransition show={editing}>
          <MobileMenu
            inputRef={inputRef}
            name={name}
            value={value}
            onInputChange={e => setValue(e.target.value)}
            onCancelClick={handleCancel}
            onAddClick={handleAdd}
            hideModal={hideModal}
          />
        </ModalTransition>
      ) : (
        <DesktopMenu
          editing={editing}
          inputRef={inputRef}
          name={name}
          value={value}
          onInputChange={e => setValue(e.target.value)}
          onCancelClick={handleCancel}
          onAddClick={handleAdd}
        />
      )}
    </Container>
  )
}
