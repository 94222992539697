import { useEffect, useState } from 'react'

export const useKonamiCode = (handler: () => void) => {
  const [keys, setKeys] = useState<string[]>([])

  const isKonamiCode =
    keys.join(' ') ===
    'ArrowUp ArrowUp ArrowDown ArrowDown ArrowLeft ArrowRight ArrowLeft ArrowRight b a'

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>

    const handleKeydown = (e: KeyboardEvent) => {
      setKeys(currentKeys => [...currentKeys, e.key])

      // Clear timeout since key was just pressed
      clearTimeout(timeout)

      // Reset keys if 2s passes so user can try again
      timeout = setTimeout(() => setKeys([]), 2000)
    }

    document.addEventListener('keydown', handleKeydown)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [])

  // Reset keys when konami code triggers successfully
  useEffect(() => {
    if (isKonamiCode) {
      handler()
      setKeys([])
    }
  }, [isKonamiCode, handler])

  return isKonamiCode
}
