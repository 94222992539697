import styled, { css } from 'styled-components'
import { RadioButton } from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'

export const Themes = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-wrap: no-wrap;

  ${breakpoint('base', 'md')`
    flex-wrap: wrap;
    justify-content: center;
  `}
`

export const ThemeOption = styled.div<{ active: boolean; disabled?: boolean }>(
  ({ theme, active, disabled }) => css`
    width: 308px;
    height: 200px;
    padding: 12px 16px;
    border-radius: ${theme.radii.xxxlarge};
    color: ${theme.colors.foreground.secondary.A};
    text-transform: uppercase;
    border: 1px solid ${active ? theme.colors.sketch.D : theme.colors.border.B};
    background-color: ${active
      ? 'transparent'
      : theme.colors.background.secondary.B};
    margin-right: 24px;
    margin-bottom: 24px;
    position: relative;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `
)

export const Thumbnail = styled.svg<{ disabled: boolean }>(
  ({ disabled }) => css`
    width: 276px;
    height: 140px;
    opacity: ${disabled ? 0.5 : 1};
  `
)

export const RadioButtonStyled = styled(RadioButton)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.C};
    font-weight: ${theme.fontWeights.semibold};
    width: 100%;
    display: flex;
    margin-bottom: 12px;
    justify-content: center;
    align-content: space-around;

    > * {
      &:first-child {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  `
)
