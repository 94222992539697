import React, { useCallback } from 'react'

import { Panel, VerificationCodeInput } from '@sketch/components'

import HelpNote from './HelpNote'

import {
  Title,
  FormContainer,
  Note,
  RecoveryCodeToggle,
  Description,
  Paragraph,
} from './RecoveryCode.styles'

import {
  ValidateMfaTotpMutation,
  useValidateMfaTotpMutation,
} from '@sketch/gql-types'
import { ParsedError } from '@sketch/graphql-apollo/useMutation'

interface VerificationCodeProps {
  onCompleted?: ((data: ValidateMfaTotpMutation) => void) | undefined
  onError: (error: ParsedError) => void
  toggleInterface: () => void
  onVerificationCodeFilled: () => void
  mfaToken: string
  verificationError?: string
  // custom filled handling
  onFilled?: (code: string) => void
  hideHeader?: boolean
}

/**
 * Renders the UI that allows the user to enter the verification code
 * This only renders if the user has mfaState === 'ENABLED' which is
 * returned after the user Signs in
 */
export const VerificationCode: React.FC<VerificationCodeProps> = ({
  onCompleted,
  onError,
  onVerificationCodeFilled,
  toggleInterface,
  mfaToken,
  verificationError,
  onFilled,
  hideHeader,
}) => {
  // Verification Code Validation
  const [validateMfaTotp] = useValidateMfaTotpMutation({
    onCompleted,
    onError,
  })

  const handleVerificationCodeFilled = useCallback(
    (code: string) => {
      if (onFilled) {
        onVerificationCodeFilled()
        onFilled(code)

        return
      }

      onVerificationCodeFilled()

      // Code from the Authenticator App
      validateMfaTotp({
        variables: {
          token: mfaToken,
          totp: code,
        },
      })
    },
    [mfaToken, validateMfaTotp, onVerificationCodeFilled, onFilled]
  )

  const form = (
    <FormContainer>
      <VerificationCodeInput
        onFilled={handleVerificationCodeFilled}
        error={verificationError}
      />
      <Note>
        <Paragraph>
          Can’t access your authenticator app?{' '}
          <RecoveryCodeToggle onClick={toggleInterface} isUnderlined>
            Use a recovery code
          </RecoveryCodeToggle>
        </Paragraph>
        <Paragraph>
          <HelpNote />
        </Paragraph>
      </Note>
    </FormContainer>
  )

  if (hideHeader) {
    return form
  }

  return (
    <>
      <Title>Enter Verification Code</Title>

      <Panel.Body>
        <Description>
          Open your authenticator app and enter the code before it expires — or
          wait for a new one.
        </Description>

        {form}
      </Panel.Body>
    </>
  )
}

export default VerificationCode
