import * as Sentry from '@sentry/browser'
import { ApolloQueryResult } from 'apollo-client'
import { GetShareQuery, useGetShareQuery } from '@sketch/gql-types'
import {
  areAuthorizationIdsEqual,
  Authorization,
  getPersonalAuthorization,
  getSsoAuthorizationForWorkspace,
  setActiveAuthorizationId,
  useUserAuthorizations,
} from '@sketch/modules-common'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useApolloClient } from 'react-apollo'
import { useEffectOnTabVisibility } from '@sketch/utils'

export type AuthorizedShare = NonNullable<GetShareQuery['share']>

export interface ShareAuthorizerQueryProps {
  shortId: string
}

export interface ShareAuthorizerQueryData {
  type: 'data'
  share: AuthorizedShare
  refetch: () => Promise<ApolloQueryResult<GetShareQuery>>
  updating: boolean
}

export interface ShareAuthorizerQueryDeleted {
  type: 'deleted'
  share: AuthorizedShare
}

export interface ShareAuthorizerQueryBaseError {
  type: 'error'
  refetch: () => Promise<ApolloQueryResult<GetShareQuery>>
  error?: Error
}

export interface ShareAuthorizerQueryWrongIdentityError
  extends ShareAuthorizerQueryBaseError {
  subtype: 'wrong-identity'
  workspaceIdentifier?: string
  email?: string
}

export interface ShareAuthorizerForbiddenError
  extends ShareAuthorizerQueryBaseError {
  subtype: 'forbidden'
  canSSOUserRequestAccess?: boolean
}

export type ShareAuthorizerQueryError =
  | (ShareAuthorizerQueryBaseError & {
      subtype: 'not-found' | 'generic'
    })
  | ShareAuthorizerQueryWrongIdentityError
  | ShareAuthorizerForbiddenError

export interface ShareAuthorizerQueryLoading {
  type: 'loading'
}

type ShareAuthorizerQueryResult =
  | ShareAuthorizerQueryError
  | ShareAuthorizerQueryData
  | ShareAuthorizerQueryLoading
  | ShareAuthorizerQueryDeleted

export type ShareAuthorizerQueryHook = (
  props: ShareAuthorizerQueryProps
) => ShareAuthorizerQueryResult

type QueryStatus = 'ready' | 'loading' | 'loaded'

const REFETCH_ERROR_SUBTYPES: ShareAuthorizerQueryError['subtype'][] = [
  'forbidden',
  'wrong-identity',
]

const shouldRefetchBecauseOfAuthError = (
  result: ShareAuthorizerQueryResult,
  nonTestedAuthorizations: Authorization[]
): result is ShareAuthorizerQueryError =>
  result.type === 'error' &&
  REFETCH_ERROR_SUBTYPES.includes(result.subtype) &&
  nonTestedAuthorizations.length > 0

/**
 * This wrapper on top of useShareAuthorizerQuery makes sure to try accessing
 * the share with all user tokens until one of them returns a non-forbidden
 * error or all of them are used.
 *
 * This solution solves a major issue we had with accessing shares: using the
 * right token to fetch its information. Documents/Shares/Prototypes URLs do not
 * contain team information, this means we have no easy way to tell what token
 * should we use to access these. This is a best-effort solution where we just
 * try with all the user tokens.
 */
export const useShareAuthorizerQueryWithAllAuths: ShareAuthorizerQueryHook = props => {
  const apolloClient = useApolloClient()
  const { authorizations, activeAuthorization } = useUserAuthorizations()
  const result = useShareAuthorizerQuery(props)

  const allAuthorizationsButCurrentOne = authorizations.filter(
    auth =>
      !activeAuthorization ||
      !areAuthorizationIdsEqual(auth, activeAuthorization)
  )

  const nonTestedAuthorizations = useRef(allAuthorizationsButCurrentOne)
  const testedAuthorizations = useRef<Authorization[]>(
    activeAuthorization ? [activeAuthorization] : []
  )

  /**
   * While re-fetching, the query will return the previous result (instead of
   * the loading state). This status let us differentiate between correct and
   * up-to-date data and Apollo returning previous data while re-fetching.
   *
   * This is what Apollo reports when re-fetching:
   *
   * LOAD > loading > data #1 > RE-FETCH > data #1 > loading > data #2
   *
   * That's why we identify new data only when there was a loading state before
   * the new data. When doing a re-fetch we set the status as "ready", only when
   * a new loading state comes then we change to loading as well. Data coming
   * after that is guaranteed to be up-to-date.
   */
  const queryStatus = useRef<QueryStatus>('ready')

  const fetchShareWithNextAuth = useCallback(() => {
    const nextAuth = nonTestedAuthorizations.current.shift()
    queryStatus.current = 'ready'

    testedAuthorizations.current.push(nextAuth!)
    setActiveAuthorizationId(nextAuth!, apolloClient.cache)

    /**
     * Calling refetch on the same loop makes the current Apollo client to
     * not report "refetch" network status as well so we delay it until the
     * next tick.
     */
    if (result.type === 'error') {
      setTimeout(result.refetch, 0)
    }
  }, [result, apolloClient])

  /**
   * Listen to changes on the active authorization. If it's one we never tested
   * then we add it to the list and try again.
   */
  useEffect(() => {
    if (!activeAuthorization) return

    const hasAlreadyTestedAuth = testedAuthorizations.current.find(auth =>
      areAuthorizationIdsEqual(auth, activeAuthorization)
    )

    const isGoingToTestAuth = nonTestedAuthorizations.current.find(auth =>
      areAuthorizationIdsEqual(auth, activeAuthorization)
    )

    if (hasAlreadyTestedAuth || isGoingToTestAuth) return

    nonTestedAuthorizations.current.push(activeAuthorization)

    if (
      shouldRefetchBecauseOfAuthError(result, nonTestedAuthorizations.current)
    ) {
      fetchShareWithNextAuth()
    }
  }, [result, activeAuthorization, fetchShareWithNextAuth])

  /**
   * We call refetch only when the current tab is visible. This fixes an issue
   * that happens when calling refetch while the tab is in the background: when
   * that happens, Apollo decides not to report the "refetch" (4) network status
   * and so we can't know if the fetched data is up-to-date or not.
   */
  useEffectOnTabVisibility(
    'visible',
    () => {
      if (
        shouldRefetchBecauseOfAuthError(result, nonTestedAuthorizations.current)
      ) {
        fetchShareWithNextAuth()
      }
    },
    [result, fetchShareWithNextAuth]
  )

  const isLoadingData =
    result.type === 'loading' || (result.type === 'data' && result.updating)

  if (isLoadingData && queryStatus.current === 'ready') {
    queryStatus.current = 'loading'
  } else if (!isLoadingData && queryStatus.current === 'loading') {
    queryStatus.current = 'loaded'
  }

  /**
   * We still don't have updated data to return so let's show a loading page.
   */
  if (['ready', 'loading'].includes(queryStatus.current)) {
    return { type: 'loading' }
  }

  if (
    shouldRefetchBecauseOfAuthError(result, nonTestedAuthorizations.current)
  ) {
    /**
     * We got new information but it's a forbidden error and we still have other
     * tokens to use so we'll try the next one and in the meantime we will
     * return a loading state.
     */
    return { type: 'loading' }
  }

  return result
}

const useShareAuthorizerQuery: ShareAuthorizerQueryHook = props => {
  const { shortId } = props

  const { error, loading, data, refetch, networkStatus } = useGetShareQuery({
    variables: { shortId },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  useRefetchShareWithBestAuthorizationIfNeeded(data, refetch)

  /**
   * Loading state screen
   *
   * We should show the loading screen page when there is no
   * cached information about the share and it's in a loading state.
   *
   * When we are changing the variables and the current cache data doesn't match the requested shortId
   *
   * Cases:
   *
   * [networkStatus === 1 && !data?.share]
   * - First Loading, no cache data -> Show Big Loading
   *
   * [networkStatus === 2 && data?.share?.shortId !== shortId]
   * - Change ShortId and the share is not cached -> Show Big Loading  (needed to reset the versioning context)
   * - Change ShortId and the share is cache -> Silently update the share data.
   *
   * [networkStatus === 4 && !data?.share]
   * - Refetch Information from the current share -> Show Big Loading
   *
   * Please check the networkStatus in
   * https://github.com/apollographql/apollo-client/blob/e75a1ec94db62710e0a537e4a48d3c8b61f5d1a5/src/core/networkStatus.ts#L4
   *
   * https://github.com/sketch-hq/Cloud/issues/3885
   */
  if (
    (networkStatus === 1 && !data?.share) ||
    (networkStatus === 2 && data?.share?.identifier !== shortId) ||
    (networkStatus === 4 && !data?.share)
  ) {
    return { type: 'loading' }
  }

  if (
    error &&
    error.graphQLErrors.some(
      e =>
        e.extensions?.code === 'FORBIDDEN' &&
        e.extensions?.reason === 'WRONG_ACCESS_TOKEN'
    )
  ) {
    const wrongAccessTokenError = error.graphQLErrors.find(
      e => e.extensions?.reason === 'WRONG_ACCESS_TOKEN'
    )
    const context = wrongAccessTokenError?.extensions?.context ?? []

    const workspaceIdentifier = context.find(
      (c: { key: string; value: string }) => c.key === 'teamId'
    )?.value

    const email = context.find(
      (c: { key: string; value: string }) => c.key === 'email'
    )?.value

    return {
      type: 'error',
      subtype: 'wrong-identity',
      workspaceIdentifier,
      email,
      refetch,
      error,
    }
  }

  /**
   * We show the share request access page when the user is
   * - 401, Doesn't have a user session and should login before requesting access
   * - 403, Needs to request access to see the share
   */
  if (error && error.message.match(/(forbidden|unauthorized)/i)) {
    const forbiddenErrorContext =
      error.graphQLErrors.find(
        e => e.extensions?.reason === 'USER_CANT_VIEW_SHARE'
      )?.extensions?.context ?? []

    const canSSOUserRequestAccess = forbiddenErrorContext.find(
      (c: { key: string; value: string }) => c.key === 'canSSOUserRequestAccess'
    )?.value

    return {
      type: 'error',
      subtype: 'forbidden',
      refetch,
      error,
      canSSOUserRequestAccess,
    }
  }

  if (
    error &&
    (error.message.match(/not found/i) ||
      error.graphQLErrors.some(x => x.extensions?.code === 'NOT_FOUND'))
  ) {
    return { type: 'error', subtype: 'not-found', refetch, error }
  }

  if (error) {
    Sentry.addBreadcrumb({
      message: 'ShareAuthorizer',
    })
    Sentry.captureException(error)
    return { type: 'error', subtype: 'generic', refetch, error }
  }

  if (!data?.share) {
    return { type: 'error', subtype: 'not-found', refetch }
  }

  if (data.share.deletedAt) {
    return { type: 'deleted', share: data.share }
  }

  return { type: 'data', share: data.share, refetch, updating: loading }
}

/**
 * useShareAuthorizerQueryWithAllAuths hook is already handling the case where the API returns an
 * error "WRONG_ACCESS_TOKEN" but this hook is here to handle a special edge case:
 * In some scenarios (share accessible publicly), accessing the share with the wrong access token will work
 * and will not raise any API error but the user will be limited to the defined public access level. As the user
 * has potentially full access to the share when using the right authentication (the one of the workspace
 * where the share belongs), once we receive the information about the workspace of the share, we check if there
 * was a better token we could have used and if so, we switch the active auth and refetch the share.
 */
function useRefetchShareWithBestAuthorizationIfNeeded(
  data: GetShareQuery | undefined,
  refetch: ReturnType<typeof useGetShareQuery>['refetch']
) {
  const apolloClient = useApolloClient()
  const { activeAuthorization } = useUserAuthorizations()

  const bestAuthForShare = useMemo(
    () => getBestAuthorizationForShare(data?.share),
    [data]
  )

  useEffect(() => {
    if (!bestAuthForShare) {
      return
    }

    const switchAuthAndRefetch = () => {
      setActiveAuthorizationId(bestAuthForShare, apolloClient.cache)
      refetch()
    }

    if (!activeAuthorization) {
      // activeAuthorization is empty but we have the bestAuthForShare available.
      // (Not sure if this is a real scenario)
      switchAuthAndRefetch()
      return
    }

    // Is the active auth the same as the best one we can use for the share
    const isAlreadyUsingBestAuth = areAuthorizationIdsEqual(
      bestAuthForShare,
      activeAuthorization
    )

    if (!isAlreadyUsingBestAuth) {
      switchAuthAndRefetch()
    }
  }, [bestAuthForShare, activeAuthorization, refetch, apolloClient])
}

/**
 * Users can have multiple authentication tokens (personal auth & SSO auth per workspace).
 * Get the authentication that is the best for this specific share.
 */
function getBestAuthorizationForShare(
  share?: GetShareQuery['share']
): Authorization | null {
  if (!share) {
    return null
  }

  const shareBelongsToAnSsoWorkspace = Boolean(
    share.workspace.customer?.ssoEnabled
  )

  if (shareBelongsToAnSsoWorkspace) {
    /* Note: For no particular reasons except that we can't really detect this scenario,
     * we consider the SSO auth as better in the rare scenario where:
     * - The share belongs to an SSO workspace.
     * - The user has access to the workspace through both his SSO and personal account (e.g: the admin
     * that has set up the SSO workspace)
     * - The user is logged in with both methods at the same time (rare scenario)
     *
     * In that particular scenario, even though the workspace is SSO, the user
     * has also equal access through the personal account and me may end-up with an unnecessary
     * refetch (not visible to the user).
     */
    const shareWorkspaceId = share.workspace.identifier
    return getSsoAuthorizationForWorkspace(shareWorkspaceId)
  }

  return getPersonalAuthorization()
}
