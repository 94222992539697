import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "persons-two-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "currentColor" },
        React.createElement("path", { d: "M5 9.5c1.114 0 2.124.386 2.855 1.017C8.557 11.124 9 11.96 9 12.89c-.328.437-.589.567-.9.679C7.283 13.86 6.14 14 5 14s-2.284-.14-3.1-.432c-.311-.112-.572-.242-.746-.405-.154-1.203.289-2.039.991-2.646C2.875 9.886 3.886 9.5 5 9.5zm0-6c.552 0 1.052.224 1.414.586.362.362.586.862.586 1.414 0 .552-.224 1.052-.586 1.414A1.994 1.994 0 015 7.5a1.994 1.994 0 01-1.414-.586A1.994 1.994 0 013 5.5c0-.552.224-1.052.586-1.414A1.994 1.994 0 015 3.5z", fillOpacity: 0.15 }),
        React.createElement("path", { d: "M5 9c2.485 0 4.5 1.741 4.5 3.89 0 1.032-2.08 1.568-4.24 1.608L5 14.5c-2.25 0-4.5-.537-4.5-1.61 0-2.084 1.894-3.784 4.275-3.885L5 9zm0 1.5c-1.512 0-2.706.887-2.953 1.973l-.014.071.201.081.127.043C3.02 12.878 3.983 13 5 13c1.017 0 1.98-.123 2.639-.332.09-.029.17-.057.239-.086l.088-.038-.013-.07c-.236-1.042-1.343-1.9-2.766-1.97L5 10.5zM5 3a2.5 2.5 0 01.164 4.995L5 8a2.5 2.5 0 01-.164-4.995L5 3zm0 1.5a1 1 0 100 2 1 1 0 000-2z" }),
        React.createElement("path", { d: "M11 7.5c1.114 0 2.124.386 2.855 1.017C14.557 9.124 15 9.96 15 10.89c-.328.437-.589.567-.9.679-.816.292-1.959.432-3.1.432h-.08c-.278-1.522-1.258-2.81-2.616-3.612l-.159.13C8.875 7.885 9.886 7.5 11 7.5zm0-6c.552 0 1.052.224 1.414.586.362.362.586.862.586 1.414 0 .552-.224 1.052-.586 1.414A1.994 1.994 0 0111 5.5a1.994 1.994 0 01-1.414-.586A1.994 1.994 0 019 3.5c0-.552.224-1.052.586-1.414A1.994 1.994 0 0111 1.5z", fillOpacity: 0.15 }),
        React.createElement("path", { d: "M11 7c2.485 0 4.5 1.741 4.5 3.89 0 1.032-2.08 1.568-4.24 1.608L11 12.5h-.015a4.91 4.91 0 00-.363-1.507c.124.005.25.007.378.007 1.017 0 1.98-.123 2.639-.332.09-.029.17-.057.239-.086l.088-.038-.013-.07c-.236-1.042-1.343-1.9-2.766-1.97L11 8.5a3.46 3.46 0 00-1.837.504 6.038 6.038 0 00-1.337-.871c.763-.657 1.8-1.08 2.95-1.128L11 7zm0-6a2.5 2.5 0 01.164 4.995L11 6a2.5 2.5 0 01-.164-4.995L11 1zm0 1.5a1 1 0 100 2 1 1 0 000-2z" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
