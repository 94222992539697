import React, { useEffect, useRef } from 'react'
import { InView } from 'react-intersection-observer'
import { useHistory } from 'react-router'

import { ResponsiveValues } from '@sketch/global-styles'

import { clamp } from '@sketch/utils'

import { routes } from '@sketch/modules-common'

import {
  Breakpoint,
  Table,
  AspectRatioGrid,
  pluralize,
} from '@sketch/components'

import {
  DocumentGridItemPlaceholder,
  DocumentTableItemPlaceholder,
} from '../DocumentItem'

import { ProjectItem } from '../ProjectItem'
import { thumbnailForPreviewFile } from '../../utils'

import {
  ProjectListItemFragment,
  useGetDocumentListSettingsQuery,
} from '@sketch/gql-types'

import {
  ActionTableCell,
  Footer,
  TableWrapper,
  FocusLink,
  HeaderTableCell,
} from './ProjectItemsLayout.styles'

interface CustomColumns {
  customColumns?: ResponsiveValues<number>
}

interface ProjectItemsLayoutProps<P> extends CustomColumns {
  projects: P[]
  onLoadMore: () => Promise<any>
  totalCount: number
  renderDropdown: (project: P) => React.ReactNode
  workspaceId: string
}

const COLUMNS: ResponsiveValues<number> = [2, 2, 3, 3, 3, 4, 5, 6]
const GUTTER: ResponsiveValues<number> = [16, 16, 16, 16, 40, 40, 40]
const VERTICAL_SPACE: ResponsiveValues<number> = [24, 24, 24, 24, 40, 40, 40]
const PAGE_SIZE = 20

const buildPlaceholderArray = (remainingProjectsToLoad: number) =>
  Array(clamp(remainingProjectsToLoad, 0, PAGE_SIZE))

const ProjectItemsLayout = <P extends ProjectListItemFragment>(
  props: ProjectItemsLayoutProps<P>
) => {
  const {
    projects,
    onLoadMore,
    totalCount,
    renderDropdown,
    customColumns,
    workspaceId,
  } = props

  const history = useHistory()

  // Make sure the onLoadMore Reference is always the latest
  const onLoadMoreRef = useRef<() => Promise<any>>(onLoadMore)
  useEffect(() => {
    onLoadMoreRef.current = onLoadMore
  }, [onLoadMore])

  // Load the document layout
  const { data } = useGetDocumentListSettingsQuery()
  const { documentsLayout } = data || {}

  const renderGridItem = (project: P, isMobile: boolean) => {
    const thumbnails = project.shares.entries.map(item => {
      const imageSource = thumbnailForPreviewFile(
        item.version?.document?.previewFile
      )

      return { name: item.name, imageSource }
    })

    return (
      <FocusLink
        draggable={false}
        to={routes.WORKSPACE_PROJECT.create({
          workspaceId,
          projectId: project.identifier,
        })}
      >
        <ProjectItem.Card
          name={project.name}
          documentsNumber={project.shares.meta.totalCount}
          thumbnails={thumbnails}
          archivedAt={project.archivedAt || undefined}
          renderDropdownItems={() => renderDropdown(project)}
        />
      </FocusLink>
    )
  }

  const renderTableItem = (project: P, isMobile: boolean) => {
    const link = routes.WORKSPACE_PROJECT.create({
      workspaceId,
      projectId: project.identifier,
    })

    return (
      <ProjectItem.ListItem
        name={project.name}
        documentsNumber={project.shares.meta.totalCount}
        archivedAt={project.archivedAt || undefined}
        totalCollections={project.collections.meta.totalCount}
        renderDropdownItems={() => renderDropdown(project)}
        onClick={event => {
          if (event.metaKey) {
            /* Simulate the link opening with meta key */
            window.open(link, '_blank')
          } else {
            history.push(link)
          }
        }}
      />
    )
  }

  const renderLayout = (
    isMobile: boolean,
    customColumns?: ResponsiveValues<number>
  ) => {
    const placeholders = buildPlaceholderArray(
      (totalCount || 0) - projects.length
    )

    // Merge the items with the placeholders have them rendered together
    // This prevents the page from bumping when the placeholders are replaced
    // with actual items
    const projectAndPlaceholders = [...projects, ...placeholders]

    // Create the InView listener warn when it starts showing the placeholders, this will load the next page
    const inViewListener =
      placeholders.length > 0 ? (
        <InView onChange={inView => inView && onLoadMoreRef.current?.()}>
          <span className="sr-only">Loading Placeholder</span>
        </InView>
      ) : null

    if (documentsLayout === 'LIST') {
      const headerLabel = pluralize('Project', 'Projects', totalCount)

      return (
        <TableWrapper>
          <Table
            header={[
              {
                label: `${totalCount} ${headerLabel}`,
                customCell: HeaderTableCell,
              },
              { label: 'Total Collections', customCell: HeaderTableCell },
              { label: 'Total Documents', customCell: HeaderTableCell },
              { label: 'Archived At', customCell: HeaderTableCell },
              { label: '', customCell: ActionTableCell },
            ]}
            items={projectAndPlaceholders}
            renderItemKey={project =>
              // if it's not a project the table will generate a index based key
              typeof project === 'object' ? project.identifier : undefined
            }
            renderItem={item => {
              if (typeof item === 'object') {
                return renderTableItem(item, isMobile)
              }

              return (
                <DocumentTableItemPlaceholder inViewListener={inViewListener} />
              )
            }}
            evenColumns={!isMobile}
          />
        </TableWrapper>
      )
    } else {
      return (
        <AspectRatioGrid
          columns={customColumns ?? COLUMNS}
          verticalRowSpace={VERTICAL_SPACE}
          gutterSize={GUTTER}
        >
          {projectAndPlaceholders.map((item, index) => {
            if (typeof item === 'object') {
              return renderGridItem(item, isMobile)
            }

            return (
              <DocumentGridItemPlaceholder
                key={index}
                inViewListener={inViewListener}
              />
            )
          })}
        </AspectRatioGrid>
      )
    }
  }

  return (
    <>
      <Breakpoint on="sm">
        {matches => renderLayout(!matches, customColumns)}
      </Breakpoint>
      <Footer />
    </>
  )
}

export default ProjectItemsLayout
