import { ModalBody } from './ModalBody'
import { ModalText } from './ModalText'
import { ModalFooter } from './ModalFooter'
import { ModalHeader } from './ModalHeader'
import { ModalExplainer } from './ModalExplainer'
import { ModalHighlight } from './ModalHighlight'
import { Modal as ModalBase } from './Modal'

export * from './Modal'

export const Modal = Object.assign(ModalBase, {
  Highlight: ModalHighlight,
  Body: ModalBody,
  Footer: ModalFooter,
  Header: ModalHeader,
  Text: ModalText,
  Explainer: ModalExplainer,
})
