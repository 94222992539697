import { useEffect, useMemo, useState } from 'react'

import { dataIdFromObject } from '@sketch/graphql-cache'

import { handleFetchMore } from '@sketch/components'

import {
  AnnotationDotFragment,
  GetAnnotationDotsQueryVariables,
  useGetAnnotationDotsQuery,
} from '@sketch/gql-types'

const ENTRIES_PATH = ['annotations', 'entries']
const WAIT_BETWEEN_PAGES_LOADING = 100
const EMPTY_ANNOTATION_ARRAY = [] as AnnotationDotFragment[]

interface LegacyAnnotationDot {
  annotation: AnnotationDotFragment
  count: number
}

const formatAnnotationDotsResponse = (
  annotationList: AnnotationDotFragment[]
) => {
  const legacyAnnotationsByArtboard: Record<string, LegacyAnnotationDot> = {}
  const floatingAnnotations: AnnotationDotFragment[] = []

  annotationList.forEach(annotation => {
    /**
     * If the annotation is artboard only and it has no
     * coordinates we should represent it as legacy annotation
     *
     * Otherwise it's a floating one
     */

    if (
      annotation.currentSubject.__typename === 'Artboard' &&
      !annotation.coordinates
    ) {
      const { uuid } = annotation.currentSubject

      legacyAnnotationsByArtboard[annotation.currentSubject.uuid] = {
        annotation,
        count: (legacyAnnotationsByArtboard[uuid]?.count || 0) + 1,
      }
    } else {
      floatingAnnotations.push(annotation)
    }
  })

  /**
   * If count of artboard legacy comments is "1" we should
   * just render a normal annotation since we can drag it freely
   */
  for (const key in { ...legacyAnnotationsByArtboard }) {
    const { count, annotation } = legacyAnnotationsByArtboard[key] || {}
    if (count === 1) {
      floatingAnnotations.push(annotation)
      delete legacyAnnotationsByArtboard[key]
    }
  }

  return [
    floatingAnnotations,
    Object.entries(legacyAnnotationsByArtboard),
  ] as const
}

const useGetAnnotationDots = (
  variables: GetAnnotationDotsQueryVariables,
  disabled: boolean = false
) => {
  const { subjects, subject } = variables

  const skipLoadingAnnotations =
    !(Number(subjects?.length) > 0 || subject) || disabled

  const [pageLoadingError, setPageLoadingError] = useState<Error | null>(null)
  const {
    loading: queryLoading,
    error: queryError,
    data,
    fetchMore,
  } = useGetAnnotationDotsQuery({
    skip: skipLoadingAnnotations,
    variables,
    fetchPolicy: 'cache-and-network',
    shouldInvalidatePrevious: (prev, curr) =>
      prev?.versionIdentifier !== curr?.versionIdentifier,
  })

  const after = data?.annotations?.meta?.after
  const hasPendingPages = !!after

  /**
   * This hook will be in-charge of loading
   * the follow up annotation dot pages so we can
   * have the complete list then represent them
   */
  useEffect(() => {
    if (!after || pageLoadingError) {
      return
    }

    const timeout = setTimeout(() => {
      handleFetchMore(fetchMore, ENTRIES_PATH, {
        dataIdFromObject,
        after,
      })().catch(setPageLoadingError)
    }, WAIT_BETWEEN_PAGES_LOADING)

    return () => {
      clearTimeout(timeout)
    }
  }, [pageLoadingError, after, fetchMore])

  /**
   * The "annotationList" will take care of hiding
   * the intermediate results until we have the full composed
   * list it will also return a blank array when the annotation
   * should be hidden
   */
  const annotationList =
    skipLoadingAnnotations || hasPendingPages
      ? EMPTY_ANNOTATION_ARRAY
      : data?.annotations.entries || EMPTY_ANNOTATION_ARRAY

  /**
   * Distinguish between floating annotation and legacy
   * ones so we render them separately
   */
  const [annotations, legacyAnnotations] = useMemo(
    () => formatAnnotationDotsResponse(annotationList),
    [annotationList]
  )

  if (queryError || pageLoadingError) {
    return { loading: false, annotations: [], legacyAnnotations: [] }
  }

  return {
    loading: queryLoading || hasPendingPages,
    annotations,
    legacyAnnotations,
  } as const
}

export default useGetAnnotationDots
