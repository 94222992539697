import styled from 'styled-components'

import { ButtonUnstyled } from '@sketch/components'

const Selector = styled(ButtonUnstyled)`
  padding: 8px;
  line-height: 0;
  transition: 100ms background-color linear, 100ms color linear,
    100ms opacity linear;

  border-radius: ${({ theme }) => theme.radii.large};
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  & [role='img'] {
    width: 16px;
    height: 16px;
  }

  &[aria-expanded='true'],
  &:hover {
    background-color: ${({ theme }) => theme.colors.state.hover};
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }
`

export default Selector
