import React, { useState } from 'react'

import { noop } from '@sketch/utils'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import {
  Button,
  Text,
  Checkbox,
  Modal,
  ModalInjectedProps,
  Link,
} from '@sketch/components'

import {
  useUserAcceptTosAgreementMutation,
  GetInitialUserDocument,
} from '@sketch/gql-types'
import { useSignOut } from '@sketch/modules-common'

interface TosMandatoryModalProps extends ModalInjectedProps {}

const TosMandatoryModal: React.FC<TosMandatoryModalProps> = ({ hideModal }) => {
  const [hasAcceptedTos, setTos] = useState(false)

  const [agreeToTos, { loading }] = useUserAcceptTosAgreementMutation({
    redirectErrors: false,
    onError: 'show-toast',
    onCompleted: data => {
      if (data.userAcceptTosAgreement?.successful) {
        hideModal()
      }
    },
    refetchQueries: [{ query: GetInitialUserDocument }],
    awaitRefetchQueries: true,
  })
  const signOut = useSignOut({ reason: 'User rejected to accept ToS' })

  return (
    <Modal onCancel={noop}>
      <Modal.Header>Terms of Service</Modal.Header>
      <Modal.Body>
        <Text>
          To access your Workspace, please read and accept the Terms of Service
          and Privacy Policy. If you have any questions, please email{' '}
          <Link href="mailto:licensing@sketch.com" external>
            licensing@sketch.com
          </Link>
          .
        </Text>
        <label className="sr-only" htmlFor="tos-check">
          I accept the terms of service.
        </label>
        <Checkbox
          id="tos-check"
          checked={hasAcceptedTos}
          onChange={e => setTos(e.target.checked)}
        >
          I&apos;ve read and agree to the{' '}
          <Link href={`${SKETCH_WEBSITE}/tos`} external>
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link href={`${SKETCH_WEBSITE}/privacy`} external>
            Privacy Policy
          </Link>
        </Checkbox>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          disabled={loading}
          onClick={() => {
            signOut().then(() => hideModal())
          }}
        >
          Sign Out
        </Button>
        <Button
          variant="primary"
          disabled={!hasAcceptedTos}
          loading={loading}
          onClick={() => agreeToTos()}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default TosMandatoryModal
