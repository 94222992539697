import { PureQueryOptions } from 'apollo-client'
import { MutationHookOptions as OriginalMutationHookOptions } from 'react-apollo'

import { UserOnErrorFn } from './useParseErrorHandler'

import type { UserErrorFragment } from './UserErrorFragment.type'
import type { MutationHookOptions } from './useMutation.types'

export const findUserErrors = (
  data: Record<string, any> | undefined | null
): UserErrorFragment[] => {
  if (!data || typeof data !== 'object') return []

  return Object.values(data)
    .filter(
      value =>
        !!value &&
        typeof value === 'object' &&
        Array.isArray(value.errors) &&
        value.errors[0]?.__typename === 'UserError'
    )
    .flatMap(value => value.errors as UserErrorFragment)
}

export interface ExtraOptions {
  onUserErrorHandler: UserOnErrorFn | undefined
}

export interface MutationHookOptionsForErrorRedirect<Data, Variables>
  extends Pick<
    MutationHookOptions<Data, Variables>,
    'redirectErrors' | 'onCompleted' | 'update' | 'refetchQueries'
  > {}

export function useErrorRedirect<Data, Variables>(
  options: MutationHookOptionsForErrorRedirect<Data, Variables>,
  extraOptions: ExtraOptions
): OriginalMutationHookOptions<Data, Variables> {
  const { redirectErrors, onCompleted, update, refetchQueries } = options || {}
  const { onUserErrorHandler } = extraOptions

  if (!redirectErrors) {
    // if we don't need to redirect errors
    // then we don't want to override any of the existing options
    return {}
  }

  return {
    refetchQueries: (...args: any[]): Array<string | PureQueryOptions> => {
      const { data } = args[0]
      if (!refetchQueries || findUserErrors(data).length > 0) {
        return []
      }

      if (typeof refetchQueries === 'function') {
        return refetchQueries(...(args as [any])) || []
      }

      return (refetchQueries as Array<string | PureQueryOptions>) || []
    },
    update: (proxy, mutationResult) => {
      if (findUserErrors(mutationResult.data).length > 0) {
        return
      }
      update?.(proxy, mutationResult as any)
    },
    onCompleted: data => {
      const userErrors = findUserErrors(data)
      if (userErrors.length > 0) {
        onUserErrorHandler?.(userErrors)
      } else {
        onCompleted?.(data as any)
      }
    },
  }
}
