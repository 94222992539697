import React, { useRef, useState } from 'react'

import { Breakpoints } from '@sketch/global-styles'

import { SelectList } from '../SelectList'
import { MenuContextProvider, useSelectDropdownMenu } from './menuContext'
import { useBreakpoint } from '../../Breakpoint'

type ButtonOptions = {
  active: boolean
  onClick: () => void
}

interface SubMenuTriggerProps<T, U> {
  items: T[] | ReadonlyArray<T>
  setItem?: (item: T) => void

  value: U
  headerItem?: React.ReactNode
  footerItem?: React.ReactNode
  disabled?: boolean
  usePortal?: boolean
  breakpoint?: Breakpoints
  forceExpanded?: boolean

  renderTrigger: (props: TriggerProps) => React.ReactNode
  renderItem: (item: T, options: ButtonOptions) => React.ReactNode
}

export interface TriggerProps {
  active: boolean
  onClick: () => void
  forceHover: boolean
  ref: React.RefObject<HTMLButtonElement>
}

/**
 * Beware of using this component because of mobile viewports!
 * Read more on the onClick trigger below.
 */
export function SelectDropdownSubMenu<T, U>(props: SubMenuTriggerProps<T, U>) {
  const {
    items,
    setItem,

    value,
    headerItem,
    footerItem,
    disabled = false,
    usePortal = false,
    breakpoint = 'sm',

    renderTrigger,
    renderItem,
  } = props

  const [expanded, setExpanded] = useState(false)
  const [forceExpanded, setForceExpanded] = useState(false)

  const buttonRef = useRef<HTMLButtonElement>(null)
  const shouldRenderDropdown = useBreakpoint(breakpoint)

  const context = useSelectDropdownMenu()
  const showDropdown = (forceExpanded || expanded) && !disabled

  const parentActive = context.activePath.includes(value)

  const children = (
    <>
      {items.map((item: T, index: number) => (
        <React.Fragment key={index}>
          {renderItem(item, {
            active: parentActive && context.activePath.includes(item),
            onClick: () => {
              context.setValue(item, [value, item])
              setExpanded(() => false)

              // Call the external "setItem" in case it exists
              setItem?.(item)
            },
          })}
        </React.Fragment>
      ))}
    </>
  )

  const triggerOnClick = () => {
    !shouldRenderDropdown && setExpanded(value => !value)
  }

  return (
    <div
      onMouseEnter={() => {
        shouldRenderDropdown && !expanded && setExpanded(() => true)

        if (forceExpanded) {
          setForceExpanded(false)
          context.setForceExpanded(false)
        }
      }}
      onMouseLeave={() => {
        shouldRenderDropdown && expanded && setExpanded(() => false)
      }}
    >
      {/* Render the trigger as a customizable item */}

      {renderTrigger({
        active: parentActive,
        onClick: triggerOnClick,
        forceHover: expanded,
        ref: buttonRef,
      })}

      <SelectList
        expanded={showDropdown}
        placement="right"
        headerItem={headerItem}
        footerItem={footerItem}
        setExpanded={getState => {
          setExpanded(getState(expanded, buttonRef.current!))
        }}
        usePortal={usePortal}
        breakpoint={breakpoint}
      >
        <MenuContextProvider
          value={{
            ...context,
            setValue: (item, path) => {
              context.setValue(item, [value, ...path])
              setExpanded(() => false)
            },
            forceExpanded,
            setForceExpanded: (forceExpanded: boolean) => {
              setForceExpanded(forceExpanded)
              context.setForceExpanded(forceExpanded)
            },
          }}
        >
          {children}
        </MenuContextProvider>
      </SelectList>
    </div>
  )
}
