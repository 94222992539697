import { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useURLQuerySearch } from '@sketch/utils'

interface useSearchReturn {
  search: string
  searchDebounced: string
  setSearch: (search: string) => void
}

/**
 * Custom hook to get values from URL related to the search.
 */
export const useSearch = (): useSearchReturn => {
  const history = useHistory()
  /* XXX: we use `useLocation` rather than `history.location` as
   * `history.location` is **mutable** meaning it can lead to very unexpected
   * bugs as it does not play nicely with our normal assumptions about React
   * life-cycle events.
   */
  const location = useLocation()
  const [search, setSearch, searchDebounced] = useURLQuerySearch(
    location,
    history
  )

  const memoizedValue = useMemo(
    () => ({
      search,
      searchDebounced,
      setSearch,
    }),
    [search, searchDebounced, setSearch]
  )

  return memoizedValue
}
