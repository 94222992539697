import { useMemo } from 'react'
import {
  PresentationManifest,
  isDefinedPresentationManifestArtboard,
} from '@sketch-hq/sketch-web-renderer'
import config from '@sketch/env-config'

type URLMap = Record<string, string>
type ImageTokens = Record<string, string>

type Result = {
  imagesURLMap: URLMap
  fragmentsURLMap: URLMap
}

/**
 * Build image and fragment URL maps so the renderer can map image and fragment
 * ids to their real valid urls.
 */
export function useURLMaps(
  manifest: PresentationManifest | null,
  imageTokens: ImageTokens | null
): Result | null {
  return useMemo(() => {
    if (manifest === null || imageTokens === null) return null

    const urlMaps: Result = {
      imagesURLMap: {},
      fragmentsURLMap: {},
    }

    for (const page of manifest.contents.pages) {
      const artboards =
        page.artboards?.filter(isDefinedPresentationManifestArtboard) ?? []

      for (const artboard of artboards) {
        const fragId = artboard.fragment.path.split('.').shift()

        if (fragId) {
          urlMaps.fragmentsURLMap[fragId] = `${
            config.web_renderer_image_domain
          }/presentation/p/${page.id}/f/${fragId}?token=${imageTokens[page.id]}`
        }

        const images = artboard.assets ?? []

        for (const image of images) {
          const imageId = image.path.split('.').shift()

          if (imageId) {
            urlMaps.imagesURLMap[imageId] = `${
              config.web_renderer_image_domain
            }/presentation/p/${page.id}/i/${imageId}?token=${
              imageTokens[page.id]
            }&format=:format`
          }
        }
      }
    }

    return urlMaps
  }, [manifest, imageTokens])
}
