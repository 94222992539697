import styled from 'styled-components'
import { Filter, Flex, Text, Modal } from '@sketch/components'

const ROW_HEIGHT = 60

export const StyledModal = styled(Modal)`
  max-width: 705px;
`

export const StyledFilter = styled(Filter)`
  margin: 16px 0;
`
// The minimum height should be the height of the title + subtitle
// of the empty state
export const ListWrapper = styled.div<{ $rows: number }>`
  height: ${({ $rows }) => $rows * ROW_HEIGHT}px;
  min-height: 120px;
  overflow-y: auto;
`

export const EmptyStateWrapper = styled(Flex)`
  height: 100%;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const EmptyTitle = styled(Text.H5)`
  margin: 0;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`

export const EmptySubtitle = styled(Text)`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`
