import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "canvas-page-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M13.75 11.75v-7.5a1 1 0 00-1-1h-9.5a1 1 0 00-1 1v7.5a1 1 0 001 1h9.5a1 1 0 001-1z", strokeOpacity: 0.15, stroke: "currentColor", strokeWidth: 1.5 }),
        React.createElement("path", { d: "M12.75 2.5h-9.5A1.75 1.75 0 001.5 4.25v7.5c0 .966.784 1.75 1.75 1.75h9.5a1.75 1.75 0 001.75-1.75v-7.5a1.75 1.75 0 00-1.75-1.75z", fillOpacity: 0.15, fill: "currentColor", fillRule: "nonzero" }),
        React.createElement("path", { d: "M6 3.25H3.25a1 1 0 00-1 1V7h0M10 12.75h2.75a1 1 0 001-1V9h0", stroke: "currentColor", strokeWidth: 1.5, strokeLinecap: "round" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
