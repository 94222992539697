import React, { useCallback, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Modifier } from '@popperjs/core'
import { Mention, MentionsInputProps, SuggestionDataItem } from 'react-mentions'

import { getEmojisListBySearch, getUsersListBySearch } from '@sketch/utils'
import { strippedString } from './utils'

import { useBreakpoint } from '../Breakpoint'
import { Truncate } from '../Truncate'
import { PersonAvatar } from '../PersonAvatar'
import { ListItem } from '../List'
import { useResponsiveDropdown } from '../ResponsiveDropdown'

import {
  MentionWrapper,
  MentionsInput,
  MentionPopoverFakeButton,
} from './SuggestionsInput.styles'

interface UserSuggestionDataItem extends SuggestionDataItem {
  avatar: string
}

type UserMentionCallback = () => [
  string,
  (user: UserSuggestionDataItem[]) => void
]

const POPOVER_MODIFIERS: Partial<Modifier<any, any>>[] = [
  /**
   * This modifier will allow the popover to be the same
   * width as the reference element
   */
  {
    name: 'makeSameWidth',
    phase: 'beforeWrite' as const,
    enabled: true,
    fn({ state }) {
      const { popper, reference } = state.elements || {}

      if (!(reference && reference instanceof HTMLElement)) {
        return
      }

      const { width } = reference.getBoundingClientRect()
      popper.style.width = `${width}px`
    },
  },
  {
    name: 'computeStyles',
    options: { gpuAcceleration: true },
  },
]

const POPOVER_OFFSET = [0, 0] as [number, number]

const MENTIONS_STYLE = {
  suggestions: {
    top: 'auto',
    bottom: '1.5em',
    marginTop: '0px',
    background: 'none',
    width: '100%',
    position: 'sticky',
  },
  '&multiLine': {
    control: {
      minHeight: 0,
      lineHeight: '1.25rem',
      position: 'relative',
    },
    highlighter: {
      padding: 0,
      border: '0px solid transparent',
      lineHeight: '1.25rem',
      strong: {
        fontWeight: 'bold',
      },
    },
    input: {
      marginLeft: 0,
      padding: 0,
      border: '0px solid transparent',
      outline: 'none',
      lineHeight: '1.25rem',
    },
  },
}

const MentionsPopoverWrapper: React.FC = ({ children }) => (
  <MentionWrapper>{children}</MentionWrapper>
)

const MentionsPopover: React.FC = ({ children }) => {
  const [content, button, { setVisible, update }] = useResponsiveDropdown({
    dropdown: MentionsPopoverWrapper,
    dropdownProps: { children },
    usePortal: true,
    placement: 'top-start',
    modifiers: POPOVER_MODIFIERS,
    /* Force the breakpoint to always show popover, even on mobile */
    dropdownBreakpoint: 'base',
    offset: POPOVER_OFFSET,
  })

  useEffect(() => {
    setVisible?.(true)
    update?.()
  }, [setVisible, update])

  return (
    <>
      <MentionPopoverFakeButton ref={button.ref} />
      {content}
    </>
  )
}

const customHighlightDisplay = (display: string, query: string) => {
  const strippedDisplay = strippedString(display)
  const strippedQuery = strippedString(query)
  const i = strippedDisplay.indexOf(strippedQuery)

  if (i === -1) {
    return display
  }

  return (
    <>
      {display.substring(0, i)}
      <b>{display.substring(i, i + query.length)}</b>
      {display.substring(i + query.length)}
    </>
  )
}

export function renderEmojiSuggestion(entry: SuggestionDataItem) {
  const Icon = (props: React.ComponentProps<'div'>) => (
    <div {...props}>{entry.display}</div>
  )

  return <ListItem icon={React.createFactory(Icon)}>{entry.id}</ListItem>
}

export const renderUserSuggestion = (
  entry: SuggestionDataItem | UserSuggestionDataItem,
  search: string
) => {
  if (!('avatar' in entry)) {
    return null
  }

  return (
    <ListItem
      icon={({ className }) => (
        <PersonAvatar
          className={className}
          size="16px"
          name={entry.display || ''}
          flavour="image"
          src={entry.avatar}
        />
      )}
    >
      <Truncate>{customHighlightDisplay(entry.display || '', search)}</Truncate>
    </ListItem>
  )
}

export interface SuggestionsInputProps
  extends OmitSafe<MentionsInputProps, 'children' | 'onSubmit'> {
  mentionableUsers: UserSuggestionDataItem[]
}

const SuggestionsInputUnstyled = (props: SuggestionsInputProps) => {
  const { mentionableUsers, style, ...otherProps } = props

  const isDesktop = useBreakpoint('sm')

  const mentionCallback = useRef<UserMentionCallback | null>(null)
  const searchEmoji = useCallback((search: string) => {
    return getEmojisListBySearch(search.toLowerCase())
  }, [])

  const searchUser = useCallback(
    (search: string) =>
      getUsersListBySearch(mentionableUsers, search.toLowerCase()),
    [mentionableUsers]
  )

  useEffect(() => {
    if (!mentionCallback.current) {
      return
    }

    const [search, callback] = mentionCallback.current()
    callback(searchUser(search))
  }, [searchUser, mentionableUsers])

  const userMention = (
    <Mention
      key="user-mention"
      trigger="@"
      markup="@[__display__](user:__id__)"
      data={(search, callback) => {
        callback(searchUser(search))
        mentionCallback.current = () => [search, callback]
      }}
      displayTransform={(id, display) => `@${display}`}
      renderSuggestion={renderUserSuggestion}
      appendSpaceOnAdd
    />
  )

  const emojiMention = (
    <Mention
      key="emoji-mention"
      trigger=":"
      markup="__display__"
      regex={/($a)/}
      data={searchEmoji}
      renderSuggestion={renderEmojiSuggestion}
      appendSpaceOnAdd
    />
  )

  return (
    <MentionsInput
      placeholder="Write a comment..."
      allowSpaceInQuery
      customSuggestionsContainer={children => (
        <MentionsPopover>{children}</MentionsPopover>
      )}
      {...otherProps}
      style={MENTIONS_STYLE}
    >
      {isDesktop ? [userMention, emojiMention] : [userMention]}
    </MentionsInput>
  )
}

export const SuggestionsInput = styled(SuggestionsInputUnstyled)``
