import { useEffect, useRef } from 'react'

type OptionalPropertyNames<T> = {
  [K in keyof T]-?: {} extends { [P in K]: T[K] } ? K : never
}[keyof T]

type SpreadProperties<L, R, K extends keyof L & keyof R> = {
  [P in K]: L[P] | Exclude<R[P], undefined>
}

type Id<T> = T extends infer U ? { [K in keyof U]: U[K] } : never

type SpreadTwo<L, R> = Id<
  Pick<L, Exclude<keyof L, keyof R>> &
    Pick<R, Exclude<keyof R, OptionalPropertyNames<R>>> &
    Pick<R, Exclude<OptionalPropertyNames<R>, keyof L>> &
    SpreadProperties<L, R, OptionalPropertyNames<R> & keyof L>
>

type Spread<A extends readonly [...any]> = A extends [infer L, ...infer R]
  ? SpreadTwo<L extends object ? L : unknown, Spread<R>>
  : unknown

// eslint-disable-next-line @typescript-eslint/ban-types
type PossibleTypes = Record<any, Function | any> | null | false

const createProxyHandler = <V extends PossibleTypes>(
  values: React.RefObject<V[]>
) => {
  const getObjectValue = (key: string | symbol) => {
    const objectsArray = values.current!
    const typedKey = key as keyof V
    let returnValue = null

    for (const object of objectsArray) {
      if (!object) {
        /**
         * This array item can be null or false
         * because of conditional items, so let's move to the next one
         */
        continue
      }

      const value = object[typedKey]

      if (!value) {
        /**
         * There's no value in the pair [object key]
         * but there can be in another object on the array
         */
        continue
      }

      /**
       * If the value is a function we can group with
       * other functions on other array items calling them
       * when the proxy element is called
       */
      if (typeof value === 'function') {
        /**
         * We return because there's no point on doing the same for the other
         * items
         */

        return (...args: any) => {
          values.current?.forEach(object => {
            if (
              object &&
              object[typedKey] &&
              typeof object[typedKey] === 'function'
            ) {
              object[typedKey](...args)
            }
          })
        }
      }

      /**
       * Save the value in case any
       * of the array items doesn't have them defined
       * if it has it will replace it
       */
      returnValue = value
    }

    return returnValue
  }

  return {
    ownKeys() {
      const objectKeys = values.current?.map(object => {
        if (!object) {
          return []
        }

        return Object.keys(object)
      })

      return objectKeys?.flat() || []
    },
    getOwnPropertyDescriptor(target, key) {
      const value = getObjectValue(key)
      return { configurable: true, enumerable: true, value }
    },
    get(target, key) {
      return getObjectValue(key)
    },
  } as ProxyHandler<any>
}

/**
 * useObjectProxyAccumulator
 *
 * This hook allows objects to be passed as arguments and be combined
 * into a proxy object that will read from the passed objects given the accessed properties
 *
 * This allows a continuos ref to be passed and not a dynamic value
 */
export const useObjectProxyAccumulator = <T extends readonly PossibleTypes[]>(
  ...values: [...T]
) => {
  const cacheValues = useRef(values)
  useEffect(() => {
    cacheValues.current = values
  }, [values])

  return useRef(
    new Proxy({} as any, createProxyHandler(cacheValues)) as Spread<T>
  )
}
