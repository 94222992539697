import React, { FC } from 'react'

import {
  PublicWorkspaceFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

import { IS_EMBEDDED } from '@sketch/constants'

import { Tooltip } from '@sketch/components'
import { SketchHomeButton, useUserAuthorizations } from '@sketch/modules-common'

import { useSignInToUnauthorizedWorkspace } from '../../../sso/operations'

import HeaderWorkspaceBadge from '../HeaderWorkspaceBadge'
import { DocumentSidebarLayoutExtraProps } from '../DocumentSidebarLayout/types'

export interface HeaderBadgeProps {
  setSidebarLeftOpen?: DocumentSidebarLayoutExtraProps['setSidebarLeftOpen']
  workspace: PublicWorkspaceFragment | WorkspaceMinimalFragment
}

const HeaderBadge: FC<HeaderBadgeProps> = props => {
  const { workspace, setSidebarLeftOpen } = props
  const { signIn } = useSignInToUnauthorizedWorkspace()
  const { hasAccessToWorkspace } = useUserAuthorizations()

  /**
   * Embedded users can only use the token they already have set
   * no change account action should be required
   */
  if (IS_EMBEDDED) {
    return <SketchHomeButton />
  }

  /**
   * If it's a "Workspace" typename we already know the user is a member
   * the doubt will be on the "PublicWorkspace" type hence the usage of
   * "userIsMember"
   */
  const isUserWorkspaceMember =
    workspace.__typename === 'Workspace' || workspace.userIsMember

  /**
   * Same logic applies as "isUserWorkspaceMember" applies here,
   * however we validate if the credentials are in place for workspace requests
   */
  const doesUserHaveAccessToWorkspace =
    workspace.__typename === 'Workspace' ||
    (workspace.userRole &&
      hasAccessToWorkspace({
        ...workspace,
        userRole: workspace.userRole,
      }))

  if (isUserWorkspaceMember && doesUserHaveAccessToWorkspace) {
    /**
     * In this case, we have validated that the user has the
     * credentials to access the workspace and that is a member
     * so he can perform the default action correctly, usually
     * opening the sidebar
     */
    return (
      <HeaderWorkspaceBadge
        onBadgeClick={() => setSidebarLeftOpen?.(true)}
        onKeyDown={e => {
          e.preventDefault()
          if (['Enter', ' '].includes(e.key)) {
            setSidebarLeftOpen?.(true)
            // also focus on the navbar that has just been opened
            // setTimeout needed because otherwise the focus doesn't apply
            setTimeout(
              () =>
                document
                  .querySelector<HTMLElement>('[data-testid="navbar-title"]')
                  ?.focus(),
              0
            )
          }
        }}
        workspace={props.workspace}
      />
    )
  } else if (isUserWorkspaceMember) {
    /**
     * In here, we have found that the user is a workspace member but is doesn't
     * have the credential set to see the workspace. Either because:
     *  - User is a authenticated as a SSO account checking a public share from a regular workspace were he is a member on the personal authorization
     *  - User is a authenticated as the personal account checking a public share from a SSO workspace were he is a member with a proper SSO authorization
     *
     * So we need to sign the user in to allow him to see the sidebar
     */
    return (
      <Tooltip
        content="You’re not signed in to this Workspace. Sign in"
        placement="bottom"
      >
        <HeaderWorkspaceBadge
          onBadgeClick={() => signIn({ workspace })}
          workspace={props.workspace}
        />
      </Tooltip>
    )
  }

  /**
   * The user is not a member of the workspace which contains this share
   */
  return <SketchHomeButton />
}

export default HeaderBadge
