import { DataProxy } from 'apollo-cache'
import { removeFromPaginated } from '@sketch/modules-common'

export interface HandleShareDeletedPermanentlyProps {
  cache: DataProxy
  identifier: string
}

export const handleShareDeletedPermanently = (
  props: HandleShareDeletedPermanentlyProps
) => {
  const { cache, identifier } = props

  // ProjectFragment should be removed once BE returns the newer types in the getTrash
  // https://github.com/sketch-hq/Cloud/issues/11226
  removeFromPaginated(
    cache,
    { __typename: 'Share', identifier },
    key => key.includes('workspaceTrash') || key.includes('.shares')
  )

  // even if the share is null here,
  // there still can be smaller fragments in the cache
  // let's remove all of them.
  removeFromPaginated(
    cache,
    { __typename: 'TrashedShare', identifier },
    key => key.includes('workspaceTrash') || key.includes('.shares')
  )
}
