import React, { FC } from 'react'

import { LoadingPlaceholder } from '@sketch/components'
import BillingInfoTable from '../BillingInfoTable'

import { formatPriceShort } from '@sketch/utils'
import config from '@sketch/env-config'

import {
  BillingSeatsInfoFragment,
  useDraftBillingSummaryQuery,
} from '@sketch/gql-types'

interface ProjectedTotalProps {
  seats: BillingSeatsInfoFragment
  projectedCost?: number
  hasSubscription?: boolean
}

type ProjectedTotalValueProps = {
  projectedCost?: number
  hasSubscription?: boolean
  billingCycleAmountYearly?: number
  billingCycleAmountMonthly?: number
  billingCycleAmoungBusiness?: number
}

const ProjectedTotalValue: React.FC<ProjectedTotalValueProps> = ({
  hasSubscription,
  projectedCost,
  billingCycleAmountYearly,
  billingCycleAmountMonthly,
  billingCycleAmoungBusiness,
}) => {
  return (
    <div>
      {hasSubscription ? (
        <strong data-testid="projected-total-amount">
          {formatPriceShort(projectedCost!)}
        </strong>
      ) : (
        <span data-testid="draft-amount">
          <strong>{formatPriceShort(billingCycleAmountYearly!)}</strong> yearly
          or <strong>{formatPriceShort(billingCycleAmountMonthly!)}</strong>{' '}
          monthly for the Standard plan or{' '}
          <strong>{formatPriceShort(billingCycleAmoungBusiness!)}</strong>{' '}
          yearly for the Business plan
        </span>
      )}
      <br />
      <div>
        {hasSubscription
          ? 'Total excluding tax'
          : 'With a paid subscription you would be charged for the total number of Editor Seats you’re using. Total excluding tax.'}
      </div>
    </div>
  )
}

const ProjectedTotal: FC<ProjectedTotalProps> = ({
  projectedCost,
  hasSubscription,
  seats,
}) => {
  const plansId = 'pro_plans_ids'

  const {
    data: draftBillingSummaryYearlyData,
    loading: loadingYearly,
  } = useDraftBillingSummaryQuery({
    variables: {
      planId: config[plansId].yearly,
      seats: seats.currentSeatsTotal,
    },
    skip: hasSubscription,
  })
  const {
    data: draftBillingSummaryMonthlyData,
    loading: loadingMonthly,
  } = useDraftBillingSummaryQuery({
    variables: {
      planId: config[plansId].monthly,
      seats: seats.currentSeatsTotal,
    },
    skip: hasSubscription,
  })
  const {
    data: draftBillingSummaryBusinessData,
    loading: loadingBusiness,
  } = useDraftBillingSummaryQuery({
    variables: {
      planId: config['business_plans_ids'].yearly,
      seats: seats.currentSeatsTotal,
    },
    skip: hasSubscription,
  })

  return (
    <BillingInfoTable.Row>
      <BillingInfoTable.HeadingCell>
        Projected Total
      </BillingInfoTable.HeadingCell>
      <BillingInfoTable.Cell>
        {loadingMonthly || loadingYearly || loadingBusiness ? (
          <LoadingPlaceholder />
        ) : (
          <ProjectedTotalValue
            hasSubscription={hasSubscription}
            projectedCost={projectedCost}
            billingCycleAmountYearly={
              draftBillingSummaryYearlyData?.draftBillingSummary
                .billingCycleAmount
            }
            billingCycleAmountMonthly={
              draftBillingSummaryMonthlyData?.draftBillingSummary
                .billingCycleAmount
            }
            billingCycleAmoungBusiness={
              draftBillingSummaryBusinessData?.draftBillingSummary
                .billingCycleAmount
            }
          />
        )}
      </BillingInfoTable.Cell>
    </BillingInfoTable.Row>
  )
}

export default ProjectedTotal
