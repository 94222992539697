import React from 'react'
import { useLocalStorage } from 'react-use'

import { useFlag } from '@sketch/modules-common'

import { UpsellModal } from '../UpsellModal'
import {
  NewFeatureHighlightPopover,
  useModalContext,
  SelectDropdownButtonItem,
} from '@sketch/components'

import { ReactComponent as DSIcon } from '@sketch/icons/design-system-16'

export const DesignSystemsNotEnabled = () => {
  const { showModal } = useModalContext()
  const isDesignSystemsOn = useFlag('design-systems')

  const [introductionSeen, setIntroductionSeen] = useLocalStorage(
    'dsm_introduction_seen'
  )

  if (!isDesignSystemsOn) {
    return null
  }

  const onLearnMore = () => {
    showModal(UpsellModal)
    setIntroductionSeen(true)
  }

  return (
    <SelectDropdownButtonItem
      icon={{
        icon: DSIcon,
        highlight: !introductionSeen,
      }}
      text="Design Systems"
      onClick={onLearnMore}
      showActionsOnHover
      action={
        <NewFeatureHighlightPopover
          highlightPlacement="sidebar"
          titleText="New: Design Systems"
          bodyText="Upgrade your Workspace Plan to create your own powerful, easy-to-manage design systems — right here in Sketch!"
          confirmCopy="Learn More"
          onConfirm={onLearnMore}
          localStorageKey="dsmIntroductionSeen"
        />
      }
    />
  )
}
