import React, { useRef, useState } from 'react'

interface FileSelectionChildrenProps {
  onSelectFile: () => void
  loading: boolean
}

interface FileSelectionProps {
  children: (props: FileSelectionChildrenProps) => React.ReactNode
  onFileSelected: (file: File) => Promise<void>
  accept: string
}

/**
 * This component lets the user select a file when performing any other action,
 * like pressing a button or selecting an item in a dropdown button.
 */
export const FileSelection = ({
  children,
  onFileSelected,
  accept,
}: FileSelectionProps) => {
  const fileUploadRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)

  const onFileChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const file = event.target.files?.[0]

    if (file) {
      setLoading(true)
      await onFileSelected(file)
      setLoading(false)
    }
  }

  return (
    <>
      <input
        data-testid="file-selection"
        type="file"
        accept={accept}
        ref={fileUploadRef}
        onChange={onFileChanged}
        hidden
      />
      {children({
        onSelectFile: () => {
          if (fileUploadRef.current) fileUploadRef.current.value = ''
          fileUploadRef.current?.click()
        },
        loading,
      })}
    </>
  )
}
