import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { FormikHelpers, useFormik, FormikProvider } from 'formik'
import * as yup from 'yup'
import { debounce } from 'lodash'

import { Skeleton } from '@sketch/components'

import { BUY_ME_A_COFFEE_URL } from '../BuyMeACoffeeButton'

import { BuyMeACoffeeInput } from '../Input'

import {
  validateURL,
  decorateURLWithProtocol,
  PROTOCOL,
} from '../Input/validators'

import { Input } from './ProfileForm.styles'

// GQL
import { useUpdateWorkspaceProfileMutation } from '@sketch/gql-types'

const DEBOUNCE_TIMEOUT = 500

interface FormValues {
  identifier: string
  description: string
  websiteUrl: string
  donateUrl: string
}

type FormActions = FormikHelpers<FormValues>

interface ProfileFormProps {
  data: FormValues
  loading: boolean
}

/**
 * Used before sending to the BE. Websites with missing
 * protocol will be prefilled so they are valid in this case.
 */
const VALID_WEBSITE_URL_STATES = ['VALID', 'MISSING_PROTOCOL']

/**
 * Validates or fixes a given URL.
 * If the protocol is missing, HTTP is prepended.
 * Show a form error if the URL is invalid.
 */
const validateOrFixWebsiteURL = (
  url: string,
  actions: Pick<FormActions, 'setFieldError' | 'setFieldValue'>
) => {
  // Don't validate if empty
  if (!url) return

  // Clear input if it only contains the protocol placeholder
  if (url === PROTOCOL) {
    actions.setFieldValue('websiteUrl', '')
    return
  }

  const validateResult = validateURL(url)

  if (validateResult === 'MISSING_PROTOCOL') {
    url = decorateURLWithProtocol(url)
    actions.setFieldValue('websiteUrl', url)
  } else if (validateResult === 'INVALID') {
    actions.setFieldError('websiteUrl', 'Invalid URL')
  }
}

// Adds the protocol (http://) if the URL is missing one
const prefixProtocolToUrl = (url: string) =>
  validateURL(url) === 'MISSING_PROTOCOL' ? decorateURLWithProtocol(url) : url

// Validation
const validationSchema = yup.object().shape({
  description: yup.string().trim(),
  websiteUrl: yup.string().trim(),
  donateUrl: yup.string().trim(),
})

export const ProfileForm: FC<ProfileFormProps> = ({ data, loading }) => {
  const isFormDirty = useRef(false)

  /**
   * This intermediary state will validate if the inputs
   * has already submitted a BE request, and ignore the data change (from BE)
   * until there's no request in flight.
   *
   * Use-cases:
   * - If a user clicks a letter and then another one between the 500ms the debounce will take care of only
   * requesting the last value
   * - If a user clicks a letter and then another one after the 500ms threshold a request will be in flight
   * given there could be 2 requests in flight at the same time (depending on the response time) the "countPendingBEUpdate"
   * will only make the formik reflect the new data state when there's none, so we don't receive the previous request
   * data then a flicker
   */
  const [state, setState] = useState(data)
  const countPendingBEUpdate = useRef(0)

  useEffect(() => {
    if (isFormDirty.current && countPendingBEUpdate.current === 0) {
      /**
       * This scenario means the user update the input right in the moment
       * where the request response has reached back creating a moment where
       * the form is dirty from the input changes and has new data to update.
       *
       * In this situation we should allow the dirty data to be submitted and ignore
       * the data that arrived from the network
       *
       * https://github.com/sketch-hq/Cloud/issues/15522#issuecomment-1311753952
       */
      return
    }

    if (countPendingBEUpdate.current > 0) {
      return
    }

    setState(data)
  }, [data])

  const [updateProfile] = useUpdateWorkspaceProfileMutation({
    onError: 'show-toast',
    update: () => {
      /**
       * We decrement the "countPendingBEUpdate" here because the "onComplete"
       * callback is called after the data props have already been updated causing
       * the logic to break, this way we warranty that the count logic works correctly.
       */
      countPendingBEUpdate.current--
    },
  })

  /**
   * The prevent updates char by char, the submit is debounced by 500ms (check DEBOUNCE_TIMEOUT).
   * After that time is passed we validate if the form is dirty (preventing unneeded updates) and
   * warning there's a pending BE update.
   */
  const debouncedUpdateProfile = useMemo(
    () =>
      debounce((...params) => {
        if (!isFormDirty.current) {
          return
        }

        countPendingBEUpdate.current++

        return updateProfile(...params)
      }, DEBOUNCE_TIMEOUT) as typeof updateProfile,
    [updateProfile]
  )

  const handleSubmit = (formData: FormValues, actions: FormActions) => {
    const isWebsiteURLEmpty = !formData.websiteUrl.length
    const isWebsiteURLValid = VALID_WEBSITE_URL_STATES.includes(
      validateURL(formData.websiteUrl)
    )

    // This avoids sending invalid urls to the backend.
    // onBlur the user gets the field error message.
    if (!isWebsiteURLValid && !isWebsiteURLEmpty) return

    // If the user is typing a url without protocol (which is required)
    // and the debounce triggers the mutation, the BE throws an error
    // Here we are allowing the user to type a url without protocol (example.com)
    // but we inject the protocol (http://) when we run the mutation
    const validWebsiteUrl = prefixProtocolToUrl(formData.websiteUrl)
    const validDonateUrl =
      formData.donateUrl &&
      prefixProtocolToUrl(`${BUY_ME_A_COFFEE_URL}${formData.donateUrl}`)

    const input = {
      identifier: formData.identifier,
      description: formData.description,
      websiteUrl: validWebsiteUrl,
      donateUrl: validDonateUrl,
    }

    debouncedUpdateProfile({ variables: { input } })
  }

  const formikBag = useFormik({
    initialValues: {
      ...state,
      // BE sends the whole url value, but we only show the last bit
      donateUrl: state.donateUrl.replace(BUY_ME_A_COFFEE_URL, ''),
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnBlur: false,
  })

  const {
    setFieldError,
    setFieldValue,
    setFieldTouched,
    submitForm,
    dirty,
    resetForm,
  } = formikBag

  /**
   * After the new state is updated, we force reset the form
   * to confirm that the states are synced between FE and BE allowing the "dirty" value to be
   * reset to "false", given we have a new state.
   */
  useEffect(() => {
    resetForm()
  }, [state, resetForm])

  /**
   * Since the "dirty" value is only available after the formik
   * is hook mounted and because we use it on the "debouncedUpdateProfile" we
   * needed to have this value saved in a ref so it could be used in these concurrent
   * methods
   */
  isFormDirty.current = dirty

  if (loading) {
    return (
      <>
        <Skeleton width="320px" height="48px" style={{ marginBottom: 12 }} />
        <Skeleton width="470px" height="30px" style={{ marginBottom: 8 }} />
        <Skeleton width="350px" height="30px" style={{ marginBottom: 8 }} />
        <Skeleton width="350px" height="30px" />
      </>
    )
  }

  return (
    <FormikProvider value={formikBag}>
      <Input name="description" placeholder="Say something about yourself " />
      <Input
        name="websiteUrl"
        placeholder="Add a link to a website"
        width="350px"
        onFocus={() => {
          setFieldTouched('websiteUrl', false)
        }}
        onPaste={() => {
          // Empty input value
          setFieldValue('websiteUrl', '')
        }}
        onBlur={event =>
          validateOrFixWebsiteURL(event.target.value, {
            setFieldValue,
            setFieldError,
          })
        }
      />
      <BuyMeACoffeeInput
        name="donateUrl"
        width="350px"
        placeholder="Add a Buy Me a Coffee button"
        onAddClick={submitForm}
      />
    </FormikProvider>
  )
}
