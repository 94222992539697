import styled from 'styled-components'
import { Modal } from '@sketch/components'

export const MinHeightModal = styled(Modal)`
  overflow-y: hidden;
  max-height: 85%;
  min-height: 550px;
`

export const ModalBody = styled(Modal.Body)`
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
`

export const ModalFooterZIndex = styled(Modal.Footer)`
  z-index: 1;
`
