import React from 'react'
import { Route } from 'react-router-dom'

import { routes } from '@sketch/modules-common'
import { WorkspaceSidebarLayoutExtraProps } from 'cloud-frontend'

import GeneralSettings from './GeneralSettings'
import AppearanceSettings from './AppearanceSettings'

import { PageWrapper } from './UserSettingsView.styles'

export const UserSettingsView = (props: WorkspaceSidebarLayoutExtraProps) => {
  const { workspace, useOverrideLayoutProps } = props

  useOverrideLayoutProps({
    title: 'Settings',
  })

  return (
    <>
      <PageWrapper>
        <Route
          exact
          path={[
            routes.PERSONAL_SETTINGS.template(),
            routes.PERSONAL_SETTINGS_UNSUBSCRIBE.template(),
          ]}
        >
          <GeneralSettings currentWorkspace={workspace} />
        </Route>
        <Route exact path={[routes.APPEARANCE_SETTINGS.template()]}>
          <AppearanceSettings />
        </Route>
      </PageWrapper>
    </>
  )
}
