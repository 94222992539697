import React from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { History } from 'history'

import CoreLayout from '../layouts/CoreLayout'

import { DocumentHead, ModalRoot } from '@sketch/components'

import { Toasts } from '@sketch/toasts'
import {
  EmbedGate,
  EmbedSignInGate,
  SketchSource,
  routes,
} from '@sketch/modules-common'

import {
  PrivateRoute,
  AcceptShareAccessRequestView,
  AcceptProjectAccessRequestView,
  WorkspaceRedirectRoute,
  ServiceWorker,
  VerifiedUsersRoute,
  UserContext,
  WebRendererLogging,
} from 'cloud-frontend'
import { DevErrors } from '@sketch/devtools'
import {
  TelemetryConfig,
  PageLoadAnalytics as PageLoadAnalyticsRaw,
} from '@sketch/analytics'
import { EmailVerifyView } from '@sketch/sub-app-authentication'
import CookieConsent from './CookieConsent'

import Providers from './Providers'
import { ForceAppRefresh } from './ForceAppRefresh'
import {
  AcceptInviteRoutes,
  allAcceptInviteRouteTemplates,
  AuthRoutes,
  allAuthRouteTemplates,
} from '../routing'

const DevTools = React.lazy(
  () => import(/* webpackChunkName: "devtools" */ './DevTools')
)

const PageWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`

const PageLoadAnalytics = () => <PageLoadAnalyticsRaw />

interface AppProps {
  history: History
}

const App: React.FC<AppProps> = ({ history }) => {
  return (
    <Providers history={history}>
      <Helmet>
        <DocumentHead />
      </Helmet>

      <ServiceWorker />
      <ForceAppRefresh />
      <TelemetryConfig />
      <PageLoadAnalytics />
      <SketchSource />
      <WebRendererLogging />
      {(process.env.REACT_APP_ENV === 'dev' ||
        process.env.REACT_APP_ENV === 'test') && (
        <>
          {/**
           * Warnings that may appear before this render
           * (e.g. in the <Providers />), will not be reported by showing a
           * toast, however, that is an acceptable tradeoff for us */}
          {!window.Cypress && <DevErrors />}
          <React.Suspense fallback={null}>
            <DevTools />
          </React.Suspense>
        </>
      )}

      <EmbedSignInGate>
        <EmbedGate>
          <PageWrapper>
            {/*
              This component is the first to get the User Profile data
              Make sure its one of the first components to load
             */}
            <UserContext />

            <ModalRoot />
            <Switch>
              <Route
                exact
                path={allAcceptInviteRouteTemplates}
                component={AcceptInviteRoutes}
              />
              <Route
                exact
                path={allAuthRouteTemplates}
                component={AuthRoutes}
              />

              {/* enter from email */}
              <Route
                path={[routes.CONFIRM.template(), routes.ACCEPT.template()]}
                component={EmailVerifyView}
              />
              <PrivateRoute
                path={routes.SHARE_ACCEPT_ACCESS_REQUEST.template()}
                component={AcceptShareAccessRequestView}
              />
              <PrivateRoute
                path={routes.PROJECT_ACCEPT_ACCESS_REQUEST.template()}
                component={AcceptProjectAccessRequestView}
              />

              <VerifiedUsersRoute>
                <Route
                  path={[
                    routes.ROOT_LEGACY.create({}),
                    routes.ENTRY.create({}),

                    // Workspace agnostic routes
                    routes.WORKSPACE_AGNOSTIC_SETTINGS.create({}),
                    routes.WORKSPACE_AGNOSTIC_SETTINGS_SETTINGS.create({}),
                    routes.WORKSPACE_AGNOSTIC_SETTINGS_PEOPLE.create({}),
                    routes.WORKSPACE_AGNOSTIC_SETTINGS_BILLING.create({}),
                    routes.WORKSPACE_AGNOSTIC_SETTINGS_SSO.create({}),
                    routes.WORKSPACE_AGNOSTIC_DISCOVER.create({}),
                  ]}
                  component={WorkspaceRedirectRoute}
                  exact
                />
                <Route component={CoreLayout} />
              </VerifiedUsersRoute>
            </Switch>
            <CookieConsent />
            <Toasts />
          </PageWrapper>
        </EmbedGate>
      </EmbedSignInGate>
    </Providers>
  )
}

export default App
