import styled from 'styled-components'

import { Panel as PanelOld } from '@sketch/components'
import Panel, { PanelTitle, PanelBody } from './Panel'

export default Object.assign(Panel, {
  Section: PanelBody,
  Title: styled(PanelTitle)``,
  Header: PanelTitle,
  Body: PanelBody,
  Footer: PanelOld.Footer,
})
