import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "device-phone-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M9.795 14.25h-3.59c-.902 0-1.24-.067-1.572-.245a1.532 1.532 0 01-.638-.638c-.178-.332-.245-.67-.245-1.572v-7.59c0-.902.067-1.24.245-1.572.147-.275.363-.49.638-.638.332-.178.67-.245 1.572-.245h3.59c.902 0 1.24.067 1.572.245.275.147.49.363.638.638.178.332.245.67.245 1.572v7.59c0 .902-.067 1.24-.245 1.572-.147.275-.363.49-.638.638-.332.178-.67.245-1.572.245z", stroke: "currentColor", strokeWidth: 1.5 }),
        React.createElement("path", { fillOpacity: 0.15, fill: "currentColor", fillRule: "nonzero", d: "M12 2H4v9h8z" }),
        React.createElement("path", { d: "M4 2.5v-1h8v1h-2a.5.5 0 00-.5.5v.5A.5.5 0 019 4H7a.5.5 0 01-.5-.5V3a.5.5 0 00-.5-.5H4z", fill: "currentColor" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
