import React, { ErrorInfo } from 'react'
import * as Sentry from '@sentry/browser'
import styled from 'styled-components'
import { Text } from '@sketch/components'

const Container = styled.div`
  margin: 12px;
  text-align: center;
`

export const NotificationsErrorDisplay = () => {
  return (
    <Container>
      <Text textStyle="prominent.secondary.F" mb={2}>
        Something went wrong
      </Text>
      <Text textStyle="copy.tertiary.standard.E" mt={2}>
        We’re looking into the issue. Try again later or visit our{' '}
        <a href="https://status.sketch.com/">Status Page</a>.
      </Text>
    </Container>
  )
}

/**
 * ### Note:
 * Error boundaries do not catch errors for:
 * - Event handlers
 * - Asynchronous code (e.g. `setTimeout` or `requestAnimationFrame` callbacks)
 * - Server side rendering
 * - Errors thrown in the error boundary itself (rather than its children)
 * see more: https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries
 *
 * This means, that using ErrorBoundary we should NOT expect to cover GraphQL, network or all other async errors.
 */
class NotificationsErrorBoundary extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtra('info', info)
      Sentry.captureException(error)
    })
  }

  render() {
    if (this.state.hasError) {
      return <NotificationsErrorDisplay />
    }

    return this.props.children
  }
}

export default NotificationsErrorBoundary
