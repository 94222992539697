import React, { useImperativeHandle, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { selectors } from '@sketch/constants'

import { DocumentHead } from '../DocumentHead'
import { ErrorBoundary } from '../ErrorBoundary'
import { useContainerRefScroll } from '@sketch/utils'
import { HeaderOld } from '../HeaderOld'

export interface PageLayoutProps {
  darkBackground?: boolean
  className?: string
  title?: string
  showHeader?: boolean
  header?: React.ReactNode
  footer?: React.ReactNode
  children?: React.ReactNode
}

const PageContent = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: space-between;

  /* stylelint-disable declaration-block-no-duplicate-properties */
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
  /* stylelint-enable */
`

const focusedContainerCss = css`
  height: 100vh;

  /* Workaround to make 100vh work on safari, see polyfills.js */
  height: calc(var(--vh, 1vh) * 100);

  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  overflow: auto;

  :focus {
    outline: none;
  }
`

type FocusedContainerDivProps = {
  darkBackground?: boolean
}

const FocusedContainerDiv = styled.div<FocusedContainerDivProps>`
  ${focusedContainerCss}

  background-color: ${({ darkBackground, theme }) =>
    darkBackground ? theme.colors.background.secondary.A : 'transparent'};
`

type FocusedContainerProps = React.PropsWithChildren<{
  darkBackground?: boolean
}>

const FocusedContainer = React.forwardRef<Element, FocusedContainerProps>(
  function FocusedContainer({ darkBackground, children }, ref) {
    const focusRef = useRef<HTMLDivElement>(null)
    const { setContainerRef } = useContainerRefScroll()

    useEffect(() => {
      setContainerRef(focusRef)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusRef])

    // Allow keyboard scroll for the main area
    useEffect(() => {
      // #split-layout-content is the wrapper that has scroll
      const content = focusRef.current?.querySelector<HTMLElement>(
        selectors.contentScrollable
      )

      // We want to focus on the scrolling area only if there are not another
      // element focused, we know it because if there's no focus then
      // document.activeElement points to <body/>
      if (!content || document.activeElement?.tagName !== 'BODY') {
        return
      }

      content.focus({ preventScroll: false })
    }, [])

    useImperativeHandle(ref, () => ({
      ...focusRef.current!,
      scrollTo: (...args: any) => {
        focusRef.current?.scrollTo(...args)
      },
    }))

    return (
      <FocusedContainerDiv ref={focusRef} darkBackground={darkBackground}>
        {children}
      </FocusedContainerDiv>
    )
  }
)

export const PageLayout = React.forwardRef<Element, PageLayoutProps>(
  function PageLayout(
    {
      className,
      title,
      header,
      showHeader = true,
      children,
      footer,
      darkBackground = false,
      ...props
    }: PageLayoutProps,
    ref
  ) {
    return (
      <FocusedContainer ref={ref} darkBackground={darkBackground}>
        <DocumentHead title={title} />
        {showHeader && <HeaderOld>{header}</HeaderOld>}
        <ErrorBoundary>
          <PageContent className={className}>{children}</PageContent>
          {footer}
        </ErrorBoundary>
      </FocusedContainer>
    )
  }
)
