import React from 'react'
import styled from 'styled-components'

import { Wrapper, Item } from './PermissionWrapper.styles'

const PermissionWrapper: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  children,
  ...props
}) => (
  <Wrapper {...props}>
    {React.Children.map(children, item => (
      <Item>{item}</Item>
    ))}
  </Wrapper>
)

export default styled(PermissionWrapper)``
