import React, { FC, ReactNode } from 'react'
import warning from 'warning'
import styled from 'styled-components'

import { dasherize } from '@sketch/utils'
import { FormLabel } from '../FormLabel'
import { FormError } from '../FormError'
import { withBox } from '../../Box'
import { HelpText, HelpTextContainer, ErrorList } from './FormField.styles'

export interface FormFieldProps {
  id?: string
  name?: string
  label?: ReactNode
  errorText?: string | string[]
  children?: ReactNode
  help?: ReactNode
  srOnlyLabel?: boolean
  hasError?: boolean
}

// Helper functions
const renderErrorTextArray = (errorTextArray: string[]) => (
  <ErrorList>
    {errorTextArray.map((errorText, i) => (
      <li key={i}>
        <FormError>{errorText}</FormError>
      </li>
    ))}
  </ErrorList>
)

const renderErrorText = (errorText: string) =>
  errorText ? renderErrorTextArray([errorText]) : null

// Unstyled FormField
const FormFieldBase: FC<FormFieldProps> = ({
  id,
  name,
  label,
  errorText,
  help,
  children,
  srOnlyLabel,
  hasError,
  ...props
}) => {
  warning(
    id || name,
    'Not enough information to generate a unique id. Please provide an id or a name.'
  )

  const isInvalid = (errorText && errorText.length > 0) || hasError
  const inputId = dasherize(id || name) + '-input' // use id or name to generate unique id for input element
  const renderChild = (child: any) =>
    React.cloneElement(child, {
      id: child.id || inputId,
      invalid: isInvalid,
    })

  return (
    <div {...props}>
      {label && (
        <FormLabel
          htmlFor={inputId}
          {...(srOnlyLabel && { className: 'sr-only' })}
        >
          {label}
        </FormLabel>
      )}
      {children && React.Children.map(children, renderChild)}
      {errorText &&
        (Array.isArray(errorText)
          ? renderErrorTextArray(errorText)
          : renderErrorText(errorText))}
      {help && (
        <HelpTextContainer>
          <HelpText>{help}</HelpText>
        </HelpTextContainer>
      )}
    </div>
  )
}

export const FormField = styled(withBox(FormFieldBase))`
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
`

FormField.defaultProps = {
  mt: 0,
  mb: 6,
}
