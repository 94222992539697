import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "calendar-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M14.25 6.75v5.5a2 2 0 01-2 2h-8.5a2 2 0 01-2-2v-5.5h12.5z" }),
        React.createElement("path", { d: "M14.25 6.75v-1.5a2 2 0 00-2-2h-8.5a2 2 0 00-2 2v1.5h12.5z", fillOpacity: 0.15, fill: "currentColor" }),
        React.createElement("path", { d: "M12.25 1a.75.75 0 01.75.75v.854a2.75 2.75 0 012 2.646v7a2.75 2.75 0 01-2.582 2.745L12.25 15h-8.5a2.75 2.75 0 01-2.745-2.582L1 12.25v-7c0-1.259.846-2.32 2-2.647V1.75a.75.75 0 011.5 0v.75h7v-.75a.75.75 0 01.75-.75zm1.25 6.5h-11v4.75c0 .604.428 1.108.998 1.225l.124.019.128.006h8.5a1.25 1.25 0 001.244-1.122l.006-.128V7.5zM12.25 4h-8.5l-.128.006A1.25 1.25 0 002.5 5.25V6h11v-.75c0-.69-.56-1.25-1.25-1.25z", fill: "currentColor", fillRule: "nonzero" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
