import styled from 'styled-components'
import {
  Table as BaseTable,
  Button,
  Skeleton,
  TableComponents,
  Text,
} from '@sketch/components'

import { ReactComponent as DotIcon } from '@sketch/icons/dots-3-horizontal-24'

export const ListWrapper = styled.div`
  margin-top: 24px;
`

export const EmptyStateWrapper = styled.div`
  margin: auto;
  max-width: 300px;
`

export const Table = styled(BaseTable)`
  width: 100%;
` as typeof BaseTable

export const OptionsButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  padding: 4px 8px;
  color: ${({ theme }) => theme.colors.foreground.secondary.B};

  &:hover {
    color: inherit;
  }
`

export const OptionsIcon = styled(DotIcon)`
  width: 24px;
`

export const MinimumTableCell = styled(TableComponents.TableCell)`
  width: 66px;
`

export const SkeletonAvatar = styled(Skeleton)`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  margin-right: 8px;
  border-radius: ${({ theme }) => theme.radii.rounded};
`

export const SkeletonName = styled(Skeleton)`
  margin-bottom: 5px; /* stylelint-disable-line scales/space */
  height: 20px;
  width: 200px;
`

export const MaxWidth = styled(Text)`
  max-width: 350px;
`
