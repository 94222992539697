import { getBrowserFromUserAgent } from '@sketch/utils'

/**
 * Returns the correct device name string based on the type and user agent of
 * the device.
 * e.g. Sketch for Mac on John's iMac
 */
export const getDeviceNameString = (
  type: string,
  userAgent: string | null,
  name: string
): string => {
  if (type === 'WEB') {
    return `Sketch Web App on ${getBrowserFromUserAgent(userAgent || '')}`
  }

  // Since it's not a Web session we should use the userAgent to check
  // if it's iOS or macOS
  const device = userAgent?.split('/')[0]

  if (device === 'iOS') return `Sketch for iPhone on ${name}`

  if (device === 'macOS') return `Sketch for Mac on ${name}`

  return `Sketch on ${name}`
}
