import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import styled from 'styled-components'
import { useStableHandler } from '@sketch/utils'

import { AnnotationListWrapper } from './AnnotationReverseList.styles'

export interface AnnotationReverseListProps
  extends React.ComponentPropsWithRef<'div'> {
  'data-testid'?: string
  blockScroll?: boolean
}

const isListScrollTop = (scrollLevel: number = 0) => scrollLevel < 2

const AnnotationReverseList = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<AnnotationReverseListProps>
>(function AnnotationReverseList(
  {
    children,
    blockScroll,
    'data-testid': dataTestId = 'annotation-list',
    ...listProps
  },
  ref
) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  useImperativeHandle(ref, () => wrapperRef.current!)

  const [isScrollTop, setIsScrollTop] = useState(true)

  const childCount = React.Children.count(children)
  const savedWrapperState = useRef({
    offsetHeight: 0,
    scrollHeight: 0,
    scrollTop: 0,
  })

  const currentChildNumber = useRef(childCount)

  useEffect(() => {
    window.requestAnimationFrame(() => {
      if (wrapperRef.current && savedWrapperState.current) {
        const {
          offsetHeight,
          scrollHeight,
          scrollTop,
        } = savedWrapperState.current

        // If on the end of the list keep it there
        // because new items can be added and should be viewed
        if (offsetHeight + scrollTop === scrollHeight) {
          wrapperRef.current.scrollTop =
            wrapperRef.current.scrollHeight - wrapperRef.current.offsetHeight
        } else {
          wrapperRef.current.scrollTop =
            wrapperRef.current.scrollHeight - scrollHeight + scrollTop
        }
      }

      setIsScrollTop(isListScrollTop(wrapperRef.current?.scrollTop))
    })
  }, [childCount])

  const onScroll = useStableHandler((event: React.UIEvent) => {
    const { scrollTop } = event.currentTarget as HTMLElement
    const nextIsScrollTop = isListScrollTop(scrollTop)

    if (isScrollTop !== nextIsScrollTop) {
      setIsScrollTop(nextIsScrollTop)
    }
  })

  /**
   * Usually this should be done on the unmount step of a useEffect
   * but because the hook runs after the list has already changed
   * we would need to know the size before so we can maintain the scroll
   *
   * We could also save the values if after the list is updated
   * but those values could change during a window resize and this
   * way is more error prone
   */
  if (currentChildNumber.current !== childCount) {
    currentChildNumber.current = childCount
    savedWrapperState.current = {
      offsetHeight: wrapperRef.current?.offsetHeight || 0,
      scrollHeight: wrapperRef.current?.scrollHeight || 0,
      scrollTop: wrapperRef.current?.scrollTop || 0,
    }
  }

  return (
    <AnnotationListWrapper
      data-testid={dataTestId}
      data-scrolltop={isScrollTop}
      ref={wrapperRef}
      onScroll={onScroll}
      $blockScroll={blockScroll}
      reverse
      {...listProps}
    >
      {children}
    </AnnotationListWrapper>
  )
})

export default styled(AnnotationReverseList)``
