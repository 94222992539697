import { useCollectionSearchArgument } from '../hooks/useCollectionSearchArgument'

import {
  invalidateCollectionShares,
  invalidateCollectionsExcept,
  removeSharesFromCollection,
  removeSharesFromProject,
  invalidateShareAfterCollectionUpdate,
} from './utils'
import { readShareWithProjectFragment } from '../../projects/operations/utils/project.cache'

// GraphhQL
import {
  useAddSharesToCollectionMutation,
  AddSharesToCollectionMutation,
  ShareListItemFragment,
} from '@sketch/gql-types'

interface UseAddSharesToCollectionProps {
  projectIdentifier: string
  currentCollectionIdentifier?: string
  onCompleted?: (data: AddSharesToCollectionMutation) => void
  invalidateShare?: boolean
}

export const useAddSharesToCollection = ({
  projectIdentifier,
  currentCollectionIdentifier,
  onCompleted,
  invalidateShare,
}: UseAddSharesToCollectionProps) => {
  const search = useCollectionSearchArgument()

  const [mutation] = useAddSharesToCollectionMutation({
    onCompleted,
    onError: 'show-toast',
  })

  const mutationWithCacheUpdate: typeof mutation = (options = {}) => {
    const shareIdentifiers = options.variables?.shareIdentifiers ?? []
    const variables = options.variables
      ? { ...options.variables, search }
      : undefined

    return mutation({
      ...options,
      variables,
      update: (cache, { data }) => {
        if (!data) {
          return
        }
        const { collection } = data.addSharesToCollection

        const shares = shareIdentifiers
          .map(identifier => readShareWithProjectFragment(cache, identifier))
          .filter(maybeShare => maybeShare !== null) as ShareListItemFragment[]

        // Remove the shares from their current collection
        // **NOTE** this should be performed before `addSharesToCollection`
        // to ensure that the share ends up in the correct state.
        if (currentCollectionIdentifier) {
          removeSharesFromCollection({
            cache,
            projectIdentifier,
            collectionIdentifier: currentCollectionIdentifier,
            search,
            sharesToRemove: shares,
          })
        }

        // Invalidate any cached `collection.shares` records so that they are
        // refetched from the GraphQL API
        invalidateCollectionShares({
          cache,
          collectionIdentifier: collection.identifier,
        })

        if (invalidateShare) {
          shareIdentifiers.forEach(shareIdentifier =>
            invalidateShareAfterCollectionUpdate({ cache, shareIdentifier })
          )
        }

        invalidateCollectionsExcept({
          cache,
          projectIdentifier,
          search,
        })

        // Remove the shares from `project.shares`
        removeSharesFromProject(cache, projectIdentifier, shares)
      },
    })
  }

  return mutationWithCacheUpdate
}
