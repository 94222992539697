import React from 'react'
import { useLocalStorage } from 'react-use'

import {
  Text,
  Button,
  UnstyledList,
  Modal,
  ModalInjectedProps,
} from '@sketch/components'
import { UpsellListItem, UpsellIcon } from './UpsellModal.styles'
import { ReactComponent as DSIcon } from '@sketch/icons/design-system-16'
import { ReactComponent as AlignLeft } from '@sketch/icons/text-align-left-16'
import { ReactComponent as SymbolIcon } from '@sketch/icons/link-library-16'

export const UpsellModal: React.FC<ModalInjectedProps> = ({ hideModal }) => {
  // eslint-disable-next-line
  const [_introductionSeen, setIntroductionSeen] = useLocalStorage(
    'dsm_introduction_seen',
    false
  )

  const handleCancel = () => {
    setIntroductionSeen(true)
    hideModal()
  }

  return (
    <Modal title="Build Your Design Systems in Sketch" onCancel={handleCancel}>
      <Modal.Body>
        <Text pt={2} pb={2}>
          Supercharge your Libraries by integrating them into your own design
          systems. Available with the Business Plan.
        </Text>
        <UnstyledList>
          <UpsellListItem>
            <UpsellIcon>
              <DSIcon />
            </UpsellIcon>
            Combine Libraries to create multiple design systems
          </UpsellListItem>
          <UpsellListItem>
            <UpsellIcon>
              <AlignLeft />
            </UpsellIcon>
            Set up your documentation in minutes
          </UpsellListItem>
          <UpsellListItem>
            <UpsellIcon>
              <SymbolIcon />
            </UpsellIcon>
            Add or remove multiple Libraries with ease
          </UpsellListItem>
        </UnstyledList>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCancel}>Maybe Later</Button>
        {/* @TODO: this will be hooked on Cloud#14421 */}
        <Button onClick={handleCancel} variant="primary">
          Get in Touch
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
