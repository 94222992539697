import React, { FC } from 'react'
import { Text } from '../Box'

export const pluralize = (
  singular: string,
  plural: string | undefined,
  count: number
) => {
  if (count === 0 || count > 1) return plural ?? `${singular}s`
  return singular
}

export interface PluralizeProps {
  singular: string
  plural?: string
  count: number
  showCount?: boolean
  wrapperElement?: React.ElementType
}

export const Pluralize: FC<PluralizeProps> = ({
  singular,
  plural,
  count,
  showCount,
  wrapperElement: WrapperElement = Text,
  ...props
}) => {
  return (
    <WrapperElement {...props}>
      {`${showCount ? count : ''} ${pluralize(singular, plural, count)}`.trim()}
    </WrapperElement>
  )
}
