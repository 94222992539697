import styled from 'styled-components'
import { RadioButton as BaseRadioButton } from '@sketch/components'
import Selector from '../Selector'

import { ReactComponent as ChevronUpDown } from '@sketch/icons/chevrons-up-down-16'

export const RadioButton = styled(BaseRadioButton)`
  margin: 8px 20px;
  text-transform: none;

  /* Correct internal radio button spacing */
  line-height: 0;
`

export const FilterButton = styled(Selector)`
  margin: -8px 0 -8px -8px;

  display: flex;
  align-items: center;

  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.182; /* stylelint-disable-line scales/line-height */

  padding: 8px 8px 8px 12px;
`

export const Chevron = styled(ChevronUpDown)`
  margin-left: 4px;
`

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
