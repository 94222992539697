import React, { FC, useCallback, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { animated, useTransition } from 'react-spring'
import {
  CardNumberElement,
  CardNumberElementProps,
} from '@stripe/react-stripe-js'

import { PaymentLogo } from '../../PaymentLogo'
import { Input, InputProps } from '../Input'
import { commonStyles, invalidStyles } from './commonStyles'
import { inputBaseStyles } from '../Input.styles'

const INITIAL_ANIMATION_STATE = { transform: 'scale(0)', opacity: 0 }
const FINAL_ANIMATION_STATE = { transform: 'scale(1)', opacity: 1 }

export interface CardNumberInputProps extends CardNumberElementProps {
  disabled?: boolean
  invalid?: boolean
}

const ANIMATION_STATES = {
  initial: null,
  from: INITIAL_ANIMATION_STATE,
  enter: FINAL_ANIMATION_STATE,
  leave: INITIAL_ANIMATION_STATE,
  config: { mass: 0.5, tension: 400, friction: 20 },
  unique: true,
}

const CardWrapper = styled.div`
  width: auto;
  height: 20px;
  user-select: none;
  overflow: hidden;
`

const CardLogo = styled(PaymentLogo)`
  :not(:last-child) {
    margin-right: 12px;
  }
`

const CardInputWrapper = styled(Input)<InputProps>`
  ${inputBaseStyles};

  display: flex;
  align-items: center;
`

CardInputWrapper.defaultProps = {
  className: 'StripeElement',
}

const BaseCardNumberInput = styled(CardNumberElement)`
  flex: 1;
  height: 40px;
  padding-top: 13px; /* stylelint-disable-line scales/space */

  &::placeholder {
    color: ${({ theme }) => theme.colors.foreground.secondary.C};
  }
`

const SUPPORT_CREDIT_CARDS = ['amex', 'mastercard', 'visa'] as const

export const CardNumberInput: FC<CardNumberInputProps> = ({
  className,
  disabled,
  onChange,
  invalid,
  onFocus,
  onBlur,
  ...props
}) => {
  const theme = useTheme()
  const [cardBrand, setCardBrand] = useState('unknown')
  const [isInputFocused, setIsInputFocused] = useState(false)

  const handleCardNumberOnChange = useCallback(
    callbackValues => {
      const { brand } = callbackValues

      cardBrand !== brand && setCardBrand(brand)
      onChange && onChange(callbackValues)
    },
    [cardBrand, onChange]
  )

  const transitions = useTransition(cardBrand, null, ANIMATION_STATES)
  const handleTransition = useCallback(({ item, key, props }) => {
    if (item === 'unknown') {
      return (
        <animated.div key={key} style={props}>
          {SUPPORT_CREDIT_CARDS.map(card => (
            <CardLogo className={card} brand={card} key={card} />
          ))}
        </animated.div>
      )
    }

    return (
      <animated.div key={key} style={props}>
        <CardLogo brand={item} />
      </animated.div>
    )
  }, [])

  return (
    <CardInputWrapper
      className={className}
      disabled={disabled ?? false}
      stripeFakeFocus={isInputFocused}
      invalid={invalid}
      as="div"
    >
      <BaseCardNumberInput
        {...props}
        options={{
          style: invalid ? invalidStyles(theme) : commonStyles(theme),
        }}
        onFocus={event => {
          onFocus?.(event)
          setIsInputFocused(true)
        }}
        onBlur={event => {
          setIsInputFocused(false)
          onBlur?.(event)
        }}
        onChange={handleCardNumberOnChange}
      />
      <CardWrapper>{transitions.map(handleTransition)}</CardWrapper>
    </CardInputWrapper>
  )
}
