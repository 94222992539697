import { useGetWorkspaceQuery } from '@sketch/gql-types'
import { INVITEE_LIMIT, InviteeLimitValueType } from '../constants'

export const useInviteeLimits = (workspaceId: string, isOnTrial: boolean) => {
  // Used to check its type for invitee limits
  const { data: workspaceData } = useGetWorkspaceQuery({
    variables: { identifier: workspaceId },
    fetchPolicy: 'cache-only',
  })

  const isBusiness = workspaceData?.workspace.type === 'BUSINESS'

  let inviteeLimit: InviteeLimitValueType = INVITEE_LIMIT.standard

  if (isOnTrial) {
    inviteeLimit = INVITEE_LIMIT.trial
  }

  if (isBusiness) {
    inviteeLimit = INVITEE_LIMIT.business
  }

  return { inviteeLimit }
}
