import styled from 'styled-components'

import { TableComponents, Checkbox } from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'
import { TableWrapper as TableWrapperOriginal } from '../../../shares/components/DocumentItemsLayout/DocumentItemsLayout.styles'

export const TableWrapper = styled(TableWrapperOriginal).attrs({
  $hideProjects: false,
  $hideCollections: false,
})`
  height: 395px;
  overflow-y: auto;

  ${breakpoint('sm')`
    margin: 0;
  `}

  ${TableComponents.Table} {
    /* necessary to reset the default value of 800px */
    min-width: auto;
    padding-left: 0;

    ${breakpoint('sm')`
        padding: 0 ;
      `}
  }

  ${TableComponents.TableHeaderCell} {
    &:nth-child(1) {
      width: 5%;
    }
    &:nth-child(2) {
      width: 45%;
    }

    &:hover {
      color: inherit;
    }
  }

  ${TableComponents.TableCell} {
    &:last-child {
      /* overriding default table behaviour  */
      text-align: left !important; /* stylelint-disable-line declaration-no-important */
    }
  }
`

export const TableCellAction = styled(TableComponents.TableCell)`
  width: 10%;
`

export const TableCellRegular = styled(TableComponents.TableCell)`
  width: 45%;
`

export const SelectAllCheck = styled(Checkbox)`
  span {
    margin-right: 0;
  }
`
