import { useEffect, useReducer, useRef } from 'react'

type JSONValue =
  | string
  | number
  | boolean
  | null
  | JSONValue[]
  | { [key: string]: JSONValue }

type JSONObject = {
  [k: string]: JSONValue
}

interface JSONArray extends Array<JSONValue> {}

type JSON = JSONObject | JSONArray

type State<T extends JSON> =
  | { status: 'loading' }
  | { data: T; status: 'complete' }
  | { error: Error; status: 'error' }

type Action<T> =
  | { type: 'loading' }
  | { type: 'complete'; data: T }
  | { type: 'error'; error: Error }

type Options = RequestInit & { validator?: (data: unknown) => boolean }

// Using a create reducer function allows the State generic type to be inferred
// properly in the component function body
function createReducer<T extends JSON>() {
  return (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case 'loading':
        return { status: 'loading' }
      case 'complete':
        return { data: action.data, status: 'complete' }
      case 'error':
        return { error: action.error, status: 'error' }
      default:
        return state
    }
  }
}

/**
 * useFetch hook for loading JSON, with the following features:
 * - Only invokes when the `url` changes and is truthy
 * - Aborts requests in progress when url changes or component unmounts
 * - Support for a validator function to smoke test the returned data
 * - Pass in TypeScript generic for the returned data type
 */
export function useFetchJSON<T extends JSON>(
  url?: string | null,
  options: Options = {}
): State<T> {
  const optionsRef = useRef<Options>(options) // Freeze the options in a ref
  const [state, dispatch] = useReducer(createReducer<T>(), {
    status: 'loading',
  })

  useEffect(() => {
    if (!url) return

    dispatch({ type: 'loading' })

    const controller = new AbortController()

    ;(async function invokeFetch(url: string) {
      try {
        const res = await fetch(url, {
          ...optionsRef.current,
          signal: controller.signal,
        })
        if (!res.ok) throw Error(res.statusText)
        const data: T = (await res.json()) as T
        const validator = optionsRef.current?.validator ?? (() => true)
        if (!validator(data)) throw Error('JSON is invalid')
        dispatch({ type: 'complete', data })
      } catch (error) {
        if (controller.signal.aborted) return
        if (error instanceof Error) dispatch({ type: 'error', error })
      }
    })(url)

    return () => {
      controller.abort()
    }
  }, [url])

  return state
}
