import React from 'react'
import Attribute, { Label, Value, Display } from '../Attribute'
import { Tooltip, Flex } from '@sketch/components'
import { useHandleCopyValue, CopiableArea } from '@sketch/modules-common'
import DirtyIconTooltip from '../Style/DirtyIconTooltip'

interface FullCopyAttributeProps {
  label: string
  value: string | React.ReactNode
  copyValue?: string
  display?: Display
  dirtyAttributes?: { originalValue: string; originalProperty: string }
  isTextStyle?: boolean
}

export default function FullCopyAttribute({
  copyValue,
  display,
  label,
  value,
  dirtyAttributes,
  isTextStyle = false,
}: FullCopyAttributeProps) {
  const {
    handleCopyValue,
    copyTooltipText,
    handleEnterCopiableArea,
    handleLeaveCopiableArea,
    copyTooltipVisible,
  } = useHandleCopyValue(copyValue ?? `${value}`)

  return (
    <CopiableArea
      onClick={handleCopyValue}
      onMouseEnter={handleEnterCopiableArea}
      onMouseLeave={handleLeaveCopiableArea}
    >
      <Tooltip
        placement="left"
        spacing="10px"
        visible={copyTooltipVisible}
        content={copyTooltipText}
      >
        <Attribute noCopy display={display ?? undefined}>
          <Label>
            <Flex alignItems="center">
              {/* the extra handleEnterCopiableArea is to make sure the copy tooltip is triggered after leaving the dirty icon tooltip */}
              <div
                aria-hidden
                onMouseEnter={
                  dirtyAttributes ? handleEnterCopiableArea : undefined
                }
              >
                {label}
              </div>{' '}
              {dirtyAttributes && (
                <DirtyIconTooltip
                  originalProperty={dirtyAttributes.originalProperty}
                  originalValue={dirtyAttributes.originalValue}
                  preventLabelTooltip={handleLeaveCopiableArea}
                  isTextStyle={isTextStyle}
                />
              )}
            </Flex>
          </Label>
          <Value
            valueString={copyValue}
            label={label}
            onMouseEnter={dirtyAttributes ? handleEnterCopiableArea : undefined}
          >
            {value}
          </Value>
        </Attribute>
      </Tooltip>
    </CopiableArea>
  )
}
