import React from 'react'
import { Redirect, useParams } from 'react-router-dom'

import {
  useQueryParams,
  RouteParams,
  versionedRoutes,
  DynamicLoadingPage,
  NotFoundView,
} from '@sketch/modules-common'

import {
  GetAnnotationLatestVisibleVersionQuery,
  useGetAnnotationLatestVisibleVersionQuery,
} from '@sketch/gql-types'

/**
 * When a user clicks on a notification for an annotation, we need to find the
 * latest version of the document in which the annotation is still visible.
 * For example, if a user comments on an Artboard, but then deletes the
 * Artboard, then notifications for that comment should link to the version of
 * the document _before_ the Artboard was deleted.
 *
 * This component is used to render a loading screen while we request the
 * "latest visible version" for the given annotation, and then redirects to
 * that version.
 */

type LatestVisibleVersion = {
  isLatest: boolean
  identifier: string
  shortId: string
}

const artboardAnnotationUrl = (
  shareID: string,
  annotationId: string,
  permanentArtboardShortId: string,
  latestVisibleVersion: LatestVisibleVersion
) => {
  if (latestVisibleVersion.isLatest) {
    return versionedRoutes.ARTBOARD_DETAIL.LATEST.create({
      shareID,
      permanentArtboardShortId,
      query: { annotation: annotationId },
    })
  } else {
    const versionShortId = latestVisibleVersion.shortId
    return versionedRoutes.ARTBOARD_DETAIL.VERSION.create({
      shareID,
      versionShortId,
      permanentArtboardShortId,
      query: { annotation: annotationId },
    })
  }
}

const pageAnnotationUrl = (
  shareID: string,
  annotationId: string,
  pageUUID: string,
  latestVisibleVersion: LatestVisibleVersion
) => {
  if (latestVisibleVersion.isLatest) {
    return versionedRoutes.SHARE_PAGE_CANVAS_VIEW.LATEST.create({
      shareID,
      pageUUID,
      query: { annotation: annotationId },
    })
  } else {
    const versionShortId = latestVisibleVersion.shortId
    return versionedRoutes.SHARE_PAGE_CANVAS_VIEW.VERSION.create({
      shareID,
      versionShortId,
      pageUUID,
      query: { annotation: annotationId },
    })
  }
}

const getAnnotationUrl = (
  shareId: string,
  annotationId: string,
  subjectType: 'Artboard' | 'Page',
  subjectId: string,
  latestVisibleVersion: LatestVisibleVersion
): string => {
  if (subjectType === 'Artboard') {
    return artboardAnnotationUrl(
      shareId,
      annotationId,
      subjectId,
      latestVisibleVersion
    )
  } else {
    return pageAnnotationUrl(
      shareId,
      annotationId,
      subjectId,
      latestVisibleVersion
    )
  }
}

const latestVisibleVersionFor = (
  annotation: GetAnnotationLatestVisibleVersionQuery['annotation'] | undefined
): LatestVisibleVersion => {
  if (annotation) {
    return annotation.latestVisibleVersion
  }

  // If we could not find the given annotation, then always redirect to the
  // latest version rather than show an error
  return {
    isLatest: true,
    identifier: '',
    shortId: '',
  }
}

const AnnotationRedirect: React.FC<{}> = () => {
  const { shareId, annotationId } = useParams<
    RouteParams<'ANNOTATION_REDIRECT'>
  >()
  const { subjectType, subjectId } = useQueryParams<'ANNOTATION_REDIRECT'>()

  const { data, loading } = useGetAnnotationLatestVisibleVersionQuery({
    variables: { identifier: annotationId },
  })

  if (loading) {
    return <DynamicLoadingPage />
  }

  const isValidSubject = subjectType === 'Artboard' || subjectType === 'Page'
  if (!isValidSubject || !subjectId) {
    return <NotFoundView />
  }

  const latestVisibleVersion = latestVisibleVersionFor(data?.annotation)

  const redirectTo = getAnnotationUrl(
    shareId,
    annotationId,
    subjectType,
    subjectId,
    latestVisibleVersion
  )

  return <Redirect to={redirectTo} push={false} />
}

export default AnnotationRedirect
