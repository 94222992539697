/**
 * This file is a copy of
 * https://github.com/floating-ui/react-popper/blob/master/src/usePopper.js
 *
 * It was imported to the project to tackle
 * https://github.com/sketch-hq/Cloud/issues/13849
 *
 * Because of the canvas way the canvas has been assembled
 * it allows elements to have negative positions.
 *
 * Because popper by default renders elements on top:0 and left:0
 * as you can see on the original file, this forced the issue you see on the ticket.
 * Basically forcing the poppers initial position to be visible.
 *
 * By sending no positions we prevent this "overflow" for occurring
 */
import React, { CSSProperties, useLayoutEffect } from 'react'
import { flushSync } from 'react-dom'
import isEqual from 'react-fast-compare'

import {
  createPopper as defaultCreatePopper,
  Options as PopperOptions,
  VirtualElement,
  State as PopperState,
  Instance as PopperInstance,
} from '@popperjs/core'

type Options = Partial<PopperOptions> & {
  createPopper?: typeof defaultCreatePopper
}

type Styles = Record<keyof PopperState['styles'], CSSProperties>
type Attributes = PopperState['attributes']

type State = {
  styles: Record<keyof Styles, CSSProperties>
  attributes: Attributes
}

const EMPTY_MODIFIERS = [] as const

type UsePopperResult = {
  state: PopperState | undefined
  styles?: Styles
  attributes?: Attributes
  update: PopperInstance['update'] | undefined
  forceUpdate: PopperInstance['forceUpdate'] | undefined
}

export const usePopper = (
  referenceElement: Element | VirtualElement | null | undefined,
  popperElement: HTMLElement | null | undefined,
  options: Options
): UsePopperResult => {
  const prevOptions = React.useRef<PopperOptions | null>()

  const optionsWithDefaults = {
    onFirstUpdate: options.onFirstUpdate,
    placement: options.placement || 'bottom',
    strategy: options.strategy || 'absolute',
    modifiers: options.modifiers || EMPTY_MODIFIERS,
  }

  const [state, setState] = React.useState<State | null>({
    styles: {
      popper: {
        position: optionsWithDefaults.strategy,
      },
      arrow: {
        position: 'absolute',
      },
    },
    attributes: {},
  })

  const updateStateModifier = React.useMemo(
    () => ({
      name: 'updateState',
      enabled: true,
      phase: 'write' as const,
      fn: ({ state }: { state: PopperState }) => {
        const elements = Object.keys(
          state.elements
        ) as (keyof PopperState['elements'])[]

        flushSync(() => {
          setState({
            styles: Object.fromEntries(
              elements.map(element => [
                element,
                (state.styles[element] as CSSProperties) || {},
              ])
            ),
            attributes: Object.fromEntries(
              elements.map(element => [element, state.attributes[element]])
            ),
          })
        })
      },
      requires: ['computeStyles'],
    }),
    []
  )

  const popperOptions = React.useMemo(() => {
    const newOptions = {
      onFirstUpdate: optionsWithDefaults.onFirstUpdate,
      placement: optionsWithDefaults.placement,
      strategy: optionsWithDefaults.strategy,
      modifiers: [
        ...optionsWithDefaults.modifiers,
        updateStateModifier,
        { name: 'applyStyles', enabled: false },
      ],
    }

    if (isEqual(prevOptions.current, newOptions)) {
      return prevOptions.current || newOptions
    } else {
      prevOptions.current = newOptions
      return newOptions
    }
  }, [
    optionsWithDefaults.onFirstUpdate,
    optionsWithDefaults.placement,
    optionsWithDefaults.strategy,
    optionsWithDefaults.modifiers,
    updateStateModifier,
  ])

  const popperInstanceRef = React.useRef<PopperInstance | null>(null)

  useLayoutEffect(() => {
    if (popperInstanceRef.current) {
      popperInstanceRef.current?.setOptions(popperOptions)
    }
  }, [popperOptions])

  useLayoutEffect(() => {
    if (referenceElement == null || popperElement == null) {
      return
    }

    const createPopper = options.createPopper || defaultCreatePopper
    const popperInstance = createPopper(
      referenceElement,
      popperElement,
      popperOptions
    )

    popperInstanceRef.current = popperInstance

    return () => {
      popperInstance.destroy()
      popperInstanceRef.current = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceElement, popperElement, options.createPopper])

  return {
    state: popperInstanceRef.current?.state,
    styles: state?.styles,
    attributes: state?.attributes,
    update: popperInstanceRef.current?.update,
    forceUpdate: popperInstanceRef.current?.forceUpdate,
  }
}
