import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "color-fan-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "currentColor" },
        React.createElement("path", { d: "M12.27 4.586l1.917 3.32a1.583 1.583 0 01-.58 2.162l-6.856 3.959-.022.01.027-.014a3.343 3.343 0 00.125-.076l.059-.039a3.47 3.47 0 00.965-.961l.125-.202 3.958-6.856a1.584 1.584 0 00-.125-1.768c.16.123.3.279.407.465z", fillOpacity: 0.15 }),
        React.createElement("path", { d: "M8.089 1.81l3.32 1.917a1.583 1.583 0 01.58 2.162l-3.96 6.856a3.486 3.486 0 01.462-1.556l.006-.192V3.08c0-.665-.41-1.235-.993-1.47.2.027.399.093.585.2z", fillOpacity: 0.15 }),
        React.createElement("path", { d: "M6.913 1.497c.222 0 .433.045.625.128l-.034-.014c.2.026.399.092.585.199l3.32 1.917c.146.084.273.188.381.307l.09.11-.017-.023c.16.123.3.279.407.465l1.917 3.32a1.583 1.583 0 01-.58 2.162l-6.825 3.94-.03.019a3.5 3.5 0 01-5.255-3.03V3.08c0-.874.709-1.583 1.583-1.583h3.833zm0 1.5H3.08a.083.083 0 00-.077.05l-.006.033v7.917a2 2 0 003.994.149l.006-.15V3.08a.083.083 0 00-.051-.077l-.033-.006zM5 10a1 1 0 110 2 1 1 0 010-2zm6.639-3.507L9.06 10.959l3.796-2.19a.083.083 0 00.041-.082l-.01-.032-1.25-2.162zM8.497 3.777v5.16l2.192-3.798a.083.083 0 00-.006-.092l-.024-.021-2.162-1.25z" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
