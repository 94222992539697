import { ButtonUnstyled } from '@sketch/components'
import styled, { css } from 'styled-components'

import { ReactComponent as Arrow } from '@sketch/icons/arrow-circle-right-14'

// Hide the artboard name if the artboard's width is less than:
const HEADER_MIN_WIDTH = 100

export const ArrowIcon = styled(Arrow)`
  width: 14px;
  height: 14px;
  pointer-events: none;
  flex-shrink: 0;
`

export const ArtboardHeader = styled.div`
  position: absolute;
  top: 1px;

  height: auto;
  transform: translateY(-100%);

  /* Allow the ArtboardCTA to have the correct measure of ArtboardHeader */
  width: 100%;

  /* 
  This is a mimic way of hiding a element based on its size via CSS 
  basically we if 100% - the minimum with is negative then the max-width is 0px
  so this part is hidden
  */
  max-width: max(0px, calc((100% - ${HEADER_MIN_WIDTH}px) * 999));
  overflow: hidden;
`

export const ArtboardName = styled.span`
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
  pointer-events: auto;
`

export const ArtboardCTA = styled(ButtonUnstyled)`
  pointer-events: auto;
  line-height: 1.4;
  color: currentColor;

  display: inline-flex;
  align-items: center;
  max-width: calc(100% - 6px);

  padding: 4px; /* stylelint-disable-line scales/space */
  border-radius: 4px 4px 0 0;
  position: relative;

  ::after {
    content: '';
    display: block;
    visibility: hidden;

    position: absolute;

    right: -4px;
    bottom: -2px;

    width: 6px;
    height: 6px;

    background: radial-gradient(
        circle 4px at top right,
        #0000 98%,
        ${({ theme }) => theme.colors.sketch.A}
      )
      bottom left;
  }
`

export const Artboard = styled.div<{ $disableHover?: boolean }>`
  position: absolute;
  box-sizing: border-box;
  cursor: pointer;

  /* Just some default font styles */
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 1.182; /* stylelint-disable-line scales/line-height */

  color: ${({ theme }) => theme.colors.foreground.secondary.C};

  ${ArrowIcon} {
    display: none;
  }

  ${({ $disableHover }) =>
    !$disableHover &&
    css`
      &:hover {
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.sketch.A};
      }
    `}

  &[aria-selected='true'] {
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.sketch.A};
    color: white;
    font-weight: 500;

    ${ArtboardCTA} {
      background-color: ${({ theme }) => theme.colors.sketch.A};

      ::after {
        visibility: visible;
      }
    }

    ${ArrowIcon} {
      display: flex;
    }
  }
`
