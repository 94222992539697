import { Component } from 'react'
import PropTypes from 'prop-types'

export class SupportsTouch extends Component {
  state = { hasTouch: false }

  componentDidMount() {
    try {
      document.createEvent('TouchEvent')
      this.setState({ hasTouch: true })
    } catch (e) {
      // no need for extra action
    }
  }

  render() {
    const { hasTouch } = this.state
    const { children } = this.props

    return typeof children === 'function' ? children(hasTouch) : children
  }
}

SupportsTouch.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
}
