import styled from 'styled-components'
import { Button } from '@sketch/components'
import AnnotationListWrapper from '../AnnotationListWrapper'

export const Wrapper = styled.div`
  width: 100%;
  padding: 12px;

  /* Hide top border for draft annotations */
  ${AnnotationListWrapper} + & {
    border-top: 1px solid ${({ theme }) => theme.colors.border.A};
  }

  font-size: 0.8125rem; /* 13px */
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

export const IconButton = styled(Button)`
  background: none;
  box-shadow: none;
  border: none;
  padding: 4px 8px;

  transition: background 0.1s;

  & + && {
    margin: 0;
  }

  [role='img'] {
    width: 24px;
    height: 24px;
    transition: opacity 0.1s;
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
    opacity: 0.55;
  }

  :focus,
  :hover {
    [role='img'] {
      color: ${({ theme }) => theme.colors.background.primary.A};
      opacity: 1;
    }
  }
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
`

export const ActionsWrapper = styled.div`
  display: flex;
  /* Force the action buttons to be the right one */
  margin-left: auto;

  [type='submit'] {
    margin-left: 8px;
  }
`
