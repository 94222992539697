import {
  WorkspaceMinimalFragment,
  useCanUseDesignSystemManagerQuery,
} from '@sketch/gql-types'

interface UseGetDesignSystems {
  workspace?: WorkspaceMinimalFragment
}

export const useCanUseDesignSystemManager = ({
  workspace,
}: UseGetDesignSystems) => {
  const userIsGuest =
    workspace?.userRole === 'GUEST' || workspace?.userRole === undefined

  const { data, loading, error } = useCanUseDesignSystemManagerQuery({
    variables: { identifier: workspace?.identifier ?? '' },
    skip: userIsGuest || !workspace,
  })

  const canUseDesignSystemManager = !!data?.workspace?.features
    ?.designSystemsEnabled

  return { canUseDesignSystemManager, loading, error }
}
