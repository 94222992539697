import { Shadow } from '../../../../types'
import rgbTo, { ColorFormat } from '../Color/utils'

const copy = (shadows: Shadow[], colorFormat?: ColorFormat) => {
  return shadows
    .map((shadow, i) => {
      const spread = shadow.spread
      const opacity =
        shadow.appearance?.opacity !== undefined
          ? shadow.appearance?.opacity
          : 1

      return (
        `Shadow ${++i}` +
        `\nColor: ${rgbTo(colorFormat, shadow.color)}` +
        `\nX: ${shadow.offsetX}` +
        `\nY: ${shadow.offsetY}` +
        `\nBlur: ${shadow.blurRadius}` +
        (spread ? `\nSpread: ${spread}` : ``) +
        (opacity < 1 ? `\nOpacity: ${(opacity * 100).toFixed()}%` : ``)
      )
    })
    .join(`\n\n`)
}

export default copy
