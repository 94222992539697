import { v4 as uuid } from 'uuid'

import { localStorageKeys } from '@sketch/constants'
import { getItem, setItem } from '@sketch/utils'

export const getDeviceId = () => {
  const savedDeviceId = getItem(localStorageKeys.deviceId)

  if (savedDeviceId) return savedDeviceId

  const newDeviceId = uuid()

  setItem(localStorageKeys.deviceId, newDeviceId)

  return newDeviceId
}
