import React, { useRef } from 'react'
import styled from 'styled-components'

import { Segment } from '../Segment'
import { Tooltip, TooltipShortcut } from '../Tooltip'

import { Wrapper, Input } from './NavigationSelector.styles'
import { useKey } from 'react-use'
import { keyWithoutModifier } from '@sketch/utils'

interface BaseItem<T> {
  label: string
  value: T
  shortcut?: string
}

interface IconItem<T> extends BaseItem<T> {
  type: 'icon'
  icon: React.ElementType
}

interface TextItem<T> extends BaseItem<T> {
  type: 'text'
}

export type Item<T> = IconItem<T> | TextItem<T>

export interface NavigationSelectorProps<T> {
  className?: string
  disabled?: boolean
  hideTooltip?: boolean

  name: string
  items: Item<T>[]

  onChange?: (item: Item<T>) => void
  selected?: string
}

interface NavigationSelectorItemProps<T> {
  name: string
  hideTooltip: boolean
  selected?: string
  onChange?: (item: Item<T>) => void
  item: Item<T>
}

function NavigationSelectorItem<T extends string>(
  props: NavigationSelectorItemProps<T>
) {
  const labelRef = useRef<HTMLLabelElement>(null)

  const { name, hideTooltip, onChange, selected, item } = props

  useKey(keyWithoutModifier('Enter'), () => {
    // We need to check if the label is focused because the key event is global
    const isFocused = labelRef.current === document.activeElement

    isFocused && onChange?.(item)
  })

  return (
    <label ref={labelRef} tabIndex={item.value === selected ? undefined : 0}>
      <Tooltip
        placement="bottom-end"
        disabled={hideTooltip}
        content={
          <>
            View as {item.value.toLowerCase()}
            {item.shortcut && (
              <TooltipShortcut>Press {item.shortcut}</TooltipShortcut>
            )}
          </>
        }
      >
        <Input
          name={name}
          value={item.value}
          type="radio"
          onChange={event => {
            onChange?.(item)
            event.target.blur()
          }}
          checked={item.value === selected}
        />
        <Segment
          type={item.type}
          label={item.label}
          icon={item.type === 'icon' ? item.icon : undefined}
          active={item.value === selected}
        />
      </Tooltip>
    </label>
  )
}

export const NavigationSelector = styled(function NavigationSelectorProps<
  T extends string
>(props: NavigationSelectorProps<T>) {
  const {
    className,
    disabled,
    hideTooltip = false,
    name,
    items,
    onChange,
    selected,
  } = props

  return (
    <Wrapper className={className} disabled={disabled}>
      <legend className="sr-only">{name}</legend>
      {items.map((item, index) => (
        <NavigationSelectorItem
          key={index}
          name={name}
          item={item}
          hideTooltip={hideTooltip}
          onChange={onChange}
          selected={selected}
        />
      ))}
    </Wrapper>
  )
})``
