import React, { ReactElement } from 'react'

import { ReactComponent as Information } from '@sketch/icons/information-outline-16'

import {
  BannerBorders,
  BannerContent,
  BannerType,
  ButtonWrapper,
  Description,
  DescriptionRow,
  IconWrapper,
  Size,
  Wrapper,
} from './BannerSection.styles'

export interface BannerSectionProps {
  className?: string
  type: BannerType
  icon?: ReactElement | null
  button?: ReactElement | null
  /**
   * Should round the corners of the section
   * @defaults true
   */
  rounded?: boolean
  borders?: BannerBorders[]
  /**
   * Size of the BannerSection. At the moment, size changes only the
   * padding.
   *    - `smaller` - `16px 12px`
   *    - `tiny`    -  `8px 12px`
   *
   * Naming of this property is aligned with the `<Button />` component sizing.
   * However, not all properties are supported (yet).
   */
  size?: Size
}

export const BannerSection: React.FC<BannerSectionProps> = props => {
  const {
    className,
    children,
    type = 'neutral',
    icon = <Information />,
    button,
    rounded = true,
    borders = [],
    size = 'smaller',
  }: typeof props = props

  return (
    <Wrapper
      data-testid="banner-section"
      className={className}
      $type={type}
      $rounded={rounded}
      $borders={borders}
      $size={size}
    >
      <BannerContent>
        <DescriptionRow>
          {icon && <IconWrapper>{icon}</IconWrapper>}
          <Description>{children}</Description>
        </DescriptionRow>
        {button && <ButtonWrapper>{button}</ButtonWrapper>}
      </BannerContent>
    </Wrapper>
  )
}
