import React from 'react'

import {
  ItemWrapper,
  SkeletonTitle,
  SkeletonItem,
} from './DiscoverInsightList.styles'

export const DiscoverInsightListSkeleton = () => (
  <>
    <SkeletonTitle data-testid="insight-list-skeleton" />
    <ItemWrapper>
      <SkeletonItem />
      <SkeletonItem />
    </ItemWrapper>
  </>
)
