import React from 'react'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import {
  Wrapper,
  Title,
  Description,
  StyledLink,
} from './NeedHelpSection.styles'

export const NeedHelpSection: React.FC = () => {
  return (
    <Wrapper>
      <Title>Need Help?</Title>
      <Description>
        For help with setting up SSO for your Workspace, check out our{' '}
        <StyledLink
          variant="tertiary"
          href={`${SKETCH_WEBSITE}/docs/sso/setting-up-saml-sso/`}
          target="_blank"
          external
          isUnderlined
        >
          detailed documentation
        </StyledLink>{' '}
        — or{' '}
        <StyledLink
          variant="tertiary"
          href={`${SKETCH_WEBSITE}/support/contact/`}
          target="_blank"
          external
          isUnderlined
        >
          get in touch
        </StyledLink>{' '}
        with us.
      </Description>
    </Wrapper>
  )
}
