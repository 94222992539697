import React, { FC } from 'react'
import { ThemeProvider } from 'styled-components'
import { render as ReactDOMRender } from 'react-dom'
import jsPDF from 'jspdf'

import { RecoveryCodesPDFTemplate } from '../RecoveryCodesPDFTemplate'

import { lightTheme as theme } from '@sketch/global-styles'
import { StyledButton } from './PrintToPDFButton.styles'

interface PrintToPDFButtonProps {
  codes: string[]
}

export const PrintToPDFButton: FC<PrintToPDFButtonProps> = ({ codes }) => {
  const handlePrintCodes = async () => {
    const container = document.createElement('div')
    ReactDOMRender(
      <ThemeProvider theme={theme}>
        <RecoveryCodesPDFTemplate recoveryCodes={codes} />
      </ThemeProvider>,
      container
    )

    const pdf = new jsPDF('p', 'pt', 'a4')
    pdf.html(container, {
      callback: function (pdf) {
        // Open PDF in a new tab
        const blob = pdf.output('blob')
        window.open(URL.createObjectURL(blob))
      },
    })
  }

  return (
    <StyledButton onClick={handlePrintCodes} size="40">
      Print Codes (PDF)
    </StyledButton>
  )
}
