import styled from 'styled-components'

import CommentDate from '../CommentDate'

import { ReactComponent as Check } from '@sketch/icons/checkmark-circle-fill-16'

export const ResolutionFooterContainer = styled.div`
  padding: 14px 20px; /* stylelint-disable-line scales/space */
  display: flex;

  border-top: 1px solid ${({ theme }) => theme.colors.border.A};
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  align-items: center;
`
export const ResolutionFooterUsername = styled.div`
  display: block;
  margin: 0 8px;

  font-size: 0.8125rem; /* 13px */
  line-height: 1;
  font-weight: 500;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  > div {
    display: inline;
  }
`

export const ResolutionFooterDate = styled(CommentDate)`
  font-size: 0.75rem /* 12px */;
`

export const ResolutionFooterTooltipContent = styled.div`
  display: flex;
  gap: 8px;
`

export const ResolutionFooterCheckIcon = styled(Check)`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
`
