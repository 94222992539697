import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "community-16", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("path", { d: "M1.33 5c2.486 0 4.5 1.583 4.5 3.536 0 .598-.845 1.013-2.018 1.245.013-.081.019-.163.019-.245 0-1.274-.63-2.446-1.7-3.287a5.732 5.732 0 00-1.862-.96c.085-.071.166-.148.243-.229.264-.04.538-.06.819-.06zm0-5a2 2 0 11-.169 3.993A2.997 2.997 0 000 .507C.353.192.82 0 1.33 0z", id: "community-16_svg__a" }),
        React.createElement("path", { d: "M1.33 5c2.486 0 4.5 1.583 4.5 3.536 0 .598-.845 1.013-2.018 1.245.013-.081.019-.163.019-.245 0-.431-.072-.85-.21-1.249a4.75 4.75 0 00.633-.174l-.01-.049c-.213-.57-.798-1.082-1.603-1.355a5 5 0 00-.51-.46 5.732 5.732 0 00-1.862-.96c.085-.071.166-.148.243-.229.264-.04.538-.06.819-.06zm0-5a2 2 0 11-.169 3.993 2.964 2.964 0 00.128-1.497.5.5 0 10-.292-.868A2.986 2.986 0 000 .507C.353.192.82 0 1.33 0z", id: "community-16_svg__b" }),
        React.createElement("path", { d: "M4.5 5c.28 0 .555.02.822.059.075.082.156.158.24.229a5.768 5.768 0 00-1.862.961C2.63 7.09 2 8.262 2 9.536c0 .082.006.163.018.244C.846 9.55 0 9.134 0 8.536 0 6.583 2.015 5 4.5 5zm0-5c.511 0 .978.192 1.332.508a2.993 2.993 0 00-1.164 3.485l-.019.002L4.5 4a2 2 0 110-4z", id: "community-16_svg__c" }),
        React.createElement("path", { d: "M4.5 5c.28 0 .555.02.822.059.075.082.156.158.24.229a5.768 5.768 0 00-1.862.961 5 5 0 00-.51.461c-.805.273-1.39.784-1.602 1.354l-.016.046c.04.016.08.03.12.043l.131.04c.12.034.248.066.385.096A3.832 3.832 0 002 9.536c0 .082.006.163.018.244C.846 9.55 0 9.134 0 8.536 0 6.583 2.015 5 4.5 5zm0-5c.511 0 .978.192 1.332.508-.421.28-.767.666-1 1.12A.5.5 0 104.5 2.5l.042-.005a3.02 3.02 0 00.126 1.497A2 2 0 114.5 0z", id: "community-16_svg__d" })),
    React.createElement("g", { fill: "currentColor" },
        React.createElement("path", { d: "M8 9c2.485 0 4.5 1.583 4.5 3.536 0 1.952-9 1.952-9 0C3.5 10.583 5.515 9 8 9zm0-1a2 2 0 100-4 2 2 0 000 4z", fillOpacity: 0.15 }),
        React.createElement("path", { d: "M8 9c2.485 0 4.5 1.583 4.5 3.536 0 1.952-9 1.952-9 0C3.5 10.583 5.515 9 8 9zm0 1.5c-1.46 0-2.597.717-2.912 1.564l-.016.046c.04.016.08.03.12.043l.131.04c.67.194 1.648.307 2.677.307 1.03 0 2.006-.113 2.677-.307a3.68 3.68 0 00.246-.08l-.01-.049c-.302-.808-1.351-1.499-2.716-1.56L8 10.5zM8 4a2 2 0 110 4 2 2 0 010-4zm0 1.5a.5.5 0 100 1 .5.5 0 000-1z" }),
        React.createElement("g", { transform: "translate(9.67 3)" },
            React.createElement("use", { fillOpacity: 0.15, xlinkHref: "#community-16_svg__a" }),
            React.createElement("use", { xlinkHref: "#community-16_svg__b" })),
        React.createElement("g", { transform: "translate(.5 3)" },
            React.createElement("use", { fillOpacity: 0.15, xlinkHref: "#community-16_svg__c" }),
            React.createElement("use", { xlinkHref: "#community-16_svg__d" }))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
