import SignInFormComponent, {
  Fields,
  FieldsProps as FieldsPropsType,
  Submit,
  Errors,
  ExtendedFormikProps,
} from './SignInForm'

export type SignInFormFieldsProps = FieldsPropsType
export type SignInFormExtendedFormikProps = ExtendedFormikProps

export const SignInForm = Object.assign(SignInFormComponent, {
  Fields,
  Submit,
  Errors,
})
