import React from 'react'
import styled from 'styled-components'

import {
  ListItemIcon,
  ListItemText,
  ListItemWrapper,
  ListHeader,
  ListItemActionWrapper,
  SkeletonListItemIcon,
  SkeletonListItemText,
} from './List.styles'

export interface ListItemProps {
  className?: string
  action?: React.ReactNode
  icon?: React.ElementType
}

const BaseListItem: React.FC<ListItemProps> = props => {
  const { action, className, icon, children } = props

  return (
    <ListItemWrapper className={className}>
      {icon && <ListItemIcon role="img" as={icon}></ListItemIcon>}
      <ListItemText>{children}</ListItemText>
      {action && <ListItemActionWrapper>{action}</ListItemActionWrapper>}
    </ListItemWrapper>
  )
}

export interface SkeletonListItemProps {
  className?: string
  icon?: boolean
}

const BaseListItemSkeleton: React.FC<SkeletonListItemProps> = ({
  className,
  icon,
}) => (
  <ListItemWrapper className={className}>
    {icon && <SkeletonListItemIcon as="span" />}
    <SkeletonListItemText />
  </ListItemWrapper>
)

const ListItem = styled(BaseListItem)``
const ListItemSkeleton = styled(BaseListItemSkeleton)``

export { ListHeader, ListItem, ListItemIcon, ListItemSkeleton }
