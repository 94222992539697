import styled from 'styled-components'

import { StatelessDropdown } from '@sketch/components'
import AppLogo from '@sketch/icons/logo-2x.png'

export const StyledCanvasContextMenu = styled(StatelessDropdown)`
  position: absolute;
`

export const AppIcon = styled.img.attrs({ src: AppLogo })`
  width: 16px;
  height: 16px;
  margin-left: 4px;
`
