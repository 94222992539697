import { createContext, useContext } from 'react'
import { SketchElement } from '../../../../../inspector'

type InspectorStatus = 'pending' | 'ready' | 'errored'

export type InspectorContext = {
  isInspectorActive: boolean
  /** True once renderer has finished rendering.  */
  inspectorStatus: InspectorStatus
  sketchSceneRootElement: SketchElement | null
  selectedElement: SketchElement | null
  setHoveredElement: (element: SketchElement | null) => void
  /**
   * Allow to overwrite the selected element element from a user interaction other than
   * clicking on the canvas.
   */
  setSelectedElement: (element: SketchElement | null) => void
  /**
   * True if at least one layer element in the artboard has exportable assets. This does not
   * count exportable assets at the artboard level.
   * This is use to decide if we want to show the "No exportable assets" message
   * or if we prefer to not show anything when the selected layer does not have any
   * exportable assets.
   * @see https://github.com/sketch-hq/Cloud/issues/6669#issuecomment-748183677
   */
  hasAnyLayerWithExportableAssets: boolean
  /**
   * The handler function to attach to onClick prop of the WebRendererCanvas component
   * to handle layer selection.
   */
  clickHandler: (event: React.MouseEvent<HTMLElement>) => void
  /**
   * The handler function to attach to the onMouseMove prop of the WebRendererCanvas component
   * to handle the hovered layer.
   */
  mouseMoveHandler: (event: React.MouseEvent<HTMLElement>) => void
  mouseDownHandle: (event: React.MouseEvent<HTMLElement>) => void
}

export const inspectorContext = createContext<InspectorContext | undefined>(
  undefined
)

export const inspectorHoveredElementContext = createContext<
  SketchElement | null | undefined
>(undefined)

export function useInspectorContext() {
  const value = useContext(inspectorContext)

  if (typeof value === 'undefined') {
    throw new Error(
      'useInspectorContext must be used within a ArtboardDetailInspectorProvider'
    )
  }

  return value
}

export function useInspectorHoveredElement() {
  const value = useContext(inspectorHoveredElementContext)

  if (typeof value === 'undefined') {
    throw new Error(
      'useInspectorHoveredElement must be used within a ArtboardDetailInspectorProvider'
    )
  }

  return value
}
