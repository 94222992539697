import React, { useEffect, useState } from 'react'
import { Portal } from 'react-portal'
import {
  ListboxPopover,
  ListboxPopoverProps,
  useListboxContext,
} from '@reach/listbox'

import { useBreakpoint } from '../Breakpoint'
import { Modal } from '../Modal'
import { ModalTransition } from '../ModalManager'

import { CancelButton, ModalBody, ModalFooter } from './SelectPopover.styles'

interface MobileSelectPopoverProps {
  isExpanded: boolean
}

const MobileSelectPopover: React.FC<MobileSelectPopoverProps> = props => {
  const { children, isExpanded } = props

  // Reach doesn't seem to like the modal opening so
  // we need to trick it into opening
  const [expanded, setExpanded] = useState(isExpanded)

  // When it expands it opens the modal
  // but the closing is done by when the ModalBody is clicked
  useEffect(() => {
    setExpanded(isExpanded)
  }, [isExpanded])

  const handleClose = () => {
    setExpanded(false)
  }

  return (
    <Portal>
      <ModalTransition show={expanded}>
        <Modal onCancel={handleClose}>
          <ModalBody onClick={handleClose}>
            <ListboxPopover className="cleanListBox" portal={false}>
              {children}
            </ListboxPopover>
          </ModalBody>
          <ModalFooter>
            <CancelButton variant="tertiary" small onClick={handleClose} fill>
              Cancel
            </CancelButton>
          </ModalFooter>
        </Modal>
      </ModalTransition>
    </Portal>
  )
}

export interface SelectPopoverProps
  extends Pick<ListboxPopoverProps, 'position'> {
  className?: string
  showModalInMobile?: boolean
}

export const SelectPopover: React.FC<SelectPopoverProps> = props => {
  const { className, children, position, showModalInMobile } = props

  // Get the Listbox "isExpanded" value
  const { isExpanded } = useListboxContext()
  const isDesktop = useBreakpoint('sm')

  const showDesktopModal = isDesktop || !showModalInMobile
  if (showDesktopModal) {
    return (
      <ListboxPopover className={className} position={position}>
        {children}
      </ListboxPopover>
    )
  }

  return (
    <MobileSelectPopover isExpanded={isExpanded}>
      {children}
    </MobileSelectPopover>
  )
}
