import React from 'react'
import { InitialUserFragment } from '@sketch/gql-types'

import { Avatar, useBreakpoint } from '@sketch/components'

import {
  AvatarWrapper,
  ButtonPill,
  WorkspaceName,
} from './MyWorkspacePill.styles'

export interface MyWorkspacePillProps {
  user: Pick<InitialUserFragment, 'name' | 'avatar'> | undefined
  children?: React.ReactNode
}

/**
 * This components is ported version of "My Workspace" button found on
 * marketing website.
 *
 * Like on marketing website, we are showing user avatar instead of the workspace
 * avatar. If that will ever diverge between marketing website and cloud-frontend app
 * use marketing website as the source of truth.
 */
export const MyWorkspacePill = (props: MyWorkspacePillProps) => {
  const {
    user,
    children = <WorkspaceName>My Workspace</WorkspaceName>,
  }: typeof props = props

  const isDesktop = useBreakpoint('sm')

  const avatar = (
    <Avatar
      src={user?.avatar?.small}
      name={
        user?.name ||
        // if user name is not available, default to `My Workspace` name
        // as this link will lead to a workspace either way
        'My Workspace'
      }
      size="24px"
    />
  )

  if (!isDesktop) {
    return avatar
  }

  return (
    <ButtonPill variant="primary-untinted">
      <AvatarWrapper>{avatar}</AvatarWrapper>
      {children}
    </ButtonPill>
  )
}
