import React from 'react'
import styled, { css } from 'styled-components'
import { NavLink as BaseNavLink, NavLinkProps } from 'react-router-dom'

import { Tooltip, truncateStyles, Pill } from '@sketch/components'

import { useIsNodeContentTruncated } from '@sketch/utils'

import { breakpoint } from '@sketch/global-styles'

const ICON_SIZE = 16

const ActionWrapper = styled.div`
  /* Fix positioning issue with dropdown in Safari */
  position: relative;

  opacity: 0;

  margin-left: 10px; /* stylelint-disable-line scales/space */
  margin-right: 0;

  transition: ${({ theme: { transitions } }) =>
    css`
        opacity ${transitions.duration[2]} ${transitions.timing.easeInOut};
      `};
`

export const Icon = styled.svg`
  ${({ theme }) => css`
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
    color: ${theme.colors.foreground.secondary.D};
  `}
`

const hoverStyle = css`
  background-color: #f2f2f2;
  color: ${({ theme }) => theme.colors.foreground.secondary.B};

  ${ActionWrapper} {
    opacity: 1;
  }

  ${/* sc-selector */ ActionWrapper}:hover {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
  }

  /* Fix the hover color of the icon */
  ${Icon}, ${ActionWrapper} {
    color: ${({ theme }) => theme.colors.foreground.secondary.D};
  }
`

interface StyledLinkProps extends NavLinkProps {
  $forceHover?: boolean
}

const NavLink: React.FC<StyledLinkProps> = ({ $forceHover, ...props }) => (
  <BaseNavLink {...props} />
)

export const menuLinkStyles = css`
  width: 100%;
  height: 40px;

  ${breakpoint('sm')`
    height: 32px;
  `};

  display: inline-flex;
  align-items: center;

  background-color: transparent;

  /*
  Remove 5px from the right padding so that
  the button icon stays at 30px from the sidebar end.
  */
  padding: 0 8px 0 12px;
  border-radius: 5px;

  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.D};
  line-height: 1.4;

  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  transition: none;

  :hover,
  :focus,
  :focus-within {
    ${hoverStyle};

    ${Icon} {
      color: ${({ theme }) => theme.colors.foreground.secondary.D};
    }
  }

  &.active {
    /* TODO: change it, this value is not in our theme: https://github.com/sketch-hq/Cloud/issues/3579 */
    background-color: ${({ theme }) => theme.colors.sketch.F};
    color: ${({ theme }) => theme.colors.sketch.A};

    ${Icon}, ${ActionWrapper} {
      color: ${({ theme }) => theme.colors.sketch.A};
    }

    ${Pill} {
      background: ${({ theme }) => theme.colors.sketch.F};
      color: ${({ theme }) => theme.colors.sketch.B};
    }

    :hover,
    :focus,
    :focus-within {
      background-color: ${({ theme }) => theme.colors.sketch.F};

      color: ${({ theme }) => theme.colors.sketch.A};

      ${/* sc-selector */ ActionWrapper}:hover,
      ${/* sc-selector */ ActionWrapper}:focus,
      ${/* sc-selector */ ActionWrapper}:focus-within {
        color: ${({ theme }) => theme.colors.sketch.A};
      }

      ${Icon}, ${ActionWrapper} {
        color: ${({ theme }) => theme.colors.sketch.A};
      }
    }
  }

  & ~ & {
    margin-top: 1px; /* stylelint-disable-line scales/space */
  }
`

export const StyledMenuLink = styled(NavLink)<StyledLinkProps>`
  ${menuLinkStyles};
  ${({ $forceHover }) => $forceHover && hoverStyle};
`

export const Text = styled.span`
  ${truncateStyles};
  margin-right: auto;
`

export const IconWrapper = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  margin-right: 8px;
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
`

export interface MenuLinkProps {
  action?: React.ReactNode
  className?: string
  iconClassName?: string
  $forceHover?: boolean
  to: string
  text: React.ReactNode
  icon?: React.ComponentType
  /** Text that will appear inside a pill, placed to the right */
  badge?: string

  // Drag and drop events
  onDrop?: (event: React.DragEvent<HTMLElement>) => void
  onDragOver?: (event: React.DragEvent<HTMLElement>) => void
  onDragLeave?: (event: React.DragEvent<HTMLElement>) => void
}

export const MenuLink: React.FC<MenuLinkProps> = props => {
  const {
    action,
    className,
    iconClassName,
    $forceHover,
    icon: iconComponent,
    to,
    text,

    // Support for drag and drop events
    onDragOver,
    onDragLeave,
    onDrop,
    badge,
  } = props

  const [isNodeContentTruncated, truncatedNodeRef] = useIsNodeContentTruncated()

  return (
    <Tooltip
      placement="right"
      content={text}
      disabled={!isNodeContentTruncated}
    >
      <StyledMenuLink
        className={className}
        to={to}
        $forceHover={$forceHover}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        {iconComponent && (
          <IconWrapper className={iconClassName}>
            <Icon as={iconComponent} />
          </IconWrapper>
        )}
        <Text ref={truncatedNodeRef}>{text}</Text>
        {action && <ActionWrapper>{action}</ActionWrapper>}
        {badge && <Pill variant="secondary">{badge}</Pill>}
      </StyledMenuLink>
    </Tooltip>
  )
}
