import React from 'react'

// Plan Types
import { BillingPlanFragment } from '@sketch/gql-types'

import {
  CardWrapper,
  Cents,
  Currency,
  HiddenRadioButton,
  HiddenRadioButtonLabel,
  PlanName,
  Price,
  FakeRadioButton,
  Unit,
  TrialPrice,
  Small,
  BestValuePill,
} from './Card.styles'

const READABLE_PLAN_NAME = {
  YEARLY: 'yearly',
  MONTHLY: 'monthly',
  TRIAL: '30 days trial',
} as const

// Helper functions
const CALCULATE_PRICE = (value: number, output?: 'CENTS' | 'UNITS') => {
  const price = (value / 100).toFixed(2)
  const [units, cents] = `${price}`.split('.')

  if (output === 'CENTS') {
    return cents === '00' ? '' : cents
  } else if (output === 'UNITS') {
    return units
  }

  return price.replace(/\D00$/, '')
}

interface CardProps {
  currency?: {
    code: string
    symbol: string
  }
  isDisabled: boolean
  isSelected: boolean
  onChange: (clickedPlan: BillingPlanFragment) => void
  plan: BillingPlanFragment
}

/*
 * Card
 *
 * Renders a single plan card with its info (plan name,
 * price per editor and monthly / yearly price).
 *
 * Cards are loaded from Apollo cache if available otherwise
 * default values are used.
 *
 */
const Card: React.FC<CardProps> = ({
  isDisabled,
  isSelected,
  onChange,
  plan,
  currency = {
    code: 'USD',
    symbol: '$',
  },
}) => {
  const handleChange = () => onChange(plan)

  const priceUnit = CALCULATE_PRICE(plan.invoicePrice, 'UNITS')
  const priceCents = CALCULATE_PRICE(plan.invoicePrice, 'CENTS')

  const isTrial = plan.type === 'TRIAL'
  const name = READABLE_PLAN_NAME[plan.type] || plan.name

  const renderPlanPrice = ({ isSelected }: { isSelected: boolean }) => {
    return isTrial ? (
      <TrialPrice>Free</TrialPrice>
    ) : (
      <Price>
        <Currency isSelected={isSelected}>{currency.symbol}</Currency>
        <Unit isSelected={isSelected}>{priceUnit}</Unit>
        {priceCents && <Cents>{priceCents}</Cents>}
      </Price>
    )
  }

  return (
    <CardWrapper
      isSelected={isSelected}
      tabIndex={-1}
      isDisabled={isDisabled}
      aria-disabled={isDisabled}
    >
      <HiddenRadioButtonLabel>
        <HiddenRadioButton
          data-testid={`${plan.id}-radio-button`}
          checked={isSelected}
          disabled={isDisabled}
          name="plan"
          onChange={handleChange}
          type="radio"
          value={plan.id}
        />
        <FakeRadioButton
          onKeyPress={handleChange}
          aria-label={name}
          aria-selected={isSelected}
          tabIndex={-1}
        />
      </HiddenRadioButtonLabel>
      <PlanName>{name.toUpperCase()}</PlanName>
      {renderPlanPrice({ isSelected })}
      <Small isSelected={isSelected}>
        {isTrial ? 'unlimited seats' : 'per Editor'}
      </Small>
      {plan.type === 'YEARLY' && (
        <BestValuePill variant="primary" isSelected={isSelected}>
          Best Value
        </BestValuePill>
      )}
    </CardWrapper>
  )
}

export default Card
