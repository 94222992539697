import { castError } from '@sketch/utils'
import { ErrorHandler } from '@sketch/tracing'

const LOCAL_STORAGE_KEY = 'setAppToBeRefreshed'

const clearLocalStorage = () => {
  try {
    localStorage.removeItem(LOCAL_STORAGE_KEY)
  } catch (e) {
    const err = castError(e)
    ErrorHandler.ignore(err)
  }
}

export const onRefreshed = (callback: () => void) => {
  try {
    const date = localStorage.getItem(LOCAL_STORAGE_KEY)

    clearLocalStorage()
    if (!date) return

    // Just a safeguard to check that the item was written to localStorage just recently
    if (new Date(date).getTime() > new Date().getTime() - 1000 * 60) {
      callback()
    }
  } catch (e) {
    const err = castError(e)
    ErrorHandler.ignore(err)
  }
}

export const recordRefresh = () => {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, new Date().toISOString())
  } catch (e) {
    const err = castError(e)
    ErrorHandler.ignore(err)
  }
}
