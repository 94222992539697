import React from 'react'
import { ColorIcon } from '../ColorDot'
import {
  Trigger,
  ColorInfo,
  ColorName,
  ColorPath,
} from './ColorVariablePopover.styles'

const PopoverTrigger = ({
  openPopover,
  name,
  path,
  red,
  green,
  blue,
  alpha,
}: {
  name: string
  path: string
  red: number
  green: number
  blue: number
  alpha: number
  openPopover: () => void
}) => {
  return (
    <Trigger onClick={openPopover}>
      <ColorIcon
        red={red}
        green={green}
        blue={blue}
        alpha={alpha}
        $iconSize={24}
      />
      <ColorInfo>
        <ColorName>{name}</ColorName>
        <ColorPath>{path}</ColorPath>
      </ColorInfo>
    </Trigger>
  )
}

export default PopoverTrigger
