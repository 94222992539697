import React from 'react'

import { ReactComponent as Dot16Icon } from '@sketch/icons/dots-3-horizontal-16'
import { ReactComponent as Dot24Icon } from '@sketch/icons/dots-3-horizontal-24'
import { ReactComponent as CloseIcon } from '@sketch/icons/cross-circle-black-24'

import { useResponsiveDropdown } from '@sketch/components'
import { useMultipleCommentContext } from '../CommentEditContext'

import ResolveAnnotationButton from '../ResolveAnnotationButton'
import AnnotationPopoverOptionsDropdown from '../AnnotationPopoverOptionsDropdown'
import Selector from '../Selector'

import { Wrapper } from './AnnotationPopoverOptions.styles'

import { useGetAnnotationCommentsQuery } from '@sketch/gql-types'

const DROPDOWN_MODIFIERS = [
  { name: 'computeStyles', options: { adaptive: true, gpuAcceleration: true } },
]
interface AnnotationPopoverOptionsProps {
  annotationIdentifier: string
  onModalHide: () => void
  isModalVariant: boolean
}

const AnnotationPopoverOptions = (props: AnnotationPopoverOptionsProps) => {
  const { annotationIdentifier, onModalHide, isModalVariant } = props

  /**
   *  We use the "useGetAnnotationCommentsQuery" with the
   *  "cache-only" fetch policy to make "listen" to the request
   *  made by "AnnotationCommentsList" and the "subscriptionStatus"
   *
   *  All there request logic is made by "AnnotationCommentsList"
   */
  const { data } = useGetAnnotationCommentsQuery({
    variables: { annotationIdentifier: props.annotationIdentifier },
    fetchPolicy: 'cache-only',
  })

  const { activeCommentIdentifier } = useMultipleCommentContext()

  const { annotation } = data || {}
  const disabled = !annotation

  const notificationStatus = annotation?.subscriptionStatus || 'OFF'
  const firstCommentIdentifier = annotation?.firstComment.identifier || ''
  const isResolved = !!annotation?.resolution

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: AnnotationPopoverOptionsDropdown,
    dropdownProps: {
      annotationIdentifier,
      firstCommentIdentifier,
      notificationStatus,
      isResolved,
    },
    placement: 'bottom-end',
    modifiers: DROPDOWN_MODIFIERS,
  })

  const isFirstCommentEditing =
    activeCommentIdentifier === firstCommentIdentifier

  if (isFirstCommentEditing) {
    return null
  }

  const DotIcon = isModalVariant ? Dot24Icon : Dot16Icon

  return (
    <Wrapper $isModalVariant={isModalVariant}>
      <ResolveAnnotationButton
        annotationIdentifier={annotationIdentifier}
        isResolved={!!annotation?.resolution}
        isModalVariant={isModalVariant}
      />
      <Selector
        data-testid="annotation-options"
        disabled={disabled}
        {...buttonProps}
      >
        <DotIcon role="img" />
        <span className="sr-only">Options</span>
      </Selector>
      {isModalVariant && (
        <Selector
          data-testid="close-annotation"
          disabled={disabled}
          onClick={onModalHide}
        >
          <CloseIcon role="img" />
          <span className="sr-only">Close Annotation</span>
        </Selector>
      )}
      {content}
    </Wrapper>
  )
}

export default AnnotationPopoverOptions
