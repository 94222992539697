import React from 'react'
import styled from 'styled-components'

import { shortcuts } from '@sketch/utils'
import { Tooltip } from '@sketch/components'

import { ReactComponent as ChatBubble } from '@sketch/icons/chat-bubble-add-24'

import { usePlaceDraftAnnotation } from '../../hooks'
import { AddButton } from './AddAnnotationButton.styles'

export interface AddAnnotationButtonProps {
  disabled?: boolean
  toolbar?: boolean
}

const AddAnnotationButton: React.FC<AddAnnotationButtonProps> = ({
  disabled,
  children,
  toolbar,
  ...rest
}) => {
  const [placeDraftAnnotation, setPlaceDraftAnnotation] =
    usePlaceDraftAnnotation() || []

  return (
    <Tooltip
      content={
        <>
          Add a comment{' '}
          <Tooltip.Shortcut>
            Press {shortcuts.addComment.toUpperCase()}
          </Tooltip.Shortcut>
        </>
      }
      disableWhenTouchDevice
      placement="bottom"
    >
      <AddButton
        variant={toolbar ? 'secondary-untinted' : 'primary'}
        $toolbar={toolbar}
        size="32"
        data-testid="add-annotation"
        icon={children && ChatBubble}
        disabled={Boolean(disabled) || Boolean(placeDraftAnnotation)}
        {...rest}
        onClick={() => {
          setPlaceDraftAnnotation?.(true)
        }}
      >
        {children || <ChatBubble />}
        <span className="sr-only">New comment</span>
      </AddButton>
    </Tooltip>
  )
}

export default styled(AddAnnotationButton)``
