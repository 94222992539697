import React from 'react'
import styled from 'styled-components'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import { linkCss } from './RecoveryCode.styles'
import { Link } from '@sketch/components'

const StyledLink = styled(Link)`
  ${linkCss}
`

/**
 * Common links used in both Verification Code and Recovery Code UI
 */
export const HelpNote: React.FC = () => {
  return (
    <>
      Lost your recovery codes?{' '}
      <StyledLink
        external
        isUnderlined
        href={`${SKETCH_WEBSITE}/support/contact/`}
      >
        Contact us
      </StyledLink>
      <br />
    </>
  )
}

export default HelpNote
