import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "notes-20-1x", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { id: "Icons/Cloud/20/my.drafts@1x", stroke: "none", strokeWidth: 1, fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "Group", transform: "translate(3 3.5)" },
            React.createElement("path", { d: "M4.025 0h5.95c1.225 0 1.67.128 2.118.367.448.24.8.592 1.04 1.04.24.448.367.893.367 2.118v5.95c0 1.225-.128 1.67-.367 2.118-.24.448-.592.8-1.04 1.04-.448.24-.893.367-2.118.367h-5.95c-1.225 0-1.67-.128-2.118-.367-.448-.24-.8-.592-1.04-1.04C.627 11.145.5 10.7.5 9.475v-5.95c0-1.225.128-1.67.367-2.118.24-.448.592-.8 1.04-1.04C2.355.127 2.8 0 4.025 0z", id: "Rectangle", stroke: "currentColor" }),
            React.createElement("path", { id: "Rectangle", fill: "currentColor", d: "M0 3.5h14v1H0z" }),
            React.createElement("path", { d: "M4 4v2.5h9.5v1H4v2h9.5v1H4V13H3v-2.5H.5v-1H3v-2H.5v-1H3V4h1z", id: "Combined-Shape", fill: "currentColor", opacity: 0.35 }),
            React.createElement("path", { d: "M3.5 0h7a3 3 0 013 3v1H.5V3a3 3 0 013-3z", id: "Rectangle", fill: "currentColor", opacity: 0.2 }))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
