import React from 'react'

import FormField from '../FormField'
import StripeField from '../StripeField'

import {
  InlineStripeField,
  TransactionsIcon,
  TransactionsText,
  Section,
  Row,
} from './CreditCardForm.styles'

interface CreditCardFormFields {
  cardName: string
}

const CreditCardForm = <FormValues extends CreditCardFormFields>() => {
  return (
    <Section>
      <StripeField
        name="cardNumber"
        id="cardNumber"
        label="Card Number"
        type="card-number"
        help="Amex, MasterCard and Visa accepted"
      />
      <FormField<FormValues> name="cardName" label="Name on Card" />
      <Row>
        <InlineStripeField
          id="cardExpirationDate"
          label="Expiry Date"
          name="cardExpirationDate"
          type="card-expiration"
        />
        <InlineStripeField
          id="cardCvc"
          label="CVC"
          name="cardCvc"
          type="card-cvc"
        />
      </Row>
      <TransactionsText>
        <TransactionsIcon />
        <span>All transactions are securely encrypted by Stripe</span>
      </TransactionsText>
    </Section>
  )
}

export default CreditCardForm
