import React from 'react'
import { Location } from 'history'

import {
  getIsForInAppPresentation,
  IndexLayoutExtraProps,
  IndexLayoutOldTitle,
  RedirectBadge,
  RouteProps,
  routes,
  SignInForm,
} from '@sketch/modules-common'
import { Link, OrderedBreadcrumbs } from '@sketch/components'
import { SignInMutation } from '@sketch/gql-types'

import {
  BottomSection,
  BreadcrumbsWrapper,
  FormContainer,
  Line,
  Paragraph,
  StyledLinkButton,
} from './SignInView.styles'

const SIGN_IN_CRUMBS = [{ content: 'Sign In' }, { content: 'Create Workspace' }]

interface LocationState {
  from?: Location
  // In some cases, we need to force the users to login with email and password
  // for their SSO account, for example, when accepting a workspace invitation
  hideSSO?: boolean
}

interface BaseSignInViewProps
  extends RouteProps<'SIGN_IN'>,
    IndexLayoutExtraProps {
  location: Location<LocationState>
  email?: string
  onCompleted: (data: SignInMutation) => void
}

export const BaseSignInView = (props: BaseSignInViewProps) => {
  const { location, onCompleted, email } = props

  const { state } = location
  const linkState = { from: state?.from }
  const showSSOSignInButton = !state?.hideSSO

  const isForInAppPresentation = getIsForInAppPresentation()

  const willContinueToWorkspaceCreation =
    state?.from?.pathname === routes.WORKSPACE_CREATE.create({})

  return (
    <>
      {state?.from && <RedirectBadge from={state.from} {...state} />}

      {willContinueToWorkspaceCreation && (
        <BreadcrumbsWrapper>
          <OrderedBreadcrumbs crumbs={SIGN_IN_CRUMBS} currentCrumb={0} />
        </BreadcrumbsWrapper>
      )}

      <IndexLayoutOldTitle>Sign in to Sketch</IndexLayoutOldTitle>
      <FormContainer>
        <SignInForm email={email} onCompleted={onCompleted} />

        {showSSOSignInButton && (
          <StyledLinkButton
            size="40"
            variant="secondary"
            to={{
              pathname: routes.SSO_SIGN_IN.create({}),
              state: linkState,
            }}
          >
            Sign in with SSO
          </StyledLinkButton>
        )}
      </FormContainer>

      <Line />
      <BottomSection>
        {!isForInAppPresentation && (
          <Paragraph>
            New to Sketch?{' '}
            <Link
              variant="primary"
              isUnderlined
              to={{
                pathname: routes.SIGN_UP.create({}),
                state,
              }}
            >
              Create an account
            </Link>
          </Paragraph>
        )}
      </BottomSection>
    </>
  )
}
