import styled from 'styled-components'

import { Dropdown, Tooltip } from '@sketch/components'

import { ReactComponent as ArrowRightSvg } from '@sketch/icons/arrow-right-16'

export const ArrowDownIcon = styled(ArrowRightSvg)`
  color: ${({ theme }) => theme.colors.sketch.A};
  height: 16px;
  width: 16px;
  transform: rotate(90deg);
`

export const ArrowUpIcon = styled(ArrowRightSvg)`
  color: ${({ theme }) => theme.colors.sketch.A};
  height: 16px;
  width: 16px;
  transform: rotate(270deg);
`

export const ArrowContainerTooltip = styled(Tooltip)`
  display: flex;
  width: 40px;
  height: 32px;
  border-radius: ${({ theme }) => theme.radii.large};

  align-items: center;
  justify-content: center;

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.sketch.F};
  }
`

export const StyledDropdownItem = styled(Dropdown.Item)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 32px;
  padding: 0 0 0 12px;
`
