import styled, { css } from 'styled-components'
import { getDisplayName } from '@sketch/utils'
import {
  alignItems,
  AlignItemsProps,
  flex,
  flexDirection,
  FlexDirectionProps,
  FlexProps as SyledFlexProps,
  flexWrap,
  FlexWrapProps,
  justifyContent,
  JustifyContentProps,
  alignSelf,
  justifySelf,
  JustifySelfProps,
  AlignSelfProps,
} from 'styled-system'

export interface FlexProps
  extends SyledFlexProps,
    FlexWrapProps,
    FlexDirectionProps,
    JustifyContentProps,
    JustifySelfProps,
    AlignSelfProps,
    AlignItemsProps {
  display?: never
  is?: never
}

const FlexCss = css`
  display: flex;
  ${flex}
  ${flexWrap}
  ${flexDirection}
  ${justifyContent}
  ${alignItems}
  ${alignSelf}
  ${justifySelf}
`

interface CompPropsWithoutIs
  extends Omit<React.ComponentPropsWithRef<any>, 'is'> {
  is: never
}

type WithFlexCompArg =
  | keyof JSX.IntrinsicElements
  | React.ComponentType<CompPropsWithoutIs>

export function withFlex<C extends WithFlexCompArg>(Comp: C) {
  // eslint-disable-next-line no-unexpected-multiline
  const styledComp = styled(Comp)<FlexProps>`
    ${FlexCss}
  `
  styledComp.displayName = `withFlex(${getDisplayName(Comp)})`
  return styledComp
}
