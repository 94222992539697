import React from 'react'
import { Location } from 'history'

import { useToast } from '@sketch/toasts'
import { LinkButton, Link } from '@sketch/components'
import { ForgotPasswordForm } from '@sketch/user'
import {
  RouteProps,
  routes,
  getIsForInAppPresentation,
  IndexLayoutOldTitle,
  IndexLayoutOldSubtitle,
  IndexLayoutExtraProps,
  IndexLayoutContent,
  IndexLayoutHeaderLink,
} from '@sketch/modules-common'

import { Wrapper, Text, Line } from './ForgotPasswordView.styles'
import { FormContainer } from '../SignInView/SignInView.styles'

interface LocationState {
  from?: Location
}

interface ForgotPasswordViewProps
  extends RouteProps<'FORGOT_PASSWORD'>,
    IndexLayoutExtraProps {
  location: Location<LocationState>
}

const ForgotPasswordView: React.FC<ForgotPasswordViewProps> = props => {
  const {
    location: { state },
    HeaderPortal,
  } = props
  const { showToast } = useToast()
  const isForInAppPresentation = getIsForInAppPresentation()

  return (
    <IndexLayoutContent center="horizontal" marginTop paddingHorizontal>
      <HeaderPortal>
        <IndexLayoutHeaderLink />
      </HeaderPortal>

      <FormContainer>
        <ForgotPasswordForm
          onCompleted={data => {
            const errors = data.userRequestPasswordReset?.errors || []

            if (errors.length > 0) {
              const apiErrors = errors.map(error => error?.message)
              showToast(apiErrors.join('; '), 'negative')
            }
          }}
          onError={error => {
            showToast(error.message, 'negative')
          }}
        >
          {formikProps =>
            formikProps.successful ? (
              <Wrapper>
                <IndexLayoutOldTitle>Forgot Your Password?</IndexLayoutOldTitle>
                <IndexLayoutOldSubtitle>
                  If there is an account associated with this email address you
                  will receive a password reset email.
                </IndexLayoutOldSubtitle>
                <LinkButton
                  to={{ pathname: routes.SIGN_IN.create({}), state }}
                  variant="primary"
                >
                  Go back to Sign in
                </LinkButton>
              </Wrapper>
            ) : (
              <>
                <IndexLayoutOldTitle>Forgot Your Password?</IndexLayoutOldTitle>
                <IndexLayoutOldSubtitle>
                  Enter the email address you use to sign in to Sketch.
                </IndexLayoutOldSubtitle>
                <ForgotPasswordForm.Fields {...formikProps} />
                <ForgotPasswordForm.Submit {...formikProps} />
                {!isForInAppPresentation && (
                  <>
                    <Line />
                    <Text>
                      Already have a Sketch account?{' '}
                      <Link
                        variant="primary"
                        to={routes.SIGN_IN.create({})}
                        isUnderlined
                      >
                        Sign in
                      </Link>
                    </Text>
                    <Text>
                      New to Sketch?{' '}
                      <Link
                        variant="primary"
                        isUnderlined
                        to={routes.SIGN_UP.create({})}
                      >
                        Create an account
                      </Link>
                    </Text>
                  </>
                )}
              </>
            )
          }
        </ForgotPasswordForm>
      </FormContainer>
    </IndexLayoutContent>
  )
}

export default ForgotPasswordView
