import styled from 'styled-components'

import BillingInfoTable from '../BillingInfoTable'

export const BillingDescriptionCell = styled(BillingInfoTable.Cell)`
  flex-direction: column;
`

export const Description = styled.div`
  word-wrap: break-word;
`

export const DiscountCode = styled.strong`
  color: #6dd400;
`
