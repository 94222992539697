import React from 'react'
import { ShareWithoutVersion } from '../../../versioning'
import InvitePeople from '../../modals/ShareSettingsModal/InvitePeople'
import ShareMemberships, {
  ShareForMembershipList,
} from '../../modals/ShareSettingsModal/ShareMemberships'

export const GuestsTitle = ({ guestsCount }: { guestsCount: number }) => (
  <>Guests {guestsCount > 0 && `(${guestsCount})`}</>
)

export const GuestsPanel = ({ share }: { share: ShareWithoutVersion }) => {
  return (
    <>
      <InvitePeople
        hideLabel
        data-testid="sharing-guests-invite"
        share={share}
        filter="Guests"
        removeSelf
      />
      <ShareMemberships share={share as ShareForMembershipList} guestsOnly />
    </>
  )
}
