import React from 'react'

import {
  IndexLayoutOldTitle,
  GenericErrorView,
  RouteParams,
  routes,
  useQueryParams,
  IndexLayoutExtraProps,
  IndexLayoutContent,
  removeActiveAuthorization,
  useUserProfile,
} from '@sketch/modules-common'
import {
  PersonAvatar,
  WorkspaceLogo,
  useModalContext,
  LoadingState,
  SsoTag,
} from '@sketch/components'
import { useLinkAccountConfirmation } from './useLinkAccountConfirmation'
import {
  Wrapper,
  StyledPanel,
  LinkedWorkspacesAvatarsContainer,
  StyledWorkspaceLogo,
  WorkspaceName,
  Description,
  StyledPanelFooter,
  StyledButton,
  Container,
  Line,
  LineTitle,
  LineContent,
  Name,
  StyledPanelBody,
  WorkspaceLineWrapper,
  StyledLink,
  StyledFakeLinkButton,
} from './LinkAccountConfirmationView.styles'
import { useHistory, useParams } from 'react-router-dom'
import { useToast } from '@sketch/toasts'
import { WorkspaceMinimalIdentityFragment } from '@sketch/gql-types'
import LinkedWorkspacesModal from './LinkedWorkspacesModal'
import { goToLinkAccountUrl } from '../../utils'

const WorkspaceLines = ({
  workspaces,
  userEmail,
}: {
  workspaces: WorkspaceMinimalIdentityFragment[]
  userEmail: string
}) => {
  const { showModal } = useModalContext()

  const maxWorkspaceAvatars = workspaces.length > 3 ? 3 : workspaces.length

  const linkedWorkspaces = workspaces
    .slice(0, maxWorkspaceAvatars)
    .map(workspace => (
      <WorkspaceLineWrapper key={workspace.identifier}>
        <StyledWorkspaceLogo
          key={workspace.identifier}
          size="24px"
          workspaceName={workspace.name}
          src={workspace.avatar?.small}
        />
        <WorkspaceName>{workspace.name}</WorkspaceName>
      </WorkspaceLineWrapper>
    ))

  const numWorkspacesCropped = workspaces.length - 3

  return (
    <Wrapper data-testid="linked-workspaces">
      {linkedWorkspaces}
      {workspaces.length > 3 && (
        <StyledLink
          variant="secondary"
          onClick={() =>
            showModal(LinkedWorkspacesModal, {
              userEmail,
              workspaces,
            })
          }
          isUnderlined
        >
          {numWorkspacesCropped} More…
        </StyledLink>
      )}
    </Wrapper>
  )
}

const LinkedWorkspaces = ({
  workspaces,
  userEmail,
}: {
  workspaces: WorkspaceMinimalIdentityFragment[]
  userEmail: string
}) => {
  if (workspaces.length === 0) return null

  return (
    <LinkedWorkspacesAvatarsContainer>
      <WorkspaceLines workspaces={workspaces} userEmail={userEmail} />
    </LinkedWorkspacesAvatarsContainer>
  )
}

export const LinkAccountConfirmationView = (props: IndexLayoutExtraProps) => {
  const { showToast } = useToast()
  const { workspaceId } = useParams<RouteParams<'SSO_LINK_ACCOUNT'>>()
  const queryParams = useQueryParams<'SSO_LINK_ACCOUNT'>()
  const { data: userProfile, loading, client } = useUserProfile()
  const result = useLinkAccountConfirmation(workspaceId)
  const history = useHistory()

  if (result.type === 'loading' || loading) {
    return <LoadingState />
  }

  if (result.type === 'error') {
    if (result.message) showToast(result.message)

    return <GenericErrorView isInLayout />
  }

  const user = userProfile?.me
  const hasLinkedWorkspaces = result.linkedWorkspaces.length > 0

  return (
    <IndexLayoutContent center="horizontal" marginTop paddingHorizontal>
      <StyledPanel>
        <IndexLayoutOldTitle>Link Your Accounts</IndexLayoutOldTitle>
        <StyledPanelBody>
          <Description>
            Link your accounts to quickly switch between all the Workspaces
            you’ve joined.
          </Description>
          <Container data-testid="signin-workspace">
            <Line>
              <LineTitle>Link</LineTitle>
              <LineContent>
                <WorkspaceLogo
                  src={result.signInWorkspace.avatar?.small}
                  workspaceName={result.signInWorkspace.name}
                  size="24px"
                  UNSAFE_ADD_MARGIN
                />
                <span>{result.signInWorkspace.name}</span>
                <SsoTag />
              </LineContent>
            </Line>
            <Line>
              <LineTitle>to Sketch Account</LineTitle>
              <LineContent>
                {user?.avatar?.small ? (
                  <PersonAvatar
                    size="24px"
                    flavour="image"
                    src={user.avatar.small}
                    name={user?.name ?? ''}
                  />
                ) : (
                  <PersonAvatar
                    size="24px"
                    flavour="color"
                    name={user?.name ?? ''}
                  />
                )}
                <Name>{user?.email ?? ''}</Name>
              </LineContent>
            </Line>

            {hasLinkedWorkspaces && (
              <Line>
                <LineTitle>which has access to</LineTitle>
                <LineContent>
                  <LinkedWorkspaces
                    workspaces={result.linkedWorkspaces}
                    userEmail={user?.email ?? ''}
                  />
                </LineContent>
              </Line>
            )}
          </Container>
        </StyledPanelBody>
        <StyledPanelFooter>
          <StyledButton
            size="40"
            variant="primary"
            onClick={() =>
              goToLinkAccountUrl({
                url: result.signInWorkspace.ssoStartUrl,
                linkedAccountToken: result.ssoLinkToken,
                ...queryParams,
              })
            }
          >
            Link Account
          </StyledButton>
          <StyledFakeLinkButton
            variant="secondary"
            size="40"
            onClick={() => {
              /**
               * Move to the TOS check
               */
              history.push(
                routes.SSO_TOS.create({
                  workspaceId,
                  query: queryParams,
                })
              )

              /**
               * We need to remove the active authorization (personal)
               * if any because on this view the SSO account wasn't linked
               * with the personal one and if any traces of the personal token
               * are send as secondary authorization when fetching
               * BE will return an error
               */
              removeActiveAuthorization(client)
            }}
          >
            Don&apos;t link accounts
          </StyledFakeLinkButton>
        </StyledPanelFooter>
      </StyledPanel>
    </IndexLayoutContent>
  )
}
