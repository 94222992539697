import type {
  ServiceWorkerTyped,
  Kind,
  LogsSource,
  RequestRaw,
  SwReply,
  SwRequests,
} from '@sketch/service-worker'
import { v4 as uuid } from 'uuid'
import { ServiceWorkerTimeoutError } from './ServiceWorkerError'

export interface RequestLog {
  request: Kind
  id?: string
  responded?: boolean
}
export interface ResponseLog {
  response: string
  id?: string
}

type CommunicationLog = RequestLog | ResponseLog

// TODO: Cleanup additional service worker sentry logging,
//       https://github.com/sketch-hq/Cloud/issues/11830
const requestsLog: CommunicationLog[] = []

export class ServiceWorkerClient {
  constructor(
    public readonly originalSw: ServiceWorkerTyped,
    private readonly win = window
  ) {}

  getLogs = async (options: Partial<RequestRaw<'logs'>> = {}) => {
    const {
      source = 'all',
      details = true,
      last,
      first,
    }: Partial<RequestRaw<'logs'>> = options

    const logs = await this.sendRequest('logs', {
      source,
      details,
      last,
      first,
    })
    return logs.data
  }

  getLogsAll = (details = true) => this.getLogs({ source: 'all', details })
  getLogsInstance = (details = true) =>
    this.getLogs({ source: 'running-instance', details })
  getLogsStatic = (details = true) =>
    this.getLogs({ source: 'static', details })

  getState = async () => {
    const response = await this.sendRequest('state', {})
    return response.data
  }

  getLogEntries = async (source: LogsSource = 'all') => {
    const logs = await this.sendRequest('log-entries', {
      source,
    })
    return logs.data
  }

  getLogEntriesAll = () => this.getLogEntries('all')
  getLogEntriesInstance = () => this.getLogEntries('running-instance')
  getLogEntriesStatic = () => this.getLogEntries('static')

  getClientsInfo = async () => {
    const response = await this.sendRequest('clients-info', {})
    return response
  }

  /**
   * It will start service worker intercepting `fetch` events and
   * caching images.
   * This function can be called multiple times in a row - if the
   * service worker was running already it will stay in the same state.
   */
  startWorker = async (
    request: RequestRaw<'start-service'> = {}
  ): Promise<void> => {
    const response = await this.sendRequest('start-service', request)
    if (response.error) {
      throw new Error('could not start service worker, ' + response.error)
    }
  }

  /**
   * It will stop service worker intercepting `fetch` events
   * AND will delete all cache storage related to this service worker.
   *
   * However, it will not unload service worker script file.
   */
  stopWorker = async (): Promise<void> => {
    const response = await this.sendRequest('stop-service', {}, 5000)
    if (response.error) {
      throw new Error('could not stop service worker, ' + response.error)
    }
  }

  popMetrics = async () => {
    const response = await this.sendRequest('metrics', {})
    const { imagesServed, evicted } = response
    return { imagesServed, evicted }
  }

  /**
   * It will stop service and then start the service worker again.
   * In this process cached images and IndexedDb will be cleared.
   * Additionally it is possible to clear logs.
   */
  hardRestart = async (clearLogs = false): Promise<void> => {
    const response = await this.sendRequest('hard-restart', { clearLogs }, 5000)
    if (response.error) {
      throw new Error(
        'could not hard restart service worker, ' + response.error
      )
    }
  }

  getRequestLog = () => {
    return requestsLog.slice(-100)
  }

  private sendRequest = async <K extends Kind>(
    kind: K,
    request: RequestRaw<K>,
    timeout = 3000
  ): Promise<SwReply<K>> => {
    const requestLog: RequestLog = { request: kind }
    requestsLog.push(requestLog)

    const container = this.win.navigator.serviceWorker
    const serviceWorker = this.originalSw

    let handler: ((event: MessageEvent<any>) => void) | undefined = undefined
    const result = await new Promise<SwReply<K>>((res, rej) => {
      const messageId = uuid()
      requestLog.id = messageId

      const timeoutHandle = setTimeout(() => {
        rej(new ServiceWorkerTimeoutError(kind, request))
      }, timeout)

      handler = event => {
        const data = event.data as SwReply<K>
        requestsLog.push({
          response: data?.kind || 'unknown',
          id: data?.messageId,
        })

        // we might receive multiple event listeners added
        // and receive events meant for different handlers
        // so if we have received an event which isn't expected
        // simply skip it
        if (data.kind !== kind) return
        if (data.messageId !== messageId) return

        requestLog.responded = true

        res(data)
        clearTimeout(timeoutHandle)
      }
      container.addEventListener('message', handler)
      const requestData = {
        ...request,
        kind,
        messageId,
      } as SwRequests

      serviceWorker.postMessage(requestData)
    })

    if (handler) container.removeEventListener('message', handler)

    return result
  }
}
