import styled, { css } from 'styled-components'
import { breakpoint } from '@sketch/global-styles'
import HeaderPlaceholder from '@sketch/icons/design-systems/library-management-placeholder-header.svg'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  margin: -40px -40px 0;
`

export const Logo = styled.img`
  height: 64px;
  width: 64px;
  border-radius: ${({ theme }) => theme.radii.large};
`

// HEADER

export const HeaderWrapper = styled.div`
  position: relative;
`

export const HeaderBanner = styled.div<{
  bgSrc: string | null
  $loading: boolean
}>(
  ({ bgSrc, theme, $loading }) => css`
    height: 319px;
    /* don't show any bg while loading, then try the bgSrc and default to placeholder */
    background: url(${$loading ? undefined : bgSrc ?? HeaderPlaceholder});
    background-size: cover;
    background-color: ${theme.colors.background.tertiary.D};
    transition: background ${theme.transitions.duration[2]};
    display: flex;
    justify-content: center;
    align-items: center;
  `
)

export const HeaderOptions = styled.div`
  opacity: 0;
  transition: opacity ${({ theme }) => theme.transitions.duration[2]};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px 40px;
  display: flex;
  align-items: flex-end;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  background-size: 100% 105px;
  background-position: bottom;
  background-repeat: repeat-x;

  ${breakpoint('base', 'sm')`
    opacity: 1;
    top: 16px;
    bottom: auto;
    left: auto;
    background: none;
  `};

  &:hover {
    opacity: 1;
  }
`
