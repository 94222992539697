import React, { useEffect, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 8px 8px 8px 0; /* stylelint-disable-line scales/space */
  margin: 0;

  height: 100%;
  overflow-y: auto;
`

const ShareVersionsListWrapper: React.FC<
  React.ComponentProps<typeof Wrapper>
> = React.forwardRef(function ShareVersionsListWrapper(props, ref) {
  const { children, ...listProps } = props
  const wrapperRef = useRef<HTMLUListElement | null>(null)
  const childCount = React.Children.count(children)

  useImperativeHandle(ref, () => wrapperRef.current)

  const savedWrapperState = useRef({
    offsetHeight: 0,
    scrollHeight: 0,
    scrollTop: 0,
  })

  const currentChildNumber = useRef(childCount)

  useEffect(() => {
    window.requestAnimationFrame(() => {
      if (wrapperRef.current && savedWrapperState.current) {
        const {
          offsetHeight,
          scrollHeight,
          scrollTop,
        } = savedWrapperState.current

        // If on the end of the list keep it there
        // because new items can be added and should be viewed
        if (offsetHeight + scrollTop === scrollHeight) {
          wrapperRef.current.scrollTop =
            wrapperRef.current.scrollHeight - wrapperRef.current.offsetHeight
        } else {
          wrapperRef.current.scrollTop =
            wrapperRef.current.scrollHeight - scrollHeight + scrollTop
        }
      }
    })
  }, [childCount])

  /**
   * Usually this should be done on the unmount step of a useEffect
   * but because it the hook runs after the list has already changed
   * we would need to know the size before so we can maintain the scroll
   *
   * We could also save the values if after the list is updated
   * but those values could change during a window resize and this
   * way is more error prone
   */
  if (currentChildNumber.current !== childCount) {
    currentChildNumber.current = childCount
    savedWrapperState.current = {
      offsetHeight: wrapperRef.current?.offsetHeight || 0,
      scrollHeight: wrapperRef.current?.scrollHeight || 0,
      scrollTop: wrapperRef.current?.scrollTop || 0,
    }
  }

  return (
    <Wrapper ref={wrapperRef} {...listProps}>
      {children}
    </Wrapper>
  )
})

export default ShareVersionsListWrapper
