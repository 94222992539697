import { readSettings } from '../settings'

let _isEnabled: boolean | null = null
export const getIsDummyDataEnabled = () => {
  if (
    !(
      process.env.REACT_APP_ENV === 'dev' ||
      process.env.REACT_APP_ENV === 'test'
    )
  ) {
    return false
  }

  if (_isEnabled !== null) return _isEnabled

  const storedDevToolsState = readSettings()

  _isEnabled = !!(
    storedDevToolsState.general?.isTurnedOn &&
    storedDevToolsState.dummyData?.isTurnedOn
  )

  return _isEnabled
}
