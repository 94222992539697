import React from 'react'
import styled from 'styled-components'

import { routes } from '@sketch/modules-common'

import { Link, Button, ErrorMessage } from '@sketch/components'

import { ReactComponent as WorkspaceIcon } from '@sketch/icons/workspace-64'
import { ReactComponent as GlobeIcon } from '@sketch/icons/globe-64'

import { useGetInitialUserQuery } from '@sketch/gql-types'
import { WorkspaceSidebarLayout } from '../../components/WorkspaceSidebarLayout/WorkspaceSidebarLayout'
import { isBillingHidden } from '@sketch/env-config'

import { useBellNotifications } from '../../../activity/operations'

const StyledErrorMessage = styled(ErrorMessage)<{ isPartner?: boolean }>`
  height: auto;
  max-width: ${({ isPartner }) => (isPartner ? '542px' : '480px')};
  flex: 1 0 auto;
  align-self: center;
  padding: 16px;
`

export const NoWorkspaceView = () => {
  useBellNotifications()

  const { data } = useGetInitialUserQuery()
  const me = data?.me

  const isPartner = me?.isPartner

  const children = (
    <>
      {isPartner ? (
        <StyledErrorMessage
          title="You’re now a Sketch Partner"
          icon={<GlobeIcon />}
          isPartner
          extra={
            <Link
              to={{
                pathname: routes.WORKSPACE_CREATE.create({}),
                state: { fromNoWorkspaces: true },
              }}
            >
              <Button variant="primary">Create Workspace</Button>
            </Link>
          }
        >
          As a Sketch Partner, you can join or create Workspaces, and manage
          billing details and Editor Seats on behalf of your clients.
        </StyledErrorMessage>
      ) : (
        <StyledErrorMessage
          title="Create your first Workspace"
          icon={<WorkspaceIcon />}
          extra={
            <Link
              to={{
                pathname: routes.WORKSPACE_CREATE.create({}),
                state: { fromNoWorkspaces: true },
              }}
            >
              <Button variant="primary">Create Workspace</Button>
            </Link>
          }
        >
          Saved documents will display here.{' '}
          {!me?.eligibleForWorkspaceTrial || isBillingHidden ? (
            ''
          ) : (
            <>
              Kick off your <b>30-day free trial</b> by creating your first
              Workspace!
            </>
          )}
        </StyledErrorMessage>
      )}
    </>
  )

  return (
    <WorkspaceSidebarLayout title="No Workspaces" render={() => children} />
  )
}
