import styled, { css } from 'styled-components'
import { Checkbox, Modal, OrderedBreadcrumbs } from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'

// Height is shared with the document list
export const HEIGHT = '350px'

export const FullWidthContainer = styled.div<{ $noBorder?: boolean }>`
  position: relative;
  /* Make this container touch the sides of the modal */
  margin: 0 -32px;
  padding-top: 8px;
  overflow: hidden;

  ${({ $noBorder }) =>
    !$noBorder &&
    css`
      border-top: 1px solid ${({ theme }) => theme.colors.border.A};
    `}
`

export const StyledOrderedBreadcrumbs = styled(OrderedBreadcrumbs)`
  margin-top: 12px;
  margin-bottom: 24px;

  ${breakpoint('base', 'xs')`
    margin-top: 0px;
  `}
`

export const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 16px 16px;
`

export const StyledModal = styled(Modal)`
  max-width: 640px;
`

export const StyledModalBody = styled(Modal.Body)`
  padding: 0 16px 0 16px;

  ${breakpoint('sm')`
    padding: 0 32px 0 32px;
  `}
`

export const SelectAllCheck = styled(Checkbox)`
  span {
    margin-right: 0;
  }
`
