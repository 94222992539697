import styled, { css } from 'styled-components'

import { Button } from '../../Button'
import { Modal } from '../../Modal'

export const SelectDropdownCancelButton = styled(Button)`
  &&& {
    margin: 8px 0 0;

    &:only-child {
      margin: 0;
    }
  }
`

export const SelectDropdownModalHeader = styled(Modal.Header)`
  padding: 10px 8px 0; /* stylelint-disable-line scales/space */
`

export const SelectDropdownModalBody = styled(Modal.Body)`
  &&& {
    margin-top: 0;
    padding: 0 8px 8px;
    border-bottom: none;
  }
`

export const SelectDropdownModalFooter = styled.div<{ hasOverflow?: boolean }>(
  ({ theme, hasOverflow }) => css`
    display: flex;
    flex-direction: column;
    padding: 8px;

    border-top: ${hasOverflow
      ? `1px solid ${theme.colors.border.A}`
      : undefined};
    text-align: right;

    position: sticky;
    bottom: 0;

    /* Hide the content when it scrolls */
    background: ${theme.colors.background.secondary.A};
  `
)

export const SelectDropdownItemWrapper = styled.div`
  :not(:empty) {
    padding: 8px;

    max-height: 40vh;
    overflow-y: auto;

    & + & {
      border-top: 1px solid ${({ theme }) => theme.colors.border.B};
    }
  }
`
