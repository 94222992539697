import { keyWithoutModifier } from '@sketch/utils'
import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { useKey } from 'react-use'

import { Button, ButtonVariant } from '../Button'
import { Modal } from '../Modal'
import { ModalInjectedProps } from '../ModalManager'

interface ButtonProps {
  text?: string
  variant?: ButtonVariant
  loading?: boolean
  disabled?: boolean

  // `isHidden` is only used for an edge case where we need to show 2 similar
  // modals, one without the confirmation button (<ExplainPublicProfileModal />),
  // but in most cases this prop is not wanted
  isHidden?: boolean
}

export interface ConfirmationDialogProps extends ModalInjectedProps {
  title: ReactNode
  cancelButton?: ButtonProps
  onHide?: () => void
  confirmButton: ButtonProps
  onConfirm: () => void
  onCancelAction?: () => void
  children: React.ReactNode
  hero?: React.ReactElement
}

const HeroWrapper = styled.div`
  border-radius: 16px 16px 0 0;
`

export const ConfirmationDialog = ({
  title,
  hideModal,
  cancelButton: cancelButtonProp,
  onHide,
  confirmButton: confirmButtonProp,
  onConfirm,
  onCancelAction,
  children,
  hero,
}: ConfirmationDialogProps) => {
  const [isTabPressed, setIsTabPressed] = useState(false)

  const cancelButton = { text: 'Cancel', disabled: false, ...cancelButtonProp }
  const confirmButton = {
    variant: 'negative' as ButtonVariant,
    ...confirmButtonProp,
  }

  const handleCancel = () => {
    onHide?.()
    hideModal()

    if (onCancelAction) {
      // This callback serves to execute an action
      // right after the modal is closed.
      // Needed to open chained modals in ProjectSharingModal.tsx
      onCancelAction()
      return
    }
  }

  const handleConfirmOnEnterPressed = () => {
    // In order to mantain "Cancel" and "Confirm" buttons accessible when
    // navigating with "tab", it's only possible to confirm when "Enter" is
    // pressed before using "tab" navigation.
    // The usage of "tab" will disable this functionality.
    if (!isTabPressed) onConfirm()
  }

  const handleTabPressed = () => {
    setIsTabPressed(true)
  }

  useKey(keyWithoutModifier('Enter'), handleConfirmOnEnterPressed, {
    event: 'keyup',
  })
  useKey(keyWithoutModifier('Tab'), handleTabPressed, { event: 'keyup' })

  return (
    <Modal onCancel={handleCancel}>
      {hero && <HeroWrapper>{hero}</HeroWrapper>}
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleCancel}
          disabled={cancelButton.disabled}
          variant={cancelButton.variant}
        >
          {cancelButton.text}
        </Button>
        {!confirmButton.isHidden && (
          <Button
            data-testid="confirmation-button"
            variant={confirmButton.variant}
            loading={confirmButton.loading}
            disabled={confirmButton.disabled}
            onClick={onConfirm}
          >
            {confirmButton.text}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
