import { useMemo } from 'react'

import { handleFetchMore } from '@sketch/components'
import { dataIdFromObject } from '@sketch/graphql-cache'
import {
  ShareGroupMembershipInfoFragment,
  ShareMembershipInfoFragment,
  useGetShareMembershipsQuery,
} from '@sketch/gql-types'

const EMPTY_ARRAY = [] as const
const ENTRIES_PATH = ['share', 'members', 'entries']

const useShareMemberships = (shareIdentifier: string) => {
  const { data, error, fetchMore, loading } = useGetShareMembershipsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { shareIdentifier },
  })

  const memberships = data?.share?.members?.entries || EMPTY_ARRAY
  const myMembership = data?.share?.myMembership

  /**
   * There's an issue with the BE implementation that includes both
   * Share Memberships and PermissionGroup Memberships. The "after" value
   * is always present, even tho there might not be more to load.
   *
   * Therefore we need to check if the count is the same as the loaded ones, if so
   * there's no more to loads
   */
  const after =
    data?.share?.members.meta.totalCount === memberships.length
      ? null
      : data?.share?.members.meta.after

  const { guests, members } = useMemo(() => {
    const guests: ShareMembershipInfoFragment[] = []
    const members: (
      | ShareMembershipInfoFragment
      | ShareGroupMembershipInfoFragment
    )[] = []

    memberships.forEach(member => {
      if (
        member.__typename === 'ShareMembership' &&
        member.workspaceMembership?.role === 'GUEST'
      ) {
        guests.push(member)
      } else {
        members.push(member)
      }
    })

    return { guests, members }
  }, [memberships])

  const loadNewPage = handleFetchMore(fetchMore, ENTRIES_PATH, {
    dataIdFromObject,
    after,
  })

  return {
    loading,
    error,
    after,
    guestsCount: guests.length,
    guests,
    members,
    memberships,
    myMembership,
    loadNewPage,
    data,
  }
}

export default useShareMemberships
