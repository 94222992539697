import React from 'react'

// Cache
import { dataIdFromObject } from '@sketch/graphql-cache'

import {
  Dropdown,
  handleFetchMore,
  Text,
  LoadPageSeparator,
} from '@sketch/components'

import {
  Wrapper,
  ErrorItem,
  StyledLoadingPlaceholder,
} from './PublicationPagesDropdown.styles'

import {
  PublicationPageFragment,
  useGetSharePagesQuery,
} from '@sketch/gql-types'

const ENTRIES_PATH = ['share', 'version', 'document', 'pages', 'entries']

interface PublicationPagesDropdownProps {
  identifier: string
  selectedPage: PublicationPageFragment
  onPageSelected: (page: PublicationPageFragment) => void
}

const PublicationPagesDropdown: React.FC<PublicationPagesDropdownProps> = ({
  identifier,
  onPageSelected,
  selectedPage,
}) => {
  // This query was already triggered by its parent view, here we load
  // more pages when needed (users scrolls down the page list)
  const { data, error, loading, fetchMore } = useGetSharePagesQuery({
    variables: {
      identifier,
    },
    notifyOnNetworkStatusChange: true,
  })

  const pages = data?.share?.version?.document?.pages?.entries ?? [selectedPage]
  const meta = data?.share?.version?.document?.pages?.meta

  const onLoadMore = handleFetchMore(fetchMore, ENTRIES_PATH, {
    dataIdFromObject,
    after: meta?.after,
  })

  return (
    <Wrapper>
      {pages.map(page => {
        const { identifier, name } = page

        return (
          <Dropdown.Item
            key={identifier}
            onClick={() => onPageSelected(page)}
            aria-selected={identifier === selectedPage.identifier}
          >
            {name}
          </Dropdown.Item>
        )
      })}

      {meta?.after && (
        <Dropdown.Item>
          <LoadPageSeparator key={meta.after} loadNewPage={onLoadMore} />
        </Dropdown.Item>
      )}

      {loading && <StyledLoadingPlaceholder />}
      {error && (
        <ErrorItem>
          <Text textStyle="copy.negative.standard.D">
            Unable to load. Try again later
          </Text>
        </ErrorItem>
      )}
    </Wrapper>
  )
}

export default PublicationPagesDropdown
