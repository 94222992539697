import React, { useRef, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Header } from '../Header'

import {
  FullPageLayout,
  Wrapper,
  Sidebar,
  Content,
  ContentContainer,
} from './SplitLayout.styles'

import {
  useOnClickOutside,
  DROPDOWN_SELECTOR,
  MODAL_SELECTOR,
  OPTION_ITEM,
} from '@sketch/utils'

import {
  callIfFunction,
  RenderFunctionOrNode,
} from '../SidebarLayout/callIfFunction'

import { SearchExpandedProvider } from '../HeaderContext'

/**
 * TYPES
 */
interface SplitLayoutProps {
  title?: string
  /** Left sidebar, for navigation */
  sidebar?: RenderFunctionOrNode
  header: RenderFunctionOrNode
  footer: RenderFunctionOrNode
  children?: RenderFunctionOrNode
}

/**
 * COMPONENT
 * Used for documents view (homepage), it provides a left sidebar to show the
 * main navigation
 */
const SplitLayout: React.FC<SplitLayoutProps> = ({
  title,
  sidebar,
  header,
  footer,
  children,
}) => {
  // Only used for mobile
  const [isSidebarLeftOpen, setSidebarLeftOpen] = useState(false)

  const closeSidebar = () => {
    if (isSidebarLeftOpen) {
      // setTimeout is needed to make sure `setSidebarLeftOpen` is called after
      // any other click event listener, for example the hamburger icon click
      setTimeout(() => setSidebarLeftOpen(false), 0)
    }
  }

  // Close the left sidebar when the user clicks outside of it
  const sidebarRef = useRef<HTMLElement>(null)
  useOnClickOutside(sidebarRef, closeSidebar, {
    includeSelectors: [MODAL_SELECTOR, DROPDOWN_SELECTOR, OPTION_ITEM],
  })

  // Make sure the sidebar is closed every time the route changes
  const location = useLocation()
  useEffect(() => {
    closeSidebar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <SearchExpandedProvider>
      <FullPageLayout title={title}>
        <Wrapper>
          {sidebar && (
            <Sidebar ref={sidebarRef} isSidebarOpen={isSidebarLeftOpen}>
              {callIfFunction(sidebar)}
            </Sidebar>
          )}

          <Content data-testid="page-content" isSidebarOpen={isSidebarLeftOpen}>
            <Header>
              {callIfFunction(header, {
                isSidebarLeftOpen,
                setSidebarLeftOpen,
              })}
            </Header>

            <ContentContainer isSidebarOpen={isSidebarLeftOpen}>
              {callIfFunction(children)}
            </ContentContainer>
            {callIfFunction(footer)}
          </Content>
        </Wrapper>
      </FullPageLayout>
    </SearchExpandedProvider>
  )
}

export { SplitLayout }
