import { Resolvers } from 'apollo-client'

import { dummyResolvers } from './dummyResolvers'
import { localResolvers } from './localResolvers'
import { augmentAndMergeResolvers } from './resolvers.augment'

export const resolvers: Resolvers = augmentAndMergeResolvers([
  dummyResolvers,
  localResolvers,
])
