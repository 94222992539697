import styled from 'styled-components'
import BaseAnnotationDot from '../AnnotationDot'

export const TextWrapper = styled.div`
  max-width: min(300px, 100vw);
  color: ${({ theme }) => theme.colors.foreground.primary.B};
`

export const Header = styled.span`
  color: ${({ theme }) => theme.colors.foreground.primary.A};
  display: block;
`

export const AnnotationDot = styled(BaseAnnotationDot)`
  /* This will allow to represent an oval shape when the count is one digit */
  min-width: 18px;
  min-height: 16px;

  &:hover {
    min-width: 20px;
    min-height: 18px;
  }

  /* When the link is disabled it should prevent */
  /* the pointer events and look disabled  */
  /* this rule is triggered when "placeDraftAnnotation" is on */
  &[aria-disabled='true'] {
    pointer-events: none;
    opacity: 0.5;
  }

  /* When a annotation is active it should force */
  /* the sibling items to look disabled. */

  /* however the user should be able to select-it */
  &[aria-current='true'] ~ & {
    opacity: 0.5;

    /* when hovered the opacity should be the normal one */
    &:hover {
      opacity: 1;
    }
  }
`
