import React from 'react'
import styled from 'styled-components'

import { breakpoint } from '@sketch/global-styles'
import { OpenInMacButton } from '@sketch/modules-common'
import { isMac } from '@sketch/utils'

import DocumentListEmptyState from '../DocumentListEmptyState'
import { useBreakpoint } from '@sketch/components'

interface PrototypesEmptyStateProps {
  userIsEditor: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 40px;

  ${breakpoint('base', 'sm')`
    padding: 16px;
  `}
`

const PrototypesEmptyState: React.FC<PrototypesEmptyStateProps> = ({
  userIsEditor,
}) => {
  const isMacUser = isMac()
  const isMobile = !useBreakpoint('md')
  const openMacAppAction =
    isMacUser && userIsEditor && !isMobile ? <OpenInMacButton /> : null

  return (
    <Wrapper>
      <DocumentListEmptyState
        title="No prototypes yet"
        description="To start creating a prototype for this document, open it in the Mac app and select an Artboard as a start point."
        actions={openMacAppAction}
        // Content can be found in
        // cloud-frontend/packages/build-app/public/data/discover-resource-prototypes-empty-data.json
        editorialContent="empty-state-prototypes"
      />
    </Wrapper>
  )
}

export default PrototypesEmptyState
