import React from 'react'
import { Link } from 'react-router-dom'

import { useUserSignedIn } from '../../user'
import { IS_EMBEDDED } from '@sketch/constants'
import { SKETCH_WEBSITE } from '@sketch/env-config'
import { Tooltip, ExternalLink, Navbar } from '@sketch/components'

/**
 * Logo sometimes shown on top left corner of header.
 * Links to cloud app root, sketch.com or the non-embed version of the current page in a new tab
 * based on if the user is logged in or not and if the user is using the embed mode or not.
 */
export function SketchHomeButton() {
  const isSignedIn = useUserSignedIn()

  if (IS_EMBEDDED) {
    const origin = window.location.origin
    const pathname = window.location.pathname.replace(/^\/embed/, '')
    const currentUrl = `${origin}${pathname}`

    return (
      <Tooltip placement="bottom-start" content="Open in a new tab">
        <ExternalLink href={currentUrl} aria-label="Open in a new tab">
          <Navbar.Logo variant="black" />
        </ExternalLink>
      </Tooltip>
    )
  }

  if (isSignedIn) {
    return (
      <Link to="/c" title="Home">
        <Navbar.Logo variant="black" />
      </Link>
    )
  }

  return (
    <ExternalLink href={`${SKETCH_WEBSITE}/home`} title="Home">
      <Navbar.Logo variant="black" />
    </ExternalLink>
  )
}
