import {
  WorkspaceMinimalFragment,
  WorkspaceMinimalWithBillingStatusFragment,
} from '@sketch/gql-types'
import { useUserAuthorizations } from '@sketch/modules-common'

type Workspace =
  | WorkspaceMinimalFragment
  | WorkspaceMinimalWithBillingStatusFragment

/**
 * Get information about the different statuses of the workspace in
 * in the context of the workspaces dropdown.
 */
export function useWorkspaceDropdownStatuses(
  workspace: Workspace,
  selectedWorkspaceId: string
) {
  const { hasAccessToWorkspace } = useUserAuthorizations()

  return getWorkspaceDropdownStatuses(
    workspace,
    selectedWorkspaceId,
    hasAccessToWorkspace
  )
}

/**
 * @returns an object with different status information
 * about the workspace that can be used in the workspaces dropdown.
 */
function getWorkspaceDropdownStatuses(
  workspace: Workspace,
  selectedWorkspaceId: string,
  hasAccessToWorkspace: ReturnType<
    typeof useUserAuthorizations
  >['hasAccessToWorkspace']
) {
  const workspaceBillingStatus = isWorkspaceWithBillingStatus(workspace)
    ? workspace.customer?.billing?.status
    : undefined

  const { ssoEnabled: isSSOWorkspace } = workspace.customer || {}

  const isAuthorizedWorkspace = hasAccessToWorkspace(workspace)
  const isWorkspaceActive = workspace.status === 'ACTIVE'
  const isGuestWorkspace = workspace.userRole === 'GUEST'
  const isOnTrial = workspaceBillingStatus === 'TRIALING'

  const shouldShowTrialPill =
    isOnTrial &&
    isWorkspaceActive &&
    !isGuestWorkspace &&
    isAuthorizedWorkspace &&
    // We have decided to not show trial pill for sso workspaces
    // but we could change that if we wanted as an SSO workspace can be in trial.
    //https://sketch.slack.com/archives/C04N2EPHUGN/p1679576959940739?thread_ts=1679572023.772919&cid=C04N2EPHUGN
    !isSSOWorkspace

  return {
    isAuthorizedWorkspace,
    isWorkspaceActive,
    isGuestWorkspace,
    isSSOWorkspace,
    shouldShowTrialPill,
    isSelectedWorkspace: workspace.identifier === selectedWorkspaceId,
    isPartnerWorkspace: workspace.userRole === 'PARTNER',
    isOnTrial: workspaceBillingStatus === 'TRIALING',
  }
}

/** Typeguard to know the exact type of the workspace */
export function isWorkspaceWithBillingStatus(
  workspace: Workspace
): workspace is WorkspaceMinimalWithBillingStatusFragment {
  const customer = workspace.customer || {}

  return 'billing' in customer
}
