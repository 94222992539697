import * as timeConstants from './constants'

export const getRelativeDateComposition = (seconds: number) => {
  /**
   * This util is based on the NSDate+BCFoundation.m code
   * https://github.com/sketch-hq/Sketch/blob/c0a3cb5a87877a58e619991a7925a363bcab4630/Modules/BCFoundation/Source/NSDate%2BBCFoundation.m
   */

  const year = seconds / timeConstants.YEAR
  const month =
    ((year - Math.floor(year)) * timeConstants.YEAR) / timeConstants.MONTH
  const weekOfMonth =
    ((month - Math.floor(month)) * timeConstants.MONTH) / timeConstants.WEEK
  const day =
    ((weekOfMonth - Math.floor(weekOfMonth)) * timeConstants.WEEK) /
    timeConstants.DAY
  const hour =
    ((day - Math.floor(day)) * timeConstants.DAY) / timeConstants.HOUR
  const minute =
    ((hour - Math.floor(hour)) * timeConstants.HOUR) / timeConstants.MINUTE
  const second = (minute - Math.floor(minute)) * timeConstants.MINUTE

  return {
    year: Math.floor(year),
    month: Math.floor(month),
    weekOfMonth: Math.floor(weekOfMonth),
    day: Math.floor(day),
    hour: Math.floor(hour),
    minute: Math.floor(minute),
    second: Math.floor(second),
  }
}

export const getRelativeDate = (seconds: number) => {
  const compositeDate = getRelativeDateComposition(seconds)

  if (compositeDate.year > 0) {
    return [
      compositeDate.year + (compositeDate.month > 6 ? 1 : 0),
      'year',
    ] as const
  } else if (compositeDate.month > 0) {
    return [
      compositeDate.month +
        (compositeDate.day + compositeDate.weekOfMonth * 7 > 14 ? 1 : 0),
      'month',
    ] as const
  } else if (compositeDate.weekOfMonth > 0) {
    return [
      compositeDate.weekOfMonth + (compositeDate.day % 7 > 3 ? 1 : 0),
      'week',
    ] as const
  } else if (compositeDate.day > 0) {
    return [
      compositeDate.day + (compositeDate.hour > 11 ? 1 : 0),
      'day',
    ] as const
  } else if (compositeDate.hour > 0) {
    return [
      compositeDate.hour + (compositeDate.minute > 30 ? 1 : 0),
      'hour',
    ] as const
  } else if (compositeDate.minute > 0) {
    return [
      compositeDate.minute + (compositeDate.second > 30 ? 1 : 0),
      'minute',
    ] as const
  } else {
    return [compositeDate.second, 'second'] as const
  }
}

export { timeConstants }
