import styled from 'styled-components'

import { Avatar as BaseAvatar, truncateStyles } from '@sketch/components'

export const Container = styled.section`
  display: flex;
  align-items: center;
  margin-left: 5px; /* stylelint-disable-line scales/space */
  overflow: hidden;
`

/* stylelint-disable scales/radii */
// TODO: Fix scales/radii stylelint issues - https://github.com/sketch-hq/Cloud/issues/13759
export const BackgroundWrapper = styled.div`
  padding: 11px 12px; /* stylelint-disable-line scales/space */
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  border: 0.5px solid ${({ theme }) => theme.colors.border.A}; /* stylelint-disable-line scales/border-width */
  border-radius: 7px;
  box-shadow: ${({ theme }) => theme.shadows.boxShadow.subtle};
`
/* stylelint-enable scales/radii */

export const Avatar = styled(BaseAvatar)`
  margin: 0px 8px 0px 0;
`

Avatar.defaultProps = {
  size: '32px',
}

export const Text = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.D};
  ${truncateStyles};
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  overflow: hidden;
  flex: 1;

  & ${/* sc-selector */ Text}:first-child {
    display: flex;
    align-items: center;

    font-size: 0.875rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  & ${/* sc-selector */ Text}:last-child {
    font-size: 0.8125rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.foreground.secondary.C};
  }
`

export const TextOverflow = styled.span`
  ${truncateStyles};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`
