import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { TruncateWithTooltip, HighlightedText } from '@sketch/components'
import {
  ListGridItem,
  ItemImage,
  ItemName,
  ItemImageWrapper,
  ItemSecondary,
} from '../../components/Grid'
import { GridSize, useCustomGridContext } from '../../context/CustomGridContext'

import type { SymbolParsed, Thumbnail } from '../../types'
import { getThumbnail } from '../../utils'

const SymbolImage = styled(ItemImage)<{ isHovering: boolean }>`
  opacity: ${({ isHovering }) => (isHovering ? 0 : undefined)};
`

const SymbolImageWrapper = styled.div<{ isHovering: boolean }>`
  padding: ${({ isHovering }) => (isHovering ? `8px` : 0)};
`

// gets the 2x image url formatted for background-image
const getBackgroundImage = (srcSet?: string) => {
  if (!srcSet) {
    return undefined
  }

  const splitResolutions = srcSet.split(',')

  const standard = splitResolutions[0]
  const retina = splitResolutions[1].replace(' 2x', '').trim()

  if (!standard.length) {
    return undefined
  }

  const imageUrl = `
     url("${standard}")
     ${retina && `, url("${retina}")  2x`}
  `

  return imageUrl
}

export const Figure = styled.figure<{
  backgroundImage: string | undefined
  height: number
  width: number
}>(
  ({ backgroundImage, height, width }) => css`
    /*width and height with less 8px to have a border*/
    width: ${width ? `${width - 8}px` : `100%`};
    height: ${height ? `${height - 8}px` : `100%`};
    margin: 0;
    background-repeat: no-repeat;

    /* stylelint-disable value-no-vendor-prefix */
    ${backgroundImage &&
    css`
      background-image: -webkit-image-set(${backgroundImage});
    `}
    /* stylelint-enable value-no-vendor-prefix */
    

    /*will-change sorcery to make safari not have an epilepsy attack*/
    will-change: background-image;
  `
)

export const SymbolImageZoom = ({
  imageProps,
  dimensions,
}: {
  imageProps: {
    src: string
    srcSet: string
    alt: string
  }
  dimensions: { width: number; height: number }
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { selectedGridSize } = useCustomGridContext()
  const [backgroundPosition, setBackgroundPosition] = useState('0% 0%')
  const [isHovering, setIsHovering] = useState(false)
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  })
  const [mouseMoveTarget, setMouseMoveTarget] = useState<{
    left: number
    top: number
    width: number
    height: number
  } | null>(null)
  const [delayHandler, setDelayHandler] = useState<any>(null)

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    const { left, top, width, height } =
      mouseMoveTarget ?? (target?.getBoundingClientRect() || {})

    // saving these values to avoid jumps on the hovering caused by changing width, for instance
    if (!mouseMoveTarget) {
      setMouseMoveTarget(target?.getBoundingClientRect())
    }

    const containerHeight = containerRef?.current?.clientHeight ?? 0
    const containerWidth = containerRef?.current?.clientWidth ?? 0

    // moving zoom on axis should not apply if the image is smaller on width or height

    const x =
      // if the image width is smaller than the container width, we don't need to move the background (stay in center)
      imageDimensions.width < containerWidth
        ? 50
        : ((e.pageX - left) / width) * 100

    const y =
      // same situation with the height! Smaller height than container, fixed position at 50%
      imageDimensions.height < containerHeight
        ? 50
        : ((e.pageY - top) / height) * 100

    setBackgroundPosition(`${x}% ${y}%`)
  }

  // compares the size of the symbol image and its container
  // if the image is smaller than the container, then we don't need zoom
  const compareDimensions = (width: number, height: number) => {
    const containerHeight = containerRef?.current?.clientHeight ?? 0
    const containerWidth = containerRef?.current?.clientWidth ?? 0

    // only activate hover to zoom if the image is smaller than the container
    if (width > containerWidth || height > containerHeight) {
      setIsHovering(true)
    }
  }

  const activateZoom = () => {
    const { width, height } = imageDimensions

    // if we haven't gotten the symbol image dimensions yet,
    if (width === 0 && height === 0) {
      const image = new Image()
      image.src = imageProps.src

      image.onload = () => {
        // use those dimensions to compare, and then store, so we don't need to do this again
        compareDimensions(image.width, image.height)
        setImageDimensions({ width: image.width, height: image.height })
      }
    } else {
      compareDimensions(width, height)
    }
  }

  // delay before activating zoom
  const handleMouseEnter = () => {
    if (selectedGridSize === GridSize.Full) {
      return
    }

    setDelayHandler(setTimeout(activateZoom, 800))
  }

  const handleMouseLeave = () => {
    if (selectedGridSize === GridSize.Full) {
      return
    }

    clearTimeout(delayHandler)
    setIsHovering(false)
  }

  const backgroundImage = isHovering
    ? getBackgroundImage(imageProps.srcSet)
    : undefined

  return (
    <SymbolImageWrapper
      isHovering={isHovering}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Figure
        ref={containerRef}
        onMouseMove={handleMouseMove}
        backgroundImage={backgroundImage}
        style={{ backgroundPosition }}
        width={dimensions.width}
        height={dimensions.height}
      >
        <SymbolImage
          isHovering={isHovering && !!backgroundImage}
          {...imageProps}
        />
      </Figure>
    </SymbolImageWrapper>
  )
}

interface SymbolItemProps {
  symbol: SymbolParsed
  searchValue: string
  groupName?: string
  isSearch?: boolean
}

export const SymbolItem = ({
  symbol,
  searchValue,
  groupName,
  isSearch,
}: SymbolItemProps) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const gridItemRef = useRef<HTMLDivElement>(null)

  const thumbnails = {
    '1x': (symbol.artboard.files.find(f => f.scale === 1)?.thumbnails ??
      []) as Thumbnail[],
    '2x': (symbol.artboard.files.find(f => f.scale === 2)?.thumbnails ??
      []) as Thumbnail[],
  }

  const imageProps = {
    alt: symbol.name,
    src: getThumbnail(thumbnails['1x']),
    srcSet: `
      ${getThumbnail(thumbnails['1x'])},
      ${getThumbnail(thumbnails['2x'])} 2x
    `,
  }

  useEffect(() => {
    setDimensions({
      width: gridItemRef.current?.clientWidth ?? 0,
      height: gridItemRef.current?.clientHeight ?? 0,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridItemRef])

  return (
    <ListGridItem>
      <ItemImageWrapper ref={gridItemRef}>
        <SymbolImageZoom dimensions={dimensions} imageProps={imageProps} />
      </ItemImageWrapper>
      <ItemName>
        <TruncateWithTooltip>
          <HighlightedText search={searchValue}>{symbol.name}</HighlightedText>
        </TruncateWithTooltip>
      </ItemName>
      {isSearch && (
        <ItemSecondary>
          <TruncateWithTooltip>{groupName}</TruncateWithTooltip>
        </ItemSecondary>
      )}
    </ListGridItem>
  )
}
