import React from 'react'
import PropTypes from 'prop-types'
import { useShareAcceptRequestMutation } from '@sketch/gql-types'
import { Footer, EmptyHeader, routes } from '@sketch/modules-common'
import { LoadingState, DoMutation, PageLayout } from '@sketch/components'
import { parse } from 'query-string'

import { useToast } from '@sketch/toasts'
import { parseInviteInfo } from '@sketch/utils'
import * as Sentry from '@sentry/browser'

const AcceptShareAccessRequestView = ({ location, history }) => {
  const params = parse(location.search)

  let accessInfo
  try {
    accessInfo = parseInviteInfo(params.token)
  } catch (e) {
    Sentry.captureException(e)
  }

  const shareIdentifier = accessInfo?.share_short_id
  const membershipId = accessInfo?.membership_id

  const { showToast } = useToast()
  const [acceptShareAccessRequest] = useShareAcceptRequestMutation({
    variables: { id: membershipId },
    onError: () => {
      showToast(
        'Something went wrong with accepting this access request.',
        'negative'
      )

      history.push({
        pathname: routes.SHARE_VIEW.create({ shareID: shareIdentifier }),
        search: `?settings`,
      })
    },
    onCompleted: data => {
      const errors = data?.shareAcceptRequest?.errors
      if (errors && errors.length >= 1) {
        showToast(data.shareAcceptRequest.errors[0].message, 'negative')
        history.push({
          pathname: routes.SHARE_VIEW.create({ shareID: shareIdentifier }),
          search: `?settings`,
        })
        return
      }

      showToast(`Request accepted`)

      history.push(
        routes.SHARE_VIEW.create({
          shareID: data.shareAcceptRequest.membership.share.identifier,
        })
      )
    },
  })

  return (
    <PageLayout header={<EmptyHeader />} footer={<Footer />}>
      <DoMutation mutate={acceptShareAccessRequest}>
        <LoadingState />
      </DoMutation>
    </PageLayout>
  )
}

AcceptShareAccessRequestView.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  showToast: PropTypes.func.isRequired,
}

export default AcceptShareAccessRequestView
