import { LocationDescriptor, parsePath } from 'history'
import qs from 'query-string'

export const addQueryParametersToUrl = (
  url: string,
  queryParameters: { [key: string]: string }
): string => {
  const parsedUrl = qs.parseUrl(url)

  Object.keys(queryParameters).forEach(
    key => (parsedUrl.query[key] = queryParameters[key])
  )

  return qs.stringifyUrl(parsedUrl)
}

export const normalizeToLocationObject = (location: LocationDescriptor<any>) =>
  typeof location === 'string' ? parsePath(location) : location
