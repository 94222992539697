import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "at-email-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { stroke: "currentColor", strokeWidth: 1.5, fill: "none", fillRule: "evenodd" },
        React.createElement("circle", { cx: 12, cy: 12, r: 4 }),
        React.createElement("path", { d: "M20.5 12a8.5 8.5 0 10-3.355 6.767", strokeLinecap: "round" }),
        React.createElement("path", { d: "M20.5 12v.45a2.25 2.25 0 11-4.5 0V12" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
