import React, {
  useState,
  CSSProperties,
  useEffect,
  useRef,
  PropsWithRef,
} from 'react'
import styled, { css } from 'styled-components'

import { LoadingPlaceholder as BaseLoadingPlaceholder } from '../LoadingPlaceholder'
import { noop } from '@sketch/utils'

import { ReactComponent as ImageSlash } from '@sketch/icons/image-slash-64'
import { ReactComponent as WarningTriangle } from '@sketch/icons/warning-triangle-64'

interface ImageSizeProps {
  width?: number | string
  height?: number | string
}

export type ObjectFit = 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'

export type ImageBaseProps = PropsWithRef<
  Pick<
    JSX.IntrinsicElements['img'],
    'alt' | 'src' | 'srcSet' | 'aria-hidden' | 'onLoad' | 'onError' | 'ref'
  >
> & {
  zoomFactor?: number
  elementtiming?: string
  objectFit?: ObjectFit
}

const ImageBaseComponent = styled.img.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    ['elementtiming'].includes(prop) || defaultValidatorFn(prop),
})<ImageBaseProps>(
  ({ zoomFactor, objectFit = undefined }) => css`
    display: block;
    width: inherit;
    height: inherit;
    image-rendering: ${zoomFactor && zoomFactor > 1 && 'pixelated'};
    object-fit: ${objectFit};

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;

      /* To avoid a jump when switching between loading and successful state */
      width: 0;
    }
  `
)

export const overlayStyle = css`
  position: absolute;
  margin: auto;
`

export const Icon = styled.svg.attrs({
  role: 'img',
})`
  ${overlayStyle}
  max-width: 64px;
  max-height: 64px;

  width: 100%;
  height: 100%;

  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.3;
  padding: 4px;
`

const LoadingPlaceholder = styled(BaseLoadingPlaceholder)`
  ${overlayStyle}
`

export const ImageContainer = styled.div.attrs<ImageSizeProps>(
  ({ width, height }) => ({ style: { width, height } })
)<ImageSizeProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export type ImageStatus = 'no-image' | 'loading' | 'error' | 'successful'

export interface ImageProps extends ImageSizeProps {
  alt: string
  className?: string

  customImageElement?: React.ComponentType<ImageBaseProps>
  zoomFactor?: number

  onError?: () => void
  onLoad?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void

  src?: string
  srcSet?: string

  style?: CSSProperties
  loadingPlaceholder?: React.ReactNode
  objectFit?: ObjectFit
}

const Image: React.FC<ImageProps> = props => {
  const {
    alt,
    className,
    customImageElement: ImageComponent = ImageBaseComponent,
    onError = noop,
    onLoad = noop,
    loadingPlaceholder = <LoadingPlaceholder size="24px" />,
    src,
    srcSet,
    width,
    height,
    zoomFactor,
    objectFit,
  }: typeof props = props

  const hasSource = !!(src || srcSet)
  const [status, setStatus] = useState<ImageStatus>(
    hasSource ? 'loading' : 'no-image'
  )
  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    if (src || srcSet) {
      if (imageRef.current && imageRef.current.complete) {
        setStatus('successful')
      } else {
        setStatus('loading')
      }
    } else {
      setStatus('no-image')
    }
  }, [src, srcSet])

  const handleImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    setStatus('successful')
    onLoad(event)
  }

  const handleImageError = () => {
    setStatus('error')
    onError()
  }

  let overlayIcon = null

  switch (status) {
    case 'error': {
      overlayIcon = <Icon as={WarningTriangle} aria-label="Error loading" />
      break
    }

    case 'no-image': {
      overlayIcon = <Icon as={ImageSlash} aria-label="No image" />
      break
    }

    case 'loading': {
      overlayIcon = loadingPlaceholder
      break
    }
  }

  const imageSize = status === 'successful' ? { width, height } : {}
  const imageHidden = status !== 'successful'

  return (
    <ImageContainer
      aria-busy={status === 'loading'}
      className={className}
      {...imageSize}
    >
      {overlayIcon}
      <ImageComponent
        aria-hidden={imageHidden}
        alt={alt}
        ref={imageRef}
        src={src}
        srcSet={srcSet}
        onLoad={handleImageLoad}
        onError={handleImageError}
        zoomFactor={zoomFactor}
        elementtiming="image"
        objectFit={objectFit}
      />
    </ImageContainer>
  )
}

export { Image, ImageBaseComponent }
