import React from 'react'
import {
  Wrapper,
  Icon,
  Title,
  Description,
  ActionWrapper,
} from './SidebarEmpty.styles'

interface SidebarEmptyProps {
  icon?: React.ElementType
  title: string
  description?: React.ReactNode
  action?: React.ReactNode
}

export const SidebarEmpty = (props: SidebarEmptyProps) => {
  const { icon, title, description, action } = props

  return (
    <Wrapper>
      {icon && <Icon as={icon} />}
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      {action && <ActionWrapper>{action}</ActionWrapper>}
    </Wrapper>
  )
}
