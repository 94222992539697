import React, { SyntheticEvent, useState, useEffect } from 'react'
import { useTransition, animated } from 'react-spring'

import {
  Container,
  Header,
  StyledCrossIcon,
  CloseButton,
  Content,
  Title,
} from './DismissableCard.styles'

export interface DismissableCardProps {
  title: React.ReactNode
  className?: string
  isVisible?: boolean
  onDismiss?: () => void
}

// Globals
const TRANSITION_STEPS = {
  from: {
    opacity: 0,
    // added the zIndex to fix a glitch while animating the opacity
    zIndex: 1,
  },
  enter: {
    opacity: 1,
    zIndex: 1,
  },
  leave: {
    opacity: 0,
    zIndex: 1,
  },
}

/**
 * DismissableCard
 *
 * Renders a "Card"-like container with header for the title
 * and can render any content. It also can be dismissed by presssing a "X".
 *
 * TODO: Needs a Storybook story and unit tests
 */
export const DismissableCard: React.FC<DismissableCardProps> = ({
  className,
  title,
  children,
  isVisible: isInitiallyVisible,
  onDismiss,
}) => {
  const [isVisible, setIsVisible] = useState(isInitiallyVisible)
  const transitions = useTransition(isVisible, null, TRANSITION_STEPS)

  // This component at the time of implementation
  // didn't need to keep track of its visible state
  // from the outside, only its initial visiblity state.
  // However for more flexibility we added this useEffect
  useEffect(() => {
    setIsVisible(isInitiallyVisible)
  }, [isInitiallyVisible])

  function handleDismiss(event: SyntheticEvent) {
    event?.stopPropagation()
    setIsVisible(false)
    onDismiss?.()
  }

  return (
    <>
      {transitions.map(
        ({ item, props }) =>
          item && (
            <animated.div key="dismissable-card" style={props}>
              <Container className={className}>
                <Header>
                  <Title>{title}</Title>
                  {onDismiss && (
                    <CloseButton onClick={handleDismiss}>
                      <span className="sr-only">Close banner</span>
                      <StyledCrossIcon />
                    </CloseButton>
                  )}
                </Header>
                <Content>{children}</Content>
              </Container>
            </animated.div>
          )
      )}
    </>
  )
}
