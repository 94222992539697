import React, { useMemo } from 'react'
import { Redirect } from 'react-router'
import queryString from 'query-string'

import {
  RouteProps,
  NotFoundView,
  DynamicLoadingPage,
} from '@sketch/modules-common'
import { useVersioning } from '../../versioning'
import { useGetArtboardInDocumentWithUuidQuery } from '@sketch/gql-types'
import { SegmentValues, SEGMENT_VALUES } from '../constants'

const isValidSidebarSegment = (value: string): value is SegmentValues =>
  (SEGMENT_VALUES as string[]).includes(value)

/**
 * In some places, like when navigating to an artboard in the canvas view, we only
 * have access to the artboard UUID. This component allows to navigate to the normal
 * artboard view by using the uuid instead of the artboard permanent short id.
 */
export default function ArtboardUuidRedirect(
  props: RouteProps<'ARTBOARD_DETAIL_UUID'>
) {
  const { match, location } = props
  const { artboardUUID, shareID } = match.params

  const versionContext = useVersioning()
  const { getPathname } = versionContext

  const documentId = versionContext.loading
    ? null
    : versionContext.currentVersion.document?.identifier

  const { data, loading } = useGetArtboardInDocumentWithUuidQuery({
    variables: {
      documentId: documentId || '',
      artboardUuid: artboardUUID,
    },
    skip: versionContext.loading,
  })

  const matchingArtboardPermanentShortId =
    data?.artboardInDocumentWithUuid?.permanentArtboardShortId

  const artboardLink = useMemo(() => {
    if (!matchingArtboardPermanentShortId) return ''

    const artboardRoutePathname = getPathname({
      routeKey: 'ARTBOARD_DETAIL',
      routeParams: {
        shareID: shareID,
        permanentArtboardShortId: matchingArtboardPermanentShortId,
        query: queryString.parse(location.search),
      },
    })

    const locationHash = location.hash.slice(1)

    return isValidSidebarSegment(locationHash)
      ? `${artboardRoutePathname}#${locationHash}`
      : artboardRoutePathname
  }, [
    getPathname,
    location.hash,
    location.search,
    matchingArtboardPermanentShortId,
    shareID,
  ])

  if (loading || versionContext.loading) {
    return <DynamicLoadingPage />
  }

  if (!matchingArtboardPermanentShortId) {
    return <NotFoundView />
  }

  return <Redirect to={artboardLink} push={false} />
}
