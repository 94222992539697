import React from 'react'
import { TooltipProps, Tooltip } from '../Tooltip'
import * as S from './LearnMoreTooltip.styles'

export type LearnMoreTooltipProps = {
  href?: string
  tooltipPlacement?: TooltipProps['placement']
  tooltipContent: TooltipProps['content']
}

/**
 * Simple question mark icon with tooltip (can also be a link) to use when
 * we want to help the user to understand something
 * and optionally link to the Sketch documentation.
 */
export function LearnMoreTooltip({
  href,
  tooltipPlacement = 'top',
  tooltipContent,
}: LearnMoreTooltipProps) {
  const isLink = Boolean(href)

  const iconWithTooltip = (
    <Tooltip
      disableWhenTouchDevice={false}
      tooltipContainerAs="span"
      placement={tooltipPlacement}
      content={tooltipContent}
    >
      <S.StyledHelpIcon $isLink={isLink} />
    </Tooltip>
  )

  if (isLink) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={href}>
        {iconWithTooltip}
      </a>
    )
  }
  return iconWithTooltip
}
