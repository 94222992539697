import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "mail-envelope-24", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("path", { d: "M6 5h12a3 3 0 013 3v8a3 3 0 01-3 3H6a3 3 0 01-3-3V8a3 3 0 013-3z", id: "mail-envelope-24_svg__a" })),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("mask", { id: "mail-envelope-24_svg__b", fill: "#fff" },
            React.createElement("use", { xlinkHref: "#mail-envelope-24_svg__a" })),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, d: "M18 5.75c.621 0 1.184.252 1.591.659.407.407.659.97.659 1.591h0v8c0 .621-.252 1.184-.659 1.591-.407.407-.97.659-1.591.659h0H6a2.243 2.243 0 01-1.591-.659A2.243 2.243 0 013.75 16h0V8c0-.621.252-1.184.659-1.591.407-.407.97-.659 1.591-.659h0z" }),
        React.createElement("path", { d: "M12 14l9-6v8a3 3 0 01-3 3H6a3 3 0 01-3-3V8l9 6z", fillOpacity: 0.15, fill: "currentColor", fillRule: "nonzero", mask: "url(#mail-envelope-24_svg__b)" }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, strokeLinejoin: "round", mask: "url(#mail-envelope-24_svg__b)", d: "M3 8l9 6 9-6" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
