import React, { useEffect, useRef, useState } from 'react'

import {
  OnboardingPanel,
  Button,
  AnchorButton,
  useForTablet,
} from '@sketch/components'

import { useLocalStorage } from 'react-use'
import { useFlag } from '@sketch/modules-common'
import { CSSTransition } from 'react-transition-group'
import sizzleInspect from '@sketch/icons/sizzle-inspect-16-9.gif'
import { AnimationWrapper } from './OnboardingPanelFloatingPanels.styles'

type OnboardingPanelFloatingPanelsProps = {
  ref?: React.RefObject<HTMLDivElement>
  onClose: () => void
}

const OnboardingPanelFloatingPanelsBase = ({
  ref,
  onClose,
}: OnboardingPanelFloatingPanelsProps) => {
  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger

  return (
    <OnboardingPanel
      ref={ref}
      title="A Lighter, Brighter Web Canvas"
      imageSrc={sizzleInspect}
      alt="Floating panels introduction"
    >
      <OnboardingPanel.Body>
        <p>
          We’ve replaced the sidebar with floating panels, giving you more space
          for what’s important — your designs.
        </p>
        <p>
          Click the top-right icons to open the Details, Versions, Inspect and
          Comments panels. Click again to close.
        </p>
      </OnboardingPanel.Body>
      <OnboardingPanel.Footer>
        <AnchorButton
          size={isMobile ? '32' : '24'}
          href="/redirect/web/100-blog"
        >
          Learn More
        </AnchorButton>
        <Button
          size={isMobile ? '32' : '24'}
          variant="primary"
          onClick={onClose}
        >
          Close
        </Button>
      </OnboardingPanel.Footer>
    </OnboardingPanel>
  )
}

export const OnboardingPanelFloatingPanels = () => {
  const [show, setShow] = useState(false)
  const onboardingPanelRef = useRef(null)
  const isV100 = useFlag('v100-callouts')
  const [
    floatingPanelsIntroductionSeen,
    setfloatingPanelsIntroductionSeen,
  ] = useLocalStorage('floating_panels_introduction_seen')
  const showOnboardingPanel = !floatingPanelsIntroductionSeen && isV100

  const handleCloseOnboardingPanel = () => {
    setfloatingPanelsIntroductionSeen(true)
    setShow(false)
  }

  useEffect(() => {
    setShow(showOnboardingPanel)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CSSTransition
      data-testid="onboarding-floating-panels"
      timeout={300}
      mountOnEnter
      unmountOnExit
      in={show}
      classNames="onboarding-panel"
      nodeRef={onboardingPanelRef}
    >
      <AnimationWrapper ref={onboardingPanelRef}>
        <OnboardingPanelFloatingPanelsBase
          onClose={handleCloseOnboardingPanel}
        />
      </AnimationWrapper>
    </CSSTransition>
  )
}
