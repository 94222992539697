import { isMD5 } from '@sketch/utils'

export const getAssetsVersion = async () => {
  const fileURL = '/static/assets-version.txt'
  let assetsVersion = null

  try {
    const response: Response = await fetch(fileURL, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })

    if (response.status < 300) {
      const responseText = await response.text()
      const trimmedResponse = responseText ? responseText.trim() : ''

      // Cloud can return non existing pages as 200 with a `We cannot find the page you’re looking for.` content
      assetsVersion = isMD5(trimmedResponse) ? trimmedResponse : null
    }
  } catch (error) {
    assetsVersion = null
  }

  return assetsVersion
}
