import React from 'react'

import { Button, Modal, ModalInjectedProps } from '@sketch/components'

import { noop } from '@sketch/utils'

export const SketchLoginDeprecationModal: React.FC<ModalInjectedProps> = props => {
  const { hideModal } = props

  return (
    <Modal onCancel={noop}>
      <Modal.Header>This login method is no longer supported</Modal.Header>
      <Modal.Body>
        We’ve updated our login processes to make them more secure. Please
        update your Mac app to version 53 or higher, in order to sign in with
        Cloud.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal}>Cancel</Button>
        <Button
          variant="primary"
          onClick={() => {
            window.open('https://www.sketch.com/updates/')
            hideModal()
          }}
        >
          See Mac Updates
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
