import React, { useRef } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { Header } from '../Header'
import { Wrapper, Sidebar, SidebarResizeHandle, Content } from './Layout.styles'
import { ComponentsList, GroupsManager } from '../Sidebar'
import { ExportColorTokens } from '../ExportColorTokens'
import { ExportDesignTokensSidebarButton } from '../../../modals/ExportDesignTokensModal'
import { isCwvSpecificRoute } from '@sketch/modules-common'

import { useForDesktop } from '@sketch/components'
import { useResizableSidePanel } from '@sketch/utils'

import { useGetComponentsGroups } from '../../operations'
import { getGroupType } from '../../utils'

/**
 * COMPONENT
 */
export const Layout: React.FC = ({ children }) => {
  const isDesktopAndBigger = useForDesktop()
  const { path } = useRouteMatch()
  const groupType = getGroupType(path)
  const { status, tree } = useGetComponentsGroups(groupType)

  // Resizeable sidebar logic
  const SIDEBAR_WIDTH = 215
  const MIN_SIDEBAR_WIDTH = 150
  const MAX_SIDEBAR_WIDTH = document.documentElement.clientWidth - 500 // right sidebar open + some extra space

  const sidebarDragRef = useRef() as React.MutableRefObject<HTMLDivElement>

  const width = useResizableSidePanel({
    handleRef: sidebarDragRef,
    defaultWidth: SIDEBAR_WIDTH,
    minWidth: MIN_SIDEBAR_WIDTH,
    maxWidth: MAX_SIDEBAR_WIDTH,
  })

  const showNewExport = status === 'READY'

  // Note: the sidebar is rendering in a different place for mobile view
  return (
    <Wrapper isDesktopAndBigger={isDesktopAndBigger}>
      {isDesktopAndBigger ? (
        <Sidebar style={{ width }} className="cwvSidebar">
          <ComponentsList />
          <GroupsManager isDesktop status={status} tree={tree} />
          {showNewExport ? (
            <ExportDesignTokensSidebarButton />
          ) : (
            showNewExport &&
            isCwvSpecificRoute(path, 'CWV_COLOR_VARIABLES') && (
              <ExportColorTokens />
            )
          )}
        </Sidebar>
      ) : null}
      <Content style={{ marginLeft: isDesktopAndBigger ? width : 0 }}>
        <Header />
        {children}
        <SidebarResizeHandle ref={sidebarDragRef} />
      </Content>
    </Wrapper>
  )
}
