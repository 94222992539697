import { useMemo } from 'react'
// Do not import useVersioning from modules/versioning to avoid circular dependency
import { useVersioning } from '../../../versioning/ShareVersionContext/useVersioning'
import { NavigationStackNode } from './types'

/**
 * Create a link to the view corresponding to the provided navigation stack node.
 * @return the URL to the view.
 */
export function useShareViewNavigationNodeLink(
  navigationStackNode: NavigationStackNode | undefined
) {
  const { share, getPathname } = useVersioning()

  return useMemo(() => {
    if (!navigationStackNode) {
      return getPathname({
        routeKey: 'SHARE_VIEW',
        routeParams: { shareID: share.identifier },
      })
    }

    if (navigationStackNode.view === 'canvas') {
      return getPathname({
        routeKey: 'SHARE_PAGE_CANVAS_VIEW',
        routeParams: {
          shareID: share.identifier,
          pageUUID: navigationStackNode.pageUUID,
        },
      })
    }

    if (navigationStackNode.view === 'prototype') {
      return getPathname({
        routeKey: 'PROTOTYPE_PLAYER',
        routeParams: {
          shareID: share.identifier,
          prototypeArtboardUUID: navigationStackNode.prototypeArtboardUUID,
          currentArtboardUUID: navigationStackNode.currentArtboardUUID,
        },
      })
    }

    if (navigationStackNode.view === 'shareprototypes') {
      return getPathname({
        routeKey: 'SHARE_PROTOTYPES',
        routeParams: {
          shareID: share.identifier,
        },
      })
    }

    if (navigationStackNode.view === 'page') {
      return getPathname({
        routeKey: 'SHARE_PAGE_VIEW',
        routeParams: {
          shareID: share.identifier,
          pageUUID: navigationStackNode.pageUUID,
        },
      })
    }

    if (navigationStackNode.view === 'symbols') {
      return getPathname({
        routeKey: 'CWV_SYMBOLS',
        routeParams: {
          shareID: share.identifier,
        },
      })
    }

    return getPathname({
      routeKey: 'SHARE_VIEW',
      routeParams: { shareID: share.identifier },
    })
  }, [getPathname, navigationStackNode, share.identifier])
}
