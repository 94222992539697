import styled, { css } from 'styled-components'
import { ReactComponent as BaseLinkIcon } from '@sketch/icons/link-12'
import {
  Flex,
  truncateStyles,
  Input as BaseInput,
  CopyToClipboard,
} from '@sketch/components'

interface InvitePeopleInputWrapperProps {
  disabled: boolean
}

const InvitePeopleInputWrapper = styled(Flex)<InvitePeopleInputWrapperProps>(
  ({ theme, disabled }) => css`
    flex: 1;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    background-color: ${theme.colors.background.secondary.B};
    border: 1px ${disabled ? 'dashed' : 'solid'} ${theme.colors.border.A};
    padding-left: 12px;
  `
)

const LinkInviteInput = styled(BaseInput)`
  height: 38px;
  margin-right: 4px;

  &[type='text'] {
    font-size: ${({ theme }) => theme.fontSizes.D};
    border: none;
    padding-right: 0;
    ${truncateStyles};

    :disabled {
      background-color: ${({ theme }) => theme.colors.background.secondary.B};
    }
  }
`

const CopyButton = styled(CopyToClipboard)`
  width: 92px;
`

const AccessLevelSelectInvite = styled.div`
  margin-left: auto;
`

const iconStyles = css`
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
`

const LinkIcon = styled(BaseLinkIcon)`
  ${iconStyles}
`

const PublicPermissionsAvatar = styled.div<{ noAccess?: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: ${({ theme }) => theme.radii.medium};
  background-color: ${({ theme }) => theme.colors.background.tertiary.B};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme, noAccess }) =>
      noAccess
        ? theme.colors.state.disabled
        : theme.colors.foreground.secondary.B};
  }
`

export {
  AccessLevelSelectInvite,
  CopyButton,
  InvitePeopleInputWrapper,
  LinkIcon,
  LinkInviteInput,
  PublicPermissionsAvatar,
}
