import { useDeleteAnnotationReplyMutation } from '@sketch/gql-types'

import { removeFromPaginated } from '@sketch/modules-common'

interface useDeleteAnnotationCommentProps {
  identifier: string
  onCompleted?: () => void
  skipCache?: boolean
}

const useDeleteAnnotationReply = ({
  identifier,
  onCompleted,
}: useDeleteAnnotationCommentProps) => {
  const [mutate, { loading }] = useDeleteAnnotationReplyMutation({
    variables: { identifier },
    onError: 'show-toast',
    onCompleted: () => {
      onCompleted && onCompleted()
    },
    update: (cache, { data }) => {
      if (!data) {
        return
      }
      removeFromPaginated(
        cache,
        { __typename: 'Comment', identifier },
        key => true
      )
    },
  })
  return [mutate, { loading }] as const
}

export default useDeleteAnnotationReply
