import { DataProxy } from 'apollo-cache'

import { errorPreventiveCacheRead } from '@sketch/modules-common'

import { GetInitialUserDocument, GetInitialUserQuery } from '@sketch/gql-types'

export const createInitialUserQuery = (cache: DataProxy) => {
  const user = errorPreventiveCacheRead<GetInitialUserQuery>(cache, {
    query: GetInitialUserDocument,
  })

  if (!user) {
    // This is a typescript validation that shouldn't happen
    // because to send a comment you need a session so
    // let's just throw an error
    throw new Error('Error: no user session found')
  }

  return user
}
