import React from 'react'

import { Children, renderConditionally } from '@sketch/utils'
import { useUserProfile } from '@sketch/modules-common'

interface HasPersonalIdentityProps {
  children: Children
}

const useHasPersonalIdentity = () => {
  const { data } = useUserProfile()
  return data?.me?.hasPersonalIdentity ?? false
}

export const HasNoPersonalIdentity: React.FC<HasPersonalIdentityProps> = ({
  children,
}) => <>{renderConditionally(children, !useHasPersonalIdentity())}</>

export const HasPersonalIdentity: React.FC<HasPersonalIdentityProps> = ({
  children,
}) => <>{renderConditionally(children, useHasPersonalIdentity())}</>
