import { FC } from 'react'
import { useOnEvent } from '@sketch/utils'
import { ShareInfoFragment } from '@sketch/gql-types'

interface LatestShareVersionSubscribeProps {
  onVersionUpdate: (share: ShareInfoFragment) => void
  shareID: string
}

export const useLatestShareVersionSubscribe = ({
  onVersionUpdate,
  shareID,
}: LatestShareVersionSubscribeProps) => {
  useOnEvent('versionIsProcessed', share => {
    if (share.identifier !== shareID) {
      return
    }
    onVersionUpdate(share)
  })
}

const LatestShareVersionSubscribe: FC<LatestShareVersionSubscribeProps> = props => {
  useLatestShareVersionSubscribe(props)
  return null
}

export default LatestShareVersionSubscribe
