import styled from 'styled-components'
import { ButtonUnstyled } from '@sketch/components'

export const Wrapper = styled.span`
  display: block;
  line-height: 0;

  [role='img'] {
    width: 16px;
    height: 16px;
  }
`

export const Button = styled(ButtonUnstyled)`
  display: block;
  line-height: 1;
  color: currentColor;
`
