import React from 'react'

import { Button, Modal, ModalInjectedProps } from '@sketch/components'
import {
  ProjectInSidebarAndHeaderFragment,
  ProjectListItemFragment,
  useArchiveProjectMutation,
} from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'
import { removeFromPaginated, removeQuery } from '@sketch/modules-common'
import { useEventDispatch } from '@sketch/utils'

interface ArchiveProjectModalProps extends ModalInjectedProps {
  project: ProjectInSidebarAndHeaderFragment | ProjectListItemFragment
  workspaceIdentifier: string
}

const ArchiveProjectModal = (props: ArchiveProjectModalProps) => {
  const { hideModal, project, workspaceIdentifier } = props
  const { showToast } = useToast()

  const { identifier: projectIdentifier, name } = project

  const dispatchSharesRefresh = useEventDispatch('workspaceShareRefresh')

  const [archiveProject, { loading }] = useArchiveProjectMutation({
    variables: { projectIdentifier },
    onError: 'show-toast',
    onCompleted: ({ archiveProject }) => {
      showToast(`“${archiveProject.project.name}” Archived`)
      hideModal()
    },
    update: cache => {
      // Remove the project from the sidebar Query
      removeFromPaginated(
        cache,
        { __typename: 'Project', identifier: projectIdentifier },
        key => key.includes('projects')
      )

      // Remove from cache every query related with this workspace shares
      // This way the view will be refetched from scratch if needed to make sure
      // we always get the correct server information
      //
      // "key.includes('"archived":"ARCHIVED"')"
      // We also remove the archived listing to make sure it's refreshed
      removeQuery(
        cache,
        key =>
          key.includes(workspaceIdentifier) &&
          (key.includes('.shares') || key.includes('"archived":"ARCHIVED"'))
      )

      // Force any visible query to refresh
      dispatchSharesRefresh({
        workspaceIdentifier,
        projectIdentifier,
      })
    },
  })

  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>Archive Project?</Modal.Header>
      <Modal.Body>
        The <strong>“{name}”</strong> project and all its documents will be sent
        to the Archive. You’ll still be able to view and edit archived documents
        as usual.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal}>Cancel</Button>
        <Button
          variant="primary"
          onClick={() => archiveProject()}
          loading={loading}
        >
          Archive Project
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ArchiveProjectModal
