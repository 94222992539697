import styled, { CSSProperties, css } from 'styled-components'

import { LayerOverlay } from '../ArtboardDetailInspectorOverlay.styles'
import { ReactComponent as SymbolIconSvg } from '@sketch/icons/arrow-circle-right-16'

export const StyledLayerOverlay = styled(LayerOverlay)`
  background: ${({ theme }) => theme.colors.inspect.symbol.B};
`

/* Show symbol name on top, truncated if needed */
export const SymbolNameContainer = styled.div<{
  isLabelBiggerThanSymbol: boolean
}>(
  ({ theme, isLabelBiggerThanSymbol }) => css`
    display: flex;
    align-items: flex-end;

    height: 20px;

    position: absolute;
    top: -22px;
    left: -2px;
    z-index: 1; /* Symbol name will be placed below measurements and grid but should be clickable (if applies) */

    line-height: 1.2;
    font-size: 0.75rem;
    font-weight: ${theme.fontWeights.medium};

    /* Create rounded bottom right border to make it nicer when the label name fits the symbol width */
    ${!isLabelBiggerThanSymbol &&
    css`
      ::after {
        content: '';
        width: 4px;
        height: 4px;
        border-bottom-left-radius: 4px;
        box-shadow: -1px 1px 0px 1px ${theme.colors.inspect.symbol.A};
      }
    `}
  `
)

type SymbolLabelProps = {
  $width?: number
}
export const SymbolLabel = styled.div.attrs(({ $width }: SymbolLabelProps) => {
  const style: CSSProperties = {
    maxWidth: $width ? `${$width}px` : '100%',
  }
  return { style }
})<SymbolLabelProps>(
  ({ theme }) => css`
    display: inline-flex;
    align-items: center;
    padding: 0 4px;
    background: ${theme.colors.inspect.symbol.A};
    border-radius: 4px 4px 0 0;
    height: 17px;

    /* This needs to be white for any theme because the background is always purple */
    color: white;
  `
)

export const GoToSymbolIcon = styled(SymbolIconSvg)`
  width: 17px;
  height: 17px;
  flex: 0 0 17px;
  margin: 0 -2px 0 2px; /* stylelint-disable-line scales/space */
`
