import React from 'react'

import { routes } from '@sketch/modules-common'
import { Link } from '@sketch/components'

import { Banner } from './PublicViewHiddenProfileBanner.styles'

interface PublicViewHiddenProfileBannerProps {
  workspaceId: string
}

const PublicViewHiddenProfileBanner = (
  props: PublicViewHiddenProfileBannerProps
) => {
  const { workspaceId } = props
  return (
    <Banner type="neutral" size="tiny" rounded={false}>
      Your public profile is currently hidden — only you and other Admins can
      see it. To publish, head over to{' '}
      <Link
        variant="secondary"
        to={{
          pathname: routes.WORKSPACE_SETTINGS_PROFILE.create({ workspaceId }),
        }}
      >
        Profile Settings
      </Link>
      .
    </Banner>
  )
}

export default PublicViewHiddenProfileBanner
