import styled from 'styled-components'
import { Text } from '@sketch/components'
import { ReactComponent as CrossIconUnstyled } from '@sketch/icons/cross-circle-18'

export const Pill = styled(Text.Div).attrs({
  textStyle: 'copy.primary.standard.D',
})`
  height: 30px;

  display: flex;
  align-items: center;
  padding: 0 12px;

  text-transform: uppercase;

  border-radius: ${({ theme }) => theme.radii.medium};
  border: 1px solid ${({ theme }) => theme.colors.border.B};

  :not(:last-child) {
    margin-right: 8px;
  }
`

export const CrossIcon = styled(CrossIconUnstyled)`
  width: 18px;
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  cursor: pointer;
`
