import React, { FC } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom'
import { Location } from 'history'

import DocumentView from '../DocumentView'
import PageCanvasView from '../PageCanvasView'
import {
  extractVersionInformation,
  ShareVersionProvider,
  useVersioning,
} from '../../versioning'
import {
  getAllTemplatesForVersionedRoute,
  routes,
  RouteParams,
  NotFoundView,
  RouteProps,
} from '@sketch/modules-common'
import { ShareAuthorizerQueryData } from './useShareAuthorizerQuery'
import ShareSidebarTabContext from '../components/ShareSidebarTabContext'
import {
  DocumentSidebarLayoutRoutes,
  documentSidebarLayoutRoutesRoutePaths,
} from './DocumentSidebarLayoutRoutes'
import { DocumentSidebarLayout } from '../components/DocumentSidebarLayout'

import { useToast } from '@sketch/toasts'

import AnnotationRedirect from './AnnotationRedirect'
import ArtboardUuidRedirect from './ArtboardUuidRedirect'
import { getToast } from '../../projects/toasts'
import { useOnEvent, ContainerRefScrollProvider } from '@sketch/utils'
import { parse } from 'query-string'
import { useHandleSettingsQueryParamIfNeeded } from '../DocumentView/DocumentView.hooks'
import { AnnotationSubscriptions } from '../../annotations/containers'
import { PrototypeView } from '../../prototype'
import { useUnfollowShareFromEmail } from '../operations'
import { PrototypeLegacyRoute1Redirect } from './PrototypeLegacyRoute1Redirect'
import { PrototypeLegacyRoute2Redirect } from './PrototypeLegacyRoute2Redirect'
import { ComponentsStateProvider } from '../components/ComponentsStateContext'
import { DocumentHead } from '@sketch/components'

interface ShareMainRoutesProps
  extends Pick<ShareAuthorizerQueryData, 'share' | 'refetch'> {
  location: Location<any>
}

const ConnectedAnnotationSubscriptions = () => {
  const { versionIdentifier, share } = useVersioning()

  return (
    <AnnotationSubscriptions
      shareIdentifier={share.identifier}
      versionIdentifier={versionIdentifier || ''}
    />
  )
}

const UnsubscribeShareNotifications = () => {
  const { versionShortId, share } = useVersioning()

  // Run the unsubscribe hook
  useUnfollowShareFromEmail({
    versionShortId,
    shareIdentifier: share.identifier,
    shareName: share.name,
    subscriptionStatus: share.subscriptionStatus,
  })

  // Remove the "/unsubscribe from the URL"
  return (
    <Redirect to={routes.SHARE_VIEW.create({ shareID: share.identifier })} />
  )
}

const ShareMainRoutes: FC<ShareMainRoutesProps> = props => {
  const { share, refetch, location } = props

  const history = useHistory()
  const { showToast } = useToast()

  const { shareID } = useParams<RouteParams<'SHARE_VIEW'>>()
  const { version, ...shareBase } = share

  const { versionShortId } = extractVersionInformation(location.pathname)
  const latestVersion = version?.shortId!

  // Handle potential deep-link to settings modal
  useHandleSettingsQueryParamIfNeeded(share, {
    current: { shortId: versionShortId || latestVersion },
    latest: { shortId: latestVersion },
  })

  useOnEvent('projectAccessRevoked', projectData => {
    if (!share.project) {
      return
    }

    if (share.project.identifier === projectData.projectIdentifier) {
      history.push(
        routes.WORKSPACE_SHARES.create({
          workspaceId: projectData.workspaceIdentifier,
        })
      )

      showToast(
        getToast('PROJECT_ACCESS_REVOKED', projectData.projectName),
        'default'
      )
    }
  })

  return (
    <ShareSidebarTabContext>
      <ShareVersionProvider
        latestVersion={version!}
        share={shareBase}
        onVersionUpdateNeeded={async () => {
          await refetch()
        }}
      >
        <ConnectedAnnotationSubscriptions />
        <ContainerRefScrollProvider>
          <ComponentsStateProvider>
            <Switch>
              {/* Legacy prototype route 1 redirect */}
              <Route
                exact
                path={getAllTemplatesForVersionedRoute(
                  'PROTOTYPE_LEGACY_ROUTE_1'
                )}
                component={PrototypeLegacyRoute1Redirect}
              />
              {/* Legacy prototype route 2 redirect */}
              <Route
                exact
                path={getAllTemplatesForVersionedRoute(
                  'PROTOTYPE_LEGACY_ROUTE_2'
                )}
                component={PrototypeLegacyRoute2Redirect}
              />
              {/* Prototype player using web renderer */}
              <Route
                exact
                path={getAllTemplatesForVersionedRoute('PROTOTYPE_PLAYER')}
                component={PrototypeView}
              />
              {/* Artboard detail view based on UUID (redirect to ARTBOARD_DETAIL) */}
              <Route
                exact
                path={[
                  ...getAllTemplatesForVersionedRoute('ARTBOARD_DETAIL_UUID'),
                ]}
                component={ArtboardUuidRedirect}
              />

              {/* Redirect to the appropriate ARTBOARD_DETAIL or SHARE_PAGE_CANVAS_VIEW for the given annotation */}
              <Route
                exact
                path={routes.ANNOTATION_REDIRECT.template()}
                component={AnnotationRedirect}
              />

              {/*
                - Share view (document view)
                - Share view when searching
                - Unsuscribe share notifications
               */}
              <Route
                exact
                path={[
                  ...getAllTemplatesForVersionedRoute('SHARE_VIEW'),
                  routes.SHARE_UNSUBSCRIBE.template(),
                ]}
                render={routeProps => {
                  const { location } = routeProps
                  const { search } = parse(location.search)

                  /**
                   * If there's a search parameter
                   * we should render to the document view since it needs
                   * the grid view.
                   *
                   * Otherwise we show the canvas view
                   */
                  const layout = (
                    <DocumentSidebarLayout
                      workspace={shareBase.workspace}
                      render={layoutProps =>
                        search ? (
                          <>
                            <DocumentHead title={`${share.name} results`} />
                            <DocumentView {...layoutProps} />
                          </>
                        ) : (
                          <PageCanvasView
                            {...(routeProps as RouteProps<'SHARE_VIEW'>)}
                            {...layoutProps}
                          />
                        )
                      }
                    />
                  )

                  /**
                   * This route is mounted when the share_view url includes "/unsubscribe".
                   * Having this implemented as a sibling of the view makes the share component
                   * mount correctly and do the pending queries in order to show the view while
                   * at the same time unsubscribing it.
                   *
                   * We could have used a hook inside the PageCanvasView to perform this operation, but
                   * this would make the unsubscribe logic tight to the view and not the router. Also breaking
                   * this functionality if we ever change components in the future
                   */
                  const unsubscribe = (
                    <Route
                      path={routes.SHARE_UNSUBSCRIBE.template()}
                      component={UnsubscribeShareNotifications}
                      exact
                    />
                  )

                  return (
                    <>
                      {layout}
                      {unsubscribe}
                    </>
                  )
                }}
              />

              {/*
                - Artboard view
                - Page view (Canvas)
                - Page view (Legacy)
                - Prototype view
                - CWV view (symbol, text styles, layer styles or color variables)
              */}
              <Route
                exact
                path={documentSidebarLayoutRoutesRoutePaths}
                render={() => (
                  <DocumentSidebarLayoutRoutes
                    workspace={shareBase.workspace}
                  />
                )}
              />

              {/*
                Catch the rest of the urls, redirect unknown routes to the root
                of the share. Have in mind that urls with 3 params like
                `s/:shareID/whatever` will be taken by LEGACY_ARTBOARD_DETAIL, and
                urls with 4 params will be taken by LEGACY_ARTBOARD_SLUG
              */}
              <Route path={routes.SHARE_VIEW.template()}>
                <Redirect to={routes.SHARE_VIEW.create({ shareID })} />
              </Route>
              {/* We should never reach this point, because of the previous redirection */}
              <Route component={NotFoundView} />
            </Switch>
          </ComponentsStateProvider>
        </ContainerRefScrollProvider>
      </ShareVersionProvider>
    </ShareSidebarTabContext>
  )
}

export default ShareMainRoutes
