import get from 'lodash.get'
import set from 'lodash.set'
import { ApolloQueryResult, OperationVariables } from 'apollo-client'
import { ErrorHandler } from '@sketch/tracing'
import { excludeError, castError } from '@sketch/utils'
import { FetchMoreFunc, FetchMoreConfig } from './InfiniteList'

export function handleFetchMore<TData, TVariables extends OperationVariables>(
  fetchMore: FetchMoreFunc | undefined,
  entriesPath: string[],
  {
    after = null,
    fetchLatest,
    variables = {},
    dataIdFromObject,
    preserveAfter = false,
    afterPath,
  }: FetchMoreConfig<TData, TVariables>
) {
  return async (): Promise<ApolloQueryResult<TData> | void | null> => {
    if (!fetchLatest && !after) return Promise.resolve()

    try {
      return await fetchMore?.({
        variables: {
          after: fetchLatest ? null : after,
          ...variables,
        },
        updateQuery: (previousResult, { fetchMoreResult }: any) => {
          const newEntries = get(fetchMoreResult, entriesPath, [])
          const newEntriesIds = newEntries.map(dataIdFromObject)

          const oldEntries = get(previousResult, entriesPath, []).filter(
            (x: any) => !newEntriesIds.includes(dataIdFromObject(x))
          )

          const merged = fetchLatest
            ? [...newEntries, ...oldEntries]
            : [...oldEntries, ...newEntries]

          if (preserveAfter) {
            fetchMoreResult = set(
              fetchMoreResult,
              afterPath ?? [],
              get(previousResult, afterPath ?? [])
            )
          }

          return set(fetchMoreResult, entriesPath, merged)
        },
      })
    } catch (e) {
      const err = castError(e)
      if (excludeError(err)) {
        ErrorHandler.ignore(
          err,
          'Workaround for an issue where apollo attempts to access a query which has already unmounted.'
        )
        return null
      }
      throw err
    }
  }
}
