interface NavigationStackNodeBase {
  /** The 'view' field act as a unique id for the view */
  view: string
  /** The level is not unique, two views can have the same level */
  level: number
}

/**
 * Define the level of all the views managed by the ShareViewsNavigation.
 * The same level can be re-used by multiple views.
 */
export enum ViewLevels {
  document = 1,
  page = 2,
  symbols = 2,
  shareprototypes = 2,
  canvas = 3,
  artboard = 4,
  prototype = 3,
}

interface DocumentNavigationStackNode extends NavigationStackNodeBase {
  view: 'document'
  level: ViewLevels.document
}

interface CanvasFallbackNavigationStackNode extends NavigationStackNodeBase {
  view: 'document'
  level: ViewLevels.document
}

interface PageNavigationStackNode extends NavigationStackNodeBase {
  view: 'page'
  level: ViewLevels.page
  pageUUID: string
}

interface SymbolsNavigationStackNode extends NavigationStackNodeBase {
  view: 'symbols'
  level: ViewLevels.symbols
  groupId: string
}

interface CanvasNavigationStackNode extends NavigationStackNodeBase {
  view: 'canvas'
  level: ViewLevels.canvas
  pageUUID: string
}

interface ArtboardNavigationStackNode extends NavigationStackNodeBase {
  view: 'artboard'
  level: ViewLevels.artboard
  permanentArtboardShortId: string
}

interface PrototypeNavigationStackNode extends NavigationStackNodeBase {
  view: 'prototype'
  level: ViewLevels.prototype
  shareID: string
  prototypeArtboardUUID: string
  currentArtboardUUID: string
}

interface SharePrototypeNavigationStackNode extends NavigationStackNodeBase {
  view: 'shareprototypes'
  level: ViewLevels.shareprototypes
  shareID: string
}

export type NavigationStackNode =
  | DocumentNavigationStackNode
  | PageNavigationStackNode
  | SymbolsNavigationStackNode
  | CanvasNavigationStackNode
  | ArtboardNavigationStackNode
  | CanvasFallbackNavigationStackNode
  | PrototypeNavigationStackNode
  | SharePrototypeNavigationStackNode

export type NavigationStack = NavigationStackNode[]

export interface ShareViewsNavigationLocationState {
  shareViewsNavigation: {
    navigationStack: NavigationStack
  }
}

export interface ShareViewsNavigationContext {
  navigationStack: NavigationStack
  currentView: NavigationStackNode
  previousView: NavigationStackNode | undefined
}
