import React from 'react'
import styled from 'styled-components'
import type { ZXCVBNResult } from 'zxcvbn'

import { Flex, Text } from '../Box'

const scoreToColor: Record<ZXCVBNResult['score'], string> = {
  0: 'state.negative.A',
  1: 'state.negative.A',
  2: 'sketch.A',
  3: '#007BB3',
  4: 'state.positive.A',
}

const scoreToLabel: Record<ZXCVBNResult['score'], string> = {
  0: 'Weak',
  1: 'Weak',
  2: 'Okay',
  3: 'Good',
  4: 'Great!',
}

interface StrengthBarProps {
  score: ZXCVBNResult['score']
  evaluatePassword: boolean
}

const StrengthBar = styled.div<StrengthBarProps>`
  flex: 1;
  height: 4px;
  margin-right: 2px; /* stylelint-disable-line scales/space */
  background-color: ${({ theme }) => theme.colors.border.A};

  &:last-child {
    margin-right: 0;
  }

  ${({ score, evaluatePassword, theme }) => {
    if (evaluatePassword) {
      switch (score) {
        case 0:
        case 1:
          return `
          &:nth-child(1) {
            background-color: ${theme.colors.state.negative.A};
          }
        `
        case 2:
          return `
          &:nth-child(1), &:nth-child(2) {
            background-color: ${theme.colors.sketch.A};
          }
        `
        case 3:
          return `
          &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            background-color: #007BB3;
          }
        `
        case 4:
          return `
        background-color: ${theme.colors.state.positive.A};
        `
      }
    }
  }}
`

const formatSuggestionsMessage = ({
  suggestions,
  warning,
}: ZXCVBNResult['feedback']) => {
  const messages = [warning, ...suggestions]
  const finalMessage = messages
    // Remove empty strings (warning can be empty)
    .filter(Boolean)
    // Remove the dot in the end if it has to avoid duplicate dots after join
    .map(message =>
      message[message.length - 1] === '.' ? message.slice(0, -1) : message
    )
    // Putting all messages together with a dot and a space between them
    .join('. ')

  // returns with a dot in the end if there's at least one message
  return messages.length ? `${finalMessage}.` : null
}

interface PasswordStrengthMeterProps {
  password: string
  strength: ZXCVBNResult
}

export const PasswordStrengthMeter: React.FC<PasswordStrengthMeterProps> = ({
  password,
  strength: { score, feedback },
}: PasswordStrengthMeterProps) => {
  // Only show visual feedback if there's at least one character
  const evaluatePassword = password.length > 0
  const attendsToLengthCriteria = password.length >= 8
  const feedbackMessage = formatSuggestionsMessage(feedback)

  return (
    <>
      <Flex mt={5} mb={2}>
        <Text.Span textStyle="copy.quaternary.standard.C" mr={1}>
          Password Strength:{' '}
        </Text.Span>
        {evaluatePassword && (
          <Text.Span
            textStyle="copy.quaternary.standard.C"
            color={scoreToColor[score]}
          >
            {scoreToLabel[score]}
          </Text.Span>
        )}
      </Flex>
      <Flex width="100%">
        <StrengthBar score={score} evaluatePassword={evaluatePassword} />
        <StrengthBar score={score} evaluatePassword={evaluatePassword} />
        <StrengthBar score={score} evaluatePassword={evaluatePassword} />
        <StrengthBar score={score} evaluatePassword={evaluatePassword} />
      </Flex>
      <Flex mt={2}>
        {/*
          Only show the feedback message if:
          - The score is Weak (0 or 1)
          - We have feedback to show
          - The password attends to the requirement of 8 characters
         */}
        {score <= 1 && feedbackMessage && attendsToLengthCriteria && (
          <Text
            textStyle="copy.quaternary.standard.C"
            color="state.negative.A"
            data-testid="password-feedback"
          >
            {formatSuggestionsMessage(feedback)}
          </Text>
        )}
        {!attendsToLengthCriteria && (
          <Text
            textStyle="copy.quaternary.standard.C"
            color="foreground.secondary.D"
            data-testid="password-instructions"
          >
            Secure your account with a unique password that doesn&apos;t include
            names, dates and/or repeating characters.
          </Text>
        )}
      </Flex>
    </>
  )
}
