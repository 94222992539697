import styled, { css } from 'styled-components'

import { Checkbox, Navbar, Skeleton } from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'

export const Thumbnail = styled.img`
  max-height: 32px;
  max-width: 32px;
  margin-right: 12px;
  margin-top: -4px;
`

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-radius: ${({ theme }) => theme.radii.large};

  background-color: ${({ theme }) => theme.colors.background.secondary.A};

  :nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.background.secondary.B};
  }
`
export const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 100%;
`

export const SearchInput = styled(Navbar.FilterOld)`
  ${({ theme }) => css`
    background-color: ${theme.colors.background.secondary.B};

    // Override breakpoint from Navbar.Filter
    ${breakpoint('xs')`
      max-width: 100%;
    `};
  `}
`

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  height: 20vh;
  overflow-y: auto;
`

export const Sticky = styled.div<{ hasWideOption?: boolean }>`
  position: sticky;
  top: 0;
  z-index: 1;
  padding-bottom: 16px;
  background-color: ${({ theme }) => theme.colors.background.secondary.A};

  ${({ hasWideOption }) =>
    hasWideOption &&
    breakpoint('sm')`
        padding-top: 32px;
    `}
`

export const WidePanel = styled(Panel)`
  ${breakpoint('sm')`
    height: 30vh;
  `};
`

export const SkeletonItem = styled(Skeleton)`
  min-height: 56px;

  :nth-child(odd) {
    background-image: none;
    background-color: white;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  display: flex;
  align-items: center;
  width: 100%;
`

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-height: 300px;
  height: 20vh;
  width: 100%;
`
