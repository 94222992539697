import React from 'react'

import { LoadingPlaceholder, useResponsiveDropdown } from '@sketch/components'
import { ReactComponent as ImagePlaceholder } from '@sketch/icons/document-64'

import {
  DropdownButton,
  DropdownButtonEllipsis,
  DropdownWrapper,
  ImageElement,
} from '../DocumentItem/DocumentItem.styles'
import {
  Thumbnail,
  ThumbnailWrapper,
  StyledStatusIcon,
} from './ProjectItem.styles'

type ThumbnailItem = { name: string; imageSource?: string }
export interface ProjectItemProps {
  className?: string
  name: string
  deletedAt?: string
  archivedAt?: string
  documentsNumber?: number
  totalCollections?: number
  thumbnails?: ThumbnailItem[]
  onClick?: React.MouseEventHandler<HTMLElement>
  renderDropdownItems?: () => React.ReactNode
}

const ActionPopoverWrapper: React.FC = ({ children }) => <div>{children}</div>

interface ResponsiveDropdownProps {
  deleteAt?: string
}

const ResponsiveDropdown: React.FC<ResponsiveDropdownProps> = ({
  children,
  deleteAt,
}) => {
  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: ActionPopoverWrapper,
    dropdownProps: {
      children,
    },
    placement: 'bottom-end',
    usePortal: true,
  })

  const ariaLabel = deleteAt ? 'Deleted Project Settings' : 'Project Settings'

  return (
    <DropdownWrapper onClick={event => event.stopPropagation()}>
      <DropdownButton data-testid="project-options" {...buttonProps}>
        <DropdownButtonEllipsis aria-label={ariaLabel} />
        <span className="sr-only">{ariaLabel}</span>
      </DropdownButton>
      {content}
    </DropdownWrapper>
  )
}

export const renderDropdown = (
  children: React.ReactNode,
  deleteAt?: string
) => <ResponsiveDropdown deleteAt={deleteAt}>{children}</ResponsiveDropdown>

export const getButtonProps = (
  handleOnClick: (event: React.MouseEvent<HTMLElement>) => void
) => ({
  role: 'button',
  tabIndex: 0,
  onClick: handleOnClick,
  onKeyPress: (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      /* Converge for the same type of event */
      handleOnClick((event as unknown) as React.MouseEvent<HTMLElement>)
    }
  },
})

export const renderPreview = (
  props: ProjectItemProps,
  layout: 'grid' | 'list'
) => {
  const { name, thumbnails } = props

  if (!thumbnails || thumbnails?.length === 0) {
    return (
      <StyledStatusIcon as={ImagePlaceholder} aria-label="No Image available" />
    )
  } else {
    const ariaLabel = props.deletedAt
      ? `Deleted project ${name}`
      : `Project ${name}`

    return (
      <ThumbnailWrapper aria-label={ariaLabel}>
        {thumbnails?.map(({ name, imageSource }, index) => (
          <Thumbnail
            key={index}
            alt={name}
            customImageElement={ImageElement}
            src={imageSource}
            loadingPlaceholder={
              layout === 'grid' ? (
                <LoadingPlaceholder size="64px" />
              ) : (
                <LoadingPlaceholder size="16px" />
              )
            }
          />
        ))}
      </ThumbnailWrapper>
    )
  }
}
