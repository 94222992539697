import React from 'react'

import { useLocation } from 'react-router-dom'
import { Location } from 'history'

import {
  routes,
  IndexLayoutExtraProps,
  SignUpForm,
  IndexLayoutHeaderLink,
  IndexLayoutOldTitle,
  IndexLayoutOldSubtitle,
} from '@sketch/modules-common'
import { Link } from '@sketch/components'
import { DataWithoutUserErrors, SignUpMutation } from '@sketch/gql-types'

import { CampaignSidePanel, TrialMessage } from '../../../components'

import { useSignUpFormContextForAnalytics } from './useSignUpFormContextForAnalytics'
import { Container, LeftColumn, RightColumn } from './CampaignSignUpView.styles'
import { Line } from './SignUpView.styles'

export interface BaseSignUpViewProps
  extends Pick<
    IndexLayoutExtraProps,
    'useOverrideLayoutProps' | 'HeaderPortal'
  > {
  email: string | undefined
  invitedUser: boolean
  onCompleted: (data: DataWithoutUserErrors<SignUpMutation>) => void
  callToActionText?: string
}

const CampaignSignUpView: React.FC<BaseSignUpViewProps> = ({
  email,
  invitedUser,
  onCompleted,
  HeaderPortal,
}) => {
  const { state, search } = useLocation<{ from: Location }>()
  const formContextForAnalytics = useSignUpFormContextForAnalytics()

  return (
    <>
      <HeaderPortal>
        <IndexLayoutHeaderLink />
      </HeaderPortal>
      <Container>
        <LeftColumn>
          <CampaignSidePanel />
        </LeftColumn>

        <RightColumn>
          <IndexLayoutOldTitle>Sign up</IndexLayoutOldTitle>
          <IndexLayoutOldSubtitle>
            Already have a Sketch account?{' '}
            <Link
              variant="primary"
              to={{
                pathname: routes.SIGN_IN.create({}),
                state,
                search,
              }}
              isUnderlined
            >
              Sign in
            </Link>
          </IndexLayoutOldSubtitle>
          <Line />
          <br />

          <SignUpForm
            email={email}
            invitedUser={invitedUser}
            onCompleted={onCompleted}
            callToAction="Next: Choose a Plan"
            formContextForAnalytics={formContextForAnalytics}
          />

          <TrialMessage />
        </RightColumn>
      </Container>
    </>
  )
}

export default CampaignSignUpView
