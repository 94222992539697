import React from 'react'

import { PublicationItemFragment } from '@sketch/gql-types'
import { Wrapper, ListPublicationItem } from './PublicationList.styles'
import { routes, useTrackEventInView } from '@sketch/modules-common'

interface PublicationListProps {
  analyticsId: string
  className?: string
  items: PublicationItemFragment[]
}

const PublicationList = (props: PublicationListProps) => {
  const { className, items, analyticsId } = props

  // Analytics
  const { ref } = useTrackEventInView(`COMMUNITY - List`, {
    type: 'load',
    target: analyticsId,
  })

  return (
    <Wrapper data-testid="publication-list" ref={ref} className={className}>
      {items.map(publication => (
        <ListPublicationItem
          key={publication.identifier}
          publication={publication}
          url={routes.WORKSPACE_PROFILE_DOCUMENT.create({
            publicationId: publication.identifier,
            shortUrlName: publication.workspaceProfile!.shortUrlName,
          })}
        />
      ))}
    </Wrapper>
  )
}

export default PublicationList
