import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "control-color-well-16", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("path", { d: "M6.742 1h2.516c1.974 0 2.707.209 3.44.601a4.089 4.089 0 011.701 1.701c.392.733.601 1.466.601 3.44v2.516c0 1.974-.209 2.707-.601 3.44a4.089 4.089 0 01-1.701 1.701c-.733.392-1.466.601-3.44.601H6.742c-1.974 0-2.707-.209-3.44-.601a4.089 4.089 0 01-1.701-1.701C1.209 11.965 1 11.232 1 9.258V6.742c0-1.974.209-2.707.601-3.44a4.089 4.089 0 011.701-1.701C4.035 1.209 4.768 1 6.742 1z", id: "control-color-well-16_svg__a" })),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("mask", { id: "control-color-well-16_svg__b", fill: "#fff" },
            React.createElement("use", { xlinkHref: "#control-color-well-16_svg__a" })),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, d: "M9.258 1.75c1.763 0 2.428.16 3.086.512a3.35 3.35 0 011.394 1.394c.351.658.512 1.323.512 3.086v2.516c0 1.763-.16 2.428-.512 3.086a3.35 3.35 0 01-1.394 1.394c-.658.351-1.323.512-3.086.512H6.742c-1.763 0-2.428-.16-3.086-.512a3.35 3.35 0 01-1.394-1.394c-.351-.658-.512-1.323-.512-3.086V6.742c0-1.763.16-2.428.512-3.086a3.35 3.35 0 011.394-1.394c.658-.351 1.323-.512 3.086-.512z" }),
        React.createElement("path", { fill: "currentColor", opacity: 0.5, mask: "url(#control-color-well-16_svg__b)", d: "M5 7h2v2H5zm4 0h2v2H9zm4 0h2v2h-2zM7 5h2v2H7zm0 4h2v2H7zm2 2h2v2H9zm2 2h2v2h-2zm0-8h2v2h-2zm2-2h2v2h-2zM9 3h2v2H9zm2-2h2v2h-2zM7 1h2v2H7zM5 3h2v2H5zM3 1h2v2H3zm0 4h2v2H3zM1 3h2v2H1zm0 4h2v2H1zm2 2h2v2H3zm-2 2h2v2H1zm4 0h2v2H5zm-2 2h2v2H3zm4 0h2v2H7zm4-4h2v2h-2zm2 2h2v2h-2z" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
