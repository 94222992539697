import React from 'react'
import styled from 'styled-components'

import { ReactComponent as CrossIcon } from '@sketch/icons/cross-circle-18'
import { ReactComponent as ExclamationMark } from '@sketch/icons/exclamation-mark-16'
import { ReactComponent as Information } from '@sketch/icons/information-16'

import {
  Wrapper,
  BannerType,
  CloseButton as DefaultCloseButton,
  CloseButtonUpsell,
  Text,
  IconWrapper,
  WorkspaceIcon,
} from './Banner.styles'

export interface BannerProps {
  className?: string
  type: BannerType
  dismissible?: boolean
  onDismiss?: (event: React.MouseEvent<HTMLElement>) => void
  showIcon?: boolean
}

type IconType = {
  type: BannerType
}

const Icon: React.FC<IconType> = ({ type }) => {
  if (type === 'error') return <ExclamationMark />
  if (type === 'upsell') return <WorkspaceIcon />
  return <Information />
}

const BannerUnstyled: React.FC<BannerProps> = props => {
  const {
    className,
    children,
    type,
    dismissible,
    onDismiss,
    showIcon = true,
  } = props

  if (type === 'custom') {
    return (
      <Wrapper
        className={className}
        data-testid={`banner-${type}`}
        role="alert"
        $type={type}
      >
        {children}
      </Wrapper>
    )
  }

  const CloseButton = type === 'upsell' ? CloseButtonUpsell : DefaultCloseButton

  return (
    <Wrapper
      className={className}
      data-testid={`banner-${type}`}
      role="alert"
      $type={type}
    >
      {showIcon && (
        <IconWrapper>
          <Icon type={type} />
        </IconWrapper>
      )}
      <Text>{children}</Text>
      {dismissible && (
        <CloseButton onClick={onDismiss}>
          <span className="sr-only">Close banner</span>
          <CrossIcon aria-hidden />
        </CloseButton>
      )}
    </Wrapper>
  )
}
export const Banner = styled(BannerUnstyled)``
