import React from 'react'

import { usePreloadImage } from '@sketch/utils'
import { PublicationThumbnailFragment } from '@sketch/gql-types'

import { getPublicationThumbnail } from '../../utils'

import {
  Thumbnail,
  NoImageIcon,
  NoImageThumbnail,
  Skeleton,
} from './PublicationThumbnail.styles'

interface PublicationExistingThumbnailProps {
  className?: string
  name: string
  srcSet: string
  fallback?: string
}

const PublicationExistingThumbnail = (
  props: PublicationExistingThumbnailProps
) => {
  const { className, srcSet, name, fallback } = props

  const { isLoaded, ref } = usePreloadImage({
    srcSet,
  })

  if (isLoaded) {
    return <Skeleton className={className} ref={ref} width="100%" />
  }

  return (
    <Thumbnail
      className={className}
      alt={name}
      src={fallback}
      srcSet={srcSet}
    />
  )
}

export interface PublicationThumbnailProps {
  className?: string
  publication: PublicationThumbnailFragment
}

const PublicationThumbnail = (props: PublicationThumbnailProps) => {
  const { className, publication } = props
  const thumbnail = getPublicationThumbnail(publication.share)

  const srcSet = thumbnail
    ? `${thumbnail.standard} 1x, ${thumbnail.retina} 2x`
    : undefined

  if (srcSet) {
    return (
      <PublicationExistingThumbnail
        className={className}
        name={publication.share.name}
        srcSet={srcSet}
        fallback={thumbnail?.standard}
      />
    )
  }

  return (
    <NoImageThumbnail className={className}>
      <NoImageIcon aria-label="No Image available" />
    </NoImageThumbnail>
  )
}

export default PublicationThumbnail
