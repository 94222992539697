import { Modal, Pill, Skeleton } from '@sketch/components'
import styled from 'styled-components'
import { ReactComponent as PlusIcon } from '@sketch/icons/plus-16'
import { ReactComponent as ArrowCircleIcon } from '@sketch/icons/clockwise-arrow-circle-16'
import { ReactComponent as ControlSwitchesIcon } from '@sketch/icons/control-switches-16'

export const PREVIEW_CANVAS_SIZE = {
  width: 432,
  height: 213,
}

export const StyledModalHeader = styled(Modal.Header)`
  background-color: ${({ theme }) =>
    theme._name === 'dark' ? theme.colors.background.secondary.B : '#f9f9f9'};
  /* !important to override all padding set across different breakpoints */
  padding: 0 !important; /* stylelint-disable-line declaration-no-important */
`

export const StyledModalBody = styled(Modal.Body)`
  height: 298px;
  overflow: auto;

  && {
    padding: 0 20px 32px 32px;
  }

  /* !important to override all padding-top set across different selectors in Modal.tsx and ModalBody.tsx */
  padding-top: 0px !important; /* stylelint-disable-line declaration-no-important */
`

export const ExportModalSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes.E};
  background-color: ${({ theme }) => theme.colors.background.secondary.A};

  /* 
     We force sticky here so the title can always:
     - Be on top
     - Have the same spacing as the list with/without the scrollbar
  */
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 16px 0 7px; /* stylelint-disable-line scales/space */
`

export const HeaderActionsContainer = styled.div`
  display: flex;
  /* Overwrite crazy rule set in the button component
   https://github.com/sketch-hq/cloud-frontend/blob/52f2211a17f65ce37ee8385d6e08c023e572ac81/packages/components/src/Button/Button.styles.ts#L63
   */
  button + button {
    margin-left: 0px;
  }
`

export const PreviewCanvasContainer = styled.div`
  height: ${PREVIEW_CANVAS_SIZE.height}px;
  width: ${PREVIEW_CANVAS_SIZE.width}px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PreviewCanvas = styled.canvas`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`
export const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 42px 0 29px 0; /* stylelint-disable-line scales/space */
  margin: auto;
`

export const PreviewSkeleton = styled(Skeleton)`
  width: 100%;
  height: 100%;
`

export const StyledPlusIcon = styled(PlusIcon)`
  width: 16px;
  height: 16px;
`

export const StyledArrowCircleIcon = styled(ArrowCircleIcon)`
  width: 16px;
  height: 16px;
`

export const StyledControlSwitchesIcon = styled(ControlSwitchesIcon)`
  width: 16px;
  height: 16px;
`

export const ExportItemsListContainer = styled.div`
  overflow-y: auto;
  /* We need to leave 5px for the input focus border to not be clipped on safari. */
  padding-top: 5px; /* stylelint-disable-line scales/space */
`
export const FormatTagListContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const StyledPill = styled(Pill)`
  position: absolute;
  top: 16px;
  left: 15px;
  background-color: ${({ theme }) => theme.colors.background.primary.B};
`
