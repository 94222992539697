import React from 'react'
import {
  ShareInfoWithCollectionFragment,
  VersionFragment,
} from '@sketch/gql-types'
import { RenderStatus } from '@sketch/modules-common'

export type ShareWithoutVersion = OmitSafe<
  ShareInfoWithCollectionFragment,
  'version'
>

export interface VersioningContextBase {
  share: ShareWithoutVersion
  latestVersion: VersionFragment
  isViewingLatestVersion: boolean
  hasPendingPatches: boolean
  latestVersionId: string
  versionShortId: string
  onVersionUpdateNeeded: () => Promise<void>
  setArtboardThumbnail: (thumbnail: string) => void
  artboardThumbnail: string
  renderStatus: RenderStatus | null
}

export interface VersioningContextPreparing extends VersioningContextBase {
  loading: true
}

export interface VersioningContextReady extends VersioningContextBase {
  loading: false
  currentVersion: VersionFragment
}

export type VersioningContext =
  | VersioningContextPreparing
  | VersioningContextReady

export const ShareVersionContext = React.createContext<
  VersioningContext | undefined
>(undefined)
