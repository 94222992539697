export type DownloadProps =
  | { isAvailable: true; url: string }
  | { isAvailable: false; url?: string }

export type VersionForDownloadProps = {
  document: {
    downloadAvailable: boolean
    url: string | null
  } | null
}

export const getDownloadProps = (
  version: VersionForDownloadProps | null
): DownloadProps => {
  const isAvailable = !!version?.document?.downloadAvailable

  if (isAvailable && version?.document?.url) {
    return { isAvailable: true, url: version.document.url }
  }
  return { isAvailable: false, url: version?.document?.url || undefined }
}
