import {
  useGetProjectsQuery,
  useGetProjectsLazyQuery,
  GetProjectsQueryVariables,
  GetProjectsQuery,
} from '@sketch/gql-types'

export interface UseProjectsSwitchProps
  extends OmitSafe<GetProjectsQueryVariables, 'workspaceId'> {
  workspaceId: string
}

export const useGetProjects = ({
  workspaceId,
  after,
}: UseProjectsSwitchProps) => {
  const workspaceResult = useGetProjectsQuery({
    variables: { workspaceId, after },
  })
  const { data: workspaceData, ...rest } = workspaceResult

  return { ...rest, ...normalizeProjects(workspaceData) }
}

export const useGetProjectsLazy = () => {
  const [getProjects, workspaceResult] = useGetProjectsLazyQuery()
  const { data: workspaceData, ...rest } = workspaceResult

  return { getProjects, ...rest, ...normalizeProjects(workspaceData) }
}

function normalizeProjects(workspaceData: GetProjectsQuery | undefined) {
  if (!workspaceData) return {}

  const projects = workspaceData.workspace.projects
  const draftsProject = workspaceData.workspace.draftsProject

  return {
    projects,
    draftsProject,
    allProjects: [...draftsProject.entries, ...projects.entries],
  }
}
