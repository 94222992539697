import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import isEqual from 'react-fast-compare'
import { parse } from 'query-string'

import AnnotationDot from '../AnnotationDot'
import { useStableHandler } from '@sketch/utils'

import {
  ArtboardDimensions,
  Coordinates,
  ReferenceBounds,
  ArtboardContainerBounds,
  AnnotationDotFlavour,
} from '../../types'
import { useDotCoordinateStyle, useTranslateDot } from '../../hooks'
import useMoveAnnotation from '../../operations/useMoveAnnotation'
import { addAnnotationToQueryParams } from '../../utils'

import { Link } from './AnnotationOverlayDot.styles'

export interface AnnotationOverlayDotProps
  extends React.ComponentPropsWithoutRef<'a'> {
  annotationIdentifier: string
  flavour: AnnotationDotFlavour
  selected?: boolean
  artboardBounds?: ArtboardDimensions
  getArtboardContainerBounds?: () => ArtboardContainerBounds | undefined
  prefixBounds?: Coordinates
  referenceBounds: ReferenceBounds
  coordinates: Coordinates
  disabled?: boolean
  onMoveDrop?: ReturnType<typeof useMoveAnnotation>
  additionalStyle?: React.CSSProperties
}

const AnnotationOverlayDot = React.forwardRef<
  HTMLAnchorElement,
  AnnotationOverlayDotProps
>(function AnnotationOverlayDot(props, ref) {
  const {
    annotationIdentifier,
    coordinates,
    referenceBounds,
    artboardBounds,
    getArtboardContainerBounds,
    prefixBounds,
    flavour,
    selected,
    disabled,
    onMoveDrop,
    style: linkStyle,
    additionalStyle,
    ...linkProps
  } = props

  const linkRef = useRef<HTMLAnchorElement | null>(null)
  useImperativeHandle(ref, () => linkRef.current!)

  useEffect(() => {
    const link = linkRef.current
    const stopPropagation = (e: TouchEvent) => {
      e.stopPropagation()
    }

    /**
     * We need to prevent the "touchStart" from bubbling down to
     * the canvas container, because if it reaches it it will be
     * prevented.
     *
     * Since the elements are links, they shouldn't be prevented
     * otherwise the user-agent won't push the entry to history
     */
    link?.addEventListener('touchstart', stopPropagation)

    return () => {
      link?.removeEventListener('touchstart', stopPropagation)
    }
  })

  const coordinatesStyle = useDotCoordinateStyle({
    coordinates,
    referenceBounds,
    prefixBounds,
    artboardBounds,
  })

  const { hasMoved, ...translateDot } = useTranslateDot({
    annotationIdentifier,
    coordinates,
    prefixBounds,
    getArtboardContainerBounds,
    disabled: flavour === 'resolved',
    onMoveDrop: useCallback(
      mouseCoordinates => {
        onMoveDrop?.(annotationIdentifier, mouseCoordinates)
      },
      [annotationIdentifier, onMoveDrop]
    ),
    onClick: useCallback(
      (event, hasMoved) => {
        /**
         * If the dot moved we want to prevent any action from happening
         * this will be the case for opening or closing the annotation
         */
        if (hasMoved) {
          event.preventDefault()
        } else {
          linkProps.onClick?.(event as React.MouseEvent<HTMLAnchorElement>)
        }

        /**
         * Prevent the click event from propagating
         * so it doesn't reach the canvas and closes
         * the annotation
         */
        event.stopPropagation()
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [linkProps.onClick]
    ),
  })

  const style = {
    ...linkStyle,
    ...coordinatesStyle,
    ...translateDot.style,
    ...additionalStyle,
  } as const

  const handleOnContextMenu = useStableHandler(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      /**
       * Prevent the accidental click on the right button
       * to show the context menu when moving the button
       *
       * https://github.com/sketch-hq/Cloud/issues/13876#issuecomment-1189246019
       */
      if (hasMoved) {
        event.stopPropagation()
        event.preventDefault()
      }
    }
  )

  return (
    <Link
      ref={linkRef}
      isActive={match => !!(match && selected)}
      aria-disabled={disabled}
      aria-current={selected ? 'true' : undefined}
      // This "tabIndex" was added purposefully
      // to prevent the canvas from translating when a tab event happens
      // check https://github.com/sketch-hq/Cloud/issues/18432 to see the behaviour
      //
      tabIndex={-1}
      to={location => ({
        ...location,
        state: {
          ...((location?.state as any) || {}),
          /**
           * Safe-guarding that this state
           * doesn't propagate to clicked dots
           */
          isFromCommentTab: false,
        },
        search: addAnnotationToQueryParams(
          parse(location.search),
          annotationIdentifier
        ),
      })}
      replace
      {...linkProps}
      {...translateDot}
      onContextMenu={handleOnContextMenu}
      style={style}
    >
      <AnnotationDot flavour={flavour} selected={selected} />
    </Link>
  )
})

export default React.memo(AnnotationOverlayDot, isEqual)
