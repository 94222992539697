import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "canvas-tooltip", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 225 100", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("path", { id: "canvas-tooltip_svg__a", d: "M0 0h225v100H0z" })),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("mask", { id: "canvas-tooltip_svg__b", fill: "#fff" },
            React.createElement("use", { xlinkHref: "#canvas-tooltip_svg__a" })),
        React.createElement("path", { strokeOpacity: 0.1, stroke: "currentColor", mask: "url(#canvas-tooltip_svg__b)", d: "M.5.5h224v99H.5z" }),
        React.createElement("path", { strokeOpacity: 0.55, stroke: "currentColor", mask: "url(#canvas-tooltip_svg__b)", d: "M11.5 11.5h30v49h-30zm41 0h30v49h-30zm41 0h30v49h-30z" }),
        React.createElement("circle", { strokeOpacity: 0.55, stroke: "currentColor", mask: "url(#canvas-tooltip_svg__b)", cx: 159, cy: 45, r: 7.5 }),
        React.createElement("path", { d: "M192 44.13l2.313 4.686 5.172.752-3.742 3.648.883 5.151L192 55.935l-4.626 2.432.883-5.151-3.742-3.648 5.172-.752L192 44.13zm-15-30.012l7.191 14.382h-14.382L177 14.118zM93.5 71.5h30v49h-30zm-82 0h71v49h-71zm123 0h30v49h-30z", strokeOpacity: 0.55, stroke: "currentColor", mask: "url(#canvas-tooltip_svg__b)" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
