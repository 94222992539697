import React from 'react'

import { EmptyHeader } from '@sketch/modules-common'
import { Link } from '@sketch/components'

import {
  Container,
  ErrorContainer,
  StyledErrorMessage,
} from './PartnerExpiredWorkspaceError.styles'

import { ReactComponent as ExclamationMarkIcon } from '@sketch/icons/exclamation-mark-triangle-64'

const PartnerExpiredWorkspaceError: React.FC = () => (
  <Container>
    <EmptyHeader />
    <ErrorContainer>
      <StyledErrorMessage
        title="The Workspace you’re trying to join has expired"
        icon={<ExclamationMarkIcon />}
      >
        To join this Workspace, please email{' '}
        <Link external href="mailto:partners@sketch.com" isUnderlined>
          partners@sketch.com
        </Link>
      </StyledErrorMessage>
    </ErrorContainer>
  </Container>
)

export default PartnerExpiredWorkspaceError
