import { useEffect, useRef, useState } from 'react'
import { ObservableQueryFields } from 'react-apollo'
import { NetworkStatus } from 'apollo-client'
import isEqual from 'lodash.isequal'
import { dataIdFromObject } from '@sketch/graphql-cache'

import { handleFetchMore } from '@sketch/components'

import { useGetArtboardRevisionsQuery } from '@sketch/gql-types'

const AFTER_PATH = ['artboardRevisions', 'meta', 'after']
const ENTRIES_PATH = ['artboardRevisions', 'entries']

const createHandleFetchLatest = (
  fetchMore: ObservableQueryFields<any, any>['fetchMore']
) =>
  handleFetchMore(fetchMore, ENTRIES_PATH, {
    dataIdFromObject,
    fetchLatest: true,
    preserveAfter: true,
    afterPath: AFTER_PATH,
  })

const useGetArtboardRevisions = (
  shareIdentifier: string,
  permanentArtboardIdentifier: string
) => {
  const query = useGetArtboardRevisionsQuery({
    variables: {
      shareIdentifier,
      permanentArtboardIdentifier,
    },
    shouldInvalidatePrevious: (...args) => !isEqual(...args),
  })

  const [initialNetworkStatus] = useState(query.networkStatus)

  /* Make sure that the loadLatestHandler is always updated, but is only called once */
  const loadLatestHandler = useRef(createHandleFetchLatest(query.fetchMore))
  useEffect(() => {
    loadLatestHandler.current = createHandleFetchLatest(query.fetchMore)
  }, [query.fetchMore])

  useEffect(() => {
    /* Call the "loadLatestHandler" when mounted to refresh the list */
    /* But only when the request is already cached */
    initialNetworkStatus === NetworkStatus.ready && loadLatestHandler.current()
  }, [initialNetworkStatus])

  return query
}

export default useGetArtboardRevisions
