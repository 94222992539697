import React from 'react'

import { routes, useFlag } from '@sketch/modules-common'

import { getWorkspaceUserRolePermissions } from '../../../../utils'

import { icons } from '../../icons'

import { useDiscover } from '@sketch/discover'

import { SelectDropdownLinkItem } from '@sketch/components'

import HelpMenu from '../HelpMenu'
import DiscoverNewContentPill from '../DiscoverNewContentPill'

import { Wrapper } from './BottomMenu.styles'

import { WorkspaceMinimalFragment } from '@sketch/gql-types'

interface BottomMenuProps {
  workspace?: WorkspaceMinimalFragment
}

const BottomMenu: React.FC<BottomMenuProps> = ({ workspace }) => {
  // Clear FF when its released
  // https://github.com/sketch-hq/Cloud/issues/16994
  const isCommunityCanvasFFEnabled = useFlag('community-canvas')

  const { hasNewContent } = useDiscover()

  const { isFinance, isPartner } = getWorkspaceUserRolePermissions(workspace)
  const isFinanceOrPartner = isFinance || isPartner

  return (
    <Wrapper data-testid="bottom-menu">
      {!isFinanceOrPartner && workspace && (
        <SelectDropdownLinkItem
          icon={icons.CompassIcon}
          text="Discover"
          to={routes.WORKSPACE_DISCOVER.create({
            workspaceId: workspace.identifier,
          })}
          additional={hasNewContent && <DiscoverNewContentPill />}
        />
      )}

      {isCommunityCanvasFFEnabled && !isFinanceOrPartner && (
        <SelectDropdownLinkItem
          icon={icons.CommunityIcon}
          text="Community"
          to={routes.COMMUNITY_CANVAS.create({})}
        />
      )}

      <HelpMenu
        title="Help"
        userCanAdminister={workspace?.userCanAdminister}
        workspaceId={workspace?.identifier}
        workspaceName={workspace?.name}
      />
    </Wrapper>
  )
}

export default BottomMenu
