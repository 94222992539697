import React from 'react'

import { DropdownItemWrapper } from './Item'
import { Skeleton } from '../Skeleton'

export interface DropdownSkeletonProps {
  count?: number
}

export const DropdownSkeleton = (props: DropdownSkeletonProps) => {
  const { count = 1 }: typeof props = props
  return (
    <>
      {Array.from({ length: count }).map((_, i) => (
        <DropdownItemWrapper key={i}>
          <Skeleton height="12px" width="100%" />
        </DropdownItemWrapper>
      ))}
    </>
  )
}
