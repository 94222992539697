import styled, { css, DefaultTheme, StyledComponent } from 'styled-components'
import { ForwardRefExoticComponentWithAs } from '@reach/utils'

import { Flex, Text } from '../Box'
import { Caption } from '../Caption'
import { Checkbox as BaseCheckbox, CheckboxLabel } from '../Checkbox'
import { DropdownActionButton } from '../Button'
import { Pill } from '../Pill'
import { SelectorButton } from '../SelectorButton'
import { Banner } from '../Banner'
import { RadioButton as BaseRadioButton } from '../RadioButton'
import { SelectOption, ListboxOptionProps } from '../Select'
import { SelectPopover } from '../SelectPopover'

const hoverStyle = css<{ disabled?: boolean }>`
  padding: 8px 12px;

  border-radius: ${({ theme }) => theme.radii.large};

  :hover {
    ${({ disabled, theme }) =>
      !disabled && `background: ${theme.colors.background.tertiary.C}`};
  }
`

export const RadioButton = styled(BaseRadioButton)`
  padding: 8px 12px;
  margin: 0;
`

export const Checkbox = styled(BaseCheckbox)`
  ${hoverStyle}
  margin: 0 8px;
  line-height: 1;

  ${CheckboxLabel} {
    line-height: 1;
  }

  ${Caption} {
    font-size: ${({ theme }) => theme.fontSizes.C};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: initial;
    `}
`

export const FullSeparator = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border.B};
  margin: 8px 0;
`

export const Popover = styled(SelectPopover)`
  width: 256px;
  padding: 8px 0px;

  &[data-reach-listbox-popover] {
    background: ${({ theme }) => theme.colors.background.secondary.A};
    max-height: none;
  }
`

/**
 * We have to provide am explicity type for the Option
 * as otherwise TypeScript fails to compile. See more:
 *  - https://github.com/microsoft/TypeScript/issues/42873
 *  - https://github.com/microsoft/TypeScript/issues/47663
 */
type OptionComponent = StyledComponent<
  ForwardRefExoticComponentWithAs<'li', ListboxOptionProps>,
  DefaultTheme
>

export const Option: OptionComponent = styled(SelectOption)`
  :hover {
    background-color: none;
  }

  &&& {
    background-color: transparent;
    border-radius: ${({ theme }) => theme.radii.large};
    margin: 0 8px;
    padding: 0px;
    width: calc(100% - 16px);

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: initial;
        label {
          /* We want the opacity of the text will be normal,
          the radio button will be handling it's own opacity */
          opacity: 1;
        }
      `}
  }
`

// This button doesn't need to be focusable because his parent is already focusable
export const TriggerButton = styled(SelectorButton).attrs({
  chevrons: true,
  tabIndex: -1,
})<{
  $colorRed?: boolean
}>`
  text-align: right;
  height: auto;

  ${({ $colorRed, theme }) =>
    $colorRed &&
    css`
      color: ${theme.colors.state.negative.A};

      :hover {
        color: ${theme.colors.state.negative.A};
      }
    `}
`

export const CommentLabel = styled(Text.Div).attrs({
  textStyle: 'copy.quaternary.standard.C',
})`
  white-space: nowrap;
`

export const Header = styled.span`
  margin: 0;
  display: block;

  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 1.182; /* stylelint-disable-line scales/line-height */
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  padding: 10px 20px 10px; /* stylelint-disable-line scales/space */

  text-transform: uppercase;
`

export const RemoveButton = styled(DropdownActionButton)`
  color: ${({ theme }) => theme.colors.state.negative.A};

  :hover {
    color: ${({ theme }) => theme.colors.state.negative.A};
    background-color: ${({ theme }) => theme.colors.background.tertiary.C};
  }
`

export const WorkspaceAccessLevelWrapper = styled(Flex)`
  align-items: flex-start;
  padding: 12px 8px 12px 16px;
`

export const AccessLevelSelectBanner = styled(Banner).attrs({
  type: 'warning',
  showIcon: false,
})`
  padding: 14px 16px; /* stylelint-disable-line scales/space */
  margin: 8px;
`

export const StyledPill = styled(Pill)`
  margin-left: 8px;
`
