import React from 'react'
import styled from 'styled-components'

import { Text, Section } from '@sketch/components'

const PanelWrapper = styled.div`
  position: relative;
`

const Panel: React.FC = ({ children }) => {
  return <PanelWrapper>{children}</PanelWrapper>
}

export interface PanelTitleProps {
  extra?: React.ReactNode
}

export const PanelTitle: React.FC<PanelTitleProps> = ({ extra, children }) => (
  <Section title={children} action={extra} />
)

export const PanelBody = styled(Text.Div).attrs({
  textStyle: 'copy.tertiary.standard.D',
})``

export default Panel
