import React from 'react'
import { Editor } from '@tiptap/react'

import { useKey } from 'react-use'
import { keyWithModifier } from '@sketch/utils'

import {
  BubbleMenuStyled,
  BubbleMenuListItem,
  BubbleMenuItem,
  BubbleMenuIcon,
  Divider,
} from './BubbleMenu.styles'
import { BubbleMenuLink } from './BubbleMenuLink'

import { Tooltip } from '../../Tooltip'
import {
  ParagraphTooltip,
  TitleTooltip,
  HeadingTooltip,
  SubheadingTooltip,
  BulletListTooltip,
  NumberedListTooltip,
  BoldTooltip,
  ItalicsTooltip,
  UnderlineTooltip,
  LinkTooltip,
} from './ShortcutsTooltips'

import {
  setParagraph,
  isParagraphActive,
  setTitle,
  isTitleActive,
  setHeading,
  isHeadingActive,
  setSubheading,
  isSubheadingActive,
  setBulletList,
  isBulletListActive,
  setNumberedList,
  isNumberedListActive,
  setBold,
  isBoldActive,
  setItalics,
  isItalicsActive,
  setUnderline,
  isUnderlineActive,
  setLink,
  isLinkActive,
} from '../utils'

import { ReactComponent as ParagraphIcon } from '@sketch/icons/editor-text-paragraph-24'
import { ReactComponent as H1Icon } from '@sketch/icons/editor-text-h1-24'
import { ReactComponent as H2Icon } from '@sketch/icons/editor-text-h2-24'
import { ReactComponent as H3Icon } from '@sketch/icons/editor-text-h3-24'
import { ReactComponent as BoldIcon } from '@sketch/icons/editor-text-bold-24'
import { ReactComponent as ItalicIcon } from '@sketch/icons/editor-text-italic-24'
import { ReactComponent as UnderlineIcon } from '@sketch/icons/editor-text-underline-24'
import { ReactComponent as BulletListIcon } from '@sketch/icons/editor-list-bullet-24'
import { ReactComponent as NumberListIcon } from '@sketch/icons/editor-list-number-24'
import { ReactComponent as LinkIcon } from '@sketch/icons/link-sharing-24'

interface BubbleMenuProps {
  editor: Editor | null
}

export const BubbleMenu: React.FC<BubbleMenuProps> = ({ editor }) => {
  const [showLinkPopover, setShowLinkPopover] = React.useState(false)

  // Keyboard shortcut (⌘ + K) to open link control, this is an exception
  // because usually keyboard shortcuts are part of tiptap
  useKey(keyWithModifier('k'), () => {
    setShowLinkPopover(true)
  })

  // To close the link control
  useKey('Escape', () => {
    setShowLinkPopover(false)
  })

  const setLinkMemoized = React.useCallback(
    (url: string) => {
      setLink(editor, url)
    },
    [editor]
  )

  if (!editor) {
    return null
  }

  return (
    <BubbleMenuStyled
      className="bubble-menu"
      tippyOptions={{
        duration: 100,
        maxWidth: 'none',
        onHidden: () => {
          setShowLinkPopover(false)
        },
      }}
      editor={editor}
    >
      {showLinkPopover ? (
        <BubbleMenuListItem>
          <BubbleMenuLink
            currentUrl={editor.getAttributes('link').href}
            isLinkActive={editor.isActive('link')}
            onAdd={setLinkMemoized}
            onRemove={() => {
              editor.chain().focus().unsetLink().run()
            }}
          />
        </BubbleMenuListItem>
      ) : (
        <>
          <BubbleMenuListItem>
            <Tooltip
              placement="top"
              spacing="12px"
              content={<ParagraphTooltip />}
            >
              <BubbleMenuItem
                onClick={() => setParagraph(editor)}
                className={isParagraphActive(editor)}
              >
                <BubbleMenuIcon as={ParagraphIcon} />
              </BubbleMenuItem>
            </Tooltip>
          </BubbleMenuListItem>
          <Divider />
          <BubbleMenuListItem>
            <Tooltip placement="top" spacing="12px" content={<TitleTooltip />}>
              <BubbleMenuItem
                onClick={() => setTitle(editor)}
                className={isTitleActive(editor)}
              >
                <BubbleMenuIcon as={H1Icon} />
              </BubbleMenuItem>
            </Tooltip>
          </BubbleMenuListItem>
          <BubbleMenuListItem>
            <Tooltip
              placement="top"
              spacing="12px"
              content={<HeadingTooltip />}
            >
              <BubbleMenuItem
                onClick={() => setHeading(editor)}
                className={isHeadingActive(editor)}
              >
                <BubbleMenuIcon as={H2Icon} />
              </BubbleMenuItem>
            </Tooltip>
          </BubbleMenuListItem>
          <BubbleMenuListItem>
            <Tooltip
              placement="top"
              spacing="12px"
              content={<SubheadingTooltip />}
            >
              <BubbleMenuItem
                onClick={() => setSubheading(editor)}
                className={isSubheadingActive(editor)}
              >
                <BubbleMenuIcon as={H3Icon} />
              </BubbleMenuItem>
            </Tooltip>
          </BubbleMenuListItem>
          <Divider />
          <BubbleMenuListItem>
            <Tooltip
              placement="top"
              spacing="12px"
              content={<BulletListTooltip />}
            >
              <BubbleMenuItem
                onClick={() => setBulletList(editor)}
                className={isBulletListActive(editor)}
              >
                <BubbleMenuIcon as={BulletListIcon} />
              </BubbleMenuItem>
            </Tooltip>
          </BubbleMenuListItem>
          <BubbleMenuListItem>
            <Tooltip
              placement="top"
              spacing="12px"
              content={<NumberedListTooltip />}
            >
              <BubbleMenuItem
                onClick={() => setNumberedList(editor)}
                className={isNumberedListActive(editor)}
              >
                <BubbleMenuIcon as={NumberListIcon} />
              </BubbleMenuItem>
            </Tooltip>
          </BubbleMenuListItem>
          <Divider />
          <BubbleMenuListItem>
            <Tooltip placement="top" spacing="12px" content={<BoldTooltip />}>
              <BubbleMenuItem
                onClick={() => setBold(editor)}
                className={isBoldActive(editor)}
              >
                <BubbleMenuIcon as={BoldIcon} />
              </BubbleMenuItem>
            </Tooltip>
          </BubbleMenuListItem>
          <BubbleMenuListItem>
            <Tooltip
              placement="top"
              spacing="12px"
              content={<ItalicsTooltip />}
            >
              <BubbleMenuItem
                onClick={() => setItalics(editor)}
                className={isItalicsActive(editor)}
              >
                <BubbleMenuIcon as={ItalicIcon} />
              </BubbleMenuItem>
            </Tooltip>
          </BubbleMenuListItem>
          <BubbleMenuListItem>
            <Tooltip
              placement="top"
              spacing="12px"
              content={<UnderlineTooltip />}
              disabled={editor.isActive('link')}
            >
              <BubbleMenuItem
                onClick={() => setUnderline(editor)}
                className={isUnderlineActive(editor)}
                disabled={editor.isActive('link')}
              >
                <BubbleMenuIcon as={UnderlineIcon} />
              </BubbleMenuItem>
            </Tooltip>
          </BubbleMenuListItem>
          <BubbleMenuListItem>
            <Tooltip placement="top" spacing="12px" content={<LinkTooltip />}>
              <BubbleMenuItem
                onClick={() => setShowLinkPopover(true)}
                className={isLinkActive(editor)}
              >
                <BubbleMenuIcon as={LinkIcon} />
              </BubbleMenuItem>
            </Tooltip>
          </BubbleMenuListItem>
        </>
      )}
    </BubbleMenuStyled>
  )
}
