import { Stripe } from '@stripe/stripe-js'

export const confirmPendingSetupIntent = async (
  stripe: Stripe,
  intent: string,
  cardId?: string
) => {
  if (intent.startsWith('pi_')) {
    if (!cardId) return await stripe.confirmCardPayment(intent)
    return await stripe.confirmCardPayment(intent, {
      payment_method: cardId,
    })
  } else if (intent.startsWith('seti_')) {
    if (!cardId) return await stripe.confirmCardSetup(intent)
    return await stripe.confirmCardSetup(intent, {
      payment_method: cardId,
    })
  }
}
