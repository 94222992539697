import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useResponsiveDropdown } from '../ResponsiveDropdown'

const OFFSET = [0, 0] as [number, number]

export interface ContextMenuPassedProps<U> {
  positionRelativeToContainer: Position
  additionalState: U
}

type ContextMenuProps<U, P extends ContextMenuPassedProps<U>> = {
  contextMenu: React.ComponentType<P>
  contextMenuProps?: OmitSafe<P, keyof ContextMenuPassedProps<U>>
  onContextMenuToggle?: (state: boolean) => void
  onContextMenuState?: (position: Position) => U | Promise<U>
}

type Position = { x: number; y: number }

const handleClick = (event: MouseEvent) => {
  event.stopPropagation()
}

export function useContextMenu<U, P extends {}>(
  containerRef: React.RefObject<HTMLDivElement>,
  props: ContextMenuProps<U, P & ContextMenuPassedProps<U>>
) {
  const {
    contextMenu: ContextMenu,
    contextMenuProps,
    onContextMenuToggle,
    onContextMenuState,
  } = props

  const [position, setPosition] = useState<Position | null>(null)
  const [additionalState, setAdditionalState] = useState<U | null>(null)

  const [
    content,
    { ref },
    { setVisible, visible, update },
  ] = useResponsiveDropdown({
    dropdown: ContextMenu as any,
    dropdownProps: {
      ...contextMenuProps,
      positionRelativeToContainer: position,
      additionalState,
    },
    dropdownBreakpoint: 'base',
    placement: 'bottom-start',
    offset: OFFSET,
  })

  /**
   * Update the dropdown when the position changes
   */
  useEffect(() => {
    update?.()
  }, [position, update])

  /**
   * Prevent the popup click to propagate down
   */
  useEffect(() => {
    if (!ref || typeof ref !== 'object' || !ref.current || !visible) {
      return
    }

    const wrapperRef = ref.current as HTMLElement

    wrapperRef.addEventListener('mousedown', handleClick)

    return () => {
      wrapperRef.removeEventListener('mousedown', handleClick)
    }
  }, [position, ref, visible])

  /**
   * Handler for the button
   */
  const handleContextMenu = useCallback(
    async (
      e: Pick<
        React.MouseEvent<HTMLElement>,
        'preventDefault' | 'pageX' | 'pageY'
      >
    ) => {
      e.preventDefault()
      const container = containerRef.current

      if (!container) {
        return
      }

      const containerRect = container.getBoundingClientRect()
      const relativeX = e.pageX - containerRect.left
      const relativeY = e.pageY - containerRect.top

      const position = { x: relativeX, y: relativeY }
      const additionalState = (await onContextMenuState?.(position)) || null

      setAdditionalState(additionalState)
      setPosition(position)
      setVisible(true)
    },
    [containerRef, setVisible, onContextMenuState]
  )

  const cachedContextMenuToggle = useRef(onContextMenuToggle)
  useEffect(() => {
    cachedContextMenuToggle.current = onContextMenuToggle
  }, [onContextMenuToggle])

  useEffect(() => {
    cachedContextMenuToggle.current?.(visible)
  }, [visible])

  const renderedContextMenu = position ? (
    <div
      ref={ref}
      style={{ position: 'absolute', top: position.y, left: position.x }}
    >
      {content}
    </div>
  ) : null

  return [{ handleContextMenu }, renderedContextMenu] as const
}
