import React, { ReactElement, useState } from 'react'

export interface TabsProps {
  onTabSelected?: (tabOption: number) => void
  shouldSelectTab?: (tabOption: number) => boolean
  selectedTabIndex?: number
  children: TabElement[]
}

type ElementWithTabType = React.ReactElement & { type: { tabType: string } }

type TabElement =
  | (React.ReactElement & { type: { tabType: string } })
  | null
  | false

export const Tabs: React.FunctionComponent<TabsProps> = ({
  children,
  selectedTabIndex,
  onTabSelected,
  shouldSelectTab = () => true,
}) => {
  const [selectedTab, setSelectedTab] = useState(selectedTabIndex || 0)
  const elements = React.Children.toArray(children)

  let tabList
  const tabPanels: React.ReactElement[] = []

  elements.forEach(element => {
    if (!element || !React.isValidElement(element)) return null
    if ((element as ElementWithTabType)?.type.tabType === 'TabList') {
      tabList = React.cloneElement(element as ReactElement, {
        onTabSelected: (index: number) => {
          if (!shouldSelectTab(index)) return

          setSelectedTab(index)
          if (onTabSelected) onTabSelected(index)
        },
        shouldSelectTab,
        selectedTabIndex,
      })
    } else if ((element as ElementWithTabType)?.type.tabType === 'TabPanel') {
      tabPanels.push(React.cloneElement(element as ReactElement))
    }
  })

  return (
    <>
      {tabList}
      {tabPanels[selectedTab]}
    </>
  )
}
