import ApolloClient from 'apollo-client'
import { useHistory } from 'react-router-dom'
import { routes } from '@sketch/modules-common'
import { isViewingCurrentProject, isViewingWorkspace } from '../../utils'
import { ErrorHandler } from '@sketch/tracing'
import { useEventDispatch } from '@sketch/utils'
import {
  GetProjectsDocument,
  GetProjectsQuery,
  GetProjectsQueryVariables,
  GetWorkspacesDocument,
  GetWorkspacesQuery,
  useProjectAccessChangedSubscription,
} from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'
import { getToast } from '../../toasts'

declare module '@sketch/utils' {
  export interface EventsMap {
    projectAccessRevoked: {
      workspaceIdentifier: string
      projectIdentifier: string
      projectName: string
    }
  }
}

interface CheckWorkspaceAccessParams {
  workspaceIdentifier: string
  workspaces?: GetWorkspacesQuery
}

function checkWorkspaceAccess({
  workspaceIdentifier,
  workspaces,
}: CheckWorkspaceAccessParams) {
  const availableWorkspacesIdentifiers =
    workspaces?.me.workspaces.map(workspace => workspace.identifier) || []

  return availableWorkspacesIdentifiers.includes(workspaceIdentifier)
}

export const useProjectMembershipSubscriptions = () => {
  const history = useHistory()
  const { showToast } = useToast()

  const dispatchSharesRefresh = useEventDispatch('workspaceShareRefresh')
  const dispatchProjectAccessRevoked = useEventDispatch('projectAccessRevoked')

  /**
   * Project Access Changed Mutation
   *
   * Mutation fired when project access changes
   * for the current logged user.
   * Projects' list will be refreshed to reflect
   * the latest projects that user has access to.
   */
  useProjectAccessChangedSubscription({
    async onSubscriptionData({
      client,
      subscriptionData: { data, error, loading },
    }) {
      const responseData = data?.projectAccessChanged

      if (!responseData || error || loading) {
        ErrorHandler.shouldNeverHappen(
          'Received "projectAccessChanged" subscription data should always be valid one'
        )
        return
      }

      const { workspaceIdentifier } = responseData

      // We refetch the Workspaces to make sure the user
      // still has access to the current Workspace
      const { data: workspacesData } = await client.query<GetWorkspacesQuery>({
        query: GetWorkspacesDocument,
        fetchPolicy: 'network-only',
      })

      const hasWorkspaceAccess = checkWorkspaceAccess({
        workspaceIdentifier,
        workspaces: workspacesData,
      })

      // User loses access to the project
      if (responseData.__typename === 'ProjectAccessRevokedEvent') {
        const {
          project: { identifier: projectIdentifier },
        } = responseData

        const isUserViewingWorkspace = isViewingWorkspace(
          window.location.pathname,
          workspaceIdentifier
        )

        // Send user to the ENTRY route if he lost access to the Workspace and
        // is currently viewing it
        if (!hasWorkspaceAccess && isUserViewingWorkspace) {
          history.push(routes.ENTRY.create({}))

          return
        }

        // If the user is in a project Share, redirect him to the All Documents
        dispatchProjectAccessRevoked({
          workspaceIdentifier: responseData.workspaceIdentifier,
          projectIdentifier: responseData.project.identifier,
          projectName: responseData.project.name,
        })

        // Redirect the user to All Documents if he was viewing the current project
        if (
          isViewingCurrentProject(window.location.pathname, projectIdentifier)
        ) {
          showToast(
            getToast('PROJECT_ACCESS_REVOKED', responseData.project.name),
            'default'
          )

          history.push(
            routes.WORKSPACE_SHARES.create({ workspaceId: workspaceIdentifier })
          )
        }
      }

      if (hasWorkspaceAccess) {
        // Refetch the projects to reflect the projects that user has access to
        refetchProjects(client, workspaceIdentifier)
      }

      // Force the Shares view to refresh for All Documents to refresh, if visible
      // see src/modules/shares/components/WorkspaceSharesList.tsx
      dispatchSharesRefresh({
        workspaceIdentifier,
      })
    },
  })
}

const refetchProjects = (client: ApolloClient<object>, workspaceId: string) => {
  client.query<GetProjectsQuery, GetProjectsQueryVariables>({
    query: GetProjectsDocument,
    variables: { workspaceId },
    fetchPolicy: 'network-only',
  })
}
