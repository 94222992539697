import React from 'react'

import { Button, Modal, ModalInjectedProps } from '@sketch/components'

import {
  BillingPlanFragment,
  GetNextBillingCycleDocument,
  useCancelScheduledBillingSeatsMutation,
} from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'

interface CancelScheduleChangeModalProps extends ModalInjectedProps {
  workspaceId: string
  currentSeats: number
  customerId: string
  currentPlan?: BillingPlanFragment
}

/*
 * CancelScheduleChangeModal
 *
 * Renders a modal to cancel the scheduled next billing plan in "Workspace Settings"
 *
 */
export const CancelScheduleChangeModal: React.FC<CancelScheduleChangeModalProps> = props => {
  const {
    hideModal,
    workspaceId,
    currentSeats,
    customerId,
    currentPlan,
  } = props
  const isNewPricingEnabled = currentPlan?.product !== 'BUSINESS'

  const { showToast } = useToast()

  const [
    cancelScheduledSeats,
    { loading },
  ] = useCancelScheduledBillingSeatsMutation({
    variables: { workspaceId },
    onError: 'show-toast',
    onCompleted: () => {
      showToast('Scheduled Changes Successfully Cancelled')
      hideModal()
    },
    awaitRefetchQueries: true,
    refetchQueries: () => {
      return [
        {
          query: GetNextBillingCycleDocument,
          variables: { customerId, isNewPricing: isNewPricingEnabled },
        },
      ]
    },
  })

  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>Cancel Scheduled Change?</Modal.Header>
      <Modal.Body>
        <p>
          {`If you cancel, we’ll go back to charging you for the number of Editor seats you have on your next billing date. Right now you have ${currentSeats} Editor seats.`}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} onClick={hideModal}>
          Keep Changes
        </Button>
        <Button
          loading={loading}
          variant="negative"
          onClick={() => cancelScheduledSeats()}
        >
          Confirm Cancellation
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
