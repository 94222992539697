import * as Sentry from '@sentry/browser'

import { castError } from '@sketch/utils'

// We need it to prevent it from logging the error multiple times, since
// every time we try to use the lib can trigger an error and we don't wanna
// have multiple logs from the same error and user in Sentry.
let loggedError = false

export const loadZxcvbn = () =>
  import(/* webpackChunkName: "zxcvbn" */ 'zxcvbn').catch(e => {
    if (!loggedError) {
      const error = castError(e)

      // Log the error if we failed to load ZXCVBN
      Sentry.captureException(error)

      loggedError = true
    }
  })

export const passwordStrengthValidation = async (value?: string) => {
  const zxcvbn = await loadZxcvbn()

  if (!zxcvbn) return true

  const { score } = zxcvbn.default(value || '')

  // The score should be at least 2 (Okay)
  return score > 1
}
