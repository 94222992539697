import React, { useContext } from 'react'
import styled from 'styled-components'
import { LaunchSketchAlert } from '@sketch/modules-common'
import { getParsedItem, setStringifiedItem } from '@sketch/utils'
import {
  FlatButton,
  ModalContext,
  Text,
  DismissableCard,
} from '@sketch/components'
import { linkStyles, breakpoint } from '@sketch/global-styles'

const MISSING_FONTS_LOCAL_STORAGE_KEY = 'dismissedMissingFonts'

const StyledDismissableCard = styled(DismissableCard)`
  position: absolute;
  top: 20px;
  right: 20px;
  max-width: 314px;

  ${breakpoint('base', 'xs')`
  margin-left: 20px;
`}
`

const Message = styled(Text).attrs({
  textStyle: 'copy.secondary.standard.E',
})`
  margin: 0;
`

interface CustomFontsMessageProps {
  userCanEdit: boolean
  shareIdentifier: string
  hasMissingFonts: boolean
}

export const CustomFontsMessage = ({
  userCanEdit,
  shareIdentifier,
  hasMissingFonts,
}: CustomFontsMessageProps) => {
  const { showModal } = useContext(ModalContext)

  const dismissedWarningShareIds: string[] =
    getParsedItem(MISSING_FONTS_LOCAL_STORAGE_KEY) || []
  const shouldShowFontsWarning = !dismissedWarningShareIds.includes(
    shareIdentifier
  )

  const handleDismiss = () => {
    const currentValue = [...dismissedWarningShareIds, shareIdentifier]
    setStringifiedItem(MISSING_FONTS_LOCAL_STORAGE_KEY, currentValue)
  }

  const handleOpen = (e: React.MouseEvent) => {
    e.preventDefault()

    showModal(LaunchSketchAlert, {
      shareId: shareIdentifier,
      action: 'openInSketch',
    })
  }

  return (
    <StyledDismissableCard
      title="Document is missing custom fonts"
      onDismiss={handleDismiss}
      isVisible={hasMissingFonts && shouldShowFontsWarning}
    >
      {userCanEdit ? (
        <Message>
          To embed missing fonts,{' '}
          <ButtonLink onClick={handleOpen}>open the document</ButtonLink> and
          select File &gt; Document Settings &gt; Fonts
        </Message>
      ) : (
        <Message>
          For an accurate preview, ask the document&apos;s author to embed the
          fonts.
        </Message>
      )}
    </StyledDismissableCard>
  )
}
const ButtonLink = styled(FlatButton)`
  padding: 0;
  ${linkStyles}
`
