import styled from 'styled-components'

import { Modal } from '@sketch/components'

export const InputLabel = styled.p``

export const ModalBody = styled(Modal.Body)`
  ${InputLabel} {
    padding-bottom: 6px; /* stylelint-disable-line scales/space */
  }
`
