import {
  GetProjectsQuery,
  GetProjectsQueryVariables,
  QueryHookOptions,
  useGetProjectsQuery,
} from '@sketch/gql-types'

export function useGetMyDraftsProject(
  workspaceId: string,
  options?: QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>
) {
  const workspaceResult = useGetProjectsQuery({
    variables: { workspaceId },
    ...options,
  })
  const { data: workspaceData } = workspaceResult

  const draftsProject = workspaceData?.workspace.draftsProject?.entries[0]

  return draftsProject
}
