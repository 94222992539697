import React from 'react'
import styled from 'styled-components'
import {
  breakpoint,
  BREAKPOINTS_KEYS_SORTED,
  ResponsiveValues,
} from '@sketch/global-styles'

type Ratio = number | 'no-ratio'
export type ResponseProp = ResponsiveValues | number

export type ResponsiveRatio = ResponsiveValues<Ratio>

export const RatioContainer = styled.div``

const setItemWidthAndMargin = ([columns, gutterSize]: [number, number]) => `
  width: calc(100% / ${columns} - ${(gutterSize * (columns - 1)) / columns}px);
  margin-right: ${gutterSize}px;

  :nth-child(${columns}n) {
    margin-right: 0;
  }
`

const setItemRatioAndMargin = ([ratio, verticalRowSpace]: [Ratio, number]) => `
  margin-bottom: ${verticalRowSpace}px;

  ${
    ratio !== 'no-ratio'
      ? `
    position: relative;

    ::before {
      content: '';
      display: block;
      padding-bottom: ${(1 / ratio) * 100}%;
    }

    /* Style "RatioContainer" only when there's an actual ratio */
    ${RatioContainer} {
      position: absolute;

      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
    }
    `
      : ''
  }
`

const setWrapperNegativeMargin = ([verticalRowSpace]: [number]) => `
  margin-bottom: -${verticalRowSpace}px;
`

const normalizeToArray = <T,>(property: T[] | T) =>
  (Array.isArray(property) ? property : [property]) as ResponsiveValues<T>

const getIndexOrLastValue = <T extends any[]>(array: T, index: number) =>
  array[index] || array[array.length - 1]

const buildResponsiveCSS = <P, T extends Array<any>>(
  propsFunction: (props: P) => T,
  cssFunction: (responsiveValues: any) => string
) => (props: P) => {
  const properties = propsFunction(props)
  const propertiesLength = properties.map(item => item.length)

  const largestBreakpointIndex = Math.min(
    Math.max(...propertiesLength),
    BREAKPOINTS_KEYS_SORTED.length
  )

  const selectedBreakpoints = BREAKPOINTS_KEYS_SORTED.slice(
    0,
    largestBreakpointIndex
  )

  let cssByBreakpoint = ''

  for (let index = 0; index < largestBreakpointIndex; index++) {
    const currentBreakpoint = selectedBreakpoints[index]
    const nextBreakpoint = selectedBreakpoints[index + 1]

    const valuesByBreakpoint = properties.map(propertyArray =>
      getIndexOrLastValue<T>(propertyArray, index)
    )

    const cssInThisBreakpoint: string = breakpoint(
      currentBreakpoint,
      nextBreakpoint
    )`${cssFunction(valuesByBreakpoint)}`.join('')

    cssByBreakpoint += cssInThisBreakpoint
  }

  return cssByBreakpoint
}

export interface WrapperProps {
  columns: ResponsiveValues
  gutterSize: ResponsiveValues
  verticalRowSpace: ResponsiveValues
}

const buildResponsiveGrid = buildResponsiveCSS<
  WrapperProps,
  [ResponsiveValues, ResponsiveValues]
>(({ columns, gutterSize }) => [columns, gutterSize], setItemWidthAndMargin)

const buildWrapperMargin = buildResponsiveCSS<WrapperProps, [ResponsiveRatio]>(
  ({ verticalRowSpace }) => [verticalRowSpace],
  setWrapperNegativeMargin
)

export interface ItemProps {
  ratio: ResponsiveRatio
  verticalRowSpace: ResponsiveValues
  minWidth?: number
}

const buildResponsiveItem = buildResponsiveCSS<
  ItemProps,
  [ResponsiveRatio, ResponsiveValues]
>(
  ({ ratio, verticalRowSpace }) => [ratio, verticalRowSpace],
  setItemRatioAndMargin
)

export const Item = styled.li<ItemProps>`
  position: relative;
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : undefined)};
  ${buildResponsiveItem};
`

const Wrapper = styled.ul<WrapperProps>`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;

  list-style: none;
  padding: 0;
  margin: 0;

  /* Prevent the "verticalRowSpace" from the last row */
  ${buildWrapperMargin}

  ${Item} {
    ${buildResponsiveGrid};
  }
`

export interface AspectRatioGridProps {
  className?: string
  ratio?: ResponsiveRatio | Ratio
  columns?: ResponseProp
  gutterSize?: ResponseProp
  verticalRowSpace?: ResponseProp
  minWidth?: number
}

export const AspectRatioGrid: React.FC<AspectRatioGridProps> = props => {
  const {
    children,
    columns = 4,
    gutterSize = 0,
    ratio = 'no-ratio',
    verticalRowSpace = 0,
    minWidth,
    className,
  } = props

  const normalizedColumns = normalizeToArray<number>(columns)
  const normalizedGutterSize = normalizeToArray<number>(gutterSize)

  const normalizedRatio = normalizeToArray<Ratio>(ratio)
  const normalizedVerticalRowSpace = normalizeToArray<number>(verticalRowSpace)

  return (
    <Wrapper
      className={className}
      columns={normalizedColumns}
      gutterSize={normalizedGutterSize}
      verticalRowSpace={normalizedVerticalRowSpace}
    >
      {React.Children.map(children, childNode => (
        <Item
          minWidth={minWidth}
          verticalRowSpace={normalizedVerticalRowSpace}
          ratio={normalizedRatio}
        >
          <RatioContainer>{childNode}</RatioContainer>
        </Item>
      ))}
    </Wrapper>
  )
}
