import styled, { css } from 'styled-components'
import { breakpoint } from '@sketch/global-styles'

import {
  EmptyState as BaseEmptyState,
  GenericSectionTitle,
  GenericSectionTitleSkeleton,
} from '@sketch/components'

import { Footer as BaseFooter } from '@sketch/modules-common'

import { Insight, InsightSkeleton } from '@sketch/discover'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  /* Use the available height of the parent (WorkspaceSidebarLayout) */
  flex: 1;

  /*
    We are removing the margins added by the parent container "WorkspaceSidebarLayout.Margin"
    To allow the background of "EditorialWrapper" to reach all the corners
   */
  margin: 0 -16px;

  ${breakpoint('sm')`
    margin: 0 -40px;
  `}
`

export const EditorialWrapper = styled.div(
  ({ theme }) => css`
    background-image: linear-gradient(
      180deg,
      ${theme.colors.background.tertiary.D},
      transparent
    );

    padding-top: 32px;

    ${breakpoint('base', 'sm')`
      /*
        This changes will allow the discover carousel
        to scroll without showing borders on the side boundaries
        of the cards
      */

      ${Insight}, ${InsightSkeleton} {
        padding-left: 16px;

        :last-child {
          padding-right: 16px;
        }
      }

      /*
        We are reposing the margin removed in "Wrapper" to make sure
        the title elements are properly aligned
      */
      ${GenericSectionTitle}, ${GenericSectionTitleSkeleton} {
        padding-left: 16px;
        padding-right: 16px;
      }
  `}

    /*
    We are reposing the margin removed in "Wrapper" to make sure
    the elements inside are properly aligned
  */
  ${breakpoint('sm')`
    padding-top: 40px;
    padding-left: 56px;
    padding-right: 56px;
    margin: 0 -16px;
  `}
  `
)

export const EmptyState = styled(BaseEmptyState)`
  padding: 16px 16px 32px;

  /*
    Limit the width so the test can overflow bellow
    and not expand to the page full width
  */
  max-width: 620px;

  ${breakpoint('sm')`
    padding: 24px 40px 64px;
  `}
`

export const Footer = styled(BaseFooter)`
  margin-top: auto;
`
