interface Error {
  message: string
}

// Workaround for an issue where apollo attempts to access a query which has
// already unmounted.
// See:
// - https://github.com/sketch-hq/Cloud/issues/1032
// - https://github.com/sketch-hq/Cloud/issues/1597
// - https://github.com/sketch-hq/Cloud/issues/1962
// - and Apollo issue https://github.com/apollographql/apollo-client/issues/4114
// We can remove this when the underlying issues are resolved.
export const excludeError = (error: Error) => {
  if (!error || !error.message) {
    return false
  }

  const message = error.message

  return (
    message.includes(`ObservableQuery with this id doesn't exist`) ||
    message.includes(`Invariant Violation: 15`) ||
    message.includes(`Invariant Violation: 17`)
  )
}
