import styled from 'styled-components'

import { Skeleton, tabStyle } from '@sketch/components'

export const ParentGroup = styled.button<{ selected?: boolean }>`
  ${tabStyle};

  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  text-align: left;
  white-space: normal;
  line-height: 1.3;
  font-weight: ${({ theme }) => theme.fontWeights.regular};

  padding-left: 12px;
  border: none;
  background: ${({ theme, selected }) =>
    selected ? theme.colors.sketch.F : 'none'};
  color: ${({ theme, selected }) =>
    selected ? theme.colors.sketch.A : theme.colors.foreground.secondary.C};
`

export const MobileGroupsDropdownSkeleton = styled(Skeleton)`
  height: 40px;
  margin-left: 12px;
`
