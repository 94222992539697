import styled from 'styled-components'

import {
  Text,
  truncateStyles,
  PaymentLogo as BasePaymentLogo,
} from '@sketch/components'

export const Paragraph = styled(Text).attrs({
  textStyle: 'copy.tertiary.standard.D',
})`
  /* Needed because of modal internal styling */
  /* https://github.com/sketch-hq/cloud-frontend/blob/39270c24d5f558c765d111943d2767986587347e/src/components/Modal/Modal.tsx#L78-L81 */
  && {
    padding: 0;
  }
`

export const BoldParagraph = styled(Paragraph)`
  margin: 0;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 1.4;
`

export const SummaryLineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px; /* stylelint-disable-line scales/space */

  > ${Paragraph} {
    :first-child {
      ${truncateStyles};
    }
    + ${Paragraph} {
      margin-left: 8px;
    }
  }
`

export const SummaryHeaderWrapper = styled(SummaryLineWrapper)`
  padding-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
  align-items: flex-start;

  ${BoldParagraph} {
    /* We are forcing the card logo center to be aligned correctly with the text */
    margin-top: 6px; /* stylelint-disable-line scales/space */
  }
`

export const PaymentInfoWrapper = styled.div`
  display: flex;
  align-items: center;

  margin-top: 4px;
  padding: 4px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.background.tertiary.B};
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  font-size: ${({ theme }) => theme.fontSizes.B};
`

export const PaymentLogo = styled(BasePaymentLogo)`
  width: 28px;
`

export const Strong = styled.strong`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`

export const SummaryTotalParagraph = styled(Paragraph)`
  text-align: right;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  /* Needed because of modal internal styling */
  /* https://github.com/sketch-hq/cloud-frontend/blob/39270c24d5f558c765d111943d2767986587347e/src/components/Modal/Modal.tsx#L78-L81 */
  && {
    /* The "-15px" compensates the margin bottom 15px from "SummaryLineWrapper" to make sure the text are really close */
    margin: -15px 0 15px; /* stylelint-disable-line scales/space */
  }
`

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
export const AppliedDiscountRow = styled.div`
  display: flex;
  justify-content: space-between;

  ${Paragraph} {
    &:first-child {
      font-style: italic;
    }
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`

export const DiscountMessage = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.B};
  color: ${({ theme }) => theme.colors.foreground.secondary.C};

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  /* Needed because of modal internal styling */
  /* https://github.com/sketch-hq/cloud-frontend/blob/39270c24d5f558c765d111943d2767986587347e/src/components/Modal/Modal.tsx#L78-L81 */
  && {
    margin-top: 8px;
  }
`

export const SummaryDiscountDivider = styled.div`
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`

export const DiscountWrapper = styled.div<{ variant: 'default' | 'partner' }>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
  margin-bottom: 16px;
  padding-bottom: ${({ variant }) => variant !== 'partner' && '16px'};
`
