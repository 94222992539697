import styled from 'styled-components'
import { Breadcrumb } from '@sketch/components'

export const NavigationContainer = styled.div`
  overflow: hidden;
  margin: 0 16px;
`

export const HeaderBreadcrumbItem = styled(Breadcrumb.Item)`
  flex-shrink: 0;
`
