import { CURRENT_ENV } from './appEnv'

const releaseInfo: {
  app_env?: string
  current_env?: string
  node_env?: string
  commit_hash?: string
  release_version?: string
} = {
  app_env: process.env.REACT_APP_ENV,
  current_env: CURRENT_ENV,
  node_env: process.env.NODE_ENV,
  commit_hash: window.COMMIT_HASH,
  release_version: window.RELEASE_VERSION,
}

// Show only current_env if relevant
if (releaseInfo.app_env === releaseInfo.current_env) {
  delete releaseInfo.current_env
}

// Only relevant if it's `development`(because build optimizations won't be made in that case)
// Also, it can cause confusion between `production` as environment and `production` as a build env.
if (releaseInfo.node_env === 'production') {
  delete releaseInfo.node_env
}

if (!releaseInfo.release_version) {
  delete releaseInfo.release_version
}

export const RELEASE_INFO = { ...releaseInfo }
