import React, { useMemo, useState } from 'react'
import { PRMarinaRect } from '@sketch-hq/sketch-web-renderer'
import { TruncateWithTooltip } from '@sketch/components'

import {
  useFetchSymbolArtboard,
  useMapPRMarinaRectToCanvasRelativeRect,
} from '../../../hooks/inspector'

import * as S from './SymbolInstanceOverlay.styles'
import { ArtboardDetailInspectorSymbolLink } from '../../ArtboardDetailInspectorSymbolLink'
import { OVERLAYS_Z_INDEX } from '../constants'
import { SymbolMaster } from '../../../../../inspector'

type SymbolInstanceOverlayProps = {
  className?: string
  bounds: PRMarinaRect
  symbolMaster: SymbolMaster
  canvasContainerRef: React.RefObject<HTMLDivElement>
}

export function SymbolInstanceOverlay({
  className,
  bounds,
  symbolMaster,
  canvasContainerRef,
}: SymbolInstanceOverlayProps) {
  const {
    isLoading,
    permanentArtboardShortId,
    shareIdentifier,
  } = useFetchSymbolArtboard(symbolMaster)

  const symbolName = symbolMaster.symbolMasterShortName
    ? symbolMaster.symbolMasterShortName
    : symbolMaster.name
  const canvasRelativeRect = useMapPRMarinaRectToCanvasRelativeRect(bounds)
  const [labelWidth, setLabelWidth] = useState(0)

  const symbolWidth = canvasRelativeRect?.width ?? 0
  const symbolPositionX = canvasRelativeRect?.x ?? 0

  const canvasWidth = canvasContainerRef.current?.getBoundingClientRect().width

  /**
   * Getting the width of the symbol name for two purposes:
   * 1) To check if the name needs to overflow the symbol container
   * 2) To get the coordinates of the symbol on the X axis. This is used to
   * check if the symbol's name will overflow under the sidebar, and if it does,
   * we limit its width.
   */
  const { isLabelBiggerThanSymbol, symbolLabelMaxWidth } = useMemo(() => {
    return {
      isLabelBiggerThanSymbol: labelWidth > symbolWidth,
      symbolLabelMaxWidth:
        canvasWidth && symbolPositionX
          ? canvasWidth - symbolPositionX
          : undefined,
    }
  }, [labelWidth, symbolWidth, canvasWidth, symbolPositionX])

  // Hide the selector until it's ready
  if (isLoading || !canvasRelativeRect) {
    return null
  }

  const { x, y, width, height } = canvasRelativeRect

  return (
    <S.StyledLayerOverlay
      className={className}
      $borderSize={2}
      $color="purple"
      $width={width}
      $height={height}
      $x={x}
      $y={y}
      title={symbolName}
      $zIndex={OVERLAYS_Z_INDEX.SELECTED_SYMBOL_RECTANGLE}
    >
      <S.SymbolNameContainer isLabelBiggerThanSymbol={isLabelBiggerThanSymbol}>
        {/* Note the user does not have access permanentArtboardShortId will be undefined
        and the component ArtboardDetailInspectorSymbolLink will handle it by showing a tooltip
        instead of creating a link */}
        <ArtboardDetailInspectorSymbolLink
          permanentArtboardShortId={permanentArtboardShortId}
          symbolMaster={symbolMaster}
          shareIdentifier={shareIdentifier}
        >
          <S.SymbolLabel
            ref={(ref: HTMLDivElement) => {
              // Wait for ref to be ready,
              // We need to calculate the width when the html is ready in the DOM.
              setLabelWidth(ref?.clientWidth ?? 0)
            }}
            $width={symbolLabelMaxWidth}
          >
            <TruncateWithTooltip>{symbolName}</TruncateWithTooltip>
            {/* Don't show the arrow icon if user doesn't have access to the artboard */}
            {Boolean(permanentArtboardShortId) && <S.GoToSymbolIcon />}
          </S.SymbolLabel>
        </ArtboardDetailInspectorSymbolLink>
      </S.SymbolNameContainer>
    </S.StyledLayerOverlay>
  )
}
