import React from 'react'
import styled from 'styled-components'

import {
  LoadingPlaceholder,
  TimeAgo,
  Tooltip,
  timeUnits,
  TableComponents,
  HighlightedText,
  TimeAgoProps,
} from '@sketch/components'
import DocumentCardIcons, {
  DesignSystemForLibraryList,
  LibraryIcon,
  PinnedIcon,
  sharedStatusToIconMap,
  TemplateIcon,
} from '../../../../components/DocumentCard/Icons/DocumentCardIcons'

import DocumentPresenceCreatorAvatars, {
  AvatarItem,
} from '../DocumentPresenceCreatorAvatars'

import { ReactComponent as CloudUpload } from '@sketch/icons/cloud-arrow-up-64'
import { ReactComponent as DocumentProcessing } from '@sketch/icons/document-placeholder-64'
import { ReactComponent as WarningTriangle } from '@sketch/icons/warning-triangle-64'
import { ReactComponent as ImagePlaceholder } from '@sketch/icons/image-64'

import { RenderStatus, useFlag } from '@sketch/modules-common'
import { DocumentSharingStatus } from '../../utils/getDocumentSharingStatus'
import { ShareType } from '../EditInSketchButton'
import { useSearch } from '../../hooks/useSearch'

import {
  ImageWrapper,
  ImageElement,
  GridWrapper,
  TableWrapper,
  Name,
  Image,
  InfoIcon,
  StatusIcon,
  AutosaveWrapper,
  SubtitleTextWrapper,
  TableCell,
  DropdownButtonWrapper,
  NameWrapper,
  TableAvatarNames,
  GridPlaceholderWrapper,
  TablePlaceholderWrapper,
  TopLeftIcons,
  BottomRightIcons,
  IconsWrapper,
} from './DocumentItem.styles'

export interface DocumentItemProps {
  className?: string
  name: string
  renderStatus: RenderStatus
  thumbnailSource?: string
  lastUpdatedDate: string
  isLatestVersionAutosaved?: boolean
  documentSharingStatus: DocumentSharingStatus
  projectName?: string
  collectionName?: string
  deletedAt?: string | null
  isEditing?: boolean
  type?: ShareType
  onClick?: React.MouseEventHandler<HTMLElement>
  avatars: AvatarItem[]
  renderDropdown?: () => React.ReactNode
  designSystems: DesignSystemForLibraryList[]
  draggable?: boolean
  onDragStart?: (event: React.DragEvent<HTMLElement>) => void
  archivedAt?: string | null
  pinned?: string | null
}

export const handleTimeFormat: TimeAgoProps['formatter'] = (value, unit) => {
  if (unit === 'second') return 'Now'

  return `${value}${timeUnits[unit]} ago`
}

export const handleDeletedAtTimeFormat: TimeAgoProps['formatter'] = (
  value,
  unit
) => {
  if (unit === 'second') return '(Deleted now)'

  return `(Deleted ${value}${timeUnits[unit]} ago)`
}

export const handleArchivedAtTimeFormat: TimeAgoProps['formatter'] = (
  value,
  unit
) => {
  if (unit === 'second') return '(Archived now)'

  return `(Archived ${value}${timeUnits[unit]} ago)`
}

export const renderImage = (
  props: Pick<DocumentItemProps, 'name' | 'renderStatus' | 'thumbnailSource'>,
  layout: 'grid' | 'list'
) => {
  const { name, renderStatus, thumbnailSource } = props

  let image
  let tooltipMessage = name

  switch (renderStatus) {
    case 'failed':
      tooltipMessage = `"${name}" - Processing failed.`

      image = (
        <StatusIcon as={WarningTriangle} aria-label="Processing Failed icon" />
      )
      break

    case 'loading':
    case 'uploading':
      tooltipMessage = `"${name}" - Uploading document…`

      image = (
        <StatusIcon as={CloudUpload} aria-label="Uploading to cloud icon" />
      )
      break

    case 'ready': {
      if (!thumbnailSource) {
        image = (
          <StatusIcon as={ImagePlaceholder} aria-label="No Image available" />
        )
      } else {
        image = (
          <Image
            alt={name}
            customImageElement={ImageElement}
            src={thumbnailSource}
            loadingPlaceholder={
              layout === 'grid' ? (
                <LoadingPlaceholder size="64px" />
              ) : (
                <LoadingPlaceholder size="16px" />
              )
            }
          />
        )
      }

      break
    }

    case 'processing':
      tooltipMessage = `"${name}" - Processing document…`

      image = (
        <StatusIcon as={DocumentProcessing} aria-label="Processing icon" />
      )
      break
  }

  return { image, tooltipMessage }
}

const getButtonProps = (
  handleOnClick: (event: React.MouseEvent<HTMLElement>) => void
) => ({
  role: 'button',
  tabIndex: 0,
  onClick: handleOnClick,
  onKeyPress: (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      /* Converge for the same type of event */
      handleOnClick((event as unknown) as React.MouseEvent<HTMLElement>)
    }
  },
})

export const DocumentGridItem = styled(function DocumentGridItem(
  props: DocumentItemProps
) {
  const isDesignSystemsOn = useFlag('design-systems')

  const {
    className,
    name,
    documentSharingStatus,
    projectName,
    collectionName,
    lastUpdatedDate,
    isLatestVersionAutosaved,
    isEditing,
    deletedAt,
    renderDropdown,
    onClick,
    avatars,
    designSystems,
    type,
    archivedAt,
    pinned,
  } = props

  const { image, tooltipMessage } = renderImage(props, 'grid')
  const dropdownContent = renderDropdown?.()
  const a11yProps = onClick ? getButtonProps(onClick) : {}

  const { search } = useSearch()

  const showDesignSystems = !!(isDesignSystemsOn && designSystems?.length)
  const isLibrary = type === 'LIBRARY'
  const isTemplate = type === 'TEMPLATE'
  const hasTopRightIcons = !!pinned

  const sharingStatusIcon = sharedStatusToIconMap[documentSharingStatus]

  const hasBottomRightIcons = isLibrary || isTemplate || !!sharingStatusIcon

  return (
    <GridWrapper className={className} aria-label={name} {...a11yProps}>
      <IconsWrapper>
        {hasTopRightIcons && (
          <TopLeftIcons>
            <PinnedIcon />
          </TopLeftIcons>
        )}
        <ImageWrapper title={tooltipMessage}>{image}</ImageWrapper>
        {hasBottomRightIcons && (
          <BottomRightIcons>
            {sharingStatusIcon}
            {isLibrary && (
              <LibraryIcon
                showDesignSystems={showDesignSystems}
                designSystems={designSystems}
              />
            )}
            {isTemplate && <TemplateIcon />}
          </BottomRightIcons>
        )}
      </IconsWrapper>
      <Name>
        <HighlightedText search={search}>{name}</HighlightedText>
      </Name>
      <SubtitleTextWrapper>
        {projectName && (
          <b className="shrink">
            <HighlightedText search={search}>{projectName}</HighlightedText>
          </b>
        )}
        {collectionName && (
          <b className="shrink">
            <HighlightedText search={search}>{collectionName}</HighlightedText>
          </b>
        )}
        <span className="keep-size">
          {isEditing ? (
            'Active'
          ) : (
            <TimeAgo date={lastUpdatedDate} formatter={handleTimeFormat} />
          )}
        </span>
        {(!isLatestVersionAutosaved ||
          (isLatestVersionAutosaved && isEditing)) && (
          <DocumentPresenceCreatorAvatars
            avatars={avatars}
            isEditing={isEditing || false}
            showTooltipText
          />
        )}
      </SubtitleTextWrapper>

      {deletedAt && (
        <SubtitleTextWrapper>
          <TimeAgo date={deletedAt} formatter={handleDeletedAtTimeFormat} />
        </SubtitleTextWrapper>
      )}

      {archivedAt && !deletedAt && (
        <SubtitleTextWrapper>
          <TimeAgo date={archivedAt} formatter={handleArchivedAtTimeFormat} />
        </SubtitleTextWrapper>
      )}

      {dropdownContent && (
        <DropdownButtonWrapper aria-label="Document Options">
          {dropdownContent}
        </DropdownButtonWrapper>
      )}
    </GridWrapper>
  )
})``

export const DocumentListItem = styled(function DocumentListItem(
  props: DocumentItemProps
) {
  const {
    className,
    name,
    documentSharingStatus,
    projectName,
    collectionName,
    isEditing,
    type,
    lastUpdatedDate,
    isLatestVersionAutosaved,
    deletedAt,
    renderDropdown,
    onClick,
    avatars,
    designSystems,
    draggable = false,
    onDragStart = () => undefined,
    pinned,
  } = props

  const { image, tooltipMessage } = renderImage(props, 'list')
  const dropdownContent = renderDropdown?.()

  const a11yProps = onClick ? getButtonProps(onClick) : {}

  const { search } = useSearch()

  return (
    <TableWrapper
      className={className}
      aria-label={name}
      draggable={draggable}
      onDragStart={onDragStart}
      data-testid="drag-wrapper"
      {...a11yProps}
    >
      <TableCell>
        <NameWrapper data-drag-image>
          <ImageWrapper title={tooltipMessage}>{image}</ImageWrapper>
          <Name>
            <HighlightedText search={search}>{name}</HighlightedText>
            <DocumentCardIcons
              documentSharingStatus={documentSharingStatus}
              designSystems={designSystems}
              type={type}
              pinned={pinned}
            />
          </Name>
        </NameWrapper>
      </TableCell>
      <TableCell className="wrap">
        <span>
          {projectName ? (
            <HighlightedText search={search}>{projectName}</HighlightedText>
          ) : (
            '—'
          )}
        </span>
      </TableCell>
      {deletedAt ? null : (
        <TableCell className="wrap">
          <span>
            {collectionName ? (
              <HighlightedText search={search}>
                {collectionName}
              </HighlightedText>
            ) : (
              '—'
            )}
          </span>
        </TableCell>
      )}
      {deletedAt ? (
        <TableCell>
          <TimeAgo date={deletedAt} formatter={handleTimeFormat} />
        </TableCell>
      ) : (
        <TableCell>
          <span>
            {isEditing ? (
              'Active'
            ) : (
              /* TODO: https://github.com/sketch-hq/Cloud/issues/9044 */
              /* The time format needs to be adjusted to show date in a format "Yesterday at 5:32pm" */
              <TimeAgo date={lastUpdatedDate} formatter={handleTimeFormat} />
            )}
          </span>
        </TableCell>
      )}
      <TableCell>
        {!isEditing && isLatestVersionAutosaved ? (
          <AutosaveWrapper>
            Autosave
            <Tooltip
              placement="bottom"
              content="Autosaved versions include the document's latest changes"
            >
              <InfoIcon role="img" />
            </Tooltip>
          </AutosaveWrapper>
        ) : (
          <NameWrapper type="table">
            <DocumentPresenceCreatorAvatars
              avatars={avatars}
              isEditing={isEditing || false}
              showTooltipText
            />
            <TableAvatarNames>
              <HighlightedText search={search}>
                {avatars.map(({ name }) => name).join(', ')}
              </HighlightedText>
            </TableAvatarNames>
          </NameWrapper>
        )}
      </TableCell>
      <TableComponents.TableCellSticky>
        {dropdownContent}
      </TableComponents.TableCellSticky>
    </TableWrapper>
  )
})``

interface PlaceholderProps {
  inViewListener?: React.ReactNode
}

export const DocumentGridItemPlaceholder: React.FC<PlaceholderProps> = props => {
  return (
    <GridPlaceholderWrapper>
      <ImageWrapper />
      {props.inViewListener}
    </GridPlaceholderWrapper>
  )
}

export const DocumentTableItemPlaceholder: React.FC<PlaceholderProps> = props => (
  <TablePlaceholderWrapper>
    <TableCell>
      <ImageWrapper />
      {props.inViewListener}
    </TableCell>

    {/* Placeholder cells to make sure the table is correctly rendered */}
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
  </TablePlaceholderWrapper>
)
