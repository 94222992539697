import * as Sentry from '@sentry/browser'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { onError } from 'apollo-link-error'
import { getMainDefinition } from 'apollo-utilities'

import { GetInitialUserDocument, GetInitialUserQuery } from '@sketch/gql-types'
import { registrationManager } from 'cloud-frontend'
import { ErrorHandler } from '@sketch/tracing'
import { castError } from '@sketch/utils'
import {
  getUserAuthorization,
  getAllAuthorizations,
} from '@sketch/modules-common'

import { checkIfTokenIsExpired } from '../oauth-fetcher'

const getInitialUser = (
  getClient: () => ApolloClient<NormalizedCacheObject>
): GetInitialUserQuery | null => {
  const client = getClient()
  if (!client) return null
  try {
    const user = client.readQuery<GetInitialUserQuery>({
      query: GetInitialUserDocument,
    })
    if (!user) return null
    return user
  } catch (err) {
    return null
  }
}

const tryParseError = (errorMessage: string) => {
  try {
    return JSON.parse(errorMessage.replace('request: ', ''))
  } catch (err) {
    return null
  }
}

const getAuthorizations = () => {
  try {
    const allAuthorizations = getAllAuthorizations()

    return allAuthorizations.map(x => ({
      type: x.type,
      expirationDateTime: x.fragment.expirationDateTime,
      // do NOT include actual tokens here
    }))
  } catch (err) {
    return null
  }
}

const getActiveTokenExpiration = (
  getClient: () => ApolloClient<NormalizedCacheObject>
) => {
  try {
    const credentials = getUserAuthorization(getClient().cache)
    const isTokenExpired =
      credentials?.expirationDateTime &&
      checkIfTokenIsExpired(credentials.expirationDateTime)
    return {
      isTokenExpired,
      expirationDateTime: credentials?.expirationDateTime,
      // do NOT include actual tokens here
    }
  } catch {
    return null
  }
}

const getClientsInfo = async () => {
  try {
    const swClient = await registrationManager?.getClient()
    const { allClients, currentClient } =
      (await swClient?.getClientsInfo()) || {}
    return { allClients, currentClient }
  } catch (e) {
    const err = castError(e)
    ErrorHandler.ignore(err)
    return { allClients: null, currentClient: null }
  }
}

let isReportedToSentry = false
export const createReportSubscriptionErrorsLink = (
  getClient: () => ApolloClient<NormalizedCacheObject>
) =>
  onError(({ networkError, operation }) => {
    if (!networkError) return
    if (isReportedToSentry) return

    const mainDefinition = getMainDefinition(operation.query)
    if (
      !(
        mainDefinition.kind === 'OperationDefinition' &&
        mainDefinition.operation === 'subscription'
      )
    ) {
      return
    }

    const parsedError = tryParseError(networkError.message)
    if (!parsedError) return

    const code = parsedError?.extensions?.code
    if (code !== 'TOO_MANY_REQUESTS') return

    const user = getInitialUser(getClient)
    if (!user) return

    const isFeatureFlagOn = user.me.featureFlags?.includes(
      'report-subscriptions-to-sentry'
    )
    if (!isFeatureFlagOn) return

    isReportedToSentry = true

    const report = async () => {
      const { allClients, currentClient } = await getClientsInfo()

      const tokens = getAuthorizations()

      const clientsCount = allClients?.length
      const extras = {
        operation: operation.operationName,
        variables: operation.variables,
        error: parsedError,
        clientsCount,
        currentClient,
        allClients,
        token: getActiveTokenExpiration(getClient),
        tokens,
      }

      Sentry.withScope(scope => {
        scope.setExtras(extras)

        if (clientsCount && clientsCount >= 7) {
          networkError.message = `${code} - Error at GraphQL Subscription, many tabs open`
          Sentry.captureException(networkError)
        } else {
          networkError.message = `${code} - GraphQL Subscription, few tabs open`
          Sentry.captureException(networkError)
        }
      })
    }
    report()
  })
