import styled, { css } from 'styled-components'

import { ReactComponent as QuestionMark } from '@sketch/icons/question-mark-16'

type StyledHelpIconProps = {
  $isLink: boolean
}
export const StyledHelpIcon = styled(QuestionMark)<StyledHelpIconProps>`
  width: 16px;
  height: 16px;
  margin-left: 4px;
  vertical-align: sub;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  ${({ $isLink }) =>
    $isLink &&
    css`
      &:hover {
        color: ${({ theme }) => theme.colors.foreground.secondary.A};
      }
    `}
`
