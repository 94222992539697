import ApolloClient from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import {
  Authorization,
  getActiveAuthorization,
  getAllAuthorizations,
  removeAuthorizationByAuthToken,
} from '@sketch/modules-common'

import { refreshToken, checkIfTokenIsExpired } from './refreshToken'
import { addCredentialsToOptions } from './utils'

type FetchType = typeof window.fetch
type AuthorizationType = 'current-only' | 'current-and-secondary'

/**
 * getAuthorizations
 *
 * This function get the stored authorizations validate
 * that the active authorization from apollo is stored and
 * create a sorted authorizations array.
 *
 * If the authorization type is "current-only" it will only
 * send on the array the active authorization, if exists, plus
 * the 4 most recent authorizations on the authorizations array.
 * This could be used given some BE services might not support multiple
 * authorizations
 *
 * The 4 most recent limitation has been added by:
 * https://github.com/sketch-hq/Cloud/issues/15988
 *
 * If the active authorization isn't present, it will send the most
 * recent authorization
 */
const getAuthorizations = (
  client: ApolloClient<NormalizedCacheObject>,
  authorizationType: AuthorizationType
) => {
  const activeAuthorization = getActiveAuthorization(client)
  const activeAuthorizationArray = activeAuthorization
    ? [activeAuthorization]
    : []

  if (authorizationType === 'current-only') {
    return activeAuthorizationArray
  }

  const nonActiveAuthorizations = getAllAuthorizations().filter(
    ({ fragment }) =>
      fragment.authToken !== activeAuthorization?.fragment.authToken
  )

  return [...activeAuthorizationArray, ...nonActiveAuthorizations.splice(0, 4)]
}

/**
 * validateAndRefreshAuthorizations
 *
 * This function will validate if the tokens present on the
 * "authorizations" parameter are valid, and if they aren't
 * attempt to refresh them.
 *
 * If the refresh is successful the tokens will already be updated
 * on the storage and apollo (if the active token is refreshed).
 *
 * In the case where a refresh isn't possible, given that the "expiration"
 * date is evaluated with a margin of 5 minutes FE will allow the token to still
 * exist on the storage. It will only remove it once the exact token validation has
 * passed.
 */
const validateAndRefreshAuthorizations = async (
  client: ApolloClient<NormalizedCacheObject>,
  authorizations: Authorization[]
) => {
  const clientTokenRefresher = async (authorization: Authorization) => {
    const successfulRefresh = await refreshToken(client, authorization)
    const isTokenStillValid =
      new Date(authorization.fragment.expirationDateTime) > new Date()

    return successfulRefresh || isTokenStillValid
  }

  const verifiedAuthorizations = await Promise.all(
    authorizations.map(async authorization => {
      if (checkIfTokenIsExpired(authorization.fragment.expirationDateTime)) {
        return clientTokenRefresher(authorization)
      } else {
        return Promise.resolve(true)
      }
    })
  )

  const [, ...nonActiveAuthorizations] = verifiedAuthorizations

  nonActiveAuthorizations.forEach((successful, index) => {
    if (!successful) {
      /**
       * "index + 1" is because of the activeAuthorization we removed from
       * the "verifiedAuthorizations" otherwise the index would match
       */
      const authorization = authorizations[index + 1]
      removeAuthorizationByAuthToken(client, authorization.fragment.authToken)
    }
  })
}

/**
 * createOAuthFetcher
 *
 * This function mimics the browser fetcher additionally
 * implements the header creation and authorization refresher.
 *
 * The main motivator for this was to make the token refresh logic
 * unaware from the apollo point of view.
 */
const createOAuthFetcher = (
  getClient: () => ApolloClient<NormalizedCacheObject>,
  authorizationType: AuthorizationType = 'current-only'
) => {
  const oauthFetcher: FetchType = async (uri, options) => {
    const client = getClient()

    /**
     * We get all the stored authorization and "save" the "activeAuthorization"
     * this will allow us to use it even if it will be later removed because of
     * a impossible refresh.
     */
    const authorizations = getAuthorizations(client, authorizationType)
    await validateAndRefreshAuthorizations(client, authorizations)

    /**
     * Refetch authorizations to make sure they are updated
     */
    const refreshedAuthorizations = getAuthorizations(client, authorizationType)

    /**
     * Create the header options
     */
    const fetchOptions = addCredentialsToOptions(
      refreshedAuthorizations,
      options
    )

    return fetch(uri, fetchOptions)
  }

  return oauthFetcher
}

export { createOAuthFetcher }
