import { AnnotationDotFragment } from '@sketch/gql-types'
import { AnnotationDotFlavour } from '../types'

export const getAnnotationDotFlavour = (
  annotation?: AnnotationDotFragment
): AnnotationDotFlavour => {
  if (annotation?.resolution) {
    return 'resolved'
  }

  if (annotation?.hasNewComments) {
    return 'unread'
  }

  return 'read'
}
