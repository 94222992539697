import React from 'react'
import { useMapPRMarinaRectToCanvasRelativeRect } from '../../hooks/inspector'

import * as S from './ElementRelativeContainer.styles'
import { SketchElement } from '../../../../inspector'

type ElementRelativeContainerProps = {
  element: SketchElement
  children: React.ReactNode
}

/**
 * Container positioned on top of the element on the canvas. This can be used
 * to position elements relative to the element.
 */
export function ElementRelativeContainer({
  element,
  children,
}: ElementRelativeContainerProps) {
  const elementBounds = element?.prMarinaNode.getAbsoluteBounds()
  const elementRelative = useMapPRMarinaRectToCanvasRelativeRect(elementBounds)

  if (!elementRelative) {
    return null
  }

  return (
    <S.ElementRelativeContainer
      $x={elementRelative.x}
      $y={elementRelative.y}
      $width={elementRelative.width}
      $height={elementRelative.height}
    >
      {children}
    </S.ElementRelativeContainer>
  )
}
