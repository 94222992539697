import styled from 'styled-components'
import { Text } from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'

export const Header = styled.div`
  display: flex;
  margin-bottom: 32px;
  flex-direction: column;

  ${breakpoint('sm')`
    flex-direction: row;
    align-items: center;
  `}
`

export const Title = styled(Text).attrs({
  textStyle: 'header.primary.H',
  $reset: true,
})``

export const TitleSkeleton = styled(Title)`
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  border-radius: ${({ theme }) => theme.radii.xlarge};

  width: 40%;

  ::after {
    /* Have some dummy text to fill the height */
    content: 'a';
    opacity: 0;
  }
`
export const ModifiersWrapper = styled.div`
  ${breakpoint('sm')`
    margin-left: auto;
  `}
`
