import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "arrow-up-right-bottom-left-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { stroke: "currentColor", strokeWidth: 1.5, fill: "none", fillRule: "evenodd", strokeLinecap: "round" },
        React.createElement("path", { strokeLinejoin: "round", d: "M4.25 14.75h5v5" }),
        React.createElement("path", { d: "M9 15l-4.5 4.5" }),
        React.createElement("path", { strokeLinejoin: "round", d: "M19.75 9.25h-5v-5" }),
        React.createElement("path", { d: "M15 9l4.5-4.5" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
