import React from 'react'
import { Tooltip } from '@sketch/components'
import { useParams } from 'react-router'
import { RouteParams } from '@sketch/modules-common'
import { useVersioning } from '../../../versioning'
import * as Styles from './ArtboardLink.styles'
import { usePrototypeState } from '@sketch-hq/sketch-web-renderer'

/**
 * Displays a compact icon link to the Artboard Detail view.
 */
export function ArtboardLink() {
  const { getPathname } = useVersioning()
  const { shareID } = useParams<RouteParams<'PROTOTYPE_PLAYER'>>()
  const { currentArtboardId } = usePrototypeState()

  const to = getPathname({
    routeKey: 'ARTBOARD_DETAIL_UUID',
    routeParams: {
      artboardUUID: currentArtboardId,
      shareID,
    },
  })

  return (
    <Tooltip disableWhenTouchDevice placement="bottom" content="View Artboard">
      <Styles.LinkButton
        data-testid="view-artboard-button"
        to={`${to}#Inspect`}
        aria-label="View Artboard"
      >
        <Styles.Icon />
      </Styles.LinkButton>
    </Tooltip>
  )
}
