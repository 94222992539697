import React, { useEffect, useMemo, useRef } from 'react'
import CSS from 'csstype'

import {
  useInspectorContext,
  useMapPRMarinaRectToCanvasRelativeRect,
} from '../../../hooks/inspector'
import { SketchElement, isArtboardElement } from '../../../../../inspector'
import { ArtboardOverlay } from '../../../../../canvas/components'
import { OVERLAYS_Z_INDEX } from '../constants'
import { GuidelinesPairRect } from './types'

import { ReactComponent as SelectionCornerIconTopLeft } from '@sketch/icons/inspector/top_left'
import { ReactComponent as SelectionCornerIconTopRight } from '@sketch/icons/inspector/top_right'
import { ReactComponent as SelectionCornerIconBottomLeft } from '@sketch/icons/inspector/bottom_left'
import { ReactComponent as SelectionCornerIconBottomRight } from '@sketch/icons/inspector/bottom_right'

import { LayerOverlay as LayerOverlayStyledComponent } from '../ArtboardDetailInspectorOverlay.styles'
import * as S from './SelectedElementOverlays.styles'
import { Rect } from '@sketch-hq/sketch-web-renderer'
import { useStableHandler } from '@sketch/utils'

import {
  useArtboardEvents,
  useHandleArtboardsInteractions,
} from '../../../../../canvas/hooks'

// The corner SVGs are drawn using an outer border which means they are drawn at a 1
// pixel offset.
const CORNER_OFFSET = 1
const SELECTED_LAYER_MIN_SIZE_TO_SHOW_CORNERS = 12
/**
 * Space in pixel between the selection red dashed guidelines and the selected element.
 */
const SELECTION_GUIDELINES_CORNER_OFFSET = 3

type SelectedElementOverlaysProps = {
  selectedElement: SketchElement | null
}
export function SelectedElementOverlays({
  selectedElement,
}: SelectedElementOverlaysProps) {
  const { sketchSceneRootElement } = useInspectorContext()

  // We don't show artboard as selected element.
  if (!selectedElement || !sketchSceneRootElement) {
    return null
  }

  return (
    <>
      {selectedElement.artboardAncestorElement && (
        <SelectionGuidelines selectedElement={selectedElement} />
      )}
      <SelectionRectangle selectedElement={selectedElement} />
    </>
  )
}

type SelectionRectangleProps = {
  selectedElement: SketchElement
}
function SelectionRectangle({ selectedElement }: SelectionRectangleProps) {
  const bounds = selectedElement.prMarinaNode.getAbsoluteLayerBounds()
  const canvasRelativeRect = useMapPRMarinaRectToCanvasRelativeRect(bounds)

  if (!canvasRelativeRect) {
    return null
  }

  const { x, y, width, height } = canvasRelativeRect
  // Don't show the corners if the selected rectangle is very small
  // as the corner won't fit.
  const showMinimalVersion =
    width < SELECTED_LAYER_MIN_SIZE_TO_SHOW_CORNERS ||
    height < SELECTED_LAYER_MIN_SIZE_TO_SHOW_CORNERS

  const artboardOverlay = isArtboardElement(selectedElement) && (
    <ArtboardSelectionRectangle
      artboardName={selectedElement.name}
      artboardUUID={selectedElement.elementUUID}
      bounds={canvasRelativeRect}
    />
  )

  if (showMinimalVersion) {
    return (
      <>
        {artboardOverlay}
        <LayerOverlayStyledComponent
          $borderSize={1}
          $color="red"
          $width={width}
          $height={height}
          $x={x}
          $y={y}
          $zIndex={OVERLAYS_Z_INDEX.SELECTION_RECTANGLE}
        />
      </>
    )
  }

  const cornerStyles: CSS.Properties = {
    position: 'absolute',
    width: `6px`,
    height: `6px`,
  }

  return (
    <>
      {artboardOverlay}
      <S.SelectionRectangleContainer
        $width={width}
        $height={height}
        $x={x}
        $y={y}
      >
        <S.SelectionRectangleTopEdgeLine />
        <S.SelectionRectangleBottomEdgeLine />
        <S.SelectionRectangleLeftEdgeLine />
        <S.SelectionRectangleRightEdgeLine />

        <SelectionCornerIconTopLeft
          style={{
            top: -CORNER_OFFSET,
            left: -CORNER_OFFSET,
            ...cornerStyles,
          }}
        />
        <SelectionCornerIconTopRight
          style={{
            top: -CORNER_OFFSET,
            right: -CORNER_OFFSET,
            ...cornerStyles,
          }}
        />
        <SelectionCornerIconBottomLeft
          style={{
            bottom: -CORNER_OFFSET,
            left: -CORNER_OFFSET,
            ...cornerStyles,
          }}
        />
        <SelectionCornerIconBottomRight
          style={{
            bottom: -CORNER_OFFSET,
            right: -CORNER_OFFSET,
            ...cornerStyles,
          }}
        />
      </S.SelectionRectangleContainer>
    </>
  )
}

type SelectionGuidelinesProps = {
  selectedElement: SketchElement
}

/**
 * Draw the red dashed guidelines on every side of the selected element rectangle.
 *
 * Note: We are drawing the guidelines using 4 rectangles each of them with dashed borders on two sides
 * (left + right or top + bottom). We are using 4 instead of 2 because we need to be careful to not draw any
 * guidelines behind the actual selected element selection rectangle. This is because the selection rectangle
 * lines are not taking the full length of the edges of the rectangle (there is a little transparent
 *  gap between the line and the corner of the rectangle). We don't want the guidelines to show in that little gap.
 */
export function SelectionGuidelines({
  selectedElement,
}: SelectionGuidelinesProps) {
  const selectionGuidelinesData = useSelectionGuidelinePositionData(
    selectedElement
  )

  if (!selectionGuidelinesData) {
    return null
  }

  return (
    <>
      {/* Guidelines at the top of selected element */}
      <S.SelectionVerticalGuidelines
        guidelinesPairRect={selectionGuidelinesData.topGuidelines}
      />
      {/* Guidelines at the bottom of selected element */}
      <S.SelectionVerticalGuidelines
        guidelinesPairRect={selectionGuidelinesData.bottomGuidelines}
      />
      {/* Guidelines on the left of selected element */}
      <S.SelectionHorizontalGuidelines
        guidelinesPairRect={selectionGuidelinesData.leftGuidelines}
      />
      {/* Guidelines on the right of selected element */}
      <S.SelectionHorizontalGuidelines
        guidelinesPairRect={selectionGuidelinesData.rightGuidelines}
      />
    </>
  )
}

/**
 * Calculate the position of the 4 pairs of guidelines.
 * Each pair of guidelines is basically a rectangle with two opposites sides with dashed borders
 * and the other sides with no borders.
 */
function useSelectionGuidelinePositionData(selectedElement: SketchElement) {
  const artboardElementBounds =
    selectedElement.artboardAncestorElement?.prMarinaNode.getAbsoluteLayerBounds() ||
    null

  const selectedElementBounds = selectedElement.prMarinaNode.getAbsoluteLayerBounds()
  const selectedElementCanvasRelativeRect = useMapPRMarinaRectToCanvasRelativeRect(
    selectedElementBounds
  )
  const artboardElementCanvasRelativeRect = useMapPRMarinaRectToCanvasRelativeRect(
    artboardElementBounds
  )

  return useMemo(() => {
    if (
      !selectedElementCanvasRelativeRect ||
      !artboardElementCanvasRelativeRect
    ) {
      return null
    }

    const selectedElementRightEdgeX =
      selectedElementCanvasRelativeRect.x +
      selectedElementCanvasRelativeRect.width
    const selectedElementBottomEdgeY =
      selectedElementCanvasRelativeRect.y +
      selectedElementCanvasRelativeRect.height

    const artboardElementRightEdgeX =
      artboardElementCanvasRelativeRect.x +
      artboardElementCanvasRelativeRect.width
    const artboardElementBottomEdgeY =
      artboardElementCanvasRelativeRect.y +
      artboardElementCanvasRelativeRect.height

    const topGuidelines: GuidelinesPairRect = {
      x: selectedElementCanvasRelativeRect.x,
      y: artboardElementCanvasRelativeRect.y,
      width: selectedElementCanvasRelativeRect.width,
      height:
        selectedElementCanvasRelativeRect.y -
        artboardElementCanvasRelativeRect.y -
        SELECTION_GUIDELINES_CORNER_OFFSET,
    }

    const bottomGuidelinesY =
      selectedElementBottomEdgeY + SELECTION_GUIDELINES_CORNER_OFFSET
    const bottomGuidelines: GuidelinesPairRect = {
      x: selectedElementCanvasRelativeRect.x,
      y: bottomGuidelinesY,
      width: selectedElementCanvasRelativeRect.width,
      height: artboardElementBottomEdgeY - bottomGuidelinesY,
    }

    const leftGuidelines: GuidelinesPairRect = {
      x: artboardElementCanvasRelativeRect.x,
      y: selectedElementCanvasRelativeRect.y,
      width:
        selectedElementCanvasRelativeRect.x -
        artboardElementCanvasRelativeRect.x -
        SELECTION_GUIDELINES_CORNER_OFFSET,
      height: selectedElementCanvasRelativeRect.height,
    }

    const rightGuidelinesX =
      selectedElementRightEdgeX + SELECTION_GUIDELINES_CORNER_OFFSET
    const rightGuidelines: GuidelinesPairRect = {
      x: rightGuidelinesX,
      y: selectedElementCanvasRelativeRect.y,
      width: artboardElementRightEdgeX - rightGuidelinesX,
      height: selectedElementCanvasRelativeRect.height,
    }

    return {
      topGuidelines,
      bottomGuidelines,
      leftGuidelines,
      rightGuidelines,
    }
  }, [selectedElementCanvasRelativeRect, artboardElementCanvasRelativeRect])
}

interface ArtboardSelectionRectangleProps {
  artboardUUID: string
  artboardName: string
  bounds: Rect
}

function ArtboardSelectionRectangle(props: ArtboardSelectionRectangleProps) {
  const { artboardUUID, artboardName, bounds } = props
  const artboardRef = useRef<HTMLDivElement>(null)

  const { onGoToArtboard } = useHandleArtboardsInteractions()
  const { setSelectedElement } = useInspectorContext()

  const artboardEvents = useArtboardEvents((event, eventType) => {
    if (eventType === 'cta-click') {
      onGoToArtboard(artboardUUID, 'CTA')
    }
  })

  const handleWindowKeyPress = useStableHandler((event: KeyboardEvent) => {
    if (
      event.key === 'Enter' &&
      global.document.activeElement === artboardRef.current
    ) {
      onGoToArtboard?.(artboardUUID!, 'keyPressEnter')
    } else if (event.key === 'Escape') {
      setSelectedElement(null)
    }
  })

  useEffect(() => {
    window.document.body.addEventListener('keydown', handleWindowKeyPress)

    return () => {
      window.document.body.removeEventListener('keydown', handleWindowKeyPress)
    }
  }, [handleWindowKeyPress])

  useEffect(() => {
    /**
     * Force focus on the element
     * given it was just mounted and not a the same as the selected state
     */
    artboardRef.current?.focus()
  }, [])

  return (
    <ArtboardOverlay
      artboardName={artboardName}
      artboardUUID={artboardUUID}
      bounds={bounds}
      events={artboardEvents}
      ref={artboardRef}
      selected
    />
  )
}
