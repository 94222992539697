import React, { useState, useEffect } from 'react'
import { castError } from '@sketch/utils'
import { ReactComponent as DocumentIcon } from '@sketch/icons/document-16'
import {
  StyledIconWrapper,
  StyledInput,
  InlineEditorWrapper,
} from '../../../../projects/components/InlineEditor/InlineEditor.styles'
import { Formik, FormikProps, FormikHelpers } from 'formik'
import { ErrorHandler } from '@sketch/tracing'
import { Tooltip, Form } from '@sketch/components'
import * as yup from 'yup'

interface FormValues {
  pageName: string
}

type FormActions = FormikHelpers<FormValues>

export type FormOnSubmit = (
  formValues: FormValues,
  formikActions?: FormActions
) => Promise<any> | undefined

interface InlinePageFormFormProps {
  placeholder?: string
  onSubmit: (v: FormValues) => void
  toggleEditor: () => void
  defaultValue?: string
}

const inlinePageFormSchema = yup.object({
  pageName: yup
    .string()
    .max(100, 'Your project name cannot be longer than 100 characters')
    .trim()
    .required('A project name is required'),
})

export const InlinePageForm = ({
  placeholder = 'Page name',
  onSubmit,
  toggleEditor,
  defaultValue,
}: InlinePageFormFormProps) => {
  const [pageName, setPageName] = useState(defaultValue || '')

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        toggleEditor()
      }
    }

    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [toggleEditor])

  const handleFormikRender = (formikBag: FormikProps<FormValues>) => {
    const { errors, values, handleBlur, handleChange, isSubmitting } = formikBag

    const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (pageName.trim() === '' || pageName === undefined) {
        toggleEditor()
        return
      }

      if (!errors.pageName) {
        onSubmit({ pageName: e.target.value })
      }

      handleBlur(e)
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setPageName(e.target.value)
      handleChange(e)
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        if (pageName === undefined || pageName.trim().length === 0) {
          toggleEditor()
          return
        }

        if (!errors) {
          setPageName('')
          onSubmit({ pageName: pageName })
        }
      }
    }

    return (
      <Form>
        <Form.Field name="pageName">
          <Tooltip
            placement="bottom"
            spacing="8px"
            content={errors?.pageName}
            visible={!!errors?.pageName}
            disabled={!errors.pageName}
          >
            <InlineEditorWrapper aria-label="Inline Editor">
              <StyledIconWrapper>
                <DocumentIcon aria-label="Folder icon" />
              </StyledIconWrapper>
              <StyledInput
                name="pageName"
                type="text"
                placeholder={placeholder}
                value={values.pageName}
                onChange={onChange}
                onKeyPress={handleKeyPress}
                onBlur={onBlur}
                disabled={isSubmitting}
                hasError={errors?.pageName}
                autoFocus
              />
            </InlineEditorWrapper>
          </Tooltip>
        </Form.Field>
      </Form>
    )
  }

  const handleOnSubmit = async (
    values: FormValues,
    formikActions: FormActions
  ) => {
    const trimmedValues = inlinePageFormSchema.cast(values)

    formikActions.setSubmitting(true)

    try {
      // This is already being validated, but since yup.cast can return
      // undefined we need this extra check.
      const { pageName } = trimmedValues
      pageName && (await onSubmit({ pageName }))
    } catch (e) {
      const error = castError(e)
      ErrorHandler.ignore(
        error,
        'Ignore this error the mutation will handle it'
      )
    } finally {
      formikActions.setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={{ pageName: pageName }}
      onSubmit={handleOnSubmit}
      validationSchema={inlinePageFormSchema}
    >
      {handleFormikRender}
    </Formik>
  )
}
