import {
  getIsForInAppPresentation,
  IndexLayout,
  IndexLayoutExtraProps,
} from '@sketch/modules-common'
import React from 'react'

export const withIndexLayout = (
  Component: React.ComponentType<IndexLayoutExtraProps>
) => {
  const IndexLayoutComp = () => {
    const disableLogoLink = getIsForInAppPresentation()

    return (
      <IndexLayout
        disableLogoLink={disableLogoLink}
        render={layoutProps => <Component {...layoutProps} />}
      />
    )
  }

  return IndexLayoutComp
}
