import { useHideCollectionsSetting } from './useHideCollectionsSetting'

// GraphQL
import { useGetProjectCollectionsQuery } from '@sketch/gql-types'

import { ShareSearchFilter } from '../../shares/hooks/useSearchFilters'

interface UseGetProjectCollectionsProps {
  projectIdentifier: string
  filters: ShareSearchFilter[]
  searchTerm?: string
}

export const useGetProjectCollections = ({
  projectIdentifier,
  filters,
  searchTerm,
}: UseGetProjectCollectionsProps) => {
  const { hideCollections } = useHideCollectionsSetting()

  const hasSearchCriteria = !!searchTerm || filters.length > 0
  const skip = hideCollections

  const search = hasSearchCriteria
    ? { name: searchTerm ?? null, filters }
    : undefined

  return useGetProjectCollectionsQuery({
    variables: {
      projectIdentifier,
      search,
      after: null,
    },
    shouldInvalidatePrevious: (prev, curr) =>
      prev?.projectIdentifier !== curr?.projectIdentifier,
    skip,
  })
}
