import React from 'react'

import {
  Button,
  WorkspaceLogo,
  Modal,
  ModalInjectedProps,
} from '@sketch/components'

import {
  Description,
  List,
  ListItem,
  WorkspaceName,
} from './LinkedWorkspacesModal.styles'

import { WorkspaceMinimalIdentityFragment } from '@sketch/gql-types'

interface LinkedWorkspacesModalProps extends ModalInjectedProps {
  userEmail: string
  workspaces: WorkspaceMinimalIdentityFragment[]
}

const LinkedWorkspacesModal: React.FC<LinkedWorkspacesModalProps> = ({
  hideModal,
  userEmail,
  workspaces,
}) => {
  return (
    <Modal title={`${workspaces.length} Workspaces`} onCancel={hideModal}>
      <Modal.Body>
        <Description>{userEmail} has access to:</Description>
        <List>
          {workspaces.map(workspace => (
            <ListItem key={workspace.identifier}>
              <WorkspaceLogo
                key={workspace.identifier}
                size="24px"
                workspaceName={workspace.name}
                src={workspace.avatar?.small}
              />
              <WorkspaceName>{workspace.name}</WorkspaceName>
            </ListItem>
          ))}
        </List>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary-untinted" onClick={hideModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default LinkedWorkspacesModal
