import React, { useCallback, useEffect, useState } from 'react'

import {
  useGetArtboardAtPosition,
  useLockCamera,
  useMapRelativePositionToCameraPosition,
  useStatus,
} from '@sketch-hq/sketch-web-renderer'

import {
  AnnotationOverlayContext,
  DraftCommentProvider,
} from '../../../../annotations/components'
import { MouseCoordinates } from '../../../../annotations/types'
import { useGetAnnotationSettingsQuery } from '@sketch/gql-types'

interface ArtboardAnnotationsOverlayContextProps
  extends Pick<
    React.ComponentProps<typeof AnnotationOverlayContext>,
    'children' | 'disabled' | 'isViewingLatestVersion' | 'hidden'
  > {
  permanentPageId: string
  isFakeArtboard: boolean
}

export const ArtboardAnnotationsOverlayContext = (
  props: ArtboardAnnotationsOverlayContextProps
) => {
  const { permanentPageId, hidden, isFakeArtboard, ...otherProps } = props

  const status = useStatus()
  const lockCamera = useLockCamera()
  const [lockCameraState, setLockCameraState] = useState(false)

  useEffect(() => {
    lockCamera(lockCameraState)
  }, [lockCamera, lockCameraState])

  const getArtboardAtPosition = useGetArtboardAtPosition()
  const mapRelativePosition = useMapRelativePositionToCameraPosition()

  const getElementAtPosition = useCallback(
    async (mouseCoordinates: MouseCoordinates) => {
      const artboard = await getArtboardAtPosition(
        mouseCoordinates.clientX,
        mouseCoordinates.clientY
      )

      if (isFakeArtboard) {
        return {
          type: 'PAGE' as const,
          permanentId: permanentPageId,
        }
      }

      if (artboard) {
        return {
          type: 'ARTBOARD' as const,
          permanentId: artboard.getLayerUUID(),
          bounds: artboard.getAbsoluteExtent(),
          permanentPageId,
        }
      }

      return null
    },
    [getArtboardAtPosition, permanentPageId, isFakeArtboard]
  )

  const getPositionRelativeToOrigin = useCallback(
    (mouseCoordinates: MouseCoordinates) => {
      if (status.type !== 'READY') {
        return null
      }

      return mapRelativePosition(
        mouseCoordinates.clientX,
        mouseCoordinates.clientY
      )
    },
    [mapRelativePosition, status]
  )

  const { data: settings } = useGetAnnotationSettingsQuery()
  const { hideAnnotationsDots } = settings || {}

  return (
    <DraftCommentProvider>
      <AnnotationOverlayContext
        onMovingAnnotation={setLockCameraState}
        getElementAtPosition={getElementAtPosition}
        getPositionRelativeToOrigin={getPositionRelativeToOrigin}
        hidden={hideAnnotationsDots || hidden}
        {...otherProps}
      />
    </DraftCommentProvider>
  )
}
