import debounce from 'debounce'
import * as Sentry from '@sentry/browser'

import type {
  TelemetryConfiguration,
  TelemetryMetric,
} from '@sketch/modules-common'

let config: TelemetryConfiguration | undefined = undefined

// Set telemetry configuration
export const setConfig = (newConfig: TelemetryConfiguration) => {
  config = { ...newConfig }
}

// Send metrics to telemetry backend service
const sendMetrics = async (metrics: TelemetryMetric[]) => {
  if (!config) {
    return
  }

  const data = {
    meta: {
      client: 'webapp' as const,
      version: window.COMMIT_HASH!,
    },
    data: {
      metrics,
    },
  }
  const headers: Record<string, string> = {
    'content-type': 'application/json',
  }
  if (config.authToken) {
    headers.authorization = `bearer ${config.authToken}`
  }

  try {
    const response = await fetch(config.apiHost, {
      method: 'POST',
      mode: 'cors',
      headers,
      body: JSON.stringify(data),
    })

    if (!response.ok) {
      const text = await response.text()
      throw new Error(`${response.status}: ${text}`)
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { data } })
  }
}

// Collect and send metrics. Sending is debounced by 1 second so
// `trackMetric` calls in quick succession are batched together
const buildTrackMetric = () => {
  const metrics: TelemetryMetric[] = []

  const send = debounce(() => {
    sendMetrics(metrics)
    metrics.length = 0
  }, 1000)

  const track = (metric: TelemetryMetric) => {
    metrics.push(metric)
    send()
  }

  return track
}

export const trackMetric = buildTrackMetric()
