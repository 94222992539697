import React from 'react'
import styled from 'styled-components'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import * as yup from 'yup'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import {
  Button,
  Checkbox,
  Link,
  Form,
  Input,
  CreatePasswordInput,
  passwordStrengthValidation,
} from '@sketch/components'

import { validEmail } from '@sketch/utils'

import {
  setUserAuthorization,
  createPersonalAuthorization,
} from '../../libs/authentication'
import {
  useAnalytics,
  SignupBaseProps as SignupAnalyticsEventBaseProps,
} from '../../analytics'

import { useTrackEditedFields } from './useTrackEditedFields'

import {
  SIGN_UP_INITIAL_VALUES,
  TERMS_OF_SERVICE_PRIVACY_POLICY_ERROR,
  Values,
} from './constants'

import { useSignUpMetadata } from './useSignUpMetadata'

import {
  useSignUpMutation,
  GetInitialUserDocument,
  GetInitialUserQuery,
  SignUpMutation,
} from '@sketch/gql-types'
import { DataWithoutUserErrors, ParsedError } from '@sketch/graphql-apollo'

const signUpSchema = yup.object().shape({
  name: yup.string().trim().required('Enter your first name'),
  email: validEmail().required('Enter your email address'),
  password: yup
    .string()
    .min(8, 'Should be 8 or more characters')
    .max(72, 'Should be 72 or less characters')
    .required('Create a new password')
    .test(
      'passwordStrength',
      'The password strength should be at least Okay',
      passwordStrengthValidation
    ),
  'terms-privacy-policy': yup.boolean(),
  'marketing-consent': yup.boolean(),
})

export interface ExtendedFormikProps extends FormikProps<Values> {
  apiError: ParsedError | undefined
  callToAction: string
  formContextForAnalytics: SignupAnalyticsEventBaseProps['formContext']
}

const StyledCheckbox = styled(Checkbox)`
  /*
  Prevent the TOS link from being "blocked" by the input.
  */
  input {
    width: auto;
  }
`

type FieldsProps = ExtendedFormikProps & {
  formContextForAnalytics: SignupAnalyticsEventBaseProps['formContext']
}

export const Fields: React.FC<FieldsProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  formContextForAnalytics,
}) => {
  useTrackEditedFields(values, formContextForAnalytics)
  const { trackEvent } = useAnalytics()

  return (
    <>
      <Form.Field
        name="name"
        label="Your first name"
        errorText={touched.name ? errors.name : undefined}
      >
        <Input
          type="text"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Form.Field>
      <Form.Field
        name="email"
        label="Email"
        errorText={touched.email ? errors.email : undefined}
      >
        <Input
          name="email"
          type="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus={!!values.email}
        />
      </Form.Field>
      <CreatePasswordInput
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        invalid={touched.password && !!errors.password}
      />

      <Form.Field name="terms-privacy-policy" mt={2} mb={3}>
        <StyledCheckbox
          name="terms-privacy-policy"
          variant="untinted"
          checked={Boolean(values['terms-privacy-policy'])}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          I’ve read and agree to the{' '}
          <Link
            variant="primary"
            isUnderlined
            external
            href={`${SKETCH_WEBSITE}/tos`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              trackEvent('SIGN UP - "Terms of Service" link opened', {
                formContext: formContextForAnalytics,
              })
            }}
          >
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link
            variant="primary"
            isUnderlined
            external
            href={`${SKETCH_WEBSITE}/privacy`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              trackEvent('SIGN UP - "Privacy Policy" link opened', {
                formContext: formContextForAnalytics,
              })
            }}
          >
            Privacy Policy
          </Link>
        </StyledCheckbox>
      </Form.Field>
      <Form.Field name="marketing-consent" mb={8}>
        <StyledCheckbox
          name="marketing-consent"
          variant="untinted"
          checked={Boolean(values['marketing-consent'])}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          Get our newsletter with resources, invites to events and more
        </StyledCheckbox>
      </Form.Field>
    </>
  )
}

export const Errors: React.FC<ExtendedFormikProps> = ({ apiError, status }) =>
  status || apiError ? (
    <Form.ErrorField>{status || apiError?.message || ''}</Form.ErrorField>
  ) : null

export const DefaultFormFields: React.FC<ExtendedFormikProps> = formProps => {
  const { trackEvent } = useAnalytics()

  return (
    <>
      <Fields {...formProps} />
      <Errors {...formProps} />
      <Submit
        disabled={!(formProps.isValid && formProps.dirty)}
        callToAction={formProps.callToAction}
        isSubmitting={formProps.isSubmitting}
        onClick={() => {
          const submitCount = formProps.submitCount + 1
          trackEvent('SIGN UP - Sign up form submitted', {
            submitCount: submitCount,
            formContext: formProps.formContextForAnalytics,
          })
        }}
      />
    </>
  )
}
export interface SignUpFormProps {
  email?: string
  /** User has an invitation token */
  invitedUser?: boolean
  children?: (formikProps: ExtendedFormikProps) => React.ReactNode
  onCompleted?: (data: DataWithoutUserErrors<SignUpMutation>) => void
  onError?: (message: string) => void
  callToAction?: string
  formContextForAnalytics: SignupAnalyticsEventBaseProps['formContext']
}

export interface SubmitProps {
  isSubmitting: boolean
  callToAction?: string
  disabled?: boolean
  onClick?: () => void
}

export const Submit: React.FC<SubmitProps> = ({
  isSubmitting,
  disabled,
  onClick,
  callToAction = 'Sign Up',
}) => (
  <Form.Footer>
    <Button
      variant="primary"
      type="submit"
      fill
      size="40"
      disabled={isSubmitting || disabled}
      loading={isSubmitting}
      onClick={onClick}
    >
      {callToAction}
    </Button>
  </Form.Footer>
)

const SignUpForm: React.FC<SignUpFormProps> = ({
  email = '',
  invitedUser,
  onCompleted,
  onError,
  formContextForAnalytics,
  children = DefaultFormFields,
  callToAction = 'Sign Up',
}) => {
  const { metadata, clearMetadata: cleanSignUpMetadata } = useSignUpMetadata()

  const [signUp, { loading, error: apiError }] = useSignUpMutation({
    redirectErrors: true,
    update: (cache, { data }) => {
      if (!data?.signUp) {
        return
      }

      /* Save the user credentials in the apollo cache */
      setUserAuthorization(
        cache,
        createPersonalAuthorization(data.signUp!.credentials!)
      )

      /* Save the user profile information */
      cache.writeQuery<GetInitialUserQuery>({
        query: GetInitialUserDocument,
        data: {
          __typename: 'RootQueryType',
          me: data.signUp.user!,
        },
      })
    },
    onCompleted: data => {
      cleanSignUpMetadata()
      onCompleted?.(data)
    },
    onError: ({ message }) => {
      onError?.(message)
    },
  })

  const handleFormSubmit = async (
    values: Values,
    actions: FormikHelpers<Values>
  ) => {
    try {
      if (!values['terms-privacy-policy']) {
        actions.setStatus(TERMS_OF_SERVICE_PRIVACY_POLICY_ERROR)
        return
      }

      actions.setStatus()

      const {
        'terms-privacy-policy': tosAgreed,
        'marketing-consent': marketingConsent,
        ...otherValues
      } = values

      await signUp({
        variables: {
          input: {
            ...otherValues,

            /**
             * We have unified both values on the UI
             * however BE still receives them distinctly
             *
             * https://github.com/sketch-hq/Cloud/issues/15774
             */
            privacyPolicyAgreed: tosAgreed,
            tosAgreed,

            marketingConsent,
            createWorkspace: !invitedUser,
            metadata,
          },
        },
      })
    } catch (e) {
      // The error handling should be taken care of by Apollo
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          ...SIGN_UP_INITIAL_VALUES,
          email,
        }}
        onSubmit={handleFormSubmit}
        validationSchema={signUpSchema}
        validateOnBlur={false}
        enableReinitialize
      >
        {formikbag => (
          <Form>
            {children({
              ...formikbag,
              isSubmitting: loading,
              apiError,
              callToAction,
              formContextForAnalytics,
            })}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default SignUpForm
