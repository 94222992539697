import styled from 'styled-components'

export const WorkspaceLogoWrapper = styled.div`
  z-index: 3; /* stylelint-disable-line scales/z-indices */
  margin-right: -3px; /* stylelint-disable-line scales/space */
`

export const GuestLogoWrapper = styled.div`
  display: flex;
`

export const PrivacyIconWrapper = styled.div`
  z-index: 4; /* stylelint-disable-line scales/z-indices */
  margin-right: -12px; /* stylelint-disable-line scales/space */
`

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 16px;
`
