import React from 'react'
import { PageLayout, LoadingState } from '@sketch/components'
import { Footer } from '../Footer'
import { EmptyHeader } from '../EmptyHeader'

export const SsoLoadingPage = () => (
  <PageLayout header={<EmptyHeader />} footer={<Footer />}>
    <LoadingState />
  </PageLayout>
)
