import React from 'react'
import Helmet from 'react-helmet'

interface DocumentHeadProps {
  title?: string
}

export const DocumentHead: React.FC<DocumentHeadProps> = ({ title }) => (
  // Social meta tags are handled by the backend, so it shouldn't be necessary
  // to dynamically add or manipulate them here, aside from updating the page title
  <Helmet>{<title>{title ? `Sketch - ${title}` : 'Sketch'}</title>}</Helmet>
)
