import styled, { css } from 'styled-components'

import { ReactComponent as BaseVatCheckmark } from '@sketch/icons/checkmark-circle-filled-12'
import { ReactComponent as BaseVatClock } from '@sketch/icons/clock-circle-filled-12'
import { ReactComponent as BaseVatExclamation } from '@sketch/icons/exclamation-circle-filled-12'

export const VatIconStyle = css`
  width: 12px;
  margin-left: 7px; /* stylelint-disable-line scales/space */
  margin-right: 7px; /* stylelint-disable-line scales/space */
`

export const VatWrapper = styled.div`
  display: flex;
  align-items: center;
`

const VatError = css`
  color: ${({ theme }) => theme.colors.state.negative.A};
`

export const VatCheckmark = styled(BaseVatCheckmark)`
  ${VatIconStyle};
  color: ${({ theme }) => theme.colors.sketch.A};
`

export const VatClock = styled(BaseVatClock)`
  ${VatIconStyle};
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

export const VatExclamation = styled(BaseVatExclamation)`
  ${VatIconStyle};
  ${VatError};
`

export const VatValue = styled.span<{ status: 'UNVERIFIED' | any }>`
  color: ${({ status }) => (status === 'UNVERIFIED' ? '#999999' : 'inherit')};
`
export const VatErrorText = styled.span`
  ${VatError};
`
