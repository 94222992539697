import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "workspaces-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, strokeLinejoin: "round", d: "M12 2.5l8.25 4.71v9.575L12 21.5l-8.25-4.715V7.209z" }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, fillOpacity: 0.15, fill: "currentColor", strokeLinejoin: "round", d: "M12.002 7.1l4.253 2.45v4.9l-4.253 2.45-4.252-2.45v-4.9z" }),
        React.createElement("path", { fillOpacity: 0.15, fill: "currentColor", d: "M7.776 14.651l-3.579 2.21 8.053 4.418 8.053-4.419v-9.5l-3.58 1.989v5.302l-4.473 2.651z" }),
        React.createElement("path", { d: "M12 2.5l.002 4.6M12 16.9v4.6M3.75 7.209l4 2.341m8.505 4.9l3.995 2.335m0-9.576L16.255 9.55m-8.505 4.9l-4 2.335", stroke: "currentColor", strokeWidth: 1.5 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
