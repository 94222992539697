import styled from 'styled-components'

import { Link } from '@sketch/components'

export const Wrapper = styled.div`
  max-width: 400px;
  width: 100%;
`

export const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  margin-top: 24px;
  text-align: center;
`
