import { RevisionChangedProps } from '../../../activity/operations'
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import { versionedRoutes, VersionedRouteParams } from '@sketch/modules-common'
import { useUpgradeToLatestVersion } from '../../../versioning'

export const useOnCurrentRevisionChange = () => {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch<VersionedRouteParams<'ARTBOARD_REVISION'>>({
    path: [
      versionedRoutes.ARTBOARD_REVISION.LATEST.template(),
      versionedRoutes.ARTBOARD_REVISION.VERSION.template(),
    ],
  })

  const { upgradeToLatest } = useUpgradeToLatestVersion()

  const onCurrentRevisionChange = (props: RevisionChangedProps): void => {
    // this hook assumes that it is run only when there is a currently selected revision
    if (!match) return

    switch (props.type) {
      case 'revision-deleted': {
        const { revisionDocShortId, versionShortId } = match.params
        if (!!revisionDocShortId && revisionDocShortId === versionShortId) {
          // if it will end up in this if statement it means that:
          // - there is currently selected revision which was deleted
          // - version of this revision matches version of the whole document
          // - that means, that the whole selected version was deleted
          // in this case, user will be upgraded to the latest version.
          // This will be handled by useVersionDeleted subscription
          return
        }

        return upgradeToLatest('deleted-version')
      }
      case 'revision-moved': {
        const { documentVersionShortId } = props.revision.artboard || {}
        if (!documentVersionShortId) return

        const params: VersionedRouteParams<'ARTBOARD_REVISION'> = {
          ...match.params,
          revisionDocShortId: documentVersionShortId,
        }

        history.replace({
          pathname: match.params.versionShortId
            ? versionedRoutes.ARTBOARD_REVISION.VERSION.create(params)
            : versionedRoutes.ARTBOARD_REVISION.LATEST.create(params),
          search: location.search,
          state: location.state,
        })

        return
      }
      default:
        return
    }
  }

  return { onCurrentRevisionChange }
}
