import {
  useVersionUpdatedSubscription,
  VersionListItemFragment,
  VersionListItemFragmentDoc,
} from '@sketch/gql-types'
import { dataIdFromObject } from '@sketch/graphql-cache'
import { useEventDispatch } from '@sketch/utils'

declare module '@sketch/utils' {
  export interface EventsMap {
    versionIsUpdated: {
      versionIdentifier: string
      documentIdentifier: string
      hasComponentManifest?: boolean
    }
  }
}

/*
 * This subscription is fired in these scenarios:
 * - When a version is starred/unstarred
 * - When the "presentationStatus" is updated (usually by the Render Farm finishing the presentation file processing)
 * - When the components are asynchronously processed
 */
export const useVersionUpdated = () => {
  const onVersionUpdated = useEventDispatch('versionIsUpdated')

  useVersionUpdatedSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      const { data, error } = subscriptionData

      if (error || !data) {
        return
      }

      const version = data.versionUpdated!
      const document = version.document!

      // Emit event to inform about a version update
      onVersionUpdated({
        versionIdentifier: version.identifier,
        documentIdentifier: document.identifier,
        hasComponentManifest: version.hasComponentManifest,
      })

      const cachedVersion = client.readFragment<VersionListItemFragment>({
        fragment: VersionListItemFragmentDoc,
        fragmentName: 'VersionListItem',
        id: dataIdFromObject(data.versionUpdated)!,
      })

      if (!cachedVersion) {
        return
      }

      client.writeFragment({
        fragment: VersionListItemFragmentDoc,
        fragmentName: 'VersionListItem',
        id: dataIdFromObject(data.versionUpdated)!,
        data: data.versionUpdated,
      })
    },
  })
}
