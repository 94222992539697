import styled from 'styled-components'
import { Text } from '../Box'

export const ModalText = styled(Text.Span).attrs({
  textStyle: 'copy.tertiary.standard.D',
})`
  > b {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
  }
`
