import React from 'react'
import styled from 'styled-components'

import { ReactComponent as PeopleIcon } from '@sketch/icons/persons-two-16'
import { ReactComponent as PersonIcon } from '@sketch/icons/person-16'
import { ReactComponent as PlusIcon } from '@sketch/icons/plus-16'
import { ReactComponent as BriefcaseIcon } from '@sketch/icons/briefcase-16'
import { ReactComponent as LockIcon } from '@sketch/icons/lock-16'

import { Wrapper, IconSize, IconWrapper } from './WorkspaceLogo.styles'

const ICON_TO_COMPONENT = {
  person: PersonIcon,
  plus: PlusIcon,
  briefcase: BriefcaseIcon,
  lock: LockIcon,
  people: PeopleIcon,
}

export interface WorkspaceLogoIconProps {
  className?: string
  onClick?: () => void
  size: IconSize
  icon: keyof typeof ICON_TO_COMPONENT
  'data-testid'?: string

  /**
   * @deprecated
   *
   * This Property should be removed from this components
   * Wrappers around it should add the needed margin in order
   * to make the design requirements
   */
  UNSAFE_ADD_MARGIN?: boolean
}

/**
 *  WorkspaceLogoIcon
 *
 * This components has similar representation as the WorkspaceLogo without a src
 * It exists to prevent component duplicates and is currently used in as 2 flavours
 *
 * - A Plus symbol
 * - A Person symbol (Will be deprecated with the Personal Workspace)
 *
 * We should strive to match the layout implemented in https://www.sketch.com/s/5a711544-9ad3-40ed-b98c-a8e656b7ff42
 * and in case we update this we should warn the design team to update it.
 */
const WorkspaceLogoIcon = React.forwardRef<
  HTMLDivElement,
  WorkspaceLogoIconProps
>(function WorkspaceLogoIcon(props, ref) {
  const {
    className,
    size,
    icon,
    'data-testid': dataTestId = 'workspace-logo',
    onClick,
    UNSAFE_ADD_MARGIN,
    ...rest
  } = props
  const Icon = ICON_TO_COMPONENT[icon]

  return (
    <Wrapper
      className={className}
      onClick={onClick}
      data-testid={dataTestId}
      ref={ref}
      $size={size}
      $addRightMargin={UNSAFE_ADD_MARGIN}
      {...rest}
    >
      <IconWrapper $size={size}>
        <Icon />
      </IconWrapper>
    </Wrapper>
  )
})

export default styled(WorkspaceLogoIcon)``
