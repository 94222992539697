import styled from 'styled-components'

export const TrialMessageWrapper = styled.div`
  margin-top: 20px;
  max-width: 300px;
`

export const TrialMessageHeader = styled.b`
  display: block;
  margin-bottom: 2px;
`
