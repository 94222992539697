import React from 'react'

import * as S from './WorkspaceSidebarNoticeContainer.styles'
import { useThemeContext } from '@sketch/global-styles'

type WorkspaceSidebarNoticeContainerProps = React.PropsWithChildren<{
  variant?: S.WorkspaceSidebarNoticeContainerProps['$variant']
}>

/**
 * Generic workspace sidebar notice container displaying the provided children in a
 * kind of always open tooltip like container that can be of grey or black background
 * based on the provided variant. Wrap the content of your notice in this container.
 * It also provides some CSS variables for descendant to use
 * to style the content based on the container variant (black or grey background).
 */
export function WorkspaceSidebarNoticeContainer({
  children,
  variant = 'standard',
}: WorkspaceSidebarNoticeContainerProps) {
  const { isDarkMode } = useThemeContext()

  return (
    <S.WorkspaceSidebarNoticeContainer
      $variant={variant}
      $isDarkMode={isDarkMode}
      data-testid="workspace-notice"
    >
      {children}
    </S.WorkspaceSidebarNoticeContainer>
  )
}
