import styled, { css } from 'styled-components'

import {
  PersonAvatar as BaseAvatar,
  Text,
  Tooltip,
  Truncate,
  truncateStyles,
  Pill,
  PillProps,
} from '@sketch/components'

const Wrapper = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  padding: 12px 8px 12px 16px;
  height: 64px;

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.background.secondary.B};
    border-radius: ${({ theme }) => theme.radii.large};
  }

  &:last-of-type {
    border-bottom: none;
  }
`

const Avatar = styled(BaseAvatar)<{ invitationRequested?: boolean }>`
  display: flex;

  ${({ invitationRequested }) =>
    invitationRequested &&
    css`
      opacity: 50%;
    `}
`

const StyledPill = styled(Pill)`
  margin-left: 8px;
`

const GuestBadge = styled(Pill).attrs<PillProps>({
  variant: 'guest',
})`
  margin-left: 8px;
`

const TooltipWithTruncatedContent = styled(Tooltip)`
  overflow: hidden;
  display: flex;
`

const UserEmail = styled(Text.Div).attrs({
  textStyle: 'copy.quaternary.standard.D',
})`
  ${truncateStyles}
  width: 100%;
`

const StyledTruncate = styled(Truncate)<{ invitationRequested?: boolean }>`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  ${({ invitationRequested }) =>
    invitationRequested &&
    css`
      color: ${({ theme }) => theme.colors.foreground.secondary.D};
    `}
`

const PlaceholderAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.background.tertiary.B};
`

export {
  Wrapper,
  Avatar,
  StyledPill as Pill,
  TooltipWithTruncatedContent,
  GuestBadge,
  UserEmail,
  StyledTruncate,
  PlaceholderAvatar,
}
