import React from 'react'
import { ReactComponent as CommentsIcon } from '@sketch/icons/comments-64'

import InfoMessage from '../InfoMessage'
import AddAnnotationButton from '../AddAnnotationButton'

import { AnnotationStatusFilter } from '../../types'

type AnnotationStatusFilterWithoutUnread = Exclude<
  AnnotationStatusFilter,
  'UNREAD_ONLY'
>

interface CommentTabMessageNoAnnotationsProps {
  action?: React.ReactNode
  showAddCommentButton?: boolean
  annotationStatus?: AnnotationStatusFilterWithoutUnread
}

const emptyAnnotationsTitle: Record<
  AnnotationStatusFilterWithoutUnread,
  string
> = {
  ACTIVE_ONLY: 'No open comments',
  RESOLVED_ONLY: 'No resolved comments',
  ALL: 'No comments yet',
}

const CommentTabMessageNoAnnotations: React.FC<CommentTabMessageNoAnnotationsProps> = ({
  action,
  showAddCommentButton,
  annotationStatus,
}) => {
  return (
    <InfoMessage
      title={emptyAnnotationsTitle[annotationStatus || 'ALL']}
      icon={CommentsIcon}
      action={
        showAddCommentButton && (
          <AddAnnotationButton>Add New Comment</AddAnnotationButton>
        )
      }
    />
  )
}

export default CommentTabMessageNoAnnotations
