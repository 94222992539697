import React from 'react'
import styled from 'styled-components'

import { AnnotationDotFlavour } from '../../types'
import { Dot, CheckMark } from './AnnotationDot.styles'

export interface AnnotationDotProps
  extends React.ComponentPropsWithoutRef<'span'> {
  flavour?: AnnotationDotFlavour
  selected?: boolean
}

const AnnotationDot = (props: AnnotationDotProps) => {
  const { flavour = 'read', selected, children, ...otherProps } = props

  return (
    <Dot
      aria-selected={selected}
      data-testid="annotation-dot"
      $flavour={flavour}
      {...otherProps}
    >
      {flavour === 'resolved' ? <CheckMark /> : children}
    </Dot>
  )
}

export default styled(AnnotationDot)``
