import styled from 'styled-components'

import { Button } from '../Button'
import { Modal } from '../Modal'

export const CancelButton = styled(Button)`
  &&& {
    margin-top: 8px;
  }
`

export const ModalHeader = styled(Modal.Header)`
  padding: 8px 8px 0;
`

export const ModalBody = styled(Modal.Body)`
  &&& {
    margin-top: 0;
    padding: 8px;
    border-bottom: none;
  }

  [data-reach-listbox-popover] {
    /* This forces all that select styles to be ignored */
    display: contents;

    /* 
      When the user closes the modal we want the content to remain visible 
      while the modal unmount transition is running to avoid a UI flicker.
    */
    &[hidden] {
      display: contents;
    }
  }
`

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;

  text-align: right;

  position: sticky;
  bottom: 0;
`
