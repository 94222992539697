import { useState, useMemo, useEffect } from 'react'
import debounce from 'debounce'

/**
 * useDebounceValue
 *
 * This hook queues the change of a value until the last change passes the debounceTime.
 *
 * @param {*} originalValue - Value to be debounced.
 * @param {*} debounceTime - Debounce delay time in milliseconds.
 */
export function useDebounceValue<T>(originalValue: T, debounceTime: number): T {
  const [value, setValue] = useState(originalValue)

  const debounceCall = useMemo(
    () =>
      debounce<(val: T) => void>(newValue => {
        setValue(newValue)
      }, debounceTime),
    [debounceTime]
  )

  useEffect(() => {
    debounceCall(originalValue)

    return () => {
      debounceCall.clear()
    }
  }, [debounceCall, originalValue])

  return value
}
