import styled, { css } from 'styled-components'

import {
  Text,
  PersonAvatar,
  Markdown as BaseMarkdown,
} from '@sketch/components'

import CommentAuthor from '../CommentAuthor'

interface DummyRenderProps {
  children?: (className?: string) => React.ReactElement | null
  className?: string
}
const DummyRender = ({ children, className }: DummyRenderProps) =>
  children?.(className) || null

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 4px;
  padding-right: 0px;

  transition: 100ms padding linear;
  /* Delay it a bit so the options button can disappear softly */
  transition-delay: 100ms;

  ${PersonAvatar} {
    /* Move the avatar up a bit for visual centering purposes */
    transform: translate(2px, -1px);
  }

  ${PersonAvatar}, ${CommentAuthor} {
    margin-right: 8px;
  }
`

export const CommentDetails = styled(Text.Span).attrs({
  textStyle: 'copy.quaternary.standard.C',
})`
  flex-shrink: 0;

  &[data-highlight='true'] {
    color: ${({ theme }) => theme.colors.sketch.A};
  }

  & + & {
    margin-left: 8px;
  }
`

export const CommentOptionsWrapper = styled(DummyRender)`
  position: absolute;
  top: 4px;
  right: 4px;

  opacity: 0;

  &[aria-expanded='true'],
  &:hover {
    opacity: 1;
  }
`

const showOptionsButton = css`
  ${CommentOptionsWrapper} + ${HeaderWrapper} {
    /* Force the transition to occur right from the beginning */
    transition-delay: 0s;
    padding-right: 48px;
  }

  ${CommentOptionsWrapper} {
    opacity: 1;
  }
`

export const Wrapper = styled.div<{ $showPressedState: boolean }>`
  display: block;
  position: relative;

  padding: 11px 12px 12px; /* stylelint-disable-line scales/space */

  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  font-size: 0.8125rem; /* 13px */
  border-radius: ${({ theme }) => theme.radii.large};

  white-space: normal;
  transition: 100ms background-color linear;

  :hover,
  :focus-within {
    background: ${({ theme }) => theme.colors.background.tertiary.C};
  }

  :active {
    ${({ $showPressedState }) =>
      $showPressedState &&
      css`
        background: ${({ theme }) => theme.colors.background.tertiary.B};
      `}
  }

  :focus,
  :focus-within,
  :hover {
    ${showOptionsButton}
  }

  &[data-highlight='true'] {
    background-color: ${({ theme }) => theme.colors.sketch.F};
  }

  ${PersonAvatar} {
    transform: none;
  }

  ${CommentOptionsWrapper}[aria-expanded='true'] + {
    ${showOptionsButton}
  }

  @media (hover: none) {
    ${showOptionsButton}
  }
`

export const Markdown = styled(BaseMarkdown)`
  /* Fix the markdown style that prevent inline items from being overflown */
  && > p:last-child {
    display: inline;
  }
`

export const AuthorName = styled.span`
  /* Limit the name size of the tooltip */
  [data-testid='tooltip'] & {
    display: block;
    max-width: min(300px, 80vw);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
