import styled, { css } from 'styled-components'

import { Navbar } from '@sketch/components'

import { breakpoint } from '@sketch/global-styles'

import { ReactComponent as LogoIcon } from '@sketch/icons/logo-black'

export const Wrapper = styled.div.attrs({ 'data-testid': 'index-layout' })`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
  background: ${({ theme }) => theme.colors.background.secondary.B};
`

export const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const Header = styled(Navbar)(
  ({
    theme: {
      colors,
      transitions: { duration, timing },
    },
  }) => css`
    position: sticky;
    top: 0;
    height: 80px;
    padding: 0 12px;
    z-index: 1;
    border: none;
    background: transparent;

    ${breakpoint('base', 'sm')`
      ::before {
        content: '';
        position: absolute;
        inset: 0;
        backdrop-filter: blur(15px);
        box-shadow: ${colors.headerSticky.shadow};
        background-color: ${colors.headerSticky.background};
        transform: translateY(-185px);
        transition: transform ${duration[2]} ${timing.easeInOut};
        z-index: -1;
      }

      &&[data-scrolltop='false'] {
        ::before {
          transform: translateY(0px);
        }
      }
    `}

    ${breakpoint('sm')`
      padding: 0 40px;
    `}

    ${breakpoint('md')`
      padding: 0 120px;
    `}
  `
)

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 56px;

  ${breakpoint('base', 'sm')`
    border-right: none;
  `}
`

export const FooterWrapper = styled.div`
  footer {
    padding-top: 40px;
  }
`

export const Main = styled.main<{
  isCreatingWorkspace?: boolean
  fullWidth?: boolean
  maxContainerWidth?: string
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  ${breakpoint('base', 'sm')`
    padding: 0 16px;
  `};
`

export const Content = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.background.secondary.B};
    display: flex;
    flex-direction: column;
    flex: 1;

    min-width: 400px;

    /** In mobile, the sidebar disappears so we don't need the margin */
    ${breakpoint('base', 'sm')`
      margin-left: 0;
      width: 100%;
      min-width: auto;
    `};
  `}
`

export const Logo = styled(LogoIcon)`
  width: 32px;
  height: 32px;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`
