import styled, { css } from 'styled-components'

import { breakpoint } from '@sketch/global-styles'

import {
  Button,
  Text,
  WorkspaceLogo as WorkspaceLogoUnstyled,
  Panel,
  FakeLinkButton,
} from '@sketch/components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledPanel = styled(Panel)`
  max-width: 520px;
`

export const Header = styled(Text.Span).attrs({
  textStyle: 'copy.quaternary.standard.D',
})`
  display: inline-block;
  padding-right: 12px;
`

export const WorkspaceLineWrapper = styled.div`
  display: flex;

  :not(:last-child) {
    margin-bottom: 8px;
  }
`

export const LinkedWorkspacesAvatarsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledWorkspaceLogo = styled(WorkspaceLogoUnstyled)`
  margin-right: 12px;
`

export const WorkspaceName = styled.span`
  flex: 1;
`

export const Description = styled(Text).attrs({
  textStyle: 'copy.primary.standard.F',
})`
  margin: 0;
  padding: 0;
  text-align: center;
`

export const StyledPanelFooter = styled(Panel.Footer)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  padding: 0;
  text-align: center;
  background: none;
`

export const linkCss = css`
  display: inline-block;
  padding: 10px 12px;
  text-align: center;
  color: ${({ theme }) => theme.colors.buttons.secondary.color};
`

export const StyledFakeLinkButton = styled(Button)`
  ${linkCss}

  flex: 1;
`

export const StyledButton = styled(Button)`
  flex: 1;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`

export const LineTitle = styled(Text).attrs({
  textStyle: 'copy.tertiary.standard.E',
})`
  display: flex;
  align-items: flex-start;
  margin: 0;
  flex: 2;

  ${breakpoint('base', 'sm')`
    margin-bottom: 8px;
  `}
`
export const LineContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 3;
`

export const Line = styled.div`
  display: flex;
  justify-items: center;
  padding: 12px 16px;
  border-radius: ${({ theme }) => theme.radii.xxlarge};

  :first-child {
    ${LineContent} {
      align-items: center;
    }
  }

  :nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.background.secondary.B};
  }

  ${breakpoint('base', 'sm')`
    flex-direction: column;
  `}
`

export const Name = styled.p`
  margin: 0;
  padding-left: 12px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`

export const StyledPanelBody = styled(Panel.Body)`
  max-width: 448px;
  padding: 0;
`

export const StyledLink = styled(FakeLinkButton)`
  margin-left: 36px;
  font-size: ${({ theme }) => theme.fontSizes.E};
`
