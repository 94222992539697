import React, { useContext } from 'react'

import {
  useRenameProjectMutation,
  GetProjectsDocument,
  GetProjectsQueryVariables,
} from '@sketch/gql-types'

import { ToastContext } from '@sketch/toasts'

import { InlineEditorForm } from './InlineEditorForm'

const typeInline = <T extends any>(variables: T) => variables

interface RenameInlineEditorProps {
  projectName: string
  projectId: string
  workspaceId: string
  placeholder: string
  toggleEditor: () => void
  onCompleted?: () => void
}

interface FormValues {
  projectName: string
}

const RenameInlineEditor = ({
  projectName,
  projectId,
  workspaceId,
  placeholder,
  toggleEditor,
  onCompleted,
}: RenameInlineEditorProps) => {
  const { showToast } = useContext(ToastContext)

  const [renameProject] = useRenameProjectMutation({
    redirectErrors: true,
    onCompleted: () => {
      showToast('Project was renamed successfully')
      toggleEditor()
      onCompleted?.()
    },
    onError: 'show-toast',
    refetchQueries: [
      {
        query: GetProjectsDocument,
        variables: typeInline<GetProjectsQueryVariables>({
          workspaceId,
        }),
      },
    ],
  })

  const onSubmit = ({ projectName: newProjectName }: FormValues) => {
    if (projectName === newProjectName) {
      toggleEditor()
      return
    }

    return renameProject({
      variables: {
        name: newProjectName,
        projectId,
      },
    })
  }

  return (
    <InlineEditorForm
      placeholder={placeholder}
      onSubmit={onSubmit}
      toggleEditor={toggleEditor}
      defaultValue={projectName}
    />
  )
}

export { RenameInlineEditor }
