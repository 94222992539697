import React, { useState } from 'react'
import styled from 'styled-components'

import { Avatar, Spinner, Dropdown, FileSelection } from '@sketch/components'

import { useToast } from '@sketch/toasts'

import { noop, castError } from '@sketch/utils'

import validateImage from './utils'

import {
  useUserAvatarDeleteMutation,
  useUserAvatarCreateMutation,
} from '@sketch/gql-types'

const AvatarWrapper = styled.div`
  position: relative;
  display: inline-block;
`

const Overlay = styled.div<{ active: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.8125rem;
  text-align: center;
  cursor: pointer;

  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: opacity
    ${({ theme }) =>
      `${theme.transitions.duration[2]} ${theme.transitions.timing.easeInOut}`};

  &:hover {
    opacity: 1;
  }
`

export interface AvatarUploadContainerProps {
  userName: string
  userHasAvatar: boolean
  userAvatarSrc: string
}

const AvatarUploadContainer: React.FC<AvatarUploadContainerProps> = props => {
  const { userName, userHasAvatar, userAvatarSrc } = props

  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const { showToast } = useToast()

  const [deleteImage, { loading: deleteLoading }] = useUserAvatarDeleteMutation(
    {
      redirectErrors: true,
      onError: 'show-toast',
    }
  )

  const [uploadImage, { loading: uploadLoading }] = useUserAvatarCreateMutation(
    {
      redirectErrors: true,
      onError: 'show-toast',
    }
  )

  const handleDeleteAvatar = () => {
    deleteImage()
  }

  const handleFileOnChange = async (file: File) => {
    try {
      await validateImage(file)
      /* Mutation will handle the errors from this promise */
      await uploadImage({ variables: { file } }).catch(noop)
    } catch (e) {
      const error = castError(e)
      showToast(error.message, 'negative')
    }
  }

  const isLoading = deleteLoading || uploadLoading

  const avatar = (
    <AvatarWrapper>
      <Avatar
        src={userAvatarSrc}
        name={userName}
        size={'64px'}
        data-testid="user-update-avatar"
      />
      <Overlay active={isDropdownOpen || isLoading}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            Update
            <br />
            Image
          </>
        )}
      </Overlay>
    </AvatarWrapper>
  )

  return (
    <FileSelection onFileSelected={handleFileOnChange} accept="image/*">
      {({ onSelectFile, loading: fileIsLoading }) =>
        userHasAvatar ? (
          <Dropdown
            onToggle={setDropdownOpen}
            toggle={avatar}
            disabled={fileIsLoading || isLoading}
          >
            <Dropdown.Item onClick={onSelectFile}>Upload Image</Dropdown.Item>
            <Dropdown.Item
              data-testid="user-remove-avatar"
              onClick={handleDeleteAvatar}
            >
              Remove Current Image
            </Dropdown.Item>
          </Dropdown>
        ) : (
          <div onClick={onSelectFile}>{avatar}</div>
        )
      }
    </FileSelection>
  )
}

export default AvatarUploadContainer
