import React, { FC, useEffect } from 'react'

interface DoMutationProps {
  mutate: () => void
  children: React.ReactNode
}

export const DoMutation: FC<DoMutationProps> = ({ mutate, children }) => {
  useEffect(() => {
    mutate()
  }, [mutate])

  if (typeof children === 'function') {
    return children()
  } else {
    return children
  }
}
