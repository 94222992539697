import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "comment-exclamation-64", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 64 64", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M23.196 19.501C25.392 12.575 32.53 7.5 41 7.5c10.217 0 18.5 7.387 18.5 16.5 0 5.934-3.513 11.137-8.785 14.045.635 1.874 1.434 3.42 2.398 4.637h0l.219.267.075.101a1 1 0 01-.903 1.543c-3.126-.201-5.886-1.649-8.28-4.343a20.816 20.816 0 01-3.42.25", stroke: "currentColor", strokeWidth: 3, strokeLinejoin: "round" }),
        React.createElement("path", { d: "M41 7.5c10.217 0 18.5 7.387 18.5 16.5 0 5.934-3.513 11.137-8.785 14.045.635 1.874 1.434 3.419 2.397 4.636l.219.268a1 1 0 01-.827 1.644c-3.126-.201-5.886-1.649-8.28-4.343a20.816 20.816 0 01-3.42.25c.453-1.432.696-2.94.696-4.5 0-9.113-8.283-16.5-18.5-16.5l.196.001C25.392 12.575 32.53 7.5 41 7.5z", fillOpacity: 0.2, fill: "currentColor" }),
        React.createElement("path", { d: "M23 19.5c10.217 0 18.5 7.387 18.5 16.5S33.217 52.5 23 52.5c-1.1 0-2.178-.086-3.225-.25-2.393 2.694-5.153 4.142-8.28 4.343a1 1 0 01-.826-1.644l.219-.268c.963-1.217 1.762-2.762 2.397-4.636C8.013 47.137 4.5 41.935 4.5 36c0-9.113 8.283-16.5 18.5-16.5z", stroke: "currentColor", strokeWidth: 3, strokeLinejoin: "round" }),
        React.createElement("g", { transform: "translate(20.5 24)", fill: "currentColor" },
            React.createElement("path", { d: "M3 0a2.339 2.339 0 012.334 2.494L4.6 13.504a1.603 1.603 0 01-3.2 0L.666 2.493A2.339 2.339 0 013 0z" }),
            React.createElement("circle", { cx: 3, cy: 20, r: 3 }))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
