import styled, { css } from 'styled-components'
import { breakpoint } from '@sketch/global-styles'
import { LinkButton } from '../Button'

export const CloseIconContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`

export const NewFeatureHighlightContainerSidebar = styled.div`
  position: absolute;
  right: -8px;
  bottom: -16px;
  left: initial;
  top: initial;

  ${breakpoint('sm')`${css`
    left: -8px;
    top: -8px;
  `}`}
`

export const NewFeatureHighlightContainerHeader = styled.div`
  position: absolute;
  right: -8px;
  bottom: -16px;
  left: initial;
  top: initial;

  ${breakpoint('sm')`${css`
    left: -8px;
    top: -8px;
  `}`}
`

export const StyledLinkButton = styled(LinkButton)`
  ${({ theme, variant }) =>
    variant === 'secondary' &&
    css`
      color: ${theme.colors.sketch.A};
    `}
`

export const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  /* Forcing the size to be 0 allows the element to center on itself */
  width: 0;
  height: 0;
`
