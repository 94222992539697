import React, { FC } from 'react'
import { Redirect, useHistory } from 'react-router'
import { useRouteMatch } from 'react-router-dom'

import { routes } from '@sketch/modules-common'

interface WorkspaceAgnosticRedirectRouteProps {
  workspaceId: string
  fallbackRoute: string
}

type NonAgnosticRoutes =
  | typeof routes.WORKSPACE_SETTINGS
  | typeof routes.WORKSPACE_SETTINGS_PEOPLE
  | typeof routes.WORKSPACE_SETTINGS_SETTINGS
  | typeof routes.WORKSPACE_SETTINGS_BILLING
  | typeof routes.WORKSPACE_SETTINGS_SSO
  | typeof routes.WORKSPACE_DISCOVER

/**
 * Map workspace agnostic routes to workspace routes
 */
const getWorkspaceRoute = (path: string): NonAgnosticRoutes => {
  const routeMap = {
    [routes.WORKSPACE_AGNOSTIC_SETTINGS.template()]: routes.WORKSPACE_SETTINGS,
    [routes.WORKSPACE_AGNOSTIC_SETTINGS_PEOPLE.template()]: routes.WORKSPACE_SETTINGS_PEOPLE,
    [routes.WORKSPACE_AGNOSTIC_SETTINGS_SETTINGS.template()]: routes.WORKSPACE_SETTINGS_SETTINGS,
    [routes.WORKSPACE_AGNOSTIC_SETTINGS_BILLING.template()]: routes.WORKSPACE_SETTINGS_BILLING,
    [routes.WORKSPACE_AGNOSTIC_SETTINGS_SSO.template()]: routes.WORKSPACE_SETTINGS_SSO,
    [routes.WORKSPACE_AGNOSTIC_DISCOVER.template()]: routes.WORKSPACE_DISCOVER,
  }

  return routeMap[path]
}

/**
 * Redirects users trying to access workspace routes without a workspace id.
 */
const WorkspaceAgnosticRedirectRoute: FC<WorkspaceAgnosticRedirectRouteProps> = ({
  workspaceId,
  fallbackRoute,
}) => {
  const { path } = useRouteMatch()
  const history = useHistory()

  const mappedRoute = getWorkspaceRoute(path)
  if (mappedRoute) {
    return (
      <Redirect
        from={history.location.pathname}
        to={mappedRoute.create({ workspaceId })}
      />
    )
  }

  return <Redirect to={fallbackRoute} />
}

export default WorkspaceAgnosticRedirectRoute
