import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { routes, RouteParams } from '@sketch/modules-common'
import { ReactComponent as NotebookIcon } from '@sketch/icons/notebook-16'
import {
  ConfirmationDialog,
  Tooltip,
  UnstyledList,
  useModalContext,
  SelectDropdownLinkItem,
} from '@sketch/components'
import { Page, ComponentPage } from '../Page'
import {
  SectionTitle,
  SectionWrapper,
  AddNewPageLong,
  PlusIcon,
  AddNewButton,
  TooltipCommand,
} from '../EditorSidebar.styles'
import { DesignSystemSection, DesignSystemPage } from '../../../types'
import { useDSDocsEditModeContext } from '../../../operations/DSDocsEditModeContext'

interface SectionProps {
  section: DesignSystemSection
  workspaceId: string
  designSystemId: string
}

export const Section = (props: SectionProps) => {
  const { section, workspaceId, designSystemId } = props

  const history = useHistory()
  const { showModal, hideModal } = useModalContext()
  const { hasUnsavedChanges, discardChanges } = useDSDocsEditModeContext()

  if (section.name === 'Components') {
    return <ComponentsSection {...props} />
  }

  const url = routes.DESIGN_SYSTEM.create({
    workspaceId: workspaceId,
    designSystemId: designSystemId,
  })

  return (
    <SectionWrapper>
      <SectionTitle>{section.name}</SectionTitle>
      <UnstyledList>
        {section.name === 'Overview' && (
          <UnstyledList>
            <li>
              <SelectDropdownLinkItem
                icon={NotebookIcon}
                exact
                text="Introduction"
                onClick={event => {
                  if (hasUnsavedChanges) {
                    event.preventDefault()

                    showModal(ConfirmationDialog, {
                      title: 'Discard changes?',
                      children: (
                        <p>
                          You have unsaved changes on this page. Do you want to
                          discard those changes, or continue editing?
                        </p>
                      ),
                      confirmButton: {
                        text: 'Continue Editing',
                      },
                      cancelButton: {
                        text: 'Discard Changes',
                      },
                      onConfirm: () => {
                        hideModal()
                      },
                      onHide: () => {
                        discardChanges()
                        history.push(url)
                      },
                    })
                  }
                }}
                to={url}
              />
            </li>
          </UnstyledList>
        )}
        {section.pages.map(page => (
          <Page key={page.identifier} {...props} page={page} />
        ))}
      </UnstyledList>
    </SectionWrapper>
  )
}

const ComponentsSection = ({ section, ...props }: SectionProps) => {
  const { isEditMode, setHasUnsavedChanges } = useDSDocsEditModeContext()
  const [newPages, setNewPages] = useState<DesignSystemPage[]>([])
  const { pageId } = useParams<RouteParams<'DESIGN_SYSTEM_PAGES'>>()

  const getNewPage = () => ({ identifier: 'new', name: 'Untitled' })

  const handleAddNewPage = () => {
    setNewPages([...newPages, getNewPage()])
  }

  useEffect(() => {
    // if we land on the URL /page/new, make sure there's an Untitled page to show
    if (pageId === 'new' && newPages.length === 0) {
      setNewPages([getNewPage()])
    }

    const handleKeyPress = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.code === 'KeyN') {
        e.preventDefault()
        handleAddNewPage()
      }
    }

    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasPages = section.pages.length > 0 || (newPages && newPages.length > 0)

  const handleDeletePage = (pageId: string) => {
    // @TODO: update sections with the deleted page (to later save the data)
    const newPagesIndex = newPages?.findIndex(p => p.identifier === pageId)

    if (newPagesIndex === -1) {
      return
    }

    newPages?.splice(newPagesIndex, 1)
    setHasUnsavedChanges(true)
  }

  const handleRenamePage = (pageId: string, newName: string) => {
    // @TODO: update sections with the renamed page (to later save the data)
    const renamedPages = newPages.map(p =>
      p.identifier === pageId ? { ...p, name: newName } : p
    )

    setNewPages(renamedPages)
    setHasUnsavedChanges(true)
  }

  const handleDuplicatePage = (pageId: string) => {
    // @TODO: update sections with the duplicated page (to later save the data)
    const savedPage = section.pages.find(p => p.identifier === pageId)

    if (savedPage) {
      setNewPages([...newPages, { ...savedPage }])
      setHasUnsavedChanges(true)
      return
    }

    const anotherNewPage = newPages.find(p => p.identifier === pageId)
    if (anotherNewPage) {
      setNewPages([...newPages, { ...anotherNewPage }])
      setHasUnsavedChanges(true)
    }
  }

  return (
    <SectionWrapper>
      <SectionTitle>
        Components
        {isEditMode && hasPages && (
          <Tooltip
            placement="right"
            content={
              <>
                <p>New page</p>
                <TooltipCommand>&#8984; N</TooltipCommand>
              </>
            }
          >
            <AddNewButton onClick={handleAddNewPage}>
              <PlusIcon />
            </AddNewButton>
          </Tooltip>
        )}
      </SectionTitle>
      {isEditMode && !hasPages && (
        <AddNewPageLong onClick={handleAddNewPage}>
          <PlusIcon />
          New Page&hellip;
        </AddNewPageLong>
      )}
      <UnstyledList>
        {section.pages.map(page => (
          <Page key={page.identifier} {...props} page={page} />
        ))}
        {newPages?.map((newPage, key) => (
          <ComponentPage
            key={key}
            {...props}
            page={newPage}
            handleDeletePage={handleDeletePage}
            handleRenamePage={handleRenamePage}
            handleDuplicatePage={handleDuplicatePage}
          />
        ))}
      </UnstyledList>
    </SectionWrapper>
  )
}
