import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "my-drafts-prism-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "currentColor", fillRule: "evenodd" },
        React.createElement("path", { d: "M14.25 6.25v-2.5a2 2 0 00-2-2h-8.5a2 2 0 00-2 2v2.5h12.5z", fillOpacity: 0.15 }),
        React.createElement("path", { d: "M15 12.25A2.75 2.75 0 0112.25 15h-8.5A2.75 2.75 0 011 12.25v-8.5A2.75 2.75 0 013.75 1h8.5A2.75 2.75 0 0115 3.75zM13.5 7h-11v5.25c0 .647.492 1.18 1.122 1.244l.128.006h8.5c.69 0 1.25-.56 1.25-1.25V7zm-1.25-4.5h-8.5l-.128.006A1.25 1.25 0 002.5 3.75V5.5h11V3.75c0-.69-.56-1.25-1.25-1.25z", fillRule: "nonzero" }),
        React.createElement("path", { d: "M6 7v1h7.5v1.5H6V11h7.5v1.5H6v1H4.5v-1h-2V11h2V9.5h-2V8h2V7H6z", fillRule: "nonzero", opacity: 0.3 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
