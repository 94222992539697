import { localStorageKeys } from '@sketch/constants'
import debounce from 'debounce'
import { getParsedItem, removeItem, setStringifiedItem } from '@sketch/utils'
import { PartialDevToolsSettings } from './types'

export const readSettings = (): PartialDevToolsSettings => {
  const storedDevToolsState = getParsedItem<boolean | PartialDevToolsSettings>(
    localStorageKeys.devTools
  )

  if (typeof storedDevToolsState === 'boolean') {
    return { general: { isTurnedOn: storedDevToolsState } }
  }

  if (storedDevToolsState?.general?.isTurnedOn === undefined) {
    return { general: { isTurnedOn: false } }
  }

  return storedDevToolsState
}

export const storeSettings = (value: PartialDevToolsSettings | undefined) => {
  if (value === undefined) {
    removeItem(localStorageKeys.devTools)
    return
  }
  setStringifiedItem(localStorageKeys.devTools, value)
}

export const storeSettingsDebounced = debounce(storeSettings, 500, false)
