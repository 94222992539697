import styled from 'styled-components'
import { Caption } from '@sketch/components'

export const StyledCaption = styled(Caption)<{ hasError: boolean }>`
  color: ${({ hasError, theme }) =>
    hasError ? theme.colors.state.negative.A : 'unset'};
`

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`
