import React, { useCallback } from 'react'
import { Dropdown } from '@sketch/components'
import { ExportPrefixSuffixSettings, ExportSettingsItem } from '../types'

import * as S from './PrefixSuffixInput.styles'

type PrefixSuffixInputProps = {
  exportSettingsItems: ExportSettingsItem
  updatePrefixSuffixSettings: (prefixSuffix: ExportPrefixSuffixSettings) => void
}

/**
 * Text input also opening a dropdown to select the type of prefix/suffix
 * to define how we want to use the content of the input.
 */
export function PrefixSuffixInput({
  exportSettingsItems,
  updatePrefixSuffixSettings,
}: PrefixSuffixInputProps) {
  const prefixSuffix = exportSettingsItems.prefixSuffix
  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      updatePrefixSuffixSettings({
        ...prefixSuffix,
        content: e.target.value,
      })
    },
    [prefixSuffix, updatePrefixSuffixSettings]
  )

  return (
    <S.FakeInputContainer>
      <S.UnstyledInput
        type="text"
        value={exportSettingsItems.prefixSuffix.content}
        onChange={handleInputChange}
        placeholder="…"
      />
      <Dropdown
        usePortal={true}
        placement="bottom-start"
        maxWidth={'auto'}
        toggle={visible => (
          <S.StyledDropdownTriggerButton
            variant="ghost"
            size="32"
            aria-expanded={visible}
          >
            {prefixSuffix.type === 'suffix' ? 'Suffix' : 'Prefix'}
            <S.ChevronsIcon />
          </S.StyledDropdownTriggerButton>
        )}
      >
        <SuffixPrefixDropdownItem
          type="primaryPrefix"
          exportSettingsItem={exportSettingsItems}
          updatePrefixSuffixSettings={updatePrefixSuffixSettings}
        />
        <SuffixPrefixDropdownItem
          type="secondaryPrefix"
          exportSettingsItem={exportSettingsItems}
          updatePrefixSuffixSettings={updatePrefixSuffixSettings}
        />
        <SuffixPrefixDropdownItem
          type="suffix"
          exportSettingsItem={exportSettingsItems}
          updatePrefixSuffixSettings={updatePrefixSuffixSettings}
        />
      </Dropdown>
    </S.FakeInputContainer>
  )
}

type SuffixPrefixDropdownItemProps = {
  type: ExportPrefixSuffixSettings['type']
  exportSettingsItem: ExportSettingsItem
  updatePrefixSuffixSettings: (prefixSuffix: ExportPrefixSuffixSettings) => void
}

/**
 * Define how each prefix/suffix should look like in the dropdown menu.
 */
const SUFFIX_PREFIX_OPTIONS_MAP: Record<
  ExportPrefixSuffixSettings['type'],
  {
    label: string
    getExample: (content: string, format: string) => React.ReactNode
  }
> = {
  primaryPrefix: {
    label: 'Primary Prefix',
    getExample: (content: string, format: string) => (
      <>
        <S.PathHighlightedWord>Prefix</S.PathHighlightedWord>
        Your/Layer.{format}
      </>
    ),
  },
  secondaryPrefix: {
    label: 'Secondary Prefix',
    getExample: (content: string, format: string) => (
      <>
        Your/
        <S.PathHighlightedWord>Prefix</S.PathHighlightedWord>
        Layer.
        {format}
      </>
    ),
  },
  suffix: {
    label: 'Suffix',
    getExample: (content: string, format: string) => (
      <>
        Your/Layer
        <S.PathHighlightedWord>Suffix</S.PathHighlightedWord>.{format}
      </>
    ),
  },
}

function SuffixPrefixDropdownItem({
  type,
  exportSettingsItem,
  updatePrefixSuffixSettings,
}: SuffixPrefixDropdownItemProps) {
  const prefixSuffix = exportSettingsItem.prefixSuffix
  const label = SUFFIX_PREFIX_OPTIONS_MAP[type].label
  const examplePath = SUFFIX_PREFIX_OPTIONS_MAP[type].getExample(
    prefixSuffix.content,
    exportSettingsItem.format
  )

  return (
    <Dropdown.Item
      className={prefixSuffix.type === type ? 'active' : undefined}
      onClick={() =>
        updatePrefixSuffixSettings({
          ...prefixSuffix,
          type: type,
        })
      }
    >
      <div>{label}</div>
      <div>{examplePath}</div>
    </Dropdown.Item>
  )
}
