import { Point } from '../../../../../../../inspector'

export interface CopyProps {
  type: string
  radius?: number
  saturation?: number
  motionAngle?: number
  center?: Point
}

const copy = ({ type, radius, saturation, motionAngle, center }: CopyProps) => {
  let sectionCopyValue = `Type: ${type}`
  if (radius) {
    sectionCopyValue += `\nValue: ${radius}`
  }
  if (saturation) {
    sectionCopyValue += `\nSaturation: ${saturation}%`
  }
  if (motionAngle) {
    sectionCopyValue += `\nAngle: ${motionAngle}°`
  }
  if (center && ((center.x && center.y) || center.x === 0 || center.y === 0)) {
    sectionCopyValue += `\nOrigin: (${center.x}, ${center.y})`
  }
  return sectionCopyValue
}

export default copy
