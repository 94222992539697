import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'

import { IgnoreSubscriptionsLink } from './IgnoreSubscriptionsLink'
import { MutationsTracker } from './MutationsTracker'
import { getShouldTrackMutationPredicates } from './shouldTrackMutationPredicates'
import {
  getIsMutationSuccessfulPredicates,
  getSubscriptionsToMutationsMap,
} from './subscriptionsMutationsMap'

export const createIgnoreSubscriptionsLink = (
  getClient: () => ApolloClient<NormalizedCacheObject>
) =>
  new IgnoreSubscriptionsLink({
    tracker: new MutationsTracker(),
    isMutationSuccessful: getIsMutationSuccessfulPredicates,
    shouldTrackMutation: getShouldTrackMutationPredicates(getClient),
    subscriptionsToMutationsMap: getSubscriptionsToMutationsMap(getClient),
  })
