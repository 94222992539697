import React, { useEffect } from 'react'

import { getItem, setItem, parseSafariUserAgent } from '@sketch/utils'

import { localStorageKeys } from '@sketch/constants'

import { useToast } from '@sketch/toasts'
import { CANVAS_VIEW_DOCS } from '../../../modules/shares/PageCanvasView/constants'

const storageKey = localStorageKeys['safariGPUWarning']

/**
 * Shows a warning to users of browsers with a known performance issue that
 * affects the Web Renderer. Warning is shown only once per affected browser
 * version to avoid spamming the user.
 *
 * @see https://bugs.webkit.org/show_bug.cgi?id=227408
 * @see https://bugs.webkit.org/show_bug.cgi?id=231012
 */
export const useSafariGPUWarning = () => {
  const { showToast } = useToast()

  useEffect(() => {
    const safariUA = parseSafariUserAgent()

    if (safariUA === null) return // Not Safari
    if (safariUA.hardware !== 'Macintosh') return // Not Desktop Safari

    const { major, minor, patch } = safariUA.version

    const safariGPUWarning =
      (major === 14 && minor >= 1) || (major === 15 && minor <= 1)

    if (!safariGPUWarning) return // Not affected browser

    const safariVersion = `${major}.${minor}.${patch}`
    const warningSeenForVersion = getItem(storageKey) === safariVersion

    if (warningSeenForVersion) return

    setItem(storageKey, safariVersion)

    showToast(
      <>
        Canvas performance on Safari is degraded by a{' '}
        <a href={CANVAS_VIEW_DOCS}>known issue</a>
      </>,
      'default',
      false
    )
  }, [showToast])
}
