import { Breakpoints } from '@sketch/global-styles'

export type MobileBreakpoint = ExtractSafe<Breakpoints, 'base' | 'xxs' | 'xs'>
export type WideBreakpoint = ExtractSafe<Breakpoints, 'xxl' | 'xxxl'>
export type BreakpointKeys = ExtractSafe<
  Breakpoints,
  'xs' | 'sm' | 'md' | 'lg' | 'xl'
>

// The correct order for the breakpoint array
export const BREAKPOINT_SORT_ORDER = ['xs', 'sm', 'md', 'lg', 'xl']
export const MOBILE_BREAKPOINTS: MobileBreakpoint[] = ['base', 'xxs']
export const WIDE_BREAKPOINTS: WideBreakpoint[] = ['xxl', 'xxxl']

// Makes sure the breakpoint array is ordered correctly
export const sortBreakpointsArray = (a: BreakpointKeys, b: BreakpointKeys) =>
  BREAKPOINT_SORT_ORDER.indexOf(a) - BREAKPOINT_SORT_ORDER.indexOf(b)

// Our theme breakpoints have many values we don't need. This functions clamps them
// 'base', 'xxs' and 'xs' => 'sm'
// 'xxl' and 'xxxl' => 'xl'
export const clampCurrentBreakpoint = (
  currentBreakpoint: Breakpoints
): BreakpointKeys => {
  const isMobileBreakpoint = MOBILE_BREAKPOINTS.some(
    mobleBreakpoint => mobleBreakpoint === currentBreakpoint
  )

  if (isMobileBreakpoint) {
    return 'xs'
  }

  const isWideBreakpoint = WIDE_BREAKPOINTS.some(
    wideBreakpoint => wideBreakpoint === currentBreakpoint
  )

  if (isWideBreakpoint) {
    return 'xl'
  }

  return currentBreakpoint as BreakpointKeys
}
