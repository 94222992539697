import { Editor } from '@tiptap/react'

export const setParagraph = (editor: Editor) =>
  editor.chain().focus().setParagraph().run()

export const setTitle = (editor: Editor) =>
  editor.chain().focus().setHeading({ level: 1 }).run()

export const setHeading = (editor: Editor) =>
  editor.chain().focus().setHeading({ level: 2 }).run()

export const setSubheading = (editor: Editor) =>
  editor.chain().focus().setHeading({ level: 3 }).run()

export const setBulletList = (editor: Editor) =>
  editor.chain().focus().toggleBulletList().run()

export const setNumberedList = (editor: Editor) =>
  editor.chain().focus().toggleOrderedList().run()

export const setBold = (editor: Editor) =>
  editor.chain().focus().toggleBold().run()

export const setItalics = (editor: Editor) =>
  editor.chain().focus().toggleItalic().run()

export const setUnderline = (editor: Editor) =>
  editor.chain().focus().toggleUnderline().run()

export const setDivider = (editor: Editor) =>
  editor.chain().focus().setHorizontalRule().run()

export const setLink = (editor: Editor | null, url: string) => {
  if (!editor) {
    return
  }

  // cancelled
  if (url === null) {
    return
  }

  // empty
  if (url === '') {
    editor?.chain().focus().extendMarkRange('link').unsetLink().run()

    return
  }

  // update link
  editor?.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
}
