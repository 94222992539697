import { WorkspaceMinimalFragment } from '@sketch/gql-types'

/**
 * see https://sketch.slack.com/archives/C1XGDTD0X/p1587644498173300
 */
export const getCanAdminister = (shareRights: {
  userCanUpdateSettings: boolean
}) => shareRights.userCanUpdateSettings

interface GetCanAdministerSharesProps
  extends Pick<WorkspaceMinimalFragment, 'userRole' | 'userCanEdit'> {}

export const getCanAdministerShares = (
  workspace: GetCanAdministerSharesProps
) => workspace.userCanEdit || workspace.userRole === 'ADMIN'
