import React, { ReactNode } from 'react'
import { SidebarLayout, useForTablet } from '@sketch/components'
import { usePrototypeStatus } from '@sketch-hq/sketch-web-renderer'
import { useVersioning } from '../../../versioning'
import ShareWorkspaceSidebar from '../../../shares/components/ShareWorkspaceSidebar'
import { PrototypeEmptyHeader, PrototypeHeader } from '../PrototypeHeader'
import { PrototypeFooter } from '../PrototypeFooter/PrototypeFooter'
import { PrototypeAnnotationsSidebar } from '../PrototypeAnnotationsSidebar'
import { usePrototypeComments } from '../../hooks'
import { useQueryParams } from '@sketch/modules-common'
import { CSSTransition } from 'react-transition-group'

type PrototypeLayoutProps = {
  children: ReactNode
}

/**
 * Top-level global layout for everything shown within the Prototype view.
 */
export function PrototypeLayout(props: PrototypeLayoutProps) {
  const status = usePrototypeStatus()
  const versioning = useVersioning()
  const isTabletAndBigger = useForTablet()
  const isReady = status.type === 'READY'
  const { fullscreen: routerFullScreen } = useQueryParams<'PROTOTYPE_PLAYER'>()
  const hasFullscreenQueryParam = routerFullScreen === 'true'

  const [areCommentVisible] = usePrototypeComments()

  const isCommentSidebarOpened = isTabletAndBigger && areCommentVisible

  /**
   * When the fullscreen query param is present, then we show the player without the header/sidebar/footer
   */
  if (hasFullscreenQueryParam) {
    return (
      <SidebarLayout darkBackground header={null}>
        {props.children}
      </SidebarLayout>
    )
  }

  /**
   * We are using the "CSSTransition" to match the animation from
   * the sidebar and also mount and unmount the "PrototypeCommentSidebar" when its
   * needed
   */
  const sidebarRight = (
    <CSSTransition
      in={isCommentSidebarOpened}
      timeout={300}
      mountOnEnter
      unmountOnExit
    >
      <PrototypeAnnotationsSidebar />
    </CSSTransition>
  )

  return (
    <SidebarLayout
      darkBackground
      sidebarLeft={
        <ShareWorkspaceSidebar
          workspaceIdentifier={versioning.share.workspace.identifier}
        />
      }
      header={headerProps =>
        isReady ? (
          <PrototypeHeader
            setSidebarLeftOpen={headerProps.setSidebarLeftOpen}
            share={versioning.share}
          />
        ) : (
          <PrototypeEmptyHeader
            setSidebarLeftOpen={headerProps.setSidebarLeftOpen}
            share={versioning.share}
          />
        )
      }
      footer={(isReady && !isTabletAndBigger && <PrototypeFooter />) || null}
      sidebarRight={sidebarRight}
      isSidebarRightOpen={isCommentSidebarOpened}
    >
      {props.children}
    </SidebarLayout>
  )
}
