import * as AbsintheSocket from '@lukaskl/absinthe_socket'
import { createAbsintheSocketLink } from '@lukaskl/absinthe_socket-apollo-link'
import { ApolloClient } from 'apollo-client'
import { AuthenticatedSocket } from './socket/AuthenticatedSocket'

export const createAuthenticatedSocketWithLink = (
  socketURI: string | undefined,
  getClient: () => ApolloClient<any>
) => {
  const socket = new AuthenticatedSocket(socketURI, getClient)

  return {
    socket,
    link: createAbsintheSocketLink(AbsintheSocket.create(socket)),
  }
}

export const createAuthenticatedSocket = (
  socketURI: string | undefined,
  getClient: () => ApolloClient<any>
) => {
  return new AuthenticatedSocket(socketURI, getClient)
}
