import React, { useEffect } from 'react'
import styled from 'styled-components'

import {
  getIsForInAppPresentation,
  useQueryParams,
  sourceToSsoSignInSource,
} from '@sketch/modules-common'
import { Spinner, Heading } from '@sketch/components'
import { useToast } from '@sketch/toasts'
import { useGetSsoStartUrl } from '../useGetSsoStartUrl'

const LoadingStateContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  justify-content: center;
  flex-grow: 1;
  align-self: center;

  margin: 16px 0;
`

const StyledSpinner = styled(Spinner)`
  display: flex;
  align-self: center;
`

const Message = styled(Heading.H2)`
  margin: 32px auto;
`

export const RedirectingToIdentityProvider = () => {
  const { showToast } = useToast()
  const { source = 'sketch', team, shortname } = useQueryParams<'SSO_SIGN_IN'>()
  const isForInAppPresentation = getIsForInAppPresentation()

  const { getUrl, response } = useGetSsoStartUrl({
    source: isForInAppPresentation
      ? 'sketch'
      : sourceToSsoSignInSource[source] ?? 'sketch',
    onNetworkError: error => showToast(error, 'negative'),
  })
  const teamName = team || shortname

  useEffect(() => {
    getUrl({ teamName: teamName! })
  }, [getUrl, teamName])

  if (response.url) {
    window.location.href = response.url
  }

  return (
    <LoadingStateContainer>
      <StyledSpinner size="40px" primary />
      <Message>Redirecting to Identity Provider&hellip;</Message>
    </LoadingStateContainer>
  )
}
