import React, {
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  useCallback,
} from 'react'
import styled from 'styled-components'
import debounce from 'debounce'
import type { ZXCVBNResult } from 'zxcvbn'
import { breakpoint } from '@sketch/global-styles'

import { Text } from '../Box'
import { Form } from '../Form'
import { InputProps } from '../Input'
import { PasswordInput } from '../PasswordInput'

import { PasswordCriteria } from './PasswordCriteria'
import { PasswordStrengthMeter } from './PasswordStrengthMeter'
import { loadZxcvbn } from './CreatePasswordInput.utils'

const PasswordTipsArea = styled.div<{ show: boolean }>`
  /*
    Removing this component with JSX is introducing a bug probably related to
    ReactContext. So we're just hidding it with CSS instead of removing.
  */
  display: ${({ show }) => (show ? 'block' : 'none')};
  margin: 16px auto;
  width: auto;
  max-width: 400px;

  ${breakpoint('md')`
    max-width: 480px;
  `}
`

interface CreatePasswordInputProps extends InputProps {
  label?: string
}

export const CreatePasswordInput: React.FC<CreatePasswordInputProps> = ({
  label = 'Create a password',
  onChange,
  value,
  ...props
}: CreatePasswordInputProps) => {
  const [password, setPassword] = useState(value?.toString() || '')
  const [showTips, setShowTips] = useState(false)
  const [strength, setStrength] = useState<ZXCVBNResult>()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('focus', () => setShowTips(true))
    }
  }, [inputRef])

  useEffect(() => {
    async function updateStrength() {
      const zxcvbn = await loadZxcvbn()

      if (!zxcvbn) return

      setStrength(zxcvbn.default(password))
    }
    updateStrength()
  }, [password])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updatePassword = useCallback(
    debounce((password: string) => setPassword(password), 250),
    []
  )

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e)
    updatePassword(e.target.value)
  }
  const attendToLengthCriteria = password.length >= 8
  const attendToStrengthCriteria = strength ? strength.score >= 2 : false

  return (
    <Form.Field label={label} name="password">
      <PasswordInput ref={inputRef} onChange={handleChange} {...props} />
      <PasswordTipsArea show={showTips}>
        <Text.Span mt={2} mb={0} textStyle="copy.quaternary.standard.C">
          Your password must have:
        </Text.Span>
        <PasswordCriteria
          attendToCriteria={attendToLengthCriteria}
          text="8 or more characters"
        />
        <PasswordCriteria
          attendToCriteria={attendToStrengthCriteria}
          text={
            <>
              <Text.Span
                mr={1}
                color={
                  attendToStrengthCriteria ? 'state.positive.A' : 'sketch.C'
                }
              >
                Okay
              </Text.Span>
              or better strength
            </>
          }
        />
        {strength && (
          <PasswordStrengthMeter password={password} strength={strength} />
        )}
      </PasswordTipsArea>
    </Form.Field>
  )
}
