import { ConfirmationDialog, ModalInjectedProps } from '@sketch/components'
import React from 'react'

import { useToast } from '@sketch/toasts'
import { useEmptyWorkspaceTrash } from '../../operations/useEmptyWorkspaceTrash'

interface EmptyTrashModalProps extends ModalInjectedProps {
  workspaceId: string
}

export const EmptyTrashModal: React.FC<EmptyTrashModalProps> = props => {
  const { hideModal, isModalOpen, workspaceId } = props
  const { showToast } = useToast()

  const [emptyTrash, { loading }] = useEmptyWorkspaceTrash({
    onCompleted: () => {
      showToast('Trash emptied')
      hideModal()
    },
    onError: message => showToast(message, 'negative'),
    identifier: workspaceId,
  })

  return (
    <ConfirmationDialog
      title="Empty the Trash?"
      hideModal={hideModal}
      isModalOpen={isModalOpen}
      cancelButton={{
        disabled: loading,
      }}
      confirmButton={{
        text: 'Empty Trash',
        variant: 'negative-secondary',
        loading,
        disabled: loading,
      }}
      onConfirm={() => emptyTrash()}
    >
      <p>
        Do you want to permanently delete all Workspace items in Trash,
        including all their activity and history? You can’t undo this action.
      </p>
    </ConfirmationDialog>
  )
}
