import {
  GetUserCredentialsDocument,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'
import { useEffect, useState } from 'react'
import { useApolloClient } from 'react-apollo'
import { uniqueId } from '@sketch/utils'
import isEqual from 'lodash.isequal'
import { localStorageKeys } from '@sketch/constants'
import {
  getActiveAuthorization,
  getAllAuthorizations,
  isPersonalAuthorization,
  isSsoAuthorization,
} from './multisession'

interface HasAccessToWorkspaceProps
  extends Pick<WorkspaceMinimalFragment, 'identifier' | 'userRole'> {
  customer: { ssoEnabled: boolean } | null
}

const useReactiveAuthorizations = () => {
  const [authorizations, setAuthorizations] = useState(getAllAuthorizations())

  useEffect(() => {
    const onStorageChange = (event: StorageEvent) => {
      if (event.key === localStorageKeys.userAllAuthorizations) {
        setAuthorizations(getAllAuthorizations())
      }
    }

    window.addEventListener('storage', onStorageChange)
    return () => {
      window.removeEventListener('storage', onStorageChange)
    }
  }, [])

  return authorizations
}

/**
 * Returns all authorizations for the user.
 * It also returns some helpful properties in case you don't want to deal with
 * authorizations directly and just want to know what kind of sessions the user
 * has.
 */
export const useUserAuthorizations = () => {
  const authorizations = useReactiveAuthorizations()
  const hasPersonalAuthorization = authorizations.some(isPersonalAuthorization)
  const { cache } = useApolloClient()
  const [activeAuthorization, setActiveAuthorization] = useState(
    getActiveAuthorization(cache)
  )

  const ssoWorkspaceIds = authorizations
    .filter(isSsoAuthorization)
    .map(session => session.workspaceId)

  useEffect(() => {
    return cache.watch({
      query: GetUserCredentialsDocument,
      /**
       * We are including a fake variables object here to enforce cache.watch
       * to notify this subscriber.
       *
       * Apollo's cache will create a key for every watcher for internal
       * storage. For the current version of Apollo (v2.6.2), this key depends
       * on just two things: the query and the variables used. This means that
       * Apollo will only notify one watcher, even though there are more than
       * one as long as the query and the variables are the same.
       *
       * This is what's happening here, the socket.ts subscription is exactly
       * the same than here and, therefore, this callback is never notified.
       * Creating a fake variables object tricks the cache into believing this
       * is a different watcher (which it is!) and fixes the issue.
       *
       * More modern versions of Apollo added the callback function to the
       * watcher key, effectively fixing this issue.
       *
       * See Apollo's commit fixing the issue:
       * https://github.com/apollographql/apollo-client/commit/088d4853371b27af2b0ca34836edaaa0c41b1aff
       */
      variables: { key: uniqueId() },
      optimistic: false,
      callback: event => {
        if (!event.complete) return

        if (
          !isEqual(event.result.userCredentials, activeAuthorization?.fragment)
        ) {
          setActiveAuthorization(getActiveAuthorization(cache))
        }
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAuthorization])

  return {
    authorizations,
    activeAuthorization,
    hasPersonalAuthorization,
    hasAccessToWorkspace: ({
      identifier,
      customer,
      userRole,
    }: HasAccessToWorkspaceProps) =>
      // This is a personal workspace and user has personal auth
      (!customer && hasPersonalAuthorization) ||
      // It's a non-SSO workspace and user has personal auth
      (!customer?.ssoEnabled && hasPersonalAuthorization) ||
      // It's an SSO workspace and the user has an SSO auth for that workspace
      (customer?.ssoEnabled && ssoWorkspaceIds.includes(identifier)) ||
      // User is guest in an SSO workspace, and it has a personal auth
      (customer?.ssoEnabled &&
        userRole === 'GUEST' &&
        hasPersonalAuthorization) ||
      // User is admin of an SSO workspace, and it has a personal auth
      (customer?.ssoEnabled &&
        hasPersonalAuthorization &&
        userRole === 'ADMIN') ||
      // User is partner of an SSO workspace
      (customer?.ssoEnabled &&
        hasPersonalAuthorization &&
        userRole === 'PARTNER'),
  }
}

export type UserAuthorizations = ReturnType<typeof useUserAuthorizations>
