import React from 'react'
import { WebRendererStatus } from '@sketch-hq/sketch-web-renderer'
import { PrototypeLoadStatus } from './PrototypeLoadStatus'
import { PrototypeErrorStatus } from './PrototypeErrorStatus'

type PrototypeRendererStatusProps = {
  status: WebRendererStatus
}

/**
 * Visualizes the status of the Prototype renderer, whether it's loading or
 * in an error state. Gets out of the way and renders null while the renderer
 * is nominal and playing a Prototype.
 */
export function PrototypeRendererStatus(props: PrototypeRendererStatusProps) {
  const { status } = props
  const { type } = status

  switch (type) {
    case 'IDLE':
    case 'INITIALIZING':
    case 'LOADING_FILE':
    case 'FILE_READY':
    case 'DRAWING_FILE':
      return <PrototypeLoadStatus text="Initializing" />
    case 'FAILURE':
      return <PrototypeErrorStatus type={`RENDERER_${status.code}`} />
    case 'WEBGL_CONTEXT_LOST':
      return <PrototypeErrorStatus type="RENDERER_WEBGL_ERROR" />
    default:
      return null
  }
}
