import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import {
  useListboxContext,
  ListboxProps,
  ListboxInput,
  ListboxButton,
  ListboxList,
  ListboxOption,
} from '@reach/listbox'

import {
  NativeSelect,
  NativeSelectWrapper,
  StyledChevronsUpDownIcon,
} from './NativeSelect.styles'
import { ListBoxStyled } from './Select.styles'
import { Breakpoint } from '../Breakpoint'

export type { ListboxOptionProps } from '@reach/listbox'

/**
 * Types
 */
export interface SelectProps extends ListboxProps {
  disabled?: boolean
  label?: React.ReactNode
  /**
   * Provide the list of options for the mobile version of the select (Native select).
   * The <Select> component switches to NativeSelect under the hood on small viewports.
   * Normally Select has a hacky mechanism to build this optionsForNativeSelect list from
   * the children but this does not work for more complex structure (fragments, options with children
   * that are ReactNodes instead of strings...). This prop allows you to overwrite this hacky parsing
   * by providing manually the list of possible options.
   */
  optionsForNativeSelect?: NativeOptionConfig[]
  placeholder?: string
  invalid?: boolean
}

/** Reads the children of this Select component and returns the strings for
 * the <option> tags as well as their onClick action function
 */
export interface NativeOptionConfig {
  value?: string
  name?: string
  action?: () => void
}

/**
 * Helpers
 */
const getOptions = (children: React.ReactNode): NativeOptionConfig[] => {
  const options: NativeOptionConfig[] = []
  React.Children.forEach(children, child => {
    if (
      child &&
      typeof child === 'object' &&
      'props' in child &&
      typeof child?.props?.children === 'string'
    ) {
      options.push({
        value: child.props.value,
        name: child.props.children,
        action: child.props.onClick,
      })
    }
  })

  return options
}

/**
 * Returns a function that gets the currently select `<option>` value
 * This function finds the corresponding `onClick` action function and calls it
 */
const selectNativeOption = (options: NativeOptionConfig[]) => (
  e: React.ChangeEvent<HTMLSelectElement>
) => {
  const value = e?.target.value
  const selectedOption = options.find(opt => opt.value === value)
  selectedOption?.action?.()
}

const KeyboardWrapper: React.FC = ({ children }) => {
  const { highlightedOptionRef } = useListboxContext()
  const [searchTerm, setSearchTerm] = useState<string | ''>('')
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300)

  useEffect(() => {
    if (debouncedSearchTerm) {
      const el = document.querySelector(
        `[data-label^=${debouncedSearchTerm} i]`
      )
      el?.scrollIntoView({ block: 'nearest' })
      setSearchTerm('')
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      const lastFocusedElement = document.activeElement
      const role = lastFocusedElement?.getAttribute('role')
      if (role === 'listbox') {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
          if (highlightedOptionRef.current) {
            highlightedOptionRef.current?.scrollIntoView({ block: 'nearest' })
          }
        } else {
          setSearchTerm(searchTerm + e.key)
        }
      }
    }

    document.body.addEventListener('keydown', onKeyDown)

    return () => document.body.removeEventListener('keydown', onKeyDown)
  }, [highlightedOptionRef, searchTerm])

  return <>{children}</>
}

const Select = ({
  disabled,
  label,
  optionsForNativeSelect,
  placeholder,
  invalid,
  value,
  ...props
}: SelectProps) => (
  <Breakpoint on="sm">
    {notSmall => {
      if (notSmall || disabled) {
        return (
          <ListBoxStyled
            arrow={<StyledChevronsUpDownIcon />}
            disabled={disabled}
            data-invalid={invalid}
            value={value ? value : placeholder}
            {...props}
            data-placeholder={placeholder && !value}
          >
            {placeholder && (
              <ListboxOption
                key={
                  placeholder /* We use the key to force the option to update when the placeholder changes */
                }
                value={placeholder}
                aria-disabled="true"
              >
                {placeholder}
              </ListboxOption>
            )}

            <KeyboardWrapper>{props.children}</KeyboardWrapper>
          </ListBoxStyled>
        )
      } else {
        // Mobile version - uses native html elements so that the mobile OS
        // displays their native selectors
        const options = optionsForNativeSelect || getOptions(props.children)
        const isAnOptionSelected = options.some(
          opt => opt.value === props.defaultValue
        )
        return (
          <NativeSelectWrapper>
            <StyledChevronsUpDownIcon />
            <NativeSelect
              data-invalid={invalid}
              onChange={event => {
                selectNativeOption(options)(event)
                props.onChange?.(event.target.value)
              }}
            >
              {!optionsForNativeSelect &&
                (!isAnOptionSelected || label || placeholder) && (
                  // Show the label instead of the selected option
                  <option value="" disabled={true} selected={true}>
                    {label || placeholder}
                  </option>
                )}
              {options.map(({ value: optionValue, name }) => (
                <option
                  key={optionValue}
                  value={optionValue}
                  selected={value === optionValue}
                >
                  {name}
                </option>
              ))}
            </NativeSelect>
          </NativeSelectWrapper>
        )
      }
    }}
  </Breakpoint>
)

export {
  Select,
  ListboxInput as SelectInput,
  ListboxButton as SelectTrigger,
  ListboxList as SelectList,
  ListboxOption as SelectOption,
}
