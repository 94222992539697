import React from 'react'

import {
  Text,
  pluralize,
  PermissionGroupIcon,
  TruncateWithTooltip,
} from '@sketch/components'
import { WorkspacePermissionGroupFragment } from '@sketch/gql-types'

import { TextWrapper, TitleText, Wrapper } from './PermissionGroupItem.styles'

export interface PermissionGroupItemProps {
  className?: string
  permissionGroup: WorkspacePermissionGroupFragment
}

const PermissionGroupItem = (props: PermissionGroupItemProps) => {
  const { className, permissionGroup } = props

  return (
    <Wrapper className={className} data-testid="permission-group-item">
      <PermissionGroupIcon name={permissionGroup.name} />
      <TextWrapper>
        <Text.H1 textStyle="prominent.secondary.E" $reset>
          <TruncateWithTooltip style={{ overflowWrap: 'break-word' }}>
            <TitleText>{permissionGroup.name}</TitleText>
          </TruncateWithTooltip>
        </Text.H1>
        <Text textStyle="copy.quaternary.standard.D">
          {permissionGroup.members.meta.totalCount}{' '}
          {pluralize(
            'Member',
            'Members',
            permissionGroup.members.meta.totalCount
          )}
        </Text>
      </TextWrapper>
    </Wrapper>
  )
}

export default PermissionGroupItem
