import React from 'react'

import { Navbar } from '@sketch/components'
import { SketchHomeButton } from '../SketchHomeButton'

import { ReactComponent as CrossIcon } from '@sketch/icons/cross-20'

export interface EmptyHeaderProps {
  title?: string
  onClickClose?: () => void
  hideLogo?: boolean
  isDocumentView?: boolean
}

export const EmptyHeader = ({
  onClickClose,
  title,
  hideLogo,
  isDocumentView,
}: EmptyHeaderProps) => {
  return (
    <Navbar isDocumentView={isDocumentView}>
      <Navbar.Section flex={0}>
        {onClickClose && (
          <>
            <Navbar.Button icon={CrossIcon} onClick={onClickClose} />
            <Navbar.Divider />
          </>
        )}
        {!onClickClose && !hideLogo && (
          <>
            <SketchHomeButton />
            <Navbar.Divider />
          </>
        )}
      </Navbar.Section>
      {title && <Navbar.Title>{title}</Navbar.Title>}
    </Navbar>
  )
}
