import React from 'react'

import {
  Caption,
  Panel,
  Text,
  Tooltip,
  useModalContext,
} from '@sketch/components'

import {
  StyledButton,
  PanelHeader,
  PanelBody,
} from './WorkspaceSettingsEnableSsoPanel.styles'

import { ChooseSsoWorkspaceShortNameModal } from '../../modals/ChooseSsoWorkspaceShortNameModal'

interface WorkspaceSettingsEnableSsoPanelProps {
  customerId: string
  showUpsell: boolean
}

type ShortnameButtonProps = {
  onClick: () => void
  showUpsell: boolean
}

const ShortnameButton: React.FC<ShortnameButtonProps> = ({
  onClick,
  showUpsell,
}) => {
  if (showUpsell) {
    return (
      <Tooltip
        placement="bottom"
        content={
          <Text>
            Upgrade to our Business Plan to enable Single Sign-On (SSO)
          </Text>
        }
      >
        <StyledButton variant="primary" onClick={onClick} size="32" disabled>
          Choose a Shortname
        </StyledButton>
      </Tooltip>
    )
  }

  return (
    <StyledButton variant="primary" onClick={onClick} size="32">
      Choose a Shortname
    </StyledButton>
  )
}

export const WorkspaceSettingsEnableSsoPanel: React.FC<WorkspaceSettingsEnableSsoPanelProps> = props => {
  const { customerId, showUpsell } = props

  const { showModal } = useModalContext()

  const onChooseShortNameSelected = () => {
    showModal(ChooseSsoWorkspaceShortNameModal, { customerId })
  }

  return (
    <Panel>
      <PanelHeader>Set Up Single Sign-On (SSO)</PanelHeader>
      <PanelBody>
        <Caption>
          Begin by choosing the SSO shortname that people in your Workspace will
          use to sign in
        </Caption>
        <ShortnameButton
          onClick={onChooseShortNameSelected}
          showUpsell={showUpsell}
        />
      </PanelBody>
    </Panel>
  )
}
