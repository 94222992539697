import React from 'react'
import { Dropdown } from '@sketch/components'

import { useHideAnnotationDots, usePlaceDraftAnnotation } from '../../hooks'
import { useNotificationSubscription } from '../../operations'
import { useGetActiveSidebarTab } from '../../../shares/components/ShareSidebarTabContext'

const HIDE_ANNOTATION_DOTS_COPY = {
  false: 'Hide Comments',
  true: 'Show Comments',
} as const

interface AnnotationDropdownActionsProps {
  shareIdentifier: string
  subscriptionStatus: string
  permanentArtboardId?: string
  permanentArtboardShortId?: string
}

export const AnnotationDropdownActions = (
  props: AnnotationDropdownActionsProps
) => {
  const {
    shareIdentifier,
    permanentArtboardId,
    permanentArtboardShortId,
    subscriptionStatus,
  } = props

  const [placeAnnotation, setPlaceAnnotation] = usePlaceDraftAnnotation() || []
  const [hideAnnotations, setHideAnnotations] = useHideAnnotationDots() || []
  const updateNotificationStatus = useNotificationSubscription()
  const activePanel = useGetActiveSidebarTab()

  // When the Inspect panel is active, we don't want to show some comment
  // related options
  const isInspectPanelActive = activePanel === 'Inspect'

  if (hideAnnotations === undefined) {
    return null
  }

  return (
    <>
      {!isInspectPanelActive && (
        <Dropdown.Item onClick={() => setHideAnnotations?.(!hideAnnotations)}>
          {HIDE_ANNOTATION_DOTS_COPY[`${hideAnnotations}` as const]}
          <Dropdown.Item.ShortcutText>Shift N</Dropdown.Item.ShortcutText>
        </Dropdown.Item>
      )}

      {!isInspectPanelActive && !placeAnnotation && setPlaceAnnotation && (
        <Dropdown.Item
          onClick={event => {
            /**
             * We are stopping the propagation of this event
             * to prevent it from the "DraftAnnotationNotifier" onClick catching it and
             * disabling the place annotation mode right away
             */
            event.stopPropagation()
            setPlaceAnnotation(true)
          }}
        >
          Add a comment
          <Dropdown.Item.ShortcutText>N</Dropdown.Item.ShortcutText>
        </Dropdown.Item>
      )}

      {subscriptionStatus !== 'ON' && (
        <Dropdown.Item
          onClick={() => {
            updateNotificationStatus({
              shareIdentifier,
              permanentArtboardShortId,
              permanentArtboardId,
              state: 'ON',
            })
          }}
        >
          Follow Comments
        </Dropdown.Item>
      )}

      {subscriptionStatus === 'ON' && (
        <Dropdown.Item
          onClick={() => {
            updateNotificationStatus({
              shareIdentifier,
              permanentArtboardShortId,
              permanentArtboardId,
              state: 'OFF',
            })
          }}
        >
          Unfollow Comments
        </Dropdown.Item>
      )}
    </>
  )
}
