import React from 'react'
import { useLocation } from 'react-router'
import { stringifyUrl } from 'query-string'
import copy from 'copy-to-clipboard'

import { Dropdown } from '@sketch/components'
import { useToast } from '@sketch/toasts'
import { useStableHandler } from '@sketch/utils'

interface CopyAnnotationLinkProps {
  annotationIdentifier: string
}

const CopyAnnotationLink = (props: CopyAnnotationLinkProps) => {
  const { annotationIdentifier: annotation } = props

  const location = useLocation()
  const { showToast } = useToast()

  const onClick = useStableHandler(() => {
    const url = stringifyUrl({
      url: `${window.location.origin}${location.pathname}`,
      query: { annotation },
    })

    copy(url)
    showToast('Link copied')
  })

  return <Dropdown.Item onClick={onClick}>Copy Link</Dropdown.Item>
}

export default CopyAnnotationLink
