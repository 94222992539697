import React from 'react'
import { ApolloError } from 'apollo-client'

import { Wrapper, StyledToast } from './InlineSidebarError.styles'

interface InlineSidebarErrorProps {
  error?: ApolloError
}

const InlineSidebarError: React.FC<InlineSidebarErrorProps> = ({ error }) => (
  <Wrapper>
    <StyledToast variant="negative" id="toast-error" hovered={false}>
      {error?.graphQLErrors.map(({ message }) => message).join(', ') ||
        'Something went wrong. Please try again later'}
    </StyledToast>
  </Wrapper>
)

export default InlineSidebarError
