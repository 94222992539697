import { ConfirmationDialog, ModalInjectedProps } from '@sketch/components'
import React from 'react'

import { useShareDeleteWithUi } from './useShareDeleteWithUi'

interface DeleteShareModalProps extends ModalInjectedProps {
  name: string
  shareShortId: string
}

export const DeleteShareModal: React.FC<DeleteShareModalProps> = props => {
  const { name, hideModal, isModalOpen, shareShortId } = props
  const [deleteShare, { loading }] = useShareDeleteWithUi({
    onCompleted: hideModal,
  })

  return (
    <ConfirmationDialog
      title="Move to Trash?"
      hideModal={hideModal}
      isModalOpen={isModalOpen}
      cancelButton={{
        disabled: loading,
      }}
      confirmButton={{
        text: 'Move to Trash',
        variant: 'negative',
        loading,
        disabled: loading,
      }}
      onConfirm={() => deleteShare({ identifier: shareShortId })}
    >
      <>
        <p>
          Move “<strong>{name}”</strong> to Trash?
        </p>
        <p>
          Documents in Trash will be permanently deleted after 90 days.
          Workspace Members with access can still see it in Trash.
        </p>
      </>
    </ConfirmationDialog>
  )
}
