import styled from 'styled-components'

import { ReactComponent as CheckmarkOutline24 } from '@sketch/icons/checkmark-circle-small-outline-24'
import { ReactComponent as CheckmarkFilled24 } from '@sketch/icons/checkmark-circle-small-fill-24'

import { ReactComponent as CheckmarkOutline16 } from '@sketch/icons/checkmark-circle-outline-16'
import { ReactComponent as CheckmarkFilled16 } from '@sketch/icons/checkmark-circle-fill-16'

export const ResolveAnnotationIconMobile = styled(CheckmarkOutline24)`
  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
`

export const UnResolveAnnotationIconMobile = styled(CheckmarkFilled24)`
  color: ${({ theme }) => theme.colors.state.positive.A};
`

export const ResolveAnnotationIconDesktop = styled(CheckmarkOutline16)`
  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
`

export const UnResolveAnnotationIconDesktop = styled(CheckmarkFilled16)`
  color: ${({ theme }) => theme.colors.state.positive.A};
`
