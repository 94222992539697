import React from 'react'
import { BaseListRow } from './BaseListRow'
import { BaseListRowPlaceholderAvatar } from './BaseListRow.styles'
import { BaseListRowPlaceholderProps } from '../types'

export const BaseListRowPlaceholder = ({
  name = 'Placeholder item',
  showStripes = false,
  ...boxProps
}: BaseListRowPlaceholderProps) => (
  <BaseListRow
    name={name}
    avatar={
      <BaseListRowPlaceholderAvatar size="32px" name="" flavour="color" />
    }
    showStripes={showStripes}
    {...boxProps}
  >
    <span className="sr-only">{name}</span>
  </BaseListRow>
)
