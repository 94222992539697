import { v1 as uuid } from 'uuid'
import { LocalResolvers } from '@sketch/gql-types/resolvers'
import { createLocalResolvers as createDiscoverLocalResolvers } from '@sketch/discover'
import { createCommunityLocalResolvers } from 'cloud-frontend'

import { Mutation } from './Mutation'

const baseLocalResolvers: LocalResolvers = {
  Annotation: {
    isOptimistic: () => false,
  },
  Mutation,
  AnnotationCoordinates: {
    identifier: () => uuid(),
  },
}

const resolverCreators = [
  createDiscoverLocalResolvers,
  createCommunityLocalResolvers,
]

/**
 * Enhance the baseLocalResolvers with the resolvers
 * from the other modules.
 */
export const localResolvers = resolverCreators.reduce(
  (previousResolvers, resolver) => resolver(previousResolvers),
  baseLocalResolvers
)
