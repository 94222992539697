import {
  Dropdown as BaseDropdown,
  StatelessDropdown,
  useDropdownState,
  DropdownProps,
} from './Dropdown'
import {
  DropdownItem,
  DropdownItemAnchor,
  DropdownItemNavLink,
  DropdownShortcutText,
  ListItem,
} from './Item'
import { DropdownDivider } from './Divider'
import { DropdownHeader } from './Header'
import { DropdownChevron } from './DropdownChevron'
import { DropdownChevrons } from './DropdownChevrons'
import { DropdownItemDescription } from './DropdownItemDescription'
import { DropdownDownload } from './DropdownDownload'
import { DropdownSkeleton } from './DropdownSkeleton'

export { StatelessDropdown, useDropdownState }
export type { DropdownProps }
export * from './EllipsisDropdownAction'
export { DropdownItem, dropdownItemStyles, DropdownItemStyle } from './Item'
export { dropdownDividerStyles } from './Divider'
export { DropdownHeader } from './Header'
export * from './CardDropdown'

const Item = Object.assign(DropdownItem, {
  Anchor: DropdownItemAnchor,
  NavLink: DropdownItemNavLink,
  ShortcutText: DropdownShortcutText,
  ListItem,
})

export const Dropdown = Object.assign(BaseDropdown, {
  Item,
  ItemDescription: DropdownItemDescription,
  Download: DropdownDownload,
  Divider: DropdownDivider,
  Header: DropdownHeader,
  Chevron: DropdownChevron,
  Chevrons: DropdownChevrons,
  Skeleton: DropdownSkeleton,
})
