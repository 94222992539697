import { ApolloClient } from 'apollo-client'
import { ApolloCache } from 'apollo-cache'
import { ApolloLink } from 'apollo-link'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import {
  createAugmentedCache,
  initializeLocalApolloState,
} from '@sketch/graphql-cache'
import { resolvers } from './resolvers'
import { createReportSubscriptionErrorsLink } from './link/reportSubscriptionErrorsLink'
import { createConnectionLink } from './link/connection'
import { createClientHeaders } from './link/clientHeaders'
import { createIgnoreSubscriptionsLink } from './link/IgnoreSubscriptionsLink'
import { getUsageMetrics } from '@sketch/devtools'
import { isTruthy, isDeployedInProduction } from '@sketch/utils'
import { operationsTimestampLink } from '@sketch/analytics'
import { createPrefetchDropdownOptionsLink } from './link/prefetchDropdownOptions'
import { createSubscriptionsSentryLink } from './link/subscriptionsSentryLink'
import { createDummyDataLink } from './link/dummyDataLink'
import { createAbortRequestController } from './link/abortRequestController'
import { createDeviceIdLink } from './link/deviceIdLink'
import { createHidePaymentsLink } from './link/hideBillingLink'

export type ExtraLinkFn = (
  getClient: () => ApolloClient<NormalizedCacheObject>
) => ApolloLink | undefined

export type ExtraLinksFn = (
  getClient: () => ApolloClient<NormalizedCacheObject>
) => ApolloLink[]

export type CreateApolloClientConfig = {
  cache?: ApolloCache<NormalizedCacheObject>
} & OneOf<{ link?: ExtraLinkFn }, { extraLinks?: ExtraLinksFn }>

export const createApolloClient = (config: CreateApolloClientConfig = {}) => {
  // Store apollo client on a ref and use it as an argument to avoid circular
  // dependencies by importing this file from other locations inside
  // this directory.
  const clientRef: { ref?: ApolloClient<NormalizedCacheObject> } = {
    ref: undefined,
  }
  const getClient = () => {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'test' ||
      process.env.CI === 'true'
    ) {
      if (!clientRef.ref) {
        throw new Error(
          'There is a bug in the code, clientRef.ref should never be undefined at this point. ' +
            'Investigate stack trace to understand where Apollo client is being accessed before it was created'
        )
      }
    }
    return clientRef.ref!
  }

  const usageMetrics = getUsageMetrics()
  const usageMetricsLink =
    usageMetrics.status === 'loaded'
      ? usageMetrics.usageMetrics.apolloLink
      : undefined

  const extraLinks: (
    getClient: () => ApolloClient<NormalizedCacheObject>
  ) => ApolloLink[] = config.extraLinks ? config.extraLinks : () => []

  const {
    link: abortRequestLink,
    reset: resetAbortController,
  } = createAbortRequestController()

  const {
    cache = createAugmentedCache(),
    link = getClient =>
      ApolloLink.from(
        [
          operationsTimestampLink,
          abortRequestLink,
          createClientHeaders(),
          createDeviceIdLink(),
          createPrefetchDropdownOptionsLink(getClient),
          createIgnoreSubscriptionsLink(getClient),
          ...extraLinks(getClient),
          createReportSubscriptionErrorsLink(getClient),
          createSubscriptionsSentryLink(),
          usageMetricsLink,
          createHidePaymentsLink(),
          createDummyDataLink(),
          createConnectionLink(getClient, cache),
        ].filter(isTruthy)
      ),
  }: typeof config = config

  initializeLocalApolloState(cache)

  const client = new ApolloClient({
    connectToDevTools: !isDeployedInProduction(),
    link: link(getClient),
    resolvers,
    cache,
  })

  client.onResetStore(async () => {
    /**
     * After the store is reset
     * we should re-create the abort request controller
     * to allow the app to make requests again
     */
    resetAbortController()
  })

  clientRef.ref = client
  return client
}
