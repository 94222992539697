// MAX_SIZE is used in Workspace avatar upload and profile avatar upload
// Its limited to 10MB in non-prod env and 5MB in production, however
// we decided to limit this in the FE to 2MB
// Issue: https://github.com/sketch-hq/Cloud/issues/7200#issuecomment-851401961
export const MAX_SIZE = 2 * 1024 * 1024
// 8MB for the design system headers
export const MAX_SIZE_DS_HEADER = 8 * 1024 * 1024

const VALID_FORMAT = ['image/jpg', 'image/jpeg', 'image/png']
const MIN_WIDTH = 128
const MIN_HEIGHT = 128

const getImage = (file: File) =>
  new Promise<HTMLImageElement>(resolve => {
    const img = new window.Image()

    img.onload = () => {
      window.URL.revokeObjectURL(img.src)
      resolve(img)
    }

    img.onerror = error => {
      throw error
    }

    img.src = window.URL.createObjectURL(file)
  })

export const validateImage = async (
  file?: File,
  imageType: 'DesignSystemHeader' | 'default' = 'default'
) => {
  const fileSize: Record<typeof imageType, number> = {
    DesignSystemHeader: MAX_SIZE_DS_HEADER,
    default: MAX_SIZE,
  }

  if (
    !file ||
    !VALID_FORMAT.includes(file?.type) ||
    file.size > fileSize[imageType]
  ) {
    throw new Error(
      `Please upload a png or jpg with a maximum size of ${
        fileSize[imageType] / 1024 / 1024
      }MB`
    )
  }

  let image: HTMLImageElement | null = null

  try {
    image = await getImage(file)
  } catch (e) {
    throw new Error('Something went wrong when uploading your avatar.')
  }

  const { width, height } = image
  if (width < MIN_WIDTH || height < MIN_HEIGHT) {
    throw new Error(
      `Please upload a file of at least ${MIN_WIDTH} x ${MIN_HEIGHT} pixels`
    )
  }
}
