import React, { FC } from 'react'
import styled from 'styled-components'

import { Link, showDropdownStyles } from '@sketch/components'

import Artboard from '../Artboard'
import PrototypeCardDropdown from '../PrototypeCardDropdown'

import { ReactComponent as BasePlayIcon } from '@sketch/icons/play-24'
import { LocationDescriptorObject } from 'history'
import { useVersioning } from '../../../versioning'

type PlayButtonSize = 32 | 44

type PlayProps = {
  size: PlayButtonSize
}

const PlayButton = styled.span<PlayProps>`
  position: absolute;
  margin: auto;

  bottom: 10%;
  right: 10%;

  padding: ${({ size }) => (size === 44 ? '9px' : '5px')};
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  background-color: ${({ theme }) => theme.colors.background.secondary.A};
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.colors.shadow.outer};
  border-radius: ${({ size }) => size / 2}px;
  border: 1px solid ${({ theme }) => theme.colors.border.A};

  transition: opacity 200ms linear;

  :hover {
    background-color: ${({ theme }) => theme.colors.background.secondary.B};
  }
  :active {
    background-color: ${({ theme }) => theme.colors.background.secondary.C};
  }
`

const PlayIcon = styled(BasePlayIcon)`
  display: block;
`

const Prototype = styled(Artboard)`
  ${showDropdownStyles};
`

export interface PrototypeCardProps {
  artboardUUID: string
  title: string
  src: string
  showTitle?: boolean
  showDropdown?: boolean
  iconSize?: PlayButtonSize
}

const PrototypeCard: FC<PrototypeCardProps> = ({
  artboardUUID,
  title,
  src,
  showTitle,
  showDropdown = true,
  iconSize = 44,
}) => {
  const { getPathname, share } = useVersioning()

  const pathname: string = getPathname({
    routeKey: 'PROTOTYPE_PLAYER',
    routeParams: {
      shareID: share.identifier,
      prototypeArtboardUUID: artboardUUID,
      currentArtboardUUID: artboardUUID,
    },
  })

  const to: LocationDescriptorObject = { pathname }

  return (
    <Link data-testid="prototype-card-link" to={to}>
      <Prototype
        title={title}
        imageSrc={src}
        showTitle={showTitle}
        overlay={
          <>
            {showDropdown && <PrototypeCardDropdown pathname={pathname} />}
            <PlayButton size={iconSize}>
              <PlayIcon />
            </PlayButton>
          </>
        }
      />
    </Link>
  )
}

export default PrototypeCard
