import React from 'react'

import IntroPopover from './IntroPopover'
import {
  HeaderWrapper,
  HeaderText,
  AddButton,
  StyledAddButton,
} from './DesignSystemsSidebar.styles'

interface HeaderProps {
  /** Handle the onClick event of the AddButton. */
  onAdd?: () => void
  shouldShow: boolean
  canManageDesignSystems: boolean
}

export const SidebarHeader: React.FC<HeaderProps> = ({
  onAdd,
  shouldShow,
  canManageDesignSystems,
}) => {
  if (!onAdd) {
    return (
      <HeaderWrapper>
        <HeaderText>Design Systems</HeaderText>
      </HeaderWrapper>
    )
  }

  return (
    <HeaderWrapper>
      <HeaderText>Design Systems</HeaderText>
      {canManageDesignSystems && (
        <IntroPopover shouldShow={shouldShow} onConfirm={onAdd}>
          <AddButton data-testid="add-design-system" onClick={onAdd}>
            <StyledAddButton aria-label="Create new Design System" />
          </AddButton>
        </IntroPopover>
      )}
    </HeaderWrapper>
  )
}
