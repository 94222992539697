import styled from 'styled-components'

export const StyledCanvasViewWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
`

export const ErrorWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-self: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  z-index: 1;
`
