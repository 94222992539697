import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import {
  AnalyticsEventName,
  AnalyticsEventNameWithProps,
  AnalyticsEventPropsOf,
} from '../../analytics'

import { useTrackEvent } from './useTrackEvent'

export const useTrackEventInView = <TEventName extends AnalyticsEventName>(
  event: TEventName,
  eventProps?: TEventName extends AnalyticsEventNameWithProps
    ? AnalyticsEventPropsOf<TEventName> & { target?: string }
    : never
) => {
  const [wasSeen, setWasSeen] = useState(false)
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const { trackEvent } = useTrackEvent()

  useEffect(() => {
    if (!wasSeen && inView) {
      trackEvent(event, eventProps)
      setWasSeen(true)
    }
  }, [wasSeen, event, eventProps, inView, trackEvent])

  return { ref }
}
