import React from 'react'
import styled, { css } from 'styled-components'

import {
  WorkspaceLogo as BaseWorkspaceLogo,
  LoadingPlaceholder,
  SelectDropdownItem,
} from '@sketch/components'

import { ReactComponent as ExclamationMark } from '@sketch/icons/exclamation-mark-16'
import { ReactComponent as ChevronRight } from '@sketch/icons/chevron-right-16'

export const Chevron = styled(ChevronRight)`
  width: 16px;
  margin: 0 12px 0 auto;
  opacity: 0.55;
`

export const WorkspaceLogo = styled(BaseWorkspaceLogo)`
  margin-right: 12px;
  opacity: 1;
`

export const WorkspacesItem = styled(SelectDropdownItem)`
  /* This is needed to make the dropdown container always visible even when the button is just a since pixel on */
  & ~ [aria-haspopup='true'] {
    position: sticky;
    top: 0;
    bottom: 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

export const AdditionalWrapper = styled.div`
  margin-left: auto;
  margin-right: 12px;
`

const ErrorItem = styled(SelectDropdownItem)`
  color: ${({ theme }) => theme.colors.state.negative.A};
`

const ErrorIcon = styled(ExclamationMark)`
  color: ${({ theme }) => theme.colors.state.negative.A};
  opacity: 1;
`

export function LoadingState() {
  return (
    <SelectDropdownItem
      text="Loading projects…"
      icon={({ className }) => (
        <LoadingPlaceholder className={className} size="16px" />
      )}
      disabled
    />
  )
}

export function ErrorState() {
  return (
    <ErrorItem
      text="Unable to load"
      icon={({ className }) => <ErrorIcon className={className} />}
      disabled
    />
  )
}
