interface PositionAndSizeProps {
  rotate?: string
  x: string
  y: string
  width: string
  height: string
}

const copy = ({ rotate, x, y, width, height }: PositionAndSizeProps) => {
  let sectionCopyValue = `X: ${x}\nY: ${y}\nRotate: ${rotate}\n\n`
  sectionCopyValue += `Width: ${width}\nHeight: ${height}`
  return sectionCopyValue
}

export default copy
