import React, { FC, useEffect, useState } from 'react'

import { MAX_SEATS_VALUE } from '../../constants'

import { Stepper } from '@sketch/components'

import { Wrapper, StepperContainer, Text, HelpText } from './AddEditors.styles'

const MIN_SEATS_VALUE = 1

interface AddEditorsProps {
  description?: string
  helpText?: string
  seats: number
  onChange: (value: number) => void
}

const AddEditors: FC<AddEditorsProps> = ({
  description = 'Bring more Editors into the Workspace',
  helpText,
  seats,
  onChange,
}) => {
  const [newSeats, setNewSeats] = useState(seats || MIN_SEATS_VALUE)

  useEffect(() => {
    setNewSeats(seats)
  }, [seats])

  const handleChange = (value: number) => [setNewSeats(value), onChange(value)]

  return (
    <Wrapper>
      <StepperContainer>
        <Text>{description}</Text>
        <Stepper
          value={newSeats}
          onChange={handleChange}
          min={MIN_SEATS_VALUE}
          max={MAX_SEATS_VALUE}
        />
      </StepperContainer>
      {helpText && <HelpText>{helpText}</HelpText>}
    </Wrapper>
  )
}

export default AddEditors
