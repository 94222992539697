import styled from 'styled-components'

import { breakpoint } from '@sketch/global-styles'

export const CanvasContainer = styled.div`
  position: relative;
  height: 600px;
  border-radius: ${({ theme }) => theme.radii.xxlarge};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.border.B};
  overflow: hidden;

  ${breakpoint('base', 'sm')`
    margin: 0 -24px;
    border-radius: 0;
  `}
`
