import styled, { css } from 'styled-components'

import { ButtonUnstyled, tabStyle } from '@sketch/components'
import { GroupLink } from '../Grid/GroupName'

import arrowDownIcon from '@sketch/icons/chevron-vertical-12.svg'

import { breakpoint } from '@sketch/global-styles'

export const LinkStyle = css`
  ${tabStyle};

  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  text-align: left;
  white-space: normal;
  line-height: 1.3;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`

export const Link = styled(GroupLink)<{
  selected?: boolean
  level: number
  onClick?: (e: React.SyntheticEvent) => void
}>(
  ({ theme, level, selected }) => css`
    ${LinkStyle};
    padding-left: calc(${12 * level}px + 12px);

    &:hover {
      background: ${selected ? undefined : theme.colors.background.tertiary.C};
    }
  `
)

export const CollapsibleIcon = styled(ButtonUnstyled)<{
  selected?: boolean
  isOpen?: boolean
}>(
  ({ theme, selected, isOpen }) => css`
    position: relative;
    flex: none;
    width: 40px;
    height: 40px;
    margin: -8px -12px -8px 0;
    border-radius: 6px;

    ${breakpoint('sm')`
      height: 32px;
    `};

    &:hover {
      background-color: ${theme.colors.background.tertiary.C};
    }

    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      mask: url(${arrowDownIcon}) no-repeat 50% 50%;
      mask-size: cover;
      background-color: ${selected
        ? theme.colors.sketch.A
        : theme.colors.foreground.secondary.D};
      transition-timing-function: ease-in-out;
      transition-duration: 0.3s;
      transition-property: transform;
      will-change: transform;

      /* Closed icon needs a rotation */
      transform: ${isOpen
        ? 'translate(-50%, -50%)'
        : 'translate(-50%, -50%) rotate(-90deg)'};
    }
  `
)
