import { ModalInjectedProps } from '@sketch/components'

import {
  invalidateCollectionShares,
  invalidateProjectShares,
  invalidateProjectCollections,
  sortProjectCollections,
} from './utils'

import { useToast } from '@sketch/toasts'
import { useEventDispatch } from '@sketch/utils'

import {
  CollectionPreviewsFragment,
  ProjectFragment,
  useEditCollectionMutation,
} from '@sketch/gql-types'

interface CurrentShare {
  identifier: string
}

interface EditCollectionProps extends ModalInjectedProps {
  workspaceIdentifier: string
  project: ProjectFragment
  collection: CollectionPreviewsFragment
  currentShares: CurrentShare[]
}

export const useEditCollection = ({
  workspaceIdentifier,
  project,
  collection,
  hideModal,
  currentShares,
}: EditCollectionProps) => {
  const { showToast } = useToast()
  const dispatchSharesRefresh = useEventDispatch('workspaceShareRefresh')

  const [editCollection, { loading }] = useEditCollectionMutation({
    onCompleted: ({ editCollection }) => {
      const collectioName = editCollection.collection.name

      showToast(`"${collectioName}" changes saved`)
      hideModal()
    },
    onError: 'show-toast',
  })

  const editCollectionWithCacheUpdate: typeof editCollection = (
    options = {}
  ) => {
    const shareIdentifiers: string[] = options.variables?.shareIdentifiers ?? []
    const addAllProjectShares: boolean =
      options.variables?.addAllProjectShares ?? false

    return editCollection({
      ...options,
      update: (cache, { data }) => {
        if (!data) {
          return
        }

        const { collection: updatedCollection } = data.editCollection

        if (collection.name !== updatedCollection.name) {
          sortProjectCollections({
            cache,
            projectIdentifier: project.identifier,
          })
        }

        const sharesChanged =
          addAllProjectShares ||
          shareIdentifiers.length !== currentShares.length ||
          !currentShares.every(s => shareIdentifiers.includes(s.identifier))

        if (sharesChanged) {
          // Invalidate any cached `collection.shares` and `project.shares`
          // records so that they are refetched from the GraphQL API
          invalidateCollectionShares({
            cache,
            collectionIdentifier: collection.identifier,
          })
          invalidateProjectShares({
            cache,
            projectIdentifier: project.identifier,
          })
          invalidateProjectCollections({
            cache,
            projectIdentifier: project.identifier,
          })

          // Force a refresh of the project and collection views if the user is
          // currently viewing them since they are out of date due to the
          // invalidations.
          dispatchSharesRefresh({
            workspaceIdentifier,
            projectIdentifier: project.identifier,
            collectionIdentifier: collection.identifier,
          })
        }
      },
    })
  }

  return {
    editCollection: editCollectionWithCacheUpdate,
    loading,
  }
}
