import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { routes, useQueryParams } from '@sketch/modules-common'

import { Grid, Text, GenericError } from '@sketch/components'
import { convertSize } from '@sketch/discover'

import CommunityWrapper from '../../components/CommunityWrapper'
import CommunityHomeItems from '../../components/CommunityHomeItems'
import CommunityHomePagePlaceholder from './CommunityHomePagePlaceholder'

import { GenericQueryError } from '../../utils'

import { Description, Header, Title, Search } from './CommunityHomePage.styles'

import {
  CommunitySectionFragment,
  useGetCommunityPageItemsQuery,
} from '@sketch/gql-types'

const Section = ({ size, items }: CommunitySectionFragment) => (
  <Grid.Column {...convertSize(size)}>
    {items.map(item => (
      <CommunityHomeItems key={item.id} item={item} />
    ))}
  </Grid.Column>
)

interface CommunityHomeSectionsProps {
  sections: CommunitySectionFragment[]
}

interface CommunityHomeSectionsState {
  error: Error | null
}

/**
 * CommunityHomeSections
 *
 * In this component we are rendering the sections defined on the "community.json"
 * Additionally we also render a GenericError if any of the editorial sections that
 * requests additional data fails
 *
 * Check "../../components/CommunityHomeItems" to understand the logic better
 */
class CommunityHomeSections extends React.PureComponent<
  CommunityHomeSectionsProps,
  CommunityHomeSectionsState
> {
  constructor(props: CommunityHomeSectionsProps) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error) {
    if (error instanceof GenericQueryError) {
      /**
       * We are only interested on representing the GenericError
       * with the additional Community Title and Header if there is a "LoadingQueryError"
       *
       * If the error is from another nature it should be taken care of by the main ErrorHandler
       */
      return { error }
    }
  }

  render() {
    const { sections } = this.props
    const { error } = this.state

    if (error) {
      return <GenericError />
    }

    return (
      <Grid>
        {sections.map(section => (
          <Section key={section.id} {...section} />
        ))}
      </Grid>
    )
  }
}

const CommunityHomePage = () => {
  const queryParams = useQueryParams<
    'COMMUNITY_CANVAS' | 'COMMUNITY_CANVAS_LISTING'
  >()
  const [search, setSearch] = useState('')
  const { push } = useHistory()

  const { data, error, loading } = useGetCommunityPageItemsQuery()

  const sections = data?.community.sections || []

  let content = null

  const handleSearch = (value?: string) => {
    setSearch(value || '')

    push(
      routes.COMMUNITY_CANVAS.create({
        query: {
          ...queryParams,
          search: value,
        },
      })
    )
  }

  if (loading) {
    content = <CommunityHomePagePlaceholder />
  } else if (error || !data) {
    content = <GenericError />
  } else {
    content = <CommunityHomeSections sections={sections} />
  }

  return (
    <CommunityWrapper>
      <Header>
        <Title>
          <Text.H1 textStyle="header.primary.I" m={0}>
            Community Canvas
          </Text.H1>
          <Description>
            Explore our curated collection of freely-available resources, made
            with love by Sketch and the community.
          </Description>
        </Title>

        <Search
          onChange={setSearch}
          onSubmit={() => handleSearch(search)}
          onClear={() => handleSearch('')}
          value={search}
          name="search"
          placeholder="Search resources..."
        />
      </Header>

      {content}
    </CommunityWrapper>
  )
}

export default CommunityHomePage
