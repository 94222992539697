import {
  isArtboardElementType,
  isGroupElementType,
  ElementType,
} from '../../../../../../../inspector'
import styled from 'styled-components'

export const ElementIcon = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;

  width: 36px;
  height: 36px;

  background-color: #f0f0f0;
  border-radius: 4px;
  margin-right: 12px;
`

// Some icons need an extra space for the exportable icon (the knife icon)
const isNeededExtraSpaceForExportableIcon = (
  elementType: ElementType
): boolean =>
  isArtboardElementType(elementType) ||
  elementType === 'text' ||
  isGroupElementType(elementType)

/**
 * STYLES
 */
export const ExportableIconWrapper = styled.div<{
  elementType: ElementType
}>`
  width: 21px;
  height: 21px;
  position: absolute;
  transform: ${({ elementType }) =>
    isNeededExtraSpaceForExportableIcon(elementType)
      ? 'translate(2px, 1px)'
      : undefined};
`
