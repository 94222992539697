import React from 'react'
import { SidebarEmpty } from '@sketch/components'

interface InfoMessageProps {
  title: string
  icon: React.ElementType
  action?: React.ReactNode
}

const InfoMessage: React.FC<InfoMessageProps> = ({
  title,
  icon,
  children,
  action,
}) => {
  return (
    <SidebarEmpty
      title={title}
      icon={icon}
      description={children}
      action={action}
    />
  )
}

export default InfoMessage
