import React, { useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/browser'

import {
  FlagsProvider,
  defaultFlags,
  errorPreventiveCacheRead,
} from '@sketch/modules-common'

import { useDevToolsFlags } from '@sketch/devtools'
import {
  GetInitialUserDocument,
  GetInitialUserQuery,
  useApolloClient,
} from '@sketch/gql-types'

const formatArrayIntoObject = (flags: string[]) =>
  flags.reduce((acc, value) => ({ ...acc, [value]: true }), {})

const mapValuesToString = (flags: { [key: string]: boolean }) =>
  Object.fromEntries(Object.entries(flags).map(([k, v]) => [k, String(v)]))

const getFeatureFlagsFromData = (data?: GetInitialUserQuery) =>
  (data?.me?.featureFlags ?? []).filter(f => !!f) as string[]

const ConnectedFlagsProvider: React.FC = ({ children }) => {
  const client = useApolloClient()
  const data = errorPreventiveCacheRead<GetInitialUserQuery>(client.cache, {
    query: GetInitialUserDocument,
  })

  const [featureFlags, setFeatureFlags] = useState<string[]>(
    getFeatureFlagsFromData(data || undefined)
  )

  const { localFlagOverrides } = useDevToolsFlags()

  const flags = useMemo(
    () => ({
      ...defaultFlags,
      ...formatArrayIntoObject(featureFlags),
      ...localFlagOverrides,
    }),
    [featureFlags, localFlagOverrides]
  )

  useEffect(() => {
    const sentryFlags = mapValuesToString(flags)

    Sentry.configureScope(scope => {
      scope.setContext('Feature Flags', sentryFlags)
    })
  }, [flags])

  // We noticed that the useUserProfile hook stopped updated when we "resetStore" when signing out
  // So, instead we are using the cache.watch method to update the feature flags
  // cache.watch doesn't need to be resubscibed to the store after a resetStore
  useEffect(() => {
    const unsubscribe = client.cache.watch({
      query: GetInitialUserDocument,
      optimistic: false,
      callback: ({ result }: { result: GetInitialUserQuery }) => {
        setFeatureFlags(getFeatureFlagsFromData(result))
      },
    })

    return unsubscribe
  }, [client.cache])

  return <FlagsProvider value={flags}>{children}</FlagsProvider>
}

export default ConnectedFlagsProvider
