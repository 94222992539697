import { useEffect } from 'react'
import { useBanner } from '@sketch/components'

import { useGetWorkspaceUploadsRestrictedForQuery } from '@sketch/gql-types'

interface RestrictedWorkspaceBannerManagerProps {
  workspaceId: string
}

const BANNER_ID = 'restricted-workspace'

declare module '@sketch/components' {
  export interface BannersMap {
    'restricted-workspace': null
  }
}

const BANNER_TEXT =
  'Uploading, renaming and editing documents are not available for Editors ' +
  'because document uploads are restricted in this Workspace.'

export const RestrictedWorkspaceBannerManager = (
  props: RestrictedWorkspaceBannerManagerProps
) => {
  const { workspaceId } = props
  const { showBanner, dismissBanner: globalDismissBanner } = useBanner()

  const { data } = useGetWorkspaceUploadsRestrictedForQuery({
    variables: { identifier: workspaceId },
    shouldInvalidatePrevious: (prev, curr) =>
      prev?.identifier !== curr?.identifier,
  })

  const showUploadRestrictedBanner =
    data?.workspace?.uploadsRestrictedFor === 'SELF'

  useEffect(() => {
    if (showUploadRestrictedBanner) {
      const { dismissBanner } = showBanner({
        id: BANNER_ID,
        type: 'information',
        message: BANNER_TEXT,
      })

      return () => {
        dismissBanner()
      }
    } else {
      globalDismissBanner(BANNER_ID)
    }
  }, [showBanner, showUploadRestrictedBanner, globalDismissBanner])

  return null
}
