import React, { FC, ComponentType } from 'react'

import RouteBasedLoadingPage from './RouteBasedLoadingPage'
import { DynamicLoadingPropsByKey } from './DynamicLoadingPage.types'

export interface LoadingPageContextProps {
  component: ComponentType<DynamicLoadingPropsByKey<any>>
}

export const LoadingPageContext = React.createContext<LoadingPageContextProps>({
  component: RouteBasedLoadingPage,
})

export const LoadingPageProvider: FC<{
  component?: ComponentType<DynamicLoadingPropsByKey<any>>
}> = ({ component = RouteBasedLoadingPage, children }) => {
  return (
    <LoadingPageContext.Provider value={{ component }}>
      {children}
    </LoadingPageContext.Provider>
  )
}
