import React from 'react'

import UserDropdown from './UserDropdown'

import {
  useGetNewNotificationsCount,
  useUserProfile,
} from '@sketch/modules-common'

interface UserNavbarSectionProps {
  /**
   * To hide parts of user nav section when there's no workspace
   */
  noWorkspace?: boolean
  isWorkspaceGuestUser?: boolean
}

const UserNavbarSection: React.FC<UserNavbarSectionProps> = ({
  noWorkspace,
  isWorkspaceGuestUser,
}) => {
  const { data } = useUserProfile(true)
  const newNotificationsCount = useGetNewNotificationsCount({
    fetchPolicy: 'cache-only',
  })

  return (
    <UserDropdown
      user={data?.me}
      unreadNotifications={newNotificationsCount}
      noWorkspace={noWorkspace}
      isWorkspaceGuestUser={isWorkspaceGuestUser}
    />
  )
}

export default UserNavbarSection
