import * as Sentry from '@sentry/browser'
import { NetworkStatus } from 'apollo-client'
import isEqual from 'lodash.isequal'
import { useRef } from 'react'
import { QueryHookOptions, QueryResult } from 'react-apollo'

export type ShouldInvalidateFn<Variables> = (
  previousVariables: Variables | undefined,
  currentVariables: Variables | undefined
) => boolean

export interface ShouldInvalidatePreviousOptions<Data, Variables>
  extends Pick<QueryHookOptions<Data, Variables>, 'variables'> {
  shouldInvalidatePrevious?: ShouldInvalidateFn<Variables>
}

const apiViolationError = (initialState: boolean) =>
  'Violation of shouldInvalidatePrevious API contract. ' +
  `shouldInvalidatePrevious was ${
    initialState ? 'truthy' : 'falsy'
  } at first render, ` +
  `but received a ${
    !initialState ? 'truthy' : 'falsy'
  } value at later render. That should never change.`

export const useShouldInvalidatePrevious = <Data, Variables>(
  options: ShouldInvalidatePreviousOptions<Data, Variables> | undefined,
  result: QueryResult<Data, Variables>
): boolean => {
  const { shouldInvalidatePrevious, variables } = options || {}

  const previousVariables = useRef<Variables | undefined>(options?.variables)

  // capture the fact whether the shouldInvalidatePrevious was passed at first render or not
  // if it was passed, it should be always passed for this Query
  // and if it was not passed, it should never be passed
  const isShouldInvalidateTrackingOn = useRef<boolean>(
    !!shouldInvalidatePrevious
  )

  if (isShouldInvalidateTrackingOn.current !== !!shouldInvalidatePrevious) {
    const message = apiViolationError(isShouldInvalidateTrackingOn.current)

    if (
      process.env.REACT_APP_ENV === 'dev' ||
      process.env.REACT_APP_ENV === 'test'
    ) {
      throw new Error(message)
    }

    Sentry.withScope(scope => {
      scope.setExtra('options', JSON.stringify(options))
      Sentry.captureMessage(message)
    })

    return false
  }

  if (!isShouldInvalidateTrackingOn || !shouldInvalidatePrevious) {
    return false
  }

  if (
    (result.networkStatus === NetworkStatus.ready ||
      result.networkStatus === NetworkStatus.error) &&
    !isEqual(previousVariables.current, variables)
  ) {
    previousVariables.current = variables
  }

  const shouldInvalidateResult =
    (!!result.data || !!result.error) &&
    shouldInvalidatePrevious(previousVariables.current, options?.variables)

  return shouldInvalidateResult
}
