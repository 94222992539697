import React, { useEffect, useMemo, useRef } from 'react'

import { ModalContext, useModalContext, ShowModal } from '../ModalContext'

export const ModalRestrictor: React.FC = ({ children }) => {
  const {
    showModal: rootShowModal,
    showRestrictedModal: rootShowRestrictedModal,
    hideModal: rootHideModal,
    modal: rootModalComponent,
    isModalOpen,
  } = useModalContext()

  // Save the reference of the opened modal (in case its opened)
  const modalRef = useRef<React.ComponentType<any>>()
  const onUnmount = useRef<() => void>()

  // Mimic the provider methods to make sure they match
  const methods = useMemo(() => {
    const showModal: ShowModal = (modal, props) => {
      modalRef.current = modal
      rootShowModal(modal, props)
    }

    const showRestrictedModal: ShowModal = (modal, props) => {
      modalRef.current = modal
      rootShowRestrictedModal(modal, props)
    }

    const hideModal = () => {
      rootHideModal()
      modalRef.current = undefined
    }

    return {
      showModal,
      showRestrictedModal,
      hideModal,
    }
  }, [rootShowModal, rootHideModal, rootShowRestrictedModal])

  // When this trigger unmounts check if there is any onUmount hook pending
  useEffect(() => {
    return () => {
      onUnmount.current?.()
    }
  }, [])

  useEffect(() => {
    // If a modal opens with the same component as the saved one we set the unmounting component hook
    // otherwise we clear it
    if (isModalOpen && rootModalComponent === modalRef.current) {
      onUnmount.current = () => {
        methods.hideModal()
      }

      return () => {
        onUnmount.current = undefined
      }
    }
  }, [isModalOpen, rootModalComponent, methods])

  const state = useMemo(
    () => ({
      ...methods,
      isModalOpen,
      modal: rootModalComponent,
    }),
    [methods, isModalOpen, rootModalComponent]
  )

  return <ModalContext.Provider value={state}>{children}</ModalContext.Provider>
}
