import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { dataIdFromObject } from '@sketch/graphql-cache'

import { ReactComponent as SearchIcon } from '@sketch/icons/search-64'
import { ReactComponent as WarningTriangle } from '@sketch/icons/warning-triangle-64'

import { useDebounceValue } from '@sketch/utils'

import {
  Form,
  Button,
  Input,
  handleFetchMore,
  ModalInjectedProps,
  Tooltip,
  Flex,
  Text,
  Link,
  Breakpoint,
  LoadingPlaceholder,
} from '@sketch/components'
import SelectableDocumentList from '../../../designSystems/components/SelectableDocumentList'

import {
  ProjectFragment,
  SharePublicationFragment,
  useGetProjectSharesQuery,
} from '@sketch/gql-types'
import { SelectableShare } from '../../../designSystems/components/SelectableDocumentList/SelectableDocumentTable'

import {
  Separator,
  StyledTextArea,
  Wrapper,
  Title,
  Description,
  IconWrapper,
  StyledFolderClosed,
  StyledQuestionMarkIcon,
  ModalWide,
  ModalWideColumnsWrapper,
  Column,
  ModalHeader,
} from './CollectionModalContent.styles'

const LOADING_PAGE_SIZE = 3
const MAX_LENGTH = 255
const ENTRIES_PATH = ['project', 'shares', 'entries']

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(
    `Give your Collection a name. Don't worry, you can change it later.`
  ),
  description: Yup.string().max(
    MAX_LENGTH,
    'Description must be 255 characters or less'
  ),
})

const NoResults = () => (
  <Wrapper>
    <IconWrapper>
      <SearchIcon height="64px" />
    </IconWrapper>
    <Title>No search results</Title>
    <Description>
      No matching documents found. Try again with another term.
    </Description>
  </Wrapper>
)

const ErrorState = () => (
  <Wrapper>
    <IconWrapper>
      <WarningTriangle height="64px" />
    </IconWrapper>
    <Title>An error occurred</Title>
    <Description>
      An error occurred while getting the documents, please try again.
    </Description>
  </Wrapper>
)

const EmptyState = () => (
  <Wrapper>
    <IconWrapper>
      <StyledFolderClosed height="64px" />
    </IconWrapper>
    <Title>Project is empty</Title>
    <Description>
      Add some documents to the project, after which you can add them to your
      collection.
    </Description>
  </Wrapper>
)

export interface FormProps {
  name: string
  description: string
  selectedShares: string[]
  selectAll: boolean
}

interface CreateCollectionModalProps extends ModalInjectedProps {
  title: string
  project: ProjectFragment

  // Collection pre-filled data (if defined) used when creating a collection
  // from a share or managing collections
  name?: string
  description?: string
  collectionShares: SelectableShare[] // Used to pre-select shares

  onSubmit: (values: FormProps) => void
  isLoading?: boolean
}

export const CollectionModalContent = (props: CreateCollectionModalProps) => {
  const {
    title,
    name,
    description,
    collectionShares = [],
    project,
    isLoading,
    hideModal,
    onSubmit,
  } = props

  const [search, setSearch] = useState('')
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const debouncedSearch = useDebounceValue(search, 500)

  const {
    data,
    error,
    loading: isSearchLoading,
    fetchMore: fetchMoreProjectShares,
  } = useGetProjectSharesQuery({
    variables: {
      shortId: project.identifier,
      search: {
        name: debouncedSearch,
        filters: ['NO_COLLECTION'],
        isCurrentVersionDownloadable: null,
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  /* The document list should be replaced by a loading placeholder if:
   * - we are fetching the initial page of documents
   * - the search term has changed
   *
   * To acheive this, we use `usesState<boolean>` and update the value with `useEffect`.
   * We set the initial value based on `isLoading` and `isSearchLoading`.
   *
   * When `debouncedSearch` changes, we set the value to `true`.
   * When `isSearchLoading` changes to `false`, we set the value to `false`.
   *
   * This avoids us setting the value to true whenever a new page is fetched
   * using `fetchMore`, which would cause the already fetched pages to
   * disappear and be replaced by a loading spinner.
   */
  const [searchLoadingState, setSearchLoadingState] = useState(isSearchLoading)

  useEffect(() => {
    setSearchLoadingState(true)
  }, [debouncedSearch])

  useEffect(() => {
    if (!isSearchLoading) {
      setSearchLoadingState(false)
    }
  }, [isSearchLoading])

  const documentListLoadingState = searchLoadingState || !!isLoading

  const searchPlaceholder = `Search documents in "${project.name}"`
  const projectShares = data?.project.shares.entries ?? []

  const initialSelectedShares = collectionShares.map(share => share.identifier)

  const after = data?.project.shares.meta.after
  const totalCount = data?.project.shares.meta.totalCount ?? 0
  const remainingCount = totalCount - projectShares.length
  const placeholderCount = Math.min(LOADING_PAGE_SIZE, remainingCount)

  const fetchMore = handleFetchMore(fetchMoreProjectShares, ENTRIES_PATH, {
    dataIdFromObject,
    after,
  })

  // When there are pre-selected shares, we need to remove them from the
  // project shares query, in order to avoid duplicate items
  const filteredProjectDocuments = projectShares.filter(
    share =>
      !collectionShares.find(
        ({ identifier }) => share.identifier === identifier
      ),
    [] as SharePublicationFragment[]
  )

  // merged shares can be a list of project shares
  // or a single share (e.g: creating collection from a share)
  const mergedShares = [...collectionShares, ...filteredProjectDocuments]

  const hasDocumentsInProject = mergedShares.length !== 0

  const isSingleShare = !!(mergedShares?.length === 1)
  const noResults = !hasDocumentsInProject && search

  // UI States for SelectableDocumentList
  const errorState = error ? <ErrorState /> : null
  const noResultsState = noResults ? <NoResults /> : null
  // can only show empty state after the Loading is finished
  const emptyState =
    !isLoading && !hasDocumentsInProject ? <EmptyState /> : null

  const isSearchDisabled =
    !!(!isLoading && emptyState && !search) || (isSingleShare && !search)

  const initialValues: FormProps = {
    name: name ?? '',
    description: description ?? '',
    selectedShares: initialSelectedShares ?? [],
    selectAll: false,
  }

  return (
    <ModalWide onCancel={hideModal}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {formikbag => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            setFieldValue,
          } = formikbag

          const isDisabled = !values.name.trim().length
          const selectedDocuments = formikbag.values.selectedShares.length

          const resetSelectedShares = () => {
            // if we are turning select all on (so now it's false), we want to clear the selectedShares
            if (!values.selectAll) {
              setFieldValue('selectedShares', [])
            }
          }

          return (
            <Form>
              <ModalWide.Body>
                <ModalWideColumnsWrapper>
                  <Column>
                    <ModalHeader>{title}</ModalHeader>
                    <Flex alignItems="center" pb={6}>
                      <Text
                        textStyle={'copy.tertiary.standard.E'}
                        //   inline style needed to override the padding bottom of the p
                        style={{ paddingBottom: 0 }}
                        pr={1}
                      >
                        Use collections to easily organize project documents.
                        <Tooltip
                          content="Learn how to use collections"
                          placement="top"
                          visible={tooltipVisible}
                          style={{
                            display: 'inline',
                          }}
                        >
                          <Link
                            external
                            href="https://www.sketch.com/docs/workspaces/organizing-your-workspace/"
                            tabIndex={-1}
                          >
                            <StyledQuestionMarkIcon
                              // using the controlled tooltip because there's a bug with tooltips and modals
                              // when you open a modal with an uncontrolled tooltip, it auto shows the tooltip
                              onMouseEnter={() => setTooltipVisible(true)}
                              onMouseLeave={() => setTooltipVisible(false)}
                            />
                          </Link>
                        </Tooltip>
                      </Text>
                    </Flex>

                    <Form.Field
                      name="name"
                      label="Name"
                      errorText={touched.name ? errors.name : undefined}
                    >
                      <Input
                        type="text"
                        name="name"
                        placeholder="Give your collection a name…"
                        value={values.name}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </Form.Field>
                    <Form.Field
                      name="description"
                      label="Description (Optional)"
                      errorText={
                        touched.description ? errors.description : undefined
                      }
                    >
                      <StyledTextArea
                        name="description"
                        placeholder="This collection is for…"
                        maxLength={MAX_LENGTH}
                        value={values.description}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      <Text textStyle="copy.quaternary.standard.C" pt={2}>
                        {MAX_LENGTH - values.description.length} characters left
                      </Text>
                    </Form.Field>
                  </Column>
                  <Column>
                    <Separator />
                    <SelectableDocumentList
                      // Data
                      name="selectedShares"
                      items={mergedShares}
                      selectedItems={formikbag.values.selectedShares}
                      selectAll={formikbag.values.selectAll}
                      placeholderCount={placeholderCount}
                      // Loading
                      loading={documentListLoadingState}
                      // Handlers
                      onItemChange={formikbag.handleChange}
                      fetchMore={fetchMore}
                      resetSelectedShares={resetSelectedShares}
                      // Search
                      search={search}
                      searchPlaceholder={searchPlaceholder}
                      onSearch={setSearch}
                      isSearchDisabled={isSearchDisabled}
                      // UI States
                      emptyState={emptyState}
                      errorState={errorState}
                      noResultsState={noResultsState}
                      hasWideOption
                    />
                  </Column>
                </ModalWideColumnsWrapper>
              </ModalWide.Body>
              <ModalWide.Footer
                leftContent={
                  <Breakpoint on="xs">
                    {!isLoading ? (
                      <Flex pl={8} pt={3}>
                        <Text.Span textStyle="copy.quaternary.standard.D">
                          {formikbag.values.selectAll
                            ? 'All'
                            : selectedDocuments}{' '}
                          document
                          {(formikbag.values.selectAll ||
                            selectedDocuments !== 1) &&
                            's'}{' '}
                          selected
                        </Text.Span>
                      </Flex>
                    ) : (
                      <Flex
                        pl={8}
                        pt={3}
                        justifyContent="center"
                        alignItems="start"
                        width="130px"
                      >
                        <LoadingPlaceholder size="16px" />
                      </Flex>
                    )}
                  </Breakpoint>
                }
              >
                <Button disabled={isSubmitting} onClick={hideModal}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  loading={isLoading}
                  disabled={isDisabled}
                >
                  Save
                </Button>
              </ModalWide.Footer>
            </Form>
          )
        }}
      </Formik>
    </ModalWide>
  )
}

export default CollectionModalContent
