import React from 'react'
import styled from 'styled-components'
import { TextAlignment } from '../../../../types'

// Text Align Images
import { ReactComponent as AlignCenter } from '@sketch/icons/text-align-center-16'
import { ReactComponent as AlignJustify } from '@sketch/icons/text-align-justify-16'
import { ReactComponent as AlignLeft } from '@sketch/icons/text-align-left-16'
import { ReactComponent as AlignMiddle } from '@sketch/icons/arrow-up-down-to-line-16'
import { ReactComponent as AlignTop } from '@sketch/icons/arrow-up-to-line-16'

const ICON_SIZE = '17px'

export const TextAlignImg = styled.svg.attrs({
  role: 'img',
})`
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  margin-right: 4px;
  opacity: 50%;
`

export const TextAlignContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
`

export const TextAlign = styled.span`
  text-transform: capitalize;
`

export const TextAlignValue = ({
  alignment,
  label,
}: {
  alignment: TextAlignment
  label: string
}) => {
  const renderImage = () => {
    switch (alignment) {
      case TextAlignment.Bottom:
        return (
          <TextAlignImg as={AlignTop} style={{ transform: 'rotate(180deg)' }} />
        )
      case TextAlignment.Top:
        return <TextAlignImg as={AlignTop} />
      case TextAlignment.Right:
        return (
          <TextAlignImg as={AlignLeft} style={{ transform: 'scaleX(-1)' }} />
        )
      case TextAlignment.Left:
        return <TextAlignImg as={AlignLeft} />
      case TextAlignment.Middle:
        return <TextAlignImg as={AlignMiddle} />
      case TextAlignment.Center:
        return <TextAlignImg as={AlignCenter} />
      case TextAlignment.Justified:
        return <TextAlignImg as={AlignJustify} />
      default:
        return null
    }
  }
  return (
    <TextAlignContainer>
      {renderImage()}
      <TextAlign
        aria-label={`${label} ${
          alignment === TextAlignment.Justified ? 'justify' : alignment
        }`}
      >
        {alignment === TextAlignment.Justified ? 'justify' : alignment}
      </TextAlign>
    </TextAlignContainer>
  )
}
