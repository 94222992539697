import React from 'react'
import { WorkspaceSsoConfigFragment } from '@sketch/gql-types'

import { useFlag } from '@sketch/modules-common'
import {
  NeedHelpSection,
  WorkspaceSettingsEnableSsoPanel,
  WorkspaceSettingsScimPanel,
  WorkspaceSettingsSsoSetupPanel,
  WorkspaceSettingsSsoUpsell,
} from '../../components'

interface WorkspaceSettingsSsoProps {
  customerId: string
  workspaceId: string
  ssoShortName?: string
  isSsoActive: boolean
  isScimEnabled: boolean
  showUpsell: boolean
  config?: WorkspaceSsoConfigFragment
}

export const WorkspaceSettingsSso: React.FC<WorkspaceSettingsSsoProps> = ({
  customerId,
  workspaceId,
  ssoShortName,
  isSsoActive,
  isScimEnabled,
  config,
  showUpsell,
}) => {
  const isScimFlagEnabled = useFlag('scim-access')

  if (ssoShortName && !showUpsell) {
    return (
      <>
        <WorkspaceSettingsSsoSetupPanel
          customerId={customerId}
          workspaceShortName={ssoShortName}
          isSsoActive={isSsoActive}
          config={config!}
        />
        {isScimFlagEnabled && (
          <WorkspaceSettingsScimPanel
            workspaceId={workspaceId}
            isSsoEnabled={isSsoActive}
            isScimEnabled={isScimEnabled}
          />
        )}
        <NeedHelpSection />
      </>
    )
  }

  return (
    <>
      {showUpsell && <WorkspaceSettingsSsoUpsell />}
      <WorkspaceSettingsEnableSsoPanel
        customerId={customerId}
        showUpsell={showUpsell}
      />
    </>
  )
}
