// This constants translate how many seconds is on X unit
export const MINUTE = 60
export const HOUR = MINUTE * 60
export const DAY = HOUR * 24
export const WEEK = DAY * 7
export const YEAR = DAY * 365
export const MONTH = YEAR / 12

export const MILLISECONDS_PER_HOUR = HOUR * 1000
export const MILLISECONDS_PER_DAY = 24 * MILLISECONDS_PER_HOUR
