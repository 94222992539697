import React from 'react'

import { Crumb } from './components/Crumb'

import { List, Link } from './OrderedBreadcrumbs.styles'

/**
 * OrderedBreadcrumbs
 *
 * A list of breadcrumbs "1 Crumb > 2 Crumb > 3 Crumb"
 * It can handle any element or component
 *
 * @example
 * <OrderedBreadcrumbs
 * crumbs={[
 *  { content: <p>Hello</p> },
 *  { content: <p>I am</p> },
 *  { content: <p>A list of</p> },
 *  { content: <p>numericaly ordered</p> },
 *  { content: <p>breadcrumb</p> },
 * ]}
 * currentCrumb={3}
 * />
 *
 */

export interface CrumbType {
  content: React.ReactNode
  url?: string
}

export interface OrderedBreadcrumbsProps {
  currentCrumb?: number
  crumbs: readonly CrumbType[]
  className?: string
  showAllInMobile?: boolean
}

export const OrderedBreadcrumbs: React.FC<OrderedBreadcrumbsProps> = ({
  crumbs,
  currentCrumb = 0,
  className,
  showAllInMobile,
}) => {
  if (!crumbs.length) {
    return null
  }

  return (
    <List className={className}>
      {crumbs.map((crumb, index) => (
        <Crumb
          key={index}
          number={index + 1}
          isActive={currentCrumb === index}
          isCompleted={index < currentCrumb}
          showAllInMobile={showAllInMobile}
        >
          {currentCrumb !== index && currentCrumb > index && crumb.url ? (
            <Link to={crumb.url} variant="secondary">
              {crumb.content}
            </Link>
          ) : (
            crumb.content
          )}
        </Crumb>
      ))}
    </List>
  )
}
