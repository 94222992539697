type URL_VALIDATION_RESULT = 'VALID' | 'INVALID' | 'MISSING_PROTOCOL'

export const PROTOCOL = 'http://'

export const URL_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
export const URL_PROTOCOL_REGEX = /^(?:http(s)?:\/\/)/

export const validateURL = (value: string): URL_VALIDATION_RESULT => {
  if (URL_REGEX.test(value)) {
    // URL is valid, lets prepare the URL for the native URL constructor
    // Check if the URL has the protocol
    if (URL_PROTOCOL_REGEX.test(value)) {
      // URL is correct, no need to check anything else
      return 'VALID'
    } else {
      // URL missing protocol, by default let's append HTTP
      return 'MISSING_PROTOCOL'
    }
  }

  return 'INVALID'
}

export const decorateURLWithProtocol = (url: string) => `${PROTOCOL}${url}`
