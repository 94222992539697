import { useContext, useEffect } from 'react'
import { EventsMap } from './EventEmitter.types'
import { EventEmitterContext } from './EventEmitter.context'

type EventTypes = keyof EventsMap

export const _useEventDispatch = <T extends EventTypes>(event: T) => {
  const { events } = useContext(EventEmitterContext)

  return (data: EventsMap[T]) => {
    events.dispatch(event, data)
  }
}

export const _useOnEvent = <T extends EventTypes>(
  event: T,
  callback: (data: EventsMap[T]) => void
) => {
  const { events } = useContext(EventEmitterContext)

  useEffect(() => {
    events.subscribe(event, callback)
    return () => events.unsubscribe(event, callback)
  }, [events, event, callback])
}
