import React from 'react'

import { Button, Link, Modal } from '@sketch/components'
import { PrintToPDFButton } from '../../../components/PrintToPDFButton'
import { CopyCodesButton } from '../../../components/CopyCodesButton'

import {
  Title,
  Description,
  RecoveryCodes,
  RecoveryCode,
  Buttons,
} from '../RegenerateRecoveryCodesModal.styles'

interface ThirdStepProps {
  recoveryCodes: string[]
  onNextStep: (goToStep?: number) => void
}

/**
 * Renders the third step for the Regenerate Recovery Codes modal
 */
export const ThirdStep: React.FC<ThirdStepProps> = ({
  onNextStep,
  recoveryCodes,
}) => {
  return (
    <>
      <Modal.Body>
        <Title>Your New Recovery Codes</Title>
        <Description>
          Use these recovery codes to sign in if you lose access to the
          authentication app. Store them somewhere safe!
        </Description>
        <RecoveryCodes data-testid="recovery-codes-list">
          {recoveryCodes.map((code, index) => (
            <RecoveryCode key={`${code}${index}`}>{code}</RecoveryCode>
          ))}
        </RecoveryCodes>
        <Buttons>
          <PrintToPDFButton codes={recoveryCodes}>
            Print Codes (PDF)
          </PrintToPDFButton>
          <CopyCodesButton codes={recoveryCodes} />
        </Buttons>
        <Link
          onClick={e => {
            e.preventDefault()

            onNextStep(1)
          }}
          isUnderlined
          external
        >
          Generate new recovery codes
        </Link>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onNextStep()} size="40">
          Finish
        </Button>
      </Modal.Footer>
    </>
  )
}
