import styled from 'styled-components'

import { Text, Link as UnstyledLink } from '@sketch/components'

import { breakpoint } from '@sketch/global-styles'

export const StyledLink = styled(UnstyledLink)`
  display: block;
  margin-top: 24px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.E};
`

export const FormContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
`

export const DimText = styled(Text).attrs({
  textStyle: 'copy.quaternary.standard.E',
})`
  max-width: 400px;
  margin: 24px auto 0;
  text-align: center;

  ${StyledLink} {
    display: inline-block;
    margin: 0;
    color: ${({ theme }) => theme.colors.foreground.secondary.D};
  }
`

export const TextSignUp = styled.p`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  margin-top: 32px;
  font-size: ${({ theme }) => theme.fontSizes.E};
  text-align: center;
`

export const Line = styled.div`
  width: 100%;
  margin-top: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`

export const Container = styled.div`
  width: auto;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  padding: 0;

  ${breakpoint('md')`
    padding: 0 32px;
    max-width: 600px;
  `}
`

export const OperatingSystemWarning = styled(Text).attrs({
  textStyle: 'copy.quaternary.standard.C',
})`
  text-align: center;
  margin-top: 12px;
`

export const RequiredOSVersion = styled.div`
  margin-bottom: 24px;
  padding: 16px;
  font-size: ${({ theme }) => theme.fontSizes.C};
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  background-color: ${({ theme }) => theme.colors.background.tertiary.C};
  border-radius: ${({ theme }) => theme.radii.large};
`
