import styled, { css } from 'styled-components'
import { Button, Text, Pill, Form } from '@sketch/components'

export const FieldRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  &:last-child {
    margin-bottom: 4px; /** This is to prevent cutting the button shadows */
  }
`

const Field = styled(Form.Field)`
  flex: 1;
`

export const NameField = styled(Field)`
  margin: 0 0 0 24px;
`

export const EmailField = styled(Field)`
  margin: 24px 0 0 0;
  /* Setting a fixed width makes the component adapt to smaller screens */
  width: 100px;
`

export const PasswordField = styled(Field)`
  margin: 24px 0 0 0;
`

const SaveButton = styled(Button).attrs({ size: '40' })(
  ({ theme }) => css`
    width: 64px;
    margin-left: 16px;
    font-size: ${theme.fontSizes.E};
  `
)

export const SaveNameButton = styled(SaveButton)`
  align-self: center;

  align-self: flex-start;
  margin-top: 24px;
`

export const EditEmailButton = styled(SaveButton)`
  align-self: flex-end;
`

export const EditPasswordButton = styled(SaveButton)`
  align-self: flex-end;
`

export const NameFieldCaption = styled(Text.Span).attrs({
  textStyle: 'copy.quaternary.standard.C',
})`
  margin: 4px 80px 0px 88px; /* stylelint-disable-line scales/space */
`
export const NewEmailCaption = styled(Text.Span).attrs({
  textStyle: 'copy.quaternary.standard.C',
})(
  ({ theme }) => css`
    margin-top: 4px;

    b {
      font-weight: ${theme.fontWeights.medium};
      color: ${theme.colors.foreground.secondary.A};
    }
  `
)

export const ResendLink = styled.a`
  margin-left: 4px;
  cursor: pointer;
`

export const PendingEmailPill = styled(Pill).attrs({
  variant: 'secondary',
})`
  margin-left: 8px;
`
