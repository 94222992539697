import React from 'react'
import { Route as TypeRoute } from 'typesafe-react-router'
import { Location, LocationDescriptor } from 'history'
import { Route, RouteProps, Redirect, useLocation } from 'react-router-dom'

import { routes } from '@sketch/modules-common'

type RedirectPathSignOut = 'sign-in'

interface MFAPrivateRouteProps extends RouteProps {
  redirectPathIfSignOut?:
    | RedirectPathSignOut
    | ((location: Location<any>) => LocationDescriptor)
}

interface LocationState {
  mfaToken?: string
}

const REDIRECT_PATHS_BY_KEY: Record<
  RedirectPathSignOut,
  TypeRoute<any, any>
> = {
  'sign-in': routes.SIGN_IN,
}

export const MFAPrivateRoute: React.FC<MFAPrivateRouteProps> = ({
  location: externalLocation,
  redirectPathIfSignOut = 'sign-in',
  ...props
}) => {
  const contextLocation = useLocation<LocationState>()
  const location = externalLocation || contextLocation

  const hasMFAToken = contextLocation.state?.mfaToken

  if (hasMFAToken) {
    return <Route {...props} />
  } else {
    // Redirect if the user does not have the mfa token
    let to

    if (typeof redirectPathIfSignOut === 'function') {
      to = redirectPathIfSignOut(location)
    } else {
      to = {
        pathname: REDIRECT_PATHS_BY_KEY[redirectPathIfSignOut].create({}),
        state: { from: location },
      }
    }

    return <Redirect to={to} />
  }
}
