import React from 'react'

import { FlatButton } from '@sketch/components'
import { useRemovePermissionGroupMemberMutation } from '@sketch/gql-types'
import { removeFromPaginated } from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'

import {
  LoadingPlaceholder,
  Wrapper,
} from './RemovePermissionGroupMember.styles'

interface RemoveMemberProps {
  memberIdentifier: string
}

const RemovePermissionGroupMember = (props: RemoveMemberProps) => {
  const { memberIdentifier } = props
  const { showToast } = useToast()

  const [removeMember, { loading }] = useRemovePermissionGroupMemberMutation({
    onError: 'show-toast',
    onCompleted: () => {
      showToast('Member has been removed', 'positive')
    },
    update: cache => {
      removeFromPaginated(
        cache,
        {
          __typename: 'PermissionGroupMembership',
          identifier: memberIdentifier,
        },
        () => true
      )
    },
  })

  if (loading) {
    return (
      <Wrapper>
        <LoadingPlaceholder />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <FlatButton
        variant="negative"
        onClick={() => {
          removeMember({
            variables: {
              input: {
                member: memberIdentifier,
              },
            },
          })
        }}
      >
        Remove
      </FlatButton>
    </Wrapper>
  )
}

export default RemovePermissionGroupMember
