import React, { PropsWithChildren } from 'react'
import { VersionLink } from '../../../../versioning'
import { Tooltip, Link } from '@sketch/components'
import { SymbolMaster } from '../../../../inspector'
import { routes } from '@sketch/modules-common'

type ArtboardDetailInspectorSymbolLinkProps = PropsWithChildren<{
  symbolMaster: SymbolMaster | null
  permanentArtboardShortId: string | undefined
  shareIdentifier: string | undefined
}>

export function ArtboardDetailInspectorSymbolLink({
  symbolMaster,
  shareIdentifier,
  permanentArtboardShortId,
  children,
}: ArtboardDetailInspectorSymbolLinkProps) {
  // Data is not consistent for now, so we need to check that both exist
  // in order to link to a proper artboard (symbol masters are just arboards)
  if (!symbolMaster || !permanentArtboardShortId || !shareIdentifier) {
    return <SymbolUnavailable>{children}</SymbolUnavailable>
  }

  // When linking to a foreign symbol we always link to the latest version,
  // but for internal symbols we want a versioned link
  return symbolMaster.isForeign ? (
    <Link
      data-testid="inspector-symbol-link"
      to={
        routes.ARTBOARD_DETAIL.create({
          shareID: shareIdentifier,
          permanentArtboardShortId: permanentArtboardShortId,
        }) + '#Inspect'
      }
    >
      {children}
    </Link>
  ) : (
    <VersionLink
      data-testid="inspector-symbol-link"
      to={{
        routeKey: 'ARTBOARD_DETAIL',
        routeParams: {
          shareID: shareIdentifier,
          permanentArtboardShortId: permanentArtboardShortId,
        },
        hash: '#Inspect',
      }}
    >
      {children}
    </VersionLink>
  )
}

export const SymbolUnavailable: React.FC = ({ children }) => (
  <Tooltip
    placement="top"
    content="This Symbol belongs to a Library that is unavailable"
  >
    {children}
  </Tooltip>
)
