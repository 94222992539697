import styled from 'styled-components'
import { breakpoint } from '@sketch/global-styles'
import { Text } from '../Box'

export const ModalBody = styled(Text.Div).attrs({
  textStyle: 'copy.tertiary.standard.E',
})`
  padding: 0 16px 16px;

  ${breakpoint('sm')`
    padding: 0 32px 32px;
  `}
`
