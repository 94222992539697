import {
  NotificationStatusFragment,
  NotificationStatusFragmentDoc,
} from '@sketch/gql-types'
import * as Sentry from '@sentry/browser'
import { ApolloClient } from 'apollo-client'

enum THUMBNAIL_TYPES {
  S = 0,
  M,
  L,
  XL,
}

type ThumbnailTypes = keyof typeof THUMBNAIL_TYPES

interface ArtboardThumbnail {
  size: number
  type: ThumbnailTypes
  url: string
}

export interface ArtboardFile {
  identifier: string
  url: string
  size: number | null
  thumbnails: ArtboardThumbnail[]
}

function getSmallestFile(sizeLimit: number, artboardFiles: ArtboardFile[]) {
  return artboardFiles.find(file => file.size && file.size <= sizeLimit)
}

function getThumbnailsFromArtboardFiles(artboardFiles: ArtboardFile[]) {
  return (
    artboardFiles.find(file => file.thumbnails && file.thumbnails.length > 0)
      ?.thumbnails || []
  )
}

function sortThumbnailsByType(thumbnails: ArtboardThumbnail[]) {
  return thumbnails.slice().sort((a, b) => {
    const thumbnailATypeValue = THUMBNAIL_TYPES[a.type]
    const thumbnailBTypeValue = THUMBNAIL_TYPES[b.type]

    if (thumbnailATypeValue < thumbnailBTypeValue) {
      return -1
    }

    if (thumbnailATypeValue > thumbnailBTypeValue) {
      return 1
    }

    return 0
  })
}

export const getBestThumbnailUrlFromArtboardFiles = (
  artboardFiles: ArtboardFile[] = []
) => {
  const thumbnails = getThumbnailsFromArtboardFiles(
    artboardFiles
  ) as ArtboardThumbnail[]
  const sortedThumbnails = sortThumbnailsByType(thumbnails)
  const smallestThumbnailUrl = sortedThumbnails?.[0]?.url

  const smallestFileUrl = getSmallestFile(400000, artboardFiles)?.url

  return smallestThumbnailUrl || smallestFileUrl
}

/**
 * @param {import('apollo-client').ApolloClient} client
 * @param {string} userId
 */
export function setHasUnreadNotificationsToZero(
  client: ApolloClient<object>,
  userId: string
) {
  try {
    const notificationStatus = client.readFragment({
      fragment: NotificationStatusFragmentDoc,
      id: `User:${userId}`,
    })

    client.writeFragment<NotificationStatusFragment>({
      fragment: NotificationStatusFragmentDoc,
      id: `User:${userId}`,
      data: {
        __typename: 'User',
        identifier: userId,
        hasUnreadNotifications: notificationStatus?.hasUnreadNotifications,
        hasUnseenNotifications: false,
      },
    })
  } catch (e) {
    Sentry.captureException(e)
  }
}

/**
 * @param {import('apollo-client').ApolloClient} client
 * @param {string} userId
 */
export function setHasUnreadNotificationsAndHasUnseenNotificationsToZero(
  client: ApolloClient<object>,
  userId: string
) {
  try {
    client.writeFragment<NotificationStatusFragment>({
      fragment: NotificationStatusFragmentDoc,
      id: `User:${userId}`,
      data: {
        __typename: 'User',
        identifier: userId,
        hasUnreadNotifications: false,
        hasUnseenNotifications: false,
      },
    })
  } catch (e) {
    Sentry.captureException(e)
  }
}
