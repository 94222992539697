import styled from 'styled-components'

import { breakpoint } from '@sketch/global-styles'

import { ReactComponent as BaseLockIcon } from '@sketch/icons/lock-16'

import Panel from '../Panel'
import { Button, Pill } from '@sketch/components'

import BillingInfoTable from '../BillingInfoTable'

export const StyledBillingInfoCell = styled(BillingInfoTable.Cell)`
  display: flex;
  flex-direction: column;
`

export const LastDigits = styled.strong`
  margin-right: 16px;
`

export const StripeTransactionsWrapper = styled.aside`
  display: inline-flex;
  align-items: center;
  padding: 10px ${({ theme }) => theme.space[6]}px; /* stylelint-disable-line scales/space */
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.A};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};

  ${({ theme }) => breakpoint('xs')`
    width: auto;
    padding: 10px;
    border: none;

    position: absolute;
    right: 0px;
    top: 0px;

    font-size:  ${theme.fontSizes.B};
  `}
`

export const StripeTransactionsText = styled.span`
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  font-size: ${({ theme }) => theme.fontSizes.A};

  ${({ theme }) => breakpoint('xs')`
    font-size: ${theme.fontSizes.B};
  `}
`

export const PaymentInformationWrapper = styled(Panel)`
  position: relative;
`
export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

export const StyledButton = styled(Button)`
  margin-top: 0px;
`

export const PaymentLogoWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const SCAWarningPill = styled(Pill)`
  margin-top: 4px;
`

export const CreditBalanceTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const CreditLogoAmountContainer = styled.div`
  display: flex;

  img {
    margin-right: 8px;
  }
`

export const PaymentInformationTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  ${breakpoint('base', 'sm')`
    flex-direction: column;
    align-items: flex-start;
  `}
`

export const StripeSecureInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  font-size: ${({ theme }) => theme.fontSizes.D};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  line-height: 2; /* stylelint-disable-line scales/line-height */
`

export const LockIcon = styled(BaseLockIcon)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  margin-left: 8px;
`
