import React from 'react'
import { ShareVersionContext } from './ShareVersionContext'

const ShareVersionConsumer = ShareVersionContext.Consumer

export const withShareVersion = Component => {
  const withShareVersion = props => (
    <ShareVersionConsumer>
      {contextProps => <Component {...contextProps} {...props} />}
    </ShareVersionConsumer>
  )

  return withShareVersion
}

export default ShareVersionConsumer
