import { SignupBaseProps, useTrackFirstValueChange } from '../../analytics'
import { SIGN_UP_INITIAL_VALUES } from './constants'

/**
 * Trigger analytics events when user starts editing some
 * fields of the form
 */
export function useTrackEditedFields(
  values: typeof SIGN_UP_INITIAL_VALUES,
  formContext: SignupBaseProps['formContext']
) {
  const eventProps = {
    formContext: formContext,
  } as const

  useTrackFirstValueChange(
    values.name,
    'SIGN UP - "name" field edited',
    eventProps
  )

  useTrackFirstValueChange(
    values.email,
    'SIGN UP - "email" field edited',
    eventProps
  )
  useTrackFirstValueChange(
    values.password,
    'SIGN UP - "password" field edited',
    eventProps
  )
}
