import { fragmentTypes } from '@sketch/gql-types'
import {
  IntrospectionFragmentMatcher,
  InMemoryCache,
  InMemoryCacheConfig,
} from 'apollo-cache-inmemory'

import { cacheRedirects } from './redirects'
import { dataIdFromObject } from './dataIdFromObject'
import { ShortIdsMapper } from './ShortIdsMapper'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: fragmentTypes,
})

const redirects = cacheRedirects as any

export const createAugmentedCache = (options: InMemoryCacheConfig = {}) => {
  const merged: InMemoryCacheConfig = {
    // default values
    fragmentMatcher,
    dataIdFromObject,
    cacheRedirects: redirects,
    // override values
    ...options,
  }

  const shortIdsMapper = new ShortIdsMapper()
  const augmented: InMemoryCacheConfig = {
    ...merged,
    dataIdFromObject: object => {
      const { __typename } = object

      switch (__typename) {
        case 'Organization':
        case 'Version': {
          shortIdsMapper.record({ ...object, __typename })
          break
        }
      }

      return merged.dataIdFromObject?.(object)
    },
  }
  return Object.assign(new InMemoryCache(augmented), { shortIdsMapper })
}

export type AugmentedCache = ReturnType<typeof createAugmentedCache>

export const createCache = createAugmentedCache

export type DataIdFromObjectFn = typeof dataIdFromObject
