import React from 'react'
import { Text } from '../Box'

import { ActionWrapper, Title } from './EmptyState.styles'

export interface EmptyStateProps {
  className?: string
  title: string
  description: string
  actions?: React.ReactNode
}

const EmptyState = (props: EmptyStateProps) => {
  const { className, title, description, actions } = props

  return (
    <div className={className}>
      <Text textStyle="prominent.primary.F" as={Title}>
        {title}
      </Text>
      <Text textStyle="copy.quaternary.standard.E">{description}</Text>
      {actions && <ActionWrapper>{actions}</ActionWrapper>}
    </div>
  )
}

export default EmptyState
