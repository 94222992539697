import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { textOverflowFadeEffect } from '../Color/ColorDot/ColorDot.styles'
import { ReactComponent as BlendMode } from '@sketch/icons/circles-2-overlapping-diagonal-16'
import { capitalize } from '@sketch/utils'
import { Flex, Text, CopyToClipboard } from '@sketch/components'

export type Display = 'inline' | 'wide' | 'block'

const Label = styled(Text.Div).attrs({
  textStyle: 'copy.quaternary.standard.D',
})`
  flex: none;
  display: flex;
  align-items: center;
  min-height: 24px;
`

export interface ValueProps {
  label?: string
  valueString?: string
}

const Value = styled.div.attrs(
  ({
    children,
    label,
    valueString,
  }: {
    children: JSX.Element | string
    label: string
    valueString: string
  }) => ({
    'aria-label':
      typeof children === 'string'
        ? `${label} ${children}`
        : `${label} ${valueString}`,
  })
)<ValueProps>`
  flex: 2;
  min-width: 0;

  display: flex;
  align-items: center;

  min-height: 24px;
`

const labelStyles = (display?: Display) => {
  switch (display) {
    case 'wide':
      return `width: 66.66%;`
    case 'block':
      return `width: 100%;`
    default:
      return `width: 33.33%;`
  }
}

const AttributeList = styled.div`
  /* Vertical spacing between AttributeLists with the exception of Fills, this is not
  the best approach to style it but I think it's the easiest to read and mantain */
  & + &:not([data-testid='fill']) {
    margin-top: 20px;
  }

  & > * + * {
    margin-top: 12px;
  }
`

const ColumnWrapper = styled.div`
  width: 33%;
`

interface AttributeColumnsBaseProps {
  className?: string
}

const AttributeColumnsBase: React.FC<AttributeColumnsBaseProps> = ({
  children,
  className,
}) => {
  const columns = children
    ? React.Children.map(children, (child: React.ReactNode, index: number) => (
        <ColumnWrapper key={index}>{child}</ColumnWrapper>
      ))
    : null

  return (
    <Flex justifyContent="flex-start" className={className}>
      {columns}
    </Flex>
  )
}

const AttributeColumns = styled(AttributeColumnsBase)`
  ${Value} {
    font-size: 1rem;
  }

  & + ${AttributeList} {
    margin-top: 20px;
  }
`

export interface ContainerProps {
  display?: Display
  isUsingCopyOnAttributeClick?: boolean
}

const Container = styled.div<ContainerProps>`
  align-items: center;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  display: ${({ display }) =>
    display && display !== 'wide' ? display : 'flex'};
  flex-wrap: wrap;
  font-size: 0.8125rem;
  position: relative;

  ${Label} {
    ${props => labelStyles(props.display)}
  }

  .CopyToClipboard {
    visibility: ${props =>
      props.isUsingCopyOnAttributeClick ? `hidden` : `visible`};
    width: ${({ isUsingCopyOnAttributeClick }) =>
      !isUsingCopyOnAttributeClick && '100%'};

    ${Label} {
      ${props => labelStyles(props.display)}
    }
  }

  &.withDropdown .CopyToClipboard,
  &:hover .CopyToClipboard {
    visibility: visible;
  }

  &:hover .ColorText,
  &.withDropdown .ColorText {
    ${textOverflowFadeEffect}
    max-width: calc(180px - 48px);
  }
`

const BlendIcon = styled(BlendMode)`
  margin-right: 3px; /* stylelint-disable-line scales/space */
  opacity: 0.5;
  overflow: visible;
  padding-left: 1px; /* stylelint-disable-line scales/space */
  width: 16px;
`

const Blend = ({ blendMode }: { blendMode: string }) => (
  <>
    {' '}
    (<BlendIcon />
    {capitalize(blendMode)})
  </>
)

interface AttributeProps {
  children?: string | React.ReactNode
  className?: string
  copyValue?: string
  display?: Display
  dropdown?: React.ReactElement
  dropdownVisible?: boolean
  style?: CSSProperties
  noCopy?: boolean
  showIcon?: boolean
}

const Attribute: React.FC<AttributeProps> = ({
  children = '',
  copyValue,
  display,
  dropdown,
  dropdownVisible,
  noCopy,
  showIcon,
  ...props
}) => {
  const copyPlacement = display === 'block' || dropdown ? 'top' : 'left'

  const getAttribute = () => {
    if (noCopy) {
      return children
    }

    if (!dropdown) {
      return (
        <>
          {children}
          {copyValue && (
            <CopyToClipboard
              showIcon={showIcon}
              value={copyValue}
              placement={copyPlacement}
              dropdown={dropdown}
            />
          )}
        </>
      )
    }

    if (copyValue) {
      return (
        <CopyToClipboard
          showIcon={showIcon}
          value={copyValue}
          placement="left"
          dropdown={dropdown}
          disabled={dropdownVisible}
        >
          {children}
        </CopyToClipboard>
      )
    }

    return children
  }

  return (
    <Container
      display={display}
      isUsingCopyOnAttributeClick={!dropdown}
      {...props}
      className={
        dropdownVisible ? `withDropdown ${props.className}` : props.className
      }
    >
      {getAttribute()}
    </Container>
  )
}

export default Attribute
export { Label, Value, Blend, AttributeList, AttributeColumns }
