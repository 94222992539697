import React from 'react'
import { Navbar } from '@sketch/components'
import { ShareWithoutVersion } from '../../../versioning'
import BackButton, {
  useBackButton,
} from '../../../shares/components/BackButton'
import HeaderBadge from '../../../shares/components/HeaderBadge'
import * as S from './PrototypeHeader.styles'
import { usePrototypeContext } from '../../hooks'

type PrototypeEmptyHeaderProps = {
  setSidebarLeftOpen?: React.Dispatch<React.SetStateAction<boolean>>
  share: ShareWithoutVersion
}

/**
 * A bare-bones header to show while the Prototype is loading, or in an error
 * state.
 */
export function PrototypeEmptyHeader(props: PrototypeEmptyHeaderProps) {
  const { setSidebarLeftOpen, share } = props
  const { link: shouldRenderBackButton } = useBackButton()

  const { isFullscreen } = usePrototypeContext()

  if (isFullscreen) {
    return null
  }

  return (
    <Navbar data-testid="prototype-empty-header">
      <Navbar.Section>
        <HeaderBadge
          workspace={share.workspace}
          setSidebarLeftOpen={setSidebarLeftOpen}
        />
        {shouldRenderBackButton && (
          <S.BackButtonItem>
            <BackButton />
          </S.BackButtonItem>
        )}
      </Navbar.Section>
    </Navbar>
  )
}
