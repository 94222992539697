import React from 'react'
import { Tooltip, useModalContext } from '@sketch/components'
import { useUserProfile } from '@sketch/modules-common'
import { useThemeContext } from '@sketch/global-styles'

import * as S from './FirstStepsBanner.styles'

import { FirstStepsCard } from '../FirstStepsCard'
import { FirstStepsItem } from '../types'
import { useFirstSteps } from '../useFirstSteps'

import SkipFirstStepsModal from '../../../modals/SkipFirstStepsModal'

export function FirstStepsBanner() {
  const { data } = useUserProfile()

  const userShouldShowFirstSteps =
    data?.me.preferences.showOnboardingFirstSteps || false

  const showFirstStepsBanner = userShouldShowFirstSteps

  if (!showFirstStepsBanner) {
    return null
  }

  return <FirstStepBannerContainer />
}

function FirstStepBannerContainer() {
  const firstStepsItems = useFirstSteps()

  return <FirstStepsBannerPresentational steps={firstStepsItems} />
}

type FirstStepsBannerProps = {
  steps: FirstStepsItem[]
}
function FirstStepsBannerPresentational({ steps }: FirstStepsBannerProps) {
  const { showModal } = useModalContext()
  const hasNoSteps = !steps.length
  const { theme, matchSystem } = useThemeContext()
  const isDark = !matchSystem && theme === 'dark'

  if (hasNoSteps) {
    // Hide the whole banner in case there is no steps to show.
    return null
  }

  const onButtonClick = () => {
    showModal(SkipFirstStepsModal)
  }

  return (
    <S.FirstStepsBanner data-testid="first-steps" isDarkMode={isDark}>
      {steps.map(step => {
        return <FirstStepsCard key={step.stepId} {...step} />
      })}

      {/**
       *  Both buttons are rendered in the DOM at the same time
       *  however only one of them is visible at each time (hidden with media queries)
       */}
      <S.DismissButtonDesktop onClick={onButtonClick}>
        <Tooltip content="Dismiss Onboarding" placement="bottom" spacing="0">
          <S.StyledCloseIcon $isDarkMode={isDark} />
          <span className="sr-only">Dismiss Onboarding</span>
        </Tooltip>
      </S.DismissButtonDesktop>

      <S.DismissButtonMobile buttonStyle="tertiary-40" onClick={onButtonClick}>
        Dismiss Onboarding
      </S.DismissButtonMobile>
    </S.FirstStepsBanner>
  )
}
