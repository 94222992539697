import React from 'react'
import { BillingPlanFragment } from '@sketch/gql-types'

import Card from './components/Card'
import CardProvisionNewPlans from './components/CardProvisionNewPlans'

import { PlansWrapper } from './PlanPicker.styles'

interface PlansProps {
  isDisabled?: boolean
  selectedPlan?: BillingPlanFragment
  onlyBusinessPlansAllowed: boolean
  plans: BillingPlanFragment[]
  hasAutomatedPlanProvisionFFOn?: boolean
  onChange: (clickedPlan: BillingPlanFragment) => void
}

/**
 * PlanPicker
 *
 * Renders cards with each sketch plan (Trial, Monthly, Yearly) detail
 * and allows the user to select one of them.
 *
 * Trial can be hidden for certain users using the showTrial flag
 *
 * @example
 * <PlanPicker onChange={(clickedPlan) => {...}} />
 *
 */
export const PlanPicker: React.FC<PlansProps> = ({
  isDisabled = false,
  onChange,
  selectedPlan,
  onlyBusinessPlansAllowed,
  plans,
  hasAutomatedPlanProvisionFFOn = false,
}) => {
  const CardComponent = hasAutomatedPlanProvisionFFOn
    ? CardProvisionNewPlans
    : Card
  return (
    <PlansWrapper>
      {plans.map(plan => (
        <CardComponent
          key={plan.id}
          onChange={onChange}
          plan={plan}
          isSelected={plan.id === selectedPlan?.id}
          isDisabled={
            isDisabled ||
            (selectedPlan?.type === 'MONTHLY' && plan.product === 'BUSINESS') ||
            (onlyBusinessPlansAllowed && plan.product !== 'BUSINESS')
          }
          showAvailableOnlyOnYearlyNotice={plan.product === 'BUSINESS'}
          showAvailableOnlyOnYearlyTooltip={
            plan.product === 'BUSINESS' && selectedPlan?.type === 'MONTHLY'
          }
        />
      ))}
    </PlansWrapper>
  )
}
