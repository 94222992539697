import React, { useReducer, useCallback, ReactNode } from 'react'

import { ToastContext, ToastObject, ToastId } from './ToastContext'
import { uniqueId } from '@sketch/utils'
import { Variant } from '../Toast'

type Action =
  | { type: 'ADD_TOAST'; payload: ToastObject }
  | { type: 'DISMISS_TOAST'; payload: { id: ToastId } }

type State = ToastObject[]

const reducer = (currentState: State, action: Action) => {
  switch (action.type) {
    case 'ADD_TOAST': {
      const { id, variant, autoDismiss, message } = action.payload

      return [{ id, variant, autoDismiss, message }, ...currentState]
    }

    case 'DISMISS_TOAST': {
      return currentState.filter(({ id }) => id !== action.payload.id)
    }
  }

  return currentState
}

export const ToastProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, [])

  const showToast = useCallback(
    (
      message: ReactNode,
      variant: Variant = 'positive',
      autoDismiss: boolean = true
    ) => {
      const id = uniqueId('toast') as ToastId
      dispatch({
        type: 'ADD_TOAST',
        payload: { id, message, autoDismiss, variant },
      })

      return id
    },
    []
  )

  const dismissToast = useCallback((id: ToastId) => {
    dispatch({ type: 'DISMISS_TOAST', payload: { id } })
  }, [])

  return (
    <ToastContext.Provider
      value={{
        showToast,
        dismissToast,
        /*
        This should be removed from here, we shouldn't expose internal state.

        Currently we do that because the ToastsProvider is a different component from the Toasts
        main visual component.
        */
        toasts: state,
      }}
    >
      {children}
    </ToastContext.Provider>
  )
}
