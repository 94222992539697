import React from 'react'
import { LoadingState, LoadingStateProps, PageLayout } from '@sketch/components'
import { Footer } from '../Footer'
import { EmptyHeader } from '../EmptyHeader'

export interface LoadingPageProps extends LoadingStateProps {
  hideFooter?: boolean
}

export const LoadingPage = ({ hideFooter, ...props }: LoadingPageProps) => {
  return (
    <PageLayout
      header={<EmptyHeader hideLogo isDocumentView />}
      footer={!hideFooter && <Footer />}
    >
      <LoadingState {...props} />
    </PageLayout>
  )
}
