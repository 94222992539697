import React from 'react'
import { Route, Switch } from 'react-router'

import {
  CWV_ROUTES,
  RouteProps,
  getAllTemplatesForVersionedRoute,
  routes,
  useFlag,
} from '@sketch/modules-common'

import PageCanvasView from '../../PageCanvasView/PageCanvasView'
import DocumentView from '../../DocumentView'
import { PrivateWorkspaceRoute } from '../../../workspace/containers'
import { DocView } from '../../../designSystems/pages'
import {
  DocumentSidebarLayout,
  DocumentSidebarLayoutExtraProps,
} from '../../components/DocumentSidebarLayout'
import {
  PublicWorkspaceFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'
import {
  ArtboardDetailQueries,
  ArtboardDetailQueriesProps,
} from '../../ArtboardDetailView'
import { ArtboardRevisionRedirect } from '../ArtboardRevisionRedirect'

const DocumentSidebarLayoutRoutesSwitch = (
  layoutProps: DocumentSidebarLayoutExtraProps
) => {
  const isDesignSystemsOn = useFlag('design-systems')

  return (
    <Switch>
      {/* Artboard Revisions route now redirects to versioned Artboard Detail route */}
      <Route
        exact
        path={[...getAllTemplatesForVersionedRoute('ARTBOARD_REVISION')]}
        component={ArtboardRevisionRedirect}
      />
      {/* Artboard detail view */}
      <Route
        exact
        path={[...getAllTemplatesForVersionedRoute('ARTBOARD_DETAIL')]}
        render={routeProps => (
          <ArtboardDetailQueries
            {...layoutProps}
            {...(routeProps as ArtboardDetailQueriesProps)}
          />
        )}
      />

      {/* Page view (Canvas) */}
      <Route
        exact
        path={getAllTemplatesForVersionedRoute('SHARE_PAGE_CANVAS_VIEW')}
        render={routeProps => (
          <PageCanvasView
            {...layoutProps}
            {...(routeProps as RouteProps<'SHARE_VIEW'>)}
          />
        )}
      />

      {/*
        - Legacy Page view (SHARE_PAGE_VIEW)
        - Prototype view (SHARE_PROTOTYPES)
        - CWV view (symbol, text styles, layer styles or color variables)
        */}
      <Route
        exact
        path={[
          ...getAllTemplatesForVersionedRoute('SHARE_PAGE_VIEW'),
          ...getAllTemplatesForVersionedRoute('SHARE_PROTOTYPES'),
          ...CWV_ROUTES,
        ]}
        render={routeProps => <DocumentView {...layoutProps} {...routeProps} />}
      />

      {isDesignSystemsOn && (
        <PrivateWorkspaceRoute
          exact
          path={routes.DESIGN_SYSTEM_PAGES.template()}
          render={routeProps => (
            <DocView {...routeProps} layoutProps={layoutProps} />
          )}
        />
      )}
    </Switch>
  )
}

export const DocumentSidebarLayoutRoutes = ({
  workspace,
}: {
  workspace: WorkspaceMinimalFragment | PublicWorkspaceFragment
}) => (
  <DocumentSidebarLayout
    workspace={workspace}
    render={layoutProps => (
      <DocumentSidebarLayoutRoutesSwitch {...layoutProps} />
    )}
  />
)
