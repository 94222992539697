import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "template-16", viewBox: "-941 1809 16 16", xmlns: "http://www.w3.org/2000/svg", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "template-16_svg__a" },
            React.createElement("path", { d: "M4 0a1 1 0 011 1v4.5a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1h3z" }))),
    React.createElement("g", { clipPath: "url(#template-16_svg__a)", transform: "matrix(-1 0 0 1 -926.75 1810.75)" },
        React.createElement("path", { opacity: 0.15, fill: "currentColor", d: "M0 0h5v6.5H0V0z" })),
    React.createElement("path", { d: "M-927.75 1810.75h-3a1 1 0 00-1 1v4.5a1 1 0 001 1h3a1 1 0 001-1v-4.5a1 1 0 00-1-1z", stroke: "currentColor", strokeWidth: 1.5, fill: "none", strokeMiterlimit: 10 }),
    React.createElement("g", { clipPath: "url(#template-16_svg__a)", transform: "matrix(-1 0 0 1 -934.25 1816.75)" },
        React.createElement("path", { opacity: 0.15, fill: "currentColor", d: "M0 0h5v6.5H0V0z" })),
    React.createElement("path", { d: "M-935.25 1816.75h-3a1 1 0 00-1 1v4.5a1 1 0 001 1h3a1 1 0 001-1v-4.5a1 1 0 00-1-1zm7.5 3h-3a1 1 0 00-1 1v1.5a1 1 0 001 1h3a1 1 0 001-1v-1.5a1 1 0 00-1-1zm-7.5-9h-3a1 1 0 00-1 1v1.5a1 1 0 001 1h3a1 1 0 001-1v-1.5a1 1 0 00-1-1z", stroke: "currentColor", strokeWidth: 1.5, fill: "none", strokeMiterlimit: 10 }));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
