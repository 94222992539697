import React from 'react'
import styled from 'styled-components'

import { Box, Text } from '../Box'

export const ErrorContainer = styled(Box)`
  box-sizing: border-box;
  margin: auto 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 100%;
  justify-content: center;
`

const GenericErrorState = () => (
  <ErrorContainer>
    <Text fontWeight="semibold" color="foreground.secondary.B" mb={2}>
      Something went wrong
    </Text>
    <Text color="foreground.secondary.C" mt={2}>
      We’re looking into the issue. Try again later or visit our{' '}
      <a href="https://status.sketch.com/">Status Page</a>.
    </Text>
  </ErrorContainer>
)

export default GenericErrorState
