export const localStorageKeys = {
  lastWorkspaceIdKey: 'lastWorkspaceId',
  userProfile: 'user:profile',
  userAllAuthorizations: 'user:allAuthorizations',

  devTools: 'devTools',

  isRunningCypressTests: 'cypress:isRunningCypressTests',
  /**
   * Allow the document layout setting to be persist in storage
   */
  documentLayout: 'user:documentLayout',
  /**
   * Workspaces where the Welcome Modal was dismissed
   * (The Welcome Modal won't appear again)
   */
  dismissedWelcomeModal: 'dismissedWelcomeModal',
  /**
   * BannerCards can be dismissed and for now we save it in local storage
   */
  dismissedBannerCards: 'dismissedBannerCards',
  /**
   * Education Workspace approved modal should not render again after being dismissed
   */
  dismissedEducationApprovedModal: 'dismissedEducationApprovedModal',
  /**
   * MfaBanner first time rendered date. Use to hide it after some time passed
   */
  mfaBanner: 'mfaBanner',
  /**
   * Set once the user has seen the Safari 14.1 performance warning in relation
   * to the Web Renderer for the first time.
   */
  safariGPUWarning: 'user:safariGPUWarning',
  /**
   * Stores the last position (to restore) and zoom level for each canvas view
   */
  lastCameraPositionsByPageCanvasKey: 'lastCameraPositionsByPageCanvasKey',
  /**
   * Whether the components web view beta is on.
   */
  componentsWebViewBetaClosed: 'user:componentsWebViewBetaClosed',
  /**
   * If this is set to "true" - when sending GraphQL operation,
   * all operation will have `?op=${operationName}` added to the request URL
   */
  addOperationQueryParamToURL: 'addOperationQueryParamToURL',
  /**
   * Dismissed banners on Discover page
   */
  discoverDismissedHeros: 'discover:dismissedHeros',
  /**
   * Discover Hash. We storage a discover.json md5 hash to check
   * if there's new content on the Discover page in order to render
   * a "new" pill in the sidebar menu
   */
  discoverLastContentHash: 'discover:lastContentHash',
  /**
   * The last time the user viewed the discover page.
   * We use this against the "Last-Updated" header to check if there's new content
   * in the useDiscover hook
   */
  discoverLastViewedDate: 'discover:lastViewedDate',
  /**
   * Persist the resizable canvas sidebar width between sessions
   */
  canvasSidebarWidth: 'canvasSidebarWidth',
  /**
   * Persist the user annotation settings between sessions
   */
  userAnnotationSettings: 'user:annotationSettings',
  /**
   * Sign up metadata for analytics purposes
   * This can either come from the website or be created here directly.
   * This is *not* for PII (personal identifiable information), but rather for generic UTM params.
   */
  signUpMetadata: 'signUpMetadata',
  /**
   * Persist the theme selection
   */
  theme: 'theme',
  /**
   * Persist the match system theme selection
   */
  matchSystemTheme: 'matchSystemTheme',
  /**
   * Annotations Intro seen
   */
  annotationsIntroSeen: 'annotations_intro_seen',
  /**
   * Public Profile Popover seen
   */
  publicProfilePopoverSeen: 'public_profile_popover_seen',
  /**
   * DSM introductions seen
   */
  dsmIntroductionSeen: 'dsm_introduction_seen',
  /**
   * Sections states on the sidebar
   *  */
  sidebarSectionDocumentsCollapsed: 'sidebar_section_documents_collapsed',
  sidebarSectionProjectsCollapsed: 'sidebar_section_projects_collapsed',
  /**
   * Data related to the Table component. It is used to create a custom key for each table
   *  */
  membershipPanelTable: 'membershipPanel',
  /**
   * The ID that will be sent to BE to identify actions from a single device
   */
  deviceId: 'device-id',
  /**
   * Persist the user's setting for the "Hide Collections" button in the
   * "Filter" dropdown in the Project view
   */
  userProjectSearchSettings: 'user:projectSearchSettings',
  /**
   * Keeps track if the user has seen the cookie consent banner
   */
  cookieConsent: 'cookieConsent',
  /**
   * Keeps track if the user has seen the prototypes new feature highlight
   */
  seenSharingPrototype: 'user:seenSharingPrototype',
  /**
   * Keeps track if the user has seen the "Remix" button of the document
   */
  seenRemixButton: 'user:seenRemixButton',
  /**
   *
   */
  prototypeSharingSettings: 'user:prototypeSharingSettings',
  /**
   * Hide billing UI and replace GraphQL operations with predefined responses
   * This flag works only on dev and test environments
   */
  hideBilling: 'hideBilling',
} as const

// Keys that belong to an authorized user
// These need to be cleared when signing out
const allowedKeys = Object.values(localStorageKeys)
export const authorizationKeys: typeof allowedKeys = [
  'user:profile',
  'user:allAuthorizations',
  'lastWorkspaceId',
]

export type StorageKey = keyof typeof localStorageKeys
