import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "link-library-20", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: 1, fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("g", { id: "Web/Regular/20x20/link.library", stroke: "currentColor" },
            React.createElement("path", { d: "M15 8.5a3.5 3.5 0 010 7h-5a3.5 3.5 0 010-7h1.5", id: "Path" }),
            React.createElement("path", { d: "M5 11.5a3.5 3.5 0 010-7h5a3.5 3.5 0 010 7H8.5", id: "Path" }))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
