import { RELEASE_INFO } from '@sketch/env-config'

const { commit_hash, release_version } = RELEASE_INFO

const HEADERS = {
  'x-client-name': 'cloud-frontend',
  'x-client-version': (release_version || commit_hash) as string,
}

export const getNetworkRequestHeaders = () => HEADERS
