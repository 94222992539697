import styled, { css } from 'styled-components'

import { ButtonUnstyled, DropdownAction } from '@sketch/components'

import { SidebarWrapper } from '../../../workspace/components/WorkspaceSidebarLayout/WorkspaceSidebarLayout.styles'

import { ReactComponent as PlusSvg } from '@sketch/icons/plus-16'

export const Sidebar = styled(SidebarWrapper)(
  ({ theme }) => css`
    width: 215px;
    padding: 28px 16px;
    background-color: ${theme.colors.background.secondary.A};
    border-right: 1px solid ${theme.colors.border.B};
  `
)

export const AddNewButton = styled(DropdownAction)(
  ({ theme }) => css`
    opacity: 0;
    transition: opacity ${theme.transitions.duration[1]};
  `
)

export const SectionTitle = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.foreground.secondary.D};
    text-transform: uppercase;
    font-weight: ${theme.fontWeights.semibold};
    font-size: ${theme.fontSizes.B};
    margin-left: 12px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      ${AddNewButton} {
        opacity: 1;
      }
    }
  `
)

export const SectionWrapper = styled.div`
  margin-bottom: 20px;
`

export const PlusIcon = styled(PlusSvg)(
  ({ theme }) => css`
    color: ${theme.colors.foreground.secondary.D};
    height: 16px;
    width: 16px;
  `
)

export const AddNewPageLong = styled(ButtonUnstyled)(
  ({ theme }) => css`
    cursor: pointer;
    padding: 8px 0px 8px 12px;
    font-size: ${theme.fontSizes.D};
    display: flex;
    align-items: flex-end;

    svg {
      margin-right: 12px;
    }
  `
)

export const TooltipCommand = styled.span`
  color: #ffffff;
  opacity: 0.7;
`

export const NavbarIcon = styled.svg`
  width: 12px;
  height: 12px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  margin-right: 8px;
  margin-top: -1px; /* stylelint-disable-line scales/space */
`
