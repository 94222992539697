import styled, { css } from 'styled-components'
import { truncateStyles } from '../Truncate'
import { breakpoint } from '@sketch/global-styles'

export const OnboardingPanelHeader = styled.h1(
  ({ theme }) => css`
    margin: 0 0 8px 0;
    padding: 16px 16px 0;

    ${breakpoint('sm')`
      font-size: ${theme.fontSizes.E};
      padding: 16px 16px 4px;
    `}

    text-transform: none;
    word-break: break-word;
    color: ${theme.colors.foreground.secondary.A};
    display: block;

    font-size: ${theme.fontSizes.F};
    font-weight: ${theme.fontWeights.medium};
    line-height: 1.5;

    /* The header should stick to the top */
    position: sticky;
    top: 0;
    background: ${theme.colors.background.secondary.A};
    z-index: ${theme.zIndex[2]};

    span {
      display: block;
    }

    small {
      ${truncateStyles};

      display: inline-block;
      font-weight: ${theme.fontWeights.regular};
      color: ${theme.colors.foreground.secondary.C};
      background-color: ${theme.colors.border.A};
      line-height: 1.4;
      padding: 0 8px;
      vertical-align: top;
      margin-top: 4px;
      border-radius: 3px;
      max-width: 100%;
    }
  `
)
