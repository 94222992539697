import { NotificationCommentFragment } from '@sketch/gql-types'

export const formatAnnotationSubject = (
  annotation?: NotificationCommentFragment['annotation']
) => {
  const { currentSubject } = annotation || {}

  if (currentSubject?.__typename === 'Artboard') {
    return currentSubject.nameArtboard
  } else if (currentSubject?.__typename === 'Page') {
    return currentSubject.name
  }
}
