import React, { FC } from 'react'

import { routes } from '@sketch/modules-common'

import { LinkButton } from '@sketch/components'

// Icon
import { ReactComponent as Templates } from '@sketch/icons/templates-46'

import { Container, Title, Description } from './EmptyState.styles'

export interface EmptyStateProps {
  workspaceId: string
  workspaceName: string
  editing: boolean
  className?: string
}

const EmptyState: FC<EmptyStateProps> = ({
  workspaceId,
  workspaceName,
  editing,
  className,
}) => {
  return (
    <Container className={className}>
      <Templates width={46} />
      {editing ? (
        <>
          <Title>Your window to the world</Title>
          <Description>
            Got a design to share, or a masterpiece to show off? Add it to your
            public profile!
          </Description>
          <LinkButton
            to={routes.WORKSPACE_SHARES.create({ workspaceId })}
            variant="primary-untinted"
            size="32"
          >
            Choose Your Best Work…
          </LinkButton>
        </>
      ) : (
        <>
          <Title>No Documents</Title>
          <Description>
            {workspaceName} hasn’t added anything to their public profile yet.
          </Description>
        </>
      )}
    </Container>
  )
}

export default EmptyState
