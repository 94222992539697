import React from 'react'
import { BreadcrumbDivider } from './Breadcrumbs.styles'

const Breadcrumbs: React.FC = ({ children }) => (
  <>
    {React.Children.toArray(children)
      .filter(Boolean)
      .map((child, index) => (
        <React.Fragment key={index}>
          {index > 0 && <BreadcrumbDivider />}
          {child}
        </React.Fragment>
      ))}
  </>
)

export default Breadcrumbs
