import React from 'react'
import styled from 'styled-components'

import { LoadingPlaceholder } from '@sketch/components'
import { useDisableZoom } from '@sketch/modules-common'

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
`

const StyledMessageContainer = styled.div`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  position: absolute;
  margin-top: 64px;
`

type LoadingStateProps = {
  message?: string
}

const LoadingState = ({ message }: LoadingStateProps) => {
  useDisableZoom(true)

  return (
    <LoadingWrapper>
      <LoadingPlaceholder size="64px" />
      {message && <StyledMessageContainer>{message}</StyledMessageContainer>}
    </LoadingWrapper>
  )
}

export default LoadingState
