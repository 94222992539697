import { useMemo } from 'react'
import { isWASMSupported, isWebGLSupported } from '@sketch/utils'

const webGL = isWebGLSupported()
const wasm = isWASMSupported()

/**
 * Hook gathers together all values indicating the level of support for the
 * Web Renderer in the current browser.
 */
export const useSketchWebSupport = () => {
  const supported = useMemo(() => webGL && wasm, [])

  return {
    detail: { webGL, wasm },
    supported,
  } as const
}
