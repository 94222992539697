import styled from 'styled-components'

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
`

export const Field = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

export const Label = styled.div`
  margin-bottom: 4px;
`

export const Description = styled.p`
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: ${({ theme }) => theme.fontSizes.E};
`

export const SubTitle = styled.h2`
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: ${({ theme }) => theme.fontSizes.F};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`

export const Value = styled.div`
  flex: 1 1 100%;
  margin-right: 16px;
  padding: 10px 12px; /* stylelint-disable-line scales/space */
  background: ${({ theme }) => theme.colors.background.secondary.B};
  border: 1px dashed ${({ theme }) => theme.colors.border.A};
  border-radius: ${({ theme }) => theme.radii.large};
`

export const Attributes = styled.ul`
  margin: 32px 0;
  padding-left: 0;
  list-style: none;
`

export const Attribute = styled.li`
  :not(:last-child) {
    margin-bottom: 8px;
  }
`

export const Code = styled.code`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  padding: 2px 4px; /* stylelint-disable-line scales/space */
  background: ${({ theme }) => theme.colors.background.tertiary.C};
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  border-radius: ${({ theme }) => theme.radii.small};
`
