import React, { FC } from 'react'
import { useThemeContext } from '@sketch/global-styles'

import { WorkspaceApprovedModal } from '../WorkspaceApprovedModal'

import { ModalInjectedProps } from '@sketch/components'

// Assets
import Sticker from '@sketch/icons/sticker-animation-light@1x.webp'
import StickerDark from '@sketch/icons/sticker-animation-dark@1x.webp'
import Sticker2x from '@sketch/icons/sticker-animation-light@2x.webp'
import StickerDark2x from '@sketch/icons/sticker-animation-dark@2x.webp'

interface PartnerSubscriptionDoneModalProps extends ModalInjectedProps {
  onHide: () => void
  workspaceName: string
}

const PartnerSubscriptionDoneModal: FC<PartnerSubscriptionDoneModalProps> = ({
  onHide,
  workspaceName,
}) => {
  const { theme } = useThemeContext()

  return (
    <WorkspaceApprovedModal
      title="You're All Set!"
      description={`As a Sketch Partner, you can now manage billing and Editor Seats in “${workspaceName}”.`}
      image={
        theme === 'light'
          ? { src: Sticker, src2x: Sticker2x }
          : { src: StickerDark, src2x: StickerDark2x }
      }
      hideModal={onHide}
      buttonText="Manage People & Settings"
    />
  )
}

export default PartnerSubscriptionDoneModal
