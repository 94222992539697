import React, { useCallback, useEffect, useState } from 'react'

import { useLockCamera } from '@sketch-hq/sketch-web-renderer'

export function useInspectorContextMenu(
  canvasContainerRef: React.RefObject<HTMLDivElement>
) {
  const [position, setPosition] = useState<{ x: number; y: number } | null>(
    null
  )
  const lockCamera = useLockCamera()

  const handleContextMenuEvent = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      const container = canvasContainerRef.current
      if (!container) {
        return
      }
      const containerRect = container.getBoundingClientRect()
      const relativeX = e.pageX - containerRect.left
      const relativeY = e.pageY - containerRect.top

      setPosition({ x: relativeX, y: relativeY })
    },
    [canvasContainerRef]
  )

  const handleCloseContextMenu = useCallback(() => {
    setPosition(null)
  }, [])

  useEffect(() => {
    if (position) {
      lockCamera(true)
    } else {
      lockCamera(false)
    }
  }, [lockCamera, position])

  return {
    canvasContainerRef,
    handleContextMenuEvent,
    contextMenuPosition: position,
    handleCloseContextMenu,
  }
}
