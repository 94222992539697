import { CredentialsFragment } from '@sketch/gql-types'
import { Socket as PhoenixSocket } from 'phoenix'
import { ApolloClient } from 'apollo-client'
import {
  refreshToken,
  checkIfTokenIsExpired,
} from '../../graphql/oauth-fetcher/refreshToken'

import {
  getActiveAuthorization,
  getUserAuthorization,
} from '@sketch/modules-common'
import { log } from '@sketch/utils'
import config from '@sketch/env-config'
import { sentrySocketLogger } from './SocketSentryLogger'

const reconnectAfterMs = function (tries: number) {
  const rnd = Math.floor(Math.random() * 2000)
  return ([1, 2000, 4000, 8000, 16000][tries - 1] || 30000) + rnd
}

const formatAuthorizationHeader = (
  credentials: CredentialsFragment | undefined | null
) => {
  return credentials ? `bearer ${credentials.authToken}` : null
}

/**
 * Authenticated Socket
 *
 * This class extends PhoenixSocket to add custom code
 * that checks whether the token has expired or not before
 * connecting to the backend
 *
 * It is needed because PhoenixSocket class does not
 * accept an async function for parameters, and this
 * is what one of the authors recommends
 * https://github.com/phoenixframework/phoenix/issues/3515#issuecomment-628192821
 */

export class AuthenticatedSocket extends PhoenixSocket {
  constructor(
    socketURI: string | undefined,
    private readonly getClient: () => ApolloClient<any>
  ) {
    super(socketURI || config.events_uri, {
      params: () => ({ ...this.getParameters() }),
      reconnectAfterMs,
    })

    this.onError(async () => {
      sentrySocketLogger.error(this.endPointURL())
      await this.connect()
    })

    this.onOpen(() => {
      sentrySocketLogger.opened(this.endPointURL())
    })

    this.onClose(() => {
      sentrySocketLogger.closed(this.endPointURL())
    })
  }

  async connect() {
    // Original connect() is not async
    // but we need it here to refresh the token.
    // There are no issues with it so far.
    const credentials = getUserAuthorization(this.getClient().cache)
    const isTokenExpired = checkIfTokenIsExpired(
      credentials?.expirationDateTime ?? '0'
    )

    if (isTokenExpired) {
      // Disconnect socket before refreshing
      // to avoid unwanted reconnections
      log('Socket Error -> Disconnecting socket and refreshing token')
      this.disconnect(async () => {
        // Cache watchers will take care of reconnecting
        // after token refresh
        await this.refreshToken()
      })
      return
    }

    super.connect()
  }

  private async refreshToken() {
    const client = this.getClient()
    const authorization = getActiveAuthorization(client)
    authorization && (await refreshToken(client, authorization))
  }

  private getParameters() {
    const credentials = getUserAuthorization(this.getClient().cache)

    return {
      Authorization: formatAuthorizationHeader(credentials),
    }
  }
}
