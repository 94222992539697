import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StepperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.E};
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  margin: 0;
`

export const HelpText = styled.small`
  font-size: ${({ theme }) => theme.fontSizes.C};
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  margin-top: 16px;
`
