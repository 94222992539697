import { useEffect } from 'react'
import { usePrototypeState } from '@sketch-hq/sketch-web-renderer'
import { useVersioning } from '../../versioning'
import { useHistory, useLocation, useParams } from 'react-router'
import { RouteParams } from '@sketch/modules-common'

/**
 * Update the url when the current Artboard changes, i.e. when the user
 * navigates between Screens.
 */
export function useSyncUrl() {
  const prototypeState = usePrototypeState()
  const params = useParams<RouteParams<'PROTOTYPE_PLAYER'>>()
  const { search, pathname } = useLocation()
  const { getPathname } = useVersioning()
  const { replace } = useHistory()

  useEffect(() => {
    if (!prototypeState.currentArtboardId) return

    const nextPathname = getPathname({
      routeKey: 'PROTOTYPE_PLAYER',
      routeParams: {
        shareID: params.shareID,
        prototypeArtboardUUID: params.prototypeArtboardUUID,
        currentArtboardUUID: prototypeState.currentArtboardId,
      },
    })

    if (pathname === nextPathname) return

    replace({ pathname: nextPathname, search })
  }, [
    getPathname,
    params.currentArtboardUUID,
    params.prototypeArtboardUUID,
    params.shareID,
    pathname,
    prototypeState.currentArtboardId,
    replace,
    search,
  ])
}
