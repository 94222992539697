import React, { ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import { SKETCH_WEBSITE } from '@sketch/env-config'
import {
  RouteParams,
  GenericErrorView,
  useQueryParams,
  IndexLayoutExtraProps,
  IndexLayoutContent,
} from '@sketch/modules-common'
import {
  Button,
  Caption,
  Checkbox,
  Panel,
  LoadingState,
} from '@sketch/components'
import { goToLinkAccountUrl } from '../../utils'
import { useGetPublicWorkspaceQuery } from '@sketch/gql-types'

const StyledPanel = styled(Panel)`
  max-width: 520px;
  margin: auto;
`

const StyledCheckbox = styled(Checkbox)`
  margin-top: 28px;
`

const PanelFooter = styled(Panel.Footer)`
  background: none;
`

interface SsoTosViewProps extends IndexLayoutExtraProps {}
export const SsoTosView = (props: SsoTosViewProps) => {
  const { useOverrideLayoutProps } = props
  const [hasAcceptedTos, setHasAcceptedTos] = useState(false)
  const { workspaceId } = useParams<RouteParams<'SSO_TOS'>>()
  const queryParams = useQueryParams<'SSO_TOS'>()
  const { data, loading, error } = useGetPublicWorkspaceQuery({
    variables: { identifier: workspaceId },
  })

  useOverrideLayoutProps({ disableLogoLink: true })

  if (loading) {
    return <LoadingState />
  }

  if (error || !data) {
    return <GenericErrorView isInLayout />
  }

  return (
    <IndexLayoutContent center paddingHorizontal>
      <StyledPanel>
        <Panel.Title>Terms of Service</Panel.Title>
        <Panel.Body>
          <Caption>
            Before proceeding, please confirm you have read and agree to the{' '}
            <b>
              Sketch Cloud{' '}
              <a
                href={`${SKETCH_WEBSITE}/tos`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </b>
            .
          </Caption>

          <StyledCheckbox
            name="acceptTos"
            checked={hasAcceptedTos}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setHasAcceptedTos(event.target.checked)
            }}
          >
            I accept the terms of service.
          </StyledCheckbox>
        </Panel.Body>
        <PanelFooter>
          <Button
            variant="primary"
            disabled={!hasAcceptedTos}
            onClick={() =>
              goToLinkAccountUrl({
                url: data!.publicWorkspace!.customer!.ssoStartUrl!,
                ...queryParams,
              })
            }
          >
            Continue
          </Button>
        </PanelFooter>
      </StyledPanel>
    </IndexLayoutContent>
  )
}
