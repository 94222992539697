import styled from 'styled-components'

import { ReactComponent as CrossIcon } from '@sketch/icons/cross-16'
import {
  Button,
  ButtonUnstyled,
  inputBaseStyles,
  inputBorderStyle,
} from '@sketch/components'
import { ReactComponent as ChevronsUpDownIcon } from '@sketch/icons/chevrons-up-down-16'

export const DeleteButton = styled(Button)`
  width: 40px;
  justify-self: end;
`

export const StyledCrossIcon = styled(CrossIcon)`
  width: 16px;
  height: 16px;
`

export const ExportItem = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 98px 1fr 98px 40px;
  padding-bottom: 12px;
  align-items: center;
`

export const FakeInputButton = styled(ButtonUnstyled)`
  ${inputBaseStyles}
  ${inputBorderStyle}
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const FakeInputContent = styled.div`
  flex-grow: 1;
`

export const ChevronsIcon = styled(ChevronsUpDownIcon)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`
