import styled from 'styled-components'

import { breakpoint } from '@sketch/global-styles'

import {
  Link,
  OrderedBreadcrumbs,
  VerificationCodeInput,
} from '@sketch/components'

export const StyledOrderedBreadcrumbs = styled(OrderedBreadcrumbs)`
  margin-top: 12px;

  ${breakpoint('base', 'xs')`
    margin-top: 0px;
  `}
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.G};
  margin: 24px 0 8px 0;
`

export const Description = styled.p`
  margin-bottom: 16px;
`

export const Links = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledLink = styled(Link)`
  :not(:last-child) {
    margin-bottom: 16px;
  }
`

export const Code = styled.code`
  display: block;
  padding: 16px;
  margin-bottom: 16px;
  font-family: ${({ theme }) => theme.fonts.monospace};
  font-size: ${({ theme }) => theme.fontSizes.G};
  text-align: center;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};

  :hover {
    cursor: pointer;
  }
`

export const StyledVerificationCode = styled(VerificationCodeInput)`
  margin-bottom: 16px;
`

export const RecoveryCodes = styled.ul`
  text-align: center;
  margin: 16px 0;
  padding: 24px;
  border: 1px dashed ${({ theme }) => theme.colors.border.A};
  border-radius: ${({ theme }) => theme.radii.xlarge};
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  list-style: none;
  columns: 2;
`

export const RecoveryCode = styled.li`
  display: block;
  font-family: ${({ theme }) => theme.fonts.monospace};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.F};

  :not(:last-child, :nth-child(5)) {
    padding-bottom: 24px;
  }
`

export const Buttons = styled.div`
  display: flex;
  margin-bottom: 16px;
`

export const Columns = styled.div`
  display: flex;
  margin-bottom: 16px;

  ${breakpoint('base', 'xs')`
    flex-direction: column;
  `}
`

export const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const List = styled.ol`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 16px 0;

  ${breakpoint('base', 'xs')`
    margin-top: 24px;
    padding-left: 16px;
  `}
`

export const Item = styled.li`
  :not(:last-child) {
    margin-bottom: 20px;
  }
`
