import styled from 'styled-components'
import { Button } from '@sketch/components'

import { ReactComponent as ArrowsSvg } from '@sketch/icons/img-arrows-up-right-down-left-24'

export const EnterFullScreenButton = styled(Button)`
  color: white;
  position: absolute;
  bottom: 8px;
  right: 8px;
  z-index: 1;
`

export const ArrowsIcon = styled(ArrowsSvg)`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`
