import { Variant } from '../Toast'
import { createContext, ReactNode } from 'react'
import { noop } from '@sketch/utils'

export type ToastId = string & {
  readonly __tag: unique symbol
}

export interface ToastObject {
  id: ToastId
  message: ReactNode
  variant: Variant
  autoDismiss?: boolean
}

export interface ToastActions {
  showToast: (
    message: ReactNode,
    variant?: Variant,
    autoDismiss?: boolean
  ) => ToastId | void
  dismissToast: (id: ToastId) => void
}

export interface ToastContextValues extends ToastActions {
  toasts: ToastObject[]
}

export const ToastContext = createContext<ToastContextValues>({
  showToast: noop,
  dismissToast: noop,
  toasts: [],
})
