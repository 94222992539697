/**
 * Clamp
 *
 * Clamp numbers between a min and a max value
 *
 *
 * @param num Number to clamp
 * @param min Minimum number
 * @param max Maximum number
 *
 * @returns A number clamped to a range of max and min values
 */

export const clamp = (num: number, min: number, max: number): number =>
  Math.min(Math.max(num, min), max)
