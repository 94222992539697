import { ReactNode } from 'react'

import styled from 'styled-components'
import {
  MentionsInput as BaseMentionsInput,
  MentionsInputProps as BaseMentionsInputProps,
} from 'react-mentions'

import { ListItem, listItemHoverStyle, ListItemIcon } from '../List'

interface MentionsInputProps extends BaseMentionsInputProps {
  customSuggestionsContainer: (children: ReactNode) => ReactNode
}

export const MentionWrapper = styled.div`
  list-style: none;
  padding: 0px 8px;

  ${ListItemIcon} {
    line-height: 1;
    opacity: 1;
  }

  /* Needed for the keyboard selection */
  & [class*='--focused'] ${ListItem} {
    ${listItemHoverStyle};
  }
`

export const MentionsInput = styled(BaseMentionsInput)<MentionsInputProps>`
  font-size: 0.8125rem; /* 13px */
  margin: 8px 8px 12px;

  max-height: 150px;
  overflow-x: auto;

  position: relative;

  textarea {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};

    ::placeholder {
      color: ${({ theme }) => theme.colors.foreground.secondary.D};
    }
  }
`

export const MentionPopoverFakeButton = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`
