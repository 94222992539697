import React, { useState } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import { routes } from '@sketch/modules-common'
import {
  ModalInjectedProps,
  Modal,
  Button,
  SelectDropdown,
  SelectDropdownItem,
  ToastLink,
  Text,
} from '@sketch/components'
import { useToast } from '@sketch/toasts'
import { useAddSharesToCollection } from '../../operations'
import { HeaderItem } from './MoveShareToCollectionModal.styles'
import {
  ProjectFragment,
  CollectionForSelectFragment,
  ShareListItemFragment,
} from '@sketch/gql-types'

interface Share
  extends Pick<
    ShareListItemFragment,
    | 'identifier'
    | 'name'
    | 'project'
    | 'userCanUpdateSettings'
    | 'publicAccessLevel'
    | 'type'
  > {}

export interface MoveShareToCollectionModalProps extends ModalInjectedProps {
  share: Share
  collections: CollectionForSelectFragment[]
  project: ProjectFragment
  workspaceIdentifier: string
  handleCreateCollection: () => void
  currentCollection?: CollectionForSelectFragment | null
}

export const MoveShareToCollectionModal: React.FC<MoveShareToCollectionModalProps> = ({
  share,
  collections,
  hideModal,
  handleCreateCollection,
  workspaceIdentifier,
  project,
  currentCollection,
}) => {
  const location = useLocation()
  const { showToast } = useToast()
  const [
    collection,
    setCollection,
  ] = useState<CollectionForSelectFragment | null>(null)
  const [loading, setLoading] = useState(false)

  const isAllDocumentsView = matchPath(location.pathname, {
    path: routes.WORKSPACE_SHARES.template(),
    exact: true,
  })

  const addSharesToCollection = useAddSharesToCollection({
    projectIdentifier: project.identifier,
    currentCollectionIdentifier: currentCollection?.identifier,
    // we need to invalidate the share cache
    // otherwise, in the All Documents view, the share isn't moved
    // and when you open the move modal again, you can still see
    // the previous collection
    invalidateShare: !!isAllDocumentsView,
    onCompleted: ({ addSharesToCollection }) => {
      const { collection } = addSharesToCollection
      setLoading(false)
      hideModal()

      showToast(
        <>
          Document added to &quot;
          <ToastLink
            to={routes.WORKSPACE_COLLECTION.create({
              workspaceId: workspaceIdentifier,
              projectId: project.identifier,
              collectionId: collection.identifier,
            })}
          >
            {collection.name}
          </ToastLink>
          &quot; collection
        </>
      )
    },
  })

  const handleOnClickMove = () => {
    if (!collection) {
      return
    }

    setLoading(true)

    addSharesToCollection({
      variables: {
        identifier: collection.identifier,
        shareIdentifiers: [share.identifier],
      },
    })
  }

  return (
    <Modal title="Add Document to Collection?" onCancel={hideModal}>
      <Modal.Body>
        <Text mb={6} textStyle="copy.tertiary.standard.E.left">
          Choose where you want to add <strong>“{share.name}”</strong>.
        </Text>
        <SelectDropdown
          placeholder="Select a Collection…"
          value={collection}
          renderValueText={value => value?.name || ''}
          items={collections}
          onChange={setCollection}
          headerItem={<HeaderItem>Select a Collection</HeaderItem>}
          renderItem={(item, props) => (
            <SelectDropdownItem
              onClick={() => setCollection(item as CollectionForSelectFragment)}
              text={item?.name}
              {...props}
            />
          )}
        />
      </Modal.Body>
      <Modal.Footer
        leftContent={
          <Button onClick={handleCreateCollection} disabled={loading}>
            Create Collection&hellip;
          </Button>
        }
      >
        <Button onClick={hideModal}>Cancel</Button>
        <Button
          variant="primary"
          loading={loading}
          disabled={!collection}
          onClick={handleOnClickMove}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
