import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "workspace-64", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 64 64", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { stroke: "currentColor", strokeWidth: 3, strokeLinejoin: "round", d: "M32 4.5l24.5 13.75v27.5L32 59.5 7.5 45.75v-27.5z" }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 3, fillOpacity: 0.15, fill: "currentColor", strokeLinejoin: "round", d: "M32 13.5l16.5 9.25v18.5L32 50.5l-16.5-9.25v-18.5z" }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 3, d: "M32 4v10m0 36v10M7 18l9 5m41-5l-9 5M7 46l9-5.5m32 0l9 5.5" }),
        React.createElement("path", { fillOpacity: 0.15, fill: "currentColor", d: "M16 40.5L7 46l25 14 25-14V18l-9 5v17.5L32 50z" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
