import React from 'react'
import { InView } from 'react-intersection-observer'

import {
  Button,
  useModalContext,
  useResponsiveDropdown,
} from '@sketch/components'

import { ManageCollectionModal } from '../../modals'
import { CollectionDropdownContent } from '../CollectionDropdown'

import {
  Dots,
  ButtonWrapper,
} from '../../../projects/components/ProjectHeader/ProjectHeader.styles'

import { Divider } from './CollectionHeader.styles'

import { CollectionHeaderActionsProps } from './types'

export const CollectionHeaderActions: React.FC<CollectionHeaderActionsProps> = ({
  project,
  workspace,
  collection,
  userCanManageCollection,
  headerSlim,
}) => {
  const { showModal } = useModalContext()

  const [content, buttonProps, { setVisible }] = useResponsiveDropdown({
    dropdown: CollectionDropdownContent,
    dropdownProps: {
      workspace,
      project,
      collection,
      userCanManageCollection,
    },
    usePortal: true,
    placement: 'bottom-start',
  })

  const handleManageCollection = () => {
    showModal(ManageCollectionModal, {
      workspaceIdentifier: workspace.identifier,
      project,
      collection,
    })
  }

  const handleOtherActionsDropdown = (visible: boolean) => {
    if (!visible) {
      setVisible(false)
    }
  }

  return (
    <ButtonWrapper>
      <Button
        variant={headerSlim ? 'secondary-untinted' : 'secondary'}
        onClick={handleManageCollection}
      >
        Manage Collection
      </Button>
      <>
        <Button
          variant="secondary-untinted"
          data-testid="collection-other-actions"
          {...buttonProps}
        >
          <InView onChange={handleOtherActionsDropdown}>
            <span className="sr-only">Other Actions</span>
            <Dots />
          </InView>
        </Button>
        {content}
        {headerSlim && <Divider />}
      </>
    </ButtonWrapper>
  )
}
