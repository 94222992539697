import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Link = styled(NavLink)`
  position: absolute;
  cursor: pointer;
  transform-origin: center;

  /*
  In some implementations the wrappers of the annotation dot
  have "pointer-events: none" to prevent the wrapper from consuming the events
  having "pointer-events: auto" here will make sure this events are still actionable
  even if the parent ain't
   */
  pointer-events: auto;

  width: 0px;
  height: 0px;
  transition: opacity 0.1ms linear;

  /* When the link is disabled it should prevent */
  /* the pointer events and look disabled  */
  /* this rule is triggered when "placeDraftAnnotation" is on */
  &[aria-disabled='true'] {
    pointer-events: none;
    opacity: 0.5;
  }

  /* When a annotation is active it should force */
  /* the sibling items to look disabled. */

  /* however the user should be able to select-it */
  /* the "aria-current='true'" element is created by "AnnotationOverlayActiveDot" */
  /* to ease the complexity of the operation*/
  [aria-current='true'] ~ & {
    opacity: 0.5;

    /* when hovered the opacity should be the normal one */
    &:hover {
      opacity: 1;
    }
  }
`
