import React from 'react'
import styled from 'styled-components'
import { ListItemSkeleton } from '@sketch/components'

const Placeholder = styled(ListItemSkeleton)`
  padding: 2px 0; /* stylelint-disable-line scales/space */
  position: relative;

  ::before {
    content: '';
    display: inline-block;
    width: 8px;

    position: absolute;
    top: 16px;
    left: 0px;

    margin-right: 12px;

    vertical-align: top;
    border-top: 1px solid ${({ theme }) => theme.colors.border.B};
  }
`

interface VersionItemPlaceholderProps {
  count?: number
}

const VersionItemPlaceholder = ({ count = 1 }: VersionItemPlaceholderProps) => (
  <>
    {[...Array(Math.max(count, 0))].fill(null).map((_, index) => (
      <Placeholder key={index} />
    ))}
  </>
)

export default VersionItemPlaceholder
