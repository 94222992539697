import styled, { css } from 'styled-components'
import { Button, Separator, Text } from '@sketch/components'
import { ReactComponent as CommentsIcon } from '@sketch/icons/comments-64'
import { ReactComponent as FolderIcon } from '@sketch/icons/folder-closed-64'

export const PreviewContainer = styled.div(
  ({ theme }) => css`
    width: 68px;
    height: 68px;
    border-radius: ${theme.radii.large};
    border: 1px solid ${theme.colors.border.A};
    background-color: ${theme.colors.background.tertiary.D};
    padding: 4px;
    display: grid;
    grid-template-columns: repeat(2, 28px);
    grid-gap: 4px;
  `
)

export const PreviewImage = styled.img(
  ({ theme }) => css`
    width: 28px;
    height: 28px;
    border-radius: ${theme.radii.base};
    border: 1px solid ${theme.colors.border.A};
    background-color: ${theme.colors.background.secondary.A};
  `
)

export const Title = styled(Text).attrs({
  fontSize: 'G',
  fontWeight: 'regular',
})`
  padding-bottom: 4px !important; /* stylelint-disable-line declaration-no-important */
`

export const Subtitle = styled(Text).attrs({
  textStyle: 'copy.tertiary.standard.D',
  fontWeight: 'normal',
})``

export const EmptyTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 208px;
  text-align: center;

  p:first-of-type {
    padding-bottom: 4px !important; /* stylelint-disable-line declaration-no-important */
  }
`

export const CommentsIconStyled = styled(CommentsIcon)`
  opacity: 0.3;
`

export const EmptyProjectIcon = styled(FolderIcon)`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  width: 60px;
  height: 60px;
`

export const StyledSeparator = styled(Separator)`
  margin-top: 24px;
  margin-bottom: 4px;
`

export const InviteButton = styled(Button).attrs({
  variant: 'primary',
  size: '40',
})`
  width: 92px;
  margin-left: 16px;
  align-self: end;
`

export const MemberInviteSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`
export const NonMemberError = styled(Text).attrs({
  textStyle: 'copy.negative.standard.C',
})`
  /* important and negative margin here to negate the space the message occupies */
  /* stylelint-disable-next-line */
  margin-top: -12px !important;
`

export const PermissionText = styled.span<{ noAccess?: boolean }>`
  padding: 0 12px;
  font-size: ${({ theme }) => theme.fontSizes.D};
  color: ${({ theme, noAccess }) =>
    noAccess
      ? theme.colors.state.negative.A
      : theme.colors.foreground.secondary.D};
`
