import React from 'react'
import styled from 'styled-components'

import { Text, Button } from '@sketch/components'

import CommentTabMessageError from '../CommentTabMessageError'
import CommentTabMessageNoAnnotations from '../CommentTabMessageNoAnnotations'
import CommentSkeleton from '../CommentSkeleton'

import useGetAnnotations from '../../operations/useGetAnnotations'

import { useAnnotationQueryVariables } from '../../context'

import { AnnotationStatusFilterWithoutUnread } from '../../types'
import AnnotationList from '../AnnotationList'
import { BaseAnnotationFragment } from '@sketch/gql-types'
import { NavLinkProps } from 'react-router-dom'

const StyledButton = styled(Button)`
  margin-top: 16px;
`

const PLACEHOLDER_COUNT = 3

// Same interface used in "containers/AnnotationsListContainer"
interface ListProps {
  activeAnnotationIdentifier?: string
  resolveAnnotationLink?: (
    annotation: BaseAnnotationFragment
  ) => NavLinkProps['to'] | undefined
}

const ActiveOrResolvedAnnotationsList: React.FC<ListProps> = props => {
  const { activeAnnotationIdentifier, resolveAnnotationLink } = props

  const variables = useAnnotationQueryVariables('sidebar')
  const { annotationStatus } = variables

  const { data, error, loading, fetchMore, refetch } = useGetAnnotations({
    variables,
  })

  if (loading) {
    return <CommentSkeleton count={PLACEHOLDER_COUNT} />
  }

  if (error || !data) {
    return (
      <CommentTabMessageError title="Unable to load comments">
        <Text textStyle="copy.quarternary.standard.D">
          There was an error loading comments.
        </Text>
        <StyledButton onClick={() => refetch()} variant="secondary" size="32">
          Try again
        </StyledButton>
      </CommentTabMessageError>
    )
  }

  const { entries, meta } = data.annotations

  if (entries.length === 0) {
    return (
      <CommentTabMessageNoAnnotations
        annotationStatus={
          annotationStatus as AnnotationStatusFilterWithoutUnread
        }
        showAddCommentButton
      />
    )
  }

  return (
    <AnnotationList
      activeAnnotationIdentifier={activeAnnotationIdentifier}
      resolveAnnotationLink={resolveAnnotationLink}
      entries={entries}
      meta={meta}
      fetchMore={fetchMore}
      listingType={annotationStatus === 'ACTIVE_ONLY' ? 'ACTIVE_ONLY' : null}
    />
  )
}

export default ActiveOrResolvedAnnotationsList
