import styled from 'styled-components'

import { ReactComponent as ChevronRightIcon } from '@sketch/icons/chevron-right-16'

export const Chevron = styled(ChevronRightIcon)`
  width: 16px;
  margin-left: auto;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

export const Wrapper = styled.section`
  padding: 16px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.B};

  button,
  a {
    padding: 8px 12px;
  }
`
