import styled, { css } from 'styled-components'

import { TableComponents } from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'

export const TableWrapper = styled.div(
  ({ theme }) => css`
    margin: 0 -16px 16px;
    table-layout: fixed;

    /*
     * This position was added to prevent the ::before from the "TableCellSticky" 
     * to fill the other space of other components. Example the "FirstSteps" from being seen
     */
    position: relative;

    ${TableComponents.TableCellSticky} {
      /* AvatarStack has a z-index of 2 to actually show the stacked
      logos, so we need to do this to prevent the avatar go over the sticky cell */
      z-index: 2; /* stylelint-disable-line scales/z-indices */
      right: 0;
    }

    ${breakpoint('sm')`
    margin: 0 -40px 40px;
  `}

    /* Compensate for the Margin and make the table scroll without blank sides */
    ${TableComponents.Table} {
      padding-left: 16px;

      position: relative;
      z-index: 0; /* stylelint-disable-line scales/z-indices */

      ${breakpoint('sm')`
        padding: 0 40px;
      `}
    }

    ${TableComponents.TableHeaderCell} {
      &:nth-child(1) {
        min-width: 300px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: 20%;
      }
    }

    ${TableComponents.TableRow} {
      cursor: pointer;
    }

    ${TableComponents.TableRow} td:last-child {
      text-align: right;
      width: 60px;

      ${breakpoint('lg')`
        width: 60px;
      `}
    }

    ${breakpoint('base', 'sm')`
    overflow: auto;
    width: calc(100% + 16px);

    ${TableComponents.TableHeaderCell}:not:last-child {
      min-width: 120px;
      max-width: 200px;
    }

    ${TableComponents.TableHeaderCell}:last-child {
      right: 0;
      top: 0;
      width: 60px;

      ${breakpoint('base', 'sm')`
        color: ${theme.colors.background.secondary.A}; /** Just for hiding the text */
      `}
    }
  `}
  `
)
