import type { LocalResolvers } from '@sketch/gql-types/resolvers'

/**
 * This function is intended to be used in a tandem with `dummyLocalResolvers` defined at
 * `@sketch/frontend-factory`.
 *
 * The idea of `dummyResolvers` is to provide dummy data for operations which are already in use
 * but the BE for some particular fields(s) isn't ready yet. So those fields are marked with `@client`
 * directive and dummy data is returned for them (if `Dummy Data` feature is enabled in dev-tools).
 *
 * To make this work you have to do these steps:
 *  - update `dummyLocalResolvers` at `@sketch/frontend-factory` and define custom resolver there.
 *  - connect dummy resolver defined at `@sketch/frontend-factory` with dummy resolver defined here:
 * @example
 * ```
 *   import { connect } from './dummyResolvers.connect'
 *
 *   export const dummyResolvers: LocalResolvers = {
 *     Workspace: {
 *       ...connect('Workspace', 'isProfileEnabled_local', false),
 *     }
 *   }
 * ```
 *
 * ---
 *
 * NOTE: Do not delete this whole object even if it gets empty. That is expected state between new projects development.
 */
export const dummyResolvers: LocalResolvers = {}
