import React from 'react'
import { thumbnailForPreviewFile } from '../../../../../shares/utils'
import {
  LoadingPlaceholder,
  TableComponents,
  TableHeaderItem,
} from '@sketch/components'

import {
  SelectedDocumentTable,
  SelectedDocumentsListWrapper,
} from './SendToTrashDocumentList.styles'

import {
  Image,
  ImageWrapper,
  Name,
  NameWrapper,
  TableWrapper,
} from '../../../../../shares/components/DocumentItem/DocumentItem.styles'

import { ShareWithSizeFragment } from '@sketch/gql-types'
import { FullWidthContainer } from '../../ReviewLargeDocumentsModal.styles'
import { formatStorageNumber } from '../../../../utils/storage'

interface SendToTrashDocumentListProps {
  sharesWithSize: ShareWithSizeFragment[]
}

const HEADERS: TableHeaderItem[] = []

const SendToTrashDocumentList: React.FC<SendToTrashDocumentListProps> = ({
  sharesWithSize,
}) => {
  return (
    <FullWidthContainer $noBorder>
      <SelectedDocumentsListWrapper>
        <SelectedDocumentTable
          header={HEADERS}
          items={sharesWithSize}
          renderItem={(item: ShareWithSizeFragment) => {
            const previewFile = item.share.version?.document?.previewFile
            const image = thumbnailForPreviewFile(previewFile)
            const sizeFormatted = formatStorageNumber(item.size)
            return (
              <TableWrapper>
                <TableComponents.TableCell>
                  <NameWrapper>
                    {image && (
                      <ImageWrapper title={item.share.name}>
                        <Image
                          alt={item.share.name}
                          src={image}
                          loadingPlaceholder={
                            <LoadingPlaceholder size="16px" />
                          }
                          height={32}
                        />
                      </ImageWrapper>
                    )}
                    <Name>{item.share.name}</Name>
                  </NameWrapper>
                </TableComponents.TableCell>

                <TableComponents.TableCell>
                  {sizeFormatted}
                </TableComponents.TableCell>
              </TableWrapper>
            )
          }}
        />
      </SelectedDocumentsListWrapper>
    </FullWidthContainer>
  )
}

export default SendToTrashDocumentList
