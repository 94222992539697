import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const Link = styled(NavLink)`
  display: block;
  border-radius: ${({ theme }) => theme.radii.large};
  outline: none;
`

export default Link
