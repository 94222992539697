import React from 'react'
/**
 * In order to position the Select popover we can use a function, it's not documented
 * in Reach UI docs but the source code is quite readable so if you need more info
 * about how to position the popover please check this:
 * https://github.com/reach/reach-ui/blob/01c1a16315e7d89f7e7f338cb8c3d2a72023087c/packages/popover/src/index.tsx#L140
 */
import { positionRight } from '@reach/popover'
import { useInView } from 'react-intersection-observer'

import { SelectTriggerUnstyled, SelectInput, SelectList } from '../Select'

import {
  Popover,
  TriggerButton as DefaultTriggerButton,
  CommentLabel,
  Header,
} from './AccessLevelSelect.styles'

import { AccessLevelSelectProps } from './types'

// We will rely on the typing from the parent components, that will
// check that the level and setLevel are one of the allowed levels
export const AccessLevelSelect = <T, U>({
  label,
  level,
  setLevel,
  disabled,
  commentsEnabled,
  hideCommentLabel,
  position,
  triggerButton,
  children,
  noAccessRedColor = true,
  title = 'Access',
}: AccessLevelSelectProps<T, U>) => {
  // Hide the dropdown when the same is not in view
  const { ref, inView } = useInView({
    threshold: 0.25,
  })

  // Usage of type "unknown" is to prevent TS2367 error
  const unknownLevel = level as unknown
  const displayCommentingOffLabel =
    unknownLevel !== 'NONE' && !commentsEnabled && !hideCommentLabel

  return (
    <SelectInput
      // To solve https://github.com/sketch-hq/Cloud/issues/8122 we are using
      // intersection observer to check when the list item is out of view.
      // Since reach-ui ListboxPopover doesn't have a method to close the popover that we can use,
      // we are setting a "key" prop to the parent of the popover to force it to rerender.
      // This is a hack that forces the popover to close and stay closed when scrolling the members list.
      key={`isInView-${!!inView}`}
      data-testid={`isInView-${!!inView}`}
      value={unknownLevel as string}
      disabled={disabled}
      onChange={value => {
        if (value === unknownLevel) {
          // If the value is the same there shouldn't happen any action
          return
        }

        setLevel((value as unknown) as U)
      }}
    >
      <SelectTriggerUnstyled aria-disabled={disabled}>
        {triggerButton || (
          <DefaultTriggerButton
            disabled={disabled}
            data-testid="access-level-dropdown-toggle"
            ref={ref}
            label={
              <>
                {label}
                {displayCommentingOffLabel && (
                  <CommentLabel>Commenting off</CommentLabel>
                )}
              </>
            }
            $colorRed={unknownLevel === 'NONE' && noAccessRedColor}
          />
        )}
      </SelectTriggerUnstyled>

      <Popover position={position || positionRight} showModalInMobile>
        <SelectList data-testid="AccessLevelSelect">
          <Header>{title}</Header>

          {children}
        </SelectList>
      </Popover>
    </SelectInput>
  )
}
