import { DataProxy } from 'apollo-cache'

import {
  GetWorkspaceSettingsDocument,
  GetWorkspaceSettingsQuery,
  GetWorkspaceSettingsQueryVariables,
  WorkspaceMinimalFragment,
  WorkspaceMinimalFragmentDoc,
} from '@sketch/gql-types'
import { dataIdFromObject } from '@sketch/graphql-cache'
import produce from 'immer'
import { ErrorHandler } from '@sketch/tracing'
import { castError } from '@sketch/utils'

interface UpdateSubscriptionEndParameters {
  workspaceId: string
  subscriptionEnd: string | null
  isPartner?: boolean
}

export const updateSubscriptionEndInCache = (
  cache: DataProxy,
  { workspaceId, subscriptionEnd, isPartner }: UpdateSubscriptionEndParameters
) => {
  let existingData: GetWorkspaceSettingsQuery | null
  const variables = isPartner
    ? { identifier: workspaceId, includeShares: false }
    : { identifier: workspaceId }

  try {
    existingData = cache.readQuery<
      GetWorkspaceSettingsQuery,
      GetWorkspaceSettingsQueryVariables
    >({
      query: GetWorkspaceSettingsDocument,
      variables,
    })
  } catch (e) {
    // If the workspace isn't loaded then there's no need to update anything
    const err = castError(e)
    ErrorHandler.ignore(err)
    existingData = null
  }

  if (!existingData) {
    return
  }

  const updated = produce(existingData, draft => {
    if (!draft.workspace.customer) {
      ErrorHandler.shouldNeverHappen(
        'It is always expected to have customer when updating the subscription end.'
      )
      return
    }

    if (!draft.workspace.customer.billing) {
      ErrorHandler.shouldNeverHappen(
        'It is always expected to have Billing data when we are updating subscription end.'
      )
      return
    }

    draft.workspace.customer.billing.subscriptionEnd = subscriptionEnd
  })

  cache.writeQuery<
    GetWorkspaceSettingsQuery,
    GetWorkspaceSettingsQueryVariables
  >({
    query: GetWorkspaceSettingsDocument,
    variables,
    data: updated,
  })
}

interface UpdateWorkspaceTosAgreedParameters {
  workspaceId: string
  tosAgreed: boolean
}

export const updateWorkspaceTosAgreed = (
  cache: DataProxy,
  { workspaceId, tosAgreed }: UpdateWorkspaceTosAgreedParameters
) => {
  const workspaceCacheId = dataIdFromObject({
    __typename: 'Workspace',
    identifier: workspaceId,
  })!

  const workspaceFragment = cache.readFragment<WorkspaceMinimalFragment>({
    fragment: WorkspaceMinimalFragmentDoc,
    fragmentName: 'WorkspaceMinimal',
    id: workspaceCacheId,
  })

  if (!workspaceFragment) {
    return
  }

  /**
   * Because TOS lives on WorkspaceCustomer we can't query the value
   * from the payments mutation therefore we manually updated the value
   * on the cache
   */
  cache.writeFragment({
    fragment: WorkspaceMinimalFragmentDoc,
    fragmentName: 'WorkspaceMinimal',
    id: workspaceCacheId,
    data: produce(workspaceFragment, workspace => {
      if (!workspace.customer) {
        ErrorHandler.shouldNeverHappen(
          "We shouldn't update the 'tos agreed' of a customer that doesn't exist"
        )
        return
      }

      workspace.customer.tosAgreed = tosAgreed
    }),
  })
}
