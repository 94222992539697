import { LocationDescriptor } from 'history'

import { routes, normalizeToLocationObject } from '../../routes'

export type RedirectId = NonNullable<
  ReturnType<typeof getRedirectIdFromPathname>
>

/**
 * Object derived from location.from containing information
 * about the redirect we need to do.
 */
export type RedirectState = {
  /**
   * Made up id to help us identify what redirect needs to take place so we
   * can show different UI elements for example.
   */
  redirectId: RedirectId
  pathname: string
  search: string | undefined
}

const getRedirectIdFromPathname = (pathname?: string) => {
  if (routes.WORKSPACE_INVITE.template() === pathname) {
    return 'workspace-invite' as const
  } else if (
    routes.SHARE_ACCEPT_INVITE_SIGN_IN.template() === pathname ||
    routes.SHARE_ACCEPT_INVITE_SIGN_UP.template() === pathname
  ) {
    return 'document-invite' as const
  } else if (routes.PROJECT_ACCEPT_INVITE.template() === pathname) {
    return 'project-invite'
  }
}

/**
 * Parse the history location object and return
 * an enriched "RedirectState" object easier to work with.
 */
export function getRedirectStateFromLocationObject(
  location: LocationDescriptor
): RedirectState | null {
  const { pathname, search } = normalizeToLocationObject(location)
  const redirectId = getRedirectIdFromPathname(pathname)

  if (!pathname || !redirectId) {
    return null
  }

  return {
    redirectId,
    pathname,
    search,
  }
}
