import {
  RadioButton,
  SelectDropdownItem,
  Separator,
  Text,
} from '@sketch/components'
import styled from 'styled-components'

export const Note = styled(Text).attrs({
  textStyle: 'copy.quaternary.standard.C',
})`
  font-size: ${({ theme }) => theme.fontSizes.B};

  /* Forcing an override for a "p" padding-bottom style */
  && {
    padding: 8px 0 0 0;
  }
`

export const MovingTextWrapper = styled.div`
  margin-top: 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.border.B};
  padding: 24px 0 0;
`

export const DangerRadioButton = styled(RadioButton)`
  ${RadioButton.Label} {
    color: ${({ theme }) => theme.colors.state.negative.A};
  }
`

export const WarningWrapper = styled.div`
  margin-top: 24px;
  padding: 16px;
  border-radius: ${({ theme }) => theme.radii.large};
  background-color: ${({ theme }) => theme.colors.state.neutral.F};
  font-size: 0.8125rem;
  /* This can't be one of our foreground tokens because the banner keeps the
      same background for any theme, so we always want the text to be dark */
  color: hsla(0, 0%, 0%);
`

export const StyledSeparator = styled(Separator)`
  margin: 24px 0;
`

export const HeaderItem = styled(Text.H1).attrs({
  textStyle: 'subheader.quaternary.standard.B',
})`
  margin: 0;
  padding: 10px 12px; /* stylelint-disable-line scales/space */
  text-transform: uppercase;
`

export const Item = styled(SelectDropdownItem)`
  &[disabled] {
    opacity: 0.5;
  }
`
