import React, { useState } from 'react'
import { useToast } from '@sketch/toasts'
import { useVersionUpdateKind } from '../../hooks/useVersionUpdateKind'
import {
  Checkbox,
  ConfirmationDialog,
  ModalInjectedProps,
} from '@sketch/components'

export interface UnstarUpdateModalProps extends ModalInjectedProps {
  versionIdentifier: string
  isLibrary: boolean
}

export const UnstarUpdateModal = ({
  hideModal,
  isModalOpen,
  versionIdentifier,
  isLibrary,
}: UnstarUpdateModalProps) => {
  const { showToast } = useToast()
  const [skipModal, setSkipModal] = useState(false)

  const [versionKindUpdate, { loading }] = useVersionUpdateKind({
    onCompleted: () => {
      showToast('Version unstarred')
      hideModal()
    },
    onError: message => showToast(message, 'negative'),
  })

  const handleSkipModal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSkipModal(event.target.checked)
  }

  const onConfirm = () => {
    versionKindUpdate({ versionIdentifier, kind: 'DRAFT' })

    localStorage.setItem(
      'user:preferences:skipUnstarModal',
      JSON.stringify(skipModal)
    )
  }

  return (
    <ConfirmationDialog
      data-testid="create-star-update-modal"
      title={'Unstar this Version?'}
      hideModal={hideModal}
      isModalOpen={isModalOpen}
      cancelButton={{ disabled: loading }}
      confirmButton={{
        variant: 'primary',
        text: 'Unstar Version',
        loading,
        disabled: loading,
      }}
      onConfirm={onConfirm}
    >
      {isLibrary ? (
        <p>
          If you remove a star from a Library version, anyone who uses your
          Library will receive changes from the next most recent starred
          version. If there are no starred Library versions, they will receive
          changes from the most recent Library version.
        </p>
      ) : (
        <p>
          If you remove a star, external Viewers will see the next most recent
          starred version. If no versions are starred, Viewers will see the most
          recent version.
        </p>
      )}
      <Checkbox
        checked={skipModal}
        onChange={handleSkipModal}
        label="Don’t ask me again"
      />
    </ConfirmationDialog>
  )
}
