import styled from 'styled-components'
import { ReactComponent as MaskIcon } from '@sketch/icons/arrow.left.down-16'

export const ElementIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  position: relative;
`

export const StyledMaskedIcon = styled(MaskIcon)`
  width: 16px;
  height: 16px;
  margin-right: 2px; /* stylelint-disable-line scales/space */
  margin-top: 1px; /* stylelint-disable-line scales/space */
`
