import styled from 'styled-components'
import { ReactComponent as ArrowUpRightDownLeftSvg } from '@sketch/icons/img-arrows-up-right-down-left-24'

export const Icon = styled(ArrowUpRightDownLeftSvg).attrs({
  width: '24px',
  height: '24px',
  flex: 'none',
})``

export const Button = styled.div`
  padding: 4px 8px;
  border: none;
  box-shadow: none;
  cursor: pointer;
  height: 32px;

  && svg {
    display: flex;
    /* This color should be "Light/colors.foreground.secondary.D" but given the icon composition */
    /* Opacity needs to be applied to the whole object */
    opacity: 0.55;
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  ${({ theme }) =>
    `
    background-color: transparent;
    border-radius: ${theme.radii.large};
    color: ${theme.colors.foreground.secondary.D};

    &:hover {
      background-color: ${theme.colors.background.tertiary.C};

      && svg {
        opacity: 1;
      }
    }

    &[disabled] {
      cursor: not-allowed;
      pointer-events: none;
      color: ${theme.colors.state.disabled};
    }
  `};
`
