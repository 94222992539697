export const getFactoryModule = () => {
  if (
    process.env.REACT_APP_ENV === 'dev' ||
    process.env.REACT_APP_ENV === 'test'
  ) {
    /**
     * frontend factory is not available right now, after migration to vite we are facing problems to import it
     * as it aims to import libraries which depend on nodejs modules (such as node-fetch, stream).
     * This is a temporary solution to avoid breaking the build.
     * TODO: fix frontend-factory import to work with vite
     */
    if (true as boolean) return null

    return import('@sketch/frontend-factory')
  }
  return null
}

if (
  process.env.REACT_APP_ENV === 'dev' ||
  process.env.REACT_APP_ENV === 'test'
) {
  // it is meant to use this by Cypress to assure that code is actually split
  // and not preloaded by any other code block unintentionally.
  // eslint-disable-next-line no-extra-semi
  ;(window as any).__getFactoryModule = getFactoryModule
}
