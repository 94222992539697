import React from 'react'
import { useEditor } from '@tiptap/react'
import type {
  JSONContent,
  EditorOptions,
} from '@tiptap/core/dist/packages/core/src/types'

// Editor default extensions
import { SketchStarterKit } from './extensions/SketchStarterKit'

import { FloatingMenu, BubbleMenu } from './components'
import { EditorContentStyled } from './TextEditor.styles'

interface TextEditorProps {
  content?: JSONContent
  placeholder?: string
  editModeEnabled?: boolean
  onBlur: EditorOptions['onBlur']
}

export const TextEditor = ({
  content = {},
  placeholder = 'Start writing or add a block...',
  onBlur,
  editModeEnabled = true,
}: TextEditorProps) => {
  const editor = useEditor(
    {
      extensions: [SketchStarterKit.configure({ placeholder })],
      content,
      editable: editModeEnabled,
      onBlur: onBlur,
    },
    [editModeEnabled]
  )

  /**
   * Note: a <div /> wrapper is needed in order to make the floating menu work
   * with keyboard (through tabs). Using a react fragment was fine before, but
   * at some point stopped working (I don't know the cause), basically because
   * the floating menu without this <div /> wrapper it's rendered after the
   * libraries section, but with this wrapper it's rendered just after the
   * cursor, where it should be, so tab is jumping to the menu items as expected.
   */
  return (
    <div>
      {/* To have a medium like text formatting through a popover when a text is selected */}
      <BubbleMenu editor={editor} />

      {/* To have a floating menu when starting a new line */}
      <FloatingMenu editor={editor} />

      <EditorContentStyled editor={editor} />
    </div>
  )
}
