import styled from 'styled-components'

import {
  CircleButton,
  Caption,
  Spinner as BaseSpinner,
} from '@sketch/components'
import { ReactComponent as CrossIcon } from '@sketch/icons/cross-16'

export const Spinner = styled(BaseSpinner)``

export const Dropzone = styled.div<{ isDragActive: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-color: ${({ theme, isDragActive }) =>
    isDragActive ? theme.colors.sketch.A : '#d9d9d9'};

  cursor: pointer;
  user-select: none;
  * {
    pointer-events: none;
  }
`

/* stylelint-disable scales/radii */
// TODO: Fix scales/radii stylelint issues - https://github.com/sketch-hq/Cloud/issues/13759
export const Blur = styled.div`
  background-color: rgb(0, 0, 0, 0.35);
  backdrop-filter: blur(3px);
  border-radius: 7px;
`
/* stylelint-enable scales/radii */

export const RemoveButton = styled(CircleButton).attrs({
  icon: CrossIcon,
  buttonSize: '32px',
  size: '16px',
})`
  position: absolute;
  top: -16px;
  left: -16px;
  padding: 0;
  pointer-events: auto;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  opacity: 0;
  visibility: hidden;
  transform: translate(0, -10px);
  transition: ${({ theme }) => `
    opacity ${theme.transitions.duration[2]} ${theme.transitions.timing.easeInOut}
    transform ${theme.transitions.duration[2]} ${theme.transitions.timing.easeInOut}
    visibility ${theme.transitions.duration[2]} ${theme.transitions.timing.easeInOut}
  `};
`

export const UploadLogoCaption = styled(Caption)`
  width: 100%;
  margin-top: 0;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.E};
`

export const ChooseFile = styled.span`
  text-decoration: underline;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  :hover {
    color: ${({ theme }) => theme.colors.foreground.secondary.D};
  }
`

export const PreviewContainer = styled.div`
  position: relative;
  pointer-events: none;
  height: ${({ theme }) => theme.avatarSizes.default}px;
  background-color: ${({ theme }) => theme.colors.sketch.A};
  /* This needs to be white because the background is orange */
  color: white;
  border-radius: ${({ theme }) => theme.radii.xxlarge};

  ${Spinner} {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  ${Blur} {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 88px;
  display: flex;
  align-items: center;
  padding: 12px;

  border-radius: 20px; /* stylelint-disable-line scales/radii */
  border: 1px solid ${({ theme }) => theme.colors.border.B};
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  box-shadow: ${({ theme }) =>
    `${theme.colors.shadow.inner} 0 1px 2px 0 inset;`};

  &:hover ${/* sc-selector */ RemoveButton}:not([disabled]) {
    opacity: 1;
    transform: translate(0, 0);
    visibility: visible;
  }
`
