import React, { useCallback, useContext, useState } from 'react'

import { Stripe } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import { captureException } from '@sentry/core'

import {
  StripeContext,
  StripeContextType,
  StripeLoadingState,
} from './StripeContext'
import { regular } from '@sketch/global-styles/fonts'

type StripeElementsProviderProps = Pick<
  StripeContextType,
  'status' | 'load' | 'stripe'
>

interface StripeProviderProps {
  stripeKey: string
}

export const StripeElementsProvider: React.FC<StripeElementsProviderProps> = props => {
  const { children, stripe, status, load } = props

  return (
    <Elements
      stripe={stripe || null}
      options={{
        fonts: [
          {
            src: `url(${regular}) format('woff2')`,
            family: 'Marfa',
            display: 'optional',
            style: 'normal',
            weight: 'normal',
          },
        ],
      }}
    >
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <StripeContext.Provider
            value={{
              status,
              load,
              // Make sure the stripe elements are consistent with the context provider
              elements: elements || undefined,
              stripe: stripe || undefined,
            }}
          >
            {children}
          </StripeContext.Provider>
        )}
      </ElementsConsumer>
    </Elements>
  )
}

export const StripeProvider: React.FC<StripeProviderProps> = props => {
  const { children, stripeKey } = props
  const [stripe, setStripe] = useState<Stripe | undefined>(undefined)
  const [status, setStatus] = useState<StripeLoadingState>('pending')

  const load = useCallback(async () => {
    try {
      const stripe = await loadStripe(stripeKey)

      if (stripe) {
        setStripe(stripe)
        setStatus('success')

        return stripe
      }

      throw new Error('Stripe failed to initialize')
    } catch (error) {
      setStatus('error')
      captureException(error)
      throw error
    }
  }, [stripeKey])

  return (
    <StripeElementsProvider stripe={stripe} load={load} status={status}>
      {children}
    </StripeElementsProvider>
  )
}

export const useStripe = () => useContext(StripeContext)
