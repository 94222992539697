import { roundWithLocale } from '@sketch/utils'
import {
  Gradient,
  Point,
  formatCssRgbaColor,
} from '../../../../../../inspector'

// Taken from Sketch
// https://github.com/sketch-hq/Sketch/blob/b373bfc2207956a93f23116cae2770fa296c3e9a/Modules/SketchModel/Source/MSLayer%2BCSS.m#L208-L211
const slopeBetweenPoints: (a: Point, b: Point) => number = (a, b) =>
  Math.atan2(b.y - a.y, b.x - a.x)

const radiansToDegrees = (radians: number) => radians * (180.0 / Math.PI)

const gradientAngle: (gradient: Gradient) => number = gradient => {
  const slope = slopeBetweenPoints(gradient.from, gradient.to)
  return 90 + radiansToDegrees(slope)
}

const gradientStops: (gradient: Gradient) => string = gradient => {
  const stops = gradient.stops
    .sort((lhs, rhs) => lhs.position - rhs.position)
    .map(
      stop =>
        `${formatCssRgbaColor(stop.color)} ${roundWithLocale(
          stop.position * 100,
          0
        )}%`
    )
    .join(', ')
  return stops
}

const gradientBackground: (gradient: Gradient) => string = gradient => {
  if (gradient.type === 'angular' || gradient.type === 'radial') {
    return 'transparent'
  }

  const angle = Math.round(gradientAngle(gradient))
  const stops = gradientStops(gradient)
  return `linear-gradient(${angle}deg, ${stops})`
}

export { gradientBackground, gradientAngle, gradientStops }
