import React from 'react'

import { Tooltip } from '@sketch/components'
import { StyledLeftArrow, StyledButton } from './BackButton.styles'
import { useBackButton } from './useBackButton'

type BackButtonProps = {
  className?: string
}

/**
 * Contextual back button for the shares related views so that
 * the user can quickly come back to the previous view they are
 * coming from.
 */
export default function BackButton({ className }: BackButtonProps) {
  const { content, link } = useBackButton()

  if (!link) {
    return null
  }

  return (
    <Tooltip content={content} placement="bottom">
      <StyledButton to={link} className={className} data-testid="back-button">
        <StyledLeftArrow />
      </StyledButton>
    </Tooltip>
  )
}
