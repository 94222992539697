import React from 'react'
import styled from 'styled-components'

import { ActionWrapper, Header, Wrapper } from './AnnotationListHeader.styles'

interface AnnotationListHeaderProps {
  className?: string
  backButton?: React.ReactNode
  action?: React.ReactNode
}

const AnnotationListHeader: React.FC<AnnotationListHeaderProps> = props => {
  const { className, backButton, children, action, ...rest } = props

  return (
    <Wrapper className={className}>
      {backButton}
      <Header {...rest}>{children}</Header>
      {action && <ActionWrapper>{action}</ActionWrapper>}
    </Wrapper>
  )
}

export default styled(AnnotationListHeader)``
