import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "paint-brush-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M5.638 5.604l4.758 4.758a.56.56 0 010 .793l-.829.829a1.121 1.121 0 01-1.586 0l-.776-.776a.56.56 0 00-.793 0l-.024.025-2.07 2.659a1.575 1.575 0 11-2.21-2.21L4.74 9.634a.56.56 0 00.099-.787l-.047-.052-.776-.776a1.121 1.121 0 010-1.586l.83-.83a.56.56 0 01.792 0z", fillOpacity: 0.15, fill: "currentColor" }),
        React.createElement("path", { d: "M10.218 1.786l3.996 3.996a.978.978 0 010 1.383L9.24 12.14a.978.978 0 01-1.383 0l-.873-.873a.489.489 0 00-.691 0l-.021.022h0L4.23 13.911a1.526 1.526 0 11-2.142-2.142l2.6-2.021a.489.489 0 00.085-.686l-.04-.046h0l-.873-.873a.978.978 0 010-1.383l4.974-4.974a.978.978 0 011.383 0z", stroke: "currentColor", strokeWidth: 1.5 }),
        React.createElement("path", { d: "M12.202 4.323l1.05 1.05-2.11 1.408a.25.25 0 01-.348-.346l1.408-2.112zm-1.313-2.101l1.05 1.05-3.148 2.45a.25.25 0 01-.351-.351l2.449-3.149z", fill: "currentColor" }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, d: "M5.636 5.636l4.728 4.728" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
