import React from 'react'
import styled from 'styled-components'
import { useHandleCopyValue } from '@sketch/modules-common'
import {
  AttributeLabel,
  AttributeValue,
  AttributeDisplay,
} from '../../Attribute'
import { ColorDot } from '../ColorDot'
import ColorDropdown from '../../ColorDropdown'
import { Tooltip } from '@sketch/components'
import { rgbTo } from '../utils'
import { ColorFormat, Color as ColorType } from '../../../../../../types'
import copy from 'copy-to-clipboard'
import { DirtyIconTooltip } from '../../Style/DirtyIconTooltip'
import { IS_SHOW_DIRTY_ATTRIBUTES_ENABLED } from '../../../constants'

/**
 * TYPES
 */
export interface PlainColorProps extends ColorType {
  label?: string
  style?: React.CSSProperties
  display?: AttributeDisplay
  dirtyAttributes?: { originalValue: ColorType; originalProperty: string }
  onColorFormatChange: (f: ColorFormat) => void
  colorFormat: ColorFormat
}

/**
 * STYLES
 */
export const ClickableArea = styled(AttributeLabel)`
  cursor: pointer;
  width: 100%;
  display: flex;
  flex: 1;
`

const LabelWithWarning = styled(AttributeLabel)`
  display: flex;
  width: 33.33%;
  align-content: center;
`

const FlexValue = styled(AttributeValue)`
  display: flex;
`

/**
 * UTILS
 */
const copyFormat = (format: ColorFormat, colorParams: ColorType) => {
  copy(rgbTo(format, colorParams))
}

/**
 * COMPONENT
 */
const PlainColor: React.FC<PlainColorProps> = ({
  red,
  green,
  blue,
  alpha = 1,
  label = 'Color',
  onColorFormatChange,
  colorFormat,
  dirtyAttributes,
}) => {
  // Overwrite value if dirty attributes are not enabled
  dirtyAttributes = IS_SHOW_DIRTY_ATTRIBUTES_ENABLED
    ? dirtyAttributes
    : undefined
  const colorParams = { red, green, blue, alpha }
  const copyValue = rgbTo(colorFormat, colorParams)

  const {
    handleCopyValue,
    copyTooltipText,
    handleEnterCopiableArea,
    handleLeaveCopiableArea,
    copyTooltipVisible,
  } = useHandleCopyValue(copyValue)

  const dirtyColorValues = []

  if (dirtyAttributes) {
    dirtyColorValues.push(rgbTo(colorFormat, dirtyAttributes.originalValue))

    if (
      dirtyAttributes.originalValue?.alpha &&
      colorFormat === ColorFormat.HEX
    ) {
      dirtyColorValues.push(
        `opacity ${dirtyAttributes.originalValue?.alpha * 100}%`
      )
    }
  }

  return (
    <Tooltip
      placement="left"
      spacing="10px"
      visible={copyTooltipVisible}
      content={copyTooltipText}
    >
      <ClickableArea
        onClick={handleCopyValue}
        onMouseEnter={handleEnterCopiableArea}
        onMouseLeave={handleLeaveCopiableArea}
      >
        {label && (
          <LabelWithWarning>
            {/* the extra handleEnterCopiableArea is to make sure the copy tooltip is triggered after leaving the dirty icon tooltip */}
            <div
              aria-hidden
              onMouseEnter={
                dirtyAttributes ? handleEnterCopiableArea : undefined
              }
            >
              {label}
            </div>
            {dirtyAttributes && (
              <DirtyIconTooltip
                originalProperty={dirtyAttributes.originalProperty}
                originalValue={dirtyColorValues.join(', ')}
                preventLabelTooltip={handleLeaveCopiableArea}
              />
            )}
          </LabelWithWarning>
        )}
      </ClickableArea>
      <FlexValue
        valueString={copyValue}
        label="Color"
        onMouseEnter={dirtyAttributes ? handleEnterCopiableArea : undefined}
      >
        <ClickableArea
          onClick={handleCopyValue}
          onMouseEnter={handleEnterCopiableArea}
          onMouseLeave={handleLeaveCopiableArea}
        >
          <ColorDot
            red={red}
            green={green}
            blue={blue}
            alpha={alpha}
            format={colorFormat}
          />
        </ClickableArea>
        <ColorDropdown
          onChange={newFormat => {
            copyFormat(newFormat, colorParams)
            onColorFormatChange(newFormat)
          }}
        />
      </FlexValue>
    </Tooltip>
  )
}

export default PlainColor
