import React, { useState } from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

import { routes, RouteParams } from '@sketch/modules-common'

import { useToast } from '@sketch/toasts'
import {
  useGetCollectionsForSelectLazy,
  useMoveAllCollectionShares,
} from '../../operations'
import { useCollectionSearchArgument } from '../../hooks'

import {
  Dropdown,
  Text,
  Modal,
  useModalContext,
  ModalInjectedProps,
  Checkbox,
  Button,
  Link,
  SelectDropdownSubMenu,
  useForDesktop,
} from '@sketch/components'
import {
  CollectionsLoadingState,
  CollectionsErrorState,
} from '../../../shares/components/ShareActionsDropdown/ManageProjectAndCollectionActions'
import {
  Chevron,
  SubMenuTrigger,
} from '../../../shares/components/ShareActionsDropdown/ManageProjectAndCollectionActions.styles'

import {
  CollectionPreviewsFragment,
  CollectionFragment,
  ProjectFragment,
  CollectionForSelectFragment,
  WorkspaceMinimalFragment,
  MoveAllCollectionSharesMutation,
} from '@sketch/gql-types'

interface MoveAllModalProps extends ModalInjectedProps {
  workspaceId: WorkspaceMinimalFragment['identifier']
  destinationCollection: CollectionForSelectFragment
  originCollection: CollectionFragment
}

type CollectionRoute = RouteParams<'WORKSPACE_COLLECTION'>

const MoveAllModal: React.FC<MoveAllModalProps> = ({
  workspaceId,
  originCollection,
  destinationCollection,
  hideModal,
}) => {
  const history = useHistory()
  const { showToast } = useToast()
  const [removeCollection, setRemoveCollection] = useState(false)
  const { collectionId } = useParams<CollectionRoute>()
  const search = useCollectionSearchArgument()

  const [moveAllCollectionShares, { loading }] = useMoveAllCollectionShares({
    workspaceIdentifier: workspaceId,
    onCompleted: ({
      moveAllCollectionShares,
    }: MoveAllCollectionSharesMutation) => {
      const { projectIdentifier, targetCollection } = moveAllCollectionShares

      const linkToTarget = targetCollection
        ? routes.WORKSPACE_COLLECTION.create({
            workspaceId,
            projectId: projectIdentifier,
            collectionId: targetCollection.identifier,
          })
        : routes.WORKSPACE_PROJECT.create({
            workspaceId,
            projectId: projectIdentifier,
          })

      // Only redirect to the target collection if we're deleting the origin collection
      if (removeCollection) {
        // only show toast if we're on the origin collection view
        // (outside that view we have the useCollectionDeleted subscription)
        if (collectionId === originCollection.identifier) {
          showToast(`“${originCollection.name}” collection removed`)
        }

        history.push(linkToTarget)
      }

      hideModal()
      showToast(
        <>
          All documents moved to &quot;
          {targetCollection ? (
            <Link to={linkToTarget}>{destinationCollection.name}</Link>
          ) : (
            destinationCollection.name
          )}
          &quot; collection
        </>,
        'positive'
      )
    },
  })

  const handleMoveClick = () => {
    moveAllCollectionShares({
      variables: {
        originIdentifier: originCollection.identifier,
        targetIdentifier: destinationCollection.identifier,
        removeCollection,
        search,
      },
    })
  }

  return (
    <Modal
      title={`Move All Documents to "${destinationCollection.name}"?`}
      onCancel={hideModal}
    >
      <Modal.Body>
        <Text textStyle="copy.tertiary.standard.E">
          All documents in the &quot;<strong>{originCollection.name}</strong>
          &quot; collection will be moved into the &quot;
          <strong>{destinationCollection.name}</strong>&quot; collection.
        </Text>

        <Checkbox
          value={removeCollection ? 1 : 0}
          name="receiveCommentsNotifications"
          checked={removeCollection}
          data-testid="remove-origin-collection"
          label={`Remove the "${originCollection.name}" collection`}
          onChange={() => setRemoveCollection(!removeCollection)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} onClick={hideModal}>
          Cancel
        </Button>
        <Button
          variant="primary"
          loading={loading}
          disabled={loading}
          onClick={handleMoveClick}
        >
          Move
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

type FetchCollectionState = 'loading' | 'error'

interface MoveAllToProps {
  workspaceId: WorkspaceMinimalFragment['identifier']
  projectId: ProjectFragment['identifier']
  originCollection: CollectionPreviewsFragment
}

export const MoveAllToAction: React.FC<MoveAllToProps> = ({
  workspaceId,
  projectId,
  originCollection,
}) => {
  const { showModal } = useModalContext()
  const isDesktopAndBigger = useForDesktop()
  const isMobileOrTablet = !isDesktopAndBigger
  const [isLoaded, setIsLoaded] = useState(false)

  const [
    getCollections,
    { loading, error, data },
  ] = useGetCollectionsForSelectLazy()

  if (originCollection.previews.length === 0) {
    return null
  }

  let collections: (CollectionForSelectFragment | FetchCollectionState)[] = [
    'loading',
  ]

  if (isMobileOrTablet && !isLoaded) {
    getCollections({ variables: { projectIdentifier: projectId } })
    setIsLoaded(true)
  }

  if (loading) {
    collections = ['loading']
  } else if (error) {
    collections = ['error']
  } else {
    collections = data.filter(
      ({ identifier }) => identifier !== originCollection.identifier
    )
  }

  const handleClickCollection = (
    destinationCollection: CollectionForSelectFragment
  ) =>
    showModal(MoveAllModal, {
      workspaceId,
      destinationCollection,
      originCollection,
    })

  // do not show the move all option if there are no collections and we can't show the submenu stating that
  // (this is to avoid triggering and empty modal)
  if (isMobileOrTablet && collections.length === 0) {
    return null
  }

  return (
    <div
      onMouseEnter={() =>
        getCollections({ variables: { projectIdentifier: projectId } })
      }
    >
      <SelectDropdownSubMenu
        value="Move"
        usePortal
        breakpoint="sm"
        data-hassublevels={true}
        renderTrigger={props => (
          <SubMenuTrigger
            data-hassublevels={true}
            text="Move All Documents"
            additional={<Chevron />}
            {...props}
            disabled={collections.length === 0}
            onClick={() => {
              if (isMobileOrTablet && collections.length === 0) {
                return
              }

              props.onClick()
            }}
          />
        )}
        headerItem={
          collections.length === 0 ? (
            <Dropdown.Item disabled>No collections available</Dropdown.Item>
          ) : (
            <Dropdown.Header>Select a Collection</Dropdown.Header>
          )
        }
        items={collections}
        renderItem={item => {
          if (item === 'loading') {
            return <CollectionsLoadingState />
          } else if (item === 'error') {
            return <CollectionsErrorState />
          }

          return (
            <Dropdown.Item
              onClick={() => handleClickCollection(item)}
              data-testid="move-collection-option"
            >
              {item.name}
            </Dropdown.Item>
          )
        }}
      />
    </div>
  )
}
