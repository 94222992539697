import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "symbol-16", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("path", { id: "symbol-16_svg__a", d: "M7.677 1.646l-4.963 6.09a.417.417 0 000 .527l4.963 6.09a.417.417 0 00.646 0l4.963-6.09a.417.417 0 000-.526l-4.963-6.09a.417.417 0 00-.646 0z" }),
        React.createElement("mask", { id: "symbol-16_svg__b", fill: "#fff" },
            React.createElement("use", { fillRule: "evenodd", xlinkHref: "#symbol-16_svg__a" }))),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("use", { stroke: "currentColor", strokeLinejoin: "round", strokeWidth: 1.5, xlinkHref: "#symbol-16_svg__a" }),
        React.createElement("path", { d: "M8 1.333h5.833v13.334H8z", fill: "currentColor", fillOpacity: 0.15, mask: "url(#symbol-16_svg__b)" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
