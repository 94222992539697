import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'

import { ShouldTrackMutationPredicates } from './subscriptionsMutationsMap.types'

export const getShouldTrackMutationPredicates = (
  getClient: () => ApolloClient<NormalizedCacheObject> | undefined
): ShouldTrackMutationPredicates => {
  return {
    deleteSharePermanently: true,
    emptyWorkspaceTrash: true,
    shareDelete: true,
    restoreShare: true,
    deleteProject: true,
    deleteProjectPermanently: true,
    restoreProject: true,
    addProjectMember: true,
    removeProjectMember: true,
    createAnnotation: true,
    createAnnotationComment: true,
    moveAnnotation: true,
    editComment: true,
    resolveAnnotation: true,
    unResolveAnnotation: true,
    archiveProject: true,
    unarchiveProject: true,
    updateVersionDescription: true,
    versionUpdateKind: true,
  }
}
