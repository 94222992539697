import React, { useEffect } from 'react'
import { dataIdFromObject } from '@sketch/graphql-cache'

import {
  ErrorMessage,
  handleFetchMore,
  Link,
  LoadMoreButton,
  Section,
  Spinner,
} from '@sketch/components'

import { routes } from '@sketch/modules-common'

import BillingHistoryItem from '../BillingHistoryItem'
import { useGetBillingHistoryQuery } from '@sketch/gql-types'
import { dateFormat } from '@sketch/utils'

import { Paragraph } from './BillingHistoryPanel.styles'
import BillingInfoTable from '../BillingInfoTable'

interface BillingHistoryPanelProps {
  customerId: string
  workspaceId: string
  trialEndDate: string
  customerHasPaymentDetails: boolean
  isWorkspaceOnTrial?: boolean
  isEducationWorkspace: boolean
  nextBillingCycleDate?: string
  cancelAtPeriodEnd?: string
  partnerAcceptAt?: string | undefined | null
  partnerName?: string | undefined | null
  isPartner?: boolean
  /** Used to warn the parent component when the data is loaded */
  onLoadComplete: () => void
}

const billingHistoryPath = ['customer', 'billingHistory', 'entries']

export const BillingHistoryPanel: React.FC<BillingHistoryPanelProps> = props => {
  const {
    customerId,
    isWorkspaceOnTrial,
    trialEndDate,
    cancelAtPeriodEnd,
    workspaceId,
    customerHasPaymentDetails,
    partnerAcceptAt,
    partnerName,
    isEducationWorkspace,
    nextBillingCycleDate,
    onLoadComplete,
  } = props

  const { loading, error, data, fetchMore } = useGetBillingHistoryQuery({
    variables: { customerId },
    notifyOnNetworkStatusChange: true,
    /**
     * "cache-and-network" policy doesn't work very well together with pagination
     * In essence when this query is revisited - "refetch" action is triggered
     * which in return throws away all item previously loaded.
     * However, for such infrequently accessed feature as billing
     * this behaviour seems to be acceptable.
     */
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (!loading) onLoadComplete()
  }, [loading, onLoadComplete])

  let content = null

  if (data) {
    const { meta, entries } = data?.customer?.billingHistory || {}
    const billingItems = entries || []

    if (billingItems.length === 0 && !isWorkspaceOnTrial) {
      content = <p>There is no billing history to display.</p>
    } else {
      content = (
        <>
          <br />
          <BillingInfoTable>
            {billingItems.map((item, index) => (
              <BillingHistoryItem item={item} key={index} />
            ))}
            {entries.length !== meta.totalCount && (
              <LoadMoreButton
                onClick={handleFetchMore(fetchMore, billingHistoryPath, {
                  dataIdFromObject,
                  after: meta.after,
                })}
                loading={loading}
              />
            )}
          </BillingInfoTable>
        </>
      )
    }
  } else if (loading) {
    content = <Spinner.Flex primary />
  } else if (error) {
    content = <ErrorMessage.Generic />
  }

  let text = (
    <Paragraph>
      You can view and download all your previous invoices here. If you’ve just
      made a payment, it may take a few hours for it to appear.
    </Paragraph>
  )

  if (partnerName && partnerAcceptAt) {
    text = (
      <Paragraph>
        Your Sketch Partner {partnerName} has been issuing invoices for this
        Workspace since {dateFormat(new Date(partnerAcceptAt))}
      </Paragraph>
    )
  }

  if (isWorkspaceOnTrial) {
    text = (
      <Paragraph>
        You&apos;re currently on a trial which will end on{' '}
        <strong>{dateFormat(new Date(trialEndDate))}</strong>.
        {cancelAtPeriodEnd || !customerHasPaymentDetails
          ? ' After that date your subscription will be cancelled and you will not be charged.'
          : ' Once your subscription starts, billing information and invoices will appear here.'}
        {!customerHasPaymentDetails && (
          <>
            <br />
            <br />
            <Link to={routes.WORKSPACE_SUBSCRIBE.create({ workspaceId })}>
              Set up a subscription
            </Link>{' '}
            to continue using this Workspace.
          </>
        )}
      </Paragraph>
    )
  } else if (isEducationWorkspace && customerHasPaymentDetails) {
    text = (
      <Paragraph>
        You’re currently on an Education plan which will end on{' '}
        <strong>{dateFormat(new Date(nextBillingCycleDate!))}</strong>. Once
        your subscription starts, billing information and invoices will appear
        here.
      </Paragraph>
    )
  }

  return (
    <Section title="Billing History" data-testid="billing-history" text={text}>
      {/* Billing item lines */}
      {content}
    </Section>
  )
}
