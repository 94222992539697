import React, { FC, ReactNode } from 'react'

import { IS_EMBEDDED } from '@sketch/constants'

type NotEmbeddedProps = {
  children: ReactNode
}

/**
 * Only renders its children when the app is in a normal, non-embedded context.
 */
const NotEmbedded: FC<NotEmbeddedProps> = props =>
  !IS_EMBEDDED ? <>{props.children}</> : null

export { NotEmbedded }
