import styled, { css } from 'styled-components'

import { breakpoint } from '@sketch/global-styles'

import {
  ErrorMessage,
  Text,
  FakeLinkButton,
  LinkButton,
  Button,
  Panel,
  SsoTag,
} from '@sketch/components'

export const StyledPanel = styled(Panel)`
  max-width: 520px;
  overflow: visible;
`

export const StyledSsoTag = styled(SsoTag)`
  margin-left: 0;
  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
`

export const UberHeader = styled.div`
  max-width: 400px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  border-radius: ${({ theme }) => theme.radii.xlarge};
`

export const WorkspaceName = styled.strong`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`

export const Title = styled.h1`
  margin: 24px 0px 8px 0px;
  font-size: ${({ theme }) => theme.fontSizes.I};
  text-align: center;
`

export const FormContainer = styled.div`
  margin-top: 24px;
`

export const GenericErrorMessage = styled(ErrorMessage.Generic)`
  flex: 1 1 100%;
`

export const Note = styled(Text.Div).attrs({
  textStyle: 'copy.tertiary.standard.C',
})`
  text-align: center;
  margin: 24px 0px 0px 0px;
`

export const linkCss = css`
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontWeights.regular};

  :not(:last-child) {
    margin-bottom: 8px;
  }
`
export const StyledFakeLinkButton = styled(FakeLinkButton)`
  ${linkCss}
`
export const Description = styled(Text).attrs({
  textStyle: 'copy.primary.standard.F',
})`
  margin: 0;
  padding: 0;
  text-align: center;
`

export const Paragraph = styled.p`
  margin: 0;
  line-height: 1.4;
`

export const StyledButton = styled(Button)`
  flex: 1;
`

export const StyledLinkButton = styled(LinkButton)`
  flex: 1;
`

export const PanelFooter = styled(Panel.Footer)`
  display: flex;
  margin: 0 48px;
  padding: 0;
  background: none;

  ${breakpoint('base', 'xs')`
    margin: 0 16px;
  `}
`
