import React from 'react'
import { Input } from './DecoratedInput.styles'

interface DecoratedInputProps {
  value: string
  label: string
  suffixComponent: React.ReactNode
}
/**
 * This is a component that simulates a disabled input.
 * It shows any component next to the "input" value so we can use it to put
 * icons.
 */
export const DecoratedInput = ({
  value,
  label,
  suffixComponent,
}: DecoratedInputProps) => (
  <Input.Container>
    <Input.Background value="" type="text" disabled />
    <Input.Value.Container>
      <Input.Value aria-label={label}>{value}</Input.Value>
      {suffixComponent}
    </Input.Value.Container>
  </Input.Container>
)
