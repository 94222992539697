import styled from 'styled-components'
import BasePublicationThumbnail from '../PublicationThumbnail'
import BaseHeartButton from '../HeartButton'
import { Text, truncateLines } from '@sketch/components'

export const Name = styled(Text.H1).attrs({
  textStyle: 'copy.tertiary.standard.F',
  $reset: true,
})`
  ${truncateLines(2)}
`

export const NameSkeleton = styled(Name)`
  margin-top: 10px; /* stylelint-disable-line scales/space */
  width: 40%;
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  border-radius: ${({ theme }) => theme.radii.xlarge};

  ::after {
    /* Have some dummy text to fill the height */
    content: 'a';
    opacity: 0;
  }
`

export const WorkspaceProfile = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.C};
  line-height: 1.333; /* stylelint-disable-line scales/line-height */
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
`

export const PublicationThumbnail = styled(BasePublicationThumbnail)`
  width: 100%;
  margin-bottom: 10px; /* stylelint-disable-line scales/space */
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: break-spaces;
`

export const HeartButton = styled(BaseHeartButton)`
  min-width: 64px;
  margin-left: 12px;
`

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  :hover {
    ${PublicationThumbnail} {
      box-shadow: 0 4px 12px 0 ${({ theme }) => theme.colors.border.A},
        0 12px 40px 0 ${({ theme }) => theme.colors.border.A};
    }

    ${Name} {
      color: ${({ theme }) => theme.colors.foreground.secondary.B};
    }
  }
`
