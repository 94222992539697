import { DataProxy } from 'apollo-cache'
import {
  ShareListItemFragment,
  ShareListItemFragmentDoc,
  ShareSubscriptionStatusFragment,
  ShareSubscriptionStatusFragmentDoc,
} from '@sketch/gql-types'
import { dataIdFromObject } from '@sketch/graphql-cache'
import { ErrorHandler } from '@sketch/tracing'
import { castError } from '@sketch/utils'

interface ReadShareListItemFromCache {
  cache: DataProxy
  id: string
}

export const readShareListItemFromCache = ({
  cache,
  id,
}: ReadShareListItemFromCache) => {
  try {
    return (
      cache.readFragment<ShareListItemFragment>({
        id: dataIdFromObject({
          __typename: 'Share',
          identifier: id,
        }) as string,
        fragment: ShareListItemFragmentDoc,
        fragmentName: 'ShareListItem',
      }) || undefined
    )
  } catch (e) {
    const err = castError(e)
    //readFragment can throw errors if there isn't sufficient data in the cache
    ErrorHandler.ignore(
      err,
      'by design, we ignore errors when using readShareFromCache util'
    )
    return undefined
  }
}

interface WriteShareListItemToCache {
  cache: DataProxy
  share: ShareListItemFragment
}

export const writeShareListItemToCache = ({
  cache,
  share,
}: WriteShareListItemToCache) =>
  cache.writeFragment<ShareListItemFragment>({
    id: dataIdFromObject(share) as string,
    fragment: ShareListItemFragmentDoc,
    fragmentName: 'ShareListItem',
    data: share,
  })

export const shareSubscriptionStatus = {
  read: (cache: DataProxy, identifier: string) => {
    try {
      return cache.readFragment<ShareSubscriptionStatusFragment>({
        fragment: ShareSubscriptionStatusFragmentDoc,
        fragmentName: 'ShareSubscriptionStatus',
        id: dataIdFromObject({ __typename: 'Share', identifier }) as string,
      })
    } catch (e) {
      const err = castError(e)
      ErrorHandler.ignore(err)
      return null
    }
  },
  write: (
    cache: DataProxy,
    share: ShareSubscriptionStatusFragment & { identifier: string }
  ) =>
    cache.writeFragment({
      fragment: ShareSubscriptionStatusFragmentDoc,
      fragmentName: 'ShareSubscriptionStatus',
      id: dataIdFromObject(share) as string,
      data: share,
    }),
}

export const readSharesByIdentifiers = ({
  cache,
  shareIds,
}: {
  cache: DataProxy
  shareIds: string[]
}) => {
  const shares: ShareListItemFragment[] = []

  shareIds.forEach(identifier => {
    const share = readShareListItemFromCache({
      cache,
      id: identifier,
    })

    if (share) {
      shares.push(share)
    }
  })

  return shares
}
