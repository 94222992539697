import styled from 'styled-components'

import { Pluralize } from '@sketch/components'

export const SeatsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`
export const Title = styled.h5`
  margin: 0;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`

export const StyledPluralize = styled(Pluralize)`
  display: inline;
`
