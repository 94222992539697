import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "license-diamond-48", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 48 48", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M37.59 9c1.946 0 2.665.167 3.38.55a3.557 3.557 0 011.48 1.48c.383.715.55 1.434.55 3.38h0v19.18c0 1.946-.167 2.665-.55 3.38a3.557 3.557 0 01-1.48 1.48c-.715.383-1.434.55-3.38.55h0-27.18c-1.946 0-2.665-.167-3.38-.55a3.557 3.557 0 01-1.48-1.48c-.383-.715-.55-1.434-.55-3.38h0V14.41c0-1.946.167-2.665.55-3.38a3.557 3.557 0 011.48-1.48C7.744 9.167 8.463 9 10.41 9h0z", stroke: "currentColor", strokeWidth: 2, fill: "#F9BB97" }),
        React.createElement("path", { d: "M41.564 13.004l1.436.17v1.479a.115.115 0 00-.02.004l-.04.023a.13.13 0 00-.018.177l.078.102v5.856h-6.883a.273.273 0 01-.124-.029l-.054-.036a.309.309 0 01-.044-.42l4.183-5.473a.131.131 0 00-.01-.169.116.116 0 00-.17.001l-5.664 5.988a.221.221 0 01-.172.07.234.234 0 01-.216-.25l.195-4.337a.118.118 0 00-.088-.122.114.114 0 00-.136.09l-1.073 4.992a.466.466 0 01-.348.361l-2.658.603a.118.118 0 00-.09.116c0 .066.051.12.114.12h2.652c.164 0 .315.093.396.243l6.36 11.927c.043.082.14.115.22.075a.183.183 0 00.082-.238L34.33 22.83a.373.373 0 01-.032-.153.35.35 0 01.34-.357L43 22.319v14.992l-1.284 1.584a.275.275 0 01-.432 0L28.136 22.681a.62.62 0 01-.024-.742l5.518-7.818a.563.563 0 01.393-.238l7.413-.88a.54.54 0 01.128 0z", fill: "currentColor" }),
        React.createElement("rect", { fill: "currentColor", x: 10, y: 18, width: 12, height: 2, rx: 1 }),
        React.createElement("rect", { fill: "currentColor", x: 10, y: 14, width: 8, height: 2, rx: 1 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
