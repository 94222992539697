import React from 'react'

import {
  LoadingPlaceholder,
  pluralize,
  HighlightedText,
} from '@sketch/components'

import {
  ArtboardImageWrapper,
  ArtboardTitle,
  ArtboardWrapper,
  CommentCopy,
  Image,
  ImageComponent,
} from './Artboard.styles'

interface ArtboardProps {
  className?: string
  title: string
  imageSrc?: string
  commentCount?: number
  hasNewComments?: boolean
  srcSet?: string
  search?: string
  overlay?: React.ReactNode
  showTitle?: boolean
}

const Artboard: React.FC<ArtboardProps> = props => {
  const {
    className,
    title,
    imageSrc,
    commentCount = 0,
    hasNewComments,
    srcSet,
    search = '',
    overlay,
    showTitle = true,
  } = props

  return (
    <ArtboardWrapper className={className}>
      <ArtboardImageWrapper>
        <Image
          alt={`${title} artboard`}
          customImageElement={ImageComponent}
          src={imageSrc}
          srcSet={srcSet}
          loadingPlaceholder={<LoadingPlaceholder size="64px" />}
        />

        {overlay}
      </ArtboardImageWrapper>

      {showTitle && (
        <ArtboardTitle>
          <HighlightedText search={search}>{title}</HighlightedText>
        </ArtboardTitle>
      )}

      {!!(commentCount > 0) && (
        <CommentCopy
          data-testid="comment-text"
          $hasNewComments={hasNewComments}
        >
          {commentCount} {pluralize('Comment', 'Comments', commentCount)}
        </CommentCopy>
      )}
    </ArtboardWrapper>
  )
}

export default Artboard
