import styled from 'styled-components'
import { ReactComponent as PersonIcon } from '@sketch/icons/person-circle-64'

export const EmptyTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 208px;
  text-align: center;
  padding-top: 32px;

  p:first-of-type {
    padding-bottom: 4px !important; /* stylelint-disable-line declaration-no-important */
  }
`

export const PersonIconStyled = styled(PersonIcon)`
  opacity: 0.3;
`
