import React, { FC, useState, useEffect } from 'react'
import { useTransition, config } from 'react-spring'

import {
  StyledButton,
  CopiedCodesButtonText,
  CopyText,
  AnimatedDiv,
} from './CopyCodesButton.styles'

import { ReactComponent as CheckIcon } from '@sketch/icons/check-16'

interface CopyCodesButtonProps {
  codes: string[]
}

export const CopyCodesButton: FC<CopyCodesButtonProps> = ({ codes }) => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 2000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [copied])

  const handleClick = () => {
    if (copied) return

    const parsedRecoveryCodes = codes.join().replaceAll(',', ' \n')

    navigator.clipboard.writeText(parsedRecoveryCodes)
    setCopied(true)
  }

  const transitions = useTransition(copied, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.wobbly,
  })

  return (
    <StyledButton onClick={handleClick} smaller>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <AnimatedDiv key={key} style={props}>
              <CopiedCodesButtonText>
                <CheckIcon width="16px" /> Copied
              </CopiedCodesButtonText>
            </AnimatedDiv>
          )
      )}
      {!copied && <CopyText>Copy to Clipboard</CopyText>}
    </StyledButton>
  )
}
