import React from 'react'

import { Tooltip, PersonAvatar } from '@sketch/components'

import {
  ResolutionFooterContainer,
  ResolutionFooterTooltipContent,
  ResolutionFooterCheckIcon,
  ResolutionFooterUsername,
  ResolutionFooterDate,
} from './AnnotationPopoverResolutionFooter.styles'

import { CommentUserFragment } from '@sketch/gql-types'

interface AnnotationPopoverResolutionFooterProps {
  user?: CommentUserFragment | null
  resolvedDate: string
}

const AnnotationPopoverResolutionFooter: React.FC<AnnotationPopoverResolutionFooterProps> = props => {
  const { user, resolvedDate } = props
  let content

  if (user) {
    const userName = user.name?.split(' ')[0] || ''
    content = (
      <Tooltip
        disabled={!user}
        content={
          <ResolutionFooterTooltipContent>
            <PersonAvatar
              flavour="image"
              size="16px"
              src={user.avatar!.small!}
              name={user.name!}
            />
            <span>{user.name}</span>
          </ResolutionFooterTooltipContent>
        }
        placement="bottom"
      >
        {userName}
      </Tooltip>
    )
  } else {
    content = 'a deleted user'
  }

  return (
    <ResolutionFooterContainer>
      <ResolutionFooterCheckIcon />
      <ResolutionFooterUsername>Resolved by {content}</ResolutionFooterUsername>
      <ResolutionFooterDate date={resolvedDate} />
    </ResolutionFooterContainer>
  )
}

export default AnnotationPopoverResolutionFooter
