import React, { useRef, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { EMBEDDED_SIGNIN_POPUP_WINDOW_NAME } from '@sketch/constants'

import { Button, PageLayout } from '@sketch/components'

import { createLocalStorageSubscription } from '@sketch/utils'
import {
  useUserSignedIn,
  EmptyHeader,
  routes,
  useSignOut,
} from '@sketch/modules-common'

import { ReactComponent as LockIcon } from '@sketch/icons/lock-64'
import { MessageContainer } from './RequiresSignInEmbeddedView.styles'

type StorageEventListener =
  | ((event: StorageEvent) => void | undefined)
  | undefined

const useSignInPopup = (onSignIn: () => void) => {
  const popupRef = useRef<Window | null>(null)
  const storageListener = useRef<StorageEventListener>()

  // cleanup popup window and listener references
  const reset = useCallback(() => {
    window.removeEventListener('storage', storageListener.current!)
    storageListener.current = undefined
    popupRef.current?.close()
    popupRef.current = null
  }, [])

  // open signin window
  const open = useCallback(() => {
    // cleanup any previous instance
    reset()
    // open window
    popupRef.current = window.open(
      routes.SIGN_IN.create({}),
      EMBEDDED_SIGNIN_POPUP_WINDOW_NAME,
      'toolbar=0,location=0,menubar=0,width=640,height=700'
    )
    // wait for storage change following successful sign-in
    const subscription = createLocalStorageSubscription()
    storageListener.current = subscription?.trackStorageChanges(
      'userProfile',
      () => {
        reset()
        onSignIn()
      }
    )
  }, [reset, onSignIn])

  // cleanup on unmount
  useEffect(() => reset, [reset])

  return { open, close: reset }
}

const RequiresSignInEmbeddedView = () => {
  const location = useLocation()
  const isUserSignedIn = useUserSignedIn()
  const signOut = useSignOut({
    location,
    reason: 'Switching account in Embedded View',
  })
  const onSignIn = () => window.location.reload()
  const { open: openSignInWindow } = useSignInPopup(onSignIn)

  // sign out and reload
  const switchAccount = () => {
    signOut()
    window.location.reload()
  }

  return (
    <PageLayout header={<EmptyHeader />}>
      <MessageContainer
        icon={<LockIcon />}
        iconSize="medium"
        title="The document you’re trying to access is private"
        extra={
          isUserSignedIn ? (
            <Button onClick={switchAccount}>Switch Account</Button>
          ) : (
            <Button onClick={openSignInWindow}>Sign In</Button>
          )
        }
      >
        You don’t have permission from the owner to view this document.
      </MessageContainer>
    </PageLayout>
  )
}

export default RequiresSignInEmbeddedView
