import React, { useContext } from 'react'
import { LoadingPageContext } from './LoadingPageContext'

import { DynamicLoadingPropsByKey } from './DynamicLoadingPage.types'

/**
 * This component loads through context a different loading page based on the current
 * route.
 * See RouteBasedLoadingPage.tsx to configure what loading page to use for
 * your route.
 */
export const DynamicLoadingPage = (props?: DynamicLoadingPropsByKey<any>) => {
  const { component: Component } = useContext(LoadingPageContext)

  // Typescript safeguard
  if (props && 'routeKey' in props) {
    return <Component {...props} />
  }

  return <Component />
}
