import styled from 'styled-components'
import { ButtonBaseStyle, MenuIconButton } from '@sketch/components'
import PreventiveVersionLink from '../../../versioning/components/PreventiveVersionLink'

export const LatestVersionLink = styled(PreventiveVersionLink)`
  ${ButtonBaseStyle}

  margin-top: 16px;

  min-height: 32px;
  padding: 4px 12px;
  font-size: ${({ theme }) => theme.fontSizes.D};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  box-shadow: none;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  background-color: ${({ theme }) => theme.colors.sketch.B};
  border: none;

  &:hover {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
    background-color: ${({ theme }) => theme.colors.sketch.B};
  }
`

export const AddAnnotationButton = styled(MenuIconButton)`
  [role='img'] {
    width: 16px;
  }
`
