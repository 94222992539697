import React from 'react'

import { Dropdown } from '@sketch/components'

import AnnotationSubscriptionStatus from '../AnnotationSubscriptionStatus'
import CommentOptionDropdown from '../CommentOptionDropdown'
import CopyAnnotationLink from '../CopyAnnotationLink'
import DeleteCommentOption from '../DeleteCommentOption'
import { useMultipleCommentContext } from '../CommentEditContext'

import { useGetBaseCommentQuery } from '@sketch/gql-types'

// This is a valid use case to narrow down Component type
// eslint-disable-next-line no-restricted-imports
import { NotificationSubscriptionStatus } from '@sketch/gql-types/expansive'

interface AnnotationPopoverOptionsDropdownProps {
  annotationIdentifier: string
  firstCommentIdentifier: string
  notificationStatus: NotificationSubscriptionStatus
  isResolved: boolean
}

/**
 * Right clicking on the dropdown options was triggering the canvas
 * menu, so we are stopping propagation here to avoid it
 */
const stopPropagation = (event: React.MouseEvent) => {
  event.stopPropagation()
}

const AnnotationPopoverOptionsDropdown = (
  props: AnnotationPopoverOptionsDropdownProps
) => {
  const {
    annotationIdentifier,
    firstCommentIdentifier,
    notificationStatus,
    isResolved,
  } = props

  const { requestEditingComment } = useMultipleCommentContext()

  /**
   *  We use the client "useGetBaseCommentQuery" with the "cache-only" fetch
   *  policy to make "listen" to comment in cache.
   *
   *  We are using a apollo redirect to get this information from cache
   */
  const { data: commentData } = useGetBaseCommentQuery({
    variables: { identifier: firstCommentIdentifier },
    fetchPolicy: 'cache-only',
  })

  const { comment } = commentData || {}
  let content = (
    <>
      <AnnotationSubscriptionStatus
        annotationIdentifier={annotationIdentifier}
        notificationStatus={notificationStatus}
        variant="dropdown"
      />
      <Dropdown.Divider />
      <CommentOptionDropdown
        annotationIdentifier={annotationIdentifier}
        commentIdentifier={firstCommentIdentifier}
        hasReplies
        isFirstComment
        showCopyLink
      />
    </>
  )

  if (isResolved) {
    content = (
      <>
        <CopyAnnotationLink annotationIdentifier={annotationIdentifier} />

        {(comment?.userIsCreator || comment?.userCanDelete) && (
          <Dropdown.Divider />
        )}

        {comment?.userIsCreator && (
          <Dropdown.Item
            onClick={() => {
              requestEditingComment(firstCommentIdentifier)
            }}
          >
            Edit…
          </Dropdown.Item>
        )}

        {comment?.userCanDelete && (
          <DeleteCommentOption
            annotationIdentifier={annotationIdentifier}
            isAnnotation
            hasReplies
          />
        )}
      </>
    )
  }

  return <div onContextMenu={stopPropagation}>{content}</div>
}

export default AnnotationPopoverOptionsDropdown
