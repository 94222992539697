import { useCallback, useContext } from 'react'
import { ModalContext } from '@sketch/components'
import { ErrorHandler } from '@sketch/tracing'
import { useHistory } from 'react-router-dom'
import { mapSsoStartUrl, routes } from '@sketch/modules-common'
import SignInWithPasswordModal from '../modals/SignInWithPasswordModal'
import {
  PublicWorkspaceSsoIdentityFragment,
  WorkspaceSsoIdentityFragment,
} from '@sketch/gql-types'

type Workspace =
  | PublicWorkspaceSsoIdentityFragment
  | WorkspaceSsoIdentityFragment

interface PopupCentered {
  url: string
  title: string
  w: number
  h: number
}

const openPopupCentered = ({ url, title, w, h }: PopupCentered) => {
  const width = global.screen.width
  const height = global.screen.height

  const systemZoom = width / window.screen.availWidth
  const left = (width - w) / 2
  const top = (height - h) / 2

  const popup = window.open(
    url,
    title,
    `
        scrollbars=yes,
        width=${w / systemZoom},
        height=${h / systemZoom},
        top=${top},
        left=${left}
        popup=yes
        `
  )

  return popup
}

interface SignInPersonalAccount {
  workspaceName: string
  onCompleted?: () => void
  invitationEmail?: string
}

interface SignInSSOWorkspace {
  workspace: Workspace
  redirectBackToCurrentUrl?: boolean
  showPopup?: boolean
  onPopup?: (window: Window) => void
}

type SignInGeneric = SignInSSOWorkspace &
  OmitSafe<SignInPersonalAccount, 'workspaceName'>

export const useSignInToUnauthorizedWorkspace = () => {
  const { showModal } = useContext(ModalContext)
  const history = useHistory()

  const signInToPersonalAccount = useCallback(
    ({
      workspaceName,
      onCompleted,
      invitationEmail,
    }: SignInPersonalAccount) => {
      showModal(SignInWithPasswordModal, {
        workspaceName,
        onCompleted,
        invitationEmail,
      })
    },
    [showModal]
  )

  const signInToSSOAccount = useCallback(
    ({
      workspace,
      redirectBackToCurrentUrl,
      showPopup,
      onPopup,
    }: SignInSSOWorkspace) => {
      const { identifier, customer } = workspace

      if (customer?.ssoEnabled && !customer.ssoStartUrl) {
        ErrorHandler.shouldNeverHappen(
          `Workspace with id ${identifier} is configured with SSO but does not have any ssoStartUrl field.`
        )
        return
      }

      if (customer?.ssoEnabled) {
        if (showPopup) {
          const popupWindow = openPopupCentered({
            url: mapSsoStartUrl(customer.ssoStartUrl!, {
              source: 'cloud-popup',
            }),
            title: `${workspace.name} Sign-in`,
            w: 600,
            h: 500,
          })

          popupWindow && onPopup?.(popupWindow)
        } else {
          window.location.href = mapSsoStartUrl(customer.ssoStartUrl!, {
            from: redirectBackToCurrentUrl
              ? history.location.pathname
              : undefined,
          })
        }
      }
    },
    [history]
  )

  const signIn = useCallback(
    ({
      workspace,
      redirectBackToCurrentUrl,
      onCompleted,
      showPopup,
      onPopup,
    }: SignInGeneric) => {
      const { identifier, customer, userRole } = workspace

      if (userRole !== 'GUEST' && customer?.ssoEnabled) {
        return signInToSSOAccount({
          workspace,
          redirectBackToCurrentUrl,
          showPopup,
          onPopup,
        })
      }

      const workspaceId = identifier
      const preventiveOnCompleted =
        onCompleted ||
        (() => {
          history.push(routes.WORKSPACE_SHARES.create({ workspaceId }))
        })

      return signInToPersonalAccount({
        workspaceName: workspace.name,
        onCompleted: preventiveOnCompleted,
      })
    },
    [signInToSSOAccount, signInToPersonalAccount, history]
  )

  return {
    signInToPersonalAccount,
    signInToSSOAccount,
    signIn,
  }
}
