/* istanbul ignore file */

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'
import 'intersection-observer'
import objectFitImages from 'object-fit-images'
import smoothscroll from 'smoothscroll-polyfill'

// Fixes issues with Safari < 11 in private browsing mode
if (typeof window.localStorage === 'object') {
  try {
    window.localStorage.setItem('localStorage', 1)
    window.localStorage.removeItem('localStorage')
  } catch (e) {
    window.Storage.prototype._setItem = window.Storage.prototype.setItem
    window.Storage.prototype._getItem = window.Storage.prototype.getItem
    window.Storage.prototype._removeItem = window.Storage.prototype.removeItem
    window.Storage.prototype.setItem = function () {}
    window.Storage.prototype.getItem = function () {}
    window.Storage.prototype.removeItem = function () {}
  }
}

// Add support for IE11 HTMLElement ".remove"
// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md

const removePolyfill = function (arr) {
  arr.forEach(function (item) {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty('remove')) {
      return
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode === null) {
          return
        }
        this.parentNode.removeChild(this)
      },
    })
  })
}

removePolyfill([
  Element.prototype,
  CharacterData.prototype,
  DocumentType.prototype,
])

/*
 * Enable object-fit properties in IE11 to work correctly
 * Read more about it in https://github.com/fregante/object-fit-images
 */
objectFitImages()

/**
 * Enable scrollTo properties to work in IE11
 * Read more in https://github.com/iamdustan/smoothscroll
 */
smoothscroll.polyfill()

/**
 * To detect you are in IE (for this case) by checking typeof(Event) which is
 * 'function' for all except IE where it is 'object'.
 * You can then safely polyfill the Event constructor using the approach above.
 * In IE11 it seems to be safe to set window.Event = CustomEvent.
 *
 * This code was copied from https://github.com/streamich/react-use/issues/73
 */
;(function () {
  if (typeof window.Event === 'function') return false //If not IE

  function CustomEvent(event, params) {
    params = params || {
      bubbles: false,
      cancelable: false,
      detail: undefined,
    }
    const evt = document.createEvent('CustomEvent')
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    return evt
  }

  CustomEvent.prototype = window.Event.prototype

  window.Event = CustomEvent
})()

/**
 * Solution to fix the usage of 100vh in safari
 * https://www.bram.us/2020/05/06/100vh-in-safari-on-ios/
 *
 * It allows a property to have a work-around since 100vh in safari mobile differs from the expected
 * behaviour
 */
const setVh = () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

window.addEventListener('load', setVh)
window.addEventListener('resize', setVh)
