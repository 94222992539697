export type Identifier = string | null | undefined

export const getNameOrEmailFromMembership = (
  isMemberCurrentUser: boolean,
  name: string,
  email?: string
) => {
  if (isMemberCurrentUser) {
    if (!email) {
      return `${name} (You)`
    }

    return `${name || email} (You)`
  }

  return name || email || ''
}
