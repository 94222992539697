import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "trash.empty", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: 1, fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "trash-empty", transform: "translate(2 2)" },
            React.createElement("path", { d: "M2.5 2.5h11l-.846 10.997c-.085 1.106-1.05 2.003-2.151 2.003H5.497c-1.103 0-2.066-.894-2.151-2.003L2.5 2.5z", id: "Rectangle-6", stroke: "currentColor", fillOpacity: 0.2, fill: "currentColor" }),
            React.createElement("rect", { id: "Rectangle", fill: "currentColor", x: 0.5, y: 2, width: 15, height: 1, rx: 0.5 }),
            React.createElement("path", { d: "M5.5 1.5c0-.552.444-1 1-1h3c.552 0 1 .444 1 1v1h-5v-1z", id: "Rectangle-2", stroke: "currentColor" }))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
