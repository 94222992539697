import {
  GetInitialUserDocument,
  GetInitialUserQuery,
  GetInitialUserQueryVariables,
} from '@sketch/gql-types'
import { ErrorHandler } from '@sketch/tracing'
import { useApolloClient } from 'react-apollo'
import { castError } from '@sketch/utils'

/**
 *
 * A promisified getInitialUser, used to fetch the user data always using the network
 * Useful when we need to fetch user data after manually setting authorizations
 *
 * Example: useSsoSuccessfulSignIn  / VerificationCodeView
 */
export const useFetchInitialUser = () => {
  const apolloClient = useApolloClient()

  return async () => {
    try {
      const { data } = await apolloClient.query<
        GetInitialUserQuery,
        GetInitialUserQueryVariables
      >({
        query: GetInitialUserDocument,
        fetchPolicy: 'network-only',
      })

      return data?.me
    } catch (e) {
      const err = castError(e)
      ErrorHandler.ignore(err, 'Unable to get initial user')
      return undefined
    }
  }
}
