import styled, { css } from 'styled-components'

import { Box } from '../Box'
import { Caption as UnstyledCaption } from '../Caption'

import { ReactComponent as CheckIcon } from '@sketch/icons/check-12'

const checkboxSize = '16px'

export const CheckmarkIcon = styled(CheckIcon)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  color: white;
  pointer-events: none;
`

export const Container = styled(Box)<{ disabled?: boolean }>`
  display: flex;
  position: relative;
  margin-bottom: 0;
  line-height: 1.4;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

interface CheckboxInputProps {
  checked?: boolean
  variant?: 'primary' | 'untinted'
  disabled?: boolean
}

export const CheckboxInput = styled.span<CheckboxInputProps>(
  ({ theme, checked, variant, disabled }) => css`
    display: inline-block;
    vertical-align: middle;
    position: relative;

    width: ${checkboxSize};
    height: ${checkboxSize};
    padding: 0;
    margin-right: 12px;

    &:not(:last-child) {
      margin-right: 12px;
    }

    transition: background-color
      ${`${theme.transitions.duration[1]} ${theme.transitions.timing.easeOut}`};

    border-width: 1px;
    border-style: solid;
    border-color: ${theme.colors.border.B};
    background: ${theme.colors.background.secondary.A};
    border-radius: 3px;

    cursor: ${disabled ? 'not-allowed' : 'pointer'};

    align-items: flex-start;
    justify-content: center;
    flex-shrink: 0;

    /* enough space to have a 1px white line between focus ring and radio button, to give enough contrast */
    outline-offset: 3px;

    ${checked &&
    variant === 'primary' &&
    css`
      border-color: ${theme.colors.sketch.A};
      background-color: ${theme.colors.sketch.A};
    `}

    ${checked &&
    variant === 'untinted' &&
    css`
      border-color: ${theme.colors.background.primary.B};
      background-color: ${theme.colors.background.primary.B};
    `}
  `
)

export const HiddenInput = styled.input<{ disabled?: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  appearance: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

interface CheckboxLabelProps {
  disabled: boolean
}

export const CheckboxLabel = styled(Box)<CheckboxLabelProps>(
  ({ theme, disabled }) => css`
    font-weight: ${theme.fontWeights.regular};
    opacity: ${disabled ? 0.5 : 1};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    line-height: 1.6;
    color: ${theme.colors.foreground.secondary.B};
  `
)

export const CheckboxLabelText = styled.span(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.D};
  `
)

export const Caption = styled(UnstyledCaption)(
  ({ theme }) => css`
    margin: 0;
    font-size: ${theme.fontSizes.D};
    color: ${theme.colors.foreground.secondary.D};
    line-height: 1.4;
    padding-top: 2px; /* stylelint-disable-line scales/space */
  `
)

export const IconWrapper = styled.div`
  display: inline-flex;
  height: 16px;
  width: 16px;
  position: relative;
`

export const LoadingWrapper = styled.div`
  display: inline-block;
  position: absolute;

  top: -1px;
  left: 8px;
`

export const SuccessWrapper = styled.div(
  ({ theme }) => css`
    position: absolute;
    display: inline-block;

    top: 4px;
    left: 8px;

    color: ${theme.colors.state.positive.A};
  `
)
