/**
 * Determines if the app is currently rendering in an embedded context. This is
 * indicated by the path basename `/embed`. While this is active the app should
 * render a minimal UI for document shares and its subpaths.
 *
 * Although derived dynamically this value is treated as a constant
 * since it's value stays constant for the lifetime of the app.
 */
export const IS_EMBEDDED: boolean =
  typeof window !== 'undefined'
    ? window.location.pathname.startsWith('/embed')
    : false

/**
 * Window name used for login via popup window.
 */
export const EMBEDDED_SIGNIN_POPUP_WINDOW_NAME = 'sketch-signin-popup'
