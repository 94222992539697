import { InviteStatus } from './types'

type Identifier = string | null | undefined

export const hasRequestedAccess = (inviteStatus?: InviteStatus) =>
  inviteStatus === 'REQUESTED'

export const isMemberCurrentUser = (
  memberIdentifier: Identifier,
  userIdentifier: Identifier
) => memberIdentifier === userIdentifier
