import React, { useRef } from 'react'
import { History } from 'history'
import { Router } from 'react-router-dom'

import {
  ConnectedFlagsProvider,
  NotificationSearchProvider,
  SharesTrackingProvider,
} from 'cloud-frontend'
import {
  EventEmitterProvider,
  createLocalStorageSubscription,
  LayoutPortalsProvider,
} from '@sketch/utils'
import { ToastProvider } from '@sketch/toasts'
import { DevToolsSettingsProvider, UsageMetricsImport } from '@sketch/devtools'

import {
  StaticGlobalStyles,
  DynamicGlobalStyles as DynamicGlobalStylesBase,
  ThemeManager,
} from '@sketch/global-styles'
import {
  ErrorBoundary,
  selectStyles,
  BannerProvider,
  ModalProvider,
} from '@sketch/components'
import { useGetAnalyticsContext } from '@sketch/analytics'
import {
  GenericErrorView,
  LoadingPageProvider,
  StripeProvider,
  AnalyticsContext,
  AnalyticsProvider as AnalyticsProviderRaw,
  defaultFailContext,
} from '@sketch/modules-common'
import { useExtraApolloLinks } from './useExtraApolloLinks'
import { ApolloProvider as ApolloProviderRaw } from '../graphql/ApolloProvider'
import { WebsocketProvider } from './WebsocketProvider'
interface ProvidersProps {
  history: History
}

// Changes on the authorizations list will refresh non-visible tabs
const localStorageSubscription = createLocalStorageSubscription()
localStorageSubscription.trackStorageChanges('userAllAuthorizations', () => {
  document.hidden && window.location.reload()
})

interface AnalyticsProviderProps {
  /**
   * There is a circular dependency, to create AnalyticsContext we need to have ApolloProvider mounted, and to create
   * apolloClient for the ApolloProvider we need to have AnalyticsContext.
   *
   * To solve this we are postponing analyticsContext initialization and passing down just a getter to get analytics.
   */
  analyticsRef: React.MutableRefObject<AnalyticsContext>
}
const AnalyticsProvider = (
  props: React.PropsWithChildren<AnalyticsProviderProps>
) => {
  const { analyticsRef } = props
  const context = useGetAnalyticsContext()
  analyticsRef.current = context

  return (
    <AnalyticsProviderRaw value={context}>
      {props.children}
    </AnalyticsProviderRaw>
  )
}

interface ApolloProviderProps extends ProvidersProps {
  getAnalytics: () => AnalyticsContext
}

const ApolloProvider: React.FC<ApolloProviderProps> = props => {
  const { history, children, getAnalytics } = props
  const extraLinks = useExtraApolloLinks({ history, getAnalytics })

  return (
    <ApolloProviderRaw extraLinks={extraLinks}>{children}</ApolloProviderRaw>
  )
}

const DynamicGlobalStyles = () => {
  return (
    <DynamicGlobalStylesBase
      userAgent={window.navigator.userAgent}
      // TODO: Make styles of Selector component self contained and remove this property
      //  see: https://github.com/sketch-hq/Cloud/issues/14547
      extraStyles={selectStyles}
    />
  )
}

const Providers: React.FC<ProvidersProps> = ({ children, history }) => {
  const analyticsRef = useRef<AnalyticsContext>(defaultFailContext)
  const getAnalytics = () => analyticsRef.current

  return (
    <>
      <StaticGlobalStyles />
      <ErrorBoundary>
        <UsageMetricsImport>
          <EventEmitterProvider>
            <ToastProvider>
              <ApolloProvider history={history} getAnalytics={getAnalytics}>
                <WebsocketProvider>
                  <AnalyticsProvider analyticsRef={analyticsRef}>
                    <Router history={history}>
                      <DevToolsSettingsProvider>
                        <ConnectedFlagsProvider>
                          <StripeProvider>
                            <BannerProvider>
                              <ErrorBoundary
                                fallbackComponent={GenericErrorView}
                              >
                                <SharesTrackingProvider>
                                  <ThemeManager>
                                    <LoadingPageProvider>
                                      <ModalProvider>
                                        <DynamicGlobalStyles />
                                        <LayoutPortalsProvider>
                                          <NotificationSearchProvider>
                                            {children}
                                          </NotificationSearchProvider>
                                        </LayoutPortalsProvider>
                                      </ModalProvider>
                                    </LoadingPageProvider>
                                  </ThemeManager>
                                </SharesTrackingProvider>
                              </ErrorBoundary>
                            </BannerProvider>
                          </StripeProvider>
                        </ConnectedFlagsProvider>
                      </DevToolsSettingsProvider>
                    </Router>
                  </AnalyticsProvider>
                </WebsocketProvider>
              </ApolloProvider>
            </ToastProvider>
          </EventEmitterProvider>
        </UsageMetricsImport>
      </ErrorBoundary>
    </>
  )
}

export default Providers
