import styled, { css } from 'styled-components'

const skeleton = css`
  background: ${({ theme }) => theme.colors.state.hover};
  border-radius: ${({ theme }) => theme.radii.medium};
`

export const ListHeader = styled.div(
  ({ theme }) => css`
    padding: 8px 12px;
    line-height: 1.2;

    text-transform: uppercase;
    transition: color 125ms ease-in-out;
    user-select: none;
    font-size: ${theme.fontSizes.B};
    font-weight: ${theme.fontWeights.semibold};
    color: ${theme.colors.foreground.secondary.C};

    &:hover {
      color: ${theme.colors.foreground.secondary.A};
    }
  `
)

export const ListItemText = styled.span`
  font-size: 0.8125rem; /* 13px */
  line-height: 1.462; /* stylelint-disable-line scales/line-height */
  margin: 6px 12px; /* stylelint-disable-line scales/space */
`

export const ListItemIconBase = styled.svg`
  width: 16px;
  height: 16px;

  box-sizing: content-box;

  margin: 8px 12px;

  img&,
  svg& {
    /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
    /* Opacity needs to be applied to the whole object */
    color: currentColor;
    opacity: inherit;
  }

  & + ${ListItemText} {
    margin-left: 0px;
  }
`
export const ListItemIcon = styled(ListItemIconBase)`
  transition: opacity 0.2s linear;
  img&,
  svg& {
    /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
    /* Opacity needs to be applied to the whole object */
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
    opacity: 0.55;
  }
`

export const ListItemActionWrapperBase = styled.span`
  width: 16px;
  height: 16px;

  box-sizing: content-box;
  transition: opacity 0.2s linear;

  margin-left: auto;
  padding: 8px 12px;

  [role='img'] {
    display: block;
  }
`

export const ListItemActionWrapper = styled(ListItemActionWrapperBase)`
  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
`

export const listItemHoverStyle = css`
  background-color: ${({ theme }) => theme.colors.background.tertiary.B};

  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  ${ListItemIcon}, ${ListItemActionWrapper} {
    /* This color should be "Light/colors.foreground.secondary.1" but given the icon composition */
    /* Opacity needs to be applied to the whole object */
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
    opacity: 0.85;
  }
`

export const ListItemWrapperBase = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.foreground.secondary.B};
`

export const ListItemWrapper = styled(ListItemWrapperBase)`
  background-color: none;
  transition: background-color 0.2s linear;

  &:focus,
  &:hover {
    ${listItemHoverStyle}
  }
`

export const SkeletonListItemIcon = styled(ListItemIcon)`
  ${skeleton}
`

export const SkeletonListItemText = styled(ListItemText)`
  ${skeleton}

  flex: 1;
  height: 16px;
`
