import { Spinner, ErrorMessage } from '@sketch/components'
import { useGetShareQuery, useGetVersionQuery } from '@sketch/gql-types'
import React from 'react'

import { Breadcrumbs } from '../Breadcrumbs'
import DocumentInfo from './DocumentInfo'
import { ShareWithoutVersion } from '../../../versioning'

export interface DocumentInfoModalQueryProps {
  share: Pick<ShareWithoutVersion, 'identifier' | 'type'>
  versions: {
    current: { shortId: string }
    latest: { shortId: string }
  }
  workspaceIdentifier: string
  shareType: ShareWithoutVersion['type']
  handleShareType: (shareType: ShareWithoutVersion['type']) => void
}

export const DocumentInfoModalQuery = (props: DocumentInfoModalQueryProps) => {
  const { shareType, handleShareType } = props

  const getShareQuery = useGetShareQuery({
    variables: { shortId: props.share.identifier },
  })

  const isCurrentVersionLatest =
    props.versions.current.shortId === props.versions.latest.shortId

  const getVersionQuery = useGetVersionQuery({
    variables: {
      shareIdentifier: props.share.identifier,
      versionShortId: props.versions.current.shortId,
    },
    skip: isCurrentVersionLatest,
  })

  const loading = getShareQuery.loading || getVersionQuery.loading

  if (loading) {
    return <Spinner.Flex primary />
  }

  const share = getShareQuery.data?.share
  const latestVersion = share?.version
  const currentVersion = isCurrentVersionLatest
    ? latestVersion
    : getVersionQuery.data?.share?.version

  const error = getShareQuery.error || getVersionQuery.error

  if (error || !share || !latestVersion || !currentVersion) {
    return <ErrorMessage.Generic />
  }

  return (
    <>
      <Breadcrumbs share={share} />
      <DocumentInfo
        share={share}
        version={{ currentVersion, latestVersion }}
        shareType={shareType}
        handleShareType={handleShareType}
      />
    </>
  )
}
