import React from 'react'
import copy from './copy'

import {
  Section,
  Separator,
  Header,
  HeaderTitle,
  AttributeList,
  FullCopyAttribute,
  Blend,
} from '../../components'
import { LayerAppearance } from '../../../../../../inspector'

type AppearanceProps = {
  appearance: LayerAppearance
}

export const Appearance: React.FC<AppearanceProps> = ({ appearance }) => {
  const { blendMode, opacity } = appearance
  const opacityPercentage = `${(opacity * 100).toFixed().toLocaleString()}%`

  const sectionCopyValue = copy({
    opacityPercentage,
    blendMode,
  })

  return (
    <>
      <Separator />
      <Section data-testid="inspector-sidebar-appearance">
        <Header copyValue={sectionCopyValue}>
          <HeaderTitle>Appearance</HeaderTitle>
        </Header>
        <AttributeList>
          <FullCopyAttribute
            label="Opacity"
            value={
              <>
                {opacityPercentage}
                {blendMode.toLowerCase() !== 'normal' && (
                  <Blend blendMode={blendMode} />
                )}
              </>
            }
            copyValue={opacityPercentage}
          />
        </AttributeList>
      </Section>
    </>
  )
}
