import React from 'react'

import { NavLink } from 'react-router-dom'
import { useForTablet, Segment } from '@sketch/components'

import { ReactComponent as SidebarIcon } from '@sketch/icons/sidebar-24'

import { SegmentValues } from '../ShareSidebarTabContext'

import {
  NavbarSpacer,
  NavbarWrapper,
  ToggleButton,
} from './SidebarHeader.styles'

const SEGMENT_DATA_TESTID: Record<SegmentValues, string> = {
  About: 'sidebar-about',
  Comment: 'sidebar-comment',
  Inspect: 'sidebar-inspector',
  Version: 'sidebar-version',
}

interface NavbarProps {
  segments: SegmentValues[]
  currentValue?: SegmentValues
  setValue: (value: SegmentValues) => void
  toggleSidebar: () => void
}

const SidebarHeader: React.FC<NavbarProps> = ({
  currentValue,
  setValue,
  segments,
  toggleSidebar,
}) => {
  const isTabletAndBigger = useForTablet()

  return (
    <NavbarWrapper>
      {segments.map(value => (
        <NavLink
          data-testid={SEGMENT_DATA_TESTID[value]}
          key={value}
          to={location => ({ ...location, hash: value })}
        >
          <Segment type="text" label={value} active={currentValue === value} />
        </NavLink>
      ))}

      {isTabletAndBigger && (
        <>
          <NavbarSpacer />
          <ToggleButton
            size={24}
            icon={SidebarIcon}
            onClick={toggleSidebar}
            data-testid="sidebar-toggle"
          />
        </>
      )}
    </NavbarWrapper>
  )
}

export default SidebarHeader
