import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "documents-20", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: 1, fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "Cloud/20/Docs" },
            React.createElement("g", { id: "Group", transform: "translate(1.5 4.5)", stroke: "currentColor" },
                React.createElement("path", { d: "M2.243 0h2.768c.516 0 .695.019.893.064.198.046.373.119.545.226.173.108.312.221.677.586l2.998 2.998c.365.365.478.504.586.677.107.172.18.347.226.545.045.198.064.377.064.893v5.768c0 .78-.081 1.063-.234 1.348a1.59 1.59 0 01-.661.661c-.285.153-.568.234-1.348.234H2.243c-.78 0-1.063-.081-1.348-.234a1.59 1.59 0 01-.661-.661C.08 12.82 0 12.537 0 11.757V2.243C0 1.463.081 1.18.234.895A1.59 1.59 0 01.895.234C1.18.08 1.463 0 2.243 0z", id: "Rectangle" }),
                React.createElement("path", { d: "M11 5H7.282c-.446 0-.607-.046-.77-.134a.909.909 0 01-.378-.378C6.046 4.325 6 4.164 6 3.718V0", id: "Path" })),
            React.createElement("g", { id: "Group", transform: "translate(7.404 1.5)" },
                React.createElement("path", { d: "M.096 3v-.757c0-.78.081-1.063.234-1.348A1.59 1.59 0 01.99.234C1.277.08 1.56 0 2.34 0h2.767c.516 0 .695.019.893.064.198.046.373.119.545.226.173.108.312.221.677.586l2.998 2.998c.365.365.478.504.586.677.108.172.18.347.226.545.045.198.064.377.064.893v5.768c0 .78-.081 1.063-.234 1.348a1.59 1.59 0 01-.661.661c-.285.153-.568.234-1.348.234H5.096", id: "Path", stroke: "currentColor" }),
                React.createElement("path", { d: "M6 .064l.096.026v3.628c0 .39.036.563.103.709l.03.061a.915.915 0 00.379.378c.163.088.324.134.77.134h3.629l.025.096c.045.198.064.377.064.893v5.768c0 .78-.081 1.063-.234 1.348a1.59 1.59 0 01-.661.661c-.285.153-.568.234-1.348.234H5.096V8.99c0-.46-.015-.652-.05-.828l-.014-.066a1.741 1.741 0 00-.226-.545c-.108-.173-.22-.312-.586-.677L1.222 3.876C.857 3.511.718 3.398.545 3.29A1.741 1.741 0 000 3.064l.096.026v-.847c0-.78.081-1.063.234-1.348A1.59 1.59 0 01.99.234C1.277.08 1.56 0 2.34 0h2.767c.516 0 .695.019.893.064z", id: "Combined-Shape", fill: "currentColor", opacity: 0.2 }),
                React.createElement("path", { d: "M11.096 5H7.378c-.446 0-.607-.046-.77-.134a.909.909 0 01-.378-.378c-.087-.163-.134-.324-.134-.77V0", id: "Path", stroke: "currentColor" })))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
