import { useEffect, useMemo } from 'react'
import { useFormikContext } from 'formik'

import { useGetTaxableCountriesQuery } from '@sketch/gql-types'
import { BillingDetailsFormFields } from './types'

export function useIsTaxableCountry<
  FormValues extends BillingDetailsFormFields
>() {
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const {
    data: taxableCountriesData,
    loading,
    error,
  } = useGetTaxableCountriesQuery()

  const isTaxable = useMemo(() => {
    if (loading || error) {
      return false
    }
    const taxableCountries = taxableCountriesData!.taxableCountries
    const taxableCountryCodes = taxableCountries.map(({ code }) => code)
    const isTaxable = taxableCountryCodes.includes(values.country)

    return isTaxable
  }, [taxableCountriesData, values.country, loading, error])

  useEffect(() => {
    if (!loading && !error && !isTaxable && values.taxId) {
      // Remove taxId value in the form since it's not supported for the current country.
      setFieldValue('taxId', '')
    }
  }, [isTaxable, values.taxId, setFieldValue, loading, error])

  return {
    isTaxable,
    loadingTaxableCountries: loading,
    errorTaxableCountries: error,
  }
}
