/**
 * Extract the coordinates relative to the canvas html element from a mouse event.
 * This event is expected to be triggered from `WebRendererCanvas` component.
 */
export function findPointRelativeToCanvasInScreenSpaceFromMouseEvent(
  event: React.MouseEvent
) {
  // Get the canvas container rect so we can get the coordinates relative to it.
  // It's expected that event.currentTarget is the html element wrapping the canvas
  // inside WebRendererCanvas.
  const canvasContainerRect = event.currentTarget.getBoundingClientRect()

  if (!canvasContainerRect) {
    return null
  }

  return {
    x: event.clientX - canvasContainerRect.x,
    y: event.clientY - canvasContainerRect.y,
  }
}
