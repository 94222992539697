import styled from 'styled-components'

import { Caption, RadioButton, TextArea } from '@sketch/components'

export const CertificateTextArea = styled(TextArea)`
  height: 90px;
`

export const SamlRadioButton = styled(RadioButton)`
  margin: 8px 16px 8px 0;
`

export const StyledCaption = styled(Caption)`
  margin-top: 6px; /* stylelint-disable-line scales/space */
  font-size: ${({ theme }) => theme.fontSizes.C};
`
