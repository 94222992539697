import React from 'react'
import { useVersioning } from '../../../versioning'
import { SidebarItem, Skeleton } from './PageList.styles'
import { TruncateWithTooltip, InfiniteList } from '@sketch/components'
import { useStableHandler } from '@sketch/utils'
import { useFlag, useAnalytics } from '@sketch/modules-common'
import { useGetPagesList } from '../../hooks/useGetPagesList'

const DROPDOWN_PLACEHOLDER_NUMBER = 1
const SIDEBAR_PLACEHOLDER_NUMBER = 5

interface PageListProps {
  isDropdown?: boolean
}

const PageList = (props: PageListProps) => {
  const { trackEvent } = useAnalytics()

  const { isDropdown } = props
  const {
    versionShortId,
    share: { identifier: shareIdentifier },
    getPathname,
  } = useVersioning()

  const includeHasUnreadComments = useFlag('pages-unread-annotations')

  const { pages, loading, onLoadMore } = useGetPagesList({
    shareIdentifier,
    versionShortId,
    includeHasUnreadComments,
  })

  const onPageClick = useStableHandler((pageUUID: string | null) => {
    /**
     * Do not track the `DOCUMENT - open page` event if:
     *  - user clicked on the "All Pages" link
     */
    const isAllPagesLink = pageUUID === null
    if (isAllPagesLink) {
      return
    }

    trackEvent('DOCUMENT - open page', {
      dropdown: true,
    })
  })

  if (loading) {
    const placeholderCount = isDropdown
      ? DROPDOWN_PLACEHOLDER_NUMBER
      : SIDEBAR_PLACEHOLDER_NUMBER
    return (
      <>
        {[...Array(placeholderCount)].map((_, index) => (
          <Skeleton key={index} />
        ))}
      </>
    )
  }

  return (
    <InfiniteList wrappingComponent={React.Fragment} onLoadMore={onLoadMore}>
      {pages.map((page, index) => {
        const { identifier, name, uuid, hasUnreadComments } = page
        let toRoute

        if (index === 0) {
          toRoute = getPathname({
            routeKey: 'SHARE_VIEW',
            routeParams: {
              shareID: shareIdentifier,
            },
          })
        } else {
          toRoute = getPathname({
            routeKey: 'SHARE_PAGE_CANVAS_VIEW',
            routeParams: {
              shareID: shareIdentifier,
              pageUUID: uuid!,
            },
          })
        }

        return (
          <SidebarItem
            key={identifier}
            data-testid="page-canvas-sidebar-item"
            to={toRoute}
            onClick={() => onPageClick(uuid)}
            $hasUnreadComments={hasUnreadComments}
            exact
          >
            <TruncateWithTooltip>{name}</TruncateWithTooltip>
          </SidebarItem>
        )
      })}
    </InfiniteList>
  )
}

export default PageList
