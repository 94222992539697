import React from 'react'
import { FlatButton, useModalContext } from '@sketch/components'
import {
  ConfirmRemoveMemberModal,
  RemoveProjectAccessModal,
} from '../../../components/RemoveMemberModal'
import { getTriggerCopyFromAccessLevel } from '@sketch/modules-common'
import {
  removeProjectMembershipUserAccessModal,
  removeProjectMembership,
} from '../../../operations/utils/projectMemberships.cache'
import { PermissionText } from '../ProjectSharingModal.styles'
import {
  ProjectFragment,
  ProjectMembershipFragment,
  useRemoveProjectMemberMutation,
} from '@sketch/gql-types'
import { WorkspaceMemberRow } from '../../../components/MemberRow'

interface ProjectMemberRowProps {
  member: ProjectMembershipFragment
  project: ProjectFragment
  editable?: boolean
  workspaceIdentifier: string
}

export const ProjectMemberRow = ({
  member,
  project,
  editable,
  workspaceIdentifier,
}: ProjectMemberRowProps) => {
  const { showModal, hideModal } = useModalContext()
  const membershipIdentifier = member.identifier
  const { workspaceMembership } = member
  const memberName = workspaceMembership.user?.name || ''

  const [removeMemberFromProject] = useRemoveProjectMemberMutation({
    variables: { membershipIdentifier },
    update(cache, { data }) {
      removeProjectMembership({
        cache,
        projectIdentifier: project.identifier,
        membershipIdentifier,
      })

      removeProjectMembershipUserAccessModal({
        cache,
        data,
        workspaceIdentifier,
        workspaceMembershipIdentifier: member.identifier,
      })
    },
    onError(errorResponse) {
      if (errorResponse.includesErrorCode('CONFIRMATION_REQUIRED')) {
        showModal(ConfirmRemoveMemberModal, {
          projectName: project.name,
          onConfirm: async () => {
            await removeMemberFromProject({
              variables: {
                membershipIdentifier,
                confirmed: true,
              },
            })
            hideModal()
          },
          // we don't need the callback in this case
          onCancelAction: () => {},
        })
        return
      }
      if (errorResponse.includesErrorCode('EXISTING_SHARE_MEMBERSHIPS')) {
        showModal(RemoveProjectAccessModal, {
          memberName,
          projectName: project.name,
          onConfirm: async (removeShareMemberships: boolean) => {
            await removeMemberFromProject({
              variables: {
                membershipIdentifier,
                removeShareMemberships,
                confirmed: true,
              },
            })
            hideModal()
          },
          // we don't need the callback in this case
          onCancelAction: () => {},
        })
        return
      }
    },
  })

  return (
    <WorkspaceMemberRow
      workspaceMembership={member.workspaceMembership}
      inviteStatus={member.inviteStatus}
      actions={
        editable ? (
          <FlatButton
            variant="negative"
            size="32"
            onClick={() => removeMemberFromProject()}
          >
            Remove
          </FlatButton>
        ) : (
          <PermissionText>
            {getTriggerCopyFromAccessLevel(
              member.privateAccessLevel === 'EDIT' ? 'EDIT' : 'VIEW'
            )}
          </PermissionText>
        )
      }
    />
  )
}
