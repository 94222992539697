import styled from 'styled-components'
import AnnotationReverseList from '../AnnotationReverseList'
import Comment from '../Comment'
import CommentNewSeparator from '../CommentNewSeparator'
import EditCommentInput from '../EditCommentInput'

export const AnnotationComment = styled(Comment)`
  &[data-first-comment='true'] {
    margin: 4px 0px; /* stylelint-disable-line scales/space */

    :hover,
    :focus-within {
      background: none;
    }

    &${EditCommentInput} {
      margin: 8px 0px;
    }
  }
`

export const AnnotationCommentsWrapper = styled(AnnotationReverseList).attrs({
  'data-testid': 'comment-list',
})``

export const AnnotationFirstCommentSeparator = styled.div`
  /* 1000vh is used like a infinite measure so we can set the background bellow all the response items */
  clip-path: inset(0px 0px -1000vh 0px);
  border-top: 1px solid ${({ theme }) => theme.colors.shadow.inner};

  margin: 4px -8px 0;
  padding-top: 8px;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 8px;
    height: 1000vh;
    background: ${({ theme }) =>
      theme.colors.ui.annotations.commentListBackground};
  }

  ${CommentNewSeparator} + & {
    /* Make the Separator closer to the Background separator */
    margin-top: -5px; /* stylelint-disable-line scales/space */
    border-top: none;
  }
`
