import {
  ArtboardDetailInfoFragment,
  GetDetailViewArtboardQuery,
} from '@sketch/gql-types'

export const getArtboardThumbnail = (
  artboard?:
    | ArtboardDetailInfoFragment
    | GetDetailViewArtboardQuery['artboard'],
  type: 'L' | 'M' | 'S' | 'XL' = 'L'
) => {
  const file = artboard?.files[0]

  const thumbnails = file?.thumbnails ?? []
  const thumbnail = thumbnails.find(t => t?.type === type)

  return (thumbnail || file)?.url ?? ''
}
