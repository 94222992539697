import React from 'react'
import styled from 'styled-components'

import { Tooltip } from '../Tooltip'

import {
  ArrowCircle,
  StyledLink,
  SubTitle,
  Title,
  TitleContainer,
} from './GenericSectionTitle.styles'

interface Link {
  text?: string
  url?: string
}

export interface GenericSectionTitleProps {
  className?: string
  title: string
  titleLink?: Link
  onTitleLinkClick?: React.MouseEventHandler
  subTitle?: string
  categories?: React.ReactNode
}

const GenericSectionTitle = React.forwardRef<
  HTMLDivElement,
  GenericSectionTitleProps
>(function GenericSectionTitle(props, ref) {
  const {
    className,
    title,
    titleLink,
    onTitleLinkClick,
    subTitle,
    categories,
  } = props

  const renderTitle = () => {
    const hasSubtitleSibling = !!subTitle

    if (titleLink && titleLink.text) {
      return (
        <StyledLink
          target="_blank"
          rel="noreferrer"
          href={titleLink.url}
          onClick={onTitleLinkClick}
          external
        >
          <Tooltip content={titleLink.text} placement="right" spacing="10px">
            <Title $withLink={true} $hasSubtitleSibling={hasSubtitleSibling}>
              {title}
              <ArrowCircle width="24" height="24" />
            </Title>
          </Tooltip>
        </StyledLink>
      )
    } else if (titleLink) {
      return (
        <StyledLink
          target="_blank"
          rel="noreferrer"
          href={titleLink.url}
          onClick={onTitleLinkClick}
          external
        >
          <Title $withLink={true} $hasSubtitleSibling={hasSubtitleSibling}>
            {title}
            <ArrowCircle width="24" height="24" />
          </Title>
        </StyledLink>
      )
    }

    return <Title>{title}</Title>
  }
  return (
    <TitleContainer className={className} ref={ref}>
      <div>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
        {renderTitle()}
      </div>
      {categories}
    </TitleContainer>
  )
})

export default styled(GenericSectionTitle)``
