import React from 'react'
import {
  isProjectRoute,
  isCollectionRoute,
  isLibrariesRoute,
  isDraftsRoute,
  isDesignSystemRoute,
  isTemplatesRoute,
  isWorkspaceRoute,
  isSharedWithMeRoute,
} from '@sketch/modules-common'

import { Dropdown } from '@sketch/components'

import {
  usePinShareMutation,
  useUnpinShareMutation,
  GetShareDropdownOptionsDocument,
} from '@sketch/gql-types'

import {
  updatePinnedDocument,
  updateProjectShares,
  updateCollectionShares,
  updateLibrariesShares,
} from './pinShare.cache'
import { useRouteMatch } from 'react-router'
import { DataProxy } from 'apollo-cache'
import { useSortingSettings, ShareSortOrder } from '../../hooks/useSortSettings'

export interface VersionsForDropdown {
  current: { shortId: string }
  latest: { shortId: string }
}

type UpdatePinFunctionArgs = {
  cache: DataProxy
  data: any
  queryVariables: any
  sort?: ShareSortOrder
}

type PinDocumentProps = {
  pinned?: string | null
  shareIdentifier: string
  workspaceIdentifier: string
  queryVariables: any
  updatePinFunction: (props: UpdatePinFunctionArgs) => void
  updateUnpinFunction: (props: UpdatePinFunctionArgs) => void
}

type PinDocumentWrapperProps = {
  pinned?: string | null
  shareIdentifier: string
  workspaceIdentifier: string
  queryVariables: any
}

const PinDocument = ({
  pinned,
  shareIdentifier,
  queryVariables,
  updatePinFunction,
  updateUnpinFunction,
}: PinDocumentProps) => {
  const { sort } = useSortingSettings()

  const [pinShare] = usePinShareMutation({
    variables: {
      shareIdentifier,
    },
    onError: 'show-toast',
    update(cache, { data }) {
      if (!data) return

      updatePinFunction({ cache, data, queryVariables })
    },
  })

  const [unpinShare] = useUnpinShareMutation({
    variables: {
      shareIdentifier,
    },
    onError: 'show-toast',
    update(cache, { data }) {
      if (!data) return

      updateUnpinFunction({ cache, data, queryVariables, sort })
    },
  })

  return (
    <Dropdown.Item
      onClick={() => {
        if (pinned) {
          return unpinShare({ variables: { shareIdentifier } })
        }
        return pinShare({ variables: { shareIdentifier } })
      }}
    >
      {pinned ? 'Unpin Document' : 'Pin Document'}
    </Dropdown.Item>
  )
}

const PinProjectShare = ({
  pinned,
  shareIdentifier,
  queryVariables,
  workspaceIdentifier,
}: PinDocumentWrapperProps) => {
  return (
    <PinDocument
      pinned={pinned}
      shareIdentifier={shareIdentifier}
      workspaceIdentifier={workspaceIdentifier}
      queryVariables={queryVariables}
      updatePinFunction={({ cache, data, queryVariables }) =>
        updateProjectShares({
          cache,
          pinnedShareId: data?.pinShare.share.identifier,
          projectSharesVariables: queryVariables,
        })
      }
      updateUnpinFunction={({ cache, data, queryVariables, sort }) => {
        return updateProjectShares({
          cache,
          pinnedShareId: data?.unpinShare.share.identifier,
          projectSharesVariables: queryVariables,
          sort,
        })
      }}
    />
  )
}

const PinCollectionShare = ({
  pinned,
  shareIdentifier,
  workspaceIdentifier,
  queryVariables,
}: PinDocumentWrapperProps) => {
  const getCollectionSharesVariables = (
    queryVariables: PinDocumentWrapperProps['queryVariables']
  ) => ({
    projectIdentifier: queryVariables.projectId,
    collectionIdentifier: queryVariables.collectionId,
    search: {
      name: queryVariables.search,
      filters: queryVariables.filters,
    },
    after: null,
    sortOrder: queryVariables.sort,
  })

  return (
    <PinDocument
      pinned={pinned}
      shareIdentifier={shareIdentifier}
      queryVariables={queryVariables}
      workspaceIdentifier={workspaceIdentifier}
      updatePinFunction={({ cache, data, queryVariables }) =>
        updateCollectionShares({
          cache,
          pinnedShareId: data?.pinShare.share.identifier,
          collectionSharesVariables: getCollectionSharesVariables(
            queryVariables
          ),
        })
      }
      updateUnpinFunction={({ cache, data, sort }) =>
        updateCollectionShares({
          cache,
          pinnedShareId: data?.unpinShare.share.identifier,
          collectionSharesVariables: getCollectionSharesVariables(
            queryVariables
          ),
          sort,
        })
      }
    />
  )
}

const PinLibraryShare = ({
  pinned,
  shareIdentifier,
  workspaceIdentifier,
  queryVariables,
}: PinDocumentWrapperProps) => {
  return (
    <PinDocument
      pinned={pinned}
      shareIdentifier={shareIdentifier}
      workspaceIdentifier={workspaceIdentifier}
      queryVariables={queryVariables}
      updatePinFunction={({ cache, data, queryVariables }) =>
        updateLibrariesShares({
          cache,
          pinnedShareId: data?.pinShare.share.identifier,
          librariesSharesVariables: queryVariables,
        })
      }
      updateUnpinFunction={({ cache, data, queryVariables, sort }) =>
        updateLibrariesShares({
          cache,
          pinnedShareId: data?.unpinShare.share.identifier,
          librariesSharesVariables: queryVariables,
          sort,
        })
      }
    />
  )
}

const PinShareAllDocuments = ({
  pinned,
  workspaceIdentifier,
  shareIdentifier,
  queryVariables,
}: PinDocumentWrapperProps) => {
  return (
    <PinDocument
      pinned={pinned}
      shareIdentifier={shareIdentifier}
      workspaceIdentifier={workspaceIdentifier}
      queryVariables={queryVariables}
      updatePinFunction={({ cache, data, queryVariables }) =>
        updatePinnedDocument({
          cache,
          pinnedShareId: data?.pinShare.share.identifier,
          shareDocumentsVariables: queryVariables,
        })
      }
      updateUnpinFunction={({ cache, data, queryVariables, sort }) =>
        updatePinnedDocument({
          cache,
          pinnedShareId: data?.unpinShare.share.identifier,
          shareDocumentsVariables: queryVariables,
          sort,
        })
      }
    />
  )
}

// This is a special case because it's only for the menus that
// won't need to update any list of shares or projects. In this case
// we will just refetch que dropdown options query and we are good to go.
// It will be used e.g. Document Version, Prototype or Components pages
const PinDocumentVersion = ({
  pinned,
  shareIdentifier,
  queryVariables,
}: PinDocumentWrapperProps) => {
  const [pinShare] = usePinShareMutation({
    variables: {
      shareIdentifier,
    },
    onError: 'show-toast',
    refetchQueries: [
      {
        query: GetShareDropdownOptionsDocument,
        variables: queryVariables,
      },
    ],
  })

  const [unpinShare] = useUnpinShareMutation({
    variables: {
      shareIdentifier,
    },
    onError: 'show-toast',
    refetchQueries: [
      {
        query: GetShareDropdownOptionsDocument,
        variables: queryVariables,
      },
    ],
  })

  return (
    <Dropdown.Item
      onClick={() => {
        if (pinned) {
          return unpinShare({ variables: { shareIdentifier } })
        }
        return pinShare({ variables: { shareIdentifier } })
      }}
    >
      {pinned ? 'Unpin Document' : 'Pin Document'}
    </Dropdown.Item>
  )
}

export const PinDocumentWrapper = ({
  pinned,
  shareIdentifier,
  workspaceIdentifier,
  queryVariables,
}: PinDocumentWrapperProps) => {
  const { path } = useRouteMatch()

  // TODO: We won't be pinning DS yet
  if (isDesignSystemRoute(path)) {
    return null
  }

  if (isProjectRoute(path) || isDraftsRoute(path)) {
    return (
      <PinProjectShare
        pinned={pinned}
        shareIdentifier={shareIdentifier}
        workspaceIdentifier={workspaceIdentifier}
        queryVariables={queryVariables}
      />
    )
  }

  if (isCollectionRoute(path)) {
    return (
      <PinCollectionShare
        pinned={pinned}
        shareIdentifier={shareIdentifier}
        workspaceIdentifier={workspaceIdentifier}
        queryVariables={queryVariables}
      />
    )
  }

  if (isLibrariesRoute(path)) {
    return (
      <PinLibraryShare
        pinned={pinned}
        shareIdentifier={shareIdentifier}
        workspaceIdentifier={workspaceIdentifier}
        queryVariables={queryVariables}
      />
    )
  }

  if (
    isWorkspaceRoute(path) ||
    isLibrariesRoute(path) ||
    isTemplatesRoute(path) ||
    isSharedWithMeRoute(path)
  ) {
    return (
      <PinShareAllDocuments
        pinned={pinned}
        shareIdentifier={shareIdentifier}
        workspaceIdentifier={workspaceIdentifier}
        queryVariables={queryVariables}
      />
    )
  }

  return (
    <PinDocumentVersion
      pinned={pinned}
      shareIdentifier={shareIdentifier}
      workspaceIdentifier={workspaceIdentifier}
      queryVariables={queryVariables}
    />
  )
}
