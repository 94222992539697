import React, { FC } from 'react'
import { useTransition, animated, config } from 'react-spring'
import styled from 'styled-components'

import {
  ImageContainer,
  StyledResponsiveImage,
  Title,
  Content,
  Length,
  StyledLink,
} from './Insight.styles'

import { DiscoverItemFragment } from '@sketch/gql-types'

type InsightProps = Pick<
  DiscoverItemFragment,
  'id' | 'image' | 'title' | 'source' | 'length' | 'url'
> & {
  className?: string
  onItemClick?: React.MouseEventHandler
  imageRef?: React.Ref<HTMLDivElement>
}

const BaseInsight: FC<InsightProps> = ({
  id,
  image,
  title,
  source,
  length,
  url,
  onItemClick,
  imageRef,
  className,
}) => {
  const transitions = useTransition(id, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.slow,
  })

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.section
              className={className}
              data-testid="insight-item"
              key={key}
              style={props}
            >
              <StyledLink
                href={url!}
                rel="noreferrer"
                target="_blank"
                external
                onClick={onItemClick}
              >
                <ImageContainer ref={imageRef}>
                  <StyledResponsiveImage image={image!} withSkeletonUI />
                  {length && <Length>▶ {length}</Length>}
                </ImageContainer>
                <Title>{title}</Title>
                <Content>{source}</Content>
              </StyledLink>
            </animated.section>
          )
      )}
    </>
  )
}

export const Insight = styled(BaseInsight)``
