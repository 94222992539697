import { IsEmbedded, LoadingState, NotEmbedded } from '@sketch/components'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { DocumentSidebarLayoutOld as DocumentSidebarLayout } from '../DocumentSidebarLayoutOld'
import { EmptyHeader } from '../EmptyHeader'
import { Footer } from '../Footer'

/**
 * TODO: reuse code between Layout and LoadingPage
 * https://github.com/sketch-hq/Cloud/issues/3543
 */
const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`

export type SidebarLoadingPageProps = {
  logo?: ReactNode
  footer?: ReactNode
  sidebarRight?: ReactNode
  message?: string
}

export const SidebarLoadingPage = ({
  footer = <Footer />,
  sidebarRight = () => null,
  message = undefined,
}: SidebarLoadingPageProps) => (
  <Container>
    <DocumentSidebarLayout
      header={
        <>
          <NotEmbedded>
            <EmptyHeader hideLogo isDocumentView />
          </NotEmbedded>
          <IsEmbedded>
            <EmptyHeader />
          </IsEmbedded>
        </>
      }
      footer={footer}
      sidebarRight={sidebarRight}
    >
      <LoadingState message={message} />
    </DocumentSidebarLayout>
  </Container>
)
