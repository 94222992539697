import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import {
  Wrapper,
  IconWrapper,
  Prefix,
  InputWithBox,
  RightIconWrapper,
} from './Input.styles'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  small?: boolean
  disabled?: boolean
  invalid?: boolean
  hasIcon?: boolean
  icon?: React.ReactElement
  // We can add a prefix to the Input (a sort of helper text)
  // and to add the proper padding we calculate the width of the prefix
  // and use it as a additional pixel left padding
  $prefixWidth?: number
  $hasRightIcon?: boolean
  /**
   * This boolean flag is used for Stripe input fields, where there are two different inputs.
   * 1. The outer input is controlled by us and has the look&feel we want.
   * 2. The inner input is coming from Stripe (from an iframe) and it's the one the user interacts with.
   *
   * We need to listen to changes on the focus of the inner input to make the outer component look like it's focused as
   * well. That is when this flag comes into play.
   */
  stripeFakeFocus?: boolean
}

export interface StyledInputProps extends InputProps {
  icon?: React.ReactElement
  rightIcon?: React.ReactElement
  prefix?: string
}

type InputRef = HTMLInputElement

const InputBase = React.forwardRef<InputRef, StyledInputProps>(
  function StyledInput(props, ref) {
    const [prefixWidth, setPrefixWidth] = useState(0)
    const {
      icon: Icon,
      rightIcon: RightIcon,
      value,
      prefix,
      small,
      ...remainingProps
    } = props

    const prefixRef = useRef<HTMLSpanElement>(null)

    // Calculate prefix element width so we can set the input padding properly
    useEffect(() => {
      const prefixElement = prefixRef?.current

      if (prefixElement) {
        setPrefixWidth(prefixElement.offsetWidth)
      }
    }, [prefix])

    const isValueEmpty = !value?.toString().length
    const hasIcon = isValueEmpty && !!Icon
    const hasRightIcon = !!RightIcon
    const canRenderPrefix = !!prefix && !hasIcon
    const $prefixWidth = canRenderPrefix ? prefixWidth : undefined

    return (
      <Wrapper>
        {hasIcon && <IconWrapper>{Icon}</IconWrapper>}
        {canRenderPrefix && (
          <Prefix ref={prefixRef} small={small}>
            {prefix}
          </Prefix>
        )}
        <InputWithBox
          ref={ref}
          hasIcon={hasIcon}
          $hasRightIcon={hasRightIcon}
          value={value}
          small={small}
          $prefixWidth={$prefixWidth}
          {...remainingProps}
        />
        {hasRightIcon && (
          <RightIconWrapper $small={small}>{RightIcon}</RightIconWrapper>
        )}
      </Wrapper>
    )
  }
)

export const Input = styled(InputBase)``
