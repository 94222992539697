import React from 'react'

import { useForDesktop } from '@sketch/components'
import { useThemeContext } from '@sketch/global-styles'
import {
  Feature,
  FeatureImg,
  Subtitle,
  Title,
} from './CampaignSidePanel.styles'

import WorkspacesIcon from '@sketch/icons/workspaces-24.svg'
import ChatBubbleIcon from '@sketch/icons/chat-bubble-16.svg'
import InterfaceIcon from '@sketch/icons/interface-24.svg'

export const content = {
  __typename: 'SignUpContent',
  title: 'Your journey starts here.',
  tint: 'ORANGE',
  introduction:
    'Sketch is a toolkit made by designers, for designers. From early ideas to pixel-perfect artwork, playable prototypes and developer handoff — we’ve got you covered.',
  features: [
    {
      __typename: 'SignUpFeature',
      description: 'A macOS editor made for you and your designs',
      icon: {
        __typename: 'SignUpImage',
        src: InterfaceIcon,
        alt: 'interface icon',
      },
    },
    {
      __typename: 'SignUpFeature',
      description: 'A shared Workspace for files, Libraries and drafts',
      icon: {
        __typename: 'SignUpImage',
        src: WorkspacesIcon,
        alt: 'workspaces icon',
      },
    },
    {
      __typename: 'SignUpFeature',
      description: 'All the tools you need to collaborate with others',
      icon: {
        __typename: 'SignUpImage',
        src: ChatBubbleIcon,
        alt: 'chat bubble icon',
      },
    },
  ],
  callToAction: 'Next: Choose a Plan',
  relatedContent: null,
  heroImage: null,
}

export const CampaignSidePanel = () => {
  const { isDarkMode } = useThemeContext()
  const isDesktopAndBigger = useForDesktop()

  return (
    <>
      <Title>{content.title}</Title>
      {isDesktopAndBigger && <Subtitle>{content.introduction}</Subtitle>}
      {content.features.map((feature, index) => (
        <Feature key={index}>
          <FeatureImg
            src={feature.icon.src}
            alt={feature.icon.alt}
            isDarkModeActive={isDarkMode}
          />
          <span>{feature.description}</span>
        </Feature>
      ))}
    </>
  )
}
