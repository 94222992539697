import whitespace from 'is-whitespace-character'

/**
 * This Plugin is an modified version of the remark newline
 * https://github.com/remarkjs/remark/blob/master/packages/remark-parse/lib/tokenize/newline.js
 */

const lineFeed = '\n'

/**
 * TokenizeNewline.
 *
 * This function is responsible for replacing a newline group by a break element.
 *
 * @param {Function} eat - Replace the match by an object.
 * @param {string} value - text value caught.
 * @param {boolean} silent - Whether to detect or consume.
 *
 */
const tokenizeNewline = (eat, value, silent) => {
  const length = value.length
  let character = value.charAt(0)
  let subValue
  let queue
  let index

  if (character !== lineFeed) {
    return
  }

  if (silent) {
    return true
  }

  index = 1
  subValue = character
  queue = ''

  while (index < length) {
    character = value.charAt(index)

    if (!whitespace(character)) {
      break
    }

    queue += character

    if (character === lineFeed) {
      subValue += queue
      queue = ''
    }

    index++
  }

  eat(subValue)

  /* Allow line-breaks when the quantity of "newlines" is bigger then one */
  if (subValue.length > 1) {
    eat('')({
      type: 'break',
    })
  }
}

/**
 * Newline plugin for the `remark-js`.
 */
export function newline() {
  const Parser = this.Parser
  const tokenizers = Parser.prototype.blockTokenizers

  tokenizers.newline = tokenizeNewline
}
