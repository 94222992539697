import styled, { css } from 'styled-components'
import { breakpoint } from '@sketch/global-styles'

import { ProfileAvatar as ProfileAvatarUnstyled } from '../../components/ProfileAvatar'

export const Container = styled.div<{ isProfileEditRoute: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  ${({ isProfileEditRoute }) =>
    !isProfileEditRoute &&
    css`
      margin: 0 40px 140px 40px;

      ${breakpoint('base', 'sm')`
        margin: 0 24px 140px 24px;
      `}
    `}
`

export const ProfileHeader = styled.div`
  display: flex;
  flex-direction: column;
`

export const ProfileAvatar = styled(ProfileAvatarUnstyled)`
  margin-top: 74px; /* stylelint-disable-line scales/space */
`

export const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 12px auto;
  width: 470px;

  ${breakpoint('base', 'sm')`
    width: 100%;
  `}
`

export const PublishedDocumentsContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1280px;
  margin-top: 62px; /* stylelint-disable-line scales/space */
`
