import {
  useAddShareMembershipMutation,
  AddShareMembershipMutationVariables,
  GetShareMembershipsDocument,
  GetShareMembershipsQueryVariables,
  GetShareDocument,
  GetShareQueryVariables,
} from '@sketch/gql-types'
import { useCallback, useState } from 'react'
import { ErrorHandler } from '@sketch/tracing'
import { useToast } from '@sketch/toasts'
import { useModalContext } from '@sketch/components'

export const useAddShareMembership = () => {
  const { showToast } = useToast()
  const { hideModal } = useModalContext()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [variables, setVariables] = useState<
    AddShareMembershipMutationVariables | undefined
  >(undefined)

  const reset = useCallback(() => {
    setDialogOpen(false)
    setVariables(undefined)
  }, [])

  const [
    addShareMembership,
    { loading: addMembershipLoading },
  ] = useAddShareMembershipMutation({
    onCompleted(data) {
      if (!data?.addShareMembership) {
        ErrorHandler.shouldNeverHappen(
          'Share add member permissions mutation should return valid data'
        )
        return
      }

      const { addShareMembership } = data

      if (
        addShareMembership?.shareMembership?.share?.userCanUpdateSettings ===
        false
      ) {
        hideModal()
        showToast("You can no longer view this document's settings")
        return
      }

      showToast(
        `Invitation sent to ${addShareMembership.shareMembership.email}`
      )
    },
    onError(errors) {
      if (errors.includesErrorCode('CONFIRMATION_REQUIRED')) {
        setDialogOpen(true)
        return
      }

      reset()

      if (errors.messages.length > 0) {
        showToast(errors.message, 'negative')
        return
      }

      showToast(
        'There was a problem inviting the member. Please try again.',
        'negative'
      )
    },
    awaitRefetchQueries: true,
    refetchQueries: ({ data }) => {
      const shareIdentifier = data.addShareMembership.shareMembership.share!
        .identifier

      return [
        {
          query: GetShareMembershipsDocument,
          variables: { shareIdentifier } as GetShareMembershipsQueryVariables,
        },

        // This refetch is needed basically for a single (and likely not that popular case)
        // when user updates permissions for himself/herself (when the confirmation is required)
        // It is really likely that we could find a more performant solution, if we would notice
        // any performance issues.
        {
          query: GetShareDocument,
          variables: { shortId: shareIdentifier } as GetShareQueryVariables,
        },
      ]
    },
  })

  const addMember = useCallback(
    (
      variables: AddShareMembershipMutationVariables,
      confirmed: boolean = false
    ) => {
      setVariables(variables)
      return addShareMembership({
        variables: {
          input: {
            ...variables.input,
            confirmed,
          },
        },
      })
    },
    [addShareMembership]
  )

  const getAddMemberConfirmDialogProps = useCallback(
    () => ({
      hideModal() {
        reset()
      },
      onConfirm() {
        if (variables) addMember(variables, true)
      },
      isModalOpen: true,
      confirmButton: {
        text: 'Confirm',
        intent: 'positive',
        loading: addMembershipLoading,
      } as const,
    }),
    [addMember, addMembershipLoading, variables, reset]
  )

  return {
    userIdentifier: variables?.input?.userIdentifier,
    addMember,
    addMembershipLoading,
    getAddMemberConfirmDialogProps,
    addMemberConfirmDialogOpen: dialogOpen,
  }
}
