import React from 'react'

import {
  IndexLayoutExtraProps,
  RouteProps,
  getIsForInAppPresentation,
  useQueryParams,
} from '@sketch/modules-common'
import { SsoSignInForCloud } from './SsoSignInForCloud'
import { RedirectingToIdentityProvider } from './RedirectingToIdentityProvider'

export interface SsoSignInViewProps
  extends RouteProps<'SSO_SIGN_IN'>,
    IndexLayoutExtraProps {}

export function SsoSignInView(props: SsoSignInViewProps) {
  const { team, shortname } = useQueryParams<'SSO_SIGN_IN'>()
  const isForInAppPresentation = getIsForInAppPresentation()

  if (isForInAppPresentation && (team || shortname)) {
    // SSO Sign in when trying to login with SSO in the Sketch native app.
    return <RedirectingToIdentityProvider />
  }

  // SSO Sign in when trying to login with SSO in the web app.
  return <SsoSignInForCloud {...props} />
}
