import React, { useContext } from 'react'
import { Banner as BannerComponent } from '../Banner'

import { bannerContext } from './bannerContext'

export const BannersDisplay = () => {
  const { items, dismissBanner } = useContext(bannerContext)

  return (
    <>
      {items.map(({ message, type, id, dismissible }) => (
        <BannerComponent
          type={type}
          key={id}
          dismissible={dismissible}
          onDismiss={() => {
            dismissBanner(id)
          }}
        >
          {message}
        </BannerComponent>
      ))}
    </>
  )
}
