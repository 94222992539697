import React, { useMemo } from 'react'
import styled from 'styled-components'

import { defaultMarkdownParser } from './parsers'
import { TextWrapper } from './Components'

/**
 * Markdown.
 *
 * Transform normal text to markdown text.
 *
 * @param {Object} props - Component Props.
 * @param [{string}] props.className - Style class for the markdown text wrapper.
 * @param {string} props.children - String with the text marked.
 * @param {string} props.parser - Parser to be used.
 *
 * @returns {React.ReactElement} - React element.
 *
 * @example // Basic example
 * <Markdown>"Hello I am `Normal Text` but I will markdown :smile:"</Markdown>
 */

export interface MarkdownBaseProps {
  className?: string
  children: string
  parser?: (text: any) => unknown
  'data-testid'?: string
  style?: React.ComponentProps<'div'>['style']
}

export const MarkdownBase = React.forwardRef<HTMLPreElement, MarkdownBaseProps>(
  function MarkdownBase(props, ref) {
    const {
      className,
      children,
      'data-testid': dataTestId,
      parser = defaultMarkdownParser,
      style,
    } = props

    const markdown = useMemo(() => {
      return parser(children)
    }, [children, parser])

    return (
      <TextWrapper
        ref={ref}
        style={style}
        className={className}
        data-testid={dataTestId}
      >
        {markdown}
      </TextWrapper>
    )
  }
)

export const Markdown = styled(MarkdownBase)`
  /* Prevents text for causing overflow */
  overflow-wrap: break-word;
  hyphens: auto;

  /* Prevents links for causing overflow */
  & a {
    white-space: normal;
    word-break: break-all;
  }
`
