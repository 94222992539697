import React, { FC } from 'react'

import { Text as TextRaw, WorkspaceLogo } from '@sketch/components'

import {
  Container,
  WorkspaceName,
  StyledLink,
  StyledTextH1,
} from './ProfileAvatar.styles'

import { PublicWorkspaceFragment } from '@sketch/gql-types'

export interface ProfileAvatarProps {
  workspaceName: string
  avatar?: PublicWorkspaceFragment['avatar']
  className?: string
  to?: string
  isSmall?: boolean
}

type TextProps = Pick<ProfileAvatarProps, 'isSmall'>
type ProfileAvatarContentProps = OmitSafe<ProfileAvatarProps, 'to'>

const Text: React.FC<TextProps> = ({ isSmall, children }) =>
  isSmall ? (
    <TextRaw.P textStyle="prominent.primary.E">{children}</TextRaw.P>
  ) : (
    <StyledTextH1 textStyle="header.primary.I">{children}</StyledTextH1>
  )

const ProfileAvatarContent: React.FC<ProfileAvatarContentProps> = ({
  avatar,
  className,
  isSmall,
  workspaceName,
}) => {
  const size = isSmall ? '24px' : '88px'
  const src = avatar?.large ?? avatar?.small

  return (
    <Container className={className} $isSmall={isSmall}>
      <WorkspaceLogo size={size} workspaceName={workspaceName} src={src} />
      <WorkspaceName>
        <Text isSmall={isSmall}>{workspaceName}</Text>
      </WorkspaceName>
    </Container>
  )
}

export const ProfileAvatar: FC<ProfileAvatarProps> = ({
  workspaceName,
  avatar,
  className,
  isSmall,
  to,
}) => {
  const content = (
    <ProfileAvatarContent
      workspaceName={workspaceName}
      avatar={avatar}
      className={className}
      isSmall={isSmall}
    />
  )

  if (to) {
    return <StyledLink to={to}>{content}</StyledLink>
  }

  return content
}
