import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "heart-animated_UNSAFE", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 160 148", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("path", { id: "heart-path", d: "M0 49.86c0 11.11 2.936 22.117 8.808 33.018 5.873 10.902 14.151 21.51 24.836 31.825 10.685 10.315 23.225 20.13 37.62 29.448a48.08 48.08 0 004.635 2.658c1.585.794 2.947 1.191 4.088 1.191 1.178 0 2.563-.397 4.155-1.191a45.135 45.135 0 004.624-2.658c14.41-9.317 26.95-19.133 37.617-29.448s18.936-20.923 24.809-31.825C157.064 71.977 160 60.971 160 49.86c0-7.422-1.195-14.183-3.585-20.282-2.39-6.1-5.697-11.36-9.92-15.781a44.054 44.054 0 00-14.75-10.215C126.135 1.194 120.11 0 113.67 0c-7.586 0-14.234 1.553-19.942 4.658-5.708 3.106-10.288 7.162-13.74 12.17-3.417-5.008-7.985-9.064-13.702-12.17C60.568 1.553 53.916 0 46.33 0c-6.42 0-12.433 1.194-18.035 3.582S17.768 9.375 13.519 13.797c-4.25 4.421-7.565 9.682-9.947 15.781C1.191 35.678 0 42.438 0 49.86z", strokeWidth: 30 }),
        React.createElement("path", { id: "heart-animated_svg__b", d: "M0 0l22.285 1.347L20.22 14.92a10.206 10.206 0 01-10.819 8.645A10.125 10.125 0 010 13.466z" }),
        React.createElement("mask", { id: "heart-animated_svg__c" },
            React.createElement("use", { fill: "#fff", xlinkHref: "#heart-path" })),
        React.createElement("mask", { id: "heart-animated_svg__d", fill: "#fff" },
            React.createElement("use", { fillRule: "evenodd", xlinkHref: "#heart-animated_svg__b" }))),
    React.createElement("g", { id: "wrapper", fill: "none", fillRule: "evenodd" },
        React.createElement("use", { mask: "url(#heart-animated_svg__c)", xlinkHref: "#heart-path", id: "heart" }),
        React.createElement("g", { stroke: "#2c0f00", strokeLinecap: "round", strokeLinejoin: "round", id: "wink-1" },
            React.createElement("path", { d: "M125.104 49.99c-15.292-4.025-27.578.928-36.858 14.859 15.358.267 27.044 3.76 35.06 10.475", strokeWidth: 13.136 }),
            React.createElement("path", { d: "M34.187 45.885c15.67-1.123 27.117 5.047 34.344 18.51-15.21-2.132-27.243-.914-36.098 3.654", strokeWidth: 12.911 })),
        React.createElement("g", { transform: "translate(42.949 47.445)", id: "wink-2" },
            React.createElement("path", { d: "M75.295.73c-9.545-2.312-17.125.87-22.738 9.548 9.544 0 16.843 2.025 21.896 6.074", stroke: "#2c0f00", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 8.678 }),
            React.createElement("path", { d: "M1.907 1.182c9.736-.692 16.85 3.109 21.341 11.4C13.797 11.27 6.32 12.02.818 14.834", stroke: "#2c0f00", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 8.529 }),
            React.createElement("ellipse", { cx: 36.799, cy: 33.44, fill: "#2c0f00", rx: 7.726, ry: 7.64 })),
        React.createElement("g", { transform: "rotate(-11 298.172 -145.044)", id: "wink-3" },
            React.createElement("g", { transform: "translate(28.323 33.277)" },
                React.createElement("use", { fill: "#2c0f00", stroke: "#2c0f00", strokeLinejoin: "round", strokeWidth: 8.046, xlinkHref: "#heart-animated_svg__b" }),
                React.createElement("ellipse", { cx: -1.741, cy: 22.219, fill: "#ffffe7", mask: "url(#heart-animated_svg__d)", rx: 24.026, ry: 16.832 })),
            React.createElement("path", { d: "M83.202 19.175c-7.483-1.872-13.425.701-17.827 7.718 7.483 0 13.205 1.637 17.167 4.912", stroke: "#2c0f00", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 6.705 }),
            React.createElement("ellipse", { cx: 12.257, cy: 11.017, fill: "#2c0f00", rx: 12.257, ry: 11.017 }),
            React.createElement("g", { fill: "#fff" },
                React.createElement("ellipse", { cx: 6.979, cy: 6.694, rx: 4.245, ry: 3.347, transform: "rotate(-21 6.98 6.694)" }),
                React.createElement("ellipse", { cx: 16.342, cy: 17.027, rx: 2.043, ry: 1.669 })))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
