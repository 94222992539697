import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { Location, LocationDescriptor } from 'history'
import queryString from 'query-string'

import { DocumentHead, useModalContext } from '@sketch/components'
import { SketchLoginDeprecationModal } from '@sketch/user'

import { useSignUpFormContextForAnalytics } from './useSignUpFormContextForAnalytics'
import {
  useQueryParams,
  RouteProps,
  routes,
  useAnalytics,
  IndexLayoutExtraProps,
  IndexLayoutOldTitle,
  IndexLayoutContent,
} from '@sketch/modules-common'

// GQL
import {
  useAcceptWorkspaceInviteMutation,
  useAcceptProjectMembershipInvitationMutation,
  SignUpMutation,
  DataWithoutUserErrors,
} from '@sketch/gql-types'

import { isPricingSourceRoute } from '../../../utils'

import BaseSignUpView from './BaseSignUpView'
import CampaignSignUpView from './CampaignSignUpView'

interface LocationState {
  from?: Location
}
interface SignUpViewProps extends RouteProps<'SIGN_UP'>, IndexLayoutExtraProps {
  location: Location<LocationState>
}
interface ProjectInviteProps {
  from: LocationDescriptor
  projectInvitation: {
    projectMembershipIdentifier: string
    token: string
  }
}

const SignUpView: React.FC<SignUpViewProps> = ({
  history,
  location,
  useOverrideLayoutProps,
  HeaderPortal,
}) => {
  const {
    trackEvent,
    trackSimpleAnalytics,
    removeSimpleAnalytics,
    trackSimpleEvent,
  } = useAnalytics()
  const formContextForAnalytics = useSignUpFormContextForAnalytics()

  useEffect(() => {
    trackSimpleAnalytics()
    return () => removeSimpleAnalytics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { state } = location
  const { showModal } = useModalContext()

  const queryParams = useQueryParams<'SIGN_UP'>()

  const linkState = { from: state?.from }
  const searchQuery: {
    token?: string
    email?: string
  } = queryString.parse(linkState?.from?.search || '')

  const [acceptWorkspaceInvite] = useAcceptWorkspaceInviteMutation({
    // As a temporary solution we call the mutation and proceed without
    // handling any possible errors.
    // https://github.com/sketch-hq/Cloud/issues/10236
    onError: 'do-nothing',
  })

  const [acceptProjectInvite] = useAcceptProjectMembershipInvitationMutation({
    // As a temporary solution we call the mutation and proceed without
    // handling any possible errors.
    // https://github.com/sketch-hq/Cloud/issues/10236
    onError: 'do-nothing',
  })

  const email = decodeURIComponent(queryParams?.email ?? '')

  const onCompleted = (data: DataWithoutUserErrors<SignUpMutation>) => {
    if (queryParams.sketch === 'true') {
      showModal(SketchLoginDeprecationModal)
    }

    /**
     * When a new user signs up a workspace is created for him
     * keeping this information for when the source query params is "pricing"
     */
    const createWorkspaceId = data.signUp?.user?.workspaces[0]?.identifier

    /**
     * If the source is pricing we should redirect the user to workspace-create
     * as mentioned here
     *
     * https://github.com/sketch-hq/Cloud/issues/19414
     */
    if (isPricingSourceRoute(location) && createWorkspaceId) {
      history.push(
        routes.WORKSPACE_SUBSCRIBE.create({ workspaceId: createWorkspaceId })
      )

      return
    }

    // Check if the user has a workspace invitation to accept
    if (
      linkState.from?.pathname === routes.WORKSPACE_INVITE.template() &&
      linkState.from?.search
    ) {
      if (searchQuery.token) {
        acceptWorkspaceInvite({
          variables: {
            membershipToken: searchQuery.token,
          },
        })
      }
    }

    const projectInvitationState = state as ProjectInviteProps
    if (
      projectInvitationState?.projectInvitation?.projectMembershipIdentifier &&
      projectInvitationState?.projectInvitation?.token
    ) {
      const { projectInvitation } = projectInvitationState
      acceptProjectInvite({
        variables: {
          input: {
            token: projectInvitation.token,
            projectMembershipIdentifier:
              projectInvitation.projectMembershipIdentifier,
          },
        },
      })
    }

    trackEvent('SIGN UP - Sign up form success', {
      formContext: formContextForAnalytics,
    })

    trackSimpleEvent('sign_up', '/signup')

    history.push(routes.CHOOSE_YOUR_PATH.create({}))
  }

  const signUpProps = {
    email: email.length ? email : searchQuery.email,
    invitedUser: Boolean(searchQuery.token),
    onCompleted,
    useOverrideLayoutProps,
    HeaderPortal,
  }

  return (
    <IndexLayoutContent center="horizontal" marginTop paddingHorizontal>
      <Helmet>
        <link rel="canonical" href="https://www.sketch.com/signup/" />
        <meta property="og:url" content="https://www.sketch.com/signup/" />
      </Helmet>
      <DocumentHead title="Create a Sketch Account" />

      {isPricingSourceRoute(location) ? (
        <CampaignSignUpView {...signUpProps} />
      ) : (
        <>
          <IndexLayoutOldTitle>Create a Sketch Account</IndexLayoutOldTitle>
          <BaseSignUpView {...signUpProps} />
        </>
      )}
    </IndexLayoutContent>
  )
}

export default SignUpView
