import React from 'react'

import { Tooltip } from '@sketch/components'

import { ReactComponent as StarIcon } from '@sketch/icons/star-16'

import useVersionStarUnstar from '../../hooks/useVersionStarUnstar'
import { Wrapper, Button } from './StarVersion.styles'

const STAR_TEXT = {
  false: 'Unstarred Version',
  true: 'Starred Version',
}

const STAR_TOOLTIP_TEXT = {
  false: 'Star this Version',
  true: 'Unstar this Version',
} as const

interface StarVersionProps {
  isNewLatest: boolean
  versionIdentifier: string
  versionDescription?: string
  shareIdentifier: string
  isLibrary: boolean
  isStarred: boolean
}

interface StarProps {
  isStarred: boolean
}

interface StarButtonProps extends StarProps {
  onStarClick?: () => void
}

export const Star = ({ isStarred }: StarProps) => (
  <Wrapper data-testid="version-star">
    <StarIcon role="img" />
    <span className="sr-only">{STAR_TEXT[`${isStarred}` as const]}</span>
  </Wrapper>
)

const StarButton: React.FC<StarButtonProps> = props => (
  <Button
    data-testid="version-star-button"
    onClick={event => {
      event.preventDefault()
      props.onStarClick?.()
    }}
  >
    <Star isStarred={props.isStarred} />
  </Button>
)

const StarVersion: React.FC<StarVersionProps> = props => {
  const {
    isNewLatest,
    versionIdentifier,
    versionDescription,
    isLibrary,
    isStarred,
    shareIdentifier,
  } = props

  const starUnstarVersion = useVersionStarUnstar({
    isNewLatest,
    versionIdentifier,
    versionDescription,
    shareIdentifier,
    isLibrary,
  })

  return (
    <Tooltip
      content={STAR_TOOLTIP_TEXT[`${isStarred}` as const]}
      placement="bottom"
    >
      <StarButton
        isStarred={isStarred}
        onStarClick={() => {
          starUnstarVersion(isStarred)
        }}
      />
    </Tooltip>
  )
}

export default StarVersion
