import React from 'react'

import { Markdown } from '@sketch/components'

import { DocumentDescription } from './EditableDocumentDescription.styles'

export interface DocumentDescriptionProps {
  children?: string
}

/**
 * Checks if the given markdown string is a whitespace only string Markdown
 * Blockquote is considered whitespace
 */
const isWhitespaceOnlyMd = (str: string) => {
  let original = str
  if (str.charAt(0) === '>') {
    original = str.substring(1)
  }
  return original.trimStart() === ''
}

const MarkdownDocumentDescription = React.forwardRef<
  HTMLParagraphElement,
  DocumentDescriptionProps
>(({ children, ...props }, ref) => {
  const renderMarkdown = children && !isWhitespaceOnlyMd(children)

  return (
    <DocumentDescription ref={ref} {...props}>
      {renderMarkdown ? <Markdown>{children}</Markdown> : children}
    </DocumentDescription>
  )
})

MarkdownDocumentDescription.displayName = 'MarkdownDocumentDescription'

export default MarkdownDocumentDescription
