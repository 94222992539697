import styled from 'styled-components'
import { lightTheme as theme } from '@sketch/global-styles'

export type LogoSize = '16px' | '24px' | '32px' | '64px' | '88px'
export type IconSize = '24px' | '32px'

interface LogoCSSProps {
  $size: LogoSize
  $hasSource?: boolean
}

interface MarginRightProp {
  /**
   * @deprecated
   *
   * This Property should be removed from this components
   * Wrappers around it should add the needed margin in order
   * to make the design requirements
   */
  $addRightMargin?: boolean
}

const WRAPPER_BORDER_RADIUS: Record<LogoSize, string> = {
  '16px': theme.radii.small,
  '24px': theme.radii.base,
  '32px': theme.radii.medium,
  '64px': theme.radii.xlarge,
  '88px': theme.radii.xlarge,
} as const

const LETTER_SIZE: Record<LogoSize, string> = {
  '16px': theme.fontSizes.A,
  '24px': theme.fontSizes.B,
  '32px': theme.fontSizes.H,
  '64px': theme.fontSizes.H,
  '88px': theme.fontSizes.I,
}

const ICON_SIZE: Record<IconSize, string> = {
  '24px': '16px',
  '32px': '18px',
}

export const Wrapper = styled.div<LogoCSSProps & MarginRightProp>`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};

  overflow: hidden;
  border-radius: ${({ $size }) => WRAPPER_BORDER_RADIUS[$size]};
  flex-shrink: 0;
  user-select: none;

  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  margin-right: ${({ $addRightMargin }) => ($addRightMargin ? '12px' : '0')};
`

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  color: transparent;
`

interface IconWrapperProps {
  $size: IconSize
}

export const IconWrapper = styled.div<IconWrapperProps>`
  width: ${({ $size }) => ICON_SIZE[$size]};
  height: ${({ $size }) => ICON_SIZE[$size]};
  line-height: 1;
  opacity: 0.7;
`

export const FirstLetter = styled.span<LogoCSSProps>`
  display: block;

  font-size: 0;
  line-height: 1;

  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.colors.foreground.secondary.C};

  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ $size }) => LETTER_SIZE[$size]};
`
