import styled from 'styled-components'

import { LoadingPlaceholder, Dropdown } from '@sketch/components'

export const Wrapper = styled.div`
  max-height: 300px;
  overflow-y: auto;
`

export const StyledLoadingPlaceholder = styled(LoadingPlaceholder)`
  display: flex;
  margin: 0 auto;
`

export const DropdownSkeleton = styled(Dropdown.Skeleton)`
  display: flex;
`

export const ErrorItem = styled(Dropdown.Item)`
  /* We want to hide cursor and hover styles for this message */
  pointer-events: none;
`
