import React from 'react'
import styled from 'styled-components'

const Placeholder = styled.span.attrs({
  'data-testid': 'comment-skeleton',
})`
  padding: 12px;
  display: block;

  ::before {
    content: '';

    margin-bottom: 8px;

    display: block;
    border-radius: 99%;

    width: 16px;
    height: 16px;

    background: ${({ theme }) => theme.colors.state.hover};
  }

  ::after {
    content: '';
    display: block;

    flex: 1;
    border-radius: 5px;
    height: 48px;

    background: ${({ theme }) => theme.colors.state.hover};
  }
`

interface CommentSkeletonProps {
  count?: number
}

const CommentSkeleton = ({ count = 1 }: CommentSkeletonProps) => {
  const placeholderCount = Math.max(0, count)

  return (
    <>
      {[...Array(placeholderCount)].map((_, index) => (
        <Placeholder key={index} />
      ))}
    </>
  )
}

export default CommentSkeleton
