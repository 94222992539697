import { DependencyList, EffectCallback, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'

type VisibilityState = 'hidden' | 'visible'

/**
 * Special version of useEffect that runs the provided effect only if the
 * current tab has the provided visibility.
 */
export const useEffectOnTabVisibility = (
  visibility: VisibilityState,
  effect: EffectCallback,
  deps: DependencyList
) => {
  const [tabVisibility, setTabVisibility] = useState(document.visibilityState)

  useEffectOnce(() => {
    const onVisibilityChange = () => {
      setTabVisibility(document.visibilityState)
    }

    document.addEventListener('visibilitychange', onVisibilityChange)

    return () =>
      document.removeEventListener('visibilitychange', onVisibilityChange)
  })

  useEffect(() => {
    if (tabVisibility !== visibility) return

    effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effect, tabVisibility, ...deps])
}
