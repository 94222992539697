import React from 'react'
import { stringify } from 'query-string'

import ArtboardSubscriptionStatus from '../../components/ArtboardSubscriptionStatus'
import CommentTabMessageRestricted from '../../components/CommentTabMessageRestricted'

import { Share } from '../../types'
import { useVersioning } from '../../../versioning'
import { useQueryParams } from '@sketch/modules-common'
import { TooltipShortcut } from '@sketch/components'
import { shortcuts } from '@sketch/utils'

// This is a valid use case to narrow down Component type
// eslint-disable-next-line no-restricted-imports
import { NotificationSubscriptionStatus } from '@sketch/gql-types/expansive'
import { usePlaceDraftAnnotation } from '../../hooks'
import { useStatus } from '@sketch-hq/sketch-web-renderer'

import { ReactComponent as ChatBubbleAddIcon } from '@sketch/icons/chat-bubble-add-16'
import { AddAnnotationButton } from './ArtboardAnnotations.styles'
import AnnotationListContainer from '../AnnotationListContainer'

interface ArtboardAnnotationsProps {
  share: Share
  permanentPageIdentifier: string
  permanentArtboardIdentifier: string
  permanentArtboardShortId: string
  artboardNotificationStatus: NotificationSubscriptionStatus
  showAddAnnotationButton?: boolean
}

const ArtboardAnnotations: React.FC<ArtboardAnnotationsProps> = props => {
  const {
    share,
    permanentArtboardIdentifier,
    permanentArtboardShortId,
    artboardNotificationStatus,
    showAddAnnotationButton,
  } = props

  const canvasStatus = useStatus()

  const { versionShortId } = useVersioning()
  const [isPlacingAnnotation, setPlaceAnnotation] =
    usePlaceDraftAnnotation() || []

  const { annotation, ...otherParams } = useQueryParams<
    'ARTBOARD_DETAIL_UUID' | 'ARTBOARD_DETAIL'
  >()

  if (!share.commentsEnabled) {
    return <CommentTabMessageRestricted />
  }

  const artboardSubscriptionStatus = (
    <ArtboardSubscriptionStatus
      shareIdentifier={share.identifier}
      permanentArtboardIdentifier={permanentArtboardIdentifier}
      permanentArtboardShortId={permanentArtboardShortId}
      documentVersionShortId={versionShortId}
      notificationStatus={artboardNotificationStatus}
    />
  )

  return (
    <AnnotationListContainer
      resolveAnnotationLink={annotation => location => ({
        ...location,
        state: {
          isFromCommentTab: true,
        },
        search: stringify({
          ...otherParams,
          annotation: annotation.identifier,
        }),
      })}
      navbarAction={
        <>
          {artboardSubscriptionStatus}
          {showAddAnnotationButton && (
            <AddAnnotationButton
              onClick={(event: MouseEvent) => {
                setPlaceAnnotation?.(!isPlacingAnnotation)
                event.stopPropagation()
              }}
              aria-current={isPlacingAnnotation}
              disabled={canvasStatus?.type !== 'READY'}
              description={
                <>
                  Add comment{' '}
                  <TooltipShortcut>
                    Press {shortcuts.addComment.toUpperCase()}
                  </TooltipShortcut>
                </>
              }
            >
              <span className="sr-only">New comment</span>
              <ChatBubbleAddIcon role="img" />
            </AddAnnotationButton>
          )}
        </>
      }
      activeAnnotationIdentifier={annotation}
    />
  )
}

export default ArtboardAnnotations
