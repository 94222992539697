import React, { useState } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'

import { Dropdown, LoadingPlaceholder } from '@sketch/components'

import { MAX_SIZE } from '@sketch/utils'

import {
  Dropzone,
  Overlay,
  ToggleWrapper,
  WorkspaceLogo,
  WorkspaceLogoDropzoneWrapper,
} from './WorkspaceSettingsLogoUpload.styles'

const megabytesToBytes = (n: number): number => n * 1024 * 1024

interface WorkspaceSettingsLogoUploadProps {
  logo?: string
  workspaceName: string
  onDrop: (file: File) => Promise<void> | void
  onError?: (message: string) => void
  onRemove?: () => void
}

export const WorkspaceSettingsLogoUpload: React.FC<WorkspaceSettingsLogoUploadProps> = props => {
  const { logo, workspaceName, onDrop, onError, onRemove } = props
  const [loading, setLoading] = useState(false)

  const handleOnDrop: DropzoneOptions['onDrop'] = async ([acceptedFile]) => {
    if (!acceptedFile) {
      const maxFileSize = MAX_SIZE / megabytesToBytes(1)

      onError?.(
        `Please upload a png or jpg with a maximum size of ${maxFileSize}MB`
      )
      return
    }

    setLoading(true)

    await onDrop(acceptedFile)
    setLoading(false)
  }

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: {
      'image/png': [],
      'image/jpeg': [],
      'image/jpg': [],
    },
    onDrop: handleOnDrop,
    maxSize: MAX_SIZE,
  })

  let children: React.ReactNode | null = null

  if (logo) {
    children = (
      <Dropdown
        toggle={
          <ToggleWrapper>
            <WorkspaceLogo
              workspaceName={workspaceName}
              src={logo}
              size="64px"
            />
            <Overlay>Edit</Overlay>
          </ToggleWrapper>
        }
        placement="right-start"
      >
        <Dropdown.Header>Avatar</Dropdown.Header>
        <Dropdown.Item onClick={() => open()}>Upload Image</Dropdown.Item>
        <Dropdown.Item onClick={onRemove}>Remove Current Image</Dropdown.Item>
      </Dropdown>
    )
  } else {
    children = (
      <WorkspaceLogoDropzoneWrapper>
        <Dropzone
          data-testid="dropzone"
          $isDragActive={isDragActive}
          {...getRootProps({ refKey: 'ref' })}
        />
        <WorkspaceLogo workspaceName={workspaceName} src={logo} size="64px" />
        {loading && (
          <Overlay>
            <LoadingPlaceholder
              data-testid="logo-spinner"
              primary
              size="24px"
            />
          </Overlay>
        )}
      </WorkspaceLogoDropzoneWrapper>
    )
  }

  return (
    <>
      <label>
        <span className="sr-only">Workspace upload logo</span>
        <input {...getInputProps()} />
      </label>
      {children}
    </>
  )
}
