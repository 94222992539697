import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "control-switches-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "currentColor" },
        React.createElement("path", { d: "M14.25 4a.75.75 0 01.102 1.493l-.102.007H7.5V4h6.75zM3 5.5H1.75a.75.75 0 01-.102-1.493L1.75 4H3v1.5z" }),
        React.createElement("path", { d: "M4.882 1.5c-.62 0-.87.07-1.115.2a1.371 1.371 0 00-.567.567c-.13.245-.2.495-.2 1.115v2.736c0 .62.07.87.2 1.115.131.244.323.436.567.567.245.13.495.2 1.115.2h.736c.62 0 .87-.07 1.115-.2.244-.131.436-.323.567-.567.13-.245.2-.495.2-1.115V3.382c0-.62-.07-.87-.2-1.115a1.363 1.363 0 00-.567-.567c-.245-.13-.495-.2-1.115-.2h-.736zM4.779 3h.942c.154.001.224.004.261.01.014.045.017.115.018.269v2.942a2.132 2.132 0 01-.01.261 1.13 1.13 0 01-.269.018h-.839a3.21 3.21 0 01-.364-.01 1.13 1.13 0 01-.018-.269V3.382c0-.228.003-.32.01-.364A1.13 1.13 0 014.779 3zm3.72 7.5L8.5 12H1.75a.75.75 0 01-.102-1.493l.102-.007h6.749zm5.751 0a.75.75 0 01.102 1.493L14.25 12H13l-.001-1.5h1.251z" }),
        React.createElement("path", { d: "M11.118 8h-.736c-.62 0-.87.07-1.115.2a1.371 1.371 0 00-.567.567c-.13.245-.2.495-.2 1.115v2.736c0 .62.07.87.2 1.115.131.244.323.436.567.567.245.13.495.2 1.115.2h.736c.62 0 .87-.07 1.115-.2.244-.131.436-.323.567-.567.13-.245.2-.495.2-1.115V9.882c0-.62-.07-.87-.2-1.115a1.363 1.363 0 00-.567-.567c-.245-.13-.495-.2-1.115-.2z", fillOpacity: 0.15 }),
        React.createElement("path", { d: "M10.382 8c-.62 0-.87.07-1.115.2a1.371 1.371 0 00-.567.567c-.13.245-.2.495-.2 1.115v2.736c0 .62.07.87.2 1.115.131.244.323.436.567.567.245.13.495.2 1.115.2h.736c.62 0 .87-.07 1.115-.2.244-.131.436-.323.567-.567.13-.245.2-.495.2-1.115V9.882c0-.62-.07-.87-.2-1.115a1.363 1.363 0 00-.567-.567c-.245-.13-.495-.2-1.115-.2h-.736zm-.103 1.5h.942c.154.001.224.004.261.01.014.045.017.115.018.269v2.942a2.132 2.132 0 01-.01.261 1.13 1.13 0 01-.269.018h-.839a3.21 3.21 0 01-.364-.01 1.13 1.13 0 01-.018-.269V9.882c0-.228.003-.32.01-.364a1.13 1.13 0 01.269-.018z" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
