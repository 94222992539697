import styled from 'styled-components'

import { breakpoint } from '@sketch/global-styles'

import { ReactComponent as ArrowRaw } from '@sketch/icons/pencil-16'

import { Link } from '@sketch/components'

export const Wrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  margin: 0 16px;
  align-items: center;
  margin-top: 4px;
`
export const EditIcon = styled(ArrowRaw)`
  width: 16px;
  height: 16px;
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.state.neutral.A};
`

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  margin-left: 8px;
  font-size: ${({ theme }) => theme.fontSizes.D};

  ${({ theme }) => breakpoint('base', 'xs')`
    font-size: ${theme.fontSizes.B};
  `}
`

export const StyledLink = styled(Link)`
  display: flex;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  margin-left: auto;
  font-size: ${({ theme }) => theme.fontSizes.D};

  ${({ theme }) => breakpoint('base', 'xs')`
    font-size: ${theme.fontSizes.B};
  `}
`
