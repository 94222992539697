import React from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import {
  setActiveAuthorizationId,
  useUserAuthorizations,
} from '@sketch/modules-common'
import { LocationDescriptor } from 'history'
import { useApolloClient } from 'react-apollo'
import { ErrorHandler } from '@sketch/tracing'

interface PersonalAuthRouteProps extends RouteProps {
  fallbackRouteIfUserOnlyHasSsoAuth?: (props: RouteProps) => LocationDescriptor
}

/**
 * Sets the personal session (if any) as active. Making all requests fired from
 * this route use the personal token.
 */
const PersonalAuthRoute: React.FC<PersonalAuthRouteProps> = ({
  fallbackRouteIfUserOnlyHasSsoAuth,
  ...props
}) => {
  const { authorizations, hasPersonalAuthorization } = useUserAuthorizations()
  const apolloClient = useApolloClient()

  if (
    !hasPersonalAuthorization &&
    authorizations.length > 0 &&
    fallbackRouteIfUserOnlyHasSsoAuth
  ) {
    return <Redirect to={fallbackRouteIfUserOnlyHasSsoAuth(props)} />
  }

  try {
    setActiveAuthorizationId({ type: 'personal' }, apolloClient.cache)
  } catch (e) {
    ErrorHandler.ignore(
      e as Error,
      'Ignoring to prevent crash and carry on with the route.'
    )
  }

  return <Route {...props} />
}

export default PersonalAuthRoute
