import styled, { css } from 'styled-components'
import { IconWrapper } from '../MenuLink'
import { Text, inputBaseStyles } from '@sketch/components'

const InlineEditorWrapper = styled(Text.Div).attrs({
  textStyle: 'copy.primary.standard.D',
})(
  ({ theme }) => css`
    width: 100%;
    height: 32px;

    display: inline-flex;
    align-items: center;
    position: relative;

    background-color: transparent;

    /* Remove 5px from the right padding so that the button icon stays at 30px from the sidebar end. */
    padding: 8px 0;
    border-radius: ${theme.radii.medium};

    font-weight: ${theme.fontWeights.medium};
    line-height: 1.5; /* stylelint-disable-line scales/line-height */

    transition: none;
  `
)

const StyledIconWrapper = styled(IconWrapper)`
  position: absolute;
  left: 12px;
  z-index: 1;
  margin-right: 0;

  svg {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }
`

const StyledInput = styled.input<{ hasError: string | undefined }>`
  ${inputBaseStyles}

  && {
    height: 32px;
    padding-left: 38px; /* stylelint-disable-line scales/space */
    padding-top: 8px;
    padding-bottom: 8px;
    border-width: 2px;
    box-shadow: none;
    border-color: ${({ theme, hasError }) =>
      hasError && theme.colors.state.negative.A};
  }

  &:focus {
    background: transparent;

    && {
      border-color: ${({ theme, hasError }) =>
        hasError && theme.colors.state.negative.A};
    }
  }

  &:disabled {
    border: none;
  }

  font-size: ${({ theme }) => theme.fontSizes.D};
`

export { StyledIconWrapper, StyledInput, InlineEditorWrapper }
