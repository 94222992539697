import React from 'react'

import { Button, Section, useModalContext } from '@sketch/components'

import DeleteAccountModal from './DeleteAccountModal'

type OtherOptionsPanelProps = {
  hasPersonalIdentity: boolean
}
export const OtherOptionsPanel = ({
  hasPersonalIdentity,
}: OtherOptionsPanelProps) => {
  const { showModal } = useModalContext()

  /* Avoid a scenario where only the "Other" title was being rendered */
  if (!hasPersonalIdentity) {
    return null
  }

  return (
    <Section id="delete-account-header" title="Other">
      <Button
        variant="negative"
        size="40"
        onClick={() => showModal(DeleteAccountModal)}
      >
        Delete Account&hellip;
      </Button>
    </Section>
  )
}
