import React from 'react'
import { useCurrentBreakpoint } from '@sketch/components'
import { Breakpoints } from '@sketch/global-styles'

import { GridPublicationItemPlaceholder } from '../GridPublicationItem'
import { Wrapper } from './PublicationGrid.styles'

const PLACEHOLDER_PER_BREAKPOINT: Record<Breakpoints, number> = {
  base: 2,
  xxs: 2,
  xs: 6,
  sm: 9,
  md: 8,
  lg: 8,
  xl: 8,
  xxl: 8,
  xxxl: 8,
}

interface PublicationGridPlaceholderProps {
  limit?: number
}

const PublicationGridPlaceholder = ({
  limit = 9,
}: PublicationGridPlaceholderProps) => {
  const breakpoint = useCurrentBreakpoint()
  const itemCount = PLACEHOLDER_PER_BREAKPOINT[breakpoint]

  return (
    <>
      <Wrapper>
        {Array.from({ length: Math.min(itemCount, limit) }).map((_, index) => (
          <GridPublicationItemPlaceholder key={index} />
        ))}
      </Wrapper>
    </>
  )
}

export default PublicationGridPlaceholder
