import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "exclamation-mark-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("circle", { fill: "currentColor", cx: 8, cy: 10.5, r: 1 }),
        React.createElement("path", { d: "M7 4.5h2v3a1 1 0 11-2 0v-3z", fill: "currentColor" }),
        React.createElement("path", { d: "M7.755 1.75c1.863 0 2.927.234 3.822.713a4.712 4.712 0 011.96 1.96c.473.884.713 1.933.713 3.332 0 1.863-.234 2.927-.713 3.822a4.712 4.712 0 01-1.96 1.96c-.884.473-1.933.713-3.332.713-1.863 0-2.927-.234-3.822-.713a4.712 4.712 0 01-1.96-1.96c-.473-.884-.713-1.933-.713-3.332 0-1.863.234-2.927.713-3.822a4.712 4.712 0 011.96-1.96c.884-.473 1.933-.713 3.332-.713z", stroke: "currentColor", strokeWidth: 1.5 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
