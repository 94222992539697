import styled, { css } from 'styled-components'

const skeleton = css`
  display: block;
  background: ${({ theme }) => theme.colors.state.hover};
  border-radius: ${({ theme }) => theme.radii.medium};
`

const SkeletonBase = styled.span`
  ${skeleton}
`

export const SkeletonTitle = styled(SkeletonBase)`
  width: 100%;
  height: 24px;
`

export const SkeletonDescription = styled(SkeletonBase)`
  margin-top: 12px;
  margin-bottom: 16px;

  width: 100%;
  height: 89px;
`
