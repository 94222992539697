import { buildFlags } from '@sketch/components'
import { defaultFlags } from '@sketch/constants'

export { defaultFlags }
export const { Flag, FlagsContext, FlagsProvider, useFlag } = buildFlags(
  defaultFlags
)

export type FlagKeys = keyof typeof defaultFlags
export type FlagsObject = { [flag in FlagKeys]: boolean }
