import React from 'react'
import { SidebarEmpty } from '@sketch/components'

const InspectorRestricted = () => (
  <SidebarEmpty
    title="Inspector not available"
    description="You need to be an Editor to measure and view the document’s design attributes."
  />
)

export default InspectorRestricted
