import styled from 'styled-components'

import { ErrorMessage, Flex, LinkButton } from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'

export const StyledErrorMessage = styled(ErrorMessage)`
  max-width: 660px;
  margin: auto;

  flex: 1 0 auto;
  padding: 0 16px;
`

export const StyledLinkButton = styled(LinkButton).attrs({ size: '32' })`
  &:last-child {
    ${breakpoint('base', 'xs')`
      margin-left: 0px;
    `}
  }
`

export const ButtonWrapper = styled(Flex)`
  flex-direction: column;

  a {
    margin-bottom: 8px;
  }

  button {
    margin: 0px;
  }

  ${breakpoint('xs')`
    flex-direction: row;

    a {
      margin-bottom: 0px;
    }

    button {
      margin-left: 16px;
    }
  `};
`
