import { roundUpTo } from '@sketch/utils'
import { Color } from '../types'

/**
 * Format a color to a CSS rgba string.
 * Make sure to round the alpha which can have floating point precision issues.
 */
export function formatCssRgbaColor(color: Color) {
  const formattedRgba = `rgba(${color.red}, ${color.green}, ${
    color.blue
  }, ${roundUpTo(color.alpha ?? 1, 2)})`

  return formattedRgba
}
