import {
  ShareListItemFragment,
  useDuplicateShareToMutation,
} from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'
import { useEventDispatch } from '@sketch/utils'

interface UseDuplicateShareToProps {
  share: Pick<
    ShareListItemFragment,
    'name' | 'identifier' | 'project' | 'collection'
  >
  workspaceIdentifier: string
  versionIdentifier: string
}

export function useDuplicateShareTo(props: UseDuplicateShareToProps) {
  const { showToast } = useToast()

  const { share, versionIdentifier, workspaceIdentifier } = props

  const projectIdentifier = share.project?.identifier
  const collectionIdentifier = share.collection?.identifier

  const dispatchShareRefresh = useEventDispatch('workspaceShareRefresh')

  const [
    duplicateShareTo,
    { data, loading, error },
  ] = useDuplicateShareToMutation({
    variables: {
      sourceShareIdentifier: share.identifier,
      sourceVersionIdentifier: versionIdentifier,
      targetWorkspaceIdentifier: workspaceIdentifier,
      targetProjectIdentifier: projectIdentifier,
      targetCollectionIdentifier: collectionIdentifier,
    },
    onError: 'show-toast',
    onCompleted: ({ duplicateShareTo }) => {
      showToast('Document duplicated')

      dispatchShareRefresh({
        workspaceIdentifier: workspaceIdentifier,
        projectIdentifier: duplicateShareTo?.share.project?.identifier,
        collectionIdentifier: duplicateShareTo?.share.collection?.identifier,
      })
    },
  })

  return [duplicateShareTo, { data, loading, error }] as const
}
