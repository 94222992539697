import styled from 'styled-components'

import {
  Dropdown,
  DropdownButton as BaseDropdownButton,
} from '@sketch/components'
import { ReactComponent as EllipsisIcon } from '@sketch/icons/dots-3-horizontal-24'

export const CheckboxWrapper = styled(Dropdown.Item.ListItem)`
  padding: 4px 16px;
`

export const DropdownButtonEllipsis = styled(EllipsisIcon)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

export const DropdownButton = styled(BaseDropdownButton)`
  position: absolute;
  z-index: 1;

  top: 9px;
  right: 9px;

  opacity: 0;
  transform: translate3d(0, -5px, 0);
  transition: ${({ theme }) => `
    opacity ${theme.transitions.duration[0]} ${theme.transitions.timing.easeInOut},
    transform ${theme.transitions.duration[0]} ${theme.transitions.timing.easeInOut};
  `};

  /* Prevent the button from hiding when the dropdown is open */
  &[aria-expanded='true'] {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &:has([aria-expanded='true']),
  :focus-within {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`
