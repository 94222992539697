import { getThumbnail, createSrcSet } from '../utils'
import type { File, Thumbnail } from '../types'

type useResponsiveImageProps = {
  files?: File[]
  name: string
}

const defaultImgExport = {
  src: '',
  alt: '',
  srcSet: undefined,
}

export default function useResponsiveImage({
  name,
  files,
}: useResponsiveImageProps) {
  if (!files?.length) {
    return defaultImgExport
  }

  return {
    ...defaultImgExport,
    src: getThumbnail(files[0].thumbnails as Thumbnail[]),
    alt: name,
    srcSet: createSrcSet(files) || undefined,
  }
}
