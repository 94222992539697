import styled, { css } from 'styled-components'

import { breakpoint } from '@sketch/global-styles'

import { Modal } from '@sketch/components'

import { MembersList, ModalWarning } from '../../components'

export const ModalDialog = styled(Modal)<{ isSummaryVisible?: boolean }>`
  ${({ isSummaryVisible }) =>
    isSummaryVisible
      ? css`
          ${breakpoint('sm')`
            max-width: 925px
          `}
        `
      : css`
          max-width: 576px;
        `};
`

export const StyledModalWarning = styled(ModalWarning)`
  margin-top: 30px; /* stylelint-disable-line scales/space */
`

export const Columns = styled.div<{ $smallerMargin?: boolean }>`
  display: flex;
  margin-top: ${({ $smallerMargin }) =>
    $smallerMargin ? '31px' : '45px'}; /* stylelint-disable-line scales/space */

  ${breakpoint('base', 'sm')`
    flex-direction: column;
  `}
`

export const Column = styled.div<{ isSummaryHidden?: boolean }>`
  width: ${({ isSummaryHidden }) => (isSummaryHidden ? '100%' : 'auto')};

  ${({ isSummaryHidden }) =>
    !isSummaryHidden &&
    css`
      &:first-child {
        ${breakpoint('sm')`
          width: calc(55% - 15px);
        `}
      }

      &:nth-child(2) {
        ${breakpoint('sm')`
          width: calc(45% - 15px);
          margin-left: 30px; /* stylelint-disable-line scales/space */
        `}

        ${({ theme }) => breakpoint('base', 'sm')`
            border-top: 1px solid ${theme.colors.border.A};
            margin: -40px -40px 0 -40px;
            padding: 40px 40px 0px 40px;
        `}
      }
    `}
`

export const StyledSummaryWrapper = styled.div`
  ${({ theme }) => breakpoint('sm', 'xxl')`
    padding: 10px 20px;
    margin-bottom: 30px;
    background-color: ${theme.colors.background.secondary.B};
    border: 1px solid ${theme.colors.border.A};
    box-shadow: ${theme.shadows.boxShadow.subtle};
    border-radius: 4px;
  `}
`

export const StyledMemberList = styled(MembersList)`
  /* Space between the members section and the bottom component */
  margin-bottom: 32px;

  ${breakpoint('base', 'sm')`
    margin-bottom: 40px;
  `}
`

export const DiscountWrapper = styled.div`
  margin-bottom: 16px;
  padding: 16px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.border.A};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`

export const SubTitle = styled.p`
  && {
    /* Override "Modal p" styles */
    padding-top: 4px;
    padding-bottom: 0;
    font-size: ${({ theme }) => theme.fontSizes.E};
    color: ${({ theme }) => theme.colors.foreground.secondary.C};
    font-weight: normal;
  }
`
