import styled from 'styled-components'
import { StyledButton } from '@sketch/components'

export const PlayButtonNavbar = styled(StyledButton).attrs({
  variant: 'secondary',
})`
  height: 32px;
  padding: 4px 8px;
  box-shadow: none;
  border: none;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  :hover,
  :focus,
  :active {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  [role='img'] {
    display: block;
    width: 24px;
    height: 24px;
  }
`

export const PlayButtonToolbar = styled(StyledButton).attrs({
  variant: 'secondary-untinted',
  size: '32',
})`
  box-shadow: none;

  [role='img'] {
    margin-left: 4px;
    display: block;

    width: 16px;
    height: 16px;

    opacity: 0.55;
  }
`
