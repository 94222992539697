import React from 'react'
import * as yup from 'yup'
import { Formik, FormikHelpers } from 'formik'

import {
  Form,
  Button,
  Separator,
  Modal,
  PasswordInput,
  ModalInjectedProps,
} from '@sketch/components'
import { useUserDeleteMutation } from '@sketch/gql-types'
import { useSignOut } from '@sketch/modules-common'

const DELETE_ACCOUNT_SCHEMA = yup.object().shape({
  password: yup.string().required('Password can’t be blank'),
})

interface FormValues {
  password: string
}

type FormActions = FormikHelpers<FormValues>

const DeleteAccountModal = ({ hideModal }: ModalInjectedProps) => {
  const signOut = useSignOut({
    revokeSessions: 'none',
    reason: 'Deleted account',
  })
  const [deleteUser] = useUserDeleteMutation({
    redirectErrors: false,
    onError: 'unsafe-throw-exception',
  })
  const onSubmit = (values: FormValues, actions: FormActions) => {
    deleteUser({ variables: { password: values.password } })
      .then(({ data }) => {
        if (!data || !data.userDelete) return

        actions.setSubmitting(false)

        if (data.userDelete.errors && data.userDelete.errors.length > 0) {
          actions.setStatus(data.userDelete.errors.map(e => e?.message).join())
        }

        if (data.userDelete.successful) {
          if (hideModal && typeof hideModal === 'function') {
            hideModal()
          }
          signOut()
        }
      })
      .finally(() => {
        actions.setSubmitting(false)
      })
  }
  return (
    <Modal title="Delete Your Account and All Documents?" onCancel={hideModal}>
      <Formik
        initialValues={{ password: '' }}
        validationSchema={DELETE_ACCOUNT_SCHEMA}
        onReset={hideModal}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          handleReset,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          status,
        }) => (
          <>
            <Modal.Body>
              <p>
                Deleting your account will also delete all documents you may
                have shared. You can‘t undo this action.
              </p>
              <Separator mt={5} mb={5} />
              <Form id="delete-account-form">
                <Form.Field
                  name="password"
                  label="Enter Your Password to Confirm"
                  errorText={touched.password ? errors.password : undefined}
                >
                  <PasswordInput
                    name="password"
                    disabled={isSubmitting}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="password-input"
                  />
                </Form.Field>
              </Form>
              {status && (
                <Form.Error
                  style={{ display: 'block', margin: '20px 0 0 0' }}
                  data-testid="password-error"
                >
                  {status}
                </Form.Error>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="reset"
                disabled={isSubmitting}
                onClick={handleReset}
                variant="secondary"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="negative-secondary"
                loading={isSubmitting}
                disabled={isSubmitting}
                form="delete-account-form"
                data-testid="submit-button"
              >
                Delete
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  )
}

export default DeleteAccountModal
