import React from 'react'
import { Box } from '../../Box'
import { Wrapper } from './BaseListRow.styles'

import type { BaseProps } from '../types'
import styled from 'styled-components'

const StyledActionsBox = styled(Box)`
  flex: 0 0 auto;
`

export const BaseListRow = ({
  identifier,
  name,
  avatar,
  inviteStatus,
  actions,
  showStripes,
  ariaLabel,
  children,
  ...boxProps
}: BaseProps) => (
  <Wrapper
    key={identifier}
    aria-label={ariaLabel || `Row of ${name}`}
    showStripes={showStripes}
    {...boxProps}
  >
    {avatar}
    {children}
    {/* We need to set margin left auto to the actions
    so it will align to the end only this item */}
    {actions ? <StyledActionsBox ml="auto">{actions}</StyledActionsBox> : null}
  </Wrapper>
)
