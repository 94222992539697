import { LoadingPage as LoadingPageBase } from './LoadingPage'
import { PrototypeLoadingPage } from './PrototypeLoadingPage'
import { ArtboardDetailsLoadingPage } from './ArtboardDetailsLoadingPage'
import { SidebarLoadingPage } from './SidebarLoadingPage'
import { SsoLoadingPage } from './SsoLoadingPage'
import { WorkspaceSubscribeLoadingPage } from './WorkspaceSubscribeLoadingPage'
import { IndexLayoutLoading } from './IndexLayoutLoading'
import { WorkspaceSidebarLoadingPage } from './WorkspaceSidebarLoadingPage'

// TODO: Split LoadingPage and DynamicLoadingPage https://github.com/sketch-hq/Cloud/issues/16626
export const LoadingPage = Object.assign(LoadingPageBase, {
  Prototype: PrototypeLoadingPage,
  Artboard: ArtboardDetailsLoadingPage,
  Sidebar: SidebarLoadingPage,
  Sso: SsoLoadingPage,
  WorkspaceSubscribe: WorkspaceSubscribeLoadingPage,
  IndexLayout: IndexLayoutLoading,
  WorkspaceSidebar: WorkspaceSidebarLoadingPage,
})
