import React from 'react'

import { Tooltip, Section } from '@sketch/components'

import BillingInfoTable from '../BillingInfoTable'
import Panel from '../Panel'

import { useHandleCopyValue, CopiableArea } from '@sketch/modules-common'

interface BillingWorkspacePanelProps {
  workspaceId: string
  workspaceName: string
}

export const BillingWorkspacePanel: React.FC<BillingWorkspacePanelProps> = props => {
  const { workspaceName, workspaceId } = props

  const {
    handleCopyValue,
    copyTooltipText,
    handleEnterCopiableArea,
    handleLeaveCopiableArea,
    copyTooltipVisible,
  } = useHandleCopyValue(workspaceId)

  return (
    <Panel>
      <Section title="Workspace Details" />
      <Panel.Body>
        <BillingInfoTable>
          <BillingInfoTable.Row>
            <BillingInfoTable.HeadingCell>Name</BillingInfoTable.HeadingCell>
            <BillingInfoTable.Cell>
              <strong>{workspaceName}</strong>
            </BillingInfoTable.Cell>
          </BillingInfoTable.Row>
          <BillingInfoTable.Row>
            <BillingInfoTable.HeadingCell>ID</BillingInfoTable.HeadingCell>

            <BillingInfoTable.Cell>
              <CopiableArea
                onClick={handleCopyValue}
                onMouseEnter={handleEnterCopiableArea}
                onMouseLeave={handleLeaveCopiableArea}
              >
                <Tooltip
                  placement="right"
                  content={copyTooltipText}
                  visible={copyTooltipVisible}
                >
                  <strong>{workspaceId}</strong>
                </Tooltip>
              </CopiableArea>
            </BillingInfoTable.Cell>
          </BillingInfoTable.Row>
        </BillingInfoTable>
      </Panel.Body>
    </Panel>
  )
}
