import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "person-circle-20", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 20 20", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("circle", { id: "person-circle-a", cx: 10, cy: 10, r: 8.5 })),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("mask", { id: "person-circle-b", fill: "#fff" },
            React.createElement("use", { xlinkHref: "#person-circle-a" })),
        React.createElement("use", { stroke: "currentColor", xlinkHref: "#person-circle-a" }),
        React.createElement("path", { fill: "currentColor", fillOpacity: 0.3, stroke: "currentColor", d: "M10 12.5c3.59 0 6.5 2.216 6.5 4.95 0 2.733-13 2.733-13 0 0-2.734 2.91-4.95 6.5-4.95zm0-7a2.5 2.5 0 110 5 2.5 2.5 0 010-5z", mask: "url(#person-circle-b)" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
