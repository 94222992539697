import styled from 'styled-components'

import { breakpoint } from '@sketch/global-styles'

import { IndexLayoutOldTitle } from '@sketch/modules-common'
import { LinkButton } from '@sketch/components'

export const FormContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
`

export const BreadcrumbsWrapper = styled.div`
  display: none;

  ${breakpoint('sm')`
    display: block;

    text-align: center;
    margin-top: 16px;

    + ${IndexLayoutOldTitle} {
      margin-top: 32px;
    }
  `}
`

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.E};
`

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
`

export const StyledLinkButton = styled(LinkButton)`
  width: 100%;
  margin-top: 32px;
`

export const Line = styled.div`
  width: 100%;
  margin-top: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`
