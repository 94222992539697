import React from 'react'
import { useToast } from '@sketch/toasts'
import { DropdownActionButton } from '@sketch/components'
import { useAcceptProjectAccessRequestMutation } from '@sketch/gql-types'

interface AddMemberButtonProps {
  membershipIdentifier: string
  text?: string
}

export const AddMemberButton: React.FC<AddMemberButtonProps> = function (
  props
) {
  const { membershipIdentifier, children } = props
  const { showToast } = useToast()

  const [acceptProjectRequest] = useAcceptProjectAccessRequestMutation({
    variables: { membershipIdentifier },
    onError() {
      showToast(
        'Something went wrong while accepting this access request.',
        'negative'
      )
    },
  })

  return (
    <DropdownActionButton
      variant="secondary"
      onClick={() => acceptProjectRequest()}
    >
      {children}
    </DropdownActionButton>
  )
}
