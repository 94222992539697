import { useCallback, useRef } from 'react'
import useMarkCommentAsRead from '../operations/useMarkCommentAsRead'

const INTERSECT_OPTIONS = {
  rootMargin: '0px',
  threshold: 1.0,
}

const DATA_IDENTIFIER_KEY = 'data-comment-identifier'
const DATA_NEW_KEY = 'data-new'

export const createCommentProps = (
  identifier: string,
  newState: boolean | null
) => ({
  [DATA_IDENTIFIER_KEY]: identifier,
  [DATA_NEW_KEY]: newState,
})

const isUnreadComment = (element: Element) =>
  element.getAttribute(DATA_IDENTIFIER_KEY) &&
  element.getAttribute(DATA_NEW_KEY) === 'true'

const createIntersectionObserver = (
  markAsRead: (commentIdentifiers: string[]) => void
) => {
  return new IntersectionObserver((entries, observer) => {
    const commentIdentifiers: string[] = []

    entries.reduce((acc, { target, isIntersecting }) => {
      const commentIdentifier = target.getAttribute(DATA_IDENTIFIER_KEY)

      if (commentIdentifier && isIntersecting && isUnreadComment(target)) {
        acc.push(commentIdentifier)
        observer.unobserve(target)
      }

      return acc
    }, commentIdentifiers)

    markAsRead(commentIdentifiers)
  }, INTERSECT_OPTIONS)
}

const useMarkCommentsAsReadList = () => {
  const markCommentAsRead = useMarkCommentAsRead()
  const intersection = useRef<IntersectionObserver>(
    createIntersectionObserver(markCommentAsRead)
  )

  return useCallback((item: Element) => {
    if (isUnreadComment(item)) {
      intersection.current.observe(item)
    }
  }, [])
}

export default useMarkCommentsAsReadList
