import React from 'react'
import {
  Link,
  NotEmbedded,
  CenterContent,
  ErrorMessage,
} from '@sketch/components'
import styled from 'styled-components'
import { ReactComponent as Logo } from '@sketch/icons/logo'
import { IsSignedIn } from '../../user'

const StyledErrorMessage = styled(ErrorMessage)`
  flex: 1 0 auto;
  align-self: center;
`

export const InvalidInvitationView = () => (
  <IsSignedIn>
    {(isSignedIn: boolean) => (
      <NotEmbedded>
        <CenterContent>
          <StyledErrorMessage
            title="This invitation is no longer valid"
            icon={() => <Logo width="46px" height="46px" />}
          >
            <p>Please contact the owner of the document.</p>
            {isSignedIn ? (
              <Link to="/documents">Go to your documents overview</Link>
            ) : (
              <span>
                <Link to="/signin">Sign in to Sketch</Link> or{' '}
                <Link to="/signup">Create an account</Link>
              </span>
            )}
          </StyledErrorMessage>
        </CenterContent>
      </NotEmbedded>
    )}
  </IsSignedIn>
)
