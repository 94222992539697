import React from 'react'
import {
  ProjectGroupMembershipFragment,
  useRemovePermissionGroupToShareProjectMutation,
} from '@sketch/gql-types'
import { BaseListRow, FlatButton } from '@sketch/components'
import {
  PermissionGroupItem,
  deleteEntityFromCache,
} from '@sketch/modules-common'

interface PermissionGroupRowProps {
  membership: ProjectGroupMembershipFragment
}

const PermissionGroupRow = (props: PermissionGroupRowProps) => {
  const { membership } = props

  const [remove] = useRemovePermissionGroupToShareProjectMutation({
    onError: 'show-toast',
    update: cache => {
      deleteEntityFromCache(cache, {
        __typename: 'PermissionGroupRelationProject',
        identifier: membership.identifier,
      })
    },
    optimisticResponse: () => ({
      __typename: 'RootMutationType',
      removePermissionGroupRelation: {
        __typename: 'RemovePermissionGroupRelationResponse',
        permissionGroup: membership.permissionGroup,
        project: null,
        share: null,
      },
    }),
  })

  return (
    <BaseListRow
      name=""
      key={membership.identifier}
      showStripes
      actions={
        <FlatButton
          variant="negative"
          size="32"
          onClick={() =>
            remove({
              variables: {
                input: { relationIdentifier: membership.identifier },
              },
            })
          }
        >
          Remove
        </FlatButton>
      }
    >
      <PermissionGroupItem permissionGroup={membership.permissionGroup} />
    </BaseListRow>
  )
}

export default PermissionGroupRow
