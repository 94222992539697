import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.border.A};
  padding: 12px;

  font-size: 0.8125rem; /* 13px */
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`
