import React from 'react'
import { dataIdFromObject } from '@sketch/graphql-cache'

import { LoadPageSeparator, handleFetchMore } from '@sketch/components'

import VersionItem from '../VersionItem'
import ShareVersionsListWrapper from '../ShareVersionsListWrapper'
import PreventiveRevisionLink from '../PreventiveRevisionLink'
import { useVersioning } from '../../ShareVersionContext'
import VersionItemPlaceholder from '../VersionItemPlaceholder'
import InlineSidebarError from '../InlineSidebarError'
import { Star } from '../StarVersion'

import useGetArtboardRevisions from '../../hooks/useGetArtboardRevisions'

import {
  ArtboardRevisionDeletedFragment,
  ArtboardRevisionFragment,
  ArtboardRevisionUnavailableFragment,
} from '@sketch/gql-types'
import { versionedRoutes } from '@sketch/modules-common'

const ENTRIES_PATH = ['artboardRevisions', 'entries']
const PAGE_SIZE = 20

type Revision =
  | ArtboardRevisionFragment
  | ArtboardRevisionDeletedFragment
  | ArtboardRevisionUnavailableFragment

interface ArtboardRevisionsItemProps {
  revision: Revision
  position?: number
  artboardRevisionIdentifier: string
  shareIdentifier: string
  permanentArtboardShortId: string
  getPathname: ReturnType<typeof useVersioning>['getPathname']
}

const ArtboardRevisionsItem = (props: ArtboardRevisionsItemProps) => {
  const {
    revision,
    position,
    shareIdentifier,
    permanentArtboardShortId,
    artboardRevisionIdentifier,
  } = props

  const authorName = revision.creator.name ?? null

  if (
    revision.__typename === 'RevisionDeleted' ||
    revision.__typename === 'RevisionUnavailable'
  ) {
    return (
      <VersionItem
        order={position}
        authorName={authorName}
        creationDate={revision.createdAt}
        deleted={revision.__typename === 'RevisionDeleted'}
      />
    )
  }

  const to = revision.artboard?.documentVersionShortId
    ? versionedRoutes.ARTBOARD_DETAIL.VERSION.create({
        permanentArtboardShortId,
        shareID: shareIdentifier,
        versionShortId: revision.artboard.documentVersionShortId,
      })
    : undefined

  const isAuto = revision.version?.kind === 'AUTO'
  const revisionStarred = revision.kind === 'PUBLISHED'

  return (
    <PreventiveRevisionLink
      key={revision.identifier}
      isActive={match =>
        !!match || artboardRevisionIdentifier === revision.identifier
      }
      to={to}
    >
      <VersionItem
        key={revision.identifier}
        authorName={authorName}
        creationDate={revision.createdAt}
        order={position}
        description={revision.version?.description || undefined}
        isStarred={revisionStarred}
        star={revisionStarred && (() => <Star isStarred />)}
        isAuto={isAuto}
        renderStatus={
          /* We force the finished status because only exists finished revisions */
          'FINISHED'
        }
      />
    </PreventiveRevisionLink>
  )
}

interface ArtboardRevisionsProps {
  shareIdentifier: string
  permanentArtboardIdentifier: string
  permanentArtboardShortId: string
  artboardRevisionIdentifier: string
}

const ArtboardRevisions = (props: ArtboardRevisionsProps) => {
  const {
    shareIdentifier,
    permanentArtboardIdentifier,
    permanentArtboardShortId,
    artboardRevisionIdentifier,
  } = props

  const { getPathname } = useVersioning()
  const { loading, data, error, fetchMore } = useGetArtboardRevisions(
    shareIdentifier,
    permanentArtboardIdentifier
  )

  if (loading || error || !data) {
    return (
      <ShareVersionsListWrapper>
        <VersionItemPlaceholder count={5} />
        {error && <InlineSidebarError error={error} />}
      </ShareVersionsListWrapper>
    )
  }

  const { entries, meta } = data.artboardRevisions
  const revisions = [...(entries || [])].reverse()

  const handleLoadMore = handleFetchMore(fetchMore, ENTRIES_PATH, {
    dataIdFromObject,
    after: meta?.after,
  })

  return (
    <ShareVersionsListWrapper data-testid="artboard-revisions-list">
      <VersionItemPlaceholder
        count={Math.min(PAGE_SIZE, meta.totalCount - entries.length)}
      />

      {meta.after && (
        <LoadPageSeparator key={meta.after} loadNewPage={handleLoadMore} />
      )}

      {revisions.map(revision => (
        <ArtboardRevisionsItem
          key={revision.identifier}
          shareIdentifier={shareIdentifier}
          revision={revision}
          artboardRevisionIdentifier={artboardRevisionIdentifier}
          permanentArtboardShortId={permanentArtboardShortId}
          position={revision.order || undefined}
          getPathname={getPathname}
        />
      ))}
    </ShareVersionsListWrapper>
  )
}

export default ArtboardRevisions
