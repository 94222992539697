import React, { FC, ReactElement } from 'react'

import { ColumnProps } from './Grid.types'

import { Container } from './Column.styles'

type GridElementType = React.ReactElement & { type: { name: string } }

export const Column: FC<ColumnProps> = ({ children, asListItem, ...props }) => {
  const elements = React.Children.toArray(children)

  return (
    <Container {...props} as={asListItem ? 'li' : undefined}>
      {elements.map(element => {
        if (!element || !React.isValidElement(element)) return null

        // Inner <Grid /> components should have full height, in order to
        // maintain the aspect of the Column they are being rendered.
        if ((element as GridElementType)?.type.name === 'Grid')
          return React.cloneElement(element as ReactElement, {
            fullHeight: true,
          })

        return element
      })}
    </Container>
  )
}
