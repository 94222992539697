import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "spinner-20", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("radialGradient", { cx: "97.489%", cy: "28.549%", fx: "97.489%", fy: "28.549%", r: "168.335%", id: "spinner-20_svg__a" },
            React.createElement("stop", { stopColor: "currentColor", offset: "0%" }),
            React.createElement("stop", { stopColor: "currentColor", stopOpacity: 0.816, offset: "18.426%" }),
            React.createElement("stop", { stopColor: "currentColor", stopOpacity: 0, offset: "100%" }))),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M0 0h24v24H0z" }),
        React.createElement("path", { d: "M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11z", stroke: "url(#spinner-20_svg__a)", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round", strokeDasharray: 48.33 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
