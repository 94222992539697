import styled from 'styled-components'
import { CopyCSSButton } from './CopyCSSButton'

export const OverviewComponentName = styled.div`
  flex: 1;
  min-width: 0;
  padding-right: 12px;
`

export const OverviewCopyCSSButton = styled(CopyCSSButton)`
  flex: none;
`
