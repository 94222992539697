import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "profile-card-4-fill-wide", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "currentColor", fillRule: "evenodd" },
        React.createElement("path", { fillOpacity: 0.15, d: "M1.5 4h13v9.5h-13z" }),
        React.createElement("path", { d: "M9 2.5h4a3 3 0 013 3V12a3 3 0 01-3 3H3a3 3 0 01-3-3V5.5a3 3 0 013-3h4V4H3a1.5 1.5 0 00-1.493 1.356L1.5 5.5V12c0 .78.595 1.427 1.356 1.5H13c.78 0 1.42-.54 1.493-1.3l.007-.2V5.5a1.5 1.5 0 00-1.356-1.493L13 4H9V2.5z", fillRule: "nonzero" }),
        React.createElement("path", { d: "M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.5 2.164c0-.78-1.12-1.414-2.5-1.414s-2.5.633-2.5 1.414c0 .781 5 .781 5 0z" }),
        React.createElement("path", { d: "M8.25 1C9.216 1 10 1.784 10 2.75v1A1.75 1.75 0 018.25 5.5h-.5A1.75 1.75 0 016 3.75v-1C6 1.784 6.784 1 7.75 1h.5zm0 1.5h-.5a.25.25 0 00-.25.25v1c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-1a.25.25 0 00-.25-.25z", fillRule: "nonzero" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
