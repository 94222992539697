import styled from 'styled-components'
import { breakpoint } from '@sketch/global-styles'
import {
  IndexLayoutOldSubtitle,
  IndexLayoutOldTitle,
} from '@sketch/modules-common'

import { Title } from '../../../components/CampaignSidePanel/CampaignSidePanel.styles'

export const LeftColumn = styled.div`
  ${breakpoint('md')`
    ${Title} {
      max-width: 300px;
    }
  `}
`
export const RightColumn = styled.div`
  flex-shrink: 0;
  width: 100%;

  ${IndexLayoutOldTitle}, ${IndexLayoutOldSubtitle} {
    text-align: left;
  }
`

export const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 20px;

  ${breakpoint('md')`
    flex-direction: row;
    justify-content: center;
    gap: 80px;
    padding: 0 32px;
    max-width: 100%;

    ${LeftColumn}, ${RightColumn} {
      width: 100%;
      flex-shrink: 0;
    }

    ${LeftColumn} {
      max-width: 450px;
    }
      
    ${RightColumn} {
      max-width: 380px;
    }
  `}

  ${breakpoint('lg')`
    gap: 145px;
  `}
`
