import styled from 'styled-components'

import { Button, ButtonUnstyled, PillProps, Pill } from '@sketch/components'

import Panel from '../Panel'

import { breakpoint } from '@sketch/global-styles'

export const CanceledPill = styled(Pill).attrs<PillProps>({
  variant: 'primary',
})`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`

export const Strong = styled.strong`
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`

export const BlockText = styled.span`
  display: block;
`

export const HeaderButton = styled(Button)`
  transition: none;
`

export const LearnMoreText = styled.div`
  display: inline;
  color: hsla(0, 0%, 0%, 1);
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
  margin: 0;
`

export const StyledPanelTitle = styled(Panel.Title)`
  display: flex;

  ${breakpoint('base', 'xs')`
    flex-direction: column;
    align-items: flex-start;
  `}

  /* Hack to fix the Button presentation in IE11 */
  & ${ButtonUnstyled} {
    flex-shrink: 0;
    display: inline;
  }
`
