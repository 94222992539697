import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { routes } from '@sketch/modules-common'
import { SKETCH_WEBSITE } from '@sketch/env-config'

import { LandscapeBannerCard } from '@sketch/components'

import * as S from './UpgradePersonalWorkspacePanel.styles'

export interface UpgradePersonalWorkspacePanelProps {
  name: string
}

export const UpgradePersonalWorkspacePanel: React.FC<UpgradePersonalWorkspacePanelProps> = props => {
  const { name } = props
  const history = useHistory()

  const onUpgrade = useCallback(() => {
    history.push(
      routes.WORKSPACE_CREATE.create({
        query: { name, upgrade: 'true' },
      })
    )
  }, [name, history])

  return (
    <LandscapeBannerCard
      data-testid="upgrade-personal-workspace-banner-card"
      title="Subscribe to Get the Best of Sketch"
      description="You can easily upgrade your legacy Personal Workspace with a subscription, which includes the full suite of collaboration tools and access to the Mac app."
      icon={<S.StyledLicenseDiamondIcon />}
      action={
        <S.ActionContainer>
          <S.StyledButton onClick={onUpgrade} variant="primary">
            Subscribe to Sketch
          </S.StyledButton>
          <S.StyledLink
            external={true}
            isUnderlined={true}
            href={`${SKETCH_WEBSITE}/support/switch-to-a-subscription/`}
          >
            Why subscribing makes sense
          </S.StyledLink>
        </S.ActionContainer>
      }
    />
  )
}
