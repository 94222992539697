import { useEffect, useMemo, useRef, useState } from 'react'

const TRUNCATE_AT_LINE = 6

interface UseReadMoreProps {
  description?: string
  // How many lines we want to show before truncating the text
  maxLines?: number
}

export const useReadMore = ({
  description,
  maxLines = TRUNCATE_AT_LINE,
}: UseReadMoreProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const descriptionElement = useRef<HTMLPreElement>(null)
  const [showReadMore, setShowReadMore] = useState(false)

  // Calculate the number of line the description has on the DOM
  // and show / hide the "Read more" button accordingly
  useEffect(() => {
    if (!description) return

    if (descriptionElement.current) {
      // We use scrollHeight and clientHeight to calculate if the text needs to be clamped
      // if true, there is more text than the container can show and we show the "Read more" button
      const shouldClampText =
        descriptionElement.current.scrollHeight >
        descriptionElement.current.clientHeight

      setShowReadMore(shouldClampText)
    }
  }, [description, showReadMore])

  const lineClamp = isExpanded ? 'unset' : maxLines

  const truncateStyles = useMemo(
    () =>
      ({
        /* 
          Styles.display doesn’t consider -webkit-box to be a valid value, however,
          looking at the documentation this is a valid value for browsers and
          we need to override it.
        */
        display: '-webkit-box' as 'block',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: lineClamp,
        overflow: 'hidden',
      } as const),
    [lineClamp]
  )

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // Because we can navigate to a particular Version by clicking the description,
    // We have to preventDefault here, so that the Read More button doesn't
    // trigger the navigation
    event.preventDefault()

    setIsExpanded(prev => !prev)
  }

  return {
    isExpanded,
    truncateStyles,
    handleClick,
    descriptionElement,
    showReadMore,
  }
}
