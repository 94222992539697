import React from 'react'
import styled from 'styled-components'
import { useKey } from 'react-use'

import { PersonAvatar } from '@sketch/components'

import CommentDate from '../CommentDate'
import CommentAuthor from '../CommentAuthor'

import { useVersioning } from '../../../versioning'

import { Wrapper, CommentInput, HeaderWrapper } from './EditCommentInput.styles'
import { CommentUserFragment, useEditCommentMutation } from '@sketch/gql-types'

interface EditCommentInputProps {
  className?: string
  'data-first-comment'?: boolean
  identifier: string
  createdAt: string
  user: CommentUserFragment
  comment: string
  onCancel: () => void
}

const EditCommentInput = (props: EditCommentInputProps) => {
  const { identifier, user, createdAt, comment, onCancel, ...divProps } = props
  const { share } = useVersioning()

  const [updateComment] = useEditCommentMutation({
    onError: 'show-toast',
    optimisticResponse: ({ body, identifier }) => ({
      __typename: 'RootMutationType',
      editComment: {
        __typename: 'EditCommentResponse',
        comment: {
          __typename: 'Comment',
          body,
          identifier,
          isEdited: true,
        },
      },
    }),
  })

  // Close the edit-mode when the "escape" key has been touched
  useKey('Escape', () => {
    onCancel()
  })

  return (
    <Wrapper {...divProps}>
      <HeaderWrapper>
        <PersonAvatar
          flavour="image"
          size="16px"
          src={user.avatar!.small!}
          name={user.name!}
        />
        <CommentAuthor placement="bottom-end">{user.name}</CommentAuthor>
        <div>
          <CommentDate date={createdAt} />
        </div>
      </HeaderWrapper>
      <CommentInput
        shareIdentifier={share.identifier}
        onCommentSubmit={body => {
          updateComment({ variables: { body, identifier } })
          onCancel()
        }}
        onCancel={onCancel}
        comment={comment}
        isEditing
        autoFocus
      />
    </Wrapper>
  )
}

export default styled(EditCommentInput)``
