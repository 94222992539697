import React from 'react'
import { roundWithLocale } from '@sketch/utils'

import { copyColumns, copyRows } from './copy'
import {
  AttributeList,
  FullCopyAttribute,
  Header,
  HeaderTitle,
  Section,
  Separator,
  SubTitle,
} from '../../components'

import { LayoutSettings as LayoutSettingsType } from '../../../../../../inspector'

const renderColumns = ({
  totalWidth,
  horizontalOffset,
  numberOfColumns,
  guttersOutside,
  gutterWidth,
  columnWidth,
  drawVertical,
}: Partial<LayoutSettingsType>) => {
  const hasColumnInfo =
    totalWidth ||
    horizontalOffset ||
    numberOfColumns ||
    guttersOutside === false ||
    guttersOutside === true
  if (!hasColumnInfo) return null

  const sectionCopyValue = copyColumns({
    totalWidth,
    horizontalOffset,
    numberOfColumns: drawVertical ? numberOfColumns : undefined,
    guttersOutside: drawVertical ? guttersOutside : undefined,
    gutterWidth: drawVertical ? gutterWidth : undefined,
    columnWidth: drawVertical ? columnWidth : undefined,
  })

  return (
    <>
      <Separator />

      <AttributeList>
        {totalWidth !== undefined ? (
          <FullCopyAttribute
            display="wide"
            label="Total Width"
            copyValue={`${totalWidth}px`}
            value={`${roundWithLocale(totalWidth, 2)}px`}
          />
        ) : null}

        {horizontalOffset !== undefined ? (
          <FullCopyAttribute
            display="wide"
            label="Offset"
            copyValue={`${horizontalOffset}px`}
            value={`${roundWithLocale(horizontalOffset, 2)}px`}
          />
        ) : null}

        {drawVertical && (
          <>
            <Header copyValue={sectionCopyValue}>
              <SubTitle>Columns</SubTitle>
            </Header>
            {!isNaN(numberOfColumns as number) ? (
              <FullCopyAttribute
                display="wide"
                label="Number of Columns"
                value={String(numberOfColumns)}
              />
            ) : null}

            {guttersOutside === true || guttersOutside === false ? (
              <FullCopyAttribute
                display="wide"
                label="Gutter Outside"
                value={guttersOutside ? 'Yes' : 'No'}
              />
            ) : null}
          </>
        )}
      </AttributeList>
      {drawVertical && (gutterWidth || columnWidth) ? (
        <>
          <Separator />
          <AttributeList>
            {gutterWidth !== undefined ? (
              <FullCopyAttribute
                display="wide"
                label="Gutter Width"
                copyValue={`${gutterWidth}px`}
                value={`${roundWithLocale(gutterWidth, 2)}px`}
              />
            ) : null}
            {columnWidth !== undefined ? (
              <FullCopyAttribute
                display="wide"
                label="Column Width"
                copyValue={`${columnWidth}px`}
                value={`${roundWithLocale(columnWidth, 2)}px`}
              />
            ) : null}
          </AttributeList>
        </>
      ) : null}
    </>
  )
}

export const renderRows = ({
  gutterHeight,
  rowHeightMultiplication,
  drawHorizontal,
}: Partial<LayoutSettingsType>) => {
  if (!drawHorizontal) {
    return null
  }
  const hasRows = gutterHeight || rowHeightMultiplication
  const rowHeight =
    rowHeightMultiplication && gutterHeight
      ? rowHeightMultiplication * gutterHeight
      : undefined
  if (!hasRows) return null

  const sectionCopyValue = copyRows({ gutterHeight, rowHeightMultiplication })

  return (
    <>
      <Separator />
      <Header copyValue={sectionCopyValue}>
        <SubTitle>Rows</SubTitle>
      </Header>
      <AttributeList>
        {gutterHeight !== undefined ? (
          <FullCopyAttribute
            display="wide"
            label="Gutter Height"
            copyValue={`${gutterHeight}px`}
            value={`${roundWithLocale(gutterHeight, 2)}px`}
          />
        ) : null}
        {rowHeight !== undefined ? (
          <FullCopyAttribute
            display="wide"
            label="Row Height"
            copyValue={`${rowHeight}px`}
            value={`${roundWithLocale(rowHeight, 2)}px`}
          />
        ) : null}
      </AttributeList>
    </>
  )
}

export const LayoutSettings = ({
  layout,
}: {
  layout?: Partial<LayoutSettingsType>
}) => {
  if (!layout || layout.isEnabled === false) return null
  const columns = renderColumns(layout)
  const rows = layout.drawHorizontal ? renderRows(layout) : null
  if (!columns && !rows) return null

  const sectionCopyValue =
    copyColumns(layout) +
    `\n\n` +
    (layout.drawHorizontal ? copyRows(layout) : '')

  return (
    <>
      <Separator />
      <Section data-testid="inspector-sidebar-artboard-layout-settings">
        <Header copyValue={sectionCopyValue}>
          <HeaderTitle>Layout Settings</HeaderTitle>
        </Header>
        {columns}
        {rows}
      </Section>
    </>
  )
}
