import { MILLISECONDS_PER_DAY, MILLISECONDS_PER_HOUR } from './constants'

/** Inspired by https://stackoverflow.com/questions/542938/how-to-calculate-number-of-days-between-two-dates */

type UnitPrecision =
  /** Exact number of days with decimal part.
   * @example: 1 day and 8 hours would be 1.3333....
   */
  | 'precise'
  /**
   * Round to unit. Count rounded number of days without decimal part.
   * @example: 1 day and 8 hours would be 1 day. 1 day and 20 hours would be 2 days.
   */
  | 'rounded'
  /**
   * Round the unit down. Only count entire days. The day needs to be fully completed to count as 1.
   * This is the default.
   * @example 1 day and 23hours would be 1 day.
   */
  | 'full'
  /**
   * Round the unit up. Count started days. The day just needs to be started to count as 1
   * For example for the trial period, if you have 13h left on your trial we display it to the user as 1 day left.
   * @example 1 day and 1 hours would be 2 days.
   */
  | 'started'

type ToDaysOptions = {
  precision?: UnitPrecision
}

type ToHoursOptions = {
  precision?: UnitPrecision
}

/**
 * Contains information about a time duration and contains helper methods
 * to convert it to days, hours etc...
 */
export class ElapsedTime {
  elapsedTimeInMs: number
  constructor(elapsedTimeInMs: number) {
    this.elapsedTimeInMs = elapsedTimeInMs
  }

  /** Get the elapsed time in days */
  public toDays(options?: ToDaysOptions) {
    const precision: UnitPrecision = options?.precision ?? 'full'
    const diffDays = this.elapsedTimeInMs / MILLISECONDS_PER_DAY

    return this.withPrecision(diffDays, precision)
  }

  /** Get the elapsed time in hours */
  public toHours(options?: ToHoursOptions) {
    const precision: UnitPrecision = options?.precision ?? 'full'
    const diffHours = this.elapsedTimeInMs / MILLISECONDS_PER_HOUR

    return this.withPrecision(diffHours, precision)
  }

  /**
   * Transform the value to follow the provided precision.
   */
  private withPrecision(value: number, precision: UnitPrecision) {
    if (precision === 'full') {
      return Math.floor(value)
    }

    if (precision === 'started') {
      return Math.ceil(value)
    }

    if (precision === 'rounded') {
      return Math.round(value)
    }

    // Make sure value can not be negative
    return Math.max(value, 0)
  }
}

/**
 * Get the elapsed time between two dates. The function returns an instance of
 * ElapsedTime so you can convert the time in days, hours, etc...
 */
export function getElapsedTimeBetweenDate(startDate: Date, endDate: Date) {
  assertValidDate(startDate, 'startDate')
  assertValidDate(endDate, 'endDate')

  if (endDate.getTime() < startDate.getTime()) {
    throw new Error(
      `getElapsedTimeBetweenDate: endDate can not be earlier than startDate.`
    )
  }

  const timeDiffInMsBetweenDate = endDate.getTime() - startDate.getTime()

  return new ElapsedTime(timeDiffInMsBetweenDate)
}

function assertValidDate(date: Date, type: 'startDate' | 'endDate') {
  if (isNaN(date.getTime())) {
    throw new Error(
      `getElapsedTimeBetweenDate: Provided "${type}" argument is an invalid Date.`
    )
  }
}
