import { Link, Navbar } from '@sketch/components'
import React from 'react'

import { useScrollTop } from '@sketch/utils'
import { SKETCH_WEBSITE } from '@sketch/env-config'

import { Header, LogoWrapper, Logo } from './IndexLayout.styles'

export interface IndexLayoutHeaderProps {
  disableLogoLink?: boolean
}

export const IndexLayoutHeader: React.FC<IndexLayoutHeaderProps> = ({
  disableLogoLink,
  children,
}) => {
  const { isScrollTop } = useScrollTop()

  return (
    <Header data-scrolltop={isScrollTop}>
      <LogoWrapper data-testid="sketch-logo">
        {disableLogoLink ? (
          <Logo />
        ) : (
          <Link href={`${SKETCH_WEBSITE}/home`} external>
            <Logo />
          </Link>
        )}
      </LogoWrapper>
      <Navbar.Section align="start">{children}</Navbar.Section>
    </Header>
  )
}
