import React, { useContext } from 'react'
import { useKey } from 'react-use'

import { SafeSpaceProps } from '../Box'
import { ModalContext } from '../ModalManager'

import { shortcuts, keyWithoutModifier } from '@sketch/utils'

import {
  Wrapper,
  Button,
  Pages,
  Divider,
  ChevronLeft,
  ChevronRight,
} from './Pagination.styles'

export interface PaginationProps extends SafeSpaceProps {
  // if current and total are not provided then minimal variant gets true
  current?: number
  total?: number
  onClickNext?: () => void
  onClickPrev?: () => void
  /* Triggers the minimal variant */
  minimal?: boolean
  /* Triggers the dark variant */
  dark?: boolean
}

/**
 * Component to replace PaginationControls, in most cases you could just
 * replace PaginationControls for Pagination, keeping the same props.
 */
export const Pagination = ({
  current,
  total,
  onClickNext,
  onClickPrev,
  minimal,
  dark,
  ...rest
}: PaginationProps) => {
  const { isModalOpen } = useContext(ModalContext)

  const showNumbers = Number.isInteger(current) && Number.isInteger(total)
  const parsedTotal = total !== undefined && total > 99 ? '99+' : total
  const hidePagination = showNumbers && total === 1
  const minimalUI = minimal || dark || (!current && !total)

  // Disable pagination when a modal is opened, and disable prev/next for
  // first/last page
  const disablePrev =
    (showNumbers && (current! <= 1 || isModalOpen)) || !onClickPrev
  const disableNext =
    (showNumbers && (current! === total! || isModalOpen)) || !onClickNext

  // Keyboard shortcuts to go to next/prev page
  useKey(
    keyWithoutModifier(shortcuts.paginationPrevious),
    () => !disablePrev && onClickPrev()
  )
  useKey(
    keyWithoutModifier(shortcuts.paginationNext),
    () => !disableNext && onClickNext()
  )

  return hidePagination ? null : (
    <Wrapper {...rest} dark={dark}>
      <Button
        onClick={onClickPrev}
        data-testid="pagination-prev"
        disabled={disablePrev}
      >
        <ChevronLeft />
      </Button>
      {minimalUI ? (
        <Divider />
      ) : (
        <Pages>
          {current} of {parsedTotal}
        </Pages>
      )}
      <Button
        onClick={onClickNext}
        data-testid="pagination-next"
        disabled={disableNext}
      >
        <ChevronRight />
      </Button>
    </Wrapper>
  )
}
