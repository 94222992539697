import {
  useLeaveWorkspaceMutation,
  LeaveWorkspaceMutationVariables,
} from '@sketch/gql-types'
import {
  removeFromUserMemberships,
  removeUserFromWorkspace,
} from '../../../../../common/workspaceMembershipsCache'

type Options = NonNullable<Parameters<typeof useLeaveWorkspaceMutation>[0]>
type OptionsStrict = OmitSafe<Options, 'variables' | 'onError'> & {
  variables: LeaveWorkspaceMutationVariables
  onError: (message: string) => void
}

/**
 * useLeaveWorkspace
 *
 * This is mostly the same as useRemoveWorkspaceMember but we use
 * leaveWorkspaceMutation which does not query for the billing field.
 *
 * We don't need the billing field when leaving a workspace and this solves
 * a permissions error due to Guests not being able to query billing field
 *
 */
export const useLeaveWorkspace = (options: OptionsStrict) => {
  const {
    update: updateBase,
    onError: onErrorBase,
    variables,
    ...rest
  } = options

  const onError: Options['onError'] = ({ message }) => {
    onErrorBase?.(message)
  }

  const update: typeof updateBase = (cache, mutationResult) => {
    const { data } = mutationResult
    if (!data?.removeWorkspaceMember) {
      return
    }

    const { workspace } = data.removeWorkspaceMember

    const { membershipId } = variables

    removeFromUserMemberships(cache, membershipId)
    removeUserFromWorkspace(cache, membershipId, workspace!.identifier)

    updateBase && updateBase(cache, mutationResult)
  }

  return useLeaveWorkspaceMutation({
    update,
    onError,
    variables,
    ...rest,
  })
}
