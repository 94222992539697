export const isProductionBuild = () => process.env.NODE_ENV === 'production'

// Utility to check if the current frontend built is running on staging environment (intended to be released on dev.sketch.cloud)
export const isDeployedInDev = () =>
  process.env.REACT_APP_ENV === 'dev' && isProductionBuild()

// Utility to check if the current frontend built is running on staging environment (intended to be released on test.sketch.cloud)
export const isDeployedInTest = () =>
  process.env.REACT_APP_ENV === 'test' && isProductionBuild()

// Utility to check if the current frontend built is running on staging environment (intended to be released on staging.sketch.cloud)
export const isDeployedInStaging = () =>
  process.env.REACT_APP_ENV === 'staging' && isProductionBuild()

// Utility to check if the current frontend built is production ready (intended to be released on www.sketch.cloud)
export const isDeployedInProduction = () =>
  process.env.REACT_APP_ENV === 'production' && isProductionBuild()
