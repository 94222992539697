import styled from 'styled-components'

import { ReactComponent as QuestionMarkIconUnstyled } from '@sketch/icons/question-mark-16'

export const DiscountWrapper = styled.div`
  margin-bottom: 8px;
`

export const QuestionMarkIcon = styled(QuestionMarkIconUnstyled)`
  width: 16px;
  height: 16px;
  margin-top: 4px;
  margin-left: 4px;
  cursor: pointer;
`

export const CurrentPlanPill = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  padding: 0 8px;
  bottom: -10px;
  border-radius: ${({ theme }) => theme.radii.rounded};
  background-color: ${({ theme }) => theme.colors.sketch.A};
  color: ${({ theme }) => theme.colors.foreground.primary.A};
  font-size: ${({ theme }) => theme.fontSizes.B};
`

export const TooltipText = styled.p`
  margin: 0 0 8px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.foreground.primary.B};

  :last-child {
    margin: 0;
  }

  b {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.foreground.primary.A};
  }
`
