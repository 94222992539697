import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "information-16", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("path", { d: "M7.755 1h.49c1.55 0 2.708.278 3.686.801a5.452 5.452 0 012.268 2.268c.523.978.801 2.136.801 3.686v.49c0 1.55-.278 2.708-.801 3.686a5.452 5.452 0 01-2.268 2.268c-.978.523-2.136.801-3.686.801h-.49c-1.55 0-2.708-.278-3.686-.801a5.452 5.452 0 01-2.268-2.268C1.278 10.953 1 9.795 1 8.245v-.49c0-1.55.278-2.708.801-3.686A5.452 5.452 0 014.07 1.801C5.047 1.278 6.205 1 7.755 1z", id: "information-16_svg__a" })),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("mask", { id: "information-16_svg__b", fill: "#fff" },
            React.createElement("use", { xlinkHref: "#information-16_svg__a" })),
        React.createElement("use", { fillOpacity: 0.15, fill: "currentColor", fillRule: "nonzero", xlinkHref: "#information-16_svg__a" }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, strokeLinecap: "round", mask: "url(#information-16_svg__b)", d: "M8 11V7.25H7m-.5 4h3" }),
        React.createElement("circle", { fill: "currentColor", mask: "url(#information-16_svg__b)", cx: 8, cy: 4, r: 1 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
