import styled from 'styled-components'
import { Text } from '@sketch/components'

export const Stops = styled.div`
  padding: 18px; /* stylelint-disable-line scales/space */
  width: 300px;
`

export const TextNoWrap = styled(Text)`
  overflow-wrap: inherit;
`

export const StopContainer = styled.div`
  align-items: center;
  display: flex;
  padding-bottom: 5px; /* stylelint-disable-line scales/space */
  max-width: calc(100% - 40px);
  width: 100%;
`

export const StopPercentage = styled.div`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  font-size: 0.8125rem;
  margin-right: 8px;
  text-align: right;
  width: 34px;
  min-width: 34px;
`

export const Alpha = styled.div`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  font-size: 0.8125rem;
  margin-left: 4px;
  margin-right: 8px;
`
