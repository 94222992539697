import {
  useRemoveWorkspaceMemberMutation,
  RemoveWorkspaceMemberMutationVariables,
} from '@sketch/gql-types'
import { removeFromUserMemberships, removeUserFromWorkspace } from './userCache'

type Options = NonNullable<
  Parameters<typeof useRemoveWorkspaceMemberMutation>[0]
>
type OptionsStrict = OmitSafe<Options, 'variables' | 'onError'> & {
  variables: RemoveWorkspaceMemberMutationVariables
  onError: (message: string) => void
}

export const useRemoveWorkspaceMember = (options: OptionsStrict) => {
  const {
    update: updateBase,
    onError: onErrorBase,
    variables,
    ...rest
  } = options

  const onError: Options['onError'] = ({ message }) => {
    onErrorBase?.(message)
  }

  const update: typeof updateBase = (cache, mutationResult) => {
    const { data } = mutationResult
    if (!data?.removeWorkspaceMember) {
      return
    }

    const { workspace } = data.removeWorkspaceMember

    const { membershipId } = variables

    removeFromUserMemberships(cache, membershipId)
    removeUserFromWorkspace(cache, membershipId, workspace!.identifier)

    updateBase && updateBase(cache, mutationResult)
  }

  return useRemoveWorkspaceMemberMutation({
    update,
    onError,
    variables,
    ...rest,
  })
}
