import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "coffee-cup-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M7.5 9l.865 8.65A1.5 1.5 0 009.857 19h4.286a1.5 1.5 0 001.492-1.35L16.5 9h0", stroke: "currentColor", strokeWidth: 1.5, fillOpacity: 0.15, fill: "currentColor" }),
        React.createElement("path", { d: "M6.75 8.5h10.5a.25.25 0 100-.5H6.75a.25.25 0 100 .5z", stroke: "currentColor", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M7.5 9l.69-2.412A1.5 1.5 0 019.63 5.5h4.738a1.5 1.5 0 011.442 1.088L16.5 9h0", stroke: "currentColor", strokeWidth: 1.5, fillOpacity: 0.15, fill: "currentColor" }),
        React.createElement("path", { d: "M7.065 11.5h9.87a.5.5 0 01.496.56l-.427 3.5a.5.5 0 01-.497.44H7.493a.5.5 0 01-.497-.44l-.427-3.5a.5.5 0 01.496-.56z", fill: "currentColor" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
