import styled from 'styled-components'
import { Avatar } from '@sketch/components'

export const PresenceList = styled.ul`
  margin: 0;
  padding: 0;
`

export const PresenceListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:first-child {
    flex-shrink: 0;
  }
`
export const PresenceListItemAvatar = styled(Avatar)`
  flex-shrink: 0;
`
