import { useState } from 'react'
import styled from 'styled-components'
import copy from 'copy-to-clipboard'
import { CopyToClipboard } from '@sketch/components'

export const CopiableArea = styled.div`
  cursor: pointer;
`

/** Used for copying to clipboard
 * + handling the showing/hiding of the tooltip with the message `Copied` */
export function useHandleCopyValue(
  value: string,
  defaultTooltip: string = 'Copy to clipboard'
): {
  handleCopyValue: () => void
  handleEnterCopiableArea: () => void
  handleLeaveCopiableArea: () => void
  copyTooltipText: string
  copyTooltipVisible: boolean
} {
  const [copyTooltipText, setCopyTooltipText] = useState(defaultTooltip)
  const [copyTooltipVisible, setCopyTooltipVisible] = useState(false)

  const handleEnterCopiableArea = () => setCopyTooltipVisible(true)
  const handleLeaveCopiableArea = () => setCopyTooltipVisible(false)

  const handleCopyValue = () => {
    copy(value)
    setCopyTooltipText('Copied')
    setTimeout(() => {
      setCopyTooltipVisible(false)
      setCopyTooltipText(defaultTooltip)
    }, CopyToClipboard.animationDuration)
  }

  return {
    handleCopyValue,
    handleEnterCopiableArea,
    handleLeaveCopiableArea,
    copyTooltipText,
    copyTooltipVisible,
  }
}
