import React from 'react'
import styled from 'styled-components'

import { ButtonUnstyled } from '../Button'

import { ReactComponent as EyeIcon } from '@sketch/icons/eye-24'
import { ReactComponent as EyeStrikeIcon } from '@sketch/icons/eye-strikethrough-24'

interface TogglePasswordButtonProps {
  showPassword?: boolean
}

const TogglePasswordButton = styled(ButtonUnstyled).attrs({
  type: 'button',
})<TogglePasswordButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 48px;

  svg {
    opacity: 0.55;
  }

  ${({ showPassword, theme }) =>
    showPassword &&
    `
    background-color: ${theme.colors.sketch.F};
    border-radius: 6px;
  `}
`

const StrikeEyeIcon = styled(EyeStrikeIcon)`
  color: ${({ theme }) => theme.colors.sketch.A};
`

interface ToggleShowPasswordButtonProps {
  onClick: () => void
  showPassword: boolean
  disabled?: boolean
}

export const ToggleShowPasswordButton: React.FC<ToggleShowPasswordButtonProps> = ({
  onClick,
  showPassword,
  disabled = false,
}: ToggleShowPasswordButtonProps) => {
  return (
    <TogglePasswordButton
      data-testid="eye-icon"
      onClick={onClick}
      disabled={disabled}
      showPassword={showPassword}
    >
      {showPassword ? (
        <StrikeEyeIcon
          width={24}
          height={24}
          focusable={false}
          aria-hidden={true}
        />
      ) : (
        <EyeIcon width={24} height={24} focusable={false} aria-hidden={true} />
      )}
    </TogglePasswordButton>
  )
}
