import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { stringify } from 'query-string'

import { QueryParams, useQueryParams } from '@sketch/modules-common'
import { usePrototypeContext } from './usePrototypeContext'

export const usePrototypeComments = () => {
  const { comments, ...otherQuery } = useQueryParams<'PROTOTYPE_PLAYER'>()
  const { isFullscreen } = usePrototypeContext()
  const history = useHistory()

  const commentsEnabled = (comments || '') === 'true'

  const setCommentsState = useCallback(
    (commentsEnabled: boolean) => {
      const queryArguments = (commentsEnabled
        ? { comments: 'true', ...otherQuery }
        : otherQuery) as QueryParams<'PROTOTYPE_PLAYER'>

      history.replace({
        search: stringify(queryArguments),
      })
    },
    [history, otherQuery]
  )

  return [commentsEnabled && !isFullscreen, setCommentsState] as const
}
