import { useRef, useCallback, useLayoutEffect } from 'react'

export type HandlerFn<Args extends any[], R> = (...args: Args) => R | void

/**
 * WARNING: will throw if the callback is invoked during the render phase.
 * Should only be used for call sites that are guaranteed to run after the
 * render, e.g.
 *  - event handlers
 *  - in `useEffect` hook
 */
const throwInRenderPhase = () => {
  const errorMessage = 'Cannot call an event handler while rendering.'

  if (
    process.env.REACT_APP_ENV === 'dev' ||
    process.env.REACT_APP_ENV === 'test'
  ) {
    throw new Error(errorMessage)
  } else {
    // eslint-disable-next-line no-console
    console.warn(errorMessage)
  }
}

/**
 *  __useStableHandler__
 *
 * This hooks takes a callback as an argument, and returns a *stable function*
 * that calls the *latest version of the callback* passed to it (meaning that
 * the function returned will never be re-assigned during a component's lifetime
 * and, as a result, will not cause re-renders in child components).
 *
 * NOTE:
 * - `useStableHandler` is meant to be used for *event handlers*, or for callbacks
 *   where we typically only care about the latest version of the function.
 * - Will throw an error if called during render. Invoking in `useEffect` is fine though.
 * - `useStableHandler` should work like the upcoming `useEvent` react hook (see RFC),
 *    with one caveat: we're using `useLayoutEffect`, so although this hook is
 *    "good enough" for most cases, the timing is a little off. To quote the RFC:
 *      > The "current" version of the handler is switched before all the layout effects run.
 *      This avoids the pitfall in the userland versions where **one component's effect can
 *      observe the previous version of another component's state**.
 *
 * SEE:
 * - `useEvent` RFC: https://github.com/reactjs/rfcs/pull/220
 *
 * @param handler - callback argument
 * @returns stable function that calls latest version of the callback
 */
export function useStableHandler<A extends any[], R>(
  handler: HandlerFn<A, R>
): HandlerFn<A, R> {
  const handlerRef = useRef<HandlerFn<A, R>>(throwInRenderPhase)

  handlerRef.current = throwInRenderPhase

  useLayoutEffect(() => {
    handlerRef.current = handler
  })

  return useCallback((...args: A) => handlerRef.current(...args), [])
}
