import styled from 'styled-components'
import { ReactComponent as ChatBubbleAddIcon } from '@sketch/icons/chat-bubble-add-16'

export const Wrapper = styled.div`
  width: ${({ theme }) => theme.sidebar.canvasFirstRightWidth}px;
`

export const ChatAddIcon = styled(ChatBubbleAddIcon)`
  width: 16px;
  height: 16px;
`
