import React, { useMemo } from 'react'

import { Hero, convertSize } from '@sketch/discover'
import { dataIdFromObject } from '@sketch/graphql-cache'
import { useTrackEvent } from '@sketch/modules-common'

import {
  GenericSectionTitle,
  GenericSectionTitleSkeleton,
  Grid,
  handleFetchMore,
} from '@sketch/components'

import {
  CommunityItemFragment,
  CommunityPublicationQueryFragment,
  PublicationItemFragment,
  QueryResult,
} from '@sketch/gql-types'

import PublicationList, { PublicationListPlaceholder } from '../PublicationList'
import PublicationGrid, { PublicationGridPlaceholder } from '../PublicationGrid'
import PublicationSlider, {
  PublicationSliderPlaceholder,
} from '../PublicationSlider'

import { useGetCommunityItemPublications } from './CommunityHomeItems.hooks'

const STANDARD_PAGE_LIMIT = 20
const ENTRIES_PATH = ['publications', 'entries']

interface GridItemProps {
  analyticsId: string
  items: PublicationItemFragment[]
  fetchMore?: QueryResult['fetchMore']
  after?: string
  totalCountItems: number
}

/**
 * GridItem
 *
 * Is responsible for rendering the PublicationGrid with load more functionality
 */
const GridItem = (props: GridItemProps) => {
  const { analyticsId, fetchMore, items, after, totalCountItems } = props

  const { trackEvent } = useTrackEvent()

  const handleLoadMore = useMemo(() => {
    trackEvent('COMMUNITY - Load More Items', {
      type: 'click',
    })

    return handleFetchMore(fetchMore, ENTRIES_PATH, {
      dataIdFromObject,
      after,
    })
  }, [trackEvent, fetchMore, after])

  const placeholderCount = fetchMore
    ? Math.max(Math.min(totalCountItems - items.length, 20), 0)
    : 0

  return (
    <PublicationGrid
      analyticsId={analyticsId}
      items={items}
      placeholderCount={placeholderCount}
      onLoadMore={handleLoadMore}
    />
  )
}

type ItemsWithQueriesProps = {
  item: CommunityPublicationQueryFragment
}

const COMPONENTS_BY_TYPE = {
  CommunityListing: PublicationList,
  CommunitySlider: PublicationSlider,
  CommunityGrid: GridItem,
}

const LOADING_COMPONENTS_BY_TYPE = {
  CommunityListing: PublicationListPlaceholder,
  CommunityGrid: PublicationGridPlaceholder,
  CommunitySlider: PublicationSliderPlaceholder,
}

/**
 * ItemsWithQueries
 *
 * Takes care of performing the network requests and
 * rendering the correct component and it's loading state
 */
const ItemsWithQueries = (props: ItemsWithQueriesProps) => {
  const { item } = props
  const { __typename, title, subTitle, link, preferences } = item

  const { data, fetchMore, loading } = useGetCommunityItemPublications({
    preferences,
  })

  if (loading) {
    const LoadingComponent = LOADING_COMPONENTS_BY_TYPE[__typename]
    return (
      <>
        <GenericSectionTitleSkeleton />
        <LoadingComponent />
      </>
    )
  }

  const items = data?.publications.entries || []
  const totalCountItems = data?.publications.meta.totalCount || 0
  const after = data?.publications.meta.after || 0

  const Component = COMPONENTS_BY_TYPE[__typename]

  return (
    <>
      <GenericSectionTitle
        title={title}
        subTitle={subTitle || undefined}
        titleLink={
          link && totalCountItems > (preferences?.limit || STANDARD_PAGE_LIMIT)
            ? { text: link.text || undefined, url: link.url }
            : undefined
        }
      />

      <Component
        analyticsId={item.id}
        items={items}
        /**
         * This props are needed to make pagination work for "CommunityGridItem"
         **/
        fetchMore={preferences?.allowPagination ? fetchMore : undefined}
        after={after || undefined}
        totalCountItems={totalCountItems}
      />
    </>
  )
}

export interface CommunityHomeItemsProps {
  item: CommunityItemFragment
}

/**
 * CommunityHomeItems
 *
 * Responsible for rendering the content of Sections for the Community page
 */
const CommunityHomeItems: React.FC<CommunityHomeItemsProps> = ({ item }) => {
  if (item.__typename === 'CommunityHero') {
    return (
      <Grid.Column key={item.id} {...convertSize(item.size)}>
        <Hero {...item} context="COMMUNITY" />
      </Grid.Column>
    )
  }
  return (
    <Grid.Column key={item.id}>
      <ItemsWithQueries item={item} />
    </Grid.Column>
  )
}

export default CommunityHomeItems
