import React from 'react'
import { Text } from '../Box'
import { Button, Cover, Wrapper } from './SegmentControl.styles'

interface SegmentControlProps<T> {
  className?: string
  segments: T[]
  activeSegment: T
  renderSegmentText: (segment: T) => string
  onSegmentClick?: (clickedSegment: T) => void
}

/**
 * SegmentControl
 *
 * This component mimics the UI SegmentedControl from the iOS.
 * It allows items to be rendered as options on a exposed "select"
 * where only one option is selected at the time
 *
 * When a segment is clicked the "onSegmentClick" callback is called to notify the change
 *
 * This component is stateless therefore the parent is responsible for setting the
 * "activeSegment" and to handle the set of newer segments
 *
 * Design Reference
 * https://www.sketch.com/s/fca5a886-9b59-4a97-bf75-c559cb682d20/a/j47Qw09
 */
export function SegmentControl<T>(props: SegmentControlProps<T>) {
  const {
    className,
    segments,
    activeSegment,
    onSegmentClick,
    renderSegmentText,
  } = props

  const numberOfItems = segments.length
  const indexOfActive = segments.findIndex(value => value === activeSegment)

  return (
    <Wrapper className={className}>
      {indexOfActive > -1 && (
        <Cover
          style={{
            transform: `translate3d(${100 * indexOfActive}%, 0, 0)`,
            width: `calc(${100 / numberOfItems}% - 3px)`,
          }}
        />
      )}

      {segments.map((segment, index) => {
        const activeSegment = index === indexOfActive

        /**
         * In order to make the segment selector animated
         * it was implemented a work-around that would allow both
         * the slider and the text to be animated switching
         * from black to white (not-active to active) as the Cover moves
         *
         * The "backgroundPositionX" are associated with a gradient with 3 colors
         * black    | white     | black
         * 0% - 33% | 33% - 66% | 66% - 100%
         *
         * The values -50%, -100% and -150% are associated with the positions of each color
         * given that the gradient is repeatable, the values are negative to match execute
         * the animation on the same direction the cover moves. So the animation looks right
         *
         * The gradient contains the color black twice (first and last position) because of the 2 different types of animation
         * possible. If you imagine a list of 3 items when moving from the active from the center to the right the
         * animation is the opposite of center to left, replicating basically a right motion event and a left motion event
         * respectively
         */
        let backgroundPositionX = '-50%'

        if (activeSegment) {
          backgroundPositionX = '-100%'
        } else if (indexOfActive > index) {
          backgroundPositionX = '-150%'
        }

        return (
          <Text
            key={index}
            textStyle="copy.tertiary.standard.D"
            as={Button}
            aria-selected={activeSegment}
            style={{ backgroundPositionX }}
            onClick={() => {
              onSegmentClick?.(segment)
            }}
          >
            {renderSegmentText(segment)}
          </Text>
        )
      })}
    </Wrapper>
  )
}
