import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "textformat-uppercase-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { stroke: "currentColor", strokeWidth: 1.5, fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M8.75 12l2.72-7.535a.563.563 0 011.06 0L15.25 12h0", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M9.75 9.75h4.75" }),
        React.createElement("path", { d: "M.75 12l2.72-7.535a.562.562 0 011.06 0L7.25 12h0", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M1.75 9.75H6.5" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
