import React, { useState, useCallback } from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import { useToast } from '@sketch/toasts'

import {
  Button,
  Form,
  Input,
  Modal,
  VerificationCodeInput,
  ModalInjectedProps,
} from '@sketch/components'

import {
  Title,
  Description,
  Note,
  StyledLink,
  StyledFakeLinkButton,
} from './MfaAuthenticationModal.styles'

import {
  GetUserSettingsDocument,
  useValidateMfaRecoveryCodeMutation,
  useValidateMfaTotpMutation,
} from '@sketch/gql-types'
import { useSetUserAuthorization } from '@sketch/modules-common'

interface MFAError {
  code: string
  message: string
  type: 'wrong_mfa_verification_code' | 'invalid_mfa_token'
}

interface MfaAuthenticationModalProps extends ModalInjectedProps {
  mfaToken: string
  onSuccess?: () => void
}

type Values = typeof INITIAL_VALUES

const INITIAL_VALUES = {
  recoveryCode: '',
}

const VALIDATION_SCHEMA = Yup.object().shape({
  recoveryCode: Yup.string().trim().required('Enter a recovery code'),
})

/**
 * MfaAuthenticationModal
 *
 * Allows the user to enter the verification code or recovery code
 * when siging in to a particular Workspace
 *
 * Used in Account Settings > Workspace list when signed out from the selected Workspace
 *
 */
const MfaAuthenticationModal: React.FC<MfaAuthenticationModalProps> = ({
  hideModal,
  mfaToken,
  onSuccess,
}) => {
  const { showToast } = useToast()
  const [verficationError, setVerificationError] = useState<string>()
  const [showRecoveryCodeInput, setShowRecoveryCodeInput] = useState(false)

  const setUserAuthorization = useSetUserAuthorization()

  // Verification Code Validation
  const [validateMfaTotp] = useValidateMfaTotpMutation({
    onCompleted: data => {
      setUserAuthorization(data.validateMfaTotp.credentials)
      hideModal()
      onSuccess?.()
    },
    onError: error => {
      // TODO: Improve returned error types from local resolvers
      // https://github.com/sketch-hq/Cloud/issues/11366
      const mfaError = (error.message as unknown) as MFAError

      setVerificationError(mfaError.message)
    },
    refetchQueries: [
      {
        query: GetUserSettingsDocument,
      },
    ],
  })

  const handleVerificationCodeFilled = useCallback(
    (code: string) => {
      // Clear error
      setVerificationError(undefined)

      // Code from the Authenticator App
      validateMfaTotp({
        variables: {
          token: mfaToken,
          totp: code,
        },
      })
    },
    [mfaToken, validateMfaTotp]
  )

  // Recovery Code Validation
  const [
    validateRecoveryCode,
    { loading },
  ] = useValidateMfaRecoveryCodeMutation({
    onCompleted: data => {
      setUserAuthorization(data.validateMfaRecoveryCode.credentials)
      hideModal()
      onSuccess?.()
    },
    onError: error => {
      // TODO: Improve returned error types from local resolvers
      // https://github.com/sketch-hq/Cloud/issues/11366
      const mfaError = (error.message as unknown) as MFAError

      showToast(mfaError.message, 'negative')
    },
    refetchQueries: [
      {
        query: GetUserSettingsDocument,
      },
    ],
  })

  const handleSubmitRecoveryCode = useCallback(
    (values: Values, actions: FormikHelpers<Values>) => {
      validateRecoveryCode({
        variables: {
          token: mfaToken,
          recoveryCode: values.recoveryCode,
        },
      })

      actions.setSubmitting(false)
    },
    [mfaToken, validateRecoveryCode]
  )

  const handleEnterVerificationCode = () => {
    setVerificationError(undefined)
    setShowRecoveryCodeInput(false)
  }

  const handleUseRecoveryCode = () => {
    setVerificationError(undefined)
    setShowRecoveryCodeInput(true)
  }

  const title = showRecoveryCodeInput
    ? 'Enter Recovery Code'
    : 'Enter Verification Code'
  const description = showRecoveryCodeInput
    ? 'Can’t access your authenticator app? Enter a recovery code instead.'
    : 'Open your authenticator app and enter the code before it expires — or wait for a new one.'

  const note = showRecoveryCodeInput ? (
    <p>
      Authenticator app working again?{' '}
      <StyledFakeLinkButton
        variant="tertiary"
        onClick={handleEnterVerificationCode}
        isUnderlined
      >
        Enter verification code
      </StyledFakeLinkButton>
      <br />
      Lost your recovery codes?{' '}
      <StyledLink
        external
        variant="tertiary"
        isUnderlined
        href={`${SKETCH_WEBSITE}/support/contact/`}
      >
        Contact us
      </StyledLink>
    </p>
  ) : (
    <p>
      Can’t access your authenticator app?{' '}
      <StyledFakeLinkButton
        variant="tertiary"
        onClick={handleUseRecoveryCode}
        isUnderlined
      >
        Use a recovery code
      </StyledFakeLinkButton>
      <br />
      Lost your recovery codes?{' '}
      <StyledLink
        external
        variant="tertiary"
        isUnderlined
        href={`${SKETCH_WEBSITE}/support/contact/`}
      >
        Contact us
      </StyledLink>
    </p>
  )

  return (
    <Modal onCancel={hideModal}>
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmitRecoveryCode}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({ values, handleChange, errors, touched, isSubmitting }) => (
          <Form>
            <Modal.Body>
              <Title>{title}</Title>
              <Description>{description}</Description>
              {showRecoveryCodeInput ? (
                <Form.Field
                  name="text"
                  label="Recovery Code"
                  errorText={
                    touched.recoveryCode ? errors.recoveryCode : undefined
                  }
                >
                  <Input
                    name="recoveryCode"
                    type="text"
                    value={values.recoveryCode}
                    onChange={handleChange}
                  />
                </Form.Field>
              ) : (
                <VerificationCodeInput
                  onFilled={handleVerificationCodeFilled}
                  error={verficationError}
                />
              )}
              <Note>{note}</Note>
            </Modal.Body>
            {showRecoveryCodeInput && (
              <Modal.Footer>
                <Button onClick={hideModal} size="40">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  loading={isSubmitting || loading}
                  size="40"
                >
                  Submit
                </Button>
              </Modal.Footer>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default MfaAuthenticationModal
