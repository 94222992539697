import React from 'react'
import styled from 'styled-components'
import { ReactComponent as LockIcon } from '@sketch/icons/lock-16'
import { RowIcon } from './ProjectLogo.styles'

const RestrictedProjectLogoBase = ({ className }: { className?: string }) => (
  <RowIcon className={className}>
    <LockIcon />
  </RowIcon>
)

export const ProjectLogo = styled(RestrictedProjectLogoBase)``
