import React, { FC, forwardRef } from 'react'

import { Flex, FlexDivProps } from '../Box'
import { Spinner } from './Spinner'

export interface SpinnerFlexProps {
  primary?: boolean
  size?: string
}

export const SpinnerFlex: FC<SpinnerFlexProps & FlexDivProps> = forwardRef(
  ({ primary, size, ...props }, forwardedRef) => (
    <Flex
      ref={forwardedRef}
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <Spinner primary={primary} size={size} />
    </Flex>
  )
)

SpinnerFlex.displayName = 'SpinnerFlex'
