import React from 'react'

import { MenuIconButton } from '@sketch/components'
import { ReactComponent as ChatBubbles } from '@sketch/icons/chat-bubbles-24'
import { usePrototypeComments } from '../../hooks'
import { useHasNewNotifications } from '../../../annotations/hooks'

export const AnnotationButton = () => {
  const [commentsEnabled, setCommentsEnabled] = usePrototypeComments()
  const hasNewNotifications = useHasNewNotifications()

  return (
    <MenuIconButton
      active={commentsEnabled}
      description="Comment"
      onClick={() => {
        setCommentsEnabled(!commentsEnabled)
      }}
      $isHighlighted={hasNewNotifications}
    >
      <ChatBubbles role="img" />
      <span className="sr-only">Comments</span>
    </MenuIconButton>
  )
}
