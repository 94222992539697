import styled from 'styled-components'
import { Navbar } from '@sketch/components'

import { ReactComponent as DividerSvg } from '@sketch/icons/dots-4-vertical-divider-32'

export const Item = styled.div`
  display: flex;
  margin: 0 8px;
`

export const ItemEnd = styled(Item)`
  gap: 1px;
  margin-left: auto;
`

export const FooterNavbar = styled(Navbar).attrs({
  alignItems: 'space-between',
})`
  border-top: 1px solid ${({ theme }) => theme.colors.border.A};
`

export const Divider = styled(DividerSvg)`
  width: 2px;
  height: 31px; /* stylelint-disable-line scales/space */
  margin-right: 4px;
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.background.tertiary.D};
`
