import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "document-upload-48", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 48 48", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M24 8v9a3 3 0 003 3h9L24 8z", fill: "#BBD975" }),
        React.createElement("path", { d: "M37 38V22a2 2 0 00-2-2h-8a3 3 0 01-3-3V9a2 2 0 00-2-2h-8a3 3 0 00-3 3v31h23a3 3 0 003-3z", stroke: "currentColor", strokeWidth: 2 }),
        React.createElement("path", { d: "M37 26.5v-4.843a4 4 0 00-1.172-2.829L25.172 8.172A4 4 0 0022.343 7H18.5h0", stroke: "currentColor", strokeWidth: 2 }),
        React.createElement("g", { transform: "translate(3 25)", stroke: "currentColor", strokeWidth: 2 },
            React.createElement("circle", { fill: "#BBD975", cx: 8, cy: 8, r: 8 }),
            React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M8 11V6.5m-2.5 1L8 5l2.5 2.5" }))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
