import React from 'react'
import SelectableLargeDocumentTable, {
  SelectableLargeDocumentTableProps,
} from '../SelectableLargeDocumentTable'
import { LoadingPlaceholder, Text } from '@sketch/components'

import { LoadingWrapper } from './SelectDocuments.styles'
import { SelectedItemsProps } from '../types'

interface SelectDocumentsProps {
  shares: SelectableLargeDocumentTableProps['shares']
  selectedItems: SelectedItemsProps[]
  onChange: (identifier: string) => void
  isSelectAll?: boolean
  onSelectAll: () => void
  loading: boolean
  workspaceName: string
}

const SelectDocuments: React.FC<SelectDocumentsProps> = ({
  onChange,
  shares,
  selectedItems,
  onSelectAll,
  isSelectAll,
  loading,
  workspaceName,
}) => {
  if (loading) {
    return (
      <LoadingWrapper>
        <LoadingPlaceholder size="64px" />
      </LoadingWrapper>
    )
  }

  const description = !shares.length ? (
    <Text textStyle="copy.tertiary.standard.E">
      You can delete documents and versions you no longer need to free up
      storage.
    </Text>
  ) : (
    <Text textStyle="copy.tertiary.standard.E">
      You can delete documents and versions you no longer need to free up
      storage. Here are some of the largest documents in the{' '}
      <strong>“{workspaceName}”</strong> Workspace, excluding any drafts.
    </Text>
  )

  return (
    <>
      <Text textStyle="header.primary.G">Review Large Documents</Text>
      {description}
      <SelectableLargeDocumentTable
        shares={shares}
        selectedItems={selectedItems}
        onChange={onChange}
        isSelectAll={isSelectAll}
        onSelectAll={onSelectAll}
      />
    </>
  )
}

export default SelectDocuments
