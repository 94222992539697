import React, { RefObject, useEffect, useRef } from 'react'
import { Transition } from 'react-transition-group'

import { Button } from '@sketch/components'

import { useAnnotationOverlayContext } from '../../hooks'
import { Text, Icon, Dropdown, Wrapper } from './DraftAnnotationNotifier.styles'

const STATUS_TO_CLASSNAMES = {
  unmounted: '',
  exited: 'dropdown-exit-active',
  entering: 'dropdown-enter',
  entered: 'dropdown-enter-active',
  exiting: 'dropdown-exit',
}

interface MessageProps {
  onCancelClick: () => void
}

const Message = (props: MessageProps) => (
  <>
    <Icon />
    <Text>Click anywhere to comment</Text>
    <Button variant="primary" size="24" onClick={props.onCancelClick}>
      Cancel
    </Button>
  </>
)

interface DraftAnnotationNotifierProps {
  canvasRef?: RefObject<HTMLElement>
}

const DraftAnnotationNotifier = (props: DraftAnnotationNotifierProps) => {
  const annotationContext = useAnnotationOverlayContext()
  const notifierRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    /**
     * Check if the "placeDraftAnnotation" is enabled
     * meaning the draft annotation can be placed
     */
    if (!annotationContext || !annotationContext.placeDraftAnnotation) {
      return
    }

    /**
     * Close the draft annotation mode when the user clicks any navbar item
     *
     * Decision taken here
     * https://sketch.slack.com/archives/C06D1D3JLBC/p1709036045092119
     */
    const handleClickNavbarItem = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      const navbarTarget = document.querySelector(
        '[data-testid=panel-triggers-header]'
      )

      if (navbarTarget?.contains(target)) {
        annotationContext.setPlaceDraftAnnotation(false)
      }
    }

    window.addEventListener('click', handleClickNavbarItem)

    return () => {
      window.removeEventListener('click', handleClickNavbarItem)
    }
  }, [annotationContext])

  if (!annotationContext) {
    return null
  }

  const { placeDraftAnnotation, setPlaceDraftAnnotation } = annotationContext

  return (
    <Transition
      timeout={200}
      mountOnEnter
      unmountOnExit
      in={placeDraftAnnotation}
      classNames="dropdown"
    >
      {status => (
        <Wrapper ref={notifierRef}>
          <Dropdown
            className={STATUS_TO_CLASSNAMES[status]}
            data-testid="dropdown-options"
          >
            <Message onCancelClick={() => setPlaceDraftAnnotation?.(false)} />
          </Dropdown>
        </Wrapper>
      )}
    </Transition>
  )
}

export default DraftAnnotationNotifier
