import { produce } from 'immer'
import { DataProxy } from 'apollo-cache'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

import { broadcastChanges } from '@sketch/modules-common'

export interface HandleEmptiedWorkspaceTrashProps {
  cache: DataProxy
  identifier: string
}

export const handleEmptiedWorkspaceTrash = (
  props: HandleEmptiedWorkspaceTrashProps
) => {
  const { cache, identifier } = props

  const exposedCache = cache as any

  if (!('extract' in cache && 'restore' in cache)) {
    return
  }

  const currentCache = exposedCache.extract()

  const newCache = produce(
    currentCache,
    (normalizedCache: NormalizedCacheObject) => {
      const keys = Object.keys(normalizedCache)

      keys.forEach(key => {
        if (
          !(
            key.includes(`$ROOT_QUERY.workspaceTrash`) &&
            key.includes(identifier)
          )
        ) {
          return
        }

        const cacheEntry = normalizedCache[key]!

        if (key?.includes('.meta')) {
          Object.assign(cacheEntry, {
            after: null,
            totalCount: 0,
          })
        } else if (cacheEntry?.entries) {
          cacheEntry.entries = []
        }
      })
    }
  )

  exposedCache.restore(newCache)
  broadcastChanges(exposedCache)
}
