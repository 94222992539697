import React from 'react'
import { Link } from 'react-router-dom'
import { Location } from 'history'

import { Tooltip } from '@sketch/components'

import { useDotCoordinateStyle } from '../../hooks'
import { Coordinates, ReferenceBounds } from '../../types'

import {
  Header,
  TextWrapper,
  AnnotationDot,
} from './LegacyAnnotationDot.styles'

interface LegacyAnnotationDotProps {
  count: number
  prefixBounds?: Coordinates
  referenceBounds: ReferenceBounds
  coordinates: Coordinates
  disabled?: boolean
}

const setCommentActiveTab = (location: Location) => ({
  ...location,
  hash: 'Comment',
})

const LegacyAnnotationDot = (props: LegacyAnnotationDotProps) => {
  const { count, coordinates, prefixBounds, referenceBounds, disabled } = props

  const style = useDotCoordinateStyle({
    coordinates,
    referenceBounds,
    prefixBounds,
  })

  return (
    <Link to={setCommentActiveTab} style={style} replace>
      <Tooltip
        spacing="0px"
        disabled={disabled}
        content={
          <TextWrapper>
            <Header>Older comments now appear on the Canvas</Header>
            <span>Select a comment from the sidebar to move it around</span>
          </TextWrapper>
        }
      >
        <AnnotationDot
          data-testid="legacy-annotation-dot"
          aria-disabled={disabled}
        >
          {count}
        </AnnotationDot>
      </Tooltip>
    </Link>
  )
}

export default LegacyAnnotationDot
