import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "trash-empty-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M6.5 7.5h11l-.834 10.005c-.092 1.102-1.062 1.995-2.163 1.995H9.497c-1.103 0-2.072-.902-2.163-1.995L6.5 7.5z", stroke: "currentColor", strokeWidth: 1.5, fillOpacity: 0.15, fill: "currentColor" }),
        React.createElement("path", { d: "M4.5 7.5c0-.414.341-.75.744-.75h13.512c.411 0 .744.333.744.75 0 .414-.341.75-.744.75H5.244A.745.745 0 014.5 7.5z", fill: "currentColor" }),
        React.createElement("path", { d: "M9.5 5.499a.997.997 0 011-.999h3c.552 0 1 .443 1 .999V7.5h-5V5.499z", stroke: "currentColor", strokeWidth: 1.5 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
