import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "information-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("circle", { fillOpacity: 0.15, fill: "currentColor", cx: 12, cy: 12, r: 9 }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, strokeLinecap: "round", d: "M12 16v-5.75h-1.25m-.75 6h4" }),
        React.createElement("circle", { fill: "currentColor", cx: 12, cy: 7, r: 1.25 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
