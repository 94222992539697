import React from 'react'
import { Link as RouterLink, LinkProps } from 'react-router-dom'

import { Link } from '../Link'
import { Button, BaseButtonProps } from './Button'
import { ButtonType } from './types'

interface BaseLinkButtonProps extends BaseButtonProps {
  children: React.ReactNode
  onClick?: () => void
  type?: ButtonType
}

type InternalLinkButtonProps = BaseLinkButtonProps &
  LinkProps & { external?: false }

type ExternalLinkButtonProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  BaseLinkButtonProps & {
    external: true
    to?: never
  }

export type LinkButtonProps = InternalLinkButtonProps | ExternalLinkButtonProps

export const LinkButton = ({
  children,
  external,
  ...props
}: LinkButtonProps) => {
  if (external) {
    return (
      <Button
        as={linkProps => (
          // We need the "variant" to reach Link so we pass it here
          <Link {...linkProps} variant={props.variant} />
        )}
        external
        {...props}
      >
        {children}
      </Button>
    )
  }

  return (
    <Button {...props} as={RouterLink}>
      {children}
    </Button>
  )
}
