import { DataProxy } from 'apollo-cache'

const errorPreventiveFragmentRead = <Query = any, Variables = any>(
  cache: DataProxy,
  readOptions: DataProxy.Fragment<Variables>
) => {
  try {
    return cache.readFragment<Query, Variables>(readOptions)
  } catch (e) {
    return null
  }
}

export default errorPreventiveFragmentRead
