import React from 'react'
import {
  DropdownButton,
  NotificationButton,
} from './NotificationStatusButton.styles'

// This is a valid use case to narrow down Component type
// eslint-disable-next-line no-restricted-imports
import { NotificationSubscriptionStatus } from '@sketch/gql-types/expansive'

import { ReactComponent as BellIcon } from '@sketch/icons/bell-16'
import { ReactComponent as BellStrikeIcon } from '@sketch/icons/bell-strikethrough-16'

const labels: { [key in NotificationSubscriptionStatus]: string } = {
  ON: 'Following',
  OFF: 'Follow',
  PARTIAL: 'Following…',
} as const

const icons: { [key in NotificationSubscriptionStatus]: React.ElementType } = {
  ON: BellIcon,
  OFF: BellStrikeIcon,
  PARTIAL: BellIcon,
} as const

const buttonVariant: { [key in NotificationSubscriptionStatus]: string } = {
  ON: 'secondary',
  OFF: 'secondary-untinted',
  PARTIAL: 'secondary',
} as const

interface NotificationStatusButtonProps
  extends React.ComponentPropsWithoutRef<'button'> {
  'data-testid'?: string
  notificationStatus: NotificationSubscriptionStatus
  variant?: 'button' | 'dropdown'
}

const NotificationStatusButton = React.forwardRef<
  HTMLElement,
  NotificationStatusButtonProps
>(function NotificationStatusButton(props, ref) {
  const { notificationStatus, variant, ...buttonProps } = props

  const Icon = icons[notificationStatus]
  const label = labels[notificationStatus]

  if (variant === 'dropdown') {
    return (
      <DropdownButton
        ref={ref as React.RefObject<HTMLLIElement>}
        role="checkbox"
        aria-checked={notificationStatus !== 'OFF'}
        {...buttonProps}
      >
        <Icon role="img" />
        {label}
      </DropdownButton>
    )
  }

  return (
    <NotificationButton
      ref={ref}
      variant={buttonVariant[notificationStatus]}
      icon={Icon}
      size="32"
      {...buttonProps}
    >
      <span className="sr-only">{label}</span>
    </NotificationButton>
  )
})
export default NotificationStatusButton
