import React, { FC, useState, useContext, useEffect } from 'react'
import { ApolloQueryResult } from 'apollo-client'
import { useRouteMatch, useLocation } from 'react-router-dom'

import { useGetIdentifiers } from '../../hooks/useGetIdentifiers'
import {
  useGetComponentsStateLazyQuery,
  GetComponentsQuery,
  GetComponentsCountQuery,
} from '@sketch/gql-types'
import { useEventDispatch, useOnEvent } from '@sketch/utils'
import { isCwvRouteOnly, isInspectorRoute } from '@sketch/modules-common'

// This is a valid use case to validate document.componentsState
// eslint-disable-next-line no-restricted-imports
import { DocumentComponentsState } from '@sketch/gql-types/expansive'

declare module '@sketch/utils' {
  export interface EventsMap {
    componentsAreReady?: string
  }
}

export type DocumentComponentsStateExported =
  | DocumentComponentsState
  | undefined

interface ComponentsStateBase {
  isProcessing: boolean
  componentsState: DocumentComponentsStateExported
  documentIdentifier?: string
  refetchComponentsState: () => void
}

interface ComponentsStateLoading extends ComponentsStateBase {
  loading: true
}

interface ComponentsStateReady extends ComponentsStateBase {
  loading: false
}

type ComponentsState = ComponentsStateLoading | ComponentsStateReady

export const ComponentsStateContext = React.createContext<ComponentsState>({
  loading: false,
  isProcessing: false,
  componentsState: undefined,
  documentIdentifier: undefined,
  refetchComponentsState: () => {},
})

export const ComponentsStateProvider: FC = ({ children }) => {
  const { versionShortId, shareIdentifier } = useGetIdentifiers()

  const { path } = useRouteMatch()
  const location = useLocation()

  const isComponentsPage = isCwvRouteOnly(path)
  const isInspectorEnabled = isInspectorRoute(path, location.hash)

  // We use a state to inform about the progress of the async process (instead
  // of the `loading` from `processComponents` mutation) because the whole
  // process requires several steps:
  // - `document > components > status` field queried (this triggers the ingestion)
  // - `versionUpdated` subscription is completed
  // - `refetch` is called to get the new `componentsState`
  const [isProcessing, setIsProcessing] = useState(false)

  const [
    queryComponentsStatus,
    { data, refetch, loading },
  ] = useGetComponentsStateLazyQuery({
    variables: {
      versionShortId,
      shareIdentifier,
    },
    fetchPolicy: 'network-only',
  })

  // Only query for components status when visiting a Components page or when
  // using the Inspector
  useEffect(() => {
    if (!versionShortId || !shareIdentifier) return

    if (isComponentsPage || isInspectorEnabled) {
      queryComponentsStatus()
    }
  }, [
    isComponentsPage,
    isInspectorEnabled,
    queryComponentsStatus,
    versionShortId,
    shareIdentifier,
  ])

  const document = data?.share?.version?.document
  const documentIdentifier = document?.identifier
  const componentsState = document?.components?.status

  // If componentsState is not PROCESSED, the BE will automatically trigger
  // the ingestion when we query for document > components > status field, so
  // the BE returns PROCESSING in this case, and we should update the UI as
  // "Generating..."
  const isGeneratingComponents = componentsState === 'PROCESSING'

  if (isGeneratingComponents && !isProcessing) {
    setIsProcessing(true)
  }

  const refetchComponentsState = async () => {
    await refetch()
    setIsProcessing(false)
  }

  const value: ComponentsState = {
    loading: loading,
    isProcessing,
    componentsState,
    documentIdentifier,
    refetchComponentsState,
  }

  // When a new version is updated, we refetch the components status to keep it
  // up to date
  useOnEvent(
    'versionIsUpdated',
    ({
      documentIdentifier: documentIdentifierFromSubscription,
      hasComponentManifest,
    }) => {
      if (
        hasComponentManifest &&
        documentIdentifier === documentIdentifierFromSubscription
      ) {
        refetchComponentsState()
      }
    }
  )

  return (
    <ComponentsStateContext.Provider value={value}>
      {children}
    </ComponentsStateContext.Provider>
  )
}

export const useComponentsState = () => {
  const contextValue = useContext(ComponentsStateContext)

  if (!contextValue) {
    throw new Error(
      'useComponentsState must be used within a ComponentsStateProvider'
    )
  }

  return contextValue.componentsState
}

export const useComponentsTrigger = (
  refetch?: () =>
    | Promise<ApolloQueryResult<GetComponentsQuery>>
    | Promise<ApolloQueryResult<GetComponentsCountQuery>>
) => {
  const componentsState = useComponentsState()

  const { documentIdentifier } = useContext(ComponentsStateContext)

  // Setup event to be triggered when the components are ready, this is needed
  // when the trigger and the reaction to the completion are done in different places
  const onComponentsProcessed = useEventDispatch('componentsAreReady')

  // This event can be used to check when the ingestion is done.
  // Once is complete, it refetches if the `refetch` function is provided,
  // anyway it sends the event 'componentsAreReady' and refetches the componentsState
  useOnEvent(
    'versionIsUpdated',
    ({
      documentIdentifier: documentIdentifierFromSubscription,
      hasComponentManifest,
    }) => {
      if (
        hasComponentManifest &&
        documentIdentifier === documentIdentifierFromSubscription
      ) {
        refetch?.()
        onComponentsProcessed(documentIdentifier)
      }
    }
  )

  return componentsState
}

interface AsyncIngestionProps {
  versionShortId: string
  shareIdentifier: string
  refetch: () => void
  componentsState: DocumentComponentsStateExported
  loadingComponents: boolean
}

/**
 * We use this hook when we open the Export Design Tokens modal
 * We want to check if the components are ready to be exported or not,
 * and trigger the ingestion if they are not ready yet.

* And then trigger a refetch so the modal can show the tokens.
 */
export const useTriggerAsyncComponentsIngestionForExportTokens = ({
  versionShortId,
  shareIdentifier,
  refetch,
  loadingComponents,
  componentsState,
}: AsyncIngestionProps) => {
  const [
    queryComponentsStatus,
    { data: stateData, loading: stateLoading },
  ] = useGetComponentsStateLazyQuery({
    variables: {
      versionShortId,
      shareIdentifier,
    },
    fetchPolicy: 'network-only',
  })

  const documentIdentifier = stateData?.share?.version?.document?.identifier

  useEffect(() => {
    if (componentsState === undefined && !loadingComponents && !stateData) {
      queryComponentsStatus()
    }
  }, [componentsState, loadingComponents, stateData, queryComponentsStatus])

  // Setup event to be triggered when the components are ready, this is needed
  // when the trigger and the reaction to the completion are done in different places
  const onComponentsProcessed = useEventDispatch('componentsAreReady')

  // This event can be used to check when the ingestion is done.
  // Once is complete, it refetches if the `refetch` function is provided,
  // anyway it sends the event 'componentsAreReady' and refetches the componentsState
  useOnEvent(
    'versionIsUpdated',
    ({
      documentIdentifier: documentIdentifierFromSubscription,
      hasComponentManifest,
    }) => {
      if (
        hasComponentManifest &&
        documentIdentifier === documentIdentifierFromSubscription
      ) {
        refetch?.()
        onComponentsProcessed(documentIdentifier)
      }
    }
  )

  return {
    stateLoading,
    isProcessing:
      stateData?.share?.version?.document?.components.status === 'PROCESSING',
  }
}
