import styled, { css } from 'styled-components'
import { Light } from 'react-syntax-highlighter'
import foundation from 'react-syntax-highlighter/dist/cjs/styles/hljs/foundation'
import { breakpoint } from '@sketch/global-styles'
import {
  Modal,
  Checkbox,
  Separator,
  Dropdown,
  Text,
  Button,
  ButtonBaseStyle,
  ButtonPrimaryStyle,
} from '@sketch/components'
import {
  DropdownTarget,
  DropdownContentContainer,
} from '../../../workspace/containers/WorkspacesDropdown/WorkspacesDropdown.styles'
import { ReactComponent as QuestionMarkIcon } from '@sketch/icons/question-mark-16'
import { ReactComponent as ColorFanIcon } from '@sketch/icons/color-fan-64'

export const ExportContainerSeparator = styled(Separator)`
  position: absolute;
  top: 16px;
  left: -16px;
  right: -16px;
  border-top: none;
`

export const ExportContainer = styled.div<{
  $isDarkMode: boolean
}>`
  min-height: 112px;
  padding-top: 16px;
  position: relative;

  ${({ $isDarkMode }) =>
    !$isDarkMode &&
    css`
      &::before {
        content: '';
        /* 
        To make the gradient full width and override the container padding
          - +16px with (8px for left and right parent padding) 
          - -8px left
        */
        width: calc(100% + 16px);
        left: -8px;

        height: 20px;
        position: absolute;
        top: 0;
        background: radial-gradient(
          farthest-corner,
          hsl(0deg 0% 0% / 5%) 0%,
          hsl(0deg 0% 100% / 0%) 80%
        );
        background-repeat: no-repeat;
        background-size: 100% 40px;
      }
    `}
`

export const ExplanationText = styled(Text).attrs({
  textStyle: 'copy.quaternary.standard.C',
})`
  margin: 16px 0;
`

export const ExportButton = styled(Button)<{ $narrowSidebar?: boolean }>(
  ({ $narrowSidebar }) => css`
    justify-content: flex-start;
    padding: 6px ${$narrowSidebar ? 4 : 8}px; /* stylelint-disable-line scales/space */
    margin-bottom: 8px;
  `
)

export const SyntaxHighlighter = styled(Light)<{
  language: string
}>(
  ({ theme }) => css`
    height: 100%;
    border-radius: ${theme.radii.base};
    overflow-y: auto;
    width: 100%;
    padding: 12px 16px;
    margin-bottom: -20px;
    max-height: 500px;

    /* very specific breakpoint so because of the breakline of the intro text */
    @media (min-width: 904px) {
      max-height: 480px;
    }
  `
)

export const syntaxHighlighterThemeLight = {
  ...foundation,
  hljs: {
    ...foundation.hljs,
    padding: '12px 16px',
    background: 'hsl(0, 0%, 98%)',
  },
}

export const syntaxHighlighterThemeDark = {
  ...syntaxHighlighterThemeLight,
  hljs: {
    ...syntaxHighlighterThemeLight.hljs,
    background: 'hsla(0, 0%, 0%, 0.85)',
    color: 'hsl(0, 0%, 98%)',
  },
}

export const ModalStyled = styled(Modal)`
  max-width: 960px;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const SyntaxPanel = styled.div`
  min-width: 443px;
  margin-top: -32px;
  margin-bottom: -46px; /* stylelint-disable-line scales/space */
  margin-right: -32px;

  background: ${({ theme }) => theme.colors.syntaxHighlighting.background};

  pre {
    code {
      white-space: break-spaces !important; /* stylelint-disable-line */
    }
  }
`

export const SyntaxPanelContent = styled.div(
  ({ theme }) => css`
    height: 100%;
    border-radius: ${theme.radii.base};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `
)

export const CheckboxStyled = styled(Checkbox)`
  padding: 4px 0;
`

export const SeparatorStyled = styled(Separator)`
  margin: 24px 0;
`

export const DropdownWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  padding-bottom: 12px;
  width: 100%;

  div {
    width: 100%;
  }

  ${breakpoint('xs')`
    flex-basis: auto;
    margin-right: 16px;
    padding-bottom: 0;
    width: calc(100% - 110px);
  `}
`

export const DropdownWrapperTwoColumns = styled(DropdownWrapper)`
  ${breakpoint('xs')`
    flex-basis: auto;
    margin-right: 16px;
    padding-bottom: 0;
    width: 100%;
  `}
`

const ToggleBase = css`
  height: 40px;
  border-radius: ${({ theme }) => theme.radii.large};
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  background: ${({ theme }) => theme.colors.background.secondary.B};
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  padding: 0;
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.colors.shadow.inner} inset;

  &:disabled,
  &[aria-disabled='true'] {
    color: ${({ theme }) => theme.colors.state.disabled};
    border: 1px dashed ${({ theme }) => theme.colors.border.A};
    cursor: not-allowed;
    box-shadow: none;
    text-align: left;
  }
`

export const ToggleButton = styled(DropdownTarget).attrs({
  'data-testid': 'export-tokens-share-toggle-button',
})`
  ${ToggleBase}
  width: 100%;

  ${breakpoint('xs')`
    width: 100%;
  `}
`

export const DropdownContent = styled(DropdownContentContainer)`
  width: 100%;
  ${breakpoint('xs')`
    width: 342px;
  `}
  padding: 0;
`

export const DropdownContentTwoColumns = styled(DropdownContent)`
  ${breakpoint('xs')`
    width: 222px;
  `}
`

export const DropdownItem = styled(Dropdown.Item)<{ active?: boolean }>(
  ({ theme, active }) => css`
    background-color: ${active ? theme.colors.sketch.F : undefined};
    color: ${active ? theme.colors.sketch.A : undefined};
  `
)

export const ButtonText = styled.span`
  margin-left: 8px;
`

export const ToggleButtonText = styled(ButtonText)`
  line-height: 1.4;
`

export const ExportSettingsTitle = styled(Text).attrs({
  as: 'span',
  textStyle: 'copy.tertiary.standard.E',
})`
  margin-bottom: 8px;
`

export const ModalTitle = styled(Text).attrs({
  as: 'h1',
  textStyle: 'header.primary.G',
})`
  margin-top: 0;
  margin-bottom: 4px;
`

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 28px;
  flex-wrap: wrap;
`

export const ExportSetting = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding-bottom: 12px;
  padding-right: 0;

  ${breakpoint('xs')`
    flex-basis: auto;
    flex: 0.5;
    padding-bottom: 0;
    padding-right: 8px;
    min-width: 230px;
  `};
`

export const ColorSetting = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 0;

  ${breakpoint('xs')`
    flex: 0.5;
  `};
`

export const StyledQuestionMarkIcon = styled(QuestionMarkIcon)`
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  height: 16px;
  width: 16px;
  margin-left: 4px;
  margin-bottom: -3px; /* stylelint-disable-line scales/space */
`

export const StyledColorFanIcon = styled(ColorFanIcon)`
  color: ${({ theme }) => theme.colors.state.disabled};
  height: 64px;
  width: 64px;
  margin-bottom: 16px;
`

export const DownloadLink = styled.a(
  ({ theme }) => css`
    ${ButtonBaseStyle};
    ${ButtonPrimaryStyle};
    font-size: ${theme.fontSizes.D};
    padding: 10px 12px; /* stylelint-disable-line scales/space */
    min-height: 38px;
  `
)
