import { useEmptyWorkspaceTrashMutation } from '@sketch/gql-types'
import { handleEmptiedWorkspaceTrash } from '../handlers'

export interface UseEmptyWorkspaceTrashProps {
  onCompleted: () => void
  onError: (message: string) => void
  identifier: string
}

export const useEmptyWorkspaceTrash = (props: UseEmptyWorkspaceTrashProps) => {
  const { onCompleted, onError, identifier } = props
  return useEmptyWorkspaceTrashMutation({
    variables: { identifier },
    onCompleted,
    update: cache => {
      handleEmptiedWorkspaceTrash({ cache, identifier })
    },
    onError: ({ message }) => {
      onError(message)
    },
  })
}
