import { LocalResolver, LocalResolvers } from '@sketch/gql-types/resolvers'
import type {
  DiscoverSectionFragment,
  GetDiscoverResourcesQueryVariables,
} from '@sketch/gql-types'

import { getNetworkRequestHeaders } from '@sketch/modules-common'
import { visitItems } from './utils'

const RESOURCE_CONTENT_FILE_BY_TYPE: Record<
  GetDiscoverResourcesQueryVariables['type'],
  string
> = {
  EMPTY_STATE_COLLECTION: '/data/discover-resource-collection-data.json',
  EMPTY_STATE_LIBRARIES: '/data/discover-resource-libraries-data.json',
  EMPTY_STATE_MY_DRAFTS: '/data/discover-resource-drafts-data.json',
  EMPTY_STATE_PROJECT: '/data/discover-resource-project-data.json',
  EMPTY_STATE_SHARED_WITH_ME: '/data/discover-resource-share-with-me-data.json',
  EMPTY_STATE_TEMPLATES: '/data/discover-resource-template-data.json',
  EMPTY_STATE_COMPONENTS: '/data/discover-resource-components-empty-data.json',
  EMPTY_STATE_PROTOTYPES: '/data/discover-resource-prototypes-empty-data.json',
}

const headers = {
  ...getNetworkRequestHeaders(),
  'Content-Type': 'application/json',
}

const sections: LocalResolver<'Discover'>['sections'] = async () => {
  const response = await fetch('/data/discover.json', {
    headers,
  })

  if (response.status === 200) {
    const responseJson = await response.json()

    return visitItems(responseJson.sections) as DiscoverSectionFragment[]
  }

  throw new Error('Something went wrong')
}

const contentHash: LocalResolver<'Discover'>['contentHash'] = async () => {
  const response = await fetch('/data/discover-hash.json', {
    headers,
  })

  if (response.status === 200) {
    const responseJson = await response.json()

    return responseJson.hash
  }

  throw new Error('Something went wrong')
}

const discoverResources: LocalResolver<'Query'>['discoverResources'] = async (
  _,
  { type }
) => {
  const response = await fetch(RESOURCE_CONTENT_FILE_BY_TYPE[type], { headers })

  if (response.status === 200) {
    const responseJson = await response.json()
    return visitItems([responseJson])[0] as DiscoverSectionFragment
  }

  throw new Error('Something went wrong')
}

/**
 * createLocalResolvers
 *
 * This method will enhance the apollo resolvers (passed as argument)
 * with local discovery related queries that are fetched from local json files
 * available on "@sketch/build-app/public/data"
 *
 * The graphql schema definition for this resolver is available on "@sketch/gql-types/graphql/discover/local-schema.gql"
 */
export const createLocalResolvers = (resolvers: LocalResolvers) => {
  const enhancedResolvers = { ...resolvers }

  enhancedResolvers['Discover'] = {
    sections,
    contentHash,
  }

  /** These are @client data. This avoids the BE request */
  const discover = () => ({
    __typename: 'Discover' as const,
    sections: [],
    contentHash: 'some-hash',
  })

  enhancedResolvers.Query = {
    ...enhancedResolvers.Query,

    discover,
    discoverResources,
  }

  return enhancedResolvers as LocalResolvers
}
