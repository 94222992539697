import { onError } from 'apollo-link-error'
import { Operation } from 'apollo-link'
import { log } from '@sketch/utils'

import { debouncedLogoutActiveSession } from '../utils'
import ApolloClient from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import type { History } from 'history'
import { ToastActions } from '@sketch/toasts'
import { AnalyticsContext } from '@sketch/modules-common'

const FORCE_LOGOUT_REASONS = [
  'ACCESS_TOKEN_EXPIRED',
  'USER_NOT_LOGGED_IN',
  'INVALID_ACCESS_TOKEN',
  'TOKEN_EXPIRED',
  'NO_TOKEN',
  'TOKEN_INVALID',
  'TOKEN_MALFORMED',
  'USER_NOT_SKETCH_CLIENT',
]

// Some queries return the "UNAUTHORIZED" error-code but we want to prevent the logout action.
// see https://github.com/sketch-hq/Cloud/issues/3085 as example
const FORCE_LOGOUT_IGNORE_OPERATIONS = ['getRedemption', 'getInitialUser']

const logoutActiveSession = (
  getClient: () => ApolloClient<NormalizedCacheObject>,
  history: History,
  operation: Operation,
  message: string,
  reason: string,
  getAnalytics: () => AnalyticsContext
) => {
  const client = getClient()

  /**
   * We are accessing a private API of the queryManager
   * this property is in charge of keeping track of all the queries being fetched
   *
   * Since we are going to delete the cache and most likely this Map contains the query
   * that caused the "FORBIDDEN" status we should clear it, so when the "client.resetStore"
   * is run, no errors are thrown
   *
   * Suggestion from here:
   * https://github.com/vuejs/apollo/issues/842#issuecomment-546181039
   */
  ;((client.queryManager as any).fetchQueryRejectFns as Map<any, any>).clear()

  /**
   * We are taking leverage of the abort request controller
   * to block future request from happening. If the request errored
   * it most likely followed by other parallel requests, this will prevent
   * those request from being continue and aborted.
   *
   * The abort request controller is later reset when the store is also reset
   * https://github.com/sketch-hq/cloud-frontend/blob/5d25bf861ab9507f370a82d60d2f0dd47f663f2e/packages/cloud-frontend/src/graphql/apolloClient.ts#L103-L110
   */
  const context = operation.getContext()
  const abortController = context.abortRequestController as
    | AbortController
    | undefined
  abortController?.abort()

  debouncedLogoutActiveSession(
    client,
    history,
    operation.operationName,
    message,
    getAnalytics,
    reason
  )
}

// TODO: move this to `user`, `sso` or a new `auth` module.
// Also, rename this module to say what it does, not on which action it reacts.
// see: https://github.com/sketch-hq/Cloud/issues/15957
export const createOnErrorLink = (
  getClient: () => ApolloClient<NormalizedCacheObject>,
  history: History,
  toastActions: ToastActions,
  getAnalytics: () => AnalyticsContext
) =>
  onError(({ graphQLErrors, networkError, operation, response }) => {
    if (Array.isArray(graphQLErrors)) {
      if (graphQLErrors.includes('invalid_token')) {
        const message = 'You need to be signed in to do this'

        log(`operation name: invalid_token`)
        logoutActiveSession(
          getClient,
          history,
          operation,
          message,
          'invalid_token',
          getAnalytics
        )
        toastActions.showToast(message, 'negative')
      }

      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        const code = extensions?.code ?? 'none'
        const reason = extensions?.reason ?? 'none'
        log(`operation name: ${operation.operationName}`)
        log(
          `[GraphQL error]: Message: ${message}, Code: ${code} Location: ${locations},
          Path: ${path}`
        )

        if (
          code === 'UNAUTHORIZED' &&
          FORCE_LOGOUT_REASONS.includes(reason) &&
          !FORCE_LOGOUT_IGNORE_OPERATIONS.includes(operation.operationName)
        ) {
          logoutActiveSession(
            getClient,
            history,
            operation,
            message,
            reason,
            getAnalytics
          )
        }
      })
    }

    if (networkError) {
      if (!navigator.onLine) {
        toastActions.showToast(
          'You appear to be offline. Please check your internet connection and try again.',
          'negative'
        )
      }
      log(`operation name: ${operation.operationName}`)
      log(`[Network error]: ${networkError}`)
    }
  })
