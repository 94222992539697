import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link, matchPath, useHistory, useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { useMediaQuery } from 'react-responsive'

import {
  MyWorkspacePill,
  routes,
  useFlag,
  useQueryParams,
} from '@sketch/modules-common'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import { ExternalLink, useBreakpoint, Text } from '@sketch/components'

import LogoLink from '../LogoLink'
import ShareYourCreationsButton from '../ShareYourCreationsButton'
import Search from '../Search'

import { ReactComponent as ArrowLeft } from '@sketch/icons/arrow-circle-left-16'

import {
  CommunityLink,
  Header,
  LeftContent,
  RightContent,
  CenterContent,
  SearchWrapper,
} from './CommunityHeader.styles'

//Types
import { InitialUserFragment } from '@sketch/gql-types'

const DELAY_HIDING_INPUT = 2000

interface CollapsibleSectionProps {
  search?: string
}

const CollapsibleSection = (props: CollapsibleSectionProps) => {
  const routerSearch = props.search
  const queryParams = useQueryParams<
    'COMMUNITY_CANVAS' | 'COMMUNITY_CANVAS_LISTING'
  >()
  const [search, setSearch] = useState(routerSearch)

  /**
   * Maintain the search state consistency
   */
  useEffect(() => {
    setSearch(routerSearch)
  }, [routerSearch])

  const [expanded, setExpanded] = useState(!!search)
  const history = useHistory()
  /**
   * Validate if the Community Link and the Search-bar
   * can be showed at the same time
   */
  const isPhoneSize = !useMediaQuery({ minWidth: 520 })

  /**
   * Time the dismiss of the input so it closes
   */
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const cleanTimeout = () => {
    timeoutRef.current && clearTimeout(timeoutRef.current)
    timeoutRef.current = null
  }

  const handleSearch = (value?: string) => {
    setSearch(value || '')

    history.push(
      routes.COMMUNITY_CANVAS.create({
        query: {
          ...queryParams,
          search: value,
        },
      })
    )
  }

  return (
    <CenterContent>
      <CommunityLink
        to={routes.COMMUNITY_CANVAS.create({})}
        $expanded={isPhoneSize && expanded}
      >
        <ArrowLeft role="img" />
        <Text textStyle="prominent.primary.F" $reset>
          Community
        </Text>
      </CommunityLink>

      <SearchWrapper $expanded={expanded}>
        <Search
          value={search}
          onChange={setSearch}
          onSubmit={() => handleSearch(search)}
          placeholder="Search resources..."
          onFocus={() => {
            cleanTimeout()
            setExpanded(true)
          }}
          onBlur={e => {
            if (e.target.value) {
              /* If there's a active search the input should be expanded */
              return
            }

            cleanTimeout()

            timeoutRef.current = setTimeout(() => {
              /* Delay the input expanding for 2 seconds */
              setExpanded(false)
            }, DELAY_HIDING_INPUT)
          }}
          onClear={() => handleSearch('')}
        />
      </SearchWrapper>
    </CenterContent>
  )
}

interface CommunityHeaderProps {
  user: Pick<InitialUserFragment, 'name' | 'avatar'> | undefined
}

const CommunityHeader = (props: CommunityHeaderProps) => {
  const { user } = props

  const isSmall = !useBreakpoint('sm')
  const location = useLocation()
  const isCommunityCanvas = useFlag('community-canvas')

  const isCommunityHub = !!matchPath(location.pathname, {
    path: routes.COMMUNITY_CANVAS.template(),
    exact: true,
  })

  const search = useMemo(() => {
    const { search } = parse(location.search)

    // Normalise the data from parse into string
    if (Array.isArray(search)) {
      return search[0] || undefined
    }

    return search || undefined
  }, [location])

  const hasSearch = !!search
  const showCommunityLink = !isCommunityHub || (isCommunityHub && hasSearch)

  return (
    <Header>
      <LeftContent>
        <LogoLink user={user} />
      </LeftContent>

      {isCommunityCanvas && showCommunityLink && (
        <CollapsibleSection search={search} />
      )}

      <RightContent>
        {user ? (
          <Link to={{ pathname: routes.ENTRY.create({}) }}>
            <MyWorkspacePill user={user} />
          </Link>
        ) : (
          <ExternalLink href={SKETCH_WEBSITE}>
            <ShareYourCreationsButton small={isSmall} />
          </ExternalLink>
        )}
      </RightContent>
    </Header>
  )
}

export default CommunityHeader
