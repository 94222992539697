import React from 'react'
import styled from 'styled-components'
import { ReactComponent as KnifeIcon } from '@sketch/icons/knife-21'
import maskURL from '@sketch/icons/knife-mask-21.svg'

const KnifeIconStyled = styled(KnifeIcon)`
  position: absolute;
  width: 21px;
  height: 21px;
`

interface MaskProps {
  maskColor?: string
}

const Mask = styled.div.attrs({ 'data-exportable-icon': true })<MaskProps>`
  position: absolute;
  width: 21px;
  height: 21px;

  mask: url(${maskURL});
  background: ${({ maskColor }) => maskColor || 'white'};
`

interface ExportableIconProps {
  maskColor?: string
  className?: string
}

const BaseExportableIcon = ({ maskColor, className }: ExportableIconProps) => (
  <>
    {/* A mask is needed for some icons, to fake a border around the exportable
     icon (the small knife icon that appears in the bottom right corner) */}
    <Mask maskColor={maskColor} />
    <KnifeIconStyled className={className} />
  </>
)

export const ExportableIcon = styled(BaseExportableIcon)`
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 21px;
  height: 21px;
`
