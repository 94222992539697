import React, { CSSProperties } from 'react'
import styled from 'styled-components'

import { Tooltip, Flex } from '@sketch/components'
import DirtyIconTooltip from '../Style/DirtyIconTooltip'

import { noop } from '@sketch/utils'
import { useHandleCopyValue } from '@sketch/modules-common'

/**
 * TYPES
 */
export interface HeaderProps {
  onCopy?: (value: string) => void
  copyValue?: string
  style?: CSSProperties
  className?: string
  children?: React.ReactNode
  dirtyAttributes?: { originalValue: string; originalProperty: string }
}

/**
 * HELPER COMPONENTS
 */
const Container = styled.div`
  display: flex;
  align-content: center;
  align-items: center;

  /* Separation between any content and a header container */
  /* stylelint-disable-next-line selector-type-no-unknown */
  *:not(&) + & {
    margin-top: 32px;
  }
`

const CopiableHeader = styled.div<{ canCopy?: boolean }>`
  cursor: ${({ canCopy }) => (canCopy ? 'pointer' : undefined)};
  width: 100%;

  > div {
    width: 100%;
  }
`

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.B};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  text-transform: uppercase;
  line-height: 1;
  margin: 0;
`

export const SubTitle = styled.h2`
  display: flex;
  align-items: center;

  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.D};
  font-weight: 400;
  line-height: 1;
  color: ${({ theme }) => theme.colors.foreground.secondary.B};
`

/**
 * MAIN COMPONENTS
 */
const Header: React.FC<HeaderProps> = ({
  onCopy = noop,
  copyValue,
  style,
  className,
  dirtyAttributes,
  children,
}) => {
  const {
    handleCopyValue,
    copyTooltipText,
    handleEnterCopiableArea,
    handleLeaveCopiableArea,
    copyTooltipVisible,
  } = useHandleCopyValue(copyValue ?? '')

  const handleMouseEnterLabel = () =>
    !copyValue ? null : handleEnterCopiableArea()
  const handleMouseLeaveLabel = () =>
    !copyValue ? null : handleLeaveCopiableArea()

  const handleClickHeader = () => {
    if (!copyValue) {
      return
    }
    onCopy(copyValue)
    handleCopyValue()
  }

  return (
    <Container>
      <CopiableHeader
        style={style}
        className={className}
        onClick={handleClickHeader}
        onMouseEnter={handleMouseEnterLabel}
        onMouseLeave={handleMouseLeaveLabel}
        canCopy={!!copyValue}
      >
        <Tooltip
          placement="left"
          spacing="10px"
          visible={copyTooltipVisible}
          content={copyTooltipText}
        >
          {children}
        </Tooltip>
      </CopiableHeader>
      {dirtyAttributes && (
        <Flex marginTop={1}>
          <DirtyIconTooltip
            originalProperty={dirtyAttributes.originalProperty}
            originalValue={dirtyAttributes.originalValue}
            preventLabelTooltip={handleLeaveCopiableArea}
          />
        </Flex>
      )}
    </Container>
  )
}

const StyledHeader = styled(Header)<HeaderProps>`
  display: flex;
  align-items: center;
  margin-right: 8px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  .CopyToClipboard {
    visibility: hidden;
  }
  &:hover .CopyToClipboard {
    visibility: visible;
  }
`

export default StyledHeader
