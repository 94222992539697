import React, { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { RouteParams, routes, versionedRoutes } from '@sketch/modules-common'

import { ButtonUnstyled, useResponsiveDropdown } from '@sketch/components'

import { ArtboardDetailInfoFragment } from '@sketch/gql-types'

import { ShareWithoutVersion } from '../../../../versioning'

import { PROTOTYPES_TEXT, PAGES_TEXT, COMPONENTS_TEXT } from './constants'
import { ChevronsIcon } from '../NavigationItem.styles'
import NavigationItem, { icons } from '../NavigationItem'
import { PagesDropdown } from '../../PagesDropdown'

interface PagesNavigationItemProps {
  share: ShareWithoutVersion
  artboard?: ArtboardDetailInfoFragment
}

interface PagenameAndIcon {
  icon: keyof typeof icons
  pageName: React.ReactNode
}

const DROPDOWN_STYLE = {
  maxHeight: '80vh',
  overflowY: 'auto',
} as const

const NAVIGATION_ITEM_PROPS: Record<
  string,
  (arg?: string) => PagenameAndIcon
> = {
  artboard: artboardPageName => ({
    pageName: <>{artboardPageName}</>,
    icon: 'page',
  }),
  prototype: () => ({
    pageName: <>{PROTOTYPES_TEXT}</>,
    icon: 'prototype',
  }),
  page: () => ({
    pageName: <>{PAGES_TEXT}</>,
    icon: 'page',
  }),
  document: () => ({
    pageName: <>{PAGES_TEXT}</>,
    icon: 'page',
  }),
  components: () => ({
    pageName: <>{COMPONENTS_TEXT}</>,
    icon: 'components',
  }),
  default: () => ({
    pageName: <></>,
    icon: 'page',
  }),
}

const getPagenameAndIcon = (
  path: string,
  artboardName: string
): PagenameAndIcon =>
  ({
    // artboards
    [versionedRoutes.ARTBOARD_DETAIL.LATEST.template()]: NAVIGATION_ITEM_PROPS.artboard(
      artboardName
    ),
    [versionedRoutes.ARTBOARD_DETAIL.VERSION.template()]: NAVIGATION_ITEM_PROPS.artboard(
      artboardName
    ),
    [versionedRoutes.ARTBOARD_REVISION.LATEST.template()]: NAVIGATION_ITEM_PROPS.artboard(
      artboardName
    ),
    [versionedRoutes.ARTBOARD_REVISION.VERSION.template()]: NAVIGATION_ITEM_PROPS.artboard(
      artboardName
    ),

    // prototypes
    [versionedRoutes.SHARE_PROTOTYPES.LATEST.template()]: NAVIGATION_ITEM_PROPS.prototype(),
    [versionedRoutes.SHARE_PROTOTYPES.VERSION.template()]: NAVIGATION_ITEM_PROPS.prototype(),

    // pages
    [versionedRoutes.SHARE_PAGE_VIEW.LATEST.template()]: NAVIGATION_ITEM_PROPS.page(),
    [versionedRoutes.SHARE_PAGE_VIEW.VERSION.template()]: NAVIGATION_ITEM_PROPS.page(),
    [versionedRoutes.SHARE_PAGE_CANVAS_VIEW.LATEST.template()]: NAVIGATION_ITEM_PROPS.page(),
    [versionedRoutes.SHARE_PAGE_CANVAS_VIEW.VERSION.template()]: NAVIGATION_ITEM_PROPS.page(),

    // documents
    [versionedRoutes.SHARE_VIEW.LATEST.template()]: NAVIGATION_ITEM_PROPS.document(),
    [versionedRoutes.SHARE_VIEW.VERSION.template()]: NAVIGATION_ITEM_PROPS.document(),

    // color variables
    [versionedRoutes.CWV_COLOR_VARIABLES.LATEST.template()]: NAVIGATION_ITEM_PROPS.components(),
    [versionedRoutes.CWV_COLOR_VARIABLES.VERSION.template()]: NAVIGATION_ITEM_PROPS.components(),

    // layer styles
    [versionedRoutes.CWV_LAYER_STYLES.LATEST.template()]: NAVIGATION_ITEM_PROPS.components(),
    [versionedRoutes.CWV_LAYER_STYLES.VERSION.template()]: NAVIGATION_ITEM_PROPS.components(),

    // symbols
    [versionedRoutes.CWV_SYMBOLS.LATEST.template()]: NAVIGATION_ITEM_PROPS.components(),
    [versionedRoutes.CWV_SYMBOLS.VERSION.template()]: NAVIGATION_ITEM_PROPS.components(),

    // text styles
    [versionedRoutes.CWV_TEXT_STYLES.LATEST.template()]: NAVIGATION_ITEM_PROPS.components(),
    [versionedRoutes.CWV_TEXT_STYLES.VERSION.template()]: NAVIGATION_ITEM_PROPS.components(),
  }[path] ||
  // fallback
  NAVIGATION_ITEM_PROPS.default())

const PagesNavigationItem: React.FC<PagesNavigationItemProps> = ({
  artboard,
  share,
}) => {
  const match = useRouteMatch<RouteParams<'SHARE_PAGE_VIEW'>>()

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: PagesDropdown,
    dropdownProps: {
      shareIdentifier: share.identifier,
    },
    dropdownStyle: DROPDOWN_STYLE,
    usePortal: true,
  })

  const { icon, pageName } = useMemo(
    () => getPagenameAndIcon(match.path, artboard?.page?.name || ''),
    [match.path, artboard?.page?.name]
  )

  if (artboard) {
    return (
      <NavigationItem
        to={routes.SHARE_PAGE_CANVAS_VIEW.create({
          shareID: share.identifier,
          pageUUID: artboard.page?.uuid!,
        })}
        icon={icon}
        label={pageName}
        secondary
      />
    )
  }

  return (
    <>
      <ButtonUnstyled data-testid="page-selector" {...buttonProps}>
        <NavigationItem icon={icon} label={pageName} />
        <ChevronsIcon />
      </ButtonUnstyled>
      {content}
    </>
  )
}

export default PagesNavigationItem
