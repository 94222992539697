import {
  useRenameCollectionMutation,
  CollectionPreviewsFragment,
} from '@sketch/gql-types'

import { sortProjectCollections } from './utils/collection.cache'

interface UseRenameCollectionProps {
  projectIdentifier: string
  collection: CollectionPreviewsFragment
  onCompleted?: () => void
}

export const useRenameCollection = ({
  collection,
  projectIdentifier,
  onCompleted,
}: UseRenameCollectionProps) => {
  const [mutation] = useRenameCollectionMutation({
    optimisticResponse: ({ name }) => ({
      __typename: 'RootMutationType',
      renameCollection: {
        __typename: 'RenameCollectionResponse',
        collection: {
          ...collection,
          name,
        },
      },
    }),
    onError: 'show-toast',
    onCompleted,
    update: (cache, mutationResult) => {
      sortProjectCollections({ cache, projectIdentifier })
    },
  })

  return mutation
}
