import { isTruthy, castError } from '@sketch/utils'

import { ServiceWorkerTyped } from '@sketch/service-worker'
import { ServiceWorkerClient } from './ServiceWorkerClient'
import { v4 as uuid } from 'uuid'

const expectedSwFilename = 'service-worker.js'

const assertWorkersScriptIsValid = (serviceWorker: ServiceWorker) => {
  const swScriptPathname = new URL(serviceWorker.scriptURL).pathname.replace(
    '/',
    ''
  )
  if (swScriptPathname !== expectedSwFilename) {
    throw new Error(
      `service worker is running different than expected script, running: "${swScriptPathname}", expected: ${expectedSwFilename}`
    )
  }
}

export class RegistrationManager {
  constructor(private readonly win = global.window) {}

  getClient = async (): Promise<ServiceWorkerClient | null> => {
    const registration = await this.win.navigator.serviceWorker.getRegistration()
    if (!registration) return null

    const serviceWorker =
      registration.active || registration.installing || registration.waiting

    if (!serviceWorker) return null
    assertWorkersScriptIsValid(serviceWorker)
    return new ServiceWorkerClient(serviceWorker, this.win)
  }

  waitForClient = async (): Promise<ServiceWorkerClient> => {
    // serviceWorker.ready returns a Promise that will never reject, and which waits indefinitely until
    // the ServiceWorkerRegistration associated with the current page has an ServiceWorkerRegistration.active worker.
    // see more: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/ready
    const registration = await this.win.navigator.serviceWorker.ready

    // so by definition, this should always be truthy, but if it isn't we want to know it
    if (!registration.active) {
      throw new Error(
        'registration.active is null, when it should never be null'
      )
    }

    const serviceWorker = registration.active
    assertWorkersScriptIsValid(serviceWorker)
    return new ServiceWorkerClient(serviceWorker, this.win)
  }

  register = async (): Promise<void> => {
    await navigator.serviceWorker.register(
      `${this.win.location.origin}/${expectedSwFilename}`
    )
    const client = await this.waitForClient()
    await client.startWorker()
  }

  unregister = async (): Promise<void> => {
    let registration: ServiceWorkerRegistration | undefined
    try {
      registration = await this.win.navigator.serviceWorker.getRegistration()
    } catch (e) {
      const error = castError(e)
      throw new Error(
        `Could not get service worker registration: "${error?.message}"`
      )
    }

    if (!registration) return

    try {
      const { installing, waiting, active } = registration
      const workers = [installing, waiting, active].filter(
        isTruthy
      ) as ServiceWorkerTyped[]
      for (const worker of workers) {
        worker.postMessage({ kind: 'stop-service', messageId: uuid() })
        await registration.unregister()
      }
    } catch (e) {
      const error = castError(e)
      throw new Error(
        `Could not unregister service worker: "${error?.message}"`
      )
    }
  }
}

const getRegistrationManager = (): RegistrationManager | null => {
  if (!('serviceWorker' in navigator)) return null
  return new RegistrationManager()
}

export const registrationManager = getRegistrationManager()
