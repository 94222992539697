import React from 'react'

import { EditableText } from '@sketch/components'
import Section from '../../../Inspector/Sidebar/components/Section'
import Separator from '../../../Inspector/Sidebar/components/Separator'
import Header, { Title } from '../../../Inspector/Sidebar/components/Header'
import {
  ComponentDescription,
  DisabledWrapper,
  DisabledIcon,
  DisabledText,
} from './ComponentDescription.styles'

import { useFlag } from '@sketch/modules-common'

interface ComponentDescriptionProps {
  description?: string
  disabled: boolean
  onEdit: (description: string) => void
}

export const EditableComponentDescription: React.FC<ComponentDescriptionProps> = ({
  description = '',
  disabled,
  onEdit,
}) => {
  const isComponentDescriptionsOn = useFlag('components-description')

  if (!isComponentDescriptionsOn) {
    return null
  }

  if (disabled) {
    return (
      <>
        <Separator />
        <Section>
          <Header>
            <Title>Description</Title>
          </Header>
          <DisabledWrapper>
            <DisabledIcon />
            <DisabledText>
              This document needs to be resaved to a newer format to add a
              description.
            </DisabledText>
          </DisabledWrapper>
        </Section>
      </>
    )
  }

  return (
    <>
      <Separator />
      <Section>
        <Header>
          <Title>Description</Title>
        </Header>
        <EditableText
          element={ComponentDescription}
          value={description}
          onEdit={onEdit}
          placeholder="Enter a description..."
          allowNewLines
        />
      </Section>
    </>
  )
}
