import { ExportSettings } from '@sketch-hq/sketch-web-renderer'
import { ExportSettingsItem } from './types'
import { v1 as uuid } from 'uuid'

export const DEFAULT_EXPORT_SETTINGS: ExportSettings = {
  format: 'png',
  backingScale: 1,
}

export const IOS_EXPORT_SETTINGS_PRESET: ExportSettingsItem[] = [
  {
    format: 'png',
    backingScale: 1,
    id: uuid(),
    prefixSuffix: {
      type: 'suffix',
      content: '',
    },
  },
  {
    format: 'png',
    backingScale: 2,
    id: uuid(),
    prefixSuffix: {
      type: 'suffix',
      content: '@2x',
    },
  },
  {
    format: 'png',
    backingScale: 3,
    id: uuid(),
    prefixSuffix: {
      type: 'suffix',
      content: '@3x',
    },
  },
]

export const ANDROID_EXPORT_SETTINGS_PRESET: ExportSettingsItem[] = [
  {
    format: 'png',
    backingScale: 1,
    id: uuid(),
    prefixSuffix: {
      type: 'secondaryPrefix',
      content: 'mdpi/',
    },
  },
  {
    format: 'png',
    backingScale: 1.5,
    id: uuid(),
    prefixSuffix: {
      type: 'secondaryPrefix',
      content: 'hdpi/',
    },
  },
  {
    format: 'png',
    backingScale: 2,
    id: uuid(),
    prefixSuffix: {
      type: 'secondaryPrefix',
      content: 'xhdpi/',
    },
  },
  {
    format: 'png',
    backingScale: 3,
    id: uuid(),
    prefixSuffix: {
      type: 'secondaryPrefix',
      content: 'xxhdpi/',
    },
  },
  {
    format: 'png',
    backingScale: 4,
    id: uuid(),
    prefixSuffix: {
      type: 'secondaryPrefix',
      content: 'xxxhdpi/',
    },
  },
]
