import * as Sentry from '@sentry/browser'
import { AnalyticsContext } from '@sketch/modules-common'
import { getContextOnPageLoad } from './getContextOnPageLoad'
import { reportMetricsOnPageLoad } from './reportMetricsOnPageLoad'
import { reportServiceWorkerMetrics } from './reportServiceWorkerMetrics'

const startReportingMetricsFromServiceWorker = (
  analytics: AnalyticsContext,
  isEnabled: boolean
) => {
  if (!('serviceWorker' in navigator) || !isEnabled) return

  let callIndex = 0
  const handler = async () => {
    await reportServiceWorkerMetrics(analytics, callIndex++)
  }

  setInterval(handler, 10_000)
}

let wasReported = false
export const reportMetrics = async (
  analytics: AnalyticsContext,
  isSwEnabled: boolean
) => {
  if (wasReported) return
  wasReported = true

  try {
    const context = await getContextOnPageLoad()
    await reportMetricsOnPageLoad({ analytics, context, isSwEnabled })
    startReportingMetricsFromServiceWorker(analytics, isSwEnabled)
  } catch (err) {
    Sentry.captureException(err)
  }
}
