import React, { FC, ReactNode } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { EMBEDDED_SIGNIN_POPUP_WINDOW_NAME } from '@sketch/constants'
import { routes } from '../../routes'

type EmbedSignInGateProps = {
  children: ReactNode
}

/**
 * If rendering inside a sign-in popup window, only sign-in routes are allowed.
 * Otherwise it will render children without restriction.
 */
const EmbedSignInGate: FC<EmbedSignInGateProps> = props => {
  const location = useLocation()

  if (window.name === EMBEDDED_SIGNIN_POPUP_WINDOW_NAME) {
    const isSignInRoute = matchPath(
      location.pathname,
      routes.SIGN_IN.template()
    )

    return isSignInRoute ? <>{props.children}</> : null
  }

  return <>{props.children}</>
}

export { EmbedSignInGate }
