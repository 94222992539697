import { Spinner, ErrorMessage } from '@sketch/components'
import { useGetShareQuery } from '@sketch/gql-types'
import React from 'react'

import {
  MoveShareToProject,
  MoveShareToProjectProps,
} from './MoveShareToProject'
import { MoveShareToProjectModalLayout } from './MoveShareToProjectModalLayout'

export interface MoveShareToProjectQueryProps
  extends OmitSafe<MoveShareToProjectProps, 'share'> {
  share: { identifier: string; name: string }
}

export const MoveShareToProjectModal: React.FC<MoveShareToProjectQueryProps> = props => {
  const { share: existingShare, ...rest } = props
  const { hideModal } = rest
  const { loading, error, data } = useGetShareQuery({
    variables: { shortId: props.share.identifier },
  })

  if (loading) {
    return (
      <MoveShareToProjectModalLayout
        hideModal={hideModal}
        share={existingShare}
        button={{ disabled: true, loading: false }}
      >
        <Spinner.Flex primary />
      </MoveShareToProjectModalLayout>
    )
  }

  const share = data?.share

  if (error || !share) {
    return (
      <MoveShareToProjectModalLayout
        hideModal={hideModal}
        share={existingShare}
        button={{ disabled: true, loading: false }}
      >
        <ErrorMessage.Generic />
      </MoveShareToProjectModalLayout>
    )
  }

  return <MoveShareToProject {...rest} share={share} />
}
