import React from 'react'
import styled from 'styled-components'

import { dataIdFromObject } from '@sketch/graphql-cache'
import {
  ArtboardDetailInfoFragment,
  useGetArtboardsForPageQuery,
} from '@sketch/gql-types'

import {
  Dropdown,
  ButtonUnstyled,
  InfiniteList,
  handleFetchMore,
  useResponsiveDropdown,
} from '@sketch/components'

import { useVersioning } from '../../../versioning'

import NavigationItem from './NavigationItem'

import { ChevronsIcon, DropdownItem } from './NavigationItem.styles'

const DROPDOWN_STYLE = {
  maxHeight: '80vh',
  overflow: 'auto',
} as const

interface ArtboardsNavigationItemProps {
  shareIdentifier: string
  artboard: ArtboardDetailInfoFragment
}

const ArtboardSelectorButton = styled(ButtonUnstyled)`
  min-width: 0px;
  max-width: 100%;
`

const ArtboardsDropdown = ({
  artboard,
  shareIdentifier,
}: ArtboardsNavigationItemProps) => {
  const pageUUID = artboard?.page?.uuid ?? null

  const { versionShortId, getPathname } = useVersioning()

  const { data, fetchMore, loading } = useGetArtboardsForPageQuery({
    variables: {
      identifier: shareIdentifier,
      versionShortId,
      pageUUID,
    },
  })

  // get artboards for current page
  const artboards =
    data?.share?.version?.document?.page?.artboards?.entries ?? []

  const after =
    data?.share?.version?.document?.page?.artboards?.meta?.after ?? null

  const onLoadMore = handleFetchMore(
    fetchMore,
    ['share', 'version', 'document', 'page', 'artboards', 'entries'],
    {
      dataIdFromObject,
      after,
    }
  )

  const getArtboardLink = (artboard: ArtboardDetailInfoFragment) =>
    getPathname({
      routeKey: 'ARTBOARD_DETAIL',
      routeParams: {
        shareID: shareIdentifier,
        permanentArtboardShortId: artboard.permanentArtboardShortId!,
      },
    })

  if (loading) {
    return (
      <>
        <Dropdown.Header>Artboards</Dropdown.Header>
        <Dropdown.Skeleton />
      </>
    )
  }

  return (
    <InfiniteList onLoadMore={onLoadMore}>
      <Dropdown.Header>Artboards</Dropdown.Header>
      {artboards.map(a => (
        <DropdownItem
          key={a.identifier}
          isActive={match => !!match || a.identifier === artboard?.identifier}
          to={getArtboardLink(a)}
        >
          {a.name}
        </DropdownItem>
      ))}
    </InfiniteList>
  )
}

const ArtboardsNavigationItem = (props: ArtboardsNavigationItemProps) => {
  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: ArtboardsDropdown,
    dropdownProps: props,
    dropdownStyle: DROPDOWN_STYLE,
  })

  const artboardCount = props.artboard?.page?.artboardCount || 0

  if (artboardCount === 1) {
    return <NavigationItem icon="artboard" label={props.artboard.name || ''} />
  }

  return (
    <>
      <ArtboardSelectorButton data-testid="artboard-selector" {...buttonProps}>
        <NavigationItem icon="artboard" label={props.artboard.name || ''} />
        {artboardCount > 1 && <ChevronsIcon />}
      </ArtboardSelectorButton>
      {content}
    </>
  )
}

export default ArtboardsNavigationItem
