import { useEffect, useState } from 'react'

/**
 * useScrollTop
 * Simple hook that returns the scrollTop value of an element
 */
export const useScrollTop = (element?: HTMLElement) => {
  const [isScrollTop, setIsScrollTop] = useState(true)

  const htmlElement = element ?? window.document.body

  useEffect(() => {
    const handleScroll = () => {
      const scrollTopValue = htmlElement.scrollTop
      const isOnTop = scrollTopValue === 0

      setIsScrollTop(isOnTop)
    }

    htmlElement.addEventListener('scroll', handleScroll)

    return () => {
      htmlElement.removeEventListener('scroll', handleScroll)
    }
  }, [htmlElement])

  return {
    isScrollTop,
  }
}
