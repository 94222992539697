import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

type PreventiveRevisionLinkProps = OmitSafe<NavLinkProps, 'to'> & {
  to?: string
}

const PreventiveRevisionLink: React.FC<PreventiveRevisionLinkProps> = props => {
  const { children, ...linkProps } = props
  if (!props.to) {
    return <>{children}</>
  }

  return (
    <NavLink {...linkProps} to={props.to}>
      {children}
    </NavLink>
  )
}

export default PreventiveRevisionLink
