import { TableHeaderItem, TableComponents } from '@sketch/components'
import { GetWorkspaceMembershipsQueryVariables } from '@sketch/gql-types'

export function getColumnValues(
  renderSecondaryActions: boolean,
  order: GetWorkspaceMembershipsQueryVariables['order']
) {
  let tableWidth: '100%' | '470px' = '100%'
  let firstColWidth: '100%' | '80%' = '100%'

  const tableHeader: TableHeaderItem[] = [
    { label: 'Name' },
    { label: 'Access' },
  ]

  if (renderSecondaryActions) {
    tableWidth = '470px'
    firstColWidth = '80%'
    tableHeader.push({
      label: 'Status',
      sortKey: 'ACTIVITY_STATUS',
      sort: true,
      iconVisible: order === 'ACTIVITY_STATUS',
    })
    tableHeader.push({
      label: '',
      customCell: TableComponents.TableHeaderCellSticky,
    })
  }

  return {
    tableWidth,
    firstColWidth,
    tableHeader,
  }
}
