import React from 'react'
import styled, { css } from 'styled-components'
import { Tooltip, TooltipProps } from '../Tooltip'
import { useIsNodeContentTruncated } from '@sketch/utils'

const truncateStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export interface TruncateProps {
  width?: number
}

/**
 * Usage: <Truncate>long text to truncate</Truncate>
 */
const Truncate = styled.div<TruncateProps>`
  max-width: ${({ width }) => (width ? `${width}px` : '100%')};
  ${truncateStyles};
`

const TooltipStyled = styled(Tooltip)`
  overflow: hidden;
  max-width: 100%;
`

const truncateLines = (lines: number | 'unset' = 2) => css`
  display: -webkit-box; /* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
  overflow: hidden;
`

export interface TruncateWithTooltipProps
  extends OmitSafe<TooltipProps, 'content'>,
    TruncateProps {}

/**
 * Usage:
 *  <TruncateWithTooltip placement="bottom">
 *    Text to truncate with a tooltip with the whole text showing at the bottom
 *  </TruncateWithTooltip>
 *
 * Note: You can use any prop from Tooltip or Truncate
 */
const TruncateWithTooltip = ({
  children,
  width,
  ...props
}: TruncateWithTooltipProps) => {
  const [isTruncated, nodeRef] = useIsNodeContentTruncated<HTMLDivElement>()

  return (
    <TooltipStyled content={children} disabled={!isTruncated} {...props}>
      <Truncate ref={nodeRef} width={width}>
        {children}
      </Truncate>
    </TooltipStyled>
  )
}

export { Truncate, TruncateWithTooltip, truncateStyles, truncateLines }
