import React from 'react'

const MenuContext = React.createContext({
  setValue: (item: any, path: any[]) => {},
  activePath: [] as any[],
  setForceExpanded: (force: boolean) => {},
  forceExpanded: false,
})

export const useSelectDropdownMenu = () => {
  return React.useContext(MenuContext)
}

export const MenuContextProvider = MenuContext.Provider
