import React from 'react'
import styled from 'styled-components'

import { ReactComponent as ChevronsNew } from '@sketch/icons/chevrons-up-down-16'

const Chevrons = ({ className }: { className?: string }) => (
  <ChevronsNew className={className} height="16px" />
)

export const DropdownChevrons = styled(Chevrons).attrs({ role: 'img' })``
