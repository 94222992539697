import React, { useCallback, useState } from 'react'
import debounce from 'debounce'

const DEBOUNCE_INTERNAL = 300

type DraftComment = {
  parentAnnotationId: string
  commentValue: string
}

interface ContextProps {
  draftComments: DraftComment[]
  setDraftComment: (draftComment: DraftComment) => void
  getDraftComment: (annotationId: string) => DraftComment | undefined
}

export const DraftCommentContext = React.createContext<ContextProps>({
  draftComments: [],
  setDraftComment: () => {},
  getDraftComment: () => undefined,
})

/**
 * DraftCommentProvider
 *
 * Holds the draft comment content for annotations
 */
export const DraftCommentProvider: React.FC = ({ children }) => {
  const [draftComments, setDraftComments] = useState<DraftComment[]>([])

  const debouncedSetDrafts = debounce((draftComment: DraftComment) => {
    setDraftComments(() => {
      return [
        draftComment,
        ...draftComments.filter(
          draft => draft.parentAnnotationId !== draftComment.parentAnnotationId
        ),
      ]
    })
  }, DEBOUNCE_INTERNAL)

  const setDraftComment = useCallback(debouncedSetDrafts, [debouncedSetDrafts])
  const getDraftComment = useCallback(
    (annotationId: string) =>
      draftComments.find(draft => draft.parentAnnotationId === annotationId),
    [draftComments]
  )

  return (
    <DraftCommentContext.Provider
      value={{
        draftComments,
        setDraftComment,
        getDraftComment,
      }}
    >
      {children}
    </DraftCommentContext.Provider>
  )
}
