import {
  useShareDeleteMembershipMutation,
  ShareMembershipInfoFragment,
  ShareListItemFragment,
  ShareDeleteMembershipMutation,
  GetShareDocument,
  GetShareQueryVariables,
} from '@sketch/gql-types'
import {
  deleteShareMembershipWithPreview,
  deleteAccessShareMemberships,
} from '../../../graphql/cache'
import { useCallback, useState } from 'react'
import { useToast } from '@sketch/toasts'
import { ErrorHandler } from '@sketch/tracing'
import { useModalContext } from '@sketch/components'
import { useIsMountedRef } from '@sketch/utils'

const includesConfirmationRequiredError = (
  data: ShareDeleteMembershipMutation
) =>
  data?.shareDeleteMembership?.errors?.some(
    e => e?.code === 'CONFIRMATION_REQUIRED'
  )

interface UseDeleteMemberProps {
  member: ShareMembershipInfoFragment
  share: Pick<ShareListItemFragment, 'identifier' | 'userCanUpdateSettings'>
  isMyMembership?: boolean
  workspaceIdentifier?: string
  workspaceMembershipIdentifier?: string
}

export const useDeleteMember = ({
  share,
  member,
  isMyMembership,
  workspaceIdentifier,
  workspaceMembershipIdentifier,
}: UseDeleteMemberProps) => {
  const { showToast } = useToast()
  const { hideModal } = useModalContext()
  const [dialogOpen, setDialogOpen] = useState(false)
  const isMountedRef = useIsMountedRef()
  // If we provide a workspaceIdentifier & workspaceMembershipIdentifier means that we are accessing from
  // User Access Modal, so we don't need to close automatically the modal
  const isUserAccessModal = Boolean(
    workspaceIdentifier && workspaceMembershipIdentifier
  )

  const [mutate] = useShareDeleteMembershipMutation({
    // TODO: Migrate shareDeleteMembership mutation to use unified error handler
    //       https://github.com/sketch-hq/Cloud/issues/9274
    redirectErrors: false,
    refetchQueries: ({ data }) =>
      data?.shareDeleteMembership?.successful
        ? [
            {
              query: GetShareDocument,
              variables: {
                shortId: share.identifier,
              } as GetShareQueryVariables,
            },
          ]
        : [],
    update(cache, { data }) {
      if (!data?.shareDeleteMembership) {
        ErrorHandler.shouldNeverHappen(
          'Share delete member mutation should return valid data'
        )
        return
      }

      if (includesConfirmationRequiredError(data)) {
        return
      }

      deleteShareMembershipWithPreview({
        cache,
        data,
        share,
        member,
      })

      if (workspaceIdentifier && workspaceMembershipIdentifier) {
        deleteAccessShareMemberships({
          cache,
          data,
          workspaceIdentifier,
          workspaceMembershipIdentifier,
        })
      }

      if (
        data.shareDeleteMembership.membership?.share?.userCanUpdateSettings ===
        false
      ) {
        hideModal()
        showToast("You can no longer view this document's settings")
      }
    },
    onError() {
      if (isMountedRef.current) {
        setDialogOpen(false)
      }

      showToast(
        `Failed to remove '${member.email}'. Please try again.`,
        'negative'
      )
    },
    onCompleted(data) {
      if (!isMountedRef.current) {
        return
      }

      setDialogOpen(includesConfirmationRequiredError(data) || false)
    },
  })

  const deleteMember = useCallback(
    (confirmed: boolean = false) =>
      mutate({
        variables: {
          membershipIdentifier: member.identifier,
          confirmed,
        },
        optimisticResponse: vars => {
          // If it's the current user membership, we will get an error
          // when removing themself so we can confirm if that action is correct
          if (!confirmed && isMyMembership) {
            const response: ShareDeleteMembershipMutation = {
              __typename: 'RootMutationType',
              shareDeleteMembership: {
                __typename: 'ShareDeleteMembershipPayload',
                membership: null,
                successful: false,
                errors: [
                  {
                    __typename: 'UserError',
                    code: 'CONFIRMATION_REQUIRED',
                    message: 'Confirmation required to execute the operation',
                  },
                ],
              },
            }
            return response
          }

          const {
            membershipIdentifier: membershipIdentifierDeleted,
          } = vars as ExcludeEmpty<typeof vars>

          const response: ShareDeleteMembershipMutation = {
            __typename: 'RootMutationType',
            shareDeleteMembership: {
              __typename: 'ShareDeleteMembershipPayload',
              errors: [],
              successful: true,
              membership: {
                ...member,
                identifier: membershipIdentifierDeleted,
                share: {
                  __typename: 'Share',
                  identifier: share.identifier,
                  userCanUpdateSettings: share.userCanUpdateSettings,
                },
              },
            },
          }

          return response
        },
      }),
    [member, mutate, share, isMyMembership]
  )

  const getDeleteMemberConfirmDialogProps = useCallback(
    () => ({
      hideModal() {
        if (!isMountedRef.current) {
          return
        }

        if (isUserAccessModal) {
          return
        }

        setDialogOpen(false)
      },
      onConfirm() {
        deleteMember(true)
      },
      isModalOpen: true,
      confirmButton: {
        text: 'Confirm',
        intent: 'positive',
      } as const,
    }),
    [deleteMember, isMountedRef, isUserAccessModal]
  )

  return {
    deleteMember,
    deleteMemberConfirmDialogOpen: dialogOpen,
    getDeleteMemberConfirmDialogProps,
  }
}
