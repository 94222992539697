import styled from 'styled-components'
import { Link } from '../Link'

export const LinkUnstyled = styled(Link)`
  color: inherit;
  text-decoration: inherit;

  &:hover,
  &:active,
  &:link {
    color: inherit;
    text-decoration: inherit;
  }
`
