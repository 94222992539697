import { merge } from 'lodash'
import { OperationsSortCriteria } from '../tab-panels/UsageMetrics/types'
import { readSettings } from './settings.localStorage'
import { DevToolsSettingsState, PartialDevToolsSettings } from './types'

export const defaultValues: DevToolsSettingsState = {
  general: { isTurnedOn: false },
  featureFlags: { overrides: {} },
  ui: {
    isPinned: false,
    pinnedTo: 'left',
    width: 800,
    selectedTab: 0,
    isVisible: false,
  },
  usageMetrics: {
    isTurnedOn: false,
    selectedTab: 0,
    sortFieldPathsBy: 'costs',
    filter: '',
    sortBy: OperationsSortCriteria.DEFAULT,
    displayOperationCosts: {
      requestsCount: true,
      maxRequestDuration: true,
      totalRequestsDuration: true,
      lastRequestDuration: false,
      dbQueryCount: true,
      dbQueryTotalDecodeTime: false,
      dbQueryTotalQueryTime: false,
      dbQueryTotalQueueTime: false,
      dbQueryTotalTime: false,
    },
  },
  webRenderer: {
    releaseMode: true,
    logToConsole: false,
    showTilesBorders: false,
    showPageOrigin: false,
    prototypeTestOverlay: false,
    prototypeAssetManagerLimits: {
      maxDepthForBackgroundRequests: 2,
      maxArtboardsForBackgroundRequests: 10,
      maxInitialLoadingDepth: 2,
      maxInitialArtboardToLoad: 10,
    },
  },
  dummyData: {
    isTurnedOn: false,
    logRequests: false,
    delaySeconds: 1,
  },
  sso: {
    debug: false,
  },
}

export const mergeSettingsWithDefaultState = (
  settings: PartialDevToolsSettings
): DevToolsSettingsState => merge({}, defaultValues, settings)

export const readSettingsState = () =>
  mergeSettingsWithDefaultState(readSettings())

export type {
  DevToolsSettingsState,
  PartialDevToolsSettings,
  DisplayOperationCosts,
} from './types'
