import styled from 'styled-components'
import {
  Panel as PanelBase,
  PanelSection,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from './Panel'

export const Panel = Object.assign(PanelBase, {
  Section: PanelSection,
  Title: PanelHeader,
  Header: PanelHeader,
  Body: styled(PanelBody)``,
  Footer: PanelFooter,
})
