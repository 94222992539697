import React, { MouseEvent } from 'react'

import { Link, useModalContext } from '@sketch/components'
import { PrintToPDFButton } from '../../../components/PrintToPDFButton'
import { CopyCodesButton } from '../../../components/CopyCodesButton'

// Modals
import { RegenerateRecoveryCodesModal } from '../../RegenerateRecoveryCodesModal'

import {
  Title,
  Description,
  StyledVerificationCode,
  RecoveryCodes,
  RecoveryCode,
  Buttons,
} from '../Setup2FAModal.styles'

interface CodesStepProps {
  onFilled: (value: string) => void
  recoveryCodes: string[]
  error?: string
}

/**
 * CodesStep
 *
 * Renders the third step for the 2FA Setup Modal
 */
export const CodesStep: React.FC<CodesStepProps> = ({
  onFilled,
  recoveryCodes,
  error,
}) => {
  const { showModal, hideModal } = useModalContext()

  const handleGenerateNewCodes = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    hideModal()
    showModal(RegenerateRecoveryCodesModal)
  }

  if (recoveryCodes.length) {
    return (
      <>
        <Title>Save Your Recovery Codes</Title>
        <Description>
          Use these recovery codes to sign in if you lose access to your
          authenticator app. Store them somewhere safe!
        </Description>
        <Link onClick={handleGenerateNewCodes} isUnderlined external>
          Generate new recovery codes
        </Link>
        <RecoveryCodes data-testid="recovery-codes-list">
          {recoveryCodes.map((code, index) => (
            <RecoveryCode key={`${code}${index}`}>{code}</RecoveryCode>
          ))}
        </RecoveryCodes>
        <Buttons>
          <PrintToPDFButton codes={recoveryCodes}>
            Print Codes (PDF)
          </PrintToPDFButton>
          <CopyCodesButton codes={recoveryCodes} />
        </Buttons>
      </>
    )
  }

  return (
    <>
      <Title>Enter the Verification Code</Title>
      <Description>
        Open your authenticator app and enter the code before it expires — or
        wait for a new one to appear.
      </Description>
      <StyledVerificationCode onFilled={onFilled} error={error} />
      <Link
        href="http://www.sketch.com/docs/workspaces/managing-your-sketch-account/#troubleshooting"
        isUnderlined
        external
      >
        Code not working?
      </Link>
    </>
  )
}
