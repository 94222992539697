import React from 'react'

import { StyledPill } from './MemberBadge.styles'

export type BadgeType =
  | 'super-admin'
  | 'admin'
  | 'owner'
  | 'pending-invite'
  | 'finance'
  | 'partner'

export const BADGE_PROPS_BY_TYPE = {
  'super-admin': {
    children: 'Super Admin',
  },
  owner: {
    children: 'owner',
    variant: 'owner',
  },
  admin: {
    children: 'admin',
    variant: 'admin',
  },
  'pending-invite': {
    children: 'Pending',
    variant: 'secondary',
  },
  finance: {
    children: 'finance',
    variant: 'finance',
  },
  partner: {
    variant: 'finance',
    children: 'partner',
  },
} as const

interface MemberBadgeProps {
  badge: BadgeType
}

const MemberBadge: React.FC<MemberBadgeProps> = ({ badge }) => {
  return <StyledPill {...BADGE_PROPS_BY_TYPE[badge]} />
}

export default MemberBadge
