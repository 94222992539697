import { breakpoint } from '@sketch/global-styles'
import styled from 'styled-components'

export const Iframe = styled.iframe`
  flex: 1;
  border: none;

  /** We remove the page internal padding to make the iframe look as embedded as possible */
  /* -15px on the left margin allows a border between the sidebar and the iframe, given the background in white on both */
  margin: -8px -16px 0 -15px; /* stylelint-disable-line scales/space */

  ${breakpoint('sm')`
    /* -51px on the left margin allows a border between the sidebar and the iframe, given the background in white on both */
    margin: -54px -52px 0 -51px; /* stylelint-disable-line scales/space */  
  `}
`
