import styled, { css } from 'styled-components'
import {
  color,
  ColorProps,
  fontSize,
  FontSizeProps,
  fontStyle,
  FontStyleProps,
  fontWeight,
  FontWeightProps,
  textAlign,
  TextAlignProps,
  verticalAlign,
  VerticalAlignProps,
  LineHeightProps,
  lineHeight,
  variant,
} from 'styled-system'
import { lightTheme as theme } from '@sketch/global-styles'
import { TextTransformProperty } from 'csstype'
import { truncateStyles } from '../Truncate'

type TextStyles = typeof theme['textStyles']
type DotPrefix<T extends string> = T extends '' ? '' : `.${T}`

// Type to get all nested keys from theme.textStyles object, so textStyle prop
// is correctly autocompleted.
// For example, it should return a list of all the possibilities, like
// "header.primary.G", "copy.primary.standard.C."...
// TODO: this generic type is not 100% accurate, it's generating more strings
// than needed, for example is generating all the intermediate properties (for
// "header.primary.G" is also generating "header" and "primary")
type NestedKeyOf<AnyObject extends Record<string, unknown>> = {
  [Key in keyof AnyObject & string]: AnyObject[Key] extends Record<
    string,
    unknown
  >
    ?
        | Key
        | `${Key}${DotPrefix<NestedKeyOf<AnyObject[Key]>> extends infer D
            ? // eslint-disable-next-line @typescript-eslint/ban-types
              Extract<D, string>
            : never}`
    : ''
}[keyof AnyObject & string]

export interface TextProps
  extends FontWeightProps,
    TextAlignProps,
    VerticalAlignProps,
    FontSizeProps,
    ColorProps,
    LineHeightProps,
    FontStyleProps {
  overflow?: 'ellipsis'
  textTransform?: TextTransformProperty
  textStyle?: NestedKeyOf<TextStyles>
  $reset?: boolean
}

const textCss = css`
  ${({ $reset }) =>
    $reset &&
    css`
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
    `}

  ${() =>
    variant({
      prop: 'textStyle',
      scale: 'textStyles',
      variants: {
        // can be blank, it's just to enable the newer API
        primary: {},
      },
    })}

  text-transform: ${({ textTransform }: TextProps) => textTransform};
  word-wrap: break-word;
  overflow-wrap: break-word;

  ${({ overflow }: TextProps) =>
    overflow === 'ellipsis' &&
    css`
      ${truncateStyles};
    `};

  ${textAlign}
  ${fontSize}
  ${lineHeight};
  ${color}
  ${fontWeight}
  ${verticalAlign}
  ${fontStyle}
`

export function withText<C extends AnyComponent>(Comp: C) {
  const styledComp = styled(Comp)<TextProps>`
    ${textCss}
  `
  styledComp.displayName = 'Text'

  return styledComp
}
