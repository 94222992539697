import styled from 'styled-components'
import { Text } from '@sketch/components'

export const WarningMessage = styled(Text.Span).attrs({
  textStyle: 'copy.tertiary.standard.D',
})`
  display: block;

  margin-top: 30px; /* stylelint-disable-line scales/space */
  padding: 0 60px; /* stylelint-disable-line scales/space */
  text-align: center;
`

export default WarningMessage
