import styled from 'styled-components'

import { Banner } from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'

export const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.E};
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
`

export const Total = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: auto;
  padding: 4px 8px;
  font-size: ${({ theme }) => theme.fontSizes.F};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  line-height: 1;
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  border-radius: ${({ theme }) => theme.radii.large};
`

export const Detail = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.D};
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  ::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;

    margin-right: 8px;
    background-color: ${({ theme }) => theme.colors.sketch.A};
    box-shadow: 0px 1px 2px 0px ${({ theme }) => theme.colors.shadow.inner}
      inset; /* stylelint-disable-line scales/space */
    border-radius: ${({ theme }) => theme.radii.rounded};
  }
`

export const StorageDetails = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0 16px 0;

  list-style: none;
  padding: 0;
`

export const Right = styled.ul`
  display: flex;
  justify-content: space-between;

  list-style: none;
  padding: 0;

  ${breakpoint('base', 'xs')`
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
  `}

  ${Detail} {
    :last-child {
      margin-left: 40px;

      ::before {
        background-color: ${({ theme }) => theme.colors.background.secondary.B};
      }

      ${breakpoint('base', 'xs')`
        margin-left: 0px;
        margin-top: 8px;
      `}
    }
  }
`

export const Strong = styled.strong`
  font-size: ${({ theme }) => theme.fontSizes.D};
  color: ${({ theme }) => theme.colors.foreground.secondary.B};
`

export const StyledBanner = styled(Banner)`
  margin: 2px 0 14px 0; /* stylelint-disable-line scales/space */
`

export const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`
