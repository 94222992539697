import { localStorageKeys } from '@sketch/constants'

type StorageKeyName = keyof typeof localStorageKeys
type StorageKey = typeof localStorageKeys[StorageKeyName]

type ListenerByKey = { [key in StorageKey]?: Listener }

type Listener = (key: StorageKey, value: any, oldValue: any) => void

export const createLocalStorageSubscription = () => {
  const listenerByKey: ListenerByKey = {}
  let storageEventListener: (event: StorageEvent) => void | undefined

  const createStorageEvent = (observers: ListenerByKey) => (
    event: StorageEvent
  ) => {
    const storageKey = event.key as StorageKey
    const listener = observers[storageKey]

    if (!listener) return

    const data = event.newValue && JSON.parse(event.newValue)
    const oldData = event.oldValue && JSON.parse(event.oldValue)

    if (JSON.stringify(oldData) === JSON.stringify(data)) {
      return
    }

    listener(storageKey, data, oldData)
  }

  /**
   * Subscribes to changes on the local storage for a specific key.
   * It returns the subscription in case it has to be removed.
   */
  const trackStorageChanges = (keyName: StorageKeyName, listener: Listener) => {
    const storageKey = localStorageKeys[keyName]
    listenerByKey[storageKey] = listener

    if (!storageEventListener) {
      storageEventListener = createStorageEvent(listenerByKey)
      window.addEventListener('storage', storageEventListener)
    }

    return storageEventListener
  }

  return { trackStorageChanges }
}
