import { AnalyticsContext } from '@sketch/modules-common'
import { useMemo } from 'react'
import { trackMetric } from './telemetry'
import { _trackEvent } from './plausible'
import {
  trackSimpleAnalytics,
  removeSimpleAnalytics,
  trackSimpleEvent,
} from './simple-analytics'
import { customElementsObserver } from './pageLoad'

export const useGetAnalyticsContext = () => {
  const context: AnalyticsContext = useMemo(
    () => ({
      pageLoad: {
        observeCustomElement: customElementsObserver.pushEntry,
      },
      trackEvent: _trackEvent,
      trackMetric,
      trackSimpleAnalytics,
      removeSimpleAnalytics,
      trackSimpleEvent,
    }),
    /**
     * We want to make sure that analytics properties do not change between rerenders.
     * Though, we are OK that reference objects passed to those function would change.
     *
     * Doing this we make sure that `useAnalytics` would always give the same reference
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return context
}
