import React, { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { Flex } from '@sketch/components'
import { MobileGroupsList } from './MobileGroupsList'
import { MobileGroupsDropdown } from './MobileGroupsDropdown'
import { GroupsList } from './GroupsList'
import { GroupsError } from './GroupsError'

import { useSelectedGroupContext } from '../../context/SelectedGroupContext'
import { getCwvRouteTitle, getGroupFromURL } from '../../utils'

import type { ComponentsTree } from '../../types'
import { GetComponentsGroupsResult } from '../../operations'
import { MobileGroupsDropdownSkeleton } from './MobileGroupsList.styles'
import SkeletonGroupsList from './SkeletonGroupsList'
import { useComponentsState } from '../../../components/ComponentsStateContext'

interface GroupsManagerProps {
  isDesktop?: boolean
  status: GetComponentsGroupsResult['status']
  tree: ComponentsTree[]
}

// given a path array. like /Actions/Button/Default, broken by /
// enters the tree and looks for the branch named Actions,
// then Button on its children, and so on, until we get to the branch Default
const searchTreeLeaf = (
  path: string[],
  tree: ComponentsTree[]
): ComponentsTree[] => {
  const branch = tree.find(branch => branch.name === path[0])

  if (!branch) {
    return tree
  }

  if (path.length === 1) {
    return branch.children
  }

  return searchTreeLeaf(path.slice(1), branch.children)
}

// just adds the MobileGroupsDropdown wrapper to the output depending if we're on desktop or not
const NavigationWrapper: React.FC<{
  isDesktop: boolean
  menuTitle: string
  selected: string
  level: number
  handleBack: () => void
  handleCloseMenu: () => void
}> = ({
  children,
  isDesktop,
  menuTitle,
  selected,
  level,
  handleBack,
  handleCloseMenu,
}) => {
  return isDesktop ? (
    <>{children}</>
  ) : (
    <MobileGroupsDropdown
      type={menuTitle}
      selected={selected}
      level={level}
      handleBack={handleBack}
      handleCloseMenu={handleCloseMenu}
    >
      {children}
    </MobileGroupsDropdown>
  )
}

/**
 * This components manages the groups list in the sidebar, showing a different
 * layout depending if it's a mobile screen or desktop. Also manages the
 * different states of the list (error, loading, ready)
 */
export const GroupsManager: React.FC<GroupsManagerProps> = ({
  isDesktop,
  status,
  tree,
}: {
  isDesktop?: boolean
  status: string
  tree: ComponentsTree[]
}) => {
  const { selected, setSelected } = useSelectedGroupContext()
  const { path } = useRouteMatch()
  const groupTitle = getCwvRouteTitle(path)
  const group = getGroupFromURL()

  const [selectedGroup, setSelectedGroup] = useState({
    name: 'View All',
    path: 'View All',
    tree,
    level: 0,
  })

  const [selectedPath, setSelectedPath] = useState<string[]>([])

  useEffect(() => {
    // if there's no group id, we need to go back to the root
    if (status === 'READY' && !group) {
      setSelected('View All')
    }

    // set selected group according to the groupId from the URL, if there's one
    if (group !== '' && status === 'READY') {
      setSelected(group)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, tree, status])

  // triggering a new sync every time the selected is changed
  useEffect(() => {
    if (selected !== '') {
      syncSelectedGroup()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  /** All the methods related to the group navigation down here are for mobile */
  // For setting the selection back to View All
  const resetSelectedGroup = () =>
    setSelectedGroup({
      name: 'View All',
      path: 'View All',
      tree,
      level: 0,
    })

  // gets the selected path and sets the navigation to there
  const syncSelectedGroup = () => {
    if (selected === 'View All') {
      resetSelectedGroup()
    }

    let pathArray = selected.split('/')
    let selectedGroup = searchTreeLeaf(pathArray, tree)

    // if the tree root is empty, it means the selected group is a leaf (has no children)
    // so we need to get its parent
    if (selectedGroup.length === 0) {
      pathArray = pathArray.slice(0, -1)
      selectedGroup = searchTreeLeaf(pathArray, tree)
    }

    setSelectedGroup({
      name: pathArray[pathArray.length - 1],
      path: pathArray.join('/'),
      tree: selectedGroup,
      level: selected === 'View All' ? 0 : pathArray.length,
    })
  }

  // handles a click on a group with children
  const handleParentClick = (tree: ComponentsTree, path: string) => {
    setSelectedPath(path.split('/') || [])

    setSelectedGroup({
      name: tree.name,
      path: tree.path,
      tree: tree.children,
      level: selectedGroup.level + 1,
    })
  }

  // handles the click on a header to go back a branch
  const handleBack = () => {
    // direct child of view all
    if (
      selectedGroup.level === 1 ||
      selectedPath[selectedPath.length - 1] === ''
    ) {
      resetSelectedGroup()
      setSelectedPath([])

      return
    }

    // simply search the path we have, cut out from the selected group name
    const previousPath = selected
      .substring(0, selected.indexOf(`/${selectedGroup.name}`))
      .split('/')

    const previousBranch = searchTreeLeaf(previousPath, tree)

    setSelectedGroup({
      name: previousPath[previousPath.length - 1],
      path: previousPath.join('/'),
      tree: previousBranch,
      level: previousPath.length,
    })

    setSelectedPath(previousPath)
  }

  // if it's the root, we want to show the type of group instead of the group name
  const menuTitle = selectedGroup.level === 0 ? groupTitle : selectedGroup.name

  const componentsState = useComponentsState()

  if (status === 'LOADING') {
    return (
      <NavigationWrapper
        isDesktop={!!isDesktop}
        menuTitle={menuTitle}
        selected={selected}
        level={selectedGroup.level}
        handleBack={handleBack}
        handleCloseMenu={syncSelectedGroup}
      >
        <SkeletonGroupsList />
      </NavigationWrapper>
    )
  }

  if (status === 'ERROR') {
    return (
      <NavigationWrapper
        isDesktop={!!isDesktop}
        menuTitle={menuTitle}
        selected={selected}
        level={selectedGroup.level}
        handleBack={handleBack}
        handleCloseMenu={syncSelectedGroup}
      >
        <GroupsError />
      </NavigationWrapper>
    )
  }

  if (status === 'READY') {
    if (isDesktop) {
      return (
        <Flex flexDirection="column" flex="auto">
          <GroupsList
            componentsTree={tree}
            selected={selected}
            componentsState={componentsState}
          />
        </Flex>
      )
    }

    // on tablet and below, we show the mobile sidebar, with the transitions instead of accordions
    return (
      <>
        {componentsState === 'PROCESSING' ? (
          <MobileGroupsDropdownSkeleton />
        ) : (
          <MobileGroupsDropdown
            type={menuTitle}
            selected={selected}
            level={selectedGroup.level}
            handleBack={handleBack}
            handleCloseMenu={syncSelectedGroup}
          >
            <MobileGroupsList
              name={selectedGroup.name}
              path={selectedGroup.path}
              level={selectedGroup.level}
              groupType={groupTitle}
              // checking the group here to avoid empty trees while the state hasn't been updated yet
              componentsTree={
                selectedGroup.level === 0 ? tree : selectedGroup.tree
              }
              tree={tree}
              selected={selected}
              handleParentClick={handleParentClick}
            />
          </MobileGroupsDropdown>
        )}
      </>
    )
  }

  return null
}
