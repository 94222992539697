import styled, { css } from 'styled-components'
import {
  ListGridItem,
  ItemContainerBackgroundColor,
  Group,
  SelectedBorderStyle,
} from '../../components/Grid'
import { Text } from '@sketch/components'
import { Image as ImageUnstyled } from '@sketch/modules-common'
import { breakpoint } from '@sketch/global-styles'

export const TextGrid = styled.div(
  () => css`
    display: flex;
    flex-direction: 'column';
    flex-shrink: 0;
    flex-wrap: wrap;
    gap: 4%;
    padding: 0;
    margin: 0;

    ${breakpoint('sm')`
      gap: 32px;
    `};
  `
)

export const TextGroup = styled(Group)`
  &:first-of-type {
    padding-top: 0;
  }

  padding-top: 8px;
`

export const TextItem = styled(ListGridItem)`
  width: 100%;
  margin: 0;
`

export const TextImageWrapper = styled.div<{
  isSelected?: boolean
  needsContrastingBackground?: boolean
}>(
  ({ theme, isSelected, needsContrastingBackground }) => css`
    border-radius: ${theme.radii.large};
    margin-top: 16px;
    padding: 16px;
    overflow: auto;

    ${ItemContainerBackgroundColor};
    ${SelectedBorderStyle};
  `
)

export const TextImage = styled(ImageUnstyled)`
  /* This is a hacky way to use block instead of flex when the image is loaded */
  &[aria-busy='false'] {
    display: block;
  }
`

export const TextDetail = styled(Text.Span).attrs({
  textStyle: 'copy.quaternary.standard.D',
})`
  display: flex;
  align-items: center;
`

export const TextDetailName = styled(Text.Span).attrs({
  textStyle: 'copy.primary.standard.D',
})`
  margin-right: 8px;
`

export const TextAlignImg = styled.svg`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  opacity: 50%;
`
