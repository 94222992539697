const isValidDate = (date: Date): boolean => {
  return date && date.getTime && !isNaN(date.getTime())
}

/** Formats a Date to a localized text version of it
 * @param {Date} date - The Date object to format
 * @param {String} [locale] - The locale for the date text representation (defaults to en-GB)
 */
export const dateFormat = (
  date: Date,
  locale?: string,
  options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }
) => {
  return isValidDate(date)
    ? new Intl.DateTimeFormat(locale ? [locale] : ['en-GB'], options).format(
        date
      )
    : '-'
}
