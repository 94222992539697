import styled, { css } from 'styled-components'
import { breakpoint } from '@sketch/global-styles'

import { ReactComponent as PencilIcon } from '@sketch/icons/pencil-16'
import { Separator } from '../Separator'
import { Text } from '../Box'
import { ButtonUnstyled } from '../Button'

export const Description = styled(Text).attrs({
  textStyle: 'copy.tertiary.standard.E',
})`
  max-width: 540px;
  margin: 4px 0 0 0;
  font-weight: 400;
  line-height: 1.4;
`

const titleStyle = css`
  padding: 4px;
  margin: -4px;
  white-space: break-spaces;

  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4;

  ${breakpoint('sm')`
    font-size: 1.5rem;
  `}

  word-wrap: break-word;
  hyphens: none;

  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  + ${Description} {
    margin-top: 4px;
  }
`

export const Pencil = styled(PencilIcon)`
  width: 16px;

  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
`

export const PencilButton = styled(ButtonUnstyled).attrs({
  'aria-label': 'Edit title',
})`
  margin: 0 0 0 12px;
  transition: opacity ${({ theme }) => theme.transitions.duration[1]};
  display: flex;
  align-items: center;

  /* If the button is focused we should force the Light/colors.foreground.secondary.1 */
  :hover,
  :focus {
    ${Pencil} {
      opacity: 1;
    }
  }
`

export const Title = styled.h1`
  ${titleStyle}
  overflow: hidden;

  display: inline-block;
  outline: none;

  ::selection {
    background: ${({ theme }) => theme.colors.sketch.E};
  }

  /* We need this to allow the placeholder of the textarea to be displayed */
  :empty {
    min-width: 200px;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  line-height: 0;

  margin-bottom: 16px;

  /* Prevent flex parents from growing the input */
  align-self: flex-start;

  ${breakpoint('sm')`
    margin-bottom: 24px;
  `}
`

export const EditInput = styled.textarea`
  ${titleStyle}
  box-sizing: content-box;

  /* (-4px - 2px) to compensate for the border */
  margin: -6px; /* stylelint-disable-line scales/space */

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  min-height: 1.3em;

  background: none;

  outline: none;
  resize: none;
  overflow: hidden;

  border-radius: ${({ theme }) => theme.radii.large};
  border: 2px solid ${({ theme }) => theme.colors.sketch.E};

  ::selection {
    background: ${({ theme }) => theme.colors.sketch.E};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.state.disabled};
  }
`

export const Form = styled.form`
  margin: 0;
`

export const PreviewTitle = styled(Title)`
  width: 100%;
  min-height: 1.3em;

  box-sizing: content-box;
  visibility: hidden;

  ::after {
    /* Allow spaces to be added in the end of a string, h1 cuts the excess space */
    content: 'a';
    font-size: 0;
  }
`

export const EditInputWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const TitleButtonWrapper = styled.div`
  display: inline-flex;
  min-width: 0;
  align-items: center;

  /* Show the button when the container is hovered */
  :hover {
    & ${PencilButton} {
      opacity: 1;
    }
  }

  & ${PencilButton} {
    opacity: 0;
  }

  ${Title} {
    /* Show the button when the Title is focused, this will also be used in mobile */
    :hover,
    :focus,
    :focus-within {
      ~ ${PencilButton} {
        opacity: 1;
      }
    }
  }

  /* Show the button when it's focused */
  ${PencilButton} {
    :focus {
      opacity: 1;
    }
  }

  /* Keep the pencil visible when the title input is active */
  ${EditInputWrapper /* sc-selector */} {
    + ${PencilButton} {
      opacity: 1;

      /* If the button is focused we should force the Light/colors.foreground.secondary.1 */
      ${Pencil} {
        opacity: 1;
      }
    }
  }
`

export const ActionsWrapper = styled.div`
  margin-top: 16px;
  display: block;

  &:empty {
    margin: 0;
  }
`
export const HeaderSeparator = styled(Separator)`
  margin: 0 -16px 20px;

  ${breakpoint('sm')`
      margin: 0 -40px 40px;
  `}
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
