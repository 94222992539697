import React from 'react'

import TextAttribute from './TextAttribute'
import { TextAlignValue } from './TextAlignValue'
import { TextDecoration } from './TextDecoration'
import { TextTransform } from './TextTransform'
import { roundUpTo } from '@sketch/utils'
import { ColorFormat } from '../../../../../types'
import { renderLineHeight } from './utils'
import { copySection, copyAlignment } from './copy'
import { Truncate } from '@sketch/components'
import { lightTheme as theme } from '@sketch/global-styles'
import {
  AttributeList,
  Color,
  FullCopyAttribute,
  Header,
  HeaderTitle,
  Section,
  Separator,
  SubTitle,
} from '../../components'

import { getDirtyAttributes } from '../../components/Style/DirtyIconTooltip'
import {
  TextAttributes,
  VerticalTextAlignment,
  TextAttributedString,
  WithPotentialSharedStyle,
  TextLayerStyleProperties,
} from '../../../../../../inspector'

const renderTextAttribute = (
  {
    font,
    color,
    letterSpacing,
    paragraphStyle,
    verticalAlignment,
    text,
    onColorFormatChange,
    colorFormat,
    originalSharedStyleValues,
    hideSeparators,
    decoration,
    transform,
  }: TextAttributes & {
    text: string
    verticalAlignment?: VerticalTextAlignment
    originalSharedStyleValues?: WithPotentialSharedStyle<TextLayerStyleProperties>['originalSharedStyleValues']
    hideSeparators?: boolean
  } & ColorFormatModifier,
  i: number
) => {
  const { family, weight, size, style } = font || {}
  const displayedStyle = style && style !== 'Normal' ? style : ''
  const { spacing, lineHeight, alignment } = paragraphStyle || {}

  const alignmentCopyValue = copyAlignment({
    verticalAlignment,
    paragraphStyle,
  })

  const sectionCopyValue = copySection({
    font,
    color,
    colorFormat,
    letterSpacing,
    paragraphStyle,
    verticalAlignment,
    decoration,
    transform,
  })

  const textOriginalStyles = originalSharedStyleValues?.text

  const dirtyHeaderAttributes =
    textOriginalStyles === null
      ? { originalValue: 'disabled', originalProperty: 'text style' }
      : undefined

  return (
    <div key={`TextStyle${i}`}>
      {' '}
      {!hideSeparators && <Separator />}
      <Section data-testid="inspector-sidebar-text">
        <Header
          copyValue={sectionCopyValue}
          dirtyAttributes={dirtyHeaderAttributes}
        >
          <HeaderTitle>
            <Truncate
              width={theme.sidebar.rightWidth - 60}
            >{`Text: "${text}"`}</Truncate>
          </HeaderTitle>
        </Header>
        <AttributeList>
          {family ? (
            <TextAttribute
              value={family}
              label="Typeface"
              dirtyAttributes={getDirtyAttributes({
                originalValueKey: 'family',
                valueToDisplay: textOriginalStyles?.font?.family ?? '',
                originalValues: textOriginalStyles?.font,
                labelToDisplay: 'Font Family',
              })}
            />
          ) : null}
          {weight && style ? (
            <TextAttribute
              value={`${displayedStyle} (${weight})`}
              label="Weight"
              copyValue={weight}
              dirtyAttributes={getDirtyAttributes({
                originalValueKey: 'weight',
                valueToDisplay:
                  textOriginalStyles?.font?.weight?.toString() ?? '',
                originalValues: textOriginalStyles?.font,
              })}
            />
          ) : null}
          {size || size === 0 ? (
            <TextAttribute
              value={`${roundUpTo(size, 2)}px`}
              label="Size"
              copyValue={`${size}`}
              dirtyAttributes={getDirtyAttributes({
                originalValueKey: 'size',
                valueToDisplay: `${roundUpTo(
                  textOriginalStyles?.font?.size ?? 0,
                  2
                )}`,
                originalValues: textOriginalStyles?.font,
              })}
            />
          ) : null}
          {letterSpacing && roundUpTo(letterSpacing ?? 0, 2) !== '0' ? (
            <TextAttribute
              value={`${roundUpTo(letterSpacing, 2)}`}
              label="Letter Spacing"
              copyValue={`${letterSpacing}`}
              dirtyAttributes={getDirtyAttributes({
                originalValueKey: 'letterSpacing',
                valueToDisplay: `${roundUpTo(
                  textOriginalStyles?.letterSpacing ?? 0,
                  2
                )}`,
                originalValues: textOriginalStyles,
                labelToDisplay: 'Letter Spacing',
              })}
            />
          ) : null}
          {lineHeight ? (
            <TextAttribute
              value={`${renderLineHeight(
                lineHeight,
                size ? lineHeight / size : undefined
              )}`}
              label="Line Height"
              copyValue={`${lineHeight}`}
              dirtyAttributes={getDirtyAttributes({
                originalValueKey: 'maxLineHeight',
                valueToDisplay: `${roundUpTo(
                  textOriginalStyles?.paragraphStyle?.lineHeight ?? 0,
                  2
                )}`,
                originalValues: textOriginalStyles?.paragraphStyle,
                labelToDisplay: 'Line Height',
              })}
            />
          ) : null}
          {spacing ? (
            <TextAttribute
              value={`${roundUpTo(spacing, 2)}`}
              label="Paragraph"
              copyValue={`${spacing}`}
              dirtyAttributes={getDirtyAttributes({
                originalValueKey: 'spacing',
                valueToDisplay: `${roundUpTo(
                  textOriginalStyles?.paragraphStyle?.spacing ?? 0,
                  2
                )}`,
                originalValues: textOriginalStyles?.paragraphStyle,
                labelToDisplay: 'Paragraph',
              })}
            />
          ) : null}
          {decoration ? (
            <FullCopyAttribute
              value={
                <TextDecoration decoration={decoration} label="Decoration" />
              }
              copyValue={decoration}
              label="Decoration"
              dirtyAttributes={getDirtyAttributes({
                originalValueKey: 'decoration',
                valueToDisplay: decoration,
              })}
            />
          ) : null}
          {transform ? (
            <FullCopyAttribute
              value={<TextTransform transform={transform} label="Transform" />}
              copyValue={transform}
              label="Transform"
              dirtyAttributes={getDirtyAttributes({
                originalValueKey: 'transform',
                valueToDisplay: transform,
              })}
            />
          ) : null}
          {color ? (
            <Color
              {...color}
              onColorFormatChange={onColorFormatChange}
              colorFormat={colorFormat}
              dirtyAttributes={
                textOriginalStyles?.color
                  ? {
                      originalValue: textOriginalStyles.color,
                      originalProperty: 'Color',
                    }
                  : undefined
              }
            />
          ) : null}
        </AttributeList>
        {alignment || verticalAlignment ? (
          <>
            <Header copyValue={alignmentCopyValue}>
              <SubTitle>Alignment</SubTitle>
            </Header>
            <AttributeList>
              {alignment ? (
                <FullCopyAttribute
                  value={
                    <TextAlignValue
                      alignment={alignment}
                      label="Horizontal Alignment"
                    />
                  }
                  copyValue={alignment}
                  label="Horizontal"
                  isTextStyle
                  dirtyAttributes={getDirtyAttributes({
                    originalValueKey: 'alignment',
                    valueToDisplay:
                      textOriginalStyles?.paragraphStyle?.alignment,
                    originalValues: textOriginalStyles?.paragraphStyle,
                    labelToDisplay: 'Horizontal Alignment',
                  })}
                />
              ) : null}
              {verticalAlignment ? (
                <FullCopyAttribute
                  value={
                    <TextAlignValue
                      alignment={verticalAlignment}
                      label="Vertical Alignment"
                    />
                  }
                  copyValue={verticalAlignment}
                  label="Vertical"
                  isTextStyle
                  dirtyAttributes={getDirtyAttributes({
                    originalValueKey: 'verticalAlignment',
                    valueToDisplay:
                      originalSharedStyleValues?.verticalAlignment,
                    originalValues: originalSharedStyleValues,
                    labelToDisplay: 'Vertical Alignment',
                  })}
                />
              ) : null}
            </AttributeList>
          </>
        ) : null}
      </Section>
    </div>
  )
}

const attributesToArray = ({
  string,
  attributes,
  verticalAlignment,
  onColorFormatChange,
  colorFormat,
  hideSeparators,
  originalSharedStyleValues,
}: TextAttributedString & {
  verticalAlignment?: VerticalTextAlignment
  hideSeparators?: boolean
  originalSharedStyleValues: WithPotentialSharedStyle<TextLayerStyleProperties>['originalSharedStyleValues']
} & ColorFormatModifier) => {
  const result: Array<
    TextAttributes & {
      text: string
    } & ColorFormatModifier
  > = attributes.map(a => ({
    ...a.attributes,
    text: string.slice(a.location, a.location + a.length),
    verticalAlignment,
    onColorFormatChange,
    colorFormat,
    originalSharedStyleValues,
    hideSeparators,
  }))
  return result
}

type ColorFormatModifier = {
  onColorFormatChange: (f: ColorFormat) => void
  colorFormat: ColorFormat
}

const isWhitespace = (string: string): boolean => {
  return /^\s+$/.test(string)
}

export interface TextProps extends ColorFormatModifier {
  attributedString: TextAttributedString
  verticalAlignment: VerticalTextAlignment | undefined
  originalSharedStyleValues?: WithPotentialSharedStyle<TextLayerStyleProperties>['originalSharedStyleValues']
  hideSeparators?: boolean
}

export const Text = ({
  attributedString,
  verticalAlignment,
  originalSharedStyleValues,
  onColorFormatChange,
  colorFormat,
  hideSeparators,
}: TextProps) => (
  <>
    {attributesToArray({
      ...attributedString,
      verticalAlignment,
      originalSharedStyleValues,
      onColorFormatChange,
      colorFormat,
      hideSeparators,
    })
      .filter(attribute => !isWhitespace(attribute.text))
      .map(renderTextAttribute)}
  </>
)
