import { DataProxy } from 'apollo-cache'

import { dataIdFromObject } from '@sketch/graphql-cache'
import { noop } from '@sketch/utils'

import { updateEntityFromCache, UpdaterFunction } from './apolloCache'

interface Identity {
  __typename: string
}

type ScopeValidator = (key: string) => boolean

export function createUpdater(
  scopeValidator: ScopeValidator,
  decrementTotalCount = true
) {
  const updater: UpdaterFunction = (value, additionalParameters) => {
    const { type, index, previousValues } = additionalParameters

    const isInScope = previousValues.find(
      ({ key }) => !!key && scopeValidator(key)
    )

    if (isInScope && type === 'array' && index != null) {
      const array = value as Array<any>
      const [{ key: lastKey, value: previousValue }] = previousValues.slice(-1)

      array.splice(index, 1)

      if (lastKey === 'entries' && decrementTotalCount) {
        // Get the cache root node
        const { value: cache } = previousValues[0]
        // Get the pagination object
        const pagination = cache[previousValue.meta.id]
        pagination.totalCount--
      }
    }
  }
  return updater
}

export function removeFromPaginated<
  ToBeDeletedIdentity extends Identity,
  ParentIdentity extends Identity
>(
  cache: DataProxy,
  toBeDelete: ToBeDeletedIdentity,
  scopeValidator: ParentIdentity | ScopeValidator,
  decrementTotalCount = true
) {
  if (typeof scopeValidator === 'function') {
    return updateEntityFromCache(
      cache,
      toBeDelete,
      createUpdater(scopeValidator, decrementTotalCount)
    )
  }

  const parentCacheId = dataIdFromObject(scopeValidator)

  if (!parentCacheId) {
    return noop
  }

  return updateEntityFromCache(
    cache,
    toBeDelete,
    createUpdater(key => key.includes(parentCacheId))
  )
}
