import { BaseAnnotationFragment } from '@sketch/gql-types'
import React from 'react'
import { NavLinkProps } from 'react-router-dom'
import { useAnnotationQueryVariables } from '../../context'
import AnnotationListWrapper from '../../components/AnnotationListWrapper'
import AnnotationListStatusFilter from '../../components/AnnotationListStatusFilter'
import AnnotationListHeader from '../../components/AnnotationListHeader'
import { AnnotationStatusFilterWithoutUnread } from '../../types'
import ActiveOrResolvedAnnotationsList from '../../components/ActiveOrResolvedAnnotationsList'
import AllAnnotationsList from '../../components/AllAnnotationsList'

interface ListProps {
  activeAnnotationIdentifier?: string
  resolveAnnotationLink?: (
    annotation: BaseAnnotationFragment
  ) => NavLinkProps['to'] | undefined
}

interface AnnotationListContainerProps extends ListProps {
  navbarAction?: React.ReactNode
}

const AnnotationListContainer = (props: AnnotationListContainerProps) => {
  const { navbarAction, ...otherProps } = props
  const { annotationStatus } = useAnnotationQueryVariables('sidebar')
  const showAllAnnotations = annotationStatus === 'ALL'

  /*
   A newer implementation for the list of annotations overrides  the variables 
   from "AnnotationQueryVariablesContext" and "useAnnotationQueryVariables". 
   -> This override happens in "AllAnnotationsList"
  */
  const Lists = showAllAnnotations
    ? AllAnnotationsList
    : ActiveOrResolvedAnnotationsList

  return (
    <AnnotationListWrapper data-testid="annotation-list">
      <AnnotationListHeader action={props.navbarAction}>
        <AnnotationListStatusFilter
          annotationStatus={
            (annotationStatus as AnnotationStatusFilterWithoutUnread) ||
            undefined
          }
        />
      </AnnotationListHeader>
      <Lists {...otherProps} />
    </AnnotationListWrapper>
  )
}

export default AnnotationListContainer
