import React from 'react'

import { SKETCH_WEBSITE } from '@sketch/env-config'
import { routes } from '@sketch/modules-common'

import { InitialUserFragment } from '@sketch/gql-types'

import { Logo, StyledExternalLink, StyledLink } from './LogoLink.styles'

interface LogoLinkProps {
  user?: Pick<InitialUserFragment, 'name' | 'avatar'>
}

const LogoLink = (props: LogoLinkProps) => {
  const { user } = props

  if (user) {
    // There idea is to redirect user to the home page, i.e. www.sketch.com marketing website
    // However, if user is signed in, www.sketch.com will redirect the user back to the web app
    // (using `/c` path), so we are just making this process much faster:
    return (
      <StyledLink to={{ pathname: routes.ENTRY.create({}) }} title="Home">
        <Logo />
      </StyledLink>
    )
  }

  return (
    <StyledExternalLink href={SKETCH_WEBSITE} title="Home">
      <Logo />
    </StyledExternalLink>
  )
}

export default LogoLink
