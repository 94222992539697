import React from 'react'
import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import { ButtonUnstyled } from '../Button'
import { Pill } from '../Pill'

export interface TabButtonProps {
  selected?: boolean
}

export const tabStyle = css<TabButtonProps>(
  ({ theme, selected }) => css`
    display: inline-block;

    padding: 8px 12px;

    color: ${selected
      ? theme.colors.sketch.A
      : theme.colors.foreground.secondary.C};
    background: ${selected ? theme.colors.sketch.F : undefined};

    font-family: ${theme.fonts.buttons};
    font-size: ${theme.fontSizes.D};

    text-align: center;
    user-select: none;
    white-space: nowrap;
    border-radius: ${theme.radii.large};

    &:hover {
      color: ${selected ? undefined : theme.colors.foreground.secondary.A};
    }

    ${selected &&
    css`
      ${Pill} {
        color: ${theme.colors.sketch.A};
        background: ${theme.colors.sketch.F};
      }
    `}
  `
)

export const TabButton = styled(ButtonUnstyled)`
  ${tabStyle};
`

export const TabDiv = styled.span`
  cursor: pointer;

  ${tabStyle};
`

export const TabLink = styled.a`
  ${tabStyle};
`

export interface TabProps {
  selected?: boolean
  /** This prop is deprecated, but still here for backwards compatibility */
  borderPosition?: 'top' | 'bottom'
  onTabSelected?: () => void
  urlFragment?: string
  externalUrl?: string
  className?: string
  isDisabled?: boolean
  'data-testid'?: string
}

export const Tab: React.FC<TabProps> = ({
  children,
  selected = false,
  urlFragment,
  onTabSelected,
  className,
  isDisabled,
  externalUrl,
  'data-testid': dataTestId,
}) => (
  <li className={className}>
    {urlFragment ? (
      <NavLink to={`#${urlFragment}`}>
        <TabDiv selected={selected} onClick={onTabSelected}>
          {children}
        </TabDiv>
      </NavLink>
    ) : externalUrl ? (
      <TabLink href={externalUrl} target="_blank">
        {children}
      </TabLink>
    ) : (
      <TabButton
        data-testid={dataTestId}
        selected={selected}
        onClick={onTabSelected}
        disabled={isDisabled}
      >
        {children}
      </TabButton>
    )}
  </li>
)
