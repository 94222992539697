export const STEPS_ID_MAP = {
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  DOCUMENTATION: 'DOCUMENTATION',
  UPLOAD_DOCUMENTS: 'UPLOAD_DOCUMENTS',
  DOWNLOAD_APP: 'DOWNLOAD_APP',
  COLLABORATION: 'COLLABORATION',
  SUBSCRIBE: 'SUBSCRIBE',
  UPGRADE_PERSONAL_WORKSPACE: 'UPGRADE_PERSONAL_WORKSPACE',
  ADD_EDITORS: 'ADD_EDITORS',
  MFA: 'MFA',
  EDUCATION: 'EDUCATION',
}

export const STEP_ID_SORTED_BY_PRIORITY: Array<keyof typeof STEPS_ID_MAP> = [
  'UPGRADE_PERSONAL_WORKSPACE',
  'DOWNLOAD_APP',
  'VERIFY_EMAIL',
  'DOCUMENTATION',
  'SUBSCRIBE',
  'COLLABORATION',
  'ADD_EDITORS',
  'MFA',
  'UPLOAD_DOCUMENTS',
  'EDUCATION',
]
