import React from 'react'
import styled, { css } from 'styled-components'

import { ButtonUnstyled } from '../../Button'

import { ReactComponent as Ellipsis } from '@sketch/icons/ellipsis-16'

const EllipsisIcon = styled(Ellipsis)`
  width: 16px;
  height: 16px;
`

export const DropdownAction = styled(ButtonUnstyled)(
  ({ theme }) => css`
    height: 32px;
    width: 40px;
    display: inline-flex;
    color: currentColor;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.radii.large};

    :hover,
    :focus,
    :focus-within {
      background-color: ${theme.colors.background.tertiary.C};
    }
  `
)

interface EllipsisDropdownActionProps {
  label: string
}

export const EllipsisDropdownAction: React.FC<EllipsisDropdownActionProps> = ({
  label,
}) => {
  return (
    <DropdownAction>
      <EllipsisIcon aria-label={label} />
      <span className="sr-only">{label}</span>
    </DropdownAction>
  )
}
