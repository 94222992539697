import React, { useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import qs from 'query-string'
import { LocationDescriptor } from 'history'

import { useGetPublicWorkspaceFromTokenQuery } from '@sketch/gql-types'

import {
  Description,
  WorkspaceLogo,
  Wrapper,
  ItemWrapper,
  InviterAvatar,
} from './RedirectBadge.styles'
import { getRedirectStateFromLocationObject } from './utils'

export interface Avatar {
  large: string | null
}

export interface Inviter {
  name: string | null
  avatar?: Avatar | null
}

const getFirstArgument = (field: string | string[]) =>
  Array.isArray(field) ? field[0] : field

type RedirectBadgeProps =
  | { from: LocationDescriptor }
  | DocumentInviteProps
  | ProjectInviteProps

export const RedirectBadge: React.FC<RedirectBadgeProps> = props => {
  const redirectState = getRedirectStateFromLocationObject(props.from)

  if (!redirectState) {
    return null
  }

  const { search, redirectId } = redirectState
  const query: { token?: string } = qs.parse(search || '')

  if (redirectId === 'workspace-invite' && query.token) {
    return <WorkspaceInvite inviteToken={getFirstArgument(query.token)} />
  }

  if (redirectId === 'document-invite') {
    return <DocumentInvite {...(props as DocumentInviteProps)} />
  }

  if (redirectId === 'project-invite') {
    return <ProjectInvite {...(props as ProjectInviteProps)} />
  }

  return null
}

/**
 * Redirect Badge Variants
 */

// Workspace Invite Badge
interface WorkspaceInviteProps {
  inviteToken: string
}

const WorkspaceInvite = ({ inviteToken }: WorkspaceInviteProps) => {
  const nodeRef = useRef(null)
  const { data, error } = useGetPublicWorkspaceFromTokenQuery({
    variables: { inviteToken },
  })

  if (error) {
    return null
  }

  const shouldShowBadge = Boolean(data?.getPublicWorkspaceFromToken.name)

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={shouldShowBadge}
      timeout={200}
      mountOnEnter={true}
      unmountOnExit={true}
      classNames="badge"
    >
      <Wrapper>
        <ItemWrapper ref={nodeRef}>
          <WorkspaceLogo
            workspaceName={data?.getPublicWorkspaceFromToken.name}
            src={data?.getPublicWorkspaceFromToken.avatar?.small}
            size="32px"
          />
          <Description>
            You’ve been invited to{' '}
            <b>{data?.getPublicWorkspaceFromToken.name}</b>
          </Description>
        </ItemWrapper>
      </Wrapper>
    </CSSTransition>
  )
}

// Document Invite Badge
interface DocumentInviteProps {
  from: LocationDescriptor
  documentInvitation: {
    documentName: string
    inviter?: Inviter
  }
}

const DocumentInvite = ({ documentInvitation }: DocumentInviteProps) => {
  const { documentName, inviter } = documentInvitation

  return (
    <Wrapper>
      <ItemWrapper aria-label="redirect-badge">
        {inviter && (
          <InviterAvatar
            size="32px"
            src={inviter?.avatar?.large || undefined}
            name={inviter.name || '?' /** Name should always be there */}
          />
        )}
        <Description>
          <b>{inviter?.name}</b> wants to share the document &ldquo;
          <b>{documentName}</b>&rdquo; with you
        </Description>
      </ItemWrapper>
    </Wrapper>
  )
}

// Project Invite Badge
interface ProjectInviteProps {
  from: LocationDescriptor
  projectInvitation: {
    projectName: string
    inviter?: Inviter
  }
}

const ProjectInvite = ({ projectInvitation }: ProjectInviteProps) => {
  const { projectName, inviter } = projectInvitation

  return (
    <Wrapper>
      <ItemWrapper aria-label="redirect-badge">
        {inviter && (
          <InviterAvatar
            size="32px"
            src={inviter?.avatar?.large || undefined}
            name={inviter.name || '?' /** Name should always be there */}
          />
        )}
        <Description>
          <b>{inviter?.name}</b> wants to share the &ldquo;
          <b>{projectName}</b>&rdquo; project with you
        </Description>
      </ItemWrapper>
    </Wrapper>
  )
}
