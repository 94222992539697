import styled from 'styled-components'

import { Box } from '../Box'

export const Separator = styled(Box).attrs({
  'data-testid': 'component-separator',
})`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.border.A};
`
