import styled from 'styled-components'

import { Banner } from '@sketch/components'

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
`

export const Warning = styled.strong`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.state.negative.A};
`

export const StyledBanner = styled(Banner)`
  padding: 16px;
  margin: 8px 0 24px 0;
`
