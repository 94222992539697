import styled, { css } from 'styled-components'

import { Box } from '../Box'
import { ButtonUnstyled } from '../Button'

import { ReactComponent as ChevronRightIcon } from '@sketch/icons/chevron-right-16'

export const Wrapper = styled(Box)<{ dark?: boolean }>(
  ({ theme: { fontSizes, colors }, dark }) => css`
    display: inline-flex;
    align-items: center;

    border: 1px solid ${colors.border.A};
    border-radius: 5px;

    font-size: ${fontSizes.D};

    ${dark &&
    css`
      background: ${colors.background.primary.B};
      border-color: white;

      > button {
        color: ${colors.foreground.primary.A};

        &:disabled {
          color: ${colors.foreground.primary.B};
        }
      }

      /* divider */
      > div {
        border-color: hsla(0, 0%, 100%, 0.3);
      }
    `}
  `
)

export const ChevronRight = styled(ChevronRightIcon)`
  width: 16px;
`

export const ChevronLeft = styled(ChevronRight)`
  transform: rotate(180deg);
`

export const Button = styled(ButtonUnstyled)(
  ({ theme }) => css`
    display: flex;
    flex: none;
    justify-content: center;
    align-items: center;

    width: 30px;
    height: 30px;

    cursor: pointer;
    color: ${theme.colors.foreground.secondary.D};

    &:disabled {
      color: ${theme.colors.state.disabled};
    }

    &:hover {
      background: ${theme.colors.state.hover};
    }
  `
)

export const Pages = styled.div`
  width: 52px; /* Enough space to fit "1 of 99+"" */
  text-align: center;
  font-variant-numeric: tabular-nums; /* Same size numbers */
`

export const Divider = styled.div(
  ({ theme }) => css`
    height: 13px;
    width: 0;
    border-right: 2px dotted ${theme.colors.background.tertiary.A};
  `
)
