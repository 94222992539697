import React, { ComponentType, FC } from 'react'
import styled from 'styled-components'

import { lightTheme as theme } from '@sketch/global-styles'
import { Text } from '../Box'
import { getDisplayName } from '@sketch/utils'

export interface HeadingProps {
  size?: keyof typeof headingTextStyles
}

export const headingTextStyles = {
  hero: {
    fontSize: '2.375rem',
    lineHeight: '3rem',
    fontWeight: theme.fontWeights.bold,
  },
  large: {
    fontSize: theme.fontSizes.H,
    lineHeight: '2.25rem',
    fontWeight: theme.fontWeights.bold,
  },
  medium: {
    fontSize: theme.fontSizes.G,
    lineHeight: '2rem',
    fontWeight: theme.fontWeights.medium,
  },
  small: {
    fontSize: theme.fontSizes.F,
    lineHeight: '1.5rem',
    fontWeight: theme.fontWeights.medium,
  },
  smaller: {
    fontSize: theme.fontSizes.E,
    lineHeight: '1.25rem',
    fontWeight: theme.fontWeights.medium,
  },
  tiny: {
    fontSize: theme.fontSizes.D,
    lineHeight: '1.25rem',
    fontWeight: theme.fontWeights.medium,
  },
}

function withHeading<Props>(
  Comp: ComponentType<Props>,
  defaultSize: keyof typeof headingTextStyles
) {
  const Heading: FC<Props & HeadingProps> = ({ size, ...rest }) => {
    const props = {
      ...headingTextStyles[size || defaultSize],
      ...rest,
    } as Props
    return <Comp {...props} />
  }

  Heading.displayName = `withHeading(${getDisplayName(Comp)})`
  return styled(Heading)``
}

const H0 = withHeading(Text.H1, 'hero')
const H1 = withHeading(Text.H1, 'large')
const H2 = withHeading(Text.H2, 'medium')
const H3 = styled(withHeading(Text.H3, 'small'))`
  margin-top: 48px;
  margin-bottom: 48px;
`
const H4 = withHeading(Text.H4, 'smaller')
const H5 = withHeading(Text.H5, 'tiny')
const H6 = withHeading(Text.H6, 'tiny')

export const Heading = {
  H0,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
}
