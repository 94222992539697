import styled, { css } from 'styled-components'

export const Stops = styled.div`
  padding: 18px; /* stylelint-disable-line scales/space */
  width: 300px;
`

export const StopContainer = styled.div<{ connected: boolean }>`
  display: flex;
  max-width: calc(100% - 40px);
  width: 100%;
  ${({ connected }) =>
    connected &&
    css`
      padding-bottom: 24px;
    `}
`

export const StopPercentage = styled.div`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  font-size: 0.8125rem;
  margin-right: 8px;
  text-align: right;
  width: 34px;
  min-width: 34px;
`

export const CopyAll = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.D};
  margin-top: 20px;

  button {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
  }
`
