import { dataIdFromObject } from '@sketch/graphql-cache'
import { useGetPagesQuery, GetPagesQueryVariables } from '@sketch/gql-types'
import { handleFetchMore } from '@sketch/components'

export const useGetPagesList = (variables: GetPagesQueryVariables) => {
  const { data, fetchMore, loading } = useGetPagesQuery({
    variables,
  })

  const after = data?.share?.version?.document?.pages?.meta?.after ?? null
  const pages = data?.share?.version?.document?.pages?.entries ?? []
  const componentCount = data?.share?.version?.document?.componentCount

  const prototypesCount =
    data?.share?.version?.document?.prototypes?.meta.totalCount ?? 0

  const pagesCount = data?.share?.version?.document?.pages?.meta.totalCount

  const onLoadMore = handleFetchMore(
    fetchMore,
    ['share', 'version', 'document', 'pages', 'entries'],
    { dataIdFromObject, after }
  )

  return {
    componentCount,
    loading,
    onLoadMore,
    pages,
    pagesCount,
    prototypesCount,
  }
}
