import styled from 'styled-components'

import { Banner } from '@sketch/components'

export const PartnersBanner = styled(Banner)`
  margin-bottom: 32px;
`

export const EducationBanner = styled(Banner)`
  margin-bottom: 20px;
`
