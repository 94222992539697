import { useGetProjectsQuery } from '@sketch/gql-types'

/**
 * Hook to know if the workspace has a drafts project or not.
 * (Guest don't have any Drafts folder in the workspace by default)
 */
export function useGetHasDraftsProject(workspaceId: string) {
  const workspaceResult = useGetProjectsQuery({
    variables: { workspaceId },
  })
  const { data: workspaceData } = workspaceResult
  const hasDraftsProject = Boolean(
    workspaceData?.workspace.draftsProject?.entries[0]
  )

  return hasDraftsProject
}
