import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "drive-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M15.255 5c1.001 0 1.366.088 1.743.26.378.174.687.432.925.772.237.34.389.683.568 1.669L20 16h-.035a3.5 3.5 0 00-3.465-3h-9a3.5 3.5 0 00-3.465 3H4l1.509-8.299c.18-.986.33-1.328.568-1.669.238-.34.547-.598.925-.771C7.379 5.088 7.744 5 8.745 5h6.51z", fillOpacity: 0.15, fill: "currentcolor" }),
        React.createElement("path", { d: "M16.5 13.75a2.744 2.744 0 012.75 2.75c0 .76-.308 1.447-.805 1.945a2.741 2.741 0 01-1.945.805h-9c-.76 0-1.447-.308-1.945-.805A2.741 2.741 0 014.75 16.5c0-.76.308-1.447.805-1.945A2.741 2.741 0 017.5 13.75z", stroke: "currentColor", strokeWidth: 1.5 }),
        React.createElement("path", { d: "M16.5 15.75a.75.75 0 110 1.5.75.75 0 010-1.5z", fill: "currentColor" }),
        React.createElement("path", { d: "M5.8 14.5L4 16l1.509-8.299c.18-.986.33-1.328.568-1.669.238-.34.547-.598.925-.771C7.379 5.088 7.744 5 8.745 5h6.51c1.001 0 1.366.088 1.743.26.378.174.687.432.925.772.237.34.389.683.568 1.669l1.277 6.799c.024.128.101.628.232 1.5l-1.8-1.5m.003 0l-1.188-6.53c-.118-.65-.184-.863-.292-1.033l-.03-.046a.756.756 0 00-.32-.266c-.2-.092-.397-.125-1.118-.125h-6.51c-.72 0-.918.033-1.118.125a.756.756 0 00-.32.266c-.126.18-.193.37-.322 1.078L5.796 14.5", fill: "currentColor", fillRule: "nonzero" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
