import styled from 'styled-components'

import { Pill } from '@sketch/components'

export const StyledPill = styled(Pill)`
  margin-left: auto;

  background-color: ${({ theme }) => theme.colors.sketch.A};
  color: ${({ theme }) => theme.colors.foreground.primary.A};

  .active & {
    background-color: ${({ theme }) => theme.colors.sketch.A};
    color: ${({ theme }) => theme.colors.foreground.primary.A};
  }
`
