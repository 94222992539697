import styled from 'styled-components'

import { Dropdown } from '@sketch/components'
import { DropdownButton } from '../../../shares/components/DocumentItem'

import { ReactComponent as BaseEllipsisIcon } from '@sketch/icons/dots-3-horizontal-24'

export const EllipsisIcon = styled(BaseEllipsisIcon)`
  width: 20px;
  height: 20px;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: content;
`

export const TooltipImageWrapper = styled.div`
  border-radius: 16px 16px 0 0;
`

export const WrapperItem = styled.div`
  display: inline;
  max-width: 50px;
`

export const TableButton = styled(DropdownButton).attrs({})`
  color: ${({ theme: { colors } }) => colors.foreground.secondary.D};

  &:hover {
    color: inherit;
  }
`

export const DropdownHeader = styled(Dropdown.Header)`
  padding-top: 8px;
`

export const DropdownItemDescription = styled(Dropdown.ItemDescription)`
  font-size: 0.6875rem;
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
`
