import styled from 'styled-components'

import { dropdownItemStyles } from '@sketch/components'

export const DropdownListOption = styled.div`
  padding: 0 8px;
`

export const DropdownOption = styled.div`
  ${dropdownItemStyles}
`
