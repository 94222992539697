import styled from 'styled-components'

export const Wrapper = styled.section`
  margin-top: 30px; /* stylelint-disable-line scales/space */
  background: #fbe199;
  padding: 15px 20px; /* stylelint-disable-line scales/space */
  border-radius: 4px;
  margin-bottom: 1em; /* stylelint-disable-line scales/space */

  /* This needs to be hard coded (dark) because the background is yellow
  and we don't want to change the text color between themes */
  color: hsl(0, 0%, 0%, 0.85);
`

export const Title = styled.h1`
  font-size: 0.8125rem;
  color: #624800;
  margin: 0 0 8px;
  line-height: 1;
`
export const Description = styled.span`
  color: #946c00;
  font-size: 0.8125rem;
`
