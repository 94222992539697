import styled, { css, keyframes } from 'styled-components'
import {
  ButtonUnstyled,
  SegmentControl as BaseSegmentControl,
} from '@sketch/components'

const infiniteRotation = keyframes`
  from {
	  background-position-x: 0px;
  }

  to {
    background-position-x: -1000px;
  }
`

/**
 * Item styles
 */

export const ItemBackground = styled.div`
  position: absolute;
  top: -50px;
  bottom: -50px;
  right: -20px;
  left: -20px;
  overflow: hidden;
  opacity: 0;

  z-index: -1;

  transition-property: opacity;
  transition-duration: 50ms;
  transition-timing-function: linear;

  ::before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    content: '';
    background-image: radial-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 69%,
      rgba(255, 255, 255, 1) 100%
    );
  }
`

export const ItemBackgroundImage = styled.div`
  animation: 45s linear infinite running ${infiniteRotation};
  background-size: 40px;

  /* Increase the area of the background because of the rotation */
  margin: -70px; /* stylelint-disable-line scales/space */

  position: absolute;
  z-index: -1;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  rotate: 8deg;
`

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  max-width: 530px;
  width: 100%;
  position: relative;
  padding: 0px 20px;
`

export const ItemIllustration = styled.img`
  height: 128px;
  flex-shrink: 0;
`

export const ItemTitle = styled.h1`
  margin: 16px 0 0;
`

export const ItemDescription = styled.p`
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  margin: 16px 0 24px;
`

export const ItemAnchor = styled.span`
  margin-top: 8px;

  display: block;
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
`

export const ItemAnchorButton = styled(ButtonUnstyled)`
  margin-top: 16px;

  display: block;
  color: ${({ theme }) => theme.colors.foreground.secondary.C};

  && {
    /* Correct some button styles influences */
    margin-left: 0;
  }
`

/**
 * Section Styles
 */

interface SectionProps {
  $isMobile?: boolean
}

export const Section = styled.section<SectionProps>`
  display: flex;
  position: relative;

  margin: 0 auto 80px; /* stylelint-disable-line scales/space */
  padding: 60px 0 0;

  ${({ $isMobile }) =>
    $isMobile
      ? css`
          max-width: 488px;
          padding: 40px 0 0;

          ${ItemWrapper} {
            flex: 0 0 auto;
          }
        `
      : css`
          ${ItemWrapper} {
            /* 
              This forces the items to have space between them
              and to have a bigger mouse movement X field, the
              main reason for padding to be used 
            */
            &:nth-child(1) {
              padding-right: 70px;

              & > ${ItemBackground} {
                right: 40px;
              }
            }
            &:nth-child(2) {
              padding-left: 70px;

              & > ${ItemBackground} {
                left: 40px;
              }
            }
          }
        `}
`

export const SectionTitle = styled.h1`
  line-height: 1.24; /* stylelint-disable-line scales/line-height*/
  margin: 0 auto;
`

export const SectionDescription = styled.p`
  margin: 8px 0 0;
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
`

/**
 * Page Styles
 */

export const TitleWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 100%;
  max-width: 518px;
`

export const SegmentControl = styled(BaseSegmentControl)`
  margin: 24px auto 0;
  width: 290px;

  /* Forcing the segment to be on top of the background animation */
  position: relative;
  z-index: 1;
` /* We are forcing the type so Generics work properly */ as typeof BaseSegmentControl
