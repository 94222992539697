import {
  DuplicationWorkspaceFragment,
  PublicWorkspaceFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

type Workspace = Partial<
  Pick<
    WorkspaceMinimalFragment | PublicWorkspaceFragment,
    'userIsOwner' | 'type'
  >
>

export const isPersonalWorkspace = (workspace: Workspace = {}) =>
  workspace?.type === 'PERSONAL' && workspace?.userIsOwner

export const isGuestWorkspaces = (workspace: WorkspaceMinimalFragment) =>
  workspace.userRole === 'GUEST'

export const isMemberWorkspace = (workspace: WorkspaceMinimalFragment) =>
  !isPersonalWorkspace(workspace) && !isGuestWorkspaces(workspace)

export const isSSOWorkspace = (
  workspace: Pick<WorkspaceMinimalFragment, 'customer'>
) => Boolean(workspace.customer?.ssoEnabled)

export const getPersonalWorkspace = (
  workspaces: WorkspaceMinimalFragment[] = []
) => workspaces.find(isPersonalWorkspace)

export const getMemberWorkspaces = <
  TWorkspaceFragment extends WorkspaceMinimalFragment
>(
  workspaces: TWorkspaceFragment[] = []
) => workspaces.filter(isMemberWorkspace)

export const getGuestWorkspaces = (
  workspaces: WorkspaceMinimalFragment[] = []
) => workspaces.filter(isGuestWorkspaces)

export const userCanEditInWorkspace = (
  workspace: Pick<WorkspaceMinimalFragment, 'userCanEdit' | 'status'>
) => workspace.userCanEdit && workspace.status === 'ACTIVE'

export const userCanUploadDocuments = (
  workspace: Pick<DuplicationWorkspaceFragment, 'uploadsRestrictedFor'>
) =>
  workspace.uploadsRestrictedFor === 'OTHERS' ||
  workspace.uploadsRestrictedFor === 'NONE'
