import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react'
import { useLocalStorage } from 'react-use'
import { ResponsiveValues } from '@sketch/global-styles'

export type ResponseProp = ResponsiveValues | number

export enum GridSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Full = 'full',
}

const context = createContext<
  [GridSize | undefined, Dispatch<SetStateAction<GridSize | undefined>>]
>([GridSize.Medium, () => {}])

export const CustomGridContextProvider: React.FC = ({ children }) => {
  const [gridSize, setGridSize] = useLocalStorage<GridSize>(
    'cwv-grid-size',
    GridSize.Medium
  )

  return (
    <context.Provider value={[gridSize, setGridSize]}>
      {children}
    </context.Provider>
  )
}

export function useCustomGridContext() {
  const [selectedGridSize, setSelectedGridSize] = useContext(context)

  // number of columns to display or each resolution
  const getDefaultColumns = (): ResponseProp => {
    switch (selectedGridSize) {
      case GridSize.Small:
        return [2, 2, 3, 3, 4, 5, 6, 6, 7]
      case GridSize.Large:
        return [1, 1, 2, 2, 2, 2, 3, 4, 5]
      case GridSize.Full:
        return 1
      default:
        return [2, 2, 3, 3, 3, 3, 4, 5, 6]
    }
  }

  return {
    selectedGridSize,
    setSelectedGridSize,
    defaultColumns: getDefaultColumns(),
    // space between rows for each resolution
    verticalRowSpaceGrid: [28, 28, 32] as ResponseProp,
    // minimum width that columns should have
    minColumnWidth: 70,
  }
}
