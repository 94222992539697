import {
  useEditCollectionDescriptionMutation,
  CollectionPreviewsFragment,
} from '@sketch/gql-types'

interface UseEditCollectionDescriptionProps {
  collection: CollectionPreviewsFragment
  onCompleted?: () => void
}

export const useEditCollectionDescription = ({
  collection,
  onCompleted,
}: UseEditCollectionDescriptionProps) => {
  const [mutation] = useEditCollectionDescriptionMutation({
    optimisticResponse: ({ description = null }) => ({
      __typename: 'RootMutationType',
      editCollectionDescription: {
        __typename: 'EditCollectionDescriptionResponse',
        collection: {
          ...collection,
          description,
        },
      },
    }),
    onError: 'show-toast',
    onCompleted,
  })

  return mutation
}
