import { ErrorHandler } from '@sketch/tracing'

import {
  ProjectChangedDocument,
  ProjectChangedSubscription,
  ProjectFragment,
  ProjectMembershipFragment,
  useProjectChangedSubscription,
} from '@sketch/gql-types'

import {
  getCachedProject,
  handleProjectDeletedPermanently,
  handleProjectDeleted,
  handleProjectRestored,
} from './utils'

interface ProjectResourceEvent {
  previousProject?: ProjectFragment
  workspaceIdentifier: string
  project: ProjectFragment
}

interface ProjectWorkspaceAccessLevelChangedEvent {
  userMembership?: ProjectMembershipFragment | null
  workspaceIdentifier: string
  projectIdentifier: string
}

interface ProjectDeletedPermanentlyResourceEvent {
  previousProject?: ProjectFragment
  projectIdentifier: string
  workspaceIdentifier: string
}

interface ProjectCreatedResourceEvent {
  projectIdentifier: string
  workspaceIdentifier: string
}

interface EventListeners {
  onCreated?: (resource: ProjectCreatedResourceEvent) => void
  onChanged?: (resource: ProjectResourceEvent) => void
  onDeleted?: (resource: ProjectResourceEvent) => void
  onRestored?: (resource: ProjectResourceEvent) => void
  onDeletedPermanently?: (
    resource: ProjectDeletedPermanentlyResourceEvent
  ) => void
  onWorkspaceAccessLevelChanged?: (
    resource: ProjectWorkspaceAccessLevelChangedEvent
  ) => void
  onArchive?: (resource: ProjectResourceEvent) => void
  onUnArchive?: (resource: ProjectResourceEvent) => void
}

const useProjectSubscriptions = (eventListeners?: EventListeners) => {
  const {
    onChanged,
    onCreated,
    onDeleted,
    onDeletedPermanently,
    onRestored,
    onWorkspaceAccessLevelChanged,
    onArchive,
    onUnArchive,
  } = eventListeners || {}

  useProjectChangedSubscription({
    onSubscriptionData: ({ client: cache, subscriptionData }) => {
      const payload = subscriptionData?.data?.projectChanged

      // Report if the subscription doesn't have payload
      if (!payload) {
        const jsonData = JSON.stringify(subscriptionData)
        ErrorHandler.shouldNeverHappen(
          `missing useProjectChanged payload, ${jsonData}`
        )

        return
      }

      if (
        payload.__typename === 'ProjectMemberAddedEvent' ||
        payload.__typename === 'ProjectMemberChangedEvent' ||
        payload.__typename === 'ProjectMemberRemovedEvent'
      ) {
        return
      }

      const { workspaceIdentifier } = payload

      if (payload.__typename === 'ProjectDeletedEvent') {
        handleProjectDeleted({
          cache,
          identifier: payload.project.identifier,
          workspaceIdentifier,
          projectData: payload.project,
        })
      } else if (payload.__typename === 'ProjectPermanentlyDeletedEvent') {
        handleProjectDeletedPermanently({
          cache,
          identifier: payload.projectIdentifier,
        })
      } else if (payload.__typename === 'ProjectRestoredEvent') {
        handleProjectRestored({
          cache,
          identifier: payload.project.identifier,
          workspaceIdentifier,
          archived: !!payload.project.archivedAt,
        })
      }

      if (payload.__typename === 'ProjectCreatedEvent') {
        onCreated?.({
          projectIdentifier: payload.project.identifier,
          workspaceIdentifier,
        })
      } else if (payload.__typename === 'ProjectArchivedEvent') {
        const { workspaceIdentifier, project } = payload
        onArchive?.({ project, workspaceIdentifier })
      } else if (payload.__typename === 'ProjectUnarchivedEvent') {
        const { workspaceIdentifier, project } = payload
        onUnArchive?.({ project, workspaceIdentifier })
      } else if (payload.__typename === 'ProjectPermanentlyDeletedEvent') {
        const previousProject = getCachedProject({
          cache,
          identifier: payload.projectIdentifier,
        })

        onDeletedPermanently?.({
          previousProject,
          projectIdentifier: payload.projectIdentifier,
          workspaceIdentifier,
        })
      } else if (
        payload.__typename === 'ProjectChangedEvent' ||
        payload.__typename === 'ProjectDeletedEvent' ||
        payload.__typename === 'ProjectRestoredEvent'
      ) {
        const event: Record<
          string,
          ((resource: ProjectResourceEvent) => void) | undefined
        > = {
          ProjectDeletedEvent: onDeleted,
          ProjectRestoredEvent: onRestored,
          ProjectChangedEvent: onChanged,
        }

        const eventAction = event[payload.__typename]
        const subscriptionPayload = payload
        const { identifier } = payload.project

        const previousProject = getCachedProject({
          cache,
          identifier,
        })

        eventAction?.({
          previousProject,
          workspaceIdentifier,
          project: subscriptionPayload.project,
        })
      }

      if (payload.__typename === 'ProjectWorkspaceAccessLevelChangedEvent') {
        const { workspaceIdentifier, project } = payload
        const { userMembership, identifier: projectIdentifier } = project

        onWorkspaceAccessLevelChanged?.({
          workspaceIdentifier,
          userMembership: userMembership as ProjectMembershipFragment,
          projectIdentifier,
        })
      }

      // Write to the cache the share subscription updates
      cache.writeQuery<ProjectChangedSubscription>({
        query: ProjectChangedDocument,
        data: subscriptionData.data!,
        variables: {},
      })
    },
    /**
     * The "no-cache" fetchPolicy was set to prevent the share update from being updated
     * this way we can read it from the cache, and then update it
     */
    fetchPolicy: 'no-cache',
  })
}

export default useProjectSubscriptions
