import React from 'react'

// Images
import ImageSrc from '@sketch/icons/create-profile-modal.png'
import RetinaImageSrc from '@sketch/icons/create-profile-modal@2x.png'

import { ModalInjectedProps, ConfirmationDialog } from '@sketch/components'

import { Image } from './SignInForLoveModal.styles'

interface SignInForLoveModalProps extends ModalInjectedProps {
  onSignIn?: () => void
}

export const SignInForLoveModal = ({
  hideModal,
  onSignIn,
}: SignInForLoveModalProps) => {
  const title = 'Sign in to Show Your Love'

  return (
    <ConfirmationDialog
      hideModal={hideModal}
      title={title}
      onConfirm={() => {
        onSignIn?.()
      }}
      hero={
        <Image alt={title} src={ImageSrc} srcSet={`${RetinaImageSrc} 2x`} />
      }
      confirmButton={{
        text: 'Sign In',
        variant: 'primary',
      }}
      cancelButton={{
        text: 'Cancel',
      }}
    >
      <p>Found a design that makes your heart flutter?</p>
      <p>
        Sign in to join the Community Canvas, where you can remix and show your
        appreciation for other Members&apos; work — and publish your own
      </p>
    </ConfirmationDialog>
  )
}
