import React from 'react'

import {
  PublicWorkspaceFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'
import { isPersonalWorkspace } from '../../../workspace/utils'
import {
  StyledBadge,
  StyledWorkspaceIcon,
  StyledHamburgerIcon,
} from './HeaderWorkspaceBadge.styles'
import { useForTablet, ButtonUnstyled } from '@sketch/components'

export interface HeaderWorkspaceBadgeProps {
  workspace: PublicWorkspaceFragment | WorkspaceMinimalFragment
  onBadgeClick?: () => void
  onKeyDown?: (e: React.KeyboardEvent) => void
}

const HeaderWorkspaceBadge: React.FC<HeaderWorkspaceBadgeProps> = ({
  workspace,
  onBadgeClick,
  onKeyDown,
}) => {
  const isTabletAndBigger = useForTablet()
  const isSmallerThanTablet = !isTabletAndBigger

  if (isSmallerThanTablet) {
    return <StyledHamburgerIcon onClick={onBadgeClick}></StyledHamburgerIcon>
  }

  return isPersonalWorkspace(workspace) ? (
    <ButtonUnstyled
      onClick={onBadgeClick}
      onKeyDown={onKeyDown}
      aria-haspopup="menu"
    >
      <StyledWorkspaceIcon icon="briefcase" size="24px" />
    </ButtonUnstyled>
  ) : (
    <ButtonUnstyled
      onClick={onBadgeClick}
      onKeyDown={onKeyDown}
      aria-haspopup="menu"
    >
      <StyledBadge
        workspaceName={workspace.name}
        src={workspace.avatar?.large}
        size="24px"
      />
    </ButtonUnstyled>
  )
}

export default HeaderWorkspaceBadge
