import styled from 'styled-components'

import { ReactComponent as ChevronVertical } from '@sketch/icons/chevron-vertical-16'

export const DropdownChevron = styled(ChevronVertical).attrs({
  role: 'img',
})`
  width: 16px;
  height: 16px;
`
