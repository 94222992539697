import React, { FC } from 'react'
import { FormikProps } from 'formik'

import { Button, Avatar, Modal } from '@sketch/components'

import {
  PartnerInfoContainer,
  PartnerNameContainer,
} from './SendInvitationForm.styles'

import { FormValues } from './types'

interface SendInvitationFormProps {
  name: string
  email: string
  avatar: string
  loading: boolean
  hideModal: () => void
  formikbag: FormikProps<FormValues>
}

const SendInvitationForm: FC<SendInvitationFormProps> = ({
  name,
  email,
  avatar,
  loading,
  hideModal,
  formikbag,
}) => {
  return (
    <>
      <Modal.Body>
        <p>
          Keep in mind that if you invite {name} to your Workspace as a Sketch
          Partner, they will manage billing and Editor Seats on your behalf.
        </p>
        <p>All scheduled seat changes will be canceled.</p>

        <PartnerInfoContainer>
          <Avatar name={name} src={avatar} />
          <PartnerNameContainer>
            <strong>{name}</strong>
            <small>{email}</small>
          </PartnerNameContainer>
        </PartnerInfoContainer>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          disabled={loading}
          onClick={() => {
            hideModal()
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          loading={loading}
          disabled={loading || Boolean(formikbag.errors.email)}
          onClick={formikbag.submitForm}
        >
          Send Invitation
        </Button>
      </Modal.Footer>
    </>
  )
}

export default SendInvitationForm
