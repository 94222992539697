import { toIdValue } from 'apollo-utilities'
import { dataIdFromObject } from './dataIdFromObject'

export const cacheRedirects = {
  Query: {
    /**
     * Whenever a share is queried this'll let Apollo know where
     * to first check the cache if it's already present
     */
    share: (_, args) =>
      toIdValue(
        dataIdFromObject({
          __typename: 'Share',
          identifier: args.identifier || args.shortId,
        })
      ),
    artboard: (_, { permanentArtboardShortId, documentVersionShortId }) =>
      toIdValue(
        dataIdFromObject({
          __typename: 'Artboard',
          permanentArtboardShortId,
          documentVersionShortId,
        })
      ),
    plan: (_, args) =>
      toIdValue(dataIdFromObject({ __typename: 'Plan', name: args.name })),

    workspace: (_, args) =>
      toIdValue(
        dataIdFromObject({
          __typename: 'Workspace',
          identifier: args.identifier,
        })
      ),
    annotation: (_, args) =>
      toIdValue(
        dataIdFromObject({
          __typename: 'Annotation',
          identifier: args.identifier,
        })
      ),
    comment: (_, args) =>
      toIdValue(
        dataIdFromObject({
          __typename: 'Comment',
          identifier: args.identifier,
        })
      ),
    project: (_, args) =>
      toIdValue(
        dataIdFromObject({
          __typename: 'Project',
          identifier: args.identifier,
        })
      ),
  },
  Share: {
    version: (_, args) =>
      args &&
      args.shortId &&
      toIdValue(
        dataIdFromObject({
          __typename: 'Version',
          shortId: args.shortId,
        })
      ),
  },
  Document: {
    /**
     * Whenever the artboard property of a document gets queried
     * this'll let Apollo know where to get it from the cache
     */
    artboard: (_, args) => {
      return toIdValue(
        dataIdFromObject({
          __typename: 'Artboard',
          shortId: args.id,
        })
      )
    },
  },
}
