import React from 'react'
import { Link } from 'react-router-dom'
import { LocationDescriptor } from 'history'

import { PublicationItemFragment } from '@sketch/gql-types'
import { Text } from '@sketch/components'

import { useFlag, useTrackEvent } from '@sketch/modules-common'

import {
  Container,
  HeartButton,
  Name,
  PublicationThumbnail,
  TitleWrapper,
} from './GridPublicationItem.styles'

export interface GridPublicationItemProps {
  className?: string
  publication: PublicationItemFragment
  url?: LocationDescriptor
  showProfile?: boolean
}

const GridPublicationItem = (props: GridPublicationItemProps) => {
  const { className, publication, url, showProfile } = props

  const isHeartButtonAvailable = useFlag('publication-hearts')

  const publicationName = publication.share.name
  const workspaceName = publication.workspaceProfile?.workspace.name

  // Analytics
  const { trackEvent } = useTrackEvent()

  const handleClick = () => {
    trackEvent('COMMUNITY - Publication', {
      type: 'Clicks - Publication Grid Item',
      target: publication.share.name,
    })
  }

  const content = (
    <>
      <PublicationThumbnail publication={publication} />
      <TitleWrapper>
        <Name>{publicationName}</Name>
        {isHeartButtonAvailable && (
          <HeartButton
            publication={publication}
            flavour="list"
            trackHeartCount
          />
        )}
      </TitleWrapper>
      {showProfile && (
        <Text
          textStyle="copy.quaternary.standard.C"
          as="span"
          overflow="ellipsis"
        >
          {workspaceName}
        </Text>
      )}
    </>
  )

  if (url) {
    return (
      <li className={className}>
        <Link to={url} onClick={handleClick}>
          <Container title={publicationName}>{content}</Container>
        </Link>
      </li>
    )
  }

  return (
    <Container className={className} title={publicationName}>
      {content}
    </Container>
  )
}

export default GridPublicationItem
