import styled from 'styled-components'

import { ReactComponent as Check16 } from '@sketch/icons/checkmark-16'

export const CommentDateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

export const ResolvedContainer = styled.div`
  height: 18px;
`

export const ResolvedIcon = styled(Check16)`
  color: ${({ theme }) => theme.colors.state.positive.A};
`
