import { useMapRelativePositionToCameraPosition } from '@sketch-hq/sketch-web-renderer'
import { useInspectorContext } from '../../hooks/inspector'
import { SketchElement } from '../../../../inspector'
import { useMemo } from 'react'

/**
 * Take as an input a position relative to the canvas html element (where the right click happened)
 * and returns the tree of elements containing the canvas point behind the right click.
 * Note that the point can overlap with elements from different branches of the tree.
 */
export function useSubSketchElementsTreeAtRelativePosition(
  relativePosition: { x: number; y: number } | null
) {
  const { sketchSceneRootElement } = useInspectorContext()
  const mapRelativePositionToCameraPosition = useMapRelativePositionToCameraPosition()

  return useMemo(() => {
    if (!sketchSceneRootElement || !relativePosition) {
      return null
    }
    const cameraPosition = mapRelativePositionToCameraPosition(
      relativePosition.x,
      relativePosition.y
    )
    if (!cameraPosition) {
      return null
    }
    return getSketchElementsTreeContainingPoint(
      cameraPosition,
      sketchSceneRootElement
    )
  }, [
    sketchSceneRootElement,
    mapRelativePositionToCameraPosition,
    relativePosition,
  ])
}

/**
 * Create a new tree with only the layers that contain the point.
 */
function getSketchElementsTreeContainingPoint(
  point: { x: number; y: number },
  sketchSceneRootElement: SketchElement
): SketchElement {
  /**
   * Function called recursively to get the list of sketch elements that contain the point and that should be used as children of
   * the parent element. The general idea is that we go through the tree elements and create a new tree
   * of elements, only keeping elements for which the bounds of the element contain the point.
   *
   * When discarding/skipping a element we make sure to attach it's potential descendants elements to the parent.
   * Note: If we were not skipping any elements, the function would always return a list of one element but when skipping
   * a element, we may replace it with a list of descendent elements instead of the element itself.
   */
  function getElementsListToUseAsChildren(
    /**
     * This is the element is used as a part of the original tree
     * we may want to keep it or not in the elements tree.
     */
    currentElement: SketchElement
  ): SketchElement[] {
    const shouldKeepElement = currentElement.prMarinaNode
      .getAbsoluteBounds()
      .contains(point)

    const elementsListToUseAsChildren = currentElement.children.reduce(
      (acc: SketchElement[], child) => {
        const layersListForChild = getElementsListToUseAsChildren(child)

        acc.push(...layersListForChild)

        return acc
      },
      []
    )

    if (!shouldKeepElement) {
      // We are skipping the element that was supposed to be used as a child but
      // we want to keep the potential sub elements and attach
      // them to the parent instead of the original element.
      return elementsListToUseAsChildren
    }

    return [
      // Make sure to create a copy of the element before modifying the children
      {
        ...currentElement,
        children: elementsListToUseAsChildren,
      },
    ]
  }

  const rootElement = getElementsListToUseAsChildren(sketchSceneRootElement)[0]

  return rootElement ?? null
}
