import { ApolloLink } from 'apollo-link'

/**
 * createAbortRequestController
 *
 * This link has 2 main purposes:
 * - Supply a signal to all the fetch calls
 * - Allow the abort controller to be reset
 *
 * This link was created with the objective of blocking further requests
 * from continuing when the user is going to be logged out.
 *
 * The main purpose is for users that receive a FORBIDDEN resulting on a forced sign-out
 * flow to prevent the UI from making additional requests planned to show the initial planned views
 */
export const createAbortRequestController = () => {
  let controller = new AbortController()

  const reset = () => {
    controller = new AbortController()
  }

  const link = new ApolloLink((operation, forward) => {
    operation.setContext(() => ({
      /**
       * Allow the controller to be accessed to .abort()
       * https://github.com/sketch-hq/cloud-frontend/blob/e0373f3749cceb3affb3208ecb477b82e86adf49/packages/cloud-frontend/src/graphql/link/onError.ts#L63
       */
      abortRequestController: controller,

      /* Supply the signal to the window.fetcher */
      fetchOptions: { signal: controller.signal },
    }))

    return forward(operation)
  })

  return { reset, link }
}
