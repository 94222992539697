import { castError } from '@sketch/utils'
import React, { useEffect, useState } from 'react'
import {
  UsageMetricsLoadStatus,
  UsageMetricsStatus,
} from '../../usageMetrics/types'
import { GraphQLSchema, buildClientSchema } from 'graphql'
import { CostsRaw } from '../../usageMetrics/report/report.types'
import { readSettings } from '../../settings/settings.localStorage'
import { hookUsageMetrics } from '@sketch/graphql-apollo/useQuery'

const usageMetricsRef: { current: UsageMetricsStatus } = {
  current: { status: 'non-init' },
}

export const getUsageMetrics = (): UsageMetricsStatus => usageMetricsRef.current

// TODO: adapt this for vite build process
const getVendorsBundleName = async (): Promise<string> => {
  if (process.env.NODE_ENV === 'production') {
    // later on we are comparing `filename.includes(vendorsBundleName)` and using Vite together with splitVendorChunkPlugin
    // all vendors are bundled into a single file prefixed with 'vendor-'
    return 'vendor-'
  } else {
    return 'vendors~main.chunk.js'
  }
}

const getGraphQLSchema = async (): Promise<GraphQLSchema> => {
  const response = await fetch('/static/usage-metrics.schema.json', {
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
  })

  if (response.status < 300) {
    const introspectionQuery = (await response.json()).data
    const schema = buildClientSchema(introspectionQuery)
    return schema
  }
  throw new Error('could not load GraphQL Schema')
}

const getCostsJson = async (): Promise<CostsRaw> => {
  const response = await fetch('/static/usage-metrics.costs.json', {
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
  })

  if (response.status < 300) {
    const costsRaw = (await response.json()) as CostsRaw
    return costsRaw
  }

  throw new Error('could not load Backend Costs JSON')
}

const loadUsageMetrics = async (): Promise<UsageMetricsLoadStatus> => {
  // exclude this entire code block from the build bundle targeting staging or production
  if (
    process.env.REACT_APP_ENV === 'dev' ||
    process.env.REACT_APP_ENV === 'test'
  ) {
    // if usage metrics were already initialized, return it
    if (usageMetricsRef.current.status !== 'non-init')
      return usageMetricsRef.current

    try {
      const storedDevToolsState = readSettings()

      const isEnabled =
        storedDevToolsState.usageMetrics?.isTurnedOn &&
        storedDevToolsState.general?.isTurnedOn

      if (!isEnabled) {
        usageMetricsRef.current = { status: 'not-enabled' }
        return usageMetricsRef.current
      }

      const [
        usageMetricsModule,
        vendorsBundleName,
        graphqlSchema,
        costsRaw,
      ] = await Promise.all([
        import('../../usageMetrics'),
        getVendorsBundleName(),
        getGraphQLSchema(),
        getCostsJson(),
      ])

      const usageMetrics = usageMetricsModule.createUsageMetrics({
        vendorsBundleName,
        graphqlSchema,
        costsRaw,
      })

      usageMetricsRef.current = {
        status: 'loaded',
        usageMetrics,
      }

      hookUsageMetrics((result, query) => {
        result.data = usageMetrics.proxyData(result.data, query)
      })

      // @ts-expect-error add global variable for debugging
      window.__usageMetrics = usageMetrics
    } catch (e) {
      const err = castError(e)
      usageMetricsRef.current = { status: 'error', reason: err?.message }
      return usageMetricsRef.current
    }
  }

  return { status: 'not-enabled' }
}

// It would be better to convert this to a provider
// however, it would require to change other global variables
// such as cache and apolloClient to factory functions and use those
// functions in React components.
// TODO: Review this component together with https://github.com/sketch-hq/Cloud/issues/8812
export const UsageMetricsImport: React.FC = ({ children }) => {
  const [metricsState, setMetricsState] = useState(usageMetricsRef.current)
  useEffect(() => {
    loadUsageMetrics().then(x => {
      setMetricsState(x)
    })
  }, [])

  if (
    process.env.REACT_APP_ENV === 'dev' ||
    process.env.REACT_APP_ENV === 'test'
  ) {
    if (metricsState.status === 'non-init') {
      return null
    }
    return <>{children}</>
  }

  return <>{children}</>
}
