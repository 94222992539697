import { getNetworkRequestHeaders } from '@sketch/modules-common'

import { LocalResolver, LocalResolvers } from '@sketch/gql-types/resolvers'
import type {
  PublicationListingFragment,
  CommunitySectionFragment,
} from '@sketch/gql-types'

import { normalizeListingData, visitItems } from './utils'

type ListingData = Record<string, PublicationListingFragment>

const HEADERS = {
  ...getNetworkRequestHeaders(),
  'Content-Type': 'application/json',
}

const getSections = async () => {
  const response = await fetch('/data/community.json', {
    headers: HEADERS,
  })

  if (response.status === 200) {
    const responseJson = await response.json()

    return visitItems(responseJson.sections) as CommunitySectionFragment[]
  }

  throw new Error('Something went wrong')
}

/**
 * Cache and Serve the community listings
 */
let listingData: ListingData | null = null
export const getListingData = async () => {
  if (listingData) {
    return Promise.resolve(listingData)
  }

  const response = await fetch('/data/community-listings.json', {
    headers: HEADERS,
  })

  if (response.status === 200) {
    listingData = await response.json()

    return listingData
  }

  throw new Error('Something went wrong')
}

/**
 * createLocalResolvers
 *
 * This method will enhance the apollo resolvers (passed as argument)
 * with local Community related queries that are fetched from local json files
 * available on "@sketch/build-app/public/data"
 *
 * The graphql schema definition for this resolver is available on "@sketch/gql-types/graphql/community/local-schema.gql"
 */
export const createCommunityLocalResolvers = (resolvers: LocalResolvers) => {
  const enhancedResolvers = { ...resolvers }

  /** These are @client data. This avoids the BE request */
  const community = async () => ({
    __typename: 'Community' as const,
    sections: await getSections(),
  })

  const publicationListing: LocalResolver<'Query'>['publicationListing'] = async (
    _,
    { identifier }
  ) => {
    /**
     * In order to fake a API similar to a Graphql one getting
     * the lists by identifier it was opted to load the community
     * listings all at once and then return to apollo on the listing requested
     *
     * Consequent listings will already have the values in cache, so no network
     * will occur
     */
    const listingsData = await getListingData()
    const listing = listingsData?.[identifier]

    if (!listing) {
      throw new Error('Something went wrong')
    }

    return normalizeListingData(listing)
  }

  enhancedResolvers.Query = {
    ...enhancedResolvers.Query,

    community,
    publicationListing,
  }

  return enhancedResolvers as LocalResolvers
}
