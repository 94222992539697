import React, { useCallback } from 'react'
import * as S from './EnterFullScreenButton.styles'
import { usePrototypeContext } from '../../hooks'
import { useQueryParams } from '@sketch/modules-common'

export function EnterFullScreenButton() {
  const {
    supportsFullscreen,
    isFullscreen,
    enterFullscreen,
  } = usePrototypeContext()
  const { fullscreen: routerFullScreen } = useQueryParams<'PROTOTYPE_PLAYER'>()
  const hasFullscreenQueryParam = routerFullScreen === 'true'

  const handleFullscreenButton = useCallback(() => {
    enterFullscreen()
  }, [enterFullscreen])

  // We show the enter fullscreen button only when the query param is present
  // and the browser supports the fullscreen mode
  if (!hasFullscreenQueryParam || !supportsFullscreen || isFullscreen) {
    return null
  }

  return (
    <S.EnterFullScreenButton
      aria-label="Enter fullscreen"
      variant="primary-untinted"
      size="40"
      onClick={handleFullscreenButton}
    >
      <S.ArrowsIcon width={24} />
    </S.EnterFullScreenButton>
  )
}
