import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import queryString from 'query-string'
import { Location } from 'history'

import {
  useQueryParams,
  RouteProps,
  routes,
  IndexLayoutExtraProps,
  IndexLayoutContent,
  useAnalytics,
  IndexLayoutHeaderLink,
} from '@sketch/modules-common'

import { SketchLoginDeprecationModal } from '@sketch/user'
import { DocumentHead, useModalContext } from '@sketch/components'

import { isPricingSourceRoute } from '../../../utils'

import { BaseSignInView } from './BaseSignInView'
import CampaignSignInView from './CampaignSignInView'
import { SignInMutation } from '@sketch/gql-types'

interface LocationState {
  from?: Location
  // In some cases, we need to force the users to login with email and password
  // for their SSO account, for example, when accepting a workspace invitation
  hideSSO?: boolean
}

interface SignInViewProps extends RouteProps<'SIGN_IN'>, IndexLayoutExtraProps {
  location: Location<LocationState>
}

const SignInView: React.FC<SignInViewProps> = props => {
  const { HeaderPortal, history, location, useOverrideLayoutProps } = props
  const { trackSimpleAnalytics, removeSimpleAnalytics } = useAnalytics()

  const query = useQueryParams<'SIGN_IN'>()
  const { sketch } = query

  const { state } = location

  useEffect(() => {
    trackSimpleAnalytics()
    return () => removeSimpleAnalytics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { email }: { email?: string } = queryString.parse(
    state?.from?.search || ''
  )

  const { showModal } = useModalContext()

  const willContinueToWorkspaceCreation =
    state?.from?.pathname === routes.WORKSPACE_CREATE.create({})

  const handleOnCompleted = (data: SignInMutation) => {
    // MFA auth
    if (data.signIn.__typename === 'MFACredentials') {
      history.push(routes.VERIFICATION_CODE.create({ query }), {
        mfaToken: data.signIn.mfaToken,
        from: state?.from,
      })
      return
    }

    if (
      isPricingSourceRoute(location) &&
      data.signIn.__typename === 'SignInCredentials' &&
      data.signIn.createdWorkspace
    ) {
      history.push(
        routes.WORKSPACE_SUBSCRIBE.create({
          workspaceId: data.signIn.createdWorkspace,
        })
      )
      return
    }

    // U+P auth
    if (sketch === 'true') {
      showModal(SketchLoginDeprecationModal)
    }

    // Partner invitation URL contains the "partner" parameter. This
    // allow us to go directly to the subscribe page without passing
    // on AcceptWorkspaceInviteView and be redirected from there.
    const destinationRouteParams = new URLSearchParams(state?.from?.search)
    const isPartner = destinationRouteParams.get('partner')
    const token = destinationRouteParams.get('token')
    if (isPartner && token) {
      history.push(
        routes.WORKSPACE_SUBSCRIBE_PARTNER.create({
          query: {
            token,
          },
        })
      )
      return
    }

    if (willContinueToWorkspaceCreation) {
      history.push(routes.WORKSPACE_CREATE.create({}), {
        fromSignIn: true,
      })
    } else {
      history.push(state?.from || routes.ENTRY.create({}))
    }
  }

  const signInProps = {
    onCompleted: handleOnCompleted,
    email: email,
    useOverrideLayoutProps,
    HeaderPortal,
  }

  return (
    <IndexLayoutContent center="horizontal" marginTop paddingHorizontal>
      <HeaderPortal>
        <IndexLayoutHeaderLink />
      </HeaderPortal>
      <Helmet>
        <link rel="canonical" href="https://www.sketch.com/signin/" />
        <meta property="og:url" content="https://www.sketch.com/signin/" />
      </Helmet>
      <DocumentHead title="Sign in - It's great to see you again" />
      {isPricingSourceRoute(location) ? (
        <CampaignSignInView {...signInProps} />
      ) : (
        <BaseSignInView {...signInProps} {...props} />
      )}
    </IndexLayoutContent>
  )
}

export default SignInView
