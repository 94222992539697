import { useMemo } from 'react'
import { prepareGroupURL } from '../../ComponentsWebView/utils'

import {
  useShareViewNavigationNodeLink,
  useShareViewsNavigation,
} from '../../utils/shareViewsNavigation'

export function useBackButton() {
  const { previousView } = useShareViewsNavigation()
  const backLink = useShareViewNavigationNodeLink(previousView)

  return useMemo(() => {
    // As we have defaulted the canvas view and we don't have
    // the artboard list anymore, it doesn't make sense to
    // redirect to the canvas over and over again.
    // We will use it to hide the back button
    if (!previousView) {
      return {
        content: '',
        link: null,
      }
    }

    if (previousView.view === 'canvas') {
      return {
        content: 'Back to Canvas',
        link: backLink,
      }
    }

    if (previousView.view === 'shareprototypes') {
      return {
        content: 'Back to Prototypes',
        link: backLink,
      }
    }

    if (previousView.view === 'prototype') {
      return {
        content: 'Back to Prototype',
        link: backLink,
      }
    }

    if (previousView.view === 'page') {
      return {
        content: 'Back to Artboards',
        link: backLink,
      }
    }

    if (previousView.view === 'symbols') {
      let groupURL = null

      if (previousView.groupId) {
        groupURL = prepareGroupURL(previousView.groupId.split('/'))
      }

      return {
        content: 'Back to Symbols',
        link: groupURL ? `${backLink}?${groupURL}` : backLink,
      }
    }

    // If previousView === 'document' or if the state is empty (direct URL hit)
    // use this default back button.
    return {
      content: 'Back to Document',
      link: backLink,
    }
  }, [backLink, previousView])
}
