import React from 'react'
import Header, { Title } from '../Header'
import { AttributeList, Blend } from '../Attribute'
import copy from './copy'
import Section from '../Section'
import Separator from '../Separator'
import FullCopyAttribute from '../FullCopyAttribute'

type AppearanceProps = {
  opacity: number
  blendMode: string
}

const Appearance: React.FC<AppearanceProps> = ({ opacity, blendMode }) => {
  const opacityPercentage = `${(opacity * 100).toFixed().toLocaleString()}%`

  const sectionCopyValue = copy({
    opacityPercentage,
    blendMode,
  })

  return (
    <>
      <Separator />
      <Section data-testid="inspector-sidebar-appearance">
        <Header copyValue={sectionCopyValue}>
          <Title>Appearance</Title>
        </Header>
        <AttributeList>
          <FullCopyAttribute
            label="Opacity"
            value={
              <>
                {opacityPercentage}
                {blendMode.toLowerCase() !== 'normal' && (
                  <Blend blendMode={blendMode} />
                )}
              </>
            }
            copyValue={opacityPercentage}
          />
        </AttributeList>
      </Section>
    </>
  )
}

export default Appearance
