import React from 'react'

import { Button } from '@sketch/components'

import {
  Attribute,
  Attributes,
  Code,
  Field,
  Fields,
  Label,
  Value,
  Description,
  SubTitle,
} from './SetUpIdentityProvider.styles'

import { WorkspaceSsoConfigFragment } from '@sketch/gql-types'

interface SetUpIdentityProviderProps {
  config: WorkspaceSsoConfigFragment
}

/**
 * Renders the contents of the "Set Up Identity Provider" panel
 * on the Single Sign-On setup flow
 */
const SetUpIdentityProvider: React.FC<SetUpIdentityProviderProps> = ({
  config,
}) => {
  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value)
  }

  return (
    <div>
      <SubTitle>
        Configure Your Identity Provider (IdP) to Access Sketch
      </SubTitle>
      <Description>
        To configure Sketch to work with your IdP, copy the EntityID and ACS
        URL.
      </Description>
      <Fields>
        <Label>EntityID</Label>
        <Field>
          <Value>{config.serviceProviderEntityId}</Value>
          <Button
            variant="secondary"
            onClick={() => handleCopy(config.serviceProviderEntityId!)}
            size="40"
          >
            Copy
          </Button>
        </Field>
        <Label>ACS URL</Label>
        <Field>
          <Value>{config.acsUrl}</Value>
          <Button
            variant="secondary"
            onClick={() => handleCopy(config.acsUrl!)}
            size="40"
          >
            Copy
          </Button>
        </Field>
      </Fields>
      <SubTitle>Supported Basic Attributes</SubTitle>
      <Description>
        Make sure the following SAML attributes are mapped to the matching ones
        in your IdP.
      </Description>
      <Attributes>
        <Attribute>
          – <Code>email</Code>
        </Attribute>
        <Attribute>
          – <Code>first_name, surname</Code> (at least one of these two)
        </Attribute>
      </Attributes>
    </div>
  )
}

export default SetUpIdentityProvider
