import styled from 'styled-components'

export const AnnotationOptionsWrapper = styled.div`
  display: flex;
  background: inherit;
  z-index: 0; /* stylelint-disable-line scales/z-indices */
`

export const AnnotationOptionsBackground = styled.div`
  background: inherit;
  transition: opacity ${({ theme }) => theme.transitions.duration[1]};
  z-index: -1;

  ::before {
    background: ${({ theme }) => theme.colors.background.secondary.A};
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    content: '';
  }

  ::after {
    background: inherit;
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    content: '';
  }
`
