import React from 'react'

import { Text } from '@sketch/components'

export const YearlyToMonthlyContent = () => {
  return (
    <Text.Div mt={32}>
      Your workspace will change to a <strong>monthly subscription</strong> on
      the next billing date on <strong>8 June 2024</strong>. You&apos;ll be
      charged on the same date.
    </Text.Div>
  )
}
