import styled, { css } from 'styled-components'

import { Text, Markdown as BaseMarkdown } from '@sketch/components'

import Link from '../Link'

interface DummyRenderProps {
  children?: (className?: string) => React.ReactElement | null
  className?: string
}
const DummyRender = ({ children, className }: DummyRenderProps) =>
  children?.(className) || null

export const TextWrapper = styled.span`
  display: block;
  line-height: 1.462; /* stylelint-disable-line scales/line-height */
  margin-bottom: 3px; /* stylelint-disable-line scales/space */
`

export const AnnotationOptionsWrapper = styled(DummyRender)`
  position: absolute;
  top: 4px;
  right: 4px;

  opacity: 0;

  &[aria-expanded='true'],
  &:hover {
    opacity: 1;
  }
`

const showOptionsButton = css`
  ${AnnotationOptionsWrapper} {
    opacity: 1;
  }
`

export const Wrapper = styled.div`
  display: block;
  position: relative;

  padding: 12px;
  width: 100%;

  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  font-size: 0.8125rem; /* 13px */
  border-radius: 6px;

  background: none;
  transition: 0.1s background linear;

  /* Remove some "a" specific css */
  white-space: normal;

  :hover {
    background: ${({ theme }) => theme.colors.background.tertiary.C};
  }

  ${Link}.active && {
    /* If the annotation has a direct parent that is active should look active */
    background: ${({ theme }) => theme.colors.sketch.F};

    &[data-resolved='true'] {
      background: ${({ theme }) => theme.colors.state.positive.F};
    }
  }

  &:hover {
    /* Allow the options to be seen when the Wrapper is hovered */
    ${showOptionsButton}
  }

  ${/* sc-selector */ Link}:focus &&,
  ${/* sc-selector */ Link}:focus-within && {
    /* Allow the options to be seen when the link is focused */
    ${showOptionsButton}
  }
`

export const Details = styled(Text.Span).attrs({
  textStyle: 'copy.quaternary.standard.C',
})`
  display: block;

  user-select: none;
  /* Fine tuning
     Ticket: https://github.com/sketch-hq/cloud-frontend/pull/5377 */
  margin-top: -3px; /* stylelint-disable-line scales/space */
  margin-bottom: -1px; /* stylelint-disable-line scales/space */
`

export const CommentText = styled.span`
  &[data-highlight='true'] {
    color: ${({ theme }) => theme.colors.sketch.A};
  }

  & + & {
    margin-left: 8px;
  }
`

export const Markdown = styled(BaseMarkdown)`
  /* Enforce the inline style for the first line of the text */
  && > p:first-child {
    display: inline;

    :not(:last-child) {
      /* Prevent end of text to have extra space */
      ::after {
        content: '';
        display: block;
      }
    }
  }

  && a {
    /* Prevent link from growing in the sidebar and causing overflow */
    white-space: normal;
    word-break: break-all;
  }
`

export const PersonAvatarWrapper = styled.div`
  display: inline-block;
  margin-right: 8px;
  & span:first-child {
    top: 3px; /* stylelint-disable-line scales/space */
  }
`

export const AuthorNameWrapper = styled.span`
  display: inline-flex;
  align-items: baseline;
`

export const AuthorName = styled.span`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;

  display: inline-block;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  padding-right: 4px;

  /* Prevent names with emojies from making a new line */
  white-space: nowrap;

  &::after {
    content: ':';
  }
`
