import React, { useMemo, useContext } from 'react'
import { useShareViewsNavigationStack } from './useShareViewsNavigationStack'

import { ShareViewsNavigationContext } from './types'

export const shareViewsNavigationContext = React.createContext<
  ShareViewsNavigationContext | undefined
>(undefined)

type ShareViewsNavigationContextProviderProps = {
  children: React.ReactNode
}

/**
 * We need to be able to remember where the user comes from when
 * navigating between the different views of the Share module
 * (Document view, Page View, Canvas View, Artboard view, Component Web View...).
 * To do this we use this context provider component
 * to listen to route changes and update a stack accordingly.
 * The stack can then be accessed through this context to know
 * what was the previous view the user comes from.
 *
 * Note: Our navigation stack system does not work like a basic stack system like the one
 * of the history API. (See useShareViewsNavigationStack for more info on how our navigation
 * stack works.)
 */
export function ShareViewsNavigationContextProvider({
  children,
}: ShareViewsNavigationContextProviderProps) {
  const currentNavigationStack = useShareViewsNavigationStack()

  const contextValue = useMemo(() => {
    const currentView =
      currentNavigationStack[currentNavigationStack.length - 1]
    const previousView =
      currentNavigationStack[currentNavigationStack.length - 2]

    return {
      navigationStack: currentNavigationStack,
      currentView: currentView,
      previousView: previousView,
    }
  }, [currentNavigationStack])

  return (
    <shareViewsNavigationContext.Provider value={contextValue}>
      {children}
    </shareViewsNavigationContext.Provider>
  )
}

export function useShareViewsNavigation() {
  const contextValue = useContext(shareViewsNavigationContext)
  if (contextValue === undefined) {
    throw Error(
      'useShareViewsNavigation must be used inside ShareViewsNavigationContextProvider.'
    )
  }

  return contextValue
}
