import React from 'react'
import { useFlag } from '@sketch/modules-common'
import { CreateAnnotationMutation } from '@sketch/gql-types'

import AnnotationCommentBox from '../AnnotationCommentBox'
import AnnotationPopoverWrapper from '../AnnotationPopoverWrapper'

import useCreateAnnotation from '../../operations/useCreateAnnotation'
import { Coordinates, DraftAnnotationParameters } from '../../types'

import { useAnnotationOverlayContext } from '../../hooks'

interface DraftAnnotationPopoverProps {
  coordinates: Coordinates
  parameters: DraftAnnotationParameters
  isViewingLatestVersion: boolean
  onSubmit?: (data: CreateAnnotationMutation) => void
}

const DraftAnnotationPopover = (props: DraftAnnotationPopoverProps) => {
  const { coordinates, parameters, onSubmit, isViewingLatestVersion } = props
  const { shareIdentifier, subject: parameterSubject } = parameters

  const includeHasUnreadComments = useFlag('pages-unread-annotations')

  const {
    draftAnnotationComment,
    setDraftAnnotationComment,
    setAnnotationInFlight,
  } = useAnnotationOverlayContext() || {}

  const createAnnotation = useCreateAnnotation({
    onCacheUpdated: onSubmit,
    onComplete: onSubmit,
  })

  return (
    <AnnotationPopoverWrapper>
      <AnnotationCommentBox
        shareIdentifier={shareIdentifier}
        showOlderVersionWarning={!isViewingLatestVersion}
        onCommentChange={setDraftAnnotationComment}
        comment={draftAnnotationComment || undefined}
        onCommentSubmit={async body => {
          const baseVariables = {
            shareIdentifier,
            body,
            coordinates,
            includeHasUnreadComments,
          }

          setAnnotationInFlight?.(true)

          if (parameterSubject.type === 'ARTBOARD') {
            const { permanentPageId, ...subject } = parameterSubject
            await createAnnotation(
              { ...baseVariables, subject },
              { permanentPageId }
            )
          } else {
            const subject = parameterSubject
            await createAnnotation({ ...baseVariables, subject })
          }

          setAnnotationInFlight?.(false)
        }}
        autoFocus
      />
    </AnnotationPopoverWrapper>
  )
}

export default DraftAnnotationPopover
