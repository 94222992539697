import React from 'react'

import { PanelSeparator } from '../../components'
import { UpgradePersonalWorkspacePanel } from '../../components/UpgradePersonalWorkspacePanel'

interface PersonalWorkspaceSettingsPeopleProps {
  name: string
}

export const PersonalWorkspaceSettingsPeople = ({
  name,
}: PersonalWorkspaceSettingsPeopleProps) => {
  return (
    <PanelSeparator>
      <UpgradePersonalWorkspacePanel name={name} />
    </PanelSeparator>
  )
}
