import React, { useState, useMemo } from 'react'

import { useToast } from '@sketch/toasts'
import { routes, useFlag } from '@sketch/modules-common'

import {
  Text,
  ToastLink,
  RadioButton,
  ModalInjectedProps,
  SelectDropdown,
} from '@sketch/components'
import ShareAccessList from '../../../shares/components/ShareAccessList'
import { OtherWorkspacesMenu } from './OtherWorkspacesMenu/OtherWorkspacesMenu'
import { MoveShareToProjectModalLayout } from './MoveShareToProjectModalLayout'

import { ReactComponent as FolderClosed } from '@sketch/icons/folder-closed-16'
import { ReactComponent as ProtectedProject } from '@sketch/icons/lock-16'

import {
  Bold,
  WarningWrapper,
  HeaderItem,
  DangerRadioButton,
  MovingTextWrapper,
  StyledSeparator,
  DisabledWrapper,
  OtherWorkspaceItem,
  Item,
} from './MoveShareToProject.styles'

// GraphQL
import { useGetProjects } from '../../operations'
import { useMoveShareTo } from '../../../shares/operations/useMoveShareTo'
import {
  ProjectFragment,
  PublicWorkspaceFragment,
  ShareInfoFragment,
  TransferShareMutationVariables,
  useGetWorkspacesQuery,
  WorkspaceMinimalFragment,
  WorkspaceMinimalWithBillingStatusFragment,
} from '@sketch/gql-types'

import { isMemberWorkspace } from '../../../workspace/utils'
import { TransferDocumentInfo } from './types'

const normalizePublicWorkspace = (publicWorkspace: PublicWorkspaceFragment) => {
  if (publicWorkspace.type === 'PERSONAL' && publicWorkspace.userIsOwner) {
    return {
      ...publicWorkspace,
      userRole: 'ADMIN' as const,
      customer: null,
    }
  }

  return {
    ...publicWorkspace,
    userRole: 'MEMBER' as const,
    customer: null,
  }
}

const getFooterReactNode = (
  currentWorkspace: WorkspaceMinimalWithBillingStatusFragment,
  otherWorkspaces: WorkspaceMinimalWithBillingStatusFragment[],
  isTrialAbuseOn: boolean
) => {
  if (currentWorkspace.userRole !== 'ADMIN') {
    return null
  }

  if (
    isTrialAbuseOn &&
    currentWorkspace.customer?.billing?.status === 'TRIALING' &&
    !currentWorkspace.customer?.billing.subscriptionSetUp
  ) {
    return (
      <OtherWorkspaceItem
        text="Other Workspaces"
        additional={
          <DisabledWrapper>
            <Text textStyle="copy.quaternary.standard.C">
              You can’t move documents between Workspaces during trial.
            </Text>
          </DisabledWrapper>
        }
        disabled
      />
    )
  }

  if (otherWorkspaces.length > 0) {
    return <OtherWorkspacesMenu workspaces={otherWorkspaces} />
  }

  return null
}

type MembershipsTransferOperation = TransferShareMutationVariables['membershipsOperation']
export interface MoveShareToProjectProps extends ModalInjectedProps {
  share: ShareInfoFragment
  currentProject?: ProjectFragment | null
}

export const MoveShareToProject: React.FC<MoveShareToProjectProps> = props => {
  const { share, hideModal, currentProject } = props
  const { showToast } = useToast()

  // Remove this FF on
  // https://github.com/sketch-hq/Cloud/issues/19536
  const isTrialAbuseOn = useFlag('trial-abuse')

  const [membershipsOperation, setMembershipsOperation] = useState('')
  const [selectedProject, setSelectedState] = useState<
    TransferDocumentInfo | undefined
  >(undefined)

  const workspaceId = share.workspace.identifier

  const { moveShareToProject, moveShareToWorkspace, loading } = useMoveShareTo({
    onWorkspaceTransferCompleted: data => {
      const { share: updatedShare } = data.transferShare
      showToast(
        getWorkspaceMoveCompletedToast(
          updatedShare.project,
          selectedProject?.workspace!
        )
      )
      hideModal()
    },
    onProjectTransferCompleted: project => {
      showToast(
        <>
          Document moved to &ldquo;
          <ToastLink
            to={routes.WORKSPACE_PROJECT.create({
              projectId: project.identifier,
              workspaceId,
            })}
          >
            {project.name}
          </ToastLink>
          &rdquo;
        </>
      )
      hideModal()
    },
  })

  const { projects, loading: loadingProjects } = useGetProjects({ workspaceId })

  const { data, loading: loadingWorkspaces } = useGetWorkspacesQuery()
  const workspaces = data?.me?.workspaces || []

  /* Get the currentWorkspace and all the other ones to show in the list */
  const [currentWorkspace, otherWorkspaces] = workspaces.reduce<
    [
      null | WorkspaceMinimalWithBillingStatusFragment,
      WorkspaceMinimalWithBillingStatusFragment[]
    ]
  >(
    ([currentWorkspace, workspaces], workspace) => {
      if (share.workspace.identifier === workspace.identifier) {
        return [workspace, workspaces]
      }

      /* We only show other workspaces where the user is a member and has edit permissions */
      if (
        isMemberWorkspace(workspace) &&
        (workspace.userCanEdit || workspace.userRole === 'ADMIN')
      ) {
        return [currentWorkspace, [...workspaces, workspace]]
      }

      return [currentWorkspace, workspaces]
    },
    [null, []]
  )

  /**
   * Given we are now toggling the function to move to other workspaces
   * depending if the user is on a trial workspace or not. And because the share.workspace (Public Workspace)
   * doesn't have a field that indicates if the workspace that belongs the share is on trial or not.
   *
   * We are safeguarding the current workspace value in case the share workspace is not part of the list of workspace
   * (should never happen).
   *
   * If it does it indicates there is either:
   * - Cache problem
   * - We are showing the move project feature to people that are not members of the workspace
   *
   */
  const safeguardedCurrentWorkspace =
    currentWorkspace || normalizePublicWorkspace(share.workspace)

  const projectsList = useMemo(() => {
    const projectItems = projects?.entries || []

    return projectItems.filter(
      ({ identifier }) => identifier !== currentProject?.identifier
    )
  }, [projects, currentProject])

  const handleTransferMembershipsOptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMembershipsOperation(e.target.value)
  }

  const submit = () => {
    if (!selectedProject?.workspace) {
      moveShareToProject({
        shareId: share.identifier,
        projectId: selectedProject!.project.identifier,
      })
      return
    }

    moveShareToWorkspace({
      shareIdentifier: share.identifier,
      projectIdentifier: selectedProject.project.identifier || undefined,
      workspaceIdentifier: selectedProject.workspace.identifier || '',
      membershipsOperation: membershipsOperation as MembershipsTransferOperation,
    })
  }

  let placeholder = 'Select a project...'
  const hasNoProjects = projectsList.length + otherWorkspaces.length === 0

  if (loadingProjects || loadingWorkspaces) {
    placeholder = 'Loading projects...'
  } else if (hasNoProjects) {
    placeholder = 'No available projects'
  }

  const projectValue = selectedProject?.project as ProjectFragment

  return (
    <MoveShareToProjectModalLayout
      hideModal={hideModal}
      share={share}
      button={{
        disabled:
          !selectedProject ||
          (selectedProject.workspace && !membershipsOperation),
        loading,
        onClick: () => submit(),
      }}
    >
      <SelectDropdown
        placeholder={placeholder}
        disabled={loading || hasNoProjects}
        items={projectsList}
        value={selectedProject?.project}
        onChange={(project, path) => {
          const [
            label /* eslint-disable-line @typescript-eslint/no-unused-vars */,
            workspace,
          ] = path

          setSelectedState({ project, workspace })
        }}
        renderItem={(project, { active, setItem, a11yProps }) => (
          <Item
            active={active}
            text={project.name}
            icon={
              project.workspaceAccessLevel === 'NONE'
                ? ProtectedProject
                : FolderClosed
            }
            onClick={() => setItem()}
            {...a11yProps}
          />
        )}
        renderValueText={value => {
          if (selectedProject?.workspace) {
            return `${selectedProject.workspace.name} / ${selectedProject.project.name}`
          }

          return projectValue.name || value.name
        }}
        headerItem={
          <>
            <HeaderItem>{share.workspace.name}</HeaderItem>
            {projectsList.length === 0 && (
              <Item text="No projects available" disabled />
            )}
          </>
        }
        footerItem={
          safeguardedCurrentWorkspace.__typename === 'Workspace' &&
          getFooterReactNode(
            safeguardedCurrentWorkspace,
            otherWorkspaces,
            isTrialAbuseOn
          )
        }
      />
      {/* We only show this text when the destination workspace is different from the current one  */}
      {selectedProject?.workspace &&
        selectedProject.workspace.identifier !== share.workspace.identifier && (
          <MovingTextWrapper>
            <Text>
              The document and all its comments will be moved to{' '}
              <Bold>{selectedProject.workspace.name}</Bold>. Do you want to keep
              sharing the document with previously invited people?
            </Text>
            <RadioButton
              name="transfer-option"
              label="Yes, share it with the same people"
              checked={membershipsOperation === 'TRANSFER_NON_SSO'}
              value="TRANSFER_NON_SSO"
              onChange={handleTransferMembershipsOptionChange}
            />
            <DangerRadioButton
              name="transfer-option"
              label="No, remove previously invited people"
              checked={membershipsOperation === 'DELETE_ALL'}
              value="DELETE_ALL"
              onChange={handleTransferMembershipsOptionChange}
            />
            {safeguardedCurrentWorkspace?.customer?.ssoEnabled ? (
              <WarningWrapper>
                People with a Single Sign-On (SSO) account won’t be transferred.
              </WarningWrapper>
            ) : null}
          </MovingTextWrapper>
        )}
      {selectedProject && (
        <>
          <StyledSeparator />
          <ShareAccessList
            shareIdentifier={share.identifier}
            membershipCount={share.memberships?.meta.totalCount}
            destination={selectedProject}
            hideMembersList={membershipsOperation === 'DELETE_ALL'}
          />
        </>
      )}
    </MoveShareToProjectModalLayout>
  )
}

export function getWorkspaceMoveCompletedToast(
  project: ProjectFragment | null,
  workspace: Pick<WorkspaceMinimalFragment, 'identifier' | 'name'>
) {
  if (project) {
    return (
      <span>
        Document moved to &ldquo;
        <ToastLink
          to={routes.WORKSPACE_PROJECT.create({
            projectId: project.identifier,
            workspaceId: workspace.identifier,
          })}
        >
          {project.name}
        </ToastLink>
        &rdquo; in &ldquo;
        <ToastLink
          to={routes.WORKSPACE_SHARES.create({
            workspaceId: workspace.identifier,
          })}
        >
          {workspace.name}
        </ToastLink>
        &rdquo; Workspace
      </span>
    )
  }

  return (
    <span>
      Document moved to &ldquo;
      <ToastLink
        to={routes.WORKSPACE_SHARES.create({
          workspaceId: workspace.identifier,
        })}
      >
        {workspace.name}
      </ToastLink>
      &rdquo; Workspace
    </span>
  )
}
