import { useFlag } from '@sketch/modules-common'
import { useGetWorkspaceProfileIdentifierQuery } from '@sketch/gql-types'
import { useIsProfileEnabled } from '../useIsProfileEnabled'

export const useIsProfileCreated = (identifier: string) => {
  const isProfileFFEnabled = useFlag('profiles')
  const { isProfileEnabled } = useIsProfileEnabled(identifier)

  const {
    data: workspaceProfile,
    loading,
  } = useGetWorkspaceProfileIdentifierQuery({
    variables: { identifier },
    skip: !isProfileFFEnabled,
  })

  const isProfileCreated =
    isProfileEnabled && !!workspaceProfile?.workspace.profile?.identifier

  return { isProfileCreated, loading }
}
