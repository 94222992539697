import styled from 'styled-components'
import { Text, Link, FakeLinkButton } from '@sketch/components'
import { ReactComponent as Check } from '@sketch/icons/checkmark-circle-fill-16'

export const LinkButton = styled(FakeLinkButton)`
  font-size: ${({ theme }) => theme.fontSizes.C};
`

export const CheckIcon = styled(Check)`
  width: 16px;
  color: ${({ theme }) => theme.colors.state.positive.A};
  margin-right: 8px;
  margin-bottom: 2px; /* stylelint-disable-line scales/space */
`

export const Note = styled(Text.Div).attrs({
  textStyle: 'copy.tertiary.standard.C',
})`
  margin: 24px 0 0 0;
`

export const NotePhrase = styled(Text.Span)`
  margin-bottom: 8px;
  display: block;

  &:last-child {
    margin-bottom: 0;
  }
`

export const BoldLinkButton = styled(FakeLinkButton)`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`

export const StyledLink = styled(Link)`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`
