import React, { ReactNode } from 'react'

import { Button, Breakpoint } from '@sketch/components'

import {
  Row,
  LeftSide,
  Title,
  Description,
  Wrapper,
  Container,
} from './CreateWorkspaceExtraOption.styles'

interface CreateWorkspaceExtraClosedOptionProps {
  title: string
  description: string
  button: string
  onOptionSelected: () => void
}

const CreateWorkspaceExtraClosedOption = ({
  title,
  description,
  button,
  onOptionSelected,
}: CreateWorkspaceExtraClosedOptionProps) => {
  return (
    <Breakpoint on="sm">
      {matches =>
        matches ? (
          <Row>
            <LeftSide>
              <Title onClick={onOptionSelected}>{title}</Title>
              <Description>{description}</Description>
            </LeftSide>
            <Button
              size="40"
              variant="secondary-untinted"
              onClick={onOptionSelected}
            >
              {button}
            </Button>
          </Row>
        ) : (
          <Row>
            <Button
              size="40"
              fill
              variant="secondary-untinted"
              onClick={onOptionSelected}
            >
              {title}
            </Button>
            <Description>{description}</Description>
          </Row>
        )
      }
    </Breakpoint>
  )
}

export interface CreateWorkspaceExtraOptionProps {
  isOpen: boolean
  renderOpen: () => ReactNode
  renderClosed: () => ReactNode
}

const CreateWorkspaceExtraOptionWrapper = ({
  isOpen,
  renderOpen,
  renderClosed,
}: CreateWorkspaceExtraOptionProps) => {
  return (
    <Wrapper className={isOpen ? 'open' : 'closed'}>
      {isOpen && renderOpen ? renderOpen() : null}
      {!isOpen && renderClosed ? renderClosed() : null}
    </Wrapper>
  )
}

export const CreateWorkspaceExtraOption = Object.assign(
  CreateWorkspaceExtraOptionWrapper,
  {
    Closed: CreateWorkspaceExtraClosedOption,
    Container,
  }
)
