import { useContext } from 'react'
import { currentWorkspaceContext } from '../containers/PrivateWorkspaceRoute/currentWorkspaceContext'

/**
 * Get information about the current workspace the user is in.
 * The hook can needs to be called from a descendant of `<PrivateWorkspaceRoute />`.
 */
export function useCurrentWorkspaceInPrivateWorkspaceRoute() {
  const workspace = useContext(currentWorkspaceContext)

  if (!workspace) {
    throw new Error(
      'useCurrentWorkspaceInPrivateWorkspaceRoute() can only be used inside a <PrivateWorkspaceRoute />!'
    )
  }

  return workspace
}
