import styled, { css } from 'styled-components'

import { Text, ImageBaseComponent } from '@sketch/components'
import { Image as ImageUnstyled } from '@sketch/modules-common'

export const ArtboardImageWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    position: relative;

    width: 100%;
    aspect-ratio: 1;

    margin-bottom: 6px; /* stylelint-disable-line scales/space */

    border-radius: 10px; /* stylelint-disable-line scales/radii */
    overflow: hidden;

    box-shadow: ${theme.colors.thumbnails.shadow};
    border: 4px solid ${theme.colors.background.secondary.A};

    background-color: ${({ theme }) => theme.colors.background.tertiary.D};
  `
)

export const ArtboardTitle = styled(Text.H1).attrs({
  textStyle: 'copy.primary.standard.E',
})`
  margin: 0px;
  font-weight: 500;
  line-height: 1.429; /* stylelint-disable-line scales/line-height */

  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;

  /* Making sure that the title width fills the entire parent, otherwise if the title contains emojis,
  the browser might calculate the width incorrectly, and cut the right or left side of the text
  @see https://github.com/sketch-hq/Cloud/issues/10087 */
  flex: 1;

  /* Giving a bit of padding to the title, otherwise Safari might cut off the top and bottom sides
  The browser calculates the text boundaries incorrectly when it contains emojis */
  padding: 4px;
`

export const ArtboardWrapper = styled.section`
  :hover {
    ${ArtboardImageWrapper} {
      background-color: ${({ theme }) => theme.colors.background.tertiary.C};
    }
  }
`

export const Image = styled(ImageUnstyled)`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`

export const ImageComponent = styled(ImageBaseComponent)`
  max-width: 100%;
  max-height: 100%;

  width: auto;
  height: auto;
`

export const CommentCopy = styled.span<{ $hasNewComments?: boolean }>`
  display: block;

  font-size: 0.75rem; /* 12px */
  font-weight: 400;
  text-align: center;

  line-height: 1.333; /* stylelint-disable-line scales/line-height */

  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  ${({ $hasNewComments }) =>
    $hasNewComments &&
    css`
      color: ${({ theme }) => theme.colors.sketch.A};
    `}
`
