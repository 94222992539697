import { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { useStableHandler, shortcuts, keyWithoutModifier } from '@sketch/utils'
import { useGetAnnotationContext } from './useAnnotationOverlay'
import useHideAnnotationDots from './useHideAnnotationDots'
import { INSPECTOR_SEGMENT } from '../../shares/constants'

interface UseAnnotationShortcutsParams {
  disabled?: boolean
}

const useAnnotationShortcuts = ({ disabled }: UseAnnotationShortcutsParams) => {
  const annotationContext = useGetAnnotationContext()
  const [hideAnnotations, setHideAnnotations] = useHideAnnotationDots() || []

  const location = useLocation()
  const history = useHistory()

  const handleAnnotationsVisibility = useStableHandler(() => {
    // Close the Inspect panel when toggling the Annotations visibility
    if (location.hash.includes(INSPECTOR_SEGMENT)) {
      history.replace({
        ...location,
        hash: undefined,
      })

      setHideAnnotations?.(false)
    } else {
      setHideAnnotations?.(!hideAnnotations)
    }
  })

  const handlePlaceAnnotation = useStableHandler(() => {
    if (annotationContext.placeDraftAnnotation) {
      return
    }

    // If inspect mode is enabled, close it (it's incompatible with comments)
    if (location.hash.includes(INSPECTOR_SEGMENT)) {
      history.replace({
        ...location,
        hash: undefined,
        // Needed to avoid `useShareSidebarTab` to sync url with active tab
        // (via useEffect), this would cause the replacement of the url again
        // with #Inspect hash
        state: { toggleFloatingPanel: true },
      })
    }

    annotationContext.setPlaceDraftAnnotation?.(true)
  })

  const handleDismissAnnotationPopover = useStableHandler(() => {
    if (annotationContext.placeDraftAnnotation) {
      annotationContext.setPlaceDraftAnnotation?.(false)
    }
    if (annotationContext.activeAnnotation) {
      annotationContext?.setActiveAnnotation?.()
    }
  })

  useEffect(() => {
    if (annotationContext.status !== 'available' || disabled) {
      return
    }

    const handleAnnotationKeyEvents = (event: KeyboardEvent) => {
      const addCommentShortcut = keyWithoutModifier(shortcuts.addComment)(event)
      const commentsToggleShortcut = keyWithoutModifier(
        shortcuts.addComment.toUpperCase()
      )(event)

      if (event.shiftKey && commentsToggleShortcut) {
        /**
         * Allow the user to toggle the visibility of the annotations
         */
        handleAnnotationsVisibility()
      } else if (addCommentShortcut) {
        /**
         * Allow the user to place a draft annotation
         */
        handlePlaceAnnotation()
      } else if (event.key === 'Escape') {
        /*
         * Allow the user to dismiss the draft annotation
         * mode by key pressing "Escape"
         */
        handleDismissAnnotationPopover()
      }
    }

    window.addEventListener('keydown', handleAnnotationKeyEvents)

    return () => {
      window.removeEventListener('keydown', handleAnnotationKeyEvents)
    }
  }, [
    annotationContext.status,
    disabled,
    handleDismissAnnotationPopover,
    handleAnnotationsVisibility,
    handlePlaceAnnotation,
  ])
}

export default useAnnotationShortcuts
