import styled, { css } from 'styled-components'

import { SYSTEM_FONT } from '@sketch/global-styles'

import { ReactComponent as ChevronsUpDownIcon } from '@sketch/icons/chevrons-up-down-16'

const invalidStyles = css`
  border: 2px solid ${({ theme }) => theme.colors.state.negative.A};
`

export const NativeSelectWrapper = styled.div`
  position: relative;
`

export const StyledChevronsUpDownIcon = styled(ChevronsUpDownIcon)`
  width: 15px;
  height: 15px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  z-index: ${({ theme }) => theme.zIndex[1]};
`

export const selectBaseStyles = css<{ invalid?: boolean; arrowIcon?: any }>`
  position: relative;
  width: 100%;
  display: block;

  user-select: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;

  padding: 10px 0 10px 12px; /* stylelint-disable-line scales/space */
  font-size: ${({ theme }) => theme.fontSizes.E};
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  border-radius: 6px;
  border: none;
  background-color: ${({ theme }) => theme.colors.background.tertiary.C};

  &:disabled,
  &[aria-disabled='true'] {
    color: ${({ theme }) => theme.colors.state.disabled};
    border: 1px dashed ${({ theme }) => theme.colors.border.A};
    cursor: not-allowed;
    box-shadow: none;
  }

  ${({ invalid }) => invalid && `${invalidStyles}`};

  /* native select */
  &[data-invalid='true'],
  /* data-reach-listbox */
  [data-invalid='true'] & {
    ${invalidStyles};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.background.tertiary.C};

    ${({ invalid }) =>
      invalid &&
      css`
        ${invalidStyles};
      `};
  }
`

export const NativeSelect = styled.select`
  ${selectBaseStyles};

  /**
   * We are forcing the default system font only for Safari.
   * Safari uses a serif font when it doesn't find Marfa. By using an apple font, we ensure that dropdown
   * items are similar to the rest of the page.
   * See https://gist.github.com/jbutko/6718701#gistcomment-2630350
   */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      font-family: ${SYSTEM_FONT};
    }
  }
`
