import React from 'react'
import styled, { css } from 'styled-components'
import { ButtonUnstyled } from '../Button'

const DEFAULT_SIZE = 24

const DotWrapper = styled.div<{
  size: number
}>(
  ({ size }) => css`
    position: relative;
    width: ${size}px;
    height: ${size}px;
  `
)

const NewFeatureHighlightBase = css<{
  size: number
}>(
  ({ theme }) => css`
    position: absolute;
    border-radius: 50%;
    background-color: ${theme.colors.sketch.A};
  `
)

const DotGlow = styled.div<{
  size: number
}>(
  ({ size }) => css`
    ${NewFeatureHighlightBase};
    top: 0;
    left: 0;
    width: ${size}px;
    height: ${size}px;
    opacity: 15%;
  `
)

const DotCenter = styled.div<{
  size: number
}>(
  ({ size }) => css`
    ${NewFeatureHighlightBase};
    top: 25%;
    left: 25%;
    width: ${size / 2}px;
    height: ${size / 2}px;
  `
)

interface NewFeatureHighlightProps {
  onClick?: () => void
  size?: number
}

export const NewFeatureHighlight = ({
  onClick,
  size = DEFAULT_SIZE,
}: NewFeatureHighlightProps) => (
  <ButtonUnstyled onClick={onClick}>
    <DotWrapper size={size}>
      <DotGlow size={size} />
      <DotCenter size={size} />
    </DotWrapper>
  </ButtonUnstyled>
)
