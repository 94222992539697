import styled, { css } from 'styled-components'

import { LinkUnstyled, Text } from '@sketch/components'

import { breakpoint } from '@sketch/global-styles'

export const WorkspaceName = styled.span`
  text-align: center;
  margin: 12px 0 0 0;
`

export const Container = styled.div<{ $isSmall?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ $isSmall }) =>
    $isSmall &&
    css`
      align-items: center;
      flex-direction: row;

      ${breakpoint('base', 'sm')`
        flex-direction: column;
      `}

      ${WorkspaceName} {
        margin: 0 0 0 8px;

        ${breakpoint('base', 'sm')`
          margin: 6px 0 0 0;
        `}
      }
    `}
`

export const StyledLink = styled(LinkUnstyled)`
  display: inline-flex;
  align-self: flex-start;

  :hover {
    ${Text} {
      color: ${({ theme }) => theme.colors.sketch.A};
    }
  }

  ${breakpoint('base', 'sm')`
    align-self: center;
  `}
`

export const StyledTextH1 = styled(Text.H1)`
  margin: 0;
`
