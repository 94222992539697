import styled from 'styled-components'

export const PartnerInfoContainer = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.background.secondary.B};
  border-radius: ${({ theme }) => theme.radii.large};
  align-items: center;
  padding: 12px;
  margin-top: 24px;

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`

export const PartnerNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`
