import React from 'react'
import * as S from './PrototypeStatus.styles'
import { PrototypeLoadingPlaceholder } from '@sketch/components'

type PrototypeLoadStatusProps = {
  progress?: number
  text?: string
}

/**
 * Shows the loading animation. Text label and load progress can be customized.
 */
export function PrototypeLoadStatus(props: PrototypeLoadStatusProps) {
  return (
    <S.Container>
      <PrototypeLoadingPlaceholder {...props} />
    </S.Container>
  )
}
