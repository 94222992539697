import { buildGetElementTiming } from './buildGetElementTiming'
import { customElementsObserver } from './CustomElementsObserver'
import { ElementTimingHandler } from './types'

export const getCustomElementTiming = buildGetElementTiming(
  (handler: ElementTimingHandler) => {
    const cleanup = customElementsObserver.observe(entry => {
      handler(entry)
    })

    return { cleanup }
  },
  'CustomElementTiming'
)
