import * as Sentry from '@sentry/browser'

const getShortUrl = (urlString: string) => {
  const url = new URL(urlString)
  return `${url.origin}${url.pathname}`
}

type SocketLogType = 'open' | 'closed' | 'error'

const category = 'websocket'
const MAX_LOGS = 50

class SentrySocketLogger {
  counter = 0
  maxLogMessageAdded = false

  private log = (
    url: string,
    type: SocketLogType,
    level: Sentry.SeverityLevel = 'log'
  ) => {
    this.counter++
    if (this.counter > MAX_LOGS) {
      if (this.maxLogMessageAdded) {
        return
      }

      this.maxLogMessageAdded = true
      Sentry.addBreadcrumb({
        category,
        message: `Max logs reached (${MAX_LOGS}). We will not add any more breadcrumbs related to ${category}`,
        level,
      })
      return
    }

    Sentry.addBreadcrumb({
      category,
      message: `${type} - ${getShortUrl(url)}`,
      level,
    })
  }

  opened = (url: string) => {
    this.log(url, 'open')
  }
  closed = (url: string) => {
    this.log(url, 'closed')
  }
  error = (url: string) => {
    this.log(url, 'error', 'error')
  }
}

export const sentrySocketLogger = new SentrySocketLogger()
