import React from 'react'
import { Text } from '@sketch/components'
import { TrialMessageHeader, TrialMessageWrapper } from './TrialMessage.styles'

export const TrialMessage = () => {
  return (
    <TrialMessageWrapper>
      <TrialMessageHeader>
        <Text textStyle="copy.tertiary.standard.C">
          Includes a 30-day trial
        </Text>
      </TrialMessageHeader>
      <Text textStyle="copy.tertiary.standard.C">
        We will only charge your card at the end of your trial and you can
        cancel at any time.
      </Text>
    </TrialMessageWrapper>
  )
}
