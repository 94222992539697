import { useVersioning } from '../../versioning'
import { useGetPresentationAssetsByArtboardQuery } from '@sketch/gql-types'

interface UsePresentationFileProps {
  artboardUuid: string
}

/**
 * When we arrive on a prototype we just have the start Artboard UUID. However
 * presentation file assets for the whole doc are currently accessed via the
 * Page type. So we have to go via the Artboard's page to get the presentation
 * file info.
 */
export const usePresentationFile = ({
  artboardUuid,
}: UsePresentationFileProps) => {
  const context = useVersioning()
  const documentId = context.loading
    ? null
    : context?.currentVersion.document?.identifier ?? null

  const prFileQuery = useGetPresentationAssetsByArtboardQuery({
    variables: {
      artboardUuid: artboardUuid,
      documentId: documentId ?? '',
    },
    skip: context.loading,
  })

  const isRendering = context?.renderStatus !== 'ready'
  const page = prFileQuery.data?.artboardInDocumentWithUuid?.page
  const isLoading = prFileQuery.loading || context.loading
  const hasError = prFileQuery.error
  const hasNotFoundError = prFileQuery.error?.graphQLErrors.some(
    x => x.extensions?.code === 'NOT_FOUND'
  )

  return {
    page,
    isLoading,
    hasError,
    hasNotFoundError,
    isRendering,
  }
}
