export interface FormatPriceOptions {
  trimDecimalIfZero?: boolean
}

export const formatPrice = (
  value: number,
  { trimDecimalIfZero = true }: FormatPriceOptions = {}
) => {
  const fullPrice = new Intl.NumberFormat(['en'], {
    style: 'currency',
    currency: 'usd',
    minimumFractionDigits: 2,
  }).format(value)

  return trimDecimalIfZero ? fullPrice.replace(/\D00$/, '') : fullPrice
}

export const formatPriceShort = (
  value: string | number,
  options?: FormatPriceOptions
) => formatPrice(parseInt(value as string, 10) / 100, options)
