import React from 'react'

import { Dropdown, useModalContext } from '@sketch/components'

import { DeleteCommentModal } from '../../modals/DeleteCommentModal'
import { useStableHandler } from '@sketch/utils'

interface DeleteCommentOptionProps {
  annotationIdentifier: string
  commentIdentifier?: string
  isAnnotation?: boolean
  hasReplies?: boolean
}

const DeleteCommentOption = (props: DeleteCommentOptionProps) => {
  const {
    annotationIdentifier,
    commentIdentifier,
    isAnnotation,
    hasReplies,
  } = props
  const { showModal } = useModalContext()

  const handleShowModal = useStableHandler(() => {
    showModal(DeleteCommentModal, {
      annotationIdentifier,
      commentIdentifier: commentIdentifier || annotationIdentifier,
      isAnnotation,
      hasReplies,
    })
  })

  return (
    <Dropdown.Item intent="negative" onClick={handleShowModal}>
      Delete…
    </Dropdown.Item>
  )
}

export default DeleteCommentOption
