import { Filter as BaseFilter } from '@sketch/components'
import styled from 'styled-components'

const Search = styled(BaseFilter)`
  &&& {
    background-color: transparent;
  }

  [type='search'] {
    box-shadow: none;
    border: 0;
    background-color: transparent;
  }

  [role='img'] {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }
`

export default Search
