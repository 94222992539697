import React from 'react'
import {
  IndexLayoutContent,
  IndexLayoutExtraProps,
  useQueryParams,
  useSignOut,
} from '@sketch/modules-common'
import { Button, Caption, Panel } from '@sketch/components'
import styled from 'styled-components'

const ErrorMessageContainer = styled.pre`
  max-height: 144px;
  overflow: auto;

  margin-top: 16px;
  padding: 8px 24px;

  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background.secondary.B};
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  line-height: 1.4;
  font-size: 0.75rem;
`

const StyledPanel = styled(Panel)`
  max-width: 520px;
`

interface SsoSignInErrorViewProps extends IndexLayoutExtraProps {}

export const SsoSignInErrorView = (props: SsoSignInErrorViewProps) => {
  const signOut = useSignOut({
    revokeSessions: 'none',
    reason: 'SSO Error',
  })
  const { teamName, message } = useQueryParams<'SSO_ERROR'>()

  return (
    <IndexLayoutContent center>
      <StyledPanel>
        <Panel.Title>Configuration Error</Panel.Title>
        <Panel.Body>
          <Caption>
            We could not sign you into the <b>{teamName}</b> workspace. Please
            contact the Workspace Administrator to resolve this issue:
          </Caption>
          <ErrorMessageContainer>
            <p>{message}</p>
          </ErrorMessageContainer>
        </Panel.Body>
        <Panel.Footer>
          <Button variant="secondary" onClick={signOut}>
            Sign Out
          </Button>
        </Panel.Footer>
      </StyledPanel>
    </IndexLayoutContent>
  )
}
