import React, { useState } from 'react'

// Plan Types
import { BillingPlanFragment } from '@sketch/gql-types'

import {
  CardWrapper,
  Cents,
  Currency,
  PlanName,
  Price,
  Unit,
  Small,
  OuterCardWrapper,
  SmallText,
  StyledRadioButton,
} from './Card.styles'

import { Tooltip } from '@sketch/components'

const READABLE_PLAN_NAME = {
  ESSENTIAL: 'Essential',
  TEAMS: 'Standard',
  BUSINESS: 'Business',
  STUDENT: 'Student',
} as const

// Helper functions
const calculatePrice = (value: number, output?: 'CENTS' | 'UNITS') => {
  const price = (value / 100).toFixed(2)
  const [units, cents] = `${price}`.split('.')

  if (output === 'CENTS') {
    return cents === '00' ? '' : cents
  } else if (output === 'UNITS') {
    return units
  }

  return price.replace(/\D00$/, '')
}

interface CardProps {
  currency?: {
    code: string
    symbol: string
  }
  isDisabled: boolean
  isSelected: boolean
  showAvailableOnlyOnYearlyNotice: boolean
  showAvailableOnlyOnYearlyTooltip: boolean
  onChange: (clickedPlan: BillingPlanFragment) => void
  plan: BillingPlanFragment
}

/*
 * Card
 *
 * Renders a single plan card with its info (plan name,
 * price per editor and monthly / yearly price).
 *
 * Cards are loaded from Apollo cache if available otherwise
 * default values are used.
 *
 */

const Card: React.FC<CardProps> = ({
  isDisabled,
  isSelected,
  onChange,
  plan,
  currency = {
    code: 'USD',
    symbol: '$',
  },
  showAvailableOnlyOnYearlyNotice = false,
  showAvailableOnlyOnYearlyTooltip = false,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const handleChange = () => onChange(plan)

  const priceUnit = calculatePrice(plan.monthlyPrice, 'UNITS')
  const priceCents = calculatePrice(plan.monthlyPrice, 'CENTS')

  const name = READABLE_PLAN_NAME[plan.product] || plan.product

  const renderPlanPrice = ({ isSelected }: { isSelected: boolean }) => {
    return (
      <Price>
        <Currency isSelected={isSelected}>{currency.symbol}</Currency>
        <Unit isSelected={isSelected}>{priceUnit}</Unit>
        {priceCents && <Cents>{priceCents}</Cents>}
      </Price>
    )
  }

  return (
    <OuterCardWrapper onMouseLeave={() => setTooltipVisible(false)}>
      <Tooltip
        placement="right"
        content="Available on yearly billing only"
        visible={tooltipVisible}
      >
        <CardWrapper
          isSelected={isSelected}
          tabIndex={-1}
          isDisabled={isDisabled}
          aria-disabled={isDisabled}
          // using a controlled Tooltip here because there's with the card disabled
          // events are not propagated leading to the Tooltip not hiding in same mouse situations
          onMouseEnter={() =>
            setTooltipVisible(showAvailableOnlyOnYearlyTooltip)
          }
          onMouseLeave={() => setTooltipVisible(false)}
        >
          <StyledRadioButton
            data-testid={`${plan.id}-radio-button`}
            checked={isSelected}
            disabled={isDisabled}
            name="plan"
            onChange={handleChange}
            type="radio"
            value={plan.id}
          />

          <PlanName>{name.toUpperCase()}</PlanName>
          {renderPlanPrice({ isSelected })}
          <Small isSelected={isSelected}>
            Monthly / <br /> per Editor
          </Small>
        </CardWrapper>
      </Tooltip>
      {showAvailableOnlyOnYearlyNotice && (
        <SmallText>
          Available on <strong>yearly billing only</strong>
        </SmallText>
      )}
    </OuterCardWrapper>
  )
}

export default Card
