import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Box } from '../Box'
import { truncateStyles } from '../Truncate'

import { ReactComponent as SlashForward } from '@sketch/icons/slash-forward-20'

const wrapTextNodeInSpan = node =>
  typeof node === 'string' ? <span>{node}</span> : node

const BreadcrumbItem = ({ children, ...props }) => (
  <Box {...props}>{React.Children.map(children, wrapTextNodeInSpan)}</Box>
)

BreadcrumbItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export const breadcrumbStyles = css`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  min-width: 20px;
  position: relative;

  ${truncateStyles};

  &:last-child {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.foreground.secondary.C};
  }

  &:active {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  ${props =>
    props.active &&
    css`
      color: ${({ theme }) => theme.colors.foreground.secondary.A};
    `};

  > * {
    ${truncateStyles};
  }
`

export const StyledBreadcrumbItem = styled(BreadcrumbItem)`
  ${breadcrumbStyles}
`

export const breadcrumbDividerStyles = css`
  width: 20px;
  height: 20px;

  align-self: center;
  color: ${({ theme }) => theme.colors.border.A};

  position: relative;
  top: 1px;
  flex-shrink: 0;
`

const BreadcrumbDivider = styled(SlashForward)`
  ${breadcrumbDividerStyles}
`

const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
`

export const Breadcrumb = ({
  children: unfilteredChildren,
  divider = null,
  ...props
}) => {
  const children = React.Children.toArray(unfilteredChildren).filter(x => x)
  const currentDivider = divider ?? <BreadcrumbDivider />

  return (
    <BreadcrumbContainer {...props}>
      {React.Children.map(children, (breadcrumb, i) => {
        const isLastChild = children.length ? children.length === i + 1 : true
        return isLastChild ? breadcrumb : [breadcrumb, currentDivider]
      })}
    </BreadcrumbContainer>
  )
}

Breadcrumb.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  divider: PropTypes.node,
}
