import { TrashedShareFragment } from '@sketch/gql-types'

const ACCESS_LEVEL_COPY = {
  NONE: 'No Access',
  VIEW: 'View',
  DEFAULT: 'Default',
  EDIT: 'Edit',
}

export const canRestoreShare = (
  share: Pick<TrashedShareFragment, 'userAccessLevel'>
) => share.userAccessLevel === 'EDIT'

export const getPermissionsLabel = (key: string) =>
  ACCESS_LEVEL_COPY[key as keyof typeof ACCESS_LEVEL_COPY]
