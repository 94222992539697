import React from 'react'
import styled from 'styled-components'

import { tintColors } from '@sketch/global-styles'
import {
  ImageWrapper,
  Image,
  Text,
  OverLayWrapper,
  Size,
} from './PersonAvatar.styles'

type COLOR_KEYS_TYPE = keyof typeof tintColors
const COLOR_KEYS: COLOR_KEYS_TYPE[] = Object.keys(
  tintColors
) as any /* Allow the casting to work */

interface BasePersonAvatarProps {
  className?: string
  size: Size
  name: string
  overlay?: React.ReactNode
}

export interface ImagePersonAvatarProps extends BasePersonAvatarProps {
  flavour: 'image'
  src: string
}

export interface ColorPersonAvatarProps extends BasePersonAvatarProps {
  flavour: 'color'
  color?: 'random' | COLOR_KEYS_TYPE
}

export type PersonAvatarProps = ImagePersonAvatarProps | ColorPersonAvatarProps

// Allow from the name prop get the user initial letters
const getNameLetters = (name: string) =>
  name
    .split(/ +/)
    .map(block => block[0])
    .slice(0, 2)

// Choose a random color when none of the prism ones are passed
const pickRandomColor = () =>
  Math.max(1, Math.random() * COLOR_KEYS.length).toFixed(0) as COLOR_KEYS_TYPE

/**
 * PersonalAvatar
 */
const NonStyledPersonAvatar: React.FC<PersonAvatarProps> = props => {
  const { className, name, size, overlay } = props

  if (props.flavour === 'color') {
    const color = props.color || 'random'

    return (
      <Text
        className={className}
        $size={size}
        $color={color === 'random' ? pickRandomColor() : color}
      >
        <>
          {/* We need to separate the letter so when it's a single one apply a different font-size */}
          {getNameLetters(name).map((letter, index) => (
            <span key={letter || index}>{letter}</span>
          ))}
        </>
        {overlay && <OverLayWrapper>{overlay}</OverLayWrapper>}
      </Text>
    )
  }

  return (
    <ImageWrapper className={className} $size={size}>
      <Image alt={`${name} avatar`} src={props.src} />
      {overlay && <OverLayWrapper>{overlay}</OverLayWrapper>}
    </ImageWrapper>
  )
}

export const PersonAvatar = styled(NonStyledPersonAvatar)<PersonAvatarProps>``
