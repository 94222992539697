import styled from 'styled-components'
import { ReactComponent as EllipsisIcon } from '@sketch/icons/dots-3-horizontal-24'
import { TableComponents, DropdownButton } from '@sketch/components'

export const StyledTableCellCheckbox = styled(TableComponents.TableCell)`
  width: 20px;
`

export const StyledTableCellDocument = styled(TableComponents.TableCell)`
  width: 80%;
  cursor: pointer;
`

export const StyledTableCellFixed = styled(TableComponents.TableCell)`
  width: 100px;
  cursor: pointer;
`

export const StyledTableCellButton = styled(TableComponents.TableCell)`
  width: 50px;
  cursor: pointer;
`

export const StyledDropdownButton = styled(DropdownButton)`
  height: 24px;
  opacity: 1;
  transform: translate3d(0, 0px, 0);
`

export const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DropdownButtonEllipsis = styled(EllipsisIcon)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.sketch.A};
`

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
