import React, { useMemo, useRef } from 'react'

import {
  useIsCameraMoving,
  useIsCameraZooming,
  usePan,
  usePRFile,
} from '@sketch-hq/sketch-web-renderer'
import { useDevToolsSetting } from '@sketch/devtools'

import { usePerformanceOptimizedZoom } from '../../hooks/inspector'

import { OriginCross, Wrapper } from './ArtboardOriginContent.styles'

export const ArtboardOriginContent: React.FC = ({ children }) => {
  const ref = useRef(null)

  const pan = usePan() || { x: 0, y: 0 }
  const zoom = usePerformanceOptimizedZoom()
  const isCameraMoving = useIsCameraMoving()
  const isCameraZooming = useIsCameraZooming()
  const prFile = usePRFile()

  const [showPageOrigin] = useDevToolsSetting('webRenderer.showPageOrigin')

  const enclosingRect = useMemo(() => {
    const bounds = prFile?.getRootNode()?.getAbsoluteBounds() ?? {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    }

    const extent = prFile?.getRootNode()?.getAbsoluteExtent() ?? {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    }

    // Nominally the artboard origin should be set to 0,0 - but we still need
    // to account for any offset introduced by the root element's extent being larger
    // than its bounds (e.g. if it has a shadow)
    return {
      x: extent.x - bounds.x,
      y: extent.y - bounds.y,
      width: bounds.width,
      height: bounds.height,
    }
  }, [prFile])

  const style = {
    willChange: isCameraMoving ? 'contents' : undefined,
    pointerEvents: isCameraMoving ? 'none' : 'auto',
    transform: `translate3d(${Math.round(pan.x * zoom)}px, ${Math.round(
      pan.y * zoom
    )}px, 0)`,
    width: `${enclosingRect.width * zoom}px`,
    height: `${enclosingRect.height * zoom}px`,
    marginTop: `${-enclosingRect.y * zoom}px`,
    marginLeft: `${-enclosingRect.x * zoom}px`,
  } as const

  return (
    <Wrapper
      data-testid="artboard-origin-content"
      ref={ref}
      style={style}
      data-canvas-zooming={isCameraZooming}
    >
      {children}
      {showPageOrigin && <OriginCross />}
    </Wrapper>
  )
}
