import { useVersionUpdateKind } from './useVersionUpdateKind'
import { useToast } from '@sketch/toasts'
import { useModalContext } from '@sketch/components'
import { UnstarUpdateModal } from '../modals/UnstarUpdateModal'

export interface UseVersionUnstarProps {
  versionIdentifier: string
  isLibrary: boolean
}

export const useVersionUnstar = (props: UseVersionUnstarProps) => {
  const { versionIdentifier, ...modalProps } = props
  const { showModal } = useModalContext()
  const { showToast } = useToast()

  const shouldSkipModal: boolean =
    JSON.parse(
      localStorage.getItem('user:preferences:skipUnstarModal') || 'false'
    ) ?? false

  const [versionKindUpdate] = useVersionUpdateKind({
    onCompleted: () => {
      showToast('Version unstarred')
    },
    onError: message => showToast(message, 'negative'),
  })

  if (shouldSkipModal) {
    return [
      () =>
        versionKindUpdate({
          versionIdentifier,
          kind: 'DRAFT',
        }),
    ]
  }

  return [
    () =>
      showModal(UnstarUpdateModal, {
        versionIdentifier: props.versionIdentifier,
        ...modalProps,
      }),
  ]
}
