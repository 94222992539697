import styled, { css } from 'styled-components'
import { Text } from '@sketch/components'
import { ReactComponent as ExclamationMarkSVG } from '@sketch/icons/exclamation-mark-16'

export const ComponentDescription = styled(Text.Div).attrs({
  textStyle: 'copy.tertiary.standard.D',
})(
  ({ theme }) => css`
    overflow-wrap: break-all;
    white-space: break-spaces;
    line-height: 1.462; /* stylelint-disable-line scales/line-height */
  `
)

export const DisabledWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    color: ${theme.colors.foreground.secondary.D};
  `
)

export const DisabledIcon = styled(ExclamationMarkSVG)`
  width: 16px;
  height: 16px;
  margin-top: 2px; /* stylelint-disable-line scales/space */
  margin-right: 4px;
  flex: none;
`

export const DisabledText = styled.em`
  font-size: 0.8125rem; /* 13px */
`
