import styled, { css, keyframes } from 'styled-components'

import { Button as BaseButton, ButtonUnstyled } from '@sketch/components'
import { lightTheme } from '@sketch/global-styles'

import { ReactComponent as BaseDetailHeartIcon } from '@sketch/icons/heart-animated_UNSAFE'
import { ReactComponent as BaseListHeartIcon } from '@sketch/icons/heart-24_UNSAFE'

const rotateAnimation = keyframes`
  /* Rotate the heart from left to right in big movements */
  0% {
    rotate: 0deg;
  }
  20% {
    rotate: 5deg;
  }
  40% {
    rotate: -10deg;
  }
  60% {
    rotate: 7deg;
  }
  80% {
    rotate: -17deg;
  }
  100% {
    rotate: 7deg;
  }
`

const innerRotationAnimation = keyframes`
  /* Rotate the heart in small oscillations, in conjunction with the original rotations */
  0%{
    rotate: 0deg;
  }
  25%{
    rotate: 4deg;
  }
  50%{
    rotate: 0deg;
  }
  75%{
    rotate: -4deg;
  }
  100%{
    rotate: 0deg;
  }
`

const sizeAnimation = keyframes`
  /* Increase the size of the heart progressively */
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(4.5);
  }
`

const fillColorAnimation = keyframes`
  /* Animate the color of the heart (fill) */
  0% {
    fill: ${lightTheme.colors.state.neutral.D};
  }
  40% {
    fill: ${lightTheme.colors.state.neutral.A};
  }
  100%{
    fill: ${lightTheme.colors.state.neutral.A};
  }
`

const strokeColorAnimation = keyframes`
  /* Animate the color of the heart border */
  0% {
    stroke: ${lightTheme.colors.foreground.secondary.A}
  }
  40% {
    stroke: ${lightTheme.colors.foreground.primary.A}
  }
  100% {
    stroke: ${lightTheme.colors.foreground.primary.A}
  }
`
const filterColorAnimation = keyframes`
  /* Add the heart drop-shadow during the animation */
  40% {
      filter: drop-shadow(0px 0px 1px rgb(0 0 0 / 0.3));
    }
  100% {
      filter: drop-shadow(0px 0px 1px rgb(0 0 0 / 0.3));
    }
`

const strokeOutlineAnimation = keyframes`
  /* Decrease the stroke-width as the heart increases in size */
  from {
    stroke-width: 30px;
  }
  to {
    stroke-width: 10px;
  }
`

const showWinkAnimation = keyframes`
  /* Coordinate the show of the winks, they are delayed on the animation */
  0% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`
const showWinkFinalAnimation = keyframes`
  /* Coordinate the show of the last wink, so it stays visible */
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`

export const DetailHeartIcon = styled(BaseDetailHeartIcon)<{
  $animate?: boolean
}>`
  width: 16px;
  height: 16px;
  overflow: visible;

  transform-origin: bottom center;

  /* 
    We hide all the inner wink images inside the svg so we can show them
    as the animate
  */
  #wink-1,
  #wink-2,
  #wink-3 {
    opacity: 0;
  }

  /* Heart default state without animation not filled("hearted") */
  #heart {
    fill: ${({ theme }) => theme.colors.background.tertiary.D};
    stroke: ${lightTheme.colors.foreground.secondary.A};
    transition: 0.2s linear fill;
  }

  ${({ $animate }) =>
    $animate &&
    css`
      /* This CSS is related with the animation of the elements */

      animation: linear 4s ${rotateAnimation} forwards,
        linear 4s ${sizeAnimation} forwards,
        linear 4s ${filterColorAnimation} forwards;

      #wrapper {
        animation: linear 0.5s ${innerRotationAnimation} forwards;
        transform-origin: bottom center;
        animation-iteration-count: 8;
      }

      && #heart {
        animation: linear 1s ${strokeColorAnimation} forwards,
          linear 1s ${fillColorAnimation} forwards;
        animation-timing-function: step-end;
      }

      && #heart-path {
        animation: linear 2s ${strokeOutlineAnimation} forwards 2s;
      }

      && #wink-1 {
        animation: linear 1s ${showWinkAnimation} forwards 2s;
      }
      && #wink-2 {
        animation: linear 1s ${showWinkAnimation} forwards 3s;
      }
      && #wink-3 {
        animation: linear 1s ${showWinkFinalAnimation} forwards 4s;
      }
    `}
`

export const DetailButton = styled(BaseButton)<{ $fill: boolean }>`
  transition: 0.2s linear background-color;

  :hover {
    background-color: ${({ theme }) => theme.colors.state.neutral.E};

    & ${/* sc-selector */ DetailHeartIcon} #heart {
      fill: transparent;
    }
  }

  :active {
    background-color: ${({ theme }) => theme.colors.state.neutral.D};

    & ${/* sc-selector */ DetailHeartIcon} #heart {
      fill: ${({ theme }) => theme.colors.state.neutral.E};
      transition: none;
    }
  }

  ${({ $fill, theme }) =>
    $fill &&
    css`
      /* This CSS is related with the heart being filled ("hearted") */
      background-color: ${({ theme }) => theme.colors.state.neutral.F};

      ${DetailHeartIcon} #heart {
        fill: ${theme.colors.state.neutral.A};
        transition: none;
        stroke: transparent;
      }

      :hover {
        ${DetailHeartIcon} #heart {
          fill: ${theme.colors.state.neutral.A};
        }
      }
    `}
`

export const HeartCount = styled.span`
  margin-left: 6px; /* stylelint-disable-line scales/space */
`

export const ListButtonIcon = styled(BaseListHeartIcon)`
  width: 24px;
  margin-left: 0;
  flex-shrink: 0;

  #heart-outline {
    fill: ${({ theme }) => theme.colors.foreground.secondary.D};
  }

  #heart-fill {
    fill: ${({ theme }) => theme.colors.foreground.secondary.D};
    fill-opacity: 0.19;
  }
`

export const ListButton = styled(ButtonUnstyled)<{ $fill: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  height: 24px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  :hover {
    & ${/* sc-selector */ ListButtonIcon} #heart-outline {
      fill: ${({ theme }) => theme.colors.state.neutral.A};
    }

    & ${/* sc-selector */ ListButtonIcon} #heart-fill {
      fill: ${({ theme }) => theme.colors.state.neutral.A};
      fill-opacity: 0.19;
    }
  }

  ${({ $fill, theme }) =>
    $fill &&
    css`
      color: ${theme.colors.state.neutral.A};

      #heart-outline {
        fill: ${theme.colors.state.neutral.A};
      }

      #heart-fill {
        fill: ${theme.colors.state.neutral.A};
        fill-opacity: 1;
      }

      :hover {
        & ${ListButtonIcon} #heart-fill {
          fill: ${theme.colors.state.neutral.A};
          fill-opacity: 1;
        }
      }
    `}
`
