import styled, { CSSProperties, css } from 'styled-components'
import { OVERLAYS_Z_INDEX } from '../constants'
import { GuidelinesPairRect } from './types'

const BORDER_CSS = `1px dashed red;`

type SelectionGuidelineProps = {
  guidelinesPairRect: GuidelinesPairRect
}

function getSelectionGuidelinesPositionCssProperties(
  guidelinesPairRect: GuidelinesPairRect
): CSSProperties {
  return {
    top: `${guidelinesPairRect.y}px`,
    left: `${guidelinesPairRect.x}px`,
    width: `${guidelinesPairRect.width}px`,
    height: `${guidelinesPairRect.height}px`,
  }
}

export const SelectionVerticalGuidelines = styled.div.attrs(
  ({ guidelinesPairRect }: SelectionGuidelineProps) => {
    return {
      style: getSelectionGuidelinesPositionCssProperties(guidelinesPairRect),
    }
  }
)<SelectionGuidelineProps>`
  position: absolute;
  border-left: ${BORDER_CSS};
  border-right: ${BORDER_CSS};
`

export const SelectionHorizontalGuidelines = styled.div.attrs(
  ({ guidelinesPairRect }: SelectionGuidelineProps) => {
    return {
      style: getSelectionGuidelinesPositionCssProperties(guidelinesPairRect),
    }
  }
)<SelectionGuidelineProps>`
  position: absolute;
  border-top: ${BORDER_CSS};
  border-bottom: ${BORDER_CSS};
`

type SelectionRectangleContainerProps = {
  $width: number
  $height: number
  $x: number
  $y: number
}
export const SelectionRectangleContainer = styled.div.attrs(
  ({ $x, $y, $width, $height }: SelectionRectangleContainerProps) => {
    const style: CSSProperties = {
      transform: `translate(${$x}px, ${$y}px)`,
      width: `${$width}px`,
      height: `${$height}px`,
    }
    return { style }
  }
)<SelectionRectangleContainerProps>`
  position: absolute;
  z-index: ${OVERLAYS_Z_INDEX.SELECTED_ELEMENT_RECTANGLE};
`

const CORNER_PADDING = 7
const selectionRectangleEdgeLineCss = css`
  background-color: red;
  position: absolute;
`
export const SelectionRectangleTopEdgeLine = styled.div`
  ${selectionRectangleEdgeLineCss}
  height: 1px;
  top: 0px;
  left: ${CORNER_PADDING}px;
  right: ${CORNER_PADDING}px;
`

export const SelectionRectangleBottomEdgeLine = styled.div`
  ${selectionRectangleEdgeLineCss}
  height: 1px;
  bottom: 0px;
  left: ${CORNER_PADDING}px;
  right: ${CORNER_PADDING}px;
`
export const SelectionRectangleLeftEdgeLine = styled.div`
  ${selectionRectangleEdgeLineCss}
  width: 1px;
  left: 0px;
  top: ${CORNER_PADDING}px;
  bottom: ${CORNER_PADDING}px;
`
export const SelectionRectangleRightEdgeLine = styled.div`
  ${selectionRectangleEdgeLineCss}
  width: 1px;
  right: 0px;
  top: ${CORNER_PADDING}px;
  bottom: ${CORNER_PADDING}px;
`
