import { NetworkStatus } from 'apollo-client'
import { useApolloClient } from '@apollo/react-hooks'

import {
  GetAnnotationCommentsDocument,
  GetAnnotationCommentsQuery,
  GetAnnotationCommentsQueryVariables,
  useGetAnnotationCommentsQuery,
} from '@sketch/gql-types'

import { errorPreventiveCacheRead } from '@sketch/modules-common'

const useGetAnnotationComments = (
  variables: GetAnnotationCommentsQueryVariables,
  cacheOnly?: boolean
) => {
  const apollo = useApolloClient()
  const query = useGetAnnotationCommentsQuery({
    variables,
    fetchPolicy: cacheOnly ? 'cache-only' : 'cache-and-network',
    shouldInvalidatePrevious: (prev, next) => {
      /**
       * We double-check if the next query already exists in cache
       * to prevent a loading bump when the variables change.
       *
       * This will allow annotations to refresh in the background if there's additional context
       * and remove the bump when a dot annotation is created when moving from a optimistic query
       * to a real one
       *
       * https://github.com/sketch-hq/Cloud/issues/14345
       */
      const nextQuery = errorPreventiveCacheRead<GetAnnotationCommentsQuery>(
        apollo,
        { query: GetAnnotationCommentsDocument, variables: next },
        true
      )

      const nextAnnotationQueryIdentifier = nextQuery?.annotation?.identifier

      if (
        nextAnnotationQueryIdentifier &&
        nextAnnotationQueryIdentifier !== prev?.annotationIdentifier
      ) {
        return false
      }

      return prev?.annotationIdentifier !== next?.annotationIdentifier
    },
  })

  return {
    ...query,
    /* Forcing loading to only be related with the initial loading */
    loading: query.networkStatus === NetworkStatus.loading && !query.data,
  }
}

export default useGetAnnotationComments
