import styled, { css } from 'styled-components'
import {
  ButtonUnstyled,
  Tooltip,
  Markdown,
  buttonUnstyledStyles,
} from '@sketch/components'
import { ReactComponent as InformationIcon } from '@sketch/icons/information-outline-16'

export const Text = styled.span`
  font-size: 0.75rem; /* 12px */
  transition: 100ms color linear;
  line-height: 1;

  /* Force all numbers to have the same representation (width) */
  font-variant-numeric: tabular-nums;
`

export const Order = styled(Text)`
  line-height: 1;
`

export const Author = styled(Text)`
  line-height: 1.462; /* stylelint-disable-line scales/line-height */
  /* Fix visual appearance */
  transform: translate(0, 1px);

  font-size: 0.8125rem; /* 13px */
  overflow: hidden;
  color: ${({ theme }) => theme.colors.foreground.secondary.B};
`

export const AutosaveTooltip = styled(Tooltip)`
  display: inline-block;
  margin-left: 4px;
`

export const InfoIcon = styled(InformationIcon)`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  width: 16px;
  height: 16px;
  vertical-align: text-top;

  &[aria-selected='true'],
  .active & {
    color: ${({ theme }) => theme.colors.sketch.A};
  }
`

export const Description = styled(Markdown)`
  display: block;
  margin-top: 4px;
  margin-right: 12px;

  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  font-size: 0.8125rem; /* 13px */
  line-height: 1.4;
  font-weight: 400;
`

export const TopRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.4;

  min-height: 19px;
  padding-right: 44px;

  ${Text} + ${Text} {
    padding-left: 8px;
  }
`

export const VersionOptionsButton = styled(ButtonUnstyled)`
  position: absolute;
  top: 0;
  right: 0;

  padding: 8px 12px;
  line-height: 0;
  transition: 100ms background-color linear, 100ms color linear,
    100ms opacity linear;

  border-radius: ${({ theme }) => theme.radii.large};
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  & [role='img'] {
    width: 16px;
    height: 16px;
  }

  [aria-expanded='true'] &,
  &:hover {
    opacity: 1;
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
    background: ${({ theme }) => theme.colors.background.tertiary.B};
  }

  [aria-selected='true'] [aria-expanded='true'] &,
  [aria-selected='true'] &:hover,
  .active [aria-expanded='true'] &,
  .active &:hover {
    /* These cases illustrate when a dropdown is open or the button is hovered, being the item selected */
    background-color: ${({ theme }) => theme.colors.sketch.F};
  }
`

export const DateTime = styled.span`
  color: ${({ theme }) => theme.colors.foreground.primary.B};
`

export const StarWrapper = styled(Text)`
  color: ${({ theme }) => theme.colors.state.disabled};

  &[aria-selected='true'],
  .active & {
    color: ${({ theme }) => theme.colors.sketch.E};
  }
`

export interface WrapperProps {
  $disabled?: boolean
  $isStarred?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  display: block;

  position: relative;
  padding: 6px 0 7px 12px; /* stylelint-disable-line scales/space */
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  border-radius: 6px;
  margin-left: 8px; /* relative with the "-" width */

  transition: 100ms background linear;
  background: none;

  ::before {
    content: '';
    display: inline-block;
    width: 8px;

    position: absolute;
    top: 16px;
    left: -8px;

    margin-right: 12px;

    vertical-align: top;
    border-top: 1px solid ${({ theme }) => theme.colors.border.B};
  }

  ${VersionOptionsButton} {
    opacity: 0;
  }

  :hover,
  :focus {
    background: ${({ theme }) => theme.colors.background.tertiary.C};

    ${VersionOptionsButton} {
      opacity: 1;
    }
  }

  &[aria-selected='true'],
  .active & {
    color: ${({ theme }) => theme.colors.sketch.A};
    background: ${({ theme }) => theme.colors.sketch.F};

    ::before {
      border-color: ${({ theme }) => theme.colors.sketch.F};
    }

    ${VersionOptionsButton} {
      opacity: 1;
      color: ${({ theme }) => theme.colors.sketch.A};
    }

    ${Author} {
      color: ${({ theme }) => theme.colors.sketch.A};
    }
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      ${Author} {
        color: ${({ theme }) => theme.colors.foreground.secondary.D};
      }
    `}

  ${({ $isStarred, theme }) =>
    $isStarred &&
    css`
      ${Author} {
        font-weight: 500;
      }

      ${StarWrapper} {
        color: ${theme.colors.foreground.secondary.D};
      }

      &[aria-selected='true'],
      .active & {
        ${StarWrapper} {
          color: ${({ theme }) => theme.colors.sketch.A};
        }
      }
    `}
`

export const ToolTipAuthor = styled.span`
  line-height: 1.4;

  /* Limit the name size of the tooltip */
  [data-testid='tooltip'] & {
    display: block;
    max-width: min(300px, 80vw);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export const ToggleLinesButton = styled.button`
  ${buttonUnstyledStyles}

  font-size: ${({ theme }) => theme.fontSizes.B};
  color: ${({ theme }) => theme.colors.foreground.secondary.C};

  &:hover {
    color: ${({ theme }) => theme.colors.background.primary.A};
  }
`
