import styled from 'styled-components'

import { Table } from '@sketch/components'

export const StyledTable = styled(Table)`
  width: 100%;
  /**
    * The casting fixes a type error that appears when using generic components with styled-components
    * See https://github.com/styled-components/styled-components/issues/1803#issuecomment-857092410
    */
` as typeof Table
