/**
 * Check for WebGL support in the current browser (WebGL must be both available
 * and enabled for this check to pass). Code adapted from:
 * @see https://get.webgl.org
 */
export const isWebGLSupported = () => {
  try {
    const canvas = document.createElement('canvas')
    const gl = canvas.getContext('webgl')
    return gl !== null
  } catch (e) {
    return false
  }
}
