import styled from 'styled-components'

import { Button as ButtonBase } from '../../Button'
import { Input as InputBase } from '../../Input'

import { ReactComponent as GlobeSvg } from '@sketch/icons/globe-24'
import { ReactComponent as SubmitSvg } from '@sketch/icons/checkmark-24'
import { ReactComponent as CancelSvg } from '@sketch/icons/trash-empty-24'

export const Form = styled.form`
  display: flex;
  align-items: center;
`

export const Input = styled(InputBase)`
  && {
    width: 240px;
  }

  height: 32px;
  font-size: 0.8125rem;
`

export const Button = styled(ButtonBase).attrs({ size: '32' })`
  width: 40px;
  height: 32px;

  && {
    margin-left: 8px;
  }

  &:disabled {
    opacity: 0.6;
  }
`

export const UrlIcon = styled(GlobeSvg)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

export const SubmitIcon = styled(SubmitSvg)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.state.positive.A};
`

export const CancelIcon = styled(CancelSvg)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.state.negative.A};
`
