import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "logo", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 27 25", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M5.72 9l7.78 15.313L.422 9h5.297zm15.56 0L13.5 24.313 26.578 9h-5.297z", fill: "#EA6C00" }),
        React.createElement("path", { fill: "#FDB300", d: "M5.72 9h15.56L13.5 24.313z" }),
        React.createElement("path", { d: "M13.5.688l-7.371.777L5.719 9 13.5.687zm0 0l7.371.777.41 7.535L13.5.687z", fill: "#FDD231" }),
        React.createElement("path", { d: "M26.578 9l-5.707-7.535L21.28 9h5.3zM.422 9l5.707-7.535L5.719 9H.422z", fill: "#FDA700" }),
        React.createElement("path", { fill: "#FEEEB7", d: "M13.5.688L5.72 9h15.56z" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
