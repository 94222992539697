import { useApolloClient } from 'react-apollo'
import { v1 as uuid } from 'uuid'

import { useFlag } from '@sketch/modules-common'

import {
  useCreateAnnotationMutation,
  CreateAnnotationMutation,
  CreateAnnotationMutationVariables,
} from '@sketch/gql-types'

import {
  createAnnotationCommentsQuery,
  createCommentFragment,
  createInitialUserQuery,
  hasOptimisticResponseVariables,
} from '../utils'

import { useAnnotationQueryVariables } from '../context'
import { createNewAnnotationInCache } from '../utils/annotationCacheOperations'
import {
  getOptimisticArtboardSubjectFromInputSubject,
  getOptimisticSubjectFromInputSubject,
  isArtboardAnnotationSubjectInput,
} from '../utils/cache'
import { VariablesWithAdditionalParameters } from '../types'

interface CreateAnnotationParams {
  onCacheUpdated?: (data: CreateAnnotationMutation) => void
  onComplete?: (data: CreateAnnotationMutation) => void
}

const useCreateAnnotation = (params?: CreateAnnotationParams) => {
  const includeHasUnreadComments = useFlag('pages-unread-annotations')
  const apollo = useApolloClient()

  const [createAnnotation] = useCreateAnnotationMutation({
    onError: 'show-toast',
    onCompleted: params?.onComplete,
  })

  const { shareIdentifier, versionIdentifier } = useAnnotationQueryVariables(
    'default'
  )

  return <T extends CreateAnnotationMutationVariables>(
    ...args: VariablesWithAdditionalParameters<T>
  ) => {
    const [variables, additionalParameters] = args
    const annotationMockIdentifier = uuid()

    if (!variables) {
      return
    }

    variables.includeHasUnreadComments = includeHasUnreadComments
    const user = createInitialUserQuery(apollo)

    return createAnnotation({
      variables,
      update: (cache, createAnnotationData) => {
        const { data } = createAnnotationData
        const annotation = data?.createAnnotation.annotation

        if (!annotation) {
          // If there's no annotation response no need to update anything then
          return
        }

        createNewAnnotationInCache(
          apollo,
          { shareIdentifier, versionIdentifier: versionIdentifier || '' },
          annotation
        )

        // Create the annotation query on the cache
        // We will use it to represent the annotation right away
        // on the floating annotations
        createAnnotationCommentsQuery(
          apollo,
          annotation,
          annotation.share.identifier
        )

        params?.onCacheUpdated?.(data)
      },
      optimisticResponse: () => {
        if (!hasOptimisticResponseVariables(variables)) {
          throw new Error(
            'Missing variables in optimistic update (`createAnnotation` mutation)'
          )
        }

        const { body, shareIdentifier, coordinates, subject } = variables!
        const comment = createCommentFragment(body, user.me)

        const currentSubject = isArtboardAnnotationSubjectInput(subject)
          ? getOptimisticArtboardSubjectFromInputSubject(
              subject,
              additionalParameters?.permanentPageId!
            )
          : getOptimisticSubjectFromInputSubject(subject)

        const optimisticResponse: CreateAnnotationMutation = {
          __typename: 'RootMutationType',
          createAnnotation: {
            __typename: 'CreateAnnotationResponse',
            annotation: {
              __typename: 'Annotation',
              isNew: false,
              hasNewComments: false,
              identifier: annotationMockIdentifier,
              subscriptionStatus: 'ON',
              isOptimistic: true,

              comments: {
                __typename: 'Comments',
                meta: {
                  __typename: 'PaginationMeta',
                  totalCount: 1,
                },
              },
              firstComment: comment,
              latestCommentCreatedAt: comment.createdAt,

              currentSubject,

              share: {
                __typename: 'Share',
                identifier: shareIdentifier,
                subscriptionStatus: 'PARTIAL',
              },

              coordinates: {
                __typename: 'AnnotationCoordinates',
                identifier: uuid(),
                x: coordinates?.x || 0,
                y: coordinates?.y || 0,
              },

              resolution: null,
            },
          },
        }

        return optimisticResponse
      },
    })
  }
}

export default useCreateAnnotation
