import { Table } from '@sketch/components'
import styled from 'styled-components'

import { HEIGHT } from '../../ReviewLargeDocumentsModal.styles'
import { breakpoint } from '@sketch/global-styles'

export const SelectedDocumentTable = styled(Table)`
  width: 100%;
` as typeof Table

export const SelectedDocumentsListWrapper = styled.div`
  height: ${HEIGHT};
  overflow-y: auto;

  margin: 0 32px;

  ${breakpoint('base', 'sm')`
    margin: 0 32px;
  `}
`
