import { getMatchingRoute, MatchedRoute, MismatchedRoute } from '../routes'
import { getElementTiming, getCustomElementTiming } from './elementTiming'
import { getLCP, LcpEvent } from './getLCP'
import { getRouteExpectations, RouteExpectation } from './getRouteExpectations'
import { Metric } from 'web-vitals'

export interface MetricPayload<T> {
  payload: T
  wasRedirected: 'true' | 'false'
}

export interface PageLoadMetrics {
  lcp: MetricPayload<LcpEvent> | undefined
  elementTiming: MetricPayload<Metric> | undefined
  customElementTiming: MetricPayload<Metric> | undefined
}

type Source = keyof PageLoadMetrics

export interface PageLoadContext {
  route: MatchedRoute | MismatchedRoute
  routeExpectations: RouteExpectation
}

export interface PageLoadPayload extends PageLoadMetrics, PageLoadContext {}

export type PageLoadHandler = (payload: PageLoadPayload, source: Source) => void

export const getPageLoadMetrics = (
  onMetricsUpdate: PageLoadHandler,
  pathname: string
) => {
  const route = getMatchingRoute(pathname)
  const routeExpectations = getRouteExpectations(route)

  const metrics: PageLoadPayload = {
    route,
    routeExpectations,
    elementTiming: undefined,
    lcp: undefined,
    customElementTiming: undefined,
  }

  const getWasRedirected = () => {
    return pathname !== window.location.pathname ? 'true' : 'false'
  }

  if (routeExpectations.element) {
    getElementTiming(payload => {
      const source: Source = 'elementTiming'
      metrics.elementTiming = {
        payload,
        wasRedirected: getWasRedirected(),
      }
      onMetricsUpdate(metrics, source)
    }, routeExpectations.element)

    getCustomElementTiming(payload => {
      const source: Source = 'customElementTiming'
      metrics.customElementTiming = {
        payload,
        wasRedirected: getWasRedirected(),
      }
      onMetricsUpdate(metrics, source)
    }, routeExpectations.element)
  }

  getLCP(payload => {
    const source: Source = 'lcp'
    metrics.lcp = {
      payload,
      wasRedirected: getWasRedirected(),
    }
    onMetricsUpdate(metrics, source)
  }, routeExpectations.operations)
}
