import React from 'react'

import { MemberInvite } from '../MemberInvite'

import { Wrapper } from './CreateWorkspaceInvitePartners.styles'

import { Text } from '@sketch/components'

import { Member, WorkspaceMembers } from '../../types'

import { useAnalytics } from '@sketch/modules-common'
import { useAnalyticsFormContext } from '../../views/CreateWorkspaceView/CreateWorkspaceView.hooks'
import { ItemPartners } from '../MembersList/components/ItemPartners'
import { INVITEE_LIMIT } from '../../constants'

interface CreateWorkspaceInvitePeopleProps {
  members: WorkspaceMembers
  onMembersUpdate: (members: WorkspaceMembers) => void
  onMembersDelete: (members: WorkspaceMembers) => void
  isWorkspaceCreated: boolean
  isDisabled: boolean
}

export const CreateWorkspaceInvitePartners: React.FC<CreateWorkspaceInvitePeopleProps> = props => {
  const {
    members,
    onMembersUpdate,
    isWorkspaceCreated,
    isDisabled,
    onMembersDelete,
  } = props

  const { trackEvent } = useAnalytics()
  const analyticsFormContext = useAnalyticsFormContext()

  const handleRemove = (member: Member) =>
    onMembersDelete(
      members.filter(prevMember => prevMember.email !== member.email)
    )

  const handleInvite = async (newMember: Member) => {
    if (members.find(member => member.email === newMember.email)) {
      throw new Error('Already a workspace member')
    } else {
      trackEvent('CREATE WORKSPACE - member invited', {
        formContext: analyticsFormContext,
      })
      onMembersUpdate([...members, newMember])
    }
  }

  const memberWithoutOwner = members?.filter(member => !member.isOwner)

  // New workspaces are trialing so the limit is always 10 invitees
  const inviteeLimit = INVITEE_LIMIT.trial
  const isInviteesLimitExceeded = members.length > inviteeLimit

  return (
    <Wrapper>
      <Text color="foreground.secondary.C" fontSize="E">
        Invite Workspace Owner
      </Text>
      <MemberInvite
        inviteeLimit={inviteeLimit}
        isInviteesLimitExceeded={isInviteesLimitExceeded}
        onInvite={handleInvite}
        isDisabled={
          isDisabled || isWorkspaceCreated || memberWithoutOwner?.length > 0
        }
        isPartner
      />
      {memberWithoutOwner?.length > 0 && (
        <ItemPartners member={memberWithoutOwner[0]} onRemove={handleRemove} />
      )}
    </Wrapper>
  )
}
