import { ExtraLinksFn } from '../graphql/apolloClient'
import { createOnErrorLink } from '../graphql/link/onError'
import { History } from 'history'
import { useRef } from 'react'
import { useToast, ToastActions } from '@sketch/toasts'
import { AnalyticsContext } from '@sketch/modules-common'

export interface UseExtraApolloLinksProps {
  history: History
  getAnalytics: () => AnalyticsContext
}

interface CreateExtraApolloLinksProps {
  history: History
  toastActions: ToastActions
  getAnalytics: () => AnalyticsContext
}

const createExtraApolloLinks = ({
  history,
  toastActions,
  getAnalytics,
}: CreateExtraApolloLinksProps): ExtraLinksFn => getClient => [
  createOnErrorLink(getClient, history, toastActions, getAnalytics),
]

export const useExtraApolloLinks = (props: UseExtraApolloLinksProps) => {
  const toastActions = useToast()

  // We want to have a singleton.
  // This should never change even if the parent components get rerendered.
  const extraLinks = useRef(createExtraApolloLinks({ ...props, toastActions }))

  return extraLinks.current
}
