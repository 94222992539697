import { useContext } from 'react'

// Context
import { ShareVersionContext } from '../../../modules/versioning'

import { useGetPagePresentationFileQuery } from '@sketch/gql-types'
import { getRenderStatus } from '@sketch/modules-common'

interface UseCanvasPresentationFileProps {
  pageUUID: string
}

export const useCanvasPresentationFile = ({
  pageUUID,
}: UseCanvasPresentationFileProps) => {
  const shareVersionContext = useContext(ShareVersionContext)!
  const { versionShortId, share, loading } = shareVersionContext

  const renderStatus = !loading
    ? getRenderStatus(
        shareVersionContext.currentVersion.document?.renderStatus ?? null
      )
    : null

  const shouldSkipQuery = shareVersionContext.loading
    ? true
    : shareVersionContext?.currentVersion?.presentationStatus === 'PENDING'

  const prFileQuery = useGetPagePresentationFileQuery({
    variables: {
      shareIdentifier: share.identifier,
      versionShortId,
      pageUUID,
    },
    skip: shouldSkipQuery,
  })

  const page = prFileQuery.data?.share?.version?.document?.page
  const isLoading = prFileQuery.loading
  const hasError = prFileQuery.error
  const isRendering = renderStatus !== 'ready'

  return {
    share,
    page,
    versionShortId,
    isLoading,
    hasError,
    isRendering,
  }
}
