import styled from 'styled-components'

import {
  SubtitleTextWrapper,
  Image,
  StatusIcon,
} from '../DocumentItem/DocumentItem.styles'
import { ReactComponent as Folder } from '@sketch/icons/folder-closed-16'
import { breakpoint } from '@sketch/global-styles'

export const StyledSubtitleTextWrapper = styled(SubtitleTextWrapper)`
  display: block;

  b {
    display: block;
    margin-bottom: 4px;
  }
`

export const Thumbnail = styled(Image)`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;

  /* Create the border inside */
  :after {
    border-radius: 4px;

    content: '';
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.border.A};
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export const ThumbnailWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;
  padding: 8px;

  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  position: absolute;

  ${breakpoint('xs')`
    column-gap: 16px;
    row-gap: 16px;
    padding: 16px;
  `}
`

export const StyledFolderIcon = styled(Folder)`
  width: 16px;
  height: 16px;

  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;

  margin-right: 8px;
  top: 2px;
  position: relative;
`

export const StyledStatusIcon = styled(StatusIcon)`
  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
`
