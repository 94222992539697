import { Link } from '@sketch/components'
import React, { FC } from 'react'

import { Button, CoffeeCupIcon } from './BuyMeACoffeeButton.styles'

export const BUY_ME_A_COFFEE_URL = 'https://www.buymeacoffee.com/'

export const SHORT_BUY_ME_A_COFFEE_URL = 'buymeacoffee.com/'

export interface BuyMeACoffeeButtonProps {
  url?: string
  className?: string
}

export const BuyMeACoffeeButton: FC<BuyMeACoffeeButtonProps> = ({
  url,
  className,
}) => {
  const content = (
    <Button className={className}>
      <CoffeeCupIcon />
      <span>Buy Me a Coffee…</span>
    </Button>
  )

  return url ? (
    <Link external href={url}>
      {content}
    </Link>
  ) : (
    content
  )
}
