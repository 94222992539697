import React from 'react'

import { MemberInvite } from '../../components/MemberInvite'
import { MembersList } from '../../components/MembersList'

import { Title } from './CreateWorkspaceInvitePeople.styles'

import { Member, WorkspaceMembers } from '../../types'

import { useAnalytics } from '@sketch/modules-common'
import { useAnalyticsFormContext } from '../../views/CreateWorkspaceView/CreateWorkspaceView.hooks'
import { INVITEE_LIMIT } from '../../constants'

interface CreateWorkspaceInvitePeopleProps {
  members: WorkspaceMembers
  onMembersUpdate: (members: WorkspaceMembers) => void
  isWorkspaceCreated: boolean
  hasSingleEditor: boolean
  isDisabled: boolean
}

/**
 * CreateWorkspaceInvitePeople
 *
 * This page is responsible for rendering the components
 * that will enable the user to invite members to a workspace and
 * change user roles
 */
export const CreateWorkspaceInvitePeople: React.FC<CreateWorkspaceInvitePeopleProps> = props => {
  const {
    members,
    onMembersUpdate,
    isWorkspaceCreated,
    hasSingleEditor,
    isDisabled,
  } = props

  const { trackEvent } = useAnalytics()
  const analyticsFormContext = useAnalyticsFormContext()

  const updateMemberRole = (member: Member) => {
    onMembersUpdate(
      members.map(prevMember =>
        prevMember.email === member.email ? member : prevMember
      )
    )
  }

  const handleRemove = (member: Member) => {
    onMembersUpdate(
      members.filter(prevMember => prevMember.email !== member.email)
    )
  }

  const handleEditor = (clickedMember: Member) => {
    updateMemberRole({ ...clickedMember, isEditor: true })
  }

  const handleViewer = (clickedMember: Member) => {
    updateMemberRole({ ...clickedMember, isEditor: false })
  }

  const handleToggleAdmin = (clickedMember: Member) => {
    updateMemberRole({ ...clickedMember, isAdmin: !clickedMember.isAdmin })
  }

  const handleInvite = async (newMember: Member) => {
    if (members.find(member => member.email === newMember.email)) {
      throw new Error('Already a workspace member')
    } else {
      trackEvent('CREATE WORKSPACE - member invited', {
        formContext: analyticsFormContext,
      })
      onMembersUpdate([...members, newMember])
    }
  }

  // New workspaces are trialing so the limit is always 10 invitees
  const inviteeLimit = INVITEE_LIMIT.trial
  const isInviteesLimitExceeded = members.length > inviteeLimit

  return (
    <div>
      <Title>Invite Members to the Workspace</Title>
      <MemberInvite
        inviteeLimit={inviteeLimit}
        isInviteesLimitExceeded={isInviteesLimitExceeded}
        onInvite={handleInvite}
        isDisabled={isDisabled || isWorkspaceCreated}
      />
      <MembersList
        members={members}
        onRemove={handleRemove}
        onEditor={handleEditor}
        onViewer={handleViewer}
        onToggleAdmin={handleToggleAdmin}
        isDisabled={isDisabled || isWorkspaceCreated}
        hasSingleEditor={hasSingleEditor}
      />
    </div>
  )
}
