import React from 'react'
import { ColorProps } from '../../../../../../../Inspector/Sidebar/components/Color/ColorDot'
import { rgbTo } from '../../../Color/utils'
import {
  Container,
  Variable,
  ValueContainer,
  Text,
  Alpha,
} from './ColorValue.styles'
import { ColorFormat } from '../../../../../../../types'

export const ColorValue = ({
  red,
  green,
  blue,
  alpha = 1,
  format = ColorFormat.HEX,
  colorVariableName,
}: ColorProps) => {
  const formattedValue = rgbTo(format, { red, green, blue, alpha })
  const alphaPercentage = `${(alpha * 100).toFixed()}%`

  return (
    <Container>
      {colorVariableName ? (
        <Variable className="ColorText">
          {colorVariableName.split('/').pop()}
        </Variable>
      ) : null}

      <ValueContainer>
        <Text className="ColorText" hasColorVariable={!!colorVariableName}>
          {formattedValue}
        </Text>
        {alpha !== undefined && alpha !== 1 && format === ColorFormat.HEX && (
          <Alpha>- {alphaPercentage}</Alpha>
        )}
      </ValueContainer>
    </Container>
  )
}
