import styled from 'styled-components'
import { TruncateWithTooltip } from '@sketch/components'

const CommentAuthor = styled(TruncateWithTooltip)`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: 0.8125rem; /* 13px */
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 1.4;
`

export default CommentAuthor
