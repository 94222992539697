import styled, { CSSProperties } from 'styled-components'

type ElementRelativeContainerProps = {
  $x: number
  $y: number
  $width: number
  $height: number
}
export const ElementRelativeContainer = styled.div.attrs(
  ({ $x, $y, $width, $height }: ElementRelativeContainerProps) => {
    const style: CSSProperties = {
      width: `${$width}px`,
      height: `${$height}px`,
      left: `${$x}px`,
      top: `${$y}px`,
    }
    return { style }
  }
)<ElementRelativeContainerProps>`
  position: absolute;
`
