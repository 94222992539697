import React, { useState } from 'react'
import copy from 'copy-to-clipboard'

import { Button, Tooltip, CopyToClipboard } from '@sketch/components'

import {
  gradientBackground,
  gradientStops,
} from '../../../Inspector/Sidebar/components/GradientAttribute/math'

import { Style } from '../../../types'

import { roundWithLocale } from '@sketch/utils'

export interface CSSStyle extends Style {
  corners?: {
    cornerRadius?: number[]
  }
}

export const CopyCSSButton = ({ style }: { style: CSSStyle }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const cssString: string[] = []

  const getCornerCSS = () => {
    if (!style.corners) {
      return
    }

    if (style.corners?.cornerRadius) {
      const radius = style.corners?.cornerRadius
      const hasRadius = radius.some(n => n !== 0)

      if (!hasRadius) {
        return
      }

      const normalizedRadius = radius.every(r => r === radius[0])
        ? `${radius[0]}px`
        : `${radius.join('px ')}px`

      cssString.push(`border-radius: ${normalizedRadius};`)
    }
  }

  const getBorderCSS = () => {
    // ignore borders if this is a text-style
    if (style.text) {
      return
    }

    const colors: string[] = []
    const widths: string[] = []

    style.borders?.forEach(border => {
      if (!border.isEnabled) {
        return
      }

      widths.push(`${border.thickness}px`)

      colors.push(
        `rgba(${border.color?.red},${border.color?.green},${
          border.color?.blue
        }, ${border.color?.alpha ?? 1})`
      )
    })

    if (style.borderOptions?.isEnabled) {
      let borderStyle = 'solid'

      // if only the dash ([0]) is defined but not the gap ([1]), then it's a solid border
      if (
        style.borderOptions.dashPattern?.length &&
        style.borderOptions.dashPattern[1] !== 0
      ) {
        // so dotted borders are the ones that have a dash of only 1px, otherwise it's a dashed border
        borderStyle =
          style.borderOptions.dashPattern[0] === 1 ? 'dotted' : 'dashed'
      }

      colors.forEach((color, index) => {
        cssString.push(`border: ${widths[index]} ${borderStyle} ${color};`)
      })
    } else {
      colors.forEach((color, index) => {
        cssString.push(`border: ${widths[index]} ${color};`)
      })
    }
  }

  const getShadowsCSS = () => {
    const innerShadows: string[] = []
    const outerShadows: string[] = []
    const shadows: string[] = []

    style.innerShadows?.forEach(
      ({ isEnabled, offsetX, offsetY, blurRadius, spread, color }) => {
        if (!isEnabled) {
          return
        }

        const rgba = `rgba(${color.red},${color.green},${color.blue}, ${
          color.alpha ?? 1
        })`

        // order of the parameters is: x y blur spread color
        innerShadows.push(
          `${offsetX}px ${offsetY}px ${blurRadius}px ${
            spread ? spread + 'px ' : ''
          }${rgba} inset`
        )
      }
    )

    style.shadows?.forEach(
      ({ isEnabled, offsetX, offsetY, blurRadius, spread, color }) => {
        if (!isEnabled) {
          return
        }

        const rgba = `rgba(${color.red},${color.green},${color.blue}, ${
          color.alpha ?? 1
        })`

        // order of the parameters is: x y blur spread color
        outerShadows.push(
          `${offsetX}px ${offsetY}px ${blurRadius}px ${
            spread ? spread + 'px ' : ''
          }${rgba}`
        )
      }
    )

    if (style.text && shadows.length) {
      // text shadows instead of box
      cssString.push(`text-shadow: ${shadows.join(', ')};`)
      return
    }

    if (innerShadows.length) {
      shadows.push(innerShadows.join(', '))
    }

    if (outerShadows.length) {
      shadows.push(outerShadows.join(', '))
    }

    if (shadows.length) {
      cssString.push(`box-shadow: ${shadows.join(', ')};`)
    }
  }

  const getOpacity = () => {
    cssString.push(`opacity: ${style.appearance?.opacity};`)
  }

  const getBackground = () => {
    // ignore backgrounds if this is a text-style
    if (style.text) {
      return
    }

    const gradients: string[] = []

    style.fills?.forEach(fill => {
      if (!fill.isEnabled) {
        return
      }

      if (fill.type === 'color') {
        cssString.push(
          `background-color: rgba(${fill.color?.red},${fill.color?.green},${
            fill.color?.blue
          }, ${fill.color?.alpha ?? 1});`
        )
      } else if (fill.type === 'gradient' && fill.gradient) {
        switch (fill.gradient.type) {
          case 'radial':
            gradients.push(`radial-gradient(${gradientStops(fill.gradient)});`)
            break
          case 'angular':
            gradients.push(
              `conic-gradient(from 90deg, ${gradientStops(fill.gradient)});`
            )
            break
          default:
            gradients.push(gradientBackground(fill.gradient))
        }
      } else {
        if (fill.patternTileScale) {
          cssString.push(`background-size: ${fill.patternTileScale * 100}%;`)
        }
      }
    })

    if (gradients.length) {
      cssString.push(`background-image: ${gradients.join(', ')};`)
    }
  }

  const getText = () => {
    if (!style.text) {
      return
    }

    if (style.text.color) {
      cssString.push(
        `color: rgba(${style.text.color.red},${style.text.color.green},${
          style.text.color.blue
        },${style.text.color.alpha ?? 1});`
      )
    }

    if (style.text.font) {
      cssString.push(`font-family: "${style.text.font.originalValue}";`)
      cssString.push(`font-size: ${style.text.font.size}px;`)
      cssString.push(`font-weight: ${style.text.font.weight};`)
      cssString.push(`font-style: ${style.text.font.style};`)
    }

    const roundedLetterSpacing = roundWithLocale(
      style.text.letterSpacing ?? 0,
      2
    )

    if (roundedLetterSpacing) {
      cssString.push(`letter-spacing: ${roundedLetterSpacing}px;`)
    }

    if (style.text.paragraphStyle) {
      cssString.push(`text-align: ${style.text.paragraphStyle.alignment};`)

      if (style.text.paragraphStyle.minLineHeight) {
        cssString.push(
          `line-height: ${style.text.paragraphStyle.minLineHeight}px;`
        )
      }
    }

    if (style.text.transform) {
      cssString.push(`text-transform: ${style.text.transform};`)
    }

    if (style.text.decoration) {
      cssString.push(`text-decoration: ${style.text.decoration};`)
    }
  }

  if (!cssString.length) {
    getCornerCSS()
    getBorderCSS()
    getShadowsCSS()
    getOpacity()
    getBackground()
    getText()
  }

  const handleCopyClick = () => {
    copy(cssString.join('\n'))

    setTooltipVisible(true)

    window.setTimeout(() => {
      setTooltipVisible(false)
    }, CopyToClipboard.animationDuration)
  }

  return (
    <Tooltip visible={tooltipVisible} placement="left" content="Copied">
      <Button
        size="24"
        variant="secondary"
        onClick={handleCopyClick}
        disabled={!cssString.length}
      >
        Copy CSS
      </Button>
    </Tooltip>
  )
}
