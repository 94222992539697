import React from 'react'
import styled, { DefaultTheme, ThemeProps, css } from 'styled-components'
import { variant } from 'styled-system'

export type Variant =
  | 'primary'
  | 'secondary'
  | 'black'
  | 'error'
  | 'brand'
  | 'admin'
  | 'owner'
  | 'guest'
  | 'finance'
  | 'community'
  | 'party'

interface StyledPillProps extends ThemeProps<DefaultTheme> {
  variant: Variant
}

const StyledPill = styled.span<StyledPillProps>(
  ({ theme }) => css`
    height: 18px;
    display: inline-flex;
    align-items: center;
    text-transform: capitalize;

    font-size: ${theme.fontSizes.B};
    font-weight: ${theme.fontWeights.medium};
    line-height: 18px; /* stylelint-disable-line scales/line-height */
    white-space: nowrap;

    padding: 0px 8px;
    border-radius: ${theme.radii.xxlarge};

    ${() =>
      variant({
        variants: {
          primary: {
            color: theme.colors.foreground.primary.A,
            backgroundColor: theme.colors.sketch.A,
          },
          secondary: {
            color: theme.colors.foreground.secondary.B,
            backgroundColor: theme.colors.background.tertiary.B,
          },
          brand: {
            color: theme.colors.brand.green.foreground,
            backgroundColor: theme.colors.brand.green.default.A,
          },
          black: {
            color: theme.colors.ui.badges.foreground,
            backgroundColor: theme.colors.background.primary.A,
          },
          error: {
            color: theme.colors.foreground.primary.A,
            backgroundColor: theme.colors.state.negative.A,
          },
          admin: {
            color: theme.colors.brand.purple.foreground,
            backgroundColor: theme.colors.brand.purple.default.A,
          },
          owner: {
            color: theme.colors.brand.teal.foreground,
            backgroundColor: theme.colors.brand.teal.default.A,
          },
          finance: {
            color: theme.colors.brand.green.foreground,
            backgroundColor: theme.colors.brand.green.default.A,
          },
          guest: {
            color: theme.colors.brand.yellow.foreground,
            backgroundColor: theme.colors.brand.yellow.default.A,
          },
          community: {
            color: theme.colors.brand.green.foreground,
            backgroundColor: theme.colors.brand.green.default.A,
          },
          party: {
            color: '#002F7D',
            background: `linear-gradient(
                90deg,
                rgba(232, 184, 252, 1) 0%,
                rgba(231, 219, 143, 1) 34%,
                rgba(166, 241, 232, 1) 67%,
                rgba(136, 252, 207, 1) 100%
            )`,
          },
        },
      })}
  `
)

export interface PillProps extends React.HTMLAttributes<HTMLSpanElement> {
  variant?: Variant
}

const PillUnstyled: React.FC<PillProps> = ({
  variant = 'primary',
  children,
  className,
  ...props
}) => (
  <StyledPill className={className} variant={variant} {...props}>
    {children}
  </StyledPill>
)

export const Pill = styled(PillUnstyled)``
