import React from 'react'

import { useUpdateWorkspaceProfileMutation } from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'

import { Button, BannerSection } from '@sketch/components'

export interface HideProfileSectionProps {
  identifier: string
}
export const HideProfileSection = (props: HideProfileSectionProps) => {
  const { identifier } = props
  const { showToast } = useToast()

  const [publishProfile, { loading }] = useUpdateWorkspaceProfileMutation({
    onCompleted: () => {
      showToast('Public profile is now hidden')
    },
    onError: message => showToast(message, 'negative'),
    variables: {
      input: {
        identifier,
        isPublic: false,
      },
    },
  })

  return (
    <BannerSection
      type="positive"
      button={
        <Button
          size="32"
          variant="primary-untinted"
          loading={loading}
          disabled={loading}
          onClick={() => publishProfile()}
        >
          Hide Profile
        </Button>
      }
    >
      Your public profile was successfully published.
    </BannerSection>
  )
}
