import React, { FC } from 'react'

import { Gutter, GridProps } from './Grid.types'

import { Container } from './Grid.styles'

const DEFAULT_GUTTER: Gutter = {
  xl: 40,
  lg: 20,
  md: 20,
  sm: 20,
  xs: 16,
}

export const Grid: FC<GridProps> = ({
  children,
  gutter = DEFAULT_GUTTER,
  asList,
  ...props
}) => {
  return (
    <Container as={asList ? 'ul' : undefined} gutter={gutter} {...props}>
      {children}
    </Container>
  )
}
