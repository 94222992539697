import React, { FC, useEffect } from 'react'

import {
  Box,
  Caption,
  ErrorMessage,
  InfiniteList,
  UnstyledList,
  useBreakpoint,
} from '@sketch/components'

import NotificationListItem from './NotificationListItem'
import { NotificationListItemSkeleton } from './NotificationListItemSkeleton'
import { NotificationSearch } from './NotificationSearch/NotificationSearch'
import {
  EmptyStateWrapper,
  WrapperList,
} from '../../../user/views/Updates/Updates.styles'

import { ReactComponent as BellIcon } from '@sketch/icons/bell-filled-64'

import { NotificationInfoFragment } from '@sketch/gql-types'
import { useNotificationSearchContext } from '../../context/NotificationSearchContext'

/**
 * TYPES
 */
interface NotificationsListProps {
  loading: boolean
  loadMore: () => Promise<any>
  entries?: NotificationInfoFragment[]
  subscribeToMore: () => void
  hasUnreadNotifications?: boolean
  pageSize?: number
}

/**
 * MAIN COMPONENT
 */
const NotificationsListRead: FC<NotificationsListProps> = ({
  subscribeToMore,
  entries,
  loading,
  loadMore,
  pageSize = 20,
}) => {
  const isTabletOrBigger = useBreakpoint('sm')

  const { search, isOptionsEmpty } = useNotificationSearchContext()

  useEffect(() => {
    subscribeToMore()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasNotifications = entries?.length !== 0

  const renderList = () => {
    if (loading && !entries)
      return <NotificationListItemSkeleton pageSize={pageSize} />

    // case when switching on and off search criteria
    // we don't want to show the skeleton without the search bar
    if (loading && !hasNotifications) {
      return (
        <>
          <NotificationListItemSkeleton pageSize={pageSize} />
        </>
      )
    }

    if (!hasNotifications && !search && isOptionsEmpty()) {
      return (
        <>
          <Box width="100%" height="100%" px={6} UNSAFE_py="50px">
            <ErrorMessage
              icon={<BellIcon />}
              iconSize="medium"
              title="No updates"
              description="No read comments or notifications yet."
            />
          </Box>
        </>
      )
    }

    const { readNotifications } = splitNotificationTypes(entries)

    const renderNotification = (notification: NotificationInfoFragment) => (
      <li key={notification.identifier}>
        <NotificationListItem
          isRead={!!notification.isRead}
          notification={notification}
          markNotificationAsRead={() => null}
          isVisuallyRead={notification.isRead || false}
        />
      </li>
    )

    return (
      <>
        {!isTabletOrBigger && <NotificationSearch />}
        {readNotifications.length ? (
          <InfiniteList
            onLoadMore={loadMore}
            renderLoading={() => <NotificationListItemSkeleton pageSize={5} />}
          >
            <WrapperList>
              <UnstyledList>
                {readNotifications.map(renderNotification)}
              </UnstyledList>
            </WrapperList>
          </InfiniteList>
        ) : (
          <EmptyStateWrapper>
            <Caption fontSize="E" textAlign="center" m={0}>
              {search ? (
                <ErrorMessage
                  icon={<BellIcon />}
                  iconSize="medium"
                  title="No notifications matching the search criteria"
                />
              ) : (
                <ErrorMessage
                  icon={<BellIcon />}
                  iconSize="medium"
                  title="No unread updates"
                />
              )}
            </Caption>
          </EmptyStateWrapper>
        )}
      </>
    )
  }

  return renderList()
}

export default NotificationsListRead

function splitNotificationTypes(
  notifications: NotificationInfoFragment[] = []
) {
  const notificationsFormat: {
    unreadNotifications: NotificationInfoFragment[]
    readNotifications: NotificationInfoFragment[]
  } = {
    unreadNotifications: [],
    readNotifications: [],
  }

  return (
    notifications.reduce(
      ({ unreadNotifications, readNotifications }, notification) => {
        if (notification.isRead) {
          readNotifications.push(notification)
        } else {
          unreadNotifications.push(notification)
        }

        return { unreadNotifications, readNotifications }
      },
      notificationsFormat
    ) || notificationsFormat
  )
}
