import React, { useEffect, useRef, useContext } from 'react'

import { dataIdFromObject } from '@sketch/graphql-cache'
import {
  useForDesktop,
  handleFetchMore,
  LoadPageSeparator,
} from '@sketch/components'
import { useFlag } from '@sketch/modules-common'
import { noop } from '@sketch/utils'

import CommentNewSeparator from '../CommentNewSeparator'
import AnnotationsSkeleton from '../AnnotationsSkeleton'
import CommentSkeleton from '../CommentSkeleton'
import AnnotationCommentBox from '../AnnotationCommentBox'
import { CommentEditingProvider } from '../CommentEditContext'
import CommentOptions from '../CommentOptions'
import AnnotationPopoverResolutionFooter from '../AnnotationPopoverResolutionFooter'

import { DraftCommentContext } from '../DraftCommentContext'
import useGetAnnotationComments from '../../operations/useGetAnnotationComments'
import useCreateAnnotationComment from '../../operations/useCreateAnnotationComment'
import useMarkCommentAsRead from '../../operations/useMarkCommentAsRead'
import useMarkCommentsAsReadList, {
  createCommentProps,
} from '../../hooks/useMarkCommentsAsReadList'

import { isCommentNew } from '../../utils'
import { useActiveAnnotation } from '../../hooks'

import {
  AnnotationComment,
  AnnotationCommentsWrapper,
  AnnotationFirstCommentSeparator,
} from './AnnotationCommentsList.styles'

export type CommentType = 'FLOATING' | 'FIXED'

interface AnnotationCommentsListProps {
  annotationIdentifier: string
  isViewingLatestVersion: boolean
  header?: React.ReactNode
  cacheOnly?: boolean
  commentType: CommentType
}

const ENTRIES_PATH = ['annotation', 'comments', 'entries']
const LOADING_PAGE_SIZE = 6

/**
 * __AnnotationCommentsList__
 *
 * This component displays an incrementally-loaded list of annotation comments.
 *
 * These can be rendered in one of two ways: in a popover as part of a
 * *floating* annotation, or as *fixed* annotations in the sidebar. Use the
 * `commentType` prop to choose how comments should be rendered.
 */
const AnnotationCommentsList = (props: AnnotationCommentsListProps) => {
  const {
    annotationIdentifier,
    header,
    cacheOnly,
    commentType,
    isViewingLatestVersion,
  } = props
  const listRef = useRef<HTMLDivElement>(null)
  const includeHasUnreadComments = useFlag('pages-unread-annotations')

  const isDesktopAndBigger = useForDesktop()
  const shouldAutoFocusInput = isDesktopAndBigger

  const { loading, data, error, fetchMore } = useGetAnnotationComments(
    { annotationIdentifier },
    cacheOnly
  )

  const { getDraftComment, setDraftComment } = useContext(DraftCommentContext)

  const draftComment = getDraftComment(annotationIdentifier)

  const createComment = useCreateAnnotationComment(annotationIdentifier)
  const observe = useMarkCommentsAsReadList()
  const [activeAnnotation] = useActiveAnnotation()

  const commentIdentifierSeparator = useRef<string | null>(null)

  useEffect(() => {
    /**
     *  Reset the "commentIdentifierSeparator" if the "annotationIdentifier" changes
     *  meaning it will be a different list
     */
    commentIdentifierSeparator.current = null
  }, [annotationIdentifier])

  /**
   * Mark the first comment as read, if it isn't,
   * since the first comment influences the "annotation.isRead"
   */
  const markCommentAsRead = useMarkCommentAsRead()
  useEffect(() => {
    if (data?.annotation?.isNew) {
      markCommentAsRead([data.annotation.firstComment.identifier])
    }
  }, [data?.annotation, markCommentAsRead])

  /**
   * Mark replies as read if this is the active annotation when this component unmounts
   */
  useEffect(() => {
    return () => {
      if (data?.annotation?.identifier === activeAnnotation) {
        const unreadCommentIdentifiers =
          data?.annotation?.comments?.entries
            ?.filter(comment => !comment.isRead)
            .map(comment => comment.identifier) || []
        markCommentAsRead(unreadCommentIdentifiers)
      }
    }
  }, [activeAnnotation, data, markCommentAsRead])

  const after = data?.annotation?.comments.meta.after

  if (loading || error || !data) {
    return (
      <>
        {header}
        <AnnotationsSkeleton
          error={error}
          placeholderCount={commentType === 'FLOATING' ? 1 : undefined}
        />
        {!error && (
          <AnnotationCommentBox
            shareIdentifier=""
            onCommentSubmit={noop}
            showOlderVersionWarning={false}
          />
        )}
      </>
    )
  }

  const {
    comments,
    share,
    firstComment: firstAnnotationComment,
    resolution,
  } = data.annotation!

  const { entries, meta } = comments
  const [firstComment] = entries
  const hasReplies = meta.totalCount > 1

  /* Render the comments list */
  const renderedComments = comments.entries.map(comment => {
    /**
     * Save the comment identifier on the ref
     * so when the comments is marked as read we don't
     * loose the reference to it.
     */
    if (
      !commentIdentifierSeparator.current &&
      isCommentNew(firstComment) &&
      !isCommentNew(comment)
    ) {
      commentIdentifierSeparator.current = comment.identifier
    }

    const isFirstComment =
      firstAnnotationComment.identifier === comment.identifier

    const showOptions =
      (comment.userIsCreator || comment.userCanDelete) &&
      !(commentType === 'FLOATING' && isFirstComment)

    return (
      <React.Fragment key={comment.identifier}>
        <AnnotationComment
          showPressedState={commentType !== 'FLOATING'}
          body={comment.body}
          user={comment.user}
          identifier={comment.identifier}
          createdAt={comment.createdAt!}
          edited={comment.isEdited}
          optionsMenu={className =>
            showOptions ? (
              <CommentOptions
                className={className}
                annotationIdentifier={annotationIdentifier}
                commentIdentifier={comment.identifier}
                isFirstComment={isFirstComment}
                hasReplies={hasReplies}
                isAnnotationResolved={!!resolution}
              />
            ) : null
          }
          ref={ref => ref && observe(ref)}
          data-first-comment={isFirstComment}
          {...createCommentProps(comment.identifier, !comment.isRead)}
        />

        {/* Render the annotation list if it hasn't been rendered already */}
        {commentIdentifierSeparator.current === comment.identifier &&
          hasReplies && <CommentNewSeparator />}

        {isFirstComment && hasReplies && <AnnotationFirstCommentSeparator />}
      </React.Fragment>
    )
  })

  return (
    <CommentEditingProvider>
      <AnnotationCommentsWrapper ref={listRef}>
        {/**
         * The header is included inside the list rendering
         * so we take advance of position sticky and show a
         * border bellow the header when there's more items
         * to load
         */}
        {header}

        {/**
         * The CommentSkeleton renders the annotation placeholders
         */}
        <CommentSkeleton
          count={Math.min(LOADING_PAGE_SIZE, meta.totalCount - entries.length)}
        />

        {after && (
          <LoadPageSeparator
            key={after}
            loadNewPage={handleFetchMore(fetchMore, ENTRIES_PATH, {
              dataIdFromObject,
              after,
            })}
          />
        )}

        {[...renderedComments].reverse()}
      </AnnotationCommentsWrapper>
      {resolution ? (
        <AnnotationPopoverResolutionFooter
          user={resolution.user}
          resolvedDate={resolution.resolvedAt!}
        />
      ) : (
        <AnnotationCommentBox
          shareIdentifier={share.identifier}
          showOlderVersionWarning={!isViewingLatestVersion}
          comment={draftComment?.commentValue}
          onCommentChange={(commentValue: string) => {
            setDraftComment({
              parentAnnotationId: annotationIdentifier,
              commentValue,
            })
          }}
          onCommentSubmit={body => {
            setDraftComment({
              parentAnnotationId: annotationIdentifier,
              commentValue: '',
            })
            // Create the comment
            createComment({
              variables: {
                identifier: annotationIdentifier,
                body,
                includeHasUnreadComments,
              },
            })

            // Scroll to the end
            setTimeout(() => {
              listRef.current?.scrollTo({
                top: listRef.current.scrollHeight,
                behavior: 'smooth',
              })
            }, 0)
          }}
          isReply
          autoFocus={shouldAutoFocusInput}
        />
      )}
    </CommentEditingProvider>
  )
}

export default AnnotationCommentsList
