import React, { useState } from 'react'
import {
  PopoverChildrenProps,
  Popover,
  CopyToClipboard,
} from '@sketch/components'
import ColorDropdown from '../../../ColorDropdown'
import ConnectedColorDot from './ConnectedColorDot'
import {
  Stops,
  TextNoWrap,
  StopContainer,
  StopPercentage,
  Alpha,
} from './GradientPopover.styles'
import rgbTo, { ColorFormat } from '../../../Color/utils'
import { Gradient } from '../../../../../../types'

interface StopProps {
  stopPosition: number
  red: number
  green: number
  blue: number
  alpha: number
  colorFormat: ColorFormat
  onColorFormatChange: (f: ColorFormat) => void
  connected: boolean
}

const Stop: React.FC<StopProps> = ({
  stopPosition,
  red,
  green,
  blue,
  alpha,
  colorFormat,
  onColorFormatChange,
  connected,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const alphaPercentage = `${(alpha * 100).toFixed()}%`
  const copyValue = rgbTo(colorFormat, { red, green, blue, alpha })

  return (
    <TextNoWrap fontSize="D">
      <CopyToClipboard
        value={copyValue}
        placement="left"
        disabled={dropdownVisible}
        showIcon={false}
        dropdown={
          <ColorDropdown
            onChange={newFormat => {
              onColorFormatChange(newFormat)
            }}
            dropdownOpen={setDropdownVisible}
          />
        }
      >
        <StopContainer>
          <StopPercentage>{`${Math.round(
            stopPosition * 100
          )}%`}</StopPercentage>
          <ConnectedColorDot
            red={red}
            green={green}
            blue={blue}
            alpha={alpha}
            format={colorFormat}
            connected={connected}
          />
          {alpha !== undefined &&
            alpha !== 1 &&
            colorFormat === ColorFormat.HEX && (
              <Alpha>(Alpha: {alphaPercentage})</Alpha>
            )}
        </StopContainer>
      </CopyToClipboard>
    </TextNoWrap>
  )
}

const gradientColors: (
  gradient: Gradient,
  colorModifiers: {
    onColorFormatChange: (f: ColorFormat) => void
    colorFormat: ColorFormat
  }
) => React.ReactNode[] = (gradient, colorModifiers) => {
  const stopsLength = gradient.stops.length
  const stops = gradient.stops
    .sort((lhs, rhs) => lhs.position - rhs.position)
    // Remove duplicated stops (same position, same color)
    .filter(
      (stop, index, self) =>
        index === self.findIndex(t => t.position === stop.position)
    )
    .map((stop, i) => {
      const connected = stopsLength !== i + 1

      return (
        <Stop
          stopPosition={stop.position}
          red={stop.color.red}
          green={stop.color.green}
          blue={stop.color.blue}
          alpha={stop.color.alpha}
          connected={connected}
          key={stop.position}
          {...colorModifiers}
        />
      )
    })
  return stops
}

interface GradientPopoverProps {
  toggle: PopoverChildrenProps
  gradient: Gradient
  visible: boolean
  onColorFormatChange: (f: ColorFormat) => void
  colorFormat: ColorFormat
  hasScrollBar?: boolean
  onClickOutside?: () => void
  copyValue: string
  dropdown?: React.ReactElement
}

const GradientPopover: React.FC<GradientPopoverProps> = ({
  toggle,
  gradient,
  visible,
  onColorFormatChange,
  colorFormat,
  hasScrollBar,
  onClickOutside,
  copyValue,
  dropdown,
  ...props
}) => {
  return (
    <Popover
      visible={visible}
      placement="top"
      onClickOutside={onClickOutside}
      popup={
        <>
          <Stops>
            {gradientColors(gradient, { onColorFormatChange, colorFormat })}
            <TextNoWrap fontSize="D" marginTop="8px">
              <CopyToClipboard
                title="Copy All"
                value={copyValue}
                dropdown={dropdown}
              />
            </TextNoWrap>
          </Stops>
        </>
      }
      spacing="18px"
      data-testid="gradient-popover"
      {...props}
    >
      {toggle}
    </Popover>
  )
}

export default GradientPopover
