export type Line = {
  x: number
  y: number
  size: number
}

export const enum Orientation {
  Horizontal,
  Vertical,
}
