import styled, { css } from 'styled-components'

import { Section as CreditCardSection } from '../../components/CreditCardForm/CreditCardForm.styles'
import { Section } from '../../components/CreateWorkspaceCommon'
import { WorkspaceLogo, Form as FormUnstyled, Banner } from '@sketch/components'
import { H1 as OriginalH1 } from '../../views/WorkspaceSubscribeView/WorkspaceSubscribeView.styles'

import { breakpoint } from '@sketch/global-styles'

export const H1 = styled(OriginalH1)<{ $variant: 'default' | 'partner' }>`
  ${({ $variant }) => css`
    margin: ${$variant === 'default' ? '32px 0 40px' : '32px 0 64px'};
  `}
`

export const Form = styled(FormUnstyled)`
  display: flex;
`

export const Layout = styled.div`
  display: grid;
  grid-template-columns: 448px 450px;
  grid-column-gap: 146px; /* stylelint-disable-line scales/space */
  margin: 0 auto;

  ${breakpoint('base', 'lg')`
    grid-template-columns: auto;
  `}
`

export const PaymentFormContainer = styled.div`
  margin: 24px 0;

  /***
    TODO: Remove this and remove the Section element from
    PaymentMethodForm.styles after removing the onboarding-licenses-signup feature flag.
    https://github.com/sketch-hq/Cloud/issues/10012
   */
  ${CreditCardSection} {
    padding: 0;
  }
`

export const BillingDetailsFormFormContainer = styled.div`
  margin-bottom: 14px; /* stylelint-disable-line scales/space */

  ${Section} {
    :first-child {
      margin-top: 24px;
      padding-top: 0;
    }
  }
`

export const SummaryWrapper = styled.div`
  width: 100%;

  position: fixed;
  bottom: 0;

  ${breakpoint('sm', 'md')`
    max-width: 450px;
  `}

  ${breakpoint('md')`
    position: sticky;
      bottom: 0px;
      top: 60px;
      align-self: self-start;
      width: 450px;
      margin-left: 146px;
  `}
`

export const TermsOfServiceSection = styled(Section)`
  position: relative;
  border-bottom: 0;
  padding: 24px 0;
`

export const Sticky = styled.div`
  ${({ theme }) => css`
    position: sticky;
    top: calc(${theme.header.height}px + 32px);
  `}
`

export const BillSummarySection = styled.div<{
  $variant: 'default' | 'partner'
}>`
  ${({ $variant }) => css`
    margin-top: ${$variant === 'partner' && '42px'};

    ${breakpoint('base', 'lg')`
      margin-top: ${$variant === 'default' ? '64px' : '42px'};
    `}
  `}
`

// Partners
export const WorkspaceLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledLogo = styled(WorkspaceLogo)`
  background: ${({ theme }) => theme.colors.sketch.A};

  span {
    color: ${({ theme }) => theme.colors.foreground.primary.A};
  }
`

export const WorkspaceName = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.E};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-left: 14px; /* stylelint-disable-line scales/space */
`

export const PartnersInvoicePaymentsInfo = styled(Banner)`
  margin-top: 20px;
  margin-bottom: 8px;
`

export const ManageSeatsSection = styled.div`
  ${css`
    ${breakpoint('base', 'lg')`
      margin-top: 64px;
    `}
  `}
`

export const ReduceSeatsWarning = styled(Banner)`
  margin-top: 24px;
`
