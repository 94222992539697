import React, { useState, useEffect, ReactNode } from 'react'
import { useRouteMatch } from 'react-router-dom'

import {
  useForTablet,
  useForBigScreen,
  SidebarLayout,
} from '@sketch/components'

import { isCwvRouteOnly } from '../../routes'
import { useBrowserSettings } from '../../browser'

export interface DocumentSidebarLayoutHeaderPropsOld {
  isSidebarRightOpen: boolean
  toggleSidebarRight: () => void
  isSidebarLeftOpen: boolean
  setSidebarLeftOpen: React.Dispatch<React.SetStateAction<boolean>>
}

type DocumentSidebarProps = {
  title?: string
  children: ReactNode
  footer?: ReactNode
  header: React.FC<DocumentSidebarLayoutHeaderPropsOld> | ReactNode
  /** Navigation sidebar */
  sidebarLeft?: ReactNode
  /** Activity/inspector sidebar */
  sidebarRight?: ReactNode
  darkBackground?: boolean
}

export const DocumentSidebarLayoutOld = ({ ...rest }: DocumentSidebarProps) => {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false)
  const isTabletAndBigger = useForTablet()
  const isDesktopAndBigger = useForBigScreen()
  const toggleMobileSidebar = () => setIsMobileSidebarOpen(isOpen => !isOpen)

  const [data, updateBrowserSettings] = useBrowserSettings()
  const isSidebarRightOpen = data?.sidebarRightOpen

  // For Components Web View pages on tablet we hide the sidebar because we
  // prioritise the left menu sidebar
  const { path } = useRouteMatch()
  const shouldHideRightSidebar =
    isTabletAndBigger && !isDesktopAndBigger && isCwvRouteOnly(path)

  const toggleSidebarRight = () => {
    updateBrowserSettings({
      sidebarRightOpen: !isSidebarRightOpen,
    })
  }

  useEffect(() => {
    if (isSidebarRightOpen && shouldHideRightSidebar) {
      toggleSidebarRight?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isOpen = isTabletAndBigger ? isSidebarRightOpen : isMobileSidebarOpen
  const toggle = isTabletAndBigger ? toggleSidebarRight : toggleMobileSidebar

  return (
    <SidebarLayout
      isSidebarRightOpen={isOpen}
      toggleSidebarRight={toggle}
      {...rest}
    />
  )
}
