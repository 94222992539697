import React from 'react'
import styled from 'styled-components'

import { PersonAvatar, Pluralize } from '@sketch/components'

import { CommentUserFragment } from '@sketch/gql-types'

import {
  Details,
  TextWrapper,
  Wrapper,
  CommentText,
  Markdown,
  PersonAvatarWrapper,
  AuthorName,
  AuthorNameWrapper,
  AnnotationOptionsWrapper,
} from './Annotation.styles'

import CommentDate from '../CommentDate'

export interface AnnotationProps {
  className?: string
  body: string
  user: CommentUserFragment
  commentNumber: number
  createdAt: string
  resolvedAt?: string
  hasNewComments: boolean
  isNew: boolean
  optionsMenu?: (className?: string) => React.ReactElement | null
}

const Annotation: React.FC<AnnotationProps> = props => {
  const {
    className,
    body,
    user,
    commentNumber,
    createdAt,
    resolvedAt,
    hasNewComments,
    isNew,
    optionsMenu,
  } = props
  let commentText = null

  const isResolved = Boolean(resolvedAt)

  if (isNew) {
    commentText = <CommentText data-highlight>New</CommentText>
  } else if (commentNumber > 1) {
    commentText = (
      <CommentText data-highlight={hasNewComments}>
        <Pluralize
          count={commentNumber - 1}
          singular="Reply"
          plural="Replies"
          wrapperElement={React.Fragment}
          showCount
        />
      </CommentText>
    )
  }

  return (
    <Wrapper
      className={className}
      data-testid="annotation"
      data-resolved={isResolved}
    >
      <AnnotationOptionsWrapper>{optionsMenu}</AnnotationOptionsWrapper>
      <TextWrapper>
        <PersonAvatarWrapper>
          <PersonAvatar
            flavour="image"
            size="16px"
            src={user.avatar!.small!}
            name={user.name!}
          />
        </PersonAvatarWrapper>
        <AuthorNameWrapper>
          <AuthorName>
            {/* Show only the first name of a user */}
            {user.name?.split(' ')[0]}
          </AuthorName>
        </AuthorNameWrapper>
        <Markdown data-testid="annotation-body">{body}</Markdown>
      </TextWrapper>

      <Details>
        {commentText}
        <CommentText>
          <CommentDate date={resolvedAt || createdAt} isResolved={isResolved} />
        </CommentText>
      </Details>
    </Wrapper>
  )
}

export default styled(Annotation)``
