import React, { useState, FC } from 'react'
import { useRouteMatch } from 'react-router-dom'

import {
  routes,
  isCwvSpecificRoute,
  isCwvRouteOnly,
  useFlag,
} from '@sketch/modules-common'
import { getGroupFromURL, prepareGroupURL } from '../../ComponentsWebView/utils'
import { useOnEvent } from '@sketch/utils'

import VersionLinkButton from '../VersionLinkButton'
import { NavbarItem } from '../NavbarItem'
import { Tooltip } from '@sketch/components'
import { ConsolidateVersionButton } from './ConsolidateVersionButton'

interface LatestVersionButtonProps {
  shareID: string
  pageUUID?: string
  isViewingLatestVersion: boolean
  isViewingStarredVersion: boolean
  userCanEdit: boolean
  hasPendingPatches: boolean
}

const getURL = (path: string, shareID: string) => {
  if (isCwvSpecificRoute(path, 'CWV_SYMBOLS')) {
    return routes.CWV_SYMBOLS.create({ shareID })
  }

  if (isCwvSpecificRoute(path, 'CWV_TEXT_STYLES')) {
    return routes.CWV_TEXT_STYLES.create({ shareID })
  }

  if (isCwvSpecificRoute(path, 'CWV_LAYER_STYLES')) {
    return routes.CWV_LAYER_STYLES.create({ shareID })
  }

  if (isCwvSpecificRoute(path, 'CWV_COLOR_VARIABLES')) {
    return routes.CWV_COLOR_VARIABLES.create({ shareID })
  }

  return routes.SHARE_VIEW.create({ shareID })
}

type VersionStatus =
  | 'triggered' // when the user triggers a new version bump (for example editing a component description)
  | 'uploaded' // when the version has been upload successfully, but not processed
  | 'processed' // when the version is already processed and ready to be accessed

export const VersionStatusButton: FC<LatestVersionButtonProps> = ({
  shareID,
  isViewingLatestVersion,
  isViewingStarredVersion,
  userCanEdit,
  hasPendingPatches,
  pageUUID,
}) => {
  const isFrontendConsolidateOn = useFlag('frontend-consolidate')
  const [versionState, setVersionState] = useState<VersionStatus>('processed')
  const { path } = useRouteMatch()

  const userCanConsolidate =
    userCanEdit || (!userCanEdit && !isViewingStarredVersion)

  const shouldHideButton =
    versionState === 'processed' &&
    isViewingLatestVersion &&
    (!hasPendingPatches || !userCanConsolidate || !isFrontendConsolidateOn)

  const shouldShowConsolidateButton =
    isFrontendConsolidateOn &&
    isViewingLatestVersion &&
    versionState === 'processed' &&
    hasPendingPatches

  useOnEvent('versionIsTriggeredFromComponentButNotSymbol', () => {
    setVersionState('triggered')
  })

  useOnEvent('versionIsUploaded', () => {
    setVersionState('uploaded')
  })

  useOnEvent('versionIsProcessed', () => {
    setVersionState('processed')
  })

  let groupParam = ''

  // include group name on the URL if we're in a cwv path
  const isCWVRoute = isCwvRouteOnly(path)

  if (isCWVRoute) {
    const groupsString = getGroupFromURL()

    if (groupsString) {
      groupParam = `?${prepareGroupURL(groupsString.split('/'))}`
    }
  }

  // Default state, no need to show any button or loading button
  if (shouldHideButton) return null

  if (shouldShowConsolidateButton) {
    return (
      <NavbarItem>
        <ConsolidateVersionButton shareIdentifier={shareID} />{' '}
      </NavbarItem>
    )
  }

  // When the user is not on the lastest version, a loading button is shown
  // when a version is being uploaded or processed
  if (versionState !== 'processed') {
    return (
      <NavbarItem>
        <Tooltip
          content={`New version is being ${
            versionState === 'uploaded' ? 'processed' : 'uploaded'
          }`}
          placement="bottom"
        >
          <VersionLinkButton to="" loading variant="primary">
            -
          </VersionLinkButton>
        </Tooltip>
      </NavbarItem>
    )
  }

  const route = pageUUID
    ? routes.SHARE_PAGE_CANVAS_VIEW.create({
        shareID: shareID,
        pageUUID: pageUUID,
      })
    : `${getURL(path, shareID)}${groupParam}`

  // When the user is not on the lastest version, a button to navigate to
  // latest version is shown if the new version is ready
  return (
    <NavbarItem>
      <VersionLinkButton to={route} variant="primary">
        See Latest Version
      </VersionLinkButton>
    </NavbarItem>
  )
}
