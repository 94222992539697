import React, { FC } from 'react'

import { Image as RawImage, ImageProps } from '@sketch/components'
import { useAnalytics } from '../../analytics'

/**
 * Wrapper for Image component that triggers an ananlytics event when the
 * component loads.
 */
export const Image: FC<ImageProps> = props => {
  const { pageLoad } = useAnalytics()

  const handleImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    props.onLoad?.(event)

    pageLoad.observeCustomElement({
      element: event.currentTarget,
      identifier: 'image',
    })
  }

  return <RawImage {...props} onLoad={handleImageLoad} />
}
