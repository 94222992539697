import React, { FC, useRef } from 'react'
import { EventEmitter } from './EventEmitter'
import { EventsMap } from './EventEmitter.types'

interface EventEmitterValues {
  events: EventEmitter<EventsMap>
}

export const EventEmitterContext = React.createContext<EventEmitterValues>({
  events: new EventEmitter(),
})

export const EventEmitterProvider: FC = ({ children }) => {
  const eventsRef = useRef(new EventEmitter<EventsMap>())
  return (
    <EventEmitterContext.Provider value={{ events: eventsRef.current }}>
      {children}
    </EventEmitterContext.Provider>
  )
}
