import { useState, useEffect, useCallback } from 'react'

import { useToast } from '@sketch/toasts'

import {
  ParsedError,
  useCheckWorkspaceProfileShortUrlMutation,
  useUpdateWorkspaceProfileShortUrlMutation,
} from '@sketch/gql-types'

export type CurrentStatus =
  | 'LOADING'
  | 'ERROR'
  | 'SUCCESS'
  | 'EMPTY'
  | 'UNAVAILABLE'

export const useProfileShortURL = (shortUrlName: string) => {
  const { showToast } = useToast()

  const [hasNewUrl, setHasNewUrl] = useState(false)
  const [currentShortUrl, setCurrentShortUrl] = useState(shortUrlName)
  const [currentStatus, setCurrentStatus] = useState<CurrentStatus>('EMPTY')

  const handleOnError = useCallback(
    (error: ParsedError) => {
      if (error.includesErrorReason('FORBIDDEN_TERM')) {
        setCurrentStatus('UNAVAILABLE')
        return
      } else {
        showToast(error.message, 'negative')
      }

      setCurrentStatus('ERROR')
    },
    [showToast]
  )

  const [
    checkWorkspaceProfileShortUrl,
    { loading: isLoadingCheckURL },
  ] = useCheckWorkspaceProfileShortUrlMutation({
    onCompleted: ({ checkWorkspaceProfileShortUrl }) => {
      const { available } = checkWorkspaceProfileShortUrl

      setCurrentStatus(available ? 'SUCCESS' : 'UNAVAILABLE')
    },
    onError: handleOnError,
  })

  const [
    updateWorkspaceProfileShortUrl,
    { loading: isSavingShortUrl },
  ] = useUpdateWorkspaceProfileShortUrlMutation({
    onCompleted: ({ updateWorkspaceProfileShortUrl }) => {
      const { shortUrlName } = updateWorkspaceProfileShortUrl.workspaceProfile
      setCurrentShortUrl(shortUrlName)
      setHasNewUrl(false)
      setCurrentStatus('EMPTY')
      showToast('URL updated')
    },
    onError: handleOnError,
  })

  const isCheckingURL = isLoadingCheckURL || currentStatus === 'LOADING'
  const isUrlAvailable = currentStatus !== 'UNAVAILABLE'
  const hasErrorStatus = currentStatus === 'ERROR'
  const isButtonDisabled =
    isCheckingURL || !isUrlAvailable || !hasNewUrl || hasErrorStatus

  // We keep track of the loading set the currentStatus accordingly
  useEffect(() => {
    const isCurrentlyCheckingUrl = currentStatus === 'LOADING'

    if (isLoadingCheckURL && !isCurrentlyCheckingUrl) {
      setCurrentStatus('LOADING')
    }
  }, [currentStatus, isLoadingCheckURL])

  return {
    currentShortUrl,
    checkWorkspaceProfileShortUrl,
    updateWorkspaceProfileShortUrl,
    setHasNewUrl,
    setCurrentStatus,
    currentStatus,
    isButtonDisabled,
    isSavingShortUrl,
    isUrlAvailable,
    hasNewUrl,
  }
}
