import React from 'react'

import { useResponsiveDropdown } from '@sketch/components'

import Selector from '../Selector'
import AnnotationOptionsDropdown from '../AnnotationOptionsDropdown'
import ResolveAnnotationButton from '../ResolveAnnotationButton'

import { ReactComponent as DotIcon } from '@sketch/icons/dots-3-horizontal-16'

import {
  AnnotationOptionsBackground,
  AnnotationOptionsWrapper,
} from './AnnotationInboxOptions.styles'

const DROPDOWN_MODIFIERS = [
  { name: 'computeStyles', options: { adaptive: true, gpuAcceleration: true } },
]

const optionsWrapperOnClick = (event: React.MouseEvent) => {
  event.stopPropagation()
  event.preventDefault()
}

interface AnnotationInboxOptionsProps
  extends React.ComponentProps<typeof AnnotationOptionsDropdown> {
  className?: string
}

const AnnotationInboxOptions = ({
  className,
  ...dropdownProps
}: AnnotationInboxOptionsProps) => {
  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: AnnotationOptionsDropdown,
    dropdownProps,
    placement: 'bottom-end',
    modifiers: DROPDOWN_MODIFIERS,
  })

  return (
    <>
      <AnnotationOptionsWrapper
        className={className}
        aria-expanded={buttonProps['aria-expanded']}
        onClick={optionsWrapperOnClick}
      >
        <ResolveAnnotationButton
          annotationIdentifier={dropdownProps.annotationIdentifier}
          isResolved={!!dropdownProps.isResolved}
        />

        <Selector data-testid="annotation-options" {...buttonProps}>
          <DotIcon role="img" />
          <span className="sr-only">Options</span>
        </Selector>

        <AnnotationOptionsBackground />
      </AnnotationOptionsWrapper>
      {content}
    </>
  )
}

export default AnnotationInboxOptions
