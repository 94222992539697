import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "group-circle-16", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("path", { d: "M7.245 0c1.55 0 2.708.278 3.686.801a5.452 5.452 0 012.268 2.268c.523.978.801 2.136.801 3.686v.49c0 1.55-.278 2.708-.801 3.686a5.452 5.452 0 01-2.268 2.268c-.978.523-2.136.801-3.686.801h-.49c-1.55 0-2.708-.278-3.686-.801A5.452 5.452 0 01.801 10.93C.278 9.953 0 8.795 0 7.245v-.49c0-1.55.278-2.708.801-3.686A5.452 5.452 0 013.07.801C4.047.278 5.205 0 6.755 0h.49z", id: "group-circle-16_svg__a" })),
    React.createElement("g", { transform: "translate(1 1)", fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M7.245 0c1.55 0 2.708.278 3.686.801a5.452 5.452 0 012.268 2.268c.523.978.801 2.136.801 3.686v.49c0 1.55-.278 2.708-.801 3.686a5.452 5.452 0 01-2.268 2.268c-.978.523-2.136.801-3.686.801h-.49c-1.55 0-2.708-.278-3.686-.801A5.452 5.452 0 01.801 10.93C.278 9.953 0 8.795 0 7.245v-.49c0-1.55.278-2.708.801-3.686A5.452 5.452 0 013.07.801C4.047.278 5.205 0 6.755 0h.49zm0 1.5h-.49c-1.27 0-2.198.207-2.978.624a3.952 3.952 0 00-1.653 1.653c-.382.715-.588 1.554-.62 2.667l-.004.31v.491c0 1.27.207 2.198.624 2.978.383.717.936 1.27 1.653 1.653.715.382 1.554.588 2.667.62l.31.004h.491c1.27 0 2.198-.207 2.978-.624a3.952 3.952 0 001.653-1.653c.382-.715.588-1.554.62-2.667l.004-.31v-.491c0-1.27-.207-2.198-.624-2.978a3.952 3.952 0 00-1.653-1.653c-.715-.382-1.554-.588-2.667-.62l-.31-.004z", fill: "currentColor", fillRule: "nonzero" }),
        React.createElement("mask", { id: "group-circle-16_svg__b", fill: "#fff" },
            React.createElement("use", { xlinkHref: "#group-circle-16_svg__a" })),
        React.createElement("path", { d: "M5 10.25c2.258 0 4.235 1.057 5.073 2.614-.309.46-.69.85-1.144 1.165C8.587 12.77 6.988 11.75 5 11.75c-1.988 0-3.587 1.018-3.931 2.278a4.395 4.395 0 01-1.142-1.163C.765 11.307 2.742 10.25 5 10.25zm0-5.5a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm0 1.5a1 1 0 100 2 1 1 0 000-2z", fill: "currentColor", fillRule: "nonzero", mask: "url(#group-circle-16_svg__b)" }),
        React.createElement("path", { d: "M5 11c2.144 0 3.956 1.057 4.547 2.51a4.498 4.498 0 01-1.088.807c-.842.45-1.842.683-3.214.683-2.022-.039-2.93-.27-3.704-.683a4.506 4.506 0 01-1.088-.807C1.043 12.057 2.856 11 5 11zm0-5.5A1.75 1.75 0 115 9a1.75 1.75 0 010-3.5z", fillOpacity: 0.15, fill: "currentColor", fillRule: "nonzero", mask: "url(#group-circle-16_svg__b)" }),
        React.createElement("path", { d: "M11 8.25c2.258 0 4.235 1.057 5.073 2.614-.309.46-.69.85-1.144 1.165C14.587 10.77 12.988 9.75 11 9.75c-1.988 0-3.587 1.018-3.931 2.278a4.395 4.395 0 01-1.142-1.163C6.765 9.307 8.742 8.25 11 8.25zm0-5.5a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm0 1.5a1 1 0 100 2 1 1 0 000-2z", fill: "currentColor", fillRule: "nonzero", mask: "url(#group-circle-16_svg__b)" }),
        React.createElement("path", { d: "M11 9c2.144 0 3.956 1.057 4.547 2.51a4.498 4.498 0 01-1.088.807c-.842.45-1.842.683-3.214.683-2.022-.039-2.93-.27-3.704-.683a4.506 4.506 0 01-1.088-.807C7.043 10.057 8.856 9 11 9zm0-5.5A1.75 1.75 0 1111 7a1.75 1.75 0 010-3.5z", fillOpacity: 0.15, fill: "currentColor", fillRule: "nonzero", mask: "url(#group-circle-16_svg__b)" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
