import { NotificationMarkAllAsReadMutation } from '@sketch/gql-types'
import { QueryOptions } from 'apollo-client'
export const notificationMarkAllAsReadOptimisticResponse: OmitSafe<
  NotificationMarkAllAsReadMutation,
  '__typename'
> = {
  notificationMarkAllAsRead: {
    __typename: 'NotificationMarkAllAsReadPayload',
    successful: true,
    errors: [],
  },
}

export const getNotificationsQueryOptions: OmitSafe<QueryOptions, 'query'> = {
  fetchPolicy: 'network-only',
  // Ignore `FORBIDDEN` errors for notifications where you've lost access to the share
  // ref: https://github.com/sketch-hq/Sketch/issues/23202
  errorPolicy: 'all',
}
