import styled from 'styled-components'

export const ModalExplainer = styled.aside(
  ({ theme }) => `
  background-color: ${theme.colors.state.neutral.F};
  padding: 16px;
  border-radius: ${theme.radii.xxlarge};

  font-size: ${theme.fontSizes.D};
  line-height: ${theme.lineHeights.E};
  /* This can't be one of our foreground tokens because the banner keeps the
      same background for any theme, so we always want the text to be dark */
  color: black
`
)
