import { useLocation } from 'react-router'
import { sessionStorageKeys } from '@sketch/constants'

export const getIsForInAppPresentation = () => _IS_FOR_IN_APP_PRESENTATION

const setIsForInAppPresentation = (value: boolean) => {
  _IS_FOR_IN_APP_PRESENTATION = value
}

const getSessionValue = (): boolean => {
  const storageValue = window.sessionStorage.getItem(
    sessionStorageKeys.isForInAppPresentation
  )
  return storageValue ? JSON.parse(storageValue) : false
}

let _IS_FOR_IN_APP_PRESENTATION = getSessionValue() // Don't mutate this directly. Thanks :D

/**
 *
 * useSketchSource
 *
 * This hook sets a SessionStorage key "isForInAppPresentation" to true when the query params
 * have "source=sketch". This indicates that the user is using the in-app browser (iOS App, Mac App, ...)
 * and we use this to conditionally render some buttons in various views.
 */

export const useSketchSource = () => {
  const { search } = useLocation()

  const queryParams = new URLSearchParams(search)
  const currentSessionStorageValue = getSessionValue()

  setIsForInAppPresentation(currentSessionStorageValue)

  if (queryParams.has('source') && queryParams.get('source') === 'sketch') {
    if (!getIsForInAppPresentation()) {
      window.sessionStorage.setItem(
        sessionStorageKeys.isForInAppPresentation,
        'true'
      )
      setIsForInAppPresentation(true)
    }
  }
}

// We cannot use the "useSketchSource" as a hook in "App" because its not wrapped by <Router />
// We need this component (<SketchSource />) so we can use it inside <App /> return and allow
// the useSketchSource to use the location and history hooks
export const SketchSource = () => {
  useSketchSource()

  return null
}
