import React, { FC } from 'react'

import { useThemeContext } from '@sketch/global-styles'
import { WorkspaceApprovedModal } from '../WorkspaceApprovedModal'

import { ModalInjectedProps } from '@sketch/components'

// Assets
import Sticker from '@sketch/icons/sticker-animation-light@1x.webp'
import StickerDark from '@sketch/icons/sticker-animation-dark@1x.webp'
import Sticker2x from '@sketch/icons/sticker-animation-light@2x.webp'
import StickerDark2x from '@sketch/icons/sticker-animation-dark@2x.webp'

interface SubscriptionDoneModalProps extends ModalInjectedProps {
  onHide: () => void
}

const SubscriptionDoneModal: FC<SubscriptionDoneModalProps> = ({ onHide }) => {
  const { theme } = useThemeContext()

  return (
    <WorkspaceApprovedModal
      title="All Done!"
      buttonText="Go to Workspace"
      description={
        <>
          You can now start using Sketch.
          <br />
          Thanks for subscribing!
        </>
      }
      image={
        theme === 'light'
          ? { src: Sticker, src2x: Sticker2x }
          : { src: StickerDark, src2x: StickerDark2x }
      }
      hideModal={onHide}
    />
  )
}

export default SubscriptionDoneModal
