import React, { useCallback, useEffect, useState } from 'react'
import {
  AnnotationOverlayContext,
  DraftCommentProvider,
} from '../../../../annotations/components'
import {
  useLockCamera,
  useGetArtboardAtPosition,
  useMapRelativePositionToCameraPosition,
  useStatus,
} from '@sketch-hq/sketch-web-renderer'
import { MouseCoordinates } from '../../../../annotations/types'
import { useGetAnnotationSettingsQuery } from '@sketch/gql-types'

interface PageAnnotationsOverlayContextProps
  extends Pick<
    React.ComponentProps<typeof AnnotationOverlayContext>,
    'disabled' | 'children' | 'hidden'
  > {
  isViewingLatestVersion: boolean
  permanentPageId: string
}

const PageAnnotationsOverlayContext = (
  props: PageAnnotationsOverlayContextProps
) => {
  const { permanentPageId, hidden, ...otherProps } = props

  const status = useStatus()
  const lockCamera = useLockCamera()
  const [lockCameraState, setLockCameraState] = useState(false)

  useEffect(() => {
    lockCamera(lockCameraState)
  }, [lockCamera, lockCameraState])

  const getArtboardAtPosition = useGetArtboardAtPosition()
  const mapRelativePosition = useMapRelativePositionToCameraPosition()

  const getElementAtPosition = useCallback(
    async (mouseCoordinates: MouseCoordinates) => {
      const artboard = await getArtboardAtPosition(
        mouseCoordinates.clientX,
        mouseCoordinates.clientY
      )

      if (artboard) {
        return {
          type: 'ARTBOARD' as const,
          permanentId: artboard.getLayerUUID(),
          bounds: artboard.getAbsoluteExtent(),
          permanentPageId,
        }
      }

      return {
        type: 'PAGE' as const,
        permanentId: permanentPageId,
      }
    },
    [getArtboardAtPosition, permanentPageId]
  )

  const getPositionRelativeToOrigin = useCallback(
    (mouseCoordinates: MouseCoordinates) => {
      if (status.type !== 'READY') {
        return null
      }

      return mapRelativePosition(
        mouseCoordinates.clientX,
        mouseCoordinates.clientY
      )
    },
    [mapRelativePosition, status]
  )

  const { data: settings } = useGetAnnotationSettingsQuery()
  const { hideAnnotationsDots } = settings || {}

  return (
    <DraftCommentProvider>
      <AnnotationOverlayContext
        onMovingAnnotation={setLockCameraState}
        getPositionRelativeToOrigin={getPositionRelativeToOrigin}
        getElementAtPosition={getElementAtPosition}
        hidden={hideAnnotationsDots || hidden}
        {...otherProps}
      />
    </DraftCommentProvider>
  )
}

export default PageAnnotationsOverlayContext
