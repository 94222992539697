import React from 'react'

import { routes, useShareDrop, ShareDropPayload } from '@sketch/modules-common'
import { ToastLink } from '@sketch/components'

import { useToast } from '@sketch/toasts'
import { useAddSharesToCollection } from '../operations/useAddSharesToCollection'

import {
  CollectionPreviewsFragment,
  AddSharesToCollectionMutation,
} from '@sketch/gql-types'

interface UseCollectionDropSharesProps {
  projectIdentifier: string
  workspaceIdentifier: string
  collection: CollectionPreviewsFragment
}

export const useCollectionDropShares = ({
  projectIdentifier,
  workspaceIdentifier,
  collection,
}: UseCollectionDropSharesProps) => {
  const { showToast } = useToast()

  const addSharesToCollection = useAddSharesToCollection({
    projectIdentifier,
    onCompleted: () => {
      showToast(
        <>
          Document added to &quot;
          <ToastLink
            to={routes.WORKSPACE_COLLECTION.create({
              workspaceId: workspaceIdentifier,
              projectId: projectIdentifier,
              collectionId: collection.identifier,
            })}
          >
            {collection.name}
          </ToastLink>
          &quot; collection
        </>
      )
    },
  })

  return useShareDrop({
    onDropped: ({ shareId }: ShareDropPayload) => {
      // Include an optimistic response so that the UI responds immediately and
      // moves the share
      const optimisticResponse: AddSharesToCollectionMutation = {
        __typename: 'RootMutationType',
        addSharesToCollection: {
          __typename: 'AddSharesToCollectionResponse',
          collection: collection,
        },
      }

      addSharesToCollection({
        variables: {
          identifier: collection.identifier,
          shareIdentifiers: [shareId],
        },
        optimisticResponse,
      })
    },
  })
}
