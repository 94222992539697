import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "default-ds-icon", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 64 64", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "default-ds-icon_svg__a" },
            React.createElement("path", { d: "M59 0a5 5 0 015 5v54a5 5 0 01-5 5H5a5 5 0 01-5-5V5a5 5 0 015-5h54z" })),
        React.createElement("clipPath", { id: "default-ds-icon_svg__b" },
            React.createElement("path", { d: "M4.5 0a4.5 4.5 0 110 9 4.5 4.5 0 010-9z" })),
        React.createElement("clipPath", { id: "default-ds-icon_svg__c" },
            React.createElement("path", { d: "M1.827 4.236c.255.448.645.81 1.114 1.03l-.072.106A8.5 8.5 0 0013.6 17.702c.147.502.447.94.847 1.257A9.953 9.953 0 0110 20C4.477 20 0 15.523 0 10c0-2.146.676-4.135 1.827-5.764zM10 0c5.523 0 10 4.477 10 10a9.954 9.954 0 01-1.827 5.764 2.505 2.505 0 00-1.114-1.03A8.5 8.5 0 006.399 2.298a2.498 2.498 0 00-.846-1.256A9.953 9.953 0 0110 0z" })),
        React.createElement("clipPath", { id: "default-ds-icon_svg__d" },
            React.createElement("path", { d: "M2.5 0a2.5 2.5 0 110 5 2.5 2.5 0 010-5z" }))),
    React.createElement("g", { clipPath: "url(#default-ds-icon_svg__a)" },
        React.createElement("path", { fill: "#FFF", d: "M0 0h64v64H0V0z" })),
    React.createElement("g", { clipPath: "url(#default-ds-icon_svg__a)" },
        React.createElement("path", { fill: "rgba(0, 0, 0, 0.04)", d: "M0 0h64v64H0V0z" })),
    React.createElement("path", { d: "M5 0h54a5 5 0 015 5v54a5 5 0 01-5 5H5a5 5 0 01-5-5V5a5 5 0 015-5z", stroke: "rgba(0, 0, 0, 0.1)", fill: "none", strokeMiterlimit: 10 }),
    React.createElement("g", { opacity: "30%", clipPath: "url(#default-ds-icon_svg__a)" },
        React.createElement("g", { clipPath: "url(#default-ds-icon_svg__b)", transform: "translate(27.5 27.5)" },
            React.createElement("path", { d: "M4.5 9a4.5 4.5 0 100-9 4.5 4.5 0 000 9z", stroke: "#000", strokeWidth: 3, fill: "none", strokeLinejoin: "bevel" })),
        React.createElement("g", { clipPath: "url(#default-ds-icon_svg__c)", transform: "translate(22 22)" },
            React.createElement("path", { d: "M0 0h20v20H0V0z" })),
        React.createElement("g", { clipPath: "url(#default-ds-icon_svg__d)", transform: "translate(23.5 22.5)" },
            React.createElement("path", { d: "M2.5 5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z", stroke: "#000", strokeWidth: 3, fill: "none", strokeLinejoin: "bevel" })),
        React.createElement("g", { clipPath: "url(#default-ds-icon_svg__d)", transform: "translate(35.5 36.5)" },
            React.createElement("path", { d: "M2.5 5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z", stroke: "#000", strokeWidth: 3, fill: "none", strokeLinejoin: "bevel" }))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
