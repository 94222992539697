import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { captureException } from '@sentry/browser'
import { useApolloClient } from '@apollo/react-hooks'
import { routes } from '@sketch/modules-common'
import produce from 'immer'

import { loadMore } from '@sketch/components'
import {
  NotificationCreatedSubscription,
  NotificationCreatedDocument,
  useGetNotificationsQuery,
  GetNotificationsDocument,
  useNotificationMarkAllAsSeenMutation,
  useNotificationMarkAsReadMutation,
  GetUserNotificationsCountDocument,
} from '@sketch/gql-types'
import { getNotificationsQueryOptions } from '@sketch/user'
import { setHasUnreadNotificationsToZero } from './utils'
import { ErrorHandler } from '@sketch/tracing'
import NotificationsErrorBoundary, {
  NotificationsErrorDisplay,
} from './NotificationsErrorBoundary'
import { ParsedError } from '@sketch/graphql-apollo'
import NotificationsListUnread from './NotificationsListUnread'
import NotificationsListRead from './NotificationsListRead'
import { useNotificationSearchContext } from '../../context/NotificationSearchContext'
import { getSearchString } from './NotificationSearch/Searchbar/searchUtils'

type NotificationState = 'READ' | 'UNREAD' | 'NEW'

type NotificationsListByTypeProps = {
  notificationType?: NotificationState
}

/**
 * COMPONENT
 */
const NotificationsListByType: FC<NotificationsListByTypeProps> = () => {
  const client = useApolloClient()
  const [error, setError] = useState<ParsedError | null>(null)
  const { pathname } = useLocation()

  const isUnreadNotificationsView = pathname === routes.UPDATES.template()
  const { search, options } = useNotificationSearchContext()

  const {
    data,
    fetchMore,
    subscribeToMore,
    loading,
    error: networkError,
  } = useGetNotificationsQuery({
    ...getNotificationsQueryOptions,
    variables: { filter: { search: getSearchString({ search, options }) } },
  })

  const refetchNotifications = () => {
    client.query({
      query: GetNotificationsDocument,
      ...getNotificationsQueryOptions,
      variables: { filter: { search: getSearchString({ search, options }) } },
    })
  }

  if (networkError) {
    // This error will be rendered on "NotificationsErrorBoundary"
    throw networkError
  }

  const notificationsEntriesPath = ['me', 'notifications', 'entries']
  const notificationsEntries = data?.me.notifications?.entries
  const meta = data?.me.notifications?.meta
  const userId = data?.me?.identifier

  // Mutations
  const [markAllNotificationAsSeen] = useNotificationMarkAllAsSeenMutation({
    redirectErrors: true,
    UNSAFE_ignoreResults: true,
    update: () => userId && setHasUnreadNotificationsToZero(client, userId),
    onError: setError,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GetUserNotificationsCountDocument }],
  })

  const [markNotificationAsRead] = useNotificationMarkAsReadMutation({
    redirectErrors: true,
    onError: setError,
    refetchQueries: [{ query: GetUserNotificationsCountDocument }],
  })

  useEffect(() => {
    return () => {
      // Ensure up-to-date data by refetching notifications when leaving
      // Updates
      refetchNotifications()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // refetch when search OR OPTIONS change
  useEffect(() => {
    refetchNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, options])

  if (error) {
    // TODO: review the error display UI https://github.com/sketch-hq/Cloud/issues/9314
    return <NotificationsErrorDisplay />
  }

  if (isUnreadNotificationsView) {
    return (
      <NotificationsListUnread
        loading={loading}
        entries={notificationsEntries}
        markAllNotificationAsSeen={markAllNotificationAsSeen}
        markNotificationAsRead={notificationId => {
          markNotificationAsRead({
            variables: { id: notificationId },
            optimisticResponse: {
              __typename: 'RootMutationType',
              notificationMarkAsRead: {
                __typename: 'NotificationMarkAsReadPayload',
                successful: true,
                notification: {
                  __typename: 'CommentNotification',
                  identifier: notificationId,
                  isRead: true,
                },
                errors: [],
              },
            },
          })
        }}
        loadMore={loadMore(fetchMore, meta?.after!, notificationsEntriesPath)}
        subscribeToMore={() =>
          subscribeToMore<NotificationCreatedSubscription>({
            document: NotificationCreatedDocument,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData) return prev

              const newNotification = subscriptionData.data.notificationCreated
              if (!newNotification) {
                ErrorHandler.shouldNeverHappen(
                  'There should be always a notification on notificationCreated payload'
                )
                return prev
              }

              return produce(prev, draftState => {
                draftState.me.notifications?.entries.unshift(newNotification)
              })
            },
            onError: captureException,
          })
        }
      />
    )
  }

  return (
    <NotificationsListRead
      loading={loading}
      entries={notificationsEntries}
      loadMore={loadMore(fetchMore, meta?.after!, notificationsEntriesPath)}
      subscribeToMore={() =>
        subscribeToMore<NotificationCreatedSubscription>({
          document: NotificationCreatedDocument,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData) return prev

            const newNotification = subscriptionData.data.notificationCreated
            if (!newNotification) {
              ErrorHandler.shouldNeverHappen(
                'There should be always a notification on notificationCreated payload'
              )
              return prev
            }

            return produce(prev, draftState => {
              draftState.me.notifications?.entries.unshift(newNotification)
            })
          },
          onError: captureException,
        })
      }
      hasUnreadNotifications={data?.me?.hasUnreadNotifications || false}
    />
  )
}

const NotificationsListWithDataGuard = (
  props: NotificationsListByTypeProps
) => {
  // TODO: review the error display UI https://github.com/sketch-hq/Cloud/issues/9314
  return (
    <NotificationsErrorBoundary>
      <NotificationsListByType {...props} />
    </NotificationsErrorBoundary>
  )
}

export default NotificationsListWithDataGuard
