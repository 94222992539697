import { useContext } from 'react'
import { ModalContext, ModalInjectedProps } from '@sketch/components'
import {
  DocumentInfoModal,
  DocumentInfoModalProps,
} from '../components/DocumentInfoModal'

export type { DocumentInfoModalQueryProps } from '../../shares/components/DocumentInfoModal'

export const useDocumentInfoModal = (
  props: OmitSafe<DocumentInfoModalProps, keyof ModalInjectedProps>
) => {
  const { showModal } = useContext(ModalContext)

  const openDocumentInfoModal = () => showModal(DocumentInfoModal, props)

  return { openDocumentInfoModal }
}
