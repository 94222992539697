import styled, { css } from 'styled-components'

export type Size = '16px' | '24px' | '32px'

export const MemberIcon = styled.div<{ $dark?: boolean }>`
  width: 100%;
  aspect-ratio: 1;
  border-radius: 999px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-size: ${({ theme }) => theme.fontSizes.C};
  text-transform: uppercase;

  ${({ $dark }) =>
    !$dark &&
    css`
      box-shadow: 0 0 0 1px
        ${({ theme }) => theme.colors.background.secondary.A};
    `};

  background-color: ${({ theme }) => theme.colors.inspect.guide};
  color: ${({ theme }) => theme.colors.foreground.primary.A};
`

export const MemberIconGroup = styled.div<{ $size: Size; $dark: boolean }>`
  width: ${({ $size }) => $size};
  font-size: ${({ $size }) => $size};
  flex-shrink: 0;
  aspect-ratio: 1;

  position: relative;
  z-index: 0;

  ${({ $size, $dark }) =>
    $size === '32px' &&
    css`
      ::after,
      ::before {
        content: '';
        position: absolute;
        border-radius: 999px;
        top: 0;
        left: 0;
        width: calc(100% - 4px);
        aspect-ratio: 1;
        background-color: ${({ theme }) => theme.colors.inspect.guide};
        ${!$dark &&
        css`
          box-shadow: 0 0 0 1px
            ${({ theme }) => theme.colors.background.secondary.A};
        `};
      }

      :after {
        transform: translate3d(2px, 2px, 0);
      }

      :before {
        transform: translate3d(4px, 4px, 0);
      }

      ${MemberIcon} {
        width: calc(100% - 4px);
      }
    `}
`
