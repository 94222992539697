import styled, { css } from 'styled-components'
import {
  Modal,
  Text,
  Image,
  RadioButton,
  Checkbox,
  TabList,
} from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'

import { ReactComponent as ArrowRaw } from '@sketch/icons/arrow-top-right-diagonal-16'
import { ReactComponent as QuestionMarkIconUnstyled } from '@sketch/icons/question-mark-16'

export const StyledModal = styled(Modal)`
  max-width: 700px;
  overflow-y: hidden;

  ${Modal.Body} {
    height: 400px;
    overflow-y: auto;
  }
`

// this override is necessary because two Text elements always result on a 20px padding on the first one
export const Title = styled(Text).attrs({
  fontSize: 'G',
  fontWeight: 'regular',
})`
  padding-bottom: 4px !important; /* stylelint-disable-line declaration-no-important */
`

export const PreviewImage = styled(Image)(
  ({ theme }) => css`
    width: 68px;
    height: 68px;
    background-color: ${({ theme }) => theme.colors.background.tertiary.C};
    border-radius: ${theme.radii.large};
    border: 1px solid ${theme.colors.border.A};

    img {
      height: auto;
      width: auto;
      max-width: 68px;
      max-height: 68px;
    }
  `
)

export const ShareParentName = styled.strong`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.foreground.secondary.B};
`

export const PreviewImageEmpty = styled.div(
  ({ theme }) => css`
    width: 68px;
    height: 68px;
    border-radius: ${theme.radii.large};
    border: 1px solid ${theme.colors.border.A};
    background-color: ${theme.colors.background.tertiary.D};
    position: relative;

    ::after {
      content: ' ';
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: ${theme.colors.background.tertiary.B};
      position: absolute;
      top: 25px;
      right: 10px;
    }

    ::before {
      content: ' ';
      width: 28px;
      height: 28px;
      background-color: ${theme.colors.background.tertiary.B};
      position: absolute;
      top: 12px;
      left: 12px;
    }
  `
)

export const StyledTabList = styled(TabList)`
  padding-bottom: 16px;
  margin-bottom: 16px;
  /* negative margins and paddings are necessary for the all width border */
  margin-left: -32px;
  padding-left: 32px;
  margin-right: -32px;
  padding-right: 32px;

  ${breakpoint('base', 'xs')`
    margin-left: -16px;
    padding-left: 16px;
    margin-right: -16px;
    padding-right: 16px;
  `}

  position: fixed;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.secondary.A};
  border-color: ${({ theme }) => theme.colors.border.A};
  /* needed so the tab list is on top of the radio buttons */
  z-index: 50;
  overflow: auto;
`

// this is the necessary padding for the fixed tab list (which is 60px tall, 24px are the top padding)
export const TabPanelWrapper = styled.div`
  padding-top: 76px; /* stylelint-disable-line scales/space */
`

export const QuestionMarkIcon = styled(QuestionMarkIconUnstyled)`
  width: 16px;
  height: 16px;
  margin-left: 6px; /* stylelint-disable-line scales/space */
  vertical-align: sub; /* vertically align icon with text */
`

export const StyledRadioButton = styled(RadioButton)`
  margin-bottom: 12px;
`

export const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 12px;
`

export const Arrow = styled(ArrowRaw)`
  width: 16px;
  height: 16px;
  vertical-align: middle;
`

export const Subtitle = styled(Text).attrs({
  textStyle: 'copy.tertiary.standard.D',
  fontWeight: 'normal',
})``
