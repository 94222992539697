import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "bell-strikethrough-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("g", { fill: "currentColor" },
            React.createElement("path", { d: "M4.161 3.796L5.24 4.89a.167.167 0 00-.017-.013 3.45 3.45 0 00-.715 1.886L4.5 7v2.25A1.75 1.75 0 012.75 11a.25.25 0 00-.057.493l.057.007h9.008l-.805-.824-.185-.189L13.25 13H10a2 2 0 11-4 0H2.75a1.75 1.75 0 010-3.5.25.25 0 00.243-.193L3 9.25V7c0-1.132.376-2.176 1.01-3.014l.151-.19zM5.24 4.89l.01.008c.062.055.223.211.482.468l.357.358c.841.846 2.294 2.324 4.357 4.435l.225.23-5.431-5.5zM8 1a1 1 0 01.995 1.1 4.999 4.999 0 014 4.683L13 7v2.042c.002.068.004.197.008.255l.004.03-1.513-1.281L11.5 7a3.5 3.5 0 00-3.308-3.495L8 3.5c-.455 0-.89.087-1.288.245l-.195.083-1.221-1.034a4.97 4.97 0 011.709-.695L7 2a1 1 0 011-1z" }),
            React.createElement("path", { d: "M12.513 12.25H2.75a1 1 0 01-.117-1.993l.117-.007a1 1 0 001-1V7c0-1.03.295-1.97.906-2.706l7.857 7.956zM5.896 3.307a4.25 4.25 0 016.35 3.488L12.25 7v1.69L5.896 3.306z", fillOpacity: 0.15 })),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round", d: "M1.75 2.75l12.5 10.5" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
