import React, { ComponentPropsWithRef } from 'react'
import { useHistory } from 'react-router'

import { useBreakpoint, Section, InfiniteList } from '@sketch/components'

import { useSignInToUnauthorizedWorkspace } from 'cloud-frontend'

import { WorkspaceListItem } from './Item/WorkspaceListItem'

import { StyledTable } from './WorkspaceList.styles'

import { UserWorkspaceMembershipFragment } from '@sketch/gql-types'
import { useUserAuthorizations } from '@sketch/modules-common'

interface WorkspaceListProps {
  memberships: UserWorkspaceMembershipFragment[]
  onLoadMore: ComponentPropsWithRef<typeof InfiniteList>['onLoadMore']
  linkButton?: React.ReactNode
  onLeaveWorkspace: (membership: UserWorkspaceMembershipFragment) => void
}

const WorkspaceList: React.FC<WorkspaceListProps> = ({
  memberships,
  linkButton,
  onLoadMore,
  onLeaveWorkspace,
}) => {
  const history = useHistory()
  const isMobileAndSmaller = !useBreakpoint('xs')

  const { hasAccessToWorkspace } = useUserAuthorizations()
  const { signIn } = useSignInToUnauthorizedWorkspace()

  const handleWorkspaceClick = (
    event: React.MouseEvent<HTMLElement>,
    workspace: UserWorkspaceMembershipFragment['workspace']
  ) => {
    if (!hasAccessToWorkspace(workspace)) {
      event.preventDefault()

      const selectedWorkspace = memberships.find(
        membership => membership.workspace.identifier === workspace.identifier
      )

      if (selectedWorkspace) {
        const redirectURL = event.currentTarget.getAttribute('href')
        signIn({ workspace, onCompleted: () => history.push(redirectURL!) })
      }
    }
  }

  const tableHeaders = [
    { label: 'Workspace Name' },
    { label: 'Role' },
    { label: 'Leave Workspace' },
  ].filter(header => {
    // Remove "Role" column from the table header in mobile
    if (isMobileAndSmaller && header.label === 'Role') {
      return null
    }
    return header
  })

  return (
    <Section
      title="Workspaces"
      text="Workspaces are where you’ll save your work and collaborate with others."
      action={linkButton}
    >
      <InfiniteList onLoadMore={onLoadMore}>
        <StyledTable
          header={tableHeaders}
          hideHeader
          items={memberships}
          data-testid="workspace-list"
          renderItem={(membership: UserWorkspaceMembershipFragment) => {
            return (
              <WorkspaceListItem
                membership={membership}
                onLeaveWorkspace={onLeaveWorkspace}
                hasAccessToWorkspace={
                  hasAccessToWorkspace(membership.workspace) === true
                }
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  handleWorkspaceClick(event, membership.workspace)
                }
              />
            )
          }}
        />
      </InfiniteList>
    </Section>
  )
}

export default WorkspaceList
