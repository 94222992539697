import styled from 'styled-components'
import { HEIGHT } from '../../ReviewLargeDocumentsModal.styles'

// To avoid a "heigh" jump, we want the loading height to be the same as the document list height
// So we are adding the height of the title and description to the height of the document list
const titleDescriptionHeight = '122px'

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(${titleDescriptionHeight} + ${HEIGHT});
`
