import { Text, pluralize } from '@sketch/components'
import React from 'react'
import SendToTrashDocumentList from '../SendToTrashDocumentList'
import { ShareWithSizeFragment } from '@sketch/gql-types'

interface SendToTrashProps {
  numberSelectedDocuments: number
  sharesWithSize: ShareWithSizeFragment[]
}

const SendToTrash: React.FC<SendToTrashProps> = ({
  numberSelectedDocuments,
  sharesWithSize,
}) => {
  return (
    <>
      <Text textStyle="header.primary.G">
        Send {numberSelectedDocuments}{' '}
        {pluralize('Document', 'Documents', numberSelectedDocuments)} to Trash?
      </Text>
      <Text textStyle="copy.tertiary.standard.E">
        These documents will be sent to Trash and will be permanently deleted
        after 90 days, unless you manually delete them first:
      </Text>
      <SendToTrashDocumentList sharesWithSize={sharesWithSize} />
    </>
  )
}

export default SendToTrash
