import React from 'react'

import { routes, useFlag } from '@sketch/modules-common'

import { useGetRandomPublicationsQuery } from '@sketch/gql-types'

import {
  Title,
  Container,
  StyledSkeleton,
  StyledGridPublicationItem,
} from './MoreFromThisCreator.styles'

interface MoreFromThisCreatorProps {
  workspaceIdentifier: string
  exclude: string
}

const MoreFromThisCreator: React.FC<MoreFromThisCreatorProps> = ({
  workspaceIdentifier,
  exclude,
}) => {
  /*
    Remove this flag when feature is released
    https://github.com/sketch-hq/Cloud/issues/16748
  */
  const isProfileDocumentViewFFEnabled = useFlag('profile-document-detail-view')

  const { data, loading, error } = useGetRandomPublicationsQuery({
    variables: {
      workspaceIdentifier,
      exclude: [exclude],
      limit: 2,
    },
    // We want to always have new random publications when the component renders
    fetchPolicy: 'network-only',
  })

  const profile = data?.workspaceProfile

  if (loading) {
    return (
      <section>
        <Title>More from this creator</Title>
        <Container>
          <StyledSkeleton />
          <StyledSkeleton />
        </Container>
      </section>
    )
  }

  if (error || !profile) {
    return null
  }

  const shortUrlName = profile.shortUrlName
  const publications = profile.publications.entries ?? []

  if (!publications.length) {
    return null
  }

  return (
    <section>
      <Title>More from this creator</Title>
      <Container>
        {publications.map(publication => {
          const { identifier, share } = publication
          const { identifier: shareID } = share

          const url = isProfileDocumentViewFFEnabled
            ? routes.WORKSPACE_PROFILE_DOCUMENT.create({
                shortUrlName: shortUrlName,
                publicationId: identifier,
              })
            : routes.SHARE_VIEW.create({
                shareID,
              })

          return (
            <StyledGridPublicationItem
              key={identifier}
              publication={publication}
              url={url}
            />
          )
        })}
      </Container>
    </section>
  )
}

export default MoreFromThisCreator
