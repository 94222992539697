/**
 * isTruthy
 *
 * Ideal for Array#filter, since right now the use of filter makes TS to lose
 * its types.
 * @SEE: https://github.com/microsoft/TypeScript/issues/16655
 *
 * e.g.:
 *
 * ```
 *  [1,null,3].filter(isTruthy)
 * ```
 *
 */
export function isTruthy<T = string>(m?: T | null | 0 | false | ''): m is T {
  return Boolean(m)
}
