import styled, { css } from 'styled-components'

export const UpsellListItem = styled.li`
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
`

export const UpsellIcon = styled.div(
  ({ theme }) => css`
    margin-right: 4px;
    width: 16px;
    height: 16px;
    color: ${theme.colors.foreground.secondary.D};
  `
)
