import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useContext,
} from 'react'
import { useParams } from 'react-router-dom'
import { RouteParams } from '@sketch/modules-common'
import type { JSONContent } from '@tiptap/core/dist/packages/core/src/types'
import { useToast } from '@sketch/toasts'

import { useUpdateDesignSystemContentMutation } from '@sketch/gql-types'

type DSDocsEditModeContext = {
  isEditMode: boolean
  hasUnsavedChanges: boolean
  initialContent: JSONContent
  content: JSONContent
}

const initialValues = {
  isEditMode: false,
  hasUnsavedChanges: false,
  initialContent: {},
  content: {},
}

const context = createContext<
  [DSDocsEditModeContext, Dispatch<SetStateAction<DSDocsEditModeContext>>]
>([initialValues, () => {}])

export const DSDocsEditModeContextProvider: React.FC = ({ children }) => {
  const value = useState<DSDocsEditModeContext>(initialValues)

  return <context.Provider value={value}>{children}</context.Provider>
}

export function useDSDocsEditModeContext(initialEditorContent?: JSONContent) {
  const [values, setValues] = useContext(context)
  const { isEditMode, hasUnsavedChanges, content, initialContent } = values

  // When editor mounts we store the content in 2 places, one to recover from
  // discard changes and another one that will be continuosly changing in sync with the editor
  React.useEffect(() => {
    if (initialEditorContent) {
      setValues({
        ...values,
        initialContent: initialEditorContent,
        content: initialEditorContent,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { designSystemId } = useParams<RouteParams<'DESIGN_SYSTEM'>>()

  const { showToast } = useToast()

  const [updateContent] = useUpdateDesignSystemContentMutation({
    onError: 'show-toast',
    onCompleted: () => {
      showToast('Content saved successfully')
      setValues({ ...values, isEditMode: false, hasUnsavedChanges: false })
    },
  })

  const saveContent = () => {
    const parsedContent = JSON.stringify(content)

    updateContent({
      variables: {
        identifier: designSystemId,
        input: {
          content: parsedContent,
        },
      },
      optimisticResponse: {
        __typename: 'RootMutationType',
        updateDesignSystem: {
          __typename: 'DesignSystem',
          identifier: designSystemId,
          content: parsedContent,
        },
      },
    })
  }

  const persistContent = (content: JSONContent) => {
    setValues({ ...values, hasUnsavedChanges: true, content })
  }

  const setHasUnsavedChanges = (hasUnsavedChanges: boolean) =>
    setValues({ ...values, hasUnsavedChanges })

  const openEditMode = () => setValues({ ...values, isEditMode: true })

  const discardChanges = () => {
    setValues({
      ...values,
      isEditMode: false,
      hasUnsavedChanges: false,
      content: initialContent,
    })
  }

  return {
    content,
    initialContent,
    isEditMode,
    hasUnsavedChanges,
    openEditMode,
    discardChanges,
    saveContent,
    setHasUnsavedChanges,
    persistContent,
  }
}
