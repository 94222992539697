import { useCallback, useContext } from 'react'
import { ToastContext } from '@sketch/toasts'
import { ShareVersionContext, ShareWithoutVersion } from './ShareVersionContext'
import { Location, History } from 'history'
import { useUpgradeToLatestVersion } from './UpgradeToLatestVersion'
import { ShareInfoFragment } from '@sketch/gql-types'

const buildPathNames = (
  pathname: string,
  share: ShareWithoutVersion,
  versionShortId: string
) => {
  if (!pathname.endsWith('/')) {
    pathname += '/'
  }
  const withoutVersion = pathname.replace(`/v/${versionShortId}/`, '/')

  const withVersion = withoutVersion.replace(
    `/s/${share.identifier}/`,
    `/s/${share.identifier}/v/${versionShortId}/`
  )

  return {
    withVersion,
    withoutVersion,
  }
}
export const useVersionUpdateCallback = ({
  location,
  history,
}: {
  location: Location
  history: History
}) => {
  const { showToast } = useContext(ToastContext)
  const shareVersionContext = useContext(ShareVersionContext)
  const { upgradeToLatest } = useUpgradeToLatestVersion()

  const { latestVersion, versionShortId } = shareVersionContext!

  return useCallback(
    (share: ShareInfoFragment) => {
      if (!share || !latestVersion) {
        return null
      }

      const { versionTypeAvailableToUser } = share
      const userCanAccessVersions = versionTypeAvailableToUser === 'ANY'

      if (
        // do not show toast if the user does not have permissions for this share
        (share.versionTypeAvailableToUser === 'ANY_STARRED' &&
          latestVersion.kind !== 'PUBLISHED') ||
        // or if the user can only see the latest version, no need for toast
        share.versionTypeAvailableToUser === 'LATEST'
      ) {
        return
      }

      if (share.version?.kind === 'AUTO' && share.version.userIsCreator) {
        upgradeToLatest('user-generated-autosave', true)
        return
      }

      const { withVersion } = buildPathNames(
        location.pathname,
        share,
        versionShortId
      )

      if (userCanAccessVersions) {
        showToast('New document version available')
        history.replace({ ...location, pathname: withVersion })
      } else {
        showToast('Document has been updated')
        history.replace({ ...location, pathname: withVersion })
      }
    },
    [
      upgradeToLatest,
      showToast,
      latestVersion,
      versionShortId,
      location,
      history,
    ]
  )
}
