import styled from 'styled-components'
import { breakpoint } from '@sketch/global-styles'

import {
  RadioButton as BaseRadioButton,
  SelectorButton,
} from '@sketch/components'

export const RadioButton = styled(BaseRadioButton)(
  ({ theme }) => `
  margin: 0 8px;
  padding: 8px 12px;
  border-radius: ${theme.radii.large};
  
  text-transform: none;

  /* Correct internal radio button spacing */
  line-height: 0;

  &:hover {
    background-color: ${theme.colors.background.tertiary.C};
  }
`
)

export const Selector = styled(SelectorButton)`
  ${breakpoint('base', 'sm')`
    /* 
      In mobile we decided to remove the left padding
      and the background from the Selector to match the 
      mobile design.
    */
    padding-left: 0px;

    :hover {
      background: none;
    }
  `}
`
