import { useEffect } from 'react'

export const FOCUSABLE_ELEMENTS = `
a[href]:not([disabled]):not([tabindex='-1']),
button:not([disabled]):not([tabindex='-1']),
textarea:not([disabled]):not([tabindex='-1']),
input:not([disabled]):not([tabindex='-1']),
select:not([disabled]):not([tabindex='-1']),
[tabindex='0']
`

/*
 * useFocusTrap
 *
 * Custom hook that receives a reference to an HTMLElement (the trap container)
 * and traps the focus inside of it
 *
 */
export function useFocusTrap(containerRef: React.RefObject<HTMLElement>) {
  useEffect(() => {
    const lastFocusedElement = document.activeElement

    let allFocusableChildren = containerRef.current?.querySelectorAll(
      FOCUSABLE_ELEMENTS
    )

    if (!allFocusableChildren || allFocusableChildren.length === 0) {
      return
    }

    const firstChild = allFocusableChildren[0]
    const lastChild = allFocusableChildren[allFocusableChildren.length - 1]

    function handleFocus(event: KeyboardEvent) {
      if (
        event.key !== 'Tab' ||
        !allFocusableChildren ||
        allFocusableChildren.length === 0
      ) {
        return
      }

      const updatedFocusableChildren = containerRef.current?.querySelectorAll(
        FOCUSABLE_ELEMENTS
      )

      /**
       * We need to update the focusable elements each time we will
       * press the Tab key. The reason is we might deal with dynamic
       * content in a modal
       */
      if (updatedFocusableChildren === undefined) return
      allFocusableChildren = updatedFocusableChildren

      const currentActiveElement = document.activeElement

      const isChild =
        Array.from(allFocusableChildren).find(
          child => child === currentActiveElement
        ) !== undefined

      if (
        isChild &&
        currentActiveElement !== firstChild &&
        currentActiveElement !== lastChild
      ) {
        // it's not the first or last element
        return
      }

      let elementToFocus

      if (!isChild && firstChild instanceof HTMLElement) {
        event.preventDefault()
        // This accounts for the first tab press after the modal is open
        elementToFocus = firstChild
      }

      // We need to focus last element if shift + tab key is pressed
      if (currentActiveElement === firstChild && event.shiftKey) {
        elementToFocus = lastChild
        event.preventDefault()
      }

      // We need to focus first element if tab key is pressed (but not shift)
      if (currentActiveElement === lastChild && !event.shiftKey) {
        elementToFocus = firstChild
        event.preventDefault()
      }

      // Check if the element is focusable
      if (
        elementToFocus instanceof HTMLElement &&
        elementToFocus?.tabIndex !== -1
      ) {
        elementToFocus.focus()
      }
    }

    window.addEventListener('keydown', handleFocus)

    return () => {
      window.removeEventListener('keydown', handleFocus)

      if (
        lastFocusedElement instanceof HTMLElement &&
        lastFocusedElement.tabIndex !== -1
      ) {
        lastFocusedElement.focus()
      }
    }
  }, [containerRef])
}
