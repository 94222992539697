import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import {
  Text,
  LinkButton,
  Heading as HeadingUnstyled,
  Form,
  Panel,
} from '@sketch/components'

import {
  ForgotPasswordForm,
  ForgotPasswordFormExtendedFormikProps,
} from '@sketch/user'
import { routes } from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'
import styled from 'styled-components'
import { BodyPanel, PanelFooter } from '../shared'

const Heading = styled(HeadingUnstyled.H1)`
  margin-bottom: 24px;
  text-align: center;
`

interface ForgotPasswordPanelSuccessProps {
  searchParameters: string
}

const ForgotPasswordPanelSuccess: React.FC<ForgotPasswordPanelSuccessProps> = props => (
  <>
    <BodyPanel>
      <Heading>Forgot Your Password?</Heading>
      <Text textAlign="center" mb={32}>
        If there is an account associated with this email address you will
        receive a password reset email.
      </Text>
    </BodyPanel>
    <Panel.Footer>
      <LinkButton
        variant="primary"
        to={{
          pathname: routes.SHARE_ACCEPT_INVITE.create({}),
          search: props.searchParameters,
        }}
      >
        Sign In
      </LinkButton>
    </Panel.Footer>
  </>
)

interface ForgotPasswordPanelFormProps {
  formikProps: ForgotPasswordFormExtendedFormikProps
}

const ForgotPasswordPanelForm: React.FC<ForgotPasswordPanelFormProps> = props => {
  const { formikProps } = props

  const errorMessage = formikProps.apiErrors
    .map(errors => errors?.message)
    .join('')

  return (
    <>
      <BodyPanel>
        <Heading>Forgot Your Password?</Heading>
        <Text textAlign="center" mb={32} color="foreground.secondary.A">
          Enter the email address you use to sign in to Sketch.
        </Text>
        <ForgotPasswordForm.Fields {...formikProps} />
      </BodyPanel>
      <PanelFooter>
        {errorMessage && (
          <Form.Error>
            <Text textAlign="center" mt={0}>
              {errorMessage}
            </Text>
          </Form.Error>
        )}
        <ForgotPasswordForm.Submit {...formikProps} />
      </PanelFooter>
    </>
  )
}

interface ForgotPasswordPanelProps extends RouteComponentProps {
  invitedEmail: string
}

const ForgotPasswordPanel: React.FC<ForgotPasswordPanelProps> = props => {
  const { showToast } = useToast()
  const { invitedEmail, location } = props

  return (
    <Panel {...props}>
      <ForgotPasswordForm
        initialEmail={invitedEmail}
        onError={error => {
          showToast(error.message, 'negative')
        }}
      >
        {formikProps =>
          formikProps.successful ? (
            <ForgotPasswordPanelSuccess searchParameters={location.search} />
          ) : (
            <ForgotPasswordPanelForm formikProps={formikProps} />
          )
        }
      </ForgotPasswordForm>
    </Panel>
  )
}

export default withRouter(ForgotPasswordPanel)
