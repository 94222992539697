import React from 'react'
import { useThemeContext } from '@sketch/global-styles'
import {
  Dropdown,
  Flex,
  useBreakpoint,
  useResponsiveDropdown,
} from '@sketch/components'
import { StyledDropdownItem, Icon, ArrowIcon } from './UserDropdown.styles'
import { ReactComponent as ContrastIcon } from '@sketch/icons/contrast-16'
import { ReactComponent as SunIcon } from '@sketch/icons/sun-16'
import { ReactComponent as MoonIcon } from '@sketch/icons/moon-16'
import { ReactComponent as DeviceDesktopIcon } from '@sketch/icons/device-desktop-16'
import { MobileNavigationHeader, WarningMessage } from './AppearanceMenu.styles'

const POPOVER_OFFSET = [0, 0] as [number, number]
const DROPDOWN_PROPS = {
  maxWidth: '250px',
}

type AppearanceMenuContentProps = {
  isLight: boolean
  isDark: boolean
  matchSystem: boolean
  enableLightMode: () => void
  enableDarkMode: () => void
  toggleMatchSystem: () => void
  isThemeOverridden: boolean
}

const AppearanceMenuContent = ({
  isLight,
  isDark,
  matchSystem,
  enableLightMode,
  enableDarkMode,
  toggleMatchSystem,
  isThemeOverridden,
}: AppearanceMenuContentProps) => {
  const isMobileAndBigger = useBreakpoint('xs')

  return (
    <>
      {!isMobileAndBigger && (
        <MobileNavigationHeader>Appearance</MobileNavigationHeader>
      )}
      <StyledDropdownItem $isActive={isLight} onClick={enableLightMode}>
        <Icon width="16px" height="16px" $isActive={isLight} as={SunIcon} />
        Light
      </StyledDropdownItem>
      <StyledDropdownItem $isActive={isDark} onClick={enableDarkMode}>
        <Icon width="16px" height="16px" $isActive={isDark} as={MoonIcon} />
        Dark
      </StyledDropdownItem>
      <StyledDropdownItem $isActive={matchSystem} onClick={toggleMatchSystem}>
        <Icon
          width="16px"
          height="16px"
          $isActive={matchSystem}
          as={DeviceDesktopIcon}
        />
        System Default
      </StyledDropdownItem>
      {isThemeOverridden && (
        <>
          <Dropdown.Divider />
          <WarningMessage type="warning">
            The current page’s appearance is set by Sketch
          </WarningMessage>
        </>
      )}
    </>
  )
}

export const AppearanceMenu = () => {
  const {
    enableLightMode,
    enableDarkMode,
    toggleMatchSystem,
    selectedTheme,
    overriddenTheme,
    matchSystem,
  } = useThemeContext()

  const isLight = !matchSystem && selectedTheme === 'light'
  const isDark = !matchSystem && selectedTheme === 'dark'

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: AppearanceMenuContent,
    placement: 'right-end',
    dropdownProps: {
      enableLightMode,
      enableDarkMode,
      toggleMatchSystem,
      isDark,
      isLight,
      matchSystem,
      isThemeOverridden: !!overriddenTheme,
    },
    dropdownStyle: DROPDOWN_PROPS,
    showOnHover: true,
    usePortal: true,
    offset: POPOVER_OFFSET,
  })

  return (
    <>
      <StyledDropdownItem {...buttonProps}>
        <Flex width="100%" justifyContent="space-between" alignItems="flex-end">
          <Flex alignItems="center">
            <Icon width="16px" height="16px" as={ContrastIcon} /> Appearance
          </Flex>
          <ArrowIcon width="16px" height="16px" />
        </Flex>
      </StyledDropdownItem>
      {content}
    </>
  )
}
