import React from 'react'

import { Skeleton } from '../Skeleton'

import { SkeletonWrapper, SkeletonRow } from './SkeletonSidebarList.styles'

export interface SkeletonUIProps {
  numberOfItems?: number
  'data-testid'?: string
  className?: string
}
/**
 *
 * SkeletonSidebarList
 *
 * Simple Skeleton loader for the Sidebar menus
 *
 */
export const SkeletonSidebarList: React.FC<SkeletonUIProps> = ({
  numberOfItems = 2,
  'data-testid': dataTestId,
  className,
}) => {
  const items = Array.from(Array(numberOfItems).keys())

  return (
    <SkeletonWrapper data-testid={dataTestId} className={className}>
      {items.map(key => (
        <SkeletonRow key={key}>
          <Skeleton height="16px" width="16px" />
          <Skeleton height="16px" />
        </SkeletonRow>
      ))}
    </SkeletonWrapper>
  )
}
