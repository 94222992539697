import styled, { css } from 'styled-components'

import { ReactComponent as CheckmarkIcon } from '@sketch/icons/checkmark-circle-filled-12'

// Images
import DiamondBackground from '@sketch/icons/modal-diamond-background.png'

import { breakpoint } from '@sketch/global-styles'

import { Button, LinkButton, Modal } from '@sketch/components'

export const StyledModal = styled(Modal)`
  max-width: 640px;
`

export const Title = styled.h1`
  margin-top: 40px;
  font-size: ${({ theme }) => theme.fontSizes.H};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  text-align: center;
`

export const SubTitle = styled.h3`
  margin-top: 0;
  font-size: ${({ theme }) => theme.fontSizes.G};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.D};
  line-height: ${({ theme }) => theme.lineHeights.E};
`

export const Columns = styled.div`
  display: flex;
  justify-content: center;

  ${breakpoint('base', 'xs')`
    flex-direction: column;
  `}
`

export const Column = styled.div<{ withBackground?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 24px 20px;
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  border-radius: ${({ theme }) => theme.radii.xlarge};
  flex: 1;

  :last-child {
    background-image: url(${DiamondBackground});
    background-size: cover;
    margin: 0 0 0 16px;

    ${breakpoint('base', 'xs')`
      margin: 0 0 16px 0;
    `}

    /* This needs to be hard coded (dark) because the hero background is yellow
    and we don't want to change the text color between themes */
    ${({ withBackground }) =>
      withBackground &&
      css`
        ${SubTitle} {
          color: hsl(0, 0%, 0%);
        }

        ${Paragraph} {
          color: hsl(0, 0%, 0%, 0.7);
        }
      `}
  }
`

export const Note = styled.p`
  padding-top: 24px;
  font-size: ${({ theme }) => theme.fontSizes.C};
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  text-align: center;
`

export const StyledButton = styled(Button)`
  margin-top: auto;
`

export const StyledLinkButton = styled(LinkButton)`
  margin-top: auto;
`

export const List = styled.ul`
  margin: 0px 0px 24px 0px;
  padding: 0px;
  list-style: none;
`

export const Item = styled.li`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.D};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  :not(:last-child) {
    padding-bottom: 12px;
  }
`

export const Checkmark = styled(CheckmarkIcon)`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
`
