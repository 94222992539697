import React from 'react'
import { useTransition, animated } from 'react-spring'

import { Wrapper, Title, Description } from './ModalWarning.styles'

export interface ModalWarningProps {
  title?: React.ReactNode
  description?: React.ReactNode
  show?: boolean
  className?: string
}

const TRANSITION_STEPS = {
  from: {
    opacity: 0,
    maxHeight: '0px',
  },
  enter: {
    opacity: 1,
    maxHeight: '1000px',
  },
  leave: {
    opacity: 0,
    maxHeight: '0px',
  },
}

export const ModalWarning: React.FC<ModalWarningProps> = props => {
  const { title, description, show, className } = props
  const transitions = useTransition(show, null, TRANSITION_STEPS)

  return (
    <>
      {transitions.map(
        ({ item, props }) =>
          item && (
            <animated.div key="modal-warning" style={props}>
              <Wrapper className={className}>
                {title && <Title>{title}</Title>}
                {description && <Description>{description}</Description>}
              </Wrapper>
            </animated.div>
          )
      )}
    </>
  )
}
