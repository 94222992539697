// Usage of RenderStatusType will not break the build
// eslint-disable-next-line no-restricted-imports
import { RenderStatusType } from '@sketch/gql-types/expansive'
import { GetVersionQuery } from '@sketch/gql-types'
import isEmpty from 'lodash.isempty'

const uploadingStatus = ['CREATED']
const processingStatus = ['UPLOADED', 'RENDERING', 'PROCESSING']
const readyStatus = ['FINISHED']
const failedStatus = ['ERRORED']

export type RenderStatus =
  | 'uploading'
  | 'processing'
  | 'failed'
  | 'ready'
  | 'loading'

export const getRenderStatus = (
  renderStatus: RenderStatusType | null
): RenderStatus => {
  if (renderStatus === null) return 'loading'

  switch (true) {
    case isUploading(renderStatus):
      return 'uploading'
    case isProcessing(renderStatus):
      return 'processing'
    case hasFailed(renderStatus):
      return 'failed'
    default:
      return 'ready'
  }
}

export const isUploading = (renderStatus: RenderStatusType): boolean =>
  uploadingStatus.includes(renderStatus)

export const isProcessing = (renderStatus: RenderStatusType): boolean =>
  processingStatus.includes(renderStatus)

export const isReady = (renderStatus: RenderStatusType): boolean =>
  readyStatus.includes(renderStatus)

export const hasFailed = (renderStatus: RenderStatusType): boolean =>
  failedStatus.includes(renderStatus)

const failedThreshold = (lastUpdate: Date | string): boolean => {
  const hoursDiff =
    Math.abs(new Date(lastUpdate).getTime() - new Date().getTime()) / 36e5
  if (hoursDiff > 2) {
    return true
  }
  return false
}

export const preRenderfarmStatus = (
  hasFiles: boolean,
  lastUpdate: Date | string
): RenderStatusType => {
  if (!hasFiles) {
    if (failedThreshold(lastUpdate)) {
      return 'ERRORED'
    }
    return 'PROCESSING'
  }
  return 'FINISHED'
}

type Version = NonNullable<GetVersionQuery['share']>['version']

/**
 * Extract the render status from the document version (fetched in getVersion query)
 */
export const getVersionRenderStatus = (version: Version | null | undefined) => {
  const previewFiles = version?.document?.previewFile?.thumbnails || []

  if (!version || isEmpty(version)) {
    return getRenderStatus(null)
  }

  const renderStatusType =
    version.document?.renderStatus ||
    preRenderfarmStatus(!!previewFiles.length, version.updatedAt || '')

  return getRenderStatus(renderStatusType)
}
