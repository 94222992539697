import styled, { css } from 'styled-components'
import { Input } from '@sketch/components'

const Fieldset = styled.fieldset<{ disabled?: boolean }>`
  padding: 0;
  border: 0;

  ${({ disabled }) =>
    disabled &&
    css`
      ${Input} {
        background-color: ${({ theme }) => theme.colors.state.disabled};
        pointer-events: none;
      }
    `}
`

export default Fieldset
