import { Editor } from '@tiptap/react'

// For some strange reason the paragraph gets active when a list is also
// selected, that's why we need these extra conditions
export const isParagraphActive = (editor: Editor) =>
  editor.isActive('paragraph') &&
  !(editor.isActive('bulletList') || editor.isActive('orderedList'))
    ? 'is-active'
    : ''

export const isTitleActive = (editor: Editor) =>
  editor.isActive('heading', { level: 1 }) ? 'is-active' : ''

export const isHeadingActive = (editor: Editor) =>
  editor.isActive('heading', { level: 2 }) ? 'is-active' : ''

export const isSubheadingActive = (editor: Editor) =>
  editor.isActive('heading', { level: 3 }) ? 'is-active' : ''

export const isBulletListActive = (editor: Editor) =>
  editor.isActive('bulletList') ? 'is-active' : ''

export const isNumberedListActive = (editor: Editor) =>
  editor.isActive('orderedList') ? 'is-active' : ''

export const isBoldActive = (editor: Editor) =>
  editor.isActive('bold') ? 'is-active' : ''

export const isItalicsActive = (editor: Editor) =>
  editor.isActive('italic') ? 'is-active' : ''

export const isUnderlineActive = (editor: Editor) =>
  editor.isActive('underline') ? 'is-active' : ''

export const isLinkActive = (editor: Editor) =>
  editor.isActive('link') ? 'is-active' : ''
