import * as Sentry from '@sentry/browser'

type EventCallback<EventsMap, Key extends keyof EventsMap> = (
  data: EventsMap[Key]
) => void

type EventsCallbacks<EventsMap> = {
  [key in keyof EventsMap]?: EventCallback<EventsMap, key>[]
}

/**
 * This whole class is controversial and the future of this class
 * should be determined by https://github.com/sketch-hq/Cloud/issues/2975
 *
 * Until this time avoid using it unless there is no other option
 */
export class EventEmitter<EventsMap extends {}> {
  private events: EventsCallbacks<EventsMap> = {}

  dispatch = <T extends keyof EventsMap>(event: T, data: EventsMap[T]) => {
    const callbacks = this.events[event]
    if (!callbacks) return

    /**
     * We need to make a copy here because, event handler (callback)
     * might (and actually id does) lead to new subscriptions / un-subscriptions
     * of existing callbacks.
     * Without copying the array we might end up executing wrong callback
     * and not executing the right ones.
     */
    const nonChangingCallbacks = callbacks.slice()

    for (const callback of nonChangingCallbacks) {
      try {
        callback(data)
      } catch (error) {
        Sentry.withScope(scope => {
          scope.setExtra('EventEmitter', event)
          Sentry.captureException(error)
        })
      }
    }
  }

  subscribe = <T extends keyof EventsMap>(
    event: T,
    callback: EventCallback<EventsMap, T>
  ) => {
    let callbacks = this.events[event]
    if (!callbacks) {
      callbacks = this.events[event] = []
    }

    callbacks.push(callback)
  }

  unsubscribe = <T extends keyof EventsMap>(
    event: T,
    callback: EventCallback<EventsMap, T>
  ) => {
    const callbacks = this.events[event]
    if (!callbacks) return

    const index = callbacks.indexOf(callback)
    if (index === -1) return

    callbacks.splice(index, 1)
  }
}
