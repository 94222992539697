import styled from 'styled-components'

import { Skeleton } from '@sketch/components'

export const SkeletonAvatar = styled(Skeleton)`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  margin-right: 8px;
  border-radius: ${({ theme }) => theme.radii.rounded};
`
