import styled, { css } from 'styled-components'
import { FloatingMenu } from '@tiptap/react'

import { DropdownItemStyle, DropdownHeader } from '../../Dropdown'

export const FloatingMenuStyled = styled(FloatingMenu).attrs({ role: 'list' })(
  ({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    padding: 8px 0;

    min-width: 100px;
    overflow: hidden;

    box-shadow: ${theme.shadows.boxShadow.outer};
    border: 1px solid ${theme.colors.border.A};
    border-radius: ${theme.radii.xxlarge};

    background: ${theme.colors.background.secondary.A};
    background-clip: padding-box;

    /* Make the popover on top of the modal */
    z-index: ${theme.zIndex[8]};

    button:focus {
      background: ${theme.colors.background.tertiary.C};
      color: ${theme.colors.foreground.secondary.A};
    }
  `
)

export const FloatingMenuTitle = styled(DropdownHeader)``

export const FloatingMenuListItem = styled.li`
  list-style: none;
  padding: 0 8px;
`

export const FloatingMenuItem = styled(DropdownItemStyle)`
  display: flex;
  align-items: center;
`

export const FloatingMenuIcon = styled.svg(
  ({ theme }) => css`
    flex: none;
    width: 16px;
    height: 16px;
    margin-right: 12px;
    margin-top: -2px; /* stylelint-disable-line scales/space */
    color: ${theme.colors.foreground.secondary.D};

    .is-active & {
      color: inherit;
    }
  `
)
