import styled from 'styled-components'

export const NavbarItem = styled.div`
  display: flex;
  margin: 0 8px;

  & + & {
    margin-left: 0;
  }
`
