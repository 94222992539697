import * as yup from 'yup'
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const validEmail = (
  schema?: yup.StringSchema<string | null | undefined>
): yup.StringSchema<string | null | undefined> => {
  const baseSchema = schema || yup.string()
  return baseSchema.matches(
    EMAIL_REGEX,
    'Invalid email format. Try re-entering your email address.'
  )
}
