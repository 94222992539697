import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  cursor: inherit;
`

export const OriginCross = styled.span`
  ::after,
  ::before {
    left: 0;
    top: 0;
    position: absolute;
    content: '';
    display: block;
    transform: translate(-50%, -50%);
  }

  ::after {
    border-top: 1px solid black;
    width: 20px;
  }

  ::before {
    border-left: 1px solid black;
    height: 20px;
    width: 1px;
  }
`
