import React, { useState } from 'react'
import { useField } from 'formik'

import { Form, Input } from '@sketch/components'

import { uniqueId } from '@sketch/utils'

interface FormFieldProps<F> {
  name: F
  label: string
  placeholder?: string
  help?: string
  disabled?: boolean
}

function FormField<F extends {}>(props: FormFieldProps<keyof F & string>) {
  const [inputUniqueId] = useState(uniqueId('formfield'))

  const { placeholder, label, help, name, disabled } = props
  const [field, meta] = useField({ name })

  return (
    <Form.Field
      id={inputUniqueId}
      label={label}
      help={help}
      errorText={meta.touched ? meta.error : undefined}
    >
      <Input
        placeholder={placeholder}
        type="text"
        disabled={disabled}
        {...field}
        value={field.value || ''}
      />
    </Form.Field>
  )
}
export default FormField
