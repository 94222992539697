import styled from 'styled-components'
import { ErrorMessage } from '@sketch/components'

export const ProcessingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
`

export const PageContent = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`

export const ProcessingMessage = styled(ErrorMessage)`
  max-width: 480px;
`

export const PageLoading = styled(PageContent)`
  justify-content: center;
  align-items: center;
`
