import { DataProxy } from 'apollo-cache'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import isEqual from 'lodash.isequal'
import * as Sentry from '@sentry/browser'

import {
  CredentialsFragment,
  GetUserCredentialsQuery,
  GetUserCredentialsDocument,
  UserFeatureFlagsFragmentDoc,
  UserFeatureFlagsFragment,
} from '@sketch/gql-types'

import {
  Authorization,
  AuthorizationId,
  areAuthorizationIdsEqual,
  isPersonalAuthorization,
  isSsoAuthorization,
  PersonalAuthorization,
  SsoAuthorization,
  getSessionIdFromToken,
} from './authorizations'

import { log, getParsedItem, setStringifiedItem, getItem } from '@sketch/utils'
import { localStorageKeys } from '@sketch/constants'
import { dataIdFromObject } from '@sketch/graphql-cache'

interface WorkspaceForAuth {
  ssoEnabled: boolean
  identifier: string
}

interface JWTTokenPayload extends JwtPayload {
  sub: string
  flags: string[]
}

const updateFeatureFlagsFromToken = (
  cache: DataProxy,
  newAuthorization: Authorization
) => {
  const tokenDecode = jwtDecode<JWTTokenPayload>(
    newAuthorization.fragment.authToken
  )

  /**
   * Safeguarding ourself from non-expected
   * format tokens
   */
  if (!tokenDecode.sub || !tokenDecode.flags) {
    return
  }

  const userProfileId = dataIdFromObject({
    __typename: 'User',
    identifier: tokenDecode.sub,
  }) as string

  cache.writeFragment<UserFeatureFlagsFragment>({
    fragment: UserFeatureFlagsFragmentDoc,
    id: userProfileId,
    fragmentName: 'UserFeatureFlags',
    data: {
      __typename: 'User',
      identifier: tokenDecode.sub,
      featureFlags: tokenDecode.flags,
    },
  })
}

export const getUserAuthorization = (cache: DataProxy) => {
  try {
    const data = cache.readQuery<GetUserCredentialsQuery>({
      query: GetUserCredentialsDocument,
    })

    return data?.userCredentials || null
  } catch (e) {
    return null
  }
}

export const setUserAuthorization = (
  cache: DataProxy,
  authorization: Authorization
) => {
  addOrUpdateAuthorization(cache, authorization)
  setApolloCredentials(cache, authorization.fragment)
}

export const getAllAuthorizations = (): Authorization[] =>
  getParsedItem(localStorageKeys.userAllAuthorizations) ?? []

export const setAllAuthorizations = (authorizations: Authorization[]) => {
  const oldValue = getItem(localStorageKeys.userAllAuthorizations)
  setStringifiedItem(localStorageKeys.userAllAuthorizations, authorizations)

  /**
   * We dispatch this fake event to allow the useReactiveAuthorization
   * to update it's internal values and. the local-storage event listener
   * only allows the receive changes from other tabs. This work-around will
   * allow it to work always.
   */
  setTimeout(() => {
    const storageEvent = new StorageEvent('storage', {
      key: localStorageKeys.userAllAuthorizations,
      storageArea: localStorage,
      newValue: JSON.stringify(authorizations),
      oldValue,
    })

    window.dispatchEvent(storageEvent)
  })
}

export const getActiveAuthorization = (
  cache: DataProxy
): Authorization | undefined => {
  const credentials = getUserAuthorization(cache)

  const allAuthorizations = getAllAuthorizations()
  return allAuthorizations.find(auth => isEqual(auth.fragment, credentials))
}

export const removeActiveAuthorization = (cache: DataProxy) => {
  const activeAuth = getActiveAuthorization(cache)
  if (!activeAuth) return

  removeAuthorizationByAuthToken(cache, activeAuth.fragment.authToken)
  setFallbackAuthorizationId(cache)
}

export const removeAuthorizationByAuthToken = (
  cache: DataProxy,
  authToken: string
) => {
  const allAuthorizations = getAllAuthorizations()

  const index = allAuthorizations.findIndex(
    auth => auth.fragment.authToken === authToken
  )
  const removedAuth = allAuthorizations.splice(index, 1)[0]

  setAllAuthorizations(allAuthorizations)

  const activeAuthorization = getUserAuthorization(cache)
  const isAuthorizationActive = activeAuthorization?.authToken === authToken

  if (isAuthorizationActive) {
    setFallbackAuthorizationId(cache)
  }

  return removedAuth
}

/**
 * Replace all authorizations by the one provided. This is useful to entirely
 * switch accounts.
 */
export const replaceAllAuthorizations = (authorization: Authorization) => {
  setAllAuthorizations([authorization])
}

/**
 * Add or Replace an already existent authorization with the newly negotiated tokens.
 *
 * @param cache Apollo cache's instance
 * @param newAuthorization New Authorization
 */
export const addOrUpdateAuthorization = (
  cache: DataProxy,
  newAuthorization: Authorization
) => {
  const allAuthorizations = getAllAuthorizations()
  const authorizationsWithTheSameIdentifier: Authorization[] = []

  /**
   * Exclude from the all authorizations saved the authorization
   * that is going to be added.
   */
  const clearedAuthorizations = allAuthorizations.filter(authorization => {
    const isAuthorizationEqual = areAuthorizationIdsEqual(
      authorization,
      newAuthorization
    )

    if (isAuthorizationEqual) {
      authorizationsWithTheSameIdentifier.push(authorization)
    }

    try {
      /**
       * Given that the tokens refresh every 60m and they
       * contain the FF information, might as well updated them
       */
      updateFeatureFlagsFromToken(cache, newAuthorization)
    } catch (error) {
      /**
       * If something happens with this update it's not as relevant
       * as the token being updated so we discard interrupting the
       * function flow
       */
      Sentry.captureException(error)
    }

    return !isAuthorizationEqual
  })

  /**
   * We add the new/updated authorization at the beginning
   * of the list to promote the newer tokens when sending
   * as secondary tokens.
   *
   * https://github.com/sketch-hq/Cloud/issues/15988
   */
  clearedAuthorizations.unshift(newAuthorization)

  setAllAuthorizations(clearedAuthorizations)

  const activeAuthorization = getUserAuthorization(cache)
  const isNewAuthorizationActive = authorizationsWithTheSameIdentifier.find(
    ({ fragment }) => activeAuthorization?.authToken === fragment.authToken
  )

  /**
   * If the current active authorization was the updated one
   * then we should reflect it on apollo
   */
  if (isNewAuthorizationActive) {
    setApolloCredentials(cache, newAuthorization.fragment)
  }
}

/**
 * Sets the active authorization for a workspace. If the workspace has SSO
 * enabled then we try setting the current session as the one with authorization
 * for that workspace. We use a personal session otherwise.
 */
export const setActiveAuthorizationIdForWorkspace = (
  workspace: WorkspaceForAuth,
  cache: DataProxy
) => {
  const { ssoEnabled, identifier } = workspace

  if (ssoEnabled) {
    setActiveAuthorizationId({ type: 'sso', workspaceId: identifier }, cache)
  } else {
    setActiveAuthorizationId({ type: 'personal' }, cache)
  }
}

/**
 * Configures active credentials picking one from the list of sessions.
 * This is used as a default value in case the current route doesn't set any
 * active credentials.
 */
export const setFallbackAuthorizationId = (cache: DataProxy) => {
  const allAuthorizations = getAllAuthorizations()
  const personalAuthorization = allAuthorizations.find(isPersonalAuthorization)

  const auth = personalAuthorization ?? allAuthorizations[0]

  setApolloCredentials(cache, auth?.fragment || null)
}

export const setActiveAuthorizationId = (
  authId: AuthorizationId,
  cache: DataProxy
) => {
  const allAuthorizations = getAllAuthorizations()

  const activeAuthorization = allAuthorizations.find(auth =>
    areAuthorizationIdsEqual(auth, authId)
  )

  const personalAuthorization = allAuthorizations.find(isPersonalAuthorization)

  /**
   * We fallback first to personal authorization as it's the default token we
   * used for everything before introducing SSO. Then, if there is no personal
   * token we just get the first stored authorization.
   */
  const auth =
    activeAuthorization ?? personalAuthorization ?? allAuthorizations[0]

  log(`Session -> Setting active auth to ${authId.type}. Using ${auth?.type}`)

  setApolloCredentials(cache, auth?.fragment)
}

export const setApolloCredentials = (
  cache: DataProxy,
  credentials: CredentialsFragment | null
) => {
  const previousCredentials = getUserAuthorization(cache)

  /**
   * If both credentials are exactly the same we avoid updating them. This
   * prevents Apollo from firing update events that all subscriptions would
   * receive.
   */
  if (isEqual(previousCredentials, credentials)) {
    return
  }

  cache.writeQuery<GetUserCredentialsQuery>({
    query: GetUserCredentialsDocument,
    data: {
      __typename: 'RootQueryType',
      userCredentials: credentials,
    },
  })
}

/**
 * Find the authorization of type personal (user/password)
 * among all the authorizations of the user.
 */
export function getPersonalAuthorization(): PersonalAuthorization | null {
  const allAuths = getAllAuthorizations()
  const personalAuth = allAuths.find(isPersonalAuthorization)

  return personalAuth || null
}

/**
 * Find the authorization of type SSO corresponding to the provided
 * workspace Id among all the authorizations of the user.
 */
export function getSsoAuthorizationForWorkspace(
  workspaceId: string
): SsoAuthorization | null {
  const allAuths = getAllAuthorizations()

  const ssoAuths = allAuths.filter(isSsoAuthorization)
  const workspaceSsoAuth = ssoAuths.find(
    auth => auth.workspaceId === workspaceId
  )
  return workspaceSsoAuth || null
}

/**
 * Find the authorization based on the session id
 */
export function getAuthorizationFromSessionId(sessionId: string) {
  const allAuths = getAllAuthorizations()

  return allAuths.find(auth => {
    const id = getSessionIdFromToken(auth)

    return sessionId === id
  })
}
