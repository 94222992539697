import React, { ReactElement, useState } from 'react'
import styled, { DefaultTheme, ThemeProps } from 'styled-components'

interface OptionsProps {
  shouldDrawSeparator: boolean
}

const Options = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;

  li {
    display: inline-block;
  }

  ${({ shouldDrawSeparator, theme }: OptionsProps & ThemeProps<DefaultTheme>) =>
    shouldDrawSeparator
      ? `border-bottom: 1px solid ${theme.colors.background.primary.B}`
      : ''}
`

export interface TabListProps {
  selectedTabIndex?: number
  shouldDrawSeparator?: boolean
  children: React.ReactElement[]
  onTabSelected?: (tabOption: number) => void
  shouldSelectTab?: (tabOption: number) => boolean
  className?: string
}

export const TabListOld = ({
  children,
  selectedTabIndex = 0,
  shouldDrawSeparator = false,
  onTabSelected,
  shouldSelectTab = () => true,
  className,
}: TabListProps) => {
  const tabs = React.Children.toArray(children)
  const [selectedTab, setSelectedTab] = useState(selectedTabIndex)

  return (
    <Options shouldDrawSeparator={shouldDrawSeparator} className={className}>
      {tabs.map((tab, index) => {
        return React.cloneElement(tab as ReactElement, {
          onTabSelected: () => {
            if (!shouldSelectTab(index)) return

            setSelectedTab(index)
            onTabSelected?.(index)
          },
          selected: selectedTab === index,
        })
      })}
    </Options>
  )
}

TabListOld.tabType = 'TabList'
