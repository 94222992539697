import { DataProxy } from 'apollo-cache'
import { removeFromPaginated, removeQuery } from '@sketch/modules-common'

export interface HandleShareRestoredProps {
  cache: DataProxy
  identifier: string
  workspaceIdentifier: string
  projectIdentifier?: string
}

export const handleShareRestored = (props: HandleShareRestoredProps) => {
  const { cache, identifier, workspaceIdentifier, projectIdentifier } = props

  // ProjectFragment should be removed once BE returns the newer types in the getTrash
  // https://github.com/sketch-hq/Cloud/issues/11226
  removeFromPaginated(
    cache,
    { __typename: 'Share', identifier },
    key => key.includes('.workspaceTrash') || key.includes('.shares')
  )

  // there still can be smaller fragments in the cache
  // let's remove all of them.
  removeFromPaginated(
    cache,
    { __typename: 'TrashedShare', identifier },
    key => key.includes('.workspaceTrash') || key.includes('.shares')
  )

  // Remove from cache every query related with this workspace shares list
  // We are never fully aware of the restored share placement and trying to match that would take a lot of cache operations
  // This way the view will be refetched from scratch if needed to make sure
  // we always get the correct server information
  removeQuery(cache, key => {
    const keyIncludesWorkspace = key.includes(workspaceIdentifier)

    const keyIncludesProject = Boolean(
      projectIdentifier && key.includes(projectIdentifier)
    )

    return (
      (keyIncludesProject || keyIncludesWorkspace) && key.includes('.shares')
    )
  })
}
