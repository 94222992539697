import React from 'react'
import styled from 'styled-components'

import { Wrapper, Icon, IconWrapper, Text } from './Segment.styles'

interface SegmentProps {
  className?: string
  type: 'text' | 'icon'
  label: string
  icon?: React.ElementType
  active?: boolean
  as?: React.ElementType
  render?: (className: string, children: React.ReactNode) => React.ReactNode
  disabled?: boolean
}

const WrapperComponent: React.FC<any> = ({ className, render, children }) => {
  return render(className, children)
}

const SegmentUnstyled: React.FC<SegmentProps> = props => {
  const {
    active,
    className,
    type,
    label,
    icon,
    render = (className, children) => (
      <span className={className}>{children}</span>
    ),
    disabled,
  } = props

  const children =
    type === 'text' ? (
      <Text>{label}</Text>
    ) : (
      <IconWrapper>
        <span className="sr-only">{label}</span>
        <Icon as={icon} />
      </IconWrapper>
    )

  return (
    <Wrapper
      className={className}
      $active={active}
      render={render}
      as={WrapperComponent}
      $disabled={disabled}
    >
      {children}
    </Wrapper>
  )
}

export const Segment = styled(SegmentUnstyled)``
