import React, { useRef, useState, useContext } from 'react'

import {
  Button,
  ErrorMessage,
  LoadingPlaceholder,
  Modal,
  ModalContext,
  ModalInjectedProps,
  UserChip,
} from '@sketch/components'

import SignInWithPasswordForm from './SignInWithPasswordForm'

import MfaVerificationCodeModal from '../../../user/modals/MfaAuthenticationModal'

import {
  UserChipContainer,
  Label,
  LoadingErrorWrapper,
} from './SignInWithPasswordModal.styles'
import { useUserProfile } from '@sketch/modules-common'

export interface OnlySignInWithPasswordModalProps {
  workspaceName: string
  onCompleted?: () => void
  // We want to reuse this modal for the cases where a user is signed in as SSO
  // and wants to accept an invitation, so they will need to login with their
  // personal account
  invitationEmail?: string
}

type SignInWithPasswordModalProps = OnlySignInWithPasswordModalProps &
  ModalInjectedProps

const SignInWithPasswordModal: React.FC<SignInWithPasswordModalProps> = ({
  workspaceName,
  onCompleted,
  hideModal,
  invitationEmail,
}) => {
  const { showModal } = useContext(ModalContext)

  const { data, loading, error } = useUserProfile()

  const submitButtonRef = useRef<HTMLButtonElement>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const hasError = error || !data?.me

  const title = `Sign in to the “${workspaceName}” Workspace`

  if (loading || hasError) {
    return (
      <Modal title={title} onCancel={hideModal}>
        <LoadingErrorWrapper>
          {!hasError ? (
            <LoadingPlaceholder size="64px" />
          ) : (
            <ErrorMessage.Generic icon={null} />
          )}
        </LoadingErrorWrapper>

        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={isLoading}
            onClick={() => hideModal()}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const user = data.me
  const email = invitationEmail || user.personalIdentityEmail!

  const isMfaEnabled = user.mfaState === 'ENABLED'

  return (
    <Modal title={title} onCancel={hideModal}>
      <Modal.Body>
        <UserChipContainer>
          <Label>Email</Label>
          <UserChip
            name={user.name!}
            email={email}
            avatarSrc={user.avatar?.small!}
          />
        </UserChipContainer>
        <SignInWithPasswordForm
          submitButtonRef={submitButtonRef}
          email={email}
          onSubmit={() => setIsLoading(true)}
          onCompleted={data => {
            hideModal()

            if (isMfaEnabled && data.signIn.__typename === 'MFACredentials') {
              showModal(MfaVerificationCodeModal, {
                mfaToken: data.signIn.mfaToken,
                onSuccess: onCompleted,
              })
            } else {
              onCompleted?.()
            }
          }}
          onError={() => setIsLoading(false)}
          onDisableSubmit={disabled => setDisableSubmit(disabled)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          disabled={isLoading}
          onClick={() => hideModal()}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={isLoading || disableSubmit}
          loading={isLoading}
          onClick={() => submitButtonRef.current?.click()}
        >
          Sign In
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SignInWithPasswordModal
