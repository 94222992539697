import React from 'react'
import * as S from './LandscapeBannerCard.styles'

export type LandscapeBannerCardProps = {
  className?: string
  title: string
  description: string
  icon: React.ReactElement
  action?: React.ReactElement
  'data-testid'?: string
}

/**
 * Landscape version of the BannerCard. This component does not support yet the dismiss option since
 * it is not needed for now but we may need it in the future.
 */
export function LandscapeBannerCard({
  className,
  title,
  icon,
  description,
  action,
  'data-testid': dataTestId,
}: LandscapeBannerCardProps) {
  return (
    <S.BannerWrapper className={className} data-testid={dataTestId}>
      <S.BannerIconContainer>{icon}</S.BannerIconContainer>
      <S.BannerBodyContainer>
        <S.BannerTextContainer>
          <S.BannerTitle>{title}</S.BannerTitle>
          <S.BannerDescription>{description}</S.BannerDescription>
        </S.BannerTextContainer>
        {action}
      </S.BannerBodyContainer>
    </S.BannerWrapper>
  )
}
