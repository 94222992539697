import remark from 'remark'
import stringify from 'remark-stringify'

import strip from 'strip-markdown'

import { emoji } from '../tokenizers/emoji'

const parser = remark().use(emoji).use(strip).use(stringify, { gfm: false })

export const stripMarkdownParser = content =>
  parser.processSync(content).contents
