import React from 'react'

import { Rect } from '@sketch-hq/sketch-web-renderer'

import { useArtboardEvents } from '../../hooks'
import * as Styled from './ArtboardOverlay.styled'

export interface ArtboardProps {
  bounds: Rect
  rootExtent?: Rect
  artboardUUID: string
  artboardName: string
  selected?: boolean
  events?: ReturnType<typeof useArtboardEvents>
  disablePointerCursor?: boolean
  disableHover?: boolean
}

const formatArtboardBounds = (bounds: Rect, rootExtent?: Rect) => {
  /**
   * Given we are currently using 2 ways of positioning elements in the canvas
   * - Relative %
   * - Absolute
   *
   * It was decided that if "rootExtent" was passed, this would mean it's a relative to the parent calculation
   * if not it's the absolute position
   */
  if (!rootExtent) {
    return {
      x: `${bounds.x}px`,
      y: `${bounds.y}px`,
      width: `${bounds.width}px`,
      height: `${bounds.height}px`,
    }
  }

  const x = `${(bounds.x / rootExtent.width) * 100}%`
  const y = `${(bounds.y / rootExtent.height) * 100}%`
  const width = `${(bounds.width / rootExtent.width) * 100}%`
  const height = `${(bounds.height / rootExtent.height) * 100}%`

  return { x, y, width, height }
}

/**
 * This element takes care of:
 * - Rendering the artboard name, cta and artboard outline.
 * - Tracking the user events on the artboard and emit them when needed.
 */
export const ArtboardOverlay = React.forwardRef<HTMLDivElement, ArtboardProps>(
  (props, ref) => {
    const {
      artboardUUID,
      artboardName,
      bounds,
      selected,
      disablePointerCursor,
      disableHover,
      rootExtent,
      events,
    } = props

    const { width, height, x, y } = formatArtboardBounds(bounds, rootExtent)

    return (
      <Styled.Artboard
        aria-selected={selected}
        data-testid="artboard-overlay"
        data-artboard-uuid={artboardUUID}
        ref={ref}
        // This "tabIndex" is commented on purpose
        // to prevent the canvas from translating when a tab event happens
        // check https://github.com/sketch-hq/Cloud/issues/18432 to see the behaviour
        //
        // tabIndex={0}
        $disableHover={disableHover}
        style={{
          left: x,
          top: y,
          width: width,
          height: height,
          cursor: disablePointerCursor ? 'inherit' : undefined,
        }}
        /* Event handlers */
        onDoubleClick={events?.containerDoubleClick}
        {...events?.container}
      >
        <Styled.ArtboardHeader>
          <Styled.ArtboardCTA
            // This "tabIndex" was added purposefully
            // to prevent the canvas from translating when a tab event happens
            // check https://github.com/sketch-hq/Cloud/issues/18432 to see the behaviour
            //
            tabIndex={-1}
            {...events?.cta}
          >
            <Styled.ArtboardName>{artboardName}</Styled.ArtboardName>
            <Styled.ArrowIcon />
          </Styled.ArtboardCTA>
        </Styled.ArtboardHeader>
      </Styled.Artboard>
    )
  }
)

ArtboardOverlay.displayName = 'ArtboardOverlay'

export default ArtboardOverlay
