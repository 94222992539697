import { SharePublicationFragment } from '@sketch/gql-types'

/*
 This is a specific size for the profile thumbnail.
 Besides the retina version we want it pixel perfect
 - 400x400 1x, 800x800 2x
 */
const PUBLICATION_THUMBNAIL_SIZE = 'W400P'

interface PublicationThumbnailSource {
  standard: string | undefined
  retina: string | undefined
}

export type PublicationThumbnail = PublicationThumbnailSource | undefined

export const getPublicationThumbnail = (
  item: SharePublicationFragment
): PublicationThumbnail => {
  if (!item.version?.document?.previewFile?.thumbnails?.length) {
    return
  }

  const { thumbnails } = item.version.document.previewFile

  const profileThumbnail = thumbnails.find(
    thumbnail => thumbnail?.type === PUBLICATION_THUMBNAIL_SIZE
  )

  const sources = {
    retina: profileThumbnail?.url2x?.toString(),
    standard: profileThumbnail?.url?.toString(),
  }

  const hasUrls = !!Object.values(sources).find(value => !!value)

  if (!hasUrls) {
    return undefined
  }

  return sources
}
