import React from 'react'

import { Banner } from '@sketch/components'
import StickyCommentInput from '../StickyCommentInput'
import { CommentInputProps } from '../CommentInput/CommentInput'

interface AnnotationCommentBoxProps extends Omit<CommentInputProps, 'banner'> {
  showOlderVersionWarning: boolean
}

const AnnotationCommentBox = ({
  showOlderVersionWarning,
  ...props
}: AnnotationCommentBoxProps) => {
  return (
    <StickyCommentInput
      banner={
        showOlderVersionWarning ? (
          <Banner type="warning" showIcon={false}>
            You’re commenting on an older version.
          </Banner>
        ) : undefined
      }
      {...props}
    />
  )
}

export default AnnotationCommentBox
