import { BillingPlanFragment } from '@sketch/gql-types'
import { useMemo, useReducer } from 'react'

import {
  DiscountError,
  SubscribeBilling,
  SubscribeToPlanState,
} from '../../types'

type Action =
  | { type: 'add-selected-plan'; payload: BillingPlanFragment }
  | { type: 'set-billing-information'; payload: SubscribeBilling }
  | { type: 'apply-discount-code'; payload: string }
  | { type: 'clear-discount-code' }
  | { type: 'update-discount-error'; payload?: DiscountError }
  | { type: 'set-editors'; payload: number }

const reducer = (state: SubscribeToPlanState, action: Action) => {
  const nextState = { ...state, dirty: true }

  switch (action.type) {
    case 'add-selected-plan': {
      return {
        ...nextState,
        plan: action.payload,
        // Clear discount code if the plan changes
        discountCode:
          nextState.plan === action.payload
            ? nextState.discountCode
            : undefined,
      }
    }

    case 'set-billing-information': {
      return {
        ...nextState,
        billing: action.payload,
      }
    }

    case 'apply-discount-code': {
      return {
        ...nextState,
        discountCode: action.payload,
      }
    }

    case 'clear-discount-code': {
      return {
        ...nextState,
        discountCode: undefined,
        discountError: undefined,
      }
    }

    case 'update-discount-error': {
      return {
        ...nextState,
        discountError: action.payload,
      }
    }

    case 'set-editors': {
      return {
        ...nextState,
        editors: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

const useWorkspaceSubscribeState = () => {
  const [state, dispatch] = useReducer(reducer, {
    dirty: false,
  })

  /**
   * These will be the triggers that each tab will
   * call during the subscribe flow to update the
   * common state between all steps.
   */
  const eventTriggers = useMemo(() => {
    const addSelectedPlan = (plan: BillingPlanFragment) => {
      dispatch({
        type: 'add-selected-plan',
        payload: plan,
      })
    }

    const setBillingInformation = (billing: SubscribeBilling) => {
      const type = 'set-billing-information'

      dispatch({ type, payload: billing })
    }

    const applyDiscountCode = (discountCode: string) => {
      const type = 'apply-discount-code'

      dispatch({ type, payload: discountCode })
    }

    const clearDiscountCode = () => {
      const type = 'clear-discount-code'

      dispatch({ type })
    }

    const updateDiscountError = (payload: DiscountError | undefined) => {
      const type = 'update-discount-error'

      dispatch({ type, payload })
    }

    const setEditors = (editors: number) => {
      dispatch({
        type: 'set-editors',
        payload: editors,
      })
    }

    return {
      addSelectedPlan,
      setBillingInformation,
      applyDiscountCode,
      clearDiscountCode,
      updateDiscountError,
      setEditors,
    }
  }, [])

  /**
   * Make sure there aren't additional
   * re-renders when mounting this hook
   */
  return useMemo(() => [state, eventTriggers] as const, [state, eventTriggers])
}

export default useWorkspaceSubscribeState
