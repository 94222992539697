import styled, { css } from 'styled-components'

import { PersonAvatar } from '../PersonAvatar'
import { WorkspaceLogo } from '../WorkspaceLogo'

export type Size = '16px' | '24px'

export interface WrapperProps {
  $size: Size
  $hasBackground: boolean
}

export const AvatarItemWrapper = styled.span``

export const AvatarWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  user-select: none;

  ${PersonAvatar} {
    display: flex;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.background.secondary.A};
  }

  ${AvatarItemWrapper} {
    position: relative;

    /* Make the 2 + items overlay each other */
    &:nth-child(n + 2) {
      margin-left: -6px; /* stylelint-disable-line scales/space */
    }

    /* Make sure the first one is on top of the second */
    &:nth-child(1) {
      z-index: 2; /* stylelint-disable-line scales/z-indices */
    }

    /* Make sure the second one is on top of the third */
    &:nth-child(2) {
      z-index: 1;
    }

    /* If there are more then 3 avatars hide the others visually, they should be in the doc for a11y purposes */
    &:nth-child(n + 4) {
      display: none;
    }
  }

  &&& {
    ${WorkspaceLogo}, ${WorkspaceLogo.Icon} {
      margin-right: 0;
    }
  }
`

export const AdditionalText = styled.span`
  display: flex;
  align-items: center;

  order: 2;
  padding: 0 8px 0 4px;

  & + ${/* sc-selector */ AvatarWrapper} > ${PersonAvatar} {
    &:nth-child(n + 2) {
      display: none;
    }
  }
`

export const Wrapper = styled.span<WrapperProps>`
  display: inline-flex;
  position: relative;

  border-radius: ${({ theme }) => theme.radii.xxlarge};

  && ${/* sc-selector */ AdditionalText} {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ $size, theme }) => {
      switch ($size) {
        case '16px':
          return theme.fontSizes.B
        case '24px':
          return theme.fontSizes.E
      }
    }};
  }

  /* Background is included in the Wrapper so we don't have to calculate paddings/margins on "AdditionalText" */
  ${({ $hasBackground, theme }) =>
    $hasBackground &&
    css`
      background-color: ${theme.colors.sketch.F};
      color: ${theme.colors.sketch.A};
    `}
`
