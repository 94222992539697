import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "appearance-settings-light-mode", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 280 143", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("rect", { id: "appearance-settings-light-mode_svg__a", x: 0, y: 0, width: 12, height: 12, rx: 6 }),
        React.createElement("rect", { id: "appearance-settings-light-mode_svg__b", x: 0, y: 0, width: 12, height: 12, rx: 2 })),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("rect", { stroke: "#E6E6E6", strokeWidth: 2, fill: "#FFF", x: 1, y: 1, width: 278, height: 141, rx: 4 }),
        React.createElement("path", { d: "M6 2h90v139H6a4 4 0 01-4-4V6a4 4 0 014-4z", fill: "#F9F9F9" }),
        React.createElement("path", { stroke: "#E6E6E6", d: "M96.5 2v139.999" }),
        React.createElement("g", { transform: "translate(2 117.5)" },
            React.createElement("use", { fill: "#FAD266", xlinkHref: "#appearance-settings-light-mode_svg__a", transform: "translate(8 6.5)" }),
            React.createElement("rect", { fillOpacity: 0.3, fill: "#000", x: 26, y: 10.5, width: 32, height: 4, rx: 2 }),
            React.createElement("path", { stroke: "#E6E6E6", d: "M0 .5h94" })),
        React.createElement("g", { transform: "translate(4 38)" },
            React.createElement("rect", { fillOpacity: 0.1, fill: "#000", x: 6, y: 20, width: 32, height: 4, rx: 2 }),
            React.createElement("g", { transform: "translate(6)", fill: "#000", fillOpacity: 0.3 },
                React.createElement("rect", { width: 4, height: 4, rx: 2 }),
                React.createElement("rect", { x: 8, width: 42, height: 4, rx: 2 })),
            React.createElement("g", { transform: "translate(6 52)", fill: "#000", fillOpacity: 0.3 },
                React.createElement("rect", { width: 4, height: 4, rx: 2 }),
                React.createElement("rect", { x: 8, width: 42, height: 4, rx: 2 })),
            React.createElement("g", { transform: "translate(0 31)", fill: "#F26725" },
                React.createElement("rect", { opacity: 0.1, width: 88, height: 14, rx: 2 }),
                React.createElement("rect", { x: 14, y: 5, width: 42, height: 4, rx: 2 }),
                React.createElement("rect", { x: 6, y: 5, width: 4, height: 4, rx: 2 }))),
        React.createElement("g", { transform: "translate(10 8)" },
            React.createElement("use", { fill: "#FAD266", xlinkHref: "#appearance-settings-light-mode_svg__b" }),
            React.createElement("rect", { fillOpacity: 0.3, fill: "#000", x: 18, y: 4, width: 32, height: 4, rx: 2 })),
        React.createElement("path", { stroke: "#E6E6E6", d: "M2 26h276" }),
        React.createElement("g", { fill: "#000" },
            React.createElement("g", { transform: "translate(113 42)" },
                React.createElement("path", { d: "M5.128 0h61.744c1.783 0 2.43.186 3.082.534.652.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v61.744c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 01-1.512 1.512c-.652.348-1.299.534-3.082.534H5.128c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 01-1.512-1.512C.186 69.302 0 68.655 0 66.872V5.128c0-1.783.186-2.43.534-3.082A3.635 3.635 0 012.046.534C2.698.186 3.345 0 5.128 0z", fillOpacity: 0.04 }),
                React.createElement("rect", { fillOpacity: 0.3, y: 80, width: 32, height: 4, rx: 2 })),
            React.createElement("g", { transform: "translate(197 42)" },
                React.createElement("path", { d: "M5.128 0h61.744c1.783 0 2.43.186 3.082.534.652.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v61.744c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 01-1.512 1.512c-.652.348-1.299.534-3.082.534H5.128c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 01-1.512-1.512C.186 69.302 0 68.655 0 66.872V5.128c0-1.783.186-2.43.534-3.082A3.635 3.635 0 012.046.534C2.698.186 3.345 0 5.128 0z", fillOpacity: 0.04 }),
                React.createElement("rect", { fillOpacity: 0.3, y: 80, width: 32, height: 4, rx: 2 })))));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
