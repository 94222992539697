import React from 'react'

import {
  IndexLayoutExtraProps,
  useUserAuthorizations,
} from '@sketch/modules-common'
import {
  LinkAccountConfirmationView,
  SignInToLinkAccountView,
} from '@sketch/sub-app-authentication'

interface SsoLinkAccountRouteProps extends IndexLayoutExtraProps {}

export const SsoLinkAccountRoute = (props: SsoLinkAccountRouteProps) => {
  const { hasPersonalAuthorization } = useUserAuthorizations()

  if (hasPersonalAuthorization) {
    return <LinkAccountConfirmationView {...props} />
  } else {
    return <SignInToLinkAccountView {...props} />
  }
}
