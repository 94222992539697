import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "card-default-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M11.795 3.75c.902 0 1.24.067 1.572.245.275.147.49.363.638.638.178.332.245.67.245 1.572v4.59c0 .902-.067 1.24-.245 1.572-.147.275-.363.49-.638.638-.332.178-.67.245-1.572.245h-7.59c-.902 0-1.24-.067-1.572-.245a1.532 1.532 0 01-.638-.638c-.178-.332-.245-.67-.245-1.572v-4.59c0-.902.067-1.24.245-1.572.147-.275.363-.49.638-.638.332-.178.67-.245 1.572-.245z", stroke: "currentColor", strokeWidth: 1.5 }),
        React.createElement("path", { fill: "currentColor", d: "M2 6h13v2H2z" }),
        React.createElement("circle", { fill: "currentColor", cx: 4.5, cy: 10.5, r: 1 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
