import {
  Breadcrumb as BreadcrumbBase,
  StyledBreadcrumbItem,
  breadcrumbStyles,
  breadcrumbDividerStyles,
} from './Breadcrumb'

export { breadcrumbStyles, breadcrumbDividerStyles }

export const Breadcrumb = Object.assign(BreadcrumbBase, {
  Item: StyledBreadcrumbItem,
})
