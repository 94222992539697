import React from 'react'

import {
  NotFoundView,
  RouteProps,
  useUserProfile,
} from '@sketch/modules-common'

import { WorkspaceProfileLayout } from '../containers/WorkspaceProfileLayout'
import { ProfileInfo } from '../components/ProfileInfo'
import CommunityHeader from '../components/CommunityHeader'
import PublicViewHiddenProfileBanner from '../components/PublicViewHiddenProfileBanner'

import { useGetWorkspaceProfile } from '../hooks/useGetWorkspaceProfile'

interface WorkspaceProfilePublicViewProps
  extends RouteProps<'WORKSPACE_PROFILE'> {}

const WorkspaceProfilePublicView = (props: WorkspaceProfilePublicViewProps) => {
  const { shortUrlName } = props.match.params

  const profileResult = useGetWorkspaceProfile({
    variables: { shortUrlName },
  })

  const { data } = useUserProfile()
  const user = data?.me

  if (profileResult.error) {
    return <NotFoundView />
  }

  const workspaceIdentifier = profileResult.profile?.workspace.identifier
  const isAdmin = profileResult.profile?.workspace.userRole === 'ADMIN'
  const isProfilePublic = profileResult.profile?.isPublic === true
  const showHiddenProfileBanner = isAdmin && !isProfilePublic

  return (
    <>
      {showHiddenProfileBanner && workspaceIdentifier && (
        <PublicViewHiddenProfileBanner workspaceId={workspaceIdentifier} />
      )}

      <CommunityHeader user={user} />
      <WorkspaceProfileLayout
        workspaceId={workspaceIdentifier!}
        isProfileEditRoute={false}
        profileResult={profileResult}
        profileInfo={<ProfileInfo profile={profileResult.profile || {}} />}
      />
    </>
  )
}

export default WorkspaceProfilePublicView
