import React, { useCallback, useEffect, useRef } from 'react'
import { InView } from 'react-intersection-observer'

interface LoadPageSeparatorProps {
  /* 
    This "key" is required so that the component re-renders and updates the fetch method
    Usually used with "meta.after" as a value for the key
  */
  key: string
  loadNewPage: () => Promise<any>
}

export const LoadPageSeparator = (props: LoadPageSeparatorProps) => {
  const { loadNewPage } = props

  const loadNewPageRef = useRef(props.loadNewPage)
  useEffect(() => {
    loadNewPageRef.current = loadNewPage
  }, [loadNewPage])

  const onChange = useCallback((inView: boolean) => {
    if (!inView) return

    loadNewPageRef.current()
  }, [])

  return (
    <InView onChange={onChange}>
      <React.Fragment />
    </InView>
  )
}
