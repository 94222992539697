import { useDeleteAnnotationMutation } from '@sketch/gql-types'
import { useFlag, removeFromPaginated } from '@sketch/modules-common'

interface useDeleteAnnotationCommentProps {
  identifier: string
  onCompleted?: () => void
}

const useDeleteAnnotation = ({
  identifier,
  onCompleted,
}: useDeleteAnnotationCommentProps) => {
  const includeHasUnreadComments = useFlag('pages-unread-annotations')

  const [mutate, { loading }] = useDeleteAnnotationMutation({
    variables: { identifier, includeHasUnreadComments },
    onError: 'show-toast',
    onCompleted: () => {
      onCompleted && onCompleted()
    },
    update: (cache, { data }) => {
      if (data?.deleteAnnotation) {
        removeFromPaginated(
          cache,
          { __typename: 'Annotation', identifier },
          key => true
        )
      }
    },
  })
  return [mutate, { loading }] as const
}

export default useDeleteAnnotation
