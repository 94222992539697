const sizes = [' Bytes', 'KB', 'MB', 'GB', 'TB']

interface FormatByteCountOptions {
  fractionDigits?: number
}

export const formatByteCount = (
  bytes: number,
  { fractionDigits = 2 }: FormatByteCountOptions = {}
): string => {
  if (bytes === 0) {
    return '0 Bytes'
  }

  const index = Math.min(
    Math.floor(Math.log(bytes) / Math.log(1000)),
    sizes.length - 1
  )

  return (
    parseFloat((bytes / Math.pow(1000, index)).toFixed(fractionDigits)) +
    sizes[index]
  )
}
