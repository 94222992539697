import React, { useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import {
  Modal,
  ModalInjectedProps,
  Button,
  Input,
  Form,
  Box,
} from '@sketch/components'
import { useLinkRegularAccountMutation } from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'
import { useSignOut } from '@sketch/modules-common'
import { validEmail } from '@sketch/utils'
import { MFAModal } from './MFAModal'

type Values = typeof SIGN_IN_INITIAL_VALUES

const SIGN_IN_INITIAL_VALUES = {
  email: '',
  password: '',
}

const signInSchema = yup.object().shape({
  email: validEmail().required('Email can’t be blank'),
  password: yup.string().required('Password can’t be blank'),
})

interface LinkNonSSOModalProps extends ModalInjectedProps {
  workspaceName: string
}

export const LinkNonSSOModal: React.FC<LinkNonSSOModalProps> = ({
  hideModal,
  workspaceName,
}) => {
  const { showToast } = useToast()
  const [showTwoFactor, setShowTwoFactor] = useState(false)
  const [email, setEmail] = useState('')
  const [mfa, setMFA] = useState('')

  // this error is not exactly a ParsedError, since it has the getContext method
  const handleOnError = (error: any) => {
    if (error.includesErrorReason('MFA_REQUIRED')) {
      const token =
        error
          .getContext()
          .find((c: { key: string; value: string }) => c?.key === 'mfa_token')
          ?.value || ''

      setMFA(token)
      setShowTwoFactor(true)
      return
    }

    showToast(error.message, 'negative')
  }

  const signOut = useSignOut({
    revokeSessions: 'none',
    removeDataFromSessions: 'all',
    reason: 'Account linked',
  })

  const [linkRegularAccount, { loading }] = useLinkRegularAccountMutation({
    onCompleted: () => {
      showToast(`SSO Account linked to ${email}`, 'positive')
      signOut()
    },
    onError: handleOnError,
  })

  const handleOnSubmit = (variables: Values) => {
    setEmail(variables.email)
    linkRegularAccount({ variables })
  }

  if (showTwoFactor) {
    return (
      <MFAModal
        mfa={mfa}
        hideModal={hideModal}
        email={email}
        handleClickBack={() => setShowTwoFactor(false)}
      />
    )
  }

  return (
    <Modal onCancel={hideModal} title="Link Non-SSO Account">
      <Formik
        initialValues={{ ...SIGN_IN_INITIAL_VALUES, email }}
        onSubmit={handleOnSubmit}
        validationSchema={signInSchema}
        validateOnBlur={false}
      >
        {formikBag => {
          const {
            values,
            errors,
            isSubmitting,
            isValid,
            handleChange,
            handleBlur,
            touched,
          } = formikBag

          return (
            <Form>
              <Modal.Body>
                Enter your non-SSO Sketch account details to switch between{' '}
                <strong>{workspaceName}</strong> and other Workspaces you
                already belong to.
                <Box mt={6}>
                  <Form.Field
                    label="Email"
                    name="email"
                    errorText={touched.email ? errors.email : undefined}
                  >
                    <Input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </Form.Field>
                  <Form.Field
                    label="Password"
                    name="password"
                    errorText={touched.password ? errors.password : undefined}
                  >
                    <Input
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                  </Form.Field>
                </Box>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={hideModal}>Cancel</Button>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => handleOnSubmit(values)}
                  loading={isSubmitting || loading}
                  disabled={!isValid}
                >
                  Link Account
                </Button>
              </Modal.Footer>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
