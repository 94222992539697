import React, { useEffect } from 'react'
import { Location } from 'history'
import { Redirect } from 'react-router-dom'
import { isApolloError } from 'apollo-client'

import {
  IndexLayoutErrorOld,
  DynamicLoadingPage,
  RouteProps,
  routes,
} from '@sketch/modules-common'

import {
  useGetCustomerQuery,
  useGetPaymentDetailsQuery,
} from '@sketch/gql-types'
// eslint-disable-next-line no-restricted-imports
import { BillingStatus } from '@sketch/gql-types/expansive'

// Containers
import { useToast } from '@sketch/toasts'
import { PrivateWorkspaceRouteExtraProps } from '../../containers'

import { useModalContext, useStripe } from '@sketch/components'

import SubscriptionDoneModal from '../../modals/SubscriptionDoneModal'
import {
  useCanSubscribeViaStripe,
  isWorkspaceSubscriptionActive,
} from '../../utils'

import { useNagivationScrollTop } from '@sketch/utils'
import useWorkspaceSubscribeState from './WorkspaceSubscribeView.hooks'
import {
  isBillingFormsStep,
  isPlanSelectorStep,
} from './WorkspaceSubscribeView.utils'

// Pages
import {
  WorkspaceSubscribePlan,
  WorkspaceSubscribePaymentDetails,
} from '../../pages'

// GQL
import {
  useSubscribeToPlan,
  useWorkspaceSubscribeInformation,
} from '../../operations'

import {
  WorkspaceSubscribeLayout,
  BreadcrumbsWrapper,
  StyledLogo,
  WorkspaceLogoContainer,
  WorkspaceName,
  StyledOrderedBreadcrumbs,
} from './WorkspaceSubscribeView.styles'

const SUBSCRIBE_CRUMBS = [
  { content: 'Select a plan', step: 'PLAN_SELECT' },
  { content: 'Set Up Payment', step: 'PAYMENT_DETAILS' },
] as const

const SUBSCRIBE_CRUMBS_FOR_BILLING_SIMULATION_FF = (workspaceId: string) =>
  [
    {
      content: 'Set Up Subscription',
      step: 'PLAN_SELECT',
      url: routes.WORKSPACE_SUBSCRIBE.create({
        workspaceId,
      }),
    },
    {
      content: 'Billing Details',
      step: 'PAYMENT_DETAILS',
    },
  ] as const

type Step = typeof SUBSCRIBE_CRUMBS[number]['step']

type WorkspaceSubscribeViewProps = PrivateWorkspaceRouteExtraProps &
  RouteProps<'WORKSPACE_SUBSCRIBE'> & {
    subscriptionStatus?: BillingStatus
    onlyBusinessPlansAllowed?: boolean
  }

const WorkspaceSubscribeView: React.FC<WorkspaceSubscribeViewProps> = props => {
  const {
    history,
    workspace,
    subscriptionStatus,
    onlyBusinessPlansAllowed = false,
  } = props
  // Context
  const { showToast } = useToast()
  const { showModal, hideModal } = useModalContext()

  // Scroll top on the page change
  useNagivationScrollTop()

  // Refining the location props to match the expect values
  const location = props.location as Location<{ step?: Step }>
  const workspaceId = workspace.identifier

  // We already made sure the `workspace.customer` exists in "WorkspaceSubscribeViewGuard"
  const customerId = workspace.customer!.identifier

  const [state, triggers] = useWorkspaceSubscribeState()
  const { status: stripeStatus, load, stripe, elements } = useStripe()

  const { data: customerData } = useGetCustomerQuery({
    variables: { customerId: workspace.customer?.identifier ?? '' },
    skip: !workspace.customer?.identifier,
  })
  const onCompleteSubscription = () => {
    history.push(routes.ENTRY.create({}))

    showModal(
      SubscriptionDoneModal,
      {
        onHide: hideModal,
      },
      {
        closeOnRouteChange: false,
      }
    )
  }

  const pendingSCAToken = customerData?.customer?.subscription?.pendingScaToken

  const [subscribe] = useSubscribeToPlan(
    workspaceId,
    stripe,
    elements,
    pendingSCAToken,
    {
      onError: error => {
        if (isApolloError(error)) {
          const nonFieldErrors = error.graphQLErrors.filter(
            error => !error.extensions?.stripeErrorCode
          )

          if (nonFieldErrors.length > 0) {
            /* Show a toast warning when there are generic fields that need reporting */
            const nonFieldErrorMessage = nonFieldErrors
              .map(error => error.message)
              .join('; ')

            showToast(nonFieldErrorMessage, 'negative')
          } else {
            /* Show a toast warning when there errors in fields reported by the BE */
            showToast(
              'There were some issues when submitting the form, please review the fields',
              'negative'
            )
          }
        } else {
          showToast(error.message, 'negative')
        }
      },
      onComplete: onCompleteSubscription,
    }
  )

  const { loading, error, data } = useWorkspaceSubscribeInformation(
    workspaceId,
    customerId
  )

  const isActive =
    subscriptionStatus && isWorkspaceSubscriptionActive(subscriptionStatus)
  const paymentDetails = data?.customer.paymentDetails
  const isActiveAndSubscribed =
    subscriptionStatus && isActive && paymentDetails?.type !== 'NONE'

  const currentPlan = customerData?.customer?.subscription?.currentPlan
  useEffect(() => {
    let defaultPlan = data?.availablePlans?.find(
      ({ type }) => type === 'YEARLY'
    )
    if (!isActive && paymentDetails?.type !== 'NONE') {
      defaultPlan =
        data?.availablePlans?.find(({ id }) => id === currentPlan?.id) ||
        defaultPlan
    }

    if (onlyBusinessPlansAllowed) {
      defaultPlan =
        data?.availablePlans
          ?.filter(({ type }) => type === 'YEARLY')
          .find(({ product }) => product === 'BUSINESS') || defaultPlan
    }

    !state.dirty && defaultPlan && triggers.addSelectedPlan(defaultPlan)
  }, [
    data,
    isActive,
    onlyBusinessPlansAllowed,
    paymentDetails,
    currentPlan,
    triggers,
    state.dirty,
  ])

  useEffect(() => {
    if (!loading && !state.dirty && isActiveAndSubscribed) {
      showToast('Your workspace is already subscribed', 'negative')
    }
  }, [isActiveAndSubscribed, loading, showToast, state.dirty])

  // Customer
  const customer = data?.workspace?.customer

  useEffect(() => {
    const numberOfScheduledSeats = customer?.billing?.seats.scheduledSeatsTotal
    const numberOfCurrentSeats = customer?.billing?.seats.currentSeatsTotal
    const numberOfEditors = numberOfScheduledSeats || numberOfCurrentSeats

    if (!state.editors && numberOfEditors) {
      triggers.setEditors(numberOfEditors)
    }
  }, [data, triggers, state.editors, customer])

  const currentStep = location.state?.step || 'PLAN_SELECT'

  /**
   * Reset the user url state if there is no saved state but the "currentStep"
   * is not the "PLAN_SELECT" one.
   *
   * This should be the first check to be done, because the state may become dirty
   * by the automatic select from the yearly plan.
   */
  if (currentStep !== 'PLAN_SELECT' && !state.dirty) {
    return (
      <Redirect
        to={routes.WORKSPACE_SUBSCRIBE.create({
          workspaceId,
        })}
      />
    )
  }

  if (loading) {
    return <DynamicLoadingPage />
  }

  if (error || !data?.customer || !data?.workspace || !data?.availablePlans) {
    return <IndexLayoutErrorOld />
  }

  // /**
  //  * We redirect the users back to the settings when they are already
  //  * Subscribed
  //  */
  if (!loading && !state.dirty && isActiveAndSubscribed) {
    return (
      <Redirect
        to={routes.WORKSPACE_SETTINGS_BILLING.create({ workspaceId })}
      />
    )
  }

  const currentCrumb = SUBSCRIBE_CRUMBS.findIndex(
    ({ step }) => step === currentStep
  )

  const { availablePlans: plans } = data

  const numberOfExtraSeats = customer?.billing?.seats.availableSeats || 0

  const prefillBillingInfo = () => {
    if (subscriptionStatus && isWorkspaceSubscriptionActive(subscriptionStatus))
      return undefined

    return {
      billingInfo: {
        address: customerData?.customer?.billingDetails?.address?.line1,
        city: customerData?.customer?.billingDetails?.address?.city,
        postalCode: customerData?.customer?.billingDetails?.address?.postalCode,
        state: customerData?.customer?.billingDetails?.address?.state || '',
        country: customerData?.customer?.billingDetails?.address?.country,
        email: customerData?.customer?.billingDetails?.email,
        name: customerData?.customer?.billingDetails?.name,
        taxId: customerData?.customer?.billingDetails?.taxId?.id || undefined,
        tos: false,
      },
    }
  }
  return (
    <WorkspaceSubscribeLayout headerLink="workspace-settings">
      <WorkspaceLogoContainer>
        <StyledLogo
          workspaceName={workspace.name}
          src={workspace.avatar}
          size="24px"
        />
        <WorkspaceName>{workspace.name}</WorkspaceName>
      </WorkspaceLogoContainer>

      <BreadcrumbsWrapper>
        <StyledOrderedBreadcrumbs
          showAllInMobile
          crumbs={[...SUBSCRIBE_CRUMBS_FOR_BILLING_SIMULATION_FF(workspaceId)]}
          currentCrumb={currentCrumb}
        />
      </BreadcrumbsWrapper>
      {isPlanSelectorStep(currentStep, state) && state.plan && customer && (
        <WorkspaceSubscribePlan
          customer={customer}
          plans={plans}
          selectedPlan={state.plan}
          onlyBusinessPlansAllowed={onlyBusinessPlansAllowed}
          onPlanChange={triggers.addSelectedPlan}
          onPaymentDetails={async () => {
            if (stripeStatus !== 'success') {
              await load()
            }

            const newLocationState = {
              ...location.state,
              step: 'PAYMENT_DETAILS',
            }
            history.push({ ...location, state: newLocationState })
          }}
          discountCode={state.discountCode}
          onValidDiscountCode={triggers.applyDiscountCode}
          onClearDiscountCode={triggers.clearDiscountCode}
          discountError={state.discountError}
          onUpdateDiscountError={triggers.updateDiscountError}
          numberEditors={state.editors || 1}
          onEditorsChange={triggers.setEditors}
        />
      )}
      {isBillingFormsStep(currentStep, state) &&
        customer &&
        state.editors !== undefined && (
          <WorkspaceSubscribePaymentDetails
            title=""
            stripe={stripe}
            {...prefillBillingInfo()}
            billSimulation={{
              plan: state.plan,
              trialEnd: customer.billing!.trialEnd!,
              numberEditors: state.editors,
              numberExtraSeats: numberOfExtraSeats,
              discountCode: state.discountCode,
            }}
            onSubmit={async ({ tos, ...billing }) => {
              triggers.setBillingInformation(billing)

              const currentState = {
                ...state,
                billing,
              }

              const scaError = await subscribe(
                customer.identifier,
                currentState,
                tos
              )

              if (
                !scaError ||
                (scaError && !scaError.setup_intent?.client_secret)
              ) {
                onCompleteSubscription()
              }
            }}
          />
        )}
    </WorkspaceSubscribeLayout>
  )
}

const WorkspaceSubscribeViewGuard: React.FC<WorkspaceSubscribeViewProps> = props => {
  const { workspace } = props
  const { showToast } = useToast()
  const customerId = workspace.customer?.identifier
  const { canSubscribeStripe, customerProvider } = useCanSubscribeViaStripe(
    workspace.identifier,
    customerId
  )
  const { data } = useGetPaymentDetailsQuery({
    variables: { customerId: customerId! },
    skip: !customerId,
  })
  useEffect(() => {
    if (!workspace.customer) {
      showToast(
        'You need to upgrade to a Workspace before you can subscribe',
        'negative'
      )
    }
  }, [showToast, workspace.customer])

  /**
   * In the case this workspace is an App Store subscription, we shouldn't
   * allow it to subscribe via Stripe. This is kind of a corner case because
   * it would only be possible to reach this page if the user
   * is trying to subscribe to a plan by typing the url manually.
   *
   */
  if (!canSubscribeStripe) {
    return (
      <Redirect
        to={routes.WORKSPACE_SHARES.create({
          workspaceId: workspace.identifier,
        })}
      />
    )
  }

  if (data?.customer?.paymentDetails?.type === 'INVOICE') {
    return (
      <Redirect
        to={routes.WORKSPACE_SHARES.create({
          workspaceId: workspace.identifier,
        })}
      />
    )
  }

  /**
   * In the case this workspace is a "Personal Workspace", we shouldn't
   * allow it to subscribe to any plan. It should firstly:
   *
   * - Be migrated to a Workspace
   * - Subscribe to a plan
   */
  if (!workspace.customer) {
    return (
      <Redirect
        to={routes.WORKSPACE_SHARES.create({
          workspaceId: workspace.identifier,
        })}
      />
    )
  }

  return (
    <WorkspaceSubscribeView
      {...props}
      onlyBusinessPlansAllowed={workspace.customer.ssoEnabled}
      subscriptionStatus={customerProvider?.customer.subscriptionInfo.status}
    />
  )
}

export default WorkspaceSubscribeViewGuard
