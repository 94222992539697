/**
 * Remark (Markdown) is importing packages which are using node built-ins.
 * This is a workaround to make it work in the browser.
 */
// see https://github.com/vitejs/vite/discussions/4879
// eslint-disable-next-line no-extra-semi
;(window as any).process = {
  ...(window as any).process,
  cwd: () => '/',
}

export {}
