import React from 'react'

import { Tooltip, Dropdown, VisuallyHidden } from '@sketch/components'
import { RoleDropdown } from '../../../RoleDropdown'

import { Member } from '../../../../types'

import {
  Wrapper,
  StyledMemberCard,
  EllipsisIcon,
  TableButton,
  RemoveButton,
} from './Item.styles'

export interface ItemProps {
  className?: string
  member: Member
  onEditor?: (member: Member) => void
  onViewer?: (member: Member) => void
  onRemove?: (member: Member) => void
  onToggleAdmin?: (member: Member) => void
  isDisabled?: boolean
  hasSingleEditor?: boolean
  isEditorDisabled?: boolean
  isIOS?: boolean
}

/**
 * Item
 *
 * Renders a single item for the MembersList parent component
 *
 * @example
 * <Item
 *  member={...}
 *  onEditor={() => {...}}
 *  onViewer={() => {...}}
 *  onRemove={(member) => {...}}
 * />
 */
export const Item: React.FC<ItemProps> = ({
  className,
  member,
  onRemove,
  onEditor,
  onViewer,
  onToggleAdmin,
  isDisabled,
  hasSingleEditor,
  isEditorDisabled,
  isIOS,
}) => {
  const isRemovable = !!onRemove && !member.isOwner
  const showSingleEditorMessage = hasSingleEditor && member.isEditor

  return (
    <Wrapper className={className} data-testid="member-list-item">
      <StyledMemberCard
        name={member.name}
        avatarSrc={member.avatar}
        email={member.email}
      />
      {isIOS ? (
        <Dropdown
          placement="bottom-end"
          usePortal
          toggle={
            <TableButton data-testid="edit-member-access">
              <VisuallyHidden>Edit</VisuallyHidden>
              <EllipsisIcon />
            </TableButton>
          }
        >
          <RemoveButton onClick={() => onRemove?.(member)}>
            Remove from Invites
          </RemoveButton>
        </Dropdown>
      ) : (
        <Tooltip
          placement="bottom-end"
          content="You need to have at least one Editor on your workspace"
          disabled={!showSingleEditorMessage}
        >
          <RoleDropdown
            onEditor={() => onEditor?.(member)}
            onViewer={() => onViewer?.(member)}
            onRemove={() => onRemove?.(member)}
            onToggleAdmin={() => onToggleAdmin?.(member)}
            isEditor={member.isEditor}
            isAdmin={member.isAdmin}
            isOwner={member.isOwner}
            isDisabled={isDisabled || showSingleEditorMessage}
            isRemovable={isRemovable}
            showAdminToggle={!member.isOwner}
            showBadges
            isEditorDisabled={isEditorDisabled}
          />
        </Tooltip>
      )}
    </Wrapper>
  )
}
