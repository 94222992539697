import styled, { css, keyframes } from 'styled-components'

import {
  Container as ContainerUnstyled,
  IconContainer,
  PencilIcon,
  CrossIcon,
} from './Common.styles'
import { breakpoint } from '@sketch/global-styles'
import { ButtonUnstyled, Link as LinkUnstyled } from '@sketch/components'

interface ContainerProps {
  editing: boolean
  empty: boolean
}

// Menu animations
const growDown = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`

const decreaseUp = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
`

export const Container = styled(ContainerUnstyled)<ContainerProps>(
  ({ theme, empty, editing }) => css`
    ${empty
      ? css`
          & :hover {
            /*
          Only show the Pencil icon when hovering, the input empty and not
          focused
          */
            ${PencilIcon} {
              opacity: 1;
              z-index: 1;
            }
          }
        `
      : css`
          & :hover {
            /*
          Only show the Cross icon when hovering, the input not empty
          */
            ${CrossIcon} {
              opacity: 1;
              z-index: 1;
            }
          }
        `}

    ${editing &&
    css`
      border-radius: 5px;
      border: 2px solid ${theme.colors.background.tertiary.A};

      ${IconContainer} {
        bottom: 8px;
        right: 0px;
      }

      ${CrossIcon} {
        opacity: 1;
        z-index: 1;
      }
    `}
  `
)

export const FakeInput = styled(ButtonUnstyled)<{ empty: boolean }>(
  ({ theme, empty }) => css`
    width: 100%;
    outline: 0;
    text-align: center;
    padding: 12px 40px 8px 40px;

    /* Avoid bouncing when focused */
    border: 2px solid transparent;
    border-bottom: 2px dotted ${theme.colors.state.disabled};

    color: ${theme.colors.state.disabled};

    /* force white background and dark text */
    background-color: transparent;

    :focus {
      border: 2px solid ${theme.colors.sketch.A};
      border-radius: 5px;
    }

    ${empty
      ? css`
          /* When hovering the icon container, make sure the Pencil icon doesn't show */
          ~ ${IconContainer}:hover {
            ${PencilIcon} {
              opacity: 0;
              z-index: -1;
            }
          }

          span {
            color: ${theme.colors.foreground.secondary.C};
          }

          color: ${theme.colors.foreground.secondary.A};
        `
      : css`
          /* When hovering the icon container, make sure the Cross icon doesn't show */
          ~ ${IconContainer}:hover {
            ${CrossIcon} {
              opacity: 0;
              z-index: -1;
            }
          }
        `};
  `
)

export const Menu = styled.div<{ editing: boolean }>(
  ({ theme, editing }) => css`
    width: calc(100% - 5px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: hidden;

    color: ${theme.colors.foreground.secondary.D};
    background: ${theme.colors.background.secondary.A};
    border-radius: 12px;

    position: absolute;
    top: 49px;
    left: 2px;

    ${editing
      ? css`
          border: 1px solid ${theme.colors.border.A};
          box-shadow: 0 4px 8px 0 ${theme.colors.shadow.outer};

          visibility: visible;
          transform-origin: top center;
          animation: ${growDown} 300ms ${theme.transitions.timing.spring};
        `
      : css`
          transform-origin: top center;
          animation: ${decreaseUp} 300ms ${theme.transitions.timing.easeOut};
          visibility: hidden;
          transition: visibility 300ms ${theme.transitions.timing.easeOut};
        `};
  `
)

export const Title = styled.h1(
  ({ theme }) => css`
    margin: 0 0 16px;
    font-size: ${theme.fontSizes.E};

    ${breakpoint('xs')`
  font-size: ${theme.fontSizes.B};
`}
  `
)

export const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.C};
`

export const Link = styled(LinkUnstyled)`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

export const InputContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid ${theme.colors.border.B};
    border-radius: 5px;
    padding: 0 12px;
    margin-top: 16px;
    font-size: ${theme.fontSizes.E};

    &:focus-within {
      border-color: transparent;
      /* Native focus ring */
      outline: 5px auto Highlight;
      outline: 5px auto -webkit-focus-ring-color;
    }
  `
)

export const InputPlaceholder = styled.span`
  color: ${({ theme }) => theme.colors.background.tertiary.A};
`

export const Input = styled.input`
  width: 100%;
  outline: 0;
  text-align: left;
  border: 0;
  padding: 0;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`
