import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "image-placeholder-64", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 64 64", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("path", { d: "M17.192 14.5h29.616c2.675 0 3.645.278 4.623.801a5.452 5.452 0 012.268 2.268c.523.978.801 1.948.801 4.623v19.616c0 2.675-.278 3.645-.801 4.623a5.452 5.452 0 01-2.268 2.268c-.978.523-1.948.801-4.623.801H17.192c-2.675 0-3.645-.278-4.623-.801a5.452 5.452 0 01-2.268-2.268c-.523-.978-.801-1.948-.801-4.623V22.192c0-2.675.278-3.645.801-4.623a5.452 5.452 0 012.268-2.268c.978-.523 1.948-.801 4.623-.801z", id: "image-placeholder-64_svg__a" })),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M54.5 32h0v9.808c0 2.675-.278 3.645-.801 4.623a5.452 5.452 0 01-2.268 2.268c-.978.523-1.948.801-4.623.801H17.192c-2.675 0-3.645-.278-4.623-.801a5.452 5.452 0 01-2.268-2.268c-.523-.978-.801-1.948-.801-4.623V32h0", stroke: "currentCOlor", strokeWidth: 3, strokeLinejoin: "round" }),
        React.createElement("path", { d: "M54.5 32h0v-9.808c0-2.675-.278-3.645-.801-4.623a5.452 5.452 0 00-2.268-2.268c-.978-.523-1.948-.801-4.623-.801H17.192c-2.675 0-3.645.278-4.623.801a5.452 5.452 0 00-2.268 2.268c-.523.978-.801 1.948-.801 4.623V32h0", stroke: "currentCOlor", strokeWidth: 3, strokeDasharray: 4 }),
        React.createElement("mask", { id: "image-placeholder-64_svg__b", fill: "#fff" },
            React.createElement("use", { xlinkHref: "#image-placeholder-64_svg__a" })),
        React.createElement("path", { d: "M27.822 32.225L38.429 43.34l2.883-2.998c1.958-2.036 5.045-1.964 6.906.174l9.496 10.907c0 1.803-3.857 2.614-3.857 2.614H28.143l2.553-2.656-20.553-.055S5 51.551 5 50.129l15.882-17.796c1.88-2.106 4.984-2.157 6.94-.108z", stroke: "currentCOlor", strokeWidth: 3, fillOpacity: 0.15, fill: "currentCOlor", strokeLinecap: "round", strokeLinejoin: "round", mask: "url(#image-placeholder-64_svg__b)" }),
        React.createElement("ellipse", { fill: "currentCOlor", mask: "url(#image-placeholder-64_svg__b)", cx: 43, cy: 26.041, rx: 5, ry: 5.041 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
