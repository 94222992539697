import React, { useRef, useState } from 'react'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { dataIdFromObject } from '@sketch/graphql-cache'
import {
  Text,
  Box,
  Flex,
  Input,
  handleFetchMore,
  ModalInjectedProps,
} from '@sketch/components'
import {
  Separator,
  DescriptionTextArea,
  ScrollingContainer,
  PaddedContainer,
  Field,
  SearchEmptyIcon,
} from './FormEditCreate.styles'
import { EditImage } from './EditImage'
import SelectableDocumentList from '../SelectableDocumentList'
import { FormProps, DesignSystem } from '../../types'
import { useGetEligibleSharesQuery } from '@sketch/gql-types'
import { useCalculateScrollableAreaHeight } from './useCalculateScrollableAreaHeight'
import { ReactComponent as WarningTriangle } from '@sketch/icons/warning-triangle-64'

interface FormEditCreateProps extends ModalInjectedProps {
  designSystemToEdit?: DesignSystem
  onImageChange: (file: File) => void
  formikbag: FormikProps<FormProps>
  submitting: boolean
  workspaceId: string
  logo?: string | null
}

// Form Validation
export const validationSchema = Yup.object().shape({
  name: Yup.string().required(
    `Give your System a name. Don't worry, you can change it later.`
  ),
  libraries: Yup.array().required(
    `Please select some libraries to add to your System.`
  ),
})

const ENTRIES_PATH = ['designSystemEligibleShares', 'entries']

const NoResultsState = () => (
  <Flex
    flexDirection="column"
    justifyContent="center"
    alignSelf="center"
    height="15vh"
    width="208px"
    pb={7}
  >
    <SearchEmptyIcon />
    <Text
      color="foreground.secondary.A"
      fontSize="E"
      textAlign="center"
      mt={7}
      p={0}
      // necessary to override the padding-bottom 20 and margin 0 from Text
      style={{ padding: 0, marginTop: 20 }}
    >
      No search results
    </Text>
    <Text color="foreground.secondary.D" fontSize="D" textAlign="center" p={0}>
      No matching libraries found. Try again with another term.
    </Text>
  </Flex>
)

const ErrorState = () => (
  <Flex
    flexDirection="column"
    justifyContent="center"
    alignSelf="center"
    height="15vh"
    width="208px"
    pb={7}
  >
    <WarningTriangle />
    <Text
      color="foreground.secondary.A"
      fontSize="E"
      textAlign="center"
      mt={7}
      p={0}
      // necessary to override the padding-bottom 20 and margin 0 from Text
      style={{ padding: 0, marginTop: 20 }}
    >
      An error occurred
    </Text>
    <Text color="foreground.secondary.D" fontSize="D" textAlign="center" p={0}>
      An error occurred while getting the libraries, please try again.
    </Text>
  </Flex>
)

export const FormEditCreate = (props: FormEditCreateProps) => {
  const { onImageChange, formikbag, submitting, workspaceId, logo } = props
  const [search, setSearch] = useState('')
  const fixedHeightRef = useRef<HTMLDivElement>(null)
  const listHeight = useRef<HTMLDivElement>(null)

  const { data, loading, error, fetchMore } = useGetEligibleSharesQuery({
    variables: {
      identifier: workspaceId ?? '',
      search,
      limit: 20,
    },
    skip: !workspaceId,
  })

  const libraries = data?.designSystemEligibleShares?.entries || []
  const after = data?.designSystemEligibleShares?.meta.after
  const placeholderCount = data?.designSystemEligibleShares.meta.totalCount
  const handleLoadMore = handleFetchMore(fetchMore, ENTRIES_PATH, {
    dataIdFromObject,
    after,
  })

  const scrollablePanelHeight = useCalculateScrollableAreaHeight({
    fixedHeightRef,
    hasLibraries: !!libraries,
    listHeight,
  })

  // Normalize error
  let searchError: string | undefined
  if (
    formikbag.touched.libraries &&
    Array.isArray(formikbag.errors.libraries)
  ) {
    searchError = formikbag.errors.libraries?.join('; ')
  } else if (formikbag.touched.libraries) {
    searchError = formikbag.errors.libraries as string
  }

  const noResults = !libraries.length ? <NoResultsState /> : null
  const errorUI = error ? <ErrorState /> : null

  return (
    <>
      <PaddedContainer ref={fixedHeightRef}>
        <Flex mb={5}>
          <Box mr={4}>
            <EditImage onChange={onImageChange} logo={logo} />
          </Box>
          <Box width="100%">
            <label htmlFor="name">Name</label>
            <Box mt={1}>
              <Field
                name="text"
                errorText={
                  formikbag.touched.name ? formikbag.errors.name : undefined
                }
              >
                <Input
                  name="name"
                  type="text"
                  value={formikbag.values.name}
                  placeholder="Give your design system a name"
                  disabled={submitting}
                  onChange={formikbag.handleChange}
                />
              </Field>
              <Text textStyle="copy.quaternary.standard.C">
                PNG or JPG up to 2 MB
              </Text>
            </Box>
          </Box>
        </Flex>
      </PaddedContainer>
      <ScrollingContainer ref={listHeight} height={scrollablePanelHeight}>
        <label htmlFor="description">Description (Optional)</label>
        <Box mt={1}>
          <DescriptionTextArea
            name="description"
            value={formikbag.values.description}
            onChange={formikbag.handleChange}
            placeholder="This design system is for…"
            rows={10}
            disabled={submitting}
          />
        </Box>
        <Separator />
        <SelectableDocumentList
          name="libraries"
          items={libraries}
          selectedItems={formikbag.values.libraries}
          onItemChange={formikbag.handleChange}
          fetchMore={handleLoadMore}
          resetSelectedShares={() => formikbag.setFieldValue('libraries', [])}
          search={search}
          searchError={searchError}
          onSearch={setSearch}
          searchPlaceholder="Search Libraries"
          loading={loading}
          noResultsState={noResults}
          errorState={errorUI}
          placeholderCount={placeholderCount}
        />
      </ScrollingContainer>
    </>
  )
}
