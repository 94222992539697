import { breakpoint } from '@sketch/global-styles'
import styled from 'styled-components'

export const Wrapper = styled.ul`
  /*
    We are using a "sr-only" (an element only visible for screen readers) in the Publication placeholder
    for this component which has a "position: absolute;"

    This was causing a double-scroll in the Community page.
    Adding this position: relative; fixes the UI double-scroll issue
  */
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(1, 1fr);
  align-items: flex-start;

  ${breakpoint('xs')`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${breakpoint('sm')`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${breakpoint('md')`
    grid-template-columns: repeat(4, 1fr);
  `}
`
