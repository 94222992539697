import React, { useCallback, useContext, useState } from 'react'

import { Button, Checkbox, Modal, ModalInjectedProps } from '@sketch/components'

import { ToastContext } from '@sketch/toasts'

import {
  GetUserSettingsDocument,
  useRevokeActiveSessionsMutation,
} from '@sketch/gql-types'

import { Title, Description } from './ConfirmRevokeAllSessionsModal.styles'
import { useSignOut } from '@sketch/modules-common'

const ConfirmRevokeAllSessionsModal: React.FC<ModalInjectedProps> = ({
  hideModal,
}) => {
  const [revokeCurrentSessionAlso, setRevokeCurrentSessionAlso] = useState(
    false
  )
  const signOut = useSignOut({
    revokeSessions: 'none',
    removeDataFromSessions: revokeCurrentSessionAlso ? 'all' : 'none',
    reason: 'Revoking session in Sessions Management',
  })
  const [revokeSessions, { loading }] = useRevokeActiveSessionsMutation({
    redirectErrors: false,
    onCompleted: result => {
      if (result.revokeActiveSessions?.successful) {
        showToast('All devices successfully signed out ', 'positive')
        hideModal()

        if (revokeCurrentSessionAlso) {
          signOut()
        }
      }
    },
    refetchQueries: revokeCurrentSessionAlso
      ? []
      : [{ query: GetUserSettingsDocument }],
    awaitRefetchQueries: !revokeCurrentSessionAlso,
    onError: 'show-toast',
  })
  const { showToast } = useContext(ToastContext)

  const handleRevokeSessions = useCallback(() => {
    revokeSessions({
      variables: {
        exceptCurrentOne: !revokeCurrentSessionAlso,
      },
    })
  }, [revokeCurrentSessionAlso, revokeSessions])

  return (
    <Modal onCancel={hideModal}>
      <Modal.Body>
        <Title>Sign Out of All Devices?</Title>
        <Description>
          You&apos;ll be signed out of all active sessions. If you didn&apos;t
          sign in with one or more devices, you should change your password.
        </Description>
        <Checkbox
          name="accessScopes"
          checked={revokeCurrentSessionAlso}
          onChange={() => setRevokeCurrentSessionAlso(state => !state)}
        >
          Sign out of the current session too
        </Checkbox>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={hideModal}
          size="40"
          variant="secondary"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleRevokeSessions}
          size="40"
          variant="primary"
          loading={loading}
        >
          Sign Out
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmRevokeAllSessionsModal
