import React, { useRef, useState, useEffect } from 'react'

import {
  Wrapper,
  HiddenInput,
  Digits,
  Digit,
  Error,
} from './VerificationCodeInput.styles'

export interface VerificationCodeInputProps {
  className?: string
  /**  The number of digits to render. default: 6 */
  size?: number
  /**  Will focus on the input when it mounts. default: true*/
  autoFocus?: boolean
  /**  Renders the digits in a disabled state */
  isDisabled?: boolean
  /**  An error message */
  error?: string
  /**  Event fired when user fills the last digit */
  onFilled: (value: string) => void
}

/**
 * VerificationCode
 *
 * Renders the 6-digit 2FA verification code inputs
 * By default it renders 6 digit inputs
 */
export const VerificationCodeInput: React.FC<VerificationCodeInputProps> = ({
  autoFocus = true,
  size = 6,
  isDisabled,
  error,
  onFilled,
  className,
}) => {
  const hiddenInput = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState('')
  const [isFocused, setIsFocused] = useState(autoFocus)
  const [localError, setLocalError] = useState<string | undefined>(error)

  /* Autofocus hidden input to allow the user to start typing right away */
  useEffect(() => {
    if (hiddenInput.current && autoFocus) {
      hiddenInput.current.focus()
      hiddenInput.current.scrollIntoView()
    }
  }, [autoFocus])

  /* Set local error with "error" prop if it changes */
  useEffect(() => {
    if (error) {
      setLocalError(error)
    }

    // Clear the input value to allow the user to quickly type a new one again
    const timeout = setTimeout(() => setValue(''), 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [error])

  /* Trigger onFilled when user fills the last digit */
  useEffect(() => {
    if (value.length === size) {
      onFilled?.(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size, value])

  // Always send the cursor to the last character when focusing the input
  useEffect(() => {
    if (isFocused) {
      const timeout = setTimeout(() => {
        hiddenInput.current?.setSelectionRange(value.length, value.length)
      }, 1)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isFocused, value.length])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Filter string and get only digits, slice for the first 6 characters
    const onlyDigits = event.target.value.replace(/\D/g, '').slice(0, size)
    setValue(onlyDigits)

    // Clear error
    if (value.length < size) {
      setLocalError(undefined)
    }
  }

  /* Focus on the hidden input when the user clicks the digits*/
  const handleClick = () => {
    if (hiddenInput.current) {
      hiddenInput.current.focus()

      // Scroll to the input to make sure it is visible
      // Avoids the keyboard from covering the input in a mobile device
      window.requestAnimationFrame(() => {
        hiddenInput.current!.scrollIntoView()
      })
    }
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <Wrapper className={className}>
      <HiddenInput
        data-testid="verification-code"
        type="text"
        aria-label="Verification Code"
        inputMode="numeric"
        autoComplete="one-time-code"
        pattern="\d{6}"
        ref={hiddenInput}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={isDisabled}
        value={value}
      />
      <Digits onClick={handleClick}>
        {[...Array(size)].map((_, index) => {
          // Keep the next empty digit in active state
          // If its last digit, keep it active even if filled
          const isActive =
            index + 1 === size && value.length === size
              ? true
              : value.length === index

          return (
            <Digit
              data-testid="verification-code-digit"
              key={index}
              onClick={handleClick}
              $isActive={isFocused && isActive}
              $isDisabled={isDisabled}
              $hasError={!!localError && !!value.length}
            >
              {value[index]}
            </Digit>
          )
        })}
      </Digits>
      {!!localError && <Error>{localError}</Error>}
    </Wrapper>
  )
}
