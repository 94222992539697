import React from 'react'
import styled from 'styled-components'
import { ButtonUnstyled, useResponsiveDropdown } from '@sketch/components'
import NavigationItem from '../NavigationItem'
import { ChevronsIcon } from '../NavigationItem.styles'
import { CWVNavDropdown } from './CWVNavDropdown'

interface CWVGroupNavigationItemProps {
  shareIdentifier: string
  groupId: string
  symbolName: string
}

const TriggerButton = styled(ButtonUnstyled)`
  min-width: 0;
  max-width: 100%;
`

const CWVGroupNavigationItem: React.FC<CWVGroupNavigationItemProps> = ({
  shareIdentifier,
  groupId,
  symbolName,
}) => {
  // get the groups from the location state
  const groups = groupId.split('/').filter(n => n !== ' ') ?? []
  const groupsLabel = groups.join(' / ') + ' / ' + symbolName

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: CWVNavDropdown,
    dropdownProps: {
      groups,
      shareIdentifier,
    },
  })

  const hasGroups = groups.length > 0

  if (!hasGroups) {
    return <NavigationItem icon="components" label={groupsLabel} />
  }

  return (
    <>
      <TriggerButton data-testid="group-selector" {...buttonProps}>
        <NavigationItem icon="components" label={groupsLabel} />
        <ChevronsIcon />
      </TriggerButton>
      {content}
    </>
  )
}

export default CWVGroupNavigationItem
