import { ApolloError } from 'apollo-client'
import { useToast } from '@sketch/toasts'
import { UserErrorFragment } from './UserErrorFragment.type'
import { ErrorHandler } from '@sketch/tracing'
import { useRef, useMemo, useCallback } from 'react'
import { ApolloParsedError, ParsedError, UserParsedError } from './ParsedError'
import { OnErrorUnsafe } from './useMutation.types'

export type ApolloOnErrorFn = (error: ApolloError) => void
export type UserOnErrorFn = (error: UserErrorFragment[]) => void
export interface UseParseErrorHandlerProps {
  onError: OnErrorUnsafe
}

type ErrorListener = (err: ParsedError) => void
type ErrorListenerRegister = (fn: ErrorListener) => void

export interface UseParseErrorHandlerResult {
  onApolloErrorHandler: ApolloOnErrorFn | undefined
  onUserErrorHandler: UserOnErrorFn | undefined
  onErrorDispatch: ErrorListenerRegister
}

export const useParseErrorHandler = (
  props: UseParseErrorHandlerProps
): UseParseErrorHandlerResult => {
  const { showToast } = useToast()
  const errorListenersRef = useRef<Array<(err: ParsedError) => void>>([])
  const onErrorRef = useRef(props.onError)
  onErrorRef.current = props.onError

  const onErrorDispatch: ErrorListenerRegister = useCallback(fn => {
    errorListenersRef.current.push(fn)
  }, [])

  const parsedErrorHandlers = useMemo(() => {
    const handleError = (error: ParsedError) => {
      errorListenersRef.current.forEach(x => x(error))

      /**
       * We need to check connectivity errors by the message because Apollo
       * Client considers many backend errors as network errors.
       */
      if (error.networkErrorMessage === 'Failed to fetch') {
        showToast(
          'Something went wrong. Check your connection and try again',
          'negative'
        )
        return
      }

      if (onErrorRef.current === 'do-nothing') {
        return
      }

      if (onErrorRef.current === 'unsafe-throw-exception') {
        // This should never happen as later on we don't even return this handler
        // if `onError` property is set to 'unsafe-throw-exception'
        ErrorHandler.shouldNeverHappen(
          'bug in the code - onErrorRef.current should never be equal to "unsafe-throw-exception"'
        )
        return
      }

      if (onErrorRef.current === 'show-toast') {
        showToast(error.message, 'negative')
        return
      }

      onErrorRef.current?.(error)
    }

    const onApolloErrorHandler: ApolloOnErrorFn = error => {
      handleError(new ApolloParsedError(error))
    }

    const onUserErrorHandler: UserOnErrorFn = error => {
      handleError(new UserParsedError(error))
    }

    return { onApolloErrorHandler, onUserErrorHandler }
  }, [showToast])

  /**
   * Apollo mutations work in (at least) 2 different modes:
   *  - when (1) onError callback is provided and
   *         (2) if error will occur
   *    calling mutationFn:
   *       - onError callback will be called
   *       - code after mutationFn will be executed normally
   *       - `await mutationFn()` will return execution result (not undefined object)
   *
   * However,
   *  - when (1) onError callback is NOT provided and
   *         (2) if error will occur
   *    calling mutationFn:
   *       - `await mutationFn()` will throw an exception
   *       - `await mutationFn()` will return `undefined`, which shouldn't be even possible
   *         following TypeScript definitions
   *
   * These subtle differences have proven to be error prone. So right now we are forcing us
   * to state explicitly that we want to throw an exception.
   * And in the future we want to get rid of this behavior entirely.
   */
  if (onErrorRef.current === 'unsafe-throw-exception') {
    return {
      onApolloErrorHandler: undefined,
      onUserErrorHandler: undefined,
      onErrorDispatch,
    }
  }

  return { ...parsedErrorHandlers, onErrorDispatch }
}
