import { emojiAliases } from './emojiAliases'

const aliasesRegex = /:([\w\-_+]+):/g
type Emoji = typeof emojiAliases

const doesEmojiExists = (emojies: Emoji, key: string): key is keyof Emoji =>
  key in emojies

export function emojify(text: string) {
  return text.replace(aliasesRegex, (str, val) => {
    if (doesEmojiExists(emojiAliases, val)) {
      return emojiAliases[val]
    }

    return str
  })
}
