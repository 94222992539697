/* eslint-disable react/display-name */
import React from 'react'

const TOAST_MESSAGES = {
  PROJECT_ACCESS_REVOKED: (projectName: string) => (
    <>
      You no longer have access to the “<b>{projectName}</b>” project
    </>
  ),
}

export const getToast = (
  toastName: keyof typeof TOAST_MESSAGES,
  options: Parameters<typeof TOAST_MESSAGES[typeof toastName]>[0]
) => {
  return TOAST_MESSAGES[toastName](options)
}
