import React from 'react'

import { useLocation } from 'react-router-dom'
import { Location } from 'history'

import {
  routes,
  IndexLayoutExtraProps,
  IndexLayoutHeaderLink,
  IndexLayoutOldTitle,
  IndexLayoutOldSubtitle,
  SignInForm,
  useSignIn,
} from '@sketch/modules-common'
import { Link } from '@sketch/components'
import { SignInMutation } from '@sketch/gql-types'

import { CampaignSidePanel, TrialMessage } from '../../../components'

import { Line } from './SignInView.styles'
import { Container, LeftColumn, RightColumn } from './CampaignSignInView.styles'

export interface BaseSignInViewProps
  extends Pick<
    IndexLayoutExtraProps,
    'useOverrideLayoutProps' | 'HeaderPortal'
  > {
  email: string | undefined
  onCompleted: (data: SignInMutation) => void
}

const CampaignSignInView: React.FC<BaseSignInViewProps> = ({
  email,
  onCompleted,
  HeaderPortal,
}) => {
  const { state, search } = useLocation<{ from: Location }>()

  const [signIn, { loading, error }] = useSignIn({
    onCompleted,
  })

  return (
    <>
      <HeaderPortal>
        <IndexLayoutHeaderLink />
      </HeaderPortal>
      <Container>
        <LeftColumn>
          <CampaignSidePanel />
        </LeftColumn>

        <RightColumn>
          <IndexLayoutOldTitle>Sign In</IndexLayoutOldTitle>
          <IndexLayoutOldSubtitle>
            Don&apos;t have a Sketch account?{' '}
            <Link
              variant="primary"
              to={{
                pathname: routes.SIGN_UP.create({}),
                state,
                search,
              }}
              isUnderlined
            >
              Sign up
            </Link>
          </IndexLayoutOldSubtitle>
          <Line />
          <br />

          <SignInForm
            email={email}
            submitCopy="Next: Choose a Plan"
            isLoading={loading}
            error={error}
            onSubmit={({ email, password }) =>
              signIn({ variables: { email, password, createWorkspace: true } })
            }
          />

          <TrialMessage />
        </RightColumn>
      </Container>
    </>
  )
}

export default CampaignSignInView
