import styled, { css } from 'styled-components'

import { ReactComponent as PlusIcon } from '@sketch/icons/plus-16'

import { ButtonUnstyled } from '@sketch/components'

export const AddTextWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: 32px;

    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 0 0 12px;

    color: ${theme.colors.foreground.secondary.D};
    font-size: ${theme.fontSizes.B};
    font-weight: ${theme.fontWeights.semibold};

    user-select: none;
  `
)

export const AddButton = styled(ButtonUnstyled)`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  width: 40px;
  height: 32px;
  justify-content: center;

  margin-left: auto;
  padding: 8px;
  position: relative;
  border-radius: 6px;

  transition: color
    ${({ theme }) =>
      `${theme.transitions.duration[0]} ${theme.transitions.timing.easeInOut}`};

  opacity: ${({ disabled }) => (disabled ? '0.55' : '1')};

  :hover {
    background-color: ${({ theme }) => theme.colors.background.tertiary.C};
  }
`

export const StyledAddButton = styled(PlusIcon)`
  width: 16px;
  height: 16px;
`

export const ProjectsHeaderText = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.foreground.secondary.D};
    font-size: ${theme.fontSizes.B};
    font-weight: ${theme.fontWeights.semibold};
    text-transform: uppercase;
    line-height: 0;
  `
)

export const ActionsWrapper = styled.span`
  display: flex;
  align-items: center;
`
