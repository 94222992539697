import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "documents-prism-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "currentColor", fillRule: "evenodd" },
        React.createElement("path", { d: "M12.75.5h-7A1.75 1.75 0 004 2.25V4.5h1.922c.331 0 .649.132.883.366l3.329 3.329c.234.234.366.552.366.883V13h2.25a1.75 1.75 0 001.75-1.75v-9A1.75 1.75 0 0012.75.5zm-7 1.5h7a.25.25 0 01.25.25v9l-.007.057a.25.25 0 01-.243.193H12V9.078c0-.729-.29-1.428-.805-1.944L7.866 3.805l-.145-.135a2.75 2.75 0 00-1.8-.67H5.5v-.75A.25.25 0 015.75 2z", fillRule: "nonzero" }),
        React.createElement("path", { d: "M13.75 11.25v-9a1 1 0 00-1-1h-7a1 1 0 00-1 1v1.5h1.172a2 2 0 011.414.586l3.328 3.328a2 2 0 01.586 1.414v3.172h1.5a1 1 0 001-1z", fillOpacity: 0.15 }),
        React.createElement("path", { d: "M5.75 3h-2.5A1.75 1.75 0 001.5 4.75v9c0 .966.784 1.75 1.75 1.75h7A1.75 1.75 0 0012 13.75v-4.5a1.75 1.75 0 00-1.75-1.75h-2.5a.25.25 0 01-.25-.25v-2.5A1.75 1.75 0 005.75 3zm-2.5 1.5h2.5a.25.25 0 01.25.25v2.5C6 8.216 6.784 9 7.75 9h2.5a.25.25 0 01.25.25v4.5a.25.25 0 01-.25.25h-7a.25.25 0 01-.25-.25v-9a.25.25 0 01.25-.25z", fillRule: "nonzero" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
