import React from 'react'
import { Button, Modal, ModalInjectedProps } from '@sketch/components'
import useDeleteAnnotation from '../../operations/useDeleteAnnotation'
import useDeleteAnnotationReply from '../../operations/useDeleteAnnotationReply'
import qs from 'query-string'
import { useQueryParams } from '@sketch/modules-common'
import { useLocation, useHistory } from 'react-router-dom'
import { useToast } from '@sketch/toasts'

export interface DeleteCommentModalProps extends ModalInjectedProps {
  commentIdentifier: string
  annotationIdentifier: string
  isAnnotation?: boolean
  hasReplies?: boolean
}

const REPLY = 'REPLY'
const THREAD = 'COPY'

const COPY = {
  [REPLY]: {
    title: 'Delete the comment?',
    body: 'You can’t undo this action.',
    confirm: 'Delete',
  },
  [THREAD]: {
    title: 'Delete the comment and replies?',
    body: 'You can’t undo this action.',
    confirm: 'Delete',
  },
}

export const DeleteCommentModal: React.FC<DeleteCommentModalProps> = ({
  hideModal,
  annotationIdentifier,
  commentIdentifier,
  isAnnotation,
  hasReplies,
}) => {
  const { showToast } = useToast()
  const location = useLocation()
  const { replace } = useHistory()
  const { annotation, ...queryParams } = useQueryParams<
    'ARTBOARD_DETAIL_UUID' | 'ARTBOARD_DETAIL'
  >()

  const copyText = isAnnotation && hasReplies ? COPY[THREAD] : COPY[REPLY]

  const [deleteReply, deleteReplyState] = useDeleteAnnotationReply({
    identifier: commentIdentifier,
    onCompleted() {
      hideModal()
      showToast('Comment deleted')
    },
  })

  const [deleteThread, deleteThreadState] = useDeleteAnnotation({
    identifier: annotationIdentifier,
    onCompleted() {
      hideModal()
      if (hasReplies) {
        showToast('Comment and replies deleted')
      }
      if (annotation) {
        replace({ ...location, search: qs.stringify(queryParams) })
      }
    },
  })

  const handleDelete = () => {
    if (isAnnotation) {
      deleteThread()
    } else {
      deleteReply()
    }
  }

  const loading = deleteReplyState.loading || deleteThreadState.loading

  return (
    <Modal title={copyText.title} onCancel={hideModal}>
      <Modal.Body>{copyText.body}</Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} onClick={hideModal}>
          Cancel
        </Button>
        <Button
          variant="negative-secondary"
          loading={loading}
          disabled={loading}
          onClick={handleDelete}
        >
          {copyText.confirm}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
