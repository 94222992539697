import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "person-circle-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "currentColor" },
        React.createElement("path", { d: "M8.245 1c1.55 0 2.708.278 3.686.801a5.452 5.452 0 012.268 2.268c.523.978.801 2.136.801 3.686v.49c0 1.55-.278 2.708-.801 3.686a5.452 5.452 0 01-2.268 2.268c-.978.523-2.136.801-3.686.801h-.49c-1.55 0-2.708-.278-3.686-.801a5.452 5.452 0 01-2.268-2.268C1.278 10.953 1 9.795 1 8.245v-.49c0-1.55.278-2.708.801-3.686A5.452 5.452 0 014.07 1.801C5.047 1.278 6.205 1 7.755 1h.49zm0 1.5h-.49c-1.27 0-2.198.207-2.978.624a3.952 3.952 0 00-1.653 1.653c-.382.715-.588 1.554-.62 2.667l-.004.31v.491c0 1.27.207 2.198.624 2.978.383.717.936 1.27 1.653 1.653.715.382 1.554.588 2.667.62l.31.004h.491c1.27 0 2.198-.207 2.978-.624a3.952 3.952 0 001.653-1.653c.382-.715.588-1.554.62-2.667l.004-.31v-.491c0-1.27-.207-2.198-.624-2.978a3.952 3.952 0 00-1.653-1.653c-.715-.382-1.554-.588-2.667-.62l-.31-.004z" }),
        React.createElement("path", { d: "M8 9.25c2.258 0 4.235 1.057 5.073 2.614-.309.46-.69.85-1.144 1.165C11.587 11.77 9.988 10.75 8 10.75c-1.988 0-3.587 1.018-3.931 2.278a4.395 4.395 0 01-1.142-1.163C3.765 10.307 5.742 9.25 8 9.25zm0-5.5a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm0 1.5a1 1 0 100 2 1 1 0 000-2z" }),
        React.createElement("path", { d: "M8 10c2.144 0 3.956 1.057 4.547 2.51a4.498 4.498 0 01-1.088.807c-.842.45-1.842.683-3.214.683-2.022-.039-2.93-.27-3.704-.683a4.506 4.506 0 01-1.088-.807C4.043 11.057 5.856 10 8 10zm0-5.5A1.75 1.75 0 118 8a1.75 1.75 0 010-3.5z", fillOpacity: 0.15 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
