import React from 'react'
import styled from 'styled-components'
import { Tooltip, Flex } from '@sketch/components'
import { capitalizeFirst } from '@sketch/utils'
import { ReactComponent as ExclamationMark } from '@sketch/icons/exclamation-mark-alt-16'

interface DirtyIconTooltipProps {
  originalProperty: string
  originalValue: string
  preventLabelTooltip: () => void
  isTextStyle?: boolean
}

export const DirtyIconTooltip: React.FC<DirtyIconTooltipProps> = ({
  originalProperty,
  originalValue,
  preventLabelTooltip,
  isTextStyle = false,
}) => {
  return (
    <IconWrapper marginLeft={1} onMouseEnter={preventLabelTooltip}>
      <Tooltip
        content={
          <TooltipContent>
            <span>
              * Out of sync with the original {isTextStyle ? 'Text' : 'Layer'}{' '}
              Style
            </span>
            <br />
            <span>
              Original {originalProperty}{' '}
              {!originalValue.startsWith('has') ? 'is ' : null}
              {originalValue}
            </span>
          </TooltipContent>
        }
        placement="top"
      >
        <ExclamationMarkIcon
          role="img"
          aria-label="Dirty style"
          width={17}
          height={17}
        />
      </Tooltip>
    </IconWrapper>
  )
}

/**
 * This function is essentially formatting the original values
 * into a neat object, ready for comsumption by the tooltip above
 */
export const getDirtyAttributes = ({
  originalValueKey,
  valueToDisplay,
  originalValues,
  labelToDisplay,
}: {
  originalValueKey: string
  valueToDisplay?: string
  originalValues?: any
  labelToDisplay?: string
}): { originalValue: any; originalProperty: string } | undefined => {
  if (originalValues === null) {
    return {
      originalValue: 'non-existent',
      originalProperty:
        labelToDisplay !== undefined
          ? labelToDisplay
          : capitalizeFirst(originalValueKey),
    }
  }

  if (!originalValues) {
    return undefined
  }

  return Object.prototype.hasOwnProperty.call(originalValues, originalValueKey)
    ? {
        originalValue: valueToDisplay !== undefined ? valueToDisplay : '',
        originalProperty:
          labelToDisplay !== undefined
            ? labelToDisplay
            : capitalizeFirst(originalValueKey),
      }
    : undefined
}

const IconWrapper = styled(Flex)`
  box-sizing: border-box;
  height: 24px;
  display: flex;
  align-items: center;
`

const TooltipContent = styled.div`
  max-width: 300px;
  font-size: ${({ theme }) => theme.fontSizes.C};
  text-align: center;
`

const ExclamationMarkIcon = styled(ExclamationMark)`
  cursor: initial;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  margin-top: 4px;
`
