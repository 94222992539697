import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "archive-box-16", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M1 6h14v5a3 3 0 01-3 3H4a3 3 0 01-3-3V6z", fillOpacity: 0.15, fill: "currentColor" }),
        React.createElement("path", { d: "M14.25 6.75v4.5a2 2 0 01-2 2h-8.5a2 2 0 01-2-2v-4.5", stroke: "currentcolor", strokeWidth: 1.5 }),
        React.createElement("rect", { stroke: "currentColor", strokeWidth: 1.5, x: 0.75, y: 2.75, width: 14.5, height: 3.5, rx: 1 }),
        React.createElement("path", { stroke: "currentColor", strokeWidth: 1.5, strokeLinecap: "round", d: "M5.5 9.25h5" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };
