import styled from 'styled-components'
import BaseListPublicationItem, {
  ListPublicationItemPlaceholder as BaseListPublicationItemPlaceholder,
} from '../ListPublicationItem'

export const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const ListPublicationItem = styled(BaseListPublicationItem)`
  &:nth-child(odd) {
    background: ${({ theme }) => theme.colors.background.secondary.B};
  }
`

export const ListPublicationItemPlaceholder = styled(
  BaseListPublicationItemPlaceholder
)`
  &:nth-child(odd) {
    background: ${({ theme }) => theme.colors.background.secondary.B};
  }
`
