import React, { useCallback, useRef, useState } from 'react'
import { Portal } from 'react-portal'

import { PublicPermissionsAccessSelect } from '../../../components/AccessLevelSelect'

import { ConfirmationDialog, Truncate, Tooltip, Pill } from '@sketch/components'

import { useStableHandler } from '@sketch/utils'
import { ReactComponent as GlobeIcon } from '@sketch/icons/globe-16'

import PermissionWrapper from '../PermissionWrapper'
import {
  WorkspaceNameText,
  HelpText,
} from '../ParentAccessLevelDropdown/ParentAccessLevelDropdown.styles'

import {
  AccessLevelSelectInvite,
  PublicPermissionsAvatar,
} from './PublicPermissions.styles'

import usePublicPermissionsNew, {
  ShareForPublicPermissions,
  ModalTypes,
} from './usePublicPermissions'

// Usage of PublicAccessLevel and PrivateAccessLevel will not break the build
// eslint-disable-next-line no-restricted-imports
import { PublicAccessLevel } from '@sketch/gql-types/expansive'
import { useGetWorkspaceQuery } from '@sketch/gql-types'
import { createPublishDocumentModal } from '../../PublishDocumentModal'
import { useIsProfileCreated } from '../../../../community/hooks'

export interface PublicPermissionsProps {
  share: ShareForPublicPermissions
  workspaceId: string
}

type ModalPromise = [() => void, () => void]

/**
 * Modals that are able to be prompt when the users
 * changes public view settings
 */
const MODAL_PROPS = {
  'confirm-private': (shareName: string) => ({
    title: 'Restrict Access?',
    confirmButton: { text: 'Restrict Access' },
    children: (
      <>
        Public users will no longer have access to “<strong>{shareName}</strong>
        ” until you make it public again.
      </>
    ),
  }),
  'confirm-private-community': (shareName: string) => ({
    title: 'Unpublish Document and Make Private?',
    confirmButton: { text: 'Unpublish', variant: 'primary' },
    children: (
      <>
        “<strong>{shareName}</strong>” will be unpublished from the Workspace
        profile. Direct links to the document will be deactivated.
      </>
    ),
  }),
  'confirm-publish-community': (shareName: string, isProfileCreated: boolean) =>
    createPublishDocumentModal({ shareName, isProfileCreated }),
  'confirm-unpublish-community': (shareName: string) => ({
    title: 'Unpublish Document from Profile?',
    confirmButton: {
      text: 'Unpublish',
      variant: 'primary',
    },
    children: (
      <>
        “<strong>{shareName}</strong>” will be unpublished from the Workspace
        profile.
      </>
    ),
  }),
}

/**
 * _usePublicInlineModal_
 *
 * This custom hook contains the that allows a modal to be opened inline on
 * top of a already existing modal
 *
 * it allows to open a modal and use promises to validate if the action should
 * be continued or not
 */
export const usePublicInlineModal = (
  shareName: string,
  workspaceId: string
) => {
  const [activeModal, setActiveModal] = useState<ModalTypes | null>(null)
  const promiseContentRef = useRef<ModalPromise | null>(null)

  const { isProfileCreated } = useIsProfileCreated(workspaceId)

  const openModal = useCallback(
    async (modalType: ModalTypes, callback: () => void) => {
      setActiveModal(modalType)

      try {
        await new Promise<void>((resolve, reject) => {
          promiseContentRef.current = [resolve, reject]
        })
        callback()
      } catch (e) {
        // If the promise is rejected it means that the change won't
        // occur meaning the callback won't be called
      } finally {
        setActiveModal(null)
      }
    },
    []
  )

  let modal
  if (activeModal) {
    const { children, ...otherModalProps } = MODAL_PROPS[activeModal](
      shareName,
      isProfileCreated
    )
    const [resolve, reject] = promiseContentRef.current || []

    modal = (
      <Portal>
        <ConfirmationDialog
          onConfirm={() => resolve?.()}
          hideModal={() => reject?.()}
          isModalOpen
          {...otherModalProps}
        >
          {children}
        </ConfirmationDialog>
      </Portal>
    )
  }

  return [modal, openModal] as const
}

const PublicPermissions = ({ share, workspaceId }: PublicPermissionsProps) => {
  const [modal, openModal] = usePublicInlineModal(share.name, workspaceId)
  const {
    publishShare,
    unpublishShare,
    updatePublicPermissions,
  } = usePublicPermissionsNew({ share, workspaceIdentifier: workspaceId })

  const { data: dataWorkspace } = useGetWorkspaceQuery({
    fetchPolicy: 'cache-only',
    variables: { identifier: workspaceId },
  })

  const restrictedPublicLinks =
    dataWorkspace?.workspace.features.restrictedPublicLinks ?? false

  // Preventive Variables
  const level =
    (share.publicAccessLevel as string) === 'INSPECT'
      ? 'VIEW'
      : share.publicAccessLevel

  // Handlers
  const handleSetLevel = useStableHandler(async (level: PublicAccessLevel) => {
    const downgradeToNonePermission =
      share.publicAccessLevel === 'VIEW' && level === 'NONE'

    if (downgradeToNonePermission && share.publication) {
      openModal('confirm-private-community', () => {
        unpublishShare({ publicAccessLevel: level })
      })
    } else if (downgradeToNonePermission) {
      openModal('confirm-private', () => {
        updatePublicPermissions({ publicAccessLevel: level })
      })
    } else {
      updatePublicPermissions({ publicAccessLevel: level })
    }
  })

  const handleSetCommentsEnabled = useStableHandler(e => {
    const publicCommentsEnabled = e?.target?.checked ?? false
    updatePublicPermissions({ publicCommentsEnabled })
  })

  const handleSetInspectEnabled = useStableHandler(e => {
    const publicInspectEnabled = e?.target?.checked ?? false
    updatePublicPermissions({ publicInspectEnabled })
  })

  const handleSetPublicationEnabled = useStableHandler(e => {
    const publicationEnabled = e?.target?.checked ?? false
    if (publicationEnabled) {
      openModal('confirm-publish-community', publishShare)
    } else {
      openModal('confirm-unpublish-community', () => {
        unpublishShare({ publicAccessLevel: 'VIEW' })
      })
    }
  })

  return (
    <>
      <PermissionWrapper data-testid="public-link-permissions">
        <PublicPermissionsAvatar noAccess={share.publicAccessLevel === 'NONE'}>
          <GlobeIcon />
        </PublicPermissionsAvatar>
        <Truncate>
          <WorkspaceNameText>
            Public{' '}
            {share.publication && (
              <Pill data-testid="published-community-pill" variant="finance">
                Publicly Visible
              </Pill>
            )}
          </WorkspaceNameText>
          <HelpText>Anyone with the link</HelpText>
        </Truncate>
        <AccessLevelSelectInvite aria-label="Public permissions selector">
          <Tooltip
            disabled={!restrictedPublicLinks}
            placement="top-end"
            content="Creating public document links is currently disabled"
          >
            <PublicPermissionsAccessSelect
              level={level}
              setLevel={handleSetLevel}
              commentsEnabled={share.publicCommentsEnabled}
              setCommentsEnabled={handleSetCommentsEnabled}
              inspectEnabled={share.publicInspectEnabled}
              setInspectEnabled={handleSetInspectEnabled}
              published={!!share.publication}
              setPublicationEnabled={handleSetPublicationEnabled}
              levelDisabled={!!(share.publication && !share.userCanPublish)}
              publicationDisabled={!share.userCanPublish}
              optionsDisabled={!share.userCanUpdateCommentsEnabled}
              disabled={!!restrictedPublicLinks}
            />
          </Tooltip>
        </AccessLevelSelectInvite>
      </PermissionWrapper>

      {modal}
    </>
  )
}

export default PublicPermissions
