import { useMapRelativePositionToCameraPosition } from '@sketch-hq/sketch-web-renderer'
import { useCallback } from 'react'
import { SketchElement, Point } from '../../../../../inspector'

export type CanHandleEventPredicate = (element: SketchElement) => boolean

/**
 * Hook returning a function that can be used to find the sketch element behind a given point
 * in the "screen" space (typically extracted from a mouse event relative the canvas HTML element).
 */
export function useFindElementFromCanvasRelativePositionInScreenSpace(
  rootElement: SketchElement | null
) {
  const mapRelativePositionToCameraPosition = useMapRelativePositionToCameraPosition()

  return useCallback(
    (
      canvasRelativePositionInScreenSpace: Point,
      /**
       * Sometimes we want to conditionally allow user to interact with a certain layer.
       * When provided, if this function returns false, the event system will ignore the layer
       * and continue looking for target.
       */
      canHandleEventPredicate?: CanHandleEventPredicate
    ) => {
      if (!rootElement) {
        return null
      }

      const canvasRelativePositionInDocumentSpace = mapRelativePositionToCameraPosition(
        canvasRelativePositionInScreenSpace.x,
        canvasRelativePositionInScreenSpace.y
      )

      if (!canvasRelativePositionInDocumentSpace) {
        return null
      }

      return findElementFromPositionInDocumentSpace(
        rootElement,
        canvasRelativePositionInDocumentSpace,
        canHandleEventPredicate
      )
    },
    [rootElement, mapRelativePositionToCameraPosition]
  )
}

/**
 * Find the layer containing the given point in the document space
 * (Using Sketch coordinates, not screen coordinates)
 */
function findElementFromPositionInDocumentSpace(
  element: SketchElement,
  canvasRelativePositionInDocumentSpace: Point,
  canHandleEventPredicate: CanHandleEventPredicate | undefined
): SketchElement | null {
  const currentBounds = element.prMarinaNode.getAbsoluteBounds()
  const containsPosition = currentBounds.contains(
    canvasRelativePositionInDocumentSpace
  )

  if (!containsPosition) {
    return null
  }

  for (const childElement of element.children) {
    const matchingElement = findElementFromPositionInDocumentSpace(
      childElement,
      canvasRelativePositionInDocumentSpace,
      canHandleEventPredicate
    )

    // Descendants have priority
    // if we found a descendant that contains the position, return it
    if (matchingElement && canHandleEventPredicate?.(matchingElement)) {
      return matchingElement
    }
  }

  if (canHandleEventPredicate?.(element)) {
    return element
  }

  return null
}
