import styled from 'styled-components'

import { Flex, Caption, ButtonUnstyled } from '@sketch/components'

export const Container = styled(Flex)`
  border-radius: 2rem; /* stylelint-disable-line scales/radii */
  background-color: ${({ theme }) => theme.colors.background.secondary.A};
  box-shadow: ${({ theme }) => theme.shadows.boxShadow.subtle};
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  background-clip: padding-box;
  padding: 8px 16px 8px 1.4rem; /* stylelint-disable-line scales/space */
  justify-content: center;
`

export const CookieCaption = styled(Caption)`
  margin: 0;
`

export const CookieLink = styled.a`
  font-weight: 400;
`

export const Close = styled(ButtonUnstyled)`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  transition: color ${({ theme }) => theme.transitions.duration[1]};

  &:hover {
    color: ${({ theme }) => theme.colors.foreground.secondary.C};
  }
`
