import styled, { css } from 'styled-components'

export const popoverStyles = css<PopoverContentProps>`
  ${({
    contentPadding = '8px',
    fillContent,
    fillWidth = '100%',
    theme: { colors, radii, shadows, zIndex },
  }) => css`
    display: flex;
    flex-direction: column;

    min-width: 100px;
    padding: ${contentPadding};

    /* Make the popover on top of the modal */
    z-index: ${zIndex[8]};

    border: 1px solid ${colors.border.A};
    border-radius: ${radii.xxlarge};

    background: ${colors.background.secondary.A};
    box-shadow: ${shadows.boxShadow.outer};
    background-clip: padding-box;
    ${fillContent && `width: calc(${fillWidth} + 2px);`};
  `}
`

const UnstyledPopoverContent = styled.div`
  ${popoverStyles};
`

export interface PopoverContentProps {
  fillContent?: boolean
  fillWidth?: string
  contentPadding?: string
}

export const PopoverContent = styled(UnstyledPopoverContent)``
