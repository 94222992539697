import styled, { css } from 'styled-components'

import { Text } from '../Box'

export const captionStyles = css`
  font-size: ${({ size }) => (size === 'body' ? '1rem' : '0.8125rem')};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  overflow-wrap: ${props => props.overflowWrap || 'normal'};
  color: ${({ theme, color }) => color || theme.colors.foreground.secondary.C};
`

export const Caption = styled(Text).attrs({
  lineHeight: '1.25rem',
})`
  ${captionStyles}

  & p {
    margin-top: 0;
  }
`
