// This is a valid use case of expansive types
// For these types only leaf nodes are used and types are wrapped with Partial<>
// eslint-disable-next-line no-restricted-imports
import { Organization, Version } from '@sketch/gql-types/expansive'

export type ShortIdModelType = 'Organization' | 'Version'

/**
 * There is an ongoing transition from `shortId: ShortID` to `identifier: UUID`
 * However, there might be a need to map from one field to another,
 * especially for already loaded types.
 *
 * e.g. While Version is still using `shortId` field as main cache key, VersionDeleted
 * provides just `identifier`, therefore there is a need to map new field to a new one
 * so that we would be able to perform cleanup.
 */
export class ShortIdsMapper {
  private readonly cacheByShortId: {
    [type in ShortIdModelType]: {
      [shortId: string]: string | undefined
    }
  } = { Organization: {}, Version: {} }

  private readonly cacheByIdentifier: {
    [type in ShortIdModelType]: {
      [identifier: string]: string | undefined
    }
  } = { Organization: {}, Version: {} }

  public record = (
    object: (Partial<Organization> | Partial<Version>) & {
      __typename: ShortIdModelType
    }
  ) => {
    const { identifier, shortId, __typename } = object || {}

    if (!shortId || !identifier) {
      return
    }
    this.cacheByIdentifier[__typename][identifier] = shortId
    this.cacheByShortId[__typename][shortId] = identifier
  }

  toShortId = (
    typename: ShortIdModelType,
    { identifier }: { identifier: string }
  ) => this.cacheByIdentifier[typename][identifier]

  toIdentifier = (
    typename: ShortIdModelType,
    { shortId }: { shortId: string }
  ) => this.cacheByShortId[typename][shortId]
}
