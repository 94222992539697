import React from 'react'
import { Portal } from 'react-portal'
import { capitalize } from 'lodash'

import { useUpdateShareParentPermissions } from '../ShareSettingsModal/ParentPermissions/useUpdateShareParentPermissions'

import { Banner, Box, ConfirmationDialog } from '@sketch/components'
import { ParentAccessLevel } from '@sketch/modules-common'
import InvitePeople from '../ShareSettingsModal/InvitePeople'
import ShareMemberships from '../ShareSettingsModal/ShareMemberships'

import { ShareParentName, StyledRadioButton } from './SharingModal.styles'

import { ShareWithoutVersion } from '../../../versioning'

import { useGetWorkspaceQuery } from '@sketch/gql-types'
import {
  FormLabel,
  RadioLabel,
} from '../../../projects/modals/ProjectSharingModal/Workspace/WorkspacePanel.styles'
import { StyledSeparator } from '../../../projects/modals/ProjectSharingModal/ProjectSharingModal.styles'

export const WorkspaceMembersTitle = ({
  share,
}: {
  share: Pick<ShareWithoutVersion, 'project' | 'parentAccessLevel'>
}) => {
  const viewOnly = share.parentAccessLevel === 'VIEW'
  const inRestrictedProject = share.project?.workspaceAccessLevel === 'NONE'
  const restricted = viewOnly || inRestrictedProject

  return <>Workspace Members {restricted && '(Restricted)'}</>
}

export const RestrictedProjectCallout = ({
  share,
}: {
  share: Pick<ShareWithoutVersion, 'project' | 'isDraft'>
}) => {
  const inRestrictedProject = share.project?.workspaceAccessLevel === 'NONE'
  const isDraft = share.project?.type === 'PERSONAL_DRAFTS'

  if (!inRestrictedProject) {
    return null
  }

  const calloutCopy = isDraft
    ? 'You can share documents in My Drafts with specific people, but not the whole Workspace.'
    : 'This document is in a restricted project. Changing access options will only affect current project Members.'

  return (
    <Box mb={6}>
      <Banner
        type="information"
        data-testid="sharing-workspace-restricted-banner"
      >
        {calloutCopy}
      </Banner>
    </Box>
  )
}

export const WorkspaceMembersPanel = ({
  share,
}: {
  share: ShareWithoutVersion
}) => {
  const {
    getUpdateShareParentPermissionsDropdownProps,
    getUpdateShareParentPermissionsConfirmDialogProps,
    updateShareParentPermissionsConfirmDialogOpen,
    shareParentAccessLevel,
  } = useUpdateShareParentPermissions({ share })

  /**
   * We need to get the workspace features to make sure
   * this workspace is able to see and use permission groups.
   *
   * This cache is already done on the flows this modal shows like
   * - Share Dropdown on the Document card, Workspace (documents)
   * - Share Page View
   */
  const { data } = useGetWorkspaceQuery({
    variables: { identifier: share.workspace.identifier },
    skip: !share.workspace.userIsMember,
  })

  const filter = data?.workspace.features.permissionGroupsEnabled
    ? 'Members and Groups'
    : 'Members'

  const { setLevel } = getUpdateShareParentPermissionsDropdownProps()

  const viewOnly = shareParentAccessLevel === 'VIEW'
  const inRestrictedProject = share.project?.workspaceAccessLevel === 'NONE'

  const workspaceName = share.workspace.name
  const projectName = share.project?.name
  const parentName = inRestrictedProject ? projectName : workspaceName

  const handleAccessLevelChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setLevel(e.target.value as ParentAccessLevel)

  const isDraft = !!(share.isDraft ?? share.project?.type === 'PERSONAL_DRAFTS')

  const shareWithIsDraft = { ...share, isDraft }

  return (
    <>
      <RestrictedProjectCallout share={share} />

      {!isDraft && share.userCanUpdateParentPermissions && (
        <>
          <RadioLabel>
            By default, Members of “
            <ShareParentName>{parentName}</ShareParentName>” can:
          </RadioLabel>

          <StyledRadioButton
            name="accessLevel"
            label="Edit or view this document"
            checked={!viewOnly}
            value="DEFAULT"
            onChange={handleAccessLevelChange}
          />
          <StyledRadioButton
            name="accessLevel"
            label="View this document on the web app"
            checked={viewOnly}
            value="VIEW"
            onChange={handleAccessLevelChange}
          />
          <StyledSeparator />
        </>
      )}

      <FormLabel>
        Add specific “<ShareParentName>{workspaceName}</ShareParentName>”
        Members:
      </FormLabel>

      <InvitePeople
        data-testid="sharing-workspace-invite"
        hideLabel
        share={share}
        filter={filter}
      />

      <ShareMemberships share={shareWithIsDraft} excludeGuests />

      {updateShareParentPermissionsConfirmDialogOpen && (
        <Portal>
          <ConfirmationDialog
            {...getUpdateShareParentPermissionsConfirmDialogProps()}
            title={`Change Workspace Access to ${capitalize(
              shareParentAccessLevel
            )}?`}
            confirmButton={{ text: 'Change Access', variant: 'negative' }}
          >
            You’ll no longer be able to edit “{share.name}”. To get edit access
            again, ask an Admin or somebody with edit access to add you.
          </ConfirmationDialog>
        </Portal>
      )}
    </>
  )
}
