import React from 'react'

import { useForTablet } from '../Breakpoint'

interface SearchExpandedContextValues {
  searchExpanded: boolean
  setSearchExpanded: React.Dispatch<React.SetStateAction<boolean>>
  filtersVisible: boolean
  setFiltersVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const SearchExpandedContext = React.createContext<SearchExpandedContextValues>({
  searchExpanded: false,
  setSearchExpanded: () => {},
  filtersVisible: false,
  setFiltersVisible: () => {},
})

export const useSearchExpanded = () => {
  const context = React.useContext(SearchExpandedContext)

  if (!context) {
    throw new Error(
      'useSearchExpanded must be used within a SearchExpandedProvider'
    )
  }

  return context
}

/**
 * Stores some info needed to place all the components in the header between
 * different portals.
 */
export const SearchExpandedProvider: React.FC = ({ children }) => {
  const isTabletAndBigger = useForTablet()

  // We only want to hide the search by default for mobile views
  const isMobile = !isTabletAndBigger

  const [searchExpanded, setSearchExpanded] = React.useState(
    isMobile ? true : false
  )
  const [filtersVisible, setFiltersVisible] = React.useState(false)

  const value = React.useMemo(
    () => ({
      searchExpanded,
      setSearchExpanded,
      filtersVisible,
      setFiltersVisible,
    }),
    [searchExpanded, setSearchExpanded, filtersVisible, setFiltersVisible]
  )

  return (
    <SearchExpandedContext.Provider value={value}>
      {children}
    </SearchExpandedContext.Provider>
  )
}
