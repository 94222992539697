import { merge } from 'lodash'
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { removeItem } from '@sketch/utils'

import { DevToolsSettingsContext, SetSettingOptions } from './DevToolsContext'
import {
  readSettings,
  storeSettings,
  storeSettingsDebounced,
} from './settings.localStorage'
import {
  DevToolsSettingsState,
  mergeSettingsWithDefaultState,
  PartialDevToolsSettings,
} from './settingsState'
import { Subscription } from './Subscription'

export const DevToolsSettingsProviderImpl: FC<{
  storedState: PartialDevToolsSettings
}> = props => {
  const { storedState, children } = props

  const subscription = useMemo(() => new Subscription(), [])

  // TODO: remove this after ~month of usage on dev environment
  // https://linear.app/sketch/issue/CLC-2411/remove-useeffect-removing-old-local-storage-keys
  useEffect(() => {
    removeItem('user:anonymousFlags')
    removeItem('devToolsUsageMetrics')
    removeItem('devToolsFeatureFlags')
  }, [])

  const settingsPartialRef = useRef(storedState)
  const settingsRef = useRef<DevToolsSettingsState>(
    mergeSettingsWithDefaultState(storedState)
  )

  const setSettings = useCallback(
    (value: PartialDevToolsSettings, options?: SetSettingOptions) => {
      const newPartialState = merge({}, settingsPartialRef.current, value)

      // we want to replace flags overrides object as a whole
      // as otherwise we couldn't remove properties from the state
      if (value.featureFlags?.overrides !== undefined) {
        newPartialState.featureFlags!.overrides = value.featureFlags?.overrides
      }
      settingsPartialRef.current = newPartialState

      const newFullState = mergeSettingsWithDefaultState(newPartialState)
      settingsRef.current = newFullState
      subscription.notify()

      if (options?.immediately) {
        storeSettings(newPartialState)
      } else {
        storeSettingsDebounced(newPartialState)
      }
    },
    [subscription]
  )

  return (
    <DevToolsSettingsContext.Provider
      value={{ setSettings, settings: settingsRef, subscription }}
    >
      {children}
    </DevToolsSettingsContext.Provider>
  )
}

const useDevToolsUrlParam = () => {
  const { search } = useLocation()
  const { replace } = useHistory()

  const shouldDevToolsBeTurnedOn = useMemo(() => {
    if (!search.includes('dev-tools')) {
      return false
    }

    const params = new URLSearchParams(search)
    const isDevToolsParamEnabled = params.get('dev-tools')

    if (isDevToolsParamEnabled === 'true') {
      storeSettings({ general: { isTurnedOn: true } })
      return true
    } else {
      storeSettings(undefined)
    }

    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (search.includes('dev-tools')) {
      // Remove "dev-tools" query params from URL
      replace({
        search: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { shouldDevToolsBeTurnedOn }
}

export const DevToolsSettingsProviderTurnedOnGuard: FC = props => {
  const { children } = props

  const { shouldDevToolsBeTurnedOn } = useDevToolsUrlParam()
  const storedState = useMemo(() => readSettings(), [])
  if (shouldDevToolsBeTurnedOn) {
    storedState.general!.isTurnedOn = true
  }

  if (storedState.general?.isTurnedOn !== true) {
    return <>{children}</>
  }

  return (
    <DevToolsSettingsProviderImpl storedState={storedState}>
      {children}
    </DevToolsSettingsProviderImpl>
  )
}

export const DevToolsSettingsProviderEnvGuard: FC = props => {
  const { children } = props

  if (
    !(
      process.env.REACT_APP_ENV === 'dev' ||
      process.env.REACT_APP_ENV === 'test'
    )
  ) {
    return <>{children}</>
  }

  return (
    <DevToolsSettingsProviderTurnedOnGuard>
      {children}
    </DevToolsSettingsProviderTurnedOnGuard>
  )
}

export const DevToolsSettingsProvider = DevToolsSettingsProviderEnvGuard
