import React, { useState } from 'react'

import { Button, ErrorMessage } from '@sketch/components'
import { useToast } from '@sketch/toasts'

import { ReactComponent as LockIcon } from '@sketch/icons/lock-open.64'
import { useRequestAccessToProjectMutation } from '@sketch/gql-types'

interface ProjectRequestAccessProps {
  projectShortId: string
  hasPendingRequest?: boolean
}

const ProjectRequestAccess: React.FC<ProjectRequestAccessProps> = props => {
  const { hasPendingRequest = false, projectShortId } = props

  const [disableRequest, setDisableRequest] = useState(hasPendingRequest)
  const { showToast } = useToast()

  const [requestAccess, { loading }] = useRequestAccessToProjectMutation({
    variables: { projectShortId },
    onError: error => {
      // TODO:
      // This needs to be adjusted when https://github.com/sketch-hq/Cloud/issues/11423 is done
      if (error.message.includes("You've already requested access")) {
        setDisableRequest(true)
      }

      showToast(error.messages, 'negative')
    },
    onCompleted: () => {
      setDisableRequest(true)
      showToast('Request has been sent')
    },
  })

  return (
    <ErrorMessage
      icon={<LockIcon />}
      title="The project you’re trying to access is restricted"
      description={
        disableRequest
          ? 'You have already requested access to this project.'
          : 'You don’t have permission from the owner to view this project.'
      }
      extra={
        <Button
          variant="primary"
          loading={loading}
          disabled={disableRequest}
          onClick={() => {
            requestAccess()
          }}
          size="40"
        >
          Request Access
        </Button>
      }
    />
  )
}

export default ProjectRequestAccess
