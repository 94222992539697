import { defaultDataIdFromObject, IdGetterObj } from 'apollo-cache-inmemory'
import {
  ArtboardDetailInfoFragment,
  CollectionSharePreviewFragment,
  VersionFragment,
} from '@sketch/gql-types'

export interface ShortIdGetterObj extends Object {
  __typename?: string
  shortId?: string
  permanentArtboardShortId?: string
  documentVersionShortId?: string
}

export interface IdentifierGetterObj extends Object {
  __typename?: string
  identifier?: string
}

export const dataIdFromObject = (
  object: IdGetterObj | ShortIdGetterObj | IdentifierGetterObj
): string | null | undefined => {
  switch (object.__typename) {
    case 'Artboard': {
      const artboard = object as ArtboardDetailInfoFragment
      return `${artboard.__typename}::${artboard.permanentArtboardShortId}::${artboard.documentVersionShortId}`
    }

    case 'Organization':
    case 'Version': {
      const shortIdObject = object as VersionFragment
      return `${shortIdObject.__typename}::${shortIdObject.shortId}`
    }
    case 'Plans': {
      /*
       * "defaultDataIdFromObject" will return null if the object doesn't contain
       * by default an "id" or "_id".
       *
       * Since the "Plans" object doesn't have an ID associated with it, it won't work for "defaultDataIdFromObject",
       * that's why we are forcing one here. So we register the object in cache and access it later on.
       */

      return `${object.__typename}`
    }

    case 'CollectionSharePreview': {
      const collectionSharePreview = object as CollectionSharePreviewFragment
      return `${collectionSharePreview.__typename}::${collectionSharePreview.shareIdentifier}`
    }
    default: {
      const anyObject = object as IdentifierGetterObj
      if (anyObject.identifier) {
        return defaultDataIdFromObject({
          __typename: anyObject.__typename,
          id: anyObject.identifier,
        })
      }
      return defaultDataIdFromObject(object)
    }
  }
}
